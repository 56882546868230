import { Injectable } from '@angular/core';
import { interval } from 'rxjs';

// SERVICES
import { SwUpdate } from '@angular/service-worker';
import { CommonFunctionsService } from './utils/common-functions/common-functions.service';

@Injectable()
export class UpdateService {
  promptEvent: any;

  constructor(
    private updates: SwUpdate,
    private _utilities: CommonFunctionsService
  ) {
    window.addEventListener('beforeinstallprompt', (event) => {
      this.promptEvent = event;
    });

    if (this.updates.isEnabled) {
      interval(300000).subscribe(() =>
        updates.checkForUpdate().then(() => console.log('Checking for updates'))
      );
    }
  }

  public checkForUpdates(): void {
    this.updates.available.subscribe((event) => this.promptUser());
  }

  private promptUser(): void {
    console.log('A new version of REsimpli available');

    this.updates.activateUpdate().then(() => {
      this._utilities.updateAvailable = true;
    });
  }
}
