import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrl } from '../../utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
@Injectable({
  providedIn: 'root',
})
export class TaggingService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem('token');

  constructor(private _commonFunction: CommonFunctionsService) {}

  getTags = (type): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.globalTagsUrl + 'list';

    if (type) {
      endpoint += `?tagType=${type}`;
    }
    return this._commonFunction.globalGetService(endpoint);
  };
  getUsedTagsColor = (type): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.globalTagsUrl + 'getUsedColorIndex';

    if (type) {
      endpoint += `?tagType=${type}`;
    }
    return this._commonFunction.globalGetService(endpoint);
  };
  addTags = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.globalTagsUrl + 'save';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  renameTags = (id, data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.globalTagsUrl + 'update/' + id;
    return this._commonFunction.globalPutService(endpoint, { ...data });
  };
}
