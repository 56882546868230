import { Component, Inject, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ResponseModel } from 'src/app/utils/models/response';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';
import { SharedService } from '../../shared.service';
import { TaggingService } from '../../../providers/tagging/tagging.service';
import { ListStackingService } from './../../../providers/list-stacking/list-stacking.service';
import { ListErrorModel } from 'src/app/utils/models/error';
import { ListResponseModel } from '../../../utils/models/response';
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';
@Component({
  selector: 'app-remove-market-details',
  templateUrl: './remove-market-details.component.html',
  styleUrls: ['./remove-market-details.component.scss'],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class RemoveMarketDetailsComponent implements OnInit {
  public tagsData: any[] = [];
  public removedTags: any = [[], [], [], []];
  public blankMsg: string = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<RemoveMarketDetailsComponent>,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private _sharedService: SharedService,
    private _listStackingService: ListStackingService
  ) {
    this.getData();
  }

  ngOnInit(): void {}

  getData() {
    this._listStackingService.getMarketingList().subscribe(
      (response) => {
        if (response.code == 200) {
          this.tagsData.push(response?.directMail || []);
          this.tagsData.push(response?.coldCalling || []);
          this.tagsData.push(response?.rvm || []);
          this.tagsData.push(response?.sms || []);

          if (
            !this.tagsData[0]?.length &&
            !this.tagsData[1]?.length &&
            !this.tagsData[2]?.length &&
            !this.tagsData[3]?.length
          )
            this.blankMsg = 'No records to show';
        } else {
          this.removedTags = [[], [], [], []];
          this.blankMsg = 'No records to show';
        }
      },
      (err) => {
        this.removedTags = [[], [], [], []];
        this.blankMsg = 'No records to show';
      }
    );
  }
  getMarketName(index) {
    switch (index) {
      case 0:
        return 'Direct Mail';
      case 1:
        return 'Cold Calling';
      case 2:
        return 'RVM';
      case 3:
        return 'SMS';
    }
  }
  removeUndo(id, i, mode) {
    if (mode == 'undo') {
      const index = this.removedTags[i].indexOf(id);
      if (index > -1) {
        this.removedTags[i].splice(index, 1);
      }
    } else this.removedTags[i].push(id);
  }

  submitForm() {
    if (
      !this.removedTags[0]?.length &&
      !this.removedTags[1]?.length &&
      !this.removedTags[2]?.length &&
      !this.removedTags[3]?.length
    ) {
      this._toastrService.error(MessageConstant.tagsRemoveBlank);
      return false;
    }
    console.log(this.removedTags);
    this._loaderService.start();
    this.blankMsg = '';

    let obj = {
      directMail: this.removedTags[0],
      coldCalling: this.removedTags[1],
      rvm: this.removedTags[2],
      sms: this.removedTags[3],
    };
    this._loaderService.start();

    this._listStackingService.removeMarkets(obj).subscribe(
      (response: ListResponseModel) => {
        this._loaderService.stop();
        if (response.code == 200) {
          this.dialog.close('submit');
          this._toastrService.success(response.message);
        }
      },
      (err: ListErrorModel) => {
        this._loaderService.stop();
        this.dialog.close('submit');
      }
    );
  }
}
