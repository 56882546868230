import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

// COMPONENTS
import { SendEmailDialogComponent } from './send-email-dialog/send-email-dialog.component';

// UTILS
import { MessageConstant } from '../../../../utils/message-constant';

@Component({
  selector: 'app-email-dailog',
  templateUrl: './choose-email-dailog.component.html',
  styleUrls: ['./choose-email-dailog.component.scss'],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class ChooseEmailDailogComponent implements OnInit {
  dialogRef: any;
  emailTemplateList: any[] = [];
  messageConstant = MessageConstant;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.emailTemplateList =this.data?.emailList.sort((a, b) => a.templateName.localeCompare(b.templateName));
  }

  onEditEmailTemplate(subtype, action, index, detail) {
    if (detail.message)
      detail.message = detail.message.replace(/(?:\r\n|\r|\n)/g, '<br>');

    this.dialogRef = this.dialog.open(SendEmailDialogComponent, {
      width: '650px',
      panelClass: 'editTemplate',
      disableClose: true,
      data: {
        type: 'lead-info',
        subType: subtype,
        moduleId: this.data.moduleId,
        subModuleId: this.data.subModuleId,
        action: action,
        email: this.data.email,
        items: this.data.items,
        category: '',
        allTitle: {
          heading: 'Send Email',
          buttonTitle: 'Send Email',
        },
        index: index,
        details: detail,
        leadInfo: this.data.details,
        teams: this.data.teams,
      },
    });
  }
}
