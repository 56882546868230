import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ListStackingService } from './../../../providers/list-stacking/list-stacking.service';
import { ListErrorModel } from 'src/app/utils/models/error';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { MessageConstant } from '../../../utils/message-constant';
import { ListResponseModel } from '../../../utils/models/response';
import { saveAs } from 'file-saver';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
@Component({
  selector: 'app-export-prospect',
  templateUrl: './export-prospect.component.html',
  styleUrls: ['./export-prospect.component.scss'],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class ExportProspectComponent implements OnInit {
  selectAllColumn: boolean = true;
  groupList: any[] = [
    'PERSONAL DETAILS',
    'PROPERTY DETAILS',
    'MAILING DETAILS',
    'PHONE NUMBERS',
    'EMAIL ADDRESSES',
    'TAGS & LISTS',
  ];
  checkboxesDataList: any[] = [
    {
      id: 'First_Name',
      label: 'First Name #1',
      isChecked: true,
      group: 'PERSONAL DETAILS',
    },
    {
      id: 'Last_Name',
      label: 'Last Name #1',
      isChecked: true,
      group: 'PERSONAL DETAILS',
    },
    {
      id: 'First_Name_2',
      label: 'First Name #2',
      isChecked: true,
      group: 'PERSONAL DETAILS',
    },
    {
      id: 'Last_Name_2',
      label: 'Last Name #2',
      isChecked: true,
      group: 'PERSONAL DETAILS',
    },
    {
      id: 'companyName',
      label: 'Company Name',
      isChecked: true,
      group: 'PERSONAL DETAILS',
    },
    {
      id: 'Property_Street_Address',
      label: 'Property Street Address',
      isChecked: true,
      group: 'PROPERTY DETAILS',
    },
    {
      id: 'Property_Street_Address_2',
      label: 'Property Street Address 2',
      isChecked: true,
      group: 'PROPERTY DETAILS',
    },
    {
      id: 'Property_City',
      label: 'Property City',
      isChecked: true,
      group: 'PROPERTY DETAILS',
    },
    {
      id: 'Property_State',
      label: 'Property State',
      isChecked: true,
      group: 'PROPERTY DETAILS',
    },
    {
      id: 'Property_Zip_Code',
      label: 'Property Zip Code',
      isChecked: true,
      group: 'PROPERTY DETAILS',
    },
    {
      id: 'Mailing_Street_Address',
      label: 'Mailing Street Address',
      isChecked: true,
      group: 'MAILING DETAILS',
    },
    {
      id: 'Mailing_Street_Address_2',
      label: 'Mailing Street Address 2',
      isChecked: true,
      group: 'MAILING DETAILS',
    },
    {
      id: 'Mailing_City',
      label: 'Mailing City',
      isChecked: true,
      group: 'MAILING DETAILS',
    },
    {
      id: 'Mailing_State',
      label: 'Mailing State',
      isChecked: true,
      group: 'MAILING DETAILS',
    },
    {
      id: 'Mailing_Zip_Code',
      label: 'Mailing Zip Code',
      isChecked: true,
      group: 'MAILING DETAILS',
    },
    {
      id: 'Phone_1',
      label: 'Phone 1',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_1_type',
      label: 'Phone 1 Type',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_1_status',
      label: 'Phone 1 Status',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_2',
      label: 'Phone 2',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_2_type',
      label: 'Phone 2 Type',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_2_status',
      label: 'Phone 2 Status',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_3',
      label: 'Phone 3',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_3_type',
      label: 'Phone 3 Type',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_3_status',
      label: 'Phone 3 Status',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_4',
      label: 'Phone 4',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_4_type',
      label: 'Phone 4 Type',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_4_status',
      label: 'Phone 4 Status',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_5',
      label: 'Phone 5',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_5_type',
      label: 'Phone 5 Type',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_5_status',
      label: 'Phone 5 Status',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_6',
      label: 'Phone 6',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_6_type',
      label: 'Phone 6 Type',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_6_status',
      label: 'Phone 6 Status',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_7',
      label: 'Phone 7',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_7_type',
      label: 'Phone 7 Type',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_7_status',
      label: 'Phone 7 Status',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_8',
      label: 'Phone 8',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_8_type',
      label: 'Phone 8 Type',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_8_status',
      label: 'Phone 8 Status',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_9',
      label: 'Phone 9',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_9_type',
      label: 'Phone 9 Type',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_9_status',
      label: 'Phone 9 Status',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_10',
      label: 'Phone 10',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_10_type',
      label: 'Phone 10 Type',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Phone_10_status',
      label: 'Phone 10 Status',
      isChecked: true,
      group: 'PHONE NUMBERS',
    },
    {
      id: 'Email_1',
      label: 'Email 1',
      isChecked: true,
      group: 'EMAIL ADDRESSES',
    },
    {
      id: 'Email_2',
      label: 'Email 2',
      isChecked: true,
      group: 'EMAIL ADDRESSES',
    },
    {
      id: 'Email_3',
      label: 'Email 3',
      isChecked: true,
      group: 'EMAIL ADDRESSES',
    },
    {
      id: 'Email_4',
      label: 'Email 4',
      isChecked: true,
      group: 'EMAIL ADDRESSES',
    },
    {
      id: 'ListId',
      label: 'Lists',
      isChecked: true,
      group: 'TAGS & LISTS',
    },
    {
      id: 'TagId',
      label: 'Tags',
      isChecked: true,
      group: 'TAGS & LISTS',
    },
  ];

  allTabs: any = { customData: 0, marketingTab: 0, phoneTab: 0 };
  public selectedItemsList: any[] = [];
  public listsDataCSV: any;
  public checkedIDs: any[] = [];
  isMarketing: any = 'no';
  marketingDate: any[] = [null, null, null, null];
  marketType: any[] = [];
  campaignName: any[] = ['', '', '', ''];
  marketTypeVal: any[] = ['Direct Mail', 'Cold Calling', 'SMS', 'RVM'];
  addToAll: any = [];
  selectedPhoneType: any = ['Mobile', 'Landline', 'Voip', 'Other'];
  phoneStatusInclude: any[] = [];
  phoneStatusExclude: any[] = [];
  allPhoneStatus: any[] = [];
  searchedPhoneStatus: any[] = [];
  selectedMarketing: any[] = [];
  includeCondition: string = 'or';
  excludeCondition: string = 'or';
  allPhoneKeys: any[] = [
    {
      phone: 'Phone_1',
      status: 'Phone_1_status',
      type: 'Phone_1_type',
    },
    {
      phone: 'Phone_2',
      status: 'Phone_2_status',
      type: 'Phone_2_type',
    },
    {
      phone: 'Phone_3',
      status: 'Phone_3_status',
      type: 'Phone_3_type',
    },
    {
      phone: 'Phone_4',
      status: 'Phone_4_status',
      type: 'Phone_4_type',
    },
    {
      phone: 'Phone_5',
      status: 'Phone_5_status',
      type: 'Phone_5_type',
    },
    {
      phone: 'Phone_6',
      status: 'Phone_6_status',
      type: 'Phone_6_type',
    },
    {
      phone: 'Phone_7',
      status: 'Phone_7_status',
      type: 'Phone_7_type',
    },
    {
      phone: 'Phone_8',
      status: 'Phone_8_status',
      type: 'Phone_8_type',
    },
    {
      phone: 'Phone_9',
      status: 'Phone_9_status',
      type: 'Phone_9_type',
    },
    {
      phone: 'Phone_10',
      status: 'Phone_10_status',
      type: 'Phone_10_type',
    },
  ];

  public noDataAvailable = 'No Data Available';
  public dMToMinDateValue: any = new Date();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<ExportProspectComponent>,
    private _listStackingService: ListStackingService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _commonFunctionsService: CommonFunctionsService
  ) {}

  ngOnInit(): void {
    this.getStatus();
  }
  getStatus() {
    this._listStackingService.getPhoneStatus().subscribe(
      (response) => {
        if (response.code == 200) {
          this.allPhoneStatus = response?.message?.phoneStatus || [];
          this.allPhoneStatus = this.allPhoneStatus.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
        }
      },
      (err) => {}
    );
  }
  setTabs(tab) {
    if (tab != 'customData') this.allTabs['customData'] = 0;
    if (tab != 'marketingTab') this.allTabs['marketingTab'] = 0;
    if (tab != 'phoneTab') this.allTabs['phoneTab'] = 0;
    this.allTabs[tab] = !this.allTabs[tab];
  }
  changeSelection() {
    this.selectedItemsList = this.checkboxesDataList.filter((value, index) => {
      return value.isChecked;
    });
    if (this.selectedItemsList.length == this.checkboxesDataList.length)
      this.selectAllColumn = true;
    else this.selectAllColumn = false;
  }
  clearMarketingData(e) {
    this.isMarketing = e.target.checked ? 'yes' : 'no';
    if (this.isMarketing == 'no') {
      this.marketingDate = [null, null, null, null];
      this.marketType = [];
      this.campaignName = ['', '', '', ''];
    }
  }
  phoneStatusDownClick(autocomplete: any) {
    autocomplete.handleDropdownClick(1);
  }
  clearListItem(autocomplete: any) {
    autocomplete.value = '';

    autocomplete.handleDropdownClick();
  }

  searchPhoneStatus(searchStr, mode) {
    let tempAr = [];

    this.phoneStatusInclude.filter((element) => {
      tempAr.push(element.name);
    });

    this.phoneStatusExclude.filter((element) => {
      tempAr.push(element.name);
    });

    setTimeout(() => {
      this.searchedPhoneStatus = this.allPhoneStatus.filter((e) => {
        if (
          e.name.toUpperCase().includes(searchStr.toUpperCase()) &&
          tempAr.indexOf(e.name) == -1
        )
          return e;
      });
    }, 500);
  }
  validateMarketingData() {
    if (this.marketType.length == 0) {
      this._toastrService.error('Please select at least one Marketing Type');
      return false;
    }
    let index;
    this.selectedMarketing = [
      {
        directMail: false,
        campaignName: '',
        expectedStartDate: '',
      },
      {
        coldCalling: false,
        campaignName: '',
        expectedStartDate: '',
      },
      {
        exportedForSms: false,
        campaignName: '',
        expectedStartDate: '',
      },
      {
        exportedForRvm: false,
        campaignName: '',
        expectedStartDate: '',
      },
    ];
    for (let i = 0; i < this.marketType.length; i++) {
      index = this.marketTypeVal.indexOf(this.marketType[i]);
      if (index >= 0) {
        if (this.campaignName[index].length == 0) {
          this._toastrService.error(
            'Campaign Name is required for ' + this.marketType[i]
          );
          return false;
        } else if (this.marketingDate[index] == null) {
          this._toastrService.error(
            'Expected Starting Date is required for ' + this.marketType[i]
          );
          return false;
        } else {
          switch (index) {
            case 0:
              this.selectedMarketing[0]['directMail'] = true;
              break;
            case 1:
              this.selectedMarketing[1]['coldCalling'] = true;
              break;
            case 2:
              this.selectedMarketing[2]['exportedForSms'] = true;
              break;
            case 3:
              this.selectedMarketing[3]['exportedForRvm'] = true;
              break;
          }
        }
        this.selectedMarketing[index]['campaignName'] = this.campaignName[
          index
        ];
        this.selectedMarketing[index][
          'expectedStartDate'
        ] = this._commonFunctionsService.dueDateFormat(
          this.marketingDate[index]
        ).dateFormat;
      }
    }
    return true;
  }

  downloadCSV() {
    let myObj = this.data?.filter;
    this.checkedIDs = [];
    this.checkboxesDataList.forEach((value, index) => {
      if (value.isChecked) {
        this.checkedIDs.push(value.id);
      }
    });
    if (!this.checkedIDs.length) {
      this._toastrService.error('Please select at least one column');
      return;
    }
    if (this.isMarketing == 'yes' && !this.validateMarketingData()) return;

    myObj['exportedForMarketing'] = this.isMarketing == 'yes' ? true : false;
    myObj['exportDetails'] =
      this.isMarketing == 'yes' ? this.selectedMarketing : [];
    myObj['updateRelatedProperty'] = false;
    if (this.addToAll && this.addToAll.length > 0)
      myObj['updateRelatedProperty'] = true;
    myObj['dataColumns'] = this.checkedIDs;
    if (this.selectedPhoneType.length)
      myObj['exportPhoneType'] = { value: this.selectedPhoneType };
    if (this.phoneStatusInclude.length)
      myObj['exportPhoneStatusInclude'] = {
        value: this.phoneStatusInclude
          .filter((e) => e.name != '')
          .map((x) => x.name),
        selectedCondition: this.includeCondition,
      };
    if (this.phoneStatusExclude.length)
      myObj['exportPhoneStatusExclude'] = {
        value: this.phoneStatusExclude
          .filter((e) => e.name != '')
          .map((x) => x.name),
        selectedCondition: this.excludeCondition,
      };

    this._loaderService.start();
    this._listStackingService.getAllListCSV(myObj).subscribe(
      (response) => {
        this._loaderService.stop();
        if (response.code == 200) {
          // this.listsDataCSV = response.data;
          // this.exportCSV();
          this._toastrService.success('Export Log Created Successfully');
          this.dialog.close(myObj);
        } else {
          this._toastrService.error(response.msg);
        }
      },
      (err) => {
        this._loaderService.stop();
      }
    );
  }
  exportCSV() {
    let fieldSequence = [
      {
        id: 'First_Name',
        label: 'First Name #1',
      },
      {
        id: 'Last_Name',
        label: 'Last Name #1',
      },
      {
        id: 'First_Name_2',
        label: 'First Name #2',
      },
      {
        id: 'Last_Name_2',
        label: 'Last Name #2',
      },
      {
        id: 'companyName',
        label: 'Company Name',
      },
      {
        id: 'Property_Street_Address',
        label: 'Property Street Address',
      },
      {
        id: 'Property_Street_Address_2',
        label: 'Property Street Address 2',
      },
      {
        id: 'Property_City',
        label: 'Property City',
      },
      {
        id: 'Property_State',
        label: 'Property State',
      },
      {
        id: 'Property_Zip_Code',
        label: 'Property Zip Code',
      },
      {
        id: 'Mailing_Street_Address',
        label: 'Mailing Street Address',
      },
      {
        id: 'Mailing_Street_Address_2',
        label: 'Mailing Street Address 2',
      },
      {
        id: 'Mailing_City',
        label: 'Mailing City',
      },
      {
        id: 'Mailing_State',
        label: 'Mailing State',
      },
      {
        id: 'Mailing_Zip_Code',
        label: 'Mailing Zip Code',
      },
      {
        id: 'Phone_1',
        label: 'Phone 1',
      },
      {
        id: 'Phone_1_type',
        label: 'Phone 1 Type',
      },
      {
        id: 'Phone_1_status',
        label: 'Phone 1 Status',
      },
      {
        id: 'Phone_2',
        label: 'Phone 2',
      },
      {
        id: 'Phone_2_type',
        label: 'Phone 2 Type',
      },
      {
        id: 'Phone_2_status',
        label: 'Phone 2 Status',
      },
      {
        id: 'Phone_3',
        label: 'Phone 3',
      },
      {
        id: 'Phone_3_type',
        label: 'Phone 3 Type',
      },
      {
        id: 'Phone_3_status',
        label: 'Phone 3 Status',
      },
      {
        id: 'Phone_4',
        label: 'Phone 4',
      },
      {
        id: 'Phone_4_type',
        label: 'Phone 4 Type',
      },
      {
        id: 'Phone_4_status',
        label: 'Phone 4 Status',
      },
      {
        id: 'Phone_5',
        label: 'Phone 5',
      },
      {
        id: 'Phone_5_type',
        label: 'Phone 5 Type',
      },
      {
        id: 'Phone_5_status',
        label: 'Phone 5 Status',
      },
      {
        id: 'Phone_6',
        label: 'Phone 6',
      },
      {
        id: 'Phone_6_type',
        label: 'Phone 6 Type',
      },
      {
        id: 'Phone_6_status',
        label: 'Phone 6 Status',
      },
      {
        id: 'Phone_7',
        label: 'Phone 7',
      },
      {
        id: 'Phone_7_type',
        label: 'Phone 7 Type',
      },
      {
        id: 'Phone_7_status',
        label: 'Phone 7 Status',
      },
      {
        id: 'Phone_8',
        label: 'Phone 8',
      },
      {
        id: 'Phone_8_type',
        label: 'Phone 8 Type',
      },
      {
        id: 'Phone_8_status',
        label: 'Phone 8 Status',
      },
      {
        id: 'Phone_9',
        label: 'Phone 9',
      },
      {
        id: 'Phone_9_type',
        label: 'Phone 9 Type',
      },
      {
        id: 'Phone_9_status',
        label: 'Phone 9 Status',
      },
      {
        id: 'Phone_10',
        label: 'Phone 10',
      },
      {
        id: 'Phone_10_type',
        label: 'Phone 10 Type',
      },
      {
        id: 'Phone_10_status',
        label: 'Phone 10 Status',
      },
      {
        id: 'Email_1',
        label: 'Email 1',
      },
      {
        id: 'Email_2',
        label: 'Email 2',
      },
      {
        id: 'Email_3',
        label: 'Email 3',
      },
      {
        id: 'Email_4',
        label: 'Email 4',
      },
      {
        id: 'ListId',
        label: 'Lists',
      },
      {
        id: 'TagId',
        label: 'Tags',
      },
    ];

    let finalHeader = [];
    let objectOrder = {};
    fieldSequence.forEach((value, index) => {
      if (this.checkedIDs.indexOf(value.id) >= 0) {
        finalHeader.push(value.label);
        objectOrder[value.id] = null;
      }
    });
    let rearrangedData = [];
    let keys = [];
    keys = Object.keys(objectOrder);
    let tempObj = {};
    let maxTagNumber = 0,
      maxListNumber = 0;
    for (let i = 0; i < this.listsDataCSV.length; i++) {
      tempObj = {};
      this.getFilteredValue(i);
      for (let j = 0; j < keys.length; j++) {
        if (typeof this.listsDataCSV[i][keys[j]] != 'undefined') {
          if (keys[j] == 'ListId' || keys[j] == 'TagId') {
            let keyName = '';
            if (keys[j] == 'ListId') {
              keyName = 'List_';
              if (maxListNumber < this.listsDataCSV[i][keys[j]].length)
                maxListNumber = this.listsDataCSV[i][keys[j]].length;
            } else {
              keyName = 'Tag_';
              if (maxTagNumber < this.listsDataCSV[i][keys[j]].length)
                maxTagNumber = this.listsDataCSV[i][keys[j]].length;
            }
            for (let k = 0; k < this.listsDataCSV[i][keys[j]].length; k++) {
              tempObj[keyName + (k + 1)] = this.listsDataCSV[i][keys[j]][k];
            }
          } else {
            tempObj[keys[j]] = this.listsDataCSV[i][keys[j]];
          }
        }
      }

      rearrangedData.push(tempObj);
    }

    let finalKeys = [];
    for (let j = 0; j < keys.length; j++) {
      if (keys[j] != 'ListId' && keys[j] != 'TagId') {
        finalKeys.push(keys[j]);
      }
    }
    for (let i = 0; i < maxListNumber; i++) {
      finalKeys.push('List_' + (i + 1));
    }
    for (let i = 0; i < maxTagNumber; i++) {
      finalKeys.push('Tag_' + (i + 1));
    }

    //new Angular2Csv(rearrangedData, "Report", options);
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    // const header = Object.keys(data[0]);
    let csv = rearrangedData.map((row) =>
      finalKeys
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(finalKeys.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, 'list-stacking-properties.csv');
  }
  getFilteredValue(index) {
    this.allPhoneKeys.filter((keys) => {
      if (!this.listsDataCSV[index][keys.status])
        this.listsDataCSV[index][keys.status] = [];
      if (this.listsDataCSV[index][keys.phone]) {
        //Filter for phone type
        if (this.selectedPhoneType.length) {
          if (
            this.selectedPhoneType.indexOf(
              this.listsDataCSV[index][keys.type]
            ) == -1
          ) {
            this.listsDataCSV[index][keys.phone] = '';
            this.listsDataCSV[index][keys.type] = '';
            this.listsDataCSV[index][keys.status] = '';
            return;
          }
        }
        //Filter for phone include & exclude
        if (this.phoneStatusInclude.length || this.phoneStatusExclude.length) {
          if (typeof this.listsDataCSV[index][keys.status] != undefined) {
            let includeMatched = false,
              excludeMatch = false;
            includeMatched = this.getMatched(
              this.phoneStatusInclude,
              this.listsDataCSV[index][keys.status],
              this.includeCondition
            );
            excludeMatch = this.getMatched(
              this.phoneStatusExclude,
              this.listsDataCSV[index][keys.status],
              this.excludeCondition
            );
            if (
              (this.phoneStatusInclude.length && !includeMatched) ||
              (this.phoneStatusExclude.length && excludeMatch)
            ) {
              this.listsDataCSV[index][keys.phone] = '';
              this.listsDataCSV[index][keys.type] = '';
              this.listsDataCSV[index][keys.status] = '';
              return;
            }
          }
        }
        if (typeof this.listsDataCSV[index][keys.status] != undefined) {
          this.listsDataCSV[index][keys.status] = this.listsDataCSV[index][
            keys.status
          ].join();
        }
      } else {
        this.listsDataCSV[index][keys.status] = '';
      }
    });
  }
  getMatched(array1, array2, condition) {
    let matchedElement = [],
      matched = false;
    matchedElement = array1.filter((element) => array2.includes(element.name));
    //checking for condition
    if (condition == 'or' && matchedElement.length) {
      matched = true;
    } else if (condition == 'and' && matchedElement.length == array1.length) {
      matched = true;
    } else if (
      condition == 'only' &&
      matchedElement.length == array1.length &&
      array1.length == array2.length
    ) {
      matched = true;
    }
    return matched;
  }
  selectUnselect() {
    this.selectAllColumn = !this.selectAllColumn;
    this.checkboxesDataList.map(
      (e) => (e.isChecked = this.selectAllColumn ? true : false)
    );
  }
}
