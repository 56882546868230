import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrl } from '../../utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

@Injectable({
  providedIn: 'root',
})
export class CallFlowService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem('token');

  constructor(private _commonFunction: CommonFunctionsService) {}

  saveFlow = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.callFlowUrl + 'save';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  editFlow = (data, flowId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.callFlowUrl + 'edit';
    if (flowId) {
      endpoint += `/${flowId}`;
    }

    return this._commonFunction.globalPostService(endpoint, data);
  };

  flowInfo = (flow): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.callFlowUrl + 'details';
    return this._commonFunction.globalPostService(endpoint, flow);
  };

  downloadFile = (flow): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.callFlowUrl + 'downloadFile';

    return this._commonFunction.globalPostService(endpoint, flow);
  };

  getInAppAmt = (flow): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.userV2Url + 'getInAppAnswerChargeAmount';
    return this._commonFunction.globalPostService(endpoint, flow);
  };

  makeInAppPayment = (flow): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.userV2Url + 'inAppAnswerCharge';
    return this._commonFunction.globalPostService(endpoint, flow);
  };

  flowConference = (flow): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.callFlowUrl + 'plivoConferenceCall';
    return this._commonFunction.globalPostService(endpoint, flow);
  };

  flowConferenceDetails = (flow): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.callFlowUrl + 'plivoConferenceCallDetails';
    return this._commonFunction.globalPostService(endpoint, flow);
  };

  flowConferenceAddParticipant = (flow): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.callFlowUrl + 'plivoConferenceAddParticipant';
    return this._commonFunction.globalPostService(endpoint, flow);
  };

  flowConferenceMergeParticipant = (flow): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.callFlowUrl + 'plivoConferenceMergeParticipant';
    return this._commonFunction.globalPostService(endpoint, flow);
  };

  plivoConferenceList = (flow): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.callFlowUrl + 'plivoConferenceList';
    return this._commonFunction.globalPostService(endpoint, flow);
  };

  dropMember = (flow): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.callFlowUrl + 'dropMember';
    return this._commonFunction.globalPostService(endpoint, flow);
  };

  unHoldMember = (flow): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.callFlowUrl + 'unHoldMember';
    return this._commonFunction.globalPostService(endpoint, flow);
  };

  allHoldUnholdMember = (flow): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.callFlowUrl + 'allHoldUnholdMember';
    return this._commonFunction.globalPostService(endpoint, flow);
  };

  endCall = (flow): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.callFlowUrl + 'endCall';
    return this._commonFunction.globalPostService(endpoint, flow);
  };

  getCallInfo = (flow): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.callFlowUrl + 'getCallInfo';
    return this._commonFunction.globalPostService(endpoint, flow);
  };
  getCallInfoIncoming = (flow): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.callFlowUrl + 'getCallInfoIncoming';
    return this._commonFunction.globalPostService(endpoint, flow);
  };
}
