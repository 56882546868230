import {
  Component,
  Inject,
  OnInit,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs/operators';

// UTILS
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';
import { environment } from 'src/environments/environment';
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharedService } from '../../shared.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { MasterTaskService } from 'src/app/providers/master-task/master-task.service';
import { UserService } from 'src/app/providers/user/user.service';
import { CreditService } from 'src/app/providers/credit/credit.service';
import { MarketsService } from 'src/app/providers/markets/markets.service';
import { DripService } from 'src/app/providers/drip/drip.service';
import { TaggingService } from 'src/app/providers/tagging/tagging.service';

// COMPONENTS
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-edit-number',
  templateUrl: './edit-number.component.html',
  styleUrls: ['./edit-number.component.scss'],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class EditNumberComponent implements OnInit {
  manageNumberForm: FormGroup;

  messageConstant = MessageConstant;
  getID: any = '';
  moduleName = 'Edit Manage Number';
  leadSources: any = '';
  flowList: any[];
  getManageNumber: any = '';
  submitted: boolean = false;
  isSubmitting: boolean = false;
  disableButton: boolean = false;
  getAction: String;

  users: any[] = [];
  roles: any[] = [];
  allUserRoles: any[] = [];
  markets: any[] = [];
  generalMarket: any;
  currentPage: number = 1;
  currentLimit: number = 100; //environment.pagination.pageLimit;
  isAllowManageNumberFeature: boolean = false;

  allRoles: any[] = [
    'accountant',
    'acquisitionManager',
    'acquisitionSalesManager',
    'admin',
    'bookkeeper',
    'coldCaller',
    'closingCoordinator',
    'coOwner',
    'dispositionManager',
    'leadManager',
    'marketingAssistant',
    'marketingManager',
    'officeManager',
    'otherRole',
    'owner',
    'projectManager',
    'propertyAnalyst',
    'propertyManager',
    'transactionCoordinator',
  ];
  userRoles: any = {};
  dialogRef: any;
  dripList: any = [];

  @ViewChild('tagInput1') tagInput1: ElementRef<HTMLInputElement>;
  @ViewChild('tagInput2') tagInput2: ElementRef<HTMLInputElement>;
  @ViewChild('tagInput3') tagInput3: ElementRef<HTMLInputElement>;
  @ViewChild('tagInput4') tagInput4: ElementRef<HTMLInputElement>;
  @ViewChild('tagInput5') tagInput5: ElementRef<HTMLInputElement>;
  @ViewChild('auto1') matAutocomplete1: MatAutocomplete;
  @ViewChild('auto2') matAutocomplete2: MatAutocomplete;
  @ViewChild('auto3') matAutocomplete3: MatAutocomplete;
  @ViewChild('auto4') matAutocomplete4: MatAutocomplete;
  @ViewChild('auto5') matAutocomplete5: MatAutocomplete;
  public colorCodes = MiscellaneousConstant.colorCodes;
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl1 = new FormControl();
  tagCtrl2 = new FormControl();
  tagCtrl3 = new FormControl();
  tagCtrl4 = new FormControl();
  tagCtrl5 = new FormControl();
  filteredTags1: Observable<any[]>;
  filteredTags2: Observable<any[]>;
  filteredTags3: Observable<any[]>;
  filteredTags4: Observable<any[]>;
  filteredTags5: Observable<any[]>;
  tags1: any[] = [];
  tags2: any[] = [];
  tags3: any[] = [];
  tags4: any[] = [];
  tags5: any[] = [];
  allTagList: any[] = [];
  unavailableColorIndex: any[] = [];
  unavailableColorIndexResponse: any[] = [];

  isShowPlaceHolder1: boolean = false;
  isShowPlaceHolder2: boolean = false;
  isShowPlaceHolder3: boolean = false;
  isShowPlaceHolder4: boolean = false;
  isShowPlaceHolder5: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialog: MatDialog,
    private dialog: MatDialogRef<EditNumberComponent>,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private _sharedService: SharedService,
    private fb: FormBuilder,
    public _utilities: CommonFunctionsService,
    private _masterTaskService: MasterTaskService,
    private _userService: UserService,
    private _marketServices: MarketsService,
    private _dripService: DripService,
    private _taggingService: TaggingService,
    private _creditService: CreditService
  ) {}

  ngOnInit(): void {
    this.getLeadSourceData();
    this.getManageFlow();
    this.getMarkets();
    this.getUserPlan();
    if (!this.data.details?.manualLeadDripId) {
      this.data.details.manualLeadDripId = '';
    }
    if (!this.data.details?.unansDripId) {
      this.data.details.unansDripId = '';
    }
    if (!this.data.details?.ansDripId) {
      this.data.details.ansDripId = '';
    }
    if (!this.data.details?.zapDripId) {
      this.data.details.zapDripId = '';
    }
    if (!this.data.details?.webFormDripId) {
      this.data.details.webFormDripId = '';
    }
    this.manageNumberForm = this.fb.group({
      marketingTitle: [this.data.details.marketingTitle, Validators.required],
      crmQuestionId: ['', Validators.required],
      number: [
        this._utilities.maskNumber(this.data.details.number),
        Validators.required,
      ],
      flowId: ['', Validators.required],

      // MARKET
      marketId: ['', []],
      accountant: [[], []],
      acquisitionManager: [[], []],
      acquisitionSalesManager: [[], []],
      admin: [[], []],
      bookkeeper: [[], []],
      coldCaller: [[], []],
      closingCoordinator: [[], []],
      coOwner: [[], []],
      dispositionManager: [[], []],
      leadManager: [[], []],
      marketingAssistant: [[], []],
      marketingManager: [[], []],
      officeManager: [[], []],
      otherRole: [[], []],
      owner: [[], []],
      projectManager: [[], []],
      propertyAnalyst: [[], []],
      propertyManager: [[], []],
      transactionCoordinator: [[], []],
      isAutoAssignDrip: [false],
      isLeadCreatedManual: [false],
      manualLeadDripId: [''],
      isManualLeadAutoStopDrip: [false],
      isDripAnsCall: [false],
      ansDripId: [''],
      isAnsAutoStopDrip: [false],
      isDripUnansCall: [false],
      unansDripId: [''],
      isUnansAutoStopDrip: [false],
      isDripZap: [false],
      zapDripId: [''],
      isZapAutoStopDrip: [false],
      isDripWebForm: [false],
      webFormDripId: [''],
      isWebFormAutoStopDrip: [false],
      isTagAutomation: [false],
      isTagLeadCreatedManual: [false],
      isTagAnsCall: [false],
      isTagUnansCall: [false],
      isTagZap: [false],
      isTagWebForm: [false],
    });

    this.onDripList();
    this.getAllTags();
    this.getUsedColorIndex();
  }

  getMarkets() {
    const obj = {
      page: this.currentPage,
      limit: this.currentLimit,
    };

    this._loaderService.start();
    this._marketServices.getMarkets(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.markets = response.data?.items?.filter(
            (x) => x.isEnabled === true
          );

          this.generalMarket = this.markets.find(
            (x) => x.title === 'Primary Market'
          );

          this.getRoles();
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getRoles() {
    this._userService.getRoles({}).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          this.roles = response?.data?.map((x) => {
            let obj = {
              label: x.name,
              value: x.key,
            };

            return obj;
          });

          this.allUserRoles = _.sortBy(this.roles, ['label']);
          this.roles = _.sortBy(this.roles, ['label']);

          let data = JSON.parse(JSON.stringify(this.data?.details));
          delete data['crmQuestionId'];
          this.manageNumberForm.patchValue({
            ...data,
            number: this._utilities.maskNumber(data.number),
          });

          this.getUsers();
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  mapRoles() {
    this.allRoles.filter((role) => {
      this.manageNumberForm.get(role).setValue([]);
      this.manageNumberForm.get(role).clearValidators();
      this.manageNumberForm.get(role).setErrors(null);
      this.manageNumberForm.get(role).updateValueAndValidity();
    });

    this.roles = this.roles
      .map((role) => {
        const { marketId } = this.manageNumberForm.value;
        const marketIndex = this.markets.findIndex(
          (market) => market?._id === marketId
        );

        if (marketIndex > -1) {
          if (marketId === this.data.details.marketId) {
            this.manageNumberForm.patchValue({
              [role.value]: this.data.details[role?.value],
            });
          } else {
            this.manageNumberForm.patchValue({
              [role.value]: this.markets[marketIndex][role?.value],
            });
          }

          return role;
        }
      })
      .filter((x) => x);

    this.roles.filter((x) => {
      this.manageNumberForm.get(x?.value).setValidators([Validators.required]);
    });
  }

  getUsers() {
    const obj = {
      page: this.currentPage,
      limit: 100,
    };

    this._masterTaskService.getUserRoleList(obj).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          this.users = response.data.items.map((x) => {
            let obj = {
              label: `${x?.firstName} ${x?.lastName}`,
              value: x?._id,
              roleData: x?.roleData,
            };

            return obj;
          });

          this.getUserByRole();
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  changeMarket() {
    const { marketId } = this.manageNumberForm.value;
    if (this.data.details.marketId === marketId) {
      return;
    }

    const fromMarketIndex = this.markets.findIndex(
      (market) =>
        market?._id ===
        (this.data.details.marketId ||
          this.generalMarket?._id ||
          this.markets[0]?._id)
    );
    const toMarketIndex = this.markets.findIndex(
      (market) => market?._id === marketId
    );

    this.dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '600px',
      data: {
        yesTitle: 'Yes',
        noTitle: 'Cancel',
        header: 'Change Market',
        text: `Once you change the market of this campaign from ${this.markets[fromMarketIndex]?.title} to ${this.markets[toMarketIndex]?.title}, the team will get reassigned to ${this.markets[toMarketIndex]?.title} team and all the tasks will get reassigned to ${this.markets[toMarketIndex]?.title} team. Are you sure you want to continue?`,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getUserByRole();
        return;
      }

      this.manageNumberForm.patchValue({
        marketId:
          this.data.details.marketId ||
          this.generalMarket?._id ||
          this.markets[0]?._id,
      });
    });
  }

  getUserByRole() {
    this.userRoles = {};
    this.mapRoles();
    this.roles = this.allUserRoles;
    this.roles.filter((currentRole) => {
      let isReadOnly = false;
      const users = this.users
        .filter((user) => {
          const { marketId } = this.manageNumberForm.value;
          const marketIndex = this.markets.findIndex(
            (market) => market?._id === marketId
          );

          if (
            marketIndex > -1 &&
            this.markets[marketIndex][currentRole.value].length &&
            this.markets[marketIndex][currentRole.value].includes(user.value)
          ) {
            return user;
          }
        })
        .filter((x) => x);

      if (users.length === 1) {
        isReadOnly = true;
      }

      this.userRoles[currentRole?.value] = { users, isReadOnly };
    });
  }

  public get f() {
    return this.manageNumberForm.controls;
  }

  getLeadSourceData() {
    const data = {
      searchString: 'hear-about-us',
    };
    this._sharedService.getLeadSource(data).subscribe(
      (response) => {
        if (response) {
          this.submitted = false;
          this.leadSources = response.data.items;

          this.leadSources.filter((x) => {
            if (x.answer == this.data.details.crmQuestionId) {
              this.manageNumberForm.patchValue({
                crmQuestionId: x?._id,
              });
            }
          });
        }
      },
      (err: ErrorModel) => {
        // this._toastrService.error(error.message ? error.message : this.messageConstant.unknownError,);
      }
    );
  }

  getManageFlow() {
    const obj = {
      page: 1,
      limit: 2000,
    };

    this._sharedService.getManageFlow(obj).subscribe(
      (response) => {
        if (response) {
          this.flowList = response?.data?.items;
          this.flowList.sort((a, b) => a.name.localeCompare(b.name));
          for (let i = 0; i < this.flowList.length; i++) {
            if (this.flowList[i]['_id'] == this.data?.details?.flow?._id) {
              this.manageNumberForm.patchValue({
                flowId: this.flowList[i]['_id'],
              });
              break;
            }
          }
        }
      },
      (err: ErrorModel) => {}
    );
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.manageNumberForm.controls[controlName].hasError(errorName);
  };

  async submitForm() {
    this.submitted = true;
    if (this.manageNumberForm.invalid) {
      return;
    }
    if (this.manageNumberForm?.value?.isAutoAssignDrip) {
      if (
        this.manageNumberForm?.value?.isLeadCreatedManual == false &&
        this.manageNumberForm?.value?.isDripAnsCall == false &&
        this.manageNumberForm?.value?.isDripUnansCall == false &&
        this.manageNumberForm?.value?.isDripZap == false &&
        this.manageNumberForm?.value?.isDripWebForm == false
      ) {
        this._toastrService.error('Please select drip');
        return;
      }

      if (
        this.manageNumberForm?.value?.isLeadCreatedManual &&
        this.manageNumberForm?.value?.manualLeadDripId == ''
      ) {
        this._toastrService.error('Please select drip');
        return;
      }
      if (
        this.manageNumberForm?.value?.isDripAnsCall &&
        this.manageNumberForm?.value?.ansDripId == ''
      ) {
        this._toastrService.error('Please select drip');
        return;
      }
      if (
        this.manageNumberForm?.value?.isDripUnansCall &&
        this.manageNumberForm?.value?.unansDripId == ''
      ) {
        this._toastrService.error('Please select drip');
        return;
      }
      if (
        this.manageNumberForm?.value?.isDripZap &&
        this.manageNumberForm?.value?.zapDripId == ''
      ) {
        this._toastrService.error('Please select drip');
        return;
      }
      if (
        this.manageNumberForm?.value?.isDripWebForm &&
        this.manageNumberForm?.value?.webFormDripId == ''
      ) {
        this._toastrService.error('Please select drip');
        return;
      }
    }

    let leadCreatedManualTagIds: any = [];
    let ansCallTagIds: any = [];
    let unansCallTagIds: any = [];
    let zapTagIds: any = [];
    let webFormTagIds: any = [];
    if (this.manageNumberForm?.value?.isTagAutomation) {
      if (this.manageNumberForm?.value?.isTagLeadCreatedManual) {
        if (this.tags1.length == 0) {
          this._toastrService.error("Tags can't be blank");
          return;
        }
      }
      if (this.manageNumberForm?.value?.isTagAnsCall) {
        if (this.tags2.length == 0) {
          this._toastrService.error("Tags can't be blank");
          return;
        }
      }
      if (this.manageNumberForm?.value?.isTagUnansCall) {
        if (this.tags3.length == 0) {
          this._toastrService.error("Tags can't be blank");
          return;
        }
      }
      if (this.manageNumberForm?.value?.isTagZap) {
        if (this.tags4.length == 0) {
          this._toastrService.error("Tags can't be blank");
          return;
        }
      }
      if (this.manageNumberForm?.value?.isTagWebForm) {
        if (this.tags5.length == 0) {
          this._toastrService.error("Tags can't be blank");
          return;
        }
      }
      if (this.manageNumberForm?.value?.isTagLeadCreatedManual) {
        leadCreatedManualTagIds = await this.tagSubmitForm(1);
        // if (leadCreatedManualTagIds?.length == 0) {
        //   this._toastrService.error("Tags can't be blank");
        //   return;
        // }
      }
      if (this.manageNumberForm?.value?.isTagAnsCall) {
        ansCallTagIds = await this.tagSubmitForm(2);
        // if (ansCallTagIds?.length == 0) {
        //   this._toastrService.error("Tags can't be blank");
        //   return;
        // }
      }
      if (this.manageNumberForm?.value?.isTagUnansCall) {
        unansCallTagIds = await this.tagSubmitForm(3);
        // if (unansCallTagIds?.length == 0) {
        //   this._toastrService.error("Tags can't be blank");
        //   return;
        // }
      }
      if (this.manageNumberForm?.value?.isTagZap) {
        zapTagIds = await this.tagSubmitForm(4);
        // if (zapTagIds?.length == 0) {
        //   this._toastrService.error("Tags can't be blank");
        //   return;
        // }
      }
      if (this.manageNumberForm?.value?.isTagWebForm) {
        webFormTagIds = await this.tagSubmitForm(5);
        // if (webFormTagIds?.length == 0) {
        //   this._toastrService.error("Tags can't be blank");
        //   return;
        // }
      }
    }

    this._loaderService.start();
    this.disableButton = true;
    this.isSubmitting = false;

    const details = {
      ...this.manageNumberForm.value,
      number: this.data.details.number,
      leadCreatedManualTagIds: leadCreatedManualTagIds,
      ansCallTagIds: ansCallTagIds,
      unansCallTagIds: unansCallTagIds,
      zapTagIds: zapTagIds,
      webFormTagIds: webFormTagIds,
    };

    if (!details['marketId']) {
      delete details['marketId'];
    }

    this._sharedService.editNumber(details).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode === 200) {
          this.submitted = false;
          let txt = '';
          for (let i = 0; i < this.leadSources.length; i++) {
            if (
              this.leadSources[i]['_id'] ==
              this.manageNumberForm.value.crmQuestionId
            ) {
              txt = this.leadSources[i]['answer'];
              break;
            }
          }
          let flowName = '';
          let flow = {};
          for (let i = 0; i < this.flowList.length; i++) {
            if (this.flowList[i]['_id'] == this.manageNumberForm.value.flowId) {
              flowName = this.flowList[i]['name'];
              flow = {
                _id: this.flowList[i]['_id'],
                name: this.flowList[i]['name'],
              };
              break;
            }
          }
          this.dialog.close({
            ...response.data,
            marketingTitle: details.marketingTitle,
            crmQuestionId: txt,
            flow,
            flowName,
          });

          this._toastrService.success(
            this.messageConstant.numberUpdatedSuccess,
            ''
          );
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  onDripList() {
    this._loaderService.start();
    let obj = {
      page: this.currentPage,
      limit: this.currentLimit,
    };
    this._dripService.listDrip(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          // this._loaderService.stop();
          this.dripList = response.data.items;
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  onAutoAssignDrip() {
    if (this.manageNumberForm?.value?.isAutoAssignDrip == false) {
      this.manageNumberForm.patchValue({
        isLeadCreatedManual: false,
        manualLeadDripId: '',
        isManualLeadAutoStopDrip: false,
        isDripAnsCall: false,
        ansDripId: '',
        isAnsAutoStopDrip: false,
        isDripUnansCall: false,
        unansDripId: '',
        isUnansAutoStopDrip: false,
      });
    }
  }

  onLeadCreatedManual() {
    if (this.manageNumberForm?.value?.isLeadCreatedManual == false) {
      this.manageNumberForm.patchValue({
        manualLeadDripId: '',
        isManualLeadAutoStopDrip: false,
      });
    }
  }

  onDripAnsCall() {
    if (this.manageNumberForm?.value?.isDripAnsCall == false) {
      this.manageNumberForm.patchValue({
        ansDripId: '',
        isAnsAutoStopDrip: false,
      });
    }
  }

  onDripUnansCall() {
    if (this.manageNumberForm?.value?.isDripUnansCall == false) {
      this.manageNumberForm.patchValue({
        unansDripId: '',
        isUnansAutoStopDrip: false,
      });
    }
  }

  onDripZap() {
    if (this.manageNumberForm?.value?.isDripZap == false) {
      this.manageNumberForm.patchValue({
        zapDripId: '',
        isZapAutoStopDrip: false,
      });
    }
  }

  onDripWebForm() {
    if (this.manageNumberForm?.value?.isDripWebForm == false) {
      this.manageNumberForm.patchValue({
        webFormDripId: '',
        isWebFormAutoStopDrip: false,
      });
    }
  }

  checkForUnique(val, viewIndex) {
    let flag = true;
    if (viewIndex == 1) {
      for (let i = 0; i < this.tags1.length; i++) {
        if (this.tags1[i].label?.toLowerCase() == val.toLowerCase()) {
          flag = false;
          break;
        }
      }
    } else if (viewIndex == 2) {
      for (let i = 0; i < this.tags2.length; i++) {
        if (this.tags2[i].label?.toLowerCase() == val.toLowerCase()) {
          flag = false;
          break;
        }
      }
    } else if (viewIndex == 3) {
      for (let i = 0; i < this.tags3.length; i++) {
        if (this.tags3[i].label?.toLowerCase() == val.toLowerCase()) {
          flag = false;
          break;
        }
      }
    } else if (viewIndex == 4) {
      for (let i = 0; i < this.tags4.length; i++) {
        if (this.tags4[i].label?.toLowerCase() == val.toLowerCase()) {
          flag = false;
          break;
        }
      }
    } else if (viewIndex == 5) {
      for (let i = 0; i < this.tags5.length; i++) {
        if (this.tags5[i].label?.toLowerCase() == val.toLowerCase()) {
          flag = false;
          break;
        }
      }
    }

    return flag;
  }

  add(event: MatChipInputEvent, viewIndex): void {
    if (viewIndex == 1) {
      this.isShowPlaceHolder1 = false;
    } else if (viewIndex == 2) {
      this.isShowPlaceHolder2 = false;
    } else if (viewIndex == 3) {
      this.isShowPlaceHolder3 = false;
    } else if (viewIndex == 4) {
      this.isShowPlaceHolder4 = false;
    } else if (viewIndex == 5) {
      this.isShowPlaceHolder5 = false;
    }
    const input = event.input;
    const value = event.value;
    if (this.checkForUnique(value, viewIndex)) {
      let index = -1;
      for (let i = 0; i < this.allTagList.length; i++) {
        if (this.allTagList[i].label?.toLowerCase() == value.toLowerCase()) {
          index = i;
          break;
        }
      }
      if (viewIndex == 1) {
        if (index >= 0) {
          this.tags1.push(this.allTagList[index]);
        } else {
          if ((value || '').trim()) {
            this.tags1.push({ _id: 'custom', label: value.trim() });
          }
        }
      } else if (viewIndex == 2) {
        if (index >= 0) {
          this.tags2.push(this.allTagList[index]);
        } else {
          if ((value || '').trim()) {
            this.tags2.push({ _id: 'custom', label: value.trim() });
          }
        }
      } else if (viewIndex == 3) {
        if (index >= 0) {
          this.tags3.push(this.allTagList[index]);
        } else {
          if ((value || '').trim()) {
            this.tags3.push({ _id: 'custom', label: value.trim() });
          }
        }
      } else if (viewIndex == 4) {
        if (index >= 0) {
          this.tags4.push(this.allTagList[index]);
        } else {
          if ((value || '').trim()) {
            this.tags4.push({ _id: 'custom', label: value.trim() });
          }
        }
      } else if (viewIndex == 5) {
        if (index >= 0) {
          this.tags5.push(this.allTagList[index]);
        } else {
          if ((value || '').trim()) {
            this.tags5.push({ _id: 'custom', label: value.trim() });
          }
        }
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    if (viewIndex == 1) {
      this.tagCtrl1.setValue(null);
    } else if (viewIndex == 2) {
      this.tagCtrl2.setValue(null);
    } else if (viewIndex == 3) {
      this.tagCtrl3.setValue(null);
    } else if (viewIndex == 4) {
      this.tagCtrl4.setValue(null);
    } else if (viewIndex == 5) {
      this.tagCtrl5.setValue(null);
    }
  }

  remove(index, viewIndex) {
    if (viewIndex == 1) {
      if (index >= 0) {
        this.tags1.splice(index, 1);
      }
    } else if (viewIndex == 2) {
      if (index >= 0) {
        this.tags2.splice(index, 1);
      }
    } else if (viewIndex == 3) {
      if (index >= 0) {
        this.tags3.splice(index, 1);
      }
    } else if (viewIndex == 4) {
      if (index >= 0) {
        this.tags4.splice(index, 1);
      }
    } else if (viewIndex == 5) {
      if (index >= 0) {
        this.tags5.splice(index, 1);
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent, viewIndex): void {
    if (this.checkForUnique(event.option.value.label, viewIndex)) {
      if (viewIndex == 1) {
        this.tags1.push(event.option.value);
        this.tagInput1.nativeElement.value = '';
        this.tagCtrl1.setValue(null);
        this.tagInput1.nativeElement.blur();
      } else if (viewIndex == 2) {
        this.tags2.push(event.option.value);
        this.tagInput2.nativeElement.value = '';
        this.tagCtrl2.setValue(null);
        this.tagInput2.nativeElement.blur();
      } else if (viewIndex == 3) {
        this.tags3.push(event.option.value);
        this.tagInput3.nativeElement.value = '';
        this.tagCtrl3.setValue(null);
        this.tagInput3.nativeElement.blur();
      } else if (viewIndex == 4) {
        this.tags4.push(event.option.value);
        this.tagInput4.nativeElement.value = '';
        this.tagCtrl4.setValue(null);
        this.tagInput4.nativeElement.blur();
      } else if (viewIndex == 5) {
        this.tags5.push(event.option.value);
        this.tagInput5.nativeElement.value = '';
        this.tagCtrl5.setValue(null);
        this.tagInput5.nativeElement.blur();
      }
    }
  }

  isVisible(tag, viewIndex) {
    if (viewIndex == 1) {
      const tagIndex = this.tags1.findIndex((x) => x.label === tag.label);
      return tagIndex > -1 ? false : true;
    }
    if (viewIndex == 2) {
      const tagIndex = this.tags2.findIndex((x) => x.label === tag.label);
      return tagIndex > -1 ? false : true;
    }
    if (viewIndex == 3) {
      const tagIndex = this.tags3.findIndex((x) => x.label === tag.label);
      return tagIndex > -1 ? false : true;
    }
    if (viewIndex == 4) {
      const tagIndex = this.tags4.findIndex((x) => x.label === tag.label);
      return tagIndex > -1 ? false : true;
    }
    if (viewIndex == 5) {
      const tagIndex = this.tags5.findIndex((x) => x.label === tag.label);
      return tagIndex > -1 ? false : true;
    }
    return true;
  }

  private _filter(value) {
    let filterValue;
    if (value._id) {
      filterValue = value.label?.toLowerCase();
    } else {
      filterValue = value.toLowerCase();
    }

    return this.allTagList.filter(
      (tag) => tag.label.toLowerCase().indexOf(filterValue) === 0
    );
  }

  getAllTags() {
    this._loaderService.start();

    this._taggingService.getTags(this.data?.tagType).subscribe(
      (response) => {
        this._loaderService.stop();
        if (response && response.statusCode == 200) {
          let arr = response.data;
          const result = arr.reduce((acc, d) => {
            if (d.label) {
              const value = { _id: d._id, label: d.label };

              acc.push(value);
            }

            return acc;
          }, []);
          this.allTagList = result;
          if (this.data.details?.leadCreatedManualTagIds) {
            this.data.details?.leadCreatedManualTagIds?.map((x) => {
              this.addExistingTags(x, 1);
            });
          }
          if (this.data.details?.ansCallTagIds) {
            this.data.details?.ansCallTagIds?.map((x) => {
              this.addExistingTags(x, 2);
            });
          }
          if (this.data.details?.unansCallTagIds) {
            this.data.details?.unansCallTagIds?.map((x) => {
              this.addExistingTags(x, 3);
            });
          }
          if (this.data.details?.zapTagIds) {
            this.data.details?.zapTagIds?.map((x) => {
              this.addExistingTags(x, 4);
            });
          }
          if (this.data.details?.webFormTagIds) {
            this.data.details?.webFormTagIds?.map((x) => {
              this.addExistingTags(x, 5);
            });
          }
          this.filteredTags1 = this.tagCtrl1.valueChanges.pipe(
            startWith(null),
            map((tag) => (tag ? this._filter(tag) : this.allTagList.slice()))
          );
          this.filteredTags2 = this.tagCtrl2.valueChanges.pipe(
            startWith(null),
            map((tag) => (tag ? this._filter(tag) : this.allTagList.slice()))
          );
          this.filteredTags3 = this.tagCtrl3.valueChanges.pipe(
            startWith(null),
            map((tag) => (tag ? this._filter(tag) : this.allTagList.slice()))
          );
          this.filteredTags4 = this.tagCtrl4.valueChanges.pipe(
            startWith(null),
            map((tag) => (tag ? this._filter(tag) : this.allTagList.slice()))
          );
          this.filteredTags5 = this.tagCtrl5.valueChanges.pipe(
            startWith(null),
            map((tag) => (tag ? this._filter(tag) : this.allTagList.slice()))
          );
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
      }
    );
  }
  getUsedColorIndex() {
    this._loaderService.start();

    this._taggingService.getUsedTagsColor(this.data?.tagType).subscribe(
      (response) => {
        this._loaderService.stop();
        if (response && response.statusCode == 200) {
          this.unavailableColorIndex = response?.data;
          this.unavailableColorIndexResponse = [];
          let i, j;
          for (i = 0; i < this.colorCodes.length; i++) {
            this.unavailableColorIndexResponse.push({ _id: i, count: 0 });
            for (j = 0; j < this.unavailableColorIndex.length; j++) {
              if (this.unavailableColorIndex[j]._id == i) {
                this.unavailableColorIndexResponse[i].count =
                  this.unavailableColorIndex[j].count;
                break;
              }
            }
          }
          this.unavailableColorIndexResponse.sort(
            (a, b) => parseFloat(a.count) - parseFloat(b.count)
          );
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
      }
    );
  }
  filterData(data, mode) {
    let filteredData = [];
    if (mode == 1) {
      filteredData = data.filter(function (el) {
        return el._id != 'custom';
      });
    } else {
      filteredData = data.filter(function (el) {
        return el._id == 'custom';
      });
    }

    return filteredData;
  }
  getTagsToSave(tagsArr) {
    if (tagsArr.length) {
      let finalArr = [],
        newIndex;
      for (let i = 0; i < tagsArr.length; i++) {
        newIndex = -1;
        for (let j = 0; j < this.colorCodes.length; j++) {
          if (this.unavailableColorIndex.indexOf(j) < 0) {
            newIndex = j;
            this.unavailableColorIndex.push(newIndex);
            this.unavailableColorIndexResponse.push({
              _id: newIndex,
              count: 1,
            });
            break;
          }
        }
        if (newIndex < 0) {
          let minCount = 0,
            unavailableIndex = -1;
          for (let k = 0; k < this.unavailableColorIndexResponse.length; k++) {
            if (
              minCount == 0 ||
              minCount > parseInt(this.unavailableColorIndexResponse[k].count)
            ) {
              newIndex = this.unavailableColorIndexResponse[k]._id;
              unavailableIndex = k;
              minCount = parseInt(this.unavailableColorIndexResponse[k].count);
            }
          }
          if (unavailableIndex > -1)
            this.unavailableColorIndexResponse[unavailableIndex].count =
              this.unavailableColorIndexResponse[unavailableIndex].count + 1;
        }
        if (!newIndex) {
          newIndex = 0;
        }
        finalArr.push({ tag: tagsArr[i], colorIndex: newIndex });
      }
      return finalArr;
    } else {
      return [];
    }
  }
  async tagSubmitForm(viewIndex) {
    return new Promise(async (resolve, reject) => {
      let existingTags = [];
      let newTags = [];

      if (viewIndex == 1) {
        if (this.tags1.length == 0) {
          resolve([]);
          return;
        }
        existingTags = this.filterData(this.tags1, '1');
        newTags = this.filterData(this.tags1, '0');
      } else if (viewIndex == 2) {
        if (this.tags2.length == 0) {
          resolve([]);
          return;
        }
        existingTags = this.filterData(this.tags2, '1');
        newTags = this.filterData(this.tags2, '0');
      } else if (viewIndex == 3) {
        if (this.tags3.length == 0) {
          resolve([]);
          return;
        }
        existingTags = this.filterData(this.tags3, '1');
        newTags = this.filterData(this.tags3, '0');
      } else if (viewIndex == 4) {
        if (this.tags4.length == 0) {
          resolve([]);
          return;
        }
        existingTags = this.filterData(this.tags4, '1');
        newTags = this.filterData(this.tags4, '0');
      } else if (viewIndex == 5) {
        if (this.tags5.length == 0) {
          resolve([]);
          return;
        }
        existingTags = this.filterData(this.tags5, '1');
        newTags = this.filterData(this.tags5, '0');
      }

      const existingIds = existingTags.reduce((acc, d) => {
        acc.push(d._id);
        return acc;
      }, []);
      const tagsArr = newTags.reduce((acc, d) => {
        acc.push(d.label);
        return acc;
      }, []);

      let tagsToSave = this.getTagsToSave(tagsArr);

      if (!tagsArr.length) {
        resolve(existingIds);
        return;
      }
      let obj = {
        tagsArr: tagsToSave,
        tagType: '1',
      };
      this._loaderService.start();
      this._taggingService.addTags(obj).subscribe(
        (response) => {
          this._loaderService.stop();
          let finalIds = existingIds;
          if (response) {
            for (let i = 0; i < response?.data?.length; i++) {
              if (response?.data[i] != null) finalIds.push(response?.data[i]?._id);
            }
          }
          resolve(finalIds);
          return;
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          resolve([]);
          return;
        }
      );
    });
  }

  addExistingTags(tagId, viewIndex) {
    let filterArray = this.allTagList.filter((x) => x._id === tagId);
    if (filterArray.length > 0) {
      if (viewIndex == 1) {
        this.tags1.push(filterArray[0]);
      } else if (viewIndex == 2) {
        this.tags2.push(filterArray[0]);
      } else if (viewIndex == 3) {
        this.tags3.push(filterArray[0]);
      } else if (viewIndex == 4) {
        this.tags4.push(filterArray[0]);
      } else if (viewIndex == 5) {
        this.tags5.push(filterArray[0]);
      }
    }
  }

  getUserPlan() {
    this._loaderService.start();
    let obj = {};
    this._creditService.getUserPlan(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          this.isAllowManageNumberFeature =
            response?.data?.isAllowManageNumberFeature;
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  onSearchChange(text, index) {
    let isShow = false;

    if (text) {
      let filterArray = this.allTagList.filter((x) => {
        return x?.label === text;
      });
      if (filterArray?.length == 0) {
        isShow = true;
      } else {
        isShow = false;
      }
    } else {
      isShow = false;
    }

    if (index == 1) {
      this.isShowPlaceHolder1 = isShow;
    } else if (index == 2) {
      this.isShowPlaceHolder2 = isShow;
    } else if (index == 3) {
      this.isShowPlaceHolder3 = isShow;
    } else if (index == 4) {
      this.isShowPlaceHolder4 = isShow;
    } else if (index == 5) {
      this.isShowPlaceHolder5 = isShow;
    }
  }
}
