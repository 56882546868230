export class timezoneConstant {
  public static timezoneArray = [
    {
      name: 'Eastern Standard Time',
      timezonename: 'America/New_York',
      index: 0,
      abbreviation: 'EST',
    },
    {
      name: 'Central Standard Time',
      timezonename: 'America/Chicago',
      index: 1,
      abbreviation: 'CST',
    },
    {
      name: 'Mountain Standard Time',
      timezonename: 'America/Denver',
      index: 2,
      abbreviation: 'MST',
    },
    {
      name: 'Mountain Standard Time (Phoenix)',
      timezonename: 'America/Phoenix',
      index: 3,
      abbreviation: 'MST',
    },
    {
      name: 'Pacific Standard Time',
      timezonename: 'America/Los_Angeles',
      index: 4,
      abbreviation: 'PST',
    },
    {
      name: 'Alaska Standard Time',
      timezonename: 'America/Anchorage',
      index: 5,
      abbreviation: 'AST',
    },
    {
      name: 'Hawaii-Aleutian Standard Time',
      timezonename: 'Pacific/Honolulu',
      index: 6,
      abbreviation: 'HAST',
    },
  ];
}
