import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrl } from '../../utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

@Injectable({
  providedIn: 'root',
})
export class ContractorService {
  public baseUrl = environment.baseUrl;
  public token = localStorage.getItem('token');

  constructor(private _commonFunction: CommonFunctionsService) {}

  getContractors = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contractorUrl + 'getcontractor';

    return this._commonFunction.globalPostService(endpoint, data);
  };

  getContractorsWithId = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.contractorUrl + 'getcontractorwithIds';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  addContractor = (fieldData: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contractorUrl + 'add';
    return this._commonFunction.globalPostService(endpoint, fieldData);
  };

  editContractor = (data, contactorId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.contractorUrl + 'update';

    if (contactorId) {
      endpoint += `?id=${contactorId}`;
    }

    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteContractor = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.contractorUrl + 'delete';

    return this._commonFunction.globalPostService(endpoint, data);
  };
}
