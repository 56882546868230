import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TaggingService } from '../../../providers/tagging/tagging.service';

// UTILS
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';

@Component({
  selector: 'app-add-global-tags',
  templateUrl: './add-global-tags.component.html',
  styleUrls: ['./add-global-tags.component.scss'],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class AddGlobalTagsComponent implements OnInit {
  allTagsToAdd: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<AddGlobalTagsComponent>,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private _taggingService: TaggingService
  ) {
    console.log('items---', this.data);
  }

  ngOnInit(): void {}

  removeDuplicate(data) {
    let capitalData = data.map(function (x) {
      return x.toUpperCase();
    });
    data = data.filter(
      (value, index) => capitalData.indexOf(value.toUpperCase()) === index
    );

    return data;
  }

  checkForExistingTag(nonDuplicateTags) {
    let capitalData = nonDuplicateTags.map(function (x) {
      return x.toUpperCase();
    });
    let existingTags = [];
    for (let i = 0; i < this.data.items.length; i++) {
      if (capitalData.indexOf(this.data.items[i].label?.toUpperCase()) >= 0) {
        existingTags.push(this.data.items[i].label);
      }
    }
    return existingTags;
  }

  submitForm() {
    if (!this.allTagsToAdd.length) {
      this._toastrService.error("Tags can't be blank");
      return false;
    }
    const tagsArr = this.removeDuplicate(this.allTagsToAdd);

    let existingTags = this.checkForExistingTag(tagsArr);
    if (existingTags.length) {
      this._toastrService.error(
        'Following tags already exists: ' + existingTags.join(', ')
      );
      return false;
    }
    this._loaderService.start();

    const obj = {
      tagsArr,
      tagType: this.data?.tagType
    };
    this._taggingService.addTags(obj).subscribe(
      (response) => {
        this._loaderService.stop();
        if (response) {
          this.dialog.close();
          this._toastrService.success(MessageConstant.tagsAddedSuccess);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        this._toastrService.error(
          err.message ? err.message : MessageConstant.unknownError
        );
      }
    );
  }
}
