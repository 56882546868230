// MODULES
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// UTILS
import { MessageConstant } from 'src/app/utils/message-constant';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DialerService } from 'src/app/providers/dialer/dialer.service';
import { SharedService } from '../../../shared.service';
//Component
import { ActivityLogsComponent } from 'src/app/shared/activity-logs/activity-logs.component';

@Component({
  selector: 'app-take-note-dialer',
  templateUrl: './take-note-dialer.component.html',
  styleUrls: ['./take-note-dialer.component.scss'],
})
export class TakeNoteDialerComponent implements OnInit {
  @ViewChild('activityLog') activityLog: ActivityLogsComponent;
  currentActivityPage: number = 1;
  activityCurrentLimit: number = 20;
  messageConstant = MessageConstant;
  comment: string = '';
  takeNoteType: string = 'Dialer';
  activityType: string = 'notes';
  moduleName: string = 'take-note-dialer';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<TakeNoteDialerComponent>,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private _dialerService: DialerService,
    public _sharedService: SharedService,
  ) {}

  ngOnInit(): void {}

  addLogs() {
    let obj = {
      subModuleId: this.data?.subModuleId,
      comment: this.comment,
    };
    if (this.data?.propertyDetails?.Formated_Address) {
      obj['address'] = this.data?.propertyDetails?.Formated_Address;
    }
    if (this.data?.propertyDetails?.Full_Name) {
      obj['title'] = this.data?.propertyDetails?.Full_Name;
    }
    if (this.data?.phoneNumber) {
      obj['phoneNumber'] = '+1' + this.data?.phoneNumber;
    }
    this._loaderService.start();
    this._dialerService.addNotes(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          let serviceForLeadAndLSFunction;
          this._toastrService.success(
            this.messageConstant.noteAddedSuccess,
            ''
          );
          if (
            this.data?.propertyDetails?.lead_property_id &&
            this.data?.propertyDetails?.lead_property_id != '' &&
            typeof this.data?.propertyDetails?.lead_property_id != undefined &&
            this.data?.propertyDetails?.lead_property_id != 'undefined'
          ) {
            obj['subModuleId'] = this.data?.propertyDetails?.lead_property_id;
            obj['moduleId'] = '5faa63fdfd20cd581703d255'; // Lead moduleId
            obj['isTagged'] = false;
            obj['tagUsers'] = [];
            delete obj['createdBy'];
            delete obj['subactivity'];
            serviceForLeadAndLSFunction = this._sharedService.addActivityLog(
              obj,
              false
            );
            serviceForLeadAndLSFunction.subscribe(
              (response: ResponseModel) => {
                if (response?.statusCode === 200 || response?.code === 200) {
                } else {
                  this._toastrService.error(
                    this.messageConstant.unknownError,
                    'Error'
                  );
                }
              },
              (err: ErrorModel) => {}
            );
          }
          this.dialog.close(true);
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
}
