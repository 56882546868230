// MODULES
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UploadOutput, UploadInput } from 'ngx-uploader';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common';
// UTILS
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';
import { environment } from 'src/environments/environment';
import { MessageConstant } from 'src/app/utils/message-constant';
import { BaseUrl } from 'src/app/utils/base-url-constants';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AccountingService } from 'src/app/providers/accounting/accounting.service';
import { SharedService } from 'src/app/shared/shared.service';

// COMPONENTS
import { ModalAddVendorsComponent } from 'src/app/modules/accounting/vendors/modal-add-vendors/modal-add-vendors.component';
import { ModalAddAccountTagsComponent } from 'src/app/modules/accounting/account-tags/modal-add-account-tags/modal-add-account-tags.component';
import { MediaPreviewComponent } from 'src/app/shared/dialog/media-preview/media-preview.component';

// DECLARATION
interface breakDown {
  amount: Number;
  propertyAddress: String;
  accountTag: string;
}

@Component({
  selector: 'app-edit-transaction-view',
  templateUrl: './edit-transaction-view.component.html',
  styleUrls: ['./edit-transaction-view.component.scss'],
})
export class EditTransactionViewComponent implements OnInit {
  messageConstant = MessageConstant;

  @Input() transactionItem: any;
  @Output() _transactionUpdated = new EventEmitter<any>();
  @Output() _changeTransaction = new EventEmitter<any>();

  transactionId: string = '';
  accountId: string = '';

  isEdit: boolean = false;
  accountData: any;
  vendorData: any[] = [];
  propertyData: any[] = [];
  accountTagData: any[] = [];
  campaignData: any[] = [];
  submitted: boolean = false;
  transactionDetailForm: FormGroup;
  breakdown: any = [];
  uploadInput: EventEmitter<UploadInput>;
  uploadFiles: any[] = [];
  attachmentArray: any = [];
  dialogRef: any;
  transactionData: any;
  breakDownTotal: number = 0;
  moduleId: string = '6010174147faac815403a105';
  tempTransactionDate: any;
  isShowMore: boolean = true;
  paymentMethodList: any[] = [
    { label: 'ATM', value: 'ATM' },
    { label: 'Cash', value: 'cash' },
    { label: 'Check', value: 'check' },
    { label: 'Charge', value: 'charge' },
    { label: 'Online', value: 'online' },
    { label: 'Transfer', value: 'transfer' },
    { label: 'Withdrawal', value: 'withdrawal' },
    { label: 'Wire', value: 'wire' },
    { label: 'Other', value: 'other' },
  ];
  isMarketReadonly: boolean = false;
  constructor(
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _accountingService: AccountingService,
    private formbuilder: FormBuilder,
    private dialog: MatDialog,
    public _sharedService: SharedService,
    public _utilities: CommonFunctionsService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.transactionId = this.transactionItem?._id;
    this.accountId = this.transactionItem?.accountId;

    this.formbuilder = new FormBuilder();
    this.submitted = false;
    this.breakdown = [];
    this.uploadFiles = [];
    this.attachmentArray = [];
    this.transactionData = null;
    this.breakDownTotal = 0;
    this.tempTransactionDate = '';

    this.uploadInput = new EventEmitter<UploadInput>();
    this.transactionDetailForm = this.formbuilder.group({
      vendorId: ['', Validators.required],
      creditDebitType: ['', Validators.required],
      method: ['', Validators.required],
      checkNum: [''],
      fullDescription: [''],
      amount: ['', Validators.required],
      transactionDate: [new Date(), Validators.required],
      breakDown: this.formbuilder.array([]),
    });
    this.breakdown = this.transactionDetailForm.controls['breakDown'];
    this.isEdit = true;

    this.getAccountDetails(this.accountId);
    this.getTransactionDetails(this.transactionId);

    this.propertyData.push({ address: 'General Business', _id: 'general' });
    this.propertyData.push({ address: 'Personal Expense', _id: 'personal' });

    this.getVendorList();
    this.getPropertyAddress();
    this.getAccountTag();

    this.transactionDetailForm
      .get('vendorId')
      .valueChanges.subscribe((value) => {
        this.changeTransaction();
      });
    this.transactionDetailForm
      .get('creditDebitType')
      .valueChanges.subscribe((value) => {
        this.changeTransaction();
      });
    this.transactionDetailForm
      .get('fullDescription')
      .valueChanges.subscribe((value) => {
        this.changeTransaction();
      });
    this.transactionDetailForm.get('amount').valueChanges.subscribe((value) => {
      this.changeTransaction();
    });
    this.transactionDetailForm
      .get('transactionDate')
      .valueChanges.subscribe((value) => {
        this.changeTransaction();
      });
    this.transactionDetailForm
      .get('breakDown')
      .valueChanges.subscribe((value) => {
        this.changeTransaction();
      });
  }

  ngAfterViewInit() {
    this.isMarketReadonly = true;
  }

  addBreakdown() {
    this.breakdown = this.transactionDetailForm.controls['breakDown'];
    let totalBrkAmount = 0;
    let allRowAmount = 0;
    for (let i = 0; i < this.breakdown.length; i++) {
      allRowAmount =
        allRowAmount +
        parseInt(this.breakdown.controls[i].controls.amount.value);
    }
    let diffAmount = totalBrkAmount - allRowAmount;
    diffAmount = diffAmount >= 0 ? diffAmount : 0;

    let amountGroup = this.formbuilder.group({
      amount: [''],
      accountTag: [''],
      campaignId: [''],
      propertyAddress: [''],
      userDescription: [''],
    });
    this.breakdown.push(amountGroup);
    this.changeTransaction();
  }

  deleteBreakdown(index: number): void {
    this.breakdown = this.transactionDetailForm.controls['breakDown'];
    if (this.breakdown.length > 1) {
      this.breakdown.removeAt(index);
    }
    this.updateBreakDownTotal();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.transactionDetailForm.controls[controlName].hasError(errorName);
  };

  onBlurParentAndChildMethod(bool, amt?) {
    setTimeout(() => {
      if (bool) {
        let totalBrkAmount = 0;
        if (amt.value.length == 0) {
          totalBrkAmount = 0;
        } else {
          if (isNaN(amt.value)) {
            totalBrkAmount = 0;
          } else {
            totalBrkAmount = parseFloat(amt.value);
          }
        }
        this.breakdown = this.transactionDetailForm.controls['breakDown'];
        if (this.breakdown) {
          if (this.breakdown.length == 1) {
            if (this.breakdown.controls[0]) {
              this.breakdown.controls[0].patchValue({
                amount: totalBrkAmount,
              });
            }
          }
        }
      }
      this.updateBreakDownTotal();
    }, 200);
  }

  updateBreakDownTotal() {
    this.breakDownTotal = 0;
    this.transactionDetailForm.value.breakDown.forEach((element) => {
      if (element.amount) {
        this.breakDownTotal += Number(element.amount);
      }
    });
  }

  saveTransaction() {
    this.submitted = true;
    if (this.transactionDetailForm.invalid) {
      return;
    }

    let formData = JSON.parse(JSON.stringify(this.transactionDetailForm.value));

    if (!formData.vendorId) {
      this._toastrService.error('Please select vendor', '');
      return;
    }

    if (formData.method == 'check') {
      if (!formData.checkNum || formData.checkNum == '') {
        this._toastrService.error('Ref/Check number required', '');
        return;
      }
    }

    let proceed = true;
    let sumValidator = 0;
    let errorText = '';

    for (let i = 0; i < formData.breakDown.length; i++) {
      if (
        formData.breakDown[i].propertyAddress == '' ||
        formData.breakDown[i].propertyAddress == null
      ) {
        errorText = 'Please select property address.';
        proceed = false;
        break;
      }
      if (
        formData.breakDown[i].accountTag == '' ||
        formData.breakDown[i].accountTag == null
      ) {
        errorText = 'Please select account.';
        proceed = false;
        break;
      }
      if (
        formData.breakDown[i].amount == '' ||
        formData.breakDown[i].amount == null
      ) {
        errorText = 'Please enter amount';
        proceed = false;
        break;
      }
      sumValidator += Number(formData.breakDown[i].amount);
    }

    if (
      Number(sumValidator).toFixed(2) != Math.abs(formData.amount).toFixed(2)
    ) {
      errorText = 'Oops! Split amounts do not match the transaction total.';
      proceed = false;
    }

    if (!proceed) {
      this._toastrService.error(errorText, '');
      return;
    }

    formData['accountId'] = this.accountData._id;
    formData['transactionFiles'] = this.attachmentArray || [];
    formData?.breakDown?.forEach((element) => {
      let numberId = null;
      let crmQuestionId = '';
      if (element.propertyAddress) {
        numberId = element.propertyAddress.numberId || null;
        crmQuestionId = element.propertyAddress.crmQuestionId || null;

        const address = element.propertyAddress?._id || element.propertyAddress;
        if (address == 'general') {
          element.propertyAddress = null;
          element.propertyType = 'general';
        } else if (address == 'personal') {
          element.propertyAddress = null;
          element.propertyType = 'personal';
        } else {
          element.propertyAddress = address;
          element.revenueCategory = crmQuestionId;
        }
      }

      if (element?.accountTag?._id) {
        element.childCategory = element.accountTag.childCategoryId;
        element.accountTag = element.accountTag._id;
      }

      if (element?.campaignId?._id) {
        element.campaignId = element.campaignId._id;
      }

      if (element.campaignId == '') {
        element.campaignId = null;
      }

      if (!element.campaignId) {
        element.campaignId = numberId;
      }
    });

    if (this.isEdit) {
      formData['transactionId'] = this.transactionId;
    }

    if (formData?.vendorId?._id) formData.vendorId = formData.vendorId._id;
    const offsetOfTimezone = new Date().getTimezoneOffset();
    const tempDate = new Date(formData.transactionDate);
    formData.transactionDate = new Date(tempDate.getTime() - offsetOfTimezone * 60000);
    this._loaderService.start();

    this._accountingService.addEditTransaction(formData, this.isEdit).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          this._loaderService.stop();
          this._toastrService.success(
            this.isEdit
              ? this.messageConstant.accountUpdatedSuccess
              : this.messageConstant.accountAddedSuccess
          );
          this._transactionUpdated.emit(true);
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  onCancel() {
    this._transactionUpdated.emit();
  }

  onAddVendorsDialog() {
    this.dialogRef = this.dialog.open(ModalAddVendorsComponent, {
      width: '500px',
      data: {
        action: 'add',
      },
    });
    this.dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.getVendorList();

        let filterArray = this.vendorData.filter((x) => x._id == result?._id);
        let vendorDic = {};
        if (filterArray.length > 0) {
          vendorDic = filterArray[0];
        }

        this.transactionDetailForm.patchValue({
          vendorId: vendorDic,
        });
      }
    });
  }

  onAddAccountTagDialog() {
    this.dialogRef = this.dialog.open(ModalAddAccountTagsComponent, {
      width: '500px',
      data: {
        action: 'add',
      },
    });
    this.dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.getAccountTag();

        this.breakdown = this.transactionDetailForm.controls['breakDown'];
        if (this.breakdown) {
          if (this.breakdown.length == 1) {
            if (this.breakdown.controls[0]) {
              let accountTagDic = {};
              let filterArray = this.accountTagData.filter(
                (x) => x._id == result?._id
              );
              if (filterArray.length > 0) {
                accountTagDic = filterArray[0];
              }

              this.breakdown.controls[0].patchValue({
                accountTag: accountTagDic,
              });

              if (result?.childCategoryId) {
                this.getCampaignById(result?.childCategoryId);
              }
            }
          }
        }
      }
    });
  }

  onUploadLogo(output: UploadOutput): void {
    this._loaderService.start();

    if (output.type === 'allAddedToQueue') {
      const token = localStorage.getItem('token');

      let endpoint = environment.baseUrl + BaseUrl.sharedUrl + 'file-upload';
      endpoint += `?moduleId=${this.moduleId}&isPublic=0`;

      const event: UploadInput = {
        type: 'uploadAll',
        url: endpoint,
        method: 'POST',
        headers: { Authorization: token },
      };
      this.uploadInput.emit(event);
    } else if (
      output.type === 'addedToQueue' &&
      typeof output.file !== 'undefined'
    ) {
      this.uploadFiles.push(output);
    } else if (output.type === 'done' && typeof output.file !== 'undefined') {
      this._loaderService.stop();
      if (output.file.response.statusCode == 200) {
        if (output.file.response.data) {
          for (let i = 0; i < output.file.response.data.length; i++) {
            const val = output.file.response.data[i];
            if (val.src) {
              this.attachmentArray.push(val.src);
            }
          }
        }
      }
    }
  }

  removeAttachmentArray(index) {
    if (this.attachmentArray.length > 0) {
      this.attachmentArray.splice(index, 1);
      this.attachmentArray.removeAt(index);
    }
  }

  getIcon(fileName) {
    let re = /(?:\.([^.]+))?$/;
    let fileType = re.exec(fileName)[1];

    if (!fileType) {
      return 'other';
    }
    fileType = fileType.toLocaleLowerCase();
    if (
      fileType === 'doc' ||
      fileType === 'docx' ||
      fileType === 'xls' ||
      fileType === 'xlsx'
    ) {
      return 'doc';
    }

    if (fileType === 'pdf') {
      return 'pdf';
    }

    if (fileType === 'mp3') {
      return 'mp3';
    }

    if (fileType === 'gif') {
      return 'gif';
    }

    if (fileType === 'png') {
      return 'png';
    }

    if (fileType === 'jpeg' || fileType === 'jpg') {
      return 'jpeg';
    }

    if (fileType === 'xls') {
      return 'xls';
    }

    if (fileType === 'mp4') {
      return 'mp4';
    }

    if (fileType === 'mp3') {
      return 'mp3';
    }
    if (fileType === 'ico') {
      return 'ico';
    }
    if (fileType === 'svg') {
      return 'svg';
    }
    if (fileType === 'zip') {
      return 'zip';
    }
    if (fileType === 'txt') {
      return 'txt';
    }
    if (fileType === 'rar') {
      return 'rar';
    }
    if (fileType === 'json') {
      return 'json';
    }
    if (fileType === 'html') {
      return 'html';
    }
    if (fileType === 'dwg') {
      return 'dwg';
    }
    if (fileType === 'ai') {
      return 'ai';
    }
    if (fileType === 'csv') {
      return 'csv';
    }
    if (fileType === 'webp') {
      return 'webp';
    }

    return fileType;
  }

  getAccountDetails(accountId) {
    this._loaderService.start();
    this._accountingService.getAccountDetails({ accountId }).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.accountData = response?.data;
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getTransactionDetails(transactionId) {
    this._loaderService.start();
    this._accountingService.getTransactionDetails({ transactionId }).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.transactionData = response?.data;
          setTimeout(() => {
            this.tempTransactionDate = new Date(
              this.datePipe.transform(
                    new Date(this.transactionData?.transactionDate),
                'MMM dd, yyyy',
                'GMT'
              )
            );
          }, 100);

          let filterArray = this.vendorData.filter(
            (x) => x._id == this.transactionData?.vendorId
          );
          let vendorDic = {};
          if (filterArray.length > 0) {
            vendorDic = filterArray[0];
          }

          if (!this.transactionData.creditDebitType) {
            if (Number(this.transactionData.amount) >= 0) {
              this.transactionData.creditDebitType = 'credit';
            } else {
              this.transactionData.creditDebitType = 'debit';
            }
          }

          this.transactionDetailForm.patchValue({
            vendorId: vendorDic,
            creditDebitType: this.transactionData?.creditDebitType,
            method: this.transactionData?.method,
            checkNum: this.transactionData?.checkNum,
            fullDescription: this.transactionData?.fullDescription,
            amount: this.transactionData?.amount,
            transactionDate: new Date(this.datePipe.transform(
              new Date(this.transactionData?.transactionDate),
              'MMM dd, yyyy',
              'GMT'
            )),
            breakdown: [],
          });
          this.attachmentArray = this.transactionData?.transactionFiles;
          this.breakdown = this.transactionDetailForm.controls['breakDown'];
          if (this.transactionData) {
            if (
              this.transactionData.breakDown &&
              this.transactionData.breakDown.length > 0
            ) {
              for (let i = 0; i < this.transactionData.breakDown.length; i++) {
                var item = this.transactionData.breakDown[i];

                var newAmount = item.amount ? Number(item.amount) : 0;
                if (this.transactionData.breakDown.length == 1) {
                  newAmount = this.transactionData.amount;
                }

                if (this.transactionData?.creditDebitType == 'credit') {
                  if (newAmount > 0) {
                    newAmount = Math.abs(newAmount);
                  } else {
                    newAmount = -Math.abs(newAmount);
                  }
                } else {
                  if (newAmount > 0) {
                    newAmount = -Math.abs(newAmount);
                  } else {
                    newAmount = Math.abs(newAmount);
                  }
                }

                this.transactionData.accountTagData.filter((field) => {
                  if (item.accountTag == field._id) {
                    item.accountTag = field;
                  }
                });
                if (item.accountTag) {
                  this.getCampaignById(item.accountTag.childCategoryId);
                }

                this.transactionData.campaignData.filter((field) => {
                  if (item.campaignId == field._id) {
                    item.campaignId = field;
                  }
                });

                if (item.propertyType == 'general') {
                  item.propertyAddress = {
                    _id: 'general',
                    address: 'General Business',
                  };
                } else if (item.propertyType == 'personal') {
                  item.propertyAddress = {
                    _id: 'personal',
                    address: 'Personal Expense',
                  };
                } else {
                  this.transactionData.propertyData.filter((field) => {
                    const {
                      _id,
                      address,
                      leadId,
                      leadIds,
                      crmQuestionId,
                      numberId,
                      unitNo,
                    } = field;

                    let crmId = '';
                    let numId = '';
                    let index11 = leadIds.indexOf(leadId);
                    if (index11 >= 0) {
                      crmId = crmQuestionId[index11]
                        ? crmQuestionId[index11]
                        : '';
                      numId = numberId[index11] ? numberId[index11] : '';
                    }
                    if (item.propertyAddress == _id) {
                      item.propertyAddress = {
                        _id: _id,
                        address: this._utilities.formatAddress(address, unitNo),
                        crmQuestionId: crmId,
                        numberId: numId,
                      };
                    }
                  });
                }
                let amountGroup = this.formbuilder.group({
                  amount: newAmount,
                  accountTag: item.accountTag,
                  campaignId: item.campaignId,
                  propertyAddress: item.propertyAddress,
                  userDescription: item.userDescription,
                });
                this.breakdown.push(amountGroup);
              }
            } else {
              var newAmount = this.transactionData.amount
                ? Number(this.transactionData.amount)
                : 0;

              if (this.transactionData?.creditDebitType == 'credit') {
                if (newAmount > 0) {
                  newAmount = Math.abs(newAmount);
                } else {
                  newAmount = -Math.abs(newAmount);
                }
              } else {
                if (newAmount > 0) {
                  newAmount = -Math.abs(newAmount);
                } else {
                  newAmount = Math.abs(newAmount);
                }
              }

              let amountGroup = this.formbuilder.group({
                amount: newAmount,
                accountTag: null,
                campaignId: null,
                propertyAddress: null,
                userDescription: null,
              });
              this.breakdown.push(amountGroup);
            }
          }

          this.updateBreakDownTotal();
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  async getVendorList() {
    return new Promise(async (resolve, reject) => {
      this._loaderService.start();

      this._accountingService.getAllVendors({}).subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          if (response?.statusCode && response?.statusCode == 200) {
            this.vendorData = response?.data;

            let filterArray = this.vendorData.filter(
              (x) => x._id == this.transactionData?.vendorId
            );
            let vendorDic = {};
            if (filterArray.length > 0) {
              vendorDic = filterArray[0];
            }

            this.transactionDetailForm.patchValue({
              vendorId: vendorDic,
            });
          } else {
            this._toastrService.error(response?.message, '');
          }
          resolve(true);
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
          resolve(false);
        }
      );
    });
  }

  getPropertyAddress() {
    this._loaderService.start();

    this._accountingService.getAllProperty({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.propertyData = [...this.propertyData, ...response?.data];
          for (let i = 0; i < this.propertyData?.length; i++) {
            let item = this.propertyData[i];
            if (item?.address) {
              item.address = this._utilities.formatAddress(
                item?.address,
                item?.unitNo
              );
              delete item['unitNo'];
            }
          }
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  async getAccountTag() {
    return new Promise(async (resolve, reject) => {
      this._loaderService.start();

      this._accountingService.getAllAccountTag({}).subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          if (response?.statusCode && response?.statusCode == 200) {
            this.accountTagData = response?.data;
          } else {
            this._toastrService.error(response?.message, '');
          }
          resolve(true);
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
          resolve(false);
        }
      );
    });
  }

  onChangeAccountTag(event) {
    if (event.value) {
      this.getCampaignById(event.value.childCategoryId);
    }
  }

  getCampaignArray(index) {
    if (this.breakdown.controls[index].controls.accountTag.value) {
      var crmQuestionId = this.breakdown.controls[index].controls.accountTag
        .value.childCategoryId;

      if (this.campaignData[crmQuestionId]) {
        return this.campaignData[crmQuestionId];
      }
    }

    return [];
  }

  getCampaignById(crmQuestionId) {
    if (!crmQuestionId || crmQuestionId == '') {
      return;
    }
    this._loaderService.start();
    this._accountingService.getCampaignById({ crmQuestionId }).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          if (response?.data) {
            this.campaignData[crmQuestionId] = response?.data;
          }
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  preview(index) {
    var fileDataArray = [];
    for (let i = 0; i < this.attachmentArray.length; i++) {
      var src = this.attachmentArray[i];

      var fileObject = {
        moduleId: this.moduleId,
        photo: src,
        fileType: this.getIcon(src),
        title: src,
      };
      fileDataArray.push(fileObject);
    }

    var file = fileDataArray[index];

    if (
      file?.fileType === 'pdf' ||
      file?.fileType === 'png' ||
      file?.fileType === 'jpeg' ||
      file?.fileType === 'jpg' ||
      file?.fileType === 'svg' ||
      file?.fileType === 'gif' ||
      file?.fileType === 'webp' ||
      file?.fileType === 'ico' ||
      file?.fileType === 'mp4' ||
      file?.fileType === 'mp3'
    ) {
      this.dialogRef = this.dialog.open(MediaPreviewComponent, {
        width: '50vw',
        data: { file, items: fileDataArray, index },
      });
    } else {
      this.downloadFile(file);
    }
  }

  downloadFile(file) {
    const obj = {
      moduleId: this.moduleId,
      src: file?.photo,
    };
    this._loaderService.start();
    this._sharedService.getFile(obj).subscribe(
      (response) => {
        if (response) {
          var blob = new Blob([response], {
            type: `image/${file?.fileType}`,
          });
          this._toastrService.success(this.messageConstant.fileDownloadSuccess);
          saveAs(blob, file?.title);
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(
            error.message ? error.message : this.messageConstant.unknownError,
            ''
          );
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  clearTransaction() {
    this.breakdown = this.transactionDetailForm.controls['breakDown'];

    if (this.breakdown) {
      for (let i = 0; i < this.breakdown.length; i++) {
        if (this.breakdown.controls[i]) {
          this.breakdown.controls[i].patchValue({
            amount: 0,
            accountTag: '',
            campaignId: '',
            propertyAddress: '',
            userDescription: '',
          });
        }
      }
      this.updateBreakDownTotal();
    }
  }

  unmappedTransaction() {
    let param = {
      transactionId: this.transactionData._id,
    };

    this._loaderService.start();
    this._accountingService.unmappedTransaction(param).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          this._loaderService.stop();
          this._toastrService.success(
            this.messageConstant.accountUpdatedSuccess
          );
          this._transactionUpdated.emit(true);
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  onShowMore() {
    this.isShowMore = !this.isShowMore;
  }

  addMinutesToDate(date: Date, minutes: number): Date {
    const newDate = new Date(date.getTime() + minutes * 60000);
    return newDate;
  }

  changeTransaction() {
    setTimeout(() => {
      let obj = {
        ...this.transactionDetailForm?.value,
      }
      const offsetOfTimezone = new Date().getTimezoneOffset();
      obj.transactionDate = this.addMinutesToDate(obj.transactionDate, -offsetOfTimezone);
      this._changeTransaction.emit({
        id: this.transactionId,
        values: obj,
      });
    }, 200);
  }
}
