import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';

// MODEL AND CONSTANT
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';
import { TwilioErrorMessageConstant } from '../../utils/twilioErrorMessage-constant';
import { ResponseModel } from 'src/app/utils/models/response';
import { StatusConstant } from 'src/app/utils/status-constant';
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';

// SERVICES
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { CallFlowService } from '../../providers/call-flow/call-flow.service';
import { UpgradeAccountComponent } from '../dialog/upgrade-account/upgrade-account.component';
import { SharedService } from '../shared.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { TaskService } from 'src/app/providers/task/task.service';
import { MasterTaskService } from 'src/app/providers/master-task/master-task.service';
import { UserService } from 'src/app/providers/user/user.service';
import { LeadsService } from 'src/app/providers/leads/leads.service';
import { BuyersService } from 'src/app/providers/buyers/buyers.service';
import { CreditService } from 'src/app/providers/credit/credit.service';
import { InventoryService } from 'src/app/providers/inventory/inventory.service';
import { EsignService } from 'src/app/providers/esign/esign.service';
import { DialerService } from 'src/app/providers/dialer/dialer.service';
import { TwilioService } from '../../utils/twilio-service/twilio.service';
import { VendorsService } from 'src/app/providers/vendors/vendors.service';

// COMPONENTS
import { DeleteDialogComponent } from '../dialog/delete-dialog/delete-dialog.component';
import { TakeNoteComponent } from 'src/app/shared/dialog/take-note/take-note.component';
import { PinnedNotesDialogComponent } from 'src/app/shared/dialog/pinned-notes-dialog/pinned-notes-dialog.component';
import { UserListDialogComponent } from 'src/app/shared/dialog/user-list-dialog/user-list-dialog.component';
import { MediaPreviewComponent } from '../dialog/media-preview/media-preview.component';
import { EditNumberComponent } from '../dialog/edit-number/edit-number.component';
import { AddEditFolderFilesModalComponent } from '../files/dialog/add-edit-folder-files-modal/add-edit-folder-files-modal.component';
import { PaginationComponent } from '../pagination/pagination.component';
import { AddEditEmailTemplateDailogComponent } from '../dialog/emails-dailog/add-edit-email-template-dailog/add-edit-email-template-dailog.component';
import { AddEditRvmComponent } from './../../shared/dialog/add-edit-rvm/add-edit-rvm.component';
import { AddEditLinksComponent } from '../links/add-edit-links/add-edit-links.component';
import { CopyTemplateComponent } from '../dialog/copy-template/copy-template.component';
import { AddEditTaskComponent } from 'src/app/modules/leads/leads-details/task/add-edit-task/add-edit-task.component';
import { AddEditTaskTemplateComponent } from '../dialog/add-edit-task-template/add-edit-task-template.component';
import { CopyDripComponent } from '../dialog/copy-drip/copy-drip.component';
import { ModalAddVendorsComponent } from '../../modules/accounting/vendors/modal-add-vendors/modal-add-vendors.component';
import { ModalAddAccountTagsComponent } from '../../modules/accounting/account-tags/modal-add-account-tags/modal-add-account-tags.component';
import { AddCardDialogComponent } from '../dialog/add-card-dialog/add-card-dialog.component';
import { AddEditSmsTemplateComponent } from '../dialog/add-edit-sms-template/add-edit-sms-template.component';
import { ViewMembersListDialogComponent } from '../dialog/view-members-list-dialog/view-members-list-dialog.component';
import { ConfirmationDialogComponent } from '../dialog/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationNumberDialogComponent } from '../dialog/confirmation-number-dialog/confirmation-number-dialog.component';
import { ViewHtmlDialogComponent } from '../dialog/view-html-dialog/view-html-dialog.component';
import { SowDetailsComponent } from '../dialog/sow-details/sow-details.component';
import { SowDailogComponent } from '../dialog/sow-dailog/sow-dailog.component';
import { SmsDialogComponent } from '../dialog/sms-dialog/sms-dialog.component';
import { RenameTagComponent } from '../../shared/dialog/rename-tag/rename-tag.component';
import { AddEditInterestedBuyersComponent } from 'src/app/modules/leads/leads-details/dispo/add-edit-interested-buyers/add-edit-interested-buyers.component';
import { EditCampaignComponent } from '../dialog/edit-campaign/edit-campaign.component';
import { CopyCallFlowComponent } from '../dialog/copy-call-flow/copy-call-flow.component';
import { EditDialerNumberComponent } from '../dialog/edit-dialer-number/edit-dialer-number.component';
import { ColdCallComponent } from '../dialog/cold-call/cold-call.component';
import { AddEditAppointmentsComponent } from 'src/app/modules/leads/leads-details/task/add-edit-appointments/add-edit-appointments.component';
import { ContingenciesService } from 'src/app/providers/contingencies/contingencies.service';
import { AddEditContingencyComponent } from 'src/app/shared/dialog/add-edit-contingency/add-edit-contingency.component';
import { ColdCallSettingComponent } from '../dialog/cold-call-setting/cold-call-setting.component';
import { LeadConnectDialogComponent } from '../dialog/lead-connect-dialog/lead-connect-dialog.component';
import { CampaignStatsComponent } from '../dialog/campaign-stats/campaign-stats.component';
import { AddEditInquiryComponent } from 'src/app/modules/leads/leads-details/dispo/add-edit-inquiry/add-edit-inquiry.component';
import { AddEditInterestedComponent } from 'src/app/modules/leads/leads-details/dispo/add-edit-interested/add-edit-interested.component';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListViewComponent implements OnInit {
  @ViewChild('pagination') pagination: PaginationComponent;
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  currentPage: number = 1;
  currentLimit: number = MiscellaneousConstant.paginationLimit.STANDARD;
  public s3Url: string = environment.newS3Url;
  menuTopLeftPosition = { x: '0', y: '0' };

  @Input() topHeader: any[] = [];
  @Input() header: any[] = [];
  @Input() secondaryData: any;
  @Input() data: any = {};
  @Input() moduleName: any = '';
  @Input() moduleId: any = '';
  @Input() id: any = '';
  @Input() hoverText: any = '';
  @Input() hoverTooltipText: any = '';
  @Input() paginationType: string = environment.pagination.type;
  @Input() isMultiSelectEnabled: boolean = false;
  @Input() userListing: any[] = [];
  @Input() selectedItem: any[] = [];
  @Input() currentUser: any = {};
  @Input() esignType: any = '';
  @Input() isFilterTransaction: boolean = false;
  @Input() filterTransactionType: string = '';
  @Input() filterTransactionAmount: number = 0;
  @Input() filterTransactionTypeId: string = '';
  @Input() filterTransactionTypeName: string = '';
  @Input() markets: any[] = [];
  @Input() customFunction: any;
  @Input() loader: string = '';
  @Input() isFilter: boolean = false;
  @Input() filterObj: any = {};
  @Input() selectActionType: string = '';
  @Input() style: any;
  @Input() listClass: string = '';
  @Input() customLoader: string = '';
  @Input() customLoaderMessage: string = '';
  @Input() showPagination: boolean = true;
  @Input() isOpeningBalance: boolean = false;
  @Input() openingBalance: number = 0;
  @Input() openingBalanceDate: number = 0;
  @Input() campaignId: any = '';
  @Input() campaignTodayDate: any = '';
  @Input() campaignScheduled: any = '';
  @Input() inboxCallEnable: any = '';
  @Input() dialed: boolean = false;
  @Input() isInlineAddEnabled: boolean = false;
  @Input() actionFooter: any[] = [];
  @Input() inlineAddForm: FormGroup;
  @Input() inlineFormStyle: any;
  @Input() address: any = {};
  @Input() isCustomRowStyleEnabled: any;
  @Input() customRowStyle: any;
  @Input() appliedScheduledCallFilters: any = {};
  @Input() currentCampaignPage: number = 1;
  @Input() todayCallPage: number = 1;
  @Input() scheduledCallPage: number = 1;
  @Input() inboxCallPage: number = 1;
  @Input() isDashboardStyle: boolean = false;
  @Input() isCustomMessageView: boolean = false;
  @Input() customNoRecordsMessage: string = '';
  @Input() leadsMainStatuses: any = [];
  @Input() noRecordStyle: any = {};
  @Input() mainStatus: any[];
  @Input() isheightIncrease: boolean;
  @Input() upgradeBlur: boolean = false;
  @Input() disableNgChange: boolean;
  @Input() upgradeBlurOnGoingCall: boolean = false;
  @Input() isheightIncreaseSettings: boolean = false;

  @Output() _changePage = new EventEmitter<any>();
  @Output() _changeLimit = new EventEmitter<any>();
  @Output() _changeSort = new EventEmitter<any>();
  @Output() _emitter = new EventEmitter<any>();
  @Output() _selectedItem = new EventEmitter<any>();
  @Output() callBackFunction = new EventEmitter<any>();
  @Output() _leadDetailsEmitter = new EventEmitter<any>();
  @Output() _inventoryDetailsEmitter = new EventEmitter<any>();
  @Output() _fixAccount = new EventEmitter<any>();
  @Output() _showActiveDropdown = new EventEmitter<any>();
  @Output() _emitFormSubmit = new EventEmitter<any>();
  @Output() _refresh = new EventEmitter<any>();
  @Output() _underMaintenanceView = new EventEmitter<any>();
  @Output() _leadStatusList = new EventEmitter<any>();
  @Output() _markAsReadBuyer = new EventEmitter<any>();
  @Output() _markAsReadVendor = new EventEmitter<any>();
  @Output() _editDealEmitter = new EventEmitter<any>();
  @Output() _deleteDealEmitter = new EventEmitter<any>();
  @Output() _editIntEmitter = new EventEmitter<any>();
  @Output() _deleteIntEmitter = new EventEmitter<any>();
  @Output() _editOfferEmitter = new EventEmitter<any>();
  @Output() _deleteOfferEmitter = new EventEmitter<any>();
  @Output() _callBuyer = new EventEmitter<any>();
  @Output() _emailBuyer = new EventEmitter<any>();
  @Output() _apptsDetails = new EventEmitter<any>();

  keys = Object.keys;
  dialogRef: any;
  messageConstant = MessageConstant;
  twilioErrorMessageConstant = TwilioErrorMessageConstant;
  activeIconIndex: number = -1;
  activeIcon: string = '';
  sort: string = environment.pagination.defaultSort;
  sortClass: string = '';
  sortVal: string = '';
  moduleListData: any[] = [];
  mainStatusData: any[] = [];
  allRoles: any[] = [
    'accountant',
    'acquisitionManager',
    'acquisitionSalesManager',
    'admin',
    'bookkeeper',
    'coldCaller',
    'closingCoordinator',
    'coOwner',
    'dispositionManager',
    'leadManager',
    'marketingAssistant',
    'marketingManager',
    'officeManager',
    'otherRole',
    'owner',
    'projectManager',
    'propertyAnalyst',
    'propertyManager',
    'transactionCoordinator',
  ];
  propertyInfoList: any;

  arrayLength = 0;
  buyers: any[] = [];
  userList: any[] = [];
  phoneUserList: any[] = [];
  miscellaneousConstant = MiscellaneousConstant;

  isInlineFormSubmitted: boolean = false;
  userPlan: any;
  leadConnectData: any;
  leadConnectValue: any;
  leadConnectIndex: any;
  showDropdown: boolean = false;
  activeButton: string = null;
  activeEntry: number = null;
  onActiveCall: boolean = false;

  constructor(
    public _sharedService: SharedService,
    private dialog: MatDialog,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _router: Router,
    public utilities: CommonFunctionsService,
    private _taskService: TaskService,
    private _masterTaskService: MasterTaskService,
    private _dialerService: DialerService,
    private _userService: UserService,
    private _leadservice: LeadsService,
    private _buyerService: BuyersService,
    private _creditService: CreditService,
    private _inventoryService: InventoryService,
    private _currencyPipe: CurrencyPipe,
    private _esignService: EsignService,
    private _contingenciesService: ContingenciesService,
    private _vendorService: VendorsService,
    private _callFlowService: CallFlowService,
    private _twilioService: TwilioService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.secondaryData = this.secondaryData || [];
    this.getModules();
  }

  getUserPlan(data, val, index) {
    this._creditService.getUserPlan({}).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.userPlan = response.data?.planName?.toLowerCase();
          this.checkInstantLead(data, val, index);
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getIconTooltipValue(data,header){
    try {
      if (!header || !data) {
        return;
      }
      let value = '';
      if(header?.iconType=='VACANT_PROPERTY'){
        value=data?.Vacant ? 'Vacant' : 'Occupied';
      }
      return value;
    }catch (error) {
      return '';
    }
  }
  
  getValueTooltip(data, header) {
    try {
      if (!header || !data) {
        return;
      }

      let value = '';
      if (header.key && header.subKey) {
        value = data[header.key][header.subKey]
          ? data[header.key][header.subKey]
          : 'N/A';
      } else if (header.key) {
        value =
          typeof data[header.key] != 'undefined' && data[header.key] != ''
            ? data[header.key]
            : 'N/A';
      } else if (header.value) {
        value = header.value ? header.value : 'N/A';
      } else {
        value = 'N/A';
      }

      if (header?.key === 'numberData') {
        const temp = [];
        data[header.key].forEach((dataNumber) => {
          temp.push(this.utilities.maskNumber(dataNumber.number));
        });
        value = temp?.toString() ? temp?.toString() : 'N/A';
      }
      
      return value;
    } catch (error) {
      return '';
    }
  }

  getValue(data, header, isTooltipExists?) {
    try {
      if (!header || !data) {
        return;
      }
      let value = '';
      if (header.key && header.subKey) {
        if (header.key == 'leadInfo' && header.subKey == 'address') {
          value = data['leadInfo']
            ? this.utilities.formatAddress(
                data['leadInfo']['address'],
                data['leadInfo']['unitNo']
              )
            : this.utilities.formatAddress(
                data['propertyData']['address'],
                data['propertyData']['unitNo']
              );
          value = value
            ? value
            : header?.allowZero
            ? '0'
            : header?.emptyCheck
            ? ''
            : 'N/A';
        } else if (header.callListKey == 'callLogDetails') {
          if (data[header.key] && data[header.subKey]) {
            value = data[header.key] + ' (Deleted)';
          } else if (!data[header.subKey] && data[header.key]) {
            value = data[header.key];
          } else if (!data[header.key]) {
            value = 'N/A';
          }
        } else if (header.indexCallKey == 'indexKeyDetails') {
          let userKey = data[header.key] ? data[header.key] : 'N/A';
          let userSubKey = data[header.subTitleKey]
            ? data[header.subKey] + ' (Deleted)'
            : data[header.subKey];
          if (data[header.key]) {
          }
          value = userKey + ' • ' + userSubKey;
        } else if (header.key == 'buyer' && header.subKey == 'name') {
          value = data?.[header.key]?.[header.subKey]
            ? this.utilities.capitalizeName(data[header.key][header.subKey])
            : 'N/A';
        } else if (
          header.key == 'mainstatus' &&
          header.subKey == 'title' &&
          this.moduleName == 'Scheduled SMS'
        ) {
          value = data?.[header.key]['labels'][data['mainUserId']]
            ? data?.[header.key]['labels'][data['mainUserId']]?.title
            : data?.[header.key]?.[header.subKey];
        } else {
          value = data?.[header.key]?.[header.subKey]
            ? data[header.key][header.subKey]
            : header?.allowZero
            ? '0'
            : header?.emptyCheck
            ? ''
            : 'N/A';
        }
      } else if (header.key == 'contactNumber' && header.pipe == 'PHONE') {
        const contactNumber = data[header.key].substr(
          data[header.key].length - 10
        );
        value =
          typeof data[header.key] != 'undefined' && data[header.key] !== ''
            ? this.utilities.maskNumber(contactNumber)
            : 'N/A';
      } else if (
        (header.key == 'address' || header.key == 'propertyAddress') &&
        header.pipe != 'ADDRESS_FORMAT'
      ) {
        value =
          typeof data[header.key] != 'undefined' && data[header.key] !== ''
            ? this.utilities.formatAddress(data[header.key], data['unitNo'])
            : 'N/A';
      } else if (
        header.key == 'status' &&
        header.callStatusKey == 'callLogStatus'
      ) {
        value =
          data['type'] == 'outgoingSms'
            ? 'N/A'
            : this.getCallLogStatus(data[header.key]);
      } else if (
        header.key == 'lastCallResult' &&
        header.callStatusKey == 'todayCallStatus'
      ) {
        value = this.getCallLogStatus(data[header.key]);
      } else if (header.key && header.options) {
        const item = header.options.find((x) => x.value === data[header.key]);

        value = item?.label || 'N/A';
      } else if (header.key) {
        value =
          typeof data[header.key] != 'undefined' &&
          data[header.key] !== '' &&
          data[header.key] !== ' '
            ? data[header.key]
            : header?.allowZero
            ? '0'
            : header?.emptyCheck
            ? ''
            : this.moduleName == 'List Stacking' &&
              header?.key == 'ownershipType'
            ? 'Individual'
            : 'N/A';
        if (isTooltipExists) {
          if (
            this.moduleName == 'List Stacking' &&
            header?.key == 'Owner_Type'
          ) {
            value =
              data[header?.key] == 'Unknown'
                ? 'Mailing address is missing'
                : data[header?.key] == 'Homeowner'
                ? 'Mailing address and Property address are same.'
                : 'Mailing address is different from Property address.';
          }
        }
        if (header.callTimeKey) {
          if (data[header?.key]) {
            const hours = Math.floor(data[header?.key] / 3600);
            const minutes = Math.floor((data[header?.key] % 3600) / 60);
            const seconds = data[header?.key] % 60;
            let result = `${minutes
              .toString()
              .padStart(1, '0')}:${seconds.toString().padStart(2, '0')}`;
            if (!!hours) {
              result = `${hours.toString()}:${minutes
                .toString()
                .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
            }
            value = result + ' Min';
          } else {
            value = '0:00' + ' Min';
          }
        }
      } else if (header.value) {
        value = header.value;
      } else {
        if (header?.allowZero) {
          value = '0';
        } else if (header?.emptyCheck) {
          value = '';
        } else {
          value = 'N/A';
        }
      }

      // TYPE
      if (header?.type === 'RVM' || header?.type === 'Email') {
        value = this.getValuesRVMFromId(value);
      }
      if (header?.type === 'BOOLEAN') {
        value =
          data[header.key] == true ? data?.lead_property_status || 'Yes' : 'No';
      }

      if (header?.type === 'MARKET') {
        value = this.getMarket(data[header?.key]);
      }

      // TYPE
      if (header?.type === 'BOTH') {
        value =
          data[header?.key][header?.subKey] +
          ' ' +
          data[header?.key][header?.subKey2];
      }

      if (header?.key === 'numberData' && header?.label != 'Assigned Nums.') {
        const temp = [];
        data[header.key].forEach((dataNumber) => {
          temp.push(this.utilities.maskNumber(dataNumber.number));
        });
        value =
          temp.length > 0
            ? temp.length > 1
              ? temp.length + ' Numbers'
              : temp?.toString()
            : 'N/A';
      }

      if (header?.key === 'numberData' && header?.label == 'Assigned Nums.') {
        value = `${data[header?.key].length} ${header?.label}`;
      }

      if (header?.count && Array.isArray(data[header.key])) {
        value = data[header.key]?.length;
      }

      if (header?.countLs && Array.isArray(data[header.key])) {
        value = data[header.key];
      }

      if ((header?.count || header?.length) && data[header.key]) {
        let dataValue = [...new Set([...data[header.key]])];
        value = `${dataValue?.length}` || '0';
      }

      if (header?.length && Array.isArray(header.key)) {
        value = '0';
        header?.key?.filter((key) => {
          let count = parseInt(value);
          count += data[key]?.length || 0;
          value = `${count}`;
        });
        if (header?.label != 'Deals') value = `${value} ${header?.label}`;
      }

      if (header?.uniqueLength && typeof data[header?.key] === 'object') {
        value = '0';
        let item = [];
        Object.keys(data[header?.key]).forEach((key) => {
          item = [...item, ...data[header?.key][key]];
        });

        item = [...new Set(item)];
        value = `${item.length} ${header?.label}`;
      }

      if (header?.hoverKey) {
        value = data[header?.hoverKey] || '';
      }

      if (header?.isThousandMultiplier) {
        value = `${parseInt(value) * 1000}`;
      }

      if (data?.isJournal && value == 'N/A' && header?.key == 'journalNo') {
        value = '  ';
      }

      if (header.type === 'APPOINTMENT_PROFILE') {
        value = this.getOwner(data).profile;
      }

      if (header.label === 'Delivered At') {
        if (data?.newStats?.status != 'delivered') {
          value = 'N/A';
        }
      }

      if (header.label === 'Not Delivered At') {
        if (data?.newStats?.status == 'delivered') {
          value = 'N/A';
        }
      }

      return value;
    } catch (error) {
      return '';
    }
  }

  getCallLogStatus(statusId) {
    let status = 'N/A';
    if (statusId == 1) {
      status = 'DNC Number';
    } else if (statusId == 2) {
      status = 'DNC Contact';
    } else if (statusId == 3) {
      status = 'Schedule Call';
    } else if (statusId == 4) {
      status = 'Moved to Lead';
    } else if (statusId == 5) {
      status = 'Property Sold';
    } else if (statusId == 15) {
      status = 'Not Interested';
    } else if (statusId == 6) {
      status = 'Connected Other';
    } else if (statusId == 7) {
      status = 'Seller Unavailable';
    } else if (statusId == 8) {
      status = 'Kept Ringing';
    } else if (statusId == 9) {
      status = 'Wrong Number';
    } else if (statusId == 10) {
      status = 'Inactive Number';
    } else if (statusId == 11) {
      status = 'Busy Signal';
    } else if (statusId == 12) {
      status = 'Left VM';
    } else if (statusId == 13) {
      status = 'VM not set up';
    } else if (statusId == 14) {
      status = 'Not Connected Other';
    }
    return status;
  }

  getColorCode(item, head) {
    const timeStamp = item[head?.key];
    if (!timeStamp) return;

    const day = moment(timeStamp).format('YYYY-MM-DD');
    const currentDay = moment().format('YYYY-MM-DD');

    if (moment(day).isBefore(currentDay)) {
      return '#800000';
    }

    if (moment(day).isAfter(currentDay)) {
      return '#4fa563';
    }

    if (moment(day).isSame(currentDay)) {
      return '#b76f1e';
    }
  }

  checkLoading(head, item) {
    try {
      if (head?.loadingKey && head?.loadingSubKey) {
        return (
          head?.checkLoading && item[head?.loadingKey][head?.loadingSubKey]
        );
      } else {
        return head?.checkLoading && item[head?.loadingKey];
      }
    } catch (error) {
      return false;
    }
  }

  getMarket(marketId) {
    const index = this.markets.findIndex((x) => x?._id === (marketId || ''));

    if (index > -1) {
      return this.markets[index].title;
    }

    return 'Primary Market';
  }

  numberType(item, numberType) {
    if (!numberType || !item?.contactNumber) {
      return;
    }
    const type = this.utilities.numberType(numberType?.toLowerCase());
    return `(${type || 'N/A'})`;
  }
  callNumberType(item, numberType) {
    const type = this.utilities.maskNumber(numberType);
    return type || 'N/A';
  }

  getArray(data, header) {
    try {
      let value = ['N/A'];
      if (header.key && header.subKey) {
        value = data[header.key][header.subKey]
          ? data[header.key][header.subKey]
          : 0;
      } else if (header.key) {
        value = data[header.key] ? data[header.key] : ['N/A'];
      } else if (header.value) {
        value = header.value ? header.value : ['N/A'];
      } else {
        value = ['N/A'];
      }

      let value2 = ['N/A'];
      if (header.key2 && header.subKey) {
        value2 = data[header.key2][header.subKey]
          ? data[header.key2][header.subKey]
          : 0;
      } else if (header.key2) {
        value2 = data[header.key2] ? data[header.key2] : ['N/A'];
      } else if (header.value) {
        value2 = header.value ? header.value : ['N/A'];
      } else {
        value2 = ['N/A'];
      }

      if (header?.length) {
        value = data[header.key] ? data[header.key].length : ['N/A'];
      }
      if (header?.type === 'DIRECT_MAILERS') {
        value = this.getDirectMailers(value);
      }
      if (header?.type === 'mailersDate') {
        value = this.getMailersDate(value);
      }

      if (header?.type === 'mailerType') {
        value = this.getMailerType(value);
      }
      if (header?.type === 'mailerStatus') {
        value = this.getMailerStatus(value, value2);
      }
      return value;
    } catch (error) {
      return 0;
    }
  }

  getArrayCurrency(data, header) {
    try {
      let value = [0];
      if (header.key && header.subKey) {
        value = data[header.key][header.subKey]
          ? data[header.key][header.subKey]
          : 0;
      } else if (header.key) {
        value = data[header.key] ? data[header.key] : [0];
      } else if (header.value) {
        value = header.value ? header.value : [0];
      } else {
        value = [0];
      }

      if (header?.length) {
        value = data[header.key] ? data[header.key].length : [0];
      }
      if (header?.type === 'all_total') {
        value = this.getAllTotal(data, value);
      }
      return value;
    } catch (error) {
      return [0];
    }
  }

  getCurrency(data, header) {
    try {
      let value = 0;
      if (header.key && header.subKey) {
        value = data[header.key][header.subKey]
          ? data[header.key][header.subKey]
          : 0;
      } else if (header.key) {
        value = data[header.key] ? data[header.key] : 0;
      } else if (header.value) {
        value = header.value ? header.value : 0;
      } else {
        value = 0;
      }

      if (header?.length) {
        value = data[header.key] ? data[header.key].length : '0';
      }
      return value;
    } catch (error) {
      return 0;
    }
  }

  getDirectMailers(val) {
    let value = [];
    if (val.length) {
      value = val.map((item, i) => {
        if (item.mailType) {
          return 'Mailing #' + (i + 1);
        }
        return '';
      });
      return value;
    } else {
      return ['N/A'];
    }
  }

  getMailersDate(val) {
    let value = [];
    if (val.length) {
      value = val.map((item) => {
        if (moment(item.date, 'MM-DD-YYYY').isValid())
          return moment(item.date, 'MM-DD-YYYY').format('MMM DD, yyyy');

        return moment(item.date, 'DD/MM/YYYY').format('MMM DD, yyyy');
      });
      return value;
    } else {
      return ['N/A'];
    }
  }

  getMailerType(val) {
    let value = [];
    if (val.length) {
      value = val.map((item) => {
        return item.mailType;
      });
      return value;
    } else {
      return ['N/A'];
    }
  }

  getMailerStatus(j, val) {
    let value = [];
    let spliceArray = [];
    if (j && val) {
      spliceArray = val.map((item) => {
        if (item.mailType) {
          return item.mailType;
        }
      });
      spliceArray = spliceArray.filter((element) => {
        return element !== undefined;
      });
      let k;
      if (spliceArray.length >= j) {
        k = spliceArray.length - j;
      } else {
        j = spliceArray.length;
        k = 0;
      }
      let completedArray = [];
      for (let i = 0; i < j; i++) {
        completedArray.push('Completed');
      }

      let pendingArray = [];
      for (let i = 0; i < k; i++) {
        pendingArray.push('Pending');
      }
      value = [...completedArray, ...pendingArray];
      return value;
    } else {
      return ['N/A'];
    }
  }

  getAllTotal(data, val) {
    let value = [];
    if (val.length) {
      value = val.map((item, i) => {
        if (item.totalPrice) {
          let tPrice = item.totalPrice;
          let dPrice = this.getDiscount(data, i);
          let val = parseFloat(tPrice) - parseFloat(dPrice);
          return Number(val);
        } else if (data['allTotal']) {
          let prices = data['allTotal'].split('@').filter((x) => x);
          return Number(prices[i]);
        }
        return 0;
      });
      return value;
    } else {
      return ['N/A'];
    }
  }

  getDiscount(data, index) {
    try {
      let shipping = data?.orderSummary[index]?.shipping;
      let totalPrice = data?.orderSummary[index]?.totalPrice;
      if (!totalPrice) {
        const prices = data['allTotal'].split('@').filter((x) => x);
        totalPrice = prices[index];
      }

      let val =
        (parseFloat(totalPrice) - parseFloat(shipping)) *
        parseFloat(data?.discount[index]);
      val = val / 100;
      return val.toFixed(2);
    } catch (error) {
      return '0.00';
    }
  }

  getValuesRVMFromId(val) {
    let displayVal = [];
    for (let i = 0; i < val.length; i++) {
      for (let j = 0; j < this.secondaryData.length; j++) {
        if (val[i] == this.secondaryData[j]._id) {
          displayVal.push(this.secondaryData[j].title);
          break;
        }
      }
    }
    if (displayVal.length > 1) {
      return 'Multiple';
    } else {
      return displayVal[0];
    }
  }

  getIcon(item?, type?) {
    let name = '';
    if (type?.iconType === 'FILE' || type === 'FILE') {
      name = item?.fileType;
    } else if (type?.iconType === 'CALL_LOGS') {
      name = item?.type;
    } else if (item?.iconType === 'TASKS') {
      name = item?.type;
    } else if (item?.iconType === 'CARDS') {
      name = item?.type;
      return name;
    } else if (type?.iconType === 'SHARE_SOW') {
      if (item?.sharing) name = 'share-tilt.svg';
      else name = 'share-black.svg';
    } else if (type?.iconType === 'VACANT_PROPERTY') {
      name = item?.Vacant ? 'house-grey.svg' : 'house-black.svg';
    }
    if (!name) {
      return 'other_icon.png';
    }

    if (
      name.includes('doc') ||
      name.includes('docx') ||
      name.includes('document')
    ) {
      return 'doc_icon.png';
    }

    if (name.includes('pdf')) {
      return 'pdf_icon.png';
    }

    if (name.includes('mp4')) {
      return 'mp4_icon.png';
    }

    if (name.includes('mp3')) {
      return 'mp3_icon.png';
    }

    if (name.includes('gif')) {
      return 'gif_icon.png';
    }

    if (name.includes('png')) {
      return 'png_icon.png';
    }

    if (name.includes('xls') || name.includes('xlsx')) {
      return 'xls_icon.png';
    }

    if (name.includes('jpeg') || name.includes('jpg')) {
      return 'jpeg_icon.png';
    }

    // OUTGOING
    if (name === 'outgoingCall') {
      return 'outgoing-calls.svg';
    }

    if (name === 'outgoingSms') {
      return 'outgoing-smss.svg';
    }

    if (name === 'outgoingMms') {
      return 'outgoing-smss.svg';
    }

    // INCOMING
    if (
      name === 'incomingCall' &&
      (item?.status == 'no-answer' ||
        item?.status == 'cancel' ||
        item?.status == 'no-confirm' ||
        item?.status == 'busy')
    ) {
      return 'missed-calls.svg';
    }

    if (name === 'incomingCall' && item?.communicationType == 3) {
      return 'voice-mail.svg';
    }

    if (name === 'incomingCall' && item?.communicationType == 4) {
      return 'voicemail-rvm.svg';
    }

    if (name === 'incomingCall') {
      return 'incoming-calls.svg';
    }

    if (name === 'incomingSms') {
      return 'incoming-smss.svg';
    }

    if (name === 'incomingMms') {
      return 'incoming-smss.svg';
    }

    // TASKS
    if (name === 'reminder') {
      return 'bell.svg';
    }

    if (name === 'description') {
      return 'description.svg';
    }
    if (name === 'ico') {
      return 'ico_icon.png';
    }
    if (name === 'svg') {
      return 'svg_icon.png';
    }
    if (name === 'zip') {
      return 'zip_icon.png';
    }
    if (name === 'txt') {
      return 'txt_icon.png';
    }
    if (name === 'rar') {
      return 'rar_icon.png';
    }
    if (name === 'json') {
      return 'json_icon.png';
    }
    if (name === 'html') {
      return 'html_icon.png';
    }
    if (name === 'dwg') {
      return 'dwg_icon.png';
    }
    if (name === 'ai') {
      return 'ai_icon.png';
    }
    if (name === 'csv') {
      return 'csv_icon.png';
    }
    if (name === 'webp') {
      return 'webp_icon.png';
    }

    return name;
  }

  getStatusIcon(item) {
    if (item?.type == 'outgoingSms' || item?.type == 'outgoingMms') {
      if (item?.status == 'delivered') {
        return 'SMS-delivered.png';
      }
      if (item?.status == 'failed' || item?.status == 'undelivered') {
        return 'SMS-failed.png';
      }
      if (item?.status == 'queued' || item?.status == 'sent') {
        return 'SMS-queue.png';
      }
    }
  }

  getActionIcon(action, actionIndex) {
    return this.activeIconIndex === actionIndex &&
      this.activeIcon === action?.type
      ? action?.activeIcon
      : action?.icon;
  }

  getDetails(item, bool?, isGlobalSearch?) {
    if (item?.url) {
      if (this.moduleName == 'Calendar') {
        window.open(item?.url, '_blank');
      } else {
        this._router.navigateByUrl(item?.url);
      }
    } else {
      let type = this.moduleName.toLowerCase();
      let module = type;
      let id = item?._id;

      if (type === 'call logs') {
        id = item?.leadId;
      }

      if (
        type === 'warm leads' ||
        type === 'dead leads' ||
        type === 'referred to agent' ||
        type === 'call logs' ||
        type === 'calendar' ||
        type === 'inquiry' ||
        type === 'openhouse' ||
        type === 'buyer-offers'
      ) {
        module = 'leads';
      }

      if (type === 'buyer/agents') {
        module = 'buyers';
      }

      if (type === 'deals' || type == 'interestedbuyers') {
        if (item?.isLead) {
          module = 'leads';
        } else {
          if (item?.mainStatusId == 'sold') module = 'sold';
          else if (item?.mainStatusId == 'rental') module = 'rental';
          else module = 'inventory';
        }
      }

      if (!id) {
        return;
      }

      const key = module + 'Id';
      let obj = { [key]: id };

      if (module === 'buyers') {
        if (this.filterObj?.unreadTab) {
          obj['inbox'] = 1;
        } else {
          obj['contact'] = 1;
        }
      }

      let params = {
        queryParams: obj,
      };

      params['state'] = this.filterObj;

      if (bool) {
        window.open(
          `/${module}/details?buyersId=${obj.buyersId}&contact=${obj.contact}`
        );
        return;
      } else {
        if (isGlobalSearch) {
          const url = this._router.serializeUrl(
            this._router.createUrlTree([`/${module}/details`], params)
          );
          window.open(url, '_blank');
        } else {
          this._router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => this._router.navigate([`/${module}/details`], params));
        }
      }
    }
  }

  getLeadDetails(item) {
    this._leadDetailsEmitter.emit(item);
  }

  isChecked() {
    const selectedItems = this.data?.items?.filter((x) => x.isCompleted);

    if (
      this.data?.items?.length &&
      selectedItems?.length === this.data['items']?.length
    ) {
      return true;
    }

    return false;
  }

  selectAll($event) {
    if (!$event?.isObject) $event.stopPropagation();
    this.data['items'].map((x) => (x.isCompleted = $event.target.checked));
    this.selectedItem = this.data['items'].filter((x) => x.isCompleted);
    this._selectedItem.emit(this.selectedItem);
  }

  checkKey(head, data) {
    if (!head?.checkKey) return false;

    return data[head?.checkKey];
  }

  selectItem(item, $event) {
    $event.stopPropagation();
    if (
      this.moduleName === 'Local Tasks' ||
      this.moduleName === 'Local Completed Tasks'
    ) {
      this._selectedItem.emit([item]);
    } else {
      const index = this.selectedItem.findIndex((x) => x?._id === item?._id);
      const dataIndex = this.data['items'].findIndex(
        (x) => x?._id === item?._id
      );

      if (
        this.moduleName === 'My Tasks' ||
        this.moduleName === 'Local Tasks' ||
        this.moduleName === 'Local Completed Tasks'
      ) {
        this.selectedItem = [];
        this.data['items'][dataIndex]['isCompleted'] = !$event.target.checked;
      } else {
        this.data['items'][dataIndex]['isCompleted'] = $event.target.checked;
      }

      if (index > -1) {
        this.selectedItem.splice(index, 1);
      } else {
        this.selectedItem.push(item);
      }
      if (this.moduleName === 'Dialer') {
        var dialerActivityList;
        const activedata = this.data['items'].forEach((element) => {
          if (element.isCompleted) {
            dialerActivityList = element.isCompleted;
          }
        });

        if (dialerActivityList) {
          const dialerCampaignData = {
            details: this.data['items'],
            name: 'Active Dropdown',
          };
          this._showActiveDropdown.emit(dialerCampaignData);
        } else {
          this._showActiveDropdown.emit('');
        }
      }
      this._selectedItem.emit(this.selectedItem);
    }
  }

  callAction(action, item, index, moduleId?, i?) {
    const type = action.type || action.event;

    this._toastrService.clear();
    if (
      item?.isActionDisabled &&
      (action?.label == 'Delete' || action?.label == 'Edit')
    ) {
      this._toastrService.error(
        '',
        item.disabledDescription.replace('@ACTION', action.label)
      );
      return;
    }

    if (
      (item?.isReadOnly &&
        (action?.label == 'Edit' || action?.label == 'Toggle')) ||
      (action?.isEnabled && !item?.isEnabled)
    ) {
      this._toastrService.error(
        '',
        action?.disabledText.replace(
          '{{TYPE}}',
          `${item.isReadOnly ? 'readonly' : 'disabled'}`
        )
      );
      return;
    }

    switch (type) {
      case 'GLOBAL_SEARCH':
        this.globalSearchRedirect(action, item, index);
        break;

      case 'DELETE':
        this.onDeleteDialog('', item, index);
        break;

      case 'DELETE_BUYER':
        this.onDeleteDialog('buyer', item, index);
        break;

      case 'EDIT_BUYER_OFFER':
        this._editOfferEmitter.emit({ item, index });
        break;

      case 'DELETE_BUYER_OFFER':
        this._deleteOfferEmitter.emit({ item, index });
        break;

      case 'EDIT_BUYER_INQUIRY':
        this._editIntEmitter.emit({ item, index });
        break;

      case 'DELETE_BUYER_INQUIRY':
        this._deleteIntEmitter.emit({ item, index });
        break;
      case 'EDIT_BUYER_DEAL':
        this._editDealEmitter.emit({ item, index });
        break;

      case 'DELETE_BUYER_DEAL':
        this._deleteDealEmitter.emit({ item, index });
        break;

      case 'BUYER_INBOX':
        this.markAsRead('buyer', item);
        break;
      case 'READ_BUYER_INBOX':
        this.markAsRead('buyer', item, true);
        break;

      case 'INBOX_VENDOR':
        this.markAsRead('vendor', item);
        break;
      case 'READ_INBOX_VENDOR':
        this.markAsRead('vendor', item, true);
        break;

      case 'DELETE_VENDOR_MANAGEMENT':
        this.onDeleteDialog('vendor_management', item, index);
        break;

      case 'EDIT_BUYER':
        break;

      case 'DELETE_LEAD':
        this.checkForListing(item, index);

        break;

      case 'DELETE_LINK':
        this.onDeleteDialog('link', item, index);
        break;

      case 'DELETE_FILE':
        this.onDeleteDialog('file', item, index);
        break;

      case 'DELETE_RVM':
        this.onDeleteDialog(
          this.moduleName == 'Dialer Voicemail' ? 'Dialer Voicemail' : 'RVM',
          item,
          index
        );
        break;

      case 'PREVIEW':
        this.preview(item, index);
        break;

      case 'DOWNLOAD_FILE':
        this.downloadFile(item);
        break;

      case 'SHARE_FILE':
        this.openAddEditFilesModal('share', item, index);
        break;

      case 'EDIT_FILE':
        this.openAddEditFilesModal('edit', item, index);
        break;

      case 'EDIT_LINK':
        this.openAddEditLinksModal('Edit', item, index);
        break;

      case 'DETAILS':
        this.getDetails(item, false);
        break;

      case 'CALL_DETAILS':
        this.getDetailsCall(item);
        break;

      case 'LEAD_DETAILS':
        if (action?.isTargetBlank) {
          item.bool = true;
        }
        this.getLeadDetails(item);
        break;

      case 'DELETE_NUMBER':
        this.onDeleteNumberDialog('Number', item, index);
        break;

      case 'EDIT_DIALOG':
        this.onEditNumberDialog(item, index);
        break;

      case 'EDIT_CAMPAIGN_V2':
        this.onEditNumberDialogV2(item, index);
        break;

      case 'EDIT_NUMBER_V2':
        this.onEditDailerNumberDialogV2(item, index);
        break;

      case 'DELETE_EMAIL':
        this.onDeleteDialog('email', item, index);
        break;

      case 'DELETE_SMS':
        this.onDeleteDialog('sms', item, index);
        break;

      case 'DELETE_TASK_TEMPLATE':
        this.onDeleteDialog('taskTemplate', item, index);
        break;

      case 'DELETE_CONTRACTOR':
        this.onDeleteDialog('contractor', item, index);
        break;

      case 'EDIT_EMAIL':
        this.onEditEmailDialog('Edit', item, index, moduleId);
        break;

      case 'EDIT_SCRIPT':
        localStorage.setItem('redirectData', JSON.stringify(item));
        const navigationDetails2: string[] = [
          '/dialer/script/edit',
          item['_id'],
        ];
        this._router.navigate(navigationDetails2);
        break;

      case 'COPY_MAIL_TEMPLATE':
        this.onMailTemplateCopyDialog(item);
        break;

      case 'EDIT_SMS':
        this.onEditSmsDialog('Edit', item, index, moduleId);
        break;

      case 'EDIT_CONTRACTOR':
        let redirectLink2 = `/contractor/edit`;
        const navigationDetails1: string[] = [redirectLink2, item._id];
        this._router.navigate(navigationDetails1, {
          state: { page: this.currentPage },
        });
        break;

      case 'DIRECT_MAILERS':
        let para1 = item._id;
        let redirectLink4 = `/direct-mail/details/${para1}/${i}`;
        this._router.navigate([redirectLink4]);
        break;

      case 'CAMPAIGN_NAME':
        let para2 = item._id;
        let redirectLink5 = `/direct-mail/details/${para2}/1`;
        this._router.navigate([redirectLink5]);
        break;

      case 'EDIT_RVM':
        this.onAddEditRvmDialog(item, index);
        break;

      case 'EDIT_ACCOUNT':
        this.onEditAccount(item, index);
        break;

      case 'DELETE_ACCOUNT':
        this.onDeleteDialog('account', item, index);
        break;

      case 'EDIT_VENDOR':
        this.onEditVendor(item, index);
        break;

      case 'DELETE_VENDOR':
        if (item.hasTransaction) {
          this._toastrService.error(
            'A vendor with transactions can not be deleted. Please recategorize all transactions for this vendor before deleting.'
          );
        } else {
          if (item._id == 'uncategory') {
            this._toastrService.error('You can not delete this vendor', '');
            return;
          }
          this.onDeleteDialog('vendors', item, index);
        }
        break;

      case 'EDIT_ACCOUNT_TAGS':
        this.onEditAccountTag(item, index);
        break;

      case 'DELETE_ACCOUNT_TAGS':
        if (item.hasTransaction) {
          this._toastrService.error(
            'An account with transactions can not be deleted. Please recategorize all transactions for this account before deleting.',
            ''
          );
        } else if (
          item?.name?.toLocaleLowerCase() ==
          'Retained Earnings'.toLocaleLowerCase()
        ) {
          this._toastrService.error('You can not delete this tag', '');
        } else {
          this.onDeleteDialog('accountTags', item, index);
        }
        break;

      case 'TRANSACTION_LIST':
        this.onTransactionList(item, index);
        break;

      case 'EDIT_TRANSACTION':
        this.onEditTransaction(item, index);
        break;

      case 'DELETE_TRANSACTION':
        this.onDeleteDialog('transaction', item, index);
        break;

      case 'DELETE_ESIGN':
        this.onDeleteDialog('Template', item, index);
        break;

      case 'COPY_ESIGN':
        this.onCopyEsignDialog('Template', 'copy', item, index);
        break;

      case 'EDIT_ESIGN':
        this.onEditEsignDialog('Edit', item, index);
        break;

      case 'VIEW_DOCUMENT':
        this.onViewEsignDialog('View', item, index);
        break;

      case 'CANCEL_DOCUMENT':
        this.onDocDeleteDialog('Document', item, index);
        break;

      case 'DOWNLOAD_ESIGN':
        this.downloadEsignFile(item);
        break;

      case 'DIALER_DETAILS':
        this.goToColdCall(index, item);
        break;

      case 'DIALER_INBOX':
        this.inboxNewHide(item, true);
        break;

      case 'LEAD_INVENTORY':
        let data = this.moduleListData.find(
          (module) => module._id == item.moduleId
        );
        if (item.buyerId && !item.subModuleId) {
          localStorage.setItem('RE-TAB', 'contact-details');
          window.open(
            `/buyers/details?buyersId=${item.buyerId}&contact=1`,
            '_blank'
          );
        } else if (data?.title == 'lead') {
          if (item.mainStatusId) {
            localStorage.setItem('RE-TAB', 'tasks');
          } else {
            localStorage.setItem('RE-TAB', 'lead-details');
          }
          window.open(`/leads/details?leadsId=${item.subModuleId}`, '_blank');
        } else if (data?.title == 'inventory') {
          this.setTab(item.mainStatusId);
          window.open(
            `/inventory/details?inventoryId=${item.subModuleId}`,
            '_blank'
          );
        } else if (data?.title == 'rental') {
          this.setTab(item.mainStatusId);
          window.open(`/rental/details?rentalId=${item.subModuleId}`, '_blank');
        } else if (data?.title == 'sold') {
          this.setTab(item.mainStatusId);
          window.open(`/sold/details?soldId=${item.subModuleId}`, '_blank');
        } else if (item?.type == 'Buyer' && item?.buyerId) {
          localStorage.setItem('RE-TAB', 'contact-details');
          window.open(
            `/buyers/details?buyersId=${item.buyerId}&contact=1`,
            '_blank'
          );
        } else if (item?.type == 'Vendor' && item?.vendorId) {
          localStorage.setItem('RE-TAB', 'contact-details');
          window.open(
            `/vendors/details?vendorsId=${item.vendorId}&contact=1`,
            '_blank'
          );
        } else if (item?.module.title === 'vendor') {
          if (item?.subModuleId) {
            localStorage.setItem('RE-TAB', 'contact-details');
            window.open(
              `/vendors/details?vendorsId=${item?.subModuleId}&contact=1`,
              '_blank'
            );
          }
        } else if (item?.module?.title === 'buyers') {
          if (item?.subModuleId) {
            localStorage.setItem('RE-TAB', 'contact-details');
            window.open(
              `/buyers/details?buyersId=${item?.subModuleId}&contact=1`,
              '_blank'
            );
          }
        } else if (item?.module?.title === 'wholesalePipeline') {
          localStorage.setItem('RE-TAB', 'tasks');
          window.open(
            `/wholesale-pipeline/details?leadsId=${item.subModuleId}`,
            '_blank'
          );
        } else {
          localStorage.setItem('RE-TAB', 'tasks');
        }
        break;

      case 'EDIT_TASK':
        this.onEditTaskDialog('Update', item);
        break;

      case 'EDIT_TASK_TEMPLATE':
        this.onEditTaskTemplateDialog('Update', item);
        break;

      case 'DELETE_TASK':
        this.onDeleteTaskDialog(item);
        break;

      case 'EDIT_LOCAL_TASK':
        this.onEditLocalTaskDialog('Update', item, index);
        break;

      case 'EDIT_FLOW':
        window.open(`/call-flow/edit/${item._id}`, '_self');
        break;

      case 'GROUP_LIST':
        window.open(`manage-numbers/group/${item.groupId}`, '_self');
        break;
      case 'DELETE_FLOW':
        this.onDeleteFlow('Call Flow', item, index);
        break;

      case 'EDIT_DRIP':
        let redirectLink = `/drip-campaigns/edit-drip-campaign`;
        const navigationDetails: string[] = [redirectLink, item._id];
        this._router.navigate(navigationDetails);
        break;

      case 'CONTRACTOR':
        let redirectLink3 = `/contractor/detail/`;
        const navigationDetails3: string[] = [redirectLink3, item._id];
        this._router.navigate(navigationDetails3);
        break;

      case 'COPY_DRIP':
        this.onDripCopyDialog('Drip', 'copy', item, index);
        break;

      case 'STOP_DRIP':
        this.onDripCopyDialog('Drip', 'stop', item, index);
        break;

      case 'COPY_FLOW':
        this.onFlowCopyDialog('Flow', 'copy', item, index);
        break;

      case 'DELETE_DRIP':
        this.onDripDeleteDialog('Drip', item, index);
        break;

      case 'EDIT_SUB_PROFILE_USER':
        this.onEditSubUser(item, index);
        break;

      case 'DELETE_SUB_USER':
        this.onInfoToaster('Member', item, index);
        break;

      case 'DOCUMENT_ADDRESS':
        this.onDocumentAddress(item, index);
        break;

      case 'DELETE_BLOCK_NUMBER':
        this.onBlockNumberDeleteDialog('blockNumber', item, index);
        break;

      case 'DELETE_NUMBER_DIALER':
        this.onDeleteDialog('Number', item, index);
        break;

      case 'DELETE_NUMBER_BUYER':
        this.onDeleteDialog('Number', item, index);
        break;

      case 'DELETE_NUMBER_VENDOR':
        this.onDeleteDialog('Number', item, index);
        break;

      case 'DELETE_CONTRACTOR':
        this.onDeleteDialog('contractor', item, index);
        break;

      case 'LEAD DETAILS':
        this.onFavoriteLead(item);
        break;

      case 'LEAD_DETAILS_TYPE':
        this.onMainPage(item);
        break;

      case 'EDIT_CARD':
        this.onEditCard(item, index);
        break;

      case 'DELETE_CARD':
        // this.onDeleteCardDialog('cards', item, index);
        if (item.isDefaultCard) {
          this._toastrService.error('You can not delete this card', '');
        } else {
          this.onDeleteCardDialog('cards', item, index);
        }
        break;

      case 'INVENTORY_DETAILS':
        this.onInventoryDetails(item, index);
        break;

      case 'INVENTORY_DELETE':
        this.onDeleteDialog('Inventory', item, index);
        break;

      case 'SOLD_DELETE':
        this.onDeleteDialog('Sold', item, index);
        break;

      case 'RENTAL_DELETE':
        this.onDeleteDialog('Rental', item, index);
        break;

      case 'DELETE_MARKET':
        this.onDeleteDialog('Market', item, index);
        break;

      case 'REMOVE_MARKET':
        this.onInfoToaster('Market', item, index);
        break;

      case 'RELATED_DETAILS':
        this.getRelatedDetails(item);
        break;

      case 'MEMBERS_LIST':
        this.onViewMembersList(item, false);
        break;

      case 'NUMBER_LIST':
        this.onViewMembersList(item, true);
        break;

      case 'SKIP_TRACE':
        this.viewFileWithUrl(item);
        break;

      case 'EXPORT_FILE':
        this.viewFileWithUrl(item);
        break;

      case 'HTML_CONTENT':
        this.viewHtmlTemplate(action, item);
        break;

      case 'DELETE_SCRIPT':
        this.onDeleteDialog('Script', item, index);
        break;

      case 'DIALER_CAMPAIGN':
        this._emitter.emit(index);
        break;

      case 'DETAIL_SOW':
        this.viewSOWDetail(item);
        break;

      case 'EDIT_SOW':
        this.onAddEditSow('Edit', item, index);
        break;

      case 'DELETE_SOW':
        this.onAddEditSow('Delete', item, index);
        break;

      case 'EDIT_SCHEDULED_SMS':
        this.onEditScheduledSMS(item, index);
        break;

      case 'DELETE_SCHEDULED_SMS':
        this.onDeleteSMSScheduledDialog('Scheduled SMS', item, index);
        break;

      case 'EDIT_LIST_TAGS':
        this.renameTagList(item, 'Tag');
        break;

      case 'EDIT_LIST_LIST':
        this.renameTagList(item, 'List');
        break;

      case 'DELETE_LIST_TAGS':
        this.onDeleteTagListDialog(item, 'Tag');
        break;

      case 'DELETE_LIST_LIST':
        this.onDeleteTagListDialog(item, 'List');
        break;

      case 'PROPERTY':
        this._router.navigate([`/list-stacking/details`], {
          queryParams: { id: item?._id },
        });
        break;

      case 'DELETE_LIST_PROPERTY':
        this.onDeleteDialog('Property', { ...item, mode: 'single' }, index);
        break;

      case 'DELETE_JOURNAL_ENTRY':
        this.onDeleteDialog('Journal Entry', item, index);
        break;

      case 'EDIT_JOURNAL_ENTRY':
        this.onEditJournalEntry(item, index);
        break;
      case 'DUPLICATE_JOURNAL_ENTRY':
        this.onDuplicateJournalEntry(item, index);
        break;

      case 'ACCEPT_INTERESTED_BUYER':
        this.acceptInterestedBuyer(item, index);
        break;

      case 'EDIT_INTERESTED_BUYER':
        this.editInterestedBuyer(item, index);
        break;

      case 'EDIT_INQUIRY':
        this.editInquiry(item, index);
        break;

      case 'EDIT_INTERESTED':
        this.editInterested(item, index);
        break;

      case 'DELETE_INTERESTED_BUYER':
        this.deleteInterestedBuyer(item, index);
        break;

      case 'DELETE_INQUIRY':
        this.deleteInquiry(item, index);
        break;

      case 'DELETE_INTERESTED':
        this.deleteInterested(item, index);
        break;

      case 'EDIT_OFFER':
        this.editOffer(item, index);
        break;

      case 'DELETE_OFFER':
        this.deleteOffer(item, index);
        break;

      case 'EDIT_APPOINTMENT':
        this.onAppointmentDialog(item);
        break;

      case 'DELETE_APPOINTMENT':
        this.deleteAppointment(item, index);
        break;

      case 'DELETE_CONTINGENCY':
        this.deleteContingency(item, index);
        break;

      case 'EDIT_CONTINGENCY':
        this.updateContingency(item, index);
        break;

      case 'CHECK_CONTINGENCY':
        const obj = {
          contingencyId: item._id,
          isCompleted: !item.isCompleted,
        };

        this.changeContingencyStatus(obj, item, true);
        break;

      case 'EXPAND_TRANSACTION':
        this.onRowClick(item, index);
        break;

      case 'EDIT_VENDOR_TASK':
        this.editVendorTasks(item, 'VENDORS');
        break;

      case 'EDIT_BUYER_TASK':
        this.editVendorTasks(item, 'BUYERS');
        break;

      case 'EMAIL_STATS':
        this.getEmailStats(action, item);
        break;

      case 'SMS_STATS':
        this.getSMSStats(action, item);
        break;

      // case 'VIEW_WEBHOOK_LOG':
      //   let redirectLogLink = `/webhook-and-api-token/logs`;
      //   this._router.navigate([redirectLogLink, item._id]);
      //   break;

      case 'EDIT_WEBHOOK':
        let redirectEditWebLink = `/webhook-and-api-token/edit`;
        this._router.navigate([redirectEditWebLink, item?._id]);
        break;

      case 'DELETE_WEBHOOK':
        this.onDeleteDialog('Webhook', item, index);
        break;

      case 'VIEW_LOG_EVENT':
        this._emitter.emit({
          module: 'logEvent',
          type: 'logEvent',
          index: index,
          result: '',
          details: item,
        });
        break;

      case 'POF':
        if (!item['docs']?.length) return;
        let docs = JSON.parse(JSON.stringify(item['docs']));
        docs.map((x) => {
          if (x.fileType.includes('/')) {
            x.fileType = x.fileType.split('/')[1];
          }
          x.moduleId = this.moduleId;
          x.photo = x?.thumbnailUrl;
          x.directDownload = true;
          return x;
        });
        this.dialogRef = this.dialog.open(MediaPreviewComponent, {
          width: '50vw',
          backdropClass: 're-backdrop',
          panelClass: 're-preview-media',
          data: {
            file: docs[0],
            items: docs,
            index: 0,
            moduleId: this.moduleId,
          },
        });
        break;

      case 'KPI_APP_DETAILS':
        this._apptsDetails.emit({
          item,
          index,
          action,
        });
        break;

      case 'ADD_EXTERNAL_PORTFOLIO':
        this._emitter.emit({
          type: 'add_lead',
          item: item,
        });
        break;
    }
  }

  setTab(mainStatusId) {
    if (mainStatusId) {
      localStorage.setItem('RE-TAB', 'tasks');
    } else {
      localStorage.setItem('RE-TAB', 'project-info');
    }
  }

  viewSOWDetail(item) {
    this.dialogRef = this.dialog.open(SowDetailsComponent, {
      data: {
        details: item,
        moduleId: this.moduleId,
        leadData: this.data?.leadData,
        userData: this.data?.userData,
      },
      width: '820px',
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (!result) this.callBackFunction.emit(true);
    });
  }

  onAddEditSow(data, item, index) {
    if (data !== 'Delete') {
      this.dialogRef = this.dialog.open(SowDailogComponent, {
        data: {
          type: data,
          details: item,
          id: index,
          moduleId: this.moduleId,
          inventoryId: this.data?.inventoryId,
        },
        width: '820px',
      });
    } else {
      this.dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: {
          subModule: 'SOW',
          category: data,
          details: item,
          id: index,
        },
        width: '550px',
      });
    }
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.callBackFunction.emit(
          data !== 'Delete' ? false : { delete: true, id: item?._id }
        );
      }
    });
  }

  viewHtmlTemplate(action, item) {
    if (!item[action?.htmlKey]) return;

    this.dialogRef = this.dialog.open(ViewHtmlDialogComponent, {
      width: '800px',
      data: {
        title: item?.name,
        content: item[action?.htmlKey],
      },
    });
  }

  viewFileWithUrl(item) {
    if (item?.filePath) {
      var encodedPath = item?.filePath.replace(/\+/g, '%2B');
      encodedPath = this.s3Url + encodedPath;
      window.open(encodedPath);
    }
  }

  viewExportFile(item) {
    if (item?.filePath) {
      var encodedPath = item?.filePath.replace(/\+/g, '%2B');
      encodedPath = this.s3Url + encodedPath;
      window.open(encodedPath);
    }
  }

  onViewMembersList(item, bool?) {
    this.dialogRef = this.dialog.open(ViewMembersListDialogComponent, {
      width: '600px',
      data: {
        module: this.moduleName,
        item,
        number_list: bool ? 'Number' : 'Members',
      },
    });
  }

  preview(file, index) {
    this.dialogRef = this.dialog.open(MediaPreviewComponent, {
      width: '50vw',
      backdropClass: 're-backdrop',
      panelClass: 're-preview-media',
      data: {
        file,
        items: this.data['items'],
        index,
      },
    });
  }

  checkFileType(fileType) {
    const type = fileType.toLowerCase();

    return !!(
      type === 'pdf' ||
      type === 'png' ||
      type === 'jpeg' ||
      type === 'jpg' ||
      type === 'svg' ||
      type === 'gif' ||
      type === 'webp' ||
      type === 'ico' ||
      type === 'mp4' ||
      type === 'mp3'
    );
  }

  previewAttachment(items) {
    this._loaderService.start();
    setTimeout(() => {
      this._loaderService.stop();
    }, 2000);
    let re = /(?:\.([^.]+))?$/;
    this.dialogRef = this.dialog.open(MediaPreviewComponent, {
      width: '50vw',
      data: {
        file: { fileType: 'multiple', originalName: 'Attachments' },
        items: items.map((x) => {
          let fileType = re.exec(x)[1];
          let obj = { src: x, fileType: fileType, title: x };
          return obj;
        }),
      },
    });
  }

  onEditEmailDialog(type, data, index, moduleId) {
    this.dialogRef = this.dialog.open(AddEditEmailTemplateDailogComponent, {
      width: '650px',
      disableClose: true,
      panelClass: 'editTemplate',
      data: {
        moduleId: moduleId,
        type: 'email-template',
        subType: 'email',
        action: 'edit',
        category: '',
        template: 'add-edit',
        items: this.secondaryData,
        allTitle: {
          heading: 'Edit Template',
          buttonTitle: 'Edit',
        },
        details: data,
        index: index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({ module: 'email', type: 'edit', index, result });
      }
    });
  }

  onEditSmsDialog(type, data, index, moduleId) {
    this.dialogRef = this.dialog.open(AddEditSmsTemplateComponent, {
      width: '650px',
      disableClose: true,
      panelClass: 'editTemplate',
      data: {
        moduleId: moduleId,
        action: 'edit',
        items: this.secondaryData,
        allTitle: {
          heading: 'Edit Template',
          buttonTitle: 'Edit',
        },
        details: data,
        index: index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({ module: 'sms' });
      }
    });
  }

  onEditNumberDialog(data, index) {
    this.dialogRef = this.dialog.open(EditNumberComponent, {
      width: '525px',
      data: {
        subModule: 'edit number',
        subModuleId: this.id,
        moduleId: this.moduleId,
        details: data,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.data['items'][index]['marketingTitle'] = result.marketingTitle;
        this.data['items'][index]['crmQuestionId'] = result.crmQuestionId;
        this.data['items'][index]['flow'] = result.flow;
        this.data['items'][index]['flowName'] = result.flowName;
        this.data['items'][index]['marketId'] = result.marketId;
        this.allRoles.filter((role) => {
          this.data['items'][index][role] = result[role];
        });
        this._emitter.emit({
          module: this.moduleId,
          type: 'edit_number',
          index,
          result,
        });
      }
    });
  }

  onEditNumberDialogV2(data, index) {
    this.dialogRef = this.dialog.open(EditCampaignComponent, {
      width: '550px',
      data: {
        subModule: 'edit number',
        subModuleId: this.id,
        moduleId: this.moduleId,
        details: data,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.allRoles.filter((role) => {
          this.data['items'][index][role] = result[role];
        });

        this._emitter.emit({
          module: this.moduleId,
          type: 'edit_number',
          index,
          result,
        });
      }
    });
  }

  onEditDailerNumberDialogV2(data, index) {
    this.dialogRef = this.dialog.open(EditDialerNumberComponent, {
      width: '550px',
      data: {
        subModule: 'edit number',
        subModuleId: this.id,
        moduleId: this.moduleId,
        details: data,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.allRoles.filter((role) => {
          this.data['items'][index][role] = result[role];
        });
        this._emitter.emit({
          module: this.moduleId,
          type: 'edit_number',
          index,
          result,
        });
      }
    });
  }

  getUserRoleList() {
    this._masterTaskService.getUserRoleList({ page: 1, limit: 100 }).subscribe(
      (response: ResponseModel) => {
        this.userListing = response.data.items;
        let data = response.data.items.map((item) => {
          return {
            label: item.firstName + ' ' + item.lastName,
            value: item._id,
          };
        });
        this.userList = data;
        this.getCurrentUser();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getCurrentUser = () => {
    this._sharedService.getUser().subscribe(
      (response: ResponseModel) => {
        this.currentUser = response.data;
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  };

  onEditTaskDialog(event, taskObj) {
    if (!taskObj?.leadInfo && !taskObj?.propertyData) {
      taskObj.isGeneralTask = true;
    }
    taskObj.isCompleted = taskObj.hasOwnProperty('isCompleted')
      ? taskObj.isCompleted
      : false;

    let data = this.userListing.map((item) => {
      return {
        label: item.firstName + ' ' + item.lastName,
        value: item._id,
      };
    });

    let obj = {
      type: 'task',
      subType: 'task',
      category: event,
      task: taskObj,
      id: taskObj._id,
      userList: taskObj.isGeneralTask ? data : this.userListing,
      currentUser: this.currentUser,
      leadInfo: taskObj.leadInfo
        ? taskObj.leadInfo
        : taskObj.propertyData
        ? taskObj.propertyData
        : {},
      isGetTeams: taskObj.isGeneralTask ? false : true,
      isDisable: true,
      isLocalTask: this.moduleName == 'Local Tasks' ? true : false,
    };

    if (
      taskObj.module.title === 'buyers' ||
      taskObj.module.title === 'buyers'
    ) {
      obj['moduleType'] = 'Buyers';
      const name = taskObj.user.sellerName.split(' ') || [];
      obj['moduleData'] = {
        firstName: name?.[0] || '',
        lastName: name?.[1] || '',
      };
    }

    if (
      taskObj.module.title === 'vendor' ||
      taskObj.module.title === 'vendors'
    ) {
      obj['moduleType'] = 'Vendors';
      obj['moduleData'] = { name: taskObj?.user?.sellerName || '' };
    }

    this.dialogRef = this.dialog.open(AddEditTaskComponent, {
      width: '600px',
      data: obj,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._loaderService.start();
        delete result.mainStatusId;
        this._taskService.updateTask(result, taskObj._id).subscribe(
          (response: ResponseModel) => {
            if (response.statusCode == 200) {
              this._loaderService.stop();
              // this._toastrService.success(
              //   this.messageConstant.taskUpdatedSuccess,
              //   ''
              // );
              let index = 0;
              this._emitter.emit({
                module: 'task',
                type: 'list',
                index,
                result,
              });
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
      }
    });
  }

  onEditTaskTemplateDialog(event, taskObj) {
    this.dialogRef = this.dialog.open(AddEditTaskTemplateComponent, {
      width: '600px',
      data: {
        type: 'taskTemplate',
        subType: 'taskTemplate',
        category: event,
        task: taskObj,
        id: taskObj._id,
        userList: this.userListing,
        currentUser: this.currentUser,
        isDisable: true,
        isLocalTask: this.moduleName == 'Local Tasks' ? true : false,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          module: 'taskTemplate',
          type: 'update',
          result: result,
        });
      }
    });
  }

  onChangeSort(event) {
    let sortBy = '';
    if (event instanceof Object) {
      if (event.subKey) {
        sortBy = event.key + '.' + event.subKey;
      } else {
        sortBy = event.key;
      }
    } else {
      sortBy = event;
    }

    this.sort = this.sort === 'ASC' ? 'DESC' : 'ASC';
    let obj = {
      sortMode: this.sort === 'ASC' ? 1 : -1,
      sortBy: sortBy,
    };

    this.sortClass = this.sort === 'ASC' ? 're-sort re-asc' : 're-sort re-desc';
    this.sortVal = event;
    this._changeSort.emit(obj);
  }

  onChangePage(page) {
    this._changePage.emit(page);
  }

  onChangeLimit(limit) {
    this._changeLimit.emit(limit);
  }

  onDeleteFlow(type, data, index) {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: {
        subModule: type,
        subModuleId: this.id,
        moduleId: this.moduleId,
        details: data,
        index: index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result !== 'false') {
          this._emitter.emit({
            module: type,
            type: 'delete',
            index,
            result: data,
          });
        }
      }
    });
  }

  onDeleteDialog(type, data, index) {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: {
        subModule: type,
        subModuleId: this.id,
        moduleId: this.moduleId,
        details: data,
        index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          module: type,
          type: 'delete',
          index,
          result,
          details: data,
        });
        this._sharedService.refreshActivityLog.next(true);
      }
    });
  }

  onBlockNumberDeleteDialog(type, data, index) {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: {
        subModule: type,
        subModuleId: this.id,
        moduleId: this.moduleId,
        details: data,
        index,
        customMessage: 'Are you sure you want to delete blocked Number?',
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          module: type,
          type: 'delete',
          index,
          result,
          details: data,
        });
        this._sharedService.refreshActivityLog.next(true);
      }
    });
  }

  onDeleteNumberDialog(type, data, index) {
    this.dialogRef = this.dialog.open(ConfirmationNumberDialogComponent, {
      width: '525px',
      disableClose: true,
      data: {
        subModule: type,
        subModuleId: this.id,
        details: data,
        index: index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          module: 'lead_number',
          type: 'delete',
          index,
          result,
          details: data,
        });
        this._sharedService.refreshActivityLog.next(true);
        // this.dialogRef = this.dialog.open(DeleteDialogComponent, {
        //   width: '500px',
        //   data: {
        //     subModule: type,
        //     subModuleId: this.id,
        //     moduleId: this.moduleId,
        //     details: data,
        //     deleteNumber: data?.number,
        //     index: index,
        //     newNumberId: result,
        //   },
        // });
        // this.dialogRef.afterClosed().subscribe((result) => {
        //   if (result) {
        //     this._emitter.emit({ module: type, type: 'delete', index, result });
        //     this._sharedService.refreshActivityLog.next(true);
        //   }
        // });
      }
    });
  }

  deleteSubUser(userId, type, index, result) {
    let Obj = {
      subUserId: userId,
    };

    this._loaderService.start();
    this._userService.deleteSubUser(Obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._sharedService.refreshActivityLog.next(true);
          this._emitter.emit({ module: type, type: 'delete', index, result });
          this._toastrService.success(
            this.messageConstant.subUserDeleteSuccess
          );
        }
        this._loaderService.stop();
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  onDocDeleteDialog(type, data, index) {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: {
        subModule: type,
        subModuleId: this.id,
        moduleId: this.moduleId,
        details: data,
        index: index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._sharedService.refreshActivityLog.next(true);
        this._emitter.emit({
          module: type,
          type: 'delete',
          index,
          result,
          details: data,
        });
      }
    });
  }

  openAddEditFilesModal(type, data, index) {
    type = type.toLowerCase();
    this.dialogRef = this.dialog.open(AddEditFolderFilesModalComponent, {
      width: type === 'share' ? '450px' : '400px',
      disableClose: type === 'edit' ? true : false,
      data: {
        action: type,
        moduleId: this.moduleId,
        subModuleId: this.id,
        type: 'file',
        data,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({ module: 'file', type, index, result });
      }
    });
  }

  openAddEditLinksModal(type, data, index) {
    this.dialogRef = this.dialog.open(AddEditLinksComponent, {
      width: '600px',
      data: {
        action: type,
        subModule: 'Link',
        moduleId: this.moduleId,
        subModuleId: this.id,
        details: data,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({ module: 'link', type: 'edit', index, result });
      }
    });
  }

  redirectAction(action, redirectData) {
    this._toastrService.clear();
    if (redirectData?.isActionDisabled) {
      this._toastrService.error(
        '',
        redirectData.disabledDescription.replace('@ACTION', action.label)
      );
      return;
    }

    if (
      (redirectData?.isReadOnly &&
        (action?.label == 'Edit' || action?.label == 'Toggle')) ||
      (action?.isEnabled && !redirectData?.isEnabled)
    ) {
      this._toastrService.error(
        '',
        action?.disabledText.replace(
          '{{TYPE}}',
          `${redirectData.isReadOnly ? 'readonly' : 'disabled'}`
        )
      );
      return;
    }
    if (action?.type && action?.type == 'EDIT_SCRIPT') {
      localStorage.setItem('redirectData', JSON.stringify(redirectData));
    }
    const navigationDetails: string[] = [action?.route, redirectData['_id']];
    this._router.navigate(navigationDetails);
  }

  downloadFile(file) {
    const obj = {
      moduleId: file?.moduleId,
      src: file?.photo,
    };
    this._loaderService.start();
    this._sharedService.getFile(obj).subscribe(
      (response) => {
        if (response) {
          var blob = new Blob([response], {
            type: `image/${file?.fileType}`,
          });
          this._toastrService.success(this.messageConstant.fileDownloadSuccess);
          saveAs(blob, file?.title);
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(
            error.message ? error.message : this.messageConstant.unknownError,
            ''
          );
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  onAddEditRvmDialog(item, index) {
    this.dialogRef = this.dialog.open(AddEditRvmComponent, {
      width: '500px',
      panelClass: 'editRvm',
      data: {
        subModule:
          this.moduleName == 'Dialer Voicemail'
            ? 'Edit Dialer Voicemail Recording'
            : 'Edit RVM Recording',
        items: this.secondaryData,
        dataVal: item,
        moduleId: this.moduleId,
        urlType:
          this.moduleName == 'Dialer Voicemail'
            ? 'dialerVoiceMail'
            : 'ringlessvoicemail',
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({ module: 'rvm', type: 'edit', index, result });
      }
    });
  }

  onCopyEsignDialog(type, subtype, data, index) {
    this.dialogRef = this.dialog.open(CopyTemplateComponent, {
      width: '350px',
      data: {
        subModule: type,
        subModuleId: subtype,
        moduleId: data._id,
        details: data,
        index: index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({ module: type, type: 'copy', index, result });
      }
    });
  }

  onEditEsignDialog(type, data, index) {
    this._router.navigate([`/esign/createTemplate`], {
      queryParams: { id: data._id },
    });
  }

  onViewEsignDialog(type, data, index) {
    this._router.navigate([`/esign/viewDocument`], {
      queryParams: {
        id: data.folderId,
        type: this.esignType,
        leadId: data.leadId,
        propertyId: data.propertyId,
      },
    });
  }

  getInitials(user) {
    var initials = user.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  onDripDeleteDialog(type, data, index) {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: {
        subModule: type,
        subModuleId: type,
        moduleId: data._id,
        details: data,
        index: index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          module: type,
          type: 'delete',
          index,
          result,
          details: data,
        });
      }
    });
  }

  onDripCopyDialog(type, subtype, data, index) {
    this.dialogRef = this.dialog.open(CopyDripComponent, {
      width: '500px',
      data: {
        subModule: type,
        subModuleId: subtype,
        moduleId: data._id,
        details: data,
        index: index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({ module: type, type: subtype, index, result });
      }
    });
  }

  onFlowCopyDialog(type, subtype, data, index) {
    this.dialogRef = this.dialog.open(CopyCallFlowComponent, {
      width: '500px',
      data: {
        subModule: type,
        subModuleId: subtype,
        moduleId: data._id,
        details: data,
        index: index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({ module: type, type: subtype, index, result });
      }
    });
  }

  onEditAccount(item, index) {
    this._router.navigate([`/accounting/add-manual-account`], {
      queryParams: { ['id']: item._id },
    });
  }

  onEditVendor(data, index) {
    if (data._id == 'uncategory') {
      this._toastrService.error('You can not edit this vendor', '');
      return;
    }
    this.dialogRef = this.dialog.open(ModalAddVendorsComponent, {
      width: '650px',
      disableClose: true,
      data: {
        id: data._id,
        index: index,
        action: 'edit',
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      this._emitter.emit({
        module: this.moduleName,
        type: 'edit',
        index,
        result,
      });
    });
  }

  onEditAccountTag(data, index) {
    if (data.defaultAdmin) {
      this._toastrService.error('You can not edit this tag', '');
      return;
    }

    this.dialogRef = this.dialog.open(ModalAddAccountTagsComponent, {
      width: '650px',
      disableClose: true,
      data: {
        id: data._id,
        index: index,
        action: 'edit',
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      this._emitter.emit({
        module: 'accountTags',
        type: 'edit',
        index,
        result,
      });
    });
  }

  onEditTransaction(item, index) {
    if (item?.isJournalEntry) {
      if (this.filterTransactionTypeName == 'vendors') {
        this._router.navigate([`/accounting/add-journal-entry`], {
          queryParams: {
            ['id']: item._id,
            ['vid']: this.filterTransactionTypeId,
          },
        });
      } else if (this.filterTransactionTypeName == 'properties') {
        this._router.navigate([`/accounting/add-journal-entry`], {
          queryParams: {
            ['id']: item._id,
            ['pid']: this.filterTransactionTypeId,
          },
        });
      } else if (this.filterTransactionTypeName == 'accounttags') {
        if (item?.isOpeningBalance) {
          let link = `/accounting/opening-balance`;
          window.open(link, '_blank');
        } else {
          this._router.navigate([`/accounting/add-journal-entry`], {
            queryParams: {
              ['id']: item._id,
              ['aid']: this.filterTransactionTypeId,
            },
          });
        }
      } else {
        this._router.navigate([`/accounting/add-journal-entry`], {
          queryParams: { ['id']: item._id, ['bid']: item?.accountId },
        });
      }
    } else {
      this.utilities.currentAccountId = item.accountId;
      this.utilities.currentTransactionData = [];
      this.utilities.currentTransactionType = this.filterTransactionTypeName;
      this.utilities.currentTransactionTypeId = this.filterTransactionTypeId;
      this.utilities.currentTransactionIndex = index;

      this.data?.items?.map((item) => {
        this.utilities.currentTransactionData.push({
          _id: item._id,
          isJournalEntry: item?.isJournalEntry,
        });
      });
      if (this.filterTransactionTypeName == 'vendors') {
        if (item?.isMatchTransaction) {
          this._router.navigate([`/accounting/add-credit-card-transaction`], {
            queryParams: {
              ['id']: item._id,
              ['accountId']: item.accountId,
              ['vid']: this.filterTransactionTypeId,
            },
          });
        } else {
          this._router.navigate([`/accounting/add-transaction`], {
            queryParams: {
              ['id']: item._id,
              ['accountId']: item.accountId,
              ['vid']: this.filterTransactionTypeId,
            },
          });
        }
      } else if (this.filterTransactionTypeName == 'properties') {
        if (item?.isMatchTransaction) {
          this._router.navigate([`/accounting/add-credit-card-transaction`], {
            queryParams: {
              ['id']: item._id,
              ['accountId']: item.accountId,
              ['pid']: this.filterTransactionTypeId,
            },
          });
        } else {
          this._router.navigate([`/accounting/add-transaction`], {
            queryParams: {
              ['id']: item._id,
              ['accountId']: item.accountId,
              ['pid']: this.filterTransactionTypeId,
            },
          });
        }
      } else if (this.filterTransactionTypeName == 'accounttags') {
        if (item?.isOpeningBalance) {
          let link = `/accounting/opening-balance`;
          window.open(link, '_blank');
        } else if (item?.isMatchTransaction) {
          this._router.navigate([`/accounting/add-credit-card-transaction`], {
            queryParams: {
              ['id']: item._id,
              ['accountId']: item.accountId,
              ['aid']: this.filterTransactionTypeId,
            },
          });
        } else {
          this._router.navigate([`/accounting/add-transaction`], {
            queryParams: {
              ['id']: item._id,
              ['accountId']: item.accountId,
              ['aid']: this.filterTransactionTypeId,
            },
          });
        }
      } else {
        this.utilities.currentTransactionType = item?.tempType;

        if (item?.tempType == 'mapped') {
          this.utilities.currentTransactionPage = this.utilities.currentMappedPage;
        } else if (item?.tempType == 'recognized') {
          this.utilities.currentTransactionPage = this.utilities.currentRecognizedPage;
        } else if (item?.tempType == 'unrecognized') {
          this.utilities.currentTransactionPage = this.utilities.currentUnrecognizedPage;
        }

        if (item?.isMatchTransaction && item?.recognized) {
          this._router.navigate([`/accounting/add-credit-card-transaction`], {
            queryParams: { ['id']: item._id, ['accountId']: item.accountId },
          });
        } else {
          this._router.navigate([`/accounting/add-transaction`], {
            queryParams: { ['id']: item._id, ['accountId']: item.accountId },
          });
        }
      }
    }
  }

  onTransactionList(item, index, isGlobalSearch?) {
    if (this.moduleName.toLowerCase() == 'accounttags') {
      this.utilities.filterTransaction = {};
      this.utilities.sortTransaction = null;
      this.utilities.isFilterTransaction = false;
      if (isGlobalSearch) {
        const url = this._router.serializeUrl(
          this._router.createUrlTree([`/accounting/filter-transaction-list`], {
            queryParams: {
              ['id']: item._id,
              ['type']: this.moduleName.toLowerCase(),
            },
          })
        );
        window.open(url, '_blank');
      } else {
        this._router.navigate([`/accounting/filter-transaction-list`], {
          queryParams: {
            ['id']: item._id,
            ['type']: this.moduleName.toLowerCase(),
          },
        });
      }
    } else if (this.moduleName.toLowerCase() == 'vendors') {
      this.utilities.filterTransaction = {};
      this.utilities.sortTransaction = null;
      this.utilities.isFilterTransaction = false;
      this._router.navigate([`/accounting/filter-transaction-list`], {
        queryParams: {
          ['id']: item._id,
          ['type']: this.moduleName.toLowerCase(),
        },
      });
    } else if (this.moduleName.toLowerCase() == 'properties') {
      this.utilities.filterTransaction = {};
      this.utilities.sortTransaction = null;
      this.utilities.isFilterTransaction = false;
      if (isGlobalSearch) {
        const url = this._router.serializeUrl(
          this._router.createUrlTree([`/accounting/filter-transaction-list`], {
            queryParams: {
              ['id']: item._id,
              ['type']: this.moduleName.toLowerCase(),
            },
          })
        );
        window.open(url, '_blank');
      } else {
        this._router.navigate([`/accounting/filter-transaction-list`], {
          queryParams: {
            ['id']: item._id,
            ['type']: this.moduleName.toLowerCase(),
          },
        });
      }
    } else {
      this.utilities.currentUnrecognizedPage = 1;
      this.utilities.currentRecognizedPage = 1;
      this.utilities.currentMappedPage = 1;
      this.utilities.currentTransactionTab = item?.isManual
        ? 'mapped'
        : 'unrecognized';
      this._router.navigate([`/accounting/transaction-list`], {
        queryParams: { ['id']: item._id },
      });
    }
  }

  onEditSubUser(item, index, bool?) {
    let url = `/users/user-add`;

    this._router.navigate([url], {
      queryParams: { ['id']: item._id },
    });
  }

  onDocumentAddress(item, index) {
    if (item.leadId) {
      this._router.navigate([`/leads/details`], {
        queryParams: { ['leadsId']: item.leadId },
      });
    } else {
      this._router.navigate([`/inventory/details`], {
        queryParams: { ['inventoryId']: item.propertyId },
      });
    }
  }

  onFavoriteLead(item, isGlobalSearch?) {
    let type = this.moduleName.toLowerCase();
    let module = type;
    let id = item._id;
    if (!id) {
      return;
    }
    if (item?.type == 'Inventory') {
      module = 'inventory';
      id = item?.propertiesId;
    } else if (item?.type == 'Lead') {
      module = 'leads';
    } else if (item?.type == 'Sold') {
      module = 'sold';
      id = item?.propertiesId;
    } else if (item?.type == 'Rental') {
      module = 'rental';
      id = item?.propertiesId;
    }

    if (type === 'contingencies' && item?.subModuleId?._id) {
      module = 'leads';
      id = item?.subModuleId?._id;
    }

    const key = module + 'Id';
    let obj = { [key]: id };

    if (isGlobalSearch) {
      const url = this._router.serializeUrl(
        this._router.createUrlTree([`/${module}/details`], { queryParams: obj })
      );
      window.open(url, '_blank');
    } else {
      this._router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this._router.navigate([`/${module}/details`], {
          queryParams: obj,
        })
      );
    }
  }

  onEditCard(data, index) {
    if (data.defaultAdmin) {
      this._toastrService.error('You can not edit this tag', '');
      return;
    }

    this.dialogRef = this.dialog.open(AddCardDialogComponent, {
      width: '650px',
      disableClose: true,
      data: {
        item: data,
        index: index,
        action: 'edit',
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      this._emitter.emit({
        module: 'cards',
        type: 'edit',
        index,
        result,
      });
    });
  }

  onDeleteCardDialog(type, data, index) {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: {
        subModule: type,
        subModuleId: type,
        moduleId: data._id,
        details: data,
        index: index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          module: type,
          type: 'delete',
          index,
          result,
          details: data,
        });
      }
    });
  }

  cardRadioChange(val, item) {
    if (val.target.value == 'true') {
      this._emitter.emit({
        module: 'cards',
        type: 'setDefaultCard',
        item: item,
      });
    }
  }

  onMainPage(item) {
    if (item?.type == 'Inventory') {
      var type = 'inventory';
    } else if (item?.type == 'Lead') {
      var type = 'leads';
    } else if (item?.type == 'Sold') {
      var type = 'sold';
    } else if (item?.type == 'Rental') {
      var type = 'rental';
    }
    this._router.navigate([type]);
  }

  onInventoryDetails(item, index) {
    this._inventoryDetailsEmitter.emit(item);
  }

  async getDetailsCall(item) {
    if (!item.leadId) {
      return;
    }
    const mainObj = { leadid: item.leadId };
    const status = this.moduleListData.filter(
      (x) => x._id === item.moduleId
    )[0];

    this._loaderService.start();
    if (status?.title == 'lead') {
      const obj = {
        page: 1,
        limit: this.currentLimit,
        moduleId: status?.moduleId,
      };
      this._sharedService.getMainStatus(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            let mainStatusData = response.data['items'];
            this._leadservice.getProjectWithLeadId(mainObj).subscribe(
              (response: ResponseModel) => {
                if (!response.data?.items) {
                  const obj = {
                    leadId: item.leadId,
                  };
                  this._leadservice.leadInfo(obj).subscribe(
                    (responseLead: ResponseModel) => {
                      if (
                        responseLead?.data?.leadData?._id &&
                        responseLead?.data?.leadData?.isDeleted != true
                      ) {
                        // const key = 'leadsId';
                        // this._router.navigate([`/leads/details`], {
                        //   queryParams: { [key]: item.leadId },
                        // });
                        window.open(
                          `/leads/details?leadsId=${item.leadId}`,
                          '_blank'
                        );
                        this._loaderService.stop();
                        return;
                      } else {
                        this._loaderService.stop();
                        this._toastrService.error(
                          this.messageConstant.leadDoseNotExist,
                          ''
                        );
                      }
                    },
                    (err: ErrorModel) => {}
                  );
                } else {
                  const { mainStatusId, _id } = response.data?.items;
                  const mainStatus = mainStatusData.filter(
                    (x) => x._id === mainStatusId
                  )[0];
                  if (mainStatus?.title == 'sold') {
                    // this._router.navigate([`/sold/details`], {
                    //   queryParams: { ['soldId']: _id },
                    // });
                    window.open(`/sold/details?soldId=${_id}`, '_blank');
                  } else if (mainStatus?.title == 'rental') {
                    // this._router.navigate([`/rental/details`], {
                    //   queryParams: { ['rentalId']: _id },
                    // });
                    window.open(`/rental/details?rentalId=${_id}`, '_blank');
                  } else {
                    // this._router.navigate([`/inventory/details`], {
                    //   queryParams: { ['inventoryId']: _id },
                    // });
                    window.open(
                      `/inventory/details?inventoryId=${_id}`,
                      '_blank'
                    );
                  }
                  this._loaderService.stop();
                }
              },
              (error: any) => {}
            );
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
    } else if (status?.title == 'buyers') {
      let obj = {
        id: item?.leadId,
      };
      this._buyerService.buyerDetails(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            if (response?.data?.id && response?.data?.isDeleted != true) {
              window.open(
                `/buyers/details?buyersId=${item.leadId}&contact=1`,
                '_blank'
              );
              this._loaderService.stop();
              return;
            } else {
              this._loaderService.stop();
              this._toastrService.error(
                this.messageConstant.buyerDoseNotExist,
                ''
              );
            }
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
        }
      );
    } else if (status?.title == 'vendor') {
      let obj = {
        vendorId: item?.leadId,
      };
      this._vendorService.vendorDetails(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            if (response?.data?.id && response?.data?.isDeleted != true) {
              window.open(
                `/vendors/details?vendorsId=${item.leadId}`,
                '_blank'
              );
              this._loaderService.stop();
              return;
            } else {
              this._loaderService.stop();
              this._toastrService.error(
                this.messageConstant.vendorDoseNotExist,
                ''
              );
            }
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
        }
      );
    }
  }

  getModules() {
    const data = { page: this.currentPage, limit: 50 };
    this._sharedService.getAllModules(data).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.moduleListData = response.data.items;
          let underMaintenance = false;
          this.moduleListData.forEach((element) => {
            if (element.isEnabled && element.isUnderMaintenance) {
              underMaintenance = true;
            }
          });
          if (underMaintenance) {
            this._underMaintenanceView.emit(underMaintenance);
          }
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  fixAccount(item) {
    this._fixAccount.emit(item);
  }

  getRelatedDetails(item) {
    if (
      item?.mainStatusId == StatusConstant.LeadStatus.NEW_LEAD ||
      item?.mainStatusId == StatusConstant.LeadStatus.DUE_DILIGENCE ||
      item?.mainStatusId == StatusConstant.LeadStatus.NO_CONTACT_MADE ||
      item?.mainStatusId == StatusConstant.LeadStatus.CONTACT_MADE ||
      item?.mainStatusId == StatusConstant.LeadStatus.APPOINTMENT_SET ||
      item?.mainStatusId == StatusConstant.LeadStatus.OFFER_MADE ||
      item?.mainStatusId == StatusConstant.LeadStatus.UNDER_CONTRACT ||
      item?.mainStatusId == StatusConstant.LeadStatus.ASSIGN_TO_BUYER ||
      item?.mainStatusId == StatusConstant.LeadStatus.DEAD_LEAD ||
      item?.mainStatusId == StatusConstant.LeadStatus.WARM_LEAD ||
      item?.mainStatusId == StatusConstant.LeadStatus.REFERRED_TO_AGENT
    ) {
      localStorage.setItem('RE-TAB', 'lead-details');
      this._router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this._router.navigate([`/leads/details`], {
          queryParams: { ['leadsId']: item?._id },
        })
      );
    } else if (
      item?.propertyData?.mainStatusId == StatusConstant.InventoryStatus.SOLD
    ) {
      localStorage.setItem('RE-TAB', 'project-info');
      this._router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this._router.navigate([`/sold/details`], {
          queryParams: { ['soldId']: item?.propertyData?.propertyId },
        })
      );
    } else if (
      item?.propertyData?.mainStatusId == StatusConstant.InventoryStatus.RENTAL
    ) {
      localStorage.setItem('RE-TAB', 'project-info');
      this._router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this._router.navigate([`/rental/details`], {
          queryParams: { ['rentalId']: item?.propertyData?.propertyId },
        })
      );
    } else {
      localStorage.setItem('RE-TAB', 'project-info');
      this._router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this._router.navigate([`/inventory/details`], {
          queryParams: { ['inventoryId']: item?.propertyData?.propertyId },
        })
      );
    }
  }

  onDeleteTaskDialog = (task): void => {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: {
        header: 'Confirmation',
        text: `Are you sure you want to delete task`,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._loaderService.start();
        this._taskService.deleteTask(task._id).subscribe(
          (response: ResponseModel) => {
            if (response.statusCode == 200) {
              this._sharedService.refreshInfo.next(true);
              this._sharedService.refreshActivityLog.next(true);
              this._loaderService.stop();
              // this._toastrService.success(
              //   this.messageConstant.taskDeletedSuccess,
              //   ''
              // );
              this._emitter.emit({
                module: 'Local Tasks',
                type: 'list',
                index: 0,
                result,
              });
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
      }
    });
  };

  onEditLocalTaskDialog(event, taskObj, index) {
    taskObj.isCompleted = taskObj.hasOwnProperty('isCompleted')
      ? taskObj.isCompleted
      : false;
    this.dialogRef = this.dialog.open(AddEditTaskComponent, {
      width: '600px',
      data: {
        type: 'task',
        subType: 'task',
        category: event,
        task: taskObj,
        id: taskObj._id,
        userList: this.userListing,
        currentUser: taskObj.user,
        leadInfo: taskObj.leadInfo ? taskObj.leadInfo : taskObj.propertyData,
        isLocalTask: true,
        isGetTeams: false,
        isGeneralTask: true,
        isDisable: true,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._loaderService.start();
        delete result['mainStatusId'];
        this._taskService.updateTask(result, taskObj._id).subscribe(
          (response: ResponseModel) => {
            if (response.statusCode == 200) {
              this._sharedService.refreshActivityLog.next('task');
              this._loaderService.stop();
              // this._toastrService.success(
              //   this.messageConstant.taskUpdatedSuccess,
              //   ''
              // );
              this._emitter.emit({
                module: 'Local Tasks',
                type: 'edit',
                index,
                result,
              });
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
      }
    });
  }

  onScroll() {
    if (this.paginationType !== MiscellaneousConstant.paginationType.INFINITE)
      return;

    this.currentPage += 1;
    this._changePage.emit(this.currentPage);
  }

  checkLoader() {
    if (this.paginationType === MiscellaneousConstant.paginationType.INFINITE)
      return true;

    return !this._sharedService.checkLoader(this.moduleName);
  }

  reset(moduleName) {
    this.currentPage = 1;
    this.moduleName = moduleName;
  }

  onDeleteSMSScheduledDialog(type, data, index) {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: {
        subModule: type,
        subModuleId: this.id,
        moduleId: this.moduleId,
        details: data,
        index: index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          module: type,
          type: 'delete',
          index,
          result,
          details: data,
        });
      }
    });
  }

  onEditScheduledSMS(data, index) {
    this._loaderService.start();
    let responseBuyer;
    let contact = [];
    if (data.leadInfo && !data?.lsData) {
      if (!data.buyerId) {
        if (data.leadInfo?.secondaryContact.length <= 0) {
          if (
            data.leadInfo?.email ||
            data.leadInfo?.title ||
            data.leadInfo?.phoneNumber
          ) {
            contact.push({
              email: data.leadInfo?.email,
              name: data.leadInfo?.title,
              phoneNumber: data.leadInfo?.phoneNumber,
              contactType: 'seller',
              isOwnerContact: true,
              numberType: data.leadInfo?.numberType,
            });
          }
        } else {
          let contactSelected = data.leadInfo?.secondaryContact.filter(
            (item) => {
              if (item.phoneNumber == data.sendTo) {
                return item;
              }
            }
          );
          if (contactSelected.length > 0) {
            contact = contactSelected;
          } else {
            contact.push({
              email: data.leadInfo?.email,
              name: data.leadInfo?.title,
              phoneNumber: data.leadInfo?.phoneNumber,
              contactType: 'seller',
              isOwnerContact: true,
              numberType: data.leadInfo?.numberType,
            });
          }
        }
      } else {
        // this._leadservice.leadInfo(obj).subscribe(

        this._leadservice.leadInfo({ leadId: data.leadInfo._id }).subscribe(
          (response: ResponseModel) => {
            if (response.statusCode && response.statusCode == 200) {
              this.propertyInfoList = response.data.propertyData;
              if (data.buyerId) {
                let contactSelected = response.data.leadData.buyers.filter(
                  (item) => {
                    if (item.contactNumber == data.sendTo) {
                      return item;
                    }
                  }
                );
                contactSelected.map((element) => {
                  contact.push({
                    email: element.email,
                    name: `${element.firstName} ${
                      element.lastName ? element.lastName : ''
                    }`,
                    phoneNumber: element.contactNumber,
                    contactType: element.contactType,
                    isOwnerContact: true,
                    numberType: element.numberType,
                  });
                });
              }
            } else {
              this._toastrService.error(
                this.messageConstant.unknownError,
                'Error'
              );
              this._loaderService.stop();
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
      }
    } else if (data.propertyData) {
      if (!data.buyerId) {
        this._leadservice
          .leadInfo({ leadId: data.propertyData?.leadId?._id })
          .subscribe(
            (response: ResponseModel) => {
              if (response.statusCode && response.statusCode == 200) {
                this.propertyInfoList = response.data.leadData;

                if (this.propertyInfoList?.secondaryContact.length <= 0) {
                  if (
                    this.propertyInfoList?.email ||
                    this.propertyInfoList?.title ||
                    this.propertyInfoList?.phoneNumber
                  ) {
                    contact.push({
                      email: this.propertyInfoList?.email,
                      name: this.propertyInfoList?.title,
                      phoneNumber: this.propertyInfoList?.phoneNumber,
                      contactType: 'seller',
                      isOwnerContact: true,
                      numberType: this.propertyInfoList?.numberType,
                    });
                  }
                } else {
                  let contactSelected = this.propertyInfoList?.secondaryContact.filter(
                    (item) => {
                      if (item.phoneNumber == data.sendTo) {
                        return item;
                      }
                    }
                  );
                  if (contactSelected.length > 0) {
                    contact = contactSelected;
                  } else {
                    contact.push({
                      email: this.propertyInfoList?.email,
                      name: this.propertyInfoList?.title,
                      phoneNumber: this.propertyInfoList?.phoneNumber,
                      contactType: 'seller',
                      isOwnerContact: true,
                      numberType: this.propertyInfoList?.numberType,
                    });
                  }
                }
              } else {
                this._toastrService.error(
                  this.messageConstant.unknownError,
                  'Error'
                );
                this._loaderService.stop();
              }
            },
            (err: ErrorModel) => {
              this._loaderService.stop();
              if (err.error) {
                const error: ResponseModel = err.error;
                this._toastrService.error(error.message, '');
              } else {
                this._toastrService.error(
                  this.messageConstant.unknownError,
                  ''
                );
              }
            }
          );
      } else {
        const obj = {
          inventoryId: data.propertyData._id,
        };
        this._inventoryService.projectInfo(obj).subscribe(
          (response: ResponseModel) => {
            if (response.statusCode && response.statusCode == 200) {
              this.propertyInfoList = response.data.propertyData;
              if (data.buyerId) {
                let contactSelected = response.data.propertyData.buyers.filter(
                  (item) => {
                    if (item.contactNumber == data.sendTo) {
                      return item;
                    }
                  }
                );
                contactSelected.map((element) => {
                  contact.push({
                    email: element.email,
                    name: element.firstName + ' ' + element.lastName,
                    phoneNumber: element.contactNumber,
                    contactType: element.contactType,
                    isOwnerContact: true,
                    numberType: element.numberType,
                  });
                });
              }
            } else {
              this._toastrService.error(
                this.messageConstant.unknownError,
                'Error'
              );
              this._loaderService.stop();
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
      }
    } else if (data?.lsData) {
      contact.push({
        email: '',
        name: data?.lsData?.Full_Name ? data?.lsData?.Full_Name : '',
        phoneNumber: data.sendTo,
      });
      this._creditService.getSMSCredit({}).subscribe(
        (response: ResponseModel) => {
          this.moduleId = this.moduleListData.filter(
            (x) => x.title === 'liststacking'
          )[0]._id;

          this._loaderService.stop();
          if (response?.statusCode && response?.statusCode == 200) {
            let SMSBalance = response?.data;
            let obj = {
              contact: contact,
              phoneNumber: contact[0].phoneNumber,
              leadSource: data.sendFrom,
              dialerCampaignId: data.dialerCampaignId,
              address: data.leadInfo?.address,
              contacts: contact,
              leadId: data.leadInfo?._id,
              moduleId: this.moduleId,
              type: 'scheduledSMS',
              smsTemplateId: data.smsTemplateId,
              smsTemplateBody: data.smsTemplateBody,
              dueInIso: data.dueInIso,
              timezone: data.timezone,
              scheduledSmsId: data._id,
              attachments: data.attachments,
              teams: {},
              SMSBalance,
              moduleTypeEdit: data.moduleType,
            };

            this.dialogRef = this.dialog.open(SmsDialogComponent, {
              width: '600px',
              data: obj,
            });
            this.dialogRef.afterClosed().subscribe((result) => {
              if (result) {
                this._emitter.emit({
                  module: 'Scheduled SMS',
                  type: 'edit',
                  index,
                  result,
                });
              }
            });
          } else {
            this._toastrService.error(response?.message, '');
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
    } else if (data?.vendor) {
      contact.push({
        email: '',
        name: `${data?.vendor.firstName} ${
          data?.vendor?.lastName ? data?.vendor?.lastName : ''
        }`,
        phoneNumber: data.sendTo,
      });
      this._creditService.getSMSCredit({}).subscribe(
        (response: ResponseModel) => {
          this.moduleId = this.moduleListData.filter(
            (x) => x.title === 'liststacking'
          )[0]._id;

          this._loaderService.stop();
          if (response?.statusCode && response?.statusCode == 200) {
            let SMSBalance = response?.data;
            let obj = {
              contact: contact,
              phoneNumber: contact[0].phoneNumber,
              leadSource: data.sendFrom,
              dialerCampaignId: data.dialerCampaignId,
              address: data?.vendor?.address,
              contacts: contact,
              vendorId: data.vendor?._id,
              moduleId: this.moduleId,
              type: 'scheduledSMS',
              smsTemplateId: data.smsTemplateId,
              smsTemplateBody: data.smsTemplateBody,
              dueInIso: data.dueInIso,
              timezone: data.timezone,
              scheduledSmsId: data._id,
              attachments: data.attachments,
              teams: {},
              SMSBalance,
              moduleTypeEdit: data.moduleType,
            };

            this.dialogRef = this.dialog.open(SmsDialogComponent, {
              width: '600px',
              data: obj,
            });
            this.dialogRef.afterClosed().subscribe((result) => {
              if (result) {
                this._emitter.emit({
                  module: 'Scheduled SMS',
                  type: 'edit',
                  index,
                  result,
                });
              }
            });
          } else {
            this._toastrService.error(response?.message, '');
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
    } else {
      let obj = {
        id: data.buyerId,
      };
      this._buyerService.buyerDetails(obj).subscribe(
        (responseBuyer: ResponseModel) => {
          if (responseBuyer.statusCode == 200) {
            this.buyers = [responseBuyer.data];

            let contactSelected = this.buyers.filter((item) => {
              if (item.contactNumber == data.sendTo) {
                return item;
              }
            });
            contactSelected.map((element) => {
              contact.push({
                email: element.email,
                name: `${element.firstName} ${
                  element.lastName ? element.lastName : ''
                }`,
                phoneNumber: element.contactNumber,
                contactType: element.contactType,
                isOwnerContact: true,
                numberType: element.numberType,
              });
            });

            this._creditService.getSMSCredit({}).subscribe(
              (response: ResponseModel) => {
                let leadId;
                if (data.leadInfo || data.propertyData) {
                  leadId = data.leadInfo
                    ? data.leadInfo?._id
                    : data.propertyData?.leadId?._id;
                } else {
                  if (this.buyers[0].propertyIds.length > 0) {
                    leadId = this.buyers[0].propertyIds[0];
                  } else {
                    leadId = this.buyers[0].leadIds[0];
                  }
                }
                if (data.leadInfo) {
                  this.moduleId = this.moduleListData.filter(
                    (x) => x.title === 'lead'
                  )[0]._id;
                } else if (data.propertyData) {
                  this.moduleId = this.moduleListData.filter(
                    (x) => x.title === 'inventory'
                  )[0]._id;
                } else {
                  this.moduleId = this.moduleListData.filter(
                    (x) => x.title === 'buyers'
                  )[0]._id;
                }

                this._loaderService.stop();
                if (response?.statusCode && response?.statusCode == 200) {
                  let SMSBalance = response?.data;
                  let obj = {
                    contact: contact,
                    phoneNumber: contact[0].phoneNumber,
                    leadSource: data.sendFrom,
                    address: data.leadInfo?.address,
                    contacts: contact,
                    leadId: data.leadInfo?._id,
                    moduleId: this.moduleId,
                    type: 'scheduledSMS',
                    smsTemplateId: data.smsTemplateId,
                    smsTemplateBody: data.smsTemplateBody,
                    dueInIso: data.dueInIso,
                    timezone: data.timezone,
                    scheduledSmsId: data._id,
                    attachments: data.attachments,
                    teams: {},
                    SMSBalance,
                  };

                  this.dialogRef = this.dialog.open(SmsDialogComponent, {
                    width: '600px',
                    data: obj,
                  });
                  this.dialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                      this._emitter.emit({
                        module: 'Scheduled SMS',
                        type: 'edit',
                        index,
                        result,
                      });
                    }
                  });
                } else {
                  this._toastrService.error(response?.message, '');
                }
              },
              (err: ErrorModel) => {
                this._loaderService.stop();
                if (err.error) {
                  const error: ResponseModel = err.error;
                  this._toastrService.error(error.message, '');
                } else {
                  this._toastrService.error(
                    this.messageConstant.unknownError,
                    ''
                  );
                }
              }
            );
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
    }
    if (
      (data.buyerId && data.leadInfo) ||
      (data.buyerId && data.propertyData) ||
      !data.buyerId
    ) {
      this._creditService.getSMSCredit({}).subscribe(
        (response: ResponseModel) => {
          let leadId;
          if (data.leadInfo || data.propertyData) {
            leadId = data.leadInfo
              ? data.leadInfo?._id
              : data.propertyData?.leadId?._id;
          } else {
            if (this.buyers[0].propertyIds.length > 0) {
              leadId = this.buyers[0].inventoryDetails[0].leadId;
            } else {
              leadId = this.buyers[0].leadIds[0];
            }
          }
          // let leadId = data.leadInfo ? data.leadInfo?._id : data.propertyData?.leadId;
          this._leadservice.getTeams({ leadId: leadId }).subscribe(
            (responseTeams: ResponseModel) => {
              if (responseTeams.statusCode && responseTeams.statusCode == 200) {
                if (data.leadInfo) {
                  this.moduleId = this.moduleListData.filter(
                    (x) => x.title === 'lead'
                  )[0]._id;
                } else if (data.propertyData) {
                  this.moduleId = this.moduleListData.filter(
                    (x) => x.title === 'inventory'
                  )[0]._id;
                }

                this._loaderService.stop();
                if (response?.statusCode && response?.statusCode == 200) {
                  let SMSBalance = response?.data;
                  let obj = {
                    contact: contact,
                    phoneNumber: contact[0].phoneNumber,
                    leadSource: data.sendFrom,
                    address: data.leadInfo?.address,
                    contacts: contact,
                    leadId: data.leadInfo?._id,
                    moduleId: this.moduleId,
                    type: 'scheduledSMS',
                    smsTemplateId: data.smsTemplateId,
                    smsTemplateBody: data.smsTemplateBody,
                    dueInIso: data.dueInIso,
                    timezone: data.timezone,
                    scheduledSmsId: data._id,
                    attachments: data.attachments,
                    teams: responseTeams.data.items,
                    SMSBalance,
                  };

                  this.dialogRef = this.dialog.open(SmsDialogComponent, {
                    width: '600px',
                    data: obj,
                  });
                  this.dialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                      this._emitter.emit({
                        module: 'Scheduled SMS',
                        type: 'edit',
                        index,
                        result,
                      });
                    }
                  });
                } else {
                  this._toastrService.error(response?.message, '');
                }
              }
            },
            (err: ErrorModel) => {
              this._loaderService.stop();
              if (err.error) {
                const error: ResponseModel = err.error;
                // this._toastrService.error(error.message, '');
              } else {
                this._toastrService.error(
                  this.messageConstant.unknownError,
                  ''
                );
              }
            }
          );
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
    }
  }

  onEditJournalEntry(item, index) {
    this._router.navigate([`/accounting/add-journal-entry`], {
      queryParams: { ['id']: item._id },
    });
  }

  onDuplicateJournalEntry(item, index) {
    this._router.navigate([`/accounting/add-journal-entry`], {
      queryParams: { ['id']: item._id, ['type']: 'new' },
    });
  }

  onInfoToaster(type, data, index) {
    let infoMessage = '';
    if (type == 'Market' && data.isReadOnly == true) {
      infoMessage = this.messageConstant.deletePrimaryMarket;
    } else if (type == 'Market' && data.isReadOnly == false) {
      infoMessage = this.messageConstant.deleteMarket;
    } else if (type == 'Member' && data.isSubUser == false) {
      infoMessage = this.messageConstant.deleteMainUser;
    } else if (type == 'Member' && data.isSubUser == true) {
      infoMessage = this.messageConstant.deleteUser;
    }
    this._toastrService.info(infoMessage, '', {
      enableHtml: true,
    });
  }

  renameTagList(data, mode) {
    this.dialogRef = this.dialog.open(RenameTagComponent, {
      width: '500px',
      data: {
        _id: data?._id,
        label: data?.name,
        for: mode,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit();
      }
    });
  }

  onDeleteTagListDialog(data, mode) {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        subModule: mode,
        for: 'stacking',
        details: { _id: data?._id, filter: this.secondaryData },
      },
      height: 'auto',
      width: '500px',
      panelClass: 'device-modal',
      disableClose: true,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          subModule: mode,
          type: 'delete',
          details: { _id: data?._id, filter: this.secondaryData },
        });
      }
    });
  }

  acceptInterestedBuyer(item, index) {
    let header = 'Accept Offer';
    let text = 'Interested buyer will also be added to Buyers section.';
    if (item?.status) {
      header = 'Reject Offer';
      text = 'Interested buyer will be removed from Buyers section.';
    }

    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: {
        header,
        yesTitle: 'Confirm',
        noTitle: 'Cancel',
        text,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.acceptOffer(item, item?.status ? '' : 'true');
      }
    });
  }

  editInterestedBuyer(item, index) {
    this.dialogRef = this.dialog.open(AddEditInterestedBuyersComponent, {
      width: '620px',
      data: {
        leadId: this.id,
        item,
        buyerIds: this.data?.buyerIds,
        interestedBuyerList: this.data.items,
        moduleId: this.moduleId,
        action: 'EDIT',
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.isEdit)
          this._emitter.emit({
            module: 'DISPO_DETAIL',
          });
        else this.acceptOffer(item, index);
      }
    });
  }

  editInquiry(item, index) {
    this.dialogRef = this.dialog.open(AddEditInquiryComponent, {
      width: '620px',
      data: {
        leadId: this.id,
        item,
        action: 'EDIT',
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          module: 'DISPO_DETAIL',
        });
      }
    });
  }

  editInterested(item, index) {
    this.dialogRef = this.dialog.open(AddEditInterestedComponent, {
      width: '620px',
      data: {
        leadId: this.id,
        item,
        action: 'EDIT',
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          module: 'DISPO_DETAIL',
        });
      }
    });
  }

  deleteInterestedBuyer(item, index) {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: {
        subModule: 'offer',
        details: item,
        action: 'INTERESTED_BUYER',
        leadId: this.id,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          module: 'INTERESTED_BUYER',
          details: item,
        });
      }
    });
  }

  deleteInquiry(item, index) {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: {
        subModule: 'inquiry',
        details: item,
        action: 'INTERESTED_BUYER',
        leadId: this.id,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          module: 'DELETE_INQUIRY',
          details: { ...item, leadId: this.id },
        });
      }
    });
  }

  deleteInterested(item, index) {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: {
        subModule: 'interested buyer',
        details: item,
        action: 'INTERESTED_BUYER',
        leadId: this.id,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({
          module: 'DELETE_INTERESTED',
          details: { ...item, leadId: this.id },
        });
      }
    });
  }

  acceptOffer(item, status) {
    const {
      purchasePrice,
      emd,
      contingencies,
      closingDate,
      buyerId,
      paymentMethod,
    } = item;

    let obj = {
      leadId: this.id,
      purchasePrice: purchasePrice || 0,
      emd: emd || 0,
      contingencies,
      closingDate: closingDate || 0,
      status,
      isAdd: true,
      buyerId,
      paymentMethod,
    };

    this._loaderService.start();
    this._buyerService.addInterestedBuyer(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          this._loaderService.stop();
          this._emitter.emit({
            module: 'DISPO_DETAIL',
          });
          if (!status) {
            this._toastrService.success(
              this.messageConstant.offerRejectedSuccess
            );
          } else {
            this._toastrService.success(
              this.messageConstant.offerAcceptSuccess
            );
          }
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  openJournaEntry(item) {
    this._router.navigate([`/accounting/add-journal-entry`], {
      queryParams: {
        ['id']: item?.journalData?._id,
        ['bid']: item?.accountId,
      },
    });
    // window.open(`/accounting/add-journal-entry?id=${item?.journalData?._id}`, '_blank');
  }

  renderTooltip(action, item) {
    if (!action?.isTooltipExists) return;

    if (action?.checkActive && item[action?.activeCheckKey]) {
      return action.activeLabel;
    } else {
      return action?.label;
    }
  }

  onMailTemplateCopyDialog(item) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: {
        header: 'Confirmation',
        text: `Are you sure you want to copy this Email Template?`,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._loaderService.start();
        this._sharedService.copyEmail({ mailTemplateId: item?._id }).subscribe(
          (response: ResponseModel) => {
            this._loaderService.stop();
            if (response.statusCode == 200) {
              this._emitter.emit({
                module: 'email',
              });
              this._toastrService.success(
                this.messageConstant.mailTemplateCopySuccess,
                ''
              );
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
      }
    });
  }

  checkCallRecord(id, val, index) {
    let obj = {
      isOutboundCallRecord: !val,
      numberId: id,
    };
    this._sharedService.updateCallRecord(obj).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode === 200) {
          this.data['items'][index].isOutboundCallRecord = !val;
          this._toastrService.success(
            this.messageConstant.numberUpdatedSuccess,
            ''
          );
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  checkInstantLead(data, val, index) {
    this.leadConnectData = data;
    this.leadConnectValue = val;
    this.leadConnectIndex = index;
    if (
      this.userPlan === 'enterprise' ||
      this.userPlan == 'enterprise existing'
    ) {
      this.dialogRef = this.dialog.open(LeadConnectDialogComponent, {
        width: '600px',
        data: {
          data,
          index,
        },
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        this._emitter.emit({
          module: this.moduleId,
          type: 'edit_number',
          index,
          result,
        });
      });
    } else {
      const dialogRef1 = this.dialog.open(UpgradeAccountComponent, {
        width: '350px',
        disableClose: true,
        data: {
          link: '',
          userPlan: this.userPlan,
        },
      });
      dialogRef1.afterClosed().subscribe((result) => {
        const indx = this.leadConnectIndex;
        const vl = this.leadConnectValue;
        let dt = {
          index: indx,
          type: 'cancel',
          _id: this.data._id,
        };
        this._emitter.emit({
          module: this.moduleId,
          type: 'edit_number',
          indx,
          dt,
        });
      });
    }
  }
  downloadEsignFile(item) {
    const obj = {
      folderId: item?.folderId,
    };

    this._loaderService.start();

    this._esignService.eSignDownloadFolder(obj).subscribe(
      (response) => {
        if (response) {
          let base64String = response?.data;
          let fileName = item?.templateName;
          const source = `data:application/pdf;base64,${base64String}`;
          const link = document.createElement('a');
          link.href = source;
          link.download = `${fileName}.pdf`;
          link.click();
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(
            error.message ? error.message : this.messageConstant.unknownError,
            ''
          );
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  leaveNote(item) {
    this.dialogRef = this.dialog.open(TakeNoteComponent, {
      width: '400px',
      data: {
        leadId: item?._id,
        moduleId: this.moduleId,
        moduleName: this.moduleName,
        leadData: item,
        type: 'lead',
        address: item?.address,
        mainStatusTitle: item?.mainStatusTitle,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  pinnedActivity(item) {
    this.dialogRef = this.dialog.open(PinnedNotesDialogComponent, {
      width: '400px',
      data: {
        leadId: item?._id,
        moduleId: this.moduleId,
        leadData: item,
        type: 'Leads',
        mainStatusTitle: item?.mainStatusTitle,
        moduleName: this.moduleName,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  openUserListDailog(item) {
    this.dialogRef = this.dialog.open(UserListDialogComponent, {
      width: '300px',
      data: {
        leadData: item,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  checkSpam(item) {
    let cSpam = 0;
    item?.carrierAttFlagged ? cSpam++ : '';
    item?.carrierSprintFlagged ? cSpam++ : '';
    item?.carrierTmobileFlagged ? cSpam++ : '';
    item?.carrierVerizonFlagged ? cSpam++ : '';
    return cSpam;
  }

  checkSpamMsg(item) {
    let msg = 'Marked Spam By:</br></br>';
    item?.carrierAttFlagged
      ? (msg += "<p style='line-height:16px'>AT&T</p>")
      : '';
    item?.carrierVerizonFlagged
      ? (msg += "<p style='line-height:16px'>Verizon</p>")
      : '';
    item?.carrierTmobileFlagged
      ? (msg += "<p style='line-height:16px'>T-Mobile</p>")
      : '';
    item?.carrierSprintFlagged
      ? (msg += "<p style='line-height:16px'>Sprint</p>")
      : '';
    return msg;
  }
  async getNumberList() {
    this.phoneUserList = [];
    return new Promise(async (resolve, reject) => {
      const obj = {
        crmQueId: [],
        numTypeId: 3,
      };
      this._leadservice.getCampaignList(obj).subscribe(
        (response: ResponseModel) => {
          if (response && response.statusCode == 200) {
            let phoneUserListData = response?.data?.items || [];
            for (var i = 0; i < phoneUserListData.length; i++) {
              this.phoneUserList.push(phoneUserListData[i].number);
            }
            resolve(this.phoneUserList);
          } else {
            resolve([]);
          }
        },
        (err: ErrorModel) => {
          if (err.error) {
            const error: ResponseModel = err.error;
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
          resolve([]);
        }
      );
    });
  }

  // async goToColdCall(item) {
  //   this._loaderService.start();
  //   const groupIdArray = [];
  //   let phoneUserList = await this.getNumberList();
  //   if (item?.isLs) {
  //     this.inboxNewHide(item, false);
  //   }
  //   this.dialogRef = this.dialog.open(ColdCallComponent, {
  //     width: '1366px',
  //     disableClose: false,
  //     closeOnNavigation: false,
  //     data: {
  //       dialed: this.dialed,
  //       campaignId: this.campaignId,
  //       campaignTodayDate: this.campaignTodayDate,
  //       campaignScheduled: this.campaignScheduled,
  //       inboxPropertiesId: item?.leadId ? item?.leadId : '',
  //       groupNumberId: groupIdArray,
  //       phoneNumberId: phoneUserList,
  //       isPropertyDetails: true,
  //       propertyDetailsId: item?.isLs ? item?.leadId : item?._id,
  //       scheduledCallFilters: this.appliedScheduledCallFilters,
  //     },
  //   });
  //   this.dialogRef.afterClosed().subscribe((result) => {
  //     if (result?.isEdit) {
  //     }
  //   });
  // }
  goToColdCall(index, item) {
    if (this.utilities.isLSDialer) {
      if (this.utilities.dialerCurrentCampaignId == this.campaignId) {
        this._sharedService.refreshDialerToggleDisplay.next(true);
        return false;
      } else {
        this._toastrService.warning(this.messageConstant.callUnderProgress);
        this._loaderService.stop();
        return false;
      }
    }
    if (this.inboxCallEnable && item?.isNewLs) {
      this.inboxNewHide(item, false);
    }
    if (this.inboxCallEnable) {
      if (item.dialerCampaignDeleted || item.dialerCampaignListId == null) {
        this._toastrService.error(
          'This property is not part of any campaign. Please check activity log in List stacking',
          ''
        );
        return;
      }
    }
    this.dialogRef = this.dialog.open(ColdCallSettingComponent, {
      width: '450px',
      disableClose: true,
      panelClass: 'editTemplate',
      data: {
        campaignId: this.campaignId,
        dialed: this.dialed,
        campaignTodayDate: this.campaignTodayDate,
        campaignScheduled: this.campaignScheduled,
        inboxCallEnable: this.inboxCallEnable,
        inboxPropertyListId: item.leadId,
        inboxCallPage: this.inboxCallPage,
        currentCampaignPage: this.currentCampaignPage,
        todayCallPage: this.todayCallPage,
        scheduledCallPage: this.scheduledCallPage,
        scheduledCallFilters: this.appliedScheduledCallFilters,
        currentContact: index,
        currentPropertyId: item._id,
        currentCampaignName: item.campaign,
      },
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
      }
    });
  }

  inboxNewHide(item, bool) {
    let obj = {
      subModuleId: item.leadId,
    };
    if (bool) {
      this._loaderService.start();
    }
    this._dialerService.inboxNewCallSave(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode && response.statusCode == 200) {
          this._loaderService.stop();
          this._emitter.emit(true);
          this._sharedService.refreshInbox.next(true);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  openMatchTransaction(item) {
    this._router.navigate([`/accounting/add-credit-card-transaction`], {
      queryParams: { ['id']: item._id, ['accountId']: item.accountId },
    });
  }

  showConditionAction(item) {
    let flag = false;
    if (this.moduleName == 'Import Log') {
      if (!item?.processed && item?.updatedAt) {
        let today: any = new Date();
        let updatedDate: any = new Date(item?.updatedAt);
        let diffMs = today - updatedDate; // milliseconds between now & Christmas
        let difMinutes = Math.floor(diffMs / 60000);
        if (difMinutes > 60) flag = true;
      }
    }
    return flag;
  }

  callConditionAction(item) {
    this._emitter.emit(item);
  }

  editOffer(item, index) {
    this._emitter.emit({ type: 'EDIT_INITIATE', index, item });
  }

  deleteOffer(item, index) {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: {
        subModule: 'Offer',
        details: item,
        action: 'OFFER',
        leadId: this.id,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({ type: 'DELETE', index, result, details: item });
      }
    });
  }

  transformAmount(element) {
    if (!element.target.value) {
      return;
    }

    let value = element.target.value.split('.');
    if (element.target.value && value[1]) {
      if (value[1].length == 2) {
        element.target.value = element.target.value;
      } else if (value[1].length == 1) {
        element.target.value = element.target.value + '0';
      } else {
        element.target.value = element.target.value + '00';
      }
    } else if (element.target.value != '$ ') {
      element.target.value = value[0] + '.00';
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.inlineAddForm.controls[controlName].hasError(errorName);
  };

  onSubmit() {
    this.isInlineFormSubmitted = true;

    if (this.inlineAddForm.invalid) return;

    this._emitFormSubmit.emit(this.inlineAddForm);
  }

  cancelAction() {
    this._emitFormSubmit.emit();
  }

  getOwner(task) {
    let name = '';
    let profile = '';
    this.userListing.filter((x) => {
      if (x?._id === task?.assignUserId[0]) {
        name = x?.firstName + ' ' + x?.lastName;
        profile = x?.profileImage || '/assets/images/profile.svg';
      }
    });

    return { name, profile };
  }

  onAppointmentDialog(data?) {
    if (!this.secondaryData.leadData && !this.secondaryData.propertyData) {
      return;
    }

    this.dialogRef = this.dialog.open(AddEditAppointmentsComponent, {
      width: '670px',
      data: {
        moduleId: this.moduleId,
        subModuleId: this.id,
        address: this.address,
        currentUser: this.currentUser,
        secondaryContacts: this.secondaryData.secondaryContacts,
        details: data,
        leadInfo:
          this.secondaryData.leadData || this.secondaryData.propertyData,
        teams: this.secondaryData.teams,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._emitter.emit({ refresh: true });
      }
    });
  }

  deleteAppointment(appointment, index) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: {
        header: 'Confirmation',
        text: `Are you sure you want to delete appointment?`,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._loaderService.start();
        this._sharedService.deleteAppointment(appointment?._id).subscribe(
          (response: ResponseModel) => {
            if (response.statusCode == 200) {
              this._loaderService.stop();
              this._toastrService.success(
                this.messageConstant.customDeleteMessage.replace(
                  '[[field]]',
                  'Appointment'
                )
              );
              this._sharedService.refreshActivityLog.next(true);
              this._emitter.emit({ refresh: true, isDelete: 1 });
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
      }
    });
  }

  changeValue(head, item, $event) {
    if (head.key === 'appointmentStatus') {
      const obj = {
        appointmentId: item._id,
        appointmentStatus: parseInt($event),
      };

      this._sharedService.updateAppointmentStatus(obj).subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          if (response.statusCode == 200) {
            this._refresh.emit(true);
            return;
          }

          this._toastrService.error(
            response.message || this.messageConstant.unknownError
          );
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
    }
  }

  updateContingency(item, index) {
    this.dialogRef = this.dialog.open(AddEditContingencyComponent, {
      data: {
        details: item,
        leadId: item?.subModuleId?._id,
      },
      width: '500px',
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._sharedService.refreshContingency.next(true);
        this._sharedService.refreshActivityLog.next(true);
      }
    });
  }

  changeContingencyStatus(item, data, bool?) {
    const obj = {
      ...item,
    };

    let method = bool ? 'completeContingency' : 'updateContingency';

    this._contingenciesService[method](obj).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode == 200) {
          this._sharedService.refreshContingency.next(true);
          this._sharedService.refreshActivityLog.next(true);
          data.isCompleted = obj.isCompleted;
          return;
        }

        this._toastrService.error(
          response.message || this.messageConstant.unknownError
        );
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  deleteContingency(item, index) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: {
        header: 'Confirmation',
        text: `Are you sure you want to delete contingency?`,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._loaderService.start();
        this._contingenciesService
          .deleteContingency({ contingencyId: item?._id })
          .subscribe(
            (response: ResponseModel) => {
              if (response.statusCode == 200) {
                this._loaderService.stop();
                this._sharedService.refreshActivityLog.next(true);
                this._emitter.emit({ refresh: true });
              }
            },
            (err: ErrorModel) => {
              this._loaderService.stop();
              if (err.error) {
                const error: ResponseModel = err.error;
                this._toastrService.error(error.message, '');
              } else {
                this._toastrService.error(
                  this.messageConstant.unknownError,
                  ''
                );
              }
            }
          );
      }
    });
  }

  onRowClick(item, itemIndex) {
    if (
      this.moduleName?.toLowerCase() == 'transactions' ||
      this.moduleName?.toLowerCase() == 'filtertransaction'
    ) {
      if (!item?.isJournalEntry && !item?.isMatchTransaction) {
        let isRowExpand = item?.isRowExpand;
        this.data['items'].map((x) => (x.isRowExpand = false));
        if (isRowExpand) {
          item.isRowExpand = false;
        } else {
          item.isRowExpand = true;
        }
      } else {
        this.onEditTransaction(item, itemIndex);
      }
    }
  }

  transactionUpdated(event) {
    this.data['items'].map((x) => (x.isRowExpand = false));
    if (event) {
      this._emitter.emit({ module: 'transaction' });
    }
  }

  changeTransaction(event) {
    let id = event?.id;
    let values = event.values;
    if (this.moduleName?.toLowerCase() == 'filtertransaction') {
      return;
    }
    let filterArray = this.data['items']?.filter((field) => {
      return id == field._id;
    });
    if (filterArray?.length > 0) {
      filterArray[0].vendorName = values?.vendorId?.name;

      if (values?.breakDown?.length > 1) {
        filterArray[0].propertyAddress = '- Split -';
        filterArray[0].accountTagName = '- Split -';
      } else if (values?.breakDown?.length > 0) {
        filterArray[0].propertyAddress =
          values?.breakDown[0]?.propertyAddress?.address;
        filterArray[0].accountTagName = values?.breakDown[0]?.accountTag?.name;
      }
      filterArray[0].fullDescription = values?.fullDescription;
      if (values?.creditDebitType == 'debit') {
        filterArray[0].amount = -Number(Math.abs(Number(values?.amount)));
      } else if (values?.creditDebitType == 'credit') {
        filterArray[0].amount = Math.abs(Number(values?.amount));
      }
      filterArray[0].transactionDate = values?.transactionDate;
    }
  }

  onRightClick(event, item) {
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    // we pass to the menu the information about our object
    this.matMenuTrigger.menuData = { item: item };

    // we open the menu
    this.matMenuTrigger.openMenu();
  }

  editVendorTasks(item, taskType) {
    item.isGeneralTask = true;
    let obj = {
      isGetTeams: item.isGeneralTask ? false : true,
      type: 'task',
      subType: 'task',
      category: 'Update',
      task: item,
      id: item._id,
      userList: this.userListing,
      currentUser: this.currentUser,
      leadInfo: {},
      isGeneralTask: true,
      isDisable: false,
      isHideHeader: true,
    };

    if (taskType === 'VENDORS') {
      obj['moduleType'] = 'Vendors';
      const name = item.vendorData.name || '';
      obj['moduleData'] = {
        name,
      };
    } else if (taskType === 'BUYERS') {
      obj['moduleType'] = 'Buyers';
      if (!item?.buyerData?.firstName) item.buyerData.firstName = '';
      if (!item?.buyerData?.lastName) item.buyerData.lastName = '';
      obj['moduleData'] = { ...item.buyerData };
    }

    this.dialogRef = this.dialog.open(AddEditTaskComponent, {
      width: '550px',
      data: obj,
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._loaderService.start();
        result.title = result.title.trim();
        result.taskDetail = result?.taskDetail?.trim();
        result.roleId = null;
        result.isGeneralTask = false;
        result.taskType = taskType;
        delete result['mainStatusId'];

        this._taskService.updateTask(result, item._id).subscribe(
          (response: ResponseModel) => {
            if (response.statusCode == 200) {
              this._sharedService.refreshInfo.next(true);
              this._sharedService.refreshActivityLog.next(true);
              this._loaderService.stop();
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
      }
    });
  }

  getEmailStats(action, item) {
    if (item.isOldData) {
      this._toastrService.error(this.messageConstant.dataAvailableAfter);

      return;
    }

    this.dialogRef = this.dialog.open(CampaignStatsComponent, {
      minWidth: '500px',
      data: {
        item,
        action,
        type: 'EMAIL',
      },
    });
  }

  getSMSStats(action, sms) {
    this.dialogRef = this.dialog.open(CampaignStatsComponent, {
      width: '350px',
      data: {
        item: sms,
        action,
        type: 'SMS',
      },
    });
  }

  timeAgo(text) {
    if (!text || typeof text === 'object' || text === 'N/A') {
      return 'N/A';
    }
    return moment(new Date(text)).fromNow();
  }

  getSubValue(data, header) {
    try {
      if (!header || !data) {
        return;
      }
      let value;
      if (header.subItemKey) {
        if (this.moduleName == 'newDashboard') {
          value = '(' + data?.[header.subItemKey] + ' leads)';
        }
      }
      return value;
    } catch (error) {
      return '';
    }
  }

  getTaskStatus(event) {
    this._leadStatusList.emit(event);
  }

  getStatusLabel(id) {
    const status = this.leadsMainStatuses.find((x) => x._id === id);
    return status?.labels?.title || status?.title || 'Assigned To Buyer';
  }

  markAsRead(type, item, bool?) {
    if (bool) {
      item = {
        ...item,
        isBtn: true,
      };
    }
    if (type == 'buyer') {
      this._markAsReadBuyer.emit(item);
    }
    if (type == 'vendor') {
      this._markAsReadVendor.emit(item);
    }
  }

  formatedDate(expectedStartDate) {
    if (!expectedStartDate) return 'NA';
    const dateStr = expectedStartDate;
    const dateParts = dateStr.split('-');
    let formattedDate = 'NA';
    if (dateParts.length == 3) {
      let monthData = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];

      formattedDate =
        monthData[parseInt(dateParts[0]) - 1] +
        ' ' +
        dateParts[1] +
        ', ' +
        dateParts[2];
    }
    return formattedDate;
  }
  smsTooltipMsg(item) {
    return item?.status == 'delivered'
      ? this.utilities.capitalizeName(item?.status)
      : (item?.status == 'failed' || item?.status == 'undelivered') &&
        item?.errorCode
      ? 'Undelivered </br>' + this.twilioErrorMessageConstant[item?.errorCode]
      : item?.status == 'failed' || item?.status == 'undelivered'
      ? 'Undelivered'
      : 'Sent';
  }
  callBuyer(item, value, type) {
    this._callBuyer.emit({ ...item, ...value, type: type });
  }
  buyerEmail(item, head) {
    this._emailBuyer.emit({ ...item, ...head });
  }
  async listenigCall(head, item, event, type, subType) {
    let conferenceId = '';
    let friendlyName = '';
    const { children } = item;

    if (children && children[0]) {
      conferenceId = children[0].conferenceId || '';
      friendlyName = children[0].friendlyName || '';
    }
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const Obj = {
      conferenceId,
      friendlyName,
      parentId: children[0]?.parentCallSid,
      childSid: children[0]?.sid,
      user,
      to: head.leadNumber,
      type,
      subType,
    };

    let result = await this._twilioService.initializeTwilioListen(Obj);
    if (result) {
      this._loaderService.stop();
    }
    return result;
  }
  toggleButtons(button, head, item, event, headIndex) {
    this._loaderService.start();
    const { children } = item;
    let conferenceId;
    if (children && children[0]) {
      conferenceId = children[0].conferenceId || '';
    }
    if (button === this.activeButton && headIndex === this.activeEntry) {
      this.activeButton = null;
      this.activeEntry = null;
      this.onActiveCall = false;
      this.listenigCall(head, item, event, button, 'remove');
    } else {
      this.activeButton = button;
      this.activeEntry = headIndex;
      this.onActiveCall = true;
      setTimeout(() => {
        if (button === 'listen') {
          this.listenigCall(head, item, event, button, 'add');
        } else if (button === 'call') {
          this.listenigCall(head, item, event, button, 'add').then((data) => {
            // @ts-ignore
            if (data && data?.status === 'Failed') {
              // @ts-ignore
              this._toastrService.error(data?.result?.message, '');
              this.activeButton = null;
              this.activeEntry = null;
              this.onActiveCall = false;
            }
          });
        } else if (button === 'mergeCall') {
          this.listenigCall(head, item, event, button, 'add');
        }
      }, 3000);
    }
  }

  checkForListing(item, index) {
    let obj = {
      leadId: item?._id,
    };
    this._loaderService.start();
    this._leadservice.getListing(obj).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.data) {
          this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '450px',
            data: {
              header: 'Alert!',
              text: `This lead contains a listing, so you cannot delete it.`,
              yesTitle: 'Ok',
              hideNoButton: true,
            },
          });
        } else {
          this.onDeleteDialog('lead', item, index);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
      }
    );
  }

  globalSearchRedirect(action, item, index) {
    let event = action.event;
    let url;

    switch (event) {
      case 'LEAD DETAILS':
        this.onFavoriteLead(item, true);
        break;
      case 'PROPERTY':
        url = this._router.serializeUrl(
          this._router.createUrlTree([`/list-stacking/details`], {
            queryParams: { id: item?._id },
          })
        );
        window.open(url, '_blank');
        break;
      case 'TRANSACTION_LIST':
        this.onTransactionList(item, index, true);
        break;
      case 'DETAILS':
        this.getDetails(item, false, true);
        break;
      case 'NOTE_LEAD_DETAILS':
        if (!item?.subModuleData?._id) {
          return;
        }
        let obj = { leadsId: item?.subModuleData?._id };
        url = this._router.serializeUrl(
          this._router.createUrlTree([`/leads/details`], { queryParams: obj })
        );
        window.open(url, '_blank');
        break;
      case 'NOTE_INVENTORY_DETAILS':
        if (
          item.mainStatusId == '60225879d8562ef021ca2fcd' ||
          item.mainStatusId == '5feb4221aa810a3849fa551b'
        ) {
          url = this._router.serializeUrl(
            this._router.createUrlTree([`/sold/details`], {
              queryParams: { ['soldId']: item?._id },
            })
          );
        } else if (
          item.mainStatusId == '60225867d8562ef021ca2180' ||
          item.mainStatusId == '5feb4252aa810a3849fa551c'
        ) {
          url = this._router.serializeUrl(
            this._router.createUrlTree([`/sold/details`], {
              queryParams: { ['rentalId']: item?._id },
            })
          );
        } else {
          url = this._router.serializeUrl(
            this._router.createUrlTree([`/inventory/details`], {
              queryParams: { inventoryId: item?._id },
            })
          );
        }

        window.open(url, '_blank');
        break;

      case 'NOTE_DETAILS':
        if (item?.subModuleData?.mainStatusId == '5feb4221aa810a3849fa551b') {
          url = this._router.serializeUrl(
            this._router.createUrlTree([`/sold/details`], {
              queryParams: { ['soldId']: item?.subModuleData?._id },
            })
          );
        } else if (
          item?.subModuleData?.mainStatusId == '60225867d8562ef021ca2180' ||
          item?.subModuleData?.mainStatusId == '5feb4252aa810a3849fa551c'
        ) {
          url = this._router.serializeUrl(
            this._router.createUrlTree([`/sold/details`], {
              queryParams: { ['rentalId']: item?.subModuleData?._id },
            })
          );
        } else if (
          item?.subModuleData?.mainStatusId == '60221e37d8562ef0219c072a' ||
          item?.subModuleData?.mainStatusId == '5feb3f94aa810a3849fa5515' ||
          item?.subModuleData?.mainStatusId == '605ac5fb0eee4f051cee4b02' ||
          item?.subModuleData?.mainStatusId == '5feb3fabaa810a3849fa5516' ||
          item?.subModuleData?.mainStatusId == '5feb3fc7aa810a3849fa5517' ||
          item?.subModuleData?.mainStatusId == '5feb41e6aa810a3849fa551a'
        ) {
          url = this._router.serializeUrl(
            this._router.createUrlTree([`/inventory/details`], {
              queryParams: { ['inventoryId']: item?.subModuleData?._id },
            })
          );
        } else if (
          item?.subModuleData?.mainStatusId ==
            StatusConstant.GeneralModule.GENERAL_MODULE_ID ||
          item?.subModuleData?.mainStatusId ==
            StatusConstant.GeneralModule.GENERAL_MAIN_STATUS_ID ||
          item?.subModuleData?.mainStatusId ==
            StatusConstant.GeneralModule.GENERAL_SUB_MODULE_ID
        ) {
          url = this._router.serializeUrl(
            this._router.createUrlTree([`/my-tasks`])
          );
        } else if (item?.subModuleData?.type == 'vendor') {
          url = this._router.serializeUrl(
            this._router.createUrlTree([`/vendors/details`], {
              queryParams: { ['vendorsId']: item?.subModuleData?._id },
            })
          );
        } else if (item?.subModuleData?.type == 'buyer') {
          url = this._router.serializeUrl(
            this._router.createUrlTree([`/buyers/details`], {
              queryParams: { ['buyersId']: item?.subModuleData?._id },
            })
          );
        } else {
          url = this._router.serializeUrl(
            this._router.createUrlTree([`/leads/details`], {
              queryParams: { ['leadsId']: item?.subModuleData?._id },
            })
          );
        }
        window.open(url, '_blank');
        break;

      default:
        break;
    }
  }
  getTransactionDateValue(data, header) {
    if (header?.key == 'transactionDate') {
      try {
        const date = new Date(data?.[header.key]);
        return this.datePipe.transform(date, 'MMM dd, yyyy', 'GMT');
      } catch (error) {
        console.log("error = ", error);
        return ""
      }

    }
  }
}
