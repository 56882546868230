<div class="dial-minisdiv" *ngIf="isMinimized">
  <div class="re-dial-left">
    <div class="re-dialicon">
      <img src="assets/images/call-icon-white.png" alt="call" />{{
        propertyDetails?.First_Name ? propertyDetails?.First_Name : ""
      }}
      {{ propertyDetails?.Last_Name ? propertyDetails?.Last_Name : "" }}
      • {{ propertyDetails?.Formated_Address || "N/A" }}
    </div>
  </div>
  <div class="re-dial-center" *ngIf="callReady">{{ callDuration }}</div>
  <div class="re-dial-center" *ngIf="!callReady"></div>

  <div class="re-dial-right">
    <span class="re-expand" (click)="toggleDisplay()" *ngIf="isMinimized">
      <img src="assets/images/maximize-Icons.png" alt="sqare" />
    </span>
    <span
      class="re-close"
      matDialogClose
      mat-dialog-close
      [class.re-cross-disable]="isCalling"
      [class.re-close-disable]="makingCall"
      [class.re-cross-disable]="callEnd"
    >
      <img src="assets/images/close-red.svg" alt="close" />
    </span>
  </div>
</div>
<mat-dialog-content *ngIf="!isMinimized">
  <div
    class="dial-fulldiv"
    [class.d-none]="showCallEndedPopup && dialerType == 'auto-dialer'"
    [class.dial-fulldisablediv]="isCallStatusChange"
  >
    <div class="re-loader-td" *ngIf="propertyDetailLoader == true">
      <img src="/assets/images/loader.gif" alt="loader" />
    </div>
    <div
      class="re-dial-left"
      [class.re-property-details-dialer-full]="isPropertyDetails"
      [class.re-property-details-dialer-full-width]="
        showCallEndedPopup && dialerType == 'click-dialer'
      "
    >
      <div
        class="re-dial-sub"
        [class.d-none]="showCallEndedPopup && dialerType == 'click-dialer'"
      >
        <div class="re-top-div">
          <div class="re-address-div">
            <span class="re-address">{{
              propertyDetails?.Formated_Address || "N/A"
            }}</span>
            <div class="re-google-icon">
              <a
                pTooltip="Click to view on Google"
                tooltipPosition="bottom"
                target="_blank"
                *ngIf="
                  propertyDetails?.Formated_Address &&
                  propertyDetails?.Formated_Address != 'NA'
                "
                href="https://www.google.com/search?q={{
                  _utilities.formatAddress(propertyDetails?.Formated_Address)
                }}"
                ><img
                  src="/assets/images/external-link.svg"
                  alt="external-link"
              /></a>
              <a
                pTooltip="Click to view on Zillow"
                tooltipPosition="bottom"
                target="_blank"
                *ngIf="
                  propertyDetails?.Formated_Address &&
                  propertyDetails?.Formated_Address != 'NA'
                "
                href="https://www.zillow.com/homes/{{
                  _utilities.formatAddress(propertyDetails?.Formated_Address)
                }}"
                ><img src="/assets/images/open-zillow.png" alt="external-link"
              /></a>
              <!-- <a
                pTooltip="Click to view Google Map View"
                tooltipPosition="bottom"
                ><img
                  src="/assets/images/google_pointer.svg"
                  alt="google_pointer"
              /></a>-->
              <a
                (click)="
                  streetViewApi('street-map', propertyDetails?.Formated_Address)
                "
                pTooltip="Click to view Google Street View"
                tooltipPosition="bottom"
                ><img src="/assets/images/view_doll.svg" alt="view_doll"
              /></a>
            </div>
            <a
              (click)="
                propertyDetails?.InLead || phoneData?.length == 0
                  ? ''
                  : moveToLead()
              "
              class="re-move-lead"
              [pTooltip]="propertyDetails?.InLead ? 'Already a Lead' : ''"
              tooltipPosition="bottom"
              [class.re-btn-move-lead-disable]="
                propertyDetails?.InLead || phoneData?.length == 0
              "
              >Move to Lead
            </a>
          </div>
          <div class="re-subinfo-div">
            <div class="re-subinfo">
              Vacant:
              <b *ngIf="propertyDetails?.vacantDate && propertyDetails?.Vacant"
                >Since
                {{ propertyDetails?.vacantDate | date : "mediumDate" }}</b
              >
              <b *ngIf="!propertyDetails?.Vacant">{{
                propertyDetails?.Vacant == true ? "Yes" : "No"
              }}</b>
            </div>
            <div class="re-subinfo">
              In Lead:
              <b>{{ inLeadStatus }}</b>
            </div>
            <div class="re-subinfo">
              Owner Type: <b>{{ propertyDetails?.Owner_Type }}</b>
            </div>
            <div class="re-subinfo">
              Ownership: <b>{{ propertyDetails?.ownershipType }}</b>
            </div>
          </div>
          <div class="re-status-title">
            <span class="re-tit">Last status</span>
            <span
              class="re-dropbtn"
              (click)="changeStatus()"
              [class.re-dropdown-status-disable]="!lastCallStatus?.statusId"
              >{{
                getCallStatus(
                  lastCallStatus?.isConnected
                    ? lastCallStatus?.isConnected
                    : false,
                  lastCallStatus?.statusId ? lastCallStatus?.statusId : ""
                )
              }}</span
            >
          </div>
          <span
            class="re-close"
            *ngIf="isPropertyDetails"
            [class.re-cross-disable]="isCalling"
            [class.re-close-disable]="makingCall"
            [class.re-cross-disable]="callEnd"
            matDialogClose
            mat-dialog-close
          >
            <img src="assets/images/close-red.svg" alt="close" />
          </span>
        </div>
        <div class="re-status-result-checkbx" *ngIf="isCallStatusChange">
          <div class="re-title-div">
            <h4 class="re-heading-title">LAST CALL STATUS</h4>
            <span
              class="re-closed"
              [class.re-cross-disable]="isPropertyToLead"
              (click)="changeStatus()"
              >X</span
            >
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <h5 class="re-heading1">LEAD CONNECTED</h5>
              <div class="form-group">
                <label class="re-lab">Put on DNC</label>
                <div class="radiolist">
                  <label
                    class="radiobtn"
                    [class.re-lable-disable]="isPropertyToLead"
                  >
                    <input
                      type="radio"
                      [value]="callConnectedStatus[0].value"
                      name="changeStatus"
                      [(ngModel)]="callStatus"
                    /><span class="checkmark"></span
                    >{{ callConnectedStatus[0].name }}
                  </label>
                  <label
                    class="radiobtn"
                    [class.re-lable-disable]="isPropertyToLead"
                  >
                    <input
                      type="radio"
                      [value]="callConnectedStatus[1].value"
                      name="changeStatus"
                      [(ngModel)]="callStatus"
                    /><span class="checkmark"></span
                    >{{ callConnectedStatus[1].name }}
                  </label>
                </div>
              </div>
              <div class="form-group">
                <label class="re-lab">Schedule Call</label>
                <div class="radiolist">
                  <label
                    class="radiobtn"
                    [class.re-lable-disable]="isPropertyToLead"
                  >
                    <input
                      type="radio"
                      [value]="callConnectedStatus[2].value"
                      name="changeStatus"
                      [(ngModel)]="callStatus"
                    /><span class="checkmark"></span
                    >{{ callConnectedStatus[2].name }}
                  </label>
                </div>
                <div class="re-schedule-div2" *ngIf="callStatus == '3'">
                  <p-calendar
                    appendTo="body"
                    [showIcon]="true"
                    [icon]="'fa fa-calendar'"
                    [showTime]="false"
                    [(ngModel)]="scheduleDate"
                    placeholder="__/__/____"
                    readonlyInput="true"
                    [minDate]="minimumDate"
                  ></p-calendar>
                  <p-calendar
                    appendTo="body"
                    [showIcon]="true"
                    [icon]="'fa fa-clock-o'"
                    [timeOnly]="true"
                    inputId="timeonly"
                    hourFormat="12"
                    [(ngModel)]="scheduleTime"
                    [minDate]="minimumTime"
                    placeholder="__ : __"
                    readonlyInput="true"
                  ></p-calendar>
                  <!-- <select class="form-control" [(ngModel)]="scheduleZone">
                    <option value="Los Angeles">Los Angeles</option>
                    <option value="Canada">Canada</option>
                  </select> -->
                </div>
              </div>
              <div class="form-group">
                <label class="re-lab">Move to Lead</label>
                <div class="radiolist">
                  <label
                    class="radiobtn w-auto"
                    [class.re-lable-disable]="propertyDetails?.InLead"
                  >
                    <input
                      type="radio"
                      [value]="callConnectedStatus[3].value"
                      (click)="propertyDetails?.InLead ? '' : convertToLead()"
                      name="changeStatus"
                      [(ngModel)]="callStatus"
                    /><span class="checkmark"></span>Convert to Lead
                  </label>
                  <span
                    *ngIf="propertyDetails?.InLead"
                    class="re-dropdown-already-lead"
                    >(Already a Lead)</span
                  >
                </div>
              </div>
              <div class="form-group">
                <label class="re-lab">Remove from all Marketing</label>
                <div class="radiolist">
                  <label
                    class="radiobtn"
                    [class.re-lable-disable]="isPropertyToLead"
                  >
                    <input
                      type="radio"
                      [value]="callConnectedStatus[4].value"
                      name="changeStatus"
                      [(ngModel)]="callStatus"
                    /><span class="checkmark"></span
                    >{{ callConnectedStatus[4].name }}
                  </label>
                  <label
                    class="radiobtn"
                    [class.re-lable-disable]="isPropertyToLead"
                  >
                    <input
                      type="radio"
                      [value]="callConnectedStatus[5].value"
                      name="changeStatus"
                      [(ngModel)]="callStatus"
                    /><span class="checkmark"></span
                    >{{ callConnectedStatus[5].name }}
                  </label>
                  <label
                    class="radiobtn"
                    [class.re-lable-disable]="isPropertyToLead"
                  >
                    <input
                      type="radio"
                      [value]="callConnectedStatus[6].value"
                      name="changeStatus"
                      [(ngModel)]="callStatus"
                    /><span class="checkmark"></span
                    >{{ callConnectedStatus[6].name }}
                  </label>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <h5 class="re-heading2">CALL NOT CONNECTED</h5>
              <div class="form-group">
                <div class="radiolist">
                  <label
                    class="radiobtn"
                    [class.re-lable-disable]="isPropertyToLead"
                    *ngFor="let callNotStatus of callNotConnectedStatus"
                  >
                    <input
                      type="radio"
                      name="changeStatus"
                      [value]="callNotStatus?.value"
                      [(ngModel)]="callStatus"
                    /><span class="checkmark"></span>{{ callNotStatus?.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            class="form-group"
            *ngIf="callStatus == '6' || callStatus == '14'"
          >
            <textarea
              class="form-control"
              rows="3"
              placeholder="Enter Reason"
              name="otherReason"
              [(ngModel)]="otherReason"
            ></textarea>
          </div>
          <div class="re-btn-div">
            <a
              class="btn-saves"
              [class.re-disable-save]="!callStatus"
              (click)="updateStatus(true)"
              >Save Status</a
            >
          </div>
        </div>
        <mat-tab-group
          (selectedTabChange)="getTabValues($event?.index)"
          [class.re-property-dialer-fullheight]="isPropertyDetails"
        >
          <mat-tab label="Property Detail">
            <div class="re-pro-info">
              <div
                class="re-boxes"
                *ngIf="
                  propertyDetails?.Full_Name?.trim().length != 0 ||
                  propertyDetails?.Property_County_Name?.length ||
                  propertyDetails?.companyName?.trim().length != 0 ||
                  propertyDetails?.Mailing_Street_Address?.trim().length != 0 ||
                  propertyDetails?.Mailing_Street_Address_2?.trim().length !=
                    0 ||
                  propertyDetails?.Mailing_City?.trim().length != 0 ||
                  propertyDetails?.Mailing_State?.trim().length != 0 ||
                  propertyDetails?.Mailing_Zip_Code?.trim().length != 0 ||
                  propertyDetails?.Mailing_County_Name?.length
                "
              >
                <h3 class="re-title">OWNER INFO</h3>
                <ul class="re-list">
                  <li *ngIf="propertyDetails?.Full_Name?.trim().length != 0">
                    <span class="re-text1">Full Name</span>
                    <span class="re-text2"
                      >{{
                        propertyDetails?.First_Name
                          ? propertyDetails?.First_Name
                          : ""
                      }}
                      {{
                        propertyDetails?.Last_Name
                          ? propertyDetails?.Last_Name
                          : ""
                      }}</span
                    >
                  </li>
                  <!-- <li *ngIf="propertyDetails?.Property_County_Name?.length">
                    <span class="re-text1">Property County Name</span>
                    <span class="re-text2">{{
                      propertyDetails?.Property_County_Name
                    }}</span>
                  </li> -->
                  <!-- <li>
                  <span class="re-text1">Owner name 2</span>
                  <span class="re-text2">Ava Smith</span>
                </li> -->
                </ul>
                <ul class="re-list">
                  <li *ngIf="propertyDetails?.companyName?.trim().length != 0">
                    <span class="re-text1 re-gray">Company Name</span>
                    <span class="re-text2 re-na">{{
                      propertyDetails?.companyName
                    }}</span>
                  </li>
                </ul>
                <ul class="re-dialer-list">
                  <li
                    class="re-dialer-li"
                    *ngIf="
                      propertyDetails?.Mailing_Street_Address?.trim().length !=
                        0 ||
                      propertyDetails?.Mailing_Street_Address_2?.trim()
                        .length != 0 ||
                      propertyDetails?.Mailing_City?.trim().length != 0 ||
                      propertyDetails?.Mailing_State?.trim().length != 0 ||
                      propertyDetails?.Mailing_Zip_Code?.trim().length != 0
                    "
                  >
                    <span class="re-text1">
                      Mailing Address
                      <small
                        class="re-invalid-text"
                        *ngIf="
                          propertyDetails?.MailingMatch == false &&
                          (propertyDetails?.Mailing_Street_Address != '' ||
                            propertyDetails?.Mailing_City != '' ||
                            propertyDetails?.Mailing_State != '' ||
                            propertyDetails?.Mailing_Zip_Code != '')
                        "
                      >
                        (Invalid USPS Address)
                      </small>
                      <span class="re-dialer-tooltip">
                        Related ({{ duplicateData?.length || 0 }})
                        <span class="re-tooltiptext">
                          <b>Other Properties with same Mailing Address:</b>
                          <ul>
                            <li *ngFor="let eachDuplicate of duplicateData">
                              {{ eachDuplicate?.Formated_Address }}
                            </li>
                          </ul>
                        </span>
                      </span>
                    </span>
                    <span class="re-text2">
                      {{ propertyDetails?.Mailing_Formated_Address }}
                    </span>
                  </li>
                  <!-- <li
                    *ngIf="
                      propertyDetails?.Mailing_Street_Address_2?.trim()
                        .length != 0
                    "
                  >
                    <span class="re-text1">Mailing Address 2</span>
                    <span class="re-text2 re-na">{{
                      propertyDetails?.Mailing_Street_Address_2 || "N.A"
                    }}</span>
                  </li> -->
                </ul>
                <!-- <ul class="re-list">
                  <li *ngIf="propertyDetails?.Mailing_City?.trim().length != 0">
                    <span class="re-text1">Mailing City</span>
                    <span class="re-text2">{{
                      propertyDetails?.Mailing_City || "N.A"
                    }}</span>
                  </li>
                  <li
                    *ngIf="propertyDetails?.Mailing_State?.trim().length != 0"
                  >
                    <span class="re-text1">Mailing State</span>
                    <span class="re-text2">{{
                      propertyDetails?.Mailing_State || "N.A"
                    }}</span>
                  </li>
                  <li
                    *ngIf="
                      propertyDetails?.Mailing_Zip_Code?.trim().length != 0
                    "
                  >
                    <span class="re-text1">Mailing Zip</span>
                    <span class="re-text2">{{
                      propertyDetails?.Mailing_Zip_Code || "N.A"
                    }}</span>
                  </li>
                  <li *ngIf="propertyDetails?.Mailing_County_Name?.length">
                    <span class="re-text1">Mailing County Name</span>
                    <span class="re-text2">{{
                      propertyDetails?.Mailing_County_Name
                    }}</span>
                  </li>
                </ul> -->
              </div>
              <!-- <div class="re-boxes">
                <h3 class="re-title">CONTACT INFO</h3>
                <ul class="re-list">
                  <li *ngFor="let eachPhone of phoneData; let i = index">
                    <span class="re-text1">Phone {{ i + 1 }}</span>
                    <span class="re-text2"
                      >{{ formatPhoneNumber(eachPhone?.phone, eachPhone?.type)
                      }}{{
                        eachPhone?.dnc !== undefined &&
                        eachPhone?.dnc != "Unknown" &&
                        eachPhone?.dnc != "No"
                          ? ", DNC"
                          : ""
                      }}{{
                        eachPhone?.litigator !== undefined &&
                        eachPhone?.litigator != "Unknown" &&
                        eachPhone?.litigator != "No"
                          ? ", Litigator"
                          : ""
                      }}</span
                    >
                  </li>
                </ul>
                <ul class="re-list">
                  <li *ngIf="propertyDetails?.Email_1">
                    <span class="re-text1">Email 1</span>
                    <span class="re-text2">{{ propertyDetails?.Email_1 }}</span>
                  </li>
                  <li *ngIf="propertyDetails?.Email_2">
                    <span class="re-text1">Email 2</span>
                    <span class="re-text2">{{ propertyDetails?.Email_2 }}</span>
                  </li>
                  <li *ngIf="propertyDetails?.Email_3">
                    <span class="re-text1">Email 3</span>
                    <span class="re-text2">{{ propertyDetails?.Email_3 }}</span>
                  </li>
                  <li *ngIf="propertyDetails?.Email_4">
                    <span class="re-text1">Email 4</span>
                    <span class="re-text2">{{ propertyDetails?.Email_4 }}</span>
                  </li>
                </ul>
              </div> -->
              <div class="re-boxes">
                <h3 class="re-title">
                  Lists
                  <a class="re-add-more" (click)="addTagList('List')">+ Add</a>
                </h3>
                <ul class="re-taglist" *ngIf="ListNames?.length > 0">
                  <li *ngFor="let data of ListNames">
                    {{ data?.name }}
                    <i
                      class="fa fa-times-circle-o re-close-icon"
                      (click)="confirmRemoveTags('List', data?._id)"
                    ></i>
                  </li>
                </ul>
                <p class="re-empty" *ngIf="ListNames?.length == 0">
                  No Lists to show
                </p>
              </div>
              <div class="re-boxes">
                <h3 class="re-title">
                  Tags
                  <a class="re-add-more" (click)="addTagList('Tag')">+ Add</a>
                </h3>
                <ul class="re-taglist" *ngIf="TagNames.length > 0">
                  <li *ngFor="let data of TagNames">
                    {{ data?.name }}
                    <i
                      class="fa fa-times-circle-o re-close-icon"
                      (click)="confirmRemoveTags('Tag', data?._id)"
                    ></i>
                  </li>
                </ul>
                <p class="re-empty" *ngIf="TagNames.length == 0">
                  No Tags to show
                </p>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Marketing Details ({{ allMarketData?.length }})">
            <div class="d-inline-block w-100">
              <div class="re-marketin-filter">
                <h4 class="re-title">
                  Marketing Detail ({{ allMarketData?.length }})
                </h4>
                <div class="rightdiv">
                  <span class="re-txt">Filter by touches</span>
                  <select
                    class="form-control"
                    (change)="filterMarketType($event.target.value)"
                  >
                    <option value="All">All Types</option>
                    <option value="Direct Mail">Direct Mail</option>
                    <option value="Cold Calling">Cold Calling</option>
                    <option value="SMS">SMS</option>
                    <option value="RVM">RVM</option>
                  </select>
                  <!-- <a class="re-btn" (click)="addMarketingDetails()"
                    >+ Add Details</a
                  > -->
                </div>
              </div>
              <div class="re-custom-table">
                <table class="table">
                  <colgroup>
                    <col width="40%" />
                    <col width="18%" />
                    <col width="24%" />
                    <col width="18%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Marketing Type</th>
                      <th>Mailer Type</th>
                      <th>Campaign Name</th>
                      <th>Date Added</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="allMarketData?.length == 0">
                      <td colspan="4">
                        <p class="text-center">No records to show</p>
                      </td>
                    </tr>
                    <tr *ngFor="let eachMarketData of allMarketData">
                      <td>{{ eachMarketData?.type }}</td>
                      <td>{{ eachMarketData?.mailerType }}</td>
                      <td>{{ eachMarketData?.campaignName }}</td>
                      <td>
                        {{
                          eachMarketData?.expectedStartDate
                            | date : "mediumDate"
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Related Properties ({{ duplicateData?.length }})">
            <div class="d-inline-block w-100">
              <div class="re-marketin-filter">
                <h4 class="re-title">RELATED ({{ duplicateData?.length }})</h4>
              </div>
              <div class="re-custom-table">
                <table class="table">
                  <colgroup>
                    <col width="40%" />
                    <col width="16%" />
                    <col width="16%" />
                    <col width="18%" />
                    <col width="10%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>Property Address</th>
                      <th>In Leads</th>
                      <th>Owner Type</th>
                      <th>List | Tag</th>
                      <th>Phone(s)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="duplicateData?.length == 0">
                      <td colspan="2">
                        <p class="text-center">No records to show</p>
                      </td>
                    </tr>
                    <tr *ngFor="let eachProperty of duplicateData">
                      <td>
                        {{ eachProperty?.Formated_Address }}
                        <span
                          class="re-tooltip-dialer re-icons"
                          *ngIf="eachProperty?.Vacant"
                        >
                          <img
                            src="./assets/images/vacant-Icons.png"
                            alt="Property Address"
                            tooltipPosition="bottom"
                            pTooltip="Property Address Vacant"
                          />
                        </span>
                        <span
                          class="re-tooltip-dialer re-icons"
                          *ngIf="eachProperty?.MailingVacant"
                        >
                          <img
                            src="./assets/images/home-Icons.png"
                            alt="Mailing Address"
                            tooltipPosition="bottom"
                            pTooltip="Mailing Address Vacant"
                          />
                        </span>
                      </td>
                      <td>
                        {{ getPropertyStat(eachProperty) }}
                      </td>
                      <td>
                        {{ eachProperty?.Owner_Type || "NA" }}
                      </td>
                      <td>
                        <span
                          class="re-folder-name"
                          *ngIf="eachProperty?.ListId.length == 0"
                          >0</span
                        >
                        <span
                          class="re-tooltip-dialer"
                          *ngIf="eachProperty?.ListId.length"
                        >
                          <span
                            class="re-folder-name"
                            tooltipPosition="bottom"
                            pTooltip="{{
                              getListTagContent(eachProperty, 'list')
                            }}"
                            [escape]="false"
                          >
                            {{ eachProperty?.ListId.length }}
                          </span>
                        </span>
                        |
                        <span
                          class="re-folder-name"
                          *ngIf="eachProperty?.TagId.length == 0"
                          >0</span
                        >
                        <span
                          class="re-tooltip-dialer"
                          *ngIf="eachProperty?.TagId.length"
                        >
                          <span
                            class="re-folder-name"
                            tooltipPosition="bottom"
                            pTooltip="{{
                              getListTagContent(eachProperty, 'tag')
                            }}"
                            [escape]="false"
                          >
                            {{ eachProperty?.TagId.length }}
                          </span>
                        </span>
                      </td>
                      <td>
                        {{ getPropertyPhoneNumber(eachProperty) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Activity Log">
            <activity-logs
              #activityLog
              [activityMainStyle]="{ height: 'CALC(100vh - 335px)' }"
              [style]="{ borderTop: 'none' }"
              [moduleId]="listModuleId"
              [leadId]="propertyList[this.currentContact]"
              [listPropertyData]="propertyDetails"
              [moduleName]="moduleName"
              [type]="type"
              [userListing]="userListing"
              [activityTabView]="false"
              [activityFilterView]="false"
              [activityQuillBox]="false"
              [isWebFormLink]="true"
            ></activity-logs>
          </mat-tab>
          <mat-tab label="Phone Numbers ({{ phoneData?.length }})">
            <div class="re-title-sec mb-0">
              <h4 class="re-title m-0">
                Phone Numbers ({{ phoneData?.length }})
              </h4>
              <!-- <a class="btn" (click)="addPhoneNumber()">+ Add Phone</a> -->
            </div>
            <div class="re-total-count">
              Total Attempts :
              {{ totalCallAttempt ? totalCallAttempt : "N/A" }} • Last :
              {{
                lastCallNumber ? _utilities.maskNumber(lastCallNumber) : "N/A"
              }}
              <span class="re-last-date">
                {{ lastCallDateTime ? "[" + lastCallDateTime + "]" : "" }}</span
              >
            </div>
            <div class="re-custom-table">
              <table class="table">
                <colgroup>
                  <col width="35%" />
                  <col width="10%" />
                  <col width="10%" />
                  <col width="45%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>Phone</th>
                    <th>DNC</th>
                    <th>Litigator</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="phoneData.length == 0">
                    <td colspan="4">
                      <p class="text-center">No records to show</p>
                    </td>
                  </tr>
                  <tr *ngFor="let eachPhoneList of phoneData; let i = index">
                    <td>
                      <div class="re-phonenum-div">
                        <span class="re-number">
                          {{
                            formatPhoneNumber(
                              eachPhoneList?.phone,
                              eachPhoneList?.type
                            )
                          }}
                          <!-- {{
                            formatPhoneNumber(
                              eachPhoneList?.phone,
                              eachPhoneList?.type,
                              true
                            )
                          }} -->
                          &nbsp;&nbsp;
                          <a
                            href="javascript:void(0);"
                            title="Send Message"
                            class="link"
                            (click)="sendSmsDialog(eachPhoneList?.phone)"
                            *ngIf="
                              _utilities.userAccessData
                                ?.isListStackingDialerAccess &&
                              !_utilities.userAccessData?.isBasicDialer
                            "
                            ><img
                              height="12px"
                              width="12px"
                              alt="sms"
                              src="assets/images/sms.svg"
                          /></a>
                          <a
                            href="javascript:void(0);"
                            title="Call Lead"
                            class="link"
                            *ngIf="
                              !isPropertyDetails &&
                              _utilities.userAccessData
                                ?.isListStackingDialerAccess
                            "
                            (click)="
                              callToCurrentNumber(eachPhoneList?.phone, i)
                            "
                            ><img
                              height="12px"
                              width="12px"
                              alt="call"
                              src="assets/images/call.svg"
                          /></a>
                        </span>
                        <div class="dropdown">
                          <a class="dropdown-toggle" [matMenuTriggerFor]="menu">
                            <!-- 3 Matching Records • -->
                            {{
                              callAttempt[countryCode + eachPhoneList?.phone]
                                ? callAttempt[
                                    countryCode + eachPhoneList?.phone
                                  ]
                                : 0
                            }}
                            Attempts • <span class="re-last-date">Last: </span>
                            {{
                              callDateAttempt[
                                countryCode + eachPhoneList?.phone
                              ]
                                ? callDateAttempt[
                                    countryCode + eachPhoneList?.phone
                                  ]
                                : "N/A"
                            }}
                          </a>
                          <mat-menu
                            class="re-matmenu-drop d-none"
                            #menu="matMenu"
                          >
                            <div class="re-listt-div">
                              <h6 class="re-tit">LIST STACKING</h6>
                              <div class="re-listt">
                                123 Main Street, Chicago, IL 60605 (Related)
                              </div>
                              <div class="re-listt">
                                456 New Street, New York, NY 10010 (Not-Related)
                              </div>
                            </div>
                            <div class="re-listt-div">
                              <h6 class="re-tit">LEADS</h6>
                              <div class="re-listt">
                                Sharad Mehta: 789 Anystreet, Los Angeles, CA
                              </div>
                              <div class="re-listt">
                                Darshan Joshi: 1001 New Place, San Diego, CA
                              </div>
                            </div>
                            <div class="re-listt-div">
                              <h6 class="re-tit">Buyers</h6>
                              <div class="re-listt">Sandip Vaghasiya</div>
                            </div>
                          </mat-menu>
                        </div>
                      </div>
                    </td>
                    <td>{{ eachPhoneList?.dnc }}</td>
                    <td>{{ eachPhoneList?.litigator }}</td>
                    <td>
                      <div class="re-status-list">
                        <span *ngIf="eachPhoneList?.status.length == 0">
                          No
                        </span>
                        <span *ngIf="eachPhoneList?.status.length > 0">
                          <span
                            class="re-status"
                            *ngFor="
                              let phoneList of eachPhoneList?.status
                                | slice : 0 : 2;
                              let index = index
                            "
                            >{{ phoneList.shortStatus }}</span
                          >
                        </span>
                        <a
                          class="re-more"
                          *ngIf="eachPhoneList?.status.length > 2"
                          (click)="
                            managePhoneStatus('All', eachPhoneList?.status)
                          "
                          >+{{ eachPhoneList?.status.length - 2 }} More</a
                        >
                        <a
                          class="re-manage"
                          (click)="
                            managePhoneStatus(
                              'Manage',
                              [],
                              eachPhoneList?.currentIndex
                            )
                          "
                          >Manage</a
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-tab>
          <!-- <mat-tab label="Email Addresses({{ allEmailObject?.length }})">
            <div class="re-title-sec">
              <h4 class="re-title">Email Addresses</h4>
              <a class="btn" (click)="addEmail()">+ Add Email</a>
            </div>
            <div class="re-custom-table">
              <table class="table">
                <colgroup>
                  <col width="100%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="EmptyEmailObject">
                    <td colspan="3">
                      <p class="text-center">No records to show</p>
                    </td>
                  </tr>
                  <tr
                    *ngFor="let eachEmailList of allEmailObject; let i = index"
                  >
                    <td>{{ eachEmailList }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-tab> -->
        </mat-tab-group>
        <div class="re-diallead-btnlist">
          <div class="re-btnlist-right">
            <a
              href="javascript:;"
              class="btn-sms"
              (click)="phoneData?.length != 0 ? sendSmsDialog() : ''"
              [pTooltip]="
                phoneData?.length == 0
                  ? 'You do not have any phone number in this property'
                  : ''
              "
              tooltipPosition="bottom"
              [class.re-disabled]="phoneData?.length == 0"
              *ngIf="
                _utilities.userAccessData?.isListStackingDialerAccess &&
                !_utilities.userAccessData?.isBasicDialer
              "
            >
              <img src="assets/images/Send-SMS-icon.svg" alt="sms" />
              <span>Send SMS</span>
            </a>
            <a href="javascript:;" class="btn-note" (click)="openNote()">
              <img src="assets/images/Leave-Note-icon.svg" alt="note" />
              <span>Leave Note</span>
            </a>
            <!-- <a
              href="javascript:;"
              class="btn btn-blue"
              (click)="phoneData?.length != 0 ? sendSmsDialog() : ''"
              [pTooltip]="
                phoneData?.length == 0
                  ? 'You do not have any phone number in this property'
                  : ''
              "
              tooltipPosition="bottom"
              [class.re-disabled]="phoneData?.length == 0"
              >Send SMS</a
            > -->
            <!-- <div class="btn-group dropup">
              <button
                type="button"
                class="btn btn-blue dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Send SMS
              </button>
              <div class="dropdown-menu">
                <a
                  class="dropdown-item"
                  href="javascript:;"
                  *ngFor="let eachTemplate of smsTemplateList"
                  (click)="openSendSMS(eachTemplate)"
                  >{{ eachTemplate?.templateName }}</a
                >
              </div>
            </div> -->
            <!-- <a href="javascript:;" class="btn btn-blue" (click)="openNote()"
              >Leave Note</a
            > -->
          </div>
        </div>
      </div>
      <!-- Call Ended -->
      <div
        [ngClass]="
          showCallEndedPopup && dialerType == 'click-dialer'
            ? 're-end-option'
            : 're-end-option d-none'
        "
      >
        <!-- <div class="re-message-label">
          You just called {{ propertyDetails?.Full_Name }}
        </div> -->
        <div class="re-result">
          <div class="re-result-address">
            <h3 class="re-txt1">
              {{ propertyDetails?.Formated_Address || "N/A" }}
            </h3>
            <h5 class="re-txt2">
              {{
                propertyDetails?.First_Name ? propertyDetails?.First_Name : ""
              }}
              {{ propertyDetails?.Last_Name ? propertyDetails?.Last_Name : "" }}
              •
              {{ _utilities.maskNumber(numberToCall) }}
            </h5>
            <a
              class="btn-red"
              (click)="redialMakeCall('stop')"
              [class.re-disable]="!callStatus"
              ><img src="assets/images/close-red.svg" alt="close" />Exit
              Dialer</a
            >
          </div>
          <div class="re-result-note">
            <label class="re-lab">Notes</label>
            <textarea
              rows="3"
              placeholder='Type your note and it will auto save once you click "Redial", "Call Next Contact" or "Exit Dialing" button.'
              name="comment"
              [(ngModel)]="comment"
              class="form-control"
            ></textarea>
          </div>
          <h4 class="re-call-result-title">CALL RESULT</h4>
          <div class="re-connect">
            <h4 class="re-title">LEAD CONNECTED</h4>
            <div class="radiolist">
              <h4 class="re-subtitle">Put on DNC</h4>
              <label
                class="radiobtn"
                [class.re-lable-disable]="isPropertyToLead"
              >
                <input
                  type="radio"
                  [value]="callConnectedStatus[0].value"
                  name="connect"
                  [(ngModel)]="callStatus"
                /><span class="checkmark"></span
                >{{ callConnectedStatus[0].name }}
              </label>
              <label
                class="radiobtn"
                [class.re-lable-disable]="isPropertyToLead"
              >
                <input
                  type="radio"
                  [value]="callConnectedStatus[1].value"
                  name="connect"
                  [(ngModel)]="callStatus"
                /><span class="checkmark"></span
                >{{ callConnectedStatus[1].name }}
              </label>
              <h4 class="re-subtitle">Schedule Call</h4>
              <label
                class="radiobtn"
                [class.re-lable-disable]="isPropertyToLead"
              >
                <input
                  type="radio"
                  [value]="callConnectedStatus[2].value"
                  name="connect"
                  [(ngModel)]="callStatus"
                /><span class="checkmark"></span
                >{{ callConnectedStatus[2].name }}
              </label>
              <div class="re-schedule-div" *ngIf="callStatus == '3'">
                <p-calendar
                  appendTo="body"
                  [showIcon]="true"
                  [icon]="'fa fa-calendar'"
                  [showTime]="false"
                  [(ngModel)]="scheduleDate"
                  placeholder="__/__/____"
                  readonlyInput="true"
                  [minDate]="minimumDate"
                ></p-calendar>
                <p-calendar
                  appendTo="body"
                  [showIcon]="true"
                  [icon]="'fa fa-clock-o'"
                  [timeOnly]="true"
                  inputId="timeonly"
                  hourFormat="12"
                  [(ngModel)]="scheduleTime"
                  [minDate]="minimumTime"
                  placeholder="__ : __"
                  readonlyInput="true"
                ></p-calendar>
                <!-- <select class="form-control" [(ngModel)]="scheduleZone">
                  <option value="Los Angeles">Los Angeles</option>
                  <option value="Canada">Canada</option>
                </select> -->
              </div>
              <h4 class="re-subtitle">Move to Lead</h4>
              <label
                class="radiobtn"
                [class.re-lable-disable]="propertyDetails?.InLead"
              >
                <input
                  type="radio"
                  [value]="callConnectedStatus[3].value"
                  (click)="propertyDetails?.InLead ? '' : convertToLead()"
                  name="connect"
                  [(ngModel)]="callStatus"
                /><span class="checkmark"></span>Convert to Lead
                <span
                  *ngIf="propertyDetails?.InLead"
                  class="re-dropdown-already-lead re-already-lead"
                  >(Already a Lead)</span
                >
              </label>

              <h4 class="re-subtitle">Remove from all Marketing</h4>
              <label
                class="radiobtn"
                [class.re-lable-disable]="isPropertyToLead"
              >
                <input
                  type="radio"
                  [value]="callConnectedStatus[4].value"
                  name="connect"
                  [(ngModel)]="callStatus"
                /><span class="checkmark"></span
                >{{ callConnectedStatus[4].name }}
              </label>
              <label
                class="radiobtn"
                [class.re-lable-disable]="isPropertyToLead"
              >
                <input
                  type="radio"
                  [value]="callConnectedStatus[5].value"
                  name="connect"
                  [(ngModel)]="callStatus"
                /><span class="checkmark"></span
                >{{ callConnectedStatus[5].name }}
              </label>
              <label
                class="radiobtn"
                [class.re-lable-disable]="isPropertyToLead"
              >
                <input
                  type="radio"
                  [value]="callConnectedStatus[6].value"
                  name="connect"
                  [(ngModel)]="callStatus"
                /><span class="checkmark"></span
                >{{ callConnectedStatus[6].name }}
              </label>
            </div>
            <textarea
              class="form-control"
              rows="3"
              placeholder="Reason here..."
              name="otherReason"
              [(ngModel)]="otherReason"
              *ngIf="callStatus == '6'"
            ></textarea>
          </div>
          <div class="re-notconnect">
            <h4 class="re-title">CALL NOT CONNECTED</h4>
            <div class="radiolist">
              <label
                class="radiobtn"
                [class.re-lable-disable]="isPropertyToLead"
                *ngFor="let callNotStatus of callNotConnectedStatus"
              >
                <input
                  type="radio"
                  name="callStatus"
                  [value]="callNotStatus?.value"
                  [(ngModel)]="callStatus"
                /><span class="checkmark"></span>{{ callNotStatus?.name }}
              </label>
            </div>
            <textarea
              class="form-control"
              rows="3"
              placeholder="Reason here..."
              name="otherReason"
              [(ngModel)]="otherReason"
              *ngIf="callStatus == '14'"
            ></textarea>
          </div>
          <div class="re-savebtn-div">
            <a
              href="javascript:;"
              class="btn-save"
              [class.disable-save]="!callStatus"
              (click)="saveStatus()"
              >Save Answer</a
            >
          </div>
        </div>
        <!-- <div class="re-notes">
          You need to select "Call Result" before calling "Next Number/Contact"
        </div> -->
      </div>
    </div>
    <!-- Script Section -->
    <div
      class="re-dial-right1"
      [class.re-property-details-dialer-none]="isPropertyDetails"
      [class.d-none]="showCallEndedPopup && dialerType == 'click-dialer'"
    >
      <h4 class="re-top-title">{{ scriptDetail?.name }}</h4>
      <div class="re-text" [innerHTML]="scriptDescription"></div>
    </div>
    <div
      class="re-dial-right2"
      [class.re-property-details-dialer-none]="isPropertyDetails"
      [class.d-none]="showCallEndedPopup"
    >
      <div class="re-expand-div">
        <!-- <span class="txt">{{ callDisplay }}</span> -->
        <div class="re-time-text">
          Timer: <span class="re-time-count">{{ showTimerCount }}</span>
        </div>
        <span class="re-expand" (click)="toggleDisplay()" *ngIf="!isMinimized">
          <img src="assets/images/icon-clone.svg" alt="clone" />
        </span>
        <span
          class="re-close"
          [class.re-cross-disable]="isCalling"
          [class.re-close-disable]="makingCall"
          [class.re-cross-disable]="callEnd"
          matDialogClose
          mat-dialog-close
        >
          <img src="assets/images/close-red.svg" alt="close" />
        </span>
      </div>
      <div class="re-call-change" *ngIf="defaultCallNumber && defaultDiaplay">
        <div class="re-callnum">
          <span class="re-numb">{{
            _utilities.maskNumber(defaultCallNumber)
          }}</span>
          <span class="txt">Call/SMS/RVM from number</span>
          <a
            href="javascript:;"
            pTooltip="Change phone number to call, send SMS or send RVM from"
            tooltipPosition="bottom"
            class="re-changebtn"
            (click)="changePhoneNumber()"
            >Change</a
          >
        </div>
      </div>
      <div class="re-call-hold" *ngIf="holdCall">
        <div class="re-holdname">
          <img class="mr-1" src="assets/images/icon-hold.svg" alt="hold" />
          {{ propertyDetails?.First_Name ? propertyDetails?.First_Name : "" }}
          {{ propertyDetails?.Last_Name ? propertyDetails?.Last_Name : "" }}
          on hold
        </div>
      </div>
      <!-- Top Slider -->
      <div class="re-call-slider" *ngIf="isCurrentCall && !holdCall">
        <h3 class="re-count-title">
          Current Callee ({{
            phoneData?.length == 0 ? "0" : currentNumber + 1
          }}/{{ phoneData.length }})
        </h3>
        <h4 class="re-name">
          {{ propertyDetails?.First_Name ? propertyDetails?.First_Name : "" }}
          {{ propertyDetails?.Last_Name ? propertyDetails?.Last_Name : "" }}
        </h4>
        <div
          id="nameslide"
          class="carousel slide"
          data-ride="false"
          data-interval="false"
          data-wrap="false"
          *ngIf="phoneData.length"
        >
          <div class="carousel-inner">
            <ng-container *ngFor="let eachPhone of phoneData; let i = index">
              <div
                class="carousel-item"
                [class.active]="i == currentViewNumber"
              >
                <div class="re-slidata">
                  <span class="re-number"
                    >{{ formatPhoneNumber(eachPhone?.phone, eachPhone?.type) }}
                  </span>
                </div>
              </div>
            </ng-container>
          </div>
          <a
            class="carousel-control-prev"
            href="#nameslide"
            role="button"
            data-slide="prev"
            id="prevBtn"
            [pTooltip]="
              currentNumber != 0 ? 'Previous contact in the property' : ''
            "
            tooltipPosition="bottom"
            [class]="
              makingCall
                ? 're-disable-btn-call'
                : sliderBtn
                ? 're-disable-btn-call'
                : ''
            "
            (click)="setCurrentNumber('P')"
          >
            <img src="assets/images/arrow-left.svg" alt="arrow-left" />
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#nameslide"
            role="button"
            data-slide="next"
            id="nextBtn"
            [pTooltip]="
              currentNumber + 1 != phoneData?.length
                ? 'Next contact in the property'
                : ''
            "
            [class]="
              makingCall
                ? 're-disable-btn-call'
                : sliderBtn
                ? 're-disable-btn-call'
                : ''
            "
            (click)="setCurrentNumber('N')"
          >
            <img src="assets/images/arrow-right.svg" alt="arrow-right" />
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
      <!-- Make call -->
      <div class="re-call-next" *ngIf="defaultDiaplay">
        <a
          class="btn-call"
          href="javascript:;"
          (click)="makeCall()"
          [class.btn-disable]="phoneData.length == 0"
          *ngIf="_utilities.userAccessData?.isListStackingDialerAccess"
          [pTooltip]="
            phoneData?.length == 0
              ? 'You do not have any phone number in this property'
              : ''
          "
          tooltipPosition="bottom"
          ><img
            src="assets/images/icon-call-next-number-white.svg"
            alt="contact"
          />
          <span>Call</span>
        </a>
        <a
          href="javascript:;"
          class="btn-contact"
          (click)="setNumber('N')"
          [class.btn-disable]="
            callBtnAction ||
            phoneData.length == 0 ||
            currentNumber + 1 == phoneData.length
          "
          ><img src="assets/images/icon-todo-list.svg" alt="contact" />
          <span>Next Number</span></a
        >
        <a
          href="javascript:;"
          (click)="nextProperty()"
          [ngClass]="
            currentContact + 1 == totalPropertyCount
              ? 'btn-contact btn-disable'
              : 'btn-contact'
          "
          ><img src="assets/images/icon-contact-teal.svg" alt="contact" />
          <span>Next Property</span></a
        >
        <a
          [ngClass]="
            currentContact == 0 ? 'btn-contact btn-disable' : 'btn-contact'
          "
          href="javascript:;"
          (click)="prevProperty()"
          ><img src="assets/images/icon-contact-teal.svg" alt="contact" />
          <span>Previous Property</span></a
        >
      </div>
      <!-- During-call -->
      <div class="re-dial-screen" *ngIf="makingCall">
        <div class="">
          <h3 class="re-count" *ngIf="callReady">{{ callDuration }}</h3>
          <h3 class="re-count" *ngIf="!callReady">00:00:00</h3>
          <div class="re-callnum">
            Calling from
            <span class="re-numb">{{
              _utilities.maskNumber(callingFrom)
            }}</span>
          </div>
          <div
            class="re-call-dropvm"
            *ngIf="callReady"
            (click)="dropVm()"
            pTooltip="Drop pre recorded message after beep"
            tooltipPosition="bottom"
          >
            Drop VM
          </div>
        </div>
        <div class="re-callbtn">
          <div class="re-callfeature">
            <a
              class="re-callicon re-mute"
              href="javascript:;"
              *ngIf="muteCall"
              (click)="unmute()"
              ><img src="assets/images/icon-micoff.svg" alt="micoff"
            /></a>
            <a
              class="re-callicon"
              href="javascript:;"
              *ngIf="!muteCall"
              (click)="mute()"
              ><img src="assets/images/icon-micoff.svg" alt="micoff"
            /></a>
            <a class="re-callicon" href="javascript:;" (click)="dialPad()"
              ><img src="assets/images/icon-dialpad.svg" alt="dialpad"
            /></a>
            <a class="re-callicon d-none" href="javascript:;"
              ><img src="assets/images/icon-addcall.svg" alt="addcall"
            /></a>
            <a class="re-callicon" href="javascript:;" *ngIf="multipleCall"
              ><img
                src="assets/images/icon-participants.svg"
                alt="participants"
            /></a>
            <!-- <a
              class="re-callicon"
              href="javascript:;"
              *ngIf="!holdCall"
              (click)="hold()"
              pTooltip="Hold with music"
              tooltipPosition="bottom"
              ><img src="assets/images/Call-hold.png" alt="transfer"
            /></a>
            <a
              class="re-callicon re-hold"
              href="javascript:;"
              *ngIf="holdCall"
              (click)="unHold()"
              pTooltip="Resume call"
              tooltipPosition="bottom"
              ><img src="assets/images/Call-hold.png" alt="transfer"
            /></a> -->
          </div>
          <div class="re-callfeature">
            <a class="re-callicon re-disable d-none" href="javascript:;"
              ><img src="assets/images/icon-merge.svg" alt="merge"
            /></a>
            <!-- <a
              class="re-callicon d-none"
              href="javascript:;"
              *ngIf="!holdCall"
              (click)="hold()"
              pTooltip="Hold with music"
              tooltipPosition="bottom"
              ><img src="assets/images/Call-hold.png" alt="transfer"
            /></a>
            <a
              class="re-callicon re-hold d-none"
              href="javascript:;"
              *ngIf="holdCall"
              (click)="unHold()"
              pTooltip="Resume call"
              tooltipPosition="bottom"
              ><img src="assets/images/Call-hold.png" alt="transfer"
            /></a> -->
          </div>
          <a class="re-callonoff" href="javascript:;" (click)="cancel()"
            ><img src="assets/images/call-off.png" alt="call-off" />
          </a>
        </div>
      </div>
      <div class="re-dialpad" *ngIf="isDialPad">
        <div class="re-dialpad-top">
          <div class="text-right">
            <a class="re-dialclose" (click)="closeDialPad()"
              ><img src="assets/images/close-black-1.svg" alt="close-black"
            /></a>
          </div>
          <h3 class="re-count" *ngIf="callReady">{{ callDuration }}</h3>
          <h3 class="re-count" *ngIf="!callReady">00:00:00</h3>
          <div class="re-callnum">
            Calling from
            <span class="re-numb">{{
              defultNumber
                ? _utilities.maskNumber(defultNumber)
                : _utilities.maskNumber(
                    allPhoneNumberList[this.currentNumberCall]
                  )
            }}</span>
          </div>
        </div>
        <div class="re-dialpad-bottom">
          <div class="re-numbshow">
            {{ displayNumberToCall }}
            <img
              class="re-errase"
              src="assets/images/clear-icon.png"
              alt="backspace"
              (click)="clearDialer()"
            />
          </div>
          <div class="re-keypad">
            <div class="re-row">
              <div class="re-digit" (click)="dialer('1')">1</div>
              <div class="re-digit" (click)="dialer('2')">2</div>
              <div class="re-digit" (click)="dialer('3')">3</div>
            </div>
            <div class="re-row">
              <div class="re-digit" (click)="dialer('4')">4</div>
              <div class="re-digit" (click)="dialer('5')">5</div>
              <div class="re-digit" (click)="dialer('6')">6</div>
            </div>
            <div class="re-row">
              <div class="re-digit" (click)="dialer('7')">7</div>
              <div class="re-digit" (click)="dialer('8')">8</div>
              <div class="re-digit" (click)="dialer('9')">9</div>
            </div>
            <div class="re-row">
              <div class="re-digit" (click)="dialer('*')">*</div>
              <div class="re-digit" (click)="dialer('0')">0</div>
              <div class="re-digit" (click)="dialer('#')">#</div>
            </div>
          </div>
          <div class="text-center">
            <a class="re-callonoff" href="javascript:;" (click)="cancel()"
              ><img src="assets/images/call-off.png" alt="call-off"
            /></a>
          </div>
        </div>
      </div>
      <!-- After calling -->
      <div class="re-call-ended" *ngIf="redialCall">
        <div class="re-callon-info">
          <h3 class="re-calltime">Call Ended ({{ callEndDuration }})</h3>
          <span class="re-name"
            >{{
              propertyDetails?.First_Name ? propertyDetails?.First_Name : ""
            }}
            {{
              propertyDetails?.Last_Name ? propertyDetails?.Last_Name : ""
            }}</span
          >
          <span class="re-number">{{
            _utilities.maskNumber(numberToCall)
          }}</span>
          <span class="re-endtime">Ended {{ callEndtime }}</span>
        </div>
        <a
          class="btn-redial"
          href="javascript:;"
          (click)="makeCall()"
          [pTooltip]="
            phoneData?.length == 0
              ? 'You do not have any phone number in this property'
              : ''
          "
          tooltipPosition="bottom"
          [class.re-disable]="
            !callStatus || callBtnAction || phoneData?.length == 0
          "
        >
          <img src="assets/images/icon-redial.svg" alt="redial" />
          <span>Redial</span></a
        >
        <a
          href="javascript:;"
          (click)="
            dialerType == 'auto-dialer'
              ? callSetCurrentNumber('N')
              : setNumber('N')
          "
          class="btn-redial"
          [class.re-disable]="
            !callStatus ||
            callBtnAction ||
            phoneData.length == 0 ||
            currentNumber + 1 == phoneData.length
          "
          ><img src="assets/images/icon-todo-list.svg" alt="contact" />
          <span>{{
            dialerType == "auto-dialer" ? "Call Next Number" : "Next Number"
          }}</span></a
        >
        <a
          href="javascript:;"
          (click)="
            dialerType == 'auto-dialer' ? nextProperty('call') : nextProperty()
          "
          class="btn-nextcall"
          [class.re-disable]="
            !callStatus ||
            callBtnAction ||
            currentContact + 1 == totalPropertyCount
          "
          ><img src="assets/images/icon-contact.svg" alt="contact" />
          <span>{{
            dialerType == "auto-dialer" ? "Call Next Property" : "Next Property"
          }}</span></a
        >
        <a
          href="javascript:;"
          class="btn-nextcall"
          [class.re-disable]="
            !callStatus || callBtnAction || currentContact == 0
          "
          (click)="
            dialerType == 'auto-dialer' ? prevProperty('call') : prevProperty()
          "
          ><img src="assets/images/icon-contact.svg" alt="contact" />
          <span>{{
            dialerType == "auto-dialer"
              ? "Call Previous Property"
              : "Previous Property"
          }}</span></a
        >
      </div>
    </div>
  </div>
  <div
    [ngClass]="
      showCallEndedPopup && dialerType == 'auto-dialer'
        ? 're-dialer-result re-dialer-resultfull'
        : 're-dialer-result d-none'
    "
  >
    <div class="re-dialer-resultsub">
      <div class="re-result-address">
        <h3 class="re-txt1">
          {{ propertyDetails?.Formated_Address || "N/A" }}
        </h3>
        <h5 class="re-txt2">
          {{ propertyDetails?.First_Name ? propertyDetails?.First_Name : "" }}
          {{ propertyDetails?.Last_Name ? propertyDetails?.Last_Name : "" }} •
          {{ _utilities.maskNumber(numberToCall) }}
        </h5>
        <a
          class="btn-red"
          (click)="redialMakeCall('stop')"
          [class.re-disable]="!callStatus"
          ><img src="assets/images/close-red.svg" alt="close" />Exit Dialer</a
        >
      </div>
      <div class="re-result-right">
        <div class="re-expand-div">
          <span class="re-tit">SEND SMS</span>
          <!-- <span
        class="re-expand"
        pTooltip="Minimize call window"
        tooltipPosition="bottom"
        (click)="toggleDisplay()"
        *ngIf="!isMinimized"
      >
        <img src="assets/images/icon-clone.svg" alt="clone" />
      </span>
      <span
        pTooltip="Close call window"
        tooltipPosition="bottom"
        mat-dialog-close
        [ngClass]="
          showCallEndedPopup && dialerType == 'auto-dialer'
            ? 're-close re-close-disable'
            : 're-close'
        "
      >
        <img src="assets/images/close-red.svg" alt="close" />
      </span> -->
        </div>
        <div class="re-form-div">
          <form
            [formGroup]="smsDetailForm"
            (ngSubmit)="sendSms()"
            autocomplete="off"
            novalidate
          >
            <div class="form-group">
              <label class="re-label">Send To</label>
              <select
                class="form-control"
                formControlName="smsNumber"
                name="smsNumber"
                (change)="setSmsTemplate(smsDetailForm.value.smsTemplateId)"
              >
                <option
                  *ngFor="let eachPhone of phoneData; let i = index"
                  [selected]="i == currentNumber"
                  value="{{ i }}"
                  [selected]="currentNumber === i"
                >
                  {{ formatPhoneNumber(eachPhone?.phone, eachPhone?.type) }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label class="re-label">SMS Template</label>
              <select
                class="form-control"
                formControlName="smsTemplateId"
                name="smsTemplateId"
                (change)="setSmsTemplate($event.target.value, true)"
              >
                <option value="">Select SMS Template</option>
                <option *ngFor="let sms of smsTemplateList" [value]="sms._id">
                  {{ sms?.templateName }}
                </option>
              </select>
            </div>
            <div class="form-group re-message-container">
              <label class="re-label">Message text</label>
              <quill-editor
                #editor
                name="messageText"
                formControlName="smsBody"
                [styles]="editorStyle"
                [modules]="{
                  toolbar: false,
                  mention: editorOptions.mention
                }"
                [placeholder]="editorOptions.placeholder"
                (ngModelChange)="getSMSCount($event)"
              ></quill-editor>
              <div class="invalid-feedback mt-1">
                <span
                  *ngIf="submittedSms && hasErrorSms('smsBody', 'required')"
                >
                  *Please enter Message
                </span>
              </div>
            </div>
            <div class="form-group">
              <span class="re-attach-title">ATTACHMENTS</span>
              <div class="re-attach-list">
                <div
                  class="re-attach"
                  *ngFor="
                    let attachment of showAttachments;
                    let attachmentIndex = index
                  "
                >
                  <span> {{ attachment?.name }} </span
                  ><img
                    src="/assets/images/close-black.svg"
                    (click)="removeExistingAttachment(attachmentIndex)"
                  />
                </div>
                <div
                  class="re-attach"
                  *ngFor="
                    let attachment of attachments;
                    let attachmentIndex = index
                  "
                >
                  <span> {{ attachment?.name }} </span
                  ><img
                    src="/assets/images/close-black.svg"
                    (click)="removeAttachment(attachmentIndex)"
                  />
                </div>
              </div>
              <span class="re-file-attach"
                ><input
                  type="file"
                  name="mediaUrls"
                  formControlName="mediaUrls"
                  #uploader
                  ngFileSelect
                  multiple
                  (uploadOutput)="onUploadLogo($event)"
                  [uploadInput]="uploadInput"
                /><img
                  src="assets/images/inventory-details/attachment-symbol.png"
                  alt="attachment"
                />
                Add attachments
              </span>
            </div>
            <div class="form-group">
              <button
                class="btn btn-submit"
                [class.re-disable-sms]="
                  _utilities.userAccessData?.isBasicDialer
                "
              >
                Send SMS
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="re-result-left">
        <div class="re-result-infos">
          <!-- <div class="re-result-address">
          <h3 class="re-txt1">
            {{ propertyDetails?.Formated_Address || "N/A" }}
          </h3>
          <h5 class="re-txt2">
            {{ propertyDetails?.Full_Name }}
          </h5>
        </div> -->
          <div class="re-result-note">
            <div class="">
              <label class="re-lab">Notes</label>
              <textarea
                class="form-control"
                rows="3"
                placeholder="Enter your note and it will auto save once you click Redial or Call Next Number or Call Next Contact or Stop Dialing"
                name="comment"
                [(ngModel)]="comment"
              ></textarea>
            </div>
          </div>
          <div class="re-result-checkbx">
            <h5 class="re-result-title">CALL RESULT</h5>
            <div class="row">
              <div class="col-12 col-lg-6">
                <h5 class="re-heading1">LEAD CONNECTED</h5>
                <div class="form-group">
                  <label class="re-lab">Put on DNC</label>
                  <div class="radiolist">
                    <label
                      class="radiobtn"
                      [class.re-lable-disable]="isPropertyToLead"
                    >
                      <input
                        type="radio"
                        [value]="callConnectedStatus[0].value"
                        name="connectAutoDial"
                        [(ngModel)]="callStatus"
                      /><span class="checkmark"></span
                      >{{ callConnectedStatus[0].name }}
                    </label>
                    <label
                      class="radiobtn"
                      [class.re-lable-disable]="isPropertyToLead"
                    >
                      <input
                        type="radio"
                        [value]="callConnectedStatus[1].value"
                        name="connectAutoDial"
                        [(ngModel)]="callStatus"
                      /><span class="checkmark"></span
                      >{{ callConnectedStatus[1].name }}
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="re-lab">Schedule Call</label>
                  <div class="radiolist">
                    <label
                      class="radiobtn"
                      [class.re-lable-disable]="isPropertyToLead"
                    >
                      <input
                        type="radio"
                        [value]="callConnectedStatus[2].value"
                        name="connectAutoDial"
                        [(ngModel)]="callStatus"
                      /><span class="checkmark"></span
                      >{{ callConnectedStatus[2].name }}
                    </label>
                  </div>
                  <div class="re-schedule-div2" *ngIf="callStatus == '3'">
                    <p-calendar
                      appendTo="body"
                      [showIcon]="true"
                      [icon]="'fa fa-calendar'"
                      [showTime]="false"
                      [(ngModel)]="scheduleDate"
                      placeholder="__/__/____"
                      readonlyInput="true"
                      [minDate]="minimumDate"
                    ></p-calendar>
                    <p-calendar
                      appendTo="body"
                      [showIcon]="true"
                      [icon]="'fa fa-clock-o'"
                      [timeOnly]="true"
                      inputId="timeonly"
                      hourFormat="12"
                      [(ngModel)]="scheduleTime"
                      [minDate]="minimumTime"
                      placeholder="__ : __"
                      readonlyInput="true"
                    ></p-calendar>
                    <!-- <select class="form-control" [(ngModel)]="scheduleZone">
                <option value="Los Angeles">Los Angeles</option>
                <option value="Canada">Canada</option>
              </select> -->
                  </div>
                </div>
                <div class="form-group">
                  <label class="re-lab">Move to Lead</label>
                  <div class="radiolist">
                    <label
                      class="radiobtn w-auto"
                      [class.re-lable-disable]="propertyDetails?.InLead"
                    >
                      <input
                        type="radio"
                        [value]="callConnectedStatus[3].value"
                        (click)="propertyDetails?.InLead ? '' : convertToLead()"
                        name="connectAutoDial"
                        [(ngModel)]="callStatus"
                      /><span class="checkmark"></span>Convert to Lead
                    </label>
                    <span
                      *ngIf="propertyDetails?.InLead"
                      class="re-dropdown-already-lead"
                      >(Already a Lead)</span
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label class="re-lab">Remove from all Marketing</label>
                  <div class="radiolist">
                    <label
                      class="radiobtn"
                      [class.re-lable-disable]="isPropertyToLead"
                    >
                      <input
                        type="radio"
                        [value]="callConnectedStatus[4].value"
                        name="connectAutoDial"
                        [(ngModel)]="callStatus"
                      /><span class="checkmark"></span
                      >{{ callConnectedStatus[4].name }}
                    </label>
                    <label
                      class="radiobtn"
                      [class.re-lable-disable]="isPropertyToLead"
                    >
                      <input
                        type="radio"
                        [value]="callConnectedStatus[5].value"
                        name="connectAutoDial"
                        [(ngModel)]="callStatus"
                      /><span class="checkmark"></span
                      >{{ callConnectedStatus[5].name }}
                    </label>
                    <label
                      class="radiobtn"
                      [class.re-lable-disable]="isPropertyToLead"
                    >
                      <input
                        type="radio"
                        [value]="callConnectedStatus[6].value"
                        name="connectAutoDial"
                        [(ngModel)]="callStatus"
                      /><span class="checkmark"></span
                      >{{ callConnectedStatus[6].name }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <h5 class="re-heading2">CALL NOT CONNECTED</h5>
                <div class="form-group">
                  <div class="radiolist">
                    <label
                      class="radiobtn"
                      [class.re-lable-disable]="isPropertyToLead"
                      *ngFor="let callNotStatus of callNotConnectedStatus"
                    >
                      <input
                        type="radio"
                        name="connectAutoDial"
                        [value]="callNotStatus?.value"
                        [(ngModel)]="callStatus"
                      /><span class="checkmark"></span>{{ callNotStatus?.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="form-group"
              *ngIf="callStatus == '6' || callStatus == '14'"
            >
              <textarea
                class="form-control"
                rows="2"
                placeholder="Enter Reason"
                name="otherReason"
                [(ngModel)]="otherReason"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="re-result-btnlist">
          <a
            class="btn-teal1"
            [class.re-disable]="!callStatus"
            (click)="redialMakeCall('redial')"
            ><img src="assets/images/icon-redial.svg" alt="redial" /> Redial</a
          >
          <a
            [class.disabled]="!callStatus"
            href="javascript:;"
            (click)="redialMakeCall('nextProperty')"
            [ngClass]="
              currentContact + 1 == totalPropertyCount || !callStatus
                ? 'btn-teal2 re-disable'
                : 'btn-teal2'
            "
            ><img src="assets/images/icon-contact.svg" alt="contact" />Call Next
            Property</a
          >
          <a
            class="btn-teal1"
            [class.re-disable]="
              phoneData.length == 0 ||
              currentNumber + 1 == phoneData.length ||
              !callStatus
            "
            (click)="redialMakeCall('nextNumber')"
            ><img src="assets/images/icon-todo-list.svg" alt="call" /> Call Next
            Number</a
          >
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
