<div class="autofollow_modal">
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        style="display: block !important"
        class="w-100 d-flex justify-content-between"
      >
        Auto Follow-up
      </h2>
      <span mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>
  <form
    [formGroup]="autoFollowUpForm"
    (ngSubmit)="onSubmit()"
    autocomplete="off"
    novalidate
  >
    <mat-dialog-content class="">
      <div class="container-fluid p-0">
        <div class="box re-padd-20">
          <div class="row">
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="re-labels">Choose Auto Follow-up Sequence</label>
                <select
                  class="form-control"
                  name="dripCompaignId"
                  formControlName="dripCompaignId"
                  (change)="
                    getDripTime(autoFollowUpForm.controls.dripCompaignId.value)
                  "
                  [attr.disabled]="stop ? 'disabled' : null"
                >
                  <option value="">--Select--</option>
                  <option *ngFor="let drip of dripList" value="{{ drip._id }}">
                    {{ drip.name }}
                  </option>
                </select>

                <div class="text-danger re-error">
                  <span
                    *ngIf="submitted && hasError('dripCompaignId', 'required')"
                  >
                    {{
                      messageConstant.requiredField.replace(
                        "dripCompaignId",
                        "Drip"
                      )
                    }}
                  </span>
                </div>
                <p class="sublabel" *ngIf="selectedDrip && !toggle">
                  This Drip Campaign will send first message in
                  {{ selectedDrip?.breakDown[0].duration }}
                  {{ selectedDrip?.breakDown[0].time }} from being activated.
                </p>
                <!-- <p class="sublabel" *ngIf="toggle && selectedNewDrip">
                  This Drip Campaign will send first message in
                  {{ selectedNewDrip }} from being activated.
                </p> -->
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!stop">
          <hr />
          <div class="box">
            <div class="row">
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <div class="radiosec">
                    <p class="radotext">
                      Would you like to change the timing of the 1st message?
                    </p>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="checkbox1"
                        (click)="onToggle()"
                        name="activeTimeChange"
                        formControlName="activeTimeChange"
                      />
                      <span class="slider"></span>
                      <span class="text1" value="false">No</span>
                      <span class="text2" value="true">Yes</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-12 col-sm-12" *ngIf="toggle === true">
                <div id="radiobtnlist">
                  <div class="row">
                    <div class="col-12 col-md-12 col-sm-12">
                      <p class="sublabel sublabel1">
                        When would you like the 1st step in this Drip Campaign
                        to start?
                      </p>
                    </div>
                    <div class="col-12 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="re-labels">Duration</label>
                        <select
                          class="form-control"
                          name="duration"
                          formControlName="duration"
                          (change)="
                            getNewDripTime(
                              autoFollowUpForm.controls.duration.value,
                              autoFollowUpForm.controls.timeSpan.value
                            )
                          "
                        >
                          <option
                            *ngFor="let number of minutesList"
                            value="{{ number }}"
                          >
                            {{ number }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-12 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="re-labels">Time Span</label>
                        <select
                          class="form-control"
                          name="timeSpan"
                          formControlName="timeSpan"
                          (change)="
                            getNewDripTime(
                              autoFollowUpForm.controls.duration.value,
                              autoFollowUpForm.controls.timeSpan.value
                            )
                          "
                        >
                          <option
                            *ngFor="let timeType of taskTimeTypeList"
                            value="{{ timeType }}"
                          >
                            {{ timeType }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-12 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="re-labels">&nbsp;</label>
                        <p class="sublabel1 d-inline-block">
                          From being activated
                        </p>
                      </div>
                    </div>
                    <div class="col-md-12 col-sm-12">
                      <p class="sublabel">
                        Based on your settings, the 1st step will be completed
                        on <b>{{ selectedNewDrip }}</b>
                      </p>
                      <p class="graytext">
                        (The timing for any step after the 1st step will get
                        updated automatically based on new time for the 1st
                        step)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="box">
            <div class="row">
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <div class="radiosec">
                    <p class="radotext">
                      Would you like to auto stop the drip campaign once you
                      receive a call or SMS from the lead?
                    </p>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="checkbox2"
                        name="autoStopDrip"
                        formControlName="autoStopDrip"
                      />
                      <span class="slider"></span>
                      <span class="text1">No</span>
                      <span class="text2">Yes</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <hr style="margin-bottom: 10px !important" />
    <mat-dialog-actions align="center" style="padding-top: 5px !important">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button
        *ngIf="!stop"
        mat-raised-button
        color="primary"
        type="submit"
        cdkFocusInitial
      >
        Set Auto Follow-up
      </button>
      <button
        *ngIf="stop"
        mat-raised-button
        color="warn"
        cdkFocusInitial
        (click)="onStop()"
      >
        Stop
      </button>
    </mat-dialog-actions>
  </form>
</div>
