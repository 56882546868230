<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      style="display: block !important"
      class="w-100 d-flex justify-content-between"
    >
      Remove {{ moduleName }}
    </h2>
    <span matDialogClose mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<mat-dialog-content class="re-typography">
  <div class="container">
    <div
      *ngIf="this.tagsData.length == 0 && this.blankMsg == ''"
      class="re-loader-td"
    >
      <img class="re-loader-media" src="./assets/images/loading.gif" />
    </div>
    <div
      *ngIf="this.tagsData.length == 0 && this.blankMsg != ''"
      class="re-loader-td"
    >
      {{ blankMsg }}
    </div>
    <div class="row">
      <div class="col-12 col-md-12" *ngIf="this.tagsData.length > 0">
        <label class="re-labels" for="inputCampaign4"
          >All {{ moduleName }}</label
        >
        <div class="row tag_list" *ngFor="let eachTag of tagsData">
          <div
            class="col-8 col-md-6 col-sm-12 tag_list_val remove"
            *ngIf="removedTags.indexOf(eachTag?._id) > -1"
          >
            <del *ngIf="this.data?.for != 'stacking'">{{ eachTag?.label }}</del>
            <del *ngIf="this.data?.for == 'stacking'">{{ eachTag?.name }}</del>
          </div>
          <div
            class="col-8 col-md-6 col-sm-12 tag_list_val"
            *ngIf="removedTags.indexOf(eachTag?._id) == -1"
          >
            <span
              class="re-tagname"
              *ngIf="this.data?.for != 'stacking'"
              [style.color]="
                colorCodes[eachTag?.colorIndex ? eachTag?.colorIndex : 0]?.color
              "
              >{{ eachTag?.label }}</span
            >
            <span class="re-tagname" *ngIf="this.data?.for == 'stacking'">{{
              eachTag?.name
            }}</span>
          </div>
          <div
            class="col-4 col-md-6 col-sm-12 tag_list_action remove"
            *ngIf="removedTags.indexOf(eachTag?._id) == -1"
          >
            <a
              href="javascript:void(0)"
              (click)="removeUndo(eachTag?._id, 'remove')"
              class="re-remove"
              >Remove</a
            >
          </div>
          <div
            class="col-4 col-md-6 col-sm-12 tag_list_action"
            *ngIf="removedTags.indexOf(eachTag?._id) > -1"
          >
            <a
              href="javascript:void(0)"
              (click)="removeUndo(eachTag?._id, 'undo')"
              class="re-undo"
              >Undo</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="padding-top: 14px !important">
  <button mat-raised-button [mat-dialog-close]="false" class="re-cancelbtn">
    Cancel
  </button>
  <button
    (click)="submitForm()"
    mat-raised-button
    cdkFocusInitial
    class="re-submit-btn"
  >
    Remove {{ moduleName }}
  </button>
</mat-dialog-actions>
