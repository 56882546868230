import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// SERVICES
import { SharedService } from 'src/app/shared/shared.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

// UTILS
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';

@Component({
  selector: 'app-save-template-dialog',
  templateUrl: './save-template-dialog.component.html',
  styleUrls: ['./save-template-dialog.component.scss'],
})
export class SaveTemplateDialogComponent implements OnInit {
  templateForm: FormGroup;
  submitted: boolean = false;
  messageConstant = MessageConstant;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<SaveTemplateDialogComponent>,
    private _sharedService: SharedService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.templateForm = this._formBuilder.group({
      label: ['', [Validators.required]],
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.templateForm.controls[controlName].hasError(errorName);
  };

  onSubmit() {
    this.submitted = true;
    if (this.templateForm.invalid) {
      return;
    }

    const obj = {
      ...this.templateForm.value,
      moduleId: this.data.moduleId,
      template: JSON.stringify(this.data.designPart),
    };

    setTimeout(() => {
      if (document.querySelector('.re-sendbulk-dialog'))
        document.querySelector('.re-sendbulk-dialog')['click']();
    }, 500);
    this._loaderService.start();
    this._sharedService.saveEmailTemplates(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          setTimeout(() => {
            if (document.querySelector('.re-sendbulk-dialog'))
              document.querySelector('.re-sendbulk-dialog')['click']();
          }, 500);
          this._dialogRef.close(true);
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        setTimeout(() => {
          if (document.querySelector('.re-sendbulk-dialog'))
            document.querySelector('.re-sendbulk-dialog')['click']();
        }, 500);
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(
            error.message || this.messageConstant.unknownError,
            ''
          );
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
}
