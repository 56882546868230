<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      {{ data?.subModule }}
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<mat-dialog-content class="re-typography">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-12 col-sm-12">
        <div class="form-group">
          <label for="inputCampaign4">Tags To Add</label>
          <p-chips
            [allowDuplicate]="false"
            placeholder="Enter Tags"
            [(ngModel)]="allTagsToAdd"
          ></p-chips>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center" style="padding-top: 12px !important">
  <button mat-raised-button [mat-dialog-close]="false" class="re-cancel-btn">
    Cancel
  </button>
  <button
    (click)="submitForm()"
    mat-raised-button
    cdkFocusInitial
    class="re-submit-btn"
    [disabled]="allTagsToAdd?.length == 0"
  >
    {{ data?.subModule }}
  </button>
</mat-dialog-actions>
