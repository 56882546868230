<div class="re-notification-container">
  <!-- LEFT CONTAINER -->
  <div class="re-icon-container">
    <img [src]="getIcon()?.url" *ngIf="getIcon()?.image" />
    <span *ngIf="!getIcon()?.image" class="re-initials">
      {{ getIcon()?.url }}
    </span>
    <p
      *ngIf="
        data?.data?.activityType == 45 ||
        data?.data?.activityType == 1 ||
        data?.data?.activityType == 4 ||
        data?.data?.activityType == 8 ||
        data?.data?.activityType == 9 ||
        data?.data?.activityType == 43
      "
      class="re-num-type"
      [ngClass]="
        data.data.numberType == 1
          ? 're-seller'
          : data.data.numberType == 2
          ? 're-buyer'
          : data.data.numberType == 4
          ? 're-vendor'
          : ''
      "
    >
      {{
        data.data.numberType == 1
          ? "S"
          : data.data.numberType == 2
          ? "B"
          : data.data.numberType == 4
          ? "V"
          : ""
      }}
    </p>
  </div>

  <!-- RIGHT CONTAINER -->
  <div class="re-notification-body-container">
    <div class="re-heading">
      <div class="re-number">
        <ng-container
          *ngIf="
            data?.data?.activityType == 1 ||
            data?.data?.activityType == 4 ||
            data?.data?.activityType == 43 ||
            data?.data?.activityType == 45 ||
            data?.data?.activityType == 49 ||
            data?.data?.activityType == 50
          "
        >
          {{ _utils.titleCaseToWord(data?.data?.leadTitle) || "N/A" }}
          •
          {{ data?.data?.from ? _utils.maskNumber(data?.data?.from) : "N/A" }}
        </ng-container>
        <ng-container
          *ngIf="
            data?.data?.activityType == 44 ||
            data?.data?.activityType == 37 ||
            data?.data?.activityType == 46 ||
            data?.data?.activityType == 8 ||
            data?.data?.activityType == 9 ||
            data?.data?.activityType == 10 ||
            data?.data?.activityType == 18 ||
            data?.data?.activityType == 51 ||
            data?.data?.activityType == 52 ||
            data?.data?.activityType == 53
          "
        >
          {{ data?.data?.title || "N/A" }}
        </ng-container>
        <ng-container
          *ngIf="
            data?.data?.activityType == 42 || data?.data?.activityType == 47
          "
        >
          {{
            data?.data?.leadTitle
              ? data?.data?.leadTitle
              : data?.data?.createdByUser?.name || "N/A"
          }}
          •
          {{
            data?.data?.createdByUser?.contactNumber || data?.data?.from
              ? _utils.maskNumber(
                  data?.data?.leadTitle
                    ? data?.data?.from
                    : data?.data?.createdByUser?.contactNumber
                )
              : "N/A"
          }}
        </ng-container>
      </div>
    </div>

    <div class="re-sub-heading">
      <span class="re-incoming-type"> {{ getHighlightedData(data) }} </span>

      <ng-container
        *ngIf="
          data?.data?.activityType != 1 &&
            data?.data?.activityType != 4 &&
            data?.data?.activityType != 45 &&
            data?.data?.activityType != 9 &&
            data?.data?.activityType != 8;
          else else_content
        "
      >
        <a
          class="re-link"
          href="javascript:void(0);"
          (click)="goToAddress()"
          *ngIf="
            data?.data?.activityType == 37 || data?.data?.activityType == 46
          "
        >
          {{ _utils.titleCaseToWord(data?.data?.leadTitle) || "N/A" }}
        </a>
        <span
          *ngIf="
            data?.data?.activityType == 37 ||
            (data?.data?.activityType == 46 && data?.data?.address)
          "
        >
          •
        </span>
        <span *ngIf="data?.data?.offerPurchasePrice">
          {{ data?.data?.offerPurchasePrice | currency | currencyPositive }}
          •
        </span>
        <a
          class="re-link"
          href="javascript:void(0);"
          (click)="goToAddress(true)"
          *ngIf="
            (data?.data?.activityType != 46 && !data?.data?.isFromDripCam) ||
            (data?.data?.activityType == 46 && data?.data?.address)
          "
        >
          <ng-container *ngIf="data?.data?.activityType == 50"
            >Claim this lead •
          </ng-container>
          {{ _utils.formatAddress(data?.data?.address) || "N/A" }}
        </a>
      </ng-container>
      <ng-template #else_content>
        <a class="re-link" href="javascript:void(0);" (click)="goToAddress()"
          >{{ redirectText() }}
        </a>
      </ng-template>
    </div>

    <span class="re-time-due" *ngIf="data?.data?.activityType == 9">
      Due: {{ data?.data?.dueTime | date : "mediumDate" }}
      {{ data?.data?.dueTime | date : "shortTime" }}
    </span>

    <div
      class="re-notification-body"
      *ngIf="
        (data?.data?.activityType == 9 && data?.data?.taskDetail) ||
        (data?.data?.activityType != 9 && data?.data?.comment)
      "
    >
      <p
        #comment
        class="re-comment re-text"
        [innerHtml]="
          replaceLineBreak(
            data?.data?.activityType == 9
              ? (data?.data?.taskDetail | mentionHighlight | textToLink)
              : (data?.data?.comment | mentionHighlight | textToLink)
          ) | safe
        "
      ></p>
      <!-- <a
        href="javascript:void(0);"
        *ngIf="
          data?.data?.activityType == 9 && comment?.innerText?.length > 110
        "
        class="re-more"
        (click)="goToAddress()"
      >
        ...More
      </a> -->
    </div>

    <div class="row re-task-bottom" *ngIf="data?.data?.activityType == 9">
      <div class="col-md-12 re-task-section">
        <span class="re-task-type" *ngIf="data?.data?.taskType">
          {{ _utils.getTaskTypeOption(data?.data?.taskType)?.name || "N/A" }}
        </span>
      </div>
    </div>
  </div>

  <div class="re-close-action" mat-dialog-close>
    <img src="./assets/images/close-grey.svg" alt="close" />
  </div>
</div>

<div class="re-action-container">
  <div
    class="re-call-container"
    *ngIf="
      data.data.activityType === 1 ||
      data.data.activityType === 4 ||
      data.data.activityType === 15 ||
      data.data.activityType === 45
    "
    (click)="call()"
  >
    <img src="./assets/images/call-notification.svg" alt="" />
    <span>Call</span>
  </div>

  <div
    class="re-reply-container"
    *ngIf="
      data.data.activityType === 1 ||
      data.data.activityType === 4 ||
      data.data.activityType === 15 ||
      data.data.activityType === 45 ||
      data.data.activityType === 8
    "
    (click)="reply()"
  >
    <img src="./assets/images/reply-notification.svg" alt="" />
    <span>Reply</span>
  </div>

  <div class="re-read-container" (click)="marAsRead()">
    <img src="./assets/images/check-uncheked.svg" alt="" />
    <span>Mark as Read</span>
  </div>

  <div class="re-date-container">
    {{ getDate() }}
  </div>
</div>
