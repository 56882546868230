import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';

//SERVICES
import { SharedService } from 'src/app/shared/shared.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

//UTILS
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';
import { MessageConstant } from 'src/app/utils/message-constant';

@Component({
  selector: 'app-reassign-leads',
  templateUrl: './reassign-leads.component.html',
  styleUrls: ['./reassign-leads.component.scss'],
})
export class ReassignLeadsComponent implements OnInit {
  messageConstant = MessageConstant;
  reassignForm: FormGroup;

  statusCount: number = 0;
  leadCount: number = 0;

  users: any[] = [];
  mainStatusIds: any[] = [];

  roleId: string = '';
  userId: string = '';
  newUserId: string = '';
  statusLead: string = '';
  assignmentMethod: string = '';
  selectedUser: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _sharedService: SharedService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private reassignDialog: MatDialogRef<ReassignLeadsComponent>
  ) {}

  onSubmit() {}

  ngOnInit(): void {
    const users = this.data.details.filter((item) => item != this.data.userId);

    this.users = [...new Set(users.flat(1))];

    const filtered = this.data.checkedAssignedStatus.filter(
      (item) => item.userId == this.data.userId
    );

    this.mainStatusIds = this.data.checkedAssignedStatus.map(
      (item) => item.mainStatusId
    );

    this.roleId = this.data.checkedAssignedStatus[0].roleId;
    this.userId = this.data.checkedAssignedStatus[0].userId;

    this.leadCount = filtered?.reduce((acc, item) => {
      acc += item.count || 0;
      return acc;
    }, 0);

    this.statusCount = filtered.length;
    this.statusLead = `${this.statusCount} Status • ${this.leadCount} Leads`;
  }

  getUserName(userId) {
    if (userId == 'null') {
      return 'N/A';
    }

    const user = this.data.userList.find((o) => o._id == userId);
    if (user) {
      return user.name;
    } else {
      return 'N/A';
    }
  }

  getRoleName(roleId) {
    let role = this.data.roles.find((o) => o._id == roleId);
    if (role) {
      return role.name;
    } else {
      return 'N/A';
    }
  }

  saveAssignment() {
    const payload = {
      marketingTitle: this.data.campaign.marketingTitle,
      number: this.data.campaign.number,
      crmQuestionId: this.data.campaign.crmQuestionId,
      flowId: this.data.campaign.flow._id,
      assignmentMethod: this.data.assignmentMethod,
      leadAccessManagement: [
        {
          roleId: this.roleId,
          mainStatusId: this.mainStatusIds,
          currentUserId: this.userId,
          newUserId: this.newUserId,
          actionType: this.data.actionType,
        },
      ],
    };

    this._loaderService.start();
    this._sharedService.updateAssignment(payload).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          this._loaderService.stop();
          this._toastrService.success(this.messageConstant.assigned);
          this.reassignDialog.close(true);
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
        this._loaderService.stop();
      }
    );
  }

  getNewUserId() {
    this.selectedUser = this.getUserName(this.newUserId);
  }
}
