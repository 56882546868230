import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TaggingService } from '../../../providers/tagging/tagging.service';
import { ListStackingService } from './../../../providers/list-stacking/list-stacking.service';

// UTILS
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';
import { ListErrorModel } from 'src/app/utils/models/error';
import { ListResponseModel } from '../../../utils/models/response';

@Component({
  selector: 'app-rename-tag',
  templateUrl: './rename-tag.component.html',
  styleUrls: ['./rename-tag.component.scss'],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class RenameTagComponent implements OnInit {
  messageConstant = MessageConstant;
  oldTagName: string = '';
  newTagName: string = '';
  displayName: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<RenameTagComponent>,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private _taggingService: TaggingService,
    private _listStackingService: ListStackingService
  ) {
    this.displayName = this.data.for ? this.data.for : 'Tag';
  }

  ngOnInit(): void {
    this.oldTagName = this.data.label;
  }

  pressRenameEnter(keycode) {
    if (keycode === 13) {
      this.submitForm();
    }
  }

  submitForm() {
    if (this.newTagName.trim().length == 0) {
      if (!this.data.for || this.data?.for == 'Tag')
        this._toastrService.error(this.messageConstant.blankTag);
      if (this.data?.for == 'List')
        this._toastrService.error(this.messageConstant.blankList);
      return false;
    }
    if (this.newTagName.length > 50) {
      this._toastrService.error(this.messageConstant.tagLengthError);
      return false;
    }
    if (this.newTagName.toUpperCase() == this.oldTagName.toUpperCase()) {
      if (!this.data.for || this.data?.for == 'Tag')
        this._toastrService.error(this.messageConstant.sameRenameTag);
      if (this.data?.for == 'List')
        this._toastrService.error(this.messageConstant.sameRenameList);
      return false;
    }
    for (let index = 0; index < this.data?.items?.length; index++) {
      const element = this.data?.items[index];
      if (this.newTagName.toUpperCase() == element?.label.toUpperCase()) {
        if (
          !this.data.for ||
          this.data?.for == 'Tag' ||
          this.data.for == undefined
        )
          this._toastrService.error(this.messageConstant.nonUniqueTag);
        if (this.data?.for == 'List')
          this._toastrService.error(this.messageConstant.nonUniqueTag);
        return false;
      }
    }

    this._loaderService.start();
    if (!this.data.for) {
      let obj = {
        label: this.newTagName.trim(),
        tagType: this.data?.tagType,
      };

      this._taggingService.renameTags(this.data._id, obj).subscribe(
        (response) => {
          this._loaderService.stop();
          if (response) {
            this.dialog.close('submit');
            // this._toastrService.success(this.messageConstant.tagRenamedSuccess);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
        }
      );
    } else if (this.data?.for == 'Tag') {
      let obj = {
        id: this.data._id,
        newName: this.newTagName.trim(),
      };
      this._listStackingService.renameTag(obj).subscribe(
        (response: ListResponseModel) => {
          this._loaderService.stop();
          if (response?.code == 200) {
            this.newTagName = '';
            this.dialog.close('submit');
            // this._toastrService.success(this.messageConstant.tagRenamedSuccess);
          } else {
            this._toastrService.error(this.messageConstant.nonUniqueTag);
          }
        },
        (err: ListErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ListResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(MessageConstant.unknownError, '');
          }
        }
      );
    } else if (this.data?.for == 'List') {
      let obj = {
        id: this.data._id,
        newName: this.newTagName.trim(),
      };
      this._listStackingService.renameList(obj).subscribe(
        (response: ListResponseModel) => {
          this._loaderService.stop();
          if (response?.code == 200) {
            this.newTagName = '';
            this.dialog.close('submit');
            this._toastrService.success(
              this.messageConstant.listRenamedSuccess
            );
          } else {
            this._toastrService.error(this.messageConstant.nonUniqueList);
          }
        },
        (err: ListErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ListResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(MessageConstant.unknownError, '');
          }
        }
      );
    }
  }
}
