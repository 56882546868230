import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrl } from 'src/app/utils/base-url-constants';
import { VersionConstant } from 'src/app/utils/version-constant';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

@Injectable({
  providedIn: 'root',
})
export class PublicService {
  baseUrl = environment.baseUrl;
  public listStackingUrl = environment.listStackingUrl;

  constructor(private _commonFunction: CommonFunctionsService) {}

  getPublicView = (type, id): Observable<any> => {
    let endpoint = this.baseUrl + VersionConstant.version;

    if (type) {
      endpoint += `${type}/publicView/`;
    }
    return this._commonFunction.globalPostService(endpoint, id);
  };
  getPublicViewFile = (type, id): Observable<any> => {
    let endpoint = this.baseUrl + VersionConstant.version;

    if (type) {
      endpoint += `${type}/publicView/${id}`;
    }

    return this._commonFunction.globalGetService(endpoint);
  };
  savePlan = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.plans + 'save';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  saveFile = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.fileUrl + 'savePublicFile';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getFile = (type, link, data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.sharedUrl + 'get-public-file';

    if (type === 'file') {
      endpoint += `?fileShareLink=${link}`;
    }

    if (type === 'folder') {
      endpoint += `?folderShareLink=${link}`;
    }
    return this._commonFunction.globalPostService(endpoint, data, {
      responseType: 'blob',
    });
  };

  deleteFile = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.fileUrl + 'deletePublicFile';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  webFormDetails = (val, type?): Observable<any> => {
    let endpoint = this.baseUrl;
    let method = 'get';

    if (type === 'Buyer') {
      endpoint += BaseUrl.buyerWebFormUrl;
    } else if (type === 'Website') {
      endpoint += BaseUrl.websiteWebFormUrl;
      method = 'post';
    } else {
      endpoint += BaseUrl.webFormUrl;
    }

    if (val) {
      endpoint += 'shareWebFormDetails';
    } else {
      endpoint += 'details';
    }
    if (method == 'get')
      return this._commonFunction.globalGetService(
        endpoint,
        this.getRequestHeaders(val)
      );
    else if (method == 'post')
      return this._commonFunction.globalPostService(
        endpoint,
        this.getRequestHeaders(val)
      );
    // return this.http[method](endpoint, this.getRequestHeaders(val)).pipe(
    //   catchError((err) => {
    //     return throwError(err);
    //   })
    // );
  };

  getLeadSource = (obj, val?): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.webFormUrl + 'CRMQuestion/leadSource';
    return this._commonFunction.globalPostService(
      endpoint,
      obj,
      this.getRequestHeaders(val)
    );
  };

  getCampaignList = (obj, val?, type?): Observable<any> => {
    let endpoint = this.baseUrl;

    if (type) {
      endpoint += BaseUrl.buyerWebFormUrl + 'campaignList';
    } else {
      endpoint += BaseUrl.webFormUrl + 'number/campaignList';
    }

    return this._commonFunction.globalPostService(
      endpoint,
      obj,
      this.getRequestHeaders(val)
    );
  };

  getPropertyFromMelissa = (address, val?): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.webFormUrl + 'melissa/property';

    if (address) {
      endpoint += `?address=${address}`;
    }
    return this._commonFunction.globalGetService(
      endpoint,
      this.getRequestHeaders(val)
    );
  };

  checkPhoneNumber = (obj, val?): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.webFormUrl + 'checkPhoneNumber';
    return this._commonFunction.globalPostService(
      endpoint,
      obj,
      this.getRequestHeaders(val)
    );
  };

  getMapLatLng = (obj, val?): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.webFormUrl + 'googleMap/streetView';
    return this._commonFunction.globalPostService(
      endpoint,
      obj,
      this.getRequestHeaders(val)
    );
  };

  createWebFormLead = (obj, data?, val?): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.webFormUrl;

    if (data) {
      endpoint += `editLead/${data?._id}`;
    } else {
      endpoint += 'createLead';
    }

    return this._commonFunction.globalPostService(
      endpoint,
      obj,
      this.getRequestHeaders(val)
    );
  };

  createWebFormBuyer = (obj, val?): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyerWebFormUrl + 'buyer/save';
    return this._commonFunction.globalPostService(
      endpoint,
      obj,
      this.getRequestHeaders(val)
    );
  };

  saveWebForm = (obj, val?): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.buyerWebFormUrl + 'edit';

    if (val) {
      endpoint += `/${val}`;
    }

    return this._commonFunction.globalPostService(
      endpoint,
      obj,
      this.getRequestHeaders(val)
    );
  };

  saveWebsiteWebForm = (obj, val?): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.websiteWebFormUrl + 'edit';

    if (val) {
      endpoint += `/${val}`;
    }

    return this._commonFunction.globalPostService(
      endpoint,
      obj,
      this.getRequestHeaders(val)
    );
  };

  insertSingleProperty = (fieldData: any, end?): Observable<any> => {
    let endpoint = this.listStackingUrl + 'liststacking/' + end;
    return this._commonFunction.globalPostService(endpoint, fieldData);
  };

  checkPropertyAddress = (data, val?): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.webFormUrl + 'checkPropertyAddress';

    return this._commonFunction.globalPostService(
      endpoint,
      data,
      this.getRequestHeaders(val)
    );
  };
  checkPropertyAddressWithUnitNo = (address, val?): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.webFormUrl + 'checkPropertyAddressWithUnitNo';

    return this._commonFunction.globalPostService(
      endpoint,
      address,
      this.getRequestHeaders(val)
    );
  };

  editWebform = (obj, id, val?): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.webFormUrl + 'edit';

    if (id) {
      endpoint += `/${id}`;
    }

    return this._commonFunction.globalPostService(
      endpoint,
      obj,
      this.getRequestHeaders(val)
    );
  };

  getActivityLog = (obj, val?): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.webFormUrl + 'activity/list';

    return this._commonFunction.globalPostService(
      endpoint,
      obj,
      this.getRequestHeaders(val)
    );
  };

  getUserRoleList = (obj, val?): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.webFormUrl + 'user_list';
    return this._commonFunction.globalPostService(
      endpoint,
      obj,
      this.getRequestHeaders(val)
    );
  };

  getMarkets = (obj, val?): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.webFormUrl + 'marketList';
    return this._commonFunction.globalPostService(
      endpoint,
      obj,
      this.getRequestHeaders(val)
    );
  };

  getCoupon = (obj): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.coupon + 'getCoupon';
    return this._commonFunction.globalPostService(
      endpoint,
      obj,
      this.getRequestHeaders()
    );
  };

  getPublicUploadUrl = (obj, id): Observable<any> => {
    let endpoint =
      this.baseUrl + BaseUrl.sharedUrl + 's3-signed-public-url-put';

    if (id) {
      endpoint += `?folderShareLink=${id}`;
    }

    return this._commonFunction.globalPostService(
      endpoint,
      obj,
      this.getRequestHeaders()
    );
  };

  // SET HEADERS
  protected getRequestHeaders(val?): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    let headers;
    let obj = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };

    if (val) {
      obj['webFormLink'] = val || localStorage.getItem('webFormLink');
    }

    headers = new HttpHeaders(obj);
    return { headers: headers };
  }
  getJSON = (filePath): Observable<any> => {
    return this._commonFunction.globalGetService(filePath);
  };
}
