import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { StatusConstant } from 'src/app/utils/status-constant';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {
  StatusChangeChecksModel,
  StatusChangeModel,
} from 'src/app/utils/models/leads-status-change';
import { timezoneConstant } from '../timezone-list-constant';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/dialog/confirmation-dialog/confirmation-dialog.component';
@Injectable({
  providedIn: 'root',
})
export class CommonFunctionsService {
  constructor(private http: HttpClient, private dialog: MatDialog) {}

  public dialogRef: any;
  public leadFilter: any;
  public buyerFilter: any = {};
  public vendorFilter: any = {};
  public activityLogFilter: any = {};
  public listFilter: any = {};
  public listDialerFilter: any = {};
  public callLoglistDialerFilter: any = {};
  public scheduledCallListDialerFilter: any = {};
  public islistFilter: boolean = false;
  public islistDialerFilter: boolean = false;
  public isCallLoglistDialerFilter: boolean = false;
  public isscheduledCallListDialerFilter: boolean = false;
  public isUniqueMailingAddress: boolean = false;
  public isFilterBuyer: boolean = false;
  public leadSort: any;
  public reduceGrid: boolean = false;
  public isDialogOpen: boolean = false;
  public isCallStatusDialogOpen: boolean = false;
  public dialogcurrentLeadId: string = '';
  public isFilterLeads: boolean = false;
  public updateAvailable: boolean = false;
  public eSignCount: number = 0;
  public leadIdArray: any[] = [];
  public currentLeadCount: number = 0;
  public currentModule: string = '';
  public newCount: number = 0;
  public newCount1: number = 0;
  public makingCallDialer: boolean = false;
  public currentTransactionTab: string = '';
  public currentUnrecognizedPage: number = 1;
  public currentRecognizedPage: number = 1;
  public currentMappedPage: number = 1;
  public currentNumberTab: number = 0;
  public dialerLeadSort: boolean = false;
  public userData: any;
  public currentPlanData: any;
  public userAccessData: any;
  public dashboardSelectedUserId: string = '';
  public isTaskFromDashboard: boolean = false;
  public isPlaid: boolean = true;
  public filterTransaction: any = {};
  public sortTransaction: any;
  public mappedApplySort: any;
  public recognizedApplySort: any;
  public unrecognizedApplySort: any;
  public isFilterTransaction: Boolean = false;
  public showAddLimitPopup: Boolean = true;
  public currentPageBuyer: number = 1;
  public isFromRecognized: boolean = false;
  public recognizedTransactionData: any;
  public currentRecognizedIndex: any;
  public isFilterVendor: boolean = false;

  public currentTransactionPage: number = 1;
  public currentTransactionIndex: number = 1;
  public currentTransactionData: any = [];
  public currentTransactionType: string = '';
  public mappedParam: any;
  public recognizedParam: any;
  public unrecognizedParam: any;
  public currentAccountId: string = '';
  public currentTransactionTypeId: string = '';
  public callType: string = 'outgoing';
  public callTypeReject: string = 'false';
  public currentMainStatusId: string = '';
  public isLeadPreviousEnable: boolean = true;
  public isLeadNextEnable: boolean = true;
  public dlcForm: any;
  public numberOfCallsRunning: number = 0;
  public inventoryFilter: any;
  public isSearchLead: boolean = false;
  public chargeSummary: any;

  public isLSDialer: boolean = false;
  public dialerCurrentCampaignId: string = '';
  public isCallStatusLead: boolean = false;

  public isTwilioUser: any;
  public isTwilioDeviceConnect: boolean = false;

  public isFromUpgradePopup: boolean = false;
  public isTwilioOngoingCall: boolean = false;
  public currentCallSid: string = '';
  public isShowSettingsRoute: boolean = false;

  public isSocketConnected: boolean = false;

  progressPercentage: string = '100';
  userProgressPercentage: number = 0;
  progressListStackingData: any;

  public changeLeadStatusObject: StatusChangeModel[] = [
    {
      key: 'NEW_LEAD',
      showQuestion: false,
      isVisibleKey: '',
    },
    {
      key: 'NO_CONTACT_MADE',
      showQuestion: false,
      isVisibleKey: '',
    },
    {
      key: 'CONTACT_MADE',
      showQuestion: false,
      isVisibleKey: '',
    },
    {
      key: 'APPOINTMENT_SET',
      showQuestion: false,
      isVisibleKey: 'isAppointmentSet',
    },
    {
      key: 'DUE_DILIGENCE',
      showQuestion: false,
      isVisibleKey: '',
    },
    {
      key: 'OFFER_MADE',
      showQuestion: true,
      isVisibleKey: 'isOffersMade',
    },
    {
      key: 'UNDER_CONTRACT',
      showQuestion: true,
      isVisibleKey: 'isUnderContract',
    },
    {
      key: 'ASSIGN_TO_BUYER',
      showQuestion: false,
      isVisibleKey: 'isAssignedToBuyer',
    },
    {
      key: 'WARM_LEAD',
      showQuestion: false,
      isVisibleKey: '',
    },
    {
      key: 'DEAD_LEAD',
      showQuestion: true,
      isVisibleKey: 'isDeadLead',
    },
    {
      key: 'REFERRED_TO_AGENT',
      showQuestion: false,
      isVisibleKey: '',
    },
    {
      key: 'INVENTORY',
      showQuestion: true,
      isVisibleKey: 'isInventory',
    },
  ];
  public changeWholeSaleStatusObject: StatusChangeModel[] = [
    {
      key: 'NEW_CONTRACT',
      showQuestion: false,
      isVisibleKey: '',
    },
    {
      key: 'MARKETING_TO_BUYERS',
      showQuestion: true,
      isVisibleKey: 'isMarketingToBuyers',
    },
    {
      key: 'SHOWING_TO_BUYERS',
      showQuestion: true,
      isVisibleKey: 'isShowingToBuyers',
    },
    {
      key: 'EVALUATING_OFFERS',
      showQuestion: true,
      isVisibleKey: 'isEvaluatingOffers',
    },
    {
      key: 'ACCEPTED_OFFER',
      showQuestion: true,
      isVisibleKey: 'isAcceptedOffer',
    },
    {
      key: 'CLEAR_TO_CLOSE',
      showQuestion: true,
      isVisibleKey: 'isClearToClose',
    },
    {
      key: 'SOLD',
      showQuestion: true,
      isVisibleKey: 'isSold',
    },
  ];

  public changeInventoryStatusObject: StatusChangeModel[] = [
    {
      key: 'GETTING_ESTIMATES',
      showQuestion: true,
      isVisibleKey: 'isGettingEstimates',
    },
    {
      key: 'UNDER_REHAB',
      showQuestion: true,
      isVisibleKey: 'isUnderRehab',
    },
    {
      key: 'LISTED_FOR_SALE',
      showQuestion: true,
      isVisibleKey: 'isListedForSale',
    },
    {
      key: 'SELL_SIDE_UNDER_CONTRACT',
      showQuestion: true,
      isVisibleKey: 'isUnderContract',
    },
    {
      key: 'SOLD',
      showQuestion: true,
      isVisibleKey: 'isSold',
    },
    {
      key: 'RENTAL',
      showQuestion: true,
      isVisibleKey: 'isRental',
    },
  ];
  public changeRentalStatusObject: StatusChangeModel[] = [
    {
      key: 'GETTING_ESTIMATES',
      showQuestion: true,
      isVisibleKey: 'isGettingEstimates',
    },
    {
      key: 'UNDER_REHAB',
      showQuestion: true,
      isVisibleKey: 'isUnderRehab',
    },
    {
      key: 'LISTED_FOR_SALE',
      showQuestion: true,
      isVisibleKey: 'isListedForSale',
    },
    {
      key: 'SELL_SIDE_UNDER_CONTRACT',
      showQuestion: true,
      isVisibleKey: 'isUnderContract',
    },
    {
      key: 'SOLD',
      showQuestion: true,
      isVisibleKey: 'isSold',
    },
  ]

  reset() {
    this.leadFilter = null;
    this.buyerFilter = {};
    this.activityLogFilter = {};
    this.listFilter = {};
    this.listDialerFilter = {};
    this.islistFilter = false;
    this.islistDialerFilter = false;
    this.isFilterBuyer = false;
    this.leadSort = null;
    this.reduceGrid = false;
    this.isDialogOpen = false;
    this.isCallStatusDialogOpen = false;
    this.dialogcurrentLeadId = '';
    this.isFilterLeads = false;
    this.updateAvailable = false;
    this.eSignCount = 0;
    this.leadIdArray = [];
    this.currentLeadCount = 0;
    this.currentModule = '';
    this.newCount = 0;
    this.newCount1 = 0;
    this.makingCallDialer = false;
    this.currentTransactionTab = '';
    this.currentUnrecognizedPage = 1;
    this.currentRecognizedPage = 1;
    this.currentMappedPage = 1;
    this.currentNumberTab = 0;
    this.dialerLeadSort = false;
    this.userData = null;
    this.currentPlanData = null;
    this.userAccessData = null;
    this.dashboardSelectedUserId = '';
    this.isTaskFromDashboard = false;
    this.isPlaid = true;
    this.isLSDialer = false;
    this.dialerCurrentCampaignId = '';
    this.isCallStatusLead = false;
  }

  orderItems(data: any[], key: string) {
    if (!data.length || !key) {
      return data;
    }
    const sortedData = data.sort((a, b) => a[key] - b[key]);

    return sortedData;
  }

  sortItems(data: any[], key: string) {
    const sortedData = data.sort((a, b) => {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });

    return sortedData;
  }

  reOrder(data: any[], fromIndex: number, toIndex: number) {
    if (fromIndex === toIndex) return data;

    const newArray = [...data];

    const target = newArray[fromIndex];
    const inc = toIndex < fromIndex ? -1 : 1;

    for (let i = fromIndex; i !== toIndex; i += inc) {
      newArray[i] = newArray[i + inc];
    }

    newArray[toIndex] = target;

    return newArray;
  }

  maskNumber(tel) {
    try {
      if (!tel) {
        return '';
      }

      var phone = this.unMaskNumber(tel);
      var value = phone.toString().trim().replace(/^\+/, '');
      if (value.match(/[^0-9]/)) {
        return phone;
      }
      var country, city, number;
      switch (value.length) {
        case 10:
          country = 1;
          city = value.slice(0, 3);
          number = value.slice(3);
          break;
        case 11:
          country = value.slice(0, 1);
          city = value.slice(1, 4);
          number = value.slice(4);
          break;
        case 12:
          country = value.slice(0, 2);
          city = value.slice(2, 5);
          number = value.slice(5);
          break;
        default:
          return tel;
      }

      number = number.slice(0, 3) + '-' + number.slice(3);

      return ('(' + city + ') ' + number).trim();
    } catch (error) {
      return tel;
    }
  }

  unMaskNumber(tel) {
    try {
      if (!tel) {
        return '';
      }

      return tel
        .replace('(', '')
        .replace(')', '')
        .replace(' ', '')
        .replace('-', '');
    } catch (error) {
      return tel;
    }
  }

  getCountryCode(tel) {
    try {
      switch (tel?.length) {
        case 12:
          return tel.slice(0, 2);

        case 13:
          return tel.slice(0, 3);
      }
    } catch (error) {
      return environment.countryCode;
    }
  }

  capitalizeName(name) {
    if (!name) {
      return name;
    }

    try {
      name = name?.trim();
      let words = name?.split(' ');

      if (!words?.length) {
        return name;
      }

      for (let i = 0; i < words?.length; i++) {
        if (words[i]) {
          words[i] =
            words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
        }
      }

      return words?.join(' ');
    } catch (error) {
      return '';
    }
  }

  camelCaseToWord(text, style?) {
    if (!text) {
      return;
    }

    let result = text.replace(/([A-Z])/g, ' $1');
    if (style && style?.index) {
      if (style?.transform === 'uppercase') {
        var split = result.split(' ');
        if (split[style?.index] && split[style?.index] != 'Call') {
          split[style?.index] = split[style?.index].toUpperCase();
        } else {
          split[style?.index] =
            split[style?.index].charAt(0).toUpperCase() +
            split[style?.index].slice(1);
        }
        result = split.join(' ');
      }
    }

    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  minutesList = (num, fill) => {
    return new Array(num).fill(fill).map((x, i) => i + 1);
  };

  titleCaseToWord(text) {
    try {
      if (!text || typeof text === 'object') {
        return;
      }

      return text?.toLowerCase()?.replace(/\b(\w)/g, (s) => s?.toUpperCase());
    } catch (error) {
      return text;
    }
  }

  taskTimeTypeList = () => {
    return ['Minutes', 'Hours', 'Days', 'Weeks', 'Months'];
  };

  sortByObject = (moduleList) =>
    moduleList.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      };
    }, {});

  /**
   * FUNCTION: GET DATE FORMAT
   * @param {Date} dueDate
   * @param {String} timezone
   * @param {String} time
   * @returns OBJECT OF DATE FORMAT
   */
  dueDateFormat = (dueDate?, timezone?, time?, format?, isSetTime = false) => {
    try {
      dueDate = Number(dueDate);
      let timeAbbr;
      let validDate;
      let timeStampValue;
      let formatDateByDay;
      let currentTimezone;
      let day = '' + new Date(dueDate).getDate();
      let month = '' + (new Date(dueDate).getMonth() + 1);
      let year = new Date(dueDate).getFullYear();
      let timezoneOffset = localStorage.getItem('timezoneOffset');
      let timeZoneFormat = moment(new Date(dueDate))
        .tz(timezoneOffset)
        .format('Z');
      let offset = new Date().getTimezoneOffset();
      let offset1 = Math.abs(Math.round(offset / 60));
      let offset11 = offset1.toString();
      let offset2 = Math.abs(offset) % 60;
      let offset22 = offset2.toString();
      if (offset11.length == 1) {
        offset11 = '0' + offset11;
      }

      if (offset22.length == 1) {
        offset22 = '0' + offset22;
      }

      if (!timezone) {
        timezone = timezoneOffset;
      }
      if (timezone) {
        const type = timezoneConstant.timezoneArray.find(
          (x) => x?.timezonename === timezone
        );
        timeAbbr = type.abbreviation;
      }

      if (!dueDate || dueDate == 0) {
        validDate = '';
      } else if (!isNaN(Date.parse(dueDate))) {
        validDate = new Date(dueDate);
      } else if (!isNaN(Number(dueDate))) {
        validDate = new Date(Number(dueDate));
      } else if (moment(dueDate).isValid()) {
        validDate = new Date(moment(dueDate).toString());
      } else if (moment(dueDate, 'DD/MM/YYYY').isValid()) {
        validDate = new Date(moment(dueDate, 'DD/MM/YYYY').toString());
      } else if (moment(dueDate, 'YYYY/MM/DD').isValid()) {
        validDate = new Date(moment(dueDate, 'YYYY/MM/DD').toString());
      } else if (moment(dueDate, 'YYYY/DD/MM').isValid()) {
        validDate = new Date(moment(dueDate, 'YYYY/DD/MM').toString());
      } else {
        validDate = '';
      }

      if (!dueDate) {
        timeStampValue = '';
      } else {
        let convertedDate =
          moment(new Date(dueDate)).format('YYYY-MM-DD') +
          'T' +
          moment()
            .tz(timezoneOffset)
            .set(time || { second: 0 })
            .format('HH:mm:ss') +
          timeZoneFormat;
        timeStampValue = moment(convertedDate)
          .set(time || {})
          .valueOf();
      }

      if (day.length < 2) {
        day = '0' + day;
      }
      if (month.length < 2) {
        month = '0' + month;
      }
      formatDateByDay = [year, month, day].join('-');

      if (offset < 0) {
        currentTimezone = '+' + offset11 + ':' + offset22;
      } else {
        currentTimezone = '-' + offset11 + ':' + offset22;
      }

      let dueTime = moment(new Date(dueDate)).format('LT');

      if (isSetTime) {
        dueTime = moment(new Date(dueDate))
          .set(time || { h: 0, m: 0, s: 0 })
          .format(format || 'LT');
      }

      return {
        date: moment(new Date(dueDate)).format('MMM D, YYYY'),
        time: dueTime,
        timezone: moment(new Date(dueDate))
          .tz(timezone)
          .format('MMM DD, YYYY hh:mm A'),
        timezoneDate: moment(new Date(dueDate))
          .tz(timezone)
          .format('MMM DD, YYYY'),
        timezoneTime: moment(new Date(dueDate)).tz(timezone).format('hh:mm A'),
        timezoneAbbr: timeAbbr,
        parseDate: validDate,
        timeStamp: timeStampValue,
        dateFormat: formatDateByDay,
        browserTimezone: currentTimezone,
      };
    } catch (error) {
      return {};
    }
  };

  getSum = (data, key) => {
    return data.reduce((a, b) => a + (b[key] || 0), 0);
  };

  formatAddress(address, unitNo = '') {
    try {
      if (!address) {
        return '';
      }
      const re = /, USA/gi;
      if (unitNo) {
        let address1 = address.replace(re, '');
        var fields = address1.split(',');
        fields.splice(1, 0, 'Unit # ' + unitNo);
        return fields.join(', ');
      } else {
        return address.replace(re, '');
      }
    } catch (error) {
      return '';
    }
  }

  calculateTimimg(d) {
    let months = 0,
      years = 0,
      days = 0,
      weeks = 0;
    while (d) {
      if (d >= 365) {
        years++;
        d -= 365;
      } else if (d >= 30) {
        months++;
        d -= 30;
      } else if (d >= 7) {
        weeks++;
        d -= 7;
      } else {
        days++;
        d--;
      }
    }

    if (years > 0) {
      return (
        years +
        ' year ' +
        months +
        ' months ' +
        weeks +
        ' weeks ' +
        days +
        ' days'
      );
    } else if (months > 0) {
      return months + ' months ' + weeks + ' weeks ' + days + ' days';
    } else if (weeks > 0) {
      return weeks + ' weeks ' + days + ' days';
    } else {
      return days + ' days';
    }
  }

  calculateMinutes(num, isShowSeconds?, isFormatCustom?) {
    let d, h, m, s, time;
    d = Math.floor(num / 1440); // 60*24
    h = Math.floor((num - d * 1440) / 60);
    m = Math.round(num % 60);

    if (d > 0) {
      time = d + ' days, ' + h + ' hours, ' + m + ' minutes';
    } else if (h > 0) {
      time = h + ' hours, ' + m + ' minutes';
    } else if (m > 0) {
      time = m + ' minutes';
    } else {
      s = Math.floor((num * 60000) / 1000);
      time = isShowSeconds ? s + ` seconds` : 'a few seconds ago';
    }

    return isFormatCustom ? { d, h, m, s } : time;
  }

  callConnectedStatus = () => {
    return [
      {
        name: 'Appt Set',
        value: 1,
      },
      {
        name: 'Offer Made',
        value: 2,
      },
      {
        name: 'Moved to Dead Lead',
        value: 4,
      },
      {
        name: 'Moved to Warm Lead',
        value: 3,
      },
      {
        name: 'Moved to Referred To Agent',
        value: 10,
      },
      {
        name: 'Other',
        value: 5,
      },
    ];
  };

  callNotConnectedStatus = () => {
    return [
      {
        name: 'Left VM and/or SMS',
        value: 6,
      },
      {
        name: 'Moved to Dead Lead',
        value: 8,
      },
      {
        name: 'Moved to Warm Lead',
        value: 7,
      },
      {
        name: 'Moved to Referred To Agent',
        value: 11,
      },
      {
        name: 'Not Answered',
        value: 12,
      },
      {
        name: 'Other',
        value: 9,
      },
    ];
  };
  taskType = () => {
    return [
      {
        id: 1,
        name: 'Appointment',
        class: 're-appointment',
      },
      {
        id: 2,
        name: 'Call',
        class: 're-call',
      },
      {
        id: 3,
        name: 'Closing',
        class: 're-closing',
      },
      {
        id: 4,
        name: 'Disposition',
        class: 're-disposition',
      },
      {
        id: 5,
        name: 'Due Diligence',
        class: 're-due-diligence',
      },
      {
        id: 10,
        name: 'Email',
        class: 're-email',
      },
      {
        id: 7,
        name: 'Marketing',
        class: 're-marketing',
      },
      {
        id: 6,
        name: 'Offer',
        class: 're-offer',
      },
      {
        id: 8,
        name: 'Other',
        class: 're-other',
      },
      {
        id: 9,
        name: 'SMS',
        class: 're-sms',
      },
    ];
  };

  formatNumber = (number: Number) => {
    if (!number) return number;
    return String(number).replace(/(.)(?=(\d{3})+$)/g, '$1,');
  };

  getTaskTypeOption(id) {
    return this.taskType().find((item) => {
      if (item.id == id) {
        return item;
      }
    });
  }

  numberType = (type: string) => {
    switch (type) {
      case 'voip':
        return 'V';

      case 'landline':
        return 'L';

      case 'mobile':
        return 'M';

      case 'toll-free':
        return 'TF';

      default:
        return '';
    }
  };

  generateId = () => {
    const objectID = (
      m = Math,
      d = Date,
      h = 16,
      s = (s) => m.floor(s).toString(h)
    ) =>
      s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h));

    return objectID;
  };

  getMainStatusTitleById(statusId) {
    if (statusId == StatusConstant.LeadStatus.NEW_LEAD) {
      return 'New Lead';
    }
    if (statusId == StatusConstant.LeadStatus.NO_CONTACT_MADE) {
      return 'No Contact Made';
    }
    if (statusId == StatusConstant.LeadStatus.CONTACT_MADE) {
      return 'Contact Made';
    }
    if (statusId == StatusConstant.LeadStatus.APPOINTMENT_SET) {
      return 'Appointments Set';
    }
    if (statusId == StatusConstant.LeadStatus.DUE_DILIGENCE) {
      return 'Due Diligence';
    }
    if (statusId == StatusConstant.LeadStatus.OFFER_MADE) {
      return 'Offer Made';
    }
    if (statusId == StatusConstant.LeadStatus.UNDER_CONTRACT) {
      return 'Under Contract';
    }
    if (statusId == StatusConstant.LeadStatus.ASSIGN_TO_BUYER) {
      return 'Assigned To Buyer';
    }
    if (statusId == StatusConstant.LeadStatus.WARM_LEAD) {
      return 'Warm Lead';
    }
    if (statusId == StatusConstant.LeadStatus.DEAD_LEAD) {
      return 'Dead Lead';
    }
    if (statusId == StatusConstant.LeadStatus.REFERRED_TO_AGENT) {
      return 'Referred To Agent';
    }
    if (statusId == StatusConstant.InventoryStatus.INVENTORY) {
      return 'Inventory';
    }
    if (statusId == StatusConstant.InventoryStatus.NEW_INVENTORY) {
      return 'Inventory';
    }
    return '';
  }

  getText(editor) {
    const { ops } = editor;

    const result = ops.reduce((acc, { insert }) => {
      const insertValue: any = insert;

      if (typeof insertValue === 'string') {
        acc += insertValue;
      } else {
        acc += `@${insertValue?.mention?.value} `;
      }

      return acc;
    }, '');

    return result;
  }

  getTextWithMentions(text) {
    let replacePattern1 = /<span\b(?:(?=(\s+(?:id="([^"]*)"|class="([^"]*)")|[^\s>]+|\s+))\1)*>/gim;
    let replacedText = text.replace(replacePattern1, '');
    replacedText = replacedText.replace(/<\/span>/gi, function (matched) {
      return '';
    });
    replacedText = replacedText
      .replace(/<br><\/p>/g, '\n')
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '\n')
      .replace(/<br>/g, '\n')
      .replace(/<[^>]*>?/gm, '')
      .replace(/&nbsp;/g, ' ');

    return replacedText;
  }

  populateEditorDataWithBR(data, message) {
    try {
      let result = [];
      let arr = message.split('\n');
      for (let i = 0; i < arr.length; i++) {
        result.push(this.populateEditorData(data, arr[i]));
      }
      return result.join('<br>');
    } catch (error) {
      return message;
    }
  }

  populateEditorDataWithN(message) {
    let result = [];
    let arr = message.split('\n');
    for (let i = 0; i < arr.length; i++) {
      result.push(arr[i]);
    }
    return result.join('<br>');
  }

  populateEditorDataBR(message) {
    let result = [];
    let arr = message.split('<br>');
    for (let i = 0; i < arr.length; i++) {
      result.push(arr[i]);
    }
    return result.join('\n');
  }

  populateEditorData(data, message) {
    try {
      let str = message; //.replace(/\s+/g, ' ');

      data.map((x, index) => {
        var index = str.search(x);

        if (index > -1) {
          var textToReplace = '@' + x;
          str = str.replaceAll(
            textToReplace,
            ` <span class="mention" data-index="${index}" data-denotation-char="@" data-id="${index}" data-value="${x}">&#xFEFF;<span contenteditable="false"><span class="ql-mention-denotation-char">@</span>${x}</span>&#xFEFF;</span>`
          );
        }

        return x;
      });

      return str;
    } catch (error) {
      return message;
    }
  }

  commonEditor() {
    return {
      placeholder: 'type @ to assign',
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    };
  }

  validatePhone(phone) {
    var value = phone.toString().trim().replace(/^\+/, '');
    if (value.match(/[^0-9]/)) {
      return phone;
    }
    var number;
    switch (value.length) {
      case 10:
        number = value;
        break;
      case 11:
        number = value.slice(1);
        break;
      case 12:
        number = value.slice(2);
        break;
      default:
        return phone;
    }
    return number;
  }
  callConnectedDialerStatus = () => {
    return [
      {
        name: 'DNC Number',
        value: 1,
      },
      {
        name: 'DNC Contact',
        value: 2,
      },
      {
        name: 'Schedule Call',
        value: 3,
      },
      {
        name: 'No Contact Made',
        value: 4,
      },
      {
        name: 'Property Sold',
        value: 5,
      },
      {
        name: 'Not Interested',
        value: 15,
      },
      {
        name: 'Other',
        value: 6,
      },
    ];
  };

  callNotConnectedDialerStatus = () => {
    return [
      {
        name: 'Seller Unavailable',
        value: 7,
      },
      {
        name: 'Kept Ringing',
        value: 8,
      },
      {
        name: 'Wrong Number',
        value: 9,
      },
      {
        name: 'Inactive Number',
        value: 10,
      },
      {
        name: 'Busy Signal',
        value: 11,
      },
      {
        name: 'Left VM',
        value: 12,
      },
      {
        name: 'VM not set up',
        value: 13,
      },
      {
        name: 'Other',
        value: 14,
      },
    ];
  };

  parseNumber(data) {
    try {
      if (!data) return data;
      return data.replace(/\D+/g, '');
    } catch (error) {
      return data;
    }
  }

  checkForStatusNameChange(allStatus, type) {
    let statusKey,
      index,
      statusConstantData = {},
      statusObject = [];
    if (type == 'leads') {
      statusConstantData = StatusConstant.LeadStatus;
      statusObject = this.changeLeadStatusObject;
    } else if (type == 'wholesale') {
      statusConstantData = StatusConstant.WholeSaleStatus;
      statusObject = this.changeWholeSaleStatusObject;
    } else if (type == 'Inventory') {
      statusConstantData = StatusConstant.InventoryStatus;
      statusObject = this.changeInventoryStatusObject;
    } else if (type == 'Rental') {
      statusConstantData = StatusConstant.RentalStatus;
      statusObject = this.changeRentalStatusObject;
    }
    allStatus.filter((e) => {
      statusKey = Object.keys(statusConstantData).find(
        (key) => statusConstantData[key] === e?._id
      );
      if (statusKey && e?.labels?.title) {
        index = statusObject.findIndex((e) => e.key === statusKey);
        if (index > -1) statusObject[index]['showQuestion'] = false;
      }
    });
  }

  statusChangeChecks(allParams: StatusChangeChecksModel) {
    const propertyCheckIncludeStatuses = [
        StatusConstant.InventoryStatus.INVENTORY,
        StatusConstant.LeadStatus.UNDER_CONTRACT,
        StatusConstant.InventoryStatus.RENTAL,
        StatusConstant.InventoryStatus.SOLD,
      ],
      taskCheckExcludeStatuses = [
        StatusConstant.InventoryStatus.INVENTORY,
        StatusConstant.InventoryStatus.SOLD,
      ];
    if (allParams?.fromStatus === allParams?.toStatus) {
      return { success: 0, type: 1 };
    } else if (
      allParams?.toStatus == StatusConstant.InventoryStatus.SOLD &&
      allParams?.module == 'Leads'
    ) {
      return { success: 0, type: 2 };
    }

    //conditional check
    if (allParams?.checkPropertyAddress) {
      if (
        propertyCheckIncludeStatuses.includes(allParams?.toStatus) &&
        (!allParams?.data?.address ||
          allParams?.data?.address == 'NA' ||
          allParams?.data?.address == 'N/A')
      ) {
        return { success: 0, type: 3 };
      }
    }

    let isTask = false; //without task value
    if (allParams?.checkTask) {
      if (
        allParams?.allTasks ||
        (allParams?.data?.dripId &&
          !taskCheckExcludeStatuses.includes(allParams?.toStatus))
      ) {
        isTask = true; //with task value
      }
    }

    //check if status change popup is required
    let fromStatusKey,
      toStatusKey,
      statusConstantData = {},
      statusObject = [],
      showPopup = false;
    if (allParams?.module == 'Leads') {
      statusConstantData = StatusConstant.LeadStatus;
      statusObject = this.changeLeadStatusObject;
    }
    if (allParams?.module == 'Wholesale Pipeline') {
      statusConstantData = StatusConstant.WholeSaleStatus;
      statusObject = this.changeWholeSaleStatusObject;
    }
    if (allParams?.module == 'Inventory') {
      statusConstantData = StatusConstant.InventoryStatus;
      statusObject = this.changeInventoryStatusObject;
    }
    if (allParams?.module == 'Rental') {
      statusConstantData = StatusConstant.RentalStatus;
      statusObject = this.changeRentalStatusObject;
    }
    fromStatusKey = Object.keys(statusConstantData).find(
      (key) => statusConstantData[key] === allParams?.fromStatus
    );
    toStatusKey = Object.keys(statusConstantData).find(
      (key) => statusConstantData[key] === allParams?.toStatus
    );
    let data = {},
      fromKey = '';
    if (fromStatusKey && toStatusKey) {
      //console.log(fromStatusKey +'---------------'+toStatusKey);
      if((fromStatusKey=='WARM_LEAD' || fromStatusKey=='DEAD_LEAD' || fromStatusKey=='REFERRED_TO_AGENT') && toStatusKey=='UNDER_CONTRACT'){
        showPopup = true;
        data['isAppointmentSet']=true;
        data['isOffersMade']=true;
        data['isUnderContract']=true;
      }else{
        let fromIndex = statusObject.findIndex((e) => e.key === fromStatusKey);
        let toIndex = statusObject.findIndex((e) => e.key === toStatusKey);
        fromKey = statusObject[fromIndex]?.isVisibleKey;
        statusObject?.filter((e, i) => {
          if (fromIndex < toIndex) {
            if (
              i >= fromIndex &&
              i <= toIndex &&
              e?.showQuestion &&
              e?.isVisibleKey
            ) {
              data[e?.isVisibleKey] = true;
              showPopup = true;
            }
          } else if (e?.isVisibleKey) {
            //for backward movement of status
            data[e?.isVisibleKey] = i > toIndex ? false : true;
          }
        });
      }
    }
    return {
      success: 1,
      isTask: isTask,
      showPopup: showPopup,
      data: data,
      fromKey: fromKey,
    };
  }
  getEncryptedString(value) {
    return new Promise(async (resolve, reject) => {
      const cipher = (salt) => {
        const textToChars = (text) =>
          text.split('').map((c) => c.charCodeAt(0));
        const byteHex = (n) => ('0' + Number(n).toString(16)).substr(-2);
        const applySaltToChar = (code) =>
          textToChars(salt).reduce((a, b) => a ^ b, code);

        return (text) =>
          text
            .split('')
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join('');
      };

      const myCipher = cipher('resimpli_Live_!2');

      let encryptedString = myCipher(value);
      resolve(encryptedString);
    });
  }

  /**
   * FUNCTION: GET BUYERS QUESTIONS
   * @param data
   * @param groups
   * @returns ARRAY OF QUESTIONS
   */
  getBuyersQuestions(data, groups): any[] {
    try {
      return data.reduce((acc, x) => {
        const group = groups.find((y) => y._id === x.group);
        let fields = [];
        group.fields = group.fields.map((g, i) => {
          let answer = x.questions[i]?.preferenceQuestion;
          if (g.questionType === 'CHECK_LIST') {
            answer = x.questions[i]?.preferenceQuestion.reduce((acc, x) => {
              let answer = g.options.find((o) => o.value === x.value);
              if (x.checkboxValue && answer) {
                acc.push(answer.value);
              }
              delete acc['_id'];
              return acc;
            }, []);
          }

          let obj = {
            questionTitle: g?.questionTitle,
            questionType: g?.questionType,
          };

          if (g?.options) {
            obj['options'] = g?.options;
          }

          if (g?.currencyFormat) {
            obj['currencyFormat'] = g?.currencyFormat;
          }

          if (g?.numberFormat) {
            obj['numberFormat'] = g?.numberFormat;
          }

          if (g?.dateFormat) {
            obj['dateFormat'] = g?.dateFormat;
          }

          if (g.placeholder) {
            obj['placeholder'] = g?.placeholder;
          }

          if (answer) {
            obj['answer'] = answer;
          }
          fields.push(obj);

          return {
            ...g,
            answer,
          };
        });

        acc.push({
          _id: group?._id,
          groupTitle: group?.title,
          groupDescription: group?.description,
          question: fields,
        });
        return acc;
      }, []);
    } catch (error) {
      return [];
    }
  }

  /**
   * FUNCTION: CHECK UNIQUE FLAG
   * @param val
   * @param data
   * @returns Boolean
   */
  checkForUnique(val, data) {
    let flag = true;
    for (let i = 0; i < data.length; i++) {
      if (isNaN(val) && data[i].label?.toLowerCase() === val.toLowerCase()) {
        flag = false;
        break;
      } else if (data[i].label?.toString() === val?.toString()) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  //Global Get, Post, Put and Delete
  /**
   * FUNCTION: COMMON GET HTTP REQUEST
   * @param {STRING} endpoint
   * @param {Object} data
   * @param {Object} headers
   * @returns HTTP GET CALL RESPONSE
   */
  globalGetService(endpoint, headers?) {
    let httpPostReq = this.http.get(endpoint);
    if (headers) httpPostReq = this.http.get(endpoint, headers);
    return httpPostReq.pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  /**
   * FUNCTION: COMMON POST HTTP REQUEST
   * @param {STRING} endpoint
   * @param {Object} data
   * @param {Object} headers
   * @returns HTTP POST CALL RESPONSE
   */
  globalPostService(endpoint, data?, headers?) {
    let httpPostReq = this.http.post(endpoint, data);
    if (headers) httpPostReq = this.http.post(endpoint, data, headers);
    return httpPostReq.pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  /**
   * FUNCTION: COMMON PUT HTTP REQUEST
   * @param {STRING} endpoint
   * @param {Object} data
   * @returns HTTP PUT CALL RESPONSE
   */
  globalPutService(endpoint, data) {
    return this.http.put(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  /**
   * FUNCTION: COMMON DELETE HTTP REQUEST
   * @param {STRING} endpoint
   * @returns HTTP DELETE CALL RESPONSE
   */
  globalDeleteService(endpoint) {
    return this.http.delete(endpoint).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  // FUNCTION TO OPEN CONFIRMATION DIALOG WITH CONDITIONS
  confirmCondition(leadInvestmentTitle, isUnderContract) {
    if (
      (leadInvestmentTitle?.toLowerCase().includes('wholesale') ||
        leadInvestmentTitle?.toLowerCase().includes('novation')) &&
      isUnderContract
    ) {
      this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: {
          header: 'Confirmation',
          type: 'Leads',
          yesTitle: 'OK',
          hideNoButton: true,
          text: 'Any property that is Under Contract with the following exit types will also be moved to the Wholesale Pipeline: <br><br> Wholesale Assignment <br> Wholesale Double Close <br>Novation',
        },
      });
    }
  }
}
