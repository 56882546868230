import {
  Component,
  OnInit,
  EventEmitter,
  ElementRef,
  ViewChild,
  Inject,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadOutput, UploadInput, UploaderOptions } from 'ngx-uploader';
import * as _ from 'lodash';

// COMPONENTS
import { VerifyEmailComponent } from '../verify-email/verify-email.component';
import { AddEditEmailTemplateDailogComponent } from '../../add-edit-email-template-dailog/add-edit-email-template-dailog.component';
import { QuillEditorComponent } from 'ngx-quill';
import 'quill-mention';

// UTILS
import { MessageConstant } from '../../../../../utils/message-constant';
import { MiscellaneousConstant } from '../../../../../utils/miscellaneous-constant';
import { ResponseModel } from '../../../../../utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { environment } from 'src/environments/environment';
import { BaseUrl } from 'src/app/utils/base-url-constants';

// SERVICES
import { SharedService } from '../../../../shared.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { MasterTaskService } from 'src/app/providers/master-task/master-task.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

@Component({
  selector: 'app-send-email-dialog',
  templateUrl: './send-email-dialog.component.html',
  styleUrls: ['./send-email-dialog.component.scss'],

  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class SendEmailDialogComponent implements OnInit {
  @ViewChild('uploader', { read: ElementRef }) fileInput: ElementRef;
  @ViewChild('editor') editor: QuillEditorComponent;

  sendEmailForm: FormGroup;
  options: UploaderOptions;
  dialogRef: any;

  showCC = false;
  showBCC = false;
  messageConstant = MessageConstant;
  isEmailExist;
  submitted: boolean = false;
  checkValidation = {
    fromEmail: false,
    toEmail: false,
    fromName: false,
  };
  moduleId: string = '5faa63fdfd20cd581703d255';
  uploadInput: EventEmitter<UploadInput>;
  uploadFiles: any[] = [];
  imageArr: any[] = [];
  attachments: any = [];
  editAttachmentArray: any = [];
  sendOrSave: boolean;
  leadInfo: any;
  ccEmail: string;
  bccEmail: string;
  toEmail: string;
  checkCC: any = [];
  Email: any = [];
  checkBCC: any = [];
  ccArray: any = [];
  bccArray: any = [];
  ccChecker: boolean = true;
  bccChecker: boolean = true;
  fileSize: any = 0;
  selectedFileSize: any = 0;
  isFileLimit = true;
  public items: string[] = [
    'First_Name',
    'Last_Name',
    'Property_Street_Address',
    'Property_City',
    'Property_State',
    'Property_Zip_Code',
    'Campaign_Number',
  ];
  fileCount: number = 0;
  allFileCount: number = 0;
  emailInvalidMessage: string = '';

  editorStyle: {
    height: '500px';
  };
  editorConfig: any = {};
  editorOptions: any = {};

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private _sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private emailDailog: MatDialogRef<SendEmailDialogComponent>,
    private _masterTaskService: MasterTaskService,
    public _utilities: CommonFunctionsService
  ) {
    this.options = { concurrency: 1, maxFileSize: 10000000 };
    this.editorOptions = {
      placeholder: this._utilities.commonEditor().placeholder,
      toolbar: this._utilities.commonEditor().toolbar,
      mention: this.mentionEditor(),
    };
  }

  mentionEditor() {
    return {
      mentionListClass: 'ql-mention-list mat-elevation-z8',
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      showDenotationChar: true,
      spaceAfterInsert: true,
      onSelect: (item, insertItem) => {
        const editor = this.editor.quillEditor;
        insertItem(item);
        // this.tagUser(item.value)
        // necessary because quill-mention triggers changes as 'api' instead of 'user'
        editor.insertText(editor.getLength() - 1, '', 'user');
      },
      source: (searchTerm, renderList) => {
        const values = this.items.map((item, index) => {
          return { id: index, value: item };
        });

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];

          values.forEach((entry) => {
            if (
              entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            ) {
              matches.push(entry);
            }
          });
          renderList(matches, searchTerm);
        }
      },
    };
  }

  ngOnInit(): void {
    this.getUserRoleList();
    this.sendEmailForm = this.formBuilder.group({
      fromName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(MiscellaneousConstant.namePattern),
        ]),
      ],
      fromEmail: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(MiscellaneousConstant.emailPattern),
        ]),
      ],
      replyEmail: ['', Validators.pattern(MiscellaneousConstant.emailPattern)],
      toEmail: [
        this.data?.email,
        Validators.compose([
          Validators.required,
          Validators.pattern(MiscellaneousConstant.emailPattern),
        ]),
      ],
      subject: ['', Validators.required],
      messageBody: ['', Validators.required],
      ccEmail: ['', Validators.pattern(MiscellaneousConstant.emailPattern)],
      bccEmail: ['', Validators.pattern(MiscellaneousConstant.emailPattern)],
      attachmentArray: [''],
    });

    if (this.data.action == 'edit') {
      if (this.data.details?.ccEmail) {
        this.showCC = true;
        this.onAllowUniqueCcEmail(this.data.details?.ccEmail);
      }
      if (this.data.details?.bccEmail) {
        this.showBCC = true;
        this.onAllowUniqueBccEmail(this.data.details?.bccEmail);
      }

      if (
        this.data.details?.subject &&
        this.data.details?.subject !=
          "If you don't want to use any of the available templates."
      ) {
        this.customSubject(
          this.data,
          this.data.details?.subject,
          this.data.teams
        );
      }
      if (this.data.details?.message) {
        this.customMessage(
          this.data,
          this.data.details?.message,
          this.data.teams
        );
      }
      if (this.data.details?.attachmentArray) {
        this.fileSize = this.data.details?.attachmentArray
          .map((obj) => {
            return Number(obj.size);
          })
          .reduce((a, b) => a + b, 0);
      }
      this.editAttachmentArray = this.data.details?.attachmentArray
        ? this.data.details?.attachmentArray
        : [];
      if (this.data.details?.fromEmail) {
        this.isEmailExist = true;
      }
      this.sendEmailForm.patchValue({
        fromName: this.data.details?.fromName,
        toEmail: this.data?.email,
        fromEmail: this.data.details?.fromEmail,
      });
      this.toEmail = this.data?.email;
      this.Email.push(this.data?.email);
    }
    this.uploadInput = new EventEmitter<UploadInput>();
  }

  customSubject = (data, message, teams) => {
    let name;
    let firstName;
    let lastName;
    let address;
    let streetAddress;
    let city;
    let state;
    let zip;
    let subject;
    let campaignNumber;
    const leadTitle = data?.leadInfo?.title
      ? data.leadInfo.title.replace(/ {2,}/g, ' ').trim()
      : '';
    const buyerFirstName = data?.leadInfo?.firstName
      ? data.leadInfo.firstName.replace(/ {2,}/g, ' ').trim()
      : data?.leadInfo?.buyers?.length > 0 &&
        data?.leadInfo?.buyers[0]?.firstName
      ? data?.leadInfo?.buyers[0]?.firstName.replace(/ {2,}/g, ' ').trim()
      : '';
    const buyerLastName = data?.leadInfo?.lastName
      ? data.leadInfo.lastName.replace(/ {2,}/g, ' ').trim()
      : data?.leadInfo?.buyers?.length > 0 &&
        data?.leadInfo?.buyers[0]?.lastName
      ? data?.leadInfo?.buyers[0]?.lastName.replace(/ {2,}/g, ' ').trim()
      : '';
    name = leadTitle ? leadTitle.split(' ') : '';
    firstName = name[0]
      ? name[0]
      : buyerFirstName
      ? buyerFirstName
      : 'First_Name(N/A)';
    lastName = name[1]
      ? name[1]
      : buyerLastName
      ? buyerLastName
      : 'Last_Name(N/A)';
    campaignNumber = data?.leadInfo?.number
      ? this._utilities.maskNumber(data?.leadInfo?.number)
      : 'Campaign_Number(N/A)';
    if (data.address || data?.leadInfo?.address) {
      address = data?.address
        ? data.address.split(',')
        : data?.leadInfo?.address.split(',');
      if (address.length > 1) {
        streetAddress = address[0]
          ? address[0]
          : 'Property_Street_Address(N/A)';
        city = address[1] ? address[1] : 'Property_City(N/A)';
        if (address.length > 2) {
          let subDivide = address[2];
          if (subDivide) {
            let sub = subDivide.split(' ');
            state = sub[1] ? sub[1] : 'Property_State(N/A)';
            zip = sub[2] ? sub[2] : 'Property_Zip_Code(N/A)';
          }
        } else {
          state = 'Property_State(N/A)';
          zip = 'Property_Zip_Code(N/A)';
        }
      } else {
        streetAddress = address[0];
        city = 'Property_City(N/A)';
        state = 'Property_State(N/A)';
        zip = 'Property_Zip_Code(N/A)';
      }
    } else {
      streetAddress = 'Property_Street_Address(N/A)';
      city = 'Property_City(N/A)';
      state = 'Property_State(N/A)';
      zip = 'Property_Zip_Code(N/A)';
    }
    let myObj = {
      '@First_Name': this._utilities.titleCaseToWord(firstName),
      '@Last_Name': this._utilities.titleCaseToWord(lastName),
      '@Property_Street_Address': streetAddress,
      '@Property_City': city,
      '@Property_State': state,
      '@Property_Zip_Code': zip,
      '@Campaign_Number': campaignNumber,
    };
    if (teams?.roles) {
      teams.roles.map((i) => {
        let key = i.name.toString();
        if (key != undefined && message.includes(key)) {
          let originalRole = teams.roles.find((item) => item.name == key);
          let userId = teams.assignUser[originalRole._id];
          if (!userId) {
            originalRole = teams.items.roles.find(
              (item) => item.name == 'Owner'
            );
          }
          userId =
            userId != null
              ? userId
              : teams.items.assignUser['5fd093a2f44c5ac2ea1919b4'];
          let username = originalRole.members.find(
            (item) => item._id.toString() == userId.toString()
          );
          let obj = {};
          obj['@' + key] = username?.firstName
            ? this._utilities.titleCaseToWord(`${username?.firstName}`)
            : `${key}(N/A)`;
          myObj = { ...myObj, ...obj };
        }
      });
    }
    let rowRole = [
      '@First_Name',
      '@Last_Name',
      '@Property_Street_Address',
      '@Property_City',
      '@Property_State',
      '@Property_Zip_Code',
      '@Campaign_Number',
    ];
    let roles = [];
    if (teams?.roles) {
      roles = teams.roles.map((item) => '@' + item.name);
    }
    let rolesWithKey = new RegExp([...rowRole, ...roles].join('|'), 'g');
    subject = message.replace(rolesWithKey, (matched) => {
      return myObj[matched];
    });
    subject = subject.replace(/@/g, '');
    // console.log("subject::::::::::::", subject);
    // return subject;
    this.sendEmailForm.patchValue({
      subject,
    });
  };

  customMessage = (data, message, teams) => {
    let name;
    let firstName;
    let lastName;
    let address;
    let streetAddress;
    let city;
    let state;
    let zip;
    let subject;
    let campaignNumber;
    const leadTitle = data?.leadInfo?.title
      ? data.leadInfo.title.replace(/ {2,}/g, ' ').trim()
      : '';
    const buyerFirstName = data?.leadInfo?.firstName
      ? data.leadInfo.firstName.replace(/ {2,}/g, ' ').trim()
      : data?.leadInfo?.buyers?.length > 0 &&
        data?.leadInfo?.buyers[0]?.firstName
      ? data?.leadInfo?.buyers[0]?.firstName.replace(/ {2,}/g, ' ').trim()
      : '';
    const buyerLastName = data?.leadInfo?.lastName
      ? data.leadInfo.lastName.replace(/ {2,}/g, ' ').trim()
      : data?.leadInfo?.buyers?.length > 0 &&
        data?.leadInfo?.buyers[0]?.lastName
      ? data?.leadInfo?.buyers[0]?.lastName.replace(/ {2,}/g, ' ').trim()
      : '';
    name = leadTitle ? leadTitle.split(' ') : '';
    firstName = name[0]
      ? name[0]
      : buyerFirstName
      ? buyerFirstName
      : 'First_Name(N/A)';
    lastName = name[1]
      ? name[1]
      : buyerLastName
      ? buyerLastName
      : 'Last_Name(N/A)';
    campaignNumber = data?.leadInfo?.number
      ? this._utilities.maskNumber(data?.leadInfo?.number)
      : 'Campaign_Number(N/A)';
    if (data.address || data?.leadInfo?.address) {
      address = data?.address
        ? data.address.split(',')
        : data?.leadInfo?.address.split(',');
      if (address.length > 1) {
        streetAddress = address[0]
          ? address[0]
          : 'Property_Street_Address(N/A)';
        city = address[1] ? address[1] : 'Property_City(N/A)';
        if (address.length > 2) {
          let subDivide = address[2];
          if (subDivide) {
            let sub = subDivide.split(' ');
            state = sub[1] ? sub[1] : 'Property_State(N/A)';
            zip = sub[2] ? sub[2] : 'Property_Zip_Code(N/A)';
          }
        } else {
          state = 'Property_State(N/A)';
          zip = 'Property_Zip_Code(N/A)';
        }
      } else {
        streetAddress = address[0];
        city = 'Property_City(N/A)';
        state = 'Property_State(N/A)';
        zip = 'Property_Zip_Code(N/A)';
      }
    } else {
      streetAddress = 'Property_Street_Address(N/A)';
      city = 'Property_City(N/A)';
      state = 'Property_State(N/A)';
      zip = 'Property_Zip_Code(N/A)';
    }
    let myObj = {
      '@First_Name': this._utilities.titleCaseToWord(firstName),
      '@Last_Name': this._utilities.titleCaseToWord(lastName),
      '@Property_Street_Address': streetAddress,
      '@Property_City': city,
      '@Property_State': state,
      '@Property_Zip_Code': zip,
      '@Campaign_Number': campaignNumber,
    };
    if (teams?.roles) {
      teams.roles.map((i) => {
        let key = i.name.toString();
        if (key != undefined && message.includes(key)) {
          let originalRole = teams.roles.find((item) => item.name == key);
          let userId = teams.assignUser[originalRole._id];
          if (!userId) {
            originalRole = teams?.items?.roles.find(
              (item) => item.name == 'Owner'
            );
          }
          userId =
            userId != null
              ? userId
              : teams?.items?.assignUser['5fd093a2f44c5ac2ea1919b4'];
          let username = originalRole?.members.find(
            (item) => item._id.toString() == userId.toString()
          );
          let obj = {};
          obj['@' + key] = username?.firstName
            ? this._utilities.titleCaseToWord(`${username?.firstName}`)
            : `${key}(N/A)`;
          myObj = { ...myObj, ...obj };
        }
      });
    }
    let rowRole = [
      '@First_Name',
      '@Last_Name',
      '@Property_Street_Address',
      '@Property_City',
      '@Property_State',
      '@Property_Zip_Code',
      '@Campaign_Number',
    ];
    let roles = [];
    if (teams?.roles) {
      roles = teams.roles.map((item) => '@' + item.name);
    }
    let rolesWithKey = new RegExp([...rowRole, ...roles].join('|'), 'g');
    subject = message.replace(rolesWithKey, (matched) => {
      return myObj[matched];
    });
    // subject = subject.replace(/@/g, '');
    // console.log("subject::::::::::::", subject);
    // return subject;
    this.sendEmailForm.patchValue({
      messageBody: subject,
    });
  };

  subject(data) {
    let subject;
    let address;
    let streetAddress = 'Property_Street_Address(N/A)';
    let city = 'Property_City(N/A)';
    let state = 'Property_State(N/A)';
    let zip = 'Property_Zip_Code(N/A)';
    let name;
    let firstName = 'First_Name(N/A)';
    let lastName = 'last_Name(N/A)';
    let campaignNumber = 'Campaign_Number(N/A)';

    if (data.leadInfo.title) {
      name = data.leadInfo.title.split(' ');
      firstName = name[0] ? name[0] : 'First_Name(N/A)';
      lastName = name[1] ? name[1] : 'Last_Name(N/A)';
    }

    if (data?.leadInfo?.number) {
      campaignNumber = this._utilities.maskNumber(data?.leadInfo?.number);
    }
    if (data.leadInfo.address) {
      address = data.leadInfo.address.split(',');
      if (address.length > 1) {
        streetAddress = address[0]
          ? address[0]
          : 'Property_Street_Address(N/A)';
        city = address[1] ? address[1] : 'Property_City(N/A)';
        if (address.length > 2) {
          let subDivide = address[2];
          if (subDivide) {
            let sub = subDivide.split(' ');
            state = sub[1] ? sub[1] : 'Property_State(N/A)';
            zip = sub[2] ? sub[2] : 'Property_Zip_Code(N/A)';
          }
        } else {
          state = 'Property_State(N/A)';
          zip = 'Property_Zip_Code(N/A)';
        }
      } else {
        streetAddress = address[0];
        city = 'Property_City(N/A)';
        state = 'Property_State(N/A)';
        zip = 'Property_Zip_Code(N/A)';
      }
    } else {
      streetAddress = 'Property_Street_Address(N/A)';
      city = 'Property_City(N/A)';
      state = 'Property_State(N/A)';
      zip = 'Property_Zip_Code(N/A)';
    }
    let myObj = {
      First_Name: firstName,
      Last_Name: lastName,
      Property_Street_Address: streetAddress,
      Property_City: city,
      Property_State: state,
      Property_Zip_Code: zip,
      Campaign_Number: campaignNumber,
    };
    subject = data.details?.subject.replace(
      /@First_Name|@Last_Name|@Property_Street_Address|@Property_City|@Property_State|@Property_Zip_Code|@Campaign_Number/gi,
      function (matched) {
        let key = matched.substring(1);
        return myObj[key];
      }
    );
    subject = subject.replace(/@/g, '');
    this.sendEmailForm.patchValue({
      subject,
    });
  }

  messageBody(data) {
    let messageBody;
    let address;
    let streetAddress = 'Property_Street_Address(N/A)';
    let city = 'Property_City(N/A)';
    let state = 'Property_State(N/A)';
    let zip = 'Property_Zip_Code(N/A)';
    let name;
    let firstName = 'First_Name(N/A)';
    let lastName = 'last_Name(N/A)';
    let campaignNumber = 'Campaign_Number(N/A)';

    if (data.leadInfo.title) {
      name = data.leadInfo.title.split(' ');
      firstName = name[0] ? name[0] : 'First_Name(N/A)';
      lastName = name[1] ? name[1] : 'last_Name(N/A)';
    }
    if (data.leadInfo.title) {
      campaignNumber = this._utilities.maskNumber(data.leadInfo.number);
    }

    if (data.leadInfo.address) {
      address = data.leadInfo.address.split(',');
      if (address.length > 1) {
        streetAddress = address[0]
          ? address[0]
          : 'Property_Street_Address(N/A)';
        city = address[1] ? address[1] : 'Property_City(N/A)';
        if (address.length > 2) {
          let subDivide = address[2];
          if (subDivide) {
            let sub = subDivide.split(' ');
            state = sub[1] ? sub[1] : 'Property_State(N/A)';
            zip = sub[2] ? sub[2] : 'Property_Zip_Code(N/A)';
          }
        } else {
          state = 'Property_State(N/A)';
          zip = 'Property_Zip_Code(N/A)';
        }
      } else {
        streetAddress = address[0];
        city = 'Property_City(N/A)';
        state = 'Property_State(N/A)';
        zip = 'Property_Zip_Code(N/A)';
      }
    } else {
      streetAddress = 'Property_Street_Address(N/A)';
      city = 'Property_City(N/A)';
      state = 'Property_State(N/A)';
      zip = 'Property_Zip_Code(N/A)';
    }

    let myObj = {
      First_Name: firstName,
      Last_Name: lastName,
      Property_Street_Address: streetAddress ? streetAddress : '',
      Property_City: city ? city : '',
      Property_State: state ? state : '',
      Property_Zip_Code: zip ? zip : '',
      Campaign_Number: campaignNumber,
    };

    messageBody = data.details?.message.replace(
      /@First_Name|@Last_Name|@Property_Street_Address|@Property_City|@Property_State|@Property_Zip_Code|@Campaign_Number/gi,
      function (matched) {
        let key = matched.substring(1);
        return myObj[key];
      }
    );
    messageBody = messageBody.replace(/@/g, '');
    this.sendEmailForm.patchValue({
      messageBody: messageBody,
    });
  }

  onAllowUniqueCcEmail(email) {
    let emails = email.split(',');
    emails = [...new Set([...emails])];
    this.checkCC = emails;
    emails = emails.filter((obj) => {
      return this.checkBCC.indexOf(obj) == -1;
    });
    emails = emails.filter((obj) => {
      return this.Email.indexOf(obj) == -1;
    });
    this.ccArray = [];
    this.ccEmail = emails.toString();
    let emailValidate = this.ccEmail.split(',');
    let bool = emailValidate.map((obj) => {
      return MiscellaneousConstant.emailPattern.test(obj);
    });
    this.ccChecker = bool.every(Boolean);
    this.checkCC = this.ccEmail;
    return this.ccEmail;
  }

  onAllowUniqueEmail(email) {
    let emails = email.split(',');
    emails = [...new Set([...emails])];
    this.Email = emails;
    emails = emails.filter((obj) => {
      return this.checkBCC.indexOf(obj) == -1;
    });
    emails = emails.filter((obj) => {
      return this.checkCC.indexOf(obj) == -1;
    });
    this.toEmail = emails.toString();
    let emailValidate = this.toEmail.split(',');
    let bool = emailValidate.map((obj) => {
      return MiscellaneousConstant.emailPattern.test(obj);
    });
    this.Email = this.toEmail;
    return this.toEmail;
  }

  onAllowUniqueBccEmail(email) {
    let emails = email.split(',');
    emails = [...new Set([...emails])];
    this.checkBCC = emails;
    emails = emails.filter((obj) => {
      return this.checkCC.indexOf(obj) == -1;
    });
    emails = emails.filter((obj) => {
      return this.Email.indexOf(obj) == -1;
    });
    this.bccArray = [];
    this.bccEmail = emails.toString();
    let emailValidate = this.bccEmail.split(',');
    let bool = emailValidate.map((obj) => {
      return MiscellaneousConstant.emailPattern.test(obj);
    });
    this.bccChecker = bool.every(Boolean);
    this.checkBCC = this.bccEmail;
    return this.bccEmail;
  }

  onUploadLogo(output: UploadOutput): void {
    this.fileInput.nativeElement.value = null;
    this.imageArr = [];
    if (output.type === 'allAddedToQueue') {
      this.uploadAttachment();
    } else if (
      output.type === 'addedToQueue' &&
      typeof output.file !== 'undefined'
    ) {
      this.selectedFileSize = output.file?.size ? Number(output.file?.size) : 0;
      if (this.selectedFileSize > 10000000) {
        this.selectedFileSize = 0;
        this._toastrService.error(this.messageConstant.exceedSizeLimit, '');

        const event: UploadInput = {
          type: 'cancel',
          id: output.file.id,
        };
        this.uploadInput.emit(event);

        const event2: UploadInput = {
          type: 'remove',
          id: output.file.id,
        };
        this.uploadInput.emit(event2);
      } else {
        this.fileSize = Number(this.fileSize) + this.selectedFileSize;
        if (this.fileSize > 10000000) {
          this.fileSize = Number(this.fileSize) - this.selectedFileSize;
          this.selectedFileSize = 0;
          this._toastrService.error(
            this.messageConstant.exceedTotalSizeLimit,
            ''
          );
          const event: UploadInput = {
            type: 'cancel',
            id: output.file.id,
          };
          this.uploadInput.emit(event);

          const event2: UploadInput = {
            type: 'remove',
            id: output.file.id,
          };
          this.uploadInput.emit(event2);
        } else {
          this.uploadFiles.push(output);
        }
      }
    } else if (
      output.type === 'rejected' &&
      typeof output.file !== 'undefined'
    ) {
      this._loaderService.stop();
      if (output?.file?.size > 10000000) {
        this._toastrService.error(this.messageConstant.exceedSizeLimit, '');
      }
    } else if (output.type === 'done' && typeof output.file !== 'undefined') {
    } else if (output.type === 'dragOut') {
      // drag out event
    } else if (output.type === 'drop') {
      // on drop event
    }
  }

  async uploadAttachment() {
    if (this.uploadFiles.length > 0) {
      for (let i = 0; i < this.uploadFiles.length; i++) {
        const output = this.uploadFiles[i];
        this.fileCount = i + 1;
        this.uploadFile(output.file);
      }
    }
    this.uploadFiles = [];
  }

  uploadFile(fileData) {
    let endpoint = environment.baseUrl + BaseUrl.sharedUrl + 'file-upload';
    if (this.data.moduleId) {
      endpoint += `?moduleId=${this.data.moduleId}&isPublic=1&type=0`;
    }

    var newformData: FormData = new FormData();
    const file: File = fileData.nativeFile;
    newformData.append('file', file, file.name);
    this._loaderService.start();
    this._sharedService.uploadFile(endpoint, newformData).subscribe(
      (response: ResponseModel) => {
        this.allFileCount += 1;
        if (this.allFileCount == this.fileCount) {
          this._loaderService.stop();
          this.allFileCount = 0;
        }
        if (response?.statusCode && response?.statusCode == 200) {
          if (response?.data.length > 0) {
            let imgData = response?.data[0];
            imgData['size'] = String(fileData?.size);
            this.attachments.push(imgData);
          }
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  onBlurMethod(index) {
    this.checkValidation[index] = true;
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.sendEmailForm.controls[controlName].hasError(errorName);
  };

  removeAttachmentArray(value) {
    this.editAttachmentArray = this.editAttachmentArray.filter(
      (item) => item.url !== value.url
    );
    this.fileSize = this.fileSize - Number(value?.size);
    this.selectedFileSize = 0;
  }

  removeImageArr(value) {
    this.attachments = this.attachments.filter(
      (item) => item.url !== value.url
    );
    this.fileSize = this.fileSize - Number(value?.size);
    this.selectedFileSize = 0;
  }

  addRemoveCC(value) {
    this.showCC = value;
    if (this.showCC == false) {
      this.sendEmailForm.patchValue({
        ccEmail: '',
      });
      this.checkCC = [];
      this.ccEmail = '';
      this.ccChecker = true;
    }
  }

  addRemoveBCC(value) {
    this.showBCC = value;
    if (this.showBCC == false) {
      this.sendEmailForm.patchValue({
        bccEmail: '',
      });
      this.checkBCC = [];
      this.bccEmail = '';
      this.bccChecker = true;
    }
  }

  verifyEmail(value: string) {
    if (this.sendEmailForm.controls.fromEmail.status == 'INVALID') {
      return;
    } else {
      const email = value ? value : this.sendEmailForm.value.fromEmail;
      this.checkEmail(email);
    }
  }

  checkEmail(email) {
    const obj = {
      email: email?.toLowerCase()?.trim(),
    };
    this.emailInvalidMessage = '';
    this.isEmailExist = false;
    this._loaderService.start();
    this._sharedService.checkEmail(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.isEmailExist = response.type == 'SUCCESS';
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          if (err.error.message) {
            this.isEmailExist = false;
            this.emailInvalidMessage = err.error.message;
          }
        }
      }
    );
  }

  onVerifyEmailDailog(email) {
    this.dialogRef = this.dialog.open(VerifyEmailComponent, {
      width: '550px',
      panelClass: 'verifyEmail',
      disableClose: true,
      data: {
        type: 'lead-info',
        subType: 'sendEmail',
        category: '',
        details: {},
        email: email,
      },
    });
  }

  onSubmit() {
    if (this.showCC == true) {
      let ccEmail = this.sendEmailForm.value.ccEmail;
      let ccEmailArray = ccEmail.split(',');
      let filterArray = ccEmailArray.filter((obj) => {
        return obj == this.sendEmailForm.value.fromEmail;
      });
      // if (filterArray.length > 0) {
      //   this._toastrService.error(
      //     'CC Email should not contain From Email.',
      //     ''
      //   );
      //   return;
      // }
    }
    if (this.showBCC == true) {
      let bccEmail = this.sendEmailForm.value.bccEmail;
      let bccEmailArray = bccEmail.split(',');
      let filterArray = bccEmailArray.filter((obj) => {
        return obj == this.sendEmailForm.value.fromEmail;
      });
      // if (filterArray.length > 0) {
      //   this._toastrService.error(
      //     'BCC Email should not contain From Email.',
      //     ''
      //   );
      //   return;
      // }
    }
    this.sendEmail();
  }

  sendEmail() {
    this.submitted = true;
    this.checkValidation = {
      fromEmail: true,
      toEmail: true,
      fromName: true,
    };

    if (
      this.sendEmailForm.invalid ||
      !this.ccChecker ||
      !this.bccChecker ||
      !this.isEmailExist
    ) {
      return;
    }

    this._loaderService.start();
    const { toEmail, subject, messageBody, fromName, fromEmail } =
      this.sendEmailForm.value;
    let messageBodyNew = this._utilities.getTextWithMentions(messageBody);
    this.customMessage(this.data, messageBodyNew, this.data.teams);
    messageBodyNew = this._utilities.populateEditorDataWithN(
      this.sendEmailForm.value.messageBody
    );

    let obj = {
      moduleId: this.data.moduleId,
      subModuleId: this.data.subModuleId,
      toEmail,
      subject,
      messageBody: messageBodyNew,
      fromName,
      fromEmail,
    };
    if (this.showCC == true) {
      obj['ccEmail'] = this.sendEmailForm.value.ccEmail;
    }

    if (this.showBCC == true) {
      obj['bccEmail'] = this.sendEmailForm.value.bccEmail;
    }

    this.editAttachmentArray = [
      ...this.editAttachmentArray,
      ...this.attachments,
    ];
    this.editAttachmentArray.forEach((obj) => {
      delete obj['size'];
    });

    this.editAttachmentArray = [
      ...new Set(this.editAttachmentArray.map(JSON.stringify)),
    ];
    this.editAttachmentArray = this.editAttachmentArray.map(JSON.parse);

    if (this.editAttachmentArray.length) {
      obj['attachmentArray'] = this.editAttachmentArray;
    }

    this._sharedService.sendEmail(obj).subscribe(
      (response: ResponseModel) => {
        if (response.data.status == 200) {
          this.emailDailog.close(response.data?.items);
          this._loaderService.stop();
          this._toastrService.success(this.messageConstant.emailSendSuccess);
          this._sharedService.refreshActivityLog.next(true);
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  onSaveAsTemplate() {
    this.submitted = true;
    this.checkValidation = {
      fromEmail: true,
      toEmail: true,
      fromName: true,
    };
    if (
      this.sendEmailForm.invalid ||
      !this.ccChecker ||
      !this.bccChecker ||
      !this.isEmailExist
    ) {
      return;
    }

    const { subject, messageBody, fromName, fromEmail } =
      this.sendEmailForm.value;

    let obj = {
      subject,
      message: messageBody,
      fromName,
      fromEmail,
    };

    if (this.showCC == true) {
      obj['ccEmail'] = this.sendEmailForm.value.ccEmail;
    }
    if (this.showBCC == true) {
      obj['bccEmail'] = this.sendEmailForm.value.bccEmail;
    }

    this.editAttachmentArray = [
      ...this.editAttachmentArray,
      ...this.attachments,
    ];
    this.editAttachmentArray = [
      ...new Set(this.editAttachmentArray.map(JSON.stringify)),
    ];
    this.editAttachmentArray = this.editAttachmentArray.map(JSON.parse);

    if (this.editAttachmentArray.length) {
      obj['attachmentArray'] = this.editAttachmentArray;
    }

    this.onSaveAsTemplateDialog(obj);
  }

  onSaveAsTemplateDialog(data, index?) {
    //this.emailDailog.close();
    this.dialogRef = this.dialog.open(AddEditEmailTemplateDailogComponent, {
      width: '520px',
      panelClass: 'addeditTemplate',
      disableClose: true,
      data: {
        type: 'email-template',
        subType: 'email',
        action: 'sendToSave',
        category: '',
        template: 'sendToSave',
        items: this.data.items,
        allTitle: {
          heading: 'Save Email Template',
          buttonTitle: 'Save as Template',
        },
        details: data,
        index: index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result.message == 'Success!') {
        //this.emailDailog.close(result.data);
      }
    });
  }
  onMentionSelect($event) {
    return `@${$event.name}` + ` `;
  }

  onChangeVal(event, type) {
    if (type == 'subject') {
      this.customSubject(this.data, event.target.value, this.data.teams);
    } else {
      this.customMessage(this.data, event.target.value, this.data.teams);
    }
  }

  getUserRoleList() {
    this._loaderService.start();
    let role = [];
    this._masterTaskService.getUserRoleList({ page: 1, limit: 100 }).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._loaderService.stop();
          let hash = Object.create(null);

          let roles = _.flatten(
            response.data.items.map((item) => item.roleData)
          );
          let result = roles.reduce((r, o) => {
            if (!hash[o?._id]) {
              hash[o?._id] = {
                _id: o?._id,
                name: o?.name,
                roles: [],
              };
              r.push(hash[o?._id]);
            }
            hash[o?._id].roles.push({
              _id: o?._id,
              name: o?.name,
            });
            return r;
          }, []);
          result.map((item) => {
            role.push(item.name.toString());
          });
          this.items = [...this.items, ...role];
          // console.log(this.items);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
}
