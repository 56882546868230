import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

// UTILS
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

// SERVICES
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserService } from 'src/app/providers/user/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-getting-started-user-progress',
  templateUrl: './getting-started-user-progress.component.html',
  styleUrls: ['./getting-started-user-progress.component.scss'],
})
export class GettingStartedUserProgressComponent implements OnInit {
  messageConstant = MessageConstant;
  progressData: any = {};
  totalProgress: number = 0;
  lofo: number = 100;
  userProgress: number = 0;
  userProgressPercentage: number = 0;
  progressPercentage: string = '100';
  forLead: boolean = false;
  forPortNumber: boolean = false;
  forVideo: boolean = false;
  videoUrl: any = '';
  videoLink: any = {
    listStacking: 'https://www.loom.com/share/da6fe616f6b047bb8e59510109414042',
    drivingforDollars: 'https://www.youtube.com/embed/HndFRgDDRr0',
    skipTracing:
      'https://www.loom.com/share/49c0927e2afe41bf97f6fd9646ddfb7c?sid=02ded272-b8ff-4b81-97a1-1c9501ac87f6',

    sellerWebsite: 'https://www.loom.com/share/c4baf1dbed0b4c47872fcea4ad71122f',
    coldCallingDialer: 'https://www.loom.com/share/35e46fa185e64f06aabeb1b3a37259ac',
    sendDirectMail: 'https://www.loom.com/share/c15963dfdf364a47a21eade0bfa5e1d6',
    dripCampaign: 'https://youtu.be/g1-BuA3j8a8',

    speedToLead:
      'https://www.loom.com/share/ed7b27d2d03e43c8832e10a2ab534f72?sid=7fe2fa04-b990-48fc-9ea5-8896b62dec2c',

    eSign: 'https://www.loom.com/share/aebcbe89553d475fa63a08a463e36bb6?sid=ef0c6188-6355-47e6-8503-4552cec58c73',

    inAppAnswering:
      'https://www.loom.com/share/92140250ab234f0a94a5b6620b8a8a1f?sid=3b8dc741-a366-443d-a37c-80d5982c29df',

    downloadApp: 'https://apps.apple.com/us/app/resimpli/id1503295381',
    teamMember: 'https://youtu.be/uDqvtwkV6OQ',
    addBuyer: 'https://help.resimpli.com/en/articles/6147235-how-to-import-your-buyers-list-into-resimpli',
    addVendor: 'https://youtu.be/wtfct-vEHPs',
    buyerWebsite: 'https://www.loom.com/share/4288febfc28b4c4fb05d71a914d223f9',
    marketingAndNumbers: 'https://www.loom.com/share/ce981ca982994304a0f11b20927d2768',
  };

  isDataExpand: boolean = false;

  constructor(
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    public _userService: UserService,
    private _sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _utilities: CommonFunctionsService
  ) {}

  ngOnInit(): void {
    this.getUserProgress();
  }
  getUserProgress() {
    let progressData1 = JSON.parse(JSON.stringify(this.data?.progressData));
    if (this._utilities.progressListStackingData?.length > 1) {
      progressData1?.map((item) => {
        if (item?.title == 'Data') {
          console.log('item?.value = ', item?.value);
          let valueArray = [];
          let listStackingObj = this._utilities.progressListStackingData[0];
          valueArray.push(listStackingObj);

          if (item?.value?.length > 0) {
            valueArray.push(item?.value[0]);
          }

          let skipTracingObj = this._utilities.progressListStackingData[1];
          valueArray.push(skipTracingObj);
          console.log('valueArray = ', valueArray);
          item.value = valueArray;
          console.log('item?.value 2= ', item?.value);
        }
      });
    }

    this.progressData = progressData1;
    this.totalProgress = 0;
    this.userProgress = 0;
    this.userProgressPercentage = 0;
    this.progressPercentage = '100';

    for (let i = 0; i < this.progressData.length; i++) {
      const obj = this.progressData[i];
      for (let j = 0; j < obj?.value?.length; j++) {
        const subObj = obj?.value[j];
        this.totalProgress = this.totalProgress + 1;
        if (subObj?.value) {
          this.userProgress = this.userProgress + 1;
        }
      }
    }

    this.userProgressPercentage = Math.round(
      (this.userProgress * 100) / this.totalProgress
    );

    this.progressPercentage = this.userProgressPercentage + ',100';
  }
  watchAndReadDetails(type, url?) {
    if (type == 'back') {
      this.forLead = false;
      this.forPortNumber = false;
      this.forVideo = false;
    } else if (type == 'forLead') {
      this.forLead = true;
    } else if (type == 'forPortNumber') {
      this.forPortNumber = true;
    } else if (type == 'forVideo') {
      if (this.videoLink[url]) {
        window.open(this.videoLink[url], '_blank');
      }
      // this.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
      //   this.videoLink[url]
      // );
      // this.forVideo = true;
    }
  }

  onTitleAction(obj) {
    obj.isExpand = !obj.isExpand;
  }
}
