import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injectable } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedRoutingModule } from './shared-routing.module';
import { NgxMaskModule } from 'ngx-mask';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgxUploaderModule } from 'ngx-uploader';
import {
  HammerGestureConfig,
  HammerModule,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { ToastrModule } from 'ngx-toastr';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ChipsModule } from 'primeng/chips';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatTabsModule } from '@angular/material/tabs';
import { DndModule } from 'ngx-drag-drop';
import { MentionModule } from 'angular-mentions';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxStripeModule } from 'ngx-stripe';
import { TooltipModule } from 'primeng/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
// import { AngularEditorModule } from '@kolkov/angular-editor';
// import { NgxTimerModule } from 'ngx-timer';
import { DropdownModule } from 'primeng/dropdown';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { QuillModule } from 'ngx-quill';
import { MatSliderModule } from '@angular/material/slider';
import {MatSelectModule} from '@angular/material/select';
import * as Hammer from 'hammerjs';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { DatePipe } from '@angular/common';
// UTILS
import { environment } from 'src/environments/environment';

// SERVICES
import { SharedService } from './shared.service';
import { SmsService } from '../providers/sms/sms.service';

// PIPES & DIRECTIVE
import { FormatPipe } from '../utils/pipes/format.pipe';
import { SearchTextPipe } from '../utils/pipes/search-text-pipe';
import { CurrencyPositivePipe } from '../utils/pipes/currency-positive.pipe';
import { DateByTimeZonePipe } from '../utils/pipes/date-by-time-zone.pipe';
import { SafeHtml } from '../utils/pipes/SafeHtml';
import { NumberOnlyDirective } from '../utils/directive/number-only.directive';
import { MentionHighlightPipe } from '../utils/pipes/mention-highlight.pipe';
import { TextToLinkPipe } from '../utils/pipes/text-to-link.pipe';

// COMPONENTS
import { ActivityLogsComponent } from '../shared/activity-logs/activity-logs.component';
import { SubHeaderComponent } from '../shared/sub-header/sub-header.component';
import { FilesComponent } from '../shared/files/files.component';
import { SowComponent } from '../shared/sow/sow.component';
import { DeleteDialogComponent } from './dialog/delete-dialog/delete-dialog.component';
import { ListViewComponent } from './list-view/list-view.component';
import { GridViewComponent } from './grid-view/grid-view.component';
import { TopbarComponent } from './topbar/topbar.component';
import { PaginationComponent } from './pagination/pagination.component';
import { SendEmailDialogComponent } from './dialog/emails-dailog/choose-email-dailog/send-email-dialog/send-email-dialog.component';
import { SendESignComponent } from './sub-header/send-e-sign/send-e-sign.component';
import { FilterExpensesDialogComponent } from './dialog/filter-expenses-dialog/filter-expenses-dialog.component';
import { AddEditFolderFilesModalComponent } from './files/dialog/add-edit-folder-files-modal/add-edit-folder-files-modal.component';
import { MediaPreviewComponent } from './dialog/media-preview/media-preview.component';
import { LeadsInfoComponent } from './leads-info/leads-info.component';
import { AddEditContactsComponent } from './leads-info/add-edit-contacts/add-edit-contacts.component';
import { CallDetailDailogsComponent } from './dialog/call-detail-dailogs/call-detail-dailogs.component';
import { IncomingCallDetailDailogsComponent } from './dialog/incoming-call-detail-dailogs/incoming-call-detail-dailogs.component';
import { LinksComponent } from './links/links.component';
import { AddEditLinksComponent } from './links/add-edit-links/add-edit-links.component';
import { ConfirmationDialogComponent } from './dialog/confirmation-dialog/confirmation-dialog.component';
import { AutoFollowUpComponent } from './sub-header/auto-follow-up/auto-follow-up.component';
import { BlockNumberComponent } from './sub-header/block-number/block-number.component';
import { SendRvmComponent } from './sub-header/send-rvm/send-rvm.component';
import { BlockNumberDailogComponent } from './dialog/block-number-dailog/block-number-dailog.component';

import { GoogleMapComponent } from './dialog/maps/google-map/google-map.component';
import { AddTagListSkipComponent } from './dialog/add-tag-list-skip/add-tag-list-skip.component';
import { ExportProspectComponent } from './dialog/export-prospect/export-prospect.component';
import { StreetMapComponent } from './dialog/maps/street-map/street-map.component';
import { CheckingLeadComponent } from './dialog/checking-lead/checking-lead.component';
import { EditNumberComponent } from './dialog/edit-number/edit-number.component';
import { ChooseEmailDailogComponent } from './dialog/emails-dailog/choose-email-dailog/choose-email-dailog.component';
import { VerifyEmailComponent } from './dialog/emails-dailog/choose-email-dailog/verify-email/verify-email.component';
import { AddTagsComponent } from './dialog/add-tags/add-tags.component';
import { MoveFilesComponent } from './files/dialog/move-files/move-files.component';
import { AddEditEmailTemplateDailogComponent } from './dialog/emails-dailog/add-edit-email-template-dailog/add-edit-email-template-dailog.component';
import { AddEditRvmComponent } from './dialog/add-edit-rvm/add-edit-rvm.component';
import { ContactsDialogComponent } from './dialog/contacts-dialog/contacts-dialog.component';
import { SmsDialogComponent } from './dialog/sms-dialog/sms-dialog.component';
import { CopyDripComponent } from './dialog/copy-drip/copy-drip.component';
import { CalendarLinkComponent } from './dialog/calendar-link/calendar-link.component';
import { AddGlobalTagsComponent } from './dialog/add-global-tags/add-global-tags.component';
import { RenameTagComponent } from './dialog/rename-tag/rename-tag.component';
import { RemoveTagsComponent } from './dialog/remove-tags/remove-tags.component';
import { AddRecordingComponent } from './dialog/add-recording/add-recording.component';
import { CopyTemplateComponent } from './dialog/copy-template/copy-template.component';
import { SendEmailEsignComponent } from './sub-header/send-e-sign/dialog/send-email-esign/send-email-esign.component';
import { EsignComponent } from './esign/esign.component';
import { DirectMailComponent } from './sub-header/direct-mail/direct-mail.component';
import { DirectMailPreviewComponent } from './sub-header/direct-mail/direct-mail-preview/direct-mail-preview.component';
import { TemplateOneComponent } from './sub-header/direct-mail/direct-mail-preview/template-one/template-one.component';
import { TemplateThreeComponent } from './sub-header/direct-mail/direct-mail-preview/template-three/template-three.component';
import { TemplateTwoComponent } from './sub-header/direct-mail/direct-mail-preview/template-two/template-two.component';
import { SearchDialogComponent } from './dialog/search-dialog/search-dialog.component';
import { AddCardDialogComponent } from './dialog/add-card-dialog/add-card-dialog.component';
import { SetAvailabilityDialogComponent } from './dialog/set-availability-dialog/set-availability-dialog.component';
import { AddCallForwardComponent } from './sub-header/add-call-forward/add-call-forward.component';
import { CardSelectionDialogComponent } from './dialog/card-selection-dialog/card-selection-dialog.component';
import { SendBulkSmsDialogComponent } from './dialog/send-bulk-sms-dialog/send-bulk-sms-dialog.component';
import { SendBulkEmailDialogComponent } from './dialog/send-bulk-email-dialog/send-bulk-email-dialog.component';
import { PermissionDeniedComponent } from './permission-denied/permission-denied.component';
import { SowDailogComponent } from './dialog/sow-dailog/sow-dailog.component';
import { AddEditSmsTemplateComponent } from './dialog/add-edit-sms-template/add-edit-sms-template.component';
import { SowDetailsComponent } from './dialog/sow-details/sow-details.component';
import { FilterActivitylogDialogComponent } from './activity-logs/filter-activitylog-dialog/filter-activitylog-dialog.component';
import { SaveTemplateDialogComponent } from './dialog/send-bulk-email-dialog/save-template-dialog/save-template-dialog.component';
import { ViewMembersListDialogComponent } from './dialog/view-members-list-dialog/view-members-list-dialog.component';
import { TestEmailDialogComponent } from './dialog/send-bulk-email-dialog/test-email-dialog/test-email-dialog.component';
import { OpenLeadDialogComponent } from './dialog/open-lead-dialog/open-lead-dialog.component';
import { ViewHtmlDialogComponent } from './dialog/view-html-dialog/view-html-dialog.component';
import { UnderMaintenanceComponent } from './under-maintenance/under-maintenance.component';
import { GlobalTagsComponent } from './global-tags/global-tags.component';
import { SendDialerComponent } from './dialog/send-dialer/send-dialer.component';
import { ColdCallSettingComponent } from './dialog/cold-call-setting/cold-call-setting.component';
import { ColdCallComponent } from './dialog/cold-call/cold-call.component';
import { SendSmsComponent } from './dialog/cold-call/send-sms/send-sms.component';
import { LeadDialerComponent } from './dialog/lead-dialer/lead-dialer.component';
import { ChangeNumberComponent } from './dialog/change-number/change-number.component';
import { TakeNoteComponent } from './dialog/take-note/take-note.component';
import { ListExportMarketingComponent } from './dialog/list-export-marketing/list-export-marketing.component';
import { FiltersComponent } from './filters/filters.component';
import { SaveFilterTemplateDialogComponent } from './filters/save-filter-template-dialog/save-filter-template-dialog.component';
import { ActivityDialogComponent } from './dialog/activity-dialog/activity-dialog.component';
import { AdvancedPaginationComponent } from './advanced-pagination/advanced-pagination.component';
import { TakeNoteDialerComponent } from './dialog/cold-call/take-note-dialer/take-note-dialer.component';
import { MergeLeadDialogComponent } from './dialog/merge-lead-dialog/merge-lead-dialog.component';
import { UpgradeAccountComponent } from './dialog/upgrade-account/upgrade-account.component';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { AddEditPreferenceComponent } from './leads-info/add-edit-preference/add-edit-preference.component';
import { EditCampaignComponent } from './dialog/edit-campaign/edit-campaign.component';
import { GeneralComponent } from './dialog/edit-campaign/general/general.component';
import { TeamsComponent } from './dialog/edit-campaign/teams/teams.component';
import { DripAutomationComponent } from './dialog/edit-campaign/drip-automation/drip-automation.component';
import { TagsAutomationComponent } from './dialog/edit-campaign/tags-automation/tags-automation.component';
import { ReassignLeadsComponent } from './dialog/edit-campaign/teams/reassign-leads/reassign-leads.component';

import { CopyCallFlowComponent } from './dialog/copy-call-flow/copy-call-flow.component';
import { PinnedNotesDialogComponent } from './dialog/pinned-notes-dialog/pinned-notes-dialog.component';
import { UserListDialogComponent } from './dialog/user-list-dialog/user-list-dialog.component';
import { ConfirmationNumberDialogComponent } from './dialog/confirmation-number-dialog/confirmation-number-dialog.component';
import { AddPhoneDialogComponent } from './dialog/cold-call/add-phone-dialog/add-phone-dialog.component';
import { ManagePhoneStatusComponent } from './dialog/cold-call/manage-phone-status/manage-phone-status.component';
import { ManageEmailDialogComponent } from './dialog/cold-call/manage-email-dialog/manage-email-dialog.component';
import { DialerChangeCampaignComponent } from './dialog/dialer-change-campaign/dialer-change-campaign.component';
import { AddEditGroupComponent } from './dialog/add-edit-group/add-edit-group.component';
import { EditDialerNumberComponent } from './dialog/edit-dialer-number/edit-dialer-number.component';
import { DialerConvertLeadComponent } from './dialog/dialer-convert-lead/dialer-convert-lead.component';
import { AddEditTaskTemplateComponent } from './dialog/add-edit-task-template/add-edit-task-template.component';
import { ChangeNumberDialogComponent } from './dialog/cold-call/change-number-dialog/change-number-dialog.component';
import { DialerFilterComponent } from './dialer-filter/dialer-filter.component';
import { FirstTimeLoginComponent } from './dialog/first-time-login/first-time-login.component';
import { RemoveMarketDetailsComponent } from './dialog/remove-market-details/remove-market-details.component';
import { ChangeTitleComponent } from './dialog/change-title/change-title.component';
import { ChangeColorComponent } from './dialog/change-color/change-color.component';
import { ColorPickerComponent } from './dialog/color-picker/color-picker.component';
import { ColorPaletteComponent } from './dialog/color-picker/color-palette/color-palette.component';
import { ColorSliderComponent } from './dialog/color-picker/color-slider/color-slider.component';
import { ManageOffersComponent } from './leads-info/manage-offers/manage-offers.component';
import { AddEditContingencyComponent } from './dialog/add-edit-contingency/add-edit-contingency.component';
import { LeadCallStatusDialogComponent } from './dialog/call-detail-dailogs/lead-call-status-dialog/lead-call-status-dialog.component';
import { LeadConnectDialogComponent } from './dialog/lead-connect-dialog/lead-connect-dialog.component';
import { CampaignStatsComponent } from './dialog/campaign-stats/campaign-stats.component';
import { EmailStatsComponent } from './email-stats/email-stats.component';
import { SmsStatsComponent } from './sms-stats/sms-stats.component';
import { PreferenceQuestionComponent } from './preference-question/preference-question.component';
import { EditTransactionViewComponent } from './dialog/edit-transaction-view/edit-transaction-view.component';
import { ResimpliAiComponent } from './dialog/resimpli-ai/resimpli-ai.component';
import { DlcMessageDialogComponent } from './dialog/dlc-message-dialog/dlc-message-dialog.component';
import { TemplateFourComponent } from './sub-header/direct-mail/direct-mail-preview/template-four/template-four.component';
import { TemplateFiveComponent } from './sub-header/direct-mail/direct-mail-preview/template-five/template-five.component';
import { TemplateSixComponent } from './sub-header/direct-mail/direct-mail-preview/template-six/template-six.component';
import { UpgradePlanInfoComponent } from './dialog/upgrade-plan-info/upgrade-plan-info.component';
import { GettingStartedUserProgressComponent } from './dialog/getting-started-user-progress/getting-started-user-progress.component';
import { NotificationComponent } from './notification/notification.component';
import { DlcStatusDailogComponent } from './dialog/dlc-status-dailog/dlc-status-dailog.component';
import { SettingIntroductionComponent } from './dialog/setting-introduction/setting-introduction.component';
import { DealNetProfitComponent } from './dialog/deal-net-profit/deal-net-profit.component';
import { OnboardingCallDailogComponent } from './dialog/onboarding-call-dailog/onboarding-call-dailog.component';
import { BusinessLettersTemplateOneComponent } from './sub-header/direct-mail/direct-mail-preview/business-letters/business-letters-template-one/business-letters-template-one.component';
import { YellowLettersTemplateOneComponent } from './sub-header/direct-mail/direct-mail-preview/yellow-letters/yellow-letters-template-one/yellow-letters-template-one.component';
import { YellowLettersTemplateTwoComponent } from './sub-header/direct-mail/direct-mail-preview/yellow-letters/yellow-letters-template-two/yellow-letters-template-two.component';
import { YellowLettersTemplateThreeComponent } from './sub-header/direct-mail/direct-mail-preview/yellow-letters/yellow-letters-template-three/yellow-letters-template-three.component';
import { BusinessLettersTemplateTwoComponent } from './sub-header/direct-mail/direct-mail-preview/business-letters/business-letters-template-two/business-letters-template-two.component';
import { BusinessLettersTemplateThreeComponent } from './sub-header/direct-mail/direct-mail-preview/business-letters/business-letters-template-three/business-letters-template-three.component';
import { CallTranscriptDialogComponent } from './dialog/call-transcript-dialog/call-transcript-dialog.component';
import { ChooseSmsTemplateComponent } from './dialog/choose-sms-template/choose-sms-template.component';
import { TranscriptionPlanUpgradeComponent } from './dialog/transcription-plan-upgrade/transcription-plan-upgrade.component';
import { CallTranscriptCreditManageComponent } from './dialog/call-transcript-credit-manage/call-transcript-credit-manage.component';
import { ZoomIntroDialogComponent } from './dialog/zoom-intro-dialog/zoom-intro-dialog.component';
import { UpgradePlanDialogComponent } from './dialog/upgrade-plan-dialog/upgrade-plan-dialog.component';
import { PinnedNotesViewDialogComponent } from './dialog/pinned-notes-view-dialog/pinned-notes-view-dialog.component';
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

// register FullCalendar plugins
// FullCalendarModule.registerPlugins([dayGridPlugin, timeGridPlugin]);

@NgModule({
  declarations: [
    ActivityLogsComponent,
    SubHeaderComponent,
    FilesComponent,
    SowComponent,
    DeleteDialogComponent,
    ListViewComponent,
    GridViewComponent,
    TopbarComponent,
    PaginationComponent,
    SendEmailDialogComponent,
    SendESignComponent,
    FilterExpensesDialogComponent,
    AddEditFolderFilesModalComponent,
    MediaPreviewComponent,
    LeadsInfoComponent,
    AddEditContactsComponent,
    CallDetailDailogsComponent,
    IncomingCallDetailDailogsComponent,
    LinksComponent,
    AddEditLinksComponent,
    ConfirmationDialogComponent,
    AutoFollowUpComponent,
    BlockNumberComponent,
    SendRvmComponent,
    BlockNumberDailogComponent,
    GoogleMapComponent,
    AddTagListSkipComponent,
    ExportProspectComponent,
    CheckingLeadComponent,
    StreetMapComponent,
    FormatPipe,
    SearchTextPipe,
    EditNumberComponent,
    ChooseEmailDailogComponent,
    VerifyEmailComponent,
    AddTagsComponent,
    MoveFilesComponent,
    AddEditEmailTemplateDailogComponent,
    AddEditRvmComponent,
    ContactsDialogComponent,
    SmsDialogComponent,
    NumberOnlyDirective,
    CopyTemplateComponent,
    SendEmailEsignComponent,
    EsignComponent,
    AddGlobalTagsComponent,
    RenameTagComponent,
    RemoveTagsComponent,
    CopyDripComponent,
    CalendarLinkComponent,
    AddRecordingComponent,
    CurrencyPositivePipe,
    DateByTimeZonePipe,
    SafeHtml,
    DirectMailComponent,
    DirectMailPreviewComponent,
    TemplateOneComponent,
    TemplateThreeComponent,
    TemplateTwoComponent,
    SearchDialogComponent,
    AddCardDialogComponent,
    MentionHighlightPipe,
    SetAvailabilityDialogComponent,
    AddCallForwardComponent,
    CardSelectionDialogComponent,
    SendBulkSmsDialogComponent,
    SendBulkEmailDialogComponent,
    PermissionDeniedComponent,
    SowDailogComponent,
    AddEditSmsTemplateComponent,
    SowDetailsComponent,
    FilterActivitylogDialogComponent,
    SaveTemplateDialogComponent,
    ViewMembersListDialogComponent,
    TestEmailDialogComponent,
    OpenLeadDialogComponent,
    TextToLinkPipe,
    ViewHtmlDialogComponent,
    UnderMaintenanceComponent,
    GlobalTagsComponent,
    SendDialerComponent,
    ColdCallSettingComponent,
    ColdCallComponent,
    SendSmsComponent,
    TakeNoteDialerComponent,
    LeadDialerComponent,
    ChangeNumberComponent,
    TakeNoteComponent,
    ListExportMarketingComponent,
    FiltersComponent,
    SaveFilterTemplateDialogComponent,
    ActivityDialogComponent,
    AdvancedPaginationComponent,
    MergeLeadDialogComponent,
    UpgradeAccountComponent,
    AudioPlayerComponent,
    AddEditPreferenceComponent,
    EditCampaignComponent,
    GeneralComponent,
    TeamsComponent,
    DripAutomationComponent,
    TagsAutomationComponent,
    ReassignLeadsComponent,
    CopyCallFlowComponent,
    PinnedNotesDialogComponent,
    UserListDialogComponent,
    ConfirmationNumberDialogComponent,
    AddPhoneDialogComponent,
    ManagePhoneStatusComponent,
    ManageEmailDialogComponent,
    DialerChangeCampaignComponent,
    AddEditGroupComponent,
    EditDialerNumberComponent,
    DialerConvertLeadComponent,
    AddEditTaskTemplateComponent,
    ChangeNumberDialogComponent,
    DialerFilterComponent,
    FirstTimeLoginComponent,
    RemoveMarketDetailsComponent,
    ChangeTitleComponent,
    ChangeColorComponent,
    ColorPickerComponent,
    ColorPaletteComponent,
    ColorSliderComponent,
    ManageOffersComponent,
    AddEditContingencyComponent,
    LeadCallStatusDialogComponent,
    LeadConnectDialogComponent,
    CampaignStatsComponent,
    EmailStatsComponent,
    SmsStatsComponent,
    PreferenceQuestionComponent,
    EditTransactionViewComponent,
    ResimpliAiComponent,
    DlcMessageDialogComponent,
    TemplateFourComponent,
    TemplateFiveComponent,
    TemplateSixComponent,
    UpgradePlanInfoComponent,
    GettingStartedUserProgressComponent,
    NotificationComponent,
    DlcStatusDailogComponent,
    SettingIntroductionComponent,
    DealNetProfitComponent,
    OnboardingCallDailogComponent,
    BusinessLettersTemplateOneComponent,
    YellowLettersTemplateOneComponent,
    YellowLettersTemplateTwoComponent,
    YellowLettersTemplateThreeComponent,
    BusinessLettersTemplateTwoComponent,
    BusinessLettersTemplateThreeComponent,
    ChooseSmsTemplateComponent,
    ZoomIntroDialogComponent,
    UpgradePlanDialogComponent,
    CallTranscriptDialogComponent,
    TranscriptionPlanUpgradeComponent,
    CallTranscriptCreditManageComponent,
    PinnedNotesViewDialogComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CalendarModule,
    NgxUiLoaderModule,
    NgxMaskModule.forRoot(),
    QuillModule.forRoot(),
    ToastrModule.forRoot({
      closeButton: true,
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
    }),
    MatDialogModule,
    MatIconModule,
    MultiSelectModule,
    ChipsModule,
    AutoCompleteModule,
    CheckboxModule,
    AutoCompleteModule,
    MatButtonModule,
    InfiniteScrollModule,
    NgxUploaderModule,
    ClipboardModule,
    DndModule,
    MatTabsModule,
    MatRadioModule,
    MentionModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    FullCalendarModule,
    MatSlideToggleModule,
    // NgxTimerModule,
    NgxStripeModule.forRoot(environment.stripePublickKey),
    TooltipModule,
    ModalModule.forRoot(),
    // AngularEditorModule,
    DropdownModule,
    DragDropModule,
    MatMenuModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    HammerModule,
    MatSliderModule,
    EditorModule,
    MatSelectModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CalendarModule,
    NgxMaskModule,
    NgxUiLoaderModule,
    MatDialogModule,
    ClipboardModule,
    ActivityLogsComponent,
    SubHeaderComponent,
    FilesComponent,
    SowComponent,
    NgxUiLoaderModule,
    ListViewComponent,
    GridViewComponent,
    TopbarComponent,
    CheckboxModule,
    AutoCompleteModule,
    DeleteDialogComponent,
    SendEmailDialogComponent,
    MediaPreviewComponent,
    LeadsInfoComponent,
    AddEditContactsComponent,
    CallDetailDailogsComponent,
    IncomingCallDetailDailogsComponent,
    LinksComponent,
    AddEditLinksComponent,
    DndModule,
    MatRadioModule,
    AutoFollowUpComponent,
    BlockNumberComponent,
    SendRvmComponent,
    MatTabsModule,
    MatButtonModule,
    GoogleMapComponent,
    NgxUploaderModule,
    MultiSelectModule,
    ChipsModule,
    MatIconModule,
    AutoCompleteModule,
    FormatPipe,
    SearchTextPipe,
    MentionModule,
    NumberOnlyDirective,
    EsignComponent,
    AddGlobalTagsComponent,
    MatChipsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    FullCalendarModule,
    MatSlideToggleModule,
    CurrencyPositivePipe,
    DateByTimeZonePipe,
    SafeHtml,
    PaginationComponent,
    AdvancedPaginationComponent,
    SearchDialogComponent,
    MentionHighlightPipe,
    // AngularEditorModule,
    AddEditSmsTemplateComponent,
    DragDropModule,
    TextToLinkPipe,
    MatMenuModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    UnderMaintenanceComponent,
    GlobalTagsComponent,
    SendDialerComponent,
    ColdCallSettingComponent,
    ColdCallComponent,
    TakeNoteComponent,
    SendSmsComponent,
    TakeNoteDialerComponent,
    LeadDialerComponent,
    InfiniteScrollModule,
    ListExportMarketingComponent,
    TooltipModule,
    UpgradeAccountComponent,
    QuillModule,
    HammerModule,
    MatSliderModule,
    AudioPlayerComponent,
    AddEditPreferenceComponent,
    EditCampaignComponent,
    ChangeTitleComponent,
    ChangeColorComponent,
    ColorPickerComponent,
    ColorPaletteComponent,
    ColorSliderComponent,
    CampaignStatsComponent,
    EmailStatsComponent,
    SmsStatsComponent,
    PreferenceQuestionComponent,
    EditorModule,
    MatSelectModule
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    { provide: MatDialogRef, useValue: {} },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    SharedService,
    SmsService,
    CurrencyPipe,
    DatePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
