import { VersionConstant } from './version-constant';
export class BaseUrl {
  public static user = VersionConstant.version + 'user/';
  public static projectType = VersionConstant.version + 'projectType/';
  public static projectStatus = VersionConstant.version + 'projectStatus/';
  public static mainStatus = VersionConstant.version + 'mainStatus/';
  public static crmUrl = VersionConstant.version + 'CRMQuestion/';
  public static numberUrl = VersionConstant.version + 'number/';
  public static leadUrl = VersionConstant.version + 'lead/';
  public static listingUrl = VersionConstant.version + 'listing/';
  public static melissaUrl = VersionConstant.version + 'melissa/';
  public static inventoryUrl = VersionConstant.version + 'inventory/';
  public static linkUrl = VersionConstant.version + 'link/';
  public static folderUrl = VersionConstant.version + 'folder/';
  public static fileUrl = VersionConstant.version + 'file/';
  public static masterGroupUrl = VersionConstant.version + 'masterGroup/';
  public static masterQuestionUrl = VersionConstant.version + 'masterQuestion/';
  public static appointmentUrl = VersionConstant.version + 'appointment/';
  public static sharedUrl = VersionConstant.version + 'shared/';
  public static publicFileUrl = VersionConstant.version + 'file/';
  public static publicFolderUrl = VersionConstant.version + 'folder/';
  public static taskUrl = VersionConstant.version + 'task/';
  public static googleMapUrl = VersionConstant.version + 'googleMap/';
  public static linkPlivo = VersionConstant.version + 'plivo/';
  public static linkBuyNumber = VersionConstant.version + 'buy-number/';
  public static mailTemplateUrl = VersionConstant.version + 'mailTemplate/';
  public static sendGridUrl = VersionConstant.version + 'send-grid/';
  public static tagUrl = VersionConstant.version + 'tags/';
  public static rvmUrl = VersionConstant.version + 'audioTrack/';
  public static moduleUrl = VersionConstant.version + 'module/';
  public static communicationUrl = VersionConstant.version + 'communications/';
  public static smsUrl = VersionConstant.version + 'sms-plivo/';
  public static callLogUrl = VersionConstant.version + 'callLog/';
  public static deadResonUrl = VersionConstant.version + 'deadReson/';
  public static esignUrl = VersionConstant.version + 'esign/';
  public static esignDocsUrl = VersionConstant.version + 'esign-docs/';
  public static webFormUrl = VersionConstant.version + 'webForm/';
  public static activityUrl = VersionConstant.version + 'activity/';
  public static globalTagsUrl = VersionConstant.version + 'global-tags/';
  public static googleCalendarUrl = VersionConstant.version + 'googleCalendar/';
  public static buyerUrl = VersionConstant.version + 'buyer/';
  public static buyerWebFormUrl = VersionConstant.version + 'buyerWebForm/';
  public static buyerQuestionUrl = VersionConstant.version + 'buyerQuestion/';
  public static resetPasswordRedirectLink = 'auth/reset-password';
  public static confirmEmailRedirectLink = 'auth/confirm-email/';
  public static masterTaskUrl = VersionConstant.version + 'masterTask/';
  public static accountUrl = VersionConstant.version + 'account/';
  public static vendorUrl = VersionConstant.version + 'vendor/';
  public static vendorCategoryUrl = VersionConstant.version + 'vendorCategory/';
  public static vendorSubCategoryUrl =
    VersionConstant.version + 'vendorSubCategory/';
  public static accountTagsUrl = VersionConstant.version + 'accountTag/';
  public static accountTagCategoryUrl =
    VersionConstant.version + 'accountTagCategory/';
  public static accountTagSubCategoryUrl =
    VersionConstant.version + 'accountTagSubCategory/';
  public static accountTagChildCategoryUrl =
    VersionConstant.version + 'accountTagChildCategory/';
  public static transactionUrl = VersionConstant.version + 'transaction/';
  public static dripUrl = VersionConstant.version + 'dripCompaign/';
  public static directMail = VersionConstant.version + 'lob/';
  public static directMailTemplate = VersionConstant.version + 'directmail/';
  public static callFlowUrl = VersionConstant.version + 'flow/';
  public static listStacking = 'liststacking/';
  public static role = VersionConstant.version + 'role/';
  public static plans = VersionConstant.version + 'plans/';
  public static blockNumberUrl = VersionConstant.version + 'blocknumber/';
  public static directMailUrl = VersionConstant.version + 'directMail/';
  public static eSignCreateTemplateRedirectLink = 'esign/Template';
  public static eSignCreateFolderLeadRedirectLink = 'leads/details';
  public static eSignCreateFolderInventoryRedirectLink = 'inventory/details';
  public static eSignCreateFolderSoldRedirectLink = 'sold/details';
  public static eSignCreateFolderRentalRedirectLink = 'rental/details';
  public static coupon = VersionConstant.version + 'coupon/';
  public static billingUrl = VersionConstant.version + 'billing/';
  public static creditUrl = VersionConstant.version + 'credit/';
  public static contractorUrl = VersionConstant.version + 'contractor/';
  public static smsTemplateUrl = VersionConstant.version + 'smsTemplate/';
  public static sowUrl = VersionConstant.version + 'sow/';
  public static templateUrl = VersionConstant.version + 'template/';
  public static mailJetUrl = VersionConstant.version + 'mailjet/';
  public static marketUrl = VersionConstant.version + 'market/';
  public static kpisUrl = VersionConstant.version + 'kpis/';
  public static dashboardUrl = VersionConstant.version + 'dashboard/';
  public static bulkUpload = VersionConstant.version + 'bulk-upload/';
  public static listStackingCreditUrl =
    VersionConstant.version + 'listStackingCredit/';
  public static dialerScriptUrl = VersionConstant.version + 'dialer/script/';
  public static dialerCampaignUrl =
    VersionConstant.version + 'dialer/campaign/';
  public static dialerCallLogUrl =
    VersionConstant.version + 'dialer/dialerCallLog/';
  public static dialerActivityUrl =
    VersionConstant.version + 'dialer/activity/';
  public static dialerStatsUrl = VersionConstant.version + 'dialer/stats/';
  public static scheduledSmsUrl = VersionConstant.version + 'scheduledsms/';
  public static journalEntryUrl = VersionConstant.version + 'journalEntry/';
  public static filterTemplateUrl = VersionConstant.version + 'filterTemplate/';
  public static driveUrl = VersionConstant.version + 'drive/';
  public static activityListUrl = VersionConstant.version + 'dialer/activity/';
  public static taskTemplate = VersionConstant.version + 'taskTemplate/';
  public static adminUrl = VersionConstant.version + 'admin/user/';
  public static marketV2Url = VersionConstant.version + 'market/';
  public static userV2Url = VersionConstant.version + 'user/';
  public static buyNumberV2Url = VersionConstant.version + 'buy-number/';
  public static leadUrlV2Url = VersionConstant.version + 'lead/';
  public static dashboardV2Url = VersionConstant.version + 'dashboard/';
  public static groupNumberV2Url = VersionConstant.version + 'group-number/';
  public static webFormV2Url = VersionConstant.version + 'webForm/';
  public static websiteWebFormUrl =
    VersionConstant.version + 'website-webForm/';
  public static offerV2Url = VersionConstant.version + 'offer/';
  public static contingencyV2Url = VersionConstant.version + 'contingency/';
  public static appointmentV2Url = VersionConstant.version + 'appointment/';
  public static kpiV2Url = VersionConstant.version + 'kpi/';
  public static vendorManagementV2Url = VersionConstant.version + 'vendor/';
  public static sendGridV2Url = VersionConstant.version + 'send-grid/';
  public static resimpliAiUrl = VersionConstant.version + 'resimpliAi/';
  public static linkTwilio = VersionConstant.version + 'twilio/';
  public static inboxList = VersionConstant.version + 'callLog/';

  //webhook
  public static triggerPoint = VersionConstant.version + 'triggerPoint/';
  public static OpenApiWebhook = VersionConstant.version + 'OpenApiWebhook/';

  //EMAIL ACCOUNT
  public static mailAccount = VersionConstant.version + 'mail-account/';
  public static mailSignature = VersionConstant.version + 'mail-signature/';

  //GLOBAL SEARCH
  public static globalSearch = VersionConstant.version + 'globalSearch';

  //Notification
  public static notificationUrl = VersionConstant.version + 'notifications/';

  //Transcription
  public static transcription = VersionConstant.version + 'transcription/';
}
