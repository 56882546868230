<div
  class="re-module-table"
  [class.re-dashboard-table]="isDashboardStyle"
  [class.position-relative]="upgradeBlurOnGoingCall"
  [class.infinite-scroll]="
    paginationType === miscellaneousConstant.paginationType.INFINITE
  "
  [class.height-290]="isheightIncrease"
  [class.re-call-flow-height]="isheightIncreaseSettings"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  [scrollWindow]="false"
  (scrolled)="onScroll()"
  [ngStyle]="style"
>
  <form
    class="re-inline-form"
    [formGroup]="inlineAddForm"
    (ngSubmit)="onSubmit()"
  >
    <table class="table table-hover re-res-table" [ngClass]="listClass">
      <colgroup *ngIf="moduleName == 'Drip Campaign'">
        <col width="30%" />
        <col width="8%" />
        <col width="8%" />
        <col width="8%" />
        <col width="8%" />
        <col width="8%" />
        <col width="8%" />
        <col width="10%" />
        <col width="12%" />
      </colgroup>
      <colgroup *ngIf="moduleName == 'deals whiteboard'">
        <col width="16%" />
        <col width="8%" />
        <col width="12%" />
        <col width="12%" />
        <col width="16%" />
        <col width="12%" />
        <col width="12%" />
        <col width="12%" />
      </colgroup>

      <colgroup *ngIf="moduleName == 'Email Templates'">
        <col width="26%" />
        <col width="40%" />
        <col width="10%" />
        <col width="12%" />
        <col width="12%" />
      </colgroup>

      <colgroup *ngIf="moduleName == 'eSign Template' && header?.length == 3">
        <col width="75%" />
        <col width="15%" />
        <col width="10%" />
      </colgroup>

      <colgroup *ngIf="moduleName == 'eSign Template' && header?.length == 5">
        <col width="26%" />
        <col width="40%" />
        <col width="10%" />
        <col width="12%" />
        <col width="12%" />
      </colgroup>

      <colgroup *ngIf="moduleName == 'callFlow'">
        <col width="38%" />
        <col />
        <col width="10%" />
      </colgroup>

      <colgroup
        *ngIf="moduleName == 'Tags Listing' || moduleName == 'List Listing'"
      >
        <col width="19%" />
        <col width="8%" />
        <col width="8%" />
        <col width="8%" />
        <col width="8%" />
        <col width="8%" />
        <!-- <col width="8%" /> -->
        <col width="8%" />
        <col width="8%" />
        <col width="8%" />
        <col width="8%" />
        <col width="9%" />
      </colgroup>

      <colgroup *ngIf="moduleName == 'Dialer' && header?.length == 4">
        <col width="30%" />
        <col width="45%" />
        <col width="15%" />
        <col width="10%" />
      </colgroup>

      <colgroup
        *ngIf="moduleName == 'Appointment' && header?.length == 5 && !id"
      >
        <col width="40%" />
        <col width="15%" />
        <col width="15%" />
        <col width="15%" />
        <col width="15%" />
      </colgroup>
      <colgroup *ngIf="moduleName == 'newDashboard'">
        <col width="35%" />
        <col width="20%" />
        <col width="20%" />
        <col width="25%" />
      </colgroup>
      <colgroup *ngIf="moduleName == 'list-stacking'">
        <col width="20%" />
        <col width="20%" />
        <col width="16%" />
        <col width="25%" />
      </colgroup>
      <colgroup *ngIf="moduleName == 'cold-call-dialer'">
        <col width="40%" />
        <col width="18%" />
        <col width="24%" />
        <col width="18%" />
      </colgroup>
      <colgroup *ngIf="moduleName == 'buyer-offers'">
        <col width="25%" />
        <col width="17%" />
        <col width="20%" />
        <col width="10%" />
        <col width="18%" />
        <col width="10%" />
      </colgroup>
      <!-- TABLE HEAD -->
      <thead>
        <!-- TOP HEADER -->
        <tr *ngIf="topHeader?.length" class="re-top-header">
          <th
            *ngFor="let item of topHeader"
            class="text-center"
            [colSpan]="item?.colSpan"
          >
            {{ item?.label }}
          </th>
        </tr>

        <!-- HEADER -->
        <tr *ngIf="header?.length">
          <th *ngFor="let item of header" [style]="item?.headerStyle">
            <span *ngIf="item?.isMultiSelect">
              <label class="re-checkbox-btn">
                <input
                  type="checkbox"
                  [name]="this.moduleName"
                  [value]="item?._id"
                  (click)="selectAll($event)"
                  style="vertical-align: middle"
                  [checked]="isChecked()"
                  [disabled]="selectActionType"
                />
                <span class="re-checkmark"></span>
              </label>
            </span>
            <span
              (click)="item?.sort ? onChangeSort(item) : null"
              *ngIf="!item?.isDashboardLabelHeader"
            >
              {{ item?.label }}
              <span
                [class]="sortVal == item?.key ? sortClass : 're-sort'"
                *ngIf="item?.sort"
              >
              </span>
            </span>
            <span *ngIf="item?.isDashboardLabelHeader">
              <a
                href="javascript:;"
                class="re-header-label"
                (click)="getTaskStatus(item?.taskTypeStatus)"
              >
                {{ item?.label }}
                <img
                  src="/assets/images/external-link.svg"
                  alt="external-link"
                />
              </a>
            </span>
            <span
              [pTooltip]="item?.tooltip"
              [escape]="false"
              tooltipPosition="bottom"
              [class.re-sub-label]="item?.isSubHeaderTooltip"
              *ngIf="item?.isHeaderTooltipExists || item?.isSubHeaderTooltip"
            >
              <i
                class="fa fa-question-circle"
                *ngIf="item?.isHeaderTooltipExists"
              ></i>
              <span *ngIf="item?.isSubHeaderTooltip">
                {{ item?.subLabel }}
              </span>
            </span>
          </th>
        </tr>
      </thead>

      <!-- TABLE BODY -->
      <tbody *ngIf="data">
        <div class="re-blurred-overlay" *ngIf="upgradeBlur">
          <div class="re-blur-content">
            <h3>Want to view more property details?</h3>
            <p>Subscribe to our Enterprise Plan</p>
            <a class="btn re-submitbtn" routerLink="/upgrade-plans">
              Upgrade Account
            </a>
          </div>
        </div>
        <div class="re-blurred-overlay-ongoing" *ngIf="upgradeBlurOnGoingCall">
          <div class="re-blur-content">
            <h3 class="re-head-tit">
              Train your team and close more deals using REsimpli’s advanced
              calling features
            </h3>
            <div>
              <div class="re-advance-fea">
                <img src="assets/images/adv-listing.svg" alt="listing" />
                <h5>Listen In</h5>
                <p>
                  Monitor your team’s performance by listening in to live calls.
                </p>
              </div>
              <div class="re-advance-fea">
                <img src="assets/images/adv-speak.svg" alt="speak" />
                <h5>Speak with Team</h5>
                <p>
                  Actively coach your team member without sellers listening.
                </p>
              </div>
              <div class="re-advance-fea">
                <img src="assets/images/adv-call.svg" alt="call" />
                <h5>Call Barging</h5>
                <p>
                  Take over a call and all parties will be able to hear you.
                  Great Feature to help your new sales agent close a deal.
                </p>
              </div>
            </div>
            <a class="btn" routerLink="/upgrade-plans">
              Upgrade to Enterprise
            </a>
          </div>
        </div>
        <!-- TRANSACTION -->
        <tr *ngIf="isFilterTransaction" style="cursor: default">
          <td colspan="5">
            <span class="re-colspan-head">
              {{ filterTransactionType }} Total
            </span>
          </td>

          <td colspan="2">
            <span
              [ngClass]="{
                're-colspan-head-green-amount': filterTransactionAmount >= 0,
                're-colspan-head-red-amount': filterTransactionAmount < 0
              }"
            >
              {{
                filterTransactionAmount
                  | currency: "USD":true
                  | currencyPositive
              }}
            </span>
          </td>
        </tr>

        <tr *ngIf="isOpeningBalance" style="cursor: default">
          <td>
            {{ openingBalanceDate | date: "MMM dd, yyyy" }}
          </td>

          <td colspan="4">
            <span class="re-colspan-head"> Opening Balance</span>
          </td>

          <td colspan="2">
            <span
              [ngClass]="{
                're-colspan-head-green-amount': openingBalance >= 0,
                're-colspan-head-red-amount': openingBalance < 0
              }"
            >
              {{ openingBalance | currency: "USD":true | currencyPositive }}
            </span>
          </td>
        </tr>

        <!-- ROWS -->
        <ng-container *ngIf="checkLoader()">
          <ng-container
            *ngFor="let item of data['items']; let itemIndex = index"
          >
            <tr
              [class]="item?.isRowExpand ? 're-sub-row' : ''"
              [ngStyle]="isCustomRowStyleEnabled ? customRowStyle(item) : ''"
              (contextmenu)="
                moduleName == 'Buyer/Agents' ? onRightClick($event, item) : ''
              "
            >
              <ng-container *ngFor="let head of header; let headIndex = index">
                <td
                  [style]="head?.isCustomStyle ? head?.customStyle : ''"
                  class="re-table-td"
                  [class.re-pointer]="!head?.isClickExists"
                  *ngIf="head?.key !== 'action'"
                  [ngStyle]="head?.style"
                  [class.re-disabled]="head?.isEnabled && !item['isEnabled']"
                  [class.re-buyerHover]="
                    (head?.label == 'Contact' && head?.isHoverExists) ||
                    (head?.label == 'Phone' && head?.isInterestedHoverExists) ||
                    (head?.label == 'Email' && head?.isInterestedHoverExists)
                  "
                >
                  <ng-container *ngIf="head?.manageLeadRecord">
                    <button
                      class="re-edit-btn"
                      *ngIf="item?.isInstantLeadConnect"
                      (click)="getUserPlan(item, true, itemIndex)"
                    >
                      Re-Configure
                    </button>
                    <button
                      class="re-edit-btn"
                      *ngIf="!item?.isInstantLeadConnect"
                      (click)="getUserPlan(item, false, itemIndex)"
                    >
                      Configure
                    </button>
                  </ng-container>
                  <!-- MULTI SELECT -->
                  <span [class.pull-left]="moduleName == 'files'">
                    <ng-container
                      *ngIf="isMultiSelectEnabled && headIndex === 0"
                    >
                      <label
                        class="re-checkbox-btn"
                        *ngIf="moduleName != 'Local Completed Tasks'"
                      >
                        <input
                          type="checkbox"
                          [name]="this.moduleName"
                          [value]="item?._id"
                          (click)="selectItem(item, $event)"
                          style="vertical-align: middle"
                          [checked]="item?.isCompleted"
                          [disabled]="selectActionType"
                        />
                        <span class="re-checkmark"></span>
                      </label>

                      <label
                        class="re-checkbox-btn"
                        *ngIf="moduleName == 'Local Completed Tasks'"
                      >
                        <input
                          type="checkbox"
                          [name]="this.moduleName"
                          [value]="item?._id"
                          (click)="selectItem(item, $event)"
                          style="vertical-align: middle"
                          [checked]="item?.isCompleted"
                        />
                        <span class="re-checkmark black"></span>
                      </label>
                    </ng-container>
                    <!--                    <ng-container *ngIf="head?.manageInstantLead">-->
                    <!--                      <div class="re-notification-box">-->
                    <!--                        <div class="radiosec">-->
                    <!--                          <label class="switch">-->
                    <!--                            <input-->
                    <!--                              type="checkbox"-->
                    <!--                              id="checkbox1"-->
                    <!--                              name="activeTimeChange"-->
                    <!--                              [checked]="item?.isInstantLeadConnect"-->
                    <!--                              (change)="-->
                    <!--                                getUserPlan(-->
                    <!--                                  item?._id,-->
                    <!--                                  item?.isInstantLeadConnect,-->
                    <!--                                  itemIndex-->
                    <!--                                )-->
                    <!--                              "-->
                    <!--                            />-->
                    <!--                            <span class="slider"></span>-->
                    <!--                          </label>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </ng-container>-->
                    <ng-container *ngIf="head?.manageCallRecord">
                      <div class="re-notification-box">
                        <div class="radiosec">
                          <label class="switch">
                            <input
                              *ngIf="head?.key === 'isOutboundCallRecord'"
                              type="checkbox"
                              id="checkbox1"
                              name="activeTimeChange"
                              [checked]="item?.isOutboundCallRecord"
                              (change)="
                                checkCallRecord(
                                  item?._id,
                                  item?.isOutboundCallRecord,
                                  itemIndex
                                )
                              "
                            />
                            <span class="slider"></span>
                          </label>
                        </div>
                      </div>
                    </ng-container>
                  </span>
                  <!-- DATA -->
                  <span
                    *ngIf="!head?.isOngoingCall"
                    [class.re-files-name]="moduleName == 'files'"
                    [class.re-inbox-message]="head?.inboxStyle == 'inboxFile'"
                    (click)="
                      head?.isClickExists
                        ? callAction(head, item, itemIndex, moduleId)
                        : ''
                    "
                  >
                    <!-- ICON -->
                    <img
                      *ngIf="head?.isIconExists"
                      class="re-icon"
                      src="../../../assets/images/{{ getIcon(item, head) }}"
                      [ngStyle]="head?.iconStyle?.style"
                      [pTooltip]="
                         head?.isIconToolTipExist && getIconTooltipValue(item, head)
                      "
                      tooltipPosition="bottom"
                      onerror="this.src='/assets/images/default.png'"
                    />

                    <!-- META DATA IMAGE -->
                    <span
                      *ngIf="getValue(item, head?.metaData?.image) != 'N/A'"
                    >
                      <img
                        *ngIf="head?.metaData?.image"
                        class="re-icon"
                        [ngStyle]="head?.metaData?.image?.style"
                        [src]="getValue(item, head?.metaData?.image)"
                        onerror="this.src = '/assets/images/default.png'"
                      />
                    </span>

                    <!-- INITIALS -->
                    <span
                      *ngIf="getValue(item, head?.metaData?.image) == 'N/A'"
                      class="re-initials"
                    >
                      {{ getInitials(getValue(item, head)) }}
                    </span>

                    <!-- VALUE -->

                    <ng-container *ngIf="head?.key === 'numberData'">
                      <div class="re-tooltip">
                        <span
                          class="re-file-title"
                          [pTooltip]="getValueTooltip(item, head)"
                          tooltipPosition="bottom"
                        >
                          {{ getValue(item, head) }}
                        </span>
                      </div>
                    </ng-container>

                    <ng-container
                      *ngIf="
                        !head?.pipe && !head?.file && head?.key !== 'numberData'
                      "
                    >
                      <ng-container *ngIf="head?.isNewDashboardStatusExists">
                        {{ getStatusLabel(getValue(item, head)) | titlecase }}
                        <span class="re-countss">
                          {{ getSubValue(item, head) }}
                        </span>
                      </ng-container>
                      <ng-container *ngIf="head?.isActivityIconExists">
                        <a class="re-pinboxes" (click)="pinnedActivity(item)"
                          ><img src="/assets/images/pin-gray.svg" alt="pin"
                        /></a>
                        <a class="re-pinboxes" (click)="leaveNote(item)"
                          ><img src="/assets/images/notes-type.svg" alt="notes"
                        /></a>
                      </ng-container>
                      <ng-container *ngIf="head?.isTeamAssign">
                        <div class="re-teamlists">
                          <a
                            class="re-teamboxes"
                            *ngFor="
                              let assignUsers of item?.assignUserData
                                | slice: 0:3
                            "
                            pTooltip="<b>{{ assignUsers?.fullName }}</b>"
                            tooltipPosition="bottom"
                            [escape]="false"
                            ><img
                              *ngIf="assignUsers.img"
                              [src]="assignUsers.img"
                              alt="admin"
                            /><span *ngIf="!assignUsers.img">{{
                              assignUsers.name
                            }}</span></a
                          >
                          <a
                            class="re-moreteam"
                            (click)="openUserListDailog(item)"
                            *ngIf="item?.assignUserData?.length > 3"
                            >+{{ item?.assignUserData?.length - 3 }} More</a
                          >
                        </div>
                      </ng-container>
                      <div
                        class="re-tooltip"
                        *ngIf="
                          !head?.isActivityIconExists &&
                          !head?.isTeamAssign &&
                          !head?.isNewDashboardStatusExists
                        "
                      >
                        <span
                          class="re-file-title"
                          [ngClass]="
                            getValue(item, head) != 'General Business'
                              ? head.class
                              : ''
                          "
                        >
                          <span
                            [ngClass]="head?.customClass"
                            [attr.data_id]="itemIndex"
                            [pTooltip]="
                              head?.isTooltipExists &&
                              getValue(item, head, head?.isTooltipExists)
                            "
                            tooltipPosition="bottom"
                            [tooltipStyleClass]="
                              moduleName === 'Email Templates' &&
                              head?.key === 'subject'
                                ? 're-email-template'
                                : ''
                            "
                            [class.re-display]="
                              (head?.label == 'Contact' &&
                                head?.isHoverExists) ||
                              (head?.label == 'Email' &&
                                head?.isInterestedHoverExists)
                            "
                          >
                            <ng-container
                              *ngIf="
                                head?.key && moduleName == 'Email Templates'
                              "
                            >
                              {{
                                getValue(item, head)
                                  ? getValue(item, head).slice(
                                      0,
                                      getValue(item, head)?.length > 55
                                        ? 55
                                        : getValue(item, head)?.length
                                    )
                                  : "0"
                              }}{{
                                getValue(item, head)
                                  ? getValue(item, head)?.length > 55
                                    ? "....."
                                    : ""
                                  : ""
                              }}
                            </ng-container>

                            <ng-container
                              *ngIf="
                                head?.key &&
                                  !head?.manageCallRecord &&
                                  !head?.manageLeadRecord &&
                                  moduleName != 'Email Templates';
                                else else_event
                              "
                            >
                              <ng-container
                                *ngIf="!head?.separator || head?.isValueVisible"
                              >
                                <ng-container
                                  *ngIf="
                                    checkLoading(head, item);
                                    else loadingElse
                                  "
                                >
                                  <mat-progress-bar
                                    class="re-progress"
                                    mode="indeterminate"
                                  ></mat-progress-bar>
                                </ng-container>

                                <ng-template #loadingElse>
                                  <ng-container
                                    *ngIf="
                                      head?.key == 'spamCheck' &&
                                        moduleName == 'Manage Numbers';
                                      else loadingElseEvent
                                    "
                                  >
                                    <span
                                      class="re-green-label"
                                      *ngIf="
                                        item?.carrierAttFlagged == false &&
                                        item?.carrierSprintFlagged == false &&
                                        item?.carrierTmobileFlagged == false &&
                                        item?.carrierVerizonFlagged == false
                                      "
                                      >Good</span
                                    >
                                    <span
                                      *ngIf="
                                        checkSpam(item) != 0 &&
                                        (item?.carrierAttFlagged ||
                                          item?.carrierSprintFlagged ||
                                          item?.carrierTmobileFlagged ||
                                          item?.carrierVerizonFlagged)
                                      "
                                      class="re-red-label"
                                      [pTooltip]="checkSpamMsg(item)"
                                      tooltipPosition="bottom"
                                      [escape]="false"
                                      >Spam {{ checkSpam(item) }}
                                    </span>
                                    <span
                                      *ngIf="
                                        checkSpam(item) == 0 &&
                                        (item?.carrierAttFlagged != false ||
                                          item?.carrierSprintFlagged != false ||
                                          item?.carrierTmobileFlagged !=
                                            false ||
                                          item?.carrierVerizonFlagged != false)
                                      "
                                      class="re-red-label"
                                      pTooltip="This number hasn’t been checked for Spam yet. Please check back in few days."
                                      tooltipPosition="bottom"
                                      >N/A
                                    </span>
                                  </ng-container>
                                  <ng-template #loadingElseEvent>
                                    <ng-container
                                      *ngIf="
                                        head?.key == 'replayStatus' &&
                                          head?.statusKey == 'spamStatus';
                                        else loadingScheduledStatusEvent
                                      "
                                    >
                                      <span
                                        [ngClass]="
                                          getValue(item, head) == 'Yes'
                                            ? 're-green-label'
                                            : 're-red-label'
                                        "
                                        >{{
                                          getValue(item, head) !== "" &&
                                          getValue(item, head) !== null &&
                                          getValue(item, head) !== " " &&
                                          getValue(item, head) !== "null" &&
                                          getValue(item, head) !== "N/A"
                                            ? getValue(item, head)
                                            : "No"
                                        }}</span
                                      >
                                    </ng-container>
                                  </ng-template>
                                  <ng-template #loadingScheduledStatusEvent>
                                    <ng-container
                                      *ngIf="
                                        head?.key == 'status' &&
                                          head?.scheduledKey ==
                                            'scheduledStatus';
                                        else loadingStatusEvent
                                      "
                                    >
                                      <span>{{
                                        getValue(item, head) !== "" &&
                                        getValue(item, head) !== null &&
                                        getValue(item, head) !== " " &&
                                        getValue(item, head) !== "null" &&
                                        getValue(item, head) !== "N/A"
                                          ? getValue(item, head)
                                          : "No"
                                      }}</span>
                                    </ng-container>
                                  </ng-template>
                                  <ng-template #loadingStatusEvent>
                                    {{
                                      getValue(item, head) !== "" &&
                                      getValue(item, head) !== null &&
                                      getValue(item, head) !== " " &&
                                      getValue(item, head) !== "null"
                                        ? getValue(item, head)
                                        : "N/A"
                                    }}
                                  </ng-template>
                                </ng-template>
                              </ng-container>
                            </ng-container>

                            <ng-template #else_event>
                              <ng-container
                                *ngIf="head?.event === 'MEMBERS_LIST'"
                              >
                                View All
                              </ng-container>
                            </ng-template>

                            <span
                              class="re-call-btn"
                              *ngIf="getValue(item, head) !== 'N/A'"
                            >
                              <a
                                href="javascript:;"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                class="fa"
                                title="Email"
                                (click)="buyerEmail(item, head)"
                                ><img src="/assets/images/at.svg" alt="Email" />
                              </a>
                            </span>
                          </span>
                          <p
                            [class.re-display]="
                              head?.label == 'Contact' && head?.isHoverExists
                            "
                          >
                            <!-- INNER VALUE -->
                            <ng-container
                              *ngFor="
                                let value of head?.innerValue;
                                let innerIndex = index
                              "
                            >
                              <ng-container
                                *ngIf="
                                  innerIndex > 0 &&
                                  (head?.separatorBreak || head?.separator)
                                "
                              >
                                <span
                                  *ngIf="
                                    moduleName !== 'Call Logs' ||
                                    (moduleName == 'Call Logs' &&
                                      item?.type == 'outgoingCall' &&
                                      (item?.status == 'no-answer' ||
                                        item?.status == 'cancel')) ||
                                    head?.isSeparatorEnabled
                                  "
                                >
                                  <br *ngIf="head?.separatorBreak" />

                                  <ng-container *ngIf="head?.separator">
                                    {{ head?.separator }}
                                  </ng-container>
                                </span>
                              </ng-container>

                              <span
                                *ngIf="value"
                                [ngStyle]="value?.style"
                                (click)="
                                  value?.isClickExists
                                    ? callAction(value, item, value)
                                    : ''
                                "
                              >
                                <ng-container [ngSwitch]="value?.pipe">
                                  <!-- DATE -->
                                  <ng-container *ngSwitchCase="'DATE'">
                                    {{
                                      getValue(item, value)
                                        | date: "MMM dd, yyyy"
                                    }}
                                  </ng-container>

                                  <!-- DATE TIME -->
                                  <ng-container *ngSwitchCase="'DATE_TIME'">
                                    <ng-container
                                      *ngIf="
                                        getValue(item, value) &&
                                          getValue(item, value) !== 'N/A';
                                        else else_date
                                      "
                                    >
                                      {{
                                        getValue(item, value)
                                          | date: "MMM dd, yyyy hh:mm a"
                                      }}
                                    </ng-container>

                                    <ng-template #else_date> - </ng-template>
                                  </ng-container>

                                  <!-- TIME -->
                                  <ng-container *ngSwitchCase="'TIME'">
                                    {{
                                      getValue(item, value) | date: "shortTime"
                                    }}
                                  </ng-container>

                                  <!-- PHONE -->
                                  <ng-container *ngSwitchCase="'PHONE'">
                                    {{
                                      utilities.maskNumber(
                                        getValue(item, value)
                                      ) || "N/A"
                                    }}
                                  </ng-container>

                                  <!-- CAMEL CASE -->
                                  <ng-container *ngSwitchCase="'CAMEL_CASE'">
                                    {{
                                      moduleName != "Call Logs"
                                        ? utilities.camelCaseToWord(
                                            getValue(item, value),
                                            value?.style
                                          )
                                        : item?.type === "incomingCall" &&
                                          head?.key == "source" &&
                                          (item?.status == "no-answer" ||
                                            item?.status == "cancel" ||
                                            item?.status == "no-confirm" ||
                                            item?.status == "busy")
                                        ? "Missed Call"
                                        : item?.type == "incomingCall" &&
                                          head?.key == "source" &&
                                          item?.communicationType == 3
                                        ? "Voice Mail"
                                        : item?.type == "incomingCall" &&
                                          item?.communicationType == 4
                                        ? "Incoming RVM"
                                        : utilities.camelCaseToWord(
                                            getValue(item, value),
                                            value?.style
                                          )
                                    }}
                                    <img
                                      *ngIf="
                                        head?.isStatusIconExists &&
                                        moduleName == 'Call Logs' &&
                                        (item?.type == 'outgoingSms' ||
                                          item?.type == 'outgoingMms')
                                      "
                                      [pTooltip]="smsTooltipMsg(item)"
                                      tooltipPosition="bottom"
                                      [escape]="false"
                                      class="re-sms-status"
                                      [src]="
                                        'assets/images/' + getStatusIcon(item)
                                      "
                                      alt=""
                                    />
                                    <img
                                      *ngIf="
                                        head?.isStatusIconExists &&
                                        moduleName == 'Call Logs' &&
                                        item?.type == 'incomingCall' &&
                                        item?.isNewCall == true
                                      "
                                      pTooltip="New Caller"
                                      tooltipPosition="bottom"
                                      class="re-sms-status"
                                      src="assets/images/new.svg"
                                      alt=""
                                    />
                                    <img
                                      *ngIf="
                                        head?.isStatusIconExists &&
                                        moduleName == 'Call Logs' &&
                                        item?.type == 'incomingCall' &&
                                        item?.isNewCall == false
                                      "
                                      pTooltip="Repeat Caller"
                                      tooltipPosition="bottom"
                                      class="re-sms-status"
                                      src="assets/images/Repeat-Caller.png"
                                      alt=""
                                    />
                                  </ng-container>

                                  <!-- TITLE CASE -->
                                  <ng-container *ngSwitchCase="'TITLE_CASE'">
                                    {{
                                      utilities.titleCaseToWord(
                                        getValue(item, value)
                                      )
                                    }}
                                  </ng-container>

                                  <!-- NUMBER TYPE -->
                                  <ng-container *ngSwitchCase="'NUMBER_TYPE'">
                                    {{
                                      numberType(item, getValue(item, value))
                                    }}
                                  </ng-container>

                                  <ng-container
                                    *ngSwitchCase="'CALL_NUMBER_TYPE'"
                                  >
                                    {{
                                      callNumberType(
                                        item,
                                        getValue(item, value)
                                      )
                                    }}
                                  </ng-container>

                                  <!-- CUSTOM FUNCTION -->
                                  <ng-container
                                    *ngSwitchCase="'CUSTOM_FUNCTION'"
                                  >
                                    <div
                                      class="{{
                                        value.isCustomClass
                                          ? customFunction(item, value, 'class')
                                          : ''
                                      }}"
                                      [innerHTML]="
                                        customFunction(item, value, 'value')
                                      "
                                    ></div>
                                  </ng-container>

                                  <!-- DEFAULT -->
                                  <ng-container *ngSwitchDefault>
                                    {{
                                      moduleName != "Call Logs"
                                        ? getValue(item, value)
                                        : (item?.status == "no-answer" ||
                                            item?.status == "cancel") &&
                                          item?.type == "outgoingCall"
                                        ? "Not Answered"
                                        : ""
                                    }}
                                  </ng-container>
                                </ng-container>
                              </span>

                              <span
                                *ngIf="innerIndex > 0 && head?.separatorEnd"
                              >
                                {{ head?.separatorEnd }}
                              </span>
                            </ng-container>

                            <!-- INNER ICON -->
                            <ng-container
                              *ngFor="
                                let icon of head?.innerIcon;
                                let innerIndex = index
                              "
                            >
                              <img
                                style="
                                  padding: 2px 6px 0px 0px;
                                  position: absolute;
                                  margin-left: 0px;
                                "
                                [ngStyle]="icon?.style"
                                *ngIf="
                                  item.reminders.length > 0 &&
                                  icon.type == 'reminder'
                                "
                                [src]="'assets/images/' + getIcon(icon, head)"
                                alt=""
                              />

                              <img
                                style="
                                  padding: 2px 6px 0px 0px;
                                  position: absolute;
                                  margin-left: 20px;
                                "
                                [ngStyle]="icon?.style"
                                [pTooltip]="
                                  icon?.isTooltipExists && getValue(item, icon)
                                "
                                tooltipPosition="bottom"
                                *ngIf="
                                  item.taskDetail && icon.type == 'description'
                                "
                                [src]="'assets/images/' + getIcon(icon, head)"
                                alt=""
                              />
                            </ng-container>

                            <!-- INNER DATA -->
                            <ng-container
                              *ngFor="
                                let data of head?.innerData;
                                let innerIndex = index
                              "
                            >
                              <ng-container [ngSwitch]="data.pipe">
                                <ng-container *ngSwitchCase="'CUSTOM_FUNCTION'">
                                  <div
                                    class="{{
                                      data.isCustomClass
                                        ? customFunction(item, data, 'class')
                                        : ''
                                    }}"
                                    [innerHTML]="
                                      customFunction(item, data, 'value')
                                    "
                                  ></div>
                                </ng-container>

                                <ng-container *ngSwitchDefault>
                                  <span
                                    *ngIf="!data.static"
                                    [ngStyle]="data?.style"
                                    [pTooltip]="
                                      data?.isTooltipExists &&
                                      getValue(item, data)
                                    "
                                  >
                                    {{
                                      getValue(item, data) == "general"
                                        ? ""
                                        : utilities.titleCaseToWord(
                                            getValue(item, data)
                                          )
                                    }}
                                  </span>

                                  <span
                                    class="re-sub-category"
                                    *ngIf="
                                      data.static &&
                                      data.static != 'Lead' &&
                                      getValue(item, data) != 'General Business'
                                    "
                                  >
                                    • {{ data.static }}:
                                    {{ getValue(item, data) }}
                                  </span>

                                  <span
                                    class="re-sub-category"
                                    *ngIf="data.static && data.static == 'Lead'"
                                  >
                                    {{ getValue(item, data) }}
                                    {{
                                      getValue(item, data) != "N/A"
                                        ? data.static
                                        : ""
                                    }}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </ng-container>

                            <span
                              style="
                                height: 10px;
                                width: 10px;
                                background-color: #008080;
                                border-radius: 50%;
                                display: inline-block;
                                vertical-align: middle;
                              "
                              *ngIf="head?.checkKey && checkKey(head, item)"
                            >
                              &nbsp;
                            </span>
                            <ng-container
                              *ngFor="
                                let value of head?.innerValue;
                                let innerIndex = index
                              "
                            >
                              <span
                                class="re-call-btn"
                                *ngIf="getValue(item, value) !== 'N/A'"
                              >
                                <a
                                  href="javascript:;"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  class="fa fa1"
                                  title="Call"
                                  (click)="callBuyer(item, value, 'call')"
                                  ><img
                                    src="/assets/images/call.svg"
                                    alt="call"
                                  />
                                </a>
                              </span>
                              <span
                                class="re-call-btn"
                                *ngIf="getValue(item, value) !== 'N/A'"
                              >
                                <a
                                  href="javascript:;"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  class="fa fa1"
                                  title="SMS"
                                  (click)="callBuyer(item, value, 'SMS')"
                                  ><img
                                    src="/assets/images/sms_grey.svg"
                                    alt="SMS"
                                  />
                                </a>
                              </span>
                            </ng-container>
                          </p>
                        </span>
                        <span>
                          <!-- NEW CALL LABEL INNER VALUE -->
                          <ng-container
                            *ngFor="
                              let value of head?.innerNewLabel;
                              let innerIndex = index
                            "
                          >
                            <span
                              *ngIf="value"
                              (click)="
                                value?.isClickExists
                                  ? callAction(value, item, value)
                                  : ''
                              "
                            >
                              <ng-container
                                *ngIf="
                                  value?.isNewKey == 'isNewCall' &&
                                  value?.isNewCallExists &&
                                  item?.isNewLs
                                "
                              >
                                <span class="re-red-call-label">New</span>
                              </ng-container>
                            </span>
                          </ng-container>
                        </span>
                      </div>
                    </ng-container>

                    <!-- VALUE WITH PIPES -->
                    <ng-container *ngIf="head?.pipe">
                      <ng-container [ngSwitch]="head?.pipe">
                        <!--ARRAY-->
                        <ng-container *ngSwitchCase="'ARRAY'">
                          <ul>
                            <li
                              *ngFor="
                                let val of getArray(item, head);
                                let i = index
                              "
                            >
                              <span
                                (click)="
                                  head?.isClickExists
                                    ? callAction(
                                        head,
                                        item,
                                        itemIndex,
                                        '',
                                        i + 1
                                      )
                                    : ''
                                "
                                >{{ val }}</span
                              >
                            </li>
                          </ul>
                        </ng-container>

                        <!--ARRAY CURRENCY-->
                        <ng-container *ngSwitchCase="'ARRAYCURRENCY'">
                          <ul
                            [ngClass]="{
                              're-green-amount':
                                getArrayCurrency(item, head).length >= 0,
                              're-red-amount':
                                getArrayCurrency(item, head).length < 0
                            }"
                          >
                            <li
                              *ngFor="
                                let val of getArrayCurrency(item, head);
                                let i = index
                              "
                            >
                              {{ val | currency | currencyPositive }}
                            </li>
                          </ul>
                        </ng-container>

                        <!-- CURRENCY -->
                        <ng-container *ngSwitchCase="'CURRENCY'">
                          <span
                            [ngClass]="{
                              're-green-amount':
                                !head?.hideClass &&
                                getCurrency(item, head) >= 0,
                              're-red-amount':
                                !head?.hideClass && getCurrency(item, head) < 0
                            }"
                          >
                            {{
                              item?.isJournal && getCurrency(item, head) == 0
                                ? ""
                                : moduleName == "deals whiteboard"
                                ? (getCurrency(item, head)
                                  | currency
                                  | currencyPositive)
                                : item[head.key]
                                ? (getCurrency(item, head) | currency)
                                : "N/A"
                            }}
                          </span>
                        </ng-container>

                        <!-- NUMBER -->
                        <ng-container *ngSwitchCase="'NUMBER'">
                          {{ getCurrency(item, head) | number: "1.2-2" }}
                        </ng-container>

                        <ng-container *ngSwitchCase="'NUMBER_VALUE'">
                          {{ getCurrency(item, head) | number: "1.0-0" }}
                        </ng-container>

                        <!-- DATE -->
                        <ng-container *ngSwitchCase="'DATE'">
                          {{
                            getValue(item, head) == "N/A"
                              ? item?.isJournal
                                ? ""
                                : "N/A"
                              : (getValue(item, head) | date: "MMM dd, yyyy")
                          }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'TRANSACTION_DATE'">
                          {{
                            getTransactionDateValue(item, head)
                          }}
                        </ng-container>
                        <!-- DATE TIME -->
                        <ng-container *ngSwitchCase="'DATE_TIME'">
                          <ng-container
                            *ngIf="
                              getValue(item, head) &&
                                getValue(item, head) !== 'N/A';
                              else else_date_pipe
                            "
                          >
                            {{
                              getValue(item, head)
                                | date: "MMM dd, yyyy hh:mm a"
                            }}
                          </ng-container>
                          <ng-template #else_date_pipe> - </ng-template>
                        </ng-container>

                        <!-- DATE TIME TIMEZONE -->
                        <ng-container *ngSwitchCase="'DATE_TIME_TIMEZONE'">
                          <ng-container
                            *ngIf="
                              getValue(item, head) &&
                                getValue(item, head) !== 'N/A';
                              else else_date_pipe_time
                            "
                          >
                            {{ getValue(item, head) | myTimezone }}
                          </ng-container>
                          <ng-template #else_date_pipe_time> - </ng-template>
                        </ng-container>

                        <!-- DATE TIMEZONE -->
                        <ng-container *ngSwitchCase="'DATE_TIMEZONE'">
                          <ng-container
                            *ngIf="
                              getValue(item, head) &&
                                getValue(item, head) !== 'N/A';
                              else else_date_pipe_timezone
                            "
                          >
                            {{
                              getValue(item, head) | myTimezone: "MMM DD, yyyy"
                            }}
                          </ng-container>
                          <ng-template #else_date_pipe_timezone>
                            -
                          </ng-template>
                        </ng-container>

                        <!-- TIME -->
                        <ng-container *ngSwitchCase="'TIME'">
                          {{ getValue(item, head) | date: "shortTime" }}
                        </ng-container>

                        <!-- PHONE -->
                        <ng-container *ngSwitchCase="'PHONE'">
                          <span class="re-display">
                            {{
                              utilities.maskNumber(getValue(item, head)) ||
                                "N/A"
                            }}
                            <span
                              class="re-call-btn"
                              *ngIf="getValue(item, head) !== 'N/A'"
                            >
                              <a
                                href="javascript:;"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                class="fa fa2"
                                title="Call"
                                (click)="callBuyer(item, head, 'call')"
                                ><img
                                  src="/assets/images/call.svg"
                                  alt="call"
                                />
                              </a>
                            </span>
                            <span
                              class="re-call-btn"
                              *ngIf="getValue(item, head) !== 'N/A'"
                            >
                              <a
                                href="javascript:;"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                class="fa fa2"
                                title="SMS"
                                (click)="callBuyer(item, head, 'SMS')"
                                ><img
                                  src="/assets/images/sms_grey.svg"
                                  alt="SMS"
                                />
                              </a>
                            </span>
                          </span>
                        </ng-container>

                        <!-- CAMEL CASE -->
                        <ng-container *ngSwitchCase="'CAMEL_CASE'">
                          {{ utilities.camelCaseToWord(getValue(item, head)) }}
                        </ng-container>

                        <!-- TITLE CASE -->
                        <ng-container *ngSwitchCase="'TITLE_CASE'">
                          {{ utilities.titleCaseToWord(getValue(item, head)) }}
                        </ng-container>

                        <!-- DUE DATE -->
                        <ng-container *ngSwitchCase="'DUE_DATE'">
                          <span
                            [style.color]="
                              head?.colorCode ? getColorCode(item, head) : ''
                            "
                          >
                            {{
                              getValue(item, head) !== "N/A"
                                ? utilities.dueDateFormat(
                                    getValue(item, head),
                                    item?.tasktimezone
                                  ).timezoneDate
                                : "N/A"
                            }}

                            <br />

                            <span
                              [style.color]="head.colorCode ? '' : '#9b9ea0'"
                              *ngIf="getValue(item, head) !== 'N/A'"
                            >
                              {{
                                getValue(item, head) !== "N/A"
                                  ? utilities.dueDateFormat(
                                      getValue(item, head),
                                      item?.tasktimezone
                                    ).timezoneTime
                                  : "N/A"
                              }}
                            </span>
                          </span>
                        </ng-container>

                        <!-- SCHEDULED SMS TITLE WITH TIME -->
                        <ng-container
                          *ngSwitchCase="'SCHEDULED_SMS_TITLE_WITH_TIME'"
                        >
                          <span style="color: #008080">
                            {{ getValue(item, head) || "N/A" }}
                          </span>
                          <br />
                          {{ utilities.maskNumber(item.sendTo) }}
                        </ng-container>

                        <!-- DATE WITH TIMEZONE -->
                        <ng-container *ngSwitchCase="'DUE_DATE_TIMEZONE'">
                          {{
                            utilities.dueDateFormat(
                              getValue(item, head),
                              item[head.timezone]
                            ).timezoneDate
                          }}
                          <br />
                          {{
                            utilities.dueDateFormat(
                              getValue(item, head),
                              item[head.timezone]
                            ).timezoneTime
                          }}

                          {{
                            utilities.dueDateFormat(
                              getValue(item, head),
                              item[head.timezone]
                            ).timezoneAbbr
                          }}
                        </ng-container>

                        <!-- ACCOUNT FIX -->
                        <ng-container *ngSwitchCase="'ACCOUNT_FIX'">
                          <p
                            style="color: #008080; cursor: pointer"
                            (click)="callAction(head, item, itemIndex, '')"
                          >
                            {{ getValue(item, head) }}
                          </p>
                          <p
                            class="re-fix-account"
                            (click)="fixAccount(item)"
                            *ngIf="item?.isFixAccount"
                          >
                            Fix Account
                          </p>
                          <span
                            style="color: rgb(155, 158, 160)"
                            class="text-capitalize"
                            >{{ item?.type }} > {{ item?.number }}</span
                          >
                        </ng-container>

                        <!-- TIME AGO -->
                        <ng-container *ngSwitchCase="'TIME_AGO'">
                          {{ timeAgo(getValue(item, head)) }}
                        </ng-container>

                        <!-- TIME AGO BANK-->
                        <ng-container *ngSwitchCase="'TIME_AGO_BANK'">
                          {{
                            item?.isManual
                              ? "N/A"
                              : timeAgo(getValue(item, head))
                          }}
                        </ng-container>

                        <!-- BEGGINING BALANCE -->
                        <ng-container *ngSwitchCase="'BEGGINING_BALANCE'">
                          <p *ngIf="item?.isManual == false">N/A</p>
                          <span *ngIf="item?.openingBalance && item?.isManual">
                            {{ getCurrency(item, head) | currency }}
                            <ng-container
                              *ngIf="item?.dateOfBalance && item?.isManual"
                            >
                              -
                              {{ item?.dateOfBalance | date: "MMM dd, yyyy" }}
                            </ng-container>
                          </span>
                        </ng-container>

                        <!-- RECOGNIZED_TRANSACTION -->
                        <ng-container *ngSwitchCase="'RECOGNIZED_TRANSACTION'">
                          {{
                            getValue(item, head) == "N/A"
                              ? item?.isJournal
                                ? ""
                                : "N/A"
                              : getTransactionDateValue(item, head)
                          }}
                          <div
                            *ngIf="item?.isFromJournalEntry"
                            class="re-from-journal"
                            (click)="openJournaEntry(item)"
                          >
                            Journal No: {{ item?.journalData?.journalNo }}
                          </div>
                          <p>
                            <span
                              *ngIf="item?.isMatchTransaction"
                              class="re-custom re-lead"
                              (click)="openMatchTransaction(item)"
                              >Match</span
                            >
                          </p>
                        </ng-container>

                        <!-- UNRECOGNIZED_TRANSACTION -->
                        <ng-container
                          *ngSwitchCase="'UNRECOGNIZED_TRANSACTION'"
                        >
                          {{
                            getValue(item, head) == "N/A"
                              ? "N/A"
                              : getTransactionDateValue(item, head)
                          }}
                          <p>
                            <span
                              class="re-custom re-lead"
                              *ngIf="item?.isMatchTransaction"
                              (click)="openMatchTransaction(item)"
                              >Match</span
                            >
                          </p>
                        </ng-container>

                        <!-- FORMATTED ADDRESS-->
                        <ng-container *ngSwitchCase="'ADDRESS_FORMAT'">
                          {{
                            utilities.formatAddress(
                              getValue(item, head),
                              item?.unitNo
                            )
                          }}
                        </ng-container>

                        <!-- HTML -->
                        <ng-container *ngSwitchCase="'PRE-LINE'">
                          <span
                            class="re-pre-line"
                            [innerHTML]="getValue(item, head)"
                          ></span>
                        </ng-container>

                        <ng-container *ngSwitchCase="'CUSTOM_FUNCTION'">
                          <div
                            pTooltip="{{
                              item?.plivoSubType === 'tollfree'
                                ? '10DLC registration is not required for any Toll Free numbers.'
                                : ''
                            }}"
                            tooltipPosition="bottom"
                            [innerHTML]="
                              customFunction(item, head, secondaryData)
                            "
                          ></div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'CONDITIONAL_ACTION'">
                          <ng-container
                            *ngIf="
                              showConditionAction(item);
                              else else_condition
                            "
                          >
                            <a
                              class="btn btn-default re-cancel-btn"
                              (click)="callConditionAction(item)"
                              >{{ head?.action_text }}</a
                            >
                          </ng-container>
                          <ng-template #else_condition>
                            <p>N/A</p>
                          </ng-template>
                        </ng-container>

                        <ng-container *ngSwitchCase="'SELECT'">
                          <select
                            class="form-control re-select-box"
                            [(ngModel)]="item[head.key]"
                            (ngModelChange)="changeValue(head, item, $event)"
                            [ngModelOptions]="{ standalone: true }"
                          >
                            <ng-container *ngFor="let option of head?.options">
                              <option [value]="option.value">
                                {{ option.label }}
                              </option>
                            </ng-container>
                          </select>
                        </ng-container>

                        <!-- FORMATTED DATE-->
                        <ng-container *ngSwitchCase="'FORMATTED_DATE'">
                          {{ formatedDate(getValue(item, head)) }}
                        </ng-container>
                      </ng-container>

                      <p>
                        <!-- INNER VALUE -->
                        <ng-container
                          *ngFor="
                            let value of head?.innerValue;
                            let innerIndex = index
                          "
                        >
                          <span
                            *ngIf="value"
                            [ngStyle]="value?.style"
                            (click)="
                              value?.isClickExists
                                ? callAction(value, item, value)
                                : ''
                            "
                          >
                            <ng-container [ngSwitch]="value?.pipe">
                              <!-- CURRENCY -->
                              <ng-container *ngSwitchCase="'CURRENCY'">
                                <span>
                                  {{ getCurrency(item, value) | currency }}
                                </span>
                              </ng-container>

                              <!-- TITLE CASE -->
                              <ng-container *ngSwitchCase="'TITLE_CASE'">
                                <span class="text-capitalize">
                                  {{
                                    item?.mainStatusTitle ==
                                      "assigned to buyer" &&
                                    value?.key == "buyer" &&
                                    item?.buyers?.length
                                      ? (item?.buyers[0]?.firstName
                                          ? item?.buyers[0]?.firstName
                                          : "") +
                                        " " +
                                        (item?.buyers[0]?.lastName
                                          ? item?.buyers[0]?.lastName
                                          : "")
                                      : ""
                                  }}
                                </span>
                              </ng-container>

                              <ng-container *ngSwitchDefault>
                                <span
                                  *ngIf="
                                    getValue(item, value) &&
                                    getValue(item, value) != 'N/A'
                                  "
                                >
                                  {{ getValue(item, value) }}
                                </span>
                              </ng-container>
                            </ng-container>
                          </span>

                          <span *ngIf="innerIndex > 0 && head?.separatorEnd">
                            {{ head?.separatorEnd }}
                          </span>

                          <!-- PREFERRED -->
                          <span *ngIf="innerIndex > 0 && item?.isPreferred">
                            <i class="fa fa-star"></i>
                          </span>

                          <!-- BLACKLISTED -->
                          <span *ngIf="innerIndex > 0 && item?.isBlackListed">
                            <i class="fa fa-minus-circle"></i>
                          </span>
                        </ng-container>
                      </p>
                    </ng-container>

                    <!-- VALUE WITH MEDIA -->
                    <ng-container *ngIf="head?.isInboxAttachmentExists">
                      <ng-container [ngSwitch]="head?.isInboxAttachmentExists">
                        <ng-container *ngSwitchCase="'ATTACHMENT'">
                          <!-- ATTACHMENT -->
                          <ng-container
                            *ngIf="
                              head?.isInboxAttachmentExists &&
                              item[head?.attachmentKey]?.length != 0
                            "
                          >
                            <span
                              class="re-attachment"
                              (click)="
                                previewAttachment(item[head?.attachmentKey])
                              "
                            >
                              <img
                                src="assets/images/attachment-symbol-teal.png"
                                alt="attachment"
                              />
                              View Attachment
                            </span>
                          </ng-container>
                          <ng-container
                            *ngIf="item[head?.attachmentKey]?.length == 0"
                          >
                            N/A
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="head?.file">
                      <ng-container [ngSwitch]="head?.file">
                        <!-- AUDIO -->
                        <ng-container *ngSwitchCase="'AUDIO'">
                          <app-audio-player
                            *ngIf="
                              getValue(item, head) &&
                              getValue(item, head) !== 'N/A' &&
                              item?.duration != '0' &&
                              item?.status != 'no-answer' &&
                              item?.status != 'cancel' &&
                              item?.status != 'no-confirm' &&
                              item?.status != 'busy' &&
                              item?.status != 'failed'
                            "
                            [url]="getValue(item, head)"
                          ></app-audio-player>
                          <span
                            *ngIf="
                              moduleName != 'Dialer' &&
                              (item?.duration == '0' ||
                                item?.status == 'no-answer' ||
                                item?.status == 'cancel' ||
                                item?.status == 'no-confirm' ||
                                item?.status == 'busy' ||
                                item?.status == 'failed')
                            "
                          >
                            N/A
                          </span>
                          <span
                            *ngIf="
                              moduleName == 'Dialer' &&
                              item?.status != 'no-answer' &&
                              (item?.duration == '0' ||
                                item?.status == 'cancel' ||
                                item?.status == 'no-confirm' ||
                                item?.status == 'busy')
                            "
                          >
                            {{
                              item?.type == "incomingCall"
                                ? "Missed Call"
                                : "N/A"
                            }}
                          </span>
                          <span
                            *ngIf="
                              item?.status == 'no-answer' &&
                              moduleName == 'Dialer'
                            "
                          >
                            Missed Call
                          </span>
                        </ng-container>

                        <!-- ATTACHMENT -->
                        <ng-container
                          *ngIf="
                            head?.isAttachmentExists &&
                            item[head?.attachmentKey]?.length
                          "
                        >
                          <span
                            class="re-attachment"
                            (click)="
                              previewAttachment(item[head?.attachmentKey])
                            "
                          >
                            <img
                              src="assets/images/attachment-symbol-teal.png"
                              alt="attachment"
                            />
                            View Attachment
                          </span>
                        </ng-container>

                        <!-- NO RECORD -->
                        <!-- *ngIf="
                        item['smsBody'] != '' &&
                        !item[head?.attachmentKey]?.length
                      " -->
                        <ng-container *ngIf="item['smsBody'] != ''">
                          {{
                            item["smsBody"]
                              ? item["smsBody"].slice(
                                  0,
                                  item["smsBody"]?.length > 40
                                    ? 40
                                    : item["smsBody"]?.length
                                )
                              : ""
                          }}{{
                            item["smsBody"]
                              ? item["smsBody"]?.length > 40
                                ? "....."
                                : ""
                              : ""
                          }}
                        </ng-container>

                        <!-- NO RECORD -->
                        <ng-container
                          *ngIf="
                            getValue(item, head) === 'N/A' &&
                            item['smsBody'] == '' &&
                            !head?.isAttachmentExists &&
                            !item[head?.attachmentKey]?.length
                          "
                        >
                          N/A
                        </ng-container>
                        <ng-container
                          *ngIf="
                            (item?.status == 'completed' ||
                              item?.status == 'received' ||
                              item?.status == 'delivered' ||
                              item?.status == 'sent') &&
                            !item['smsBody'] &&
                            !item['recording'] &&
                            !head?.isAttachmentExists
                          "
                        >
                          N/A
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </span>
                  <div
                    class="re-hover-text"
                    *ngIf="
                      hoverText &&
                      getValue(item, hoverText) &&
                      head?.key == 'recording'
                    "
                  >
                    {{ getValue(item, hoverText) }}
                  </div>

                  <div
                    class="re-hover-Tooltip-text"
                    *ngIf="
                      hoverTooltipText &&
                      getValue(item, hoverTooltipText) &&
                      head?.key == 'recording'
                    "
                  >
                    {{ getValue(item, hoverTooltipText) }}
                  </div>

                  <!-- PREFERRED -->
                  <span
                    *ngIf="
                      headIndex == 0 &&
                      head?.isCheckPreferred &&
                      item?.isPreferred
                    "
                  >
                    <i class="fa fa-star"></i>
                  </span>

                  <!-- BLACKLISTED -->
                  <span
                    *ngIf="
                      headIndex == 0 &&
                      head?.isCheckBlackListed &&
                      item?.isBlackListed
                    "
                  >
                    <i class="fa fa-minus-circle"></i>
                  </span>
                  <div class="re_ongoing_list" *ngIf="head?.isOngoingCall">
                    <a
                      class="re-btnlink"
                      (click)="
                        toggleButtons('listen', head, item, $event, itemIndex)
                      "
                      [class.re-stop-btn]="
                        activeEntry === itemIndex && activeButton === 'listen'
                      "
                      [class.re-ongoing-disable]="
                        (activeButton !== null && activeEntry !== itemIndex) ||
                        (activeEntry === itemIndex && activeButton !== 'listen')
                      "
                      ><img
                        src="assets/images/{{
                          activeEntry === itemIndex && activeButton === 'listen'
                            ? 'icon-listen-red.svg'
                            : 'icon-listen.svg'
                        }}"
                        alt=""
                      />Listen</a
                    >
                    <a
                      class="re-btnlink"
                      (click)="
                        toggleButtons('call', head, item, $event, itemIndex)
                      "
                      [class.re-stop-btn]="
                        activeEntry === itemIndex && activeButton === 'call'
                      "
                      [class.re-ongoing-disable]="
                        (activeButton !== null && activeEntry !== itemIndex) ||
                        (activeEntry === itemIndex && activeButton !== 'call')
                      "
                      ><img
                        src="assets/images/{{
                          activeEntry === itemIndex && activeButton === 'call'
                            ? 'icon-speak-red.svg'
                            : 'icon-speak.svg'
                        }}"
                        alt=""
                      />
                      Speak</a
                    >
                    <a
                      class="re-btnlink"
                      (click)="
                        toggleButtons(
                          'mergeCall',
                          head,
                          item,
                          $event,
                          itemIndex
                        )
                      "
                      [class.re-stop-btn]="
                        activeEntry === itemIndex &&
                        activeButton === 'mergeCall'
                      "
                      [class.re-ongoing-disable]="
                        (activeButton !== null && activeEntry !== itemIndex) ||
                        (activeEntry === itemIndex &&
                          activeButton !== 'mergeCall')
                      "
                      ><img
                        src="assets/images/{{
                          activeEntry === itemIndex &&
                          activeButton === 'mergeCall'
                            ? 'icon-barging-red.svg'
                            : 'icon-barging.svg'
                        }}"
                        alt=""
                      />
                      Barge In
                    </a>
                  </div>
                </td>

                <!-- ACTION -->
                <td
                  *ngIf="head?.key === 'action'"
                  class="re-action"
                  [ngStyle]="head?.actionStyle"
                >
                  <ng-container *ngFor="let action of head?.options">
                    <ng-container *ngIf="action?.route; else else_block">
                      <a
                        class="pointer float-left"
                        (click)="redirectAction(action, item)"
                        (mouseenter)="
                          activeIconIndex = itemIndex; activeIcon = action?.type
                        "
                        (mouseleave)="activeIconIndex = -1; activeIcon = ''"
                      >
                        <img
                          [src]="getActionIcon(action, itemIndex)"
                          class="float-left"
                          [alt]="action?.type"
                        />
                      </a>
                    </ng-container>

                    <ng-template #else_block>
                      <ng-container
                        *ngIf="action?.type == 'DEFAULT_CARD'; else else_block1"
                      >
                        <div class="radiolist w-auto">
                          <label class="radiobtn"
                            >Mark as primary
                            <input
                              (change)="cardRadioChange($event, item)"
                              type="radio"
                              name="primaryRadio"
                              value="true"
                              [checked]="item?.isDefaultCard" /><span
                              class="checkmark"
                            ></span
                          ></label>
                        </div>
                      </ng-container>

                      <ng-template #else_block1>
                        <a
                          class="pointer float-left"
                          (click)="
                            callAction(action, item, itemIndex, moduleId)
                          "
                          (mouseenter)="
                            activeIconIndex = itemIndex;
                            activeIcon = action?.type
                          "
                          (mouseleave)="activeIconIndex = -1; activeIcon = ''"
                          [pTooltip]="renderTooltip(action, item)"
                          tooltipPosition="bottom"
                          *ngIf="!item?.isHideAction || item?.isHideAction == 1"
                        >
                          <ng-container
                            *ngIf="
                              action?.type === 'SHARE_FILE' ||
                                action?.type === 'CHECK_CONTINGENCY';
                              else else_icon
                            "
                          >
                            <ng-container *ngIf="action?.type === 'SHARE_FILE'">
                              <img
                                [src]="
                                  item?.isSharing
                                    ? action?.activeIcon
                                    : getActionIcon(action, itemIndex)
                                "
                                class="float-left"
                                [alt]="action?.type"
                              />
                            </ng-container>

                            <ng-container
                              *ngIf="action?.type === 'CHECK_CONTINGENCY'"
                            >
                              <img
                                [src]="
                                  item?.isCompleted
                                    ? action?.activeIcon
                                    : getActionIcon(action, itemIndex)
                                "
                                class="float-left"
                                [alt]="action?.type"
                              />
                            </ng-container>
                          </ng-container>

                          <ng-template #else_icon>
                            <ng-container *ngIf="action?.type !== 'STOP_DRIP'">
                              <ng-container
                                *ngIf="
                                  action?.isToggle && !item.isEnabled;
                                  else icon_else
                                "
                              >
                                <i class="fa fa-repeat fa-rotate-90"></i>
                              </ng-container>

                              <ng-template #icon_else>
                                <img
                                  [src]="
                                    action?.checkActive &&
                                    item[action?.activeCheckKey]
                                      ? action?.activeIcon
                                      : getActionIcon(action, itemIndex)
                                  "
                                  class="float-left"
                                  [alt]="action?.type"
                                />
                              </ng-template>
                            </ng-container>

                            <img
                              [src]="
                                item?.isSharing
                                  ? action?.activeIcon
                                  : getActionIcon(action, itemIndex)
                              "
                              class="float-left"
                              [alt]="action?.type"
                              *ngIf="
                                action?.type === 'STOP_DRIP' &&
                                getValue(item, action)
                              "
                            />
                          </ng-template>
                        </a>
                      </ng-template>
                    </ng-template>
                  </ng-container>
                </td>
              </ng-container>
            </tr>

            <tr *ngIf="item?.isRowExpand">
              <td colspan="7" class="p-0 re-subrow-box">
                <ng-container
                  *ngIf="
                    moduleName?.toLowerCase() == 'transactions' ||
                    moduleName?.toLowerCase() == 'filtertransaction'
                  "
                >
                  <app-edit-transaction-view
                    [transactionItem]="item"
                    (_transactionUpdated)="transactionUpdated($event)"
                    (_changeTransaction)="changeTransaction($event)"
                  >
                  </app-edit-transaction-view>
                </ng-container>
              </td>
            </tr>
          </ng-container>
          <tr
            *ngIf="
              data['items']?.length &&
              data['count'] === data['items']?.length &&
              paginationType === miscellaneousConstant.paginationType.INFINITE
            "
          >
            <td
              [colSpan]="header?.length"
              class="text-center text-muted font-weight-normal"
            >
              End of list
            </td>
          </tr>
        </ng-container>

        <!-- LOADING -->
        <tr>
          <!-- STANDARD LOADING -->
          <td
            [colSpan]="header?.length"
            *ngIf="_sharedService.checkLoader(moduleName)"
          >
            <!-- LOADER BY ID -->
            <div class="re-loading">
              <img src="/assets/images/loading.gif" alt="" /> Loading...
            </div>

            <p *ngIf="customLoader" class="re-custom-loader-message">
              {{ customLoaderMessage }}
            </p>
          </td>
        </tr>

        <!-- NO DATA -->
        <ng-container *ngIf="!_sharedService.checkLoader(moduleName)">
          <tr
            *ngIf="
              !data['items'] ||
              (data['items'] && (!keys(data).length || !data['items']?.length))
            "
          >
            <td
              [colSpan]="header?.length"
              class="text-center"
              *ngIf="!isCustomMessageView"
            >
              No Records Found
            </td>
            <td
              [colSpan]="header?.length"
              class="text-center"
              [ngStyle]="noRecordStyle"
              *ngIf="isCustomMessageView"
            >
              {{ customNoRecordsMessage }}
            </td>
          </tr>
        </ng-container>
      </tbody>

      <!-- TABLE BODY - INLINE FORM -->
      <ng-container *ngIf="isInlineAddEnabled">
        <tbody [ngStyle]="inlineFormStyle">
          <tr>
            <ng-container *ngFor="let action of actionFooter">
              <td>
                <div class="form-group">
                  <label class="re-label" *ngIf="action?.fieldLabel">
                    {{ action?.fieldLabel }}

                    <span class="text-danger" *ngIf="action.isFieldRequired">
                      *
                    </span>
                  </label>

                  <!-- INPUT - TEXT-->
                  <ng-container *ngIf="action.fieldType === 'TEXT'">
                    <input
                      type="text"
                      class="form-control"
                      [formControlName]="action.fieldName"
                      [placeholder]="action.placeholder"
                      [mask]="action.mask"
                    />
                  </ng-container>

                  <!-- INPUT - PRICE-->
                  <ng-container *ngIf="action.fieldType === 'PRICE'">
                    <input
                      type="text"
                      class="form-control"
                      [formControlName]="action.fieldName"
                      [placeholder]="action.placeholder"
                      mask="separator.2"
                      [thousandSeparator]="','"
                      [decimalMarker]="'.'"
                      [dropSpecialCharacters]="true"
                      prefix="$ "
                      (blur)="transformAmount($event)"
                    />
                  </ng-container>

                  <!-- DATE -->
                  <ng-container *ngIf="action.fieldType === 'DATE'">
                    <p-calendar
                      [formControlName]="action.fieldName"
                      styleClass="ngCalendarClass"
                      [placeholder]="action.placeholder"
                      [minDate]="action.minDate"
                      [maxDate]="action.maxDate"
                      appendTo="body"
                      [showIcon]="true"
                      [icon]="'fa fa-calendar'"
                      readonlyInput="true"
                    ></p-calendar>
                  </ng-container>

                  <!-- SELECT -->
                  <ng-container *ngIf="action.fieldType === 'SELECT'">
                    <select
                      class="form-control"
                      [formControlName]="action.fieldName"
                    >
                      <option value="">Select {{ action.placeholder }}</option>
                      <option
                        *ngFor="let option of action.options"
                        [value]="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                  </ng-container>

                  <ng-container *ngIf="action.fieldType === 'SUBMIT'">
                    <button class="btn btn-add" type="submit">
                      {{ action.fieldValue }}
                    </button>
                  </ng-container>

                  <ng-container *ngIf="action.fieldType === 'CANCEL'">
                    <button class="btn btn-add" (click)="cancelAction()">
                      {{ action.fieldValue }}
                    </button>
                  </ng-container>

                  <div class="invalid-feedback">
                    <ng-container *ngFor="let validation of action.validators">
                      <span
                        *ngIf="
                          isInlineFormSubmitted &&
                          hasError(action.fieldName, validation.errorName)
                        "
                      >
                        {{ validation.errorMessage }}
                      </span>
                    </ng-container>
                  </div>
                </div>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </ng-container>
    </table>
  </form>
</div>

<!-- STANDARD PAGINATION -->
<div
  class="custom_pagination"
  *ngIf="paginationType === miscellaneousConstant.paginationType.STANDARD"
>
  <app-pagination
    #pagination
    [initialPage]="currentPage"
    [disableNgChange]="disableNgChange"
    [count]="data?.count"
    [moduleName]="moduleName"
    (changePage)="onChangePage($event)"
  ></app-pagination>
</div>

<!-- ADVANCED PAGINATION -->
<div
  class="custom_pagination"
  *ngIf="paginationType === miscellaneousConstant.paginationType.ADVANCED"
>
  <app-advanced-pagination
    #pagination
    [initialPage]="currentPage"
    [count]="data?.count"
    [showPagination]="showPagination"
    (changePage)="onChangePage($event)"
    (_emitChangeLimit)="onChangeLimit($event)"
  ></app-advanced-pagination>
</div>

<!-- An hidden div is created to set the position of appearance of the menu-->
<div
  style="visibility: hidden; position: fixed"
  [style.left]="menuTopLeftPosition.x"
  [style.top]="menuTopLeftPosition.y"
  [matMenuTriggerFor]="rightMenu"
></div>

<!-- Standard material menu -->
<mat-menu #rightMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item>
      <a (click)="getDetails(item, true)">Open in new tab</a>
    </button>
  </ng-template>
</mat-menu>
