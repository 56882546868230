import {
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChildren,
  QueryList,
  ViewChild,
} from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

// UTILS
import { FilterListModel } from 'src/app/utils/models/filter';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';
import { environment } from 'src/environments/environment';

// SERVICES
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../shared.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserService } from 'src/app/providers/user/user.service';
import { MasterTaskService } from 'src/app/providers/master-task/master-task.service';
import { DialerService } from 'src/app/providers/dialer/dialer.service';

// COMPONENTS
import { SaveFilterTemplateDialogComponent } from '../filters/save-filter-template-dialog/save-filter-template-dialog.component';
import { DeleteDialogComponent } from '../dialog/delete-dialog/delete-dialog.component';
import { MultiSelect } from 'primeng/multiselect';
import * as moment from 'moment';

@Component({
  selector: 'app-dialer-filter',
  templateUrl: './dialer-filter.component.html',
  styleUrls: ['./dialer-filter.component.scss'],
})
export class DialerFilterComponent implements OnInit {
  @ViewChildren('chipInput') chipInput: QueryList<ElementRef<HTMLInputElement>>;
  @ViewChild('search') searchElement: ElementRef;
  @ViewChild('addFilter') addFilter: ElementRef;
  @ViewChild(MultiSelect) select: MultiSelect;

  @Input() moduleId: string = '';
  @Input() dilerFilters: FilterListModel[] = [];
  @Input() isFilter: boolean = false;
  @Input() appliedDialFilters: any = {};
  @Input() _applyDialFilter: any;
  @Input() mainUserId: any;
  @Input() isSaveDialFilter: boolean = false;

  templates: any[] = [];
  currentPage: number = 1;
  currentLimit: number = environment.pagination.pageLimit;
  dialogRef: any;
  availableFilters: FilterListModel[] = [];
  appliedFilteredList: FilterListModel[] = [];
  selectedTemplateIndex: number = -1;
  searchFilter: string = '';
  searchTemplate: string = '';
  messageConstant = MessageConstant;
  lists: any[] = [];
  filterGroup: any[] = [];

  constructor(
    private _sharedServices: SharedService,
    private _toastrService: ToastrService,
    private _dialog: MatDialog,
    private _loaderService: NgxUiLoaderService,
    private _commonFunctionsService: CommonFunctionsService,
    private _userService: UserService,
    private _masterTaskService: MasterTaskService,
    private _dialerService: DialerService
  ) {}

  ngOnInit(): void {
    let index;
    for (let i = 0; i < this.dilerFilters.length; i++) {
      this.dilerFilters[i]['show'] = 1;
      index = this.filterGroup.findIndex(
        (item) => item.name === this.dilerFilters[i]?.filterGroup
      );
      if (this.dilerFilters[i]?.filterGroup && index < 0)
        this.filterGroup.push({
          name: this.dilerFilters[i]?.filterGroup,
          show: 1,
        });
    }
    this.availableFilters = Object.assign([], this.dilerFilters);
  }

  initialize() {
    if (!Object.keys(this.appliedDialFilters).length) {
      setTimeout(() => {
        this.addFilter?.nativeElement?.click();
      }, 50);
    }

    this.selectedTemplateIndex = -1;
    this.resetFilterSearch();
    this.parseFilter();
    this.getSavedTemplates();
  }

  async commonFunctionCalls(filter: FilterListModel) {
    let filterCopy = Object.assign({}, filter);
    if (filter.value === 'assignee') {
      filterCopy.options = await this.getAssignee('assignee');
    }
    if (filter.value === 'callResult') {
      filterCopy.options = await this.getCallResult();
    }
    if (filter.value === 'campaign' && filter.optionsType === 'MULTI-SELECT') {
      filterCopy.options = await this.getCampaignList('campaign');
    }
    return filterCopy;
  }

  getCampaignList(type) {
    let param = {
      page: 1,
      limit: 1000,
    };

    return new Promise((resolve, reject) => {
      this._loaderService.start();
      this._dialerService.getDialerCampaignList(param).subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          let campaignList = [];
          if (response.statusCode == 200) {
            let campaignData = response?.data.items.sort((a, b) => {
              return a.campaignName.localeCompare(b.campaignName);
            });
            campaignData.filter((x) => {
              campaignList.push({ label: x?.campaignName, value: x?._id });
            });
          }
          resolve(campaignList);
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          resolve([]);
        }
      );
    });
  }

  getAssignee(type) {
    let param = {
      page: 1,
      limit: 1000,
    };

    return new Promise((resolve, reject) => {
      this._loaderService.start();
      this._masterTaskService.getUserRoleList(param).subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          let userList = [];
          if (response.statusCode == 200) {
            response.data.items.map((x) => {
              if (x?.isDialerAccess) {
                let obj = {
                  label: `${x?.firstName} ${x?.lastName}`,
                  value: x?._id,
                  roleData: x.roleData,
                };
                userList.push(obj);
              }
            });
          }
          resolve(userList);
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          resolve([]);
        }
      );
    });
  }

  getCallResult() {
    return new Promise((resolve, reject) => {
      let callResultList = [];
      let dialedCallStatus =
        this._commonFunctionsService.callConnectedDialerStatus();
      let notDialedCallStatus =
        this._commonFunctionsService.callNotConnectedDialerStatus();
      dialedCallStatus = [...dialedCallStatus, ...notDialedCallStatus];
      for (let i = 0; i < dialedCallStatus.length; i++) {
        if (dialedCallStatus[i].value == 4) {
          dialedCallStatus[i].name = 'Moved to Lead';
        }
        if (dialedCallStatus[i].value == 6) {
          dialedCallStatus[i].name = 'Call Connected Other';
        }
        if (dialedCallStatus[i].value == 14) {
          dialedCallStatus[i].name = 'Call Not Connected Other';
        }
        callResultList.push({
          label: dialedCallStatus[i].name,
          value: dialedCallStatus[i].value,
        });
      }
      resolve(callResultList);
    });
  }

  async selectFilter(filter: FilterListModel) {
    if (this.isActive(filter)) return;

    this.appliedFilteredList.push(await this.commonFunctionCalls(filter));
    let appliedFilteredListOrder = this.appliedFilteredList.map(
      (item, index) => {
        return { ...item, order: index };
      }
    );

    this.appliedFilteredList = appliedFilteredListOrder;
    this.resetFilterSearch();
  }

  isActive(filter: FilterListModel) {
    return !!(
      this.appliedFilteredList.findIndex((x) => x.value === filter.value) > -1
    );
  }

  resetFilterSearch() {
    this.searchFilter = '';
    this.filterGroup.map((e) => {
      e.show = 1;
    });
    this.dilerFilters.map((e) => {
      e.show = 1;
    });
  }
  searchFilterFunction(e) {
    this.filterGroup.map((e) => {
      e.show = 0;
    });

    const searchData = this.searchFilter?.toLowerCase();
    let filterLabel, index;
    for (let i = 0; i < this.dilerFilters.length; i++) {
      this.dilerFilters[i].show = 0;
      filterLabel = this.dilerFilters[i]?.label?.toLowerCase();
      if (filterLabel?.includes(searchData)) {
        this.dilerFilters[i].show = 1;
        index = this.filterGroup.findIndex(
          (x) => x.name === this.dilerFilters[i]?.filterGroup
        );
        if (index >= 0) this.filterGroup[index].show = 1;
      }
    }
  }

  async applyFilter() {
    let { filter, isValid }: { filter: any; isValid: boolean } =
      this.calculateFilter(this.appliedFilteredList);
    if (isValid && Object.keys(filter).length) {
      delete filter['optionType'];
      this._applyDialFilter.emit(filter);
    }
  }

  async parseFilter() {
    let appliedFilteredListLength = this.appliedFilteredList.length;
    this.appliedFilteredList = [];
    Object.keys(this.appliedDialFilters).forEach(async (key) => {
      let filter = this.availableFilters.find((filter) => filter.value === key);

      if (filter) {
        const { value, operator, selectedCondition, order } =
          this.appliedDialFilters[key];
        filter.order = order;

        let filterMultiTypes = ['assignee', 'callResult'];
        if (
          filter.value === 'campaign' &&
          filter.optionsType === 'MULTI-SELECT'
        ) {
          filterMultiTypes.push('campaign');
        }
        let filterValue = filter.value.toString();
        if (filterMultiTypes.includes(filterValue)) {
          filter = await this.commonFunctionCalls(filter);
          filter.selectedOption = value;
          filter.selectedOperator = operator;
          if (selectedCondition) filter.selectedCondition = selectedCondition;
        } else {
          if (filter.optionsType === 'DATE-RANGE') {
            let minDate = '',
              maxDate = '',
              tempDate;
            if (value[0]) {
              tempDate = value[0].split('-');
              minDate = tempDate[1] + '-' + tempDate[2] + '-' + tempDate[0];
            }
            if (value[1]) {
              tempDate = value[1].split('-');
              maxDate = tempDate[1] + '-' + tempDate[2] + '-' + tempDate[0];
            }
            filter.minVal = minDate ? minDate : '';
            filter.maxVal = maxDate ? maxDate : '';
          } else if (filter.optionsType === 'INPUT') {
            filter.inputType = value;
          } else {
            filter.selectedOption = value;
          }

          filter.selectedOperator = operator;
        }

        this.appliedFilteredList.push(filter);
        if (appliedFilteredListLength == this.appliedFilteredList.length) {
          this.appliedFilteredList = this._commonFunctionsService.orderItems(
            this.appliedFilteredList,
            'order'
          );
        }
      }
    });
  }

  calculateFilter(filter) {
    let obj = {};
    let isValid = true;
    filter.map((x) => {
      let minVal = x?.minVal ? new Date(x?.minVal) : '';
      let maxVal = x?.maxVal ? new Date(x?.maxVal) : '';
      if (
        x.optionsType != 'DATE-RANGE' &&
        x.optionsType != 'INPUT' &&
        (!x.selectedOption || !x.selectedOption?.length)
      ) {
        x.error = true;
        isValid = false;
      } else if (
        x.optionsType === 'DATE-RANGE' &&
        this.validateDateRange(x?.selectedOperator, minVal, maxVal)
      ) {
        x.rangeErrorMsg = this.validateDateRange(
          x?.selectedOperator,
          minVal,
          maxVal
        );
        x.error = true;
        isValid = false;
      } else {
        x.error = false;
      }
      let value = x.selectedOption;
      if (x.optionsType === 'INPUT') {
        if (!x?.inputType) {
          x.error = true;
          isValid = false;
        } else {
          value = x?.inputType;
        }
      }
      if (x.optionsType === 'DATE-RANGE') {
        value = [
          this._commonFunctionsService.dueDateFormat(minVal).dateFormat,
          maxVal && x?.selectedOperator == 'between'
            ? this._commonFunctionsService.dueDateFormat(maxVal).dateFormat
            : '',
        ];
      }
      let dateValue = null;
      if (this.moduleId == '' && x.optionsType === 'DATE-RANGE') {
        dateValue = [minVal, maxVal];
      }

      obj[x.value] = {
        value,
        operator: x.selectedOperator,
        optionsType: x.optionsType,
        order: x.order,
        dateValue,
      };
      if (x?.selectedCondition)
        obj[x.value]['selectedCondition'] = x?.selectedCondition;
      return x;
    });

    return { filter: obj, isValid };
  }

  validateDateRange(selectedOperator, minVal, maxVal) {
    minVal = minVal ? new Date(minVal) : '';
    maxVal = maxVal ? new Date(maxVal) : '';
    if (selectedOperator != 'between') {
      if (!minVal) return '*Please select date.';
    } else {
      if (!minVal || !maxVal) return '*Please select both dates.';
    }
    return '';
  }

  reset() {
    this.dilerFilters.map((x) => {
      if (x.value == 'callDuration') {
        x.selectedOption = 'abandonedCalls';
      } else {
        x.selectedOption = [];
      }
      x.minVal = '';
      x.maxVal = '';
      return x;
    });
    this.selectedTemplateIndex = -1;
    this.appliedFilteredList = [];
  }

  removeFilter($event, index: number) {
    $event.stopPropagation();
    $event.preventDefault();
    this.appliedFilteredList.splice(index, 1);
  }

  checkForUnique(val, data, field?) {
    let flag = true;
    for (let i = 0; i < data.length; i++) {
      if (data[i][field || 'label']?.toLowerCase() == val.toLowerCase()) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  modelChanged(filter) {
    delete filter['error'];

    if (filter?.value !== 'assignee') {
      return;
    }

    let key;

    if (filter?.value === 'assignee') {
      key = filter?.value === 'assignee';
    }

    const currentFilterIndex = this.appliedFilteredList.findIndex(
      (x) => x.value === key
    );

    if (currentFilterIndex > -1) {
      this.appliedFilteredList[currentFilterIndex].options = this.lists.filter(
        (x) => !filter?.selectedOption?.includes(x?.value)
      );

      if (
        this.appliedFilteredList[currentFilterIndex]?.selectedOption?.length
      ) {
        filter.options = this.lists.filter(
          (x) =>
            !this.appliedFilteredList[
              currentFilterIndex
            ].selectedOption.includes(x?.value)
        );
      }
    }
  }

  setMaxDate(filter) {
    if (filter?.selectedOperator == 'between') {
      const index = this.appliedFilteredList.findIndex(
        (x) => x.value === filter.value
      );
      if (index > -1) {
        this.appliedFilteredList[index].minSelectDate =
          this.appliedFilteredList[index].minVal;
        if (
          this.appliedFilteredList[index].minVal >
          this.appliedFilteredList[index].maxVal
        ) {
          this.appliedFilteredList[index].maxVal = undefined;
        }
      }
    }
    this.modelChanged(filter);
  }

  async selectTemplate(template, index) {
    try {
      let templateData = JSON.parse(template.filterData);
      this.appliedFilteredList = [];
      templateData.map(async (e) => {
        e = await this.commonFunctionCalls(e);
        return e;
      });
      templateData.forEach((key) => {
        let filter = key.value;
        if (filter && filter == 'timePeriod') {
          if (key.optionsType === 'DATE-RANGE') {
            let minDate = '';
            let maxDate = '';
            let minSelectDate = '';
            if (key.minVal) {
              minDate = moment.utc(new Date(key.minVal)).format('MM-DD-YYYY');
              minSelectDate = moment
                .utc(new Date(key.minSelectDate))
                .format('MM-DD-YYYY');
            }
            if (key.minSelectDate) {
              minSelectDate = moment
                .utc(new Date(key.minSelectDate))
                .format('MM-DD-YYYY');
            }
            if (key.maxVal) {
              maxDate = moment.utc(new Date(key.maxVal)).format('MM-DD-YYYY');
            }
            key.minVal = minDate ? new Date(minDate) : '';
            key.minSelectDate = minSelectDate ? new Date(minSelectDate) : '';
            key.maxVal = maxDate ? new Date(maxDate) : '';
          }
        }
        this.appliedFilteredList.push(key);
      });
      this.selectedTemplateIndex = index;
    } catch (error) {
      return error;
    }
  }

  editFilterTemplate(template, index) {
    const { filter, isValid } = this.calculateFilter(
      JSON.parse(template.filterData)
    );
    if (!(isValid && Object.keys(filter).length)) return;

    this.dialogRef = this._dialog.open(SaveFilterTemplateDialogComponent, {
      width: '500px',
      data: {
        filterType: 'dialerFilter',
        filterData: template.filterData,
        moduleId: this.moduleId,
        template,
      },
    });

    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) this.templates[index] = data;
    });
  }

  saveFilterTemplate() {
    const { filter, isValid } = this.calculateFilter(this.appliedFilteredList);
    if (!(isValid && Object.keys(filter).length)) return;

    this.dialogRef = this._dialog.open(SaveFilterTemplateDialogComponent, {
      width: '500px',
      data: {
        filterType: 'dialerFilter',
        filterData: JSON.stringify(this.appliedFilteredList),
        moduleId: this.moduleId,
        template:
          this.selectedTemplateIndex === -1
            ? null
            : this.templates[this.selectedTemplateIndex],
      },
    });

    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (this.selectedTemplateIndex === -1) this.templates.push(data);
        else this.templates[this.selectedTemplateIndex] = data;
      }
    });
  }

  deleteFilterTemplate(template, index) {
    this.dialogRef = this._dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: {
        subModule: 'Dialer Filter',
        moduleId: this.moduleId,
        details: template,
        index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteDialerFilterTemplate(template?._id, index);
      }
    });
  }

  deleteDialerFilterTemplate(_id, index) {
    this._sharedServices
      .deleteDialerFilterTemplate({ filterTemplateId: _id })
      .subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this._toastrService.success(
              this.messageConstant.filterDeleteSuccess
            );
            if (index === this.selectedTemplateIndex)
              this.selectedTemplateIndex = -1;
            this.templates.splice(index, 1);
            this._loaderService.stop();
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
  }

  selected(event: MatAutocompleteSelectedEvent, filter: FilterListModel): void {
    if (this.checkForUnique(event.option.value.label, filter.selectedOption)) {
      filter.selectedOption.push(event.option.value);
      const list = this.chipInput?.toArray();
      if (list) {
        list.filter((input) => {
          input.nativeElement.value = '';
          input.nativeElement.blur();
        });
      }
      this.modelChanged(filter);
    }
  }

  setSearchFocus() {
    setTimeout(() => {
      this.searchElement?.nativeElement?.focus();
    }, 50);
  }

  hideOptionsPanel() {
    this.select.hide();
  }

  getSavedTemplates() {
    if (this.moduleId == '') {
      return;
    }
    const obj = {
      moduleId: this.moduleId,
      page: this.currentPage,
      limit: this.currentLimit,
    };

    this._sharedServices.getDialerFilterTemplates(obj).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          this.templates = [...response.data?.items];
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
}
