// MODULES
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {
  StripeCardElementChangeEvent,
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BillingService } from 'src/app/providers/billing/billing.service';

// UTILS
import { MiscellaneousConstant } from '../../../utils/miscellaneous-constant';
import { MessageConstant } from '../../../utils/message-constant';
import { ResponseModel } from '../../../utils/models/response';
import { ErrorModel } from '../../../utils/models/error';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

@Component({
  selector: 'app-add-card-dialog',
  templateUrl: './add-card-dialog.component.html',
  styleUrls: ['./add-card-dialog.component.scss'],
})
export class AddCardDialogComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  public messageConstant = MessageConstant;
  stripeError: string = '';
  defaultSource: string = '';
  selectedCardId: string = '';
  isDefaultCard: boolean = false;
  stripeCustomerId: string = '';
  isEdit: boolean = false;
  cardId: string;
  cardData: any;
  isPay: boolean = false;
  payAmount: number = 0;
  payDescription: string = '';
  paymentType: number = 0;

  constructor(
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private stripeService: StripeService,
    private _billingService: BillingService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private addCardDailog: MatDialogRef<AddCardDialogComponent>,
    public _utilities: CommonFunctionsService
  ) {
    this.cardData = {
      cardNumber: '',
      expMonth: '',
      expYear: '',
      addressZip: '',
    };

    if (this.data.action == 'edit') {
      this.cardId = this.data.item.id;
      this.isEdit = true;

      this.cardData = {
        cardNumber: 'xxxx-xxxx-xxxx-' + this.data.item.last4,
        expMonth: this.data.item.exp_month,
        expYear: this.data.item.exp_year,
        addressZip: this.data.item.address_zip,
      };
      this.isDefaultCard = this.data.item.isDefaultCard;
    } else if (this.data.action == 'pay') {
      this.isPay = true;
      this.payAmount = this.data.amount;
      this.payDescription = this.data.description;
      this.paymentType = this.data.paymentType;
    }
  }

  ngOnInit(): void {
    this._loaderService.start();
    setTimeout(() => {
      this._loaderService.stop();
    }, 2000);
  }

  onChange(event: StripeCardElementChangeEvent) {
    if (event.error?.message) {
      this.stripeError = event.error?.message;
    } else {
      this.stripeError = '';
    }
  }

  cardRadioChange(val) {
    if (val.target.value == 'true') {
      this.isDefaultCard = true;
    } else {
      this.isDefaultCard = false;
    }
  }

  submitAction(saveCreditCardForm) {
    if (this.isEdit) {
      if (saveCreditCardForm.invalid) {
        window.scroll(0, 0);
        return false;
      }
      this.updateCard();
    } else {
      this.createToken();
    }
  }

  createToken() {
    this._loaderService.start();
    this.stripeService.createToken(this.card.element).subscribe((result) => {
      this._loaderService.stop();
      if (result.token) {
        this.stripeError = '';
        let token = result.token.id;
        this.addCard(token);
      } else if (result.error) {
        this.stripeError = result.error.message;
      }
    });
  }

  async addCard(cardToken) {
    this._loaderService.start();
    let param = {
      cardToken,
      isDefaultCard: this.isDefaultCard,
    };

    if (this.data?.mainUserId) {
      let encryptedString = await this._utilities.getEncryptedString(
        this.data?.mainUserId
      );
      param['muid'] = encryptedString;
    }

    this._billingService
      .addCard(param, this.data?.mainUserId ? true : false)
      .subscribe(
        (response: ResponseModel) => {
          if (response?.statusCode == 200) {
            this._loaderService.stop();
            let result = response?.data;

            if (this.isPay) {
              this.chargeAmount(
                result.cardSourceId,
                this.payAmount,
                this.payDescription,
                this.paymentType
              );
            } else {
              this._toastrService.success(
                this.messageConstant.cardAddedSuccess
              );
              this.addCardDailog.close(result);
            }
          }
        },
        (err) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
  }

  updateCard() {
    this._loaderService.start();
    let param = {
      cardSourceId: this.data.item.id,
      isDefaultCard: this.isDefaultCard,
      expMonth: this.cardData.expMonth,
      expYear: this.cardData.expYear,
      addressZip: this.cardData.addressZip,
    };

    this._billingService.updateCard(param).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          this._loaderService.stop();
          this._toastrService.success(this.messageConstant.cardUpdatedSuccess);
          let result = response?.data;
          this.addCardDailog.close(result);
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  chargeAmount(cardSourceId, amount, description, paymentType) {
    let param = {
      cardSourceId,
      amount,
      description,
      paymentType,
    };
    this._loaderService.start();

    this._billingService.chargeAmount(param).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.addCardDailog.close({
            isPaymentSuccess: true,
            ...response?.data,
          });
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
}
