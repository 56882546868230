<div class="re-preferencetab">
  <!-------------------------------- Preference List ------------------------------------->

  <!-- LIST STACKING INFO -->
  <ng-container *ngIf="listStackingInfo">
    <div class="re-preference-title">
      <h4 class="re-group-title">LIST STACKING INFO</h4>
      <a
        href="javascript:void(0)"
        class="re-toggle-section"
        (click)="toggleSection('list-stacking')"
        *ngIf="isToggleIconView"
      >
        <span *ngIf="!isIconVisible('list-stacking')">
          <i class="re-arrow re-up"></i>
        </span>
        <span *ngIf="isIconVisible('list-stacking')">
          <i class="re-arrow re-down"></i>
        </span>
      </a>
    </div>
    <ul
      class="re-question-container re-question-border"
      [class.re-collapse]="isIconVisible('list-stacking')"
    >
      <li class="w-100">
        <span class="re-question-title"> Vacant per USPS </span>
        <span class="re-question-value" *ngIf="viewPropertyList?.property">
          {{ viewPropertyList?.property?.Vacant ? "Yes" : "No" }}
        </span>
        <span class="re-question-value" *ngIf="!viewPropertyList?.property">
          N/A
        </span>
      </li>

      <li class="w-100">
        <span class="re-question-title"> Part of lists </span>
        <div class="re-question-value-main">
          <span
            class="re-question-value"
            *ngFor="
              let propertyList of viewPropertyList?.propertyAvailableInList;
              let i = index
            "
          >
            {{ propertyList?.name
            }}{{
              i == viewPropertyList?.propertyAvailableInList?.length - 1
                ? ""
                : ","
            }}
          </span>
          <span
            class="re-question-value"
            *ngIf="
              viewPropertyList?.length == 0 ||
              viewPropertyList?.propertyAvailableInList?.length == 0
            "
            >N/A</span
          >
        </div>
      </li>
      <li class="w-100">
        <span class="re-question-title"> Part of tags </span>
        <div class="re-question-value-main">
          <span
            class="re-question-value"
            *ngFor="
              let tagList of viewPropertyList?.propertyAvailableInTags;
              let i = index
            "
          >
            {{ tagList?.name
            }}{{
              i == viewPropertyList?.propertyAvailableInTags?.length - 1
                ? ""
                : ","
            }}
          </span>
          <span
            class="re-question-value"
            *ngIf="
              viewPropertyList?.length == 0 ||
              viewPropertyList?.propertyAvailableInTags?.length == 0
            "
            >N/A</span
          >
        </div>
      </li>
    </ul>
  </ng-container>

  <!-- UPLOAD QUESTION -->
  <ng-container *ngIf="uploadQuestionsTag">
    <div class="re-tab" *ngIf="viewPropertyList && viewPropertyList?.length">
      <div class="re-info-box re-top-bor">
        <div class="re-preference-title">
          <h4 class="re-group-title">{{ uploadTitle }}</h4>
        </div>

        <!-- QUESTIONS -->
        <ul class="re-question-container">
          <li *ngFor="let question of viewPropertyList" class="w-100">
            <span class="re-question-title">
              {{ question?.questionTitle }}
            </span>
            <span
              class="re-question-value"
              [innerHTML]="
                question?.answer
                  ? (question?.answer
                    | format : question : 'PREFERENCE'
                    | textToLink)
                  : 'N/A'
              "
            >
            </span>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>

  <!------------ PREFERENCE QUESTIONS LIST GROUP ----------->
  <div class="" *ngIf="!isEdit">
    <ng-container *ngIf="!isEdit && preferenceData">
      <ng-container *ngFor="let group of preferenceData">
        <div
          [class.re-top-bor.re-info-box]="isAddStyle"
          [class.dispo-preference-questions]="isEditIconHover"
        >
          <div class="re-preference-title" [ngStyle]="style">
            <!----- TITLE ----->
            <h4 class="re-group-title">
              {{ group?.groupTitle }}
              <img
                class="re-edit-dispo"
                [class.re-edit-hover-dispo]="isEditIconHover"
                *ngIf="isEditIconView"
                src="/assets/images/edit.svg"
                alt=""
                (click)="editPreference()"
              />
            </h4>

            <!----- QUESTIONS EDIT ICON ----->
            <a
              href="javascript:void(0)"
              class="re-toggle-section"
              (click)="toggleSection(group?._id)"
              *ngIf="isToggleIconView"
            >
              <span *ngIf="!isIconVisible(group?._id)">
                <i class="re-arrow re-up"></i>
              </span>
              <span *ngIf="isIconVisible(group?._id)">
                <i class="re-arrow re-down"></i>
              </span>
            </a>
          </div>
          <!-- PREFERENCE QUESTIONS -->
          <ul
            class="re-question-container re-question-border"
            [class.re-collapse]="isIconVisible(group?._id)"
          >
            <li *ngFor="let question of group?.question" class="w-100">
              <span class="re-question-title">
                {{ question?.questionTitle }}
              </span>
              <span
                style="text-transform: none"
                class="re-question-value"
                [innerHTML]="
                  question?.answer
                    ? (question?.answer
                      | format : question : 'PREFERENCE'
                      | textToLink)
                    : 'N/A'
                "
              >
              </span>
            </li>
          </ul>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <!-- PREFERENCE EDIT START-->
  <div class="" *ngIf="isEdit && preferenceType">
    <div *ngIf="!isCustomPreference">
      <form
        [formGroup]="preferenceForm"
        (ngSubmit)="onSubmitForm()"
        autocomplete="off"
        novalidate
      >
        <div class="" formArrayName="{{ preferenceName }}">
          <ng-container
            *ngFor="
              let preference of preferenceGroups['controls'];
              let groupIndex = index
            "
            [formGroupName]="groupIndex"
          >
            <div
              [class.re-groups]="preferenceType == 'buyerWebForm'"
              [class.d-none]="
                !(
                  isVisible(
                    visibleType,
                    isAuthenticated,
                    groups[groupIndex]?._id
                  ) && getQuestionVisible(groups[groupIndex], isAuthenticated)
                ) && preferenceType == 'buyerWebForm'
              "
            >
              <div
                [class.re-preference-info]="
                  preferenceType == 'leadWebForm' ||
                  preferenceType == 'leadInfoForm'
                "
                [class.re-form-container]="preferenceType == 'buyerWebForm'"
                *ngIf="
                  isVisible(
                    visibleType,
                    isAuthenticated,
                    groups[groupIndex]?._id
                  ) && getQuestionVisible(groups[groupIndex], isAuthenticated)
                "
              >
                <div
                  [class.row]="
                    preferenceType == 'leadWebForm' ||
                    preferenceType == 'leadInfoForm'
                  "
                >
                  <!-- TITLE -->
                  <div
                    [class.col-12]="
                      preferenceType == 'leadWebForm' ||
                      preferenceType == 'leadInfoForm'
                    "
                    [class.col-lg-12]="
                      preferenceType == 'leadWebForm' ||
                      preferenceType == 'leadInfoForm'
                    "
                    [class.col-md-12]="
                      preferenceType == 'leadWebForm' ||
                      preferenceType == 'leadInfoForm'
                    "
                    [class.col-sm-12]="
                      preferenceType == 'leadWebForm' ||
                      preferenceType == 'leadInfoForm'
                    "
                  >
                    <h4
                      class="re-group-main-title title"
                      title="{{ getPreferenceTitle(groupIndex).title }}"
                    >
                      {{ groups[groupIndex]?.title }}
                      <!-- TOGGLE VISIBILITY -->
                      <ng-container *ngIf="isAuthenticated">
                        <!-- BUYER PREFERENCE -->
                        <div
                          class="btn-group"
                          role="group"
                          aria-label="Basic example"
                          *ngIf="preferenceType == 'buyerWebForm'"
                        >
                          <!-- SHOW ALL -->
                          <button
                            type="button"
                            class="btn btn-secondary"
                            (click)="
                              toggleVisibility(
                                'preferenceIds',
                                groups[groupIndex]?._id,
                                true,
                                preference,
                                groupIndex
                              )
                            "
                            [class.re-active]="
                              isActive('all', groupIndex, preference)
                            "
                          >
                            Show All
                          </button>

                          <!-- HIDE ALL -->
                          <button
                            type="button"
                            class="btn btn-secondary"
                            (click)="
                              toggleVisibility(
                                'preferenceIds',
                                groups[groupIndex]?._id,
                                false,
                                preference,
                                groupIndex
                              )
                            "
                            [class.re-active]="
                              isActive('none', groupIndex, preference)
                            "
                          >
                            Hide All
                          </button>

                          <!-- CUSTOM -->
                          <button
                            type="button"
                            class="btn btn-secondary"
                            (click)="setCustom(groupIndex)"
                            [class.re-active]="
                              isActive('custom', groupIndex, preference)
                            "
                          >
                            Custom
                          </button>
                        </div>

                        <!-- LEADS PREFERENCE -->
                        <span *ngIf="preferenceType == 'leadWebForm'">
                          <!-- VISIBLE -->
                          <span
                            (click)="
                              toggleVisibility(
                                'preferenceIds',
                                groups[groupIndex]?._id
                              )
                            "
                            class="re-toggle-display re-visible"
                            *ngIf="
                              isVisible(
                                'preferenceIds',
                                '',
                                groups[groupIndex]?._id
                              )
                            "
                          >
                            Visible
                          </span>
                          <!-- HIDDEN -->
                          <span
                            (click)="
                              toggleVisibility(
                                'preferenceIds',
                                groups[groupIndex]?._id
                              )
                            "
                            class="re-toggle-display re-hidden"
                            *ngIf="
                              !isVisible(
                                'preferenceIds',
                                '',
                                groups[groupIndex]?._id
                              )
                            "
                          >
                            Hidden
                          </span>
                        </span>
                      </ng-container>
                    </h4>
                  </div>

                  <!-- QUESTION FIELDS -->
                  <div
                    [class.re-form]="preferenceType == 'buyerWebForm'"
                    [class.re-full-block]="
                      preferenceType == 'leadWebForm' ||
                      preferenceType == 'leadInfoForm'
                    "
                  >
                    <div [class.row]="preferenceType == 'buyerWebForm'">
                      <div
                        formArrayName="questions"
                        class="col-12 col-lg-12 col-md-12 col-sm-12 re-mb"
                      >
                        <ng-container
                          *ngFor="
                            let question of getQuestion(preference);
                            let questionIndex = index
                          "
                        >
                          <div
                            *ngIf="
                              isVisible(
                                'questionIds',
                                isAuthenticated,
                                groups[groupIndex]?.fields[questionIndex]?._id
                              )
                            "
                          >
                            <div
                              class="form-group"
                              [formGroupName]="questionIndex"
                            >
                              <!-- BUYER LABEL -->
                              <div
                                class="re-label-container justify-content-between"
                                *ngIf="preferenceType == 'buyerWebForm'"
                              >
                                <span class="re-label">
                                  {{
                                    getValue(
                                      groupIndex,
                                      questionIndex,
                                      "questionTitle"
                                    )
                                  }}
                                </span>
                                <!-- TOGGLE VISIBILITY -->
                                <ng-container *ngIf="isAuthenticated">
                                  <!-- VISIBLE -->
                                  <span
                                    (click)="
                                      toggleVisibility(
                                        'questionIds',
                                        groups[groupIndex]?.fields[
                                          questionIndex
                                        ]?._id,
                                        false,
                                        preference,
                                        groups[groupIndex]?._id
                                      )
                                    "
                                    class="re-toggle-display re-visible"
                                    [class.text-muted]="
                                      isAvailable(groupIndex, preference)
                                    "
                                    *ngIf="
                                      isVisible(
                                        'questionIds',
                                        '',
                                        groups[groupIndex]?.fields[
                                          questionIndex
                                        ]?._id
                                      )
                                    "
                                  >
                                    <i class="fa fa-eye"></i>
                                    Visible
                                  </span>

                                  <!-- HIDDEN -->
                                  <span
                                    (click)="
                                      toggleVisibility(
                                        'questionIds',
                                        groups[groupIndex]?.fields[
                                          questionIndex
                                        ]?._id,
                                        true,
                                        preference,
                                        groups[groupIndex]?._id
                                      )
                                    "
                                    class="re-toggle-display re-hidden"
                                    [class.text-muted]="
                                      isAvailable(groupIndex, preference)
                                    "
                                    *ngIf="
                                      !isVisible(
                                        'questionIds',
                                        '',
                                        groups[groupIndex]?.fields[
                                          questionIndex
                                        ]?._id
                                      )
                                    "
                                  >
                                    <i class="fa fa-eye-slash"></i>
                                    Hidden
                                  </span>
                                </ng-container>
                              </div>

                              <!-- LEADS LABEL -->
                              <label
                                class="re-label re-question-title"
                                *ngIf="
                                  preferenceType == 'leadWebForm' ||
                                  preferenceType == 'leadInfoForm'
                                "
                              >
                                {{
                                  getValue(
                                    groupIndex,
                                    questionIndex,
                                    "questionTitle"
                                  )
                                }}

                                <!-- TOGGLE VISIBILITY -->
                                <ng-container *ngIf="isAuthenticated">
                                  <!-- VISIBLE -->
                                  <span
                                    (click)="
                                      toggleVisibility(
                                        'questionIds',
                                        groups[groupIndex]?.fields[
                                          questionIndex
                                        ]?._id
                                      )
                                    "
                                    class="re-toggle-display re-visible"
                                    *ngIf="
                                      isVisible(
                                        'questionIds',
                                        '',
                                        groups[groupIndex]?.fields[
                                          questionIndex
                                        ]?._id
                                      )
                                    "
                                  >
                                    Visible
                                  </span>

                                  <!-- HIDDEN -->
                                  <span
                                    (click)="
                                      toggleVisibility(
                                        'questionIds',
                                        groups[groupIndex]?.fields[
                                          questionIndex
                                        ]?._id
                                      )
                                    "
                                    class="re-toggle-display re-hidden"
                                    *ngIf="
                                      !isVisible(
                                        'questionIds',
                                        '',
                                        groups[groupIndex]?.fields[
                                          questionIndex
                                        ]?._id
                                      )
                                    "
                                  >
                                    Hidden
                                  </span>
                                </ng-container>
                              </label>

                              <!-- TEXT -->
                              <ng-container
                                *ngIf="
                                  getValue(
                                    groupIndex,
                                    questionIndex,
                                    'questionType'
                                  ) === 'TEXT'
                                "
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="preferenceQuestion"
                                  [name]="
                                    getValue(groupIndex, questionIndex, '_id')
                                  "
                                  [placeholder]="
                                    getValue(
                                      groupIndex,
                                      questionIndex,
                                      'placeholder'
                                    )
                                  "
                                  [id]="
                                    getValue(groupIndex, questionIndex, '_id')
                                  "
                                />
                              </ng-container>

                              <!-- MULTILINE_TEXT -->
                              <ng-container
                                *ngIf="
                                  getValue(
                                    groupIndex,
                                    questionIndex,
                                    'questionType'
                                  ) === 'MULTILINE_TEXT'
                                "
                              >
                                <textarea
                                  type="text"
                                  class="form-control"
                                  formControlName="preferenceQuestion"
                                  [name]="
                                    getValue(groupIndex, questionIndex, '_id')
                                  "
                                  [placeholder]="
                                    getValue(
                                      groupIndex,
                                      questionIndex,
                                      'placeholder'
                                    )
                                  "
                                  [id]="
                                    getValue(groupIndex, questionIndex, '_id')
                                  "
                                ></textarea>
                              </ng-container>

                              <!-- CURRENCY -->
                              <ng-container
                                *ngIf="
                                  getValue(
                                    groupIndex,
                                    questionIndex,
                                    'questionType'
                                  ) === 'CURRENCY'
                                "
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="preferenceQuestion"
                                  [name]="
                                    getValue(groupIndex, questionIndex, '_id')
                                  "
                                  [placeholder]="
                                    getValue(
                                      groupIndex,
                                      questionIndex,
                                      'placeholder'
                                    )
                                  "
                                  [id]="
                                    getValue(groupIndex, questionIndex, '_id')
                                  "
                                  mask="separator.2"
                                  [thousandSeparator]="','"
                                  [decimalMarker]="'.'"
                                  prefix="$ "
                                  (blur)="transformAmount($event)"
                                />
                              </ng-container>

                              <!-- NUMBER -->
                              <ng-container
                                *ngIf="
                                  getValue(
                                    groupIndex,
                                    questionIndex,
                                    'questionType'
                                  ) === 'NUMBER'
                                "
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  formControlName="preferenceQuestion"
                                  [name]="
                                    getValue(groupIndex, questionIndex, '_id')
                                  "
                                  [placeholder]="
                                    getValue(
                                      groupIndex,
                                      questionIndex,
                                      'placeholder'
                                    )
                                  "
                                  [id]="
                                    getValue(groupIndex, questionIndex, '_id')
                                  "
                                  mask="separator.2"
                                  [thousandSeparator]="','"
                                  [decimalMarker]="'.'"
                                  (blur)="transformAmount($event)"
                                />
                              </ng-container>

                              <!-- DATE -->
                              <ng-container
                                *ngIf="
                                  getValue(
                                    groupIndex,
                                    questionIndex,
                                    'questionType'
                                  ) === 'DATE'
                                "
                              >
                                <p-calendar
                                  appendTo="body"
                                  [showIcon]="true"
                                  [icon]="'fa fa-calendar'"
                                  [showTime]="true"
                                  [hourFormat]="12"
                                  formControlName="preferenceQuestion"
                                  [dateFormat]="
                                    getValue(
                                      groupIndex,
                                      questionIndex,
                                      'dateFormat'
                                    )
                                  "
                                  [placeholder]="
                                    getValue(
                                      groupIndex,
                                      questionIndex,
                                      'placeholder'
                                    )
                                  "
                                  [id]="
                                    getValue(groupIndex, questionIndex, '_id')
                                  "
                                ></p-calendar>
                              </ng-container>

                              <!-- CHECK LIST -->
                              <ng-container
                                *ngIf="
                                  getValue(
                                    groupIndex,
                                    questionIndex,
                                    'questionType'
                                  ) === 'CHECK_LIST'
                                "
                              >
                                <div
                                  *ngFor="
                                    let item of getValue(
                                      groupIndex,
                                      questionIndex,
                                      'options'
                                    );
                                    let checkIndex = index
                                  "
                                  class="text-left"
                                  formGroupName="preferenceQuestion"
                                >
                                  <div
                                    [formGroupName]="checkIndex"
                                    class="checkboxlist"
                                  >
                                    <label class="checkboxbtn">
                                      <input
                                        type="checkbox"
                                        [name]="'checkboxValue'"
                                        formControlName="checkboxValue"
                                        [checked]="item.defaultSelected"
                                        [value]="item.value"
                                      />
                                      <span class="checkmark"></span>
                                      {{ item.key }}
                                    </label>
                                  </div>
                                </div>
                              </ng-container>

                              <!-- RADIO BUTTON -->
                              <ng-container
                                *ngIf="
                                  getValue(
                                    groupIndex,
                                    questionIndex,
                                    'questionType'
                                  ) === 'RADIO_BUTTON'
                                "
                              >
                                <div class="radiolist">
                                  <label
                                    class="radiobtn form-check-inline"
                                    *ngFor="
                                      let item of getValue(
                                        groupIndex,
                                        questionIndex,
                                        'options'
                                      )
                                    "
                                  >
                                    <input
                                      type="radio"
                                      [name]="'preferenceQuestion'"
                                      formControlName="preferenceQuestion"
                                      [checked]="item.defaultSelected"
                                      [value]="item.value"
                                    />
                                    <span class="checkmark"></span>
                                    {{ item.key }}
                                  </label>
                                </div>
                              </ng-container>

                              <!-- DROPDOWN -->
                              <ng-container
                                *ngIf="
                                  getValue(
                                    groupIndex,
                                    questionIndex,
                                    'questionType'
                                  ) === 'DROPDOWN'
                                "
                              >
                                <select
                                  class="form-control"
                                  formControlName="preferenceQuestion"
                                >
                                  <option value="">Select</option>
                                  <option
                                    *ngFor="
                                      let option of getValue(
                                        groupIndex,
                                        questionIndex,
                                        'options'
                                      )
                                    "
                                    [value]="option?.key"
                                  >
                                    {{ option.key }}
                                  </option>
                                </select>
                              </ng-container>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <!-- SUBMIT BUTTON -->
          <div
            [class.re-action-container]="preferenceType == 'leadInfoForm'"
            [class.re-add-btn]="
              preferenceType == 'leadWebForm' ||
              preferenceType == 'buyerWebForm'
            "
            *ngIf="isSubmitButton && isEdit"
          >
            <button
              class="btn btn-default"
              type="button"
              (click)="cancel()"
              *ngIf="preferenceType == 'leadInfoForm'"
            >
              Cancel
            </button>
            <button
              class="btn btn-success"
              [disabled]="isDisabled"
              type="button"
              (click)="onSubmitForm()"
            >
              {{ onSubmitText().text }}
            </button>

            <a
              *ngIf="
                !isAuthenticated &&
                preferenceType == 'buyerWebForm' &&
                !updateApiCall
              "
              class="re-company"
            >
              Powered by:
              <img (click)="goToSite()" src="/assets/images/new-logo1.png" />
            </a>
          </div>
        </div>
      </form>
    </div>

    <!-------------------------------- Custom Preference Start ------------------------------------->

    <div class="re-group-container" *ngIf="isCustomPreference">
      <ng-container *ngFor="let group of groups; let groupIndex = index">
        <div
          dndDragImageRef
          [dndDraggable]="group"
          [dndEffectAllowed]="draggable.effectAllowed"
          [dndDisableIf]="draggable.disable"
          (dndStart)="onDragStart($event)"
          (dndMoved)="onDragged(group, groups, 'move')"
          [dndDropzone]="['group']"
          [dndType]="'group'"
          (dndDrop)="onDrop($event, group, 'section')"
          class="re-custom-groups"
        >
          <div class="re-form-container">
            <h4 class="title">
              <span> {{ group?.title }} </span>
              <span>
                <!-- EDIT -->
                <img
                  class="re-action-button"
                  src="/assets/images/edit.svg"
                  alt="Edit Group"
                  (click)="addEditCustomSection(group, groupIndex)"
                />

                <!-- DELETE -->
                <img
                  class="re-action-button"
                  src="/assets/images/delete.svg"
                  alt="Delete Group"
                  (click)="deleteSectionAndQuestion(true, group, groupIndex)"
                />

                <a
                  href="javascript:void(0)"
                  class="collapsed"
                  (click)="toggleSection(group?._id)"
                >
                  <span *ngIf="!isIconVisible(group?._id)">
                    <i class="re-arrow re-up"></i>
                  </span>
                  <span *ngIf="isIconVisible(group?._id)">
                    <i class="re-arrow re-down"></i>
                  </span>
                </a>
              </span>
            </h4>

            <div
              class="re-question-container"
              [class.collapse]="isIconVisible(group?._id)"
            >
              <!-- DYNAMIC FIELDS -->
              <div class="row">
                <ng-container
                  *ngFor="let field of group?.fields; let fieldIndex = index"
                >
                  <div
                    [dndDraggable]="field"
                    [dndEffectAllowed]="draggable.effectAllowed"
                    [dndDisableIf]="draggable.disable"
                    (dndStart)="onDragStart($event)"
                    (dndMoved)="onDragged(field, group?.fields, 'move')"
                    [dndDropzone]="['field']"
                    [dndType]="'field'"
                    (dndDrop)="onDrop($event, field, 'field', groupIndex)"
                    class="col-12"
                  >
                    <div class="form-group" dndDragImageRef>
                      <div class="label justify-content-between">
                        <span class="re-label">{{ field?.questionTitle }}</span>

                        <!-- ACTION CONTAINER -->
                        <span>
                          <!-- EDIT -->
                          <img
                            class="re-action-button"
                            src="/assets/images/edit.svg"
                            alt="Edit Group"
                            (click)="
                              addEditCustomQuestion(group, field, fieldIndex)
                            "
                          />

                          <!-- DELETE -->
                          <img
                            class="re-action-button"
                            src="/assets/images/delete.svg"
                            alt="Delete Group"
                            (click)="
                              deleteSectionAndQuestion(
                                false,
                                group,
                                fieldIndex,
                                field
                              )
                            "
                          />
                        </span>
                      </div>

                      <!-- TEXT -->
                      <ng-container *ngIf="field?.questionType === 'TEXT'">
                        <input
                          type="text"
                          class="form-control"
                          [name]="field?.questionType"
                          [placeholder]="field?.placeholder"
                          [id]="field?._id"
                        />
                      </ng-container>

                      <!-- MULTILINE_TEXT -->
                      <ng-container
                        *ngIf="field?.questionType === 'MULTILINE_TEXT'"
                      >
                        <textarea
                          type="text"
                          class="form-control"
                          [name]="field?.questionType"
                          [placeholder]="field?.placeholder"
                          [id]="field?._id"
                        ></textarea>
                      </ng-container>

                      <!-- NUMBER -->
                      <ng-container
                        *ngIf="
                          field?.questionType === 'NUMBER' ||
                          field?.questionType === 'CURRENCY'
                        "
                      >
                        <input
                          type="text"
                          class="form-control"
                          [id]="field?._id"
                          [name]="field?.questionType"
                          [placeholder]="field?.placeholder"
                          mask="separator.2"
                          [thousandSeparator]="','"
                          [decimalMarker]="'.'"
                        />
                      </ng-container>

                      <!-- DATE -->
                      <ng-container *ngIf="field?.questionType === 'DATE'">
                        <p-calendar
                          appendTo="body"
                          [showIcon]="true"
                          [icon]="'fa fa-calendar'"
                          [showTime]="true"
                          [dateFormat]="field?.dateFormat"
                          [placeholder]="field?.placeholder"
                        ></p-calendar>
                      </ng-container>

                      <!-- CHECKBOX -->
                      <ng-container
                        *ngIf="field?.questionType === 'CHECK_LIST'"
                      >
                        <div
                          *ngFor="let item of field.options"
                          class="text-left checkboxlist"
                        >
                          <label class="form-check-inline checkboxbtn">
                            <input
                              type="checkbox"
                              [name]="field?.questionType"
                              [checked]="item.defaultSelected"
                              [value]="item.value"
                            />
                            <div class="checkmark"></div>
                            {{ item.key }}
                          </label>
                        </div>
                      </ng-container>

                      <!-- RADIO BUTTON -->
                      <ng-container
                        *ngIf="field?.questionType === 'RADIO_BUTTON'"
                      >
                        <div class="text-left radiolist">
                          <label
                            class="form-check-inline radiobtn"
                            *ngFor="let item of field.options"
                          >
                            <input
                              type="radio"
                              [name]="field?.questionType"
                              [checked]="item.defaultSelected"
                              [value]="item.value"
                            />
                            <span class="checkmark"></span>
                            {{ item.key }}
                          </label>
                        </div>
                      </ng-container>

                      <!-- DROPDOWN -->
                      <ng-container *ngIf="field?.questionType === 'DROPDOWN'">
                        <select class="form-control">
                          <option value="">Select</option>
                          <option
                            *ngFor="let option of field?.options"
                            [value]="option?.key"
                            [selected]="option?.defaultSelected"
                          >
                            {{ option.key }}
                          </option>
                        </select>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </div>

              <!-- LOADER BY ID -->
              <div
                class="re-loading"
                *ngIf="_sharedService.checkLoader(group?._id)"
              >
                <ngx-ui-loader
                  [loaderId]="group?._id"
                  [fgsType]="'three-bounce'"
                  [hasProgressBar]="false"
                  [overlayColor]="'#f2f2f2'"
                ></ngx-ui-loader>
              </div>

              <!-- ADD CUSTOM QUESTION -->
              <button
                type="button"
                class="btn re-add-custom-question"
                (click)="addEditCustomQuestion(group)"
              >
                Add Custom Question
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <!-------------------------------- Custom Preference End ------------------------------------->
  </div>
  <!-- PREFERENCE EDIT END-->
</div>
