import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thank-you-direct-mail',
  templateUrl: './thank-you-direct-mail.component.html',
  styleUrls: ['./thank-you-direct-mail.component.scss'],
})
export class ThankYouDirectMailComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    if (localStorage.getItem('isDirectMailFreeUserOrder') != 'true') {
      this.checkOrder();
    }
  }
  checkOrder() {
    localStorage.clear();
    location.href = 'https://resimpli.com/biggerpockets/';
  }
}
