import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseUrl } from '../../utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
@Injectable({
  providedIn: 'root',
})
export class SmsService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem('token');

  constructor(private _commonFunction: CommonFunctionsService) {}

  getSmsList = (data, webLink?): Observable<any> => {
    let endpoint = this.baseUrl;

    if (!webLink) {
      endpoint += BaseUrl.smsTemplateUrl + 'list';
    } else {
      endpoint += BaseUrl.webFormUrl + 'smsTemplateList';
    }
    return this._commonFunction.globalPostService(
      endpoint,
      data,
      webLink ? this.getRequestHeaders(webLink) : {}
    );
  };

  addSmsTemplate = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.smsTemplateUrl + 'save';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  changeTemplate = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.smsTemplateUrl + 'changeTemplate';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  editSmsTemplate = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.smsTemplateUrl + 'edit';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteSmsTemplate = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.smsTemplateUrl + 'delete';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  // SET HEADERS
  protected getRequestHeaders(
    val?
  ): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    let headers;
    let obj = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };

    if (val) {
      obj['webFormLink'] = localStorage.getItem('webFormLink') || val;
    }

    headers = new HttpHeaders(obj);
    return { headers: headers };
  }
} //Main class ends here
