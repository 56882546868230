<div class="re-modal">
  <div class="dialog-header">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between"
        style="display: block !important"
      >
        {{ this.data.action === "EDIT" ? "Update" : "Add an" }} Inquiry
      </h2>
      <span matDialogClose mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>

  <form
    autocomplete="off"
    [formGroup]="addEditInquiryForm"
    (ngSubmit)="addInquiry()"
    novalidate
  >
    <mat-dialog-content class="">
      <div class="container-fluid p-0">
        <div class="re-box">
          <div class="row">
            <!-- BUYER -->
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="re-label">
                  Select Buyer
                  <span class="text-danger">*</span>
                </label>

                <p-autoComplete
                  #autoComplete
                  class="re-add-buyer-name"
                  placeholder="Name"
                  name="buyerName"
                  [(ngModel)]="buyerName"
                  [ngModelOptions]="{ standalone: true }"
                  [suggestions]="filteredBuyers"
                  (completeMethod)="checkBuyer($event, 'name')"
                  [minLength]="1"
                  (onSelect)="selectItem($event)"
                  (input)="onChange($event)"
                  appendTo="body"
                >
                  <ng-template let-buyer pTemplate="item">
                    <div class="ui-helper-clearfix">
                      <div class="re-search-name">
                        {{ _utilities.capitalizeName(buyer?.firstName) }}
                        {{ _utilities.capitalizeName(buyer?.lastName) }} |
                        {{ buyer?.email }} |
                        {{ _utilities.maskNumber(buyer?.contactNumber) }}
                        {{ numberType(buyer) }}
                      </div>
                    </div>
                  </ng-template>
                </p-autoComplete>
              </div>
            </div>

            <!-- COMMENT -->
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="re-label">
                  COMMENT
                  <span class="text-danger">*</span>
                </label>

                <textarea
                  type="text"
                  class="form-control"
                  formControlName="comment"
                  rows="4"
                  placeholder="Please write down your note here..."
                  (input)="updateTextCount($event)"
                ></textarea>

                <div class="invalid-feedback re-comment-err">
                  <span>
                    <ng-container *ngIf="maxError()">
                      You can't use more than 400 characters.
                    </ng-container>
                  </span>
                  <span class="character-count">
                    {{ characterCount ? characterCount : 0 }} / 400
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <hr style="margin: 0 0 8px 0 !important" />

    <mat-dialog-actions align="center">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        cdkFocusInitial
        [disabled]="addEditInquiryForm.invalid"
      >
        {{ this.data.action === "EDIT" ? "Update" : "Add" }} Inquiry
      </button>
    </mat-dialog-actions>
  </form>
</div>
