// MODULES
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import * as moment from 'moment';
import { Router } from '@angular/router';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { LeadsService } from '../../../providers/leads/leads.service';
import { StatusConstant } from '../../../utils/status-constant';
import { SharedService } from '../../shared.service';
import { BugsnagService } from 'src/app/bugsnag.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

// COMPONENTS
import { SmsDialogComponent } from '../sms-dialog/sms-dialog.component';

// UTILS
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';
import { MessageConstant } from 'src/app/utils/message-constant';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { BaseUrl } from '../../../utils/base-url-constants';
import { TwilioService } from '../../../utils/twilio-service/twilio.service';
import { CreditService } from '../../../providers/credit/credit.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CallFlowService } from '../../../providers/call-flow/call-flow.service';
import { UpgradeAccountComponent } from '../upgrade-account/upgrade-account.component';
import {UserService} from "../../../providers/user/user.service";

declare var Plivo;
declare var Twilio: any;

@Component({
  selector: 'app-incoming-call-detail-dailogs',
  templateUrl: './incoming-call-detail-dailogs.component.html',
  styleUrls: ['./incoming-call-detail-dailogs.component.scss'],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class IncomingCallDetailDailogsComponent implements OnInit {
  callDetailForm: FormGroup;
  plivoBrowserSdk; //: Plivo;
  plivoAccountIndex: number = 0;
  options = {
    debug: 'ALL',
    permOnClick: true,
    enableTracking: true,
    closeProtection: true,
    maxAverageBitrate: 48000,
    allowMultipleIncomingCalls: true,
  };
  callDurationIncoming: string = '00:00:00';
  counterIncoming: any;
  callReady: boolean = false;
  muteCall: boolean = false;
  isCalling: boolean = false;
  submitted: boolean = false;
  messageConstant = MessageConstant;
  userId: string = '';
  leadSourceCountryCode: string = environment.countryCode;
  callBalance: number = 0;
  isMinimized: boolean = false;
  evt: any;
  isDialerOpen: boolean = false;
  isListStackingListOpen: boolean = false;
  isLeadListOpen: boolean = false;
  listopen: string = 'inline-block';
  dialedNumber: string = '';
  dialogRef: any;
  holdCall: boolean = false;
  listLimit: any = 3;
  tagLimit: any = 3;
  tagLength: any = [3, 3, 3, 3];
  listLength: any = [3, 3, 3, 3];
  mainStatus: any = [];
  mainStatusDetails: any = [];
  callUUID: any = '';
  currentCall: any = '';
  numberType: any;
  baseUrl = environment.baseUrl;

  mergeCallDialer: any = {
    firstCall: false,
    secondCall: false,
    callMerged: false,
    callEnded: true,
    callNotJoined: false,
    checkStatus: false,
    endCause: '',
  };
  callMerged: boolean = false;
  statusCheckFlag: boolean = false;
  addMemberCall = false;
  showMemberList = false;
  mergeCallBtn: boolean = true;
  multiCallUUID = '';
  multiCallEventName = '';
  mergeCallList = false;
  totalParticipant: any;
  memberList: any[] = [];
  mainMember: any;
  callStatus: string = '';
  teams: any = {};
  allTeams: any = {};
  searchedItem: any;
  callAnswerInterval: any;
  joinToMpcHeader: boolean = false;
  userPlan: any;
  isMarketReadonly: boolean = false;

  isTwilioCall: boolean = false;
  twilioCallSid: any;
  addToMpcHeader: any;
  conferenceId: any;
  calleeFrom: any;
  callInitiat: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _toastrService: ToastrService,
    private _sharedService: SharedService,
    public _utilities: CommonFunctionsService,
    private _dialog: MatDialog,
    private _bugsnagService: BugsnagService,
    private _route: Router,
    private _leadService: LeadsService,
    private incomingCallDetailDailog: MatDialogRef<IncomingCallDetailDailogsComponent>,
    private _twilioService: TwilioService,
    private creditService: CreditService,
    private _loaderService: NgxUiLoaderService,
    private _flowService: CallFlowService,
    private _userService: UserService,
  ) {
    if (data?.isCalling) {
      this.isCalling = data?.isCalling;
      this.numberType = data?.numberType;
    }
    this.getUser();
    this.getUserPlan();
    this.getTeam();
    this._twilioService.twilioIncomingCancelEvent = new Subject();
    this._twilioService.twilioIncomingCancelEvent.subscribe((response) => {
      if (response) {
        this.callUUID = response?.parameters?.CallSid;
        this.cancel({ direction: 'incoming' });
      }
    });
  }

  getName(address) {
    let name = '';
    let name1 =
      this.teams && this.teams.length > 0
        ? this.teams?.find((d) => d.contactNumber === '+' + address || d.contactNumber === address)
        : '';
    if (name1 && name1?.name !== '') {
      return name1?.name;
    } else {
      return 'Unknown Number';
    }
  }

  removeDuplicatesValue(originalArray, objKey) {
    let trimmedArray = [];
    let values = [];
    let value;

    for (let i = 0; i < originalArray.length; i++) {
      value = originalArray[i][objKey];

      if (values.indexOf(value) === -1) {
        trimmedArray.push(originalArray[i]);
        values.push(value);
      }
    }

    return trimmedArray;
  }
  getInitialMemberList() {
    if (this._utilities.isTwilioUser) {
      this._twilioService
        .twilioConferenceList({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
        })
        .subscribe((response: ResponseModel) => {
          this.memberList = response.data;
          this.mainMember = this.memberList.filter(
            (m) => m.label === 'Owner'
          )[0];
          this.totalParticipant = this.memberList.length;
          if (this.memberList && this.memberList.length > 0) {
            this.conferenceId = this.memberList[0].conferenceSid;
          }
        });
    } else {
      this._flowService
        .plivoConferenceList({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
        })
        .subscribe((response: ResponseModel) => {
          this.memberList = response.data.objects.filter(
            (d) => d.exitCause === null
          );
          this.memberList = this.removeDuplicatesValue(
            this.memberList,
            'memberAddress'
          );
          this.totalParticipant = this.memberList.length;
        });
    }
  }
  dropMembers(id) {
    if (this._utilities.isTwilioUser) {
      this._twilioService
        .dropMember({
          conferenceId: id.conferenceSid,
          callSid: id.callSid,
        })
        .subscribe((response: ResponseModel) => {
          //this.memberList = response.data.objects.filter((d) => d.exitCause === null);
          this.showMemberList = false;
          this.getInitialMemberList();
        });
    } else {
      this._flowService
        .dropMember({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
          memberId: id,
        })
        .subscribe((response: ResponseModel) => {
          //this.memberList = response.data.objects.filter((d) => d.exitCause === null);
          this.showMemberList = false;
          this.getInitialMemberList();
        });
    }
  }
  getTeam() {
    if (this.data && this.data?.leadDetails && this.data?.leadDetails[0]?._id) {
      let param = {
        page: 1,
        limit: 100,
      };
      this._userService.getSubUserList(param).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode && response.statusCode == 200) {
            this.teams = response.data.items;
            this.allTeams = response.data.items;
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
        }
      );
    }
  }
  getUser() {
    this._sharedService.getUser().subscribe(
      (response) => {
        if (response.code == 200) {
          this.userId = response.data?.userId;
          this.initializePlivo();
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getUserPlan() {
    this.creditService.getCallCredit({}).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.callBalance = response.data;
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
    this.creditService.getUserPlan({}).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.userPlan = response.data?.planName?.toLowerCase();
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  selectUser(user) {
    this.dialedNumber = user.contactNumber;
  }
  addMemberData() {
    if (
      this.userPlan === 'enterprise' ||
      this.userPlan === 'pro' ||
      this.userPlan === 'premium' ||
      this.userPlan === 'enterprise existing'
    ) {
      this.addMemberCall = true;
      this.teams = this.allTeams;
      this.dialedNumber = '';
    } else {
      this._dialog.open(UpgradeAccountComponent, {
        disableClose: true,
        width: '350px',
        data: {
          link: '',
        },
      });
    }
  }

  async addMember(data) {
    let jointoMpc;
    jointoMpc = this.joinToMpcHeader;
    if (!this.joinToMpcHeader) {
      this.joinToMpcHeader = true;
    }
    const toNumber = environment.countryCode + this.dialedNumber;
    //const toNumber = '+12195010032';
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (this._utilities.isTwilioUser) {
      this.statusCheckFlag = false;
      this.callInitiat = true;
      this.holdCall = true;
      let startTime = new Date().getTime();

      this.addMemberCall = false;
      const params = {
        EventName: this.multiCallEventName,
        MPCUUID: this.multiCallUUID,
        toNumber: toNumber,
        fromNumber: this.calleeFrom,
        token: localStorage.getItem('token'),
        memberList: this.memberList || [],
        mergeCallDialer: {
          firstCall: this.mergeCallDialer?.firstCall
            ? this.mergeCallDialer.firstCall
            : false,
          secondCall: this.mergeCallDialer?.secondCall
            ? this.mergeCallDialer.secondCall
            : false,
          callMerged: this.mergeCallDialer?.callMerged
            ? this.mergeCallDialer.callMerged
            : false,
          callEnded: this.mergeCallDialer?.callEnded
            ? this.mergeCallDialer.callEnded
            : true,
          callNotJoined: this.mergeCallDialer?.callNotJoined
            ? this.mergeCallDialer.callNotJoined
            : false,
        },
        uname: user.eUname,
        addToMpcHeader: this.addToMpcHeader,
        jointoMpc: jointoMpc,
        callUUID: this.callUUID,
      };
      const name = this.callUUID;
      const memberInterval = setInterval(() => {
        if (this.statusCheckFlag === false) {
          this._flowService.getCallInfoIncoming({ name }).subscribe(
            (response: ResponseModel) => {
              this.mergeCallDialer = response.data.users;
              this.mergeCallDialer.callNotJoined = true;
              this.statusCheckFlag = response.data.isCheckStatus;
              if (this.statusCheckFlag === true) {
                this.callInitiat = false;
                this.getInitialMemberList();
                if (memberInterval) {
                  clearInterval(memberInterval);
                }
              }
            },
            (err: ErrorModel) => {
              this._loaderService.stop();
            }
          );
          if (new Date().getTime() - startTime > 30000) {
            this.statusCheckFlag = true;
            this.callInitiat = false;
            clearInterval(memberInterval);
            if (this.mergeCallDialer.secondCall === false) {
              setTimeout(() => {
                this.callMerge();
              }, 2000);
            } else {
              this.allHoldUnholdMember({
                hold: false,
                mute: false,
              });
            }
          }
        }
      }, 1000);
      try {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        const Obj = {
          to: toNumber,
          callUUID: this.callUUID,
          type: 'mergeCall',
          subType: 'add',
        };
        await this._twilioService.listeningConferenceIncoming(Obj).subscribe(
          (response: ResponseModel) => {},
          (err: ErrorModel) => {
            this._loaderService.stop();
          }
        );
      } catch (e) {
        console.log('e', e);
      }
    } else {
      try {
        this.statusCheckFlag = false;
        this.holdCall = true;
        let startTime = new Date().getTime();

        this.addMemberCall = false;
        const params = {
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
          toNumber: toNumber,
          token: localStorage.getItem('token'),
          memberList: this.memberList || [],
          mergeCallDialer: {
            firstCall: this.mergeCallDialer?.firstCall
              ? this.mergeCallDialer.firstCall
              : false,
            secondCall: this.mergeCallDialer?.secondCall
              ? this.mergeCallDialer.secondCall
              : false,
            callMerged: this.mergeCallDialer?.callMerged
              ? this.mergeCallDialer.callMerged
              : false,
            callEnded: this.mergeCallDialer?.callEnded
              ? this.mergeCallDialer.callEnded
              : true,
            callNotJoined: this.mergeCallDialer?.callNotJoined
              ? this.mergeCallDialer.callNotJoined
              : false,
          },
          uname: user.eUname,
          addToMpcHeader: this.addToMpcHeader,
          jointoMpc: jointoMpc,
          callUUID: this.callUUID,
        };
        const name = this.multiCallEventName.replace('+', 'Resimpli');
        const memberInterval = setInterval(() => {
          if (this.statusCheckFlag === false) {
            this._flowService.getCallInfoIncoming({ name }).subscribe(
              (response: ResponseModel) => {
                this.mergeCallDialer = response.data.users;
                this.mergeCallDialer.callNotJoined = true;
                this.statusCheckFlag = response.data.isCheckStatus;
                if (this.statusCheckFlag === true) {
                  this.getInitialMemberList();
                  if (memberInterval) {
                    clearInterval(memberInterval);
                  }
                }
              },
              (err: ErrorModel) => {
                this._loaderService.stop();
              }
            );
            if (new Date().getTime() - startTime > 30000) {
              this.statusCheckFlag = true;
              clearInterval(memberInterval);
              this.allHoldUnholdMember({
                hold: false,
                mute: false,
              });
            }
          }
        }, 1000);
        if (this.mergeCallDialer.secondCall === false) {
          if (this.plivoBrowserSdk.client.callUUID && this.muteCall) {
            this.muteCall = false;
            this.plivoBrowserSdk.client.unmute();
          }
          if (this.plivoBrowserSdk.client.callUUID && this.holdCall) {
            let obj = { uuid: this.plivoBrowserSdk.client.callUUID };
            this._sharedService.unHoldCallFn(obj).subscribe(
              (response) => {
                if (response.statusCode == 200) {
                  //this.holdCall = false;
                  this._flowService
                    .flowConferenceAddParticipant(params)
                    .subscribe(
                      (response: ResponseModel) => {},
                      (err: ErrorModel) => {
                        this._loaderService.stop();
                      }
                    );
                }
              },
              (err: ErrorModel) => {
                if (err.error) {
                  const error: ResponseModel = err.error;
                  this._toastrService.error(error.message, '');
                } else {
                  this._toastrService.error(
                    this.messageConstant.unknownError,
                    ''
                  );
                }
              }
            );
          } else {
            await this._flowService
              .flowConferenceAddParticipant(params)
              .subscribe(
                (response: ResponseModel) => {},
                (err: ErrorModel) => {
                  this._loaderService.stop();
                }
              );
          }
        } else {
          await this._flowService
            .flowConferenceAddParticipant(params)
            .subscribe(
              (response: ResponseModel) => {},
              (err: ErrorModel) => {
                this._loaderService.stop();
              }
            );
        }
      } catch (e) {
        console.log('e', e);
      }
    }
  }

  async addMemberByContact(data) {
    let jointoMpc;
    jointoMpc = this.joinToMpcHeader;
    if (!this.joinToMpcHeader) {
      this.joinToMpcHeader = true;
    }

    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (this._utilities.isTwilioUser) {
      this.statusCheckFlag = false;
      this.callInitiat = true;
      this.holdCall = true;
      let startTime = new Date().getTime();

      this.addMemberCall = false;
      const params = {
        EventName: this.multiCallEventName,
        MPCUUID: this.multiCallUUID,
        toNumber: this.dialedNumber,
        fromNumber: this.calleeFrom,
        token: localStorage.getItem('token'),
        memberList: this.memberList || [],
        mergeCallDialer: {
          firstCall: this.mergeCallDialer?.firstCall
            ? this.mergeCallDialer.firstCall
            : false,
          secondCall: this.mergeCallDialer?.secondCall
            ? this.mergeCallDialer.secondCall
            : false,
          callMerged: this.mergeCallDialer?.callMerged
            ? this.mergeCallDialer.callMerged
            : false,
          callEnded: this.mergeCallDialer?.callEnded
            ? this.mergeCallDialer.callEnded
            : true,
          callNotJoined: this.mergeCallDialer?.callNotJoined
            ? this.mergeCallDialer.callNotJoined
            : false,
        },
        uname: user.eUname,
        addToMpcHeader: this.addToMpcHeader,
        jointoMpc: jointoMpc,
        callUUID: this.callUUID,
      };
      const name = this.callUUID;
      const memberInterval = setInterval(() => {
        if (this.statusCheckFlag === false) {
          this._flowService.getCallInfoIncoming({ name }).subscribe(
            (response: ResponseModel) => {
              this.mergeCallDialer = response.data.users;
              this.mergeCallDialer.callNotJoined = true;
              this.statusCheckFlag = response.data.isCheckStatus;
              if (this.statusCheckFlag === true) {
                this.callInitiat = false;
                this.getInitialMemberList();
                if (memberInterval) {
                  clearInterval(memberInterval);
                }
              }
            },
            (err: ErrorModel) => {
              this._loaderService.stop();
            }
          );
          if (new Date().getTime() - startTime > 30000) {
            this.statusCheckFlag = true;
            this.callInitiat = false;
            clearInterval(memberInterval);
            if (this.mergeCallDialer.secondCall === false) {
              this.statusCheckFlag = true;
              setTimeout(() => {
                this.callMerge();
              }, 2000);
            } else {
              this.allHoldUnholdMember({
                hold: false,
                mute: false,
              });
            }
          }
        }
      }, 1000);
      try {
        const user = JSON.parse(localStorage.getItem('currentUser'));

        const Obj = {
          to: this.dialedNumber,

          callUUID: this.callUUID,

          type: 'mergeCall',

          subType: 'add',
        };
        await this._twilioService.listeningConferenceIncoming(params).subscribe(
          (response: ResponseModel) => {},
          (err: ErrorModel) => {
            this._loaderService.stop();
          }
        );
      } catch (e) {
        console.log('e', e);
      }
    } else {
      try {
        this.statusCheckFlag = false;
        this.addMemberCall = false;
        this.holdCall = true;
        let startTime = new Date().getTime();
        const params = {
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
          toNumber: this.dialedNumber,
          token: localStorage.getItem('token'),
          memberList: this.memberList || [],
          mergeCallDialer: {
            firstCall: this.mergeCallDialer?.firstCall
              ? this.mergeCallDialer.firstCall
              : false,
            secondCall: this.mergeCallDialer?.secondCall
              ? this.mergeCallDialer.secondCall
              : false,
            callMerged: this.mergeCallDialer?.callMerged
              ? this.mergeCallDialer.callMerged
              : false,
            callEnded: this.mergeCallDialer?.callEnded
              ? this.mergeCallDialer.callEnded
              : true,
            callNotJoined: this.mergeCallDialer?.callNotJoined
              ? this.mergeCallDialer.callNotJoined
              : false,
          },
          uname: user.eUname,
          addToMpcHeader: this.addToMpcHeader,
          callUUID: this.callUUID,
          jointoMpc: jointoMpc,
        };

        this.statusCheckFlag = false;
        const name = this.multiCallEventName.replace('+', 'Resimpli');

        const memberInter = setInterval(() => {
          if (this.statusCheckFlag === false) {
            this._flowService.getCallInfoIncoming({ name }).subscribe(
              (response: ResponseModel) => {
                this.mergeCallDialer = response.data.users;
                this.mergeCallDialer.callNotJoined = true;
                this.statusCheckFlag = response.data.isCheckStatus;
                if (this.statusCheckFlag === true) {
                  this.getInitialMemberList();
                  if (memberInter) {
                    clearInterval(memberInter);
                  }
                }
              },
              (err: ErrorModel) => {
                this._loaderService.stop();
              }
            );
            if (new Date().getTime() - startTime > 30000) {
              this.statusCheckFlag = true;
              clearInterval(memberInter);
              this.allHoldUnholdMember({
                hold: false,
                mute: false,
              });
            }
          }
        }, 1000);
        if (this.mergeCallDialer.secondCall === false) {
          if (this.plivoBrowserSdk.client.callUUID && this.muteCall) {
            this.muteCall = false;
            this.plivoBrowserSdk.client.unmute();
          }
          if (this.plivoBrowserSdk.client.callUUID && this.holdCall) {
            let obj = { uuid: this.plivoBrowserSdk.client.callUUID };
            this._sharedService.unHoldCallFn(obj).subscribe(
              (response) => {
                if (response.statusCode == 200) {
                  //this.holdCall = false;
                  this._flowService
                    .flowConferenceAddParticipant(params)
                    .subscribe(
                      (response: ResponseModel) => {},
                      (err: ErrorModel) => {
                        this._loaderService.stop();
                      }
                    );
                }
              },
              (err: ErrorModel) => {
                if (err.error) {
                  const error: ResponseModel = err.error;
                  this._toastrService.error(error.message, '');
                } else {
                  this._toastrService.error(
                    this.messageConstant.unknownError,
                    ''
                  );
                }
              }
            );
          } else {
            await this._flowService
              .flowConferenceAddParticipant(params)
              .subscribe(
                (response: ResponseModel) => {},
                (err: ErrorModel) => {
                  this._loaderService.stop();
                }
              );
          }
        } else {
          await this._flowService
            .flowConferenceAddParticipant(params)
            .subscribe(
              (response: ResponseModel) => {},
              (err: ErrorModel) => {
                this._loaderService.stop();
              }
            );
        }
      } catch (e) {
        console.log('e', e);
      }
    }
  }

  callMerge() {
    this.statusCheckFlag = true;
    this.holdCall = false;
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (this._utilities.isTwilioUser) {
      this._twilioService
        .twilioConferenceMergeParticipant({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
          memberList: this.memberList || [],
          uname: user.eUname,
          callUuid: this.callUUID,
        })
        .subscribe((response: ResponseModel) => {
          this.mergeCallDialer.callMerged = true;
          this.statusCheckFlag = true;
          //this.getInitialMemberList();
          this.getMemberList();
          this.allHoldUnholdMember({
            hold: false,
            mute: false,
          });
        });
    } else {
      this._flowService
        .flowConferenceMergeParticipant({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
          memberList: this.memberList || [],
          uname: user.eUname,
        })
        .subscribe((response: ResponseModel) => {
          this.mergeCallDialer.callMerged = true;
          this.statusCheckFlag = true;
        });
    }
  }

  getMemberList() {
    if (this._utilities.isTwilioUser) {
      this._twilioService
        .twilioConferenceList({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
        })
        .subscribe((response: ResponseModel) => {
          this.memberList = response.data;
          this.mainMember = this.memberList.filter(
            (m) => m.label === 'Owner'
          )[0];
          this.showMemberList = true;
          this.totalParticipant = this.memberList.length;
        });
    } else {
      this._flowService
        .plivoConferenceList({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
        })
        .subscribe((response: ResponseModel) => {
          this.memberList = response.data.objects.filter(
            (d) => d.exitCause === null
          );
          this.memberList = this.removeDuplicatesValue(
            this.memberList,
            'memberAddress'
          );
          this.mainMember = this.memberList[this.memberList.length - 1];
          this.showMemberList = true;
          this.totalParticipant = this.memberList.length;
        });
    }
  }

  allHoldUnholdMember(data) {
    this.holdCall = data.hold;
    this.muteCall = data.mute;
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (this._utilities.isTwilioUser) {
      this._twilioService
        .allHoldUnholdMember({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
          conferenceId: this.conferenceId,
          allHold: data.hold,
          allMute: data.mute,
          memberList: this.memberList || [],
          mergeCallDialer: {
            firstCall: this.mergeCallDialer?.firstCall
              ? this.mergeCallDialer.firstCall
              : false,
            secondCall: this.mergeCallDialer?.secondCall
              ? this.mergeCallDialer.secondCall
              : false,
            callMerged: this.mergeCallDialer?.callMerged
              ? this.mergeCallDialer.callMerged
              : false,
            callEnded: this.mergeCallDialer?.callEnded
              ? this.mergeCallDialer.callEnded
              : true,
            callNotJoined: this.mergeCallDialer?.callNotJoined
              ? this.mergeCallDialer.callNotJoined
              : false,
          },
          uname: user.eUname,
        })
        .subscribe((response: ResponseModel) => {});
    } else {
      this._flowService
        .allHoldUnholdMember({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
          allHold: data.hold,
          allMute: data.mute,
          memberList: this.memberList || [],
          mergeCallDialer: {
            firstCall: this.mergeCallDialer?.firstCall
              ? this.mergeCallDialer.firstCall
              : false,
            secondCall: this.mergeCallDialer?.secondCall
              ? this.mergeCallDialer.secondCall
              : false,
            callMerged: this.mergeCallDialer?.callMerged
              ? this.mergeCallDialer.callMerged
              : false,
            callEnded: this.mergeCallDialer?.callEnded
              ? this.mergeCallDialer.callEnded
              : true,
            callNotJoined: this.mergeCallDialer?.callNotJoined
              ? this.mergeCallDialer.callNotJoined
              : false,
          },
          uname: user.eUname,
        })
        .subscribe((response: ResponseModel) => {});
    }
  }

  onNumberType(value) {
    const type = this._utilities.numberType(value?.toLowerCase());
    return type;
  }
  searchByFilter(data) {
    const re = RegExp(
      `.*${data.target.value.toLowerCase().split('').join('.*')}.*`
    );
    this.teams = this.allTeams.filter(
      (v) =>
        v.firstName.toLowerCase().match(re) ||
        v.lastName.toLowerCase().match(re)
    );
    if (this.teams && this.teams.length === 0) {
      this.dialedNumber = '';
    }
  }

  getNumber(address) {
    if (
      address.startsWith('Listening:') ||
      address.startsWith('Agent:') ||
      address.startsWith('New Participant:')
    ) {
      return 'Team Member';
    } else {
      return this._utilities.maskNumber(address);
    }
  }
  openCloseLead(bool) {
    this.isLeadListOpen = bool;
    this.isListStackingListOpen = bool ? !bool : this.isListStackingListOpen;
    if (bool) {
      this.listopen = 'none';
    } else {
      this.listopen = 'inline-block';
    }
  }
  openCloseListStacking(bool) {
    this.isListStackingListOpen = bool;
    this.isLeadListOpen = bool ? !bool : this.isLeadListOpen;
    if (bool) {
      this.listopen = 'none';
    } else {
      this.listopen = 'inline-block';
    }
  }

  onFullList(bool, i) {
    this.listLimit = bool
      ? this.data?.listStackingDetails[i].ListId?.length
      : 3;
    this.listLength[i] = bool
      ? this.data?.listStackingDetails[i].ListId?.length
      : 3;
  }

  onFullTagList(bool, i) {
    this.tagLimit = bool ? this.data?.listStackingDetails[i].TagId?.length : 3;
    this.tagLength[i] = bool
      ? this.data?.listStackingDetails[i].TagId?.length
      : 3;
  }

  ngOnInit(): void {
    this.initializePlivo();
  }

  getMainStatus(d, i) {
    if (d.mainStatusId == StatusConstant.LeadStatus.NEW_LEAD) {
      this.mainStatus[i] = 'New Leads';
      this.mainStatusDetails[i] = 'Go To Lead Detail';
    } else if (d.mainStatusId == StatusConstant.LeadStatus.NO_CONTACT_MADE) {
      this.mainStatus[i] = 'No Contact Made';
      this.mainStatusDetails[i] = 'Go To Lead Detail';
    } else if (d.mainStatusId == StatusConstant.LeadStatus.CONTACT_MADE) {
      this.mainStatus[i] = 'Contact Made';
      this.mainStatusDetails[i] = 'Go To Lead Detail';
    } else if (d.mainStatusId == StatusConstant.LeadStatus.APPOINTMENT_SET) {
      this.mainStatus[i] = 'Appointments Set';
      this.mainStatusDetails[i] = 'Go To Lead Detail';
    } else if (d.mainStatusId == StatusConstant.LeadStatus.DUE_DILIGENCE) {
      this.mainStatus[i] = 'Due Diligence';
      this.mainStatusDetails[i] = 'Go To Lead Detail';
    } else if (d.mainStatusId == StatusConstant.LeadStatus.OFFER_MADE) {
      this.mainStatus[i] = 'Offers Made';
      this.mainStatusDetails[i] = 'Go To Lead Detail';
    } else if (d.mainStatusId == StatusConstant.LeadStatus.UNDER_CONTRACT) {
      this.mainStatus[i] = 'Under Contract';
      this.mainStatusDetails[i] = 'Go To Lead Detail';
    } else if (d.mainStatusId == StatusConstant.LeadStatus.ASSIGN_TO_BUYER) {
      this.mainStatus[i] = 'Assigned To Buyer';
      this.mainStatusDetails[i] = 'Go To Lead Detail';
    } else if (d.mainStatusId == StatusConstant.LeadStatus.WARM_LEAD) {
      this.mainStatus[i] = 'Warm Lead';
      this.mainStatusDetails[i] = 'Go To Lead Detail';
    } else if (d.mainStatusId == StatusConstant.LeadStatus.DEAD_LEAD) {
      this.mainStatus[i] = 'Dead Lead';
      this.mainStatusDetails[i] = 'Go To Lead Detail';
    } else if (d.mainStatusId == StatusConstant.LeadStatus.REFERRED_TO_AGENT) {
      this.mainStatus[i] = 'Referred To Agent';
      this.mainStatusDetails[i] = 'Go To Lead Detail';
    } else if (d.mainStatusId == StatusConstant.InventoryStatus.SOLD) {
      this.mainStatus[i] = 'Sold';
      this.mainStatusDetails[i] = 'Go To Sold Detail';
    } else if (d.mainStatusId == StatusConstant.InventoryStatus.RENTAL) {
      this.mainStatus[i] = 'Rental';
      this.mainStatusDetails[i] = 'Go To Rental Detail';
    } else if (d.mainStatusId == StatusConstant.InventoryStatus.NEW_INVENTORY) {
      this.mainStatus[i] = 'New Inventory';
      this.mainStatusDetails[i] = 'Go To Inventory Detail';
    } else if (
      d.mainStatusId == StatusConstant.InventoryStatus.GETTING_ESTIMATES
    ) {
      this.mainStatus[i] = 'Getting Estimates';
      this.mainStatusDetails[i] = 'Go To Inventory Detail';
    } else if (d.mainStatusId == StatusConstant.InventoryStatus.UNDER_REHAB) {
      this.mainStatus[i] = 'Under Rehab';
      this.mainStatusDetails[i] = 'Go To Inventory Detail';
    } else if (
      d.mainStatusId == StatusConstant.InventoryStatus.LISTED_FOR_RENT
    ) {
      this.mainStatus[i] = 'Listed For Rent';
      this.mainStatusDetails[i] = 'Go To Rental Detail';
    } else if (
      d.mainStatusId == StatusConstant.InventoryStatus.LISTED_FOR_SALE
    ) {
      this.mainStatus[i] = 'Listed For Sale';
      this.mainStatusDetails[i] = 'Go To Sold Detail';
    } else if (
      d.mainStatusId == StatusConstant.InventoryStatus.SELL_SIDE_UNDER_CONTRACT
    ) {
      this.mainStatus[i] = 'Under Contract';
      this.mainStatusDetails[i] = 'Go To Sold Detail';
    } else if (
      d.mainStatusId == StatusConstant.InventoryStatus.SELL_SIDE_UNDER_CONTRACT
    ) {
      this.mainStatus[i] = 'Under Contract';
      this.mainStatusDetails[i] = 'Go To Sold Detail';
    } else if (!this.data?.leadDetails && this.data?.listStackingDetails) {
      this.mainStatus[i] = 'List Stacking';
      this.mainStatusDetails[i] = 'View List Detail';
    } else {
      this.mainStatus[i] = '';
      this.mainStatusDetails[i] = '';
    }
    return this.mainStatus[i];
  }

  get leadSource() {
    return this.callDetailForm.get('leadSource');
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.callDetailForm.controls[controlName].hasError(errorName);
  };

  goToLeadDetails(id, i = 0) {
    if (this.isMinimized) {
      if (document.querySelector('.popup-Backdrop-Class-dialer-incoming'))
        document.querySelector('.popup-Backdrop-Class-dialer-incoming')[
          'style'
        ].display = 'block';

      if (document.querySelector('.re-incomingcall-modal')) {
        document.querySelector('.re-incomingcall-modal')['style'].position =
          'relative';
        document.querySelector('.re-incomingcall-modal')['style'].height =
          'auto';
        document.querySelector('.re-incomingcall-modal')['style'].transform =
          '';
        document
          .querySelector('.re-incomingcall-modal')
          .classList.remove('re-lead-dialer-minimize-ss');
      }
    } else {
      if (document.querySelector('.popup-Backdrop-Class-dialer-incoming'))
        document.querySelector('.popup-Backdrop-Class-dialer-incoming')[
          'style'
        ].display = 'none';
      if (document.querySelector('.re-incomingcall-modal')) {
        document.querySelector('.re-incomingcall-modal')['style'].position =
          'absolute';
        document
          .querySelector('.re-incomingcall-modal')
          .classList.add('re-lead-dialer-minimize-ss');
      }
      this.reset();
    }
    this.isMinimized = !this.isMinimized;
    if (id) {
      this.goToDetails({
        _id: id,
        mainStatusId: this.data.leadDetails[i].mainStatusId,
      });
    } else {
      this.isMinimized = false;
      this._toastrService.warning('No Lead Details Available.');
    }
  }

  goToBuyersLeadDetails(id, i = 0, statusid) {
    if (this.isMinimized) {
      if (document.querySelector('.popup-Backdrop-Class-dialer-incoming'))
        document.querySelector('.popup-Backdrop-Class-dialer-incoming')[
          'style'
        ].display = 'block';

      if (document.querySelector('.re-incomingcall-modal')) {
        document.querySelector('.re-incomingcall-modal')['style'].position =
          'relative';
        document.querySelector('.re-incomingcall-modal')['style'].height =
          'auto';
        document.querySelector('.re-incomingcall-modal')['style'].transform =
          '';
        document
          .querySelector('.re-incomingcall-modal')
          .classList.remove('re-lead-dialer-minimize-ss');
      }
    } else {
      if (document.querySelector('.popup-Backdrop-Class-dialer-incoming'))
        document.querySelector('.popup-Backdrop-Class-dialer-incoming')[
          'style'
        ].display = 'none';
      if (document.querySelector('.re-incomingcall-modal')) {
        document.querySelector('.re-incomingcall-modal')['style'].position =
          'absolute';
        document
          .querySelector('.re-incomingcall-modal')
          .classList.add('re-lead-dialer-minimize-ss');
      }
      this.reset();
    }
    this.isMinimized = !this.isMinimized;
    if (id) {
      this.goToDetails({
        _id: id,
        mainStatusId: statusid,
      });
    } else {
      this.isMinimized = false;
      this._toastrService.warning('No Lead Details Available.');
    }
  }
  goToBuyerDetails(_id) {
    if (!_id) {
      return;
    }
    this._route.navigateByUrl(`/buyers/details?buyersId=${_id}&contact=1`);
  }
  goToDetails({ _id, mainStatusId }) {
    if (!_id) {
      return;
    }
    const obj = { leadid: _id };

    localStorage.setItem('toplead', 'true');
    localStorage.setItem('topappoint', 'true');

    if (
      mainStatusId === '5feb3f94aa810a3849fa5515' ||
      mainStatusId === '5feb4221aa810a3849fa551b' ||
      mainStatusId === '5feb4252aa810a3849fa551c' ||
      mainStatusId === '60221e37d8562ef0219c072a'
    ) {
      this._leadService.getProjectWithLeadId(obj).subscribe(
        (response: ResponseModel) => {
          if (!response.data?.items) {
            this._route.navigate([`/inventory`]);
            return;
          }

          const { mainStatusId, _id } = response.data?.items;
          if (
            mainStatusId == '60225879d8562ef021ca2fcd' ||
            mainStatusId == '5feb4221aa810a3849fa551b'
          ) {
            this._route.navigate([`/sold/details`], {
              queryParams: { ['soldId']: _id },
            });
          } else if (
            mainStatusId == '60225867d8562ef021ca2180' ||
            mainStatusId == '5feb4252aa810a3849fa551c'
          ) {
            this._route.navigate([`/rental/details`], {
              queryParams: { ['rentalId']: _id },
            });
          } else {
            this._route.navigate([`/inventory/details`], {
              queryParams: { ['inventoryId']: _id },
            });
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
    } else {
      const key = 'leadsId';
      this._route.navigate([`/leads/details`], {
        queryParams: { [key]: _id },
      });
    }
  }

  initializePlivo() {
    this.plivoBrowserSdk = new Plivo(this.options);

    this.plivoBrowserSdk.client.on('onLogout', () => {});
    this.plivoBrowserSdk.client.on('onLoginFailed', (cause) => {
      console.log('onLoginFailed cause =>', cause);
      this._bugsnagService.upload({ 'onLoginFailed =>': cause }, 'error');
      window.location.reload();
    });

    this.plivoBrowserSdk.client.on('onIncomingCallIgnored', () => {});
    this.plivoBrowserSdk.client.on('onCallRemoteRinging', () => {});
    this.plivoBrowserSdk.client.on(
      'onIncomingCallCanceled',
      (hangupInfo, callInfo) => {
        if (hangupInfo.callUUID === this.currentCall) {
          this.cancel({ direction: 'incoming' });
        }
      }
    );

    this.plivoBrowserSdk.client.on('onCallFailed', (cause, callInfo) => {
      this._toastrService.clear();
      this._bugsnagService.upload(
        { 'onCallFailed =>': cause, 'callInfo=>': callInfo },
        'error'
      );
      if (cause == 'Not Found') {
        this._toastrService.error(this.messageConstant.callNotFoundMessage, '');
      } else if (cause == 'Canceled') {
        this._toastrService.info(this.messageConstant.callCancelledMessage, '');
      } else if (cause == 'Busy') {
        this._toastrService.info(this.messageConstant.callBusyMessage, '');
      } else {
        this._toastrService.warning(this.messageConstant.callNotConnected, '');
      }

      setTimeout(() => {
        this._sharedService.refreshActivityLog.next(true);
      }, 2000);

      this.cancel({ direction: 'incoming' });
    });
    this.plivoBrowserSdk.client.on('onCallAnswered', () => {
      this.callReady = true;
      let a = moment();
      let b = moment();
      this.counterIncoming = setInterval(() => {
        this.showTime(a, b);
      }, 1000);
    });
    this.plivoBrowserSdk.client.on('onMediaConnected', () => {});
    this.plivoBrowserSdk.client.on(
      'onCallTerminated',
      (hangupInfo, callInfo) => {
        this._bugsnagService.upload(
          { 'hangupInfo=>': hangupInfo, callInfo: callInfo },
          'info'
        );

        if (this.isCalling) {
          this._toastrService.clear();

          this._toastrService.success(this.messageConstant.callCompleted);
          setTimeout(() => {
            this._sharedService.refreshActivityLog.next(true);
          }, 2000);
        }

        if (this.counterIncoming) {
          clearInterval(this.counterIncoming);
        }
        if (callInfo.direction === 'incoming') {
          this.cancel({ direction: 'incoming' });
        }
      }
    );
    this.plivoBrowserSdk.client.on('onCalling', () => {});
    this.plivoBrowserSdk.client.on('onIncomingCall', () => {});
    this.plivoBrowserSdk.client.on('onMediaPermission', (event) => {
      this._bugsnagService.upload({ 'event =>': event }, 'error');
    });
    this.plivoBrowserSdk.client.on('mediaMetrics', () => {});
    this.plivoBrowserSdk.client.on('onConnectionChange', () => {});
  }

  showTime(dateA, dateB) {
    dateB.add(1, 'seconds');
    this.callDurationIncoming = moment
      .utc(
        moment(dateB, 'DD/MM/YYYY HH:mm:ss').diff(
          moment(dateA, 'DD/MM/YYYY HH:mm:ss')
        )
      )
      .format('HH:mm:ss');
  }

  mute() {
    this.muteCall = true;
    if (this.data.isTwilio) {
      this._twilioService.twilioCallIncomingConnection.mute(true);
    } else {
      if (this.plivoBrowserSdk.client.callSession) {
        this.plivoBrowserSdk.client.mute();
      }
    }
  }

  unmute() {
    this.muteCall = false;
    if (this.data.isTwilio) {
      this._twilioService.twilioCallIncomingConnection.mute(false);
    } else {
      if (this.plivoBrowserSdk.client.callSession) {
        this.plivoBrowserSdk.client.unmute();
      }
    }
  }

  async cancel(callInfo) {
    if (this.holdCall) {
      this.unHold(true);
      return;
    }
    this._utilities.callTypeReject = 'true';
    if (callInfo.direction === 'incoming') {
      if (this.data.isTwilio) {
        this._twilioService
          .getCallConfInfo({
            callUuid: this.callUUID,
          })
          .subscribe((response: ResponseModel) => {
            if (
              response &&
              response.data &&
              response.data.conferenceSid !== '' &&
              response.data.conferenceSid !== undefined
            ) {
              this._twilioService
                .dropMember({
                  conferenceId: response.data.conferenceSid,
                  callSid: this.callUUID,
                })
                .subscribe((responses: ResponseModel) => {
                  console.log('call droped', responses);
                });
            } else {
              this._twilioService.twilioCallIncomingConnection.disconnect();
            }
          });

        if (this._utilities.numberOfCallsRunning > 0) {
          this._utilities.numberOfCallsRunning--;
        }
        setTimeout(() => {
          this._sharedService.refreshActivityLog.next(true);
        }, 2000);
        this.isCalling = false;
        this.callReady = false;

        if (this.isMinimized) this.toggleDisplay();
        this.incomingCallDetailDailog.close(this.data.parameters.CallSid);
        setTimeout(() => {
          this._utilities.callType = 'outgoing';
          this._utilities.callTypeReject = 'false';
        }, 1000);
      } else {
        if (
          this.plivoBrowserSdk.client.callSession &&
          this.data.callInfo.callUUID === this.callUUID
        ) {
          this.plivoBrowserSdk.client.reject(this.data.callInfo.callUUID);
          if (callInfo && callInfo.reason === 'hangup') {
            this.plivoBrowserSdk.client.hangup();
            if (this._utilities.numberOfCallsRunning > 0) {
              this._utilities.numberOfCallsRunning--;
            }
          }
        } else {
          //this._utilities.callTypeReject = 'true';
          this.plivoBrowserSdk.client.reject(this.data.callInfo.callUUID);
          setTimeout(() => {
            this._utilities.callType = 'outgoing';
            this._utilities.callTypeReject = 'false';
          }, 1000);
        }
        setTimeout(() => {
          this._sharedService.refreshActivityLog.next(true);
        }, 2000);
        this.isCalling = false;
        this.callReady = false;

        if (this.isMinimized) this.toggleDisplay();
        this.incomingCallDetailDailog.close(this.data.callInfo.callUUID);
        setTimeout(() => {
          this._utilities.callType = 'outgoing';
          this._utilities.callTypeReject = 'false';
        }, 1000);
      }
    }
  }
  rejectCall(callInfo) {
    this._utilities.callTypeReject = 'true';
    if (callInfo.direction === 'incoming') {
      if (this.data.isTwilio) {
        this._twilioService.twilioCallIncomingConnection.reject();
        if (this._utilities.numberOfCallsRunning > 0) {
          this._utilities.numberOfCallsRunning--;
        }
        setTimeout(() => {
          this._sharedService.refreshActivityLog.next(true);
        }, 2000);
        this.isCalling = false;
        this.callReady = false;

        if (this.isMinimized) this.toggleDisplay();
        this.incomingCallDetailDailog.close(this.data.parameters.CallSid);
        setTimeout(() => {
          this._utilities.callType = 'outgoing';
          this._utilities.callTypeReject = 'false';
        }, 1000);
      } else {
        if (
          this.plivoBrowserSdk.client.callSession &&
          this.data.callInfo.callUUID === this.callUUID
        ) {
          this.plivoBrowserSdk.client.reject(this.data.callInfo.callUUID);
          if (callInfo && callInfo.reason === 'hangup') {
            this.plivoBrowserSdk.client.hangup();
            if (this._utilities.numberOfCallsRunning > 0) {
              this._utilities.numberOfCallsRunning--;
            }
          }
        } else {
          //this._utilities.callTypeReject = 'true';
          this.plivoBrowserSdk.client.reject(this.data.callInfo.callUUID);
          setTimeout(() => {
            this._utilities.callType = 'outgoing';
            this._utilities.callTypeReject = 'false';
          }, 1000);
        }
        setTimeout(() => {
          this._sharedService.refreshActivityLog.next(true);
        }, 2000);
        this.isCalling = false;
        this.callReady = false;

        if (this.isMinimized) this.toggleDisplay();
        this.incomingCallDetailDailog.close(this.data.callInfo.callUUID);
        setTimeout(() => {
          this._utilities.callType = 'outgoing';
          this._utilities.callTypeReject = 'false';
        }, 1000);
      }
    }
  }

  smsReply() {
    this.dialogRef = this._dialog.open(SmsDialogComponent, {
      width: '500px',
      data: {
        phoneNumber: '',
        leadSource: '',
      },
    });
  }

  removeDialer() {
    if (this.dialedNumber) {
      this.dialedNumber = this.dialedNumber.slice(0, -1);
    }
  }

  async answerCall() {
    if (this.data.isTwilio) {
      const call = this._twilioService.twilioCallIncomingConnection.accept();
      this._utilities.numberOfCallsRunning++;
      this._utilities.callTypeReject = 'false';
      this.callUUID = this.data.parameters.CallSid;
      this.twilioCallSid = this.data?.parameters?.CallSid;
      this.multiCallUUID = this.data?.parameters?.CallSid;
      this.calleeFrom = this.data?.parameters?.From;
      this.multiCallEventName = this.data?.parameters?.From;
      if (this.currentCall !== '') {
        this.currentCall = this.data.parameters.CallSid;
      }
      this.callReady = true;
      this.isCalling = true;
      let a = moment();
      let b = moment();
      this.mergeCallDialer.firstCall = true;
      this.counterIncoming = setInterval(() => {
        this.showTime(a, b);
      }, 1000);
    } else {
      this.plivoBrowserSdk.client.answer(this.data.callInfo.callUUID);
      this._utilities.numberOfCallsRunning++;
      this._utilities.callTypeReject = 'false';
      this.callUUID = this.data.callInfo.callUUID;
      if (this.currentCall !== '') {
        this.currentCall = this.data.callInfo.callInfo;
      }
      this.callReady = true;
      this.isCalling = true;
      let a = moment();
      let b = moment();
      this.counterIncoming = setInterval(() => {
        this.showTime(a, b);
      }, 1000);
    }
  }

  toggleDisplay() {
    if (this.isMinimized) {
      if (document.querySelector('.popup-Backdrop-Class-dialer-incoming'))
        document.querySelector('.popup-Backdrop-Class-dialer-incoming')[
          'style'
        ].display = 'block';

      if (document.querySelector('.re-incomingcall-modal')) {
        document.querySelector('.re-incomingcall-modal')['style'].position =
          'relative';
        document.querySelector('.re-incomingcall-modal')['style'].height =
          'auto';
        document.querySelector('.re-incomingcall-modal')['style'].transform =
          '';
        document
          .querySelector('.re-incomingcall-modal')
          .classList.remove('re-lead-dialer-minimize-ss');
      }
    } else {
      if (document.querySelector('.popup-Backdrop-Class-dialer-incoming'))
        document.querySelector('.popup-Backdrop-Class-dialer-incoming')[
          'style'
        ].display = 'none';
      if (document.querySelector('.re-incomingcall-modal')) {
        document.querySelector('.re-incomingcall-modal')['style'].position =
          'absolute';
        document
          .querySelector('.re-incomingcall-modal')
          .classList.add('re-lead-dialer-minimize-ss');
      }
      this.reset();
    }

    this.isMinimized = !this.isMinimized;
  }

  drop(ev): void {
    this.evt = ev.source;
  }

  reset() {
    if (this.evt) this.evt._dragRef.reset();
  }

  dialer(key) {
    if (!key) {
      return;
    }
    this.dialedNumber += key;

    if (this.data.isTwilio) {
      this._twilioService.twilioCallIncomingConnection.sendDigits(key);
    } else {
      this.plivoBrowserSdk.client.sendDtmf(key);
    }
  }

  hold() {
    if (this.data.isTwilio) {
      this._twilioService
        ?.holdCallIncomingFn({ callSid: this.data.parameters.CallSid })
        .subscribe(
          (response) => {
            this.holdCall = true;
          },
          (err: ErrorModel) => {
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
    } else {
      if (this.plivoBrowserSdk.client.callUUID) {
        let obj = { uuid: this.plivoBrowserSdk.client.callUUID };
        this._sharedService.holdCallFn(obj).subscribe(
          (response) => {
            if (response.statusCode == 200) {
              this.holdCall = true;
            }
          },
          (err: ErrorModel) => {
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
      }
    }
  }

  unHold(disConnect?) {
    if (this.data.isTwilio) {
      this._twilioService
        ?.unHoldMainIncomingCall({ callSid: this.data.parameters.CallSid })
        .subscribe(
          (response) => {
            this.holdCall = false;
            if (disConnect) {
              setTimeout(() => {
                this.cancel({ direction: 'incoming' });
              }, 2000);
            }
          },
          (err: ErrorModel) => {
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
    } else {
      if (this.plivoBrowserSdk.client.callUUID) {
        let obj = { uuid: this.plivoBrowserSdk.client.callUUID };
        this._sharedService.unHoldCallFn(obj).subscribe(
          (response) => {
            if (response.statusCode == 200) {
              this.holdCall = false;
            }
          },
          (err: ErrorModel) => {
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
      }
    }
  }
}
