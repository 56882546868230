<div class="row">
  <div class="col d-flex">
    <h2
      mat-dialog-title
      style="display: block !important"
      class="w-100 d-flex justify-content-between"
    >
      <span class="modal-title-name">Send RVM </span>
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="padding-right: 5px; margin-top: 15px"
      />
    </span>
  </div>
</div>

<mat-dialog-content class="re-send-rvm-modal">
  <div class="container">
    <div class="box re-credit-box" *ngIf="rvmCredit <= 5">
      <span class="re-txt1">Available RVM Credits</span>
      <span class="re-txt2">{{ rvmCredit }} RVM Credits (running low)</span>
      <button class="re-buycredit" type="button">Buy Credit</button>
    </div>
    <hr />

    <div class="box re-credit-box" *ngIf="rvmCredit == 0">
      <span class="re-txt1">Available RVM Credits</span>
      <span class="re-txt2">No RVM credit available</span>
      <button class="re-buycredit" type="button">Buy Credit</button>
    </div>

    <div class="box re-credit-box" *ngIf="rvmCredit > 5">
      <span class="re-txt1">Available RVM Credits</span>
      <span class="re-txt3">{{ rvmCredit }} RVM Credits</span>
    </div>

    <div class="box padd-15">
      <div class="row">
        <!-- SEND TO -->
        <div class="col-12 col-md-6 col-sm-12">
          <div class="form-group">
            <label class="labels">Send To</label>
            <select class="form-control" name="sendTo" [(ngModel)]="sendTo">
              <option
                *ngFor="let eachContact of secondaryContact"
                [value]="eachContact.phoneNumber"
              >
                {{ eachContact.contactType }} &bull;
                {{ _utilities.maskNumber(eachContact.phoneNumber) }} &bull;
                {{ eachContact.name }}
              </option>
            </select>
          </div>
        </div>

        <!-- SEND FROM -->
        <div class="col-12 col-md-6 col-sm-12">
          <div class="form-group">
            <label class="labels">Sending From</label>
            <ng-container *ngIf="data.moduleType; else else_block">
              <select
                name="contactType"
                class="form-control"
                name="sendFrom"
                (change)="changeValue($event)"
              >
                <option selected value="">Select number</option>
                <option
                  *ngFor="let source of data?.leadSource"
                  [value]="source?.number"
                >
                  {{ _utilities.maskNumber(source?.number) }}
                </option>
              </select>
            </ng-container>

            <ng-template #else_block>
              <input
                type="text"
                class="form-control"
                name="sendFrom"
                [(ngModel)]="sendFrom"
                readonly
              />
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="box">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12">
          <label class="re-label">SELECT RVM RECORDING</label>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="row">
        <div
          class="col-12 col-md-12 col-sm-12 re-bott-border padd-15"
          *ngFor="let eachRVM of rvmList"
        >
          <div class="row">
            <div class="col-12 col-md-5">
              <div class="form-group">
                <div class="re-radiolist">
                  <label class="re-radiobtn"
                    >{{ eachRVM.name }}
                    <input
                      type="radio"
                      name="selectRvmRecording"
                      [(ngModel)]="selectRvmRecording"
                      value="{{ eachRVM.url }}" /><span
                      class="re-checkmark"
                    ></span
                  ></label>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-7">
              <div class="re-audio-flat">
                <app-audio-player [url]="eachRVM.url"></app-audio-player>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<hr style="margin: 0 0 10px 0 !important" />
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button
    mat-raised-button
    color="primary"
    cdkFocusInitial
    (click)="onSubmit()"
  >
    Send RVM
  </button>
</mat-dialog-actions>
