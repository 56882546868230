// MODULES
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as jQuery from 'jquery';
import { DomSanitizer } from '@angular/platform-browser';
import 'quill-mention';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

// UTILS
import { MessageConstant } from '../../utils/message-constant';
import { TwilioErrorMessageConstant } from '../../utils/twilioErrorMessage-constant';
import { ResponseModel } from '../../utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { ActivityLogModel } from 'src/app/utils/models/activityLog';
import { timezoneConstant } from 'src/app/utils/timezone-list-constant';
import { StatusConstant } from 'src/app/utils/status-constant';

// COMPONENTS
import { MediaPreviewComponent } from '../dialog/media-preview/media-preview.component';
import { DeleteDialogComponent } from '../../shared/dialog/delete-dialog/delete-dialog.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FilterActivitylogDialogComponent } from './filter-activitylog-dialog/filter-activitylog-dialog.component';
import { ViewHtmlDialogComponent } from '../dialog/view-html-dialog/view-html-dialog.component';
import { QuillEditorComponent } from 'ngx-quill';
import { ConfirmationDialogComponent } from '../dialog/confirmation-dialog/confirmation-dialog.component';

// SERVICES
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { SharedService } from '../shared.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DashboardService } from 'src/app/providers/dashboard/dashboard.service';
import { ListStackingService } from 'src/app/providers/list-stacking/list-stacking.service';
import { MasterTaskService } from 'src/app/providers/master-task/master-task.service';
import { DialerService } from 'src/app/providers/dialer/dialer.service';
import { PublicService } from 'src/app/providers/public/public.service';
import { WebmailService } from 'src/app/providers/email/webmail.service';
import { HttpCancelService } from 'src/app/providers/http-cancel/http-cancel.service';
import { ModalReplyCommentComponent } from 'src/app/modules/dashboard/modal-reply-comment/modal-reply-comment.component';
import { SmsDialogComponent } from '../dialog/sms-dialog/sms-dialog.component';
import { CreditService } from 'src/app/providers/credit/credit.service';
import { CallDetailDailogsComponent } from '../dialog/call-detail-dailogs/call-detail-dailogs.component';
import { LeadDialerComponent } from '../dialog/lead-dialer/lead-dialer.component';
import { CallTranscriptDialogComponent } from '../dialog/call-transcript-dialog/call-transcript-dialog.component';
import { TranscriptionPlanUpgradeComponent } from '../dialog/transcription-plan-upgrade/transcription-plan-upgrade.component';
import { CallTranscriptCreditManageComponent } from '../dialog/call-transcript-credit-manage/call-transcript-credit-manage.component';
import { PinnedNotesViewDialogComponent } from '../dialog/pinned-notes-view-dialog/pinned-notes-view-dialog.component';

@Component({
  selector: 'activity-logs',
  templateUrl: './activity-logs.component.html',
  styleUrls: ['./activity-logs.component.scss'],
})
export class ActivityLogsComponent implements OnInit {
  @ViewChild('editor') editor: QuillEditorComponent;
  @ViewChild('logsList') logsList: ElementRef;
  @Output() _emitRefreshFilterData = new EventEmitter<any>();

  @Input() style: any;
  @Input() classNew: any;
  @Input() moduleId: string = '';
  @Input() leadId: string = '';
  @Input() type: string = '';
  @Input() isWebForm: boolean = false;
  @Input() isWebFormLink: boolean = false;
  @Input() isWebFormActivity: boolean = false;
  @Input() leadData: any = {};
  @Input() propertyData: any = {};
  @Input() listPropertyData: any = {};
  @Input() listPropertyDataFromLead: any = {};
  @Input() mainUserId: string = '';
  @Input() currentUserId: string = '';
  @Input() inventoryId: string = '';
  @Input() activityMainStyle: any = {};
  @Input() activityTabView: boolean = true;
  @Input() activityFilterView: boolean = true;
  @Input() activityQuillBox: boolean = true;
  @Input() isDateView: boolean = true;
  @Input() isPinnedCount: boolean = false;
  @Input() isPinnedIconView: boolean = false;
  @Input() activityMediaStyle: boolean = false;
  @Input() moduleName: string = '';
  @Input() activityType: string = 'commnotes';
  @Input() isPinned: boolean = false;
  @Input() isActivityLeadList: boolean = false;
  @Input() isInventory: boolean = false;
  @Input() isWebFormApiLink: string = '';
  @Input() userId: string = '';
  @Input() isNew: boolean = true;
  @Input() isMainUser: boolean = true;
  @Input() activityDashboardComment: boolean = false;
  @Input() dashboardCommunicationView: boolean = false;
  @Input() isCommunicationTabList: boolean = false;
  @Input() isCommentFilterData: boolean = true;
  @Input() isUserListResponse: boolean = true;
  @Input() commentFilterData: any = {};
  @Input() statusLabel: any[] = [];
  @Input() userListing: any[] = [];
  @Input() userPlan: any;

  modalRef: BsModalRef;
  dialogRef: any;
  toggler: boolean = false;
  filterActive: boolean =
    this._utilities.activityLogFilter?.reset != 'undefined' &&
    this._utilities.activityLogFilter.reset == false
      ? true
      : false;
  isVisible: any[] = [];
  comment: any = '';
  messageConstant = MessageConstant;
  twilioErrorMessageConstant = TwilioErrorMessageConstant;
  action: string = 'add';
  logs: any = {};
  selectedLog: any = {};
  selectedIndex: number;
  comment2: any;
  comments: any[] = [];
  emailBody: any = '';
  currentEmail: any = {};
  userImage: any[] = [];
  moduleIdd: string = '601cf3c070419f19b74a6882';
  timezoneList: any[] = [];
  currentTimezone: any;
  callConnectedStatus: any;
  callNotConnectedStatus: any;
  callConnectedDialerStatus: any;
  callNotConnectedDialerStatus: any;
  editorStyle: {
    height: '50px';
  };
  showQuillToolbar: boolean = true;
  editorConfig: any = {};
  editorOptions: any = {};
  currentActivityPage: number = 1;
  activityCurrentLimit: number = 20;
  initialized: boolean = false;
  activitySort: boolean =
    localStorage.getItem('activitySort') == 'true' ? true : false;
  emailActivity: any = [];
  senderNameView: boolean = false;
  scrollDisabled: boolean = false;
  isAll: boolean = true;
  isIncoming: boolean = false;
  isOutgoing: boolean = false;
  leadModuleId: string = '5faa63fdfd20cd581703d255';
  SMSBalance: number = 0;
  callBalance: number = 0;
  aiCallTranscriptBalance: number = 0;
  totalPinCommentCount: number = 0;

  constructor(
    private _dialog: MatDialog,
    public _utilities: CommonFunctionsService,
    public _sharedService: SharedService,
    public _toastrService: ToastrService,
    private modalService: BsModalService,
    private _sanitizer: DomSanitizer,
    private _loaderService: NgxUiLoaderService,
    private _dashboardService: DashboardService,
    private _listStackingService: ListStackingService,
    private _masterTaskService: MasterTaskService,
    private _dialerService: DialerService,
    private _publicService: PublicService,
    private _webMailService: WebmailService,
    private httpCancelService: HttpCancelService,
    private _creditService: CreditService,
    private _route: Router
  ) {
    this.editorOptions = {
      placeholder: this._utilities.commonEditor().placeholder,
      toolbar: this._utilities.commonEditor().toolbar,
      mention: this.mentionEditor(),
    };
    this.editorConfig.toolbar = this.editorOptions.toolbar;
    this.editorConfig.mention = this.editorOptions.mention;

    this._sharedService.refreshCommentFilterInfo = new Subject();
    this._sharedService.refreshCommentFilterInfo.subscribe((response) => {
      this.commentFilterData = response.commentFilter;
      this.isCommentFilterData = response.filterApply;
      if (response.newComment) {
        this.isNew = response.isNew;
        this.isMainUser = response.isMainUser;
      }
      setTimeout(() => {
        this.callAction(this.moduleName, true);
      }, 500);
    });

    this._sharedService.refreshWebFormInfo = new Subject();
    this._sharedService.refreshWebFormInfo.subscribe((response) => {
      this.activityType = response?.activityType;
      this.leadData = response?.duplicateLead;
      this.leadId = response?.duplicateLead?._id;
      setTimeout(() => {
        this.callAction(this.moduleName, true);
      }, 500);
    });
    this._sharedService.refreshPinnedCountActivity = new Subject();
    this._sharedService.refreshPinnedCountActivity.subscribe((response) => {
      if (response) {
        setTimeout(() => {
          this.getPinnedCountActivity();
        }, 500);
      }
    });
  }

  mentionEditor() {
    return {
      mentionListClass: 'ql-mention-list mat-elevation-z8',
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      showDenotationChar: true,
      spaceAfterInsert: true,
      onSelect: (item, insertItem) => {
        const editor = this.editor.quillEditor;
        insertItem(item);
        // this.tagUser(item.value)
        // necessary because quill-mention triggers changes as 'api' instead of 'user'
        editor.insertText(editor.getLength() - 1, '', 'user');
      },
      source: (searchTerm, renderList) => {
        const values = this.userListing.map((item) => {
          return { id: item._id, value: item.firstName };
        });

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];

          values.forEach((entry) => {
            if (
              entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            ) {
              matches.push(entry);
            }
          });
          renderList(matches, searchTerm);
        }
      },
    };
  }
  ngOnInit(): void {
    this.callConnectedStatus = this._utilities.callConnectedStatus();
    this.callNotConnectedStatus = this._utilities.callNotConnectedStatus();
    this.callConnectedDialerStatus =
      this._utilities.callConnectedDialerStatus();
    this.callNotConnectedDialerStatus =
      this._utilities.callNotConnectedDialerStatus();
    this.timezoneList = timezoneConstant.timezoneArray;
    this.currentTimezone = this.timezoneList.filter(
      (x) =>
        x.timezonename == (localStorage?.timezoneOffset || 'America/Chicago')
    )[0]?.name;
    if (this.moduleName != 'web-form') {
      this.getEmailsTagsByLeadId();
    }
    if (this.moduleName != 'Dialer') {
      let isPinnedApiVisible = true;
      if (this.activityDashboardComment || this.dashboardCommunicationView) {
        isPinnedApiVisible = false;
      }
      this.callAction(this.moduleName, false, false, isPinnedApiVisible);
    }
  }

  async initialize() {
    this.currentActivityPage = 1;
    this.logs.items = [];
    this.callAction(this.moduleName, true);
  }

  /**
   * FUNCTION: EMAIL TAG BY LEADID
   * @returns EMAIL DETAILS OF ARRAY
   */
  getEmailsTagsByLeadId() {
    this._webMailService.getEmailsByLeadId(this.leadId).subscribe(
      (res: any) => {
        this.emailActivity = res?.details;
      },
      (err: any) => {
        console.log('emailActivity err =>', err);
      }
    );
  }

  /**
   * Function: GET ACTIVITY LOG
   * @param {string} moduleName
   * @param {boolean} bool
   * @param {String} sort
   * @param {boolean} isPinnedListCount
   * @returns ARRAY OF ACTIVITY LOG
   */
  callAction(moduleName, bool?, sort?, isPinnedListCount?) {
    let obj = {
      moduleId: this.moduleId,
      subModuleId: this.inventoryId ? this.inventoryId : this.leadId,
      page: this.currentActivityPage,
      limit: this.activityCurrentLimit,
    };

    if (
      moduleName != 'Dialer' &&
      moduleName != "Today's Call" &&
      moduleName != 'web-form' &&
      moduleName != 'dashboard-comment' &&
      moduleName != 'dashboard-communication'
    ) {
      obj['holdingSort'] = this.activitySort;
      if (this.isPinned) {
        obj['isPinned'] = this.isPinned;
      }
      if (this.activityType) {
        obj['activityType'] = this.activityType;
      }
      if (this.isInventory) {
        obj['isInventory'] = this.isInventory;
      }
      if (this._utilities.activityLogFilter && this.isActivityLeadList) {
        obj = { ...obj, ...this._utilities.activityLogFilter };
        delete obj['reset'];
      }
    }
    let callFunction;
    switch (moduleName) {
      case 'lead':
        callFunction = this._sharedService.getActivityLog(obj);
        break;
      case 'inventory':
        callFunction = this._sharedService.getActivityLog(obj);
        break;
      case 'Buyers':
        callFunction = this._sharedService.getActivityLog(obj);
        break;
      case 'Vendors':
        callFunction = this._sharedService.getActivityLog(obj);
        break;
      case 'List Stacking':
        delete obj['moduleId'];
        delete obj['subModuleId'];
        obj['id'] = this.leadId;
        callFunction = this._listStackingService.getActivityLog(obj);
        break;
      case 'Contractor Detail':
        callFunction = this._sharedService.getActivityLog(obj);
        break;
      case 'deals whiteboard':
        callFunction = this._sharedService.getActivityLog(obj);
        break;
      case 'web-form':
        if (!this.leadId) {
          return;
        }
        delete obj['moduleId'];
        callFunction = this._publicService.getActivityLog(
          obj,
          this.isWebFormApiLink
        );
        break;
      case 'Dialer':
        callFunction = this._dialerService.allActivityList(obj);
        break;
      case "Today's Call":
        if (obj?.subModuleId) {
          callFunction = this._dialerService.allActivityList(obj);
        }
        break;
      case 'take-note-dialer':
        delete obj['holdingSort'];
        obj['activityType'] = this.activityType;
        callFunction = this._dialerService.getNotes(obj);
        break;
      case 'dashboard-comment':
        delete obj['moduleId'];
        delete obj['subModuleId'];
        obj['limit'] = 50;
        obj['userId'] = this.userId;
        obj['isPinned'] = this.isPinned;
        obj['browserTimeZoneOffset'] =
          this._utilities.dueDateFormat().browserTimezone;
        obj['isNew'] = this.isNew;
        if (this.isCommentFilterData) {
          let filterData = {
            userIds: this.commentFilterData?.userIds,
            propertyId: this.commentFilterData?.propertyId?._id,
          };
          obj = { ...obj, ...filterData };
        }
        callFunction = this._dashboardService.getCommentData(obj);
        break;
      case 'dashboard-communication':
        delete obj['moduleId'];
        delete obj['subModuleId'];
        obj['limit'] = 50;
        obj['userId'] = this.userId;
        obj['browserTimeZoneOffset'] =
          this._utilities.dueDateFormat().browserTimezone;
        obj['isNew'] = this.isNew;
        if (this.isCommentFilterData) {
          let filterData = {
            userIds: this.commentFilterData?.userIds,
            propertyId: this.commentFilterData?.propertyId?._id,
            activityType: this.commentFilterData?.activityType,
            rangeDate: this.commentFilterData?.rangeDate,
          };
          obj = { ...obj, ...filterData };
        }
        callFunction = this._dashboardService.getCommunicationDataWeb(obj);
        break;
    }
    if (callFunction) {
      this.activityListCall(callFunction, bool, sort, isPinnedListCount);
    }
  }

  /**
   * FUNCTION: GET ACTIVITY LIST
   * @param {String} callFunction
   * @param {Boolean} bool
   * @param {Object} sort
   * @param {Boolean} isPinnedListCount
   * @returns ARRAY OF ACTIVITY DATA
   */
  activityListCall(callFunction, bool, sort, isPinnedListCount) {
    this._loaderService.start();
    callFunction.subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode == 200 || response?.code == 200) {
          this.scrollDisabled = false;
          let communicationScrollTop = false;
          if (this.logs?.items) {
            if (!sort && !bool) {
              if (this.logs?.items?.length === response.data?.count) {
                return;
              }
            }
            this.logs.count = response.data?.count;
            if (bool) {
              if (this.type == 'list-stacking') {
                this.logs.items = response.data;
              } else {
                this.logs.items = response.data?.items;
                setTimeout(() => {
                  this.scrollToBottom();
                }, 100);
              }
              communicationScrollTop = true;
            } else {
              if (this.type == 'list-stacking') {
                this.logs.items = [...this.logs.items, ...response?.data];
              } else {
                // this.logs.items = [...this.logs.items, ...response.data?.items];
                this.logs.items = [...response.data?.items, ...this.logs.items];
              }
            }
          } else {
            if (this.type == 'list-stacking') {
              this.logs['items'] = response?.data;
            } else {
              this.logs = response?.data;
              setTimeout(() => {
                this.scrollToBottom();
              }, 100);
            }
            communicationScrollTop = true;
          }
          if (this.isCommunicationTabList && communicationScrollTop) {
            jQuery('.re-comments-data').animate({ scrollTop: 0 }, 'slow');
          }
          if (isPinnedListCount) {
            this.getPinnedCountActivity();
          }
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  scrollToBottom(): void {
    this.logsList.nativeElement.scrollTop =
      this.logsList.nativeElement.scrollHeight;
  }

  /**
   * FUNCTION: GET PINNED ACTIVITY COUNT
   * @returns COUNT OF PINNED ACTIVITY
   */
  getPinnedCountActivity() {
    let obj = {
      moduleId: this.moduleId,
      subModuleId: this.inventoryId ? this.inventoryId : this.leadId,
      activityType: 'notes',
      holdingSort: false,
      isPinned: true,
      page: 1,
      limit: 1000,
    };
    if (this.isInventory) {
      obj['isInventory'] = this.isInventory;
    }
    this._sharedService.getActivityLog(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          if (this.logs.items) {
            this.totalPinCommentCount = response.data?.count || 0;
            this.logs.count = response.data?.count || 0;
            this._sharedService.refreshSubHeaderPinnedCount.next({
              count: this.logs.count,
            });
          }
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  /**
   * FUNCTION: HEADER TEXT VIEW
   * @param {Object} item
   * @param {String} type
   * @returns HEADER OF HTML TEXT
   */
  activityLogTextView(item, type) {
    this.senderNameView = false;
    let userImage = false;
    let imageUrl = '';
    let headerText = '';
    let bodyType = 'By';
    let leadConnect = '';
    let doteView = '• ';
    let sellerViewName;
    let number = this._utilities.maskNumber(item?.from);
    let checkTypeList = this.checkType(item?.direction, item);
    let callAnswer;
    if (type == 'images') {
      if (checkTypeList?.type == 'COMMENT') {
        if (item?.isWebForm) {
          imageUrl = 'assets/images/Via-Webform.svg';
        } else {
          const type = ActivityLogModel.activityLogArray.find(
            (x) => x?.activityType === item?.activityType
          );
          if (type && type?.activityType) {
            if (
              type?.comment &&
              item?.comment != 'Lead Unmarked as Hot Lead' &&
              item?.comment != 'Lead Marked as Hot Lead'
            ) {
              imageUrl = type?.comment?.imageUrl;
            } else if (type?.subType) {
              const subType = type?.subType.find(
                (x) => x?.type === item?.subType
              );
              if (subType && subType?.type) {
                imageUrl = subType?.imageUrl;
              } else if (type?.imageUrl) {
                imageUrl = type?.imageUrl;
              } else {
                userImage = true;
              }
            } else {
              imageUrl = type?.imageUrl;
            }
          } else {
            userImage = true;
          }
          if (userImage) {
            if (item?.profileImage) {
              imageUrl = item?.profileImage;
            } else {
              imageUrl = this.getUserDetails(item?.createdBy, true);
            }
          }
        }
      } else {
        if (!item?.isWebForm) {
          imageUrl = checkTypeList?.icon;
        }
      }
    } else if (type == 'headerText') {
      const headerType = ActivityLogModel.activityHeaderArray.find(
        (x) => x?.activityType === item?.activityType
      );
      if (item?.isWebForm) {
        headerText = 'WebForm';
      } else if (headerType && headerType?.activityType) {
        if (headerType?.subType) {
          const subType = headerType?.subType.find(
            (x) => x?.type === item?.subType
          );
          if (subType && subType?.type) {
            headerText = subType?.headerText;
          } else {
            userImage = true;
          }
        }
      } else if (!item?.isWebForm) {
        if (checkTypeList?.number) {
          this.senderNameView = true;
          number = this._utilities.maskNumber(checkTypeList?.number);
          let checkUserName;
          if (this.dashboardCommunicationView) {
            checkUserName = this.getSellerName(checkTypeList?.number, item);
          } else {
            checkUserName = this.checkSellerName(checkTypeList?.number);
          }
          sellerViewName = checkUserName?.length > 0 ? checkUserName : 'N/A';
        } else if (checkTypeList?.email) {
          headerText = item?.to;
        } else {
          userImage = true;
        }
      } else {
        userImage = true;
      }
      if (userImage) {
        if (item?.firstName && item?.lastName) {
          headerText = item?.firstName + ' ' + item?.lastName;
        } else {
          headerText = this.getUserDetails(item?.createdBy);
        }
      }
    } else if (type == 'bodyText') {
      if (item?.isDrip) {
        headerText = 'Drip';
      } else if (item?.isAiActive) {
        headerText = 'REsimpli AI';
      } else if (item?.subType == 1) {
        headerText = 'Missed Call SMS';
      } else if (
        item?.direction != 'comment' &&
        !item?.subType &&
        !item?.isDrip &&
        !item?.isAiActive
      ) {
        if (
          item?.direction != 'incomingCall' &&
          item?.direction != 'incomingSms' &&
          item?.direction != 'incomingMms'
        ) {
          headerText = this.getUserDetails(item?.createdBy);
          if (this.dashboardCommunicationView) {
            if (
              item?.direction == 'outgoingCall' &&
              (item?.voiceCallStatus == 'no-answer' ||
                item?.voiceCallStatus == 'cancel')
            ) {
              callAnswer = 'Not Answered •';
            }
          }
          if (item?.callStatusId) {
            let leadText = item?.iscallConnected
              ? 'LEAD CONNECTED'
              : 'LEAD NOT-CONNECTED';
            let leadStatus = this.getCallStatus(
              item?.iscallConnected,
              item?.callStatusId,
              item?.isLs
            );
            leadConnect = leadText + ' (' + leadStatus + ') ';
            if (this.dashboardCommunicationView) {
              leadConnect += ' • ';
            }
          }
        } else {
          bodyType = 'To';
          doteView = '';
          number = this._utilities.maskNumber(item?.to);
        }
      }
    }
    return {
      imageUrl,
      headerText,
      bodyType,
      number,
      leadConnect,
      sellerViewName,
      callAnswer,
      doteView,
    };
  }

  /**
   * FUNCTION: CHECK BY CALL TYPE
   * @param {String} direction
   * @param {Object} log
   * @param {Number} index
   * @returns OBJECT OF TYPE, MODE, ICON, NUMBER AND EMAIL
   */
  checkType(direction, log?, index?) {
    let type = '';
    let mode = '';
    let icon = '';
    let number = '';
    let email = '';
    let tooltip = '';

    switch (direction) {
      case 'outgoingCall':
        type = 'CALL';
        mode = 'send';
        icon = '/assets/images/new-outgoing-call.svg';
        number = log['to'];
        break;

      case 'outbound':
        type = 'CALL';
        mode = 'send';
        icon = '/assets/images/new-outgoing-call.svg';
        number = log['to'];
        break;

      case 'incomingCall':
        type = 'CALL';
        mode = 'receive';
        icon =
          log?.voiceCallStatus == 'no-answer' ||
          log?.voiceCallStatus == 'cancel' ||
          log?.voiceCallStatus == 'no-confirm' ||
          log?.voiceCallStatus == 'busy'
            ? '/assets/images/new-missed-call.svg'
            : log?.activityType == 5
            ? '/assets/images/new-voicemail.svg'
            : '/assets/images/new-incoming-call.svg';
        number = log['from'];
        break;

      case 'outgoingSms':
        type = 'SMS';
        mode = 'send';
        icon =
          log?.status == 'delivered'
            ? '/assets/images/SMS-delivered.svg'
            : log?.status == 'failed' || log?.status == 'undelivered'
            ? '/assets/images/SMS-failed.svg'
            : log?.status == 'queued' || log?.status == 'sent'
            ? '/assets/images/SMS-queue.svg'
            : '/assets/images/new-sms-outgoing.svg';
        number = log['to'];
        tooltip =
          log?.status == 'delivered'
            ? this._utilities.capitalizeName(log?.status)
            : (log?.status == 'failed' || log?.status == 'undelivered') &&
              log?.errorCode
            ? 'Undelivered </br>' +
              this.twilioErrorMessageConstant[log?.errorCode]
            : log?.status == 'failed' || log?.status == 'undelivered'
            ? 'Undelivered'
            : 'Sent';
        break;

      case 'incomingSms':
        type = 'SMS';
        mode = 'receive';
        icon = '/assets/images/new-sms-incoming.svg';
        number = log['from'];
        break;

      case 'outgoingMms':
        type = 'MMS';
        mode = 'send';
        icon =
          log?.status == 'delivered'
            ? '/assets/images/SMS-delivered.svg'
            : log?.status == 'failed' || log?.status == 'undelivered'
            ? '/assets/images/SMS-failed.svg'
            : log?.status == 'queued' || log?.status == 'sent'
            ? '/assets/images/SMS-queue.svg'
            : '/assets/images/new-sms-outgoing.svg';
        number = log['to'];
        tooltip =
          log?.status == 'delivered'
            ? this._utilities.capitalizeName(log?.status)
            : (log?.status == 'failed' || log?.status == 'undelivered') &&
              log?.errorCode
            ? 'Undelivered </br>' +
              this.twilioErrorMessageConstant[log?.errorCode]
            : log?.status == 'failed' || log?.status == 'undelivered'
            ? 'Undelivered'
            : 'Sent';
        break;

      case 'incomingMms':
        type = 'MMS';
        mode = 'receive';
        icon = '/assets/images/new-sms-incoming.svg';
        number = log['from'];
        break;

      case 'outgoingEmail':
        type = 'EMAIL';
        mode = 'send';
        icon = '/assets/images/new-outgoing-email.svg';
        email = log['email'];
        break;

      case 'outgoingRvm':
        type = 'RVM';
        mode = 'send';
        icon = '/assets/images/new-voicemail-outgoing.svg';
        number = log['to'];
        break;

      default:
        log.direction = 'comment';
        type = 'COMMENT';
        icon = '/assets/images/demo-profile.png';
        mode = 'send';
        break;
    }
    if (index) {
      this.getFile(log, index);
    }
    return { type, mode, icon, number, email, tooltip };
  }

  /**
   * FUNCTION: SCROLL PAGE TO CALL API
   * @returns NEXT PAGE ACTIVITY LIST
   */
  onScroll() {
    if (this.logs?.count == this.logs?.items?.length) return;

    this.currentActivityPage += 1;
    this.callAction(this.moduleName, false);
  }

  /**
   * FUNCTION: ATTACHMENTS IMAGE VIEW
   * @param {Object} log
   * @param {Number} fileIndex
   * @returns VIEW IMAGE
   */
  previewAttachment(log, fileIndex) {
    this._loaderService.start();
    setTimeout(() => {
      this._loaderService.stop();
    }, 2000);
    let re = /(?:\.([^.]+))?$/;
    this.dialogRef = this._dialog.open(MediaPreviewComponent, {
      width: '50vw',
      data: {
        file: { fileType: 'multiple', originalName: 'Attachments' },
        items: log?.attachments.map((x) => {
          // let fileType = 'png';
          let fileType = re.exec(x)[1];
          let obj = { src: x, fileType: fileType, title: x };
          return obj;
        }),
        index: fileIndex,
      },
    });
  }

  /**
   * FUNCTION: TOGGLE VIEW
   * @param {Number} logIndex
   * @returns VISIBLE SECTION
   */
  toggleVisibility(logIndex) {
    const index = this.isVisible.indexOf(logIndex);
    if (index > -1) {
      this.isVisible.splice(index, 1);
    } else {
      this.isVisible.push(logIndex);
    }
  }

  /**
   * FUNCTION: EXPAND DIV
   */
  toggleExpand() {
    jQuery('.re-activity-main').toggleClass('re-expand-div');
  }

  /**
   * FUNCTION: ACTIVITY FILTER
   * @returns ARRAY OF FILTER ACTIVITY LIST
   */
  onActivityLogFilter() {
    this.dialogRef = this._dialog.open(FilterActivitylogDialogComponent, {
      width: '350px',
      data: {
        userListing: this.userListing,
        filterData: this._utilities.activityLogFilter,
        type: this.type,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.filterActive = true;

        this._utilities.activityLogFilter = result;
        if (result['reset']) {
          this.filterActive = false;
          this._utilities.activityLogFilter['reset'] = true;
        } else {
          this._utilities.activityLogFilter['reset'] = false;
        }
        this.filterActivity();
      }
    });
  }

  /**
   * FUNCTION: RESET FILTER
   */
  reset() {
    this.filterActive = false;
    this._utilities.activityLogFilter = { reset: true };
    this.filterActivity();
  }

  /**
   * FUNCTION: REPLACE COMMENT
   * @param {Object} $event
   * @returns REPLACE STRING COMMENT
   */
  onComment($event) {
    if (this.editorOptions.placeholder === $event?.target?.innerText?.trim())
      return;

    this.comment = $event?.target?.innerHTML; //.replace(/\r\n|\r|\n/g, '<br />');
  }

  /**
   * FUNCTION: REPLACE COMMENT
   * @param {Object} $event
   * @returns REPLACE STRING COMMENT
   */
  onComment2($event) {
    if (this.editorOptions.placeholder === $event?.target?.innerText?.trim())
      return;

    this.comment2 = $event?.target?.innerHTML; //.replace(/\r?\n/g, '<br />');
  }

  /**
   * FUNCTION: CANCLE EDIT COMMENT
   * @param {String} action
   * @param {Object} log
   * @param {Number} logIndex
   * @param {String} id
   * @returns CANCLE EDIT ACTIVITY COMMENT
   */
  onClose(action, log, logIndex, id) {
    let obj = log;
    delete obj['select'];
    this.action = action;
    this.comment = '';
    this.selectedLog = {};
    this.selectedIndex = undefined;
  }

  /**
   * FUNCTION: CANCLE EMAIL EDIT
   * @param {Object} log
   * @returns ARRAY OF EMAIL ACTIVITY LOG
   */
  onCloseEmail(log) {
    this.emailActivity = this.emailActivity.filter((el) => el._id !== log._id);
  }

  /**
   * FUNCTION: SET PINNED COMMENT
   * @param {Object} item
   * @returns ARRAY OF PINNED COMMENT
   */
  setPinnedComment(item) {
    let param = {
      pinnedUser: item.pinnedUser,
      isPinned: item.isPinned,
      commentId: item._id,
    };

    this._loaderService.start();

    this._dashboardService.setCommentPinned(param).subscribe(
      (response: ResponseModel) => {
        if (item.isPinned) {
          this._sharedService.refreshActivityLog.next(true);
        }
        this.getPinnedActivity(this.activityType, true);

        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this._toastrService.success(response?.data, '');

          item.isPinned = !item.isPinned;
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  /**
   * FUNCTIONl: EDIT ACTIVITY LOG
   * @param {String} action
   * @param {Object} log
   * @param {Number} logIndex
   * @param {String} id
   * @returns ARRAY OF EDIT ACTIVITY LOG
   */
  onEdit(action, log, logIndex, id) {
    this.comments.filter((x) => {
      delete x['select'];
    });

    let obj = log;
    this.action = action;
    this.comment = log?.comment.replace(/<br \/>/g, '\r\n');
    obj['select'] = true;
    this.selectedLog = obj;
    this.selectedIndex = logIndex;
  }

  /**
   * FUNCTION: DELETE ACTIVITY COMMENT
   * @param {String} type
   * @param {String} moduleName
   * @param {Object} data
   * @param {Number} index
   * @param {String} id
   * @returns ARRAY OF ACTIVITY COMMENT
   */
  onDelete(type, moduleName, data, index, id) {
    switch (moduleName) {
      case 'leads':
        if (this.isWebForm) {
          this.comments.splice(index, 1);
        } else {
          this.onDeleteLeadsLogs(type, data, index, id);
        }
        break;
    }
  }

  onDeleteEmailActivity(tags) {}

  /**
   * FUNCTION: DELETE LEAD COMMENT
   * @param {String} type
   * @param {Object} data
   * @param {Number} index
   * @param {String} id
   * @returns ARRAY OF ACTIVITY COMMENT
   */
  onDeleteLeadsLogs(type, data, index, id) {
    this.dialogRef = this._dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: {
        subModule: type,
        subModuleId: data.subModuleId,
        moduleId: data.moduleId,
        details: data,
        index: index,
        id: id,
        type: this.type,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteLogs(data?._id, index);
      }
    });
  }

  /**
   * FUNCTION: DELETE ACTIVITY COMMENT
   * @param {String} id
   * @param {Number} index
   * @returns ARRAY OF ACTIVITY COMMENT
   */
  deleteLogs(id, index) {
    let serviceFunction = this._sharedService.deleteActivityLogs;
    if (this.type == 'list-stacking') {
      serviceFunction = this._listStackingService.deleteActivityLogs;
    }
    serviceFunction(id).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200 || response?.code == 200) {
          if (
            response?.message == 'Success!' ||
            response?.message == 'Activity Deleted Successfully'
          ) {
            this.updateActivityPage({
              module: 'comment',
              type: 'delete',
              index,
              result: response?.message,
            });
            this.getPinnedCountActivity();
          }

          this._toastrService.success(
            this.messageConstant.activityLogsDeletedSuccess
          );
        }
        this._loaderService.stop();
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  /**
   * FUNCTION: ADD EDIT ACTIVITY COMMENT
   * @returns NEW ARRAY OF ACTIVITY LIST
   */
  async onSubmit() {
    const leadListData = this.listPropertyData?._id
      ? this.listPropertyData
      : this.leadData;
    const phoneNumber = leadListData?.phoneNumber
      ? leadListData?.phoneNumber
      : leadListData?.contactNumber;
    const mainStatusTitle = this.propertyData?._id
      ? this.propertyData?.mainStatusTitle
      : leadListData?.mainStatusTitle;
    switch (this.type) {
      case 'contractor':
        switch (this.action) {
          case 'add':
            this.addEditLogs(
              mainStatusTitle,
              false,
              leadListData?.address,
              false,
              '',
              '',
              '',
              this.moduleId,
              this.leadId
            );
            break;
          case 'edit':
            this.addEditLogs(mainStatusTitle, true, leadListData?.address);
            break;
        }
        break;

      case 'web-form':
        switch (this.action) {
          case 'add':
            if (!this.comment) {
              return;
            }
            if (this.isWebForm) {
              this.comments.push({
                activityType: 8,
                createdAt: Date.now(),
                comment: this.comment,
                isTagged: this.comment?.includes('@'),
                tagUsers: await this.getTaggedUsers(),
              });
              this.logs['items'] = this.comments;
              this.comment = '';
              this.comment2 = '';
            } else if (this.isWebFormActivity) {
              this.comments.push({
                activityType: 8,
                createdAt: Date.now(),
                comment: this.comment,
                isTagged: this.comment?.includes('@'),
                tagUsers: await this.getTaggedUsers(),
              });
              this.addEditLogs(
                mainStatusTitle,
                false,
                leadListData?.address,
                true,
                leadListData?.title,
                phoneNumber,
                leadListData?.unitNo,
                this.moduleId,
                this.leadId
              );
            } else {
              this.addEditLogs(
                mainStatusTitle,
                false,
                leadListData?.address,
                true,
                leadListData?.title,
                phoneNumber,
                leadListData?.unitNo,
                this.moduleId,
                this.leadId
              );
            }
            break;
          case 'edit':
            if (this.isWebForm) {
              this.comments[this.selectedIndex].comment = this.comment;
              this.comments[this.selectedIndex].isTagged =
                this.comment?.includes('@');
              this.comments[this.selectedIndex].tagUsers =
                await this.getTaggedUsers();
              this.comments.filter((x) => {
                delete x['select'];
              });
              this.comment = '';
              this.comment2 = '';
              this.selectedLog = {};
              this.selectedIndex = -1;
              this.action = 'add';
            } else {
              this.addEditLogs(
                mainStatusTitle,
                true,
                leadListData?.address,
                true,
                leadListData?.title,
                phoneNumber,
                leadListData?.unitNo
              );
            }
            break;
        }
        break;

      default:
        switch (this.action) {
          case 'add':
            this.addEditLogs(
              mainStatusTitle,
              false,
              leadListData?.address,
              false,
              leadListData?.title,
              phoneNumber,
              leadListData?.unitNo,
              this.moduleId,
              this.leadId
            );
            break;
          case 'edit':
            this.addEditLogs(
              mainStatusTitle,
              true,
              leadListData?.address,
              false,
              leadListData?.title,
              phoneNumber,
              leadListData?.unitNo
            );
            break;
        }
        break;
    }
  }

  /**
   * FUNCTION: TAG USER IN COMMENT
   * @param {Object} $event
   * @returns TAG USER NAME
   */
  tagUser($event) {
    return `@${$event.firstName} `;
  }

  /**
   * FUNCTION: GET TAG USER NAME
   * @returns ARRAY OF TAG USER
   */
  async getTaggedUsers() {
    let comment = this._utilities.getText(
      this['editor'].quillEditor.getContents()
    );
    const mention = '@';
    const myArray = comment.replace(/\n/g, ' ').split(' ');

    let taggedUsers = [];
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].charAt(0) == mention) {
        this.userListing.filter((x) => {
          if (x.firstName === myArray[i].substr(1)) {
            taggedUsers.push(x?._id);
          }
        });
      }
    }
    const users = new Set(taggedUsers);
    return [...users];
  }

  /**
   * FUNCTION: ADD EDIT LOGS
   * @param {string} mainStatus
   * @param {boolean} isEdit
   * @param {String} address
   * @param {Number} index
   * @param {boolean} isPublic
   * @param {string} title
   * @param {Number} phone
   * @param {Number} unitNo
   * @param {Number} moduleId
   * @param {Number} subModuleId
   * @returns TRUE/FALSE
   */
  async addEditLogs(
    mainStatus,
    isEdit,
    address?,
    isPublic?,
    title?,
    phone?,
    unitNo?,
    moduleId?,
    subModuleId?
  ) {
    this.comment = this.comment.trim();
    let body = {};
    if (!isEdit) {
      if (!this.isWebForm && !this.comment) {
        return;
      }
      body = {
        subModuleId,
      };
      if (this.isWebFormActivity) {
        let comments = JSON.parse(JSON.stringify(this.comments)).map((x) => {
          x.comment = x?.comment?.trim();
          delete x['activityType'];
          delete x['createdAt'];
          delete x['direction'];
          return x;
        });
        if (this.comment) {
          const userIndex = comments.findIndex(
            (x) => x.comment?.trim() === this.comment?.trim()
          );
          if (userIndex === -1) {
            comments.push({
              comment: this.comment,
              isTagged: this.comment?.includes('@'),
              tagUsers: await this.getTaggedUsers(),
            });
          }
        }
        body = {
          ...body,
          comments,
          isWebForm: true,
        };
      } else {
        body = {
          ...body,
          moduleId,
          comment: this.comment,
          isTagged: this.comment?.includes('@'),
          tagUsers: await this.getTaggedUsers(),
        };
      }
    } else {
      if (!this.comment) {
        return;
      }
      body = {
        comment: this.comment,
        isTagged: this.comment?.includes('@'),
        tagUsers: await this.getTaggedUsers(),
      };
    }
    if (address) {
      body['address'] = address;
    }
    if (unitNo) {
      body['unitNo'] = unitNo;
    }

    if (mainStatus) {
      body['mainStatus'] = mainStatus;
    }

    if (title) {
      body['title'] = title;
    }

    if (phone) {
      body['phoneNumber'] = phone;
    }
    let serviceFunction;
    let serviceForLeadAndLSFunction;
    this._loaderService.start();
    if (isEdit) {
      delete this.selectedLog['select'];
      delete body['select'];
      serviceFunction = this._sharedService.editActivityLogs(
        body,
        this.selectedLog?._id,
        isPublic
      );
    } else {
      serviceFunction = this._sharedService.addActivityLog(body, isPublic);
      if (
        this.listPropertyDataFromLead?._id &&
        typeof this.listPropertyDataFromLead?._id != undefined &&
        this.listPropertyDataFromLead?._id != 'undefined'
      ) {
        let obj = { ...body };
        obj['subModuleId'] = this.listPropertyDataFromLead?._id;
        obj['moduleId'] = '61b7043f79a58008e1663d2b'; //List Stacking moduleId
        obj['createdBy'] = this.currentUserId;
        obj['subactivity'] = '';
        obj['title'] =
          this.listPropertyDataFromLead?.Formated_Address ||
          this.listPropertyDataFromLead?.Property_Street_Address +
            ', ' +
            this.listPropertyDataFromLead?.Property_City +
            ', ' +
            this.listPropertyDataFromLead?.Property_State +
            ' ' +
            this.listPropertyDataFromLead?.Property_Zip_Code;
        delete obj['address'];
        delete obj['phoneNumber'];
        delete obj['mainStatus'];
        serviceForLeadAndLSFunction =
          this._listStackingService.addActivityLog(obj);
        serviceForLeadAndLSFunction.subscribe(
          (response: ResponseModel) => {
            if (response?.statusCode === 200 || response?.code === 200) {
            } else {
              this._toastrService.error(
                this.messageConstant.unknownError,
                'Error'
              );
            }
          },
          (err: ErrorModel) => {}
        );
      }
    }
    if (this.type == 'list-stacking') {
      if (isEdit) {
        serviceFunction = this._listStackingService.editActivityLogs(
          body,
          this.selectedLog?._id
        );
      } else {
        body['createdBy'] = this.mainUserId;
        body['subactivity'] = '';
        serviceFunction = this._listStackingService.addActivityLog(body);
        if (
          this.listPropertyData?.lead_property_id &&
          this.listPropertyData?.lead_property_id != '' &&
          typeof this.listPropertyData?.lead_property_id != undefined &&
          this.listPropertyData?.lead_property_id != 'undefined'
        ) {
          let obj = { ...body };
          obj['subModuleId'] = this.listPropertyData?.lead_property_id;
          obj['moduleId'] = '5faa63fdfd20cd581703d255'; // Lead moduleId
          delete obj['createdBy'];
          delete obj['subactivity'];
          serviceForLeadAndLSFunction = this._sharedService.addActivityLog(
            obj,
            isPublic
          );
          serviceForLeadAndLSFunction.subscribe(
            (response: ResponseModel) => {
              if (response?.statusCode === 200 || response?.code === 200) {
              } else {
                this._toastrService.error(
                  this.messageConstant.unknownError,
                  'Error'
                );
              }
            },
            (err: ErrorModel) => {}
          );
        }
      }
    }
    serviceFunction.subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode === 200 || response?.code === 200) {
          if (isEdit) {
            this.selectedLog.comment = this.comment;
            this.updateActivityPage({
              module: 'comment',
              type: 'edit',
              index: this.selectedIndex,
              result: this.selectedLog,
            });
            this._toastrService.success(
              this.messageConstant.activityLogsUpdatedSuccess
            );
          } else {
            this.currentActivityPage = 1;
            this.callAction(this.moduleName, true);
            // jQuery('.re-logs-list').scrollTop(0);
          }
        } else {
          this._toastrService.error(this.messageConstant.unknownError, 'Error');
        }
        this.comment = '';
        this.comment2 = '';
        if (isEdit) {
          this.action = 'add';
          this.selectedLog = {};
          this.selectedIndex = -1;
        } else {
          this.comments = [];
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        this.comment = '';
        if (isEdit) {
          this.action = 'add';
        }
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  /**
   * FUNCTION: EMAIL SHOW
   * @param {Object} log
   * @param {String} template
   * @returns CURRENT EMAIL
   */
  showEmail(log: any, template?: TemplateRef<any>) {
    if (template) {
      this.modalRef = this.modalService.show(template);
      this.emailBody = this.transform(log.emailBody);
      this.currentEmail = log;
    }
  }

  /**
   * FUNCTION: CLOSE MODEL
   */
  closeModal() {
    this.modalRef.hide();
  }

  /**
   * FUNCTION: HTML TRANS FORM
   * @param {String} htmlString
   * @returns NEW STRING BY HTML
   */
  transform(htmlString: string) {
    if (htmlString) {
      htmlString = String(htmlString)
        .replace(/data-x-style-url= /g, 'style')
        .replace(/data-x-div-type="html" /g, '')
        .replace(/data-x-div-type="body" /g, '')
        .replace(/data-x-src=/g, 'src=');
      const newString = this._sanitizer.bypassSecurityTrustHtml(htmlString);
      return newString;
    }
    return '';
  }

  /**
   * FUNCTION: GET ACTIVITY ICON
   * @param {String} fileName
   * @returns ACTIVITY IMAGE ICON
   */
  getIcon(fileName) {
    const re = /(?:\.([^.]+))?$/;
    let name = re.exec(fileName)[1];
    name = name.toLocaleLowerCase();

    if (name === 'doc' || name === 'docx') {
      return 'doc_icon.png';
    }

    if (name === 'pdf') {
      return 'pdf_icon.png';
    }

    if (name === 'mp4') {
      return 'mp4_icon.png';
    }

    if (name === 'mp3') {
      return 'mp3_icon.png';
    }

    if (name === 'gif') {
      return 'gif_icon.png';
    }

    if (name === 'png') {
      return 'png_icon.png';
    }

    if (name === 'xls' || name === 'xlsx') {
      return 'xls_icon.png';
    }

    if (name === 'jpeg' || name === 'jpg') {
      return 'jpeg_icon.png';
    }

    if (name === 'ico') {
      return 'ico_icon.png';
    }

    if (name === 'svg') {
      return 'svg_icon.png';
    }

    if (name === 'zip') {
      return 'zip_icon.png';
    }

    if (name === 'txt') {
      return 'txt_icon.png';
    }

    if (name === 'rar') {
      return 'rar_icon.png';
    }

    if (name === 'json') {
      return 'json_icon.png';
    }

    if (name === 'html') {
      return 'html_icon.png';
    }

    if (name === 'dwg') {
      return 'dwg_icon.png';
    }

    if (name === 'ai') {
      return 'ai_icon.png';
    }

    if (name === 'csv') {
      return 'csv_icon.png';
    }

    if (name === 'webp') {
      return 'webp_icon.png';
    }

    if (name) {
      return 'png_icon.png';
    }

    return 'other';
  }

  /**
   * FUNCTION: GET MEDIA FILE
   * @param {Object} log
   * @param {Number} index
   * @returns IMAGE URL
   */
  getFile(log, index) {
    let param = {
      moduleId: this.moduleIdd,
      src: log['profileImage'],
    };

    this._sharedService.getFile(param).subscribe(
      (response) => {
        if (response) {
          let objectURL = URL.createObjectURL(response);
          this.userImage[index] =
            this._sanitizer.bypassSecurityTrustUrl(objectURL);
        }
      },
      (err: ErrorModel) => {}
    );
  }

  /**
   * FUNCTION: UPDATE ACTIVITY PAGE
   * @param {String} module
   * @param {String} type
   * @param {Number} index
   * @param {Object} result
   * @returns ARRAY OF ACTIVITY LOG ITEM
   */
  updateActivityPage({ module, type, index, result }) {
    if (module !== 'comment') {
      return;
    }
    switch (type) {
      case 'delete':
        this.logs.items.splice(index, 1);
        break;
      case 'edit':
        this.logs.items[index] = result;
        break;
    }
  }

  /**
   * FUNCTION: SORT OF ACTIVITY LOGS
   * @returns ARRAY OF ACTIVITY LOGS
   */
  sortActivity() {
    jQuery('.re-activity-sort svg').toggleClass('fa-flip-horizontal');
    jQuery('.re-logs-list').scrollTop(0);
    this.activitySort = this.activitySort ? false : true;
    localStorage.setItem(
      'activitySort',
      this.activitySort == true ? 'true' : 'false'
    );
    this.currentActivityPage = 1;
    this.callAction(this.moduleName, true, true);
  }

  /**
   * FUNCTION: FILTER OF ACTIVITY LOGS ITEM
   * @returns ARRAY OF ACTIVITY LOGS
   */
  filterActivity() {
    this.currentActivityPage = 1;
    this.callAction(this.moduleName, true, true);
  }

  /**
   * FUNCTION: CHANGE OF ACTIVITY TYPE
   * @returns ARRAY OF ACTIVITY LOGS
   */
  changeActivityType() {
    this.currentActivityPage = 1;
    this.callAction(this.moduleName, true);
  }

  /**
   * FUNCTION: GET PINNED ACTIVITY LOGS
   * @param {String} $event
   * @param {Boolean} bool
   * @returns ARRAY OF PINNED ACTIVITY LOGS
   */
  getPinnedActivity($event, bool) {
    this.activityType = $event;
    this.currentActivityPage = 1;
    let isPinnedApiVisible = true;
    if (this.activityDashboardComment) {
      isPinnedApiVisible = false;
    }
    this.callAction(this.moduleName, bool, false, isPinnedApiVisible);
  }

  /**
   * FUNCTION: VIEW OF HTML DIALOG
   * @param {String} html
   * @returns VIEW OF ACTIVITY COMMENT
   */
  viewCampaign(html) {
    this.dialogRef = this._dialog.open(ViewHtmlDialogComponent, {
      width: '800px',
      data: {
        title: 'Campaign',
        content: html,
      },
    });
  }

  /**
   * FUNCTION: CHECK BY SELLER NAME
   * @param {Number} number
   * @returns GET ACTIVITY USER NAME
   */
  checkSellerName(number?) {
    let name = 'N/A';

    if (this.propertyData?._id) {
      let propertyBuyer = this.propertyData?.buyers
        .map((secondary) => {
          if (secondary?.contactNumber == number) {
            return secondary;
          }
        })
        .filter((x) => x);
      if (propertyBuyer?.length > 0) {
        let buyerName = propertyBuyer[0]?.firstName
          ? propertyBuyer[0]?.firstName
          : '';
        let buyerLastName = propertyBuyer[0]?.lastName
          ? propertyBuyer[0]?.lastName
          : '';
        name = buyerName + ' ' + buyerLastName;
      } else if (this.leadData) {
        if (number == this.leadData?.phoneNumber) {
          name = this.leadData?.title ? this.leadData?.title : 'N/A';
        } else if (this.leadData?.buyers) {
          let buyerDetail = this.leadData?.buyers
            .map((buyers) => {
              if (buyers?.contactNumber == number) {
                return buyers;
              }
            })
            .filter((x) => x);
          if (buyerDetail?.length > 0) {
            let buyerName = buyerDetail[0]?.firstName
              ? buyerDetail[0]?.firstName
              : '';
            let buyerLastName = buyerDetail[0]?.lastName
              ? buyerDetail[0]?.lastName
              : '';
            name = buyerName + ' ' + buyerLastName;
          } else {
            if (this.leadData?.secondaryContact) {
              name = this.leadData?.secondaryContact
                .map((secondary) => {
                  if (secondary?.phoneNumber == number) {
                    return secondary?.name ? secondary?.name : 'N/A';
                  }
                })
                .filter((x) => x);
            }
          }
        }
      }
    } else if (this.listPropertyData?._id) {
      name = this.listPropertyData?.Full_Name
        ? this.listPropertyData?.Full_Name
        : 'N/A';
    } else if (this.leadData) {
      if (number == this.leadData?.phoneNumber) {
        name = this.leadData?.title ? this.leadData?.title : 'N/A';
      } else if (number == this.leadData?.contactNumber) {
        let buyerName = this.leadData?.firstName
          ? this.leadData?.firstName
          : '';
        let buyerLastName = this.leadData?.lastName
          ? this.leadData?.lastName
          : '';
        name = buyerName + ' ' + buyerLastName;
      } else if (this.leadData?.buyers) {
        let buyerDetail = this.leadData?.buyers
          .map((buyers) => {
            if (buyers?.contactNumber == number) {
              return buyers;
            }
          })
          .filter((x) => x);
        if (buyerDetail?.length > 0) {
          let buyerName = buyerDetail[0]?.firstName
            ? buyerDetail[0]?.firstName
            : '';
          let buyerLastName = buyerDetail[0]?.lastName
            ? buyerDetail[0]?.lastName
            : '';
          name = buyerName + ' ' + buyerLastName;
        } else {
          if (this.leadData?.secondaryContact) {
            name = this.leadData?.secondaryContact
              .map((secondary) => {
                if (secondary?.phoneNumber == number) {
                  return secondary?.name ? secondary?.name : 'N/A';
                }
              })
              .filter((x) => x);
          }
        }
      }
    }

    return name;
  }

  /**
   * FUNCTION: GET DASHBOARD SELLER NAME
   * @param {Number} number
   * @param {Object} log
   * @returns GET ACTIVITY SELLER NAME
   */
  getSellerName(number?, log?) {
    let name = '';
    if (log?.propertyData?.propertyId) {
      if (log?.propertyData?.buyers) {
        let propertyBuyer = log?.propertyData?.buyers
          .map((secondary) => {
            if (secondary?.contactNumber == number) {
              return secondary;
            }
          })
          .filter((x) => x);
        if (propertyBuyer?.length > 0) {
          let buyerName = propertyBuyer[0]?.firstName
            ? propertyBuyer[0]?.firstName
            : '';
          let buyerLastName = propertyBuyer[0]?.lastName
            ? propertyBuyer[0]?.lastName
            : '';
          name = buyerName + ' ' + buyerLastName;
        }
      } else if (log?.leadData) {
        if (number == log?.leadData?.phoneNumber) {
          name = log?.leadData?.title ? log?.leadData?.title : '';
        } else if (log?.leadData?.buyers) {
          let buyerDetail = log?.leadData?.buyers
            .map((buyers) => {
              if (buyers?.contactNumber == number) {
                return buyers;
              }
            })
            .filter((x) => x);
          if (buyerDetail?.length > 0) {
            let buyerName = buyerDetail[0]?.firstName
              ? buyerDetail[0]?.firstName
              : '';
            let buyerLastName = buyerDetail[0]?.lastName
              ? buyerDetail[0]?.lastName
              : '';
            name = buyerName + ' ' + buyerLastName;
          } else {
            if (log?.leadData?.secondaryContact) {
              name = log?.leadData?.secondaryContact
                .map((secondary) => {
                  if (secondary?.phoneNumber == number) {
                    return secondary?.name ? secondary?.name : '';
                  }
                })
                .filter((x) => x);
            }
          }
        }
      }
    } else if (log?.leadData) {
      if (number == log?.leadData?.phoneNumber) {
        name = log?.leadData?.title ? log?.leadData?.title : '';
      } else if (number == log?.leadData?.contactNumber) {
        let buyerName = log?.leadData?.firstName
          ? log?.leadData?.firstName
          : '';
        let buyerLastName = log?.leadData?.lastName
          ? log?.leadData?.lastName
          : '';
        name = buyerName + ' ' + buyerLastName;
      } else if (log?.leadData?.buyers) {
        let buyerDetail = log?.leadData?.buyers
          .map((buyers) => {
            if (buyers?.contactNumber == number) {
              return buyers;
            }
          })
          .filter((x) => x);
        if (buyerDetail?.length > 0) {
          let buyerName = buyerDetail[0]?.firstName
            ? buyerDetail[0]?.firstName
            : '';
          let buyerLastName = buyerDetail[0]?.lastName
            ? buyerDetail[0]?.lastName
            : '';
          name = buyerName + ' ' + buyerLastName;
        } else {
          if (log?.leadData?.secondaryContact) {
            name = log?.leadData?.secondaryContact
              .map((secondary) => {
                if (secondary?.phoneNumber == number) {
                  return secondary?.name ? secondary?.name : '';
                }
              })
              .filter((x) => x);
          }
        }
      } else if (log?.leadData?.secondaryContact && name == '') {
        name = log?.leadData?.secondaryContact
          .map((secondary) => {
            if (secondary?.phoneNumber == number) {
              return secondary?.name ? secondary?.name : '';
            }
          })
          .filter((x) => x);
      }
    }
    return name;
  }

  /**
   * FUNCTION: GET USER DETAILS
   * @param {String} id
   * @param {Boolean} bool
   * @returns GET USER NAME
   */
  getUserDetails(id, bool?) {
    const user = this.userListing.find((x) => x._id === id);
    let userName;
    if (bool) {
      if (user?.profileImage) {
        userName = user?.profileImage;
      } else {
        userName = '/assets/images/demo-profile.png';
      }
    } else {
      if (user?.firstName) {
        userName = `${user?.firstName} ${user?.lastName}`;
      } else {
        userName = 'N/A';
      }
    }

    return userName;
  }

  /**
   * FUNCTION: SHOW HIDE TOOLBAR
   */
  showHideToolbar() {
    this.showQuillToolbar = !this.showQuillToolbar;
  }

  /**
   * FUNCTION: CHECK COMMENT EXISTS OUR NOT
   * @returns COMMENT EXISTS OUR NOT
   */
  isCommentExists() {
    return !!(this.comment?.length || this.comment2);
  }

  /**
   * FUNCTION: GET CALL STATUS
   * @param {Boolean} isCallConnected
   * @param {Number} callStatusId
   * @param {Boolean} isLs
   * @returns CALL STATUS NAME
   */
  getCallStatus(isCallConnected, callStatusId, isLs) {
    if (isCallConnected) {
      if (!isLs) {
        return this.callConnectedStatus.filter(
          (x) => x?.value == callStatusId
        )[0].name;
      } else {
        if (callStatusId == 4) {
          return 'New leads';
        }
        return this.callConnectedDialerStatus.filter(
          (x) => x?.value == callStatusId
        )[0].name;
      }
    } else {
      return !isLs
        ? this.callNotConnectedStatus.filter((x) => x?.value == callStatusId)[0]
            .name
        : this.callNotConnectedDialerStatus.filter(
            (x) => x?.value == callStatusId
          )[0].name;
    }
  }

  /**
   * FUNCTION: COMMENT REPLACE NAME
   * @param {String} name
   * @returns COMMENT REPLACE
   */
  replaceLineBreak(name: string) {
    return name; //?.replace(/\<br\>/g, '');
  }

  /**
   * FUNCTION: CHECK MAIN USER OR NOT
   * @param {String} createdBy
   * @returns TRUE/FALSE
   */
  getDirection(createdBy) {
    if (createdBy == this.mainUserId && this.type != 'list-stacking') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * FUNCTION: CHANGE ACTIVITY PINNED OR ALL
   * @param {Boolean} isNew
   * @returns ARRAY OF ACTIVITY LOGS
   */
  changeNew(isNew) {
    this.isNew = isNew;
    this.isPinned = false;
    this.commentFilterData = {};
    this.isCommentFilterData = false;
    this.logs = {};
    this.scrollDisabled = true;
    this.currentActivityPage = 1;
    this.callAction(this.moduleName, true);
  }

  /**
   * FUNCTION: GET DASHBOARD PINNED COMMENT
   * @param {Boolean} isPinned
   * @returns ARRAY OF PINNED COMMENT
   */
  getPinComment(isPinned) {
    this.isPinned = isPinned;
    this.isNew = false;
    this.commentFilterData = {};
    this.isCommentFilterData = false;
    this.currentActivityPage = 1;
    this.callAction(this.moduleName, true);
  }

  /**
   * FUNCTION: GET DASHBOARD FILTER
   * @param {String} status
   * @returns ARRAY OF FILTER BY ACTIVITY LOGS
   */
  getFilterData(status) {
    if (status == 'all') {
      this.isAll = true;
      this.isIncoming = false;
      this.isOutgoing = false;
      this.isNew = false;
      this.commentFilterData = {};
      this.isCommentFilterData = false;
      this.currentActivityPage = 1;
      this.callAction(this.moduleName, true);
    } else if (status == 'incoming') {
      this.isAll = false;
      this.isIncoming = true;
      this.isOutgoing = false;

      this.isNew = false;
      this.commentFilterData = {};
      this.commentFilterData.activityType = [1, 2, 3];
      this.isCommentFilterData = true;
      this.currentActivityPage = 1;
      this.callAction(this.moduleName, true);
    } else {
      this.isAll = false;
      this.isIncoming = false;
      this.isOutgoing = true;
      this.isNew = false;
      this.commentFilterData = {};
      this.commentFilterData.activityType = [4, 5, 6];
      this.isCommentFilterData = true;
      this.currentActivityPage = 1;
      this.callAction(this.moduleName, true);
    }
    this._emitRefreshFilterData.emit(status);
  }

  /**
   * FUNCTION: CLEAR DASHBOARD FILTER
   */
  clearActivityBadge() {
    this.dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: {
        yesTitle: 'Yes',
        noTitle: 'Cancel',
        header: 'Confirmation',
        text: 'Are you sure you want to Clear New?',
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let param = { clearType: 1 };
        if (this.dashboardCommunicationView) {
          param = { clearType: 2 };
        }
        this._loaderService.start();
        this._dashboardService.clearActivityBadge(param).subscribe(
          (response: ResponseModel) => {
            this._loaderService.stop();
            this.commentFilterData = {};
            this.isCommentFilterData = false;
            this.currentActivityPage = 1;
            this.isAll = true;
            this.isIncoming = false;
            this.isOutgoing = false;
            this.callAction(this.moduleName, true);
            if (!this.isCommunicationTabList) {
              this.getActivityBadge();
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
          }
        );
      }
    });
  }

  /**
   * FUNCTION: GET ACTIVITY BADGE COUNT
   * @returns ACTIVITY BADGE COUNT
   */
  getActivityBadge() {
    let param = {
      browserTimeZoneOffset: this._utilities.dueDateFormat().browserTimezone,
    };

    this._dashboardService.getActivityBadgeCount(param).subscribe(
      (response: ResponseModel) => {
        this._utilities.newCount = response?.data;
      },
      (err: ErrorModel) => {}
    );
  }

  /**
   * FUNCTION: CHECK SET DASHBOARD NEW COMMENT
   * @param {Object} item
   * @returns TRUE/FALSE
   */
  setNewData(item) {
    if (this.activityDashboardComment || this.dashboardCommunicationView) {
      if (this.isNew) {
        return true;
      } else if (
        item?.createdBy != this.mainUserId &&
        item?.badgeUsers.indexOf(this.mainUserId) !== -1
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * FUNCTION: CHECK DASHBOARD COMMENT LABEL VIEW
   * @param {Object} item
   * @returns TRUE/FALSE
   */
  setLabelView(item) {
    if (this.activityDashboardComment || this.dashboardCommunicationView) {
      if (
        this.dashboardCommunicationView &&
        item.direction != 'incomingSms' &&
        item.direction != 'incomingCall' &&
        item.direction != 'incomingMms'
      ) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  /**
   * FUNCTION: DASHBOARD CLEAR SINGLE ACTIVITY BADGE
   * @param {Object} item
   * @param {Number} index
   * @returns ACTIVITY BADGE COUNT
   */
  async clearSingleActivityBadge(item, index) {
    this._loaderService.start();
    if (!this.isNew && item?.badgeUsers.indexOf(this.mainUserId) !== -1) {
      item?.badgeUsers.forEach((element, index) => {
        if (element == this.mainUserId) item?.badgeUsers.splice(index, 1);
      });
    }
    let res = this.setActivityBadge(item);
    if (res) {
      if (this.isNew) {
        this.logs.items.splice(index, 1);
      }
      if (!this.isNew && item?.badgeUsers.indexOf(this.mainUserId) !== -1) {
        this.logs.items.splice(index, 1);
      }
      if (this.isNew) {
        if (this._utilities.newCount > 0) {
          this._utilities.newCount = this._utilities.newCount - 1;
        }
      } else {
        if (this._utilities.newCount >= 0) {
          this._utilities.newCount = this._utilities.newCount + 1;
        }
      }
      this._loaderService.stop();
    } else {
      this._loaderService.stop();
    }
  }

  /**
   * FUNCTION: MOVE TO LEAD DETAILS
   * @param {Object} item
   * @returns MOVE TO LEADS
   */
  async moveToDetails(item) {
    if (item?.isShowBadge) {
      let res = await this.setActivityBadge(item);
      if (this._utilities.newCount > 0) {
        this._utilities.newCount = this._utilities.newCount - 1;
      }
    }
    let link;

    if (
      item?.leadData?.mainStatusId == StatusConstant.LeadStatus.NEW_LEAD ||
      item?.leadData?.mainStatusId ==
        StatusConstant.LeadStatus.NO_CONTACT_MADE ||
      item?.leadData?.mainStatusId == StatusConstant.LeadStatus.CONTACT_MADE ||
      item?.leadData?.mainStatusId ==
        StatusConstant.LeadStatus.APPOINTMENT_SET ||
      item?.leadData?.mainStatusId == StatusConstant.LeadStatus.DUE_DILIGENCE ||
      item?.leadData?.mainStatusId == StatusConstant.LeadStatus.OFFER_MADE ||
      item?.leadData?.mainStatusId ==
        StatusConstant.LeadStatus.UNDER_CONTRACT ||
      item?.leadData?.mainStatusId ==
        StatusConstant.LeadStatus.ASSIGN_TO_BUYER ||
      item?.leadData?.mainStatusId == StatusConstant.LeadStatus.WARM_LEAD ||
      item?.leadData?.mainStatusId == StatusConstant.LeadStatus.DEAD_LEAD ||
      item?.leadData?.mainStatusId ==
        StatusConstant.LeadStatus.REFERRED_TO_AGENT
    ) {
      localStorage.setItem('RE-TAB', 'lead-details');
      link = `/leads/details?leadsId=${item?.leadData?.leadId}`;
    } else if (
      item?.propertyData?.mainStatusId == StatusConstant.InventoryStatus.SOLD
    ) {
      localStorage.setItem('RE-TAB', 'project-info');
      link = `/sold/details?soldId=${item?.propertyData?.propertyId}`;
    } else if (
      item?.propertyData?.mainStatusId == StatusConstant.InventoryStatus.RENTAL
    ) {
      localStorage.setItem('RE-TAB', 'project-info');
      link = `/rental/details?rentalId=${item?.propertyData?.propertyId}`;
    } else if (
      item?.propertyData?.mainStatusId ==
        StatusConstant.InventoryStatus.NEW_INVENTORY ||
      item?.propertyData?.mainStatusId ==
        StatusConstant.InventoryStatus.GETTING_ESTIMATES ||
      item?.propertyData?.mainStatusId ==
        StatusConstant.InventoryStatus.UNDER_REHAB ||
      item?.propertyData?.mainStatusId ==
        StatusConstant.InventoryStatus.LISTED_FOR_RENT ||
      item?.propertyData?.mainStatusId ==
        StatusConstant.InventoryStatus.LISTED_FOR_SALE ||
      item?.propertyData?.mainStatusId ==
        StatusConstant.InventoryStatus.SELL_SIDE_UNDER_CONTRACT
    ) {
      localStorage.setItem('RE-TAB', 'project-info');
      link = `/inventory/details?inventoryId=${item?.propertyData?.propertyId}`;
    } else {
      localStorage.setItem('RE-TAB', 'project-info');
      link = `/inventory`;
    }

    this._route.navigate([]).then((result) => {
      window.open(link, '_blank');
    });
  }

  /**
   * FUNCTION: DASHBOARD SET NEW ACTIVITY BADGE LOGS
   * @param {Object} item
   * @returns ARRAY OF ITEM IN ACTIVITY BADGE
   */
  async setActivityBadge(item) {
    return new Promise(async (resolve, reject) => {
      let param = {
        badgeUsers: item?.badgeUsers,
        commentId: item?._id,
        addBadgeUsers: this.isNew ? false : true,
      };
      this._loaderService.start();
      this._dashboardService.setActivityBadge(param).subscribe(
        (response: ResponseModel) => {
          item.isShowBadge = false;
          this._loaderService.stop();
          resolve(true);
        },
        (err: ErrorModel) => {
          item.isShowBadge = false;
          this._loaderService.stop();
          resolve(false);
        }
      );
    });
  }

  /**
   * FUNCTION: DASHBOARD CHECK NEW COMMENT
   * @param {Object} item
   * @param {Boolean} bool
   * @returns ACTIVITY IMAGE ICON PATH
   */
  checkIsNewData(item, bool?) {
    if (this.isNew) {
      return bool
        ? 'assets/images/re-activities-icon.svg'
        : 'assets/images/re-activities-icon-1.svg';
    } else {
      if (item?.badgeUsers.indexOf(this.mainUserId) !== -1) {
        return 'assets/images/re-activities-icon.svg';
      } else {
        return 'assets/images/re-activities-icon-1.svg';
      }
    }
  }

  /**
   * FUNCTION: GET STATUS LABEL
   * @param {String} mainStatusId
   * @returns STATUS LABEL NAME
   */
  getStatusLabel(mainStatusId) {
    let label = 'N/A';
    const status = this.statusLabel?.find(
      (status) => status._id === mainStatusId
    );

    if (status) {
      label = status?.labels?.title || status?.title;
    } else {
      label = this._utilities.getMainStatusTitleById(mainStatusId);
    }

    return this._utilities.titleCaseToWord(label) || 'N/A';
  }

  /**
   * FUNCTION: REPLY OF COMMENT
   * @param {Object} item
   * @returns REPLY OF COMMENT AND ACTIVITY BADGE COUNT
   */
  onReplyComment(item) {
    this.dialogRef = this._dialog.open(ModalReplyCommentComponent, {
      width: '600px',
      data: {
        item,
      },
    });

    this.dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        if (item?.isShowBadge) {
          await this.setActivityBadge(item);
          if (this._utilities.newCount > 0) {
            this._utilities.newCount = this._utilities.newCount - 1;
          }
        }
        this.currentActivityPage = 1;
        this.callAction(this.moduleName, true);
      }
    });
  }

  /**
   * FUNCTION: REPLAY BY CALL
   * @param {Object} item
   * @returns REPLAY BY CALL AND COUNT ACTIVITY LOGS
   */
  onReplayCall(item) {
    this._loaderService.start();
    this._creditService.getCallCredit({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.callBalance = response?.data;

          let obj = {
            callBalance: this.callBalance,
            name: item?.firstName,
            phoneNumber: item?.from,
            leadSource: item?.marketingNumber,
            userId: item.createdBy,
            leadId: item.leadData?.leadId,
            moduleId: this.leadModuleId,
          };
          if (this._utilities.userAccessData?.isDialerAccess) {
            if (this._utilities.isDialogOpen) {
              if (
                this._utilities.dialogcurrentLeadId == item.leadData?.leadId
              ) {
                this._sharedService.refreshDialerToggleDisplay.next(true);
                return false;
              } else {
                this._toastrService.warning(
                  this.messageConstant.callUnderProgress
                );
                this._loaderService.stop();
                return false;
              }
            }
            if (this.callBalance <= 0) {
              this.dialogRef = this._dialog.open(CallDetailDailogsComponent, {
                width: '400px',
                data: obj,
                disableClose: true,
                closeOnNavigation: false,
                backdropClass: 'popup-Backdrop-Class-dialer',
              });
            } else {
              this._utilities.isDialogOpen = true;
              this._utilities.dialogcurrentLeadId = item.leadData?.leadId;
              this.dialogRef = this._dialog.open(LeadDialerComponent, {
                width: '1366px',
                id: item.leadData?.leadId,
                disableClose: true,
                closeOnNavigation: false,
                backdropClass: 'popup-Backdrop-Class-dialer',
                data: {
                  leadId: item.leadData?.leadId,
                  isHideNext: false,
                },
              });
              this.dialogRef.afterClosed().subscribe((result) => {
                this._utilities.isDialogOpen = false;
                if (result?.isEdit) {
                }
              });
            }
          } else {
            //===== ELSE
            if (this._utilities.isDialogOpen) {
              if (
                this._utilities.dialogcurrentLeadId == item.leadData?.leadId
              ) {
                this._sharedService.refreshCallerToggleDisplay.next(true);
                return false;
              } else {
                this._toastrService.warning(
                  this.messageConstant.callUnderProgress
                );
                this._loaderService.stop();
                return false;
              }
            }
            this._utilities.isDialogOpen = true;
            this._utilities.dialogcurrentLeadId = item.leadData?.leadId;
            this.dialogRef = this._dialog.open(CallDetailDailogsComponent, {
              width: '400px',
              data: obj,
              disableClose: true,
              closeOnNavigation: false,
              backdropClass: 'popup-Backdrop-Class-dialer',
            });
            this.dialogRef.afterClosed().subscribe((result) => {
              this._utilities.isDialogOpen = false;
              this.isNew = false;
              this.callAction(this.moduleName, true);
            });
            //===== ELSE
          }

          if (item?.isShowBadge) {
            this.setActivityBadge(item);
          }
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  /**
   * FUNCTION: REPLAY BY MESSAGE
   * @param {Object} item
   * @returns REPLAY BY MESSAGE AND COUNT ACTIVITY LOGS
   */
  onReplayMessage(item) {
    this._loaderService.start();
    this._creditService.getSMSCredit({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode == 200) {
          this.SMSBalance = response?.data;

          if (item?.leadData?.secondaryContact) {
            if (
              item?.leadData?.email ||
              item?.leadData?.title ||
              item?.leadData?.phoneNumber
            ) {
              const obj = {
                email: item?.leadData?.email,
                name: item?.leadData?.title,
                phoneNumber: item?.leadData?.phoneNumber,
                contactType: 'seller',
                isOwnerContact: true,
              };
              item?.leadData?.secondaryContact.unshift(obj);
            }
          }

          let sellerFilterArray = item?.leadData?.secondaryContact.filter(
            (x) => {
              return x.phoneNumber === item?.from;
            }
          );

          let contact = {};
          if (sellerFilterArray.length > 0) {
            let sellerObj = sellerFilterArray[0];
            contact = {
              name: sellerObj?.name ? sellerObj?.name : '',
              contactType: sellerObj?.contactType,
              phoneNumber: sellerObj?.phoneNumber,
            };
          } else {
            let buyerFilterArray = item?.leadData?.buyers.filter((x) => {
              return x.contactNumber === item?.from;
            });
            if (buyerFilterArray.length > 0) {
              let buyerObj = buyerFilterArray[0];
              contact = {
                name: buyerObj?.firstName ? buyerObj?.firstName : '',
                contactType: buyerObj?.contactType,
                phoneNumber: buyerObj?.contactNumber,
              };
            }
          }
          let obj = {
            SMSBalance: this.SMSBalance,
            name: item?.firstName,
            phoneNumber: item?.from,
            leadSource: item?.marketingNumber,
            userId: item?.createdBy,
            leadId: item?.leadData?.leadId,
            contacts: [contact],
            moduleId: this.leadModuleId,
            changeNumber: false,
            addressLead: item?.leadData?.address
              ? this._utilities.formatAddress(
                  item?.leadData?.address,
                  item?.unitNo
                )
              : ' ',
          };

          this.dialogRef = this._dialog.open(SmsDialogComponent, {
            width: '500px',
            data: obj,
          });
          this.dialogRef.afterClosed().subscribe((result) => {
            this.isNew = false;
            this.callAction(this.moduleName, true);
          });

          if (item?.isShowBadge) {
            this.setActivityBadge(item);
          }
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  /**
   * FUNCTION: GET COMMENT TEXT LIST
   * @param {Object} item
   * @returns COMMENT TEXT
   */
  getCommentTextList(item) {
    let commentBody;
    if (this.activityDashboardComment || this.dashboardCommunicationView) {
      if (this.activityDashboardComment) {
        if (this.isUserListResponse) {
          commentBody = item?.comment;
        }
      } else if (this.dashboardCommunicationView) {
        if (item?.smsBody) {
          commentBody = item?.smsBody;
        } else if (
          item?.comment != 'SMS Received' &&
          item?.comment != 'You placed a call to' &&
          item?.comment != 'You received a call' &&
          item?.comment != 'You got a missed call' &&
          item?.activityType != '2' &&
          item?.comment &&
          this.isUserListResponse
        ) {
          commentBody = item?.comment;
        }
      }
      return commentBody;
    } else {
      return '';
    }
  }

  /**
   * FUNCTION: Call Transcript Dialog
   * @param {String} html
   * @returns VIEW OF ACTIVITY COMMENT
   */
  async callTranscriptDialog(data: any) {
    if (!data?.isAiCallTranscript) {
      let aiCallSummaryCredit = await this.getAiCallTranscriptionCredit();
      if (
        aiCallSummaryCredit['credit'] <= 0 &&
        !aiCallSummaryCredit['isAiCallTranscriptionUnlimited']
      ) {
        this.upgardePlan();
        return;
      }
    }
    let payload = {
      sellerNumber: this.activityLogTextView(data, 'headerText')?.number,
      sellerName: this.activityLogTextView(data, 'headerText')?.sellerViewName,
      recording: data?.recording,
      fromNumber: this.activityLogTextView(data, 'bodyText')?.number
        ? this.activityLogTextView(data, 'bodyText')?.doteView +
          this.activityLogTextView(data, 'bodyText')?.number
        : '',
      fromName: this.activityLogTextView(data, 'bodyText')?.headerText,
      icon: this.activityLogTextView(data, 'images')?.imageUrl,
      direction: this.activityLogTextView(data, 'bodyText')?.bodyType,
      callUuid: data?.callUuid,
      activityId: data?._id,
      leadId: data?.subModuleId,
    };
    this.dialogRef = this._dialog.open(CallTranscriptDialogComponent, {
      backdropClass: 're-call-transcript-modal',
      width: '430px',
      data: payload,
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      let activityId = localStorage.getItem('aiCallTranscipt');
      if (activityId) {
        let index = this.logs.items.findIndex((x) => x?._id == activityId);
        this.logs.items[index].isAiCallTranscript = true;
        localStorage.removeItem('aiCallTranscipt');
      }
    });
  }

  getAiCallTranscriptionCredit() {
    return new Promise(async (resolve, reject) => {
      this._creditService.getAiCallTranscriptionCredit({}).subscribe(
        (response: ResponseModel) => {
          if (response?.statusCode && response?.statusCode == 200) {
            resolve(response?.data);
          } else {
            this._toastrService.error(response?.message, '');
          }
          resolve('');
        },
        (err: ErrorModel) => {
          if (err.error) {
            // const error: ResponseModel = err.error;
            // this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
          resolve('');
        }
      );
    });
  }

  upgardePlan() {
    this.dialogRef = this._dialog.open(TranscriptionPlanUpgradeComponent, {
      panelClass: 're-dlc-status-modal',
      disableClose: true,
      width: '800px',
      data: {},
    });
  }

  callTranscriptCreditManage() {
    this.dialogRef = this._dialog.open(CallTranscriptCreditManageComponent, {
      panelClass: 're-ai-credit-modal',
      width: '400px',
      data: {},
    });
  }

  pinnedShow() {
    this.dialogRef = this._dialog.open(PinnedNotesViewDialogComponent, {
      backdropClass: 're-pinned-notes-modal',
      disableClose: true,
      width: '430px',
      data: {
        moduleId: this.moduleId,
        subModuleId: this.inventoryId ? this.inventoryId : this.leadId,
        isInventory: this.isInventory,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let data = result;
        if (data?.action == 'edit') {
          const index = this.logs?.items.findIndex(
            (obj) => obj._id == data?.data._id
          );
          if (index !== -1) {
            this.selectedIndex = index;
            this.logs.items[index]['select'] = true;
          }
          this.action = data?.action;
          this.selectedLog = data?.data;
          this.comment = data?.data.comment.replace(/<br \/>/g, '\r\n');
        }
      }
    });
  }
}
