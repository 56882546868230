import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

// SERVICES
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VendorsService } from 'src/app/providers/vendors/vendors.service';
import { ToastrService } from 'ngx-toastr';

// COMPONENTS
import { ViewHtmlDialogComponent } from '../view-html-dialog/view-html-dialog.component';
import { ListViewComponent } from '../../list-view/list-view.component';

// UTILS
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';

@Component({
  selector: 'app-campaign-stats',
  templateUrl: './campaign-stats.component.html',
  styleUrls: ['./campaign-stats.component.scss'],
})
export class CampaignStatsComponent implements OnInit {
  @ViewChild('listView') listView: ListViewComponent;

  paginationType = MiscellaneousConstant.paginationType.INFINITE;
  currentPage: number = 1;
  currentLimit: number = MiscellaneousConstant.paginationLimit.INFINITE;

  showPagination: boolean = true;

  dialogRef: any;

  title: string;

  header: any[];
  stats: any;

  recipientHeader: any[] = [
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: 'Schedule Send',
      key: 'createdAt',
      pipe: 'DATE',
    },
  ];
  deliveredHeader: any[] = [
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: 'Delivered On',
      key: 'deliveredAt',
      pipe: 'DATE',
    },
  ];
  uniqueOpenHeader: any[] = [
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: 'Times Opened',
      key: 'openCount',
      style: { textAlign: 'center' },
    },
    {
      label: 'Delivered On',
      key: 'deliveredAt',
      pipe: 'DATE',
    },
    {
      label: 'Last Opened',
      key: 'openAt',
      pipe: 'DATE',
    },
  ];
  uniqueClickHeader: any[] = [
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: 'Times Opened',
      key: 'openCount',
      style: { textAlign: 'center' },
    },
    {
      label: 'Times Clicked',
      key: 'clickCount',
      style: { textAlign: 'center' },
    },
    {
      label: 'Delivered On',
      key: 'deliveredAt',
      pipe: 'DATE',
    },
    {
      label: 'Last Opened',
      key: 'openAt',
      pipe: 'DATE',
    },
  ];
  unsubscribedHeader: any[] = [
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: 'Unsubscribed On',
      key: 'unsubscribeAt',
      pipe: 'DATE',
    },
  ];

  smsNotDeliveredHeader: any[] = [
    {
      label: 'Phone',
      key: 'contactNumber',
      pipe: 'PHONE',
    },
    {
      label: 'Not Delivered At',
      key: 'updatedAt',
      pipe: 'DATE',
    },
  ];
  smsDeliveredHeader: any[] = [
    {
      label: 'Phone',
      key: 'contactNumber',
      pipe: 'PHONE',
    },
    {
      label: 'Delivered',
      key: 'updatedAt',
      pipe: 'DATE',
    },
  ];
  smsSentHeader: any[] = [
    {
      label: 'Phone',
      key: 'contactNumber',
      pipe: 'PHONE',
    },
    {
      label: 'Sent',
      key: 'createdAt',
      pipe: 'DATE',
    },
  ];
  smsRecipientHeader: any[] = [
    {
      label: 'Phone',
      key: 'contactNumber',
      pipe: 'PHONE',
    },
    {
      label: 'Schedule Send',
      key: 'createdAt',
      pipe: 'DATE',
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _loaderService: NgxUiLoaderService,
    private _vendorService: VendorsService,
    private dialog: MatDialog,
    private statsDialogRef: MatDialogRef<CampaignStatsComponent>,
    private _toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    if (this.data.type === 'EMAIL') {
      this.getEmailStats(true);
    } else if (this.data.type === 'SMS') {
      this.showPagination = false;
      this.getSMSStats();
    }
  }

  changePage($event) {
    if (this.data.type === 'SMS') return;

    this.currentPage = $event;
    this.getEmailStats();
  }

  getEmailStats(bool?) {
    if (bool) {
      this.currentPage = 1;
    }

    let obj = {
      page: this.currentPage,
      limit: this.currentLimit,
      campaignId: this.data.item?.emailCampaignId?._id || this.data.item._id,
    };

    switch (this.data.action.label) {
      case 'Recipients':
        this.title = 'Campaign Recipients';
        this.header = this.recipientHeader;
        break;
      case 'Delivered':
        this.title = 'Email Delivered';
        this.header = this.deliveredHeader;
        obj['status'] = 'delivered';
        break;
      case 'Unique Open':
        this.title = 'Email Opened';
        this.header = this.uniqueOpenHeader;
        obj['status'] = 'open';
        break;
      case 'Unique Click':
        this.title = 'Email Clicked';
        this.header = this.uniqueClickHeader;
        obj['status'] = 'click';
        break;
      case 'Unsubscribed':
        this.header = this.unsubscribedHeader;
        this.title = 'Email Unsubscribed';
        obj['status'] = 'group_unsubscribe';
        break;
    }

    this._loaderService.start();
    this._vendorService.getEmailStatsById(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._loaderService.stop();

          if (this.data.action.label == 'Campaign') {
            this.statsDialogRef.close();
            this.dialogRef = this.dialog.open(ViewHtmlDialogComponent, {
              width: '800px',
              data: {
                title:
                  this.data.item?.emailCampaignId?.name || this.data.item?.name,
                content: response.data['htmlContent'],
              },
            });

            return;
          }

          if (bool) {
            this.stats = response?.data?.result;

            if (
              this.paginationType ===
              MiscellaneousConstant.paginationType.STANDARD
            ) {
              this.listView.pagination.count =
                response?.data?.result?.count || 0;
              this.listView.pagination.setPage(1, true);
            }
          } else {
            // STANDARD PAGINATION
            if (
              this.paginationType ===
              MiscellaneousConstant.paginationType.STANDARD
            ) {
              this.stats.items = response.data?.result?.items;
            }

            // INFINITE PAGINATION
            if (
              this.paginationType ===
              MiscellaneousConstant.paginationType.INFINITE
            ) {
              this.stats.items = this.stats.items
                ? [...this.stats.items, ...response.data?.result?.items]
                : response.data?.result?.items;
            }
          }
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }

  getSMSStats() {
    this._loaderService.start();
    this._vendorService
      .getSMSstatsById({ campaignId: this.data.item._id })
      .subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this._loaderService.stop();
            const item = response.data;
            let list = [];

            switch (this.data.action.label) {
              case 'Message':
                this.statsDialogRef.close();
                this.dialogRef = this.dialog.open(ViewHtmlDialogComponent, {
                  width: '800px',
                  data: {
                    title: item?.name || item?.campaignName,
                    content: item['message']?.replaceAll('\n', '<br />'),
                  },
                });
                return;
              case 'Recipients':
                this.title = 'Campaign Recipients';
                this.header = this.smsRecipientHeader;
                list = item?.newSmsStats;
                this.stats = { count: list.length, items: list };
                break;
              case 'Sent':
                this.title = 'SMS Sent';
                this.header = this.smsSentHeader;
                list = item?.newSmsStats;
                this.stats = { count: list.length, items: list };
                break;
              case 'Delivered':
                this.title = 'SMS Delivered';
                this.header = this.smsDeliveredHeader;
                list = item?.newSmsStats?.filter(
                  (x) => x.status === 'delivered'
                );
                this.stats = { count: list.length, items: list };
                break;
              case 'Not Delivered':
                this.title = 'SMS Not Delivered';
                this.header = this.smsNotDeliveredHeader;
                list = item?.newSmsStats?.filter(
                  (x) => x.status === 'undelivered'
                );
                this.stats = { count: list.length, items: list };
                break;
            }

            if (!item?.newSmsStats?.length) {
              this._toastrService.error(MessageConstant.noDataAvailable);
              return;
            }
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(MessageConstant.unknownError, '');
          }
        }
      );
  }
}
