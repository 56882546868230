<div class="re-top-head">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      Change Number
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<form
  [formGroup]="changeNumberForm"
  (ngSubmit)="onChangeNumber()"
  autocomplete="off"
  novalidate
>
  <mat-dialog-content class="">
    <div class="container-fluid">
      <div class="box">
        <div class="row">
          <div class="col-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label class="labels"
                >Select number to use for Call , SMS and RVM</label
              >
              <select
                class="form-control"
                name="numberId"
                formControlName="numberId"
              >
                <option value="">Select Number</option>
                <option
                  *ngFor="let campaign of data?.numberList"
                  [value]="campaign?.number"
                >
                  {{ campaign.marketingTitle }}
                  {{ _utilities.maskNumber(campaign.number) }}
                </option>
              </select>
              <div class="text-danger show-error">
                <span *ngIf="submitted && hasError('numberId', 'required')">
                  {{ messageConstant.requiredField.replace("Field", "Number") }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center" style="padding-top: 14px !important">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" type="submit" cdkFocusInitial>
      Continue
    </button>
  </mat-dialog-actions>
</form>
