import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-thank-you-for-registration',
  templateUrl: './thank-you-for-registration.component.html',
  styleUrls: ['./thank-you-for-registration.component.scss'],
})
export class ThankYouForRegistrationComponent implements OnInit {
  constructor(
    private _location: Location,
    @Inject(DOCUMENT) private _document: Document,
    private _renderer2: Renderer2
  ) {}

  ngOnInit(): void {
    // if (localStorage.getItem('isNewSignup') == 'true') {
    //   (<any>window).gtag('event', 'conversion', {
    //     send_to: 'AW-773287020/VbQUCIua6LwDEOzY3fAC',
    //   });
    //   localStorage.removeItem('isNewSignup');
    // } else {
    //   this.login();
    // }
    let userData = history.state.userData;
    if (userData) {
      this.loadDataLayer(userData);
    }

    this.loadScript();
    (<any>window).gtag('event', 'conversion', {
      send_to: 'AW-773287020/ZLVTCMeU8OEDEOzY3fAC',
    });
    (<any>window).rdt('track', 'SignUp');

    // if (localStorage.getItem('isNewSignup') != 'true') {
    //   this.login();
    // }
  }
  login() {
    localStorage.removeItem('isNewSignup');
    setTimeout(() => {
      this._location.go('/login');
      location.reload();
    }, 100);
  }

  loadScript() {
    let script1 = this._renderer2.createElement('script');
    script1.type = `text/javascript`;
    script1.text = `
    console.log("sfvbgyuef hevf sdv ")
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "AW-773287020");
    gtag('event', 'conversion', {
      send_to: 'AW-773287020/ZLVTCMeU8OEDEOzY3fAC',
    });
    console.log("sfvbgyuef hevf sdv ")
        `;

    this._renderer2.appendChild(this._document.body, script1);
  }

  loadDataLayer(data: any) {    
    (<any>window).dataLayer = (<any>window).dataLayer || [];
    (<any>window).dataLayer.push({
      event: 'purchase',
      user_id: data?.user_id,
      ecommerce: {
        transaction_id: data?.transactionId,
        currency: 'USD',
        value: data?.isYearlyPlan ? (data?.price / 100) * 12 : data?.price / 100,
        items: [
          {
            item_id: data?.planId,
            item_name: data?.planName,
            affiliation: data?.couponCode,
            item_brand: 'ReSimpli',
            item_category: 'Pricing',
            item_list_name: 'Pricing',
            item_variant: data?.isYearlyPlan ? 'Yearly' : 'Monthly',
            price: data?.price / 100,
            quantity: 1,
          },
        ],
      },
    });
  }
}
