import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// UTILS
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';
import { UserService } from 'src/app/providers/user/user.service';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

@Component({
  selector: 'app-onboarding-call-dailog',
  templateUrl: './onboarding-call-dailog.component.html',
  styleUrls: ['./onboarding-call-dailog.component.scss'],
})
export class OnboardingCallDailogComponent implements OnInit {
  messageConstant = MessageConstant;
  isShowSchedule: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialog: MatDialogRef<OnboardingCallDailogComponent>,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private _userService: UserService,
    public _utilities: CommonFunctionsService
  ) {}

  ngOnInit(): void {}

  onBoardingStatusUpdate() {
    let param = {
      isOnBoardingCallScheduled: true,
    };

    this._loaderService.start();
    this._userService.updateOnBoardingCallStatus(param).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode == 200) {
          this._utilities.userData.loginData.isOnBoardingCallScheduled = true;
          this._dialog.close(true);
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  isShowScheduleDialog() {
    this.isShowSchedule = true;
  }
}
