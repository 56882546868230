import { ActivityLogConstant } from '../activityLog-constant';
export class ActivityLogModel {
  public static activityLogArray = [
    {
      activityType: ActivityLogConstant.ActivityType.APPOINTMENT,
      imageUrl: '/assets/images/Appointment-new-color.png',
    },
    {
      activityType: ActivityLogConstant.ActivityType.FOLDER,
      imageUrl: '/assets/images/Folders-added-new-color.png',
    },
    {
      activityType: ActivityLogConstant.ActivityType.FILE,
      imageUrl: '/assets/images/Files-added-new-color.png',
    },
    {
      activityType: ActivityLogConstant.ActivityType.DRIPASSIGN,
      imageUrl: '/assets/images/Drip-on-new-color.png',
    },
    {
      activityType: ActivityLogConstant.ActivityType.DRIPUNASSIGN,
      imageUrl: '/assets/images/Drip-off-new-color.png',
    },
    {
      activityType: ActivityLogConstant.ActivityType.ESIGN,
      imageUrl: '/assets/images/eSign-sent-new-color.png',
    },
    {
      activityType: ActivityLogConstant.ActivityType.TASK,
      imageUrl: '/assets/images/Task-added-new-color.png',
      subType: [
        {
          type: ActivityLogConstant.ActivitySubType.TASKCREATE,
          imageUrl: '/assets/images/Task-added-new-color.png',
        },
        {
          type: ActivityLogConstant.ActivitySubType.TASKUPDATE,
          imageUrl: '/assets/images/Task-updated-new-color.png',
        },
        {
          type: ActivityLogConstant.ActivitySubType.TASKCOMPLETED,
          imageUrl: '/assets/images/Task-done-new-color.png',
        },
      ],
    },
    {
      activityType: ActivityLogConstant.ActivityType.STATUS,
      subType: [
        {
          type: ActivityLogConstant.ActivitySubType.HOTLEAD,
          imageUrl: '/assets/images/Hot-lead-on.png',
        },
        {
          type: ActivityLogConstant.ActivitySubType.UNHOTLEAD,
          imageUrl: '/assets/images/Hot-lead-off.png',
        },
      ],
      comment: { imageUrl: '/assets/images/Status-changed-new-color.png' },
    },
    {
      activityType: ActivityLogConstant.ActivityType.API,
      subType: [
        {
          type: ActivityLogConstant.ActivitySubType.ZAPIER,
          imageUrl: '/assets/images/Zapier.png',
          headerText: 'Zapier',
        },
        {
          type: ActivityLogConstant.ActivitySubType.CARROT,
          imageUrl: '/assets/images/Carrot.png',
          headerText: 'Carrot',
        },
        {
          type: ActivityLogConstant.ActivitySubType.WEBSITE,
          imageUrl: '/assets/images/navbar/Websites.svg',
          headerText: 'Website',
        },
        {
          type: ActivityLogConstant.ActivitySubType.DIALERMOVELEAD,
          imageUrl: '/assets/images/ColdCalling-Activity-Log-Icon.svg',
        },
        {
          type: ActivityLogConstant.ActivitySubType.LSMOVELEAD,
          imageUrl: '/assets/images/Liststacking-navigation-hover.svg',
        },
      ],
    },
    {
      activityType: ActivityLogConstant.ActivityType.TAGS,
      subType: [
        {
          type: ActivityLogConstant.ActivitySubType.TAGADD,
          imageUrl: '/assets/images/Tag-added.png',
        },
        {
          type: ActivityLogConstant.ActivitySubType.TAGREMOVE,
          imageUrl: '/assets/images/Tag-removed.png',
        },
      ],
    },
    {
      activityType: ActivityLogConstant.ActivityType.LIST,
      subType: [
        {
          type: ActivityLogConstant.ActivitySubType.LISTADD,
          imageUrl: '/assets/images/List-added.png',
        },
        {
          type: ActivityLogConstant.ActivitySubType.LISTREMOVE,
          imageUrl: '/assets/images/List-removed.png',
        },
      ],
    },
    {
      activityType: ActivityLogConstant.ActivityType.WEBHOOKTRIGGER,
      imageUrl: '/assets/images/added-via-webhook.svg',
    },
  ];

  public static activityHeaderArray = [
    {
      activityType: ActivityLogConstant.ActivityType.API,
      subType: [
        {
          type: ActivityLogConstant.ActivitySubType.ZAPIER,
          headerText: 'Zapier',
        },
        {
          type: ActivityLogConstant.ActivitySubType.CARROT,
          headerText: 'Carrot',
        },
        {
          type: ActivityLogConstant.ActivitySubType.WEBSITE,
          headerText: 'Website',
        },
      ],
    },
  ];
}
