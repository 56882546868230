<mat-dialog-content class="re-upgrade">
  <div class="main_modal">
    <span class="close_btn" matDialogClose mat-dialog-close>
      <img src="assets/images/close-white.svg" alt="close" />
    </span>

    <img class="img" src="assets/images/mask-group-4.png" />
    <h4>Upgrade to unlock this feature</h4>
    <p>This feature is available in {{istextView ? 'Pro &' : ''}} Enterprise Plans only.</p>
    <p>
      Here's a quick demo video on how this feature will help your team close
      more deals.
    </p>
    <p><a href="javascript:;">Click here</a> for demo video.</p>
    <div class="text-center mx-2">
      <button type="button" class="upgrade_btn" (click)="upgrade()">
        Upgrade
      </button>
    </div>
  </div>
</mat-dialog-content>
