<div class="re-top-head">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      User List
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<mat-dialog-content class="">
  <div class="container">
    <div class="re-boxes">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12">
          <h4 class="re-title1">Property Address</h4>
          <h5 class="re-title2">
            {{ data.leadData.address ? data.leadData.address : "N/A" }}
          </h5>
          <div class="re-usrlist">
            <div
              class="re-lists"
              *ngFor="let assignUsers of data.leadData?.assignUserData"
            >
              <img
                class="imgs"
                [src]="
                  assignUsers.img
                    ? assignUsers.img
                    : 'assets/images/demo-profile.png'
                "
                alt="user"
              />
              <span>{{ assignUsers?.fullName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
