import {
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  Inject,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from '@angular/material/autocomplete';

//service
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MarketsService } from 'src/app/providers/markets/markets.service';
import { ToastrService } from 'ngx-toastr';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { SharedService } from 'src/app/shared/shared.service';
import { LeadsService } from 'src/app/providers/leads/leads.service';
import { TaggingService } from '../../../providers/tagging/tagging.service';
import { DripService } from 'src/app/providers/drip/drip.service';

//utils
import { MessageConstant } from './../../../utils/message-constant';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { environment } from 'src/environments/environment';
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';
import { StatusConstant } from 'src/app/utils/status-constant';

@Component({
  selector: 'app-dialer-convert-lead',
  templateUrl: './dialer-convert-lead.component.html',
  styleUrls: ['./dialer-convert-lead.component.scss'],
})
export class DialerConvertLeadComponent implements OnInit {
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  mainStatusId: string = '62d6b26a8865ded41d831ece';
  moduleId: string = '5faa63fdfd20cd581703d255';
  tagType: string = '1';
  numberId: '';
  crmQuestionId: '';
  marketId: '';
  propertyDetails: any = {};
  markrtObj: any = {};
  allCampaignObj: any = {};
  defaultNumberList: any[] = [];
  markets: any[] = [];
  campaignArray: any[] = [];
  marketTitle: any[] = [];
  dripList: any = [];
  promises: any[] = [];
  phoneNumberList: any[] = [];
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredTags: Observable<any[]>;
  tags: any[] = [];
  allTagList: any[] = [];
  unavailableColorIndex: any[] = [];
  unavailableColorIndexResponse: any[] = [];
  showDripCampaign: boolean = false;
  stopDripCallAndSms: string = 'true';
  selectDripCampaign: string = '';
  selectMarketCampaign: string = '';
  selectTagLead: boolean = false;
  isShowPlaceHolder: boolean = false;
  public colorCodes = MiscellaneousConstant.colorCodes;
  messageConstant = MessageConstant;
  mainStatusList = [
    {
      id: '62d6b26a8865ded41d831ece',
      lable: 'New Lead',
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DialerConvertLeadComponent>,
    private _loaderService: NgxUiLoaderService,
    private _marketServices: MarketsService,
    private _toastrService: ToastrService,
    private _dripService: DripService,
    public _utilities: CommonFunctionsService,
    private _leadsService: LeadsService,
    private _taggingService: TaggingService,
    private _sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.propertyDetails = this.data.details;

    this.getMarket();
    this.onDripList();
    this.getAllTags();
    this.getUsedColorIndex();
    this.getAllPhoneNumberList();
    console.log('this.data.details--->', this.data.details);
  }

  getMarket() {
    const obj = {
      page: 1,
      limit: 1000,
    };
    this._loaderService.start();
    this._marketServices.getMarketsV2(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.getCampaignList();
          this.markets = response.data.items;

          this.markets = this.markets.filter((x) => x.isEnabled === true);
          for (let i = 0; i < this.markets.length; i++) {
            this.markrtObj[this.markets[i]._id] = this.markets[i].title;
            this.marketTitle.push(this.markets[i].title);
          }
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getCampaignList() {
    const data = {
      page: 1,
      limit: 1000,
      numTypeId: 1,
    };

    this._sharedService.getManageNumbersListV2(data).subscribe(
      (response) => {
        if (response && response.statusCode == 200) {
          this.defaultNumberList = response.data.items;
          this.defaultNumberList.forEach((element) => {
            if (this.markrtObj[element.marketId]) {
              if (this.allCampaignObj[this.markrtObj[element.marketId]]) {
                var campaignObj = {
                  campaignSource: element.crmQuestionAnswer,
                  campaignName: element.marketingTitle,
                  campaignId: element._id,
                  campaignSourceId: element.crmQuestionId,
                  marketId: element.marketId,
                };

                this.allCampaignObj[this.markrtObj[element.marketId]][
                  'campaignOption'
                ].push(campaignObj);
              } else {
                var newObj = {
                  campaignSource: element.crmQuestionAnswer,
                  campaignName: element.marketingTitle,
                  campaignId: element._id,
                  campaignSourceId: element.crmQuestionId,
                  marketId: element.marketId,
                };
                this.allCampaignObj[this.markrtObj[element.marketId]] = {
                  marketId: element.marketId,
                  marketName: this.markrtObj[element.marketId],
                  campaignOption: [],
                };
                this.allCampaignObj[this.markrtObj[element.marketId]][
                  'campaignOption'
                ].push(newObj);
              }
            }
          });
          for (let i = 0; i < this.marketTitle.length; i++) {
            if (this.allCampaignObj[this.marketTitle[i]]) {
              this.campaignArray.push(this.allCampaignObj[this.marketTitle[i]]);
            }
          }
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          this._loaderService.stop();
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }

  onDripList() {
    let obj = {
      page: 1,
      limit: 1000,
    };
    this._dripService.listDropDown(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          // this._loaderService.stop();
          this.dripList = response.data.items;
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  onChangeCampaign(type) {
    if (type == 'drip') {
      this.showDripCampaign = !this.showDripCampaign;
    } else {
      this.selectTagLead = !this.selectTagLead;
    }
  }

  checkForUnique(val) {
    let flag = true;
    for (let i = 0; i < this.tags.length; i++) {
      if (this.tags[i].label?.toLowerCase() == val.toLowerCase()) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  add(event: MatChipInputEvent): void {
    this.isShowPlaceHolder = false;
    const input = event.input;
    const value = event.value;
    if (this.checkForUnique(value)) {
      let index = -1;
      for (let i = 0; i < this.allTagList.length; i++) {
        if (this.allTagList[i].label?.toLowerCase() == value.toLowerCase()) {
          index = i;
          break;
        }
      }
      if (index >= 0) {
        this.tags.push(this.allTagList[index]);
      } else {
        if ((value || '').trim()) {
          this.tags.push({ _id: 'custom', label: value.trim() });
        }
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.checkForUnique(event.option.value.label)) {
      this.tags.push(event.option.value);
      this.tagInput.nativeElement.value = '';
      this.tagCtrl.setValue(null);
      this.tagInput.nativeElement.blur();
    }
  }

  isVisible(tag) {
    const tagIndex = this.tags.findIndex((x) => x.label === tag.label);
    return tagIndex > -1 ? false : true;
  }

  private _filter(value) {
    let filterValue;
    if (value._id) {
      filterValue = value.label?.toLowerCase();
    } else {
      filterValue = value.toLowerCase();
    }

    return this.allTagList.filter(
      (tag) => tag.label.toLowerCase().indexOf(filterValue) === 0
    );
  }

  getAllTags() {
    let tagType = 1;
    this._taggingService.getTags(tagType).subscribe(
      (response) => {
        if (response && response.statusCode == 200) {
          let arr = response.data;
          const result = arr.reduce((acc, d) => {
            if (d.label) {
              const value = { _id: d._id, label: d.label };

              acc.push(value);
            }

            return acc;
          }, []);
          this.allTagList = result;

          this.filteredTags = this.tagCtrl.valueChanges.pipe(
            startWith(null),
            map((tag) => (tag ? this._filter(tag) : this.allTagList.slice()))
          );
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  getUsedColorIndex() {
    let tagType = 1;
    this._taggingService.getUsedTagsColor(tagType).subscribe(
      (response) => {
        if (response && response.statusCode == 200) {
          this.unavailableColorIndex = response?.data;
          this.unavailableColorIndexResponse = [];
          let i, j;
          for (i = 0; i < this.colorCodes.length; i++) {
            this.unavailableColorIndexResponse.push({ _id: i, count: 0 });
            for (j = 0; j < this.unavailableColorIndex.length; j++) {
              if (this.unavailableColorIndex[j]._id == i) {
                this.unavailableColorIndexResponse[i].count =
                  this.unavailableColorIndex[j].count;
                break;
              }
            }
          }
          this.unavailableColorIndexResponse.sort(
            (a, b) => parseFloat(a.count) - parseFloat(b.count)
          );
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  filterData(data, mode) {
    let filteredData = [];
    if (mode == 1) {
      filteredData = data.filter(function (el) {
        return el._id != 'custom';
      });
    } else {
      filteredData = data.filter(function (el) {
        return el._id == 'custom';
      });
    }

    return filteredData;
  }
  getTagsToSave(tagsArr) {
    if (tagsArr.length) {
      let finalArr = [],
        colorIndex = 0;
      for (let i = 0; i < tagsArr.length; i++) {
        if (this.unavailableColorIndexResponse.length == colorIndex)
          colorIndex = 0;
        finalArr.push({
          tag: tagsArr[i],
          colorIndex: this.unavailableColorIndexResponse[colorIndex]?._id,
        });
        colorIndex++;
      }
      return finalArr;
    } else {
      return [];
    }
  }

  getAllPhoneNumberList() {
    this.promises = [];
    Promise.all(this.promises).then((result) => {
      this.phoneNumberList = [];
      let pType, campaignEmail;
      for (let i = 1; i < 10; i++) {
        if (this.propertyDetails[`Phone_${i + 1}`]) {
          pType = '';
          campaignEmail = '';

          if (this.propertyDetails[`Phone_${i + 1}_type`]) {
            const phoneTypes = ['Mobile', 'Landline', 'Voip'];
            if (
              !phoneTypes.includes(this.propertyDetails[`Phone_${i + 1}_type`])
            ) {
              pType = 'Other';
            } else {
              pType = this.propertyDetails[`Phone_${i + 1}_type`];
            }
          }
          if (this.propertyDetails[`Email_${i + 1}`]) {
            campaignEmail = this.propertyDetails[`Email_${i + 1}`];
          }
          this.phoneNumberList.push({
            phoneNumber: this.propertyDetails[`Phone_${i + 1}`],
            email: campaignEmail,
            name: this.propertyDetails.Full_Name,
            contactType: 'seller',
          });
        }
      }
    });
  }

  formatDate(inputString) {
    if (inputString?.length > 8) return inputString;
    const year = inputString.slice(0, 4);
    const month = inputString.slice(4, 6);
    const day = inputString.slice(6, 8);

    // Concatenate the components with hyphens
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  saveToLead() {
    if (!this.selectMarketCampaign) {
      this._toastrService.error('Please select to assign campaign');
      return;
    } else {
      let campaignAllId;
      this.campaignArray.forEach((element) => {
        if (element.campaignOption) {
          element.campaignOption.forEach((item) => {
            if (item.campaignId == this.selectMarketCampaign) {
              campaignAllId = item;
            }
          });
        }
      });
      this.numberId = campaignAllId['campaignId'];
      this.crmQuestionId = campaignAllId['campaignSourceId'];
      this.marketId = campaignAllId['marketId'];
    }

    if (this.showDripCampaign && !this.selectDripCampaign) {
      this._toastrService.error('Please select drip campaign');
      return;
    }

    if (this.selectTagLead && this.tags.length == 0) {
      this._toastrService.error("Tags can't be blank");
      return;
    }

    // save to lead
    let leadEmail;
    if (this.propertyDetails.Email_1) {
      leadEmail = this.propertyDetails.Email_1;
    } else if (this.propertyDetails.Email_2) {
      leadEmail = this.propertyDetails.Email_2;
    } else if (this.propertyDetails.Email_3) {
      leadEmail = this.propertyDetails.Email_3;
    } else if (this.propertyDetails.Email_4) {
      leadEmail = this.propertyDetails.Email_4;
    }

    let myObj:any = {
      campaignId: this.data.campaignId,
      propertyId: this.propertyDetails?._id || 'notInYourLead',
      title: this.propertyDetails.Full_Name,
      email: leadEmail ? leadEmail?.toLowerCase() : '',
      address: this.propertyDetails.Formated_Address,
      leadCreated: Date.now(),
      area: (this.propertyDetails?.Property_Approx_Sq_Ft || 0).toString(),
      estimateHigh: 0,
      estimateLow: 0,
      mortgageAmount: 0,
      preference: [],
      mortgageDate: 0,
      lastSoldDate: 0,
      priorSaleDate: 0,
      ownerOccupied: 0,
      secondaryContact: this.phoneNumberList.map((x) => {
        if (x.phoneNumber) {
          x.phoneNumber =
            environment.countryCode +
            this._utilities.unMaskNumber(x?.phoneNumber);
        }
        if (x.email) {
          x.email = x.email?.toLowerCase();
        }
        if (!x.numberType) {
          delete x['numberType'];
        }
        return x;
      }),
      defaultNumber: false,
      numberId: this.numberId,
      crmQuestionId: this.crmQuestionId,
      marketId: this.marketId,
      dispoPreference: [],
      mainStatusId: this.mainStatusId,
      duplicateLead: true,
      dialerMoveToLead: true,
      lotSizeSqft:(this.propertyDetails?.Property_Lot_Size_Sq_Ft || 0).toString(),
      yearBuilt:(this.propertyDetails?.Property_Year_Built || 0).toString(),
    };
    if (this.propertyDetails?.Phone_1)
      myObj['phoneNumber'] =
        environment.countryCode +
        this._utilities.unMaskNumber(this.propertyDetails.Phone_1);

   
    // if (
    //   this.propertyDetails?.Property_Approx_Sq_Ft ||
    //   this.propertyDetails?.Property_Bath ||
    //   this.propertyDetails?.Property_Bed ||
    //   this.propertyDetails?.Property_House_Type ||
    //   this.propertyDetails?.Property_Estimated_Value
    // ) {
      myObj['leadInfo'] = {
        Property_Approx_Sq_Ft: this.propertyDetails?.Property_Approx_Sq_Ft || 0,
        Property_House_Type: this.propertyDetails?.Property_House_Type || "",
        Property_Estimated_Value: this.propertyDetails
          ?.Property_Estimated_Value || 0,
        Property_Bath:this.propertyDetails?.Property_Bath || 0,
        Property_Bed:this.propertyDetails?.Property_Bed || 0,
        // First_Name:this.propertyDetails?.First_Name || "",
        // Last_Name:this.propertyDetails?.Last_Name || "",
        // Full_Name:this.propertyDetails?.Full_Name || "",
      };
      if(this.propertyDetails?.mortgageInfo){
        myObj['leadInfo']['mortgageInfo']=this.propertyDetails?.mortgageInfo;
      }
      myObj['leadInfo']['valuation']={estimatedTotalLiens:this.propertyDetails?.openLoan || 0};
      
      
      myObj['bathCount'] = this.propertyDetails?.Property_Bath;
      myObj['bedroomsCount'] = this.propertyDetails?.Property_Bed;
      if(!this.propertyDetails?._id){
        delete myObj['propertyId'];
      }
      //if(this.propertyDetails?._id){
       
      myObj['ownerName1']=this.propertyDetails?.Full_Name || '';
      myObj['ownerMailingAddress']=this.propertyDetails?.Mailing_Formated_Address || '';
      myObj['leadInfo']['propertyInfo']=this.propertyDetails?.propertyInfo || {
        basementType: '',
        basementArea: '',
        garageType: '',
        garageSpaces: '',
        garageArea:'',
      };
      
      myObj['leadInfo']['agentInfo']=this.propertyDetails?.agentInfo || {
        name: '',
        phone: '',
        email: ''
      };
      myObj['leadInfo']['lastSaleInfo']=this.propertyDetails?.lastSaleInfo || {
        saleDate: 0,
        ownershipDuration:  '',
        saleAmount:  '',
      };
      myObj['leadInfo']['mls']=this.propertyDetails?.mls || {
        status: '',
        statusDate:  0,
        price:'',
        listingType: '',
      };
      myObj['leadInfo']['taxInfo']=this.propertyDetails?.taxInfo || {
        totalAssessedValue:  '',
        assessedLandValue: '',
        assessedImprovementValue: '',
        assessedYear:  '',
        taxYear: '',
        propertyTaxes: '',
      };

      myObj['leadInfo']['companyName']=this.propertyDetails?.companyName || '';
      myObj['leadInfo']['First_Name']=this.propertyDetails?.First_Name || '';
      myObj['leadInfo']['First_Name_2']=this.propertyDetails?.First_Name_2 || '';
      myObj['leadInfo']['Full_Name']=this.propertyDetails?.Full_Name || '';
      myObj['leadInfo']['Full_Name_2']=this.propertyDetails?.Full_Name_2 || '';
      myObj['leadInfo']['Last_Name']=this.propertyDetails?.Last_Name || '';
      myObj['leadInfo']['Last_Name_2']=this.propertyDetails?.Last_Name_2 || '';
      myObj['leadInfo']['mailingRepeatingCount']=this.propertyDetails?.mailingRepeatingCount || 0;
      myObj['leadInfo']['Mailing_County_Name']=this.propertyDetails?.Mailing_County_Name || '';
      myObj['leadInfo']['Mailing_Formated_Address']=this.propertyDetails?.Mailing_Formated_Address || '';
      myObj['leadInfo']['Mailing_Street_Address']=this.propertyDetails?.Mailing_Street_Address || '';
      myObj['leadInfo']['Mailing_Street_Address_2']=this.propertyDetails?.Mailing_Street_Address_2 || '';
      myObj['leadInfo']['Mailing_City']=this.propertyDetails?.Mailing_City || '';
      myObj['leadInfo']['Mailing_State']=this.propertyDetails?.Mailing_State || '';
      myObj['leadInfo']['Mailing_Zip_Code']=this.propertyDetails?.Mailing_Zip_Code || '';
      
      myObj['leadInfo']['Opt']=this.propertyDetails?.Opt || false;
      myObj['leadInfo']['ownershipType']=this.propertyDetails?.ownershipType || '';
      myObj['leadInfo']['Owner_Type']=this.propertyDetails?.Owner_Type || '';
      myObj['leadInfo']['Property_Lot_Size_Sq_Ft']=this.propertyDetails?.Property_Lot_Size_Sq_Ft || '';
      myObj['leadInfo']['Property_Street_Address']=this.propertyDetails?.Property_Street_Address || '';
      myObj['leadInfo']['Property_Street_Address_2']=this.propertyDetails?.Property_Street_Address_2 || '';
      myObj['leadInfo']['Property_City']=this.propertyDetails?.Property_City || '';
      myObj['leadInfo']['Property_State']=this.propertyDetails?.Property_State || '';
      myObj['leadInfo']['Property_Zip_Code']=this.propertyDetails?.Property_Zip_Code || '';
      myObj['leadInfo']['Property_County_Name']=this.propertyDetails?.Property_County_Name || '';
      myObj['leadInfo']['Property_Year_Built']=this.propertyDetails?.Property_Year_Built || '';
      myObj['leadInfo']['Vacant']=this.propertyDetails?.Vacant || false;
      myObj['leadInfo']['userModifiedKeys']=this.propertyDetails?.userModifiedKeys || [];

      if(this.propertyDetails?.lastSaleInfo?.saleDate){
        myObj['leadInfo']['lastSaleInfo']['saleDate']=this.propertyDetails?.lastSaleInfo?.saleDate
        ? this.formatDate(this.propertyDetails?.lastSaleInfo?.saleDate)
        : '';
      }
      //}
    //}
    
    //console.log(myObj); return;
    if (this.data?.lsMoveToLead) myObj['lsMoveToLead'] = true;
    if (this.data?.fromLeadsPortfolio) myObj['fromLeadsPortfolio'] = true;
    this._loaderService.start();
    this._leadsService.addLeadsV2(myObj).subscribe(
      async (response: ResponseModel) => {
        if (response.statusCode === 200) {
          let leadId = response.data._id;
          if (this.showDripCampaign && this.selectDripCampaign) {
            this.dripCampaignAdd(leadId);
          }
          if (this.selectTagLead && this.tags.length > 0) {
            this.leadTagAdd(leadId);
          }
          if (this.propertyDetails?._id) {
            const listObj = {
              lead_property_id: response.data._id,
              propertyId: this.propertyDetails?._id,
              lead_property_status: response.data.mainStatusId,
            };
            console.log("inside if-------",listObj);
            this._leadsService.updateLeadId(listObj).subscribe(
              (response: ResponseModel) => {},
              (err: ErrorModel) => {}
            );
            let editObj={
              bathCount:this.propertyDetails?.Property_Bath,
              bedroomsCount:this.propertyDetails?.Property_Bed,
              ownerName1:this.propertyDetails?.Full_Name || '',
              ownerMailingAddress:this.propertyDetails?.Mailing_Formated_Address || '',
              leadInfo:myObj?.leadInfo,
            };
            
            this._leadsService.editLead(editObj, leadId).subscribe(
              async (response: ResponseModel) => {},
              (err: ErrorModel) => {}
            );
            //this.leadUpdateFromLS(response.data._id,myObj?.leadInfo);
          } else {
            if (response.data.address) {
              // let mainAddress = response.data.address.split(',');
              // let streetAddress = '';
              // let city = '';
              // let state = '';
              // let zip = '';
              // if (mainAddress.length > 1) {
              //   streetAddress = mainAddress[0] ? mainAddress[0] : '';
              //   city = mainAddress[1] ? mainAddress[1] : '';
              //   if (mainAddress.length > 2) {
              //     let subDivide = mainAddress[2];
              //     if (subDivide) {
              //       let sub = subDivide.split(' ');
              //       state = sub[1] ? sub[1] : '';
              //       zip = sub[2] ? sub[2] : '';
              //     }
              //   } else {
              //     state = '';
              //     zip = '';
              //   }
              // } else {
              //   streetAddress = mainAddress[0];
              //   city = '';
              //   state = '';
              //   zip = '';
              // }
              // let mail_mainAddress = '';
              // let mail_streetAddress = '';
              // let mail_city = '';
              // let mail_state = '';
              // let mail_zip = '';
              // if (response?.data?.ownerMailingAddress) {
              //   mail_mainAddress = response.data.ownerMailingAddress.split(',');
              //   if (mail_mainAddress.length > 1) {
              //     mail_streetAddress = mail_mainAddress[0]
              //       ? mail_mainAddress[0]
              //       : '';
              //     mail_city = mail_mainAddress[1] ? mail_mainAddress[1] : '';
              //     if (mail_mainAddress.length > 2) {
              //       let subDivide = mail_mainAddress[2];
              //       if (subDivide) {
              //         let sub = subDivide.split(' ');
              //         mail_state = sub[1] ? sub[1] : '';
              //         mail_zip = sub[2] ? sub[2] : '';
              //       }
              //     } else {
              //       mail_state = '';
              //       mail_zip = '';
              //     }
              //   } else {
              //     mail_streetAddress = mail_mainAddress[0];
              //     mail_city = '';
              //     mail_state = '';
              //     mail_zip = '';
              //   }
              // }

              let streetAddress = this.propertyDetails?.Property_Street_Address || '';
              let streetAddress2 = this.propertyDetails?.Property_Street_Address_2 || '';
              let city = this.propertyDetails?.Property_City || '';
              let state = this.propertyDetails?.Property_State || '';
              let zip = this.propertyDetails?.Property_Zip_Code || '';

              let mail_mainAddress = this.propertyDetails?.Mailing_Street_Address || '';
              let mail_streetAddress = this.propertyDetails?.Mailing_Street_Address_2 || '';
              let mail_city = this.propertyDetails?.Mailing_City || '';
              let mail_state = this.propertyDetails?.Mailing_State || '';
              let mail_zip = this.propertyDetails?.Mailing_Zip_Code || '';

              let fName = '';
              let lName = '';
              if (response?.data?.title) {
                let lastName = response.data.title.split(' ');
                fName = lastName ? lastName[0] : '';
                lName = lastName ? lastName[1] : '';
              }
              let listObj = {
                lead_property_id: response.data._id,
                First_Name: fName,
                Last_Name: lName,
                Full_Name: response.data.title,
                Formated_Address: response.data.address,
                formatted_address: response.data.address,
                Property_Street_Address: streetAddress,
                Property_Street_Address_2: streetAddress2,
                Property_City: city,
                Property_State: state,
                Property_Zip_Code: zip,
                Mailing_Street_Address: mail_mainAddress,
                Mailing_Street_Address_2: mail_streetAddress,
                Mailing_City: mail_city,
                Mailing_State: mail_state,
                Mailing_Zip_Code: mail_zip,
                Phone_1: response.data?.phoneNumber
                  ? await this._utilities.validatePhone(
                      response.data?.phoneNumber
                    )
                  : '',
                Phone_2: response.data?.secondaryContact[0]?.phoneNumber
                  ? await this._utilities.validatePhone(
                      response.data?.secondaryContact[0]?.phoneNumber
                    )
                  : '',
                Phone_3: response.data?.secondaryContact[1]?.phoneNumber
                  ? await this._utilities.validatePhone(
                      response.data?.secondaryContact[1]?.phoneNumber
                    )
                  : '',
                Phone_4: response.data?.secondaryContact[2]?.phoneNumber
                  ? await this._utilities.validatePhone(
                      response.data?.secondaryContact[2]?.phoneNumber
                    )
                  : '',
                Phone_5: response.data?.secondaryContact[3]?.phoneNumber
                  ? await this._utilities.validatePhone(
                      response.data?.secondaryContact[3]?.phoneNumber
                    )
                  : '',
                Phone_6: response.data?.secondaryContact[4]?.phoneNumber
                  ? await this._utilities.validatePhone(
                      response.data?.secondaryContact[4]?.phoneNumber
                    )
                  : '',
                Phone_7: response.data?.secondaryContact[5]?.phoneNumber
                  ? await this._utilities.validatePhone(
                      response.data?.secondaryContact[5]?.phoneNumber
                    )
                  : '',
                Phone_8: response.data?.secondaryContact[6]?.phoneNumber
                  ? await this._utilities.validatePhone(
                      response.data?.secondaryContact[6]?.phoneNumber
                    )
                  : '',
                Phone_9: response.data?.secondaryContact[7]?.phoneNumber
                  ? await this._utilities.validatePhone(
                      response.data?.secondaryContact[7]?.phoneNumber
                    )
                  : '',
                Phone_10: response.data?.secondaryContact[8]?.phoneNumber
                  ? await this._utilities.validatePhone(
                      response.data?.secondaryContact[8]?.phoneNumber
                    )
                  : '',
              };
             
              listObj={...listObj,...this.propertyDetails, lead_property_status: response.data.mainStatusId,InLead:true};
              this._leadsService.insertSingleProperty(listObj).subscribe(
                (response: ResponseModel) => {},
                (err: ErrorModel) => {}
              );
            }
          }
          this._toastrService.success(this.messageConstant.leadAddedSuccess);
          this.dialogRef.close(response.data);
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  // leadUpdateFromLS(leadId,myObj){
  //   let listObj={
  //     leadDetails: [{
  //       lead_property_id: leadId,
  //       leadInfo:myObj
  //     }]
  //   };
  //   this._leadsService.leadUpdateFromLS(listObj).subscribe(
  //     (response: ResponseModel) => {

  //     },
  //     (err: ErrorModel) => {

  //     }
  //   );
  // }

  dripCampaignAdd(leadId) {
    let obj = {
      dripCompaignId: this.selectDripCampaign,
      leadIds: [leadId],
      autoStopDrip: this.stopDripCallAndSms,
      activeTimeChange: false,
      duration: 1,
      timeSpan: 'Minutes',
    };
    this._dripService.assignDrip(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          // this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  leadTagAdd(leadId) {
    let existingTags = this.filterData(this.tags, '1');
    let newTags = this.filterData(this.tags, '0');

    const existingIds = existingTags.reduce((acc, d) => {
      acc.push(d._id);
      return acc;
    }, []);

    const tagsArr = newTags.reduce((acc, d) => {
      acc.push(d.label);
      return acc;
    }, []);

    let tagsToSave = this.getTagsToSave(tagsArr);

    if (!tagsArr.length) {
      let obj = {
        moduleId: this.moduleId,
        leadId: leadId,
        tagIds: existingIds,
        isTagAdd: true,
        for: 'lead',
      };

      this.saveAllIds(obj);
      return;
    }

    let obj = {
      tagsArr: tagsToSave,
      tagType: this.tagType,
    };

    this._taggingService.addTags(obj).subscribe(
      (response) => {
        if (response) {
          let finalIds = existingIds;
          for (let i = 0; i < response?.data?.length; i++) {
            if (response?.data[i] != null)
              finalIds.push(response?.data[i]?._id);
          }

          let data = {
            moduleId: this.moduleId,
            leadId: leadId,
            tagIds: finalIds,
            isTagAdd: true,
            for: 'lead',
          };
          if (finalIds.length) this.saveAllIds(data);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        this._toastrService.error(
          err.message ? err.message : MessageConstant.unknownError
        );
      }
    );
  }

  saveAllIds(obj2) {
    this._sharedService.addTags(obj2).subscribe(
      (response) => {
        if (response) {
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  onSearchChange(text) {
    if (text) {
      let filterArray = this.allTagList.filter((x) => {
        return x?.label === text;
      });
      if (filterArray?.length == 0) {
        this.isShowPlaceHolder = true;
      } else {
        this.isShowPlaceHolder = false;
      }
    } else {
      this.isShowPlaceHolder = false;
    }
  }

  remove(index) {
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }
}
