<div class="tasksdetaill_modal">
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        style="display: block !important"
        class="w-100 d-flex justify-content-between"
      >
        {{ type }} Task
      </h2>
      <span matDialogClose mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>
  <form
    [formGroup]="addEditTaskForm"
    (ngSubmit)="onTaskSubmit()"
    autocomplete="off"
    novalidate
  >
    <mat-dialog-content class="re-typography">
      <!-- <div class="propertydiv">
        <div
          class="property"
          [ngClass]="type == 'Add' ? 'property-add' : 'property'"
        >
          <span class="text1">
            {{
              !data.leadInfo.address
                ? "N/A"
                : _utilities.formatAddress(data.leadInfo.address)
            }}
          </span>
          <span class="text2">
            <span>
              Lead > {{ data.leadInfo.mainStatusTitle | titlecase }}
            </span>
            • {{ data?.leadInfo?.createdAt | date: "mediumDate" }}
            {{ data?.leadInfo?.createdAt | date: "shortTime" }}
          </span>
        </div>
        <label class="checkb" *ngIf="type != 'Add'">
          <input
            type="checkbox"
            [checked]="data.task.isCompleted"
            (change)="taskCompleted($event)"
          />
          <span class="checkmark"></span> Done
        </label>
      </div> -->

      <ng-container *ngIf="!data.isHideHeader">
        <div class="re-tabsdiv" *ngIf="!data.isLocalTask">
          <div role="tablist" class="nav re-tabs-btn">
            <a
              href="#tabs11"
              role="tab"
              data-toggle="tab"
              aria-expanded="false"
              [ngClass]="{
                active: isGeneralTask,
                're-disable-tab': !isGeneralTask && data.isDisable
              }"
              (click)="taskTab(true)"
            >
              General Business
            </a>
            <a
              href="#tabs22"
              role="tab"
              data-toggle="tab"
              aria-expanded="false"
              [ngClass]="{
                active: !isGeneralTask,
                're-disable-tab': isGeneralTask && data.isDisable
              }"
              (click)="taskTab(false)"
              >Lead Task</a
            >
          </div>
        </div>

        <div class="re-tabsdiv" *ngIf="data.isLocalTask">
          <div role="tablist" class="nav re-tabs-btn">
            <a
              href="#tabs22"
              role="tab"
              data-toggle="tab"
              aria-expanded="false"
              [ngClass]="{
                active: !isGeneralTask,
                're-disable-tab': isGeneralTask && data.isDisable
              }"
              (click)="taskTab(false)"
              >Lead Task</a
            >
            <a
              href="#tabs11"
              role="tab"
              data-toggle="tab"
              aria-expanded="false"
              [ngClass]="{
                active: isGeneralTask,
                're-disable-tab': !isGeneralTask && data.isDisable
              }"
              (click)="taskTab(true)"
              >General Business</a
            >
          </div>
        </div>
      </ng-container>

      <div class="container-fluid p-0">
        <div class="tab-content">
          <div
            role="tabpanel"
            id="tabs11"
            class="tab-pane"
            [ngClass]="isGeneralTask ? 'active' : ''"
          >
            <div class="boxes" style="padding-top: 15px">
              <div class="row">
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="re-labels">Select Task Template</label>
                    <select
                      name="taskTemplateOption"
                      formControlName="taskTemplateOption"
                      class="form-control"
                      (change)="
                        onChangeTemplate(
                          addEditTaskForm.value.taskTemplateOption
                        )
                      "
                    >
                      <option value="">
                        Choose a template to auto fill task details
                      </option>
                      <option
                        *ngFor="let task of taskTemplate"
                        value="{{ task._id }}"
                      >
                        {{ task.templateName }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  class="col-12 col-md-12 col-sm-12"
                  *ngIf="data?.moduleData"
                >
                  <div
                    class="form-group"
                    *ngIf="data?.moduleType === 'Vendors'"
                  >
                    <label class="re-labels">
                      Vendor Name <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="title"
                      [(ngModel)]="data.moduleData.name"
                      placeholder="Task Name"
                      [disabled]="true"
                      [readOnly]="true"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>

                  <div class="form-group" *ngIf="data?.moduleType === 'Buyers'">
                    <label class="re-labels">
                      Buyer Name <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="title"
                      [(ngModel)]="
                        data.moduleData.firstName +
                        ' ' +
                        data.moduleData.lastName
                      "
                      placeholder="Task Name"
                      [disabled]="true"
                      [readOnly]="true"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="re-labels">
                      Task Name <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="title"
                      formControlName="title"
                      placeholder="Task Name"
                    />
                    <div class="text-danger re-error-txt">
                      <span *ngIf="submitted && hasError('title', 'required')">
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Task Name"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="re-labels"
                      >Task Type <span class="text-danger">*</span></label
                    >
                    <select
                      name="taskTypeOption"
                      formControlName="taskTypeOption"
                      class="form-control"
                    >
                      <option value="">-Select-</option>
                      <option
                        *ngFor="let task of taskType"
                        value="{{ task.id }}"
                      >
                        {{ task.name }}
                      </option>
                    </select>
                    <div class="text-danger">
                      <span
                        *ngIf="
                          submitted && hasError('taskTypeOption', 'required')
                        "
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Task Type"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="re-labels">Task Detail </label>
                    <textarea
                      class="form-control"
                      name="taskDetail"
                      formControlName="taskDetail"
                      rows="2"
                      placeholder="Task Detail"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="boxes">
              <div class="row">
                <div class="col-12 col-md-12 col-sm-12">
                  <span class="biglabel">Assignment & due</span>
                </div>
                <div class="col-12 col-md-6 col-sm-12">
                  <div class="form-group">
                    <label class="re-labels"
                      >Assign to user <span class="text-danger">*</span></label
                    >
                    <select
                      name="assignUserId"
                      formControlName="assignUserId"
                      class="form-control"
                    >
                      <option value="">--Select--</option>
                      <option
                        *ngFor="let user of data.userList"
                        [value]="user.value"
                      >
                        {{ user?.label || user?.name }}
                      </option>
                    </select>
                    <div class="text-danger re-error-txt">
                      <span
                        *ngIf="
                          submitted && hasError('assignUserId', 'required')
                        "
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Assign To User"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-sm-12">
                  <div class="form-group">
                    <label class="re-labels"> Priority</label>
                    <div class="re-priority-select">
                      <select
                        name="taskPriority"
                        class="form-control re-urgent"
                        formControlName="taskPriority"
                        [class.re-urgent]="priorityClass == 1"
                        [class.re-urgent-2]="priorityClass == 2"
                        [class.re-urgent-3]="priorityClass == 3"
                        [class.re-urgent-4]="priorityClass == 4"
                        [class.re-urgent-5]="priorityClass == 5"
                        (change)="
                          onChangePriority(addEditTaskForm.value.taskPriority)
                        "
                      >
                        <option
                          style="color: black; background-color: #fff"
                          value="1"
                        >
                          Urgent Priority
                        </option>
                        <option
                          style="color: black; background-color: #fff"
                          value="2"
                        >
                          High Priority
                        </option>
                        <option
                          style="color: black; background-color: #fff"
                          value="3"
                        >
                          Medium Priority
                        </option>
                        <option
                          style="color: black; background-color: #fff"
                          value="4"
                        >
                          Low Priority
                        </option>
                        <option
                          style="color: black; background-color: #fff"
                          value="5"
                        >
                          None Priority
                        </option>
                      </select>
                      <i class="fa fa-flag-o" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 col-md-12 col-sm-12"
                  *ngIf="showAssignToUser"
                >
                  <div class="form-group">
                    <label class="re-labels">
                      Assign To User <span class="text-danger">*</span>
                    </label>
                    <select
                      name="assignUserId"
                      formControlName="assignUserId"
                      class="form-control"
                    >
                      <option value="">--Select--</option>
                      <option
                        *ngFor="let user of assignUserList"
                        value="{{ user._id }}"
                      >
                        {{ user?.firstName }} {{ user?.lastName }}
                      </option>
                    </select>
                    <div class="text-danger re-error-txt">
                      <span
                        *ngIf="
                          submitted && hasError('assignUserId', 'required')
                        "
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Assign To User"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-12 col-sm-12">
                  <label class="re-labels">
                    Task Due <span class="text-danger">*</span>
                  </label>
                  <div class="radiolist mb-2">
                    <label class="radiobtn">
                      <input
                        type="radio"
                        name="tasktime"
                        [checked]="taskDueNumber == 0"
                        (click)="taskDue('One Hour')"
                      />
                      <span class="checkmark"></span>
                      One Hour
                    </label>
                    <label class="radiobtn">
                      <input
                        type="radio"
                        name="tasktime"
                        [checked]="taskDueNumber == 1"
                        (click)="taskDue('One Day')"
                      />
                      <span class="checkmark"></span>
                      One Day
                    </label>
                    <label class="radiobtn">
                      <input
                        type="radio"
                        name="tasktime"
                        [checked]="taskDueNumber == 2"
                        (click)="taskDue('One Week')"
                      />
                      <span class="checkmark"></span>
                      One Week
                    </label>
                    <label class="radiobtn">
                      <input
                        type="radio"
                        name="tasktime"
                        [checked]="taskDueNumber == 3"
                        (click)="taskDue('Custom')"
                      />
                      <span class="checkmark"></span>
                      Custom
                    </label>
                  </div>
                </div>
                <div class="col-12 col-md-8 col-sm-12">
                  <div class="form-group">
                    <p-calendar
                      formControlName="dueInIso"
                      styleClass="ngCalendarClass"
                      [showIcon]="true"
                      [icon]="'fa fa-calendar'"
                      [minDate]="minimumDate"
                      appendTo="body"
                      placeholder="Due date"
                      (onSelect)="taskDueValidationChange()"
                    ></p-calendar>
                    <div class="text-danger re-error-txt">
                      <span
                        *ngIf="submitted && hasError('dueInIso', 'required')"
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Due Date"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 col-sm-12">
                  <div class="form-group" *ngIf="customTime">
                    <div class="re-custom-select">
                      <select class="form-control" formControlName="time">
                        <option
                          *ngFor="let number of timeOption"
                          [value]="number"
                        >
                          {{ number }}
                        </option>
                      </select>
                      <i class="fa fa-clock-o"></i>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="!customTime">
                    <p-calendar
                      formControlName="time"
                      styleClass="ngCalendarClass"
                      [timeOnly]="true"
                      inputId="timeonly"
                      [showIcon]="true"
                      [icon]="'fa fa-clock-o'"
                      hourFormat="12"
                      [minDate]="minimumTime"
                      appendTo="body"
                      placeholder="Due time"
                      (onSelect)="taskDueValidationChange()"
                    ></p-calendar>
                    <div class="text-danger re-error-txt">
                      <span *ngIf="submitted && hasError('time', 'required')">
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Due Time"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="re-labels">
                      Timezone <span class="text-danger">*</span>
                    </label>
                    <select
                      name="tasktimezone"
                      formControlName="tasktimezone"
                      class="form-control"
                    >
                      <option
                        *ngFor="let timezone of timezoneList"
                        value="{{ timezone.timezonename }}"
                      >
                        {{ timezone.name }}
                      </option>
                    </select>
                    <div class="text-danger re-error-txt">
                      <span
                        *ngIf="
                          submitted && hasError('tasktimezone', 'required')
                        "
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Task Timezone"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="boxes">
              <div class="row">
                <div class="col-12 col-md-12 col-sm-12">
                  <span class="biglabel">notify user</span>
                  <div class="mb-2">
                    <div
                      class="notify re-file-share-modal d-inline-block w-100"
                    >
                      <div class="radiosec">
                        <p class="radotext">
                          Would you like to notify the assignee on task
                          creation?
                        </p>
                        <label class="switch">
                          <input
                            type="checkbox"
                            id="checkbox1"
                            (click)="onInstantNotification()"
                            [checked]="instantNotification"
                          />
                          <span class="slider"></span>
                          <span class="text1" value="false">No</span>
                          <span class="text2" value="true">Yes</span>
                        </label>
                      </div>
                    </div>
                    <div *ngIf="instantNotification === true">
                      <div
                        class="radiolist mb-2"
                        id="radiobtnlist2"
                        *ngIf="isGeneralTask"
                      >
                        <label class="radiobtn form-check-inline">
                          <input
                            type="radio"
                            formControlName="smsAndEmail"
                            name="smsAndEmail"
                            value="both"
                          /><span class="checkmark"></span>Both SMS and
                          Email</label
                        >
                        <label class="radiobtn form-check-inline">
                          <input
                            type="radio"
                            formControlName="smsAndEmail"
                            name="smsAndEmail"
                            value="sms"
                          /><span class="checkmark"></span>SMS</label
                        >
                        <label class="radiobtn form-check-inline">
                          <input
                            type="radio"
                            formControlName="smsAndEmail"
                            name="smsAndEmail"
                            value="email"
                          /><span class="checkmark"></span>Email</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="boxes">
              <div class="row">
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <span class="biglabel">Task Reminder</span>
                  </div>
                </div>
              </div>

              <div
                class="row"
                *ngIf="addEditTaskForm.get('reminders')['controls'].length > 0"
              >
                <div class="col-12 col-md-4 col-sm-12">
                  <label class="re-labels">
                    Remind Via <span class="text-danger">*</span>
                  </label>
                </div>
                <div class="col-12 col-md-4 col-sm-5">
                  <label class="re-labels">
                    Remind Before <span class="text-danger">*</span>
                  </label>
                </div>
                <div class="col-12 col-md-3 col-sm-5">
                  <label class="re-labels">
                    Remind Time <span class="text-danger">*</span>
                  </label>
                </div>
              </div>

              <div formArrayName="reminders">
                <div
                  *ngFor="
                    let control of addEditTaskForm.get('reminders')['controls'];
                    let i = index
                  "
                >
                  <div [formGroupName]="i" class="row">
                    <!-- VIA -->
                    <div class="col-12 col-md-4 col-sm-12">
                      <div class="form-group">
                        <select formControlName="via" class="form-control">
                          <option value="">--Select--</option>
                          <option value="sms">SMS</option>
                          <option value="email">Email</option>
                        </select>
                        <div class="text-danger re-error-txt">
                          <span
                            *ngIf="
                              submitted &&
                              (control.get('via').invalid ||
                                (control.get('via').invalid &&
                                  control.get('via').touched))
                            "
                          >
                            {{
                              messageConstant.requiredField.replace(
                                "Field",
                                "Remind Via"
                              )
                            }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- BEFORE -->
                    <div class="col-12 col-md-4 col-sm-5">
                      <div class="form-group">
                        <select formControlName="before" class="form-control">
                          <option value="">--Select--</option>
                          <option
                            *ngFor="let number of minutesList"
                            value="{{ number }}"
                          >
                            {{ number }}
                          </option>
                        </select>
                        <div class="text-danger re-error-txt">
                          <span
                            *ngIf="
                              submitted &&
                              (control.get('before').invalid ||
                                (control.get('before').invalid &&
                                  control.get('before').touched))
                            "
                          >
                            {{
                              messageConstant.requiredField.replace(
                                "Field",
                                "Remind Before"
                              )
                            }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- TIME -->
                    <div class="col-12 col-md-3 col-sm-5">
                      <div class="form-group">
                        <select formControlName="time" class="form-control">
                          <option value="">--Select--</option>
                          <option
                            *ngFor="let timeType of taskTimeTypeList"
                            value="{{ timeType }}"
                          >
                            {{ timeType }}
                          </option>
                        </select>
                        <div class="text-danger re-error-txt">
                          <span
                            *ngIf="
                              submitted &&
                              (control.get('time').invalid ||
                                (control.get('time').invalid &&
                                  control.get('time').touched))
                            "
                          >
                            {{
                              messageConstant.requiredField.replace(
                                "Field",
                                "Remind Time"
                              )
                            }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- ACTION -->
                    <div class="col-12 col-md-1 col-sm-2">
                      <div class="form-group text-center re-trash-icon">
                        <a class="pointer" (click)="removeReminder(i)"
                          ><img src="assets/images/delete-red.svg" alt="delete"
                        /></a>
                      </div>
                    </div>

                    <div class="col-12 re-invalid-reminder" #errorReminder>
                      <span
                        class="text-danger"
                        *ngIf="
                          submitted &&
                          !control.get('via').invalid &&
                          !control.get('before').invalid &&
                          !control.get('time').invalid &&
                          reminderErrors.length &&
                          reminderErrors[i]
                        "
                      >
                        {{ reminderErrors[i] }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-12 col-sm-12 text-center">
                  <a
                    class="btn addreminder"
                    href="javascript:;"
                    (click)="addReminder()"
                  >
                    Add Reminder
                  </a>
                </div>
              </div>
            </div>
            <!-- <hr />
            <div class="boxes">
              <div class="row">
                <div class="col-12 col-md-12 col-sm-12">
                  <span class="biglabel">repeat</span>
                </div>
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="re-file-share-modal mb-2 d-inline-block w-100">
                    <div class="radiosec">
                      <p class="radotext">Want this task to repeat?</p>
                      <label class="switch">
                        <input type="checkbox" id="checkbox1" />
                        <span class="slider"></span>
                        <span class="text1" value="false">No</span>
                        <span class="text2" value="true">Yes</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="re-labels"> Repeats on </label>
                    <select class="form-control">
                      <option>
                        Daily, Weekly, WeekdaysMonthly, Yearly, Custom
                      </option>
                      <option value="">Daily</option>
                      <option value="">Weekly</option>
                      <option value="">Monthly</option>
                      <option value="">Yearly</option>
                    </select>
                    <div class="text-danger re-error-txt"></div>
                  </div>
                </div>
                <div class="col-12 col-md-8 col-sm-12">
                  <div class="form-group">
                    <label class="re-labels">End repeat on</label>
                    <p-calendar
                      styleClass="ngCalendarClass"
                      [showIcon]="true"
                      [icon]="'fa fa-calendar'"
                      [minDate]="minimumDate"
                      appendTo="body"
                      placeholder="Repeats ends on"
                    ></p-calendar>
                    <div class="text-danger re-error-txt"></div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <div
            role="tabpanel"
            id="tabs22"
            class="tab-pane"
            [ngClass]="!isGeneralTask ? 'active' : ''"
          >
            <div class="boxes" style="padding-top: 15px">
              <div class="row">
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="re-labels">Select Task Template</label>
                    <select
                      name="taskTemplateOption"
                      formControlName="taskTemplateOption"
                      class="form-control"
                      (change)="
                        onChangeTemplate(
                          addEditTaskForm.value.taskTemplateOption
                        )
                      "
                    >
                      <option value="">
                        Choose a template to auto fill task details
                      </option>
                      <option
                        *ngFor="let task of taskTemplate"
                        value="{{ task._id }}"
                      >
                        {{ task.templateName }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="re-labels">
                      Property Address <span class="text-danger">*</span>
                    </label>

                    <p-autoComplete
                      *ngIf="!data.isLocalTask && data.category == 'Add'"
                      placeholder="Type Property"
                      formControlName="subModuleId"
                      appendTo="body"
                      field="address"
                      name="subModuleId"
                      [suggestions]="propertyList"
                      (completeMethod)="getPropertyAddress($event.query)"
                      emptyMessage="{{ noDataAvailable }}"
                      dataKey="_id"
                      [multiple]="false"
                      [dropdown]="true"
                      (onSelect)="getTeamBySubModuleId($event._id)"
                    >
                    </p-autoComplete>

                    <select
                      *ngIf="
                        data.isLocalTask ||
                        (!data.isLocalTask && data.category == 'Update')
                      "
                      class="form-control"
                      name="subModuleId"
                      formControlName="subModuleId"
                      [attr.disabled]="isMarketReadonly ? 'disabled' : null"
                    >
                      <option [value]="data.leadInfo._id">
                        {{
                          !data.leadInfo.address
                            ? "N/A"
                            : _utilities.formatAddress(
                                data.leadInfo.address,
                                data.leadInfo?.unitNo
                              )
                        }}
                      </option>
                    </select>

                    <div class="text-danger re-error-txt">
                      <span
                        *ngIf="submitted && hasError('subModuleId', 'required')"
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Property"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="re-labels">
                      Task Name <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="title"
                      formControlName="title"
                      placeholder="Task Name"
                    />
                    <div class="text-danger re-error-txt">
                      <span *ngIf="submitted && hasError('title', 'required')">
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Task Name"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="re-labels"
                      >Task Type <span class="text-danger">*</span></label
                    >
                    <select
                      name="taskTypeOption"
                      formControlName="taskTypeOption"
                      class="form-control"
                    >
                      <option value="">-Select-</option>
                      <option
                        *ngFor="let task of taskType"
                        value="{{ task.id }}"
                      >
                        {{ task.name }}
                      </option>
                    </select>
                    <div class="text-danger">
                      <span
                        *ngIf="
                          submitted && hasError('taskTypeOption', 'required')
                        "
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Task Type"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="re-labels">Task Detail </label>
                    <textarea
                      class="form-control"
                      name="taskDetail"
                      formControlName="taskDetail"
                      rows="2"
                      placeholder="Task Detail"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div class="boxes">
              <div class="row">
                <div class="col-12 col-md-12 col-sm-12">
                  <span class="biglabel">Assignment & due</span>
                </div>
                <div class="col-12 col-md-6 col-sm-12">
                  <div class="form-group">
                    <label class="re-labels"
                      >Select role to assign
                      <span class="text-danger">*</span></label
                    >
                    <select
                      name="roleId"
                      formControlName="roleId"
                      class="form-control"
                      (change)="
                        onChangeRole(addEditTaskForm.value.roleId, true)
                      "
                    >
                      <option value="">--Select--</option>
                      <option *ngFor="let role of roles" [value]="role._id">
                        {{ role?.name }}
                      </option>
                    </select>
                    <div class="text-danger re-error-txt">
                      <span *ngIf="submitted && hasError('roleId', 'required')">
                        {{
                          messageConstant.requiredField.replace("Field", "Role")
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-sm-12">
                  <div class="form-group">
                    <label class="re-labels"> Priority</label>
                    <div class="re-priority-select">
                      <select
                        name="taskPriority"
                        class="form-control re-urgent"
                        formControlName="taskPriority"
                        [class.re-urgent]="priorityClass == 1"
                        [class.re-urgent-2]="priorityClass == 2"
                        [class.re-urgent-3]="priorityClass == 3"
                        [class.re-urgent-4]="priorityClass == 4"
                        [class.re-urgent-5]="priorityClass == 5"
                        (change)="
                          onChangePriority(addEditTaskForm.value.taskPriority)
                        "
                      >
                        <option
                          style="color: black; background-color: #fff"
                          value="1"
                        >
                          Urgent Priority
                        </option>
                        <option
                          style="color: black; background-color: #fff"
                          value="2"
                        >
                          High Priority
                        </option>
                        <option
                          style="color: black; background-color: #fff"
                          value="3"
                        >
                          Medium Priority
                        </option>
                        <option
                          style="color: black; background-color: #fff"
                          value="4"
                        >
                          Low Priority
                        </option>
                        <option
                          style="color: black; background-color: #fff"
                          value="5"
                        >
                          None Priority
                        </option>
                      </select>
                      <i class="fa fa-flag-o" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 col-md-12 col-sm-12"
                  *ngIf="showAssignToUser"
                >
                  <div class="form-group">
                    <label class="re-labels">
                      Assign To User <span class="text-danger">*</span>
                    </label>
                    <select
                      name="assignUserId"
                      formControlName="assignUserId"
                      class="form-control"
                    >
                      <option value="">--Select--</option>
                      <option
                        *ngFor="let user of assignUserList"
                        value="{{ user._id }}"
                      >
                        {{ user?.firstName }} {{ user?.lastName }}
                      </option>
                    </select>
                    <div class="text-danger re-error-txt">
                      <span
                        *ngIf="
                          submitted && hasError('assignUserId', 'required')
                        "
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Assign To User"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-12 col-sm-12">
                  <label class="re-labels">
                    Task Due <span class="text-danger">*</span>
                  </label>
                  <div class="radiolist mb-2">
                    <label class="radiobtn">
                      <input
                        type="radio"
                        name="leadtasktime"
                        [checked]="taskDueNumber == 0"
                        (click)="taskDue('One Hour')"
                      />
                      <span class="checkmark"></span>
                      One Hour
                    </label>
                    <label class="radiobtn">
                      <input
                        type="radio"
                        name="leadtasktime"
                        [checked]="taskDueNumber == 1"
                        (click)="taskDue('One Day')"
                      />
                      <span class="checkmark"></span>
                      One Day
                    </label>
                    <label class="radiobtn">
                      <input
                        type="radio"
                        name="leadtasktime"
                        [checked]="taskDueNumber == 2"
                        (click)="taskDue('One Week')"
                      />
                      <span class="checkmark"></span>
                      One Week
                    </label>
                    <label class="radiobtn">
                      <input
                        type="radio"
                        name="leadtasktime"
                        [checked]="taskDueNumber == 3"
                        (click)="taskDue('Custom')"
                      />
                      <span class="checkmark"></span>
                      Custom
                    </label>
                  </div>
                </div>
                <div class="col-12 col-md-8 col-sm-12">
                  <div class="form-group">
                    <p-calendar
                      formControlName="dueInIso"
                      styleClass="ngCalendarClass"
                      [showIcon]="true"
                      [icon]="'fa fa-calendar'"
                      [minDate]="minimumDate"
                      appendTo="body"
                      placeholder="Due date"
                      (onSelect)="taskDueValidationChange()"
                    ></p-calendar>
                    <div class="text-danger re-error-txt">
                      <span
                        *ngIf="submitted && hasError('dueInIso', 'required')"
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Due Date"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-4 col-sm-12">
                  <div class="form-group" *ngIf="customTime">
                    <div class="re-custom-select">
                      <select class="form-control" formControlName="time">
                        <option
                          *ngFor="let number of timeOption"
                          [value]="number"
                        >
                          {{ number }}
                        </option>
                      </select>
                      <i class="fa fa-clock-o"></i>
                    </div>
                  </div>
                  <div class="form-group" *ngIf="!customTime">
                    <p-calendar
                      formControlName="time"
                      styleClass="ngCalendarClass"
                      [timeOnly]="true"
                      inputId="timeonly"
                      [showIcon]="true"
                      [icon]="'fa fa-clock-o'"
                      hourFormat="12"
                      [minDate]="minimumTime"
                      appendTo="body"
                      placeholder="Due time"
                      (onSelect)="taskDueValidationChange()"
                    ></p-calendar>
                    <div class="text-danger re-error-txt">
                      <span *ngIf="submitted && hasError('time', 'required')">
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Due Time"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="re-labels">
                      Timezone <span class="text-danger">*</span>
                    </label>
                    <select
                      name="tasktimezone"
                      formControlName="tasktimezone"
                      class="form-control"
                      (change)="timezoneSelected($event)"
                    >
                      <option
                        *ngFor="let timezone of timezoneList"
                        value="{{ timezone.timezonename }}"
                      >
                        {{ timezone.name }}
                      </option>
                    </select>
                    <div class="text-danger re-error-txt">
                      <span
                        *ngIf="
                          submitted && hasError('tasktimezone', 'required')
                        "
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Task Timezone"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="boxes">
              <div class="row">
                <div class="col-12 col-md-12 col-sm-12">
                  <span class="biglabel">notify user</span>
                  <div class="mb-2">
                    <div
                      class="notify re-file-share-modal d-inline-block w-100"
                    >
                      <div class="radiosec">
                        <p class="radotext">
                          Would you like to notify the assignee on task
                          creation?
                        </p>
                        <label class="switch">
                          <input
                            type="checkbox"
                            id="checkbox1"
                            (click)="onInstantNotification()"
                            [checked]="instantNotification"
                          />
                          <span class="slider"></span>
                          <span class="text1" value="false">No</span>
                          <span class="text2" value="true">Yes</span>
                        </label>
                      </div>
                    </div>
                    <div *ngIf="instantNotification === true">
                      <div
                        class="radiolist mb-2"
                        id="radiobtnlist2"
                        *ngIf="!isGeneralTask"
                      >
                        <label class="radiobtn form-check-inline">
                          <input
                            type="radio"
                            formControlName="smsAndEmail"
                            name="smsAndEmail"
                            value="both"
                          /><span class="checkmark"></span>Both SMS and
                          Email</label
                        >
                        <label class="radiobtn form-check-inline">
                          <input
                            type="radio"
                            formControlName="smsAndEmail"
                            name="smsAndEmail"
                            value="sms"
                          /><span class="checkmark"></span>SMS</label
                        >
                        <label class="radiobtn form-check-inline">
                          <input
                            type="radio"
                            formControlName="smsAndEmail"
                            name="smsAndEmail"
                            value="email"
                          /><span class="checkmark"></span>Email</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div class="boxes">
              <div class="row">
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <span class="biglabel">Task Reminder</span>
                  </div>
                </div>
              </div>

              <div
                class="row"
                *ngIf="addEditTaskForm.get('reminders')['controls'].length > 0"
              >
                <div class="col-12 col-md-4 col-sm-12">
                  <label class="re-labels">
                    Remind Via <span class="text-danger">*</span>
                  </label>
                </div>
                <div class="col-12 col-md-4 col-sm-5">
                  <label class="re-labels">
                    Remind Before <span class="text-danger">*</span>
                  </label>
                </div>
                <div class="col-12 col-md-3 col-sm-5">
                  <label class="re-labels">
                    Remind Time <span class="text-danger">*</span>
                  </label>
                </div>
              </div>

              <div formArrayName="reminders">
                <div
                  *ngFor="
                    let control of addEditTaskForm.get('reminders')['controls'];
                    let i = index
                  "
                >
                  <div [formGroupName]="i" class="row">
                    <!-- VIA -->
                    <div class="col-12 col-md-4 col-sm-12">
                      <div class="form-group">
                        <select formControlName="via" class="form-control">
                          <option value="">--Select--</option>
                          <option value="sms">SMS</option>
                          <option value="email">Email</option>
                        </select>
                        <div class="text-danger re-error-txt">
                          <span
                            *ngIf="
                              submitted &&
                              (control.get('via').invalid ||
                                (control.get('via').invalid &&
                                  control.get('via').touched))
                            "
                          >
                            {{
                              messageConstant.requiredField.replace(
                                "Field",
                                "Remind Via"
                              )
                            }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- BEFORE -->
                    <div class="col-12 col-md-4 col-sm-5">
                      <div class="form-group">
                        <select formControlName="before" class="form-control">
                          <option value="">--Select--</option>
                          <option
                            *ngFor="let number of minutesList"
                            value="{{ number }}"
                          >
                            {{ number }}
                          </option>
                        </select>
                        <div class="text-danger re-error-txt">
                          <span
                            *ngIf="
                              submitted &&
                              (control.get('before').invalid ||
                                (control.get('before').invalid &&
                                  control.get('before').touched))
                            "
                          >
                            {{
                              messageConstant.requiredField.replace(
                                "Field",
                                "Remind Before"
                              )
                            }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- TIME -->
                    <div class="col-12 col-md-3 col-sm-5">
                      <div class="form-group">
                        <select formControlName="time" class="form-control">
                          <option value="">--Select--</option>
                          <option
                            *ngFor="let timeType of taskTimeTypeList"
                            value="{{ timeType }}"
                          >
                            {{ timeType }}
                          </option>
                        </select>
                        <div class="text-danger re-error-txt">
                          <span
                            *ngIf="
                              submitted &&
                              (control.get('time').invalid ||
                                (control.get('time').invalid &&
                                  control.get('time').touched))
                            "
                          >
                            {{
                              messageConstant.requiredField.replace(
                                "Field",
                                "Remind Time"
                              )
                            }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- ACTION -->
                    <div class="col-12 col-md-1 col-sm-2">
                      <div class="form-group text-center re-trash-icon">
                        <a class="pointer" (click)="removeReminder(i)"
                          ><img src="assets/images/delete-red.svg" alt="delete"
                        /></a>
                      </div>
                    </div>

                    <div class="col-12 re-invalid-reminder" #errorReminder>
                      <span
                        class="text-danger"
                        *ngIf="
                          submitted &&
                          !control.get('via').invalid &&
                          !control.get('before').invalid &&
                          !control.get('time').invalid &&
                          reminderErrors.length &&
                          reminderErrors[i]
                        "
                      >
                        {{ reminderErrors[i] }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-12 col-sm-12 text-center">
                  <a
                    class="btn addreminder"
                    href="javascript:;"
                    (click)="addReminder()"
                  >
                    Add Reminder
                  </a>
                </div>
              </div>
            </div>
            <!-- <hr />
            <div class="boxes">
              <div class="row">
                <div class="col-12 col-md-12 col-sm-12">
                  <span class="biglabel">repeat</span>
                </div>
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="re-file-share-modal mb-2 d-inline-block w-100">
                    <div class="radiosec">
                      <p class="radotext">Want this task to repeat?</p>
                      <label class="switch">
                        <input type="checkbox" id="checkbox1" />
                        <span class="slider"></span>
                        <span class="text1" value="false">No</span>
                        <span class="text2" value="true">Yes</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="re-labels"> Repeats on </label>
                    <select class="form-control">
                      <option>
                        Daily, Weekly, WeekdaysMonthly, Yearly, Custom
                      </option>
                      <option value="">Daily</option>
                      <option value="">Weekly</option>
                      <option value="">Monthly</option>
                      <option value="">Yearly</option>
                    </select>
                    <div class="text-danger re-error-txt"></div>
                  </div>
                </div>
                <div class="col-12 col-md-8 col-sm-12">
                  <div class="form-group">
                    <label class="re-labels">End repeat on</label>
                    <p-calendar
                      styleClass="ngCalendarClass"
                      [showIcon]="true"
                      [icon]="'fa fa-calendar'"
                      [minDate]="minimumDate"
                      appendTo="body"
                      placeholder="Repeats ends on"
                    ></p-calendar>
                    <div class="text-danger re-error-txt"></div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <hr />
        <div class="boxes">
          <div class="row">
            <div class="col-12 col-md-12 col-sm-12">
              <div class="checkboxlist mb-3">
                <label class="checkboxbtn text-justify mr-0 pl-0">
                  By opting in to SMS Notifications, you consent to receive
                  notifications regarding tasks and appointments created by you.
                  You understand and agree that text messages sent to your
                  mobile number may be generated using automated technology.
                  Message and data rates may apply. Reply STOP to opt-out.
                  Consent is not a condition of any purchase.
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <hr style="margin-bottom: 8px !important" />
    <mat-dialog-actions align="center">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button
        mat-flat-button
        color="primary"
        type="submit"
        (click)="save()"
        cdkFocusInitial
        [disabled]="!(addEditTaskForm.valid && addEditTaskForm.dirty || checkCustomTime)"
      >
        {{ button }} Task
      </button>
    </mat-dialog-actions>
  </form>
</div>
