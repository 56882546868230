import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
// import { PubsubModule } from '@fsms/angular-pubsub';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import Bugsnag from '@bugsnag/js';
import {
  ServiceWorkerModule,
  SwRegistrationOptions,
} from '@angular/service-worker';

// COMPONENTS
import { AppComponent } from './app.component';
import { SidebarComponent } from './navigation/sidebar/sidebar.component';
import { HeaderComponent } from './navigation/header/header.component';
import { EsignViewDocumentComponent } from './modules/esign/esign-view-document/esign-view-document.component';
import { NotificationComponent } from './navigation/header/notification/notification.component';

// SERVICES
import { AuthGuard } from './providers/guard/auth.guard';
import { AuthService } from './providers/auth/auth.service';
import { LazyGuard } from './providers/guard/lazy.guard';
import { SocketIoService } from './providers/socket-io/socket-io.service';
import { TokenInterceptor } from './token.interceptor';
import { GlobalErrorHandler } from './global-error-handler';
import { UpdateService } from './update.service';
import { HttpCancelInterceptor } from 'src/app/http-cancel.interceptor';
import { HttpCancelService } from 'src/app/providers/http-cancel/http-cancel.service';

// PIPES
import { ThousandSeparatorPipe } from './utils/pipes/thousand-separator.pipe';
import { TabContentComponent } from './navigation/header/notification/tab-content/tab-content.component';
import { SettingsComponent } from './navigation/settings/settings.component';

// CONFIGURE BUGSNAG
let config = {
  apiKey: environment.bugSnag.apiKey,
  appVersion: environment.version,
  releaseStage: environment.releaseStage,
  enabledReleaseStages: ['development', 'live'],
};

if (environment.releaseStage === 'local') {
  config['logger'] = null;
  config['enabledBreadcrumbTypes'] = [];
}

Bugsnag.start(config);

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    HeaderComponent,
    EsignViewDocumentComponent,
    ThousandSeparatorPipe,
    NotificationComponent,
    TabContentComponent,
    SettingsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    AutoCompleteModule,
    // PubsubModule.forRoot(),
    MatSnackBarModule,
    ServiceWorkerModule.register('ngsw-worker.js'),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCancelInterceptor,
    },
    AuthGuard,
    AuthService,
    LazyGuard,
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({ enabled: environment.production }),
    },
    HttpCancelService,
    UpdateService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    SocketIoService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
