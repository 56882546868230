import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// UTILS
import { MessageConstant } from 'src/app/utils/message-constant';
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../shared.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-save-filter-template-dialog',
  templateUrl: './save-filter-template-dialog.component.html',
  styleUrls: ['./save-filter-template-dialog.component.scss'],
})
export class SaveFilterTemplateDialogComponent implements OnInit {
  templateForm: FormGroup;

  submitted: boolean = false;
  isTemplateExists: boolean = false;
  isEdit: boolean = false;
  minimumValue: number = 50;

  messageConstant = MessageConstant;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    private _dialogRef: MatDialogRef<SaveFilterTemplateDialogComponent>,
    private _loaderService: NgxUiLoaderService,
    private _sharedService: SharedService,
    private _toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.templateForm = this._fb.group({
      title: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(this.minimumValue),
          Validators.pattern('.*[^ ].*'),
        ]),
      ],
    });

    if (this.data?.template) {
      this.isEdit = true;
      this.templateForm.patchValue({ title: this.data?.template?.title });
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.templateForm.controls[controlName].hasError(errorName);
  };

  onSubmit() {
    this.submitted = true;
    this.templateForm.value.title = this.templateForm.value.title.trim();
    if (this.templateForm.invalid || !this.templateForm.value.title) return;

    let obj = {
      ...this.templateForm.value,
      ...this.data,
    };

    if (this.isEdit) {
      obj['filterTemplateId'] = this.data?.template?._id;
      delete obj['moduleId'];
    }

    delete obj['template'];
    delete obj['filterType'];

    this._loaderService.start();
    if (this.data.filterType) {
      this._sharedService
        .saveDialerFilterTemplates(
          obj,
          this.isEdit ? 'filterUpdate' : 'filterSave'
        )
        .subscribe(
          (response: ResponseModel) => {
            if (response?.statusCode == 200) {
              this._loaderService.stop();
              if (this.isEdit) {
                this._toastrService.success(
                  this.messageConstant.filterEditSuccess
                );
              } else {
                this._toastrService.success(
                  this.messageConstant.filterSaveSuccess
                );
              }
              this._dialogRef.close(response.data);
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
    } else {
      this._sharedService
        .saveFilterTemplates(obj, this.isEdit ? 'edit' : 'save')
        .subscribe(
          (response: ResponseModel) => {
            if (response?.statusCode == 200) {
              this._loaderService.stop();
              if (this.isEdit) {
                this._toastrService.success(
                  this.messageConstant.filterEditSuccess
                );
              } else {
                this._toastrService.success(
                  this.messageConstant.filterSaveSuccess
                );
              }
              this._dialogRef.close(response.data);
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
    }
  }
}
