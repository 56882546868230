import { Component, Inject, OnInit } from '@angular/core';

import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ListStackingService } from './../../../providers/list-stacking/list-stacking.service';
import { DialerService } from 'src/app/providers/dialer/dialer.service';
import { ListErrorModel } from 'src/app/utils/models/error';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { MessageConstant } from '../../../utils/message-constant';
import { ConfirmationDialogComponent } from 'src/app/shared/dialog/confirmation-dialog/confirmation-dialog.component';
import {
  ListResponseModel,
  ResponseModel,
} from '../../../utils/models/response';
import { SharedService } from '../../shared.service';
@Component({
  selector: 'app-add-tag-list-skip',
  templateUrl: './add-tag-list-skip.component.html',
  styleUrls: ['./add-tag-list-skip.component.scss'],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class AddTagListSkipComponent implements OnInit {
  ListId: any[] = [];
  TagId: any[] = [];
  ListNames: any[] = [];
  tagsNames: any[] = [];
  listslistData: any;
  listViewData: any;
  tagsData: any;
  tagsViewData: any;
  noDataAvailable = 'No Data Available';
  public skipTraceName: string = '';
  public loadSkipTraceLoader: boolean = false;
  public newTagName: any;
  dialogRef;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<AddTagListSkipComponent>,
    private _listStackingService: ListStackingService,
    private _dialerService: DialerService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _sharedService: SharedService,
    public router: Router,
    private globalDialog: MatDialog
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    if (
      this.data?.subModule != 'Skip' &&
      this.data?.subModule != 'Optout' &&
      this.data?.subModule != 'Direct Mail'
    )
      this.getListsTags();
  }
  addNew(mode) {
    if (mode == 'Tag') {
      if (
        this.newTagName == '' ||
        this.newTagName == null ||
        this.newTagName == undefined
      ) {
        this._toastrService.error('Please enter Tag Name');
      } else if (this.newTagName.length > 50) {
        this._toastrService.error(MessageConstant.tagLengthError);
      } else {
        this._loaderService.start();
        let myobj = { name: this.newTagName };
        this._listStackingService.addListsTags(myobj).subscribe(
          (response: ListResponseModel) => {
            this._loaderService.stop();
            if (response.code == 200) {
              this.getListsTags();
              if (this.TagId.length > 0) {
                this.TagId.push({
                  _id: response.result._id,
                  name: response.result.name,
                });
              } else {
                let tgid = [];
                tgid.push({
                  _id: response.result._id,
                  name: response.result.name,
                });
                this.TagId = tgid;
              }
              this._toastrService.success('Tag Created Successfully');
              this.newTagName = '';
            } else {
              this._toastrService.error(MessageConstant.nonUniqueTag);
            }
          },
          (err: ListErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ListResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(MessageConstant.unknownError, '');
            }
          }
        );
      }
    } else {
      if (
        this.newTagName == '' ||
        this.newTagName == null ||
        this.newTagName == undefined
      ) {
        this._toastrService.error('Please enter List Name');
        return false;
      } else if (this.newTagName.length > 50) {
        this._toastrService.error(
          'List Name cannot be more than 50 characters'
        );
        return false;
      } else {
        this._loaderService.start();
        let myobj = { name: this.newTagName };
        this._listStackingService.addLists(myobj).subscribe(
          (response: ListResponseModel) => {
            this._loaderService.stop();
            if (response.code == 200) {
              this.getLists();
              if (response.result) {
                this.ListId.push({
                  _id: response.result._id,
                  name: response.result.name,
                });
                this.ListId.sort((a, b) => a.name.localeCompare(b.name));
              }

              this._toastrService.success('List Created Successfully');
              this.newTagName = '';
            } else {
              this._toastrService.error(MessageConstant.nonUniqueList);
            }
          },
          (err: ListErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ListResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(MessageConstant.unknownError, '');
            }
          }
        );
      }
    }
  }
  getListsTags() {
    this._listStackingService.getListsTags({}).subscribe(
      (response: ListResponseModel) => {
        if (response.code == 200) {
          this.tagsData = response.data;
          this.tagsViewData = response.data;
        }
      },
      (err: ListErrorModel) => {
        console.log('errpr');
      }
    );
  }
  getLists() {
    this._listStackingService.getLists({}).subscribe(
      (response: ListResponseModel) => {
        if (response.code == 200) {
          this.listslistData = response.data;
          this.listViewData = response.data;
        }
      },
      (err: ListErrorModel) => {
        console.log('errpr');
      }
    );
  }
  clearListItem(autocomplete: any) {
    //console.log(autocomplete);
    autocomplete.value = '';
    //console.log("autocomplete.value", autocomplete.value);
    autocomplete.handleDropdownClick();
  }
  searchAddList(listName) {
    this._listStackingService.getSearchListslist({ name: listName }).subscribe(
      (response: ListResponseModel) => {
        if (response.code == 200) {
          this.listViewData = response.data;
          let suggestionArray = [],
            flag;
          for (let i = 0; i < response.data.length; i++) {
            flag = 1;
            if (this.ListId != null && this.ListId.length > 0) {
              for (let j = 0; j < this.ListId.length; j++) {
                if (response.data[i]._id == this.ListId[j]._id) {
                  flag = 0;
                  break;
                }
              }
            }
            if (flag == 1) suggestionArray.push(response.data[i]);
          }
          this.listslistData = suggestionArray;
          this.listslistData.sort((a, b) => a.name.localeCompare(b.name));
        }
      },
      (err: ListErrorModel) => {
        console.log('errpr');
      }
    );
  }
  addTagSearch(tagName) {
    this._listStackingService.getSearchListsTags({ name: tagName }).subscribe(
      (response: ListResponseModel) => {
        if (response.code == 200) {
          this.tagsViewData = response.data;
          let suggestionArray = [],
            flag;

          for (let i = 0; i < response.data.length; i++) {
            flag = 1;
            if (this.TagId != null) {
              for (let j = 0; j < this.TagId.length; j++) {
                if (response.data[i]._id == this.TagId[j]._id) {
                  flag = 0;
                  break;
                }
              }
            }
            if (flag == 1) suggestionArray.push(response.data[i]);
          }
          this.tagsData = suggestionArray;
          this.tagsData.sort((a, b) => a.name.localeCompare(b.name));
        }
      },
      (err: ListErrorModel) => {
        console.log('errpr');
      }
    );
  }
  checkExportToFrom() {
    if (
      this.data.filter.dataType == 'Custom' &&
      parseInt(this.data?.filter?.exportCustomTo) >
        parseInt(this.data?.filter?.exportCustomFrom)
    ) {
      this._toastrService.error(
        "Custom range min value can't be more than max"
      );
      return false;
    } else return true;
  }

  updatemultiLists() {
    if (this.checkExportToFrom()) {
      if (
        this.ListId === null ||
        this.ListId === undefined ||
        this.ListId.length == 0
      ) {
        this._toastrService.error('Please Select List');
        return false;
      } else {
        //this.getLists();
        var listid = [];
        for (var i = 0; i < this.ListId.length; i++) {
          listid.push(this.ListId[i]._id);
        }
        let obj = this.data.filter;
        obj['listId'] = listid;
        obj['action'] = 'add';
        this._loaderService.start();
        this._listStackingService.updatemultilists(obj).subscribe(
          async (response: ListResponseModel) => {
            //this._loaderService.stop();
            if (response.code == 200) {
              //await this.getListNames(listid);
              this.addActivityList();
              this.dialog.close(listid);
              this._toastrService.success(response.message);
            }
          },
          (err: ListErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ListResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(MessageConstant.unknownError, '');
            }
          }
        );
      }
    }
  }
  updatemultiTags() {
    if (
      this.TagId === null ||
      this.TagId === undefined ||
      this.TagId.length == 0
    ) {
      this._toastrService.error('Please Select Tags');
      return false;
    } else {
      //this.getListsTags();
      var tgid = [];
      for (var i = 0; i < this.TagId.length; i++) {
        tgid.push(this.TagId[i]._id);
      }
      let obj = this.data.filter;
      obj['TagId'] = tgid;
      obj['action'] = 'add';
      this._loaderService.start();
      this._listStackingService.updatemultitags(obj).subscribe(
        async (response: ListResponseModel) => {
          //this._loaderService.stop();
          if (response.code == 200) {
            //await this.getTagsNames(tgid);
            this.addActivityList();
            if (this.data?.subModule == 'Tag') {
              this._toastrService.success(MessageConstant.tagsAddedSuccess);
            } else {
              this._toastrService.success(response.message);
            }
            this.dialog.close(tgid);
          }
        },
        (err: ListErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ListResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(MessageConstant.unknownError, '');
          }
        }
      );
    }
  }
  pressSkipEnter(keycode) {
    if (keycode === 13) {
      this.checkSkipTraceCredit();
    }
  }
  checkSkipTraceCredit() {
    if (this.skipTraceName === '' || this.skipTraceName.length == 0) {
      this._toastrService.error('Please enter Skip Trace Name');
      return false;
    } else {
      this._loaderService.start();
      this.loadSkipTraceLoader = true;
      this._listStackingService.getSkipTraceCredit({}).subscribe(
        (response) => {
          if (response.statusCode == 200) {
            let resData = parseInt(response.data);
            let countRecords = parseInt(this.data?.countRecords);
            if (resData >= countRecords) {
              this.checkUniqueSkipTrace();
            } else {
              this.loadSkipTraceLoader = false;
              this._toastrService.error(
                'Your skip trace credit is low. Please buy skip trace credit and retry.'
              );
              this._loaderService.stop();
            }
          } else {
            this.loadSkipTraceLoader = false;
            this._toastrService.error(response.message);
          }
        },
        (err) => {
          this.loadSkipTraceLoader = false;
          console.log(err);
        }
      );
    }
  }

  confirmRemoveTags(mode, _id) {
    this.dialogRef = this.globalDialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: {
        header: 'Confirmation',
        text: `Are you sure you want to remove this ${mode}?`,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let obj = {
            createdAt: -1,
            dataType: 'Self',
            limit: 10,
            page: 1,
            ids: this.data?.filter?.ids,
          },
          callFunction,
          objSubmit;

        let activityMsg = [{ name: 'Property updated Successfully' }];
        if (mode == 'Tag') {
          obj['TagId'] = [_id];
          obj['action'] = 'remove';
          activityMsg = [{ name: 'Tag removed Successfully' }];
          callFunction = this._listStackingService.updatemultitags(obj);
        } else if (mode == 'List') {
          obj['listId'] = [_id];
          obj['action'] = 'remove';
          activityMsg = [{ name: 'List removed Successfully' }];
          callFunction = this._listStackingService.updatemultilists(obj);
        }

        this._loaderService.start();
        callFunction.subscribe(
          (response: ListResponseModel) => {
            if (response.code == 200) {
              this.data.allData = this.data?.allData.filter((e) => {
                if (e?._id != _id) return e;
              });
              this.saveActivity(
                this.data?.filter?.ids[0],
                response,
                activityMsg,
                mode
              );
            }
          },
          (err: ListErrorModel) => {
            this._loaderService.stop();
          }
        );
      }
    });
  }

  checkUniqueSkipTrace() {
    let myobj = {
      skipTracingName: this.skipTraceName,
    };
    this._listStackingService.checkUniqueSkipTrace(myobj).subscribe(
      (response) => {
        if (response.code == 200) {
          this.submitSkipTrace();
        } else {
          this._loaderService.stop();
          this.loadSkipTraceLoader = false;
          this._toastrService.error('Skip trace name already exists');
        }
      },
      (err) => {
        this._loaderService.stop();
        this.loadSkipTraceLoader = false;
        console.log(err);
      }
    );
  }

  submitSkipTrace() {
    let myobj = this.data?.filter;
    myobj['skipTracingName'] = this.skipTraceName;

    this._listStackingService.addSkipTrace(myobj).subscribe(
      (response) => {
        this.loadSkipTraceLoader = false;
        this._loaderService.stop();
        this.dialog.close('submit');
        if (response.code == 200) {
          this.addActivityList();
          if (this.data?.from == 'details') {
            this._toastrService.success(
              'Records added for Skip Tracing. Please refresh page and view them in Phone Number tab.'
            );
          } else {
            this._toastrService.success(
              'Records added for Skip Tracing. Please refresh page to view them in Skip Tracing tab.'
            );
          }
        } else {
          this._toastrService.error(response.message);
        }
      },
      (err) => {
        this._loaderService.stop();
        this.loadSkipTraceLoader = false;
        console.log(err);
      }
    );
  }

  updatemultioptouts() {
    let myobj = this.data?.filter;
    myobj['optValue'] = this.data?.optValue;
    this._loaderService.start();
    this._listStackingService.updatemultioptouts(myobj).subscribe(
      (response) => {
        this._loaderService.stop();
        this.dialog.close('submit');
        if (response.code == 200) {
          this.addActivityList();
          this._toastrService.success(response.message);
        } else {
          this._toastrService.error(response.message);
        }
      },
      (err) => {
        this._loaderService.stop();
        console.log(err);
      }
    );
  }

  orderDMail(filePath?) {
    let myobj = { rows: this.data?.rows, filePath: filePath };
    this._loaderService.start();
    this._listStackingService.orderFromLS(myobj).subscribe(
      (response) => {
        if (response.statusCode == 200) {
          this.dialog.close();
          localStorage.setItem('dMailQuery', JSON.stringify(this.data?.filter));
          localStorage.setItem('filerow', this.data?.rows);
          localStorage.setItem('orderId', response?.data?.nid);
          this.router.navigate(['/direct-mail/create']);
        } else {
          this._loaderService.stop();
          this._toastrService.error(response.message);
        }
      },
      (err) => {
        this._loaderService.stop();
        console.log(err);
      }
    );
  }

  createCSV() {
    let myobj = this.data?.filter;
    this._loaderService.start();
    this._listStackingService.createCsvFile(myobj).subscribe(
      (response) => {
        if (response) {
          this.orderDMail(response?.filePath);
        } else {
          this._loaderService.stop();
          this._toastrService.error(response.message);
        }
      },
      (err) => {
        this._loaderService.stop();
        this._toastrService.error(err.error.message);
        this.dialog.close();
      }
    );
  }

  getListNames(listId) {
    this.ListNames = [];
    let name;
    for (var i = 0; i < listId.length; i++) {
      var ressw3 = listId[i];
      for (var j = 0; j < this.listViewData.length; j++) {
        if (ressw3 == this.listViewData[j]._id) {
          name = this.listViewData[j].name;
          this.ListNames.push({
            name: name,
          });
          break;
        }
      }
    }
  }

  getTagsNames(tagId) {
    this.tagsNames = [];
    let name;
    for (var i = 0; i < tagId.length; i++) {
      var ressw3 = tagId[i];
      for (var j = 0; j < this.tagsViewData.length; j++) {
        if (ressw3 == this.tagsViewData[j]._id) {
          name = this.tagsViewData[j].name;
          this.tagsNames.push({
            name: name,
          });
          break;
        }
      }
    }
  }

  addActivityList() {
    let tagActivity;
    let activityType;
    let activityComment;
    let myobj: any = {
      subModuleId: this.data.filter.ids,
      address: '',
      title: '',
      phoneNumber: '',
    };
    if (this.data?.subModule == 'List') {
      activityType = 34;
      activityComment = this.ListNames;
      if (this.data?.title == 'Add Lists' || this.data?.title == 'Add List') {
        tagActivity = 11;
      } else {
        tagActivity = 12;
      }
    } else if (this.data?.subModule == 'Skip') {
      activityType = 16;
      activityComment = [{ name: 'Property Skip Traced Successfully' }];
      myobj['isPhoneActivity'] = true;
    } else if (this.data?.subModule == 'Optout') {
      activityType = 16;
      if (this.data?.optValue)
        activityComment = [{ name: 'Property Marked Optout Successfully' }];
      else
        activityComment = [
          { name: 'Property Removed from Optout Successfully' },
        ];
      myobj['isPhoneActivity'] = true;
    } else {
      activityType = 19;
      activityComment = this.tagsNames;
      if (this.data.title == 'Add Tags') {
        tagActivity = 7;
      } else {
        tagActivity = 8;
      }
    }

    myobj['comment'] = activityComment;
    myobj['activityType'] = activityType;
    myobj['subType'] = tagActivity;
    //this._loaderService.start();

    this._dialerService.addActivityList(myobj).subscribe(
      (response: ResponseModel) => {
        //this._loaderService.stop();
        if (response.statusCode == 200) {
          this._sharedService.refreshActivityLog.next(true);
        }
      },
      (err) => {
        this._loaderService.stop();
        console.log(err);
      }
    );
  }

  closeModal() {
    if (this.data?.allData && this.data?.allData?.length) this.dialog.close(1);
    else this.dialog.close();
  }

  saveActivity(id, res, activityMsg, mode) {
    let activityComment = activityMsg;
    let myobj = {
      subModuleId: [id],
      comment: activityComment,
      activityType: 16,
      isPhoneActivity: true,
    };
    this._dialerService.addActivityList(myobj).subscribe(
      (response) => {
        this._loaderService.stop();

        this._toastrService.success(res.message);
      },
      (err) => {
        this._loaderService.stop();
        console.log(err);
      }
    );
  }
}
