import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadOutput, UploadInput } from 'ngx-uploader';

// SERVICES
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { BuyersService } from 'src/app/providers/buyers/buyers.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { PreferenceService } from 'src/app/providers/preference/preference.service';
import { UserService } from 'src/app/providers/user/user.service';

// UTILS
import { environment } from 'src/environments/environment';
import { MessageConstant } from 'src/app/utils/message-constant';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';
import { SharedService } from 'src/app/shared/shared.service';
import { ListViewComponent } from 'src/app/shared/list-view/list-view.component';

@Component({
  selector: 'app-add-edit-interested-buyers',
  templateUrl: './add-edit-interested-buyers.component.html',
  styleUrls: ['./add-edit-interested-buyers.component.scss'],
})
export class AddEditInterestedBuyersComponent implements OnInit {
  @ViewChild('uploader', { read: ElementRef }) fileInput: ElementRef;
  @ViewChild('listView') listView: ListViewComponent;
  uploadInput: EventEmitter<UploadInput>;
  addEditContactForm: FormGroup;

  type: string = 'Add';
  messageConstant = MessageConstant;
  isSubmitted: boolean = false;
  isEdit: boolean = false;
  contingencies: any[] = [
    { value: 'appraisal', label: 'Appraisal' },
    { value: 'financing', label: 'Financing' },
    { value: 'flood', label: 'Flood' },
    { value: 'house-sale', label: 'House Sale' },
    { value: 'inspection', label: 'Inspection' },
    { value: 'insurance', label: 'Insurance' },
    { value: 'other', label: 'Other' },
  ];

  buyer: any;
  buyerName: string = '';
  buyerNumber: string = '';
  buyerEmail: string = '';
  filteredBuyers: any[] = [];
  groups: any[] = [];
  promises: any[] = [];
  uploadFiles: any[] = [];
  filesArr: any[] = [];
  buyerModuleId: string = '6007bd05b84a567a12c3bca9';

  phoneAlreadyInUse: boolean = false;
  emailAlreadyInUse: boolean = false;
  buyerPhoneInvalid: boolean = false;
  buyerMailInvalid: boolean = false;
  isContactUpdate: boolean = false;
  dropFile: boolean = false;
  isDisabled: boolean = false;
  count: number = 0;

  files: any = {};
  paginationType: string = MiscellaneousConstant.paginationType.INFINITE;
  currentFolder: any = '';
  currentPage: number = 1;
  currentFilePage: number = 1;
  currentLimit: number = 100;
  foldersData: any[] = [];
  folderName: string;
  folders: any[] = [];
  folderCount: number = 0;
  sortFiles: string = environment.pagination.defaultSort;
  selectedItem: any[] = [];
  isUnderMaintenance: boolean = false;

  header: any = [
    {
      label: 'File Name',
      key: 'title',
      isClickExists: true,
      event: 'PREVIEW',
      isIconExists: true,
      iconType: 'FILE',
      isTooltipExists: true,
      sort: { type: this.sortFiles },
    },
    {
      label: 'Date',
      key: 'createdAt',
      pipe: 'DATE',
      sort: { type: this.sortFiles },
    },
    {
      label: 'Action',
      key: 'action',
      options: [
        {
          type: 'DELETE_FILE',
          icon: '/assets/images/delete.svg',
          activeIcon: '/assets/images/deleteActive.svg',
        },
      ],
    },
  ];
  mainFileArr: any = [];
  displayName: string = '';
  mainUserId: string = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    public _utilities: CommonFunctionsService,
    private _buyerService: BuyersService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _dialog: MatDialogRef<AddEditInterestedBuyersComponent>,
    private _preferenceService: PreferenceService,
    public _sharedService: SharedService,
    private _userService: UserService
  ) {}

  ngOnInit(): void {
    this.addEditContactForm = this._fb.group({
      buyerId: [''],
      purchasePrice: [null, [Validators.required]],
      status: [''],
      emd: [0],
      pof: ['yes'],
      contingencies: [[]],
      closingDate: [new Date(), [Validators.required]],
      paymentMethod: ['', [Validators.required]],
      buyerQuestions: this._fb.array([]),
    });
    this.uploadInput = new EventEmitter<UploadInput>();
    if (this.data.item) {
      this.type = 'Edit';
      this.isContactUpdate = true;
      this.selectItem(this.data.item);
      const {
        purchasePrice,
        emd,
        contingencies,
        closingDate,
        buyerId,
        paymentMethod,
        pof,
        docs,
      } = this.data.item;
      let pofVal = pof;

      if (!pof) {
        if (docs && docs?.length > 0) {
          pofVal = 'yes';
        } else {
          pofVal = 'no';
        }
      }

      this.addEditContactForm.patchValue({
        purchasePrice,
        emd,
        contingencies,
        closingDate: new Date(closingDate),
        buyerId,
        paymentMethod,
        pof: pofVal,
      });
      this.mainFileArr = this.data?.item?.docs ? this.data?.item?.docs : [];
    }
    this.getLoginUser();
    if (!this.isEdit) this.getPreferenceSection();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.addEditContactForm.controls[controlName].hasError(errorName);
  };

  getLoginUser() {
    this._userService.getUserDetails({}).subscribe(
      (response) => {
        this.mainUserId = response?.data?._id;
      },
      (err) => {}
    );
  }
  checkBuyer($event, key) {
    let unmaskNum = this._utilities.unMaskNumber(this.buyerNumber);

    if (!$event?.query) {
      return;
    }

    let value = $event?.query;
    if (key === 'email') {
      if (MiscellaneousConstant.emailPatternWithCaps.test(this.buyerEmail)) {
        if (
          this.type === 'UPDATE_BUYER' &&
          value === this.data.buyers[this.data.index].email
        ) {
          this.emailAlreadyInUse = false;
          return;
        }
      } else {
        return;
      }
    }

    if (key === 'number') {
      if (MiscellaneousConstant.phonePattern.test(this.buyerNumber)) {
        value = this._utilities.unMaskNumber($event.query);
        value = environment.countryCode + value;
        if (
          this.type === 'UPDATE_BUYER' &&
          value === this.data.buyers[this.data.index].contactNumber
        ) {
          this.phoneAlreadyInUse = false;
          return;
        }

        if (this.isEdit && value === this.buyer?.contactNumber) {
          this.phoneAlreadyInUse = false;
          return;
        }
      } else {
        return;
      }
    }

    if (key === 'number') {
      if (unmaskNum.length == 10 || unmaskNum.length == 0) {
        this.buyerNumber = this._utilities.maskNumber(unmaskNum);
        this.buyerPhoneInvalid = false;
      } else if (unmaskNum.length < 10) {
        this.buyerPhoneInvalid = true;
      }
    }

    if (key == 'email') {
      this.emailAlreadyInUse = false;
      this.buyerMailInvalid = false;
      let validateVal = this.validateEmail(this.buyerEmail);
      if (!validateVal) {
        this.buyerMailInvalid = true;
      }
    }

    let obj = {
      [key]: value,
      patternMatch: true,
    };

    this._buyerService.checkBuyer(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          if (key === 'name') {
            if (!this.isContactUpdate) this.filteredBuyers = response.data;
            else {
              this.filteredBuyers = [];
            }
          }

          if (key === 'email') {
            if (!this.isContactUpdate) this.filteredBuyers = response.data;
            else {
              this.filteredBuyers = [];
            }

            if (response?.data.length) {
              for (let i = 0; i < response?.data.length; i++) {
                if (
                  response?.data[i]?.email &&
                  response?.data[i]?.email.toUpperCase() == value.toUpperCase()
                ) {
                  this.emailAlreadyInUse = true;
                  break;
                }
              }
            }
          }

          if (key === 'number') {
            if (!this.isContactUpdate) this.filteredBuyers = response.data;
            else {
              this.filteredBuyers = [];
            }

            if (response?.data?.length) {
              for (let i = 0; i < response?.data?.length; i++) {
                if (
                  response?.data[i]?.contactNumber &&
                  response?.data[i]?.contactNumber == value
                ) {
                  this.phoneAlreadyInUse = true;
                  break;
                }
              }
            }
          }
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  numberType(buyer) {
    if (!buyer?.contactNumber) {
      return;
    }

    const type = this._utilities.numberType(buyer?.numberType?.toLowerCase());
    return `(${type || 'N/A'})`;
  }

  checkValidity(mode) {
    if (mode == 'number') {
      if (this.buyerNumber) {
        let unmaskNum = this._utilities.unMaskNumber(this.buyerNumber);
        if (unmaskNum.length == 10 || unmaskNum.length == 0) {
          this.buyerPhoneInvalid = false;
        } else {
          this.buyerPhoneInvalid = true;
        }
      } else {
        this.buyerPhoneInvalid = false;
      }
    }
    if (mode == 'email') {
      this.buyerMailInvalid = false;
      let validateVal = this.validateEmail(this.buyerEmail);
      if (this.buyerEmail && this.buyerEmail.length != 0 && !validateVal) {
        this.buyerMailInvalid = true;
      }
    }
  }

  validateEmail(emailField) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(emailField) == false) {
      return false;
    }
    return true;
  }

  checkValidNumber(event) {
    this.phoneAlreadyInUse = false;
    this.buyerPhoneInvalid = false;
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    let unmaskNum = this._utilities.unMaskNumber(this.buyerNumber);
    if (unmaskNum.length == 10) {
      this.buyerNumber = this._utilities.maskNumber(unmaskNum);
      return false;
    }
    return true;
  }

  selectItem(buyer) {
    const checkUnique = this.data.interestedBuyerList.find(
      (x) => x._id === buyer._id
    );

    if (
      (this.isContactUpdate &&
        this.data.item.buyerId !== buyer._id &&
        checkUnique) ||
      (!this.isContactUpdate && checkUnique)
    ) {
      this._toastrService.error(this.messageConstant.buyerAlreadyExists);
      this.buyerName = '';
      this.buyerNumber = '';
      this.buyerEmail = '';
      this.displayName = '';
      this.isEdit = false;
      return;
    }

    while (this.buyerQuestions?.controls?.length) {
      this.buyerQuestions.removeAt(0);
    }

    this.isEdit = true;
    this.buyer = buyer;

    this.phoneAlreadyInUse = false;
    this.emailAlreadyInUse = false;
    this.buyerPhoneInvalid = false;
    this.buyerMailInvalid = false;

    setTimeout(() => {
      this.buyerName = buyer?.firstName;
      if (buyer?.lastName) {
        this.buyerName += ` ${buyer?.lastName} `;
      }

      this.buyerName = this._utilities.capitalizeName(this.buyerName);
      this.buyerNumber = this._utilities.maskNumber(buyer?.contactNumber);
      this.buyerEmail = buyer?.email;

      this.displayName = `${this._utilities.capitalizeName(this.buyerName)}  `;
      if (buyer?.contactNumber) {
        this.displayName += `| ${this._utilities.maskNumber(
          buyer?.contactNumber
        )}`;
      }
      if (buyer?.email) {
        this.displayName += `| ${buyer?.email}`;
      }
    });

    this.addEditContactForm.patchValue({
      buyerId: this.buyer?._id,
    });

    this.groups = [];
    this.buyer?.buyerQuestions?.filter((x) => {
      const objectID = (
        m = Math,
        d = Date,
        h = 16,
        s = (s) => m.floor(s).toString(h)
      ) =>
        s(d.now() / 1000) +
        ' '.repeat(h).replace(/./g, () => s(m.random() * h));

      this.groups.push({
        _id: x?._id || objectID,
        description: x?.groupDescription,
        title: x?.groupTitle,
        fields: x?.question,
      });
    });
    this.addPreferenceForm(this.groups);
  }

  get buyerQuestions() {
    return this.addEditContactForm.get('buyerQuestions') as FormArray;
  }

  getPreferenceSection() {
    const obj = {
      page: 1,
      limit: 100,
      moduleId: this.buyerModuleId,
    };
    this._preferenceService.getGroups(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.groups = this._utilities.orderItems(
            response.data.items,
            'order'
          );
          this.promises = [];

          this.groups.filter((x) =>
            this.promises.push(this.getPreferenceQuestions([x?._id]))
          );

          // HANDLE PROMISE
          if (this.promises.length > 0) {
            Promise.all(this.promises)
              .then((result) => {
                result.filter((field) => {
                  const { groupId, response } = field;
                  const index = this.groups.findIndex(
                    (x) => x?._id === groupId
                  );
                  if (index > -1)
                    this.groups[index].fields = response?.data?.items;
                });
                this.addPreferenceForm(this.groups);
                this._loaderService.stop();
              })
              .catch((err) => {
                console.log('======>', err);
                this._loaderService.stop();
                if (err?.error) {
                  const error: ResponseModel = err.error;
                  this._toastrService.error(error.message, '');
                } else {
                  this._toastrService.error(
                    this.messageConstant.unknownError,
                    ''
                  );
                }
              });
          } else {
            this._loaderService.stop();
          }
        } else {
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getPreferenceQuestions(groupId) {
    const promise = new Promise((resolve, reject) => {
      const obj = {
        mainGroupId: groupId,
        page: 1,
        limit: 100,
      };

      this._preferenceService.getFields(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            return resolve({ groupId: groupId[0], response });
          }
        },
        (err: ErrorModel) => {
          return reject(err);
        }
      );
    });
    return promise;
  }

  addPreferenceForm(results) {
    results.forEach((element) => {
      this.buyerQuestions.push(
        this._fb.group({
          group: element._id,
          questions: this._fb.array(
            element.fields.map((x) => {
              return this._fb.group({
                questionId: x._id,
                preferenceQuestion: this.isCheckBox(x),
              });
            })
          ),
        })
      );
    });
  }

  isCheckBox(x) {
    if (x.questionType === 'CHECK_LIST') {
      return this._fb.array(
        x.options.map((y, i) => {
          return this._fb.group({
            key: y.key,
            value: y.value,
            checkboxValue: this.isEdit
              ? (x?.answer || []).some((a) => y.value === a)
              : x.defaultSelected,
          });
        })
      );
    } else if (!this.isEdit && x.questionType === 'RADIO_BUTTON') {
      let answer = x.options.find(
        (o) =>
          o?.defaultSelected !== '' &&
          o?.defaultSelected !== undefined &&
          o?.defaultSelected !== null
      );

      return answer ? answer?.value : '';
    }
    if (this.isEdit && x.questionType === 'DATE') {
      return x.answer ? new Date(x.answer) : '';
    } else if (!this.isEdit && x.questionType === 'CURRENCY') {
      return !this.isEdit ? x.answer : x.defaultSelected || '';
    } else {
      return this.isEdit ? x.answer : x.defaultSelected || '';
    }
  }

  removeSelection() {
    this.isEdit = false;
    this.buyer = {};

    this.phoneAlreadyInUse = false;
    this.emailAlreadyInUse = false;
    this.buyerPhoneInvalid = false;
    this.buyerMailInvalid = false;
    this.buyerName = '';
    this.buyerNumber = '';
    this.buyerEmail = '';
    this.displayName = '';
    this.addEditContactForm.patchValue({
      buyerId: '',
    });
  }

  async addBuyer() {
    this.isSubmitted = true;
    if (this.addEditContactForm.invalid) return;

    if (!this.buyerNumber && !this.buyerEmail) {
      this._toastrService.error('Please enter phone number or email.');
      return;
    }

    if (this.emailAlreadyInUse || this.phoneAlreadyInUse) {
      return;
    }
    if (this.buyerPhoneInvalid || this.buyerMailInvalid) {
      return;
    }

    let firstName = this.buyerName.split(' ').splice(0, 1).join(' ');
    let lastName = this.buyerName.split(' ').splice(1).join(' ');

    let obj = {
      ...this.buyer,
      firstName,
      lastName,
      moduleId: this.buyerModuleId,
    };

    if (!obj['contactType']) {
      obj['contactType'] = 'Buyer';
    }

    if (this.buyerNumber) {
      obj['contactNumber'] =
        environment.countryCode +
        this._utilities.unMaskNumber(this.buyerNumber);
    } else {
      delete obj['contactNumber'];
    }

    if (this.buyerEmail) {
      obj['email'] = this.buyerEmail?.toLowerCase();
    } else {
      delete obj['email'];
    }

    if (!obj['lastName']) {
      delete obj['lastName'];
    }

    if (this.isEdit) {
      obj['_id'] = this.buyer?._id;
      delete obj['createdBy'];
      delete obj['mainUserId'];
      delete obj['numberType'];
    }

    Object.keys(obj).forEach((key) => {
      if (obj[key] == '' || obj[key] == null) delete obj[key];
      if (Array.isArray(obj[key])) {
        obj[key].forEach((subKey) => {
          Object.keys(subKey).forEach(
            (innerKey) =>
              (subKey[innerKey] == '' || subKey[innerKey] == null) &&
              delete subKey[innerKey]
          );
        });

        obj[key].forEach((subKey) => {
          if (!Object.keys(subKey).length) delete obj[key];
        });
      } else {
        if (typeof obj[key] === 'object') {
          Object.keys(obj[key]).forEach((subKey) => {
            if (obj[key][subKey] == '' || obj[key][subKey] == null) {
              delete obj[key][subKey];
            }
          });

          if (!Object.keys(obj[key]).length) delete obj[key];
        }
      }
    });

    if (this.addEditContactForm.value.buyerQuestions) {
      const question = this._utilities.getBuyersQuestions(
        this.addEditContactForm.value.buyerQuestions,
        this.groups
      );
      obj = { ...obj, buyerQuestions: [].concat(...question) };
    }

    if (obj?.forwardingNumber) delete obj['forwardingNumber'];
    if (obj?.number) delete obj['number'];

    delete obj['name'];
    delete obj['phoneNumber'];
    delete obj['buyerQuestion'];
    delete obj['isNewMessage'];
    delete obj['lastMessageTime'];
    delete obj['buyerId'];
    delete obj['purchasePrice'];
    delete obj['status'];
    delete obj['emd'];
    delete obj['pof'];
    delete obj['contingencies'];
    delete obj['closingDate'];
    delete obj['paymentMethod'];
    delete obj['messageCount'];

    this._loaderService.start();
    this._buyerService.addBuyer(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          if (!this.isEdit) {
            this.buyer = response.data;
          }

          this.onSubmit();
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  onSubmit() {
    let obj = {
      ...this.addEditContactForm.value,
      isAdd: true,
      docs: this.mainFileArr,
    };

    if (obj.pof === 'no') obj.docs = [];
    else if (obj.pof === 'yes' && !obj.docs.length) {
      this._loaderService.stop();
      this._toastrService.error(MessageConstant.uploadRequired);
      return;
    }

    if (obj['closingDate'])
      obj['closingDate'] = new Date(obj['closingDate']).getTime();

    if (!this.buyer) {
      this._loaderService.stop();
      return;
    }

    obj['buyerId'] = this.buyer?._id;
    obj['leadId'] = this.data?.leadId;

    const existingBuyer = this.data?.buyerIds?.find((x) => x === obj.buyerId);
    if (existingBuyer) obj['status'] = 'true';

    delete obj['buyerQuestions'];
    this._buyerService.addInterestedBuyer(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          if (this.data?.action == 'EDIT') {
            this._toastrService.success(MessageConstant.offerEditSuccess);
          } else {
            this._toastrService.success(MessageConstant.offerAddSuccess);
          }
          this._loaderService.stop();
          this.addEditContactForm.reset();
          this.buyer = null;
          this.buyerName = '';
          this.buyerNumber = '';
          this.buyerEmail = '';
          this.isEdit = false;

          this._dialog.close({
            ...response.data?.interestedBuyer,
            isEdit: this.isContactUpdate,
          });
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  startUpload() {
    // when all files added in queue
    this.uploadFiles.filter((files) => {
      const obj = {
        isPublic: 1,
        moduleId: this.data?.moduleId,
        fileSize: files?.file?.size,
        fileName: files?.file?.name,
      };
      this._sharedService
        .getUploadUrl(obj)
        .subscribe((response: ResponseModel) => {
          if (response.statusCode === 200) {
            try {
              const fileType = files?.file?.type || 'image/heic';
              this.filesArr.push({
                originalName: files?.file?.name,
                src: response.data?.src,
                fileSize: files?.file?.size,
                fileType,
              });
              const event: UploadInput = {
                type: 'uploadFile',
                url: response.data.url,
                method: 'PUT',
                file: files?.file,
                includeWebKitFormBoundary: false,
                headers: { 'Content-Type': fileType },
              };
              this.isDisabled = false;
              this.uploadInput.emit(event);
            } catch (error) {
              this.isDisabled = false;
              this._loaderService.stop();
              this._toastrService.error(MessageConstant.unknownError);
            }
          }
        });
    });
  }

  onUploadLogo(output: UploadOutput): void {
    this.fileInput.nativeElement.value = '';
    if (output.type === 'allAddedToQueue') {
      this.startUpload();
    } else if (
      output.type === 'addedToQueue' &&
      typeof output.file !== 'undefined'
    ) {
      this._loaderService.start();
      this.uploadFiles.push(output);
    } else if (output.type === 'done' && typeof output.file !== 'undefined') {
      if (output.file.responseStatus == 200) {
        this.count++;
        if (this.count === this.uploadFiles.length) {
          this.filesArr.filter((item) => {
            this.mainFileArr.push({
              title: item?.originalName,
              photo: '',
              fileType: item?.fileType,
              originalName: item?.originalName,
              thumbnailUrl: `${this.mainUserId}/${this.data?.moduleId}/${item?.src}`,
            });
          });
          this._loaderService.stop();
          this.isDisabled = false;
          this.filesArr = [];
          this.count = 0;
          this.uploadFiles = [];
          this._toastrService.success('File Uploaded Successfully');
        }
      }
    }
  }

  getIcon(item?, type?) {
    let name = '';
    // if (type?.iconType === 'FILE') {
    if (type === 'FILE') {
      name = item?.fileType;
    } else if (type === 'CALL_LOGS') {
      name = item?.type;
    } else if (item === 'TASKS') {
      name = item?.type;
    } else if (item === 'CARDS') {
      name = item?.type;
    } else if (type === 'SHARE_SOW') {
      if (item?.sharing) name = 'share-tilt.svg';
      else name = 'share-black.svg';
    }
    if (!name) {
      return 'other_icon.png';
    }

    if (name.includes('doc') || name.includes('docx')) {
      return 'doc_icon.png';
    }

    if (name.includes('pdf')) {
      return 'pdf_icon.png';
    }

    if (name.includes('mp4')) {
      return 'mp4_icon.png';
    }

    if (name.includes('mp3')) {
      return 'mp3_icon.png';
    }

    if (name.includes('gif')) {
      return 'gif_icon.png';
    }

    if (name.includes('png')) {
      return 'png_icon.png';
    }

    if (name.includes('xls') || name.includes('xlsx')) {
      return 'xls_icon.png';
    }

    if (name.includes('jpeg') || name.includes('jpg')) {
      return 'jpeg_icon.png';
    }

    // OUTGOING
    if (name === 'outgoingCall') {
      return 'outgoing-calls.svg';
    }

    if (name === 'outgoingSms') {
      return 'outgoing-smss.svg';
    }

    if (name === 'outgoingMms') {
      return 'outgoing-smss.svg';
    }

    // INCOMING
    if (
      name === 'incomingCall' &&
      (item?.status == 'no-answer' ||
        item?.status == 'cancel' ||
        item?.status == 'no-confirm' ||
        item?.status == 'busy')
    ) {
      return 'missed-calls.svg';
    }

    if (name === 'incomingCall' && item?.communicationType == 3) {
      return 'voice-mail.svg';
    }

    if (name === 'incomingCall' && item?.communicationType == 4) {
      return 'voicemail-rvm.svg';
    }

    if (name === 'incomingCall') {
      return 'incoming-calls.svg';
    }

    if (name === 'incomingSms') {
      return 'incoming-smss.svg';
    }

    if (name === 'incomingMms') {
      return 'incoming-smss.svg';
    }

    // TASKS
    if (name === 'reminder') {
      return 'bell.svg';
    }

    if (name === 'description') {
      return 'description.svg';
    }
    if (name === 'ico') {
      return 'ico_icon.png';
    }
    if (name === 'svg') {
      return 'svg_icon.png';
    }
    if (name === 'zip') {
      return 'zip_icon.png';
    }
    if (name === 'txt') {
      return 'txt_icon.png';
    }
    if (name === 'rar') {
      return 'rar_icon.png';
    }
    if (name === 'json') {
      return 'json_icon.png';
    }
    if (name === 'html') {
      return 'html_icon.png';
    }
    if (name === 'dwg') {
      return 'dwg_icon.png';
    }
    if (name === 'ai') {
      return 'ai_icon.png';
    }
    if (name === 'csv') {
      return 'csv_icon.png';
    }
    if (name === 'webp') {
      return 'webp_icon.png';
    }

    return name;
  }

  deleteFile(item, index) {
    this.mainFileArr.splice(index, 1);
  }

  underMaintenanceView(bool) {
    this.isUnderMaintenance = bool;
  }
}
