import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { LeadsService } from 'src/app/providers/leads/leads.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';

import { MessageConstant } from 'src/app/utils/message-constant';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { SharedService } from '../../shared.service';

// COMPONENTS
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-confirmation-number-dialog',
  templateUrl: './confirmation-number-dialog.component.html',
  styleUrls: ['./confirmation-number-dialog.component.scss'],
})
export class ConfirmationNumberDialogComponent implements OnInit {
  submitted: boolean = false;
  campaignMigrationForm: FormGroup;
  defaultNumberList: any[] = [];
  assignCampaignData: boolean = false;
  marketCampaignData: boolean = true;
  dialogRef: any;
  messageConstant = MessageConstant;

  constructor(
    private ConfirmationNumberDailog: MatDialogRef<ConfirmationNumberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private _leadService: LeadsService,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private dialog: MatDialog,
    private _sharedService: SharedService,
    public _utilities: CommonFunctionsService
  ) {}

  ngOnInit(): void {
    this.campaignMigrationForm = this.fb.group({
      numberId: [''],
    });

    const obj = {
      crmQueId: [],
      numTypeId: 1,
    };

    this._leadService.getCampaignList(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.defaultNumberList = response.data?.items.filter(
            (e) => e?._id != this.data?.details?._id
          );
          this.defaultNumberList.sort((a, b) => a.marketingTitle.localeCompare(b.marketingTitle));
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
      }
    );
  }

  onAssignCampaign(bool?) {
    if (bool) {
      this.assignCampaignData = true;
      this.marketCampaignData = false;
    } else {
      this.campaignMigrationForm.patchValue({
        numberId: '',
      });
      this.assignCampaignData = false;
      this.marketCampaignData = true;
    }
  }

  onUnassignLeads() {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: {
        yesTitle: 'Confirm Un-assignment',
        noTitle: 'Cancel',
        header: 'Unassign All Leads',
        text: `<ul class="re-assignsec">
        <li>All leads will get unassigned</li>
        <li>All tasks in the leads will be deleted</li>
        <li>
          Any drip campaign set on this marketing campaign phone number will
          stop.
        </li>
      </ul>`,
        actionButtonAlignment: 'text-right',
        confirmButtonClass: 're-unassign',
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let newNumberId = '';
        this.deleteNumbers(newNumberId);
      }
    });
  }

  onDeleteNumber() {
    if (this.assignCampaignData) {
      if (this.campaignMigrationForm.invalid) {
        return;
      }

      const { numberId } = this.campaignMigrationForm?.value;
      let currentNumber;
      if (numberId) {
        currentNumber = this.defaultNumberList.filter((obj) => {
          return obj?._id == numberId;
        });
      }
      if (this.assignCampaignData && !numberId) {
        this._toastrService.error('Please select marketing campaign');
        return;
      }
      this.deleteNumbers(numberId);
    }
  }

  deleteNumbers(newNumberId) {
    let obj = {
      number: this.data?.details?.number,
    };

    if (newNumberId != true && newNumberId != '') {
      obj['newNumberId'] = newNumberId;
    }

    this._loaderService.start();
    this._sharedService.deleteNumber(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._loaderService.stop();
          this.ConfirmationNumberDailog.close(this.data?.details);
          this._toastrService.success(
            this.messageConstant.numberDeletedSuccess
          );
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          this.ConfirmationNumberDailog.close(this.data?.details);
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
}
