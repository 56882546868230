import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

//SERVICES
import { DripService } from 'src/app/providers/drip/drip.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { LeadsService } from 'src/app/providers/leads/leads.service';
import { SharedService } from '../../shared.service';

// UTILS
import { environment } from 'src/environments/environment';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';
import * as moment from 'moment';

@Component({
  selector: 'app-auto-follow-up',
  templateUrl: './auto-follow-up.component.html',
  styleUrls: ['./auto-follow-up.component.scss'],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class AutoFollowUpComponent implements OnInit {
  autoFollowUpForm: FormGroup;
  public dateOfBalance = new Date();
  public showCC = false;
  public showBCC = false;
  toggle = false;
  currentPage: number = 1;
  currentLimit: number = 100;
  messageConstant = MessageConstant;
  dripList: any = [];
  minutesList: any = [];
  taskTimeTypeList: any = [];
  stop: boolean = false;
  selectedDrip: any;
  selectedNewDrip: any;
  submitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AutoFollowUpComponent>,
    private _dripService: DripService,
    private _loaderService: NgxUiLoaderService,
    private toastr: ToastrService,
    private _utilities: CommonFunctionsService,
    private _leadsService: LeadsService,
    private _sharedService: SharedService
  ) {}
  ngOnInit(): void {
    this.minutesList = this._utilities.minutesList(31, 1);
    this.taskTimeTypeList = this._utilities.taskTimeTypeList();
    this.onDripList();
    if (this.data.data.leadId) {
      this.onLeadDetail(this.data.data.leadId);
    } else {
      this.onLeadDetail(this.data.data._id);
    }
    console.log('this.data.data.dripId', this.data.data.dripId);
    this.autoFollowUpForm = this.formBuilder.group({
      dripCompaignId: ['', Validators.required],
      autoStopDrip: [false],
      activeTimeChange: [false],
      duration: ['1'],
      timeSpan: ['Minutes'],
    });
  }

  onToggle() {
    this.toggle = !this.toggle;
    if (this.toggle) {
      this.getNewDripTime(
        this.autoFollowUpForm.controls.duration.value,
        this.autoFollowUpForm.controls.timeSpan.value
      );
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.autoFollowUpForm.controls[controlName].hasError(errorName);
  };

  onSubmit() {
    this.submitted = true;
    if (this.stop) {
      this._loaderService.start();
      let leadId;
      if (this.data.data.leadId) {
        leadId = this.data.data.leadId;
      } else {
        leadId = this.data.data._id;
      }
      this._dripService
        .unassignDrip({
          leadId: leadId,
          dripCompaignId: this.data.data.dripId,
        })
        .subscribe(
          (response: ResponseModel) => {
            if (response.statusCode == 200) {
              this._sharedService.refreshLeadsGrid.next(true);
              this.toastr.success(
                this.messageConstant.leadDripUnassignSuccess,
                ''
              );
              this.dialogRef.close(response.data);
              this._loaderService.stop();
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ResponseModel = err.error;
              this.toastr.error(error.message, '');
            } else {
              this.toastr.error(this.messageConstant.unknownError, '');
            }
          }
        );
    } else {
      this.autoFollowUpForm.value.leadIds = [this.data.data._id];
      if(!this.autoFollowUpForm.value.activeTimeChange) {
        delete this.autoFollowUpForm.value.duration;
        delete this.autoFollowUpForm.value.timeSpan;
      }  
      if (this.autoFollowUpForm.valid) {
        this._loaderService.start();
        this._dripService.assignDrip(this.autoFollowUpForm.value).subscribe(
          (response: ResponseModel) => {
            if (response.statusCode == 200) {
              this._sharedService.refreshLeadsGrid.next(true);
              this.toastr.success(
                this.messageConstant.leadDripAssignSuccess,
                ''
              );
              this.toastr.success("It may take few seconds to load Tasks. Please refresh page after 5-10 seconds");
              this.dialogRef.close(response.data);
              this._loaderService.stop();
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ResponseModel = err.error;
              this.toastr.error(error.message, '');
            } else {
              this.toastr.error(this.messageConstant.unknownError, '');
            }
          }
        );
      }
    }
  }

  onStop() {
    this.stop = true;
  }

  onDripList() {
    this._loaderService.start();
    let obj = {
      page: this.currentPage,
      limit: this.currentLimit,
    };
    this._dripService.listDropDown(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          // this._loaderService.stop();
          this.dripList = response.data.items;
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this.toastr.error(error.message, '');
        } else {
          this.toastr.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getDripTime(dripId) {
    this.selectedDrip = this.dripList.find((item) => item._id == dripId);
  }

  getNewDripTime(duration?, timespan?) {
    this.selectedNewDrip = moment()
      .add(duration, timespan)
      .tz(localStorage.getItem('timezoneOffset'))
      .format('MMMM Do YYYY, h:mm A');
  }

  onLeadDetail(leadId) {
    this._loaderService.start();
    this._leadsService.leadInfo({ leadId: leadId }).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.stop = !response.data.leadData.dripId ? false : true;
          this.data.data.dripId = this.stop
            ? response.data.leadData.dripId
            : null;

          if (this.data.data.dripId) {
            this.toggle = response.data.leadData.activeTimeChange;
            this.autoFollowUpForm.patchValue({
              dripCompaignId: this.data.data.dripId,
              autoStopDrip: response.data.leadData.autoStopDrip,
              activeTimeChange: response.data.leadData.activeTimeChange,
              duration: response.data.leadData.duration,
              timeSpan: response.data.leadData.timeSpan,
            });
          }
          setTimeout(() => {
            this._loaderService.stop();
          }, 2000);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this.toastr.error(error.message, '');
        } else {
          this.toastr.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
}
