<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      {{ titleGreeting }}
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<mat-dialog-content class="">
  <div class="container">
    <div class="box re-addrvm-modal">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <!--            <div class="form-group col-md-12">-->
            <!--              <label for="title">Greeting Title</label>-->
            <!--              <input-->
            <!--                type="text"-->
            <!--                class="form-control"-->
            <!--                id="title"-->
            <!--                [(ngModel)]="titleName"-->
            <!--              />-->
            <!--            </div>-->
            <div class="re-counter-panel">
              <label class="labels" *ngIf="!recording && !url"
                >Start Recording</label
              >
              <label class="labels" *ngIf="recording && !url">Recording</label>
              <label class="labels" *ngIf="url">Recording Done</label>
              <p *ngIf="!url">{{ display }}</p>
              <div style="cursor: pointer">
                <img
                  *ngIf="!recording && !url"
                  src="assets/images/microphone.svg"
                  alt="start recording"
                  (click)="initiateRecording()"
                />
                <img
                  *ngIf="recording && !url"
                  src="assets/images/stop-record.svg"
                  alt="Recording"
                  (click)="stopRecording()"
                />
                <!--                <img *ngIf="url"-->
                <!--                     src="assets/images/play.svg"-->
                <!--                     alt="Recording"-->
                <!--                     (click)="downloadFile()"-->

                <!--                />-->
                <!--                <span  *ngIf="url">Preview Recording</span>-->
                <app-audio-player *ngIf="url" [url]="url"></app-audio-player>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center" style="padding-top: 14px !important">
  <button class="btn btn-default" mat-raised-button (click)="closeKey()">
    Cancel
  </button>
  <button
    (click)="addKey()"
    cdkFocusInitial
    class="btn btn-success"
    [disabled]="!fileUpload"
  >
    Save
  </button>
</mat-dialog-actions>
