<div class="dial-minisdiv" *ngIf="isMinimized" (click)="toggleDisplay()">
  <div class="re-dial-left">
    <!--    <div class="re-dialicon">-->
    <!--      <img src="assets/images/call-icon-white.png" alt="call" />{{-->
    <!--      contactData[currentContact - 1]?.contactType == "Buyer"-->
    <!--        ? contactData[currentContact - 1]?.firstName +-->
    <!--        " " +-->
    <!--        contactData[currentContact - 1]?.lastName-->
    <!--        : contactData[currentContact - 1]?.name-->
    <!--      }}-->
    <!--      • {{ leadData?.address ? leadData?.address : "N/A" }}-->
    <!--    </div>-->
  </div>
  <div class="re-dial-center" *ngIf="callReady">{{ callDurationIncoming }}</div>
  <div class="re-dial-center" *ngIf="!callReady"></div>

  <div class="re-dial-right">
    <span
      class="re-expand"
      (click)="toggleDisplay()"
      pTooltip="Maximize call window"
      tooltipPosition="bottom"
    >
      <img src="assets/images/icon-sqare.svg" alt="sqare" *ngIf="isMinimized" />
    </span>
    <!--    <span-->
    <!--      class="re-close"-->
    <!--      pTooltip="Close call window"-->
    <!--      [class.re-cross-disable]="isCalling"-->
    <!--      tooltipPosition="bottom"-->
    <!--      matDialogClose-->
    <!--      mat-dialog-close-->
    <!--    >-->
    <!--      <img src="assets/images/close-white1.svg" alt="close" />-->
    <!--    </span>-->
  </div>
</div>
<div *ngIf="!isMinimized">
  <!-- Buy Credit -->
  <mat-dialog-content
    class="mat-typography m-0"
    *ngIf="isCalling && data && callBalance <= 0"
  >
    <div class="re-caller-info text-center">
      <p class="re-name">Buy Credit</p>
    </div>
    <div class="re-card-info text-center">
      <p class="re-caller-id">
        You have insufficient balance in your account. Please recharge to
        proceed.
      </p>
      <p class="re-credit-info">
        Credit remains: <span>{{ callBalance }}</span> mins
      </p>
    </div>
    <div class="re-form">
      <a
        class="btn btn-block re-submit"
        type="button"
        routerLink="credit/buy-credit"
        mat-dialog-close
      >
        Buy Credit
      </a>
    </div>
  </mat-dialog-content>
  <div *ngIf="callBalance > 0">
    <div class="re-incoming-div" *ngIf="!isCalling">
      <div class="re-topdiv">
        <div class="re-topicon"><img src="assets/images/phone-call.svg" /></div>
        <!--      <span-->
        <!--        class="re-closeicon"-->
        <!--        (click)="cancel({ direction: 'incoming' })"-->
        <!--        matDialogClose-->
        <!--        mat-dialog-close-->
        <!--      >-->
        <!--        <img src="assets/images/close-white1.svg" alt="close" />-->
        <!--      </span>-->
        <div style="text-align: center">
          <div
            class="re-names"
            *ngIf="
              data &&
              data.buyers === true &&
              (data?.numberType === '2' || data?.numberType === 2)
            "
          >
            Buyers
          </div>
          <div
            class="re-names"
            *ngIf="
              data &&
              data.buyers === true &&
              (data?.numberType === '2' || data?.numberType === 2)
            "
          >
            {{
              data && data.buyersDetails && data.buyersDetails.firstName !== ""
                ? data && data.buyersDetails && data.buyersDetails.firstName
                  ? data.buyersDetails.firstName
                  : "" +
                    " " +
                    (data && data.buyersDetails && data.buyersDetails.lastName)
                  ? data.buyersDetails.lastName
                  : ""
                : "Unknown Caller"
            }}
          </div>
          <div
            class="re-names"
            *ngIf="
              data &&
              data.buyers === false &&
              (data?.numberType !== '2' || data?.numberType !== 2)
            "
          >
            {{
              data?.leadDetails?.length !== 0 &&
              data?.leadDetails[0]?.name !== ""
                ? data.leadDetails[0].name
                : "Unknown Caller"
            }}
          </div>
          <div class="re-number">
            {{
              data?.callerName
                ? _utilities.maskNumber(data?.callerName) + "(M)"
                : ""
            }}
          </div>
          <div
            class="re-names"
            *ngIf="
              data &&
              data.buyers === false &&
              (data?.numberType !== '2' || data?.numberType !== 2)
            "
          >
            {{
              data?.leadDetails?.length !== 0 &&
              data?.leadDetails[0]?.crmQuestionTitle !== "" &&
              data?.leadDetails[0]?.numberTitle !== ""
                ? data?.leadDetails[0].crmQuestionTitle &&
                  data?.leadDetails[0].numberTitle
                  ? data.leadDetails[0].crmQuestionTitle +
                    " > " +
                    data.leadDetails[0].numberTitle
                  : ""
                : ""
            }}
          </div>
        </div>
        <div
          *ngIf="
            data &&
            data.buyers === false &&
            (data?.numberType !== 2 || data?.numberType !== '2')
          "
        >
          <div *ngIf="data?.leadDetails.length === 0">
            <div class="re-status">No Property Address Available</div>
          </div>
          <div
            *ngIf="data && data?.leadDetails && data?.leadDetails.length > 0"
          >
            <div class="re-status">
              LEAD PROPERTIES ({{
                data?.leadDetails ? data?.leadDetails.length : "0"
              }})
            </div>
            <div
              *ngFor="let d of data?.leadDetails | slice : 0 : 3; let i = index"
              style="margin-bottom: 20px"
            >
              <div
                class="re-addres"
                style="cursor: pointer"
                *ngIf="d && d._id && d._id !== ''"
                (click)="goToLeadDetails(d?._id, i)"
              >
                {{
                  d && d?.address !== "" ? d.address : "Address Not Available"
                }}
              </div>
              <div class="re-addres" *ngIf="d && !d._id">
                Address Not Available
              </div>
              <div class="re-status">
                {{ getMainStatus(d, i) }}
              </div>
            </div>
            <button
              *ngIf="data && data?.leadDetails && data?.leadDetails.length > 3"
              disabled
              class="re-disable-button"
            >
              {{ data?.leadDetails.length - 3 + " more" }}
            </button>
          </div>
          <div
            *ngIf="
              data &&
              data?.leadDetails &&
              data?.leadDetails.length > 0 &&
              data?.listStackingDetails &&
              data?.listStackingDetails.length > 0
            "
            class="re-incoming-divider"
          ></div>
          <div *ngIf="data?.listStackingDetails.length === 0">
            <div class="re-status">No Part of List Stacking.</div>
          </div>

          <div
            *ngIf="
              data?.listStackingDetails && data?.listStackingDetails.length > 0
            "
          >
            <div class="re-status">
              LIST STACKING PROPERTIES ({{
                data?.listStackingDetails
                  ? data?.listStackingDetails.length
                  : "0"
              }})
            </div>
            <div
              *ngFor="
                let l of data?.listStackingDetails | slice : 0 : 2;
                let i = index
              "
              style="margin-bottom: 20px"
            >
              <div class="re-addres-list">
                {{
                  l && l?.Formated_Address !== ""
                    ? l.Formated_Address
                    : "Address Not Available"
                }}
              </div>
              <div class="re-status">
                {{ l && l?.Full_Name !== "" ? l.Full_Name : "" }}
              </div>
              <div class="re-tags" *ngIf="l?.ListId.length > 0">
                <a
                  class="re-labell"
                  *ngFor="
                    let list of l?.ListId | slice : 0 : listLength[i];
                    let index = index
                  "
                  >{{ list?.name }}</a
                >
                <div
                  class="d-inline-block"
                  *ngIf="l?.ListId.length > 3 && listLength[i] === 3"
                >
                  <a class="re-more" href="javascript:void(0);"
                    >+{{ l?.ListId.length - 3 }} More</a
                  >
                </div>
                <div
                  *ngIf="
                    l?.ListId?.length > 3 && listLength[i] === l?.ListId?.length
                  "
                >
                  <a
                    class="re-more"
                    (click)="onFullList(false, i)"
                    href="javascript:void(0);"
                  >
                    View Less</a
                  >
                </div>
              </div>
              <div class="re-tags" *ngIf="l?.TagId.length > 0">
                <a
                  class="re-labell"
                  *ngFor="
                    let tag of l?.TagId | slice : 0 : tagLength[i];
                    let index = index
                  "
                  >{{ tag?.name }}</a
                >
                <div
                  class="d-inline-block"
                  *ngIf="l?.TagId?.length > 3 && tagLength[i] === 3"
                >
                  <a class="re-more" href="javascript:void(0);"
                    >+{{ l?.TagId?.length - 3 }} More</a
                  >
                </div>
                <div
                  class="d-inline-block"
                  *ngIf="
                    l?.TagId?.length > 3 && tagLength[i] === l?.TagId?.length
                  "
                >
                  <a
                    class="re-more"
                    (click)="onFullTagList(false, i)"
                    href="javascript:void(0);"
                  >
                    View Less</a
                  >
                </div>
              </div>
            </div>
            <button
              *ngIf="
                data &&
                data?.listStackingDetails &&
                data?.listStackingDetails.length > 2
              "
              disabled
              class="re-disable-button"
            >
              {{ data?.listStackingDetails.length - 2 + " more" }}
            </button>
          </div>
        </div>
        <div
          *ngIf="
            data &&
            data.buyers === true &&
            (data?.numberType === 2 || data?.numberType === '2')
          "
        >
          <div *ngIf="data?.buyerLeadDetails.length === 0">
            <div class="re-status">No Property Address Available</div>
          </div>
          <div
            *ngIf="
              data &&
              data?.buyerLeadDetails &&
              data?.buyerLeadDetails.length > 0
            "
          >
            <div class="re-status">
              LEAD PROPERTIES ({{
                data?.buyerLeadDetails ? data?.buyerLeadDetails.length : "0"
              }})
            </div>
            <div
              *ngFor="
                let d of data?.buyerLeadDetails | slice : 0 : 3;
                let i = index
              "
              style="margin-bottom: 20px"
            >
              <div
                class="re-addres"
                style="cursor: pointer"
                *ngIf="d && d.leadId && d.leadId !== ''"
                (click)="goToBuyersLeadDetails(d?.leadId, i, d?.mainStatusId)"
              >
                {{
                  d && d?.address !== "" ? d.address : "Address Not Available"
                }}
              </div>
              <div class="re-status">
                {{ getMainStatus(d, i) }}
              </div>
            </div>
            <button
              *ngIf="
                data &&
                data?.buyerLeadDetails &&
                data?.buyerLeadDetails.length > 3
              "
              disabled
              class="re-disable-button"
            >
              {{ data?.buyerLeadDetails.length - 3 + " more" }}
            </button>
          </div>
        </div>
      </div>
      <div class="re-btnlist">
        <a class="re-btn re-answer" (click)="answerCall()"
          ><i class="fa fa-phone" aria-hidden="true"></i>Answer</a
        >
        <a
          class="re-btn re-reject"
          style="float: right"
          (click)="rejectCall({ direction: 'incoming', reason: 'hangup' })"
          ><i class="fa fa-phone" aria-hidden="true"></i>Reject</a
        >
      </div>
    </div>
    <mat-dialog-content class="re-typography" *ngIf="isCalling && data">
      <div class="container">
        <div class="re-dial-right text-right">
          <span
            class="re-expand d-inline-block mr-2"
            pTooltip="Minimize call window"
            tooltipPosition="bottom"
            (click)="toggleDisplay()"
          >
            <img
              src="assets/images/icon-clone.svg"
              alt="clone"
              *ngIf="!isMinimized"
            />
          </span>
          <!--        <span-->
          <!--          class="re-close"-->
          <!--          pTooltip="Close call window"-->
          <!--          [class.re-cross-disable]="isCalling"-->
          <!--          tooltipPosition="bottom"-->
          <!--          (click)="cancel({ direction: 'incoming' })"-->
          <!--          matDialogClose-->
          <!--          mat-dialog-close-->
          <!--        >-->
          <!--          <img src="assets/images/close-white1.svg" alt="close" />-->
          <!--        </span>-->
        </div>
        <div class="row">
          <div class="col-12 col-md-12">
            <div
              class="re-dial-screen"
              *ngIf="
                isCalling && !addMemberCall && !showMemberList && !isDialerOpen
              "
            >
              <!--            <div-->
              <!--              class="re-addres"-->
              <!--              *ngIf="data.buyers === false && !data.buyersDetails"-->
              <!--            >-->
              <!--              {{ data?.leadDetails[0]?.address }}-->
              <!--            </div>-->
              <div class="">
                <div
                  class="re-call-name"
                  style="font-size: 24px"
                  *ngIf="data.buyers === false && !data.buyersDetails"
                >
                  {{ data?.leadDetails[0]?.name }}
                </div>
                <div
                  class="re-call-name"
                  style="font-size: 24px"
                  *ngIf="data.buyers === true"
                >
                  {{
                    data &&
                    data.buyersDetails &&
                    data.buyersDetails.firstName !== ""
                      ? data &&
                        data.buyersDetails &&
                        data.buyersDetails.firstName
                        ? data.buyersDetails.firstName
                        : "" +
                          " " +
                          (data &&
                            data.buyersDetails &&
                            data.buyersDetails.lastName)
                        ? data.buyersDetails.lastName
                        : ""
                      : "Unknown Caller"
                  }}
                </div>
                <div>
                  <span class="re-status-text">{{
                    data?.callerName
                      ? _utilities.maskNumber(data?.callerName) + "(M)"
                      : ""
                  }}</span>
                </div>
                <div
                  class="re-names"
                  style="margin-bottom: 5px"
                  *ngIf="
                    data &&
                    data.buyers === false &&
                    (data?.numberType !== '2' || data?.numberType !== 2)
                  "
                >
                  {{
                    data?.leadDetails?.length !== 0 &&
                    data?.leadDetails[0]?.crmQuestionTitle !== "" &&
                    data?.leadDetails[0]?.numberTitle !== ""
                      ? data?.leadDetails[0].crmQuestionTitle &&
                        data?.leadDetails[0].numberTitle
                        ? data.leadDetails[0].crmQuestionTitle +
                          " > " +
                          data.leadDetails[0].numberTitle
                        : ""
                      : ""
                  }}
                </div>
                <div
                  class="re-number"
                  style="margin-bottom: 5px"
                  *ngIf="
                    data &&
                    data.buyers === false &&
                    !data.buyersDetails &&
                    data?.leadDetails.length > 0
                  "
                >
                  <div *ngIf="data?.leadDetails[0]?.address !== ''">
                    {{ data?.leadDetails[0]?.address.slice(0, 19) + "..." }}
                  </div>
                  <br />
                  <a
                    href="javascript:;"
                    style="color: teal"
                    (click)="goToLeadDetails(data?.leadDetails[0]?._id, 0)"
                    >Lead Detail</a
                  >
                </div>
                <div
                  class="re-number"
                  *ngIf="
                    data &&
                    data.buyers === true &&
                    data.buyersDetails &&
                    data.buyersDetails.id
                  "
                  style="margin-bottom: 5px"
                >
                  <a
                    href="javascript:;"
                    style="color: teal"
                    (click)="goToBuyerDetails(data?.buyersDetails?.id)"
                    >Go to Buyer Detail</a
                  >
                </div>
                <br />
                <h3 class="re-count">{{ callDurationIncoming }}</h3>
                <div
                  *ngIf="data && data.buyers === false && !data.buyersDetails"
                >
                  <span class="re-status-text">
                    {{
                      (data &&
                        data?.leadDetails &&
                        data?.leadDetails.length > 1) ||
                      (data &&
                        data?.listStackingDetails &&
                        data?.listStackingDetails.length > 0)
                        ? "Matching Properties"
                        : "No Matching Properties"
                    }}</span
                  >
                  <div
                    class="re-property"
                    *ngIf="
                      data && data?.leadDetails && data?.leadDetails.length > 1
                    "
                    (click)="openCloseLead(true)"
                  >
                    Lead Properties ({{
                      data?.leadDetails ? data?.leadDetails?.length - 1 : "0"
                    }})
                  </div>
                  <div
                    class="re-property"
                    *ngIf="
                      data &&
                      data?.listStackingDetails &&
                      data?.listStackingDetails.length > 0
                    "
                    (click)="openCloseListStacking(true)"
                  >
                    List Stacking Properties ({{
                      data?.listStackingDetails
                        ? data?.listStackingDetails?.length
                        : "0"
                    }})
                  </div>
                </div>
                <br />
              </div>

              <div class="re-callbtn" [ngStyle]="{ display: listopen }">
                <div class="re-callfeature">
                  <!-- UNMUTE -->
                  <a
                    class="re-callicon re-mute"
                    href="javascript:;"
                    *ngIf="muteCall"
                    (click)="unmute()"
                    pTooltip="Unmute"
                    tooltipPosition="bottom"
                    ><img src="assets/images/icon-micoff.svg" alt="micoff"
                  /></a>

                  <!-- MUTE -->
                  <a
                    class="re-callicon"
                    href="javascript:;"
                    *ngIf="!muteCall"
                    (click)="mute()"
                    pTooltip="Mute"
                    tooltipPosition="bottom"
                    ><img src="assets/images/icon-micoff.svg" alt="micoff"
                  /></a>
                  <a
                    class="re-callicon"
                    href="javascript:;"
                    pTooltip="Add call"
                    *ngIf="!callInitiat"
                    tooltipPosition="bottom"
                    (click)="addMemberData()"
                    ><img src="assets/images/icon-addcall.svg" alt="addcall"
                  /></a>
                  <a
                    class="re-callicon re-disable"
                    href="javascript:;"
                    pTooltip="Add call"
                    *ngIf="callInitiat"
                    tooltipPosition="bottom"
                    ><img src="assets/images/icon-addcall.svg" alt="addcall"
                  /></a>
                  <a
                    class="re-callicon"
                    href="javascript:;"
                    *ngIf="mergeCallDialer?.secondCall"
                    pTooltip="Participants call"
                    tooltipPosition="bottom"
                    (click)="getMemberList()"
                    ><img
                      src="assets/images/icon-participants.svg"
                      alt="participants"
                  /></a>
                  <!-- KEYPAD -->
                  <a
                    class="re-callicon"
                    href="javascript:;"
                    pTooltip="Keypad"
                    tooltipPosition="bottom"
                    ><img
                      src="assets/images/icon-dialpad.svg"
                      alt="dialpad"
                      (click)="isDialerOpen = true"
                  /></a>
                  <a
                    class="re-callicon"
                    href="javascript:;"
                    pTooltip="Merge Call"
                    *ngIf="
                      mergeCallDialer?.secondCall &&
                      !mergeCallDialer?.callMerged &&
                      !showMemberList
                    "
                    tooltipPosition="bottom"
                    (click)="callMerge()"
                    ><img src="assets/images/icon-merge.svg" alt="merge"
                  /></a>
                  <a
                    class="re-callicon"
                    href="javascript:;"
                    pTooltip="Merge Call"
                    *ngIf="mergeCallDialer?.secondCall && showMemberList"
                    tooltipPosition="bottom"
                    [class.re-disable]="mergeCallBtn"
                    ><img src="assets/images/icon-merge.svg" alt="merge"
                  /></a>
                </div>

                <div class="re-leavebtn" *ngIf="mergeCallDialer?.secondCall">
                  <a
                    href="javascript:"
                    class="btn-dropme"
                    (click)="dropMembers(mainMember)"
                    >Drop Me</a
                  >
                  <!--          <a href="javascript:;" class="btn-leave" (click)="endCall()"-->
                  <!--            >End for All</a-->
                  <!--          >-->
                </div>
                <a
                  *ngIf="!mergeCallDialer?.secondCall"
                  class="re-callonoff"
                  href="javascript:;"
                  (click)="cancel({ direction: 'incoming', reason: 'hangup' })"
                  pTooltip="End Call"
                  tooltipPosition="bottom"
                  ><img src="assets/images/call-off.png" alt="call-off"
                /></a>
              </div>
              <div>
                <div class="re-dialpad re_propert_open" *ngIf="isLeadListOpen">
                  <div class="re-dialpad-top-lead">
                    <a class="re-dialclose" (click)="openCloseLead(false)"
                      ><img
                        src="assets/images/close-black-1.svg"
                        alt="close-black"
                    /></a>
                    <div class="re-addres-list">Lead Properties</div>
                  </div>
                  <div class="re-dialpad-bottom">
                    <div
                      *ngFor="let d of data?.leadDetails; let i = index"
                      style="margin-bottom: 10px"
                    >
                      <div *ngIf="i !== 0">
                        <div class="re-addres">
                          {{
                            d && d?.address !== ""
                              ? d.address
                              : "Address Not Available"
                          }}
                        </div>
                        <div class="re-addres" *ngIf="d && !d._id">
                          Address Not Available
                        </div>
                        <div class="re-status-text">
                          <div>{{ getMainStatus(d, i) }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="re-dialpad" *ngIf="isListStackingListOpen">
                  <div class="re-dialpad-top-lead">
                    <div
                      class="text-right"
                      (click)="openCloseListStacking(false)"
                    >
                      <a class="re-dialclose"
                        ><img
                          src="assets/images/close-black-1.svg"
                          alt="close-black"
                      /></a>
                    </div>
                    <div class="re-addres-list">List Stacking Properties</div>
                  </div>
                  <div class="re-dialpad-bottom">
                    <div
                      *ngFor="let l of data?.listStackingDetails; let i = index"
                      style="margin-bottom: 20px"
                    >
                      <div class="re-addres-list">
                        {{
                          l && l?.Formated_Address !== ""
                            ? l.Formated_Address
                            : "Address Not Available"
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="re-dialpad" *ngIf="isDialerOpen">
              <div class="re-dialpad-top">
                <div class="text-right" (click)="isDialerOpen = false">
                  <a class="re-dialclose"
                    ><img
                      src="assets/images/close-black-1.svg"
                      alt="close-black"
                  /></a>
                </div>
                <h3 class="re-count">{{ callDurationIncoming }}</h3>
                <div class="re-callnum">
                  <div>Calling from</div>
                  <div>
                    <span class="re-numb">{{
                      data?.callerName
                        ? _utilities.maskNumber(data?.callerName) + "(M)"
                        : ""
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="re-dialpad-bottom">
                <div class="re-numbshow">
                  {{ dialedNumber }}
                  <img
                    class="re-errase"
                    src="assets/images/icon-backspace.svg"
                    alt="backspace"
                    (click)="removeDialer()"
                  />
                </div>
                <div class="re-keypad">
                  <div class="re-row">
                    <div class="re-digit" (click)="dialer('1')">1</div>
                    <div class="re-digit" (click)="dialer('2')">2</div>
                    <div class="re-digit" (click)="dialer('3')">3</div>
                  </div>
                  <div class="re-row">
                    <div class="re-digit" (click)="dialer('4')">4</div>
                    <div class="re-digit" (click)="dialer('5')">5</div>
                    <div class="re-digit" (click)="dialer('6')">6</div>
                  </div>
                  <div class="re-row">
                    <div class="re-digit" (click)="dialer('7')">7</div>
                    <div class="re-digit" (click)="dialer('8')">8</div>
                    <div class="re-digit" (click)="dialer('9')">9</div>
                  </div>
                  <div class="re-row">
                    <div class="re-digit" (click)="dialer('*')">*</div>
                    <div class="re-digit" (click)="dialer('0')">0</div>
                    <div class="re-digit" (click)="dialer('#')">#</div>
                  </div>
                </div>
                <div class="text-center">
                  <a
                    class="re-callonoff"
                    href="javascript:;"
                    pTooltip="End Call"
                    tooltipPosition="bottom"
                    (click)="cancel({ direction: 'incoming' })"
                    ><img src="assets/images/call-off.png" alt="call-off"
                  /></a>
                </div>
              </div>
            </div>
            <div class="re-tabsdiv" *ngIf="addMemberCall && !showMemberList">
              <div class="text-right">
                <a
                  class="re-close-call"
                  (click)="addMemberCall = false"
                  style="display: inline-block; width: 16px; cursor: pointer"
                  ><img
                    style="max-width: 100%"
                    src="assets/images/close-black-1.svg"
                    alt="close-black"
                /></a>
              </div>
              <div role="tablist" class="nav re-tabs-btn">
                <a
                  href="#tabs11"
                  role="tab"
                  data-toggle="tab"
                  aria-expanded="false"
                  class="active"
                  >Contact</a
                >
                <a
                  href="#tabs22"
                  role="tab"
                  data-toggle="tab"
                  aria-expanded="false"
                  >Keypad</a
                >
              </div>
              <div class="tab-content">
                <div role="tabpanel" id="tabs11" class="tab-pane active">
                  <div class="re-contact-tabdiv">
                    <div class="input-group re-search-input-group">
                      <a href="javascript:;" class="btn btn-default">
                        <span class="fa fa-search"></span>
                      </a>
                      <input
                        type="text"
                        placeholder="Search contact"
                        class="form-control"
                        (keyup)="searchByFilter($event)"
                      />
                    </div>
                    <div class="re-contactlist">
                      <div *ngFor="let user of teams">
                        <div
                          class="re-contact"
                          [ngClass]="{
                            're-selected': dialedNumber === user.contactNumber
                          }"
                        >
                          <div
                            class="re-profile-img"
                            (click)="selectUser(user)"
                          >
                            <img
                              src="assets/images/demo-profile.png"
                              alt="profile"
                            />
                          </div>
                          <div (click)="selectUser(user)">
                            <span class="re-con-names">{{
                              user.name
                            }}</span>
                            <span class="re-con-num">{{
                              user.contactNumber
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-center">
                      <a
                        class="re-callonoff"
                        href="javascript:;"
                        pTooltip="Start Call"
                        tooltipPosition="bottom"
                        (click)="addMemberByContact('contact')"
                        ><img src="assets/images/call-on.png" alt="call-on"
                      /></a>
                    </div>
                  </div>
                </div>
                <div role="tabpanel" id="tabs22" class="tab-pane">
                  <div class="re-dialpad my-3">
                    <div class="re-dialpad-bottom">
                      <div class="re-numbshow">
                        {{ dialedNumber }}
                        <img
                          class="re-errase"
                          src="assets/images/icon-backspace.svg"
                          alt="backspace"
                          (click)="removeDialer()"
                        />
                      </div>
                      <div class="re-keypad">
                        <div class="re-row">
                          <div class="re-digit" (click)="dialer('1')">1</div>
                          <div class="re-digit" (click)="dialer('2')">2</div>
                          <div class="re-digit" (click)="dialer('3')">3</div>
                        </div>
                        <div class="re-row">
                          <div class="re-digit" (click)="dialer('4')">4</div>
                          <div class="re-digit" (click)="dialer('5')">5</div>
                          <div class="re-digit" (click)="dialer('6')">6</div>
                        </div>
                        <div class="re-row">
                          <div class="re-digit" (click)="dialer('7')">7</div>
                          <div class="re-digit" (click)="dialer('8')">8</div>
                          <div class="re-digit" (click)="dialer('9')">9</div>
                        </div>
                        <div class="re-row">
                          <div class="re-digit" (click)="dialer('*')">*</div>
                          <div class="re-digit" (click)="dialer('0')">0</div>
                          <div class="re-digit" (click)="dialer('#')">#</div>
                        </div>
                      </div>
                      <div class="text-center">
                        <a
                          class="re-callonoff"
                          href="javascript:;"
                          pTooltip="Start Call"
                          tooltipPosition="bottom"
                          (click)="addMember('dial')"
                          ><img src="assets/images/call-on.png" alt="call-on"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="re-callers" *ngIf="showMemberList && !addMemberCall">
              <h4 class="re-title">
                Callers
                <a
                  class="re-close-call"
                  (click)="showMemberList = false"
                  pTooltip="Go back to main screen"
                  tooltipPosition="bottom"
                  ><img src="assets/images/close-black-1.svg" alt="close-black"
                /></a>
              </h4>
              <div *ngIf="_utilities.isTwilioUser">
                <div
                  class="re-boxes"
                  *ngFor="let member of memberList; let i = index"
                >
                  <span class="re-name">{{
                    member.label !== "Owner" ? getName(member.label) : "Owner"
                  }}</span>
                  <span class="re-numb">{{
                    member.label === "Owner" ? "Owner" : getNumber(member.label)
                  }}</span>
                  <div *ngIf="member.label !== 'Owner'">
                    <a
                      class="re-cutbtn"
                      href="javascript:;"
                      (click)="dropMembers(member)"
                      >Drop</a
                    >
                  </div>
                </div>
              </div>
              <div *ngIf="!_utilities.isTwilioUser">
                <div
                  class="re-boxes"
                  *ngFor="let member of memberList; let i = index"
                >
                  <div *ngIf="member.exitCause === null">
                    <span class="re-name">{{
                      member.memberAddress.substring(0, 3) === "sip"
                        ? "Owner"
                        : getName(member.memberAddress)
                    }}</span>
                    <span class="re-numb">{{
                      member.memberAddress.substring(0, 3) === "sip"
                        ? "Owner"
                        : getNumber(member.memberAddress)
                    }}</span>
                    <!--            <span class="re-numb" *ngIf="i === memberList.length - 1">Owner{{-->

                    <!--              // _utilities.maskNumber(member.memberAddress)-->

                    <!--               */-->

                    <!--               */-->
                    <!--            }}</span>-->
                    <div *ngIf="member.memberAddress.substring(0, 3) !== 'sip'">
                      <a
                        class="re-cutbtn"
                        href="javascript:;"
                        (click)="dropMembers(member.memberId)"
                        >Drop</a
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="re-leavebtn">
                <a
                  href="javascript:;"
                  class="btn-dropme"
                  (click)="dropMembers(mainMember)"
                  >Drop Me</a
                >
                <!--          <a href="javascript:;" class="btn-leave" (click)="endCall()"-->
                <!--            >End for All</a-->
                <!--          >-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>
</div>
