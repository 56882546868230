import { Injector, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'format',
})
export class FormatPipe implements PipeTransform {
  public constructor(private injector: Injector) {}

  transform(value: any, data, type): any {
    if (type === 'PREFERENCE') {
      if (data?.questionType === 'DATE') {
        let format = '';
        switch (data?.dateFormat) {
          case 'mm/dd/yy':
            format = 'MM/DD/YY h:mm A';
            break;
          case 'MM dd, yy':
            format = 'LLL';
            break;
          case 'M dd, yy':
            format = 'lll';
            break;
          case 'Dec 31, 2020':
            format = 'MMMM d,Y';
            break;
        }
        let timezoneOffset = localStorage.getItem('timezoneOffset');
        let timeZoneFormat = moment(new Date(value))
          .tz(timezoneOffset)
          .format(format);
        return timeZoneFormat;//moment(value).format(format);
      }

      if (data?.questionType === 'NUMBER') {
        if (isNaN(value)) {
          return "";
        }
        return Number(value).toLocaleString(data?.numberFormat);
      }

      if (data?.questionType === 'CURRENCY') {

        if (isNaN(value)) {
          return "";
        }
        return '$'+Number(value).toLocaleString(data?.currencyFormat,{minimumFractionDigits: 2, maximumFractionDigits: 2});
        // return Number(value).toLocaleString(data?.currencyFormat, {
        //   style: 'currency',
        //   currency: 'USD',
        // });
      }

      if (data?.questionType === 'CHECK_LIST') {
        if (value && !value.length) {
          return 'N/A';
        } else {
          return value.join('<br>');
        }
      }
    }
    return value;
  }
}
