<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      Reply Comment
    </h2>
    <span matDialogClose mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<mat-dialog-content class="re-typography re-reply-comment-modal">
  <div class="re-box">
    <div class="row">
      <!-- ADDRESS -->
      <div class="col-12 col-md-12 col-sm-12" *ngIf="this.getMainStatus()">
        <div class="form-group">
          <p class="re-address-info">
            Property Address :
            <span>{{ propertyAddress || "N/A" }}</span>
          </p>
        </div>
      </div>

      <!-- COMMENT -->
      <div class="col-12 col-md-12 col-sm-12">
        <div
          class="re-headtext"
          [innerHTML]="userComment | mentionHighlight | textToLink"
        ></div>
      </div>

      <!-- REPLY -->
      <div class="col-12 col-md-12 col-sm-12">
        <div class="form-group">
          <label class="re-labels">Reply text</label>
          <quill-editor
            #editor
            name="rawEmailBody"
            [(ngModel)]="comment"
            (blur)="onComment($event)"
            (keyup)="onComment2($event)"
            [styles]="editorStyle"
            [modules]="editorConfig"
            [placeholder]="editorOptions.placeholder"
          ></quill-editor>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<hr class="m-0" />
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button
    mat-flat-button
    color="primary"
    type="submit"
    [style.cursor]="comment2 || comment.length ? 'pointer' : 'not-allowed'"
    (click)="onSubmit()"
    [disabled]="!comment && !comment2"
  >
    Send Message
  </button>
</mat-dialog-actions>
