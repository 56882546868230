export class StatusConstant {
  public static LeadStatus = {
    NEW_LEAD: '62d6b26a8865ded41d831ece',
    NO_CONTACT_MADE: '5fb36eace0f10a12bab6dcf4',
    CONTACT_MADE: '5fc7e2067b3b341134e771b3',
    APPOINTMENT_SET: '5fc7e23a7b3b341134e771b4',
    DUE_DILIGENCE: '630d9e77f3a9f38c6e267e85',
    OFFER_MADE: '5fc7e2697b3b341134e771b5',
    UNDER_CONTRACT: '5fc7e2777b3b341134e771b6',
    ASSIGN_TO_BUYER: '5fc7e2867b3b341134e771b7',
    WARM_LEAD: '5ff82e5cd5b12b1502f6c5ae',
    DEAD_LEAD: '5ff82e5cd5b12b1502f6c5af',
    REFERRED_TO_AGENT: '61ea5943c53bc8037490af45',
    INVENTORY: '60221e37d8562ef0219c072a',
  };

  public static InventoryStatus = {
    INVENTORY: '60221e37d8562ef0219c072a',
    NEW_INVENTORY: '5feb3f94aa810a3849fa5515',
    GETTING_ESTIMATES: '605ac5fb0eee4f051cee4b02',
    UNDER_REHAB: '5feb3fabaa810a3849fa5516',
    LISTED_FOR_RENT: '5feb3fc7aa810a3849fa5517',
    LISTED_FOR_SALE: '5feb4173aa810a3849fa5519',
    SELL_SIDE_UNDER_CONTRACT: '5feb41e6aa810a3849fa551a',
    SOLD: '5feb4221aa810a3849fa551b',
    // RENTAL: '60225867d8562ef021ca2180',
    RENTAL: '5feb4252aa810a3849fa551c',
  };

  public static WholeSaleStatus = {
    NEW_CONTRACT: '633fcc626125eefaa407154f',
    MARKETING_TO_BUYERS: '633fcc896125eefaa4071550',
    SHOWING_TO_BUYERS: '633fcc9a6125eefaa4071551',
    EVALUATING_OFFERS: '633fccbf6125eefaa4071552',
    ACCEPTED_OFFER: '633fccda6125eefaa4071553',
    CLEAR_TO_CLOSE: '633fccf86125eefaa4071554',
    SOLD: '5feb4221aa810a3849fa551b',
  };
  public static RentalStatus = {
    RENTAL: '5feb4252aa810a3849fa551c',
    SOLD: '5feb4221aa810a3849fa551b',
  };

  public static TagsType = {
    LEADS: '1',
    BUYERS: '2',
    VENDORS: '3',
  };

  public static GeneralModule = {
    GENERAL_MODULE_ID: '615f1f5634bb78755ff35013',
    GENERAL_MAIN_STATUS_ID: '615f1fbc34bb78755ff35766',
    GENERAL_SUB_MODULE_ID: '6164512234bb78755f543982',
  };

  public static MainStatusIndexes = {
    LEAD: { orderMinRange: 1, orderMaxRange: 20 },
    WHOLESALE_PIPELINE: { orderMinRange: 21, orderMaxRange: 30 },
    INVENTORY: { orderMinRange: 31, orderMaxRange: 40 },
    SOLD: { orderMinRange: 41, orderMaxRange: 50 },
    RENTAL: { orderMinRange: 51, orderMaxRange: 60 },
    GENERAL: { orderMinRange: 61 },
  };

  public static MainStatusId = {
    LEAD: '5faa63fdfd20cd581703d255',
  };

  public static LeadExcludeStatus = [
    '5ff82e5cd5b12b1502f6c5ae',
    '61ea5943c53bc8037490af45',
  ];
}
