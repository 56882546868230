// MODULES
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

// SERVICES
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AccountingService } from 'src/app/providers/accounting/accounting.service';
import { PreferenceService } from 'src/app/providers/preference/preference.service';
import { VendorsService } from 'src/app/providers/vendors/vendors.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { MessageConstant } from 'src/app/utils/message-constant';
import { ErrorModel } from 'src/app/utils/models/error';

// UTILS
import { ResponseModel } from 'src/app/utils/models/response';

@Component({
  selector: 'app-modal-add-vendors',
  templateUrl: './modal-add-vendors.component.html',
  styleUrls: ['./modal-add-vendors.component.scss'],
})
export class ModalAddVendorsComponent implements OnInit {
  messageConstant = MessageConstant;
  isEdit: boolean = false;
  _id: string;
  vendorDataForm: FormGroup;
  submitButton: string;
  submitted: boolean = false;
  categoryData: any[] = [];
  subcategoryData: any[] = [];

  groups: any[] = [];
  promises: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _accountingService: AccountingService,
    private vendorDailog: MatDialogRef<ModalAddVendorsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _preferenceService: PreferenceService,
    public _utilities: CommonFunctionsService,
    private _formBuilder: FormBuilder,
    private _vendorsService: VendorsService
  ) {}

  ngOnInit(): void {
    if (this.data.action == 'edit') {
      this._id = this.data.id;
      this.isEdit = true;
      this.getVendorDetails(this._id);
    }

    this.submitButton = this.isEdit ? 'Save Vendor' : 'Add Vendor';
    this.vendorDataForm = this.formBuilder.group({
      name: ['', Validators.required],
      categoryId: ['', Validators.required],
      subcategoryId: [''],
      vendorQuestions: this._formBuilder.array([]),
    });
    this.getVendorCategory();
    this.getPreferenceSection();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.vendorDataForm.controls[controlName].hasError(errorName);
  };

  cancel() {
    this.vendorDailog.close();
  }

  async onSubmit() {
    this.submitted = true;
    if (this.vendorDataForm.invalid) {
      return;
    }
    let { name, categoryId, subcategoryId } = this.vendorDataForm.value;

    let param = {
      company: '',
      name,
      websiteUrl: '',
      youtubeUrl: '',
      facebookUrl: '',
      twitterUrl: '',
      instagramUrl: '',
      linkedinUrl: '',
      firstName: '',
      lastName: '',
      categoryId,
      subcategoryId,
      ssnNo: '',
      email: '',
      contactNumber: '',
      dob: '',
      anniversaryDate: '',
      vendorQuestions: [],
      trackPaymentFor1099: 'no',
      licenseInfo: '',
      insuranceInfo: '',
      bondInfo: '',
      targetCities: [],
    };

    const question = this._utilities.getBuyersQuestions(
      this.vendorDataForm.value.vendorQuestions,
      this.groups
    );
    param = {
      ...param,
      vendorQuestions: [].concat(...question),
    };

    if (!param.subcategoryId) {
      delete param.subcategoryId;
    }
    if (this.isEdit) {
      param['vendorId'] = this._id;
    }
    this._loaderService.start();
    const isVendorExists = await this.isVendorExists(name);
    if (isVendorExists) {
      this._loaderService.stop();
      return;
    }

    let method = 'addVendor';

    this._vendorsService[method](param).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          this._loaderService.stop();
          this._toastrService.success(
            this.isEdit
              ? this.messageConstant.vendorUpdatedSuccess
              : this.messageConstant.vendorAddedSuccess
          );
          const result = response?.data;
          this.vendorDailog.close(result);
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getVendorDetails(vendorId) {
    const param = { vendorId };

    this._loaderService.start();

    this._accountingService.getVendorDetails(param).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          var responseData = response?.data;
          if (responseData) {
            this.vendorDataForm.patchValue({
              name: responseData.name,
              categoryId: responseData.categoryId,
              subcategoryId: responseData.subcategoryId,
            });
            this.getVendorSubcategory(responseData.categoryId);
          }
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getVendorCategory() {
    this._loaderService.start();

    this._accountingService.getVendorCategory({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.categoryData = response?.data;
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getVendorSubcategory(categoryId) {
    if (!categoryId) {
      return;
    }

    this._loaderService.start();
    this._accountingService.getVendorSubcategory({ categoryId }).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.subcategoryData = response?.data;
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getPreferenceSection() {
    const obj = {
      page: 1,
      limit: 100,
      moduleId: '6374c9378bc3a0ee1f84bae3',
    };
    this._preferenceService.getGroups(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.groups = this._utilities.orderItems(
            response.data.items,
            'order'
          );
          this.promises = [];

          this.groups.filter((x) =>
            this.promises.push(this.getPreferenceQuestions([x?._id]))
          );

          // HANDLE PROMISE
          if (this.promises.length > 0) {
            Promise.all(this.promises)
              .then((result) => {
                result.filter((field) => {
                  const { groupId, response } = field;
                  const index = this.groups.findIndex(
                    (x) => x?._id === groupId
                  );
                  if (index > -1)
                    this.groups[index].fields = response?.data?.items;
                });
                this.addPreferenceForm(this.groups);
                this._loaderService.stop();
              })
              .catch((err) => {
                this._loaderService.stop();
                if (err?.error) {
                  const error: ResponseModel = err.error;
                  this._toastrService.error(error.message, '');
                } else {
                  this._toastrService.error(
                    this.messageConstant.unknownError,
                    ''
                  );
                }
              });
          } else {
            this._loaderService.stop();
          }
        } else {
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getPreferenceQuestions(groupId) {
    return new Promise((resolve, reject) => {
      const obj = {
        mainGroupId: groupId,
        page: 1,
        limit: 100,
      };

      this._preferenceService.getFields(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            return resolve({ groupId: groupId[0], response });
          }
        },
        (err: ErrorModel) => {
          return reject(err);
        }
      );
    });
  }

  addPreferenceForm(results) {
    results.forEach((element) => {
      this.vendorQuestions.push(
        this._formBuilder.group({
          group: element._id,
          questions: this._formBuilder.array(
            element.fields.map((x) => {
              return this._formBuilder.group({
                questionId: x._id,
                preferenceQuestion: this.isCheckBox(x),
              });
            })
          ),
        })
      );
    });
  }

  get vendorQuestions() {
    return this.vendorDataForm.get('vendorQuestions') as FormArray;
  }

  isCheckBox(x) {
    if (x.questionType === 'CHECK_LIST') {
      return this._formBuilder.array(
        x.options.map((y, i) => {
          return this._formBuilder.group({
            key: y.key,
            value: y.value,
            checkboxValue: this.isEdit
              ? (x?.answer || []).some((a) => y.value === a)
              : x.defaultSelected,
          });
        })
      );
    } else if (!this.isEdit && x.questionType === 'RADIO_BUTTON') {
      let answer = x.options.find(
        (o) =>
          o?.defaultSelected !== '' &&
          o?.defaultSelected !== undefined &&
          o?.defaultSelected !== null
      );

      return answer ? answer?.value : '';
    }
    if (this.isEdit && x.questionType === 'DATE') {
      return x.answer ? new Date(x.answer) : '';
    } else if (!this.isEdit && x.questionType === 'CURRENCY') {
      return !this.isEdit ? x.answer : x.defaultSelected || '';
    } else {
      return this.isEdit ? x.answer : x.defaultSelected || '';
    }
  }

  getValue(groupIndex, questionIndex, type) {
    return this.processFormQuestion(groupIndex, questionIndex)[type] || '';
  }

  processFormQuestion(parentIndex, childIndex) {
    if (
      this.groups[parentIndex] &&
      this.groups[parentIndex].fields &&
      this.groups[parentIndex].fields[childIndex]
    ) {
      return this.groups[parentIndex].fields[childIndex];
    } else {
      return {};
    }
  }

  getQuestion(form) {
    return form.controls.questions.controls;
  }

  isVendorExists(name) {
    return new Promise(async (resolve, reject) => {
      this._vendorsService.checkVendor({ name }).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            if (response.data.length > 0) {
              let filterArray = response.data?.filter(
                (x) => x.name?.toLowerCase() === name?.toLowerCase()
              );
              if (filterArray.length > 0) {
                this._toastrService.error('Vendor already exists!');
                resolve(true);
                return;
              }
            }
            resolve(false);
          }
        },
        (err: ErrorModel) => {
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
          resolve(false);
        }
      );
    });
  }
}
