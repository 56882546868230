<div class="re-sendbulk-dialog">
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between"
        style="display: block !important"
      >
        Send Bulk SMS
      </h2>
      <span mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>

  <form
    [formGroup]="smsForm"
    (ngSubmit)="onSubmit()"
    autocomplete="off"
    novalidate
  >
    <mat-dialog-content class="re-typography">
      <div class="container-fluid p-0">
        <div class="re-box">
          <div class="row mt-3">
            <div class="col-12 col-md-12 col-sm-12">
              <!-- FROM NUMBER -->
              <div class="form-group">
                <label class="label required">Number</label>
                <select
                  name="fromNumber"
                  formControlName="fromNumber"
                  class="form-control"
                >
                  <option value="">Select From Number</option>
                  <option
                    *ngFor="let tollFree of data?.tollFreeNumbers"
                    [value]="tollFree?.number"
                  >
                    {{ _utilities.maskNumber(tollFree?.number) }}
                  </option>
                </select>

                <div class="text-danger">
                  <span *ngIf="submitted && hasError('fromNumber', 'required')">
                    <span>
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "From number"
                        )
                      }}
                    </span>
                  </span>
                </div>
              </div>

              <!-- CAMPAIGN -->
              <div class="form-group">
                <label class="label required">Campaign</label>
                <textarea
                  type="text"
                  class="form-control"
                  name="campaign"
                  formControlName="campaignName"
                  placeholder="Campaign Name"
                  (blur)="checkCampaign()"
                ></textarea>

                <div class="text-danger">
                  <span
                    *ngIf="submitted && hasError('campaignName', 'required')"
                  >
                    <span>
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Campaign Name"
                        )
                      }}
                    </span>
                  </span>

                  <span
                    *ngIf="
                      !hasError('campaignName', 'required') &&
                      isCampaignExists
                    "
                  >
                    <span> {{ messageConstant.campaignExists }} </span>
                  </span>
                </div>
              </div>

              <!-- SMS -->
              <div class="form-group">
                <label class="label required">SMS</label>
                <textarea
                  type="text"
                  class="form-control"
                  name="message"
                  formControlName="message"
                  placeholder="SMS"
                  [mention]="items"
                  rows="4"
                  [mentionConfig]="{
                    triggerChar: '@',
                    maxItems: 100,
                    labelKey: 'name',
                    allowSpace: true
                  }"
                ></textarea>

                <div class="text-danger">
                  <span *ngIf="submitted && hasError('message', 'required')">
                    <span>
                      {{
                        messageConstant.requiredField.replace("Field", "SMS")
                      }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" style="padding-top: 14px !important">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" cdkFocusInitial type="submit">
        Send
      </button>
    </mat-dialog-actions>
  </form>
</div>
