<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      style="display: block !important"
      class="w-100 d-flex justify-content-between"
    >
      {{ type }} Status
    </h2>
    <span matDialogClose mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<mat-dialog-content>
  <label class="re-title" *ngIf="type == 'All'">{{ type }} Status</label>
  <div class="re-status-list" *ngIf="type == 'All'">
    <div
      class="re-status"
      *ngFor="let phoneList of phoneStatusVal; let index = index"
    >
      {{ phoneList.shortStatus }}
    </div>
  </div>
  <div class="re-dropdown" *ngIf="type == 'Manage'">
    <p-autoComplete
      #phoneStatus
      (onFocus)="phoneStatusDownClick(phoneStatus)"
      (click)="phoneStatusDownClick(phoneStatus)"
      (onUnselect)="phoneStatusDownClick(phoneStatus)"
      (onKeyUp)="
        $event.keyCode == 8 ? phoneStatusDownClick(phoneStatus) : $event
      "
      (onClear)="clearListItem(phoneStatus)"
      placeholder="Phone {{ index + 1 }} Status"
      [(ngModel)]="allPhoneObject[0].phoneStatusVal"
      field="name"
      [suggestions]="searchedPhoneStatus"
      (completeMethod)="searchPhoneStatus($event.query, 0)"
      (onUnselect)="emitRemoveData($event, 0)"
      emptyMessage="{{ noDataAvailable }}"
      dataKey="name"
      [dropdown]="true"
      [multiple]="true"
      appendTo="body"
    >
    </p-autoComplete>
    <div class="text-center">
      <button class="btn re-update-btn" (click)="addKey()">Update</button>
    </div>
  </div>
</mat-dialog-content>
