import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrl } from '../../utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem('token');

  constructor(private _commonFunction: CommonFunctionsService) {}

  getCardList = (data, isWithoutLogin?): Observable<any> => {
    let lastPoint = isWithoutLogin ? 'listForCancelledAccount' : 'list';
    const endpoint = this.baseUrl + BaseUrl.billingUrl + lastPoint;

    return this._commonFunction.globalPostService(endpoint, data);
  };

  addCard = (data, isWithoutLogin?): Observable<any> => {
    let lastPoint = isWithoutLogin ? 'addForCancelledAccount' : 'add';
    const endpoint = this.baseUrl + BaseUrl.billingUrl + lastPoint;

    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateCard = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.billingUrl + 'update';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteCard = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.billingUrl + 'delete';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  setDefaultCard = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.billingUrl + 'setDefaultCard';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  chargeAmount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.billingUrl + 'chargeAmount';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getPaymentHistory = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.billingUrl + 'getPaymentHistory';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getFailedChargeAmount = (data, isLogin): Observable<any> => {
    let lastPoint = isLogin
      ? 'getFailedChargeAmount'
      : 'getChargeAmountForCancelledAccount';

    const endpoint = this.baseUrl + BaseUrl.billingUrl + lastPoint;

    return this._commonFunction.globalPostService(endpoint, data);
  };

  renewSubscription = (data, isWithoutLogin?): Observable<any> => {
    let lastPoint = isWithoutLogin
      ? 'reactivateCancelledAccount'
      : 'renewSubscription';
    const endpoint = this.baseUrl + BaseUrl.billingUrl + lastPoint;

    return this._commonFunction.globalPostService(endpoint, data);
  };
}
