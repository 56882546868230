<div *ngIf="SMSBalance <= 0">
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        style="display: block !important"
        class="w-100 d-flex justify-content-between"
      >
        Buy Credit
      </h2>
      <span matDialogClose mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          (click)="onClose()"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>

  <!-- SMS -->
  <mat-dialog-content class="mat-typography">
    <div class="re-box re-sms-box">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <p class="re-credit-info">
              Credit remains: <span>{{ SMSBalance }}</span> sms
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <p>
              You have insufficient balance in your account. Please recharge to
              proceed.
            </p>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="center" style="padding-top: 14px !important">
    <a
      mat-flat-button
      color="primary"
      cdkFocusInitial
      routerLink="/credit/buy-credit"
      mat-dialog-close
    >
      Buy Credit
    </a>
  </mat-dialog-actions>
</div>
<div class="re-message-box" *ngIf="SMSBalance > 0">
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        style="display: block !important"
        class="w-100 d-flex justify-content-between"
      >
        Send Message
      </h2>
      <span matDialogClose mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          (click)="onClose()"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>
  <form
    [formGroup]="smsDetailForm"
    (ngSubmit)="onSubmit()"
    autocomplete="off"
    novalidate
  >
    <mat-dialog-content class="re-typography">
      <!-- SMS -->
      <div class="re-box re-sms-box">
        <div class="row">
          <div class="col-12 col-md-6 col-sm-12">
            <div class="form-group">
              <p class="re-credit-info">
                Remaining credits: <span>{{ SMSBalance }}</span> SMS
              </p>
            </div>
          </div>
          <div class="col-12 col-md-12 col-sm-12" *ngIf="data?.addressLead">
            <div class="form-group">
              <p class="re-address-info">
                Property Address :
                <span>{{
                  data?.addressLead && data?.addressLead != " "
                    ? data?.addressLead
                    : "N/A"
                }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="label">Send To</label>
              <select
                name="sendTo"
                formControlName="smsNumber"
                class="form-control"
              >
                <option
                  *ngFor="let contact of data?.contacts"
                  [value]="contact?.phoneNumber"
                >
                  {{
                    contact?.name
                      ? _utilities.capitalizeName(contact?.name)
                      : contact?.title ?_utilities.capitalizeName(contact?.title) :"N/A"
                  }}
                  &bull;
                  {{ _utilities.capitalizeName(contact?.contactType) }}
                  {{ _utilities.maskNumber(contact?.phoneNumber) }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="label"
                >Sending From <span class="text-danger">*</span
                ><span
                  class="re-changebtn"
                  *ngIf="
                    !data.moduleType &&
                    data.moduleId == '5faa63fdfd20cd581703d255' &&
                    numberChange
                  "
                  (click)="changeNumber()"
                  >Change</span
                ></label
              >
              <ng-container *ngIf="data.moduleType; else else_block">
                <select
                  name="contactType"
                  class="form-control"
                  formControlName="leadSourceNumber"
                >
                  <option selected value="">Select number</option>
                  <option
                    *ngFor="let source of data?.leadSource; let i = index"
                    [value]="source?.number"
                  >
                    {{ _utilities.maskNumber(source?.number) }}
                  </option>
                </select>
                <div class="invalid-feedback mt-1">
                  <span
                    *ngIf="
                      submitted && hasError('leadSourceNumber', 'required')
                    "
                  >
                    *Please select Number
                  </span>
                </div>
              </ng-container>

              <ng-template #else_block>
                <input
                  type="text"
                  formControlName="leadSourceNumber"
                  class="form-control"
                  readonly
                  mask="(000) 000-0000"
                />
              </ng-template>
            </div>
          </div>
          <div class="col-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label class="label">
                Choose the message from templates ({{ smsTemplates?.length }})
                <span class="re-changebtn" (click)="onAddTemplate()"
                  >Create New Template</span
                >
              </label>
              <select
                class="form-control"
                name="smsTemplateId"
                formControlName="smsTemplateId"
                (change)="setSmsTemplate($event.target.value, true)"
              >
                <option value="">Select SMS Template</option>
                <option *ngFor="let sms of smsTemplates" [value]="sms._id">
                  {{ sms?.templateName }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label class="label">
                Message Text
                <span class="text-danger">*</span> &nbsp;
                <span class="pull-right re-grey-text" *ngIf="smsCount != 0">
                  ({{ smsCharCount + "/" + smsCount }})
                </span>
              </label>

              <quill-editor
                #editor
                name="messageText"
                formControlName="smsBody"
                [styles]="editorStyle"
                [modules]="{
                  toolbar: false,
                  mention: editorOptions.mention
                }"
                [placeholder]="editorOptions.placeholder"
                (ngModelChange)="getSMSCount($event)"
              ></quill-editor>

              <div class="invalid-feedback mt-1">
                <span *ngIf="submitted && hasError('smsBody', 'required')">
                  *Please enter Message
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!data.fromBuyerPage">
        <div class="re-box re-switch">
          <div class="row">
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <div class="radiosec">
                  <p class="radotext">
                    Do you want to schedule this SMS for later date and time?
                  </p>
                  <label
                    class="switch"
                    [ngClass]="{
                      'not-active': data.type == 'scheduledSMS'
                    }"
                  >
                    <input
                      type="checkbox"
                      id="checkbox1"
                      (click)="onToggle()"
                      name="activeTimeChange"
                      formControlName="activeTimeChange"
                    />
                    <span class="slider"></span>
                    <span class="text1" value="false">No</span>
                    <span class="text2" value="true">Yes</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-sm-12" *ngIf="toggle === true">
              <div class="row">
                <div class="col-12 col-md-7 col-sm-12">
                  <div class="form-group">
                    <label class="label">Date & Time</label>
                    <div class="row">
                      <div class="col-12 col-md-7 col-sm-12 mb-2">
                        <p-calendar
                          formControlName="scheduleDate"
                          [minDate]="minimumTime"
                          styleClass="ngCalendarClass"
                          [showIcon]="true"
                          [icon]="'fa fa-calendar'"
                          appendTo="body"
                          (onSelect)="onChangeTime()"
                        >
                        </p-calendar>
                        <div class="text-danger re-error-txt">
                          <span
                            class="re-error"
                            *ngIf="hasError('scheduleDate', 'required')"
                          >
                            {{
                              messageConstant.requiredField.replace(
                                "Field",
                                "Schedule Date"
                              )
                            }}
                          </span>
                        </div>
                      </div>
                      <div class="col-12 col-md-5 col-sm-12">
                        <p-calendar
                          formControlName="scheduleTime"
                          [minDate]="minimumTime"
                          styleClass="ngCalendarClass"
                          [showIcon]="true"
                          [icon]="'fa fa-clock-o'"
                          appendTo="body"
                          hourFormat="12"
                          [timeOnly]="true"
                          (onSelect)="onChangeTime()"
                        ></p-calendar>
                        <div class="text-danger re-error-txt">
                          <span
                            class="re-error"
                            *ngIf="hasError('scheduleTime', 'required')"
                          >
                            {{
                              messageConstant.requiredField.replace(
                                "Field",
                                "Schedule Time"
                              )
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-5 col-sm-12">
                  <div class="form-group">
                    <label class="label">Time Zone</label>
                    <select
                      name="timezone"
                      formControlName="timezone"
                      class="form-control"
                    >
                      <option
                        *ngFor="let timezone of timezoneList"
                        value="{{ timezone.timezonename }}"
                      >
                        {{ timezone.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ATTACHMENTS -->
      <div class="mat-typography re-attachments">
        <div class="re-box re-attachment-box">
          <div class="row">
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <span class="re-attach">ATTACHMENTS</span>
                <span class="re-file-attach">
                  <input
                    type="file"
                    name="mediaUrls"
                    formControlName="mediaUrls"
                    #uploader
                    ngFileSelect
                    multiple
                    (uploadOutput)="onUploadLogo($event)"
                    [uploadInput]="uploadInput"
                  />
                  <img
                    src="assets/images/inventory-details/attachment-symbol.png"
                    alt="attachment"
                  />
                  Add attachments
                </span>
              </div>
            </div>

            <!-- FILES -->
            <ng-container
              *ngFor="
                let attachment of showAttachments;
                let attachmentIndex = index
              "
            >
              <div class="col-12 col-md-12 col-sm-12 re-attachment-files">
                <span>
                  {{ attachment?.name }}
                </span>
                <img
                  src="/assets/images/close-black.svg"
                  (click)="removeExistingAttachment(attachmentIndex)"
                />
              </div>
            </ng-container>

            <!-- FILES -->
            <ng-container
              *ngFor="
                let attachment of attachments;
                let attachmentIndex = index
              "
            >
              <div class="col-12 col-md-12 col-sm-12 re-attachment-files">
                <span>
                  {{ attachment?.name }}
                </span>
                <img
                  src="/assets/images/close-black.svg"
                  (click)="removeAttachment(attachmentIndex)"
                />
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div
        class="re-box re-sms-box"
        *ngIf="
          _utilities.dlcForm?.twilioProfileData?.campaignStatus != 'VERIFIED'
        "
      >
        <div class="row">
          <div class="col-12 col-md-12 col-sm-12">
            <div class="form-group">
              <p class="re-credit-info">
                Your SMS messages might not get delivered by cell phone carriers
                because your 10DLC registration is not complete.<a
                  routerLink="/manage-numbers/dlc-number"
                  mat-dialog-close
                  >Learn more</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="center" style="padding-top: 14px !important">
      <button mat-stroked-button mat-dialog-close style="border: none">
        Cancel
      </button>
      <button
        mat-flat-button
        color="primary"
        cdkFocusInitial
        *ngIf="data.type != 'scheduledSMS'"
      >
        {{ toggle ? "Schedule Message" : "Send Right Away" }}
      </button>
      <button
        mat-flat-button
        color="primary"
        cdkFocusInitial
        *ngIf="data.type == 'scheduledSMS'"
      >
        {{ "Update Scheduled SMS" }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
