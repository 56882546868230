<div class="re-top-container">
  <span class="re-filter-title">FILTERS APPLIED</span>

  <!-- TEMPLATE -->
  <div class="dropdown-submenu">
    <a
      class="drop-btn re-template-btn"
      data-toggle="dropdown"
      tabindex="-1"
      (click)="hideOptionsPanel()"
      *ngIf="isSaveFilter"
    >
      <img src="assets/images/template.svg" alt="template" /> Saved Filters
    </a>

    <div class="dropdown-menu dropdown-menu-right">
      <div class="input-group bg-white">
        <button type="button" class="input-group-addon" title="Search">
          <span class="fa fa-search"></span>
        </button>
        <input
          #searchInput
          type="text"
          name="searchVal"
          id="searchVal"
          [(ngModel)]="searchTemplate"
          placeholder="Search saved filters"
          class="form-control"
        />
      </div>
      <a class="re-save-filter" (click)="saveFilterTemplate()">
        {{ selectedTemplateIndex === -1 ? "Save" : "Update" }} active filter
      </a>
      <div class="re-save-list">
        <span class="re-drop-title">SAVED FILTERS</span>

        <ng-container
          *ngFor="
            let template of templates | SearchText: searchTemplate:'title';
            let templateIndex = index
          "
        >
          <span
            class="re-lists"
            [class.re-active]="selectedTemplateIndex === templateIndex"
          >
            <a
              class="re-name re-template-name"
              (click)="selectTemplate(template, templateIndex)"
              [pTooltip]="template?.title"
              tooltipPosition="bottom"
            >
              {{ template?.title }}
            </a>
            <div class="re-edit-btn-filter">
              <a
                class="re-link"
                (click)="editFilterTemplate(template, templateIndex)"
              >
                <img src="assets/images/edit-teal.svg" alt="edit" />
              </a>
              <a
                class="re-link"
                (click)="deleteFilterTemplate(template, templateIndex)"
              >
                <img src="assets/images/delete-red1.svg" alt="delete" />
              </a>
            </div>
          </span>
        </ng-container>

        <ng-container *ngIf="!templates.length">
          <span class="re-lists text-center pr-0">
            <a class="re-name text-muted"> No Data </a>
          </span>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- ACTIVE FILTERS -->
<div class="re-list">
  <ng-container
    *ngFor="let filter of appliedFilteredList; let filterIndex = index"
  >
    <div class="row">
      <!-- FILTER -->
      <div class="form-group col-12 col-md-12 col-lg-4">
        <select
          [name]="filter.value + '-' + filterIndex"
          [id]="filter.value + '-' + filterIndex"
          class="form-control"
          [ngModel]="filter.value"
          (change)="changeFilter($event, filterIndex)"
        >
          <ng-container *ngFor="let filter of filters">
            <option
              [class.d-none]="isActive(filter)"
              [value]="filter?.value"
              [disabled]="isDisabled(filter)"
              [title]="filter?.tooltip || ''"
            >
              {{ filter?.label }}
            </option>
          </ng-container>
        </select>

        <ng-container *ngIf="isDisabled(filter)">
          <div class="re-filter-warning text-danger mt-1">
            <span>
              <img
                class="img re-warning"
                src="assets/images/warning-24x.png"
                alt="warning"
              />

              {{ filter?.error }}
            </span>
          </div>
        </ng-container>
      </div>

      <!-- OPERATOR -->
      <div class="form-group col-12 col-md-3 col-lg-2">
        <select
          [name]="filter.value + '-' + filterIndex"
          [id]="filter.value + '-' + filterIndex"
          class="form-control"
          [(ngModel)]="filter.selectedOperator"
        >
          <ng-container *ngFor="let operator of filter?.operator">
            <option [value]="operator?.value">{{ operator?.label }}</option>
          </ng-container>
        </select>
      </div>

      <!-- OPTIONS -->
      <div
        [ngClass]="
          filter?.conditionType && filter?.conditionType.length
            ? 'form-group col-12 col-md-6 col-lg-3'
            : 'form-group col-12 col-md-8 col-lg-5'
        "
      >
        <ng-container [ngSwitch]="filter?.optionsType">
          <!-- SINGLE SELECT -->
          <ng-container *ngSwitchCase="'SELECT'">
            <select
              [name]="filter.value + '-' + filterIndex"
              [id]="filter.value + '-' + filterIndex"
              class="form-control"
              [(ngModel)]="filter.selectedOption"
              (change)="modelChanged(filter)"
            >
              <ng-container *ngFor="let option of filter?.options">
                <option [value]="option.value">{{ option?.label }}</option>
              </ng-container>
            </select>
            <div class="text-danger mb-2" *ngIf="filter?.error == true">
              <span> *Please select any option. </span>
            </div>
          </ng-container>

          <!-- MULTIPLE SELECT -->
          <ng-container *ngSwitchCase="'MULTI-SELECT'">
            <p-multiSelect
              #select
              [name]="filter.value + '-' + filterIndex"
              [id]="filter.value + '-' + filterIndex"
              [placeholder]="'Select ' + filter?.label + '(s)'"
              [options]="filter.options"
              [(ngModel)]="filter.selectedOption"
              appendTo="body"
              (onChange)="modelChanged(filter)"
            ></p-multiSelect>
            <div class="text-danger mb-2" *ngIf="filter?.error == true">
              <span> *Please select at least one option. </span>
            </div>
          </ng-container>

          <!-- CHIPS -->
          <ng-container *ngSwitchCase="'CHIPS'">
            <mat-form-field class="re-chip-list">
              <!-- CHIPS -->
              <mat-chip-list #chipList>
                <mat-chip
                  *ngFor="
                    let chip of filter.selectedOption;
                    let chipIndex = index
                  "
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="removeChip(filter, chipIndex)"
                >
                  {{ chip?.label }}
                  <mat-icon matChipRemove *ngIf="removable"> cancel </mat-icon>
                </mat-chip>
                <input
                  #chipInput
                  [formControl]="getFormControl(filter)"
                  [matChipInputFor]="chipList"
                  [matAutocomplete]="cityAutoComplete"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="addChip(filter, $event)"
                />
              </mat-chip-list>

              <!-- PLACEHOLDER -->
              <mat-placeholder
                [ngClass]="
                  filter?.selectedOption?.length > 0
                    ? 'placeholder removePlaceHolder'
                    : 'placeholder'
                "
              >
                {{ filter?.label }}
              </mat-placeholder>

              <!--  -->
              <mat-autocomplete
                #cityAutoComplete="matAutocomplete"
                (optionSelected)="selected($event, filter)"
              >
                <ng-container
                  *ngFor="let tag of getFilteredList(filter) | async"
                >
                  <mat-option *ngIf="isVisible(filter, tag)" [value]="tag">
                    {{ tag?.label }}
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>

            <div class="text-danger mb-2" *ngIf="filter?.error">
              <span> *Please enter at least one value. </span>
            </div>
          </ng-container>

          <!-- AUTO COMPLETE -->
          <ng-container *ngSwitchCase="'AUTO-COMPLETE'">
            <p-autoComplete
              name="title"
              appendTo="body"
              [placeholder]="filter?.label"
              [suggestions]="suggestionList"
              [emptyMessage]="noDataAvailable"
              [multiple]="filter.isMultiple"
              [dropdown]="true"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="filter.selectedOption"
              [forceSelection]="true"
              (onDropdownClick)="getSuggestionList($event.query, filter, true)"
              (completeMethod)="getSuggestionList($event.query, filter)"
              (onSelect)="modelChanged(filter)"
            >
              <ng-template let-item pTemplate="selectedItem">
                <span style="vertical-align: middle">
                  {{ item[filter.optionKey] }}
                </span>
              </ng-template>

              <ng-template let-item pTemplate="item">
                <div class="ui-helper-clearfix">
                  <div class="re-search-name">
                    {{ item[filter.optionKey] }}
                  </div>
                </div>
              </ng-template>
            </p-autoComplete>

            <div class="text-danger mb-2" *ngIf="filter?.error == true">
              <span> *Please select at least one option. </span>
            </div>
          </ng-container>

          <!-- RANGE -->
          <ng-container *ngSwitchCase="'RANGE'">
            <div class="row">
              <div class="col-md-6">
                <input
                  class="form-control"
                  type="number"
                  placeholder="{{
                    filter?.selectedOperator == 'is-between' ? 'Min' : 'Value'
                  }}"
                  min="0"
                  [(ngModel)]="filter.minVal"
                  onkeypress="return (event.charCode == 8) ? null : event.charCode >= 48 && event.charCode <= 57"
                  (change)="modelChanged(filter)"
                />
              </div>
              <div
                class="col-md-6"
                *ngIf="filter?.selectedOperator == 'is-between'"
              >
                <input
                  class="form-control"
                  type="number"
                  placeholder="Max"
                  min="0"
                  [(ngModel)]="filter.maxVal"
                  onkeypress="return (event.charCode == 8) ? null : event.charCode >= 48 && event.charCode <= 57"
                  (change)="modelChanged(filter)"
                />
              </div>
            </div>
            <div class="text-danger mb-2" *ngIf="filter?.error">
              {{ filter.rangeErrorMsg }}
            </div>
          </ng-container>

          <!-- DATE-RANGE -->
          <ng-container *ngSwitchCase="'DATE-RANGE'">
            <div class="row">
              <div class="col-md-6">
                <p-calendar
                  styleClass="ngCalendarClass"
                  appendTo="body"
                  [icon]="'fa fa-calendar'"
                  [(ngModel)]="filter.minVal"
                  placeholder="{{
                    filter?.selectedOperator == 'between'
                      ? 'From'
                      : 'Select Date'
                  }}"
                  dateFormat="mm-dd-yy"
                  (onSelect)="setMaxDate(filter)"
                  [showButtonBar]="true"
                  [showIcon]="false"
                ></p-calendar>
              </div>
              <div
                class="col-md-6"
                *ngIf="filter?.selectedOperator == 'between'"
              >
                <p-calendar
                  styleClass="ngCalendarClass"
                  appendTo="body"
                  [icon]="'fa fa-calendar'"
                  [(ngModel)]="filter.maxVal"
                  placeholder="To"
                  dateFormat="mm-dd-yy"
                  [minDate]="filter.minSelectDate"
                  [showButtonBar]="true"
                  [showIcon]="false"
                  (onSelect)="modelChanged(filter)"
                ></p-calendar>
              </div>
            </div>
            <div class="text-danger mb-2" *ngIf="filter?.error">
              <span> {{ filter.rangeErrorMsg }} </span>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div
        class="form-group col-12 col-md-2 col-lg-2"
        *ngIf="filter?.conditionType && filter?.conditionType.length"
      >
        <select [(ngModel)]="filter.selectedCondition" class="form-control">
          <ng-container *ngFor="let option of filter?.conditionType">
            <option [value]="option.value">{{ option?.label }}</option>
          </ng-container>
        </select>
      </div>
      <div class="col-12 col-md-1 col-lg-1 text-center">
        <a class="re-delete" (click)="removeFilter($event, filterIndex)">
          <img src="assets/images/delete-2.svg" alt="delete" />
        </a>
      </div>
    </div>
  </ng-container>
</div>

<div class="re-action-container">
  <!-- ADD FILTER -->
  <div class="dropdown-submenu">
    <a
      #addFilter
      class="drop-btn re-add-more dropdown-toggle"
      data-toggle="dropdown"
      (click)="setSearchFocus()"
      tabindex="-1"
    >
      + Add Filter
    </a>

    <div class="dropdown-menu">
      <div class="input-group">
        <button type="button" class="input-group-addon" title="Search">
          <span class="fa fa-search"></span>
        </button>
        <input
          #search
          type="text"
          name="search-filter"
          id="search-filter"
          [(ngModel)]="searchFilter"
          (keyup)="searchFilterFunction($event)"
          placeholder="Search"
          class="form-control"
        />
      </div>
      <div class="re-name-list">
        <!--for filters with grouping-->
        <div
          *ngFor="let group of filterGroup"
          [ngClass]="group?.show ? 're-filter-group' : ''"
        >
          <ng-container *ngIf="group?.show">
            <p class="re-filter-group-heading">{{ group?.name }}</p>
          </ng-container>
          <ng-container *ngFor="let filter of filters">
            <ng-container
              *ngIf="
                filter?.filterGroup &&
                group?.name == filter?.filterGroup &&
                filter?.show
              "
            >
              <button
                class="re-filter-name re-filters"
                [disabled]="isDisabled(filter)"
                [class.d-none]="isActive(filter)"
                (click)="selectFilter(filter)"
              >
                {{ filter?.label || "N/A" }}

                <span
                  [pTooltip]="filter?.tooltip"
                  tooltipPosition="bottom"
                  *ngIf="filter?.isTooltipExists && isDisabled(filter)"
                >
                  <i class="fa fa-question-circle"></i>
                </span>
              </button>
            </ng-container>
          </ng-container>
        </div>
        <!--for filters without grouping-->
        <div *ngFor="let filter of filters | SearchText: searchFilter:'label'">
          <button
            class="re-filter-name re-filters"
            [disabled]="isDisabled(filter)"
            [class.d-none]="isActive(filter)"
            (click)="selectFilter(filter)"
            *ngIf="!filter?.filterGroup"
          >
            {{ filter?.label || "N/A" }}

            <span
              [pTooltip]="filter?.tooltip"
              tooltipPosition="bottom"
              *ngIf="filter?.isTooltipExists && isDisabled(filter)"
            >
              <i class="fa fa-question-circle"></i>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- APPLY FILTER -->
  <div class="dropdown-submenu">
    <a class="re-add-more" (click)="applyFilter()">Apply Filter</a>
  </div>
</div>
