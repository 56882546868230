<div class="re-result-address">
  <h3 class="re-txt1">{{data?.address}}</h3>
  <h5 class="re-txt2">
    {{ data?.name }} • {{ _utilities.maskNumber(data?.phoneNumber) }}
  </h5>
  <span
    [class.re-disable]="!callAnswerForm.get('callAnswer').value"
    class="btn-red"
    (click)="oncallAnswerSubmit()"
  >
    <img src="assets/images/close-red.svg" alt="close" />
  </span>
</div>
<form
  [formGroup]="callAnswerForm"
  (ngSubmit)="oncallAnswerSubmit()"
  autocomplete="off"
  novalidate
>
<mat-dialog-content>
  <div class="re-result-checkbx">
      <div class="row">
        <div class="col-12 col-md-12">
          <h4 class="re-headin">CALL RESULT</h4>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="re-lab">LEAD CONNECTED</label>
            <div class="radiolist">
              <label
                class="radiobtn"
                *ngFor="let callStatus of callConnectedStatus"
                (click)="otherReason(callStatus?.value)"
              >
                <input
                  type="radio"
                  [value]="callStatus?.value"
                  name="callAnswer"
                  formControlName="callAnswer"
                /><span class="checkmark"></span>{{ callStatus?.name }}
              </label>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <h5 class="re-heading2">LEAD NOT CONNECTED</h5>
            <div class="radiolist">
              <ng-container *ngFor="let callStatus of callNotConnectedStatus">
                <label
                  class="radiobtn"
                  *ngIf="callStatus?.value != 12"
                  (click)="otherReason(callStatus?.value)"
                >
                  <input
                    type="radio"
                    [value]="callStatus?.value"
                    name="callAnswer"
                    formControlName="callAnswer"
                  /><span class="checkmark"></span>{{ callStatus?.name }}
                </label>
              </ng-container>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-12"
          *ngIf="callConnectReason || callNotConnectReason"
        >
          <div class="mb-0">
            <textarea
              rows="2"
              placeholder="Enter Reason"
              class="form-control"
              formControlName="otherReason"
              name="otherReason"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <div class="re-btnlist">
    <button class="btn-teal-outline" type="submit"  [class.re-disable]="!callAnswerForm.get('callAnswer').value">Save Answer</button>
  </div>
</form>
