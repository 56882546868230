<div class="re-add-vendor-modal">
  <div class="dialog-header">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between"
        style="display: block !important"
      >
        {{ isEdit ? "Save Vendor" : "Add Vendor" }}
      </h2>
      <span matDialogClose mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>
  <form autocomplete="off" novalidate [formGroup]="vendorDataForm">
    <mat-dialog-content class="re-typography">
      <div class="container-fluid p-0">
        <div class="row">
          <!-- TEXT -->
          <div class="col-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label class="re-labels"
                >Vendor Display Name <span class="text-danger">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                name="name"
                placeholder="Display Name"
                formControlName="name"
              />
              <div class="invalid-feedback">
                <span *ngIf="submitted && hasError('name', 'required')"
                  >Vendor Display Name is required
                </span>
              </div>
            </div>
          </div>

          <!-- DROPDOWN -->
          <div class="col-12 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="re-labels"
                >Vendor Type <span class="text-danger">*</span>
              </label>
              <select
                name="contactType"
                class="form-control"
                formControlName="categoryId"
                (change)="
                  getVendorSubcategory(vendorDataForm.controls.categoryId.value)
                "
              >
                <option selected value="">Vendor Type</option>
                <option
                  *ngFor="let category of categoryData"
                  [ngValue]="category?._id"
                >
                  {{ category?.name }}
                </option>
              </select>
              <div class="invalid-feedback">
                <span *ngIf="submitted && hasError('categoryId', 'required')"
                  >Vendor Type is required
                </span>
              </div>
            </div>
          </div>

          <!-- DROPDOWN -->
          <div
            class="col-12 col-md-6 col-sm-12"
            *ngIf="subcategoryData?.length > 0"
          >
            <div class="form-group">
              <label class="re-labels">Sub Type</label>
              <select
                name="contactType"
                class="form-control"
                formControlName="subcategoryId"
              >
                <option selected value="">Select Sub Type</option>
                <option
                  *ngFor="let category of subcategoryData"
                  [ngValue]="category?._id"
                >
                  {{ category?.name }}
                </option>
              </select>
              <div class="invalid-feedback">
                <span *ngIf="submitted && hasError('subcategoryId', 'required')"
                  >Sub Type is required
                </span>
              </div>
            </div>
          </div>

          <!-- PREFERENCE -->
          <div class="" formArrayName="vendorQuestions" style="display: none">
            <div
              class="re-vendorsadd-form"
              *ngFor="
                let preference of vendorQuestions['controls'];
                let groupIndex = index
              "
              [formGroupName]="groupIndex"
            >
              <div class="row">
                <!-- TITLE -->
                <div class="col-12 col-lg-12 col-md-12 col-sm-12">
                  <h4
                    class="re-group-title"
                    title="{{ groups[groupIndex]?.title }} - {{
                      groups[groupIndex]?.description
                    }}"
                  >
                    {{ groups[groupIndex]?.title }}
                  </h4>
                </div>

                <!-- FIELDS -->
                <div
                  formArrayName="questions"
                  class="col-12 col-lg-12 col-md-12 col-sm-12 re-mb"
                >
                  <div
                    *ngFor="
                      let question of getQuestion(preference);
                      let questionIndex = index
                    "
                  >
                    <!-- TEXT -->
                    <div
                      class="form-group"
                      *ngIf="
                        getValue(groupIndex, questionIndex, 'questionType') ===
                        'TEXT'
                      "
                      [formGroupName]="questionIndex"
                    >
                      <label class="re-label re-question-title">
                        {{
                          getValue(groupIndex, questionIndex, "questionTitle")
                        }}
                      </label>

                      <input
                        type="text"
                        class="form-control"
                        formControlName="preferenceQuestion"
                        [name]="getValue(groupIndex, questionIndex, '_id')"
                        [placeholder]="
                          getValue(groupIndex, questionIndex, 'placeholder')
                        "
                        [id]="getValue(groupIndex, questionIndex, '_id')"
                      />
                    </div>

                    <!-- MULTILINE TEXT -->
                    <div
                      class="form-group"
                      *ngIf="
                        getValue(groupIndex, questionIndex, 'questionType') ===
                        'MULTILINE_TEXT'
                      "
                      [formGroupName]="questionIndex"
                    >
                      <label class="re-label re-question-title">
                        {{
                          getValue(groupIndex, questionIndex, "questionTitle")
                        }}
                      </label>

                      <textarea
                        type="text"
                        class="form-control"
                        formControlName="preferenceQuestion"
                        [name]="getValue(groupIndex, questionIndex, '_id')"
                        [placeholder]="
                          getValue(groupIndex, questionIndex, 'placeholder')
                        "
                        [id]="getValue(groupIndex, questionIndex, '_id')"
                      ></textarea>
                    </div>

                    <!-- NUMBER / CURRENCY -->
                    <div
                      class="form-group"
                      *ngIf="
                        getValue(groupIndex, questionIndex, 'questionType') ===
                        'CURRENCY'
                      "
                      [formGroupName]="questionIndex"
                    >
                      <label class="re-label re-question-title">
                        {{
                          getValue(groupIndex, questionIndex, "questionTitle")
                        }}
                      </label>

                      <input
                        type="text"
                        class="form-control"
                        formControlName="preferenceQuestion"
                        [name]="getValue(groupIndex, questionIndex, '_id')"
                        [placeholder]="
                          getValue(groupIndex, questionIndex, 'placeholder')
                        "
                        [id]="getValue(groupIndex, questionIndex, '_id')"
                        mask="separator.2"
                        [thousandSeparator]="','"
                        [decimalMarker]="'.'"
                        prefix="$ "
                      />
                    </div>

                    <div
                      class="form-group"
                      *ngIf="
                        getValue(groupIndex, questionIndex, 'questionType') ===
                        'NUMBER'
                      "
                      [formGroupName]="questionIndex"
                    >
                      <label class="re-label re-question-title">
                        {{
                          getValue(groupIndex, questionIndex, "questionTitle")
                        }}
                      </label>

                      <input
                        type="text"
                        class="form-control"
                        formControlName="preferenceQuestion"
                        [name]="getValue(groupIndex, questionIndex, '_id')"
                        [placeholder]="
                          getValue(groupIndex, questionIndex, 'placeholder')
                        "
                        [id]="getValue(groupIndex, questionIndex, '_id')"
                        mask="separator.2"
                        [thousandSeparator]="','"
                        [decimalMarker]="'.'"
                      />
                    </div>

                    <!-- DATE -->
                    <div
                      class="form-group"
                      *ngIf="
                        getValue(groupIndex, questionIndex, 'questionType') ===
                        'DATE'
                      "
                      [formGroupName]="questionIndex"
                    >
                      <label class="re-label re-question-title">
                        {{
                          getValue(groupIndex, questionIndex, "questionTitle")
                        }}
                      </label>

                      <p-calendar
                        appendTo="body"
                        [showIcon]="true"
                        [icon]="'fa fa-calendar'"
                        [showTime]="true"
                        [hourFormat]="12"
                        formControlName="preferenceQuestion"
                        [dateFormat]="
                          getValue(groupIndex, questionIndex, 'dateFormat')
                        "
                        [placeholder]="
                          getValue(groupIndex, questionIndex, 'placeholder')
                        "
                        [id]="getValue(groupIndex, questionIndex, '_id')"
                      ></p-calendar>
                    </div>

                    <!-- CHECKBOX -->
                    <div
                      class="form-group"
                      *ngIf="
                        getValue(groupIndex, questionIndex, 'questionType') ===
                        'CHECK_LIST'
                      "
                      [formGroupName]="questionIndex"
                    >
                      <label class="re-label re-question-title">
                        {{
                          getValue(groupIndex, questionIndex, "questionTitle")
                        }}
                      </label>

                      <div
                        *ngFor="
                          let item of getValue(
                            groupIndex,
                            questionIndex,
                            'options'
                          );
                          let checkIndex = index
                        "
                        class="text-left"
                        formGroupName="preferenceQuestion"
                      >
                        <div [formGroupName]="checkIndex" class="checkboxlist">
                          <label class="checkboxbtn">
                            <input
                              type="checkbox"
                              [name]="'checkboxValue'"
                              formControlName="checkboxValue"
                              [checked]="item.defaultSelected"
                              [value]="item.value"
                            />
                            <span class="checkmark"></span>
                            {{ item.key }}
                          </label>
                        </div>
                      </div>
                    </div>

                    <!-- RADIO BUTTON -->
                    <div
                      class="form-group"
                      *ngIf="
                        getValue(groupIndex, questionIndex, 'questionType') ===
                        'RADIO_BUTTON'
                      "
                      [formGroupName]="questionIndex"
                    >
                      <label class="re-label re-question-title">
                        {{
                          getValue(groupIndex, questionIndex, "questionTitle")
                        }}
                      </label>

                      <div class="radiolist">
                        <label
                          class="radiobtn form-check-inline"
                          *ngFor="
                            let item of getValue(
                              groupIndex,
                              questionIndex,
                              'options'
                            )
                          "
                        >
                          <input
                            type="radio"
                            [name]="'preferenceQuestion'"
                            formControlName="preferenceQuestion"
                            [checked]="item.defaultSelected"
                            [value]="item.value"
                          />
                          <span class="checkmark"></span>
                          {{ item.key }}
                        </label>
                      </div>
                    </div>

                    <!-- DROPDOWN -->
                    <div
                      class="form-group"
                      *ngIf="
                        getValue(groupIndex, questionIndex, 'questionType') ===
                        'DROPDOWN'
                      "
                      [formGroupName]="questionIndex"
                    >
                      <label class="re-label re-question-title">
                        {{
                          getValue(groupIndex, questionIndex, "questionTitle")
                        }}
                      </label>

                      <select
                        class="form-control"
                        formControlName="preferenceQuestion"
                      >
                        <option value="">Select</option>
                        <option
                          *ngFor="
                            let option of getValue(
                              groupIndex,
                              questionIndex,
                              'options'
                            )
                          "
                          [value]="option?.key"
                          [selected]="option?.defaultSelected"
                        >
                          {{ option.key }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <hr class="m-0" />
    <mat-dialog-actions align="center">
      <button mat-raised-button mat-dialog-close (click)="cancel()">
        Cancel
      </button>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        cdkFocusInitial
        (click)="onSubmit()"
      >
        {{ submitButton }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
