<div class="re-top-head">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      Email Address
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<mat-dialog-content class="">
  <div class="container">
    <div class="re-boxes">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <label class="re-labels">Email 1</label>
            <input
              type="email"
              class="form-control"
              placeholder="Email 1"
              [(ngModel)]="currentData.Email_1"
              (blur)="callValidateEmail('0')"
            />
          </div>
        </div>
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <label class="re-labels">Email 2</label>
            <input
              type="email"
              class="form-control"
              placeholder="Email 2"
              [(ngModel)]="currentData.Email_2"
              (blur)="callValidateEmail('1')"
            />
          </div>
        </div>
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <label class="re-labels">Email 3</label>
            <input
              type="email"
              class="form-control"
              placeholder="Email 3"
              [(ngModel)]="currentData.Email_3"
              (blur)="callValidateEmail('2')"
            />
          </div>
        </div>
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <label class="re-labels">Email 4</label>
            <input
              type="email"
              class="form-control"
              placeholder="Email 4"
              [(ngModel)]="currentData.Email_4"
              (blur)="callValidateEmail('3')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center" style="padding-top: 14px !important">
  <button class="cancelBtn" mat-raised-button [mat-dialog-close]="false">Cancel</button>
  <button
    cdkFocusInitial
    mat-flat-button
    class="re-submit-btn"
    (click)="updateEmail()"
    [disabled]="
    !currentData.Email_1  &&
    !currentData.Email_2 &&
    !currentData.Email_3 &&
    !currentData.Email_4 
  "
  >
    Update
  </button>
</mat-dialog-actions>
