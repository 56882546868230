<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      style="display: block !important"
      class="w-100 d-flex justify-content-between"
    >
      Copy Esign
      <!-- {{ data?.type }} -->
    </h2>
    <span matDialogClose mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<form
  [formGroup]="sectionForm"
  (ngSubmit)="onSubmit()"
  autocomplete="off"
  novalidate
>
  <mat-dialog-content class="">
    <div class="container p-0">
      <div class="re-box mt-3">
        <div class="row">
          <div class="col-12 col-md-12 col-sm-12">
            <!-- SECTION NAME -->
            <div class="form-group">
              <label class="re-label required">Template Name </label>
              <input
                type="text"
                class="form-control"
                [placeholder]="'Enter Template Name'"
                formControlName="name"
                name="name"
                maxlength="50"
              />

              <div
                class="text-danger mt-1"
                *ngIf="submitted && hasError('name', 'required')"
              >
                <span>{{
                  messageConstant?.requiredName.replace("Name", "Template Name")
                }}</span>
              </div>
              <div
                class="text-danger mt-1"
                *ngIf="hasError('name', 'minlength')"
              >
                <span>Template name should be more than 3 characters</span>
              </div>
              <div
                class="text-danger mt-1"
                *ngIf="hasError('name', 'maxlength')"
              >
                <span>Template Name Accept Maximum 50 Letters</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" style="padding-top: 14px !important">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" cdkFocusInitial type="submit">
      Submit
    </button>
  </mat-dialog-actions>
</form>
