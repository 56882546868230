// MODULES
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuillEditorComponent } from 'ngx-quill';

// UTILS
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';
import { MessageConstant } from 'src/app/utils/message-constant';
import { StatusConstant } from 'src/app/utils/status-constant';

// SERVICES
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharedService } from 'src/app/shared/shared.service';
import { MasterTaskService } from 'src/app/providers/master-task/master-task.service';

@Component({
  selector: 'app-modal-reply-comment',
  templateUrl: './modal-reply-comment.component.html',
  styleUrls: ['./modal-reply-comment.component.scss'],
})
export class ModalReplyCommentComponent implements OnInit {
  @ViewChild('editor') editor: QuillEditorComponent;

  messageConstant = MessageConstant;
  userListing: any = [];
  comment: any = '';
  comment2: any = '';
  leadData: any;
  userComment: string = '';
  propertyAddress: string = '';

  editorStyle: {
    height: '50px';
  };
  editorConfig: any = {};

  editorOptions: any = {
    placeholder: 'Type message here use @user to tag someone',
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
    mention: {
      mentionListClass: 'ql-mention-list mat-elevation-z8',
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      showDenotationChar: true,
      spaceAfterInsert: true,
      onSelect: (item, insertItem) => {
        const editor = this.editor.quillEditor;
        insertItem(item);
        // this.tagUser(item.value)
        // necessary because quill-mention triggers changes as 'api' instead of 'user'
        editor.insertText(editor.getLength() - 1, '', 'user');
      },
      source: (searchTerm, renderList) => {
        const values = this.userListing.map((item) => {
          return { id: item._id, value: item.firstName };
        });

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];

          values.forEach((entry) => {
            if (
              entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            ) {
              matches.push(entry);
            }
          });
          renderList(matches, searchTerm);
        }
      },
    },
  };

  constructor(
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    public _utilities: CommonFunctionsService,
    public _sharedService: SharedService,
    private _masterTaskService: MasterTaskService,
    private dialogRef: MatDialogRef<ModalReplyCommentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.editorConfig.toolbar = this.editorOptions.toolbar;
    this.editorConfig.mention = this.editorOptions.mention;

    this.leadData = this.data?.item?.leadData;
    this.userComment = this.data?.item?.comment;
    this.propertyAddress = this._utilities.formatAddress(
      this.data?.item?.address,
      this.data?.item?.unitNo
    );
  }

  ngOnInit(): void {
    this.getUserRoleList();

    const { firstName, createdBy } = this.data?.item;
    const str = `
      <span 
          class="mention" 
          data-index="${0}" 
          data-denotation-char="@" 
          data-id="${createdBy}" 
          data-value="${firstName}">
          <span contenteditable="false">
          <span class="ql-mention-denotation-char">@</span>
          ${firstName}
        </span>
      </span>&nbsp;
    `;

    this.comment = str;
    this.comment2 = str;

    setTimeout(() => {
      this.editor.writeValue(str);
      const editorLength = this.editor.quillEditor.getLength();
      this.editor.quillEditor.setSelection(editorLength, editorLength + 1);
    }, 700);
  }

  onComment($event) {
    if (this.editorOptions.placeholder === $event?.target?.innerText?.trim())
      return;

    this.comment = $event?.target?.innerHTML; //.replace(/\r\n|\r|\n/g, '<br />');
  }

  onComment2($event) {
    if (this.editorOptions.placeholder === $event?.target?.innerText?.trim())
      return;

    this.comment2 = $event?.target?.innerHTML; //.replace(/\r?\n/g, '<br />');
  }

  tagUser($event) {
    return `@${$event.firstName}` + ` `;
  }

  async onSubmit() {
    if (!this.comment) {
      return;
    }

    let body = {
      moduleId: this.getModuleId(),
      subModuleId: this.data?.item?.subModuleId,
      comment: this.comment,
      isTagged: this.comment?.includes('@'),
      tagUsers: await this.getTaggedUsers(),
    };

    body['address'] = this.data?.item?.address;
    body['unitNo'] = this.data?.item?.unitNo;
    if (this.leadData?.title) {
      body['title'] = this.leadData?.title;
    }
    if (this.leadData?.phoneNumber) {
      body['phoneNumber'] = this.leadData?.phoneNumber;
    }

    let mainStatus = this.getMainStatus();
    if (mainStatus) body['mainStatus'] = mainStatus;

    this._loaderService.start();
    this._sharedService.addActivityLog(body, false).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          this._toastrService.success('Comment Added Successfully', '');
          this._loaderService.stop();
          this.dialogRef.close({});
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  async getTaggedUsers() {
    let mention = '@';
    let comment = this.getText(this.editor.quillEditor.getContents());

    const commentsArray = comment.replace(/\n/g, ' ').split(' ');

    let taggedUsers = [];

    for (let i = 0; i < commentsArray.length; i++) {
      const title = commentsArray[i].split('\n');
      for (let j = 0; j < title.length; j++) {
        if (title[j].charAt(0) == mention) {
          this.userListing.filter((x) => {
            if (x.firstName === commentsArray[i].substr(1)) {
              taggedUsers.push(x?._id);
            }
          });
        }
      }
    }

    const users = new Set(taggedUsers);
    return [...users];
  }

  getText(editor) {
    const { ops } = editor;

    const result = ops.reduce((acc, { insert }) => {
      const insertValue: any = insert;

      if (typeof insertValue === 'string') {
        acc += insertValue;
      } else {
        acc += `@${insertValue.mention.value} `;
      }

      return acc;
    }, '');

    return result;
  }

  getUserRoleList() {
    this._masterTaskService.getUserRoleList({ page: 1, limit: 100 }).subscribe(
      (response: ResponseModel) => {
        this.userListing = response.data.items;
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getMainStatus() {
    let mainStatus = '';
    if (
      this.data?.item?.leadData?.mainStatusId ==
      StatusConstant.LeadStatus.NEW_LEAD
    ) {
      mainStatus = 'new leads';
    } else if (
      this.data?.item?.leadData?.mainStatusId ==
      StatusConstant.LeadStatus.NO_CONTACT_MADE
    ) {
      mainStatus = 'no contact made';
    } else if (
      this.data?.item?.leadData?.mainStatusId ==
      StatusConstant.LeadStatus.CONTACT_MADE
    ) {
      mainStatus = 'contact made';
    } else if (
      this.data?.item?.leadData?.mainStatusId ==
      StatusConstant.LeadStatus.APPOINTMENT_SET
    ) {
      mainStatus = 'appointments set';
    } else if (
      this.data?.item?.leadData?.mainStatusId ==
      StatusConstant.LeadStatus.DUE_DILIGENCE
    ) {
      mainStatus = 'due diligence';
    } else if (
      this.data?.item?.leadData?.mainStatusId ==
      StatusConstant.LeadStatus.OFFER_MADE
    ) {
      mainStatus = 'offers made';
    } else if (
      this.data?.item?.leadData?.mainStatusId ==
      StatusConstant.LeadStatus.UNDER_CONTRACT
    ) {
      mainStatus = 'under contract';
    } else if (
      this.data?.item?.leadData?.mainStatusId ==
      StatusConstant.LeadStatus.ASSIGN_TO_BUYER
    ) {
      mainStatus = 'assigned to buyer';
    } else if (
      this.data?.item?.leadData?.mainStatusId ==
      StatusConstant.LeadStatus.WARM_LEAD
    ) {
      mainStatus = 'warm lead';
    } else if (
      this.data?.item?.leadData?.mainStatusId ==
      StatusConstant.LeadStatus.DEAD_LEAD
    ) {
      mainStatus = 'dead lead';
    } else if (
      this.data?.item?.leadData?.mainStatusId ==
      StatusConstant.LeadStatus.REFERRED_TO_AGENT
    ) {
      mainStatus = 'referred to agent';
    } else if (
      this.data?.item?.propertyData?.mainStatusId ==
      StatusConstant.InventoryStatus.SOLD
    ) {
      mainStatus = 'sold';
    } else if (
      this.data?.item?.propertyData?.mainStatusId ==
      StatusConstant.InventoryStatus.RENTAL
    ) {
      mainStatus = 'rental';
    } else if (
      this.data?.item?.propertyData?.mainStatusId ==
      StatusConstant.InventoryStatus.NEW_INVENTORY
    ) {
      mainStatus = 'new inventory';
    } else if (
      this.data?.item?.propertyData?.mainStatusId ==
      StatusConstant.InventoryStatus.GETTING_ESTIMATES
    ) {
      mainStatus = 'getting estimates';
    } else if (
      this.data?.item?.propertyData?.mainStatusId ==
      StatusConstant.InventoryStatus.UNDER_REHAB
    ) {
      mainStatus = 'under rehab';
    } else if (
      this.data?.item?.propertyData?.mainStatusId ==
      StatusConstant.InventoryStatus.LISTED_FOR_RENT
    ) {
      mainStatus = 'listed for rent';
    } else if (
      this.data?.item?.propertyData?.mainStatusId ==
      StatusConstant.InventoryStatus.LISTED_FOR_SALE
    ) {
      mainStatus = 'listed for sale';
    } else if (
      this.data?.item?.propertyData?.mainStatusId ==
      StatusConstant.InventoryStatus.SELL_SIDE_UNDER_CONTRACT
    ) {
      mainStatus = 'under contract';
    }
    return mainStatus;
  }

  getModuleId() {
    let moduleId = this.data?.item?.moduleId;

    if (
      this.data?.item?.leadData?.mainStatusId ==
        StatusConstant.LeadStatus.NEW_LEAD ||
      this.data?.item?.leadData?.mainStatusId ==
        StatusConstant.LeadStatus.NO_CONTACT_MADE ||
      this.data?.item?.leadData?.mainStatusId ==
        StatusConstant.LeadStatus.CONTACT_MADE ||
      this.data?.item?.leadData?.mainStatusId ==
        StatusConstant.LeadStatus.APPOINTMENT_SET ||
      this.data?.item?.leadData?.mainStatusId ==
        StatusConstant.LeadStatus.DUE_DILIGENCE ||
      this.data?.item?.leadData?.mainStatusId ==
        StatusConstant.LeadStatus.OFFER_MADE ||
      this.data?.item?.leadData?.mainStatusId ==
        StatusConstant.LeadStatus.UNDER_CONTRACT ||
      this.data?.item?.leadData?.mainStatusId ==
        StatusConstant.LeadStatus.ASSIGN_TO_BUYER ||
      this.data?.item?.leadData?.mainStatusId ==
        StatusConstant.LeadStatus.WARM_LEAD ||
      this.data?.item?.leadData?.mainStatusId ==
        StatusConstant.LeadStatus.DEAD_LEAD ||
      this.data?.item?.leadData?.mainStatusId ==
        StatusConstant.LeadStatus.REFERRED_TO_AGENT
    ) {
      moduleId = '5faa63fdfd20cd581703d255';
    } else if (
      this.data?.item?.propertyData?.mainStatusId ==
      StatusConstant.InventoryStatus.SOLD
    ) {
      moduleId = '5fea197ce1cbc5258bd3d4b7';
    } else if (
      this.data?.propertyData?.mainStatusId ==
      StatusConstant.InventoryStatus.RENTAL
    ) {
      moduleId = '5fea1988e1cbc5258bd3d4b9';
    } else if (
      this.data?.item?.propertyData?.mainStatusId ==
        StatusConstant.InventoryStatus.NEW_INVENTORY ||
      this.data?.item?.propertyData?.mainStatusId ==
        StatusConstant.InventoryStatus.GETTING_ESTIMATES ||
      this.data?.item?.propertyData?.mainStatusId ==
        StatusConstant.InventoryStatus.UNDER_REHAB ||
      this.data?.item?.propertyData?.mainStatusId ==
        StatusConstant.InventoryStatus.LISTED_FOR_RENT ||
      this.data?.item?.propertyData?.mainStatusId ==
        StatusConstant.InventoryStatus.LISTED_FOR_SALE ||
      this.data?.item?.propertyData?.mainStatusId ==
        StatusConstant.InventoryStatus.SELL_SIDE_UNDER_CONTRACT
    ) {
      moduleId = '5faa66379837ff5b02bfb365';
    }
    return moduleId;
  }
}
