import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

declare var jQuery: any;

@Component({
  selector: 'app-view-html-dialog',
  templateUrl: './view-html-dialog.component.html',
  styleUrls: ['./view-html-dialog.component.scss'],
})
export class ViewHtmlDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {
    this.data.content = this.sanitizer.bypassSecurityTrustHtml(
      this.data?.content
    );

    setTimeout(() => {
      jQuery('.re-html-container').animate({ scrollTop: 0 }, 'fast');

      jQuery('a').bind('click', ($event) => {
        if (!$event.currentTarget.getAttribute('href')) {
          $event.preventDefault();
        }
      });
    }, 100);
  }

  ngOnInit(): void {}
}
