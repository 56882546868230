import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import * as moment from 'moment-timezone';

// SERVICES
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { Router } from '@angular/router';

// COMPONENTS
import { ModalReplyCommentComponent } from 'src/app/modules/dashboard/modal-reply-comment/modal-reply-comment.component';
import { CallDetailDailogsComponent } from '../dialog/call-detail-dailogs/call-detail-dailogs.component';
import { LeadDialerComponent } from '../dialog/lead-dialer/lead-dialer.component';

// UTILS
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';

// SERVICES
import { SharedService } from '../shared.service';
import { LeadsService } from 'src/app/providers/leads/leads.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { MessageConstant } from 'src/app/utils/message-constant';
import { CreditService } from 'src/app/providers/credit/credit.service';
import { SmsDialogComponent } from '../dialog/sms-dialog/sms-dialog.component';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @ViewChild('comment') comment: any;

  dialogRef: any;

  userListing: any[] = [];
  leadInfo: any;

  leadModuleId: string = '5faa63fdfd20cd581703d255';
  buyerModuleId: string = '6007bd05b84a567a12c3bca9';
  vendorModuleId: string = '6374c9378bc3a0ee1f84bae3';

  SMSBalance: any;
  CallBalance: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _notificationDialog: MatDialogRef<NotificationComponent>,
    private _dialog: MatDialog,
    public _utils: CommonFunctionsService,
    private _sharedService: SharedService,
    private _router: Router,
    private _leadService: LeadsService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _creditService: CreditService
  ) {}

  ngOnInit(): void {
    this._sharedService.refreshActivityLog.next(true);

    if (!this.data.id) return;
    if (this.data?.data?.leadTitle)
      this.data.data['leadTitle'] = this.data.data?.leadTitle?.trim();
    if (this.data.data.numberType == 1) this.leadInfo = this.data.data;

    if (
      this.data?.data?.activityType == 9 &&
      (this.data.data.taskDetail || this.data.data.comment)
    )
      this.data.data.taskDetail = `<p><b>${this.data?.data?.comment}</b></p>${this.data?.data?.taskDetail}`;
  }

  getLeadInfo(): void {
    const obj = {
      leadId: this.data.id,
    };

    this._leadService.leadInfo(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.leadInfo = response.data;
          this.leadInfo.leadData['subModuleId'] = this.data.id;
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }

  getIcon() {
    if (this.data?.data?.activityType == 1)
      return { image: true, url: './assets/images/main-notification-sms.png' };
    else if (this.data?.data?.activityType == 4)
      return {
        image: true,
        url: './assets/images/main-notification-incoming.png',
      };
    else if (this.data?.data?.activityType == 8) {
      if (this.data?.data?.createdByUser?.profileImage)
        return {
          image: true,
          url: this.data?.data?.createdByUser?.profileImage,
        };
      else if (this.data?.data?.createdByUser?.name)
        return {
          image: false,
          url: this.getInitials(this.data?.data?.createdByUser?.name),
        };
      else
        return {
          image: true,
          url: './assets/images/main-notification-tagged.png',
        };
    } else if (this.data?.data?.activityType == 9) {
      if (this.data?.data?.createdByUser?.profileImage)
        return {
          image: true,
          url: this.data?.data?.createdByUser?.profileImage,
        };
      else if (this.data?.data?.createdByUser?.name)
        return {
          image: false,
          url: this.getInitials(this.data?.data?.createdByUser?.name),
        };
      else
        return {
          image: true,
          url: './assets/images/main-notification-task.png',
        };
    } else if (this.data?.data?.activityType == 10)
      return {
        image: true,
        url: './assets/images/main-notification-status.png',
      };
    else if (this.data?.data?.activityType == 18)
      return {
        image: true,
        url: './assets/images/main-notification-drip-stop.png',
      };
    else if (this.data?.data?.activityType == 37)
      return {
        image: true,
        url: './assets/images/main-notification-status.png',
      };
    else if (this.data?.data?.activityType == 42)
      return {
        image: true,
        url: './assets/images/main-notification-lead-zapier.png',
      };
    else if (this.data?.data?.activityType == 43)
      return {
        image: true,
        url: './assets/images/main-notification-lead-web.png',
      };
    else if (this.data?.data?.activityType == 44)
      return {
        image: true,
        url: './assets/images/main-notification-lead-assign.png',
      };
    else if (this.data?.data?.activityType == 45)
      return {
        image: true,
        url: './assets/images/main-notification-missed-call.png',
      };
    else if (this.data?.data?.activityType == 46)
      return {
        image: true,
        url: './assets/images/main-notification-status.png',
      };
    else if (this.data?.data?.activityType == 47)
      return {
        image: true,
        url: './assets/images/main-notification-lead-website.png',
      };
    else if (this.data?.data?.activityType == 49)
      return {
        image: true,
        url: './assets/images/duplicateRejected.png',
      };
    else if (this.data?.data?.activityType == 50)
      return {
        image: true,
        url: './assets/images/ClaimLead.png',
      };
    else if (this.data?.data?.activityType == 51)
      return {
        image: true,
        url: './assets/images/eSigned-open.png',
      };
    else if (this.data?.data?.activityType == 52)
      return {
        image: true,
        url: './assets/images/eSigned-partial.png',
      };
    else if (this.data?.data?.activityType == 53)
      return {
        image: true,
        url: './assets/images/eSigned-completed.png',
      };
  }

  getInitials(user) {
    let initials = user.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  getDate() {
    const timezoneOffset = localStorage.timezoneOffset || 'America/Chicago';
    const utcOffset = moment(new Date()).tz(timezoneOffset).format('Z');
    return moment.utc(new Date()).utcOffset(utcOffset).format('MMM DD hh:mm A');
  }

  async goToAddress(isLead?: boolean) {
    if (!this.data.id) return;
    await this.marAsRead(false);

    switch (this.data.data.numberType) {
      case 2:
        if (isLead) {
          this._router.navigate(['/leads/details'], {
            queryParams: { leadsId: this.data?.data?.subModuleId },
          });
        } else {
          let id = this.data?.data?.buyerId
            ? this.data?.data?.buyerId
            : this.data?.data?.subModuleId;

          window.open(`/buyers/details?buyersId=${id}`, '_blank');
          // this._router.navigate(['/buyers/details'], {
          //   queryParams: { buyersId: id },
          // });
        }
        break;

      case 4:
        if (isLead) {
          this._router.navigate(['/leads/details'], {
            queryParams: { leadsId: this.data?.data?.subModuleId },
          });
        } else {
          let id = this.data?.data?.buyerId
            ? this.data?.data?.buyerId
            : this.data?.data?.subModuleId;

          window.open(`/vendors/details?vendorsId=${id}`, '_blank');
          // this._router.navigate(['/vendors/details'], {
          //   queryParams: { vendorsId: id },
          // });
        }
        break;

      case 5:
        let id = this.data?.data?.buyerId
          ? this.data?.data?.buyerId
          : this.data?.data?.subModuleId;

        this._router.navigate(['/inventory/details'], {
          queryParams: { inventoryId: id },
        });
        break;

      case 6:
        let rentalId = this.data?.data?.buyerId
          ? this.data?.data?.buyerId
          : this.data?.data?.subModuleId;

        this._router.navigate(['/rental/details'], {
          queryParams: { rentalId: rentalId },
        });
        break;

      case 7:
        let soldId = this.data?.data?.buyerId
          ? this.data?.data?.buyerId
          : this.data?.data?.subModuleId;

        this._router.navigate(['/sold/details'], {
          queryParams: { soldId: soldId },
        });
        break;

      case 1:
        const route = this.getType(this.data.data.moduleId);
        this._router.navigateByUrl(
          `/${route?.type}/details?${route.subType}=${this.data?.data?.subModuleId}`
        );
        break;

      default:
        if (
          this.data.data.activityType == 9 &&
          this.data.data.address == 'General Business'
        )
          this._router.navigate(['/my-tasks']);
        else {
          this._router.navigate(['/leads/details'], {
            queryParams: { leadsId: this.data?.data?.subModuleId },
          });
        }

        break;
    }

    this._utils.isDialogOpen = false;
    this._utils.dialogcurrentLeadId = '';
    this._notificationDialog.close();
  }

  getType(moduleId) {
    if (moduleId === '5faa63fdfd20cd581703d255') {
      return { type: 'leads', subType: 'leadsId' };
    } else if (moduleId === '5faa66379837ff5b02bfb365') {
      return { type: 'inventory', subType: 'inventoryId' };
    } else if (moduleId === '5fea1988e1cbc5258bd3d4b9') {
      return { type: 'rental', subType: 'rentalId' };
    } else if (moduleId === '5fea197ce1cbc5258bd3d4b7') {
      return { type: 'sold', subType: 'soldId' };
    } else {
      return { type: 'leads', subType: 'leadsId' };
    }
  }

  async reply() {
    await this.marAsRead(false);
    if (this.data.data.activityType === 8) {
      const name = this.data?.data?.createdByUser?.name?.split(' ');

      this.dialogRef = this._dialog.open(ModalReplyCommentComponent, {
        width: '600px',
        data: {
          item: {
            ...this.data?.data,
            comment: this.data?.data?.message || this.data?.data?.comment,
            ...{
              firstName: name[0] || '',
              lastName: name[1] || '',
              email: this.data?.data?.createdByUser?.email,
              direction: 'comment',
              leadData: {
                leadId: this.data?.data?.subModuleId,
                mainStatusId: this.data?.data?.mainStatusId,
              },
            },
          },
        },
      });
    } else {
      this._creditService.getSMSCredit({}).subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          if (response?.statusCode == 200) {
            this.SMSBalance = response.data;

            if (this.data.data.numberType == 1) this.leadReply();
            if (this.data.data.numberType == 2) this.buyerReply();
            if (this.data.data.numberType == 4) this.vendorReply();
          } else {
            this._toastrService.error(response?.message, '');
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(MessageConstant.unknownError, '');
          }
        }
      );
    }
  }

  leadReply() {
    if (!this.leadInfo?.leadData?.secondaryContact)
      this.leadInfo.leadData.secondaryContact = [];

    if (
      this.leadInfo?.leadData?.email ||
      this.leadInfo?.leadData?.title ||
      this.leadInfo?.leadData?.phoneNumber
    ) {
      const obj = {
        email: this.leadInfo?.leadData?.email,
        name: this.leadInfo?.leadData?.title,
        phoneNumber: this.leadInfo?.leadData?.phoneNumber,
        contactType: 'seller',
        isOwnerContact: true,
        numberType: this.leadInfo?.numberType,
      };
      if (this.leadInfo?.leadData && this.leadInfo.leadData?.secondaryContact)
        this.leadInfo.leadData.secondaryContact.unshift(obj);
    }

    if (!this.leadInfo?.leadData?.secondaryContact)
      this.leadInfo.leadData.secondaryContact = [];

    let sellerFilterArray = this.leadInfo?.leadData?.secondaryContact.filter(
      (x) => {
        return x.phoneNumber === this.leadInfo?.leadData?.phoneNumber;
      }
    );

    let contact = {};
    if (sellerFilterArray.length > 0) {
      let sellerObj = sellerFilterArray[0];
      contact = {
        name: sellerObj?.name ? sellerObj?.name : '',
        contactType: sellerObj?.contactType,
        phoneNumber: sellerObj?.phoneNumber,
      };
    } else {
      let buyerFilterArray = this.leadInfo?.leadData?.buyers.filter((x) => {
        return x.contactNumber === this.leadInfo?.leadData?.phoneNumber;
      });
      if (buyerFilterArray.length > 0) {
        let buyerObj = buyerFilterArray[0];
        contact = {
          name: buyerObj?.firstName ? buyerObj?.firstName : '',
          contactType: buyerObj?.contactType,
          phoneNumber: buyerObj?.contactNumber,
        };
      }
    }

    let obj = {
      SMSBalance: this.SMSBalance,
      name: this.leadInfo?.firstName,
      phoneNumber: this.leadInfo?.leadData?.phoneNumber,
      leadSource: this.leadInfo?.marketingNumber,
      userId: this.leadInfo?.createdByUser?._id,
      leadId: this.data.id,
      contacts: [contact],
      moduleId: this.leadModuleId,
      changeNumber: false,
      addressLead: this.leadInfo?.leadData?.address
        ? this._utils.formatAddress(
            this.leadInfo?.leadData?.address,
            this.leadInfo?.unitNo
          )
        : ' ',
      address: this.leadInfo?.leadData?.address
        ? this._utils.formatAddress(
            this.leadInfo?.leadData?.address,
            this.leadInfo?.unitNo
          )
        : ' ',
    };

    this.dialogRef = this._dialog.open(SmsDialogComponent, {
      width: '500px',
      data: obj,
    });
  }

  buyerReply(type?) {
    const data = {
      page: 1,
      limit: 1000,
      numTypeId: 2,
    };
    this._loaderService.start();
    this._sharedService.getnumbers(data).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          if (!response.data?.items?.length) {
            this._toastrService.info(MessageConstant.noNumber, '', {
              enableHtml: true,
            });
            this._loaderService.stop();
            return;
          }

          if (type) this.buyerCall(response.data?.items);
          else this.buyerSMSDialog(response.data?.items);
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        this._toastrService.error(
          err.message ? err.message : MessageConstant.unknownError
        );
      }
    );
  }

  buyerSMSDialog(numbers) {
    let contact = {
      name: this.data?.data?.leadTitle || '',
      contactType: 'buyer',
      phoneNumber: this.data.data?.from,
    };

    let obj = {
      SMSBalance: this.SMSBalance,
      name: this.data.data?.leadTitle || '',
      phoneNumber: this.data.data?.from,
      leadSource: this.data.data?.to,
      userId: this.data.data?.createdBy,
      leadId: this.data.data?.subModuleId || '',
      contacts: [contact],
      moduleId: this.data.data?.moduleId || '',
      changeNumber: false,
      addressLead: this.data.data?.address
        ? this._utils.formatAddress(
            this.data.data?.address,
            this.data.data?.unitNumber
          )
        : ' ',
      address: this.data.data?.address
        ? this._utils.formatAddress(
            this.data.data?.address,
            this.data.data?.unitNo
          )
        : ' ',
    };

    if (this.data.data?.numberType == 2) {
      obj['moduleType'] = 'Buyer';
      obj['buyerId'] = this.data.data?.subModuleId || '';
      obj['fromBuyerModule'] = true;
      obj['leadSource'] = numbers || [];
      obj['defaultSmsNumber'] = this.data.data?.to || '';
      delete obj['leadId'];
      delete obj['addressLead'];
    }

    this.dialogRef = this._dialog.open(SmsDialogComponent, {
      width: '500px',
      data: obj,
    });
  }

  vendorReply(type?) {
    const data = {
      page: 1,
      limit: 1000,
      numTypeId: 4,
    };
    this._loaderService.start();
    this._sharedService.getManagenumbers(data).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          this._loaderService.stop();

          if (!response.data?.items?.length) {
            this._toastrService.info(
              MessageConstant.noNumber
                .replace(/Buyers/g, 'Vendors')
                .replace(/buyers/g, 'vendors'),
              '',
              {
                enableHtml: true,
              }
            );
            return;
          }

          if (type === 'CALL') {
            this.vendorCall(response.data?.items);
          } else {
            this.vendorSMSDialog(response.data?.items);
          }
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        this._toastrService.error(
          err.message ? err.message : MessageConstant.unknownError
        );
      }
    );
  }

  vendorSMSDialog(numbers) {
    let contact = {
      name: this.data?.data?.leadTitle || '',
      contactType: 'vendor',
      phoneNumber: this.data.data?.from,
    };

    let obj = {
      SMSBalance: this.SMSBalance,
      name: this.data.data?.leadTitle || '',
      phoneNumber: this.data.data?.from,
      leadSource: this.data.data?.to,
      userId: this.data.data?.createdBy,
      leadId: this.data.data?.subModuleId || '',
      contacts: [contact],
      moduleId: this.data.data?.moduleId || '',
      changeNumber: false,
      addressLead: this.data.data?.address
        ? this._utils.formatAddress(
            this.data.data?.address,
            this.data.data?.unitNumber
          )
        : ' ',
      address: this.data.data?.address
        ? this._utils.formatAddress(
            this.data.data?.address,
            this.data.data?.unitNo
          )
        : ' ',
    };

    obj['moduleType'] = 'Vendor';
    obj['vendorId'] = this.data.data?.subModuleId || '';
    obj['leadSource'] = numbers || [];
    obj['defaultSmsNumber'] = this.data.data?.to || '';
    delete obj['leadId'];
    delete obj['addressLead'];

    this.dialogRef = this._dialog.open(SmsDialogComponent, {
      width: '500px',
      data: obj,
    });
  }

  async call() {
    await this.marAsRead(false);
    this._loaderService.start();
    this._creditService.getCallCredit({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.CallBalance = response.data;
          if (this.data.data.numberType == 1) this.leadCall();
          if (this.data.data.numberType == 2) this.buyerReply('CALL');
          if (this.data.data.numberType == 4) this.vendorReply('CALL');
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }

  leadCall() {
    let obj = {
      callBalance: this.CallBalance,
      name: this.leadInfo?.firstName,
      phoneNumber: this.leadInfo?.from,
      leadSource: this.leadInfo?.marketingNumber,
      userId: this.leadInfo.createdBy,
      leadId: this.data.id,
      moduleId: this.leadModuleId,
    };
    if (this._utils.userAccessData?.isDialerAccess) {
      if (this._utils.isDialogOpen) {
        if (this._utils.dialogcurrentLeadId == this.data.id) {
          this._sharedService.refreshDialerToggleDisplay.next(true);
        } else {
          this._toastrService.warning(MessageConstant.callUnderProgress);
          this._loaderService.stop();
        }
        return false;
      }

      if (this.CallBalance <= 0) {
        this.dialogRef = this._dialog.open(CallDetailDailogsComponent, {
          width: '400px',
          data: obj,
          disableClose: true,
          closeOnNavigation: false,
          backdropClass: 'popup-Backdrop-Class-dialer',
        });
      } else {
        this._utils.isDialogOpen = true;
        this._utils.dialogcurrentLeadId = this.data.id;
        this.dialogRef = this._dialog.open(LeadDialerComponent, {
          width: '1366px',
          id: this.data.id,
          disableClose: true,
          closeOnNavigation: false,
          backdropClass: 'popup-Backdrop-Class-dialer',
          data: {
            leadId: this.data.id,
            isHideNext: false,
          },
        });
        this.dialogRef.afterClosed().subscribe((result) => {
          this._utils.isDialogOpen = false;
          if (result?.isEdit) {
          }
        });
      }
    } else {
      //===== ELSE
      if (this._utils.isDialogOpen) {
        if (this._utils.dialogcurrentLeadId == this.data.id) {
          this._sharedService.refreshCallerToggleDisplay.next(true);
          return false;
        } else {
          this._toastrService.warning(MessageConstant.callUnderProgress);
          this._loaderService.stop();
          return false;
        }
      }
      this._utils.isDialogOpen = true;
      this._utils.dialogcurrentLeadId = this.data.id;
      this.dialogRef = this._dialog.open(CallDetailDailogsComponent, {
        width: '400px',
        data: obj,
        disableClose: true,
        closeOnNavigation: false,
        backdropClass: 'popup-Backdrop-Class-dialer',
      });

      //===== ELSE
    }
  }

  buyerCall(numbers) {
    let obj = {
      callBalance: this.CallBalance,
      name: this.data?.data?.leadTitle || 'N/A',
      phoneNumber: this.data?.data?.from,
      leadSource: this.data?.data?.to,
      userId: this.data?.data.createdBy || '',
      leadId: this.data?.data?.subModuleId || '',
      moduleId: this.data?.data?.moduleId || '',
    };

    obj['moduleType'] = 'Buyer';
    obj['buyerId'] = this.data?.data?.subModuleId || '';
    obj['leadSource'] = numbers || [];
    delete obj['leadId'];

    if (this._utils.isDialogOpen) {
      if (this._utils.dialogcurrentLeadId == this.data?.data?.subModuleId) {
        this._sharedService.refreshCallerToggleDisplay.next(true);
        return false;
      } else {
        this._toastrService.warning(MessageConstant.callUnderProgress);
        this._loaderService.stop();
        return false;
      }
    }
    this._utils.isDialogOpen = true;
    this._utils.dialogcurrentLeadId = this.data?.data?.subModuleId;
    this.dialogRef = this._dialog.open(CallDetailDailogsComponent, {
      width: '400px',
      data: obj,
      disableClose: true,
      closeOnNavigation: false,
      backdropClass: 'popup-Backdrop-Class-dialer',
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      this._utils.isDialogOpen = false;
    });
  }

  vendorCall(numbers) {
    let obj = {
      callBalance: this.CallBalance,
      name: this.data?.data?.leadTitle || 'N/A',
      phoneNumber: this.data?.data?.from,
      leadSource: this.data?.data?.to,
      userId: this.data?.data.createdBy || '',
      leadId: this.data?.data?.subModuleId || '',
      moduleId: this.data?.data?.moduleId || '',
    };

    obj['moduleType'] = 'Vendor';
    obj['vendorId'] = this.data?.data?.subModuleId || '';
    obj['leadSource'] = numbers || [];
    delete obj['leadId'];

    if (this._utils.isDialogOpen) {
      if (this._utils.dialogcurrentLeadId == this.data?.data?.subModuleId) {
        this._sharedService.refreshCallerToggleDisplay.next(true);
        return false;
      } else {
        this._toastrService.warning(MessageConstant.callUnderProgress);
        this._loaderService.stop();
        return false;
      }
    }
    this._utils.isDialogOpen = true;
    this._utils.dialogcurrentLeadId = this.data?.data?.subModuleId;
    this.dialogRef = this._dialog.open(CallDetailDailogsComponent, {
      width: '400px',
      data: obj,
      disableClose: true,
      closeOnNavigation: false,
      backdropClass: 'popup-Backdrop-Class-dialer',
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      this._utils.isDialogOpen = false;
    });
  }

  marAsRead(isCloseModal = true) {
    return new Promise((resolve, reject) => {
      const obj = {
        isReadMessage: true,
        notificationIds: [this.data?.data?.notificationId],
      };

      this._loaderService.start();
      this._sharedService.markNotificationAsRead(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this._loaderService.stop();
            this._utils.isDialogOpen = false;
            this._utils.dialogcurrentLeadId = '';
            this._sharedService.refreshActivityLog.next(true);
            this._sharedService.refreshNotificationCount.next(true);
            if (isCloseModal) {
              this._toastrService.success('Marked as read');
              this._notificationDialog.close();
            }

            return resolve(true);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(MessageConstant.unknownError, '');
          }

          return resolve(true);
        }
      );
    });
  }

  getHighlightedData(notification) {
    let content = '';
    if (this.data?.data?.activityType == 1) return '';
    else if (this.data?.data?.activityType == 4) return '';
    else if (
      this.data?.data?.activityType == 8 ||
      this.data?.data?.activityType == 9
    )
      return `By ${notification?.data?.createdByUser?.name || 'N/A'} •`;
    else if (
      this.data?.data?.activityType == 10 ||
      this.data?.data?.activityType == 18
    ) {
      content = `${notification?.data?.createdByUser?.name || 'N/A'}`;
      if (!notification?.data?.isFromDripCam) content += ` •`;
      return content;
    } else if (this.data?.data?.activityType == 37) return '';
    else if (this.data?.data?.activityType == 42)
      return `${notification?.message || ''} •`;
    else if (this.data?.data?.activityType == 43)
      return `${notification?.message || ''} •`;
    else if (this.data?.data?.activityType == 44) return '';
    else if (this.data?.data?.activityType == 45) return '';
    else if (this.data?.data?.activityType == 46) return '';
    else if (this.data?.data?.activityType == 47)
      return `${notification?.message || ''} •`;
    else if (this.data?.data?.activityType == 49)
      return `${notification?.message || ''} •`;
    else if (this.data?.data?.activityType == 50) return ``;
    else if (this.data?.data?.activityType == 51)
      return `${
        this._utils.titleCaseToWord(this.data?.data?.leadTitle) || 'N/A'
      } •`;
    else if (this.data?.data?.activityType == 52)
      return `${
        this._utils.titleCaseToWord(this.data?.data?.leadTitle) || 'N/A'
      } •`;
    else if (this.data?.data?.activityType == 53)
      return `${
        this._utils.titleCaseToWord(this.data?.data?.leadTitle) || 'N/A'
      } •`;
    return 'N/A •';
  }

  redirectText() {
    if (this.data.data.numberType == 2) return 'See Buyer Details';
    else if (this.data.data.numberType == 4) return 'See Vendor Details';
    else {
      if (this.data.data?.address)
        return this._utils.formatAddress(this.data.data?.address);
      else return 'See Lead Details';
    }
  }

  replaceLineBreak(name: string) {
    return name;
  }
}
