import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrl } from '../../utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
@Injectable({
  providedIn: 'root',
})
export class MarketsService {
  public baseUrl = environment.baseUrl;
  public token = localStorage.getItem('token');

  constructor(private _commonFunction: CommonFunctionsService) {}

  getMarkets = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.marketUrl + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getMarketsWithNumbers = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.marketUrl + 'listWithNumber';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  addMarket = (data, _id?): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.marketUrl;

    if (_id) {
      endpoint += 'edit';
    } else {
      endpoint += 'save';
    }
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteMarket = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.marketUrl + 'delete';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getListUserWise = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.marketUrl + 'getListUserWise';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getMarketsV2 = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.marketV2Url + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };
}
