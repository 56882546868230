<div class="re-add-account-modal">
  <div class="dialog-header">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between"
        style="display: block !important"
      >
        {{ isEdit ? "Save Account Tag" : "Add Account Tag" }}
      </h2>
      <span matDialogClose mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>
  <form autocomplete="off" novalidate [formGroup]="accountTagDataForm">
    <mat-dialog-content class="re-typography">
      <div class="container-fluid p-0">
        <div class="row">
          <!-- TEXT -->
          <div class="col-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label class="re-labels"
                >Name <span class="text-danger">*</span>
              </label>
              <input
                type="text"
                class="form-control"
                name="name"
                placeholder="Enter name here"
                formControlName="name"
              />
              <div class="invalid-feedback">
                <span *ngIf="submitted && hasError('name', 'required')"
                  >Account Tag Name is required
                </span>
              </div>
            </div>
          </div>

          <!-- DROPDOWN -->
          <div class="col-12 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="re-labels"
                >Account Type <span class="text-danger">*</span>
              </label>
              <select
                name="contactType"
                class="form-control"
                formControlName="categoryId"
                (change)="
                  getAccountTagSubcategory(
                    accountTagDataForm.controls.categoryId.value
                  )
                "
              >
                <option selected value="">Select Type</option>
                <option
                  *ngFor="let category of categoryData"
                  [ngValue]="category?._id"
                >
                  {{ category?.name }}
                </option>
              </select>
              <div class="invalid-feedback">
                <span *ngIf="submitted && hasError('categoryId', 'required')"
                  >Account Type is required
                </span>
              </div>
            </div>
          </div>

          <!-- DROPDOWN -->
          <div
            class="col-12 col-md-6 col-sm-12"
            *ngIf="subcategoryData.length > 0"
          >
            <div class="form-group">
              <label class="re-labels">Category</label>
              <select
                name="contactType"
                class="form-control"
                formControlName="subcategoryId"
                (change)="
                  getChildCategory(
                    accountTagDataForm.controls.subcategoryId.value
                  )
                "
              >
                <option selected value="">Select Sub Category</option>
                <option
                  *ngFor="let category of subcategoryData"
                  [ngValue]="category?._id"
                >
                  {{ category?.name }}
                </option>
              </select>
              <div class="invalid-feedback"></div>
            </div>
          </div>

          <!-- DROPDOWN -->
          <div
            class="col-12 col-md-6 col-sm-12"
            *ngIf="childCategoryData.length > 0"
          >
            <div class="form-group">
              <label class="re-labels">Sub Category</label>
              <select
                name="contactType"
                class="form-control"
                formControlName="childCategoryId"
              >
                <option selected value="">Select Child Category</option>
                <option
                  *ngFor="let category of childCategoryData"
                  [ngValue]="category?._id"
                >
                  {{ category?.answer || category?.name }}
                </option>
              </select>
              <div class="invalid-feedback"></div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <hr style="margin: 0 0 8px 0 !important" />
    <mat-dialog-actions align="center">
      <button mat-raised-button mat-dialog-close (click)="cancel()">
        Cancel
      </button>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        cdkFocusInitial
        (click)="onSubmit()"
      >
        {{ submitButton }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
