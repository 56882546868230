import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

// SERVICES
import { SharedService } from '../../shared.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
// UTILS
import { MessageConstant } from '../../../utils/message-constant';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';

@Component({
  selector: 'app-edit-dialer-number',
  templateUrl: './edit-dialer-number.component.html',
  styleUrls: ['./edit-dialer-number.component.scss'],
})
export class EditDialerNumberComponent implements OnInit {
  messageConstant = MessageConstant;
  numberForm: FormGroup;
  groupNumberList: any[];
  submitted: boolean = false;
  flowList: any[];
  isMarketReadonly: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _sharedService: SharedService,
    private fb: FormBuilder,
    public _utilities: CommonFunctionsService,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private _editDialereDailog: MatDialogRef<EditDialerNumberComponent>
  ) {
    this.getGroupNumber();
  }

  ngOnInit(): void {
    this.getManageFlow();
    this.numberForm = this.fb.group({
      groupId: ['', Validators.required],
      flowId: ['', Validators.required],
    });
    this.numberForm.patchValue({
      groupId: this.data?.details?.groupId,
    });
  }

  ngAfterViewInit() {
    this.isMarketReadonly = true;
  }

  public get f() {
    return this.numberForm.controls;
  }
  getGroupNumber() {
    const obj = {
      page: 1,
      limit: 20000,
    };
    this._loaderService.start();
    this._sharedService.getGroupNumber(obj).subscribe(
      (response) => {
        if (response) {
          this.groupNumberList = response?.data?.items;
          this.groupNumberList.sort((a, b) => a.title.localeCompare(b.title));
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
      }
    );
  }
  onSubmit() {
    this.submitted = true;
    if (this.numberForm.invalid) {
      return;
    }
    let obj = {
      marketingTitle: this.data.details.marketingTitle,
      crmQuestionId: this.data.details.crmQuestionId,
      marketId: this.data.details.marketId,
      number: this.data.details.number,
      ...this.numberForm.value,
    };
    this._loaderService.start();
    this._sharedService.updateAssignment(obj).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          this._toastrService.success(this.messageConstant.numberUpdated);
          this._editDialereDailog.close(obj);
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  getManageFlow() {
    const obj = {
      page: 1,
      limit: 2000,
    };

    this._sharedService.getManageFlow(obj).subscribe(
      (response) => {
        if (response) {
          this.flowList = response?.data?.items;
          this.flowList.sort((a, b) => a.name.localeCompare(b.name));
          for (let i = 0; i < this.flowList.length; i++) {
            if (this.flowList[i]['_id'] == this.data?.details?.flow?._id) {
              this.numberForm.patchValue({
                flowId: this.flowList[i]['_id'],
              });
              break;
            }
          }
        }
      },
      (err: ErrorModel) => {}
    );
  }
}
