<div class="re-test-emaildialog">
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between re-header"
        style="display: block !important; padding: 0 !important"
      >
        <span style="padding-top: 8px !important; display: inline-block">
          Verify Email
        </span>
        <span mat-dialog-close style="float: right">
          <img
            src="assets/images/close-white.png"
            alt="close"
            class="m-top"
            style="padding-right: 5px; margin-top: 15px"
          />
        </span>
      </h2>
    </div>
  </div>

  <form
    [formGroup]="userForm"
    (ngSubmit)="onSubmit()"
    autocomplete="off"
    novalidate
  >
    <mat-dialog-content class="re-typography">
      <div class="container p-0">
        <div class="re-box">
          <div class="row mt-3">
            <!-- FIRST NAME -->
            <div class="col-12 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="label required">First Name</label>
                <input
                  formControlName="firstName"
                  type="text"
                  class="form-control"
                  placeholder="First Name"
                />

                <div class="text-danger">
                  <span *ngIf="submitted && hasError('firstName', 'required')">
                    <span>
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "First Name"
                        )
                      }}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <!-- LAST NAME -->
            <div class="col-12 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="label required">Last Name</label>
                <input
                  formControlName="lastName"
                  type="text"
                  class="form-control"
                  placeholder="Last Name"
                />

                <div class="text-danger">
                  <span *ngIf="submitted && hasError('lastName', 'required')">
                    <span>
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Last Name"
                        )
                      }}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <!-- EMAIL -->
            <div class="col-12 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="label required">Email</label>
                <input
                  formControlName="email"
                  type="text"
                  class="form-control"
                  placeholder="Email"
                />

                <div class="text-danger">
                  <span *ngIf="submitted && hasError('email', 'required')">
                    <span>
                      {{
                        messageConstant.requiredField.replace("Field", "Email")
                      }}
                    </span>
                  </span>

                  <span *ngIf="hasError('email', 'pattern')">
                    <span>{{ messageConstant?.invalidEmail }}</span>
                  </span>
                </div>
              </div>
            </div>

            <!-- CONTACT NUMBER -->
            <div class="col-12 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="label required">Contact Number</label>
                <input
                  formControlName="contactNumber"
                  type="text"
                  class="form-control"
                  mask="(000) 000-0000"
                  placeholder="Contact Number"
                />

                <div class="text-danger">
                  <span
                    *ngIf="submitted && hasError('contactNumber', 'required')"
                  >
                    <span>
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Contact Number"
                        )
                      }}
                    </span>
                  </span>

                  <span
                    *ngIf="submitted && hasError('contactNumber', 'pattern')"
                  >
                    {{ messageConstant.validFieldValue }} phone number
                  </span>
                </div>
              </div>
            </div>

            <!-- COMPANY -->
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="label required">Company</label>
                <input
                  formControlName="company"
                  type="text"
                  class="form-control"
                  placeholder="Company"
                />

                <div class="text-danger">
                  <span *ngIf="submitted && hasError('company', 'required')">
                    <span>
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Company"
                        )
                      }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end" style="padding-top: 14px !important">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" cdkFocusInitial type="submit">
        Submit
      </button>
    </mat-dialog-actions>
  </form>
</div>
