import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { UploadOutput, UploadInput } from 'ngx-uploader';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { MatMenu } from '@angular/material/menu';
import { Subject, Observable, Subscription } from 'rxjs';
import { filter, debounceTime } from 'rxjs/operators';

// UTILS
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { BaseUrl } from 'src/app/utils/base-url-constants';
import { MessageConstant } from 'src/app/utils/message-constant';
import { FilterListModel } from 'src/app/utils/models/filter';
import { timezoneConstant } from 'src/app/utils/timezone-list-constant';

// SERVICES
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { EsignService } from '../../providers/esign/esign.service';
import { SharedService } from '../shared.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { CreditService } from 'src/app/providers/credit/credit.service';
import { UserService } from 'src/app/providers/user/user.service';
import { ListStackingService } from '../../providers/list-stacking/list-stacking.service';

// COMPONENTS
import { AddEditRvmComponent } from './../../shared/dialog/add-edit-rvm/add-edit-rvm.component';
import { AddEditEmailTemplateDailogComponent } from '../dialog/emails-dailog/add-edit-email-template-dailog/add-edit-email-template-dailog.component';
import { AddGlobalTagsComponent } from '../dialog/add-global-tags/add-global-tags.component';
import { ModalAddVendorsComponent } from '../../modules/accounting/vendors/modal-add-vendors/modal-add-vendors.component';
import { ModalAddAccountTagsComponent } from '../../modules/accounting/account-tags/modal-add-account-tags/modal-add-account-tags.component';
import { CalendarLinkComponent } from '../dialog/calendar-link/calendar-link.component';
import { AddEditAppointmentsComponent } from 'src/app/modules/leads/leads-details/task/add-edit-appointments/add-edit-appointments.component';
import { SetAvailabilityDialogComponent } from '../dialog/set-availability-dialog/set-availability-dialog.component';
import { ConfirmationDialogComponent } from '../dialog/confirmation-dialog/confirmation-dialog.component';
import { DialerChangeCampaignComponent } from '../dialog/dialer-change-campaign/dialer-change-campaign.component';
import { AddCardDialogComponent } from '../dialog/add-card-dialog/add-card-dialog.component';
import { SendBulkSmsDialogComponent } from '../dialog/send-bulk-sms-dialog/send-bulk-sms-dialog.component';
import { AddEditSmsTemplateComponent } from '../dialog/add-edit-sms-template/add-edit-sms-template.component';
import { SendBulkEmailDialogComponent } from '../dialog/send-bulk-email-dialog/send-bulk-email-dialog.component';
import { ColdCallSettingComponent } from '../dialog/cold-call-setting/cold-call-setting.component';
import { DeleteDialogComponent } from '../../shared/dialog/delete-dialog/delete-dialog.component';
import { ExportProspectComponent } from './../../shared/dialog/export-prospect/export-prospect.component';
import { ListExportMarketingComponent } from 'src/app/shared/dialog/list-export-marketing/list-export-marketing.component';
import { AddTagListSkipComponent } from './../../shared/dialog/add-tag-list-skip/add-tag-list-skip.component';
import { RemoveTagsComponent } from 'src/app/shared/dialog/remove-tags/remove-tags.component';
import { FiltersComponent } from '../filters/filters.component';
import { SendDialerComponent } from './../../shared/dialog/send-dialer/send-dialer.component';
import { AddEditGroupComponent } from '../dialog/add-edit-group/add-edit-group.component';
import { AddEditTaskTemplateComponent } from '../dialog/add-edit-task-template/add-edit-task-template.component';
import { DialerFilterComponent } from '../dialer-filter/dialer-filter.component';
import { RemoveMarketDetailsComponent } from 'src/app/shared/dialog/remove-market-details/remove-market-details.component';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  subject: Subject<any> = new Subject();

  @HostListener('window:click', ['$event.target'])
  checkActionModal($event) {
    const selectField = document.getElementById('re-select-field');
    if (selectField) {
      const isClickInside = selectField.contains(<HTMLInputElement>$event);

      if (!isClickInside) {
        this.isMenuVisible = false;
      }
    }
  }

  @ViewChild('actionDropdown') actionDropdown: ElementRef;
  @ViewChild('uploader', { read: ElementRef }) fileInput: ElementRef;
  @ViewChild('dropdownFilter') dropdownFilter: FiltersComponent;
  @ViewChild('DialerDropdownFilter')
  DialerDropdownFilter: DialerFilterComponent;
  @ViewChild('DialerDropdownCallLogFilter')
  DialerDropdownCallLogFilter: DialerFilterComponent;
  @ViewChild('DialerDropdownScheduledCallFilter')
  DialerDropdownScheduledCallFilter: DialerFilterComponent;
  @ViewChild('menu') menu!: MatMenu;

  @Input() moduleIcon: string;
  @Input() moduleName: string;
  @Input() currentModule: string;
  @Input() temModuleName: string;
  @Input() subModuleName: string;
  @Input() subModuleNameDetails: string;
  @Input() moduleAddName: string;
  @Input() moduleId: string;
  @Input() addText: string;
  @Input() syncedUserEmail: string = '';
  @Input() isRedirectUrl: string;
  @Input() linkTitle: string = '';
  @Input() linkRedirect: string = '';
  @Input() linkAddress: string = '';
  @Input() linkLeadId: string = '';
  @Input() linkPropertyId: string = '';
  @Input() tagType: string = '';
  @Input() pastDue: string = '';
  @Input() dueToday: string = '';
  @Input() dueLater: string = '';
  @Input() selectActionType: string = '';
  @Input() webFormHeader: string = '';

  @Input() currentLimit: number = 0;
  @Input() currentPage: number = 0;
  @Input() totalRecord: number = 0;
  @Input() currentCampaignPage: number = 1;
  @Input() todayCallPage: number = 1;
  @Input() scheduledCallPage: number = 1;
  @Input() inboxCallPage: number = 1;
  @Input() campaignDetails: any;
  @Input() latestCampaign: any = {};
  @Input() secondaryData: any[] = [];
  @Input() currentUser: any;
  @Input() moduleData: any;
  @Input() mainUserId: any;
  @Input() action: any[] = [];
  @Input() users: any[] = [];
  @Input() selectedItems: any[] = [];
  @Input() filters: FilterListModel[] = [];
  @Input() appliedFilters: any = {};
  @Input() filterActive: any;
  @Input() filterObj: any = {};
  @Input() searchStr: string = '';
  @Input() filesInProgress: any[] = [];
  @Input() additionalActions: any[] = [];
  @Input() dilerFilters: FilterListModel[] = [];
  @Input() dialerCallLogFilters: FilterListModel[] = [];
  @Input() dialerScheduledCallFilters: FilterListModel[] = [];
  @Input() appliedDialFilters: any = {};

  @Input() isViewEnabled: boolean = false;
  @Input() moduleNameToolTip: boolean = false;
  @Input() isSortEnabled: boolean = false;
  @Input() isFilterEnabled: boolean = false;
  @Input() isDropdownFilterEnabled: boolean = false;
  @Input() isDailerDropdownFilterEnabled: boolean = false;
  @Input() isDailerCallLogDropdownFilterEnabled: boolean = false;
  @Input() isDailerScheduledCallFilterEnabled: boolean = false;
  @Input() isScheduledCallMainUser: boolean = false;
  @Input() isStatsFilterMainUser: boolean = false;
  @Input() isFilter: boolean = false;
  @Input() isActionSMSEmail: boolean = false;
  @Input() isImportEnabled: boolean = false;
  @Input() isSearchEnabled: boolean = false;
  @Input() isReduceGrid: boolean = false;
  @Input() isAddEnabled: boolean = true;
  @Input() isCallEnabled: boolean = false;
  @Input() isGroupEnabled: boolean = false;
  @Input() isAddCustomSection: boolean = false;
  @Input() isAddModal: boolean = false;
  @Input() isModuleCountEnabled: boolean = false;
  @Input() isAccountList: boolean = false;
  @Input() isModuleAdd: boolean = true;
  @Input() isRefreshEnabled: boolean = false;
  @Input() isActionEnabled: boolean = false;
  @Input() isCalendarEnabled: boolean = false;
  @Input() isSearchBarEnabled: boolean = false;
  @Input() isCallLogArchived: boolean = false;
  @Input() isActionDropDownEnabled: boolean = false;
  @Input() isUserTimeZoneEnabled: boolean = false;
  @Input() isEmailSyncEnabled: boolean = false;
  @Input() isCancelShow: boolean = false;
  @Input() isSelectBarEnabled: boolean = false;
  @Input() isActionBarEnabled: boolean = false;
  @Input() toggleActionBar: boolean = false;
  @Input() isBulkFeatureEnabled: boolean = false;
  @Input() toggleBulkFeature: boolean = false;
  @Input() isBulkSMSEnabled: boolean = false;
  @Input() isBulkEmailEnabled: boolean = false;
  @Input() isExportEnable: boolean = false;
  @Input() campaignScheduled: boolean = false;
  @Input() inboxCallEnable: boolean = false;
  @Input() campaignId: string = '';
  @Input() campaignTodayDate: string = '';
  @Input() dialed: string = '';
  @Input() isExportEnabled: boolean = false;
  @Input() isCopyWebForm: boolean = false;
  @Input() isDownloadAction: boolean = false;
  @Input() downloadToolTip: string = '';
  @Input() isSaveFilter: boolean = true;
  @Input() isManageVacationModeEnabled: boolean = false;
  @Input() isDeleteUserEnabled: boolean = false;
  @Input() isModuleClickExists: boolean = false;
  @Input() isWhiteBoardEnable: boolean = false;
  @Input() isbackToLeadEnable: boolean = false;
  @Input() isSaveDialFilter: boolean = true;
  @Input() dialedCampaignName: boolean = true;
  @Input() isListStackingAdd: boolean = false;
  @Input() isReportExport: boolean = false;
  @Input() showViewBtn: boolean = false;
  @Input() isActionLeftBorderShow: boolean = true;
  @Input() isCancelAccountEnable: boolean = false;
  @Input() isCloseBtnVisible: boolean = false;
  @Input() linkTitleSetting: boolean = false;

  @Output() _emitter = new EventEmitter<any>();
  @Output() _callBackFunction = new EventEmitter<any>();
  @Output() _callBackFunctionDelete = new EventEmitter<any>();
  @Output() _emitView: EventEmitter<any> = new EventEmitter<any>();
  @Output() _emitSort: EventEmitter<any> = new EventEmitter<any>();
  @Output() _emitFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() _resetFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() _resetSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() _emitAddCustomSection: EventEmitter<any> = new EventEmitter<any>();
  @Output() _emitAccount: EventEmitter<any> = new EventEmitter<any>();
  @Output() _emitFilterSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() _emitSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() _emitCalendarEvents: EventEmitter<any> = new EventEmitter<any>();
  @Output() _emitRefresh: EventEmitter<any> = new EventEmitter<any>();
  @Output() _emitDuePast: EventEmitter<any> = new EventEmitter<any>();
  @Output() _emitDueToday: EventEmitter<any> = new EventEmitter<any>();
  @Output() _emitDueLater: EventEmitter<any> = new EventEmitter<any>();
  @Output() _emitClearFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() _exportData: EventEmitter<any> = new EventEmitter<any>();
  @Output() _emitSelectBuyerAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() _emitReduce: EventEmitter<any> = new EventEmitter<any>();
  @Output() _emitSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() _applyFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() _emitCopyToClipboard: EventEmitter<any> = new EventEmitter<any>();
  @Output() _applyCustomView: EventEmitter<any> = new EventEmitter<any>();
  @Output() _downloadAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() _applyDialFilter: EventEmitter<any> = new EventEmitter<any>();

  @Output() _reportExportAction: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  _showBuyerInboxMessages: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  _showVendorInboxMessages: EventEmitter<any> = new EventEmitter<any>();

  @Output() _eSignAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() _emitAddEmail: EventEmitter<any> = new EventEmitter<any>();
  @Output() _changeOngoingCall: EventEmitter<any> = new EventEmitter<any>();

  dialogRef: any;
  messageConstant = MessageConstant;
  searchDataForm: FormGroup;
  currentTimezone: any;
  timezoneList: any[] = [];
  sort: any[] = [];
  view: any[] = [
    {
      name: 'LIST',
      toolName: 'List',
      icon: '/assets/images/list',
      isActive: false,
    },
    {
      name: 'GRID',
      toolName: 'Grid',
      icon: '/assets/images/grid',
      isActive: false,
    },
  ];
  activeButton: string = 'all';
  selectedModuleId: any[] = [];
  uploadInput: EventEmitter<UploadInput>;
  uploadFiles: any[] = [];
  imageArr: any[] = [];
  originName: string = '';
  fileText: string = 'Select an pdf file (PDF files supported)';
  selectItemsCount: number = 0;
  selectedValue: string = '';
  selectedTo: number = 1;
  selectedFrom: number = 10;
  interval: any;

  toggle = false;
  isSearchOpen: boolean = false;
  isMenuVisible: boolean = false;

  isLeaderboardMenuVisible: boolean = false;
  isRevenueShow: boolean = true;
  isAvgRevenueShow: boolean = true;
  isDealCountShow: boolean = true;
  isUnderContractCountShow: boolean = true;
  isOfferMadeCountShow: boolean = true;
  isApptCountShow: boolean = true;
  isCallsShow: boolean = true;
  isSmsShow: boolean = true;
  isAvgCallDurationShow: boolean = true;
  isTotalCallDurationShow: boolean = true;
  isOngoingCall: boolean = true;

  constructor(
    private _router: Router,
    private dialog: MatDialog,
    private _esignService: EsignService,
    private _sharedService: SharedService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private _location: Location,
    public _utilities: CommonFunctionsService,
    private _creditService: CreditService,
    private _usersService: UserService,
    private _listStackingService: ListStackingService
  ) {}

  ngOnInit(): void {
    this.sort = [
      {
        name: 'DESC',
        tooltip:
          'Recently updated ' + this.moduleName.toLocaleLowerCase() + ' on top',
        icon: '/assets/images/asc',
        isActive: false,
      },
      {
        name: 'ASC',
        tooltip:
          'Recently updated ' +
          this.moduleName.toLocaleLowerCase() +
          ' at the bottom',
        icon: '/assets/images/desc',
        isActive: false,
      },
    ];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.setSort(
      this._utilities.leadSort
        ? this._utilities.leadSort
        : environment.pagination.defaultSort
    );
    this.setView(environment.pagination.defaultView);
    this.searchDataForm = this.formBuilder.group({
      searchText: [''],
    });
    this.timezoneList = timezoneConstant.timezoneArray;
    this.currentTimezone = this.timezoneList.filter(
      (x) => x.timezonename == localStorage?.timezoneOffset
    )[0]?.name;
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      this._emitFilterSearch.emit(this.searchDataForm.value.searchText);
    });
  }

  getCount() {
    if (this.moduleData?.count) {
      return this.moduleData?.count || 0;
    } else if (typeof this.moduleData === 'object') {
      let count = 0;
      Object.keys(this.moduleData).forEach((data) => {
        count += this.moduleData[data]?.count || 0;
      });
      return count;
    }
  }

  getSelectLabel() {
    return this.selectItemsCount > 0
      ? `${this.selectItemsCount} ${this.moduleName}`
      : `Select ${this.moduleName}`;
  }

  callParent(): void {
    this._callBackFunction.next();
  }

  onEmailDailog(subtype, action, id) {
    this.dialogRef = this.dialog.open(AddEditEmailTemplateDailogComponent, {
      width: '650px',
      disableClose: true,
      panelClass: 'editTemplate',
      data: {
        moduleId: id,
        type: 'email-template',
        subType: subtype,
        action: action,
        category: '',
        template: 'add-edit',
        items: this.secondaryData,
        allTitle: {
          heading: 'Create Template',
          buttonTitle: 'Save',
        },
        details: {},
      },
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data.statusCode == 200) {
        let result = data.data;
        this._emitter.emit({ module: 'email', type: 'add', result });
      }
    });
  }

  onSmsDailog(action, id) {
    this.dialogRef = this.dialog.open(AddEditSmsTemplateComponent, {
      width: '650px',
      disableClose: true,
      panelClass: 'editTemplate',
      data: {
        moduleId: id,
        action: action,
        items: this.secondaryData,
        allTitle: {
          heading: 'Create Template',
          buttonTitle: 'Save',
        },
      },
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this._emitter.emit({ module: 'sms' });
      }
    });
  }
  onTaskDailog(action) {
    this.dialogRef = this.dialog.open(AddEditTaskTemplateComponent, {
      width: '700px',
      disableClose: true,
      panelClass: 'editTemplate',
      data: {
        action: action,
        items: this.secondaryData,
        category: 'Add',
        allTitle: {
          heading: 'Create Template',
          buttonTitle: 'Save',
        },
      },
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this._emitter.emit({
          module: 'taskTemplate',
          type: 'create',
          result: data,
        });
      }
    });
  }

  callAction() {
    this.goToAddModule();
  }

  cancelAction() {
    this._location.back();
  }

  onBack() {
    this._location.back();
  }

  toggleMenu() {
    this.isMenuVisible = !this.isMenuVisible;
  }

  clearCustomSelection(bool?) {
    this.selectedValue = null;
    this.selectItemsCount = 0;
    this.selectedTo = 1;
    this.selectedFrom = this.totalRecord;

    if (!bool) this._emitRefresh.emit(true);
  }

  uploadFile(output: UploadOutput): void {
    this.fileInput.nativeElement.value = '';
    this._loaderService.start();
    if (output.type === 'allAddedToQueue') {
      // when all files added in queue
      const token = localStorage.getItem('token');
      let endpoint = environment.baseUrl + BaseUrl.sharedUrl + 'file-upload';
      endpoint += `?moduleId=6006bb0b86f61e62f34d54e1&isPublic=1`;

      const event: UploadInput = {
        type: 'uploadAll',
        url: endpoint,
        method: 'POST',
        headers: { Authorization: token },
      };
      this.uploadInput.emit(event);
    } else if (
      output.type === 'addedToQueue' &&
      typeof output.file !== 'undefined'
    ) {
      this.uploadFiles.push(output);
    } else if (output.type === 'done' && typeof output.file !== 'undefined') {
      if (output.file.response.statusCode == 200) {
        this.originName = output.file.name;
        this.fileText = this.originName;
        if (typeof output.file.response.data == 'object') {
          output.file.response.data?.filter((x) => {
            this.imageArr.push(x);
          });
        } else {
          this.imageArr = output.file.response.data;
        }
      }
      let data = {
        fileName: output.file.response.data[0].name,
        fileUrl: output.file.response.data[0].url,
      };
      this.esigngenieCreateTemplate(data);
      // this.files = output.file;
    } else if (output.type === 'dragOut') {
      // drag out event
    } else if (output.type === 'drop') {
      // on drop event
    }
  }

  esigngenieCreateTemplate(data) {
    var checkTemplateName = data.fileName.split('.');
    this._loaderService.start();
    let eSignObj = {
      searchString: checkTemplateName[0],
    };
    this._esignService.searchEsign(eSignObj).subscribe(
      (response: ResponseModel) => {
        if (response && response.statusCode == 200) {
          if (response?.data) {
            this._loaderService.stop();
            this._toastrService.error(
              this.messageConstant.eSignTemplateError,
              ''
            );
            return;
          } else {
            this._esignService.eSignCreateTemplate(data).subscribe(
              (response: ResponseModel) => {
                if (response?.statusCode && response?.statusCode == 200) {
                  let templateData = response?.data;

                  let savePaylod = {
                    templateUrl: data?.fileUrl,
                    esignUrl: templateData?.embeddedTemplateSessionURL,
                    esignSecretId: JSON.stringify(
                      templateData?.template?.templateId
                    ),
                    templateName: templateData?.template?.templateName,
                    redirectUrl:
                      environment.appUrl +
                      BaseUrl.eSignCreateTemplateRedirectLink,
                  };

                  this.saveEsign(savePaylod);
                }
              },
              (err: ErrorModel) => {
                this._loaderService.stop();
                if (err.error) {
                  const error: ResponseModel = err.error;
                  this._toastrService.error(error.message, '');
                } else {
                  this._toastrService.error(
                    this.messageConstant.unknownError,
                    ''
                  );
                }
              }
            );
          }
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  saveEsign(savePaylod) {
    this._loaderService.start();
    this._esignService.saveEsign(savePaylod).subscribe(
      (response: ResponseModel) => {
        if (response && response.statusCode == 200) {
          this._router.navigate([`/esign/createTemplate`], {
            queryParams: { id: response.data._id },
          });
        }
      },
      (err: ErrorModel) => {}
    );
  }

  setSort(type, val?) {
    const currentSort = this.sort.filter((x) => x.isActive);
    if (currentSort?.length && currentSort[0]?.name === type) {
      return;
    }

    const index = this.sort.findIndex((x) => x.name == type);

    if (index > -1) {
      this.sort.map((x) => (x.isActive = false));
      this.sort[index].isActive = true;
      if (val) this._emitSort.emit(type);
    }
  }

  setGridView(type) {
    if (type != this._utilities.reduceGrid) {
      this._emitReduce.emit(type);
    }
  }

  setView(type, val?) {
    const currentView = this.view.filter((x) => x.isActive);
    if (currentView?.length && currentView[0]?.name === type) {
      return;
    }

    const index = this.view.findIndex((x) => x.name == type);

    if (index > -1) {
      this.view.map((x) => (x.isActive = false));
      this.view[index].isActive = true;
      if (val) this._emitView.emit(type);
    }
  }

  openFilterModal() {
    this.clearCustomSelection();
    this._emitFilter.emit(this.moduleName);
  }

  searchDuePast() {
    this._emitDuePast.emit(this.moduleName);
  }

  searchDueToday() {
    this._emitDueToday.emit(this.moduleName);
  }

  searchDueLater() {
    this._emitDueLater.emit(this.moduleName);
  }

  clearFilter() {
    this._emitClearFilter.emit();
  }

  openSearchModal() {
    this.clearCustomSelection();
    this._emitSearch.emit(this.moduleName);
  }

  expandSearch() {
    this.isSearchOpen = !this.isSearchOpen;
  }

  onSearchSubmit() {
    let searchVal = '';
    if (this.searchDataForm.value) {
      searchVal = this.searchDataForm.value.searchText;
    }
    this._emitFilterSearch.emit(searchVal);
  }
  onSearchCallLog() {
    this.subject.next();
  }

  resetFilter() {
    this.clearCustomSelection();
    if (this.dropdownFilter) this.dropdownFilter.reset();
    if (this.DialerDropdownFilter) this.DialerDropdownFilter.reset();
    if (this.DialerDropdownCallLogFilter)
      this.DialerDropdownCallLogFilter.reset();
    if (this.DialerDropdownScheduledCallFilter)
      this.DialerDropdownScheduledCallFilter.reset();
    this._resetFilter.emit();
  }

  resetSearch() {
    this.isSearchOpen = false;
    this.searchDataForm.patchValue({
      searchText: '',
    });
    this._resetSearch.emit();
  }
  callActionGroup() {
    if (this.moduleName == 'Manage Numbers') {
      this.onAddGroup();
    }
  }
  goToAddModule() {
    if (typeof this.isAddModal !== 'undefined' && this.isAddModal == true) {
      if (
        this.moduleName == 'Ringless Voicemail' ||
        this.moduleName == 'Dialer Voicemail'
      ) {
        this.onAddEditRvmDialog();
      } else if (this.moduleName == 'vendors') {
        this.onAddEditVendorsDialog();
      } else if (this.moduleName == 'account Tags') {
        this.onAddEditAccountTagDialog();
      } else if (this.moduleName == 'Tags Manager') {
        this.onAddGlobalTagsDialog();
      } else if (this.moduleName == 'users') {
        this.onAddSubUser();
      } else if (this.moduleName === 'Direct Mail') {
        //this._callBackFunction.emit('process_count');
        this._router.navigate([`direct-mail/create`]);
      } else if (this.moduleName == 'cards') {
        this.onAddEditCardsDialog();
      } else if (this.moduleName === 'Contractor') {
        this._router.navigate([`contractor/create`]);
      } else if (this.moduleName === 'Manage Templates') {
        if (this.temModuleName === 'Email Templates') {
          this.onEmailDailog('email-template', 'create', 'id');
        } else if (this.temModuleName === 'SMS Templates') {
          this.onSmsDailog('create', 'id');
        } else if (this.temModuleName === 'Ringless Voicemail') {
          this.onAddEditRvmDialog();
        } else if (this.temModuleName === 'Task Templates') {
          this.onTaskDailog('create');
        }
      } else if (this.moduleName === 'SMS Templates') {
        this.onSmsDailog('create', 'id');
      } else if (this.moduleName === 'Dialer' || this.linkTitle === 'Dialer') {
        if (
          this.campaignId ||
          this.inboxCallEnable ||
          this.campaignScheduled ||
          this.campaignTodayDate
        )
          this.onColdCallSetup();
      } else if (this.moduleName === 'Task Templates') {
        this.onTaskDailog('create');
      } else if (this.moduleName == 'journal Entry') {
        this._router.navigate([`/accounting/add-journal-entry`]);
      }
    } else if (typeof this.isRedirectUrl !== 'undefined') {
      if (this.isRedirectUrl == '/manage-numbers/buy-numbers') {
        this.getManageFlow(this.isRedirectUrl);
      } else if (this.isRedirectUrl == '/markets/add-v2') {
        this.callChargeAmount('market');
      }
      // else if (this.isRedirectUrl == 'list-stacking/add') {
      //   this._callBackFunction.emit('process_count');
      // }
      else {
        this._router.navigate([`${this.isRedirectUrl}`]);
      }
    } else if (this.moduleName === 'Buyer/Agents') {
      this._router.navigate([`buyers/add`]);
    } else if (this.moduleName === 'Webhooks & API Token') {
      if (
        (this.moduleData == 'enterprise' ||
          this.moduleData == 'enterprise existing') &&
        this.secondaryData?.length >= 10
      )
        this._toastrService.error(this.messageConstant.webhookEnterpriseLimit);
      else if (
        this.moduleData != 'enterprise' &&
        this.moduleData != 'enterprise existing' &&
        this.secondaryData?.length
      )
        this._toastrService.error(this.messageConstant.webhookLimit);
      else this._router.navigate([`/webhook-and-api-token/add`]);
    } else if (this.moduleName == 'Email Account') {
      this._emitAddEmail.emit();
    } else {
      this._router.navigate([`${this.moduleName.toLocaleLowerCase()}/add`]);
    }
  }
  onColdCallSetup() {
    if (this._utilities.isLSDialer) {
      if (this._utilities.dialerCurrentCampaignId == this.campaignId) {
        this._sharedService.refreshDialerToggleDisplay.next(true);
        return false;
      } else {
        this._toastrService.warning(this.messageConstant.callUnderProgress);
        this._loaderService.stop();
        return false;
      }
    }
    this.dialogRef = this.dialog.open(ColdCallSettingComponent, {
      width: '450px',
      disableClose: true,
      panelClass: 'editTemplate',
      data: {
        campaignId: this.campaignId,
        dialed: this.dialed,
        campaignTodayDate: this.campaignTodayDate,
        campaignScheduled: this.campaignScheduled,
        inboxCallEnable: this.inboxCallEnable,
        currentCampaignPage: this.currentCampaignPage,
        todayCallPage: this.todayCallPage,
        scheduledCallPage: this.scheduledCallPage,
        inboxCallPage: this.inboxCallPage,
        scheduledCallFilters: this.appliedDialFilters,
      },
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
      }
    });
  }
  onAddGroup() {
    this.dialogRef = this.dialog.open(AddEditGroupComponent, {
      width: '450px',
      disableClose: true,
      data: {
        title: 'Add',
      },
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
      }
    });
  }

  callChargeAmount(mode) {
    this._loaderService.start();
    let type = 'getMarketChargeAmount';
    if (mode === 'user') {
      type = 'getSubUserChargeAmount';
    }

    this._usersService[type]({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode === 200) {
          const payAmount = parseFloat(response?.data?.payAmount);
          let additionInfo = response?.data?.additionalMarkets;
          let isTrialPeriod = response?.data?.isTrialPeriod;
          let isYearlyPlan = response?.data?.isYearlyPlan;
          let redirectUrl = '/markets/add-v2';

          if (mode === 'user') {
            additionInfo = response.data?.additionalUser;
          }

          if (mode === 'user') {
            redirectUrl = '/users/user-add';
          }
          if (payAmount > 0) {
            let durationText = 'mo';
            let durationText2 = 'month';
            if (isYearlyPlan) {
              durationText = 'year';
              durationText2 = 'year';
            }
            let modalData = {
              yesTitle: 'Yes',
              noTitle: 'Cancel',
              header: `Add Additional ${mode === 'user' ? 'User' : 'Market'}`,
              text: `Each additional ${mode} is $${additionInfo}/${durationText}.
              Your first ${durationText2} prorated charge will be $${payAmount} and then $${additionInfo}/${durationText} after that.
              Would you like to add another ${mode}?`,
            };
            if (isTrialPeriod) {
              modalData[
                'text'
              ] = `Each additional ${mode} is $${additionInfo}/${durationText}.
                Your trial period prorated charge will be $0 and then $${additionInfo}/${durationText} after that.
                Would you like to add another ${mode}?`;
            }
            this.openConfirmationDialog(modalData, redirectUrl);
          } else {
            this._utilities.showAddLimitPopup = false;
            this._router.navigate([`${redirectUrl}`]);
          }
        } else {
          this._toastrService.error(response.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  openConfirmationDialog(data, redirectUrl) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '600px',
      data: data,
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._utilities.showAddLimitPopup = false;
        this._router.navigate([`${redirectUrl}`]);
      }
    });
  }

  getManageFlow(url) {
    const obj = {
      page: 1,
      limit: 2000,
    };
    this._sharedService.getManageFlow(obj).subscribe(
      (response) => {
        if (response) {
          let flowList = response?.data?.items;
          if (flowList.length > 0) {
            this._router.navigate([`${url}`]);
          } else {
            this._toastrService.info(this.messageConstant.flowNotFound, '', {
              enableHtml: true,
            });
          }
        }
      },
      (err: ErrorModel) => {}
    );
  }

  onAddEditRvmDialog() {
    this.dialogRef = this.dialog.open(AddEditRvmComponent, {
      width: '500px',
      panelClass: 'editRvm',
      data: {
        subModule:
          this.moduleName != 'Dialer Voicemail'
            ? 'Add RVM Recording'
            : 'Add Dialer Voicemail Recording',
        items: this.secondaryData,
        moduleId: this.moduleId,
        urlType:
          this.moduleName == 'Dialer Voicemail'
            ? 'dialerVoiceMail'
            : 'ringlessvoicemail',
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      this.callParent();
    });
  }

  onAddGlobalTagsDialog() {
    this.dialogRef = this.dialog.open(AddGlobalTagsComponent, {
      width: '500px',
      data: {
        subModule: 'Add Tags',
        items: this.secondaryData,
        tagType: this.tagType,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      this.callParent();
    });
  }

  syncEmail() {
    if (!this._utilities.currentPlanData?.isAddNewCalendar) {
      this._loaderService.stop();
      this._toastrService.info(this.messageConstant.featureMessage, '', {
        enableHtml: true,
      });
      return;
    }

    this._sharedService.getAccessToken().subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          if (response.data?.authUrl) {
            window.open(response.data?.authUrl, '_blank');
            this._emitRefresh.emit(true);
          } else {
            this._toastrService.error(this.messageConstant.calendarSync);
          }
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  unSyncEmail() {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: {
        header: 'Confirmation',
        text: `By deleting your calendar all the appointments that are synced from REsimpli to your calendar will be deleted on your synced calendar but they will remain on REsimpli calendar. Are you sure you want to delete?`,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._loaderService.start();
        this._sharedService.unlinkCalendar().subscribe(
          (response: ResponseModel) => {
            if (response.statusCode === 200) {
              this._emitRefresh.emit(true);
              this._toastrService.success(this.messageConstant.calendarUnSync);
            }
            this._loaderService.stop();
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
      }
    });
  }

  onToggle() {
    this.toggle = !this.toggle;
  }

  onAddEditVendorsDialog() {
    this._router.navigate([`/vendors/add`]);
  }

  onAddEditAccountTagDialog() {
    this.dialogRef = this.dialog.open(ModalAddAccountTagsComponent, {
      width: '500px',
      data: {
        action: 'add',
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      this.callParent();
    });
  }

  onAddSubUser() {
    // TODO: REMOVE BELOW CODE
    if (window.location.pathname === '/users/user-list') {
      this.callChargeAmount('user');
      //this._router.navigate(['/users/user-add']);
    } else {
      this._router.navigate([`/profile/user-add`]);
    }
  }

  onActionClick(key) {
    if (key?.isDisabled) {
      return;
    }

    this._loaderService.start();
    switch (key?.type) {
      case 'SYNC_EMAIL':
        this.syncEmail();
        break;
      case 'UN_SYNC_EMAIL':
        this._loaderService.stop();
        this.unSyncEmail();
        break;
      case 'SET_APPOINTMENT':
        this.setAppointment();
        break;
      case 'SET_AVAILABILITY':
        this.setAvailability();
        break;
      case 'DOWNLOAD_DOCUMENT':
        this._loaderService.stop();
        this._eSignAction.emit({ type: 'download' });
        break;
      case 'RESEND_INVITE':
        this._loaderService.stop();
        this._eSignAction.emit({ type: 'resend' });
        break;
      case 'CANCEL_DOCUMENT':
        this._loaderService.stop();
        this._eSignAction.emit({ type: 'cancel' });
        break;
      default:
        this._loaderService.stop();
        break;
    }
  }

  setAppointment() {
    this._loaderService.stop();
    this.dialogRef = this.dialog.open(AddEditAppointmentsComponent, {
      width: '670px',
      data: {
        moduleId: this.moduleId,
        currentUser: this.currentUser,
      },
    });
  }

  setAvailability() {
    this._loaderService.stop();

    this.dialogRef = this.dialog.open(SetAvailabilityDialogComponent, {
      width: '670px',
      data: {
        users: this.users,
        currentUser: this.currentUser,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let obj = {
          isResult: true,
          data: result,
          currentUser: this.currentUser,
        };
        this._emitRefresh.emit(obj);
      }
    });
  }

  selectedUsers($event) {
    this.users.map((x) => {
      if (x?._id === $event._id) {
        if (x['isChecked']) {
          delete x['isChecked'];
        } else {
          x['isChecked'] = true;
        }
      }
      return;
    });

    this._emitCalendarEvents.emit(this.users);
  }

  refresh() {
    this._emitRefresh.emit(true);
  }

  onAddEditCardsDialog() {
    this.dialogRef = this.dialog.open(AddCardDialogComponent, {
      width: '500px',
      data: {
        action: 'add',
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      this.callParent();
    });
  }

  openBulkSMSModal() {
    const phoneLength = this.selectedItems.filter((x) => x.contactNumber);
    if (!phoneLength?.length) {
      this._toastrService.error(this.messageConstant.selectContactWithNumber);
      return;
    }

    let length = 0;
    if (this.selectActionType === 'All') {
      length = this.totalRecord;
    } else {
      length = this.selectedItems.length;
    }

    if (length > environment.buyers.bulkSMSLimit) {
      const limit = this.messageConstant.selectBulkContactWithLimit
        .replace('[[TYPE]]', 'SMS')
        .replace('[[LIMIT]]', `${environment.buyers.bulkSMSLimit}`);
      this._toastrService.error(limit);
      return;
    }

    this.getSmsCredits();
  }

  getSmsCredits() {
    this._loaderService.start();

    const data = this.selectedItems
      .map((x) => {
        if (x?.contactNumber) {
          let obj = {
            toNumber: x?.contactNumber,
            subModuleId: x?._id,
          };
          return obj;
        }
      })
      .filter((x) => x);

    let obj: any = {
      toSend: this.selectActionType === 'All' ? [] : data,
      filterData: { contactNumber: { value: 'yes', operator: 'is' } },
      isFilter: this.selectActionType !== 'All' ? false : true,
    };

    if (this.isFilter) {
      obj = {
        ...obj,
        filterData: { ...obj.filterData, ...this.filterObj?.filterData },
      };
    }
    if (this.searchStr) {
      obj['searchStr'] = this.searchStr;
    }

    delete obj['page'];
    delete obj['limit'];

    this._creditService.getBulkSMSCredits(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          const { buyerCount, smsCreditLimit, vendorCount } = response.data;
          if (this.moduleName === 'Vendors') {
            if (smsCreditLimit < vendorCount) {
              this._toastrService.error(this.messageConstant.smsCreditLimit);
              this._loaderService.stop();
              return;
            }
          } else {
            if (smsCreditLimit < buyerCount) {
              this._toastrService.error(this.messageConstant.smsCreditLimit);
              this._loaderService.stop();
              return;
            }
          }

          this.getNumbers();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getNumbers() {
    const data = {
      page: 1,
      limit: 1000,
      numTypeId: this.moduleName === 'Vendors' ? 4 : 2,
    };

    this._loaderService.start();
    this._sharedService.getnumbers(data).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          const tollFreeNumbers = response.data?.items?.filter(
            (x) => x.plivoSubType === 'tollfree'
          );

          if (tollFreeNumbers?.length) {
            let text = `You have selected {{COUNT}} records to send Bulk SMS to`;

            if (this.selectActionType === 'All') {
              text = text.replace('{{COUNT}}', `${this.totalRecord}`);
            } else {
              text = text.replace('{{COUNT}}', `${this.selectedItems.length}`);
            }

            this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
              width: '450px',
              data: {
                yesTitle: 'Ok',
                noTitle: 'Cancel',
                header: 'Confirmation',
                text,
              },
            });
            this.dialogRef.afterClosed().subscribe((result) => {
              if (result) {
                this.sendBulkSMS(tollFreeNumbers);
              } else {
                this._emitRefresh.emit(true);
              }
            });
          } else {
            this._toastrService.info(
              this.messageConstant.noTollFreeNumber.replace(
                /Buyers/g,
                this.moduleName
              ),
              '',
              {
                enableHtml: true,
              }
            );
          }
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        this._toastrService.error(
          err.message ? err.message : MessageConstant.unknownError
        );
      }
    );
  }

  openBulkEmailModal() {
    let length = 0;
    if (this.selectActionType === 'All') {
      length = this.totalRecord;
    } else {
      length = this.selectedItems.length;

      const emailLength = this.selectedItems.filter((x) => x.email);
      if (!emailLength?.length) {
        this._toastrService.error(this.messageConstant.selectContactWithEmail);
        return;
      }
    }

    if (!length) {
      this._toastrService.error(this.messageConstant.selectContactWithEmail);
      return;
    }

    if (length > environment.buyers.bulkEmailLimit) {
      const limit = this.messageConstant.selectBulkContactWithLimit
        .replace('[[TYPE]]', 'EMAIL')
        .replace('[[LIMIT]]', `${environment.buyers.bulkEmailLimit}`);
      this._toastrService.error(limit);
      return;
    }

    const data = {
      page: 1,
      limit: 1000,
      moduleId: this.moduleId,
    };

    this._loaderService.start();
    this._sharedService.getEmailTemplates(data).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          let text = `You have selected {{COUNT}} records to send Bulk Email to`;

          if (this.selectActionType === 'All') {
            text = text.replace('{{COUNT}}', `${this.totalRecord}`);
          } else {
            text = text.replace('{{COUNT}}', `${this.selectedItems.length}`);
          }

          this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '450px',
            data: {
              yesTitle: 'Ok',
              noTitle: 'Cancel',
              header: 'Confirmation',
              text,
            },
          });
          this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              this.sendBulkEmail(response);
            } else {
              this._emitRefresh.emit(true);
            }
          });
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        // this._toastrService.error(
        //   err.message ? err.message : MessageConstant.unknownError
        // );
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }

  sendBulkSMS(tollFreeNumbers) {
    this.dialogRef = this.dialog.open(SendBulkSmsDialogComponent, {
      width: '500px',
      data: {
        items: this.selectedItems,
        tollFreeNumbers,
        moduleId: this.moduleId,
        actionType: this.selectedValue,
        isFilter: this.isFilter,
        filterObj: this.filterObj,
        searchStr: this.searchStr,
        selectActionType: this.selectActionType,
      },
    });

    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this._emitRefresh.emit('sms');
      } else {
        this._emitRefresh.emit(true);
      }
    });
  }

  sendBulkEmail(response) {
    this.dialogRef = this.dialog.open(SendBulkEmailDialogComponent, {
      width: '400px',
      panelClass: 're-bulk-container',
      disableClose: true,
      data: {
        items: this.selectedItems,
        moduleId: this.moduleId,
        templates: response.data?.items,
        actionType: this.selectedValue,
        isFilter: this.isFilter,
        filterObj: this.filterObj,
        searchStr: this.searchStr,
        selectActionType: this.selectActionType,
        latestCampaign: this.latestCampaign,
        previousCampaign: response.data.emailCampaignData,
      },
    });

    this.dialogRef.afterClosed().subscribe((data) => {
      if (typeof data !== 'undefined' && data == true) {
        this._emitRefresh.emit(data);
      } else if (typeof data !== 'undefined' && data == 'campaign') {
        this._emitRefresh.emit('campaign');
      } else {
        this._emitRefresh.emit();
      }
    });
  }

  bulkUpload() {
    if (this.filesInProgress.length) {
      this._toastrService.error(this.messageConstant.bulkImportLimitExceed);
      return;
    }

    if (this.moduleName === 'Vendors') {
      this._toastrService.error(this.messageConstant.comingSoon);
      return;
    } else this._router.navigate(['/buyers/bulk-upload']);
  }

  onLinkAddress() {
    if (this.linkLeadId) {
      this._router.navigate([`/leads/details`], {
        queryParams: { leadsId: this.linkLeadId },
      });
    }
    if (this.linkPropertyId) {
      this._router.navigate([`/inventory/details`], {
        queryParams: { inventoryId: this.linkPropertyId },
      });
    }
    if (this.linkTitle && this.linkTitle == 'Dialer') {
      this._callBackFunction.emit('1');
    }
  }
  onMainLinkAddress() {
    if (this.linkTitle && this.linkTitle == 'Dialer') {
      this._callBackFunction.emit('0');
    }
  }

  exportData() {
    this._exportData.emit();
  }

  selectAction(mode) {
    switch (mode) {
      case 1:
        this.selectItemsCount = this.moduleData?.items?.length;
        this._emitSelectBuyerAction.emit({
          mode: 'Self',
          val: this.selectItemsCount,
          currentPage: this.currentPage,
        });
        break;
      case 2:
        this.selectItemsCount = this.totalRecord;
        this._emitSelectBuyerAction.emit({
          mode: 'All',
          val: this.totalRecord,
        });
        break;
      case 3:
        if (this.checkToFrom()) {
          this.setToFrom();
          if (this.selectItemsCount > 0) {
            this._emitSelectBuyerAction.emit({
              mode: 'Custom',
              from: this.selectedTo,
              to: this.selectedFrom,
              currentPage: this.currentPage,
            });
          }
        }
        break;
    }
  }

  checkToFrom() {
    if (
      this.selectedValue == 'Custom' &&
      parseInt(this.selectedTo.toString()) >
        parseInt(this.selectedFrom.toString())
    ) {
      this._toastrService.error(
        "Custom range min value can't be more than max"
      );
      return false;
    } else return true;
  }

  setToFrom() {
    if (this.selectedValue == 'Custom') {
      if (
        this.selectedTo.toString().trim().length == 0 ||
        this.selectedFrom.toString().trim().length == 0 ||
        parseInt(this.selectedTo.toString()) >
          parseInt(this.selectedFrom.toString())
      ) {
        this.selectItemsCount = 0;
        return;
      }
      if (parseInt(this.selectedFrom.toString()) > this.totalRecord) {
        this.selectedFrom = this.totalRecord;
      }
      this.selectItemsCount =
        parseInt(this.selectedFrom.toString()) +
        1 -
        parseInt(this.selectedTo.toString());
    }
  }

  emitSelected(action) {
    if (action.event === 'BULK_EMAIL') {
      if (!this._utilities.currentPlanData?.isAllowBulkEmail) {
        this._loaderService.stop();
        this._toastrService.info(this.messageConstant.featureMessage, '', {
          enableHtml: true,
        });
        return;
      }
      this.openBulkEmailModal();
      return;
    }

    if (action.event === 'BULK_SMS') {
      if (!this._utilities.currentPlanData?.isAllowBulkSMS) {
        this._loaderService.stop();
        this._toastrService.info(this.messageConstant.featureMessage, '', {
          enableHtml: true,
        });
        return;
      }
      this.openBulkSMSModal();
      return;
    } else if (action.event === 'DELETE_SELECTED') {
      this.onDeletePropertyDialog();
    } else if (action.event === 'EXPORT_SELECTED') {
      this.onExportDialog();
    } else if (action.event === 'SKIP_TRACE') {
      this.onSkipDialog();
    } else if (action.event === 'ADD_TAG') {
      this.onTagAddDialog();
    } else if (action.event === 'REMOVE_TAG') {
      this.getListsTagsOfSelected();
    } else if (action.event === 'ADD_LIST') {
      this.onListAddDialog();
    } else if (action.event === 'REMOVE_LIST') {
      this.getListsOfSelected();
    } else if (action.event === 'MARK_OPTOUT') {
      this.onMarkOptOutDialog(true);
    } else if (action.event === 'REMOVE_OPTOUT') {
      this.onMarkOptOutDialog(false);
    } else if (action.event === 'DIRECT_MAIL') {
      this.orderDirectMail();
      //this.getProcessValidate();
    } else if (action.event === 'MARKETING_DETAILS') {
      this.openExportList();
    } else if (action.event === 'SEND_TO_DIALER') {
      this.sendDialer();
    } else if (action.event === 'MARKETING_DETAILS_REMOVE') {
      this.openRemoveMarket();
    }

    this._emitSelected.emit({ ...action, selectedItems: this.selectedItems });
  }

  onDeletePropertyDialog() {
    this.dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        subModule: 'Properties',
        details: {
          _id: '1',
          mode: 'multi',
          filter: this.filterObj,
        },
      },
      height: 'auto',
      width: '500px',
      panelClass: 'device-modal',
      disableClose: false,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clearCustomSelection();
        this._emitRefresh.emit({
          type: 'delete',
          details: {
            _id: '1',
            mode: 'multi',
            filter: this.filterObj,
          },
        });
        // this.currentPage = 1;
        // this.totalRecord = this.totalRecord - this.selectionShowCount;
        // if (this.totalRecord == 0) {
        //
        // }
      }
    });
  }

  onExportDialog() {
    this.dialogRef = this.dialog.open(ExportProspectComponent, {
      data: {
        subModule: 'Export',
        title: 'Export Prospect List',
        filter: this.filterObj,
      },
      height: 'auto',
      width: '720px',
      panelClass: 'device-modal',
      disableClose: false,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clearCustomSelection();
        //this.openExportList(result);
        this._emitRefresh.emit('export');
      }
    });
  }

  openExportList(obj?) {
    this.dialogRef = this.dialog.open(ListExportMarketingComponent, {
      data: {
        title: obj ? 'List Export' : 'Add Marketing Details',
        myobj: obj ? obj : this.filterObj,
        fromExport: obj ? true : false,
      },
      height: 'auto',
      width: '750px',
      panelClass: 'device-modal',
      disableClose: false,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clearCustomSelection();
        this._emitRefresh.emit('add_market');
      }
    });
  }
  sendDialer() {
    if (
      !this._utilities.userAccessData?.isListStackingDialerAccess &&
      !this._utilities.userAccessData?.isLSDManagerAccess
    ) {
      this._toastrService.error(this.messageConstant.permissionDenied, '');
      return;
    }
    let countRecords = 0;
    if (this.filterObj?.dataType == 'Self') {
      countRecords = this.filterObj?.ids.length;
    } else if (this.filterObj?.dataType == 'All') {
      countRecords = this.totalRecord;
    }
    if (countRecords > 10000) {
      this._toastrService.error(this.messageConstant.sendToDialerLimit, '');
      return;
    }
    this.dialogRef = this.dialog.open(SendDialerComponent, {
      data: {
        subModule: 'Direct Mail',
        title: 'Order Direct Mail',
        filter: this.filterObj,
        rows: countRecords,
      },
      height: 'auto',
      width: '500px',
      panelClass: 'device-modal',
      disableClose: false,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clearCustomSelection();
        this._emitRefresh.emit('dialer');
      }
    });
  }
  openRemoveMarket() {
    this.dialogRef = this.dialog.open(RemoveMarketDetailsComponent, {
      data: {
        myobj: this.filterObj,
      },
      height: 'auto',
      width: '400px',
      panelClass: 'device-modal',
      disableClose: false,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clearCustomSelection();
        this._emitRefresh.emit('remove_market');
      }
    });
  }
  onSkipDialog() {
    let countRecords = 0;

    if (this.filterObj?.dataType == 'Self') {
      countRecords = this.filterObj?.ids.length;
    } else if (this.filterObj?.dataType == 'All') {
      countRecords = this.totalRecord;
    }
    if (countRecords > 10000) {
      this._toastrService.error(
        'You can only Skip Trace maximum 10,000 records at a time.',
        ''
      );
      return;
    }
    this.dialogRef = this.dialog.open(AddTagListSkipComponent, {
      data: {
        subModule: 'Skip',
        title: 'Skip Trace',
        filter: this.filterObj,
        countRecords: countRecords,
      },
      height: 'auto',
      width: '350px',
      panelClass: 'editRvm',
      disableClose: false,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clearCustomSelection();
        this._loaderService.stop();
        this._emitRefresh.emit('skip');
      }
    });
  }

  onTagAddDialog() {
    this.dialogRef = this.dialog.open(AddTagListSkipComponent, {
      data: {
        subModule: 'Tag',
        title: 'Add Tags',
        filter: this.filterObj,
      },
      height: 'auto',
      width: '350px',
      panelClass: 'editRvm',
      disableClose: false,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clearCustomSelection();
        this._loaderService.stop();
        this._emitRefresh.emit('add_tag');
      }
    });
  }

  getListsTagsOfSelected() {
    this.dialogRef = this.dialog.open(RemoveTagsComponent, {
      width: '400px',
      data: {
        moduleId: this.moduleId,
        subModuleId: 'Tags',
        for: 'stacking',
        filter: this.filterObj,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clearCustomSelection();
        this._emitRefresh.emit('remove_tag');
      }
    });
  }

  onListAddDialog() {
    this.dialogRef = this.dialog.open(AddTagListSkipComponent, {
      data: {
        subModule: 'List',
        title: 'Add List',
        filter: this.filterObj,
      },
      height: 'auto',
      width: '350px',
      panelClass: 'editRvm',
      disableClose: false,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clearCustomSelection();
        this._loaderService.stop();
        this._emitRefresh.emit('add_list');
      }
    });
  }

  getListsOfSelected() {
    this.dialogRef = this.dialog.open(RemoveTagsComponent, {
      width: '400px',
      data: {
        moduleId: this.moduleId,
        subModuleId: 'Lists',
        for: 'stacking',
        filter: this.filterObj,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clearCustomSelection();
        this._emitRefresh.emit('remove_list');
      }
    });
  }

  onMarkOptOutDialog(optOutMode) {
    this.dialogRef = this.dialog.open(AddTagListSkipComponent, {
      data: {
        subModule: 'Optout',
        title: optOutMode ? 'Mark Optout' : 'Remove Optout',
        filter: this.filterObj,
        optValue: optOutMode,
      },
      height: 'auto',
      width: '350px',
      panelClass: 'device-modal',
      disableClose: false,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clearCustomSelection();
        this._loaderService.stop();
        this._emitRefresh.emit('mark_optout');
      }
    });
  }

  orderDirectMail() {
    let countRecords = 0;
    if (this.filterObj?.dataType == 'Self') {
      countRecords = this.filterObj?.ids.length;
    } else if (this.filterObj?.dataType == 'All') {
      countRecords = this.totalRecord;
    }
    this.dialogRef = this.dialog.open(AddTagListSkipComponent, {
      data: {
        subModule: 'Direct Mail',
        title: 'Order Direct Mail',
        filter: this.filterObj,
        rows: countRecords,
      },
      height: 'auto',
      width: '350px',
      panelClass: 'device-modal',
      disableClose: false,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clearCustomSelection();
        this._loaderService.stop();
        this._emitRefresh.emit('directmail');
      }
    });
  }

  onCampaignUpdate(campaignDetails, type) {
    let campaigTitle = '';
    let campaigNameArray = [];
    let dialerCampaignId = [];
    let dialerAssignList = [];
    let campaignPropertyList = [];
    const activedata = campaignDetails.forEach((element) => {
      if (element.isCompleted) {
        campaigTitle = element.campaignName;
        campaigNameArray.push(element.campaignName);
        dialerAssignList.push(element.assignedUserId);
        dialerCampaignId.push(element._id);
        element.propertyIds.forEach((item) => {
          campaignPropertyList.push(item.pId);
        });
      }
    });
    if (type == 'campaignName') {
      if (dialerCampaignId.length > 1) {
        const validateMessage = 'Please Select Only One Campaign';
        this._toastrService.error(validateMessage);
      } else {
        this.dialogRef = this.dialog.open(DialerChangeCampaignComponent, {
          data: {
            details: {
              id: dialerCampaignId,
              propertyId: campaignPropertyList,
            },
            yesTitle: 'Update Campaign',
            header: 'Change Campaign Name',
            text: campaigTitle,
            dialerCampaign: 'campaign',
          },
          width: '400px',
          height: 'auto',
          disableClose: false,
        });
        this.dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this._callBackFunction.emit('1');
          }
        });
      }
    } else if (type == 'campaignDelete') {
      this.dialogRef = this.dialog.open(DeleteDialogComponent, {
        data: {
          details: {
            id: dialerCampaignId,
            propertyId: campaignPropertyList,
            campaignName: campaigNameArray,
          },
          subModule: 'Dialer Campaign',
        },
        height: 'auto',
        width: '500px',
        panelClass: 'device-modal',
        disableClose: false,
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this._callBackFunctionDelete.emit({
            id: dialerCampaignId,
          });
        }
      });
    } else if (type == 'assignUser') {
      this.dialogRef = this.dialog.open(DialerChangeCampaignComponent, {
        data: {
          details: {
            assignUser: dialerAssignList,
            id: dialerCampaignId,
          },
          yesTitle: 'Confirm Assignment',
          header: 'Assign to Different User',
          dialerCampaign: 'assignUser',
        },
        width: '350px',
        height: 'auto',
        disableClose: false,
      });

      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this._callBackFunction.emit('1');
        }
      });
    }
  }

  getDropdownFilters() {
    (this.menu as any).closed = this.configureMenuClose(this.menu.closed);
    this.dropdownFilter.searchFilter = '';
    this.dropdownFilter.initialize();
  }

  getDropdownDialerFilters() {
    (this.menu as any).closed = this.configureMenuClose(this.menu.closed);
    this.DialerDropdownFilter.searchFilter = '';
    this.DialerDropdownFilter.initialize();
  }

  getDropdownCallLogDialerFilters() {
    (this.menu as any).closed = this.configureMenuClose(this.menu.closed);
    this.DialerDropdownCallLogFilter.searchFilter = '';
    this.DialerDropdownCallLogFilter.initialize();
  }
  getDropdownScheduledCallDialerFilters() {
    (this.menu as any).closed = this.configureMenuClose(this.menu.closed);
    this.DialerDropdownScheduledCallFilter.searchFilter = '';
    this.DialerDropdownScheduledCallFilter.initialize();
  }

  private configureMenuClose(old: MatMenu['closed']): MatMenu['closed'] {
    const _emitter = new EventEmitter<any>();
    feed(
      _emitter.pipe(
        filter((event) => {
          if (event === 'click') {
            // Ignore clicks inside the menu
            return false;
          }
          return true;
        })
      ),
      old
    );
    return _emitter;
  }

  getFilterCount() {
    const { filterData } = this.filterObj;
    let filterCount = Object.keys(filterData).length;
    if (filterData['uniqueMailingAddress'])
      filterCount = filterCount ? filterCount - 1 : filterCount;
    return filterCount;
  }

  downloadDataAction() {
    this._downloadAction.emit();
  }

  toggleLeaderboardMenu() {
    this.isLeaderboardMenuVisible = !this.isLeaderboardMenuVisible;
  }

  onCustomViewChange(type, event) {
    this._applyCustomView.emit({
      type: type,
      value: event.target.checked,
    });
  }

  editUser(type) {
    this._callBackFunction.emit(type);
  }

  getTooltip(type) {
    let str = '';

    if (this.moduleName === 'My Tasks') {
      str += `Tasks filtered by ${type}`;
    } else if (this.moduleName === 'Contingencies') {
      str += `${
        type === 'Due Today'
          ? 'In 7 Days'
          : type === 'Due Later'
          ? 'After 7 Days'
          : type
      }`;

      return str;
    }
  }

  getProcessValidate() {
    this._loaderService.start();
    this._listStackingService.getCurrentProcessCount({}).subscribe(
      (response) => {
        if (response?.code && response?.code == 200) {
          this._loaderService.stop();
          if (response?.status == 'Success') {
            this.orderDirectMail();
          } else {
            this._toastrService.error(
              'There are currently some files under processing. Please try back in few mins.'
            );
          }
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }
  onChangeOngoingCall(type) {
    this.isOngoingCall = type == 'true' ? true : false;
    this._changeOngoingCall.emit({ type: type });
  }
  reportExportAction(type) {
    this._reportExportAction.emit({ type: type });
  }

  viewAction() {
    this._callBackFunction.emit('viewLog');
  }
  showMessages(name, type) {
    this.activeButton = type;
    if (name == 'Buyer/Agents') {
      this._showBuyerInboxMessages.emit(type);
    }
    if (name == 'Vendors') {
      this._showVendorInboxMessages.emit(type);
    }
  }

  onCancelAccount() {
    window.open('https://resimpli.com/cancellation-form/', '_blank');
  }

  closeSearch() {
    this._router.navigate(['/dashboard'], {
      state: { isClearGlobalSearch: 1 },
    });
    localStorage.removeItem('SEARCH-TAB-INDEX');
  }
}

function feed<T>(from: Observable<T>, to: Subject<T>): Subscription {
  return from.subscribe(
    (data) => to.next(data),
    (err) => to.error(err),
    () => to.complete()
  );
}
