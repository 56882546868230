import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { filter } from 'rxjs/operators';
import { Subject, Observable, Subscription } from 'rxjs';

// SERVICE
import { ToastrService } from 'ngx-toastr';

// UTILS
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';

@Component({
  selector: 'app-advanced-pagination',
  templateUrl: './advanced-pagination.component.html',
  styleUrls: ['./advanced-pagination.component.scss'],
})
export class AdvancedPaginationComponent implements OnInit, OnChanges {
  @ViewChild('menu') menu!: MatMenu;
  @ViewChild(MatMenuTrigger, { static: false }) goToMenu: MatMenuTrigger;

  @Input() count: number = 0;
  @Input() initialPage = 1;
  @Input() pageSize = MiscellaneousConstant.paginationLimit.STANDARD;
  @Input() maxPages = 10;
  @Input() showPagination: boolean = true;
  @Output() changePage = new EventEmitter<any>(true);
  @Output() _emitChangeLimit = new EventEmitter<any>();

  limit: number = MiscellaneousConstant.paginationLimit.STANDARD;
  recordsLimit: any[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  page: number = 1;
  pager: any = {};

  constructor(private _toastrService: ToastrService) {}

  ngOnInit() {
    // set page if items array isn't empty
    if (this.count && this.count > 0) {
      this.setPage(this.initialPage);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // reset page if items array has changed
    // if (changes.items.currentValue && changes.items.previousValue) {
    //   if (
    //     changes.items.currentValue.length !== changes.items.previousValue.length
    //   ) {
    //     this.setPage(this.initialPage);
    //   }
    // }
    if (
      changes.count?.currentValue &&
      changes.count?.previousValue &&
      changes.count?.currentValue !== changes.count?.previousValue
    ) {
      this.setPage(this.initialPage);
    }
  }

  paginate(totalItems, currentPage, pageSize, maxPages) {
    if (currentPage === void 0) {
      currentPage = 1;
    }
    if (pageSize === void 0) {
      pageSize = 10;
    }
    if (maxPages === void 0) {
      maxPages = 10;
    }
    // calculate total pages
    var totalPages = Math.ceil(totalItems / pageSize);
    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }
    var startPage, endPage;
    if (totalPages <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // total pages more than max so calculate start and end pages
      var maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      var maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // current page near the start
        startPage = 1;
        endPage = maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // current page near the end
        startPage = totalPages - maxPages + 1;
        endPage = totalPages;
      } else {
        // current page somewhere in the middle
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }
    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    // create an array of pages to ng-repeat in the pager control
    var pages = Array.from(Array(endPage + 1 - startPage).keys()).map(function (
      i
    ) {
      return startPage + i;
    });
    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  setPage(page: number, bool?, goTo?, pageSize?) {
    // get new pager object for specified page
    if (page === 0) {
      return;
    }

    if (goTo && page > this.pager?.totalPages) {
      this._toastrService.error(
        `Page cannot be greater than ${this.pager?.totalPages}`
      );
      return;
    }

    if (goTo && this.goToMenu) {
      this.goToMenu?.closeMenu();
    }
    if (pageSize) {
      this.pageSize = pageSize;
      this.limit = pageSize;
    }
    this.pager = this.paginate(this.count, page, this.pageSize, this.maxPages);

    // call change page function in parent component
    if (!bool) this.changePage.emit(page);
  }

  getCount() {
    let currentPage = this.limit * ((this.pager.currentPage || 1) - 1) + 1;
    let count = currentPage + '-' + this.count;

    if (this.limit * this.pager.currentPage <= this.count)
      count = currentPage + '-' + this.limit * this.pager.currentPage;

    return count;
  }

  getMenu() {
    (this.menu as any).closed = this.configureMenuClose(
      this.menu.closed
    );
  }

  private configureMenuClose(old: MatMenu['closed']): MatMenu['closed'] {
    const _emitter = new EventEmitter<any>();
    feed(
      _emitter.pipe(
        filter((event) => {
          if (event === 'click') {
            // Ignore clicks inside the menu
            return false;
          }
          return true;
        })
      ),
      old
    );
    return _emitter;
  }

  changeLimit($event) {
    this.limit = $event;
    this.pageSize = $event;
    this._emitChangeLimit.emit($event);
  }
}

function feed<T>(from: Observable<T>, to: Subject<T>): Subscription {
  return from.subscribe(
    (data) => to.next(data),
    (err) => to.error(err),
    () => to.complete()
  );
}
