import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrl } from '../../utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
@Injectable({
  providedIn: 'root',
})
export class TranscriptionModule {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem('token');

  constructor(private _commonFunction: CommonFunctionsService) {}

  getTranscript = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.transcription + 'transcription';
    return this._commonFunction.globalPostService(endpoint, data);
  };
}
