import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';

// UTILS
import { MessageConstant } from 'src/app/utils/message-constant';
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';

// COMPONENTS
import { SaveTemplateDialogComponent } from './save-template-dialog/save-template-dialog.component';
import { TestEmailDialogComponent } from './test-email-dialog/test-email-dialog.component';
import { VerifyEmailComponent } from '../emails-dailog/choose-email-dailog/verify-email/verify-email.component';

// SERVICES
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { SharedService } from '../../shared.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
declare var jQuery: any;
declare var unlayer: any;
@Component({
  selector: 'app-send-bulk-email-dialog',
  templateUrl: './send-bulk-email-dialog.component.html',
  styleUrls: ['./send-bulk-email-dialog.component.scss'],
})
export class SendBulkEmailDialogComponent implements OnInit {
  //@ViewChild('emailEditor') emailEditor: EmailEditorComponent;
  @ViewChild('editorContainer') editorContainer: ElementRef;
  emailForm: FormGroup;
  campaignForm: FormGroup;
  messageConstant = MessageConstant;
  formStep: number = 1;
  submitted: boolean = false;
  isEmailValid: boolean = true;
  isPreviousTemplateActive: boolean = false;

  templates: any[] = [];
  selectedTemplate: any = {};
  selectedTemplateIndex: number = -1;

  currentPage: number = 1;
  currentLimit: number = 100;
  emailInvalidMessage: string = '';

  items: string[] = ['First_Name', 'Last_Name', 'Company'];
  options: any = {
    id: 'editor-container',
    projectId: 1234,
    displayMode: 'email',
    mergeTags: {
      first_name: {
        name: 'First Name',
        value: '@First_Name',
      },
      last_name: {
        name: 'Last Name',
        value: '@Last_Name',
      },
      company: {
        name: 'Company',
        value: '@Company',
      },
    },
  };

  interval: any;
  _dialogVerifyRef: any;
  isCampaignExists: boolean = false;
  isSendEmail: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<SendBulkEmailDialogComponent>,
    private _dialog: MatDialog,
    public _utilities: CommonFunctionsService,
    private _formBuilder: FormBuilder,
    private _sharedService: SharedService,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private _clipboard: Clipboard,
    public sanitizer: DomSanitizer
  ) {
    this.emailForm = this._formBuilder.group({
      campaignName: ['', []],
      fromName: [
        this.data?.latestCampaign?.fromName || '',
        [Validators.required],
      ],
      fromEmail: [
        this.data?.latestCampaign?.fromEmail || '',
        [
          Validators.required,
          Validators.pattern(MiscellaneousConstant.emailPatternWithCaps),
        ],
      ],
      subject: ['', [Validators.required]],
      htmlPart: ['', []],
      designPart: ['', []],
    });
    if (this.data?.latestCampaign?.fromEmail) this.checkEmail();
  }

  ngOnInit(): void {
    this.templates = this.data.templates;
  }

  get email() {
    return this.emailForm.get('fromEmail');
  }

  checkEmail() {
    if (this.emailForm.controls.fromEmail.status === 'INVALID') {
      return;
    }
    this.emailInvalidMessage = '';
    this.isEmailValid = false;
    const obj = {
      email: this.emailForm.value.fromEmail?.toLowerCase()?.trim(),
    };

    this._loaderService.start();
    this._sharedService.checkEmail(obj, true).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.emailInvalidMessage = response.data?.message;
          this.isEmailValid = response.type == 'SUCCESS';
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          if (err.error.message) {
            this.emailInvalidMessage = err.error.message;
            this.isEmailValid = false;
          }
        }
      }
    );
  }

  onVerifyEmailDialog(email) {
    this._dialogVerifyRef = this._dialog.open(VerifyEmailComponent, {
      width: '550px',
      panelClass: 'verifyEmail',
      disableClose: true,
      data: {
        type: 'lead-info',
        subType: 'sendEmail',
        category: '',
        email,
        details: {},
        isBulkEmail: true,
      },
    });

    this._dialogVerifyRef.afterClosed().subscribe((result) => {
      if (result) {
        this._dialogRef.close(true);
      }
    });
  }

  createTemplate() {
    jQuery('.cdk-overlay-pane.re-bulk-container').css('width', '1100px');
    this.selectedTemplate = {};
    this.selectedTemplateIndex = -1;
    this.isPreviousTemplateActive = false;
    this.formStep = 4;
    this.loadUnlayer();
  }

  loadUnlayer(loadDesign?) {
    setTimeout(() => {
      unlayer.init(this.options);
      if (loadDesign) unlayer.loadDesign(this.selectedTemplate);
    }, 100);
  }

  // editorLoaded($event) {
  //   if (this.emailEditor) {
  //     clearInterval(this.interval);
  //     if (Object.keys(this.selectedTemplate).length) {
  //       this.emailEditor.loadDesign(this.selectedTemplate);
  //     }
  //   } else {
  //     this.interval = setInterval(() => {
  //       this.editorLoaded({});
  //     });
  //   }
  // }

  public hasError = (controlName: string, errorName: string) => {
    return this.emailForm.controls[controlName].hasError(errorName);
  };

  selectTemplate(template?, index?) {
    this.isPreviousTemplateActive = false;
    this.selectedTemplateIndex = index;
    try {
      this.selectedTemplate = JSON.parse(template?.template);
    } catch (error) {
      this.selectedTemplate = template?.template;
    }
  }

  save() {
    unlayer.exportHtml((data) => {
      this._clipboard.copy(JSON.stringify(data['design']));
      this._toastrService.success('Template copied successfully');
    });
  }

  onTest() {
    this.submitted = true;
    if (this.emailForm.invalid) {
      return;
    }

    if (this.isPreviousTemplateActive) {
      this.testEmail();
    } else {
      unlayer.exportHtml((data) => {
        this.emailForm.patchValue({
          htmlPart: data['html'],
          designPart: data['design'],
        });

        this.testEmail();
      });
    }
  }

  testEmail() {
    const dialogRef = this._dialog.open(TestEmailDialogComponent, {
      width: '500px',
      panelClass: 're-bulk-container',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.sendBulkEmail(result);
      }
    });
  }

  onSave() {
    this.submitted = true;
    if (this.emailForm.invalid) {
      return;
    }

    unlayer.exportHtml((data) => {
      this.emailForm.patchValue({
        htmlPart: data['html'],
        designPart: data['design'],
      });

      const currentTemplate = this.templates[this.selectedTemplateIndex];
      if (currentTemplate && currentTemplate?.status === 1) {
        this.editTemplate({
          ...currentTemplate,
          template: JSON.stringify(data['design']),
        });
      } else {
        const dialogRef = this._dialog.open(SaveTemplateDialogComponent, {
          width: '500px',
          panelClass: 're-bulk-container',
          data: {
            htmlPart: data['html'],
            designPart: data['design'],
            moduleId: this.data.moduleId,
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            setTimeout(() => {
              if (document.querySelector('.re-sendbulk-dialog'))
                document.querySelector('.re-sendbulk-dialog')['click']();
            }, 500);
            this.sendBulkEmail();
          }
        });
      }
    });
  }

  editTemplate({ template, label, _id }) {
    let obj = {
      emailTemplateId: _id,
      label,
      template,
    };

    setTimeout(() => {
      if (document.querySelector('.re-sendbulk-dialog'))
        document.querySelector('.re-sendbulk-dialog')['click']();
    }, 500);

    this._loaderService.start();
    this._sharedService.editEmailTemplates(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          setTimeout(() => {
            if (document.querySelector('.re-sendbulk-dialog'))
              document.querySelector('.re-sendbulk-dialog')['click']();
            this.sendBulkEmail();
          }, 500);
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        this._toastrService.error(
          err.message ? err.message : MessageConstant.unknownError
        );
      }
    );
  }

  exportHtml() {
    unlayer.exportHtml((data) => {
      console.log(JSON.stringify(data['design']));
    });
  }

  goBack(step) {
    jQuery('.cdk-overlay-pane.re-bulk-container').css('width', '400px');
    this.formStep = step;
    if (this.formStep !== 4) {
      this.unsetValidators([
        'fromName',
        'fromEmail',
        'subject',
        'htmlPart',
        'campaignName',
      ]);
    }

    if (this.formStep === 1) {
      this.setValidators(['fromName', 'fromEmail', 'subject']);
    }

    if (this.formStep === 2) {
      this.setValidators(['campaignName']);
    }
  }

  onSubmit() {
    try {
      this.submitted = true;
      if (this.emailForm.invalid || !this.isEmailValid) {
        return;
      }

      if (this.formStep === 1) {
        this.submitted = false;
        this.formStep = 2;
        this.setValidators(['campaignName']);
        return;
      }

      if (this.formStep === 2) {
        // this.formStep = 3;
        this.checkCampaign();
        return;
      }

      if (this.formStep === 3) {
        jQuery('.cdk-overlay-pane.re-bulk-container').css('width', '1100px');
        this.formStep = 4;
        this.loadUnlayer(true);
        return;
      }

      if (this.formStep === 4) {
        if (this.isPreviousTemplateActive) {
          this.sendBulkEmail();
        } else {
          unlayer.exportHtml((data) => {
            this.emailForm.patchValue({
              htmlPart: data['html'],
              designPart: data['design'],
            });
            this.sendBulkEmail();
          });
        }
        return;
      }
    } catch (error) {
      console.log('Error: ', error);
      this._loaderService.stop();
    }
  }

  checkCampaign() {
    this._loaderService.start();
    this.isCampaignExists = false;
    this._sharedService
      .checkCampaign({
        name: this.emailForm.value.campaignName,
        moduleId: this.data.moduleId,
      })
      .subscribe(
        (response: ResponseModel) => {
          if (response.statusCode === 200) {
            this.isCampaignExists = true;
          }
          this._loaderService.stop();
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            if (!err?.error?.data) {
              this.isCampaignExists = false;
              this.formStep = 3;
            } else {
              this._toastrService.error(
                err.error.message || this.messageConstant.unknownError,
                ''
              );
            }
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
  }

  sendBulkEmail(testEmail: any[] = []) {
    if (this.isSendEmail) return;

    if (!testEmail?.length) this.isSendEmail = true;

    const { campaignName, fromName, fromEmail, subject, htmlPart } =
      this.emailForm.value;

    setTimeout(() => {
      if (document.querySelector('.re-sendbulk-dialog'))
        document.querySelector('.re-sendbulk-dialog')['click']();
    }, 500);

    let obj = {
      campaignName,
      fromName,
      fromEmail: fromEmail?.toLowerCase(),
      subject,
      htmlPart: this.isPreviousTemplateActive
        ? this.data?.previousCampaign?.htmlContent
        : htmlPart,
      unSubscribeButton: `<a class="Unsubscribe--unsubscribeLink" href="{{{unsubscribe}}}" target="_blank" style="font-family:sans-serif;text-decoration:none;">Unsubscribe</a></body>`,
      isFilter: false,
      searchStr: this.data.searchStr,
      moduleId: this.data.moduleId,
    };

    obj['to'] = this.data.items
      .map((user) => {
        if (user.email) {
          return {
            _id: user?._id,
            email: user.email,
            firstName: this._utilities.capitalizeName(user.firstName),
            lastName: this._utilities.capitalizeName(user.lastName),
            contactNumber: user.contactNumber,
            company: user.company,
          };
        }
      })
      .filter((x) => x);

    if (testEmail?.length) {
      obj['to'] = testEmail
        .map((x) => {
          return {
            ...x,
            contactNumber:
              environment.countryCode +
              this._utilities.unMaskNumber(x.contactNumber),
            firstName: this._utilities.capitalizeName(x.firstName),
            lastName: this._utilities.capitalizeName(x.lastName),
          };
        })
        .filter((x) => x);

      obj['isDummy'] = true;
    }

    if (!testEmail?.length) {
      obj['isFilter'] =
        this.data?.selectActionType !== 'All' ? false : this.data?.isFilter;

      obj['filterData'] = this.data?.isFilter
        ? {
            ...this.data?.filterObj?.filterData,
            email: { value: 'yes', operator: 'is' },
          }
        : { email: { value: 'yes', operator: 'is' } };

      if (this.data?.selectActionType === 'All') {
        obj['isFilter'] = true;
        obj['to'] = [];
      }
    }

    this._loaderService.start();
    this._sharedService.sendBulkEmail(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          this._toastrService.success(response.data);
          this.isSendEmail = false;
          if (!testEmail?.length) {
            this._dialogRef.close('campaign');
          }
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        this.isSendEmail = false;
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(
            error.message || this.messageConstant.unknownError,
            ''
          );
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  setValidators(fields) {
    fields.filter((field) => {
      if (field === 'fromEmail') {
        this.emailForm
          .get(field)
          .setValidators([
            Validators.required,
            Validators.pattern(MiscellaneousConstant.emailPatternWithCaps),
          ]);
      } else {
        this.emailForm.get(field).setValidators([Validators.required]);
      }
    });
  }

  unsetValidators(fields) {
    fields.filter((field) => {
      this.emailForm.get(field).clearValidators();
      this.emailForm.get(field).setErrors(null);
    });
  }

  cancel() {
    this._dialogRef.close(true);
  }

  selectPreviousCampaign() {
    this.isPreviousTemplateActive = true;
    this.selectedTemplateIndex = this.templates.length;
  }
}
