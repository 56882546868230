// MODULES
import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';

// SERVICES
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PublicService } from 'src/app/providers/public/public.service';
import { ToastrService } from 'ngx-toastr';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { SharedService } from 'src/app/shared/shared.service';

// UTILS
import { MessageConstant } from 'src/app/utils/message-constant';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { UpgradeAccountComponent } from '../dialog/upgrade-account/upgrade-account.component';
import { DeleteDialogComponent } from '../dialog/delete-dialog/delete-dialog.component';
import { AddEditCustomSectionComponent } from 'src/app/modules/preference/custom-form-builder/dialog/add-edit-custom-section/add-edit-custom-section.component';
import { AddEditCustomQuestionComponent } from 'src/app/modules/preference/custom-form-builder/dialog/add-edit-custom-question/add-edit-custom-question.component';

@Component({
  selector: 'app-preference-question',
  templateUrl: './preference-question.component.html',
  styleUrls: ['./preference-question.component.scss'],
})
export class PreferenceQuestionComponent implements OnInit {
  @Input() style: any;
  @Input() preferenceData: any = {};
  @Input() preferenceName: string = '';
  @Input() preferenceForm: FormGroup;
  @Input() preferenceDetails: any = {};
  @Input() duplicateLead: any;
  @Input() webFormLink: string = '';
  @Input() preferenceType: string = '';
  @Input() type: any = {};
  @Input() draggable: any = {};
  @Input() groups: any[] = [];
  @Input() preferenceGroups: any[] = [];
  @Input() collapsedSections: any[] = [];
  @Input() viewPropertyList: any;
  @Input() uploadTitle: string = '';
  @Input() currentModule: string = '';
  @Input() isEdit: boolean = false;
  @Input() isEditIconView: boolean = false;
  @Input() isToggleIconView: boolean = false;
  @Input() listStackingInfo: boolean = false;
  @Input() uploadQuestionsTag: boolean = false;
  @Input() isAddStyle: boolean = false;
  @Input() isEditIconHover: boolean = false;
  @Input() isAuthenticated: boolean = false;
  @Input() isSubmitButton: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() updateApiCall: boolean = false;
  @Input() isCustomPreference: boolean = false;
  @Input() isCustomPermission: boolean = false;

  @Output() _emitAction = new EventEmitter<any>();
  @Output() _emitSubmitForm = new EventEmitter<any>();

  messageConstant = MessageConstant;
  visibleType: string = '';
  promises: any[] = [];
  dialogRef: any;
  _currentDraggableEvent: DragEvent;

  constructor(
    private _publicService: PublicService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    public _utilities: CommonFunctionsService,
    public _sharedService: SharedService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (
      this.preferenceType == 'leadWebForm' ||
      this.preferenceType == 'leadInfoForm'
    ) {
      this.visibleType = 'preferenceIds';
    } else if (this.preferenceType == 'buyerWebForm') {
      this.visibleType = 'group';
    }
  }

  initialize() {}

  /**
   * FUNCTION: TOGGLE SECTION
   * @param {String} groupIndex
   * @returns COLLAPS SECTION OF ARRAY
   */
  toggleSection(groupIndex) {
    const index = this.collapsedSections.findIndex((x) => x === groupIndex);
    if (index > -1) {
      this.collapsedSections.splice(index, 1);
    } else {
      this.collapsedSections.push(groupIndex);
    }
  }

  /**
   * FUNCTION: CHECK ICON VIEW
   * @param {String} groupIndex
   * @returns TRUE OR FALSE
   */
  isIconVisible(groupIndex) {
    const index = this.collapsedSections.findIndex((x) => x === groupIndex);
    return index > -1 ? true : false;
  }

  /**
   * FUNCTION: PREFERENCE QUESTION EDIT
   */
  editPreference() {
    this._emitAction.emit();
  }

  /**
   * FUNCTION: GET PREFERENCE TOOLTIP TITLE
   * @param {String} groupIndex
   * @returns TITLE TEXT
   */
  getPreferenceTitle(groupIndex) {
    let title;
    if (this.preferenceType == 'buyerWebForm') {
      title = this.groups[groupIndex]?.title;
    } else {
      title =
        this.groups[groupIndex]?.title +
        ' - ' +
        this.groups[groupIndex]?.description;
    }
    return { title };
  }

  /**
   * FUNCTION: CHECK QUESTION VISIBLE BY TYPE VISE
   * @param {String} type
   * @param {Boolean} val
   * @param {String} index
   * @param {Array} preference
   * @param {String} sectionType
   * @returns TRUE OR FALSE BY VISIBLE
   */
  isVisible(type, val?, index?, preference?, sectionType?) {
    try {
      if (val) {
        return true;
      }
      if (this.duplicateLead) {
        return true;
      }

      if (this.preferenceType == 'buyerWebForm') {
        if (type === 'preferenceIds') {
          const groupIndex =
            this.preferenceDetails?.webFromData[type]?.indexOf(index);

          const questions = Object.assign([], this.getQuestion(preference));
          const allQuestionsIds = questions.map((x) => x.value.questionId);
          const questionIds =
            this.preferenceDetails?.webFromData['questionIds'];

          const difference = allQuestionsIds.filter((x) =>
            questionIds.includes(x)
          );

          if (difference.length) {
            if (groupIndex > -1) {
              if (sectionType === 'custom') {
                return !(allQuestionsIds.length === difference.length);
              }

              if (sectionType === 'none') return true;
              return allQuestionsIds.length === difference.length;
            } else {
              if (sectionType === 'custom' || sectionType === 'none')
                return true;
              else return false;
            }
          }
          return false;
        } else {
          const key = type === 'group' ? 'preferenceIds' : type;
          return this.preferenceDetails?.webFromData &&
            this.preferenceDetails?.webFromData[key]?.indexOf(index) > -1
            ? true
            : false;
        }
      } else if (this.preferenceType == 'leadWebForm') {
        if (type === 'preferenceIds' || type === 'questionIds') {
          return this.preferenceDetails?.webFromData &&
            this.preferenceDetails?.webFromData[type]?.indexOf(index) > -1
            ? true
            : false;
        }

        return (
          this.preferenceDetails?.webFromData &&
          this.preferenceDetails?.webFromData[type]
        );
      } else if (this.preferenceType == 'leadInfoForm') {
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  /**
   * FUNCTION: CHECK QUESTION VISIBLE BY GROUP VISE
   * @param {String} groupIndex
   * @param {Boolean} isAuthenticate
   * @returns TRUE OR FALSE
   */
  getQuestionVisible(groupIndex, isAuthenticate) {
    let check = false;
    if (this.preferenceType == 'leadInfoForm') {
      return true;
    }
    if (!isAuthenticate) {
      if (this.duplicateLead) {
        return true;
      }
      for (var i = 0; i < groupIndex?.fields.length; i++) {
        if (
          this.preferenceDetails?.webFromData['questionIds']?.indexOf(
            groupIndex?.fields[i]['_id']
          ) > -1
        ) {
          check = true;
        }
      }
    } else {
      check = true;
    }
    return check;
  }

  /**
   * FUNCTION: CUSTOM SET QUESTION FOR HIDE OR VISIBLE
   * @param {String} groupIndex
   * @returns WHICH TYPE SET
   */
  setCustom(groupIndex) {
    this.type[groupIndex] = 'custom';
  }

  /**
   * FUNCTION: SET ALL QUESTION BY HIDE OR VISIBLE
   * @param {String} type
   * @param {String} index
   * @param {Boolean} bool
   * @param {Array} preference
   * @param {String} groupIndex
   * @returns SET HIDE OR VISIBLE QUESTION ICON
   */
  toggleVisibility(type, index?, bool?, preference?, groupIndex?) {
    let obj = {};
    let hiddenQuestion = true;
    let arr = this.preferenceDetails?.webFromData[type];
    let callFunction;
    const fieldIndex = this.preferenceDetails?.webFromData[type].indexOf(index);
    if (this.preferenceType == 'leadWebForm') {
      if (type === 'preferenceIds' || type === 'questionIds') {
        if (fieldIndex > -1) {
          arr.splice(fieldIndex, 1);
          hiddenQuestion = false;
        } else {
          arr.push(index);
          hiddenQuestion = true;
        }
        obj = {
          [type]: arr,
        };
      } else {
        obj = {
          [type]: this.preferenceDetails?.webFromData[type] ? 0 : 1,
        };
      }
      callFunction = this._publicService.editWebform(
        obj,
        this.preferenceDetails?.webFromData?._id,
        this.webFormLink
      );
    } else if (this.preferenceType == 'buyerWebForm') {
      if (type === 'preferenceIds') {
        delete this.type[groupIndex];
      } else {
        const group = this.groups.findIndex((x) => x._id === groupIndex);
        if (group > -1 && this.isAvailable(group, preference)) {
          return;
        }
      }
      if (!bool) {
        if (fieldIndex > -1) {
          arr.splice(fieldIndex, 1);
        }
        hiddenQuestion = false;
      } else {
        arr.push(index);
        hiddenQuestion = true;
      }
      obj = {
        [type]: arr,
      };
      const questionIds = Object.assign([], this.getQuestion(preference));
      const ids = questionIds.map((x) => x.value.questionId);
      if (type === 'preferenceIds') {
        const webFormQuestions =
          this.preferenceDetails.webFromData['questionIds'];
        if (bool) {
          obj['questionIds'] = [...webFormQuestions, ...ids];
        } else {
          const questions = webFormQuestions.filter((x) => !ids.includes(x));
          obj['questionIds'] = questions;
        }
      } else {
        if (bool) {
          obj['preferenceIds'] = [
            ...this.preferenceDetails?.webFromData['preferenceIds'],
            groupIndex,
          ];
        } else {
          const unique = [...new Set([...obj['questionIds']])];
          const difference = ids.filter((x) => !unique.includes(x));
          if (difference.length === ids.length) {
            obj['preferenceIds'] = this.preferenceDetails?.webFromData[
              'preferenceIds'
            ]?.filter((x) => x != groupIndex);
          }
        }
      }

      if (obj['questionIds']) {
        obj['questionIds'] = [...new Set([...obj['questionIds']])];
        this.preferenceDetails.webFromData['questionIds'] = obj['questionIds'];
      }

      if (obj['preferenceIds']) {
        obj['preferenceIds'] = [...new Set([...obj['preferenceIds']])];
        this.preferenceDetails.webFromData['preferenceIds'] =
          obj['preferenceIds'];
      }
      if (!this.updateApiCall) {
        callFunction = this._publicService.saveWebForm(obj, this.webFormLink);
      } else {
        callFunction = this._publicService.saveWebsiteWebForm(
          obj,
          this.webFormLink
        );
      }
    }

    this.updateWebForm(callFunction, type, hiddenQuestion);
  }

  /**
   * FUNCTION: UPDATE FOR QUESTION HIDE OR VISIBLE VIEW
   * @param {String} callFunction
   * @param {String} type
   * @param {Boolean} hiddenQuestion
   * @returns QUESTION UPDATE FOR HODE OR VISIBLE SUCCESS OR FAIL
   */
  updateWebForm(callFunction, type?, hiddenQuestion?) {
    this._loaderService.start();
    callFunction.subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          this.preferenceDetails.webFromData[type] = response.data[type];
          if (type === 'preferenceIds') {
            this._toastrService.success(
              hiddenQuestion
                ? this.messageConstant.webFormShow
                : this.messageConstant.webFormHide
            );
          } else if (type === 'questionIds') {
            this._toastrService.success(
              hiddenQuestion
                ? this.messageConstant.webFormQuestionShow
                : this.messageConstant.webFormQuestionHide
            );
          } else {
            if (this.preferenceDetails.webFromData[type] == 0) {
              this._toastrService.success(
                this.messageConstant.webFormFieldHide
              );
            } else {
              this._toastrService.success(
                this.messageConstant.webFormFieldShow
              );
            }
          }
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  /**
   * FUNCTION: CHECK QUESTION HIDE OR VISIBLE
   * @param {String} groupIndex
   * @param {Array} preference
   * @returns TRUE OR FALSE
   */
  isAvailable(groupIndex, preference) {
    if (
      this.isActive('custom', groupIndex, preference) ||
      this.type[groupIndex] === 'custom'
    ) {
      return false;
    }
    return true;
  }

  /**
   * FUNCTION: CHECK TYPE OF QUESTION ALL OR CUSTOM
   * @param {String} type
   * @param {String} groupIndex
   * @param {Array} preference
   * @returns QUESTION TYPE TRUE AND FALSE
   */
  isActive(type, groupIndex, preference) {
    if (this.type[groupIndex]) {
      return this.type[groupIndex] === type;
    } else {
      const bool = this.isGroupActive(
        this.groups,
        groupIndex,
        preference,
        type
      );
      return type === 'all' || type === 'custom' ? bool : !bool;
    }
  }

  /**
   * FUNCTION: CHECK QUESTION GROUP AVAILABLE
   * @param {Array} groups
   * @param {String} groupIndex
   * @param {Array} preference
   * @param {String} type
   * @returns BOOLEAN VALUE
   */
  isGroupActive(groups, groupIndex, preference, type) {
    return this.isPreferenceVisible(
      'preferenceIds',
      '',
      groups[groupIndex]?._id,
      preference,
      type
    );
  }

  /**
   * FUNCTION: CHECK PREFERENCE QUESTION VISIBLE
   * @param {String} type
   * @param {String} val
   * @param {String} index
   * @param {Array} preference
   * @param {String} sectionType
   * @returns BOOLEAN VALUE
   */
  isPreferenceVisible(type, val?, index?, preference?, sectionType?) {
    try {
      if (val) {
        return true;
      }

      if (type === 'preferenceIds') {
        const groupIndex =
          this.preferenceDetails?.webFromData[type]?.indexOf(index);

        const questions = Object.assign([], this.getQuestion(preference));
        const allQuestionsIds = questions.map((x) => x.value.questionId);
        const questionIds = this.preferenceDetails?.webFromData['questionIds'];

        const difference = allQuestionsIds.filter((x) =>
          questionIds.includes(x)
        );

        if (difference.length) {
          if (groupIndex > -1) {
            if (sectionType === 'custom') {
              return !(allQuestionsIds.length === difference.length);
            }

            if (sectionType === 'none') return true;
            return allQuestionsIds.length === difference.length;
          } else {
            if (sectionType === 'custom' || sectionType === 'none') return true;
            else return false;
          }
        }

        return false;
      } else {
        const key = type === 'group' ? 'preferenceIds' : type;
        return this.preferenceDetails?.webFromData &&
          this.preferenceDetails?.webFromData[key]?.indexOf(index) > -1
          ? true
          : false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  /**
   * FUNCTION: GET ALL PREFERENCE QUESTION LIST
   * @param {Array} form
   * @returns ARRAY OF QUESTION
   */
  getQuestion(form) {
    return form.controls.questions.controls;
  }

  /**
   * FUNCTION: GET VALUE OF QUESTION TITLE
   * @param {String} groupIndex
   * @param {String} questionIndex
   * @param {String} type
   * @returns QUESTION TITLE
   */
  getValue(groupIndex, questionIndex, type) {
    if (
      this.groups[groupIndex] &&
      this.groups[groupIndex].fields &&
      this.groups[groupIndex].fields[questionIndex]
    ) {
      return this.groups[groupIndex].fields[questionIndex][type] || '';
    }
  }

  /**
   * FUNCTION: GET AMOUNT FORMAT
   * @param {Number} element
   * @returns AMOUNT FORMAT
   */
  transformAmount(element) {
    var value = element.target.value.split('.');
    if (element.target.value && value[1]) {
      if (value[1].length == 2) {
        element.target.value = element.target.value;
      } else if (value[1].length == 1) {
        element.target.value = element.target.value + '0';
      } else {
        element.target.value = element.target.value + '00';
      }
    } else if (element.target.value != '$ ') {
      element.target.value = value[0] + '.00';
    }
  }

  /**
   * FUNCTION: SUBMIT BUTTON TEXT
   * @returns TEXT BY VIEW
   */
  onSubmitText() {
    let text;
    if (this.preferenceType == 'leadWebForm') {
      if (this.duplicateLead) {
        text = 'Update';
      } else {
        text = 'Add Lead';
      }
    } else if (this.preferenceType == 'buyerWebForm') {
      text = 'Add ' + this.preferenceForm?.value?.contactType;
    } else if (this.preferenceType == 'leadInfoForm') {
      text = 'Submit';
    }
    return { text };
  }

  /**
   * FUNCTION: SUBMIT FORM DATA
   */
  onSubmitForm() {
    this._emitSubmitForm.emit();
  }

  /**
   * FUNCTION: PREFERENCE EDIT CANCEL
   */
  cancel() {
    this._emitAction.emit();
  }

  /**
   * FUNCTION: REDIRECT URL
   */
  goToSite() {
    window.open('https://resimpli.com/', '_blank');
  }

  /**
   * FUNCTION: DRAG AND DROP START
   * @param {DragEvent} event
   */
  onDragStart(event: DragEvent) {
    this._currentDraggableEvent = event;
  }

  /**
   * FUNCTION: DRAG LIST
   * @param {Array} item
   * @param {Array} list
   * @param {String} effect
   */
  onDragged(item: any, list: any[], effect: DropEffect) {
    if (effect === 'move') {
      // const index = list.indexOf(item);
      // list.splice(index, 1);
    }
  }

  /**
   * FUNCTION: DROP SECTION AND QUESTION
   * @param {DndDropEvent} event
   * @param {Array} data
   * @param {String} type
   * @param {Number} groupIndex
   * @returns DROP SECTION AND QUESTION
   */
  onDrop = (event: DndDropEvent, data, type, groupIndex?): void => {
    this._loaderService.start();
    const { _id } = event.data;
    if (type == 'section') {
      const fromIndex = this.groups.findIndex((x) => x._id === _id);
      const toIndex = this.groups.findIndex((x) => x._id === data?._id);
      this.groups = this._utilities.reOrder(this.groups, fromIndex, toIndex);
      this.groups = this.groups.map((group, index) => {
        group.order = index;

        return group;
      });

      this.promises = [];

      this.groups.filter((x, index) => {
        let obj = {
          title: x.title,
          moduleId: this.currentModule,
          view: 1,
          order: index,
          isOrder: true,
        };

        this.promises.push(this.saveSectionOrder(obj, { _id: x._id }));
      });
    }

    if (type == 'field' && this.groups[groupIndex]?.fields) {
      const fromIndex = this.groups[groupIndex].fields.findIndex(
        (x) => x._id === event.data?._id
      );
      const toIndex = this.groups[groupIndex].fields.findIndex(
        (x) => x._id === data?._id
      );
      this.groups[groupIndex].fields = this._utilities.reOrder(
        this.groups[groupIndex].fields,
        fromIndex,
        toIndex
      );

      this.groups[groupIndex].fields = this.groups[groupIndex].fields.map(
        (field, index) => {
          field.order = index;

          return field;
        }
      );

      this.promises = [];

      this.groups[groupIndex].fields.filter((x, index) => {
        let obj = {
          ...x,
          order: index,
        };

        Object.keys(obj).forEach(
          (key) => (obj[key] === '' || obj[key] === null) && delete obj[key]
        );

        delete obj['createdAt'];
        delete obj['createdBy'];
        delete obj['isDeleted'];
        delete obj['isEnabled'];
        delete obj['isUpdated'];
        delete obj['updatedAt'];
        delete obj['__v'];
        delete obj['_id'];

        this.promises.push(this.saveQuestionOrder(obj, { _id: x._id }));
      });
    }

    // HANDLE PROMISE
    if (this.promises.length > 0) {
      Promise.all(this.promises)
        .then(() => {
          let successEditMessage = '';
          if (type === 'section') {
            successEditMessage = this.messageConstant.customSectionUpdateSuccess
              .replace('[[type]]', 'Section')
              .replace('[[status]]', 'Updated');
          } else {
            successEditMessage = this.messageConstant.customSectionUpdateSuccess
              .replace('[[type]]', 'Question')
              .replace('[[status]]', 'Updated');
          }
          this._toastrService.success(successEditMessage, '');
          this._loaderService.stop();
        })
        .catch((err) => {
          this._loaderService.stop();
          if (err?.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        });
    }
  };

  /**
   * FUNCTION: SAVE DROP SECTION ORDER
   * @param {Object} obj
   * @param {String} group
   * @returns DROP SECTION ORDER LIST
   */
  saveSectionOrder(obj, group) {
    return new Promise((resolve, reject) => {
      this._sharedService.saveSection(obj, group).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            resolve(true);
          }
        },
        (err: ErrorModel) => {
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
          reject(false);
        }
      );
    });
  }

  /**
   * FUNCTION: SAVE DROP QUESTION ORDER
   * @param {Object} obj
   * @param {String} question
   * @returns DROP QUESTION ORDER LIST
   */
  saveQuestionOrder(obj, question) {
    return new Promise((resolve, reject) => {
      this._sharedService.saveQuestion(obj, question).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            resolve(true);
          }
          this._loaderService.stop();
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
          reject(false);
        }
      );
    });
  }

  /**
   * FUNCTION: UPGRADE ACCOUNT DIALOG
   */
  upgradeAccount() {
    this._dialog.open(UpgradeAccountComponent, {
      disableClose: true,
      width: '350px',
      data: {
        link: '',
      },
    });
  }

  /**
   * FUNCTION: ADD EDIT CUSTOM SECTION DIALOG
   * @param {Array} group
   * @param {Number} index
   * @returns NEW ADD CUSTOM SECTION LIST
   */
  addEditCustomSection(group, index) {
    if (!this.isCustomPermission) {
      this.upgradeAccount();
      return;
    }

    this.dialogRef = this._dialog.open(AddEditCustomSectionComponent, {
      width: '500px',
      data: {
        type: 'Update Custom Section',
        group,
        moduleId: this.currentModule,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (group) {
          this.groups[index].title = result?.title;
          this.groups[index].description = result?.description;
        }
      }
    });
  }

  /**
   * FUNCTION: ADD EDIT CUSTOM QUESTION DIALOG
   * @param {Array} group
   * @param {String} question
   * @param {Number} index
   * @returns NEW ADD CUSTOM QUESTION LIST
   */
  addEditCustomQuestion(group, question?, index?) {
    if (!this.isCustomPermission) {
      this.upgradeAccount();
      return;
    }

    this.dialogRef = this._dialog.open(AddEditCustomQuestionComponent, {
      width: '600px',
      data: {
        type: `${question ? 'Update' : 'Add'} Custom Question`,
        group,
        moduleId: this.currentModule,
        question: question
          ? question
          : {
              view: 1,
              questionType: 'TEXT',
              characterLimit: 150,
            },
        isAdd: question ? 'edit' : 'add',
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (question) {
          group.fields[index] = result;
        } else {
          if (!group.fields) {
            group.fields = [result];
          } else {
            group.fields.push(result);
          }
        }
      }
    });
  }

  /**
   * FUNCTION: DELETE CUSTOM SECTION AND QUESTION
   * @param {Boolean} bool
   * @param {Object} group
   * @param {Number} index
   * @param {Object} field
   */
  deleteSectionAndQuestion(bool, group, index, field?) {
    this.dialogRef = this._dialog.open(DeleteDialogComponent, {
      width: '550px',
      data: {
        subModule: `${bool ? 'Custom Section' : 'Custom Question'}`,
        subType: `${bool ? 'customSection' : 'customQuestion'}`,
        details: bool ? group : field,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (bool) {
          this.groups.splice(index, 1);
          this.deleteCustomSection(result);
        } else {
          group.fields.splice(index, 1);
          this.deleteCustomField(result)
        }
      }
    });
  }

  deleteCustomSection(id) {    
    const obj = {
      masterGroupId: id,
    };
    this._sharedService.deleteCustomSection(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._toastrService.success(
            this.messageConstant.customSectionUpdateSuccess
              .replace('[[type]]', 'Section')
              .replace('[[status]]', 'Deleted'),
            ''
          );
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  deleteCustomField(id) {
    const obj = {
      masterQuestionId: id,
    };
    this._sharedService.deleteCustomQuestion(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._toastrService.success(
            this.messageConstant.customSectionUpdateSuccess
              .replace('[[type]]', 'Question')
              .replace('[[status]]', 'Deleted'),
            ''
          );
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
}
