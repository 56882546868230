import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../shared.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { LeadsService } from 'src/app/providers/leads/leads.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DashboardService } from 'src/app/providers/dashboard/dashboard.service';
import { ResponseModel } from '../../../utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-pinned-notes-view-dialog',
  templateUrl: './pinned-notes-view-dialog.component.html',
  styleUrls: ['./pinned-notes-view-dialog.component.scss'],
})
export class PinnedNotesViewDialogComponent implements OnInit {
  messageConstant = MessageConstant;
  totalPinCommentCount: number = 0;
  pinCommentData = [];
  dialogRef: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _toastrService: ToastrService,
    private _sharedService: SharedService,
    public _utilities: CommonFunctionsService,
    private _dialog: MatDialog,
    private _leadService: LeadsService,
    private _loaderService: NgxUiLoaderService,
    private _dashboardService: DashboardService,
    private _dialogRef: MatDialogRef<PinnedNotesViewDialogComponent>
  ) {}

  ngOnInit(): void {
    this.getPinnedCountActivity();
  }

  getTitle(fname, lname) {
    let title =
      this._utilities.capitalizeName(fname) +
      ' ' +
      this._utilities.capitalizeName(lname);

    return title;
  }

  getPinnedCountActivity() {
    let obj = {
      moduleId: this.data?.moduleId,
      subModuleId: this.data?.subModuleId,
      activityType: 'notes',
      holdingSort: false,
      isPinned: true,
      page: 1,
      limit: 1000,
    };
    if (this.data.isInventory) {
      obj['isInventory'] = this.data.isInventory;
    }
    this._loaderService.start();
    this._sharedService.getActivityLog(obj).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode == 200) {
          this.totalPinCommentCount = response.data?.count || 0;
          this.pinCommentData = response?.data?.items;
          this._sharedService.refreshSubHeaderPinnedCount.next({
            totalPinCommentCount: this.totalPinCommentCount,
          });
          this._sharedService.refreshPinnedCountActivity.next({});
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  setPinnedComment(item) {
    let param = {
      pinnedUser: item.pinnedUser,
      isPinned: item.isPinned,
      commentId: item._id,
    };

    this._loaderService.start();
    this._dashboardService.setCommentPinned(param).subscribe(
      (response: ResponseModel) => {
        if (item.isPinned) {
          this._sharedService.refreshActivityLog.next(true);
        }
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this._toastrService.success(response?.data, '');
          item.isPinned = !item.isPinned;
        } else {
          this._toastrService.error(response?.message, '');
        }
        this.getPinnedCountActivity();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  replaceLineBreak(name: string) {
    return name; //?.replace(/\<br\>/g, '');
  }

  onEdit(data) {
    this._dialogRef.close({ action: 'edit', data: data });
  }

  onDelete(pinData) {
    this.dialogRef = this._dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: {
        subModule: 'comment',
        subModuleId: this.data.subModuleId,
        moduleId: this.data?.moduleId,
        details: pinData,
        id: pinData._id,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteLogs(pinData?._id);
      }
    });
  }

  deleteLogs(id) {
    let serviceFunction = this._sharedService.deleteActivityLogs;
    serviceFunction(id).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200 || response?.code == 200) {
          if (
            response?.message == 'Success!' ||
            response?.message == 'Activity Deleted Successfully'
          ) {
            this._sharedService.refreshActivityLog.next(true);
            this.getPinnedCountActivity();
          }

          this._toastrService.success(
            this.messageConstant.activityLogsDeletedSuccess
          );
        }
        this._loaderService.stop();
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
}
