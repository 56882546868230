<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      {{ data?.header }}
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<mat-dialog-content class="">
  <div class="container">
    <div class="row">
      <div class="col-12 mb-3">
        <div class="re-information" *ngIf="data?.text">
          <p [innerHTML]="data?.text"></p>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions
  class="{{ data?.actionButtonAlignment || 'text-center' }}"
  style="padding-top: 14px !important"
  *ngIf="!data?.hideNoButton || !data?.hideYesButton || data?.buttons?.length"
>
  <ng-container *ngIf="data?.buttons; else else_block">
    <button
      mat-raised-button
      mat-dialog-close
      color="primary"
      *ngFor="let button of data?.buttons"
    >
      {{ button?.title }}
    </button>
  </ng-container>

  <ng-template #else_block>
    <div *ngIf="isInAppAnswering">
      <div class="re-typeform">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="confirmText"
          placeholder="Type 'confirm'"
        />
        <button type="button" (click)="submit()" class="btn">
          Process Payment
        </button>
      </div>
      <div
        class="text-danger small text-left"
        *ngIf="isSubmit && confirmText.toLowerCase() != 'confirm'"
      >
        Please kindly write "confirm" in the box, to proceed.
      </div>
    </div>
    <button
      mat-raised-button
      [mat-dialog-close]="false"
      class="re-cancelbtn"
      *ngIf="noButtonTitle && !data?.hideNoButton && !isInAppAnswering"
      (click)="onDismiss()"
    >
      {{ noButtonTitle }}
    </button>
    <button
      (click)="submit()"
      mat-raised-button
      color="primary"
      *ngIf="yesButtonTitle && !data?.hideYesButton && !isInAppAnswering"
      class="{{ data?.confirmButtonClass || '' }}"
      cdkFocusInitial
    >
      {{ yesButtonTitle }}
    </button>
  </ng-template>
</mat-dialog-actions>
