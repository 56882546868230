import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mentionHighlight',
})
export class MentionHighlightPipe implements PipeTransform {
  transform(value: any): any {
    const mentionRegex = /@(\w+)/g; // Regular expression to find mention names starting with @
    const highlightedContent = value.replace(
      mentionRegex,
      '<span class="mention">$&</span>'
    );
    value = highlightedContent;
    let mention = '@';
    value = value.replace("&#xFEFF;", "");
    value = value.replace("&nbsp;", " ");
    let myArray = value.split(' ');
    for (let i = 0; i < myArray.length; i++) {
      let myArray2 = myArray[i].split('\n');
      for (let j = 0; j < myArray2.length; j++) {
        myArray2[j] = myArray2[j].trim();
        if (myArray2[j].charAt(0) == mention) {
          myArray2[j] = `<span class="re-highlight">${myArray2[j]}</span>`;
        }
      }
      myArray[i] = myArray2.join('');
    }

    return myArray.join(' ');
  }
}
