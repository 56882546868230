import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';
import { MessageConstant } from '../../../utils/message-constant';

import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharedService } from 'src/app/shared/shared.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  @Output() _emitterFunction: EventEmitter<any> = new EventEmitter<any>();
  tab: string = 'all';
  messageConstant = MessageConstant;

  showTabLoader: boolean = false;
  allNotifications: any = {};
  currentNotificationPage: number = 1;
  markasRead: boolean = false;
  tabId: any = {
    all: 0,
    'missed-calls': 45,
    tasks: 9,
    sms: 1,
    mentions: 8,
  };
  unreadCount: number = 0;
  constructor(
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    public _sharedService: SharedService,
    public _commonFunctions: CommonFunctionsService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    console.log('des');
  }

  onActive(type) {
    this.tab = type;
    this.initialize();
  }

  markRead() {}

  onScroll() {
    if (this.allNotifications?.count == this.allNotifications?.items?.length)
      return;

    this.currentNotificationPage += 1;
    this.getNotification();
  }

  initialize() {
    this.currentNotificationPage = 1;
    this.allNotifications['items'] = [];
    this.allNotifications['count'] = 0;
    this.getNotification();
  }

  getNotification() {
    let obj = {
      page: this.currentNotificationPage,
      limit: 10,
      activityType: this.tabId[this.tab],
    };
    this.showTabLoader = true;
    this._sharedService.getAllNotifications(obj).subscribe(
      (response) => {
        if (response?.statusCode == 200) {
          this.showTabLoader = false;

          if (this.tab == 'all') {
            this._emitterFunction.emit({
              count: response?.data?.unreadCount || 0,
            });
            //return;
          }
          response?.data?.items?.map((n)=>{
            if(n?.activityType==9){
              n['taskDetail']=`<p><b>${n['comment']}</b></p>${n['taskDetail']}`;
            }
            if(n?.leadTitle)
              n['leadTitle']=n?.leadTitle?.trim();
            return n;
          });
          if (!this.allNotifications?.items?.length)
            this.allNotifications['items'] = response?.data?.items;
          else {
            this.allNotifications['items'] = [
              ...this.allNotifications?.items,
              ...response?.data?.items,
            ];
          }
          this.allNotifications['count'] = response?.data?.count || 0;
          this.unreadCount = response?.data?.unreadCount || 0;
        }
      },
      (err) => {
        this.showTabLoader = false;
        if (err.error) {
          this._toastrService.error(err.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  markAsReadNotification(data?) {
    if (!this.allNotifications?.items?.length) return;

    //this.markasRead = !this.markasRead;

    let obj = {
      isReadMessage: true,
    };
    if (data) {
      obj['notificationIds'] = [data?.selectedNotification?._id];
    } else {
      obj['notificationIds'] = [];
      obj['markAllRead'] = true;
    }
    // this.allNotifications?.items?.filter((n) => {
    //   notificationIds.push(n?._id);
    // });

    this.showTabLoader = true;
    this._sharedService.markAllReadUnread(obj).subscribe(
      (response) => {
        if (response?.statusCode == 200) {
          this._toastrService.success('Marked as read');
          this.initialize();
          this._emitterFunction.emit(data);
        }
      },
      (err) => {
        this.showTabLoader = false;
        if (err.error) {
          this._toastrService.error(err.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  callBackFunction(e) {
    if (!e?.data?.isReadMessage) {
      this.markAsReadNotification({
        from: e?.from,
        redirectUrl: e?.redirectUrl || '',
        selectedNotification: e?.data,
      });
    } else {
      this._emitterFunction.emit({
        from: e?.from,
        redirectUrl: e?.redirectUrl || '',
        selectedNotification: e?.data,
      });
    }
  }
}
