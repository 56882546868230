import { Injector, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyPositive',
})
export class CurrencyPositivePipe implements PipeTransform {
  public constructor(private injector: Injector) {}

  transform(value: any, args?: any): any {
    if (value) {
      return value.charAt(0) == '-' ? value.substring(1, value.length) : value;
    }
  }
}
