// MODULES
import {
  Component,
  ElementRef,
  ViewChild,
  Inject,
  OnInit,
} from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

// UTILS
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharedService } from '../../shared.service';
import { TaggingService } from '../../../providers/tagging/tagging.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

@Component({
  selector: 'app-add-tags',
  templateUrl: './add-tags.component.html',
  styleUrls: ['./add-tags.component.scss'],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class AddTagsComponent implements OnInit {
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  messageConstant = MessageConstant;

  public colorCodes = MiscellaneousConstant.colorCodes;
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredTags: Observable<any[]>;
  tags: any[] = [];
  allTagList: any[] = [];
  unavailableColorIndex: any[] = [];
  unavailableColorIndexResponse: any[] = [];
  isShowPlaceHolder: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<AddTagsComponent>,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private _taggingService: TaggingService,
    private _sharedService: SharedService,
    public _commonFunctionsService: CommonFunctionsService
  ) {
    console.log(this.data);
  }

  ngOnInit(): void {
    this.getAllTags();
    this.getUsedColorIndex();
  }

  checkForUnique(val) {
    let flag = true;
    for (let i = 0; i < this.tags.length; i++) {
      if (this.tags[i].label?.toLowerCase() == val.toLowerCase()) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  add(event: MatChipInputEvent): void {
    this.isShowPlaceHolder = false;
    const input = event.input;
    const value = event.value;
    if (this.checkForUnique(value)) {
      let index = -1;
      for (let i = 0; i < this.allTagList.length; i++) {
        if (this.allTagList[i].label?.toLowerCase() == value.toLowerCase()) {
          index = i;
          break;
        }
      }
      if (index >= 0) {
        this.tags.push(this.allTagList[index]);
      } else {
        if ((value || '').trim()) {
          this.tags.push({ _id: 'custom', label: value.trim() });
        }
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  remove(index) {
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.checkForUnique(event.option.value.label)) {
      this.tags.push(event.option.value);
      this.tagInput.nativeElement.value = '';
      this.tagCtrl.setValue(null);
      this.tagInput.nativeElement.blur();
    }
  }

  isVisible(tag) {
    const tagIndex = this.tags.findIndex((x) => x.label === tag.label);
    return tagIndex > -1 ? false : true;
  }

  private _filter(value) {
    let filterValue;
    if (value._id) {
      filterValue = value.label?.toLowerCase();
    } else {
      filterValue = value.toLowerCase();
    }

    return this.allTagList.filter(
      (tag) => tag.label.toLowerCase().indexOf(filterValue) === 0
    );
  }

  getAllTags() {
    this._loaderService.start();

    this._taggingService.getTags(this.data?.tagType).subscribe(
      (response) => {        
        this._loaderService.stop();
        if (response && response.statusCode == 200) {
          let arr = response.data;
          const result = arr.reduce((acc, d) => {
            if (d.label) {
              const value = { _id: d._id, label: d.label };

              acc.push(value);
            }

            return acc;
          }, []);
          this.allTagList = result;

          this.filteredTags = this.tagCtrl.valueChanges.pipe(
            startWith(null),
            map((tag) => (tag ? this._filter(tag) : this.allTagList.slice()))
          );
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
      }
    );
  }
  getUsedColorIndex() {
    this._loaderService.start();

    this._taggingService.getUsedTagsColor(this.data?.tagType).subscribe(
      (response) => {
        this._loaderService.stop();
        if (response && response.statusCode == 200) {
          this.unavailableColorIndex = response?.data;
          this.unavailableColorIndexResponse = [];
          let i, j;
          for (i = 0; i < this.colorCodes.length; i++) {
            this.unavailableColorIndexResponse.push({ _id: i, count: 0 });
            for (j = 0; j < this.unavailableColorIndex.length; j++) {
              if (this.unavailableColorIndex[j]._id == i) {
                this.unavailableColorIndexResponse[i].count =
                  this.unavailableColorIndex[j].count;
                break;
              }
            }
          }
          this.unavailableColorIndexResponse.sort(
            (a, b) => parseFloat(a.count) - parseFloat(b.count)
          );

          console.log(this.unavailableColorIndexResponse);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
      }
    );
  }
  filterData(data, mode) {
    let filteredData = [];
    if (mode == 1) {
      filteredData = data.filter(function (el) {
        return el._id != 'custom';
      });
    } else {
      filteredData = data.filter(function (el) {
        return el._id == 'custom';
      });
    }

    return filteredData;
  }
  getTagsToSave(tagsArr) {
    if (tagsArr.length) {
      let finalArr = [],
        colorIndex = 0;
      for (let i = 0; i < tagsArr.length; i++) {
        if (this.unavailableColorIndexResponse.length == colorIndex)
          colorIndex = 0;
        finalArr.push({
          tag: tagsArr[i],
          colorIndex: this.unavailableColorIndexResponse[colorIndex]?._id,
        });
        colorIndex++;
      }
      return finalArr;
    } else {
      return [];
    }
  }

  submitForm() {
    if (this.tags.length == 0) {
      this._toastrService.error("Tags can't be blank");
      return;
    }

    let existingTags = this.filterData(this.tags, '1');
    let newTags = this.filterData(this.tags, '0');

    const existingIds = existingTags.reduce((acc, d) => {
      acc.push(d._id);
      return acc;
    }, []);

    const tagsArr = newTags.reduce((acc, d) => {
      acc.push(d.label);
      return acc;
    }, []);

    let tagsToSave = this.getTagsToSave(tagsArr);

    this._loaderService.start();

    const { moduleId, leadId, tagType } = this.data;

    if (!tagsArr.length) {
      let obj = {
        moduleId: moduleId,
        leadId: leadId,
        tagIds: existingIds,
        isTagAdd: true,
        for: this.data?.for,
      };

      this.saveAllIds(obj);
      return;
    }

    let obj = {
      tagsArr: tagsToSave,
      tagType,
    };

    this._taggingService.addTags(obj).subscribe(
      (response) => {        
        if (response) {
          let finalIds = existingIds;
          for (let i = 0; i < response?.data?.length; i++) {
            if (response?.data[i] != null) finalIds.push(response?.data[i]?._id);
          }

          let data = {
            moduleId,
            leadId,
            tagIds: finalIds,
            isTagAdd: true,
            for: this.data?.for,
          };
          if (finalIds.length) this.saveAllIds(data);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        this._toastrService.error(
          err.message ? err.message : MessageConstant.unknownError
        );
      }
    );
  }

  saveAllIds(obj2) {    
    this._sharedService.addTags(obj2).subscribe(
      (response) => {
        this._loaderService.stop();        
        if (response) {
          this.dialog.close({});
          // this._toastrService.success(this.messageConstant.tagsAddedSuccess);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
      }
    );
  }

  onSearchChange(text) {
    this.filteredTags = new Observable((ob) => {
      ob.next(
        this.allTagList.filter((x) => {
          if (x?.label?.toLowerCase().includes(text?.toLowerCase())) {
            return x;
          }
        })
      );
    });
    if (text) {
      let filterArray = this.allTagList.filter((x) => {
        return x?.label === text;
      });
      if (filterArray?.length == 0) {
        this.isShowPlaceHolder = true;
      } else {
        this.isShowPlaceHolder = false;
      }
    } else {
      this.isShowPlaceHolder = false;
    }
  }
}
