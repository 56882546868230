<div class="re-top-head">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      Notes
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<div
  class="re-note-address"
  *ngIf="data?.address || data?.mainStatusTitle"
>
  <span class="re-txt1" *ngIf="data?.address">{{
    _commonFunctionsService.formatAddress(data?.address, data?.unitNo)
  }}</span>
  <span class="re-txt2" *ngIf="data?.mainStatusTitle && data?.type"
    >{{ data?.type }} > {{ data?.mainStatusTitle }}</span
  >
</div>
<div class="container-fluid">
  <div class="box">
    <div class="row">
      <div class="col-12 col-md-12 col-sm-12">
        <div role="tablist" class="nav">
          <a
            href="#tabs11"
            role="tab"
            data-toggle="tab"
            aria-expanded="false"
            class="re-tablink active"
            >Add a Note</a
          >
          <a
            href="#tabs22"
            role="tab"
            data-toggle="tab"
            aria-expanded="false"
            class="re-tablink"
            >Previous notes</a
          >
        </div>
        <div class="tab-content">
          <div role="tabpanel" id="tabs11" class="tab-pane active">
            <div class="form-group mt-2">
              <label class="labels">Note</label>
              <textarea
                #note
                class="form-control"
                rows="4"
                placeholder="Type message here. Use @ to tag a team member"
                [(ngModel)]="comment"
                [mention]="userListing"
                [mentionConfig]="{
                  triggerChar: '@',
                  maxItems: 100,
                  insertHTML: true,
                  labelKey: 'firstName',
                  dropUp: 'true',
                  mentionSelect: tagUser,
                  allowSpace: true
                }"
              ></textarea>
            </div>
            <mat-dialog-actions
              align="center"
              style="padding-top: 14px !important"
            >
              <button mat-raised-button mat-dialog-close>Cancel</button>
              <button mat-flat-button color="primary" (click)="addLogs()">
                Add Note
              </button>
            </mat-dialog-actions>
          </div>
          <div role="tabpanel" id="tabs22" class="tab-pane">
            <activity-logs
              #activityLog
              [activityMainStyle]="{ height: 'CALC(100vh - 535px)' }"
              [style]="{ borderTop: 'none', height: 'CALC(100vh - 535px)' }"
              [moduleId]="data?.moduleId"
              [leadId]="data?.leadId"
              [leadData]="data?.leadData"
              [activityType]="activityType"
              [moduleName]="data?.type"
              [activityTabView]="false"
              [activityFilterView]="false"
              [activityQuillBox]="false"
              [isWebFormLink]="true"
            ></activity-logs>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
