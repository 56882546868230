import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// SERVICES
import { SharedService } from '../../shared.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
// UTILS
import { MessageConstant } from '../../../utils/message-constant';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';

@Component({
  selector: 'app-add-edit-group',
  templateUrl: './add-edit-group.component.html',
  styleUrls: ['./add-edit-group.component.scss'],
})
export class AddEditGroupComponent implements OnInit {
  messageConstant = MessageConstant;
  groupForm: FormGroup;
  submitted: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private _sharedService: SharedService,
    private _groupDailog: MatDialogRef<AddEditGroupComponent>,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.groupForm = this.fb.group({
      title: ['', Validators.required],
    });
  }
  public get f() {
    return this.groupForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.groupForm.invalid) {
      return;
    }
    let obj = {
      ...this.groupForm.value,
    };
    this._loaderService.start();
    if (this.data?.title == 'Add') {
      this._sharedService.addGroupNumber(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this._groupDailog.close(true);
            this._toastrService.success(this.messageConstant.groupAddSuccess);
          }
          this._loaderService.stop();
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
    } else {
      obj['groupId'] = this.data?.details?._id;
      this._sharedService.editGroupNumber(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this._groupDailog.close(response?.data);
            this._toastrService.success(
              this.messageConstant.groupUpdateSuccess
            );
          }
          this._loaderService.stop();
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
    }
  }
}
