<div class="re-audio-container" [class]="customCss">
  <button
    class="re-play-pause"
    (click)="play()"
    [disabled]="state?.error"
    *ngIf="!state?.playing"
  >
    <mat-icon mat-list-icon>play_circle_filled</mat-icon>
  </button>

  <button class="re-play-pause" (click)="pause()" *ngIf="state?.playing">
    <mat-icon mat-list-icon>pause</mat-icon>
  </button>

  <div class="re-seek-bar">
    <mat-slider
      class="re-time-slider"
      [class.w-80]="state?.duration"
      [class.w-100]="!state?.duration"
      min="0"
      [max]="state?.duration"
      step="1"
      [value]="state?.currentTime"
      (input)="onSliderChangeEnd($event)"
      [disabled]="state?.error"
    ></mat-slider>

    <span class="re-duration">
      {{ state?.readableDuration }}
    </span>
  </div>

  <!-- DOWNLOAD -->
  <button class="re-download" (click)="downloadAudio()">
    <i class="fa fa-download" aria-hidden="true"></i>
  </button>

  <!-- CHANGE AUDIO RATE -->
  <button class="re-playback-rate" (click)="changePlayBackRate()">
    {{ state.playbackRate }}x
  </button>
</div>
