import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public token = localStorage.getItem('token');
  constructor() { }

  isAuthenticated() {
    if (this.token != undefined) {
      return true;
    } else {
      return false;
    }
  } //isAuthenticated ends here

  getCurrentUser() {
    try {
      const user = localStorage.getItem('currentUser');

      if (user) {
        return JSON.parse(user);
      }
    } catch (error) {
      console.log("GET CURRENT USER: ", error)
    }
  }

  getToken() {
    try {
      return localStorage.getItem('token');
    } catch (error) {
      console.log("GET TOKEN: ", error)
    }
  }

}
