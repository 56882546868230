<div class="re-no-record" *ngIf="!allNotifications?.items?.length">
  No Records Found
</div>
<ng-container
  *ngFor="let notification of allNotifications?.items; let logIndex = index"
>
  <div class="re-media re-receive">
    <div class="re-media-left">
      <a href="javascript:void(0);">
        <img
          tooltipposition="bottom"
          class="p-element re-media-object"
          [src]="getNotificationUrl(notification)?.url"
          *ngIf="getNotificationUrl(notification)?.image"
        />
        <span
          *ngIf="!getNotificationUrl(notification)?.image"
          class="re-initials"
        >
          {{ getNotificationUrl(notification)?.url }}
        </span>
        <p
          *ngIf="
            notification?.activityType == 45 ||
            notification?.activityType == 1 ||
            notification?.activityType == 4 ||
            notification?.activityType == 8 ||
            notification?.activityType == 9 ||
            notification?.activityType == 43
          "
          [ngClass]="
            notification?.numberType == 1
              ? 're-num-type re-seller'
              : notification?.numberType == 2
              ? 're-num-type re-buyer'
              : notification?.numberType == 4
              ? 're-num-type re-vendor'
              : ''
          "
        >
          {{
            notification?.numberType == 1
              ? "S"
              : notification?.numberType == 2
              ? "B"
              : notification?.numberType == 4
              ? "V"
              : ""
          }}
        </p>
        <p class="re-unread-dot" *ngIf="!notification?.isReadMessage">&nbsp;</p>
      </a>
    </div>
    <div class="re-media-body">
      <div class="re-title-container">
        <h4 class="re-media-heading">
          <span class="re-sender-name">
            <ng-container
              *ngIf="
                notification?.activityType == 1 ||
                notification?.activityType == 4 ||
                notification?.activityType == 43 ||
                notification?.activityType == 45 ||
                notification?.activityType == 49 ||
                notification?.activityType == 50
              "
            >
              {{
                _commonFunctions.titleCaseToWord(notification?.leadTitle) ||
                  "N/A"
              }}
              •
              {{
                notification?.from
                  ? _commonFunctions.maskNumber(notification?.from)
                  : "N/A"
              }}
            </ng-container>
            <ng-container
              *ngIf="
                notification?.activityType == 44 ||
                notification?.activityType == 37 ||
                notification?.activityType == 46 ||
                notification?.activityType == 8 ||
                notification?.activityType == 9 ||
                notification?.activityType == 10 ||
                notification?.activityType == 18 ||
                notification?.activityType == 51 ||
                notification?.activityType == 52 ||
                notification?.activityType == 53
              "
            >
              {{ notification?.title || "N/A" }}
            </ng-container>
            <ng-container
              *ngIf="
                notification?.activityType == 42 ||
                notification?.activityType == 47
              "
            >
              {{
                notification?.leadTitle
                  ? notification?.leadTitle
                  : notification?.createdByUser?.name || "N/A"
              }}
              •
              {{
                notification?.createdByUser?.contactNumber || notification?.from
                  ? _commonFunctions.maskNumber(
                      notification?.leadTitle
                        ? notification?.from
                        : notification?.createdByUser?.contactNumber
                    )
                  : "N/A"
              }}
            </ng-container>
          </span>
        </h4>
        <span class="re-time">
          {{ notification?.createdAt | myTimezone }}
        </span>
      </div>
      <span class="re-names">
        <span> {{ getHighlightedData(notification) }} </span>
        <ng-container
          *ngIf="
            notification?.activityType != 1 &&
              notification?.activityType != 4 &&
              notification?.activityType != 45 &&
              notification?.activityType != 9 &&
              notification?.activityType != 8;
            else else_content
          "
        >
          <a
            class="re-link"
            [href]="getRedirectUrlOnly(notification, false)"
            (click)="getRedirectUrl(notification, false)"
            *ngIf="
              notification?.activityType == 37 ||
              notification?.activityType == 46
            "
          >
            {{
              _commonFunctions.titleCaseToWord(notification?.leadTitle) || "N/A"
            }}
          </a>
          <span
            *ngIf="
              notification?.activityType == 37 ||
              (notification?.activityType == 46 && notification?.address)
            "
            >•</span
          >
          <span *ngIf="notification?.offerPurchasePrice">
            {{ notification?.offerPurchasePrice | currency | currencyPositive }}
            •
          </span>
          <a
            class="re-link"
            [href]="getRedirectUrlOnly(notification, true)"
            (click)="getRedirectUrl(notification, true)"
            *ngIf="
              (notification?.activityType != 46 &&
                !notification?.isFromDripCam) ||
              (notification?.activityType == 46 && notification?.address)
            "
          >
            <ng-container *ngIf="notification?.activityType == 50"
              >Claim this lead •
            </ng-container>
            {{ _commonFunctions.formatAddress(notification?.address) || "N/A" }}
          </a>
        </ng-container>
        <ng-template #else_content>
          <a
            class="re-link"
            [href]="getRedirectUrlOnly(notification, false)"
            (click)="getRedirectUrl(notification, false)"
          >
            {{ callsRedirectText(notification) }}
          </a>
        </ng-template>
      </span>
      <span class="re-time-due" *ngIf="notification?.activityType == 9">
        Due: {{ notification?.dueTime | date : "mediumDate" }}
        {{ notification?.dueTime | date : "shortTime" }}</span
      >
      <div
        class="re-comment re-text"
        *ngIf="
          (notification?.activityType == 9 && notification?.taskDetail) ||
          (notification?.activityType != 9 && notification?.comment)
        "
        [innerHtml]="
          replaceLineBreak(
            notification?.activityType == 9
              ? (notification?.taskDetail | mentionHighlight | textToLink)
              : (notification?.comment | mentionHighlight | textToLink)
          ) | safe
        "
      ></div>
      <div class="row re-task-bottom" *ngIf="notification?.activityType == 9">
        <div class="col-md-12 re-task-section">
          <span class="re-task-type" *ngIf="notification?.taskType">
            {{
              _commonFunctions.getTaskTypeOption(notification?.taskType)
                ?.name || "N/A"
            }}
          </span>
        </div>
      </div>
      <a
        [ngClass]="
          notification?.activityType == 8
            ? notification?.isReadMessage
              ? 're-btn m-l-10'
              : 're-btn m-l-50'
            : notification?.isReadMessage
            ? 're-btn'
            : 're-btn m-l-80'
        "
        href="javascript:;"
        (click)="replySms(notification)"
        *ngIf="
          notification?.activityType == 1 ||
          notification?.activityType == 4 ||
          notification?.activityType == 8 ||
          notification?.activityType == 45
        "
      >
        <svg width="14" height="10" viewBox="0 0 14 10">
          <g transform="translate(0 -40.801)">
            <path
              d="M85.926 43.76v-2.354a.6.6 0 0 0-.393-.558.662.662 0 0 0-.694.131l-3.09 2.93a.582.582 0 0 0 0 .854l3.088 2.932a.654.654 0 0 0 .45.177.665.665 0 0 0 .243-.046.6.6 0 0 0 .393-.558v-2.294a5.373 5.373 0 0 1 4.909 5.226.637.637 0 0 0 1.273 0 6.617 6.617 0 0 0-6.179-6.44z"
              transform="translate(-78.105 -.001)"
            ></path>
            <path
              d="M1.536 44.335l2.639-2.5a.582.582 0 0 0 0-.854.661.661 0 0 0-.9 0l-3.09 2.93a.582.582 0 0 0 0 .854l3.088 2.932a.661.661 0 0 0 .9 0 .582.582 0 0 0 0-.854z"
            ></path>
          </g>
        </svg>
        Reply
      </a>

      <a
        [ngClass]="
          notification?.isReadMessage ? 're-btn-left' : 're-btn-left m-l-50'
        "
        href="javascript:;"
        (click)="makeCall(notification)"
        *ngIf="
          notification?.activityType == 1 ||
          notification?.activityType == 4 ||
          notification?.activityType == 45
        "
      >
        <img src="./assets/images/notification-missed.png" />
      </a>

      <img
        src="./assets/images/notification-mark.png"
        pTooltip="Mark as Read"
        tooltipPosition="left"
        (click)="markRead(notification)"
        class="re-hover-acions"
        *ngIf="!notification?.isReadMessage"
      />
    </div>
  </div>
</ng-container>
