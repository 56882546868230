import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseUrl } from '../../utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
@Injectable({
  providedIn: 'root',
})
export class EsignService {
  baseUrl = environment.baseUrl;
  redirectUrl = environment.appUrl + BaseUrl.eSignCreateTemplateRedirectLink;
  public token = localStorage.getItem('token');
  axiosdata: any = '';
  templatePartyRole: any = '';
  constructor(private _commonFunction: CommonFunctionsService) {}

  getEsign = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.esignUrl + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getEsignUrl = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.esignUrl + `get-esign-url/${data}`;
    return this._commonFunction.globalGetService(endpoint, data);
  };

  getEsignDocumentUrl = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.esignDocsUrl + `get-esign-docs-url/${data}`;
    return this._commonFunction.globalGetService(endpoint);
  };
  getDocument = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.esignUrl + 'docList';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getDocumentByLeadId = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.esignUrl + 'listleadid';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  saveEsign = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.esignUrl + 'save';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  editEsign = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.esignUrl + 'edit';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteEsign = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.esignUrl + 'delete';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteDocumentEsign = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.esignUrl + 'deletedoc';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  //Copy esign Template

  CopyesignTemplate = (data): Observable<any> => {
    let copyTemplateData = {
      esignToken: data?.esignToken || localStorage.getItem('esigntoken'),
      templateId: data?.templateId,
      templateUrl: data?.templateUrl,
      templateName: data?.templateName,
    };
    const endpoint = this.baseUrl + BaseUrl.esignUrl + 'esigngenieCopyTemplate';
    return this._commonFunction.globalPostService(endpoint, copyTemplateData);
  };

  //saveDocument
  saveDocFolder = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.esignUrl + 'savedoc';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  eSignCreateTemplate = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.esignUrl + 'eSignCreateTemplate';
    return this._commonFunction.globalPostService(
      endpoint,
      data,
      this.getRequestHeaders()
    );
  };

  eSignGetTemplateDetails = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.esignUrl + 'eSignGetTemplateDetails';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  eSignCreateFolderFromTemplate = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.esignUrl + 'eSignCreateFolderFromTemplate';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  eSignGetFolderDetails = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.esignUrl + 'eSignGetFolderDetails';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  eSignCopyTemplate = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.esignUrl + 'eSignCopyTemplate';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getFolderDetails = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.esignUrl + 'getFolderDetails';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  searchEsign = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.esignUrl + 'search';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  eSignDownloadFolder = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.esignUrl + 'eSignDownloadFolder';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  cancelDocument = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.esignUrl + 'cancelDocument';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  resendInvite = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.esignUrl + 'resendInvite';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  protected getRequestHeaders(): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    let headers;
    headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Redirect-Url': this.redirectUrl,
    });
    return { headers: headers };
  }
}
