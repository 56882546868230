<div class="re-setting-main">
  <div class="re-subdiv">
    <div class="re-setting-top">
      <div class="re-top-left">
        <h3>Settings</h3>
        <p>Manage different areas of settings</p>
      </div>
      <div class="re-top-right">
        <form method="post" class="re-searchbox">
          <div class="input-group">
            <span class="input-group-addon"
              ><span class="fa fa-search"></span></span
            ><input
              type="text"
              placeholder="Search Settings"
              class="form-control"
              (keyup)="setttingsFilter($event)"
            />
          </div>
        </form>
      </div>
    </div>
    <div class="re-setting-bottom">
      <div class="re-sub-box mb-3" *ngFor="let module of modules">
        <div class="re-sub-title">
          <h4>{{ module?.label }}</h4>
        </div>
        <a
          class="re-sub-link1"
          *ngFor="let menu of module.submenu"
          (click)="closePopOver(menu)"
          [class]="menu?.class"
        >
          <div class="re-sub-link2">
            <div class="re-subimg">
              <img [src]="menu?.icon" alt="manage-user" />
            </div>
            <p class="re-txt1">{{ menu?.label }}</p>
            <p class="re-txt2">{{ menu?.subText }}</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
