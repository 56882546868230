// MODULES
import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
} from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// UTILS
import { MessageConstant } from 'src/app/utils/message-constant';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharedService } from 'src/app/shared/shared.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { UserService } from 'src/app/providers/user/user.service';
import { ListStackingService } from 'src/app/providers/list-stacking/list-stacking.service';
import { LeadsService } from 'src/app/providers/leads/leads.service';

//Component
import { ActivityLogsComponent } from 'src/app/shared/activity-logs/activity-logs.component';

@Component({
  selector: 'app-take-note',
  templateUrl: './take-note.component.html',
  styleUrls: ['./take-note.component.scss'],
})
export class TakeNoteComponent implements OnInit {
  @ViewChild('note') note: ElementRef;
  @ViewChild('activityLog') activityLog: ActivityLogsComponent;
  currentActivityPage: number = 1;
  activityCurrentLimit: number = 20;
  messageConstant = MessageConstant;
  comment: string = '';
  userListing: any[] = [];
  userList: any[] = [];
  selectedUserId: string = '';
  selectedUserName: string = '';
  isUserListResponse: boolean = false;
  activityType: string = 'notes';
  currentUserId: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _takeNoteDialogRef: MatDialogRef<TakeNoteComponent>,
    private _sharedService: SharedService,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    public _commonFunctionsService: CommonFunctionsService,
    private _userService: UserService,
    private _listStackingService: ListStackingService,
    private _leadService: LeadsService
  ) {}

  ngOnInit(): void {
    this.getUsers();
    this.getCurrentUser();
    if (
      !this.data?.listPropertyDataFromLead ||
      typeof this.data?.listPropertyDataFromLead == undefined ||
      this.data?.listPropertyDataFromLead == 'undefined'
    ) {
      this.viewProperty();
    }
    setTimeout(() => {
      this.note.nativeElement.focus();
    }, 700);
  }

  async addLogs() {
    this._loaderService.start();
    this.comment = this.comment.trim();
    if (!this.comment) {
      this._loaderService.stop();
      return;
    }

    let body = {};
    body = {
      moduleId: this.data?.moduleId,
      subModuleId: this.data?.leadId,
      comment: this.comment,
      isTagged: this.comment?.includes('@'),
      tagUsers: await this.getTaggedUsers(),
    };
    if (this.data?.leadData?.address) {
      body['address'] = this.data?.leadData?.address;
    }
    if (this.data?.leadData?.unitNo) {
      body['unitNo'] = this.data?.leadData?.unitNo;
    }
    if (this.data?.leadData?.title) {
      body['title'] = this.data?.leadData?.title;
    }

    if (this.data?.leadData?.mainStatusTitle) {
      body['mainStatus'] = this.data?.leadData?.mainStatusTitle;
    }
    if (this.data?.leadData?.phoneNumber) {
      body['phoneNumber'] = this.data?.leadData?.phoneNumber;
    }

    this._sharedService.addActivityLog(body, false).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          let serviceForLeadAndLSFunction;
          if (
            this.data?.listPropertyDataFromLead?._id &&
            typeof this.data?.listPropertyDataFromLead?._id != undefined &&
            this.data?.listPropertyDataFromLead?._id != 'undefined'
          ) {
            let obj = { ...body };
            obj['subModuleId'] = this.data?.listPropertyDataFromLead?._id;
            obj['moduleId'] = '61b7043f79a58008e1663d2b'; //List Stacking moduleId
            obj['createdBy'] = this.currentUserId;
            obj['subactivity'] = '';
            obj['title'] =
              this.data?.listPropertyDataFromLead?.Formated_Address ||
              this.data?.listPropertyDataFromLead?.Property_Street_Address +
                ', ' +
                this.data?.listPropertyDataFromLead?.Property_City +
                ', ' +
                this.data?.listPropertyDataFromLead?.Property_State +
                ' ' +
                this.data?.listPropertyDataFromLead?.Property_Zip_Code;
            delete obj['address'];
            delete obj['phoneNumber'];
            delete obj['mainStatus'];
            serviceForLeadAndLSFunction =
              this._listStackingService.addActivityLog(obj);
            serviceForLeadAndLSFunction.subscribe(
              (response: ResponseModel) => {
                if (response?.statusCode === 200 || response?.code === 200) {
                } else {
                  this._toastrService.error(
                    this.messageConstant.unknownError,
                    'Error'
                  );
                }
              },
              (err: ErrorModel) => {}
            );
          }
          this._loaderService.stop();
          this._toastrService.success(
            this.messageConstant.noteAddedSuccess,
            ''
          );
          let result = response.data;
          this.comment = '';
          this._takeNoteDialogRef.close();
        }
      },
      (err: ErrorModel) => {
        this.comment = '';
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  getUsers() {
    this._loaderService.start();

    this._userService.getUsers({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.userList = [];
          this.userListing = response?.data;
          for (let i = 0; i < response?.data.length; i++) {
            const user = response?.data[i];
            const fullName = user.firstName + ' ' + user.lastName;
            const matches = fullName.match(/\b(\w)/g);
            const acronym = matches?.join('');
            this.userList.push({
              label: user.firstName + ' ' + user.lastName,
              value: user._id,
              profileImage: user?.profileImage ? user?.profileImage : '',
              name: acronym || 'N/A',
            });
            if (this.selectedUserId == user._id) {
              this.selectedUserName = user.firstName + ' ' + user.lastName;
            }
          }
          this.isUserListResponse = true;
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  tagUser($event) {
    return `@${$event.firstName}` + ` `;
  }
  async getTaggedUsers() {
    const mention = '@';
    const myArray = this.comment.replace(/\n/g, ' ').split(' ');

    let taggedUsers = [];
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].charAt(0) == mention) {
        this.userListing.filter((x) => {
          if (x.firstName === myArray[i].substr(1)) {
            taggedUsers.push(x?._id);
          }
        });
      }
    }
    const users = new Set(taggedUsers);
    return [...users];
  }
  getCurrentUser = () => {
    this._sharedService.getUser().subscribe(
      (response: ResponseModel) => {
        this.currentUserId = response?.data?.userId;
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  };
  viewProperty() {
    const obj = {
      lead_property_id: this.data?.leadId,
    };
    this._leadService.viewProperty(obj).subscribe(
      (response: ResponseModel) => {
        if (response.code == 200) {
          this.data.listPropertyDataFromLead = response?.property;
        }
      },
      (err: ErrorModel) => {}
    );
  }
}
