<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      style="display: block !important"
      class="w-100 d-flex justify-content-between"
    >
      Activity Log
    </h2>
    <span matDialogClose mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<mat-dialog-content class="re-typography vh-100">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-12 col-sm-12">
        <div class="re-tabsdiv">
          <div role="tablist" class="nav re-tabs-btn">
            <a
              href="#tabs11"
              role="tab"
              data-toggle="tab"
              aria-expanded="false"
              class="active"
              (click)="changeActivityType('comm')"
              >Communication</a
            >
            <a
              href="#tabs22"
              role="tab"
              data-toggle="tab"
              aria-expanded="false"
              (click)="changeActivityType('notes')"
              >Notes</a
            >
            <!-- (2 Pinned) -->
          </div>
        </div>
        <div class="tab-content">
          <div role="tabpanel" id="tabs11" class="tab-pane active">
            <div class="re-no-record" *ngIf="logs?.items?.length == 0">
              No Records Found
            </div>
            <div
              class="re-activity-log"
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              [scrollWindow]="false"
              (scrolled)="onScroll()"
              [ngStyle]="style"
            >
              <div
                class="re-receive"
                *ngFor="let log of logs?.items; let logIndex = index"
                class="{{
                  log?.direction == 'incomingSms' ||
                  log?.direction == 'incomingCall' ||
                  log?.direction == 'incomingMms'
                    ? 're-receive'
                    : 're-send'
                }}"
              >
                <div class="re-media">
                  <div class="re-media-left">
                    <img
                      class="re-media-object"
                      [src]="checkType(log?.direction, log)?.icon"
                      *ngIf="checkType(log?.direction, log)?.type != 'COMMENT'"
                    />
                  </div>
                  <div class="re-media-body">
                    <div class="re-infodiv">
                      <h4 class="re-media-heading">
                        <ng-container
                          *ngIf="log?.isWebForm; else else_contact1"
                        >
                          WebForm
                        </ng-container>

                        <ng-template #else_contact1>
                          <ng-container
                            *ngIf="
                              checkType(log?.direction, log).number &&
                                !log?.isWebForm;
                              else else_contact
                            "
                          >
                            {{
                              _utilities.maskNumber(
                                checkType(log?.direction, log).number
                              )
                            }}
                            <span
                              class="re-sender-name"
                              *ngIf="
                                checkSellerName(
                                  checkType(log?.direction, log).number,
                                  logIndex
                                )?.length > 0
                              "
                            >
                              •
                              {{
                                checkSellerName(
                                  checkType(log?.direction, log).number,
                                  logIndex
                                )
                              }}</span
                            >
                            <span
                              class="re-sender-name"
                              *ngIf="
                                checkSellerName(
                                  checkType(log?.direction, log).number,
                                  logIndex
                                )?.length == 0
                              "
                            >
                              • N/A
                            </span>
                          </ng-container>
                        </ng-template>

                        <ng-template #else_contact>
                          <ng-container
                            *ngIf="
                              checkType(log?.direction, log).email &&
                                !log?.isWebForm;
                              else else_name
                            "
                          >
                            {{ log?.to }}
                          </ng-container>

                          <ng-template #else_name>
                            {{ log?.firstName }} {{ log?.lastName }}
                          </ng-template>
                        </ng-template>
                      </h4>
                    </div>
                    <span
                      class="deactiv_connect"
                      *ngIf="log?.callStatusId && !log?.iscallConnected"
                      >{{
                        log?.iscallConnected
                          ? "LEAD CONNECTED"
                          : "LEAD NOT-CONNECTED"
                      }}
                      ({{
                        getCallStatus(log?.iscallConnected, log?.callStatusId)
                      }})</span
                    ><span
                      class="activ_connect"
                      *ngIf="log?.callStatusId && log?.iscallConnected"
                      >{{
                        log?.iscallConnected
                          ? "LEAD CONNECTED"
                          : "LEAD NOT-CONNECTED"
                      }}
                      ({{
                        getCallStatus(log?.iscallConnected, log?.callStatusId)
                      }})</span
                    >
                    <span
                      class="re-desq"
                      *ngIf="
                        log?.direction == 'outgoingCall' &&
                        (log?.voiceCallStatus == 'no-answer' ||
                          log?.voiceCallStatus == 'cancel')
                      "
                      >Not Answered</span
                    >
                    <div *ngIf="log?.smsBody; else else_block" class="re-desq">
                      <span
                        [innerHTML]="
                          log?.smsBody | mentionHighlight | textToLink
                        "
                      ></span>
                    </div>

                    <ng-template #else_block>
                      <div
                        class="re-desq"
                        *ngIf="
                          log?.comment != 'SMS Received' &&
                          log?.comment != 'You placed a call to' &&
                          log?.comment != 'You received a call' &&
                          log?.comment != 'You got a missed call' &&
                          log?.activityType != '2' &&
                          isUserListResponse
                        "
                        [innerHTML]="
                          log?.comment | mentionHighlight | textToLink
                        "
                      ></div>
                    </ng-template>

                    <!-- ATTACHMENTS -->
                    <ng-container
                      *ngIf="log?.attachments?.length && log?.activityType == 1"
                    >
                      <div class="re-attachments">
                        <span
                          class="re-attach-icon"
                          *ngFor="
                            let attachment of log?.attachments;
                            let fileIndex = index
                          "
                          (click)="previewAttachment(log, fileIndex)"
                        >
                          <img
                            src="/assets/images/{{ getIcon(attachment) }}"
                            class="re-image"
                          />
                        </span>
                      </div>
                    </ng-container>

                    <!-- ATTACHMENTS -->
                    <ng-container
                      *ngIf="log?.attachments?.length && log?.activityType == 3"
                    >
                      <div class="re-attachments">
                        <a
                          class="re-attach-icon"
                          *ngFor="
                            let attachment of log?.attachments;
                            let fileIndex = index
                          "
                          target="_blank"
                          [href]="attachment?.url"
                        >
                          <img
                            [alt]="attachment?.name"
                            src="/assets/images/{{ getIcon(attachment?.url) }}"
                            class="re-image"
                          />
                          <!-- <p>{{ attachment?.name }}</p> -->
                        </a>
                      </div>
                    </ng-container>
                  </div>
                  <ng-container
                    *ngIf="
                      log?.recording &&
                      log?.voiceCallStatus != 'cancel' &&
                      log?.voiceCallStatus != 'no-answer'
                    "
                  >
                    <div class="audio-flat">
                      <app-audio-player
                        [url]="log?.recording"
                      ></app-audio-player>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="log?.voiceMailUrl">
                    <div class="audio-flat">
                      <app-audio-player
                        [url]="log?.voiceMailUrl"
                      ></app-audio-player>
                    </div>
                  </ng-container>
                </div>
                <span class="re-time-info">
                  <span
                    *ngIf="
                      log?.direction != 'comment' &&
                      log?.direction != 'incomingCall' &&
                      log?.direction != 'incomingSms' &&
                      log?.direction != 'incomingMms' &&
                      !log?.isDrip &&
                      (log?.firstName || log?.lastName)
                    "
                  >
                    {{ log.firstName ? log?.firstName : "" }}
                    {{ log.lastName ? log?.lastName : "" }} •
                  </span>
                  <span class="re-names" *ngIf="log?.isDrip">Drip</span>
                  <span *ngIf="checkType(log?.direction, log).from">
                    {{
                      _utilities.maskNumber(checkType(log?.direction, log).from)
                    }}</span
                  >
                  <span> • {{ log?.createdAt | myTimezone }}</span>
                </span>
              </div>
            </div>
          </div>
          <div role="tabpanel" id="tabs22" class="tab-pane">
            <div class="re-no-record" *ngIf="logs?.items?.length == 0">
              No Records Found
            </div>
            <div
              class="re-activity-log re-height-set"
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              [scrollWindow]="false"
              (scrolled)="onScroll()"
              [ngStyle]="style"
            >
              <div
                class="{{
                  getDirection(log?.createdBy) ? 're-receive' : 're-send'
                }}"
                *ngFor="let log of logs?.items; let logIndex = index"
              >
                <div class="re-media">
                  <div class="re-media-left">
                    <img
                      class="re-media-object"
                      [src]="
                        log?.profileImage
                          ? log?.profileImage
                          : '/assets/images/demo-profile.png'
                      "
                      *ngIf="
                        checkType(log?.direction, log)?.type == 'COMMENT' &&
                        !log?.isWebForm
                      "
                    />
                    <img
                      class="re-media-object"
                      [src]="checkType(log?.direction, log)?.icon"
                      *ngIf="
                        checkType(log?.direction, log)?.type != 'COMMENT' &&
                        !log?.isWebForm
                      "
                    />
                    <img
                      class="re-media-object"
                      src="assets/images/Via-Webform.svg"
                      *ngIf="
                        checkType(log?.direction, log)?.type == 'COMMENT' &&
                        log?.isWebForm
                      "
                    />
                  </div>
                  <div class="re-media-body">
                    <div class="re-infodiv">
                      <h4 class="re-media-heading">
                        <ng-container
                          *ngIf="log?.isWebForm; else else_contact1"
                        >
                          WebForm
                        </ng-container>

                        <ng-template #else_contact1>
                          <ng-container
                            *ngIf="
                              checkType(log?.direction, log).number &&
                                !log?.isWebForm;
                              else else_contact
                            "
                          >
                            {{
                              _utilities.maskNumber(
                                checkType(log?.direction, log).number
                              )
                            }}
                            <span
                              class="re-sender-name"
                              *ngIf="
                                checkSellerName(
                                  checkType(log?.direction, log).number,
                                  logIndex
                                )?.length > 0
                              "
                            >
                              •
                              {{
                                checkSellerName(
                                  checkType(log?.direction, log).number,
                                  logIndex
                                )
                              }}</span
                            >
                            <span
                              class="re-sender-name"
                              *ngIf="
                                checkSellerName(
                                  checkType(log?.direction, log).number,
                                  logIndex
                                )?.length == 0
                              "
                            >
                              • N/A
                            </span>
                          </ng-container>
                        </ng-template>

                        <ng-template #else_contact>
                          <ng-container
                            *ngIf="
                              checkType(log?.direction, log).email &&
                                !log?.isWebForm;
                              else else_name
                            "
                          >
                            {{ log?.to }}
                          </ng-container>

                          <ng-template #else_name>
                            {{ log?.firstName }} {{ log?.lastName }}
                          </ng-template>
                        </ng-template>
                        <img
                          class="re-ping"
                          src="/assets/images/pin-teal1.svg"
                          *ngIf="log?.isPinned"
                          pTooltip="Pinned Notes"
                          tooltipPosition="bottom"
                        />
                      </h4>
                    </div>
                    <div
                      class="re-desq"
                      *ngIf="isUserListResponse"
                      [innerHTML]="log?.comment | mentionHighlight | textToLink"
                    ></div>
                  </div>
                </div>
                <span class="re-time-info">
                  {{ log?.createdAt | myTimezone }}</span
                >
              </div>
            </div>
            <div class="re-msgbox">
              <textarea
                autocomplete="off"
                placeholder="Write your note here..."
                class="form-control"
                [mention]="userListing"
                [mentionConfig]="{
                  triggerChar: '@',
                  maxItems: 100,
                  insertHTML: true,
                  labelKey: 'firstName',
                  dropUp: 'true',
                  mentionSelect: tagUser,
                  allowSpace: true
                }"
                [ngModel]="comment"
                (blur)="onComment($event.target.value)"
                (keyup)="onComment2($event.target.value)"
                required
              ></textarea>
              <button
                [style.background-color]="
                  comment2 || comment.length ? 'teal' : ''
                "
                [title]="action"
                class="btn send-btn"
                type="button"
                (click)="onSubmit()"
                [style.cursor]="
                  comment2 || comment.length ? 'pointer' : 'not-allowed'
                "
              >
                <img
                  height="20px"
                  width="20px"
                  alt="activity-send"
                  [src]="
                    comment2 || comment.length
                      ? 'assets/images/activity-white-send.svg'
                      : 'assets/images/activity-send.svg'
                  "
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
