<div class="">
  <div class="col-12 d-flex re-header">
    <h2
      mat-dialog-title
      style="display: block !important"
      class="w-100 d-flex justify-content-between"
    >
      {{ isEdit ? "Edit" : "Add" }} SOW
    </h2>
    <span matDialogClose mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<mat-dialog-content class="">
  <div class="container-fluid re-boxline">
    <div class="row">
      <div class="col-12 col-lg-3 col-md-6 col-sm-12">
        <div class="form-group">
          <label class="re-labels"
            >SOW Name<span class="re-req-icon">*</span></label
          >
          <input
            type="text"
            class="form-control"
            [(ngModel)]="sowName"
            (keyup)="resetSOWName()"
            (keydown)="resetSOWName()"
            placeholder="Give sow a self explanatory name"
          />
          <div class="text-danger mt-1">
            <span>{{ sowNameMsg }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3 col-md-6 col-sm-12">
        <div class="form-group">
          <label class="re-labels"
            >Start Date<span class="re-req-icon">*</span></label
          >
          <p-calendar
            styleClass="ngCalendarClass"
            appendTo="body"
            [icon]="'fa fa-calendar'"
            [(ngModel)]="sowSDate"
            name="sowSDate"
            placeholder="Select Date"
            (onSelect)="setAddedToDate()"
            (onClearClick)="clearEndDate()"
            dateFormat="MM d, yy"
            [showButtonBar]="true"
            [showIcon]="true"
            [readonlyInput]="true"
          ></p-calendar>
          <div class="text-danger mt-1">
            <span>{{ sowSDateMsg }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3 col-md-6 col-sm-12">
        <div class="form-group">
          <label class="re-labels"
            >End Date<span class="re-req-icon">*</span></label
          >
          <p-calendar
            styleClass="ngCalendarClass"
            appendTo="body"
            [icon]="'fa fa-calendar'"
            [(ngModel)]="sowEDate"
            name="sowEDate"
            (onSelect)="resetEndDateMsg()"
            placeholder="Select Date"
            dateFormat="MM d, yy"
            [minDate]="addedMinDateValue"
            [showButtonBar]="true"
            [showIcon]="true"
            [readonlyInput]="true"
          ></p-calendar>
          <div class="text-danger mt-1">
            <span>{{ sowEDateMsg }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3 col-md-6 col-sm-12">
        <div class="form-group">
          <label class="re-labels"
            >Estimate<span class="re-req-icon">*</span></label
          >
          <input
            type="text"
            class="form-control amounts"
            [(ngModel)]="sowEstimate"
            [readonly]="itemPriced == 'Itemized'"
            placeholder="Estimate for the work"
            mask="separator.2"
            [thousandSeparator]="','"
            [decimalMarker]="'.'"
            [dropSpecialCharacters]="true"
            prefix="$ "
            (blur)="transformAmount($event)"
          />
          <div
            class="text-danger mt-1"
            *ngIf="
              sowEstimate.length == 0 ||
              sowEstimate == '0' ||
              sowEstimate == '0.00'
            "
          >
            <span>{{ sowEstimateMsg }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3 col-md-6 col-sm-12">
        <div class="form-group">
          <label class="re-labels"
            >Contractor<span class="re-req-icon">*</span></label
          >
          <select
            class="form-control"
            [(ngModel)]="sowContractor"
            (change)="changeContractor($event)"
          >
            <option value="">Select a contractor</option>
            <option
              value="{{ eachContractor?._id }}"
              *ngFor="let eachContractor of contractorList"
            >
              <!-- {{
                eachContractor?.firstName + " " + eachContractor?.lastName
              }} -->
              {{
                eachContractor?.name 
              }}
              ({{ eachContractor?.subcategoryId?.name }})
            </option>
          </select>
          <div class="text-danger mt-1" *ngIf="sowContractor.length == 0">
            <span>{{ sowContractorMsg }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3 col-md-6 col-sm-12">
        <div class="form-group">
          <label class="re-labels">Materials Paid By</label>
          <select class="form-control" [(ngModel)]="sowMaterialBy">
            <option value="Contractor">Contractor</option>
            <option value="Owner">Owner</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-lg-6 col-md-6 col-sm-12">
        <div class="form-group">
          <label class="re-labels">Items Price</label>
          <div class="radiolist">
            <label class="radiobtn"
              >Lumpsum
              <input
                type="radio"
                name="radioaa"
                checked=""
                (click)="resetTotal()"
                [(ngModel)]="itemPriced"
                value="Lumpsum" /><span class="checkmark"></span
            ></label>
            <label class="radiobtn"
              >Itemized
              <input
                type="radio"
                name="radioaa"
                value="Itemized"
                (click)="resetTotal()"
                [(ngModel)]="itemPriced" /><span class="checkmark"></span
            ></label>
          </div>
        </div>
        <input
          class="re-files"
          type="file"
          id="fileUploadInput"
          ngFileSelect
          accept="image/*"
          #uploader
          (uploadOutput)="onUploadOutput($event)"
          [uploadInput]="uploadInput"
        />
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="re-accordbox">
      <div class="panel-group" id="accordion">
        <div
          class="panel"
          *ngFor="let eachCategory of allCategoryData; let i = index"
        >
          <div class="panel-heading" [attr.id]="'headings' + eachCategory?._id">
            <a
              data-toggle="collapse"
              [attr.data-target]="'#collapses' + eachCategory?._id"
              aria-expanded="true"
              [attr.aria-controls]="'#collapses' + eachCategory?._id"
              class="collapsed re-maintabs"
              >{{ eachCategory?.name }} ({{ eachCategory?.count }} items)</a
            >
          </div>
          <div
            [attr.id]="'collapses' + eachCategory?._id"
            class="collapse"
            [attr.aria-labelledby]="'headings' + eachCategory?._id"
            data-parent="#accordion"
          >
            <div class="panel-body">
              <div class="re-sub-accordian">
                <div
                  class="panel-group"
                  [attr.id]="'accordion' + eachCategory?._id"
                >
                  <div
                    class="panel"
                    *ngFor="
                      let eachSubCategory of eachCategory?.subCategory;
                      let j = index
                    "
                  >
                    <div
                      class="panel-heading"
                      [attr.id]="
                        'headingss' +
                        eachCategory?._id +
                        '_' +
                        eachSubCategory?._id
                      "
                    >
                      <a
                        data-toggle="collapse"
                        [attr.data-target]="
                          '#collapsess' +
                          eachCategory?._id +
                          '_' +
                          eachSubCategory?._id
                        "
                        aria-expanded="true"
                        [attr.aria-controls]="
                          'collapsess' +
                          eachCategory?._id +
                          '_' +
                          eachSubCategory?._id
                        "
                        class="collapsed"
                        >{{ eachSubCategory?.name }} ({{
                          eachSubCategory?.count
                        }}
                        items)</a
                      >
                    </div>
                    <div
                      [attr.id]="
                        'collapsess' +
                        eachCategory?._id +
                        '_' +
                        eachSubCategory?._id
                      "
                      class="collapse"
                      [attr.aria-labelledby]="
                        'headingss' +
                        eachCategory?._id +
                        '_' +
                        eachSubCategory?._id
                      "
                      [attr.data-parent]="'#accordion' + eachCategory?._id"
                    >
                      <div class="">
                        <div class="re-suboxes">
                          <div class="re-itemslist">
                            <div class="items">
                              <div
                                class=""
                                *ngFor="
                                  let eachSubSubCategory of eachSubCategory?.subSubCategory;
                                  let k = index
                                "
                                [ngClass]="
                                  hasChecked(
                                    eachSubSubCategory?._id,
                                    eachCategory?._id,
                                    eachSubCategory?._id
                                  )
                                    ? 'checkbtnlist subshow'
                                    : 'checkbtnlist'
                                "
                              >
                                <div class="re-headsec">
                                  <p-checkbox
                                    value="{{ eachSubSubCategory?._id }}"
                                    label="{{ eachSubSubCategory?.name }}"
                                    (onChange)="
                                      resetSelectTotal($event, i, j, k)
                                    "
                                    [(ngModel)]="
                                      subSubCheckedVal[
                                        eachCategory?._id +
                                          '_' +
                                          eachSubCategory?._id
                                      ]
                                    "
                                  ></p-checkbox>
                                  <span
                                    class="addimg"
                                    *ngIf="
                                      hasChecked(
                                        eachSubSubCategory?._id,
                                        eachCategory?._id,
                                        eachSubCategory?._id
                                      )
                                    "
                                    (click)="setFileUpload(i, j, k)"
                                    >+ Add Image
                                  </span>
                                </div>
                                <div class="re-hidensec">
                                  <div
                                    class="re-frameinput"
                                    *ngIf="itemPriced == 'Itemized'"
                                  >
                                    <div class="row">
                                      <div
                                        class="col-12 col-xl-3 col-md-6 col-sm-12"
                                      >
                                        <div class="form-group">
                                          <label class="labes"
                                            >Quantity<span class="re-req-icon"
                                              >*</span
                                            ></label
                                          >
                                          <input
                                            type="text"
                                            class="form-control"
                                            name=""
                                            #numberQuantity
                                            placeholder="0"
                                            [(ngModel)]="
                                              subSubQuantityVal[
                                                eachCategory?._id +
                                                  '_' +
                                                  eachSubCategory?._id +
                                                  '_' +
                                                  eachSubSubCategory?._id
                                              ]
                                            "
                                            (blur)="
                                              setSOWValues(
                                                numberQuantity.value,
                                                eachCategory?._id,
                                                eachSubCategory?._id,
                                                eachSubSubCategory?._id,
                                                'q'
                                              )
                                            "
                                          />
                                        </div>
                                      </div>
                                      <div
                                        class="col-12 col-xl-3 col-md-6 col-sm-12"
                                      >
                                        <div class="form-group">
                                          <label class="labes"
                                            >UoM<span class="re-req-icon"
                                              >*</span
                                            ></label
                                          >
                                          <select
                                            class="form-control"
                                            #sowUoM
                                            [(ngModel)]="
                                              subSubUoMVal[
                                                eachCategory?._id +
                                                  '_' +
                                                  eachSubCategory?._id +
                                                  '_' +
                                                  eachSubSubCategory?._id
                                              ]
                                            "
                                            (change)="
                                              setSOWValues(
                                                sowUoM.value,
                                                eachCategory?._id,
                                                eachSubCategory?._id,
                                                eachSubSubCategory?._id,
                                                'u'
                                              )
                                            "
                                          >
                                            <option value="">
                                              Please Select
                                            </option>
                                            <option value="Each">Each</option>
                                            <option value="Per Sq Ft">
                                              Per Sq Ft
                                            </option>
                                            <option value="Per Linear Ft">
                                              Per Linear Ft
                                            </option>
                                            <option value="Per Square">
                                              Per Square
                                            </option>
                                            <option value="Per Hours">
                                              Per Hours
                                            </option>
                                            <option value="Per Sheet">
                                              Per Sheet
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div
                                        class="col-12 col-xl-3 col-md-6 col-sm-12"
                                      >
                                        <div class="form-group">
                                          <label class="labes"
                                            >Unit Price<span class="re-req-icon"
                                              >*</span
                                            ></label
                                          >
                                          <input
                                            type="text"
                                            class="form-control"
                                            name=""
                                            placeholder="$ 0.00"
                                            #sowUnitPrice
                                            [(ngModel)]="
                                              subSubUPriceVal[
                                                eachCategory?._id +
                                                  '_' +
                                                  eachSubCategory?._id +
                                                  '_' +
                                                  eachSubSubCategory?._id
                                              ]
                                            "
                                            (blur)="
                                              setSOWValues(
                                                sowUnitPrice.value,
                                                eachCategory?._id,
                                                eachSubCategory?._id,
                                                eachSubSubCategory?._id,
                                                'p'
                                              )
                                            "
                                          />
                                        </div>
                                      </div>
                                      <div
                                        class="col-12 col-xl-3 col-md-6 col-sm-12"
                                      >
                                        <div class="form-group">
                                          <label class="labes">Total</label>
                                          <input
                                            type="text"
                                            class="form-control"
                                            name=""
                                            placeholder="$ 0.00"
                                            value="{{
                                              eachSubSubCategory?.total
                                            }}"
                                            readonly
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <textarea
                                      class="form-control"
                                      rows="2"
                                      (keyup)="
                                        resetDescript(
                                          eachCategory?._id,
                                          eachSubCategory?._id,
                                          eachSubSubCategory?._id
                                        )
                                      "
                                      (keydown)="
                                        resetDescript(
                                          eachCategory?._id,
                                          eachSubCategory?._id,
                                          eachSubSubCategory?._id
                                        )
                                      "
                                      #sowDescription
                                      [(ngModel)]="
                                        subSubDescVal[
                                          eachCategory?._id +
                                            '_' +
                                            eachSubCategory?._id +
                                            '_' +
                                            eachSubSubCategory?._id
                                        ]
                                      "
                                      (blur)="
                                        setSOWValues(
                                          sowDescription.value,
                                          eachCategory?._id,
                                          eachSubCategory?._id,
                                          eachSubSubCategory?._id,
                                          'd'
                                        )
                                      "
                                    ></textarea>
                                    <ul>
                                      <li
                                        *ngFor="
                                          let eachImage of eachSubSubCategory?.imageurl;
                                          let imgI = index
                                        "
                                      >
                                        <img
                                          class="img"
                                          src="{{ s3Url + eachImage }}"
                                          alt="img"
                                        />
                                        <p class="re-text">
                                          {{
                                            eachSubSubCategory?.name +
                                              " #" +
                                              (imgI + 1)
                                          }}
                                        </p>
                                        <a
                                          class="trash"
                                          href="javascript:void(0);"
                                          (click)="removeImg(imgI, i, j, k)"
                                          ><img
                                            src="assets/images/deleteActive.svg"
                                            alt="delete"
                                        /></a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center" style="padding-top: 14px !important">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button
    mat-flat-button
    color="primary"
    cdkFocusInitial
    [disabled]="contractorList.length == 0"
    (click)="checkName()"
  >
    {{ isEdit ? "Edit" : "Add" }} SOW
  </button>
</mat-dialog-actions>
