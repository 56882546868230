<div [class.d-none]="forLead || forPortNumber || forVideo">
  <div class="col-12 d-flex justify-content-between">
    <div class="re-utilizing">
      <div class="re-chart" data-percent="60">
        <svg viewBox="0 0 36 36" class="circle">
          <path
            class="circle2"
            stroke-dasharray="100, 100"
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
          <path
            class="circle1"
            [attr.stroke-dasharray]="progressPercentage"
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          ></path>
        </svg>
        <div class="re-chart-percent">
          <span class="re-count">{{ userProgressPercentage }}</span>
          <span class="re-count">%</span>
        </div>
      </div>
      <p class="re-completed-text">
        Features Utilized {{ userProgress }} out of {{ totalProgress }}
      </p>
    </div>
    <!-- <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      Feature Utilisation
    </h2> -->
    <span mat-dialog-close style="float: right; cursor: pointer">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
  <mat-dialog-content>
    <ng-container *ngFor="let obj of progressData">
      <div class="re-getting-main" [class.re-getting-close]="!obj?.isExpand">
        <div class="re-accord-title" (click)="onTitleAction(obj)">
          {{ obj?.title }}
          <i class="fa fa-chevron-up" aria-hidden="true"></i>
        </div>
        <div class="re-lists-div" *ngFor="let subObj of obj?.value">
          <div class="re-listbox" [class.re-donediv]="subObj?.value">
            <div class="re-checkmarkdiv">
              <div class="re-checkmark"></div>
              {{ subObj?.title }}
            </div>
            <a
              class="re-read-btn"
              (click)="watchAndReadDetails('forVideo', subObj?.url)"
              *ngIf="videoLink[subObj?.url]"
              ><i class="fa fa-play"></i>
              <!--{{ subObj?.value ? 'Rewatch' : 'Start' }} -->
            </a>
          </div>
        </div>
      </div>
    </ng-container>
  </mat-dialog-content>
</div>

<div [class.d-none]="!forLead">
  <mat-dialog-content>
    <div class="importin-div">
      <h4 class="re-main-title">Importing leads from another system</h4>
      <h5 class="re-sub-title">
        Please follow the steps below to import your leads to REsimpli
      </h5>
      <ol>
        <li>Export your leads from Podio or any other CRM in Excel or CSV</li>
        <li>
          Set up your campaigns in REsimpli to match Podio or however you want
          to track your leads. Please
          <a
            href="https://docs.google.com/spreadsheets/d/1h1VIJ2G-d5PWc6X0PuD5tGLUQVSuxi2TEBuiY3NvimQ/edit?usp=sharing"
            target="_blank"
            >follow this format</a
          >
          for your list.
        </li>
        <li>
          Email your excel file to
          <a href="mailto:support@REsimpli.com">support@REsimpli.com</a>
        </li>
        <li>We will upload your leads and inform you!</li>
      </ol>
      <p class="re-desq">
        <b>Important notes:</b> We won’t be able to import any lists or any
        records of your list that are missing phone numbers. If your list is
        missing phone numbers, we advise you to upload it to our List Stacking.
      </p>
      <a
        class="re-linkbtn"
        href="https://youtu.be/l0cZrXw2UB0?feature=shared"
        target="_blank"
        >Click here to watch a demo on how to import a file to REsimpli List
        Stacking.</a
      >
      <div class="text-center">
        <a class="btn re-back-btn" (click)="watchAndReadDetails('back')"
          >Go Back</a
        >
      </div>
    </div>
  </mat-dialog-content>
</div>

<div [class.d-none]="!forPortNumber">
  <mat-dialog-content>
    <div class="importin-div">
      <h4 class="re-main-title">Porting your phone numbers into REsimpli</h4>
      <h5 class="re-sub-title">How to port your numbers into REsimpli</h5>
      <ol>
        <li>
          Complete the attached Number Porting Letter.
          <a
            href="https://resimpli.acemlnc.com/lt.php?notrack=1&notrack=1&s=bad97c655476f96a390a72c05a742011&i=26A51A9A257"
            target="_blank"
            >Click here to download Number Porting Letter</a
          >. Please note that the letter mentions Plivo. Plivo is the service we
          use on the backend so please don't change that. Some points to
          consider:
        </li>
        <li>
          The letter asks for the Organization's Name. That is the name you used
          when you set up your account with CallRail. If you set up the account
          in a personal name, just delete the Organization Name.
          <a
            href="https://resimpli.acemlnc.com/lt.php?notrack=1&notrack=1&s=bad97c655476f96a390a72c05a742011&i=26A51A9A258"
            target="_blank"
          >
            Click here to see a sample Number Porting Letter filled out</a
          >
        </li>
        <li>
          Complete the attached Number Porting File.
          <a
            href="https://docs.google.com/spreadsheets/d/1rqwPXCZrYKxzN6SR9gKeo8vJzBWDtCVhlrkEG9K5bQg/edit?usp=sharing"
            target="_blank"
            >Click here to download the Number Porting File.</a
          >
          This information will help us set up your call forwarding before the
          numbers are ported over to REsimpli so there is no downtime and your
          calls are forwarded correctly. Some points to consider:
        </li>
        <li>
          Phone numbers in column A "Numbers to Transfer" will match the phone
          numbers in the Number Porting Letter you are requesting to be
          transferred over to REsimpli to make sure that each of your numbers is
          set up
        </li>
        <li>
          <a
            href="https://docs.google.com/spreadsheets/d/17MruefRPKcDsW0kY7XlduMoxVVbHXqopV3aEA_g23pc/edit?usp=sharing"
            target="_blank"
            >Click here to download a sample Number Porting File filled out</a
          >
        </li>
        <li>
          Send an email to
          <a href="mailto:support@REsimpli.com">support@REsimpli.com</a> with
          the following:
        </li>
        <li>Completed Number Porting Letter</li>
        <li>Completed Number Porting File</li>
        <li>
          The most recent invoice from your current phone number provider
          (CallRail, GrassHopper, etc.)
        </li>
        <li>
          Obtain the PIN information for each of the numbers to be ported over.
          Without the PIN information, the porting request will not be processed
          timely and accordingly.
        </li>
        <li>Sit back and we will keep you updated on the progress</li>
      </ol>
      <div class="text-center">
        <a class="btn re-back-btn" (click)="watchAndReadDetails('back')"
          >Go Back</a
        >
      </div>
    </div>
  </mat-dialog-content>
</div>

<div class="position-relative" [class.d-none]="!forVideo">
  <span class="re-close-link" (click)="watchAndReadDetails('back')">
    <img src="assets/images/close-black.svg" alt="close" width="20" />
  </span>
  <div style="padding: 59.28% 0 0 0; position: relative">
    <iframe
      [src]="videoUrl"
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
  <!-- <script src="https://player.vimeo.com/api/player.js"></script> -->
</div>
