import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// UTILS
import { MessageConstant } from 'src/app/utils/message-constant';
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';
import { StatusConstant } from 'src/app/utils/status-constant';

// COMPONENTS
import { AddEditAppointmentsComponent } from '../../leads-details/task/add-edit-appointments/add-edit-appointments.component';

// SERVICES
import { InventoryService } from 'src/app/providers/inventory/inventory.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LeadsService } from 'src/app/providers/leads/leads.service';
import { SharedService } from 'src/app/shared/shared.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { ContingenciesService } from 'src/app/providers/contingencies/contingencies.service';

@Component({
  selector: 'app-leads-status-change',
  templateUrl: './leads-status-change.component.html',
  styleUrls: ['./leads-status-change.component.scss'],
})
export class LeadsStatusChangeComponent implements OnInit {
  @ViewChild('appointment') appointment: AddEditAppointmentsComponent;

  offersForm: FormGroup;
  deadForm: FormGroup;
  currentUser: any;

  deadReasons: any[] = [];
  appointments: any[] = [];
  isChooseAppointment: boolean = false;
  isChooseExisting: boolean = true;
  dripStop: boolean = false;
  submitted: boolean = false;
  messageConstant = MessageConstant;
  selectedAppointment: any;
  appointmentData: any;

  isNoContactMadeDripVisible: boolean = false;
  isContactMadeDripVisible: boolean = false;
  isAppointmentDripVisible: boolean = false;
  isOfferMadeDripVisible: boolean = false;
  isAppointmentVisible: boolean = false;
  isOfferVisible: boolean = false;
  isUnderContractVisible: boolean = false;
  isAssignedToBuyerVisible: boolean = false;
  isReferredToAgentVisible: boolean = false;
  isDeadReasonVisible: boolean = false;
  isWarmLeadDripVisible: boolean = false;
  isreferredToAgentDripVisible: boolean = false;
  isTaskVisible: boolean = false;

  isInventoryVisible: boolean = false;
  isUnderRehab: boolean = false;
  isListedForSale: boolean = false;
  isUnderContract: boolean = false;
  isSold: boolean = false;
  isAssignedToSold: boolean = true;
  assignedToBuyerToSold: boolean = false;
  referredToAgentToSold: boolean = false;
  isReferredToAgentToSold: boolean = false;
  isRental: boolean = false;
  isContingencyCheck: boolean = false;
  isAppointmentMainStatusExists: boolean = true;

  investmentType: any[] = [];
  projectStatus: any[] = [];
  contingencies: any[] = [];

  currentPage: number = 1;
  currentLimit: number = 100;
  inventoryModuleId: string = '5faa66379837ff5b02bfb365';

  currentDate: Date = new Date();
  assignmentContractMinDate: Date = new Date(
    this.data?.item?.underContractDate
  );
  assignmentContractMaxDate: Date = new Date(this.data?.item?.closingDate);
  offerCreatedDate: Date = new Date(this.data.offerCreatedDate);
  underContactCreatedDate: Date = new Date(this.data.underContractDate);
  underContactCreated: Date = new Date(
    this.data.offerCreatedDate
      ? this.data.offerCreatedDate
      : this.data.leadCreatedDate
  );
  leadCreatedDate: Date = new Date(this.data.leadCreatedDate);
  leadCreatedDateNew: Date = new Date(this.data.leadCreatedDate);

  purchaseDateMin: Date = new Date();
  purchaseDateMax: Date = new Date();
  saleDateMax: Date = new Date();
  isMarketReadonly: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<LeadsStatusChangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private _leadService: LeadsService,
    private _sharedService: SharedService,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private _inventoryService: InventoryService,
    private _commonFunction: CommonFunctionsService,
    private _contingencyService: ContingenciesService
  ) {}

  ngOnInit(): void {
    this.currentDate.setMinutes(0);

    this.offersForm = this.fb.group({
      yourOfferPrice: [null],
      dateOfYourOffer: [new Date()],
      offerType: ['verbal'],
      appointmentTitle: ['', []],
      appointmentDate: [new Date(), []],
      underContractDate: [new Date()],
      underContractPrice: [null],
      closingDate: [new Date()],
      contractclosingDate: [new Date()],
      assignmentFee: [''],
      referralFee: [''],
      assignmentContractDate: [new Date()],
      deadReasonIds: this.fb.array([]),
      deadReasonOtherText: [''],
      existingTypeId: [''],
      contingencyArray: this.fb.array([]),
      contingencyStatus: [false],

      // INVENTORY
      projectTypeId: [''],
      mainStatusId: [''],
      purchaseDate: [new Date()],
      purchasePrice: [''],

      rehabStart: [new Date(), []],
      rehabFinish: [new Date(), []],
      goingOnMarket: [new Date(), []],
      listedPrice: [0, []],
      underCoGoing: [new Date(), []],
      underCoPrice: [0, []],
      underCoClosingDate: [new Date(), []],
      salesDate: [new Date(), []],
      salesPrice: [0, []],
      netProfit: [0, []],
      monthlyRent: [0, []],
      leaseType: [''],
      leaseStartDate: [new Date(), []],
      leaseEndDate: [new Date(), []],
    });
    this.offersForm.patchValue({
      assignmentFee: this.data?.item?.assignmentFee,
    });
    this.getCurrentUser();
    this.addFormEvent();
    if (this.data?.item?.projectTypeId) {
      this.offersForm.patchValue({
        projectTypeId: this.data?.item?.projectTypeId,
      });
    }

    const appointmentStatus = this.data.mainStatus.find(
      (x) => x.title === 'appointments set'
    );

    if (!appointmentStatus) {
      this.isAppointmentMainStatusExists = false;

      return;
    }

    if (appointmentStatus?.labels?.title !== 'appointments set') {
      this.isAppointmentMainStatusExists = false;
    }
  }

  ngAfterViewInit() {
    this.isMarketReadonly = true;
  }

  getCurrentUser() {
    this._sharedService.getUser().subscribe(
      (response: ResponseModel) => {
        this.currentUser = response.data;

        let secondaryContacts = Object.assign(
          [],
          this.data?.item?.secondaryContact
        );

        if (
          this.data?.item?.email ||
          this.data?.item?.title ||
          this.data?.item?.phoneNumber
        ) {
          const obj = {
            email: this.data?.item?.email,
            name: this.data?.item?.title,
            phoneNumber: this.data?.item?.phoneNumber,
            contactType: 'seller',
            isOwnerContact: true,
            numberType: this.data?.item?.numberType,
          };

          secondaryContacts.unshift(obj);
        }

        secondaryContacts = secondaryContacts.filter(
          (v, i, a) =>
            a.findIndex(
              (t) =>
                t?.value?.email === v?.value?.email &&
                t?.value?.phoneNumber === v?.value?.phoneNumber
            ) === i
        );

        this.appointmentData = {
          moduleId: this.data.moduleId,
          subModuleId: this.data?.item?._id,
          address: this.data?.item?.address,
          currentUser: this.currentUser,
          secondaryContacts,
          leadInfo: this.data.item,
          // teams: this.teams,
        };
        this.isVisible();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  onDate(event, type) {
    switch (type) {
      case 'offer':
        this.offerCreatedDate = new Date(event);
        break;
      case 'under':
        this.underContactCreatedDate = new Date(event);
        this.assignmentContractMinDate = new Date(event);
        this.underContactCreated = new Date(event);
        this.offersForm.patchValue({
          closingDate: new Date(event),
          assignmentContractDate: new Date(event),
        });
        break;
    }
  }

  getInvestmentType() {
    let obj = {
      page: 1,
    };
    this._inventoryService.getInvestmentType(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode && response.statusCode == 200) {
          this.investmentType = response.data.items;

          if (
            this.data?.item?.investmentTitle &&
            this.data.status.to === 'under contract'
          ) {
            const type = this.investmentType.find(
              (x) =>
                x.name?.toLowerCase() ===
                this.data?.item?.investmentTitle?.toLowerCase()
            );

            if (!type) return;

            this.offersForm.patchValue({
              existingTypeId: type._id,
            });
          }

          return;
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getContingency() {
    let obj = {
      page: 0,
      limit: 0,
    };

    this._contingencyService.getContingencyType(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode && response.statusCode == 200) {
          this.contingencies = response.data;
        } else {
          this._toastrService.error(response.message, '');
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getProjectStatus() {
    const obj = {
      page: this.currentPage,
      limit: this.currentLimit,
      moduleId: this.inventoryModuleId,
    };

    this._sharedService.getMainStatus(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode && response.statusCode == 200) {
          let newInventory = [];
          if (this.isSold) {
            this.projectStatus = response.data.items.filter(
              (x) => x.title === 'sold'
            );
            newInventory = this.projectStatus.filter((x) => x.title === 'sold');
          } else {
            this.projectStatus = response.data.items.filter(
              (x) => x.title === 'new inventory'
            );
            newInventory = this.projectStatus.filter(
              (x) => x.title === 'new inventory'
            );
          }
          if (newInventory.length) {
            this.offersForm.patchValue({
              mainStatusId: newInventory[0]._id,
            });
          }
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  onChangeInventoryStatus(value) {
    if (!value) {
      return;
    }

    const status = this.projectStatus.filter((x) => x._id === value);
    if (status.length) {
      const statusType = status[0].title;
      if (statusType === 'under rehab') {
        this.isUnderRehab = true;
        this.isListedForSale = false;
        this.isUnderContract = false;
        this.isSold = false;
        this.isRental = false;

        this.setValidators(['rehabStart', 'rehabFinish']);
        this.unsetValidators([
          'goingOnMarket',
          'listedPrice',
          'underCoGoing',
          'underCoPrice',
          'underCoClosingDate',
          'salesDate',
          'salesPrice',
          'netProfit',
        ]);
      } else if (statusType === 'listed for sale') {
        this.isUnderRehab = true;
        this.isListedForSale = true;
        this.isUnderContract = false;
        this.isSold = false;
        this.isRental = false;

        this.setValidators([
          'rehabStart',
          'rehabFinish',
          'goingOnMarket',
          'listedPrice',
        ]);

        this.unsetValidators([
          'underCoGoing',
          'underCoPrice',
          'underCoClosingDate',
          'salesDate',
          'salesPrice',
          'netProfit',
        ]);
      } else if (statusType === 'under contract') {
        this.isUnderRehab = true;
        this.isListedForSale = true;
        this.isUnderContract = true;
        this.isSold = false;
        this.isRental = false;

        this.setValidators([
          'underCoGoing',
          'underCoPrice',
          'underCoClosingDate',
          'rehabStart',
          'rehabFinish',
          'goingOnMarket',
          'listedPrice',
        ]);
        this.unsetValidators(['salesDate', 'salesPrice', 'netProfit']);
      } else if (statusType === 'sold') {
        this.isUnderRehab = true;
        this.isListedForSale = true;
        this.isUnderContract = true;
        this.isSold = true;
        this.isRental = false;

        this.setValidators([
          'underCoGoing',
          'underCoPrice',
          'underCoClosingDate',
          'rehabStart',
          'rehabFinish',
          'goingOnMarket',
          'listedPrice',
          'salesDate',
          'salesPrice',
          'netProfit',
        ]);
      } else if (statusType === 'rental') {
        this.isUnderRehab = false;
        this.isListedForSale = false;
        this.isUnderContract = false;
        this.isSold = false;
        this.isRental = true;

        this.setValidators([
          'monthlyRent',
          'leaseType',
          'leaseStartDate',
          'leaseEndDate',
        ]);
      } else {
        this.isUnderRehab = false;
        this.isListedForSale = false;
        this.isUnderContract = false;
        this.isSold = false;
        this.isRental = false;

        this.unsetValidators([
          'underCoGoing',
          'underCoPrice',
          'underCoClosingDate',
          'rehabStart',
          'rehabFinish',
          'goingOnMarket',
          'listedPrice',
          'salesDate',
          'salesPrice',
          'netProfit',
        ]);
      }

      this.offersForm.updateValueAndValidity();
    }
  }

  setValidators(fields) {
    fields.filter((field) => {
      this.offersForm.get(field).setValidators([Validators.required]);
    });
  }

  unsetValidators(fields) {
    fields.filter((field) => {
      this.offersForm.get(field).clearValidators();
      this.offersForm.get(field).setErrors(null);
      this.offersForm.get(field).setValue('');
    });
  }

  isAnalyticsInfoExists() {
    return !!(
      this.isUnderRehab ||
      this.isListedForSale ||
      this.isUnderContract ||
      this.isSold ||
      this.isRental
    );
  }

  get deadFormArray() {
    return this.offersForm.get('deadReasonIds') as FormArray;
  }

  isVisible() {
    this.isTaskVisible = true;
    const { to, from } = this.data.status;
    console.log(this.data.isVisibleData);
    const {
      isAppointmentSet,
      isOffersMade,
      isUnderContract,
      isAssignedToBuyer,
      isDeadLead,
      isWarmLead,
      isReferredToAgent,
      isInventory,
    } = this.data.isVisibleData;

    let allValidator = [],
      updateFormValidator = [];
    if (!isInventory && isDeadLead && this.data?.fromKey != 'isDeadLead') {
      this.isDeadReasonVisible = true;
      this.getDeadReasons();
      return;
    }

    if (this.data.header === 'inventory' || this.data.header === 'sold') {
      this.getInvestmentType();
      this.getProjectStatus();
      if (this.data.header === 'sold') {
        allValidator.push(
          'projectTypeId',
          'mainStatusId',
          'salesDate',
          'salesPrice',
          'netProfit',
        );
      } else {
        allValidator.push(
          'projectTypeId',
          'mainStatusId',
          'salesDate',
          'salesPrice',
          'netProfit'
        );
      }
    }
    if (isAppointmentSet && this.data?.fromKey != 'isAppointmentSet') {
      this.isAppointmentDripVisible = true;
      this.chooseAppointment(true);
    }
    if (isOffersMade && this.data?.fromKey != 'isOffersMade') {
      //this.isAppointmentVisible = true;
      this.isOfferMadeDripVisible = true;
      this.isOfferVisible = true;

      updateFormValidator.push('yourOfferPrice');
    }

    if (
      !isWarmLead &&
      !isReferredToAgent &&
      isUnderContract &&
      this.data?.fromKey != 'isUnderContract'
    ) {
      //this.isAppointmentVisible = true;
      this.getInvestmentType();
      if (isUnderContract && this.data?.fromKey != 'isUnderContract') {
        this.getContingency();

        this.contingencyFormArray.setValidators([Validators.required]);
      }
      this.isUnderContractVisible = true;
      updateFormValidator.push('underContractPrice');
      if (!isAssignedToBuyer && !isInventory)
        updateFormValidator.push('existingTypeId');
    }

    if (
      !isWarmLead &&
      !isReferredToAgent &&
      isAssignedToBuyer &&
      this.data?.fromKey != 'isAssignedToBuyer'
    ) {
      //this.isAppointmentVisible = true;
      this.isAssignedToBuyerVisible = true;

      updateFormValidator.push('assignmentFee');
    }

    if (isWarmLead && this.data?.fromKey != 'isWarmLead') {
      this.isWarmLeadDripVisible = true;
      return;
    }

    if (isReferredToAgent && this.data?.fromKey != 'isReferredToAgent') {
      this.isreferredToAgentDripVisible = true;
      return;
    }

    if (isInventory && this.data?.fromKey != 'isInventory') {
      //this.isAppointmentVisible = true;
      this.isInventoryVisible = true;
      updateFormValidator.push('purchasePrice');
    }

    this.updateFormValidations(updateFormValidator);
    this.setValidators(allValidator);
  }

  updateFormValidations(fields: any[] = []) {
    fields.map((x) => {
      let validators = [Validators.required];
      if (x === 'buyerPhoneNumber') {
        validators.push(Validators.pattern(MiscellaneousConstant.phonePattern));
      }

      if (
        x === 'yourOfferPrice' ||
        x === 'underContractPrice' ||
        x === 'underContractPrice' ||
        x === 'purchasePrice' ||
        x === 'listedPrice' ||
        x === 'underCoPrice' ||
        x === 'monthlyRent' ||
        x === 'assignmentFee' ||
        x === 'salesPrice' ||
        x === 'netProfit'
      ) {
        validators.push(Validators.pattern(/^[0-9]+\.?[0-9]*$/));
      }
      if (x === 'buyerEmail') {
        validators.push(Validators.pattern(MiscellaneousConstant.emailPattern));
      }
      this.offersForm.get(x).setValidators(validators);
    });
  }

  chooseAppointment(bool) {
    //this.isChooseAppointment = bool;

    if (bool) {
      this.offersForm
        .get('appointmentTitle')
        .setValidators([Validators.required]);

      this.offersForm
        .get('appointmentDate')
        .setValidators([Validators.required]);

      let data = {
        limit: this.data.limit,
        moduleId: this.data.moduleId,
        page: this.data.page,
        header: this.data.header,
        status: this.data.status,
        subModuleId: this.data.subModuleId,
      };
      this.getAppointments(data);
    } else {
      this.offersForm.controls['appointmentTitle'].clearValidators();
      this.offersForm.controls['appointmentTitle'].updateValueAndValidity();
      this.offersForm.controls['appointmentDate'].clearValidators();
      this.offersForm.controls['appointmentDate'].updateValueAndValidity();
    }
  }

  getAppointments(data) {
    let obj = { ...data };
    delete obj['header'];
    delete obj['status'];
    this._sharedService.getAppointments(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.appointments = response?.data?.items;
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getDeadReasons() {
    this._loaderService.start();
    this._leadService.getDeadReason({}).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.deadReasons = response?.data;

          this.deadReasons.filter((x) => {
            this.deadFormArray.push(
              this.fb.group({
                name: x?.name,
                value: x?._id,
                selected: false,
              })
            );
          });

          this.deadFormArray.setValidators([Validators.required]);

          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
        this._loaderService.stop();
      }
    );
  }

  getValue(index) {
    return this.deadReasons[index]?.name;
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.offersForm.controls[controlName].hasError(errorName);
  };

  selectContingencyStatus(bool) {
    this.isContingencyCheck = bool;

    this.offersForm.patchValue({
      contingencyStatus: bool,
    });

    if (bool) {
      this.addContingency();
      this.setValidators(['contingencyArray']);

      return;
    }

    this.contingencies.filter((e, index) => {
      this.deleteContingency(index);
    });
    this.unsetValidators(['contingencyArray']);
  }

  selectAppointmentType(bool) {
    this.selectedAppointment = null;
    this.isChooseExisting = bool;
  }

  setExistingAppointment(appointment) {
    this.offersForm.controls['appointmentTitle'].clearValidators();
    this.offersForm.controls['appointmentDate'].clearValidators();
    this.offersForm.controls['appointmentTitle'].updateValueAndValidity();
    this.offersForm.controls['appointmentDate'].updateValueAndValidity();
    this.selectedAppointment = appointment;
  }

  getData() {
    let {
      yourOfferPrice,
      dateOfYourOffer,
      offerType,
      underContractDate,
      underContractPrice,
      closingDate,
      assignmentFee,
      assignmentContractDate,
      deadReasonIds,
      deadReasonOtherText,
      referralFee,
      contractclosingDate,
      existingTypeId,
      contingencyArray,
      contingencyStatus,
    } = this.offersForm.value;

    dateOfYourOffer = this._commonFunction.dueDateFormat(dateOfYourOffer, '', {
      hours: 0,
      minutes: 0,
      seconds: 0,
    }).timeStamp;
    underContractDate = this._commonFunction.dueDateFormat(underContractDate)
      .timeStamp;
    closingDate = this._commonFunction.dueDateFormat(closingDate).timeStamp;

    contingencyArray = contingencyArray.map((x) => {
      x.contingencyExpiration = this._commonFunction.dueDateFormat(
        x.contingencyExpiration
      ).timeStamp;

      if (x.contingencyTypeId) {
        const contingency = this.contingencies.find(
          (contingency) => contingency.value === x.contingencyTypeId
        );
        x.contingencyName = contingency?.label || '';
      }

      return x;
    });
    assignmentContractDate = this._commonFunction.dueDateFormat(
      assignmentContractDate
    ).timeStamp;
    contractclosingDate = this._commonFunction.dueDateFormat(
      contractclosingDate
    ).timeStamp;

    const { to, from } = this.data.status;
    const {
      isAppointmentSet,
      isOffersMade,
      isUnderContract,
      isAssignedToBuyer,
      isDeadLead,
      isWarmLead,
      isReferredToAgent,
      isInventory,
    } = this.data.isVisibleData;
    let inventory = this.getInventoryData();
    let statusChangeData = {};
    let exitTypeName = '';
    this.investmentType.filter((e) => {
      if (e?._id == existingTypeId) exitTypeName = e?.name;
    });
    
    if ((from == StatusConstant.LeadStatus.DEAD_LEAD || from == StatusConstant.LeadStatus.WARM_LEAD || from == StatusConstant.LeadStatus.REFERRED_TO_AGENT) && isUnderContract) {
        statusChangeData['isUnderContract'] = true;
        statusChangeData['underContractDate'] = underContractDate;
        statusChangeData['underContractPrice'] = underContractPrice;
        statusChangeData['closingDate'] = closingDate;
        if (!isAssignedToBuyer) {
          statusChangeData['existingTypeId'] = existingTypeId;
          statusChangeData['contingencyArray'] = contingencyArray;
          statusChangeData['contingencyStatus'] = contingencyStatus;
          statusChangeData['contingencyType'] = 0;
        }
    }
    else if (!isInventory && isDeadLead && this.data?.fromKey != 'isDeadLead') {
      statusChangeData = {
        deadReasonIds: deadReasonIds
          .filter((x) => x.selected)
          .map((x) => {
            return x.value;
          }),
        deadReasonOtherText,
      };
    } else {
      if (isOffersMade && this.data?.fromKey != 'isOffersMade') {
        statusChangeData['isOffersMade'] = true;
        statusChangeData['yourOfferPrice'] = yourOfferPrice;
        statusChangeData['offerType'] = offerType;
        statusChangeData['dateOfYourOffer'] = dateOfYourOffer;
      }

      if (isUnderContract && this.data?.fromKey != 'isUnderContract') {
        statusChangeData['isUnderContract'] = true;
        statusChangeData['underContractDate'] = underContractDate;
        statusChangeData['underContractPrice'] = underContractPrice;
        statusChangeData['closingDate'] = closingDate;
        if (!isAssignedToBuyer) {
          statusChangeData['existingTypeId'] = existingTypeId;
          statusChangeData['contingencyArray'] = contingencyArray;
          statusChangeData['contingencyStatus'] = contingencyStatus;
          statusChangeData['contingencyType'] = 0;
        }
      }

      if (isAssignedToBuyer && this.data?.fromKey != 'isAssignedToBuyer') {
        statusChangeData['isAssignedToBuyer'] = true;
        statusChangeData['assignmentFee'] = assignmentFee;
        statusChangeData['assignmentContractDate'] = assignmentContractDate;
      }

      if (isInventory && this.data?.fromKey != 'isInventory') {
        statusChangeData['isAssignedToBuyer'] = true;
        statusChangeData['inventory'] = inventory;
      }
      if (isAppointmentSet && this.data?.fromKey != 'isAppointmentSet') {
        statusChangeData = {
          isAppointmentSet: true,
        };
      }


    }
    // if (to === StatusConstant.InventoryStatus.SOLD) {
    //   statusChangeData = {
    //     referralFee,
    //     closingDate,
    //     inventory: {
    //       ...inventory,
    //       purchaseDate: closingDate,
    //     },
    //   };
    // }

    if (
      !statusChangeData['contingencyArray'] ||
      !statusChangeData['contingencyArray'].length
    ) {
      delete statusChangeData['contingencyArray'];
    }

    return statusChangeData;
  }

  getInventoryData() {
    let {
      projectTypeId,
      mainStatusId,
      purchaseDate,
      purchasePrice,
      rehabStart,
      rehabFinish,
      goingOnMarket,
      listedPrice,
      underCoGoing,
      underCoPrice,
      underCoClosingDate,
      salesPrice,
      salesDate,
      netProfit,
      leaseStartDate,
      leaseEndDate,
    } = this.offersForm.value;

    // INVENTORY
    purchaseDate = this._commonFunction.dueDateFormat(purchaseDate).timeStamp;
    rehabStart = this._commonFunction.dueDateFormat(rehabStart).timeStamp;
    rehabFinish = this._commonFunction.dueDateFormat(rehabFinish).timeStamp;
    goingOnMarket = this._commonFunction.dueDateFormat(goingOnMarket).timeStamp;
    underCoGoing = this._commonFunction.dueDateFormat(underCoGoing).timeStamp;
    underCoClosingDate = this._commonFunction.dueDateFormat(underCoClosingDate)
      .timeStamp;
    salesDate = this._commonFunction.dueDateFormat(salesDate).timeStamp;
    leaseStartDate = this._commonFunction.dueDateFormat(leaseStartDate)
      .timeStamp;
    leaseEndDate = this._commonFunction.dueDateFormat(leaseEndDate).timeStamp;

    let inventory = {};
    if (this.isSold && this.isAssignedToSold == false) {
      inventory = {
        projectTypeId,
        mainStatusId,
        purchaseDate,
        assignedToBuyerToSold: this.assignedToBuyerToSold,
        referredToAgentToSold: this.referredToAgentToSold,

        // purchaseDate,
        // purchasePrice,
        // salesDate,
        // salesPrice,
      };
    } else if (this.isSold) {
      inventory = {
        projectTypeId,
        mainStatusId,
        purchaseDate,
        purchasePrice,
        salesDate,
        salesPrice,
        netProfit,
      };
    } else {
      inventory = {
        projectTypeId,
        mainStatusId,
        purchaseDate,
        purchasePrice,
      };
    }

    if (this.isUnderRehab) {
      inventory['rehabStart'] = rehabStart;
      inventory['rehabFinish'] = rehabFinish;
    }

    if (this.isListedForSale) {
      inventory['rehabStart'] = rehabStart;
      inventory['rehabFinish'] = rehabFinish;
      inventory['goingOnMarket'] = goingOnMarket;
      inventory['listedPrice'] = listedPrice;
    }

    if (this.isUnderContract) {
      inventory['rehabStart'] = rehabStart;
      inventory['rehabFinish'] = rehabFinish;
      inventory['goingOnMarket'] = goingOnMarket;
      inventory['listedPrice'] = listedPrice;
      inventory['underCoGoing'] = underCoGoing;
      inventory['underCoPrice'] = underCoPrice;
      inventory['underCoClosingDate'] = underCoClosingDate;
    }

    return inventory;
  }

  onSubmit() {
    this.submitted = true;
    if (this.offersForm.invalid && this.data?.header !== 'dead lead') {
      return;
    }

    let statusChangeData: any = this.getData();
    
    if (
      !this.data?.isVisibleData?.isInventory &&
      this.data?.isVisibleData?.isDeadLead &&
      this.data?.fromKey != 'isDeadLead'
    ) {
      if (
        !statusChangeData?.deadReasonOtherText &&
        (!statusChangeData?.deadReasonIds ||
          !statusChangeData?.deadReasonIds.length)
      ) {
        this._toastrService.error('Please select any one reason');
        return;
      }
    }

    if (this.selectedAppointment) {
      this.dialogRef.close({
        ...statusChangeData,
        appointmentDate: new Date(
          this.selectedAppointment.startDateTime
        ).getTime(),
        dripId: this.dripStop ? this.data?.item?.dripId : '',
      });
      return;
    }

    if (!this.isChooseAppointment) {
      this.dialogRef.close({
        ...statusChangeData,
        dripId: this.dripStop ? this.data?.item?.dripId : '',
      });

      return;
    }

    this.appointment.onSubmit();
  }

  transformAmount(element) {
    var value = element.target.value.split('.');
    if (element.target.value && value[1]) {
      if (value[1].length == 2) {
        element.target.value = element.target.value;
      } else if (value[1].length == 1) {
        element.target.value = element.target.value + '0';
      } else {
        element.target.value = element.target.value + '00';
      }
    } else if (element.target.value != '$ ') {
      element.target.value = value[0] + '.00';
    }
  }

  addFormEvent() {
    this.purchaseDateMin = this.data.underContractDate
      ? new Date(this.data.underContractDate)
      : new Date();

    this.offersForm.get('underContractDate').valueChanges.subscribe((val) => {
      this.purchaseDateMin = new Date(val);
      this.offersForm.patchValue({
        purchaseDate: this.purchaseDateMin,
      });
    });
  }

  chooseDripStop(bool) {
    if (bool) {
      this.dripStop = true;
    } else {
      this.dripStop = false;
    }
  }

  _emit($event) {
    let statusChangeData: any = this.getData();

    this.dialogRef.close({
      ...statusChangeData,
      appointmentDate: new Date($event).getTime(),
      dripId: this.dripStop ? this.data?.item?.dripId : '',
    });
  }

  get contingencyFormArray() {
    return this.offersForm.get('contingencyArray') as FormArray;
  }

  addContingency() {
    this.contingencyFormArray.push(
      this.fb.group({
        contingencyName: ['', []],
        contingencyTypeId: ['', [Validators.required]],
        contingencyExpiration: ['', [Validators.required]],
      })
    );
  }

  deleteContingency(index) {
    this.contingencyFormArray.removeAt(index);
  }
}
