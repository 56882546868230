import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ListStackingService } from './../../../providers/list-stacking/list-stacking.service';
import { DialerService } from 'src/app/providers/dialer/dialer.service';
import { saveAs } from 'file-saver';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
@Component({
  selector: 'app-list-export-marketing',
  templateUrl: './list-export-marketing.component.html',
  styleUrls: ['./list-export-marketing.component.scss'],
})
export class ListExportMarketingComponent implements OnInit {
  public isMarketing: string = 'yes';
  public marketType: any[] = [];
  public marketTypeVal: any[] = ['Direct Mail', 'Cold Calling', 'SMS', 'RVM'];
  marketingDate: any[] = [null, null, null, null];
  campaignName: any[] = ['', '', '', ''];
  public dMToMinDateValue: any;
  public selectedMarketing: any[] = [];
  public listsDataCSV: any;
  public checkedIDs: any[] = [];
  addToAll: any = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<ListExportMarketingComponent>,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private _listStackingService: ListStackingService,
    private _dialerService: DialerService,
    private _commonFunctionsService: CommonFunctionsService
  ) {
    this.checkedIDs = this.data?.myobj?.dataColumns;
    console.log('this.data::', this.data);
    if (this.data?.fromExport) {
      this.dMToMinDateValue = new Date();
    }
  }

  ngOnInit(): void {}
  clearMarketingData(mode) {
    if (mode == 1) {
      this.marketingDate = [null, null, null, null];
      this.marketType = [];
      this.campaignName = ['', '', '', ''];
    }
  }
  validateMarketingData() {
    if (this.marketType.length == 0) {
      this._toastrService.error('Please select at least one Marketing Type');
      return false;
    }
    let index;
    this.selectedMarketing = [
      {
        directMail: false,
        campaignName: '',
        expectedStartDate: '',
      },
      {
        coldCalling: false,
        campaignName: '',
        expectedStartDate: '',
      },
      {
        exportedForSms: false,
        campaignName: '',
        expectedStartDate: '',
      },
      {
        exportedForRvm: false,
        campaignName: '',
        expectedStartDate: '',
      },
    ];
    for (let i = 0; i < this.marketType.length; i++) {
      index = this.marketTypeVal.indexOf(this.marketType[i]);
      if (index >= 0) {
        if (this.campaignName[index].length == 0) {
          this._toastrService.error(
            'Campaign Name is required for ' + this.marketType[i]
          );
          return false;
        } else if (this.marketingDate[index] == null) {
          this._toastrService.error(
            'Expected Starting Date is required for ' + this.marketType[i]
          );
          return false;
        } else {
          switch (index) {
            case 0:
              this.selectedMarketing[0]['directMail'] = true;
              break;
            case 1:
              this.selectedMarketing[1]['coldCalling'] = true;
              break;
            case 2:
              this.selectedMarketing[2]['exportedForSms'] = true;
              break;
            case 3:
              this.selectedMarketing[3]['exportedForRvm'] = true;
              break;
          }
        }
        this.selectedMarketing[index]['campaignName'] = this.campaignName[
          index
        ];
        this.selectedMarketing[index][
          'expectedStartDate'
        ] = this._commonFunctionsService.dueDateFormat(
          this.marketingDate[index]
        ).dateFormat;
      }
    }
    return true;
  }

  saveDetails() {
    if (this.isMarketing == 'yes' && !this.validateMarketingData()) {
      return;
    }
    let myObj = this.data?.myobj;
    myObj['exportedForMarketing'] = this.isMarketing == 'yes' ? true : false;
    myObj['exportDetails'] =
      this.isMarketing == 'yes' ? this.selectedMarketing : [];
    myObj['updateRelatedProperty'] = false;
    if (this.addToAll && this.addToAll.length > 0)
      myObj['updateRelatedProperty'] = true;

    this._loaderService.start();

    this._listStackingService.addMarketingDetails(myObj).subscribe(
      (response) => {
        if (response.code == 200) {
          this.saveActivity(myObj?.ids);
        } else {
          this._toastrService.error(response.msg);
        }
      },
      (err) => {
        this._loaderService.stop();
      }
    );
  }

  saveActivity(id) {
    let activityComment = [{ name: 'Marketing Details Added Successfully' }];
    let myobj = {
      subModuleId: id,
      comment: activityComment,
      activityType: 16,
      isPhoneActivity: true,
    };
    this._dialerService.addActivityList(myobj).subscribe(
      (response) => {
        this._loaderService.stop();
        this.dialog.close('submit');
        this._toastrService.success('Marketing Details Added Successfully');
      },
      (err) => {
        this._loaderService.stop();
        console.log(err);
      }
    );
  }
}
