<div class="dial-minisdiv" *ngIf="isMinimized" (click)="toggleDisplay()">
  <div class="re-dial-left">
    <div class="re-dialicon">
      <img src="assets/images/call-icon-white.png" alt="call" />{{
        contactData[currentContact - 1]?.contactType == "Buyer"
          ? contactData[currentContact - 1]?.firstName +
            " " +
            contactData[currentContact - 1]?.lastName
          : contactData[currentContact - 1]?.name
      }}
      • {{ leadData?.address ? leadData?.address : "N/A" }}
    </div>
  </div>
  <div class="re-dial-center" *ngIf="callReady">{{ callDuration }}</div>
  <div class="re-dial-center" *ngIf="!callReady"></div>

  <div class="re-dial-right">
    <span
      class="re-expand"
      (click)="toggleDisplay()"
      pTooltip="Maximize call window"
      tooltipPosition="bottom"
    >
      <img src="assets/images/icon-sqare.svg" alt="sqare" *ngIf="isMinimized" />
    </span>
    <span
      class="re-close"
      pTooltip="Close call window"
      [class.re-cross-disable]="isCalling"
      [class.re-close-disable]="makingCall"
      [class.re-cross-disable]="callEnd"
      tooltipPosition="bottom"
      matDialogClose
      mat-dialog-close
    >
      <img src="assets/images/close-white1.svg" alt="close" />
    </span>
  </div>
</div>
<mat-dialog-content>
  <div
    class="dial-fulldiv"
    *ngIf="!isMinimized"
    [class.re-inner-auto]="callEnd"
    [class.re-hide-sidebar]="callEnd"
  >
    <div class="re-dial-left">
      <!-- <div class="re-tabsdiv">
        <div role="tablist" class="nav re-tabs-btn">
          <a
            href="#tabs1"
            role="tab"
            data-toggle="tab"
            aria-expanded="false"
            class="active"
            >Lead Details</a
          >
          <a
            href="#tabs2"
            role="tab"
            data-toggle="tab"
            aria-expanded="false"
            (click)="getActivityLog(true)"
            >Activity Log</a
          >
        </div>
      </div> -->
      <div class="tab-content">
        <div role="tabpanel" id="tabs1" class="tab-pane active">
          <div class="re-diallead">
            <div class="re-dialinfo scrollhide1">
              <div
                class="re-trialdiv"
                *ngIf="_utilities.currentPlanData?.dialerUpgradeDate"
              >
                <div class="re-txt">
                  Free trial of upgraded Leads Follow-Up Dialer ends
                  {{
                    _utilities.currentPlanData?.dialerUpgradeDate
                      | date : "MMMM dd, yyyy"
                  }}
                </div>
                <!-- <a class="re-hidebtn" (click)="freeTrialHide()">
                  <i class="fa fa-times-circle mr-1"></i>Hide</a
                > -->
              </div>
              <div class="re-lead-topbar">
                <div class="breathbar">
                  <span class="re-address">{{
                    leadData?.address
                      ? _utilities.formatAddress(
                          leadData?.address,
                          leadData?.unitNo
                        )
                      : "N/A"
                  }}</span>
                  <span class="re-google-icon">
                    <a
                      *ngIf="leadData?.address && leadData?.address != 'NA'"
                      target="_blank"
                      pTooltip="Click to view on Google"
                      tooltipPosition="bottom"
                      href="https://www.google.com/search?q={{
                        _utilities.formatAddress(leadData?.address)
                      }}"
                    >
                      <img
                        src="/assets/images/external-link.svg"
                        alt="external-link"
                      />
                    </a>
                    <a
                      *ngIf="leadData?.address && leadData?.address != 'NA'"
                      target="_blank"
                      pTooltip="Click to view on Zillow"
                      tooltipPosition="bottom"
                      href="https://www.zillow.com/homes/{{
                        _utilities.formatAddress(leadData?.address)
                      }}"
                    >
                      <img
                        src="/assets/images/open-zillow.png"
                        alt="external-link"
                      />
                    </a>
                    <a
                      (click)="
                        onMapDailog(
                          'google-map',
                          leadData?.latitude,
                          leadData?.longitude
                        )
                      "
                      pTooltip="Click to view Google Map View"
                      tooltipPosition="bottom"
                      ><img
                        src="/assets/images/google_pointer.svg"
                        alt="google_pointer"
                    /></a>
                    <a
                      (click)="streetViewApi('street-map', leadData?.address)"
                      pTooltip="Click to view Google Street View"
                      tooltipPosition="bottom"
                      ><img src="/assets/images/view_doll.svg" alt="view_doll"
                    /></a>
                    <a
                      (click)="markHotLead()"
                      *ngIf="leadData?.isHotLead"
                      pTooltip="Click to unmark as Hot Lead"
                      tooltipPosition="bottom"
                      ><img
                        src="/assets/images/hot-lead-new.svg"
                        alt="hot"
                        *ngIf="leadData?.isHotLead"
                      /><img
                        src="/assets/images/hot-lead.svg"
                        alt="hot"
                        *ngIf="!leadData?.isHotLead"
                      />
                    </a>
                  </span>
                  <!-- <span class="re-google-icon">
                    <a (click)="markAsFavorite()"
                      ><img
                        src="/assets/images/favorite-active-dark.svg"
                        alt="favorite"
                        *ngIf="leadData?.isFavourite" /><img
                        src="/assets/images/favorite.svg"
                        alt="favorite"
                        *ngIf="!leadData?.isFavourite"
                    /></a>
                    <a><img src="/assets/images/time-icon.png" alt="drip" /></a>
                  </span> -->

                  <!-- Dropdown Icon -->
                  <div class="re-custom-dropdown" *ngIf="leadData?.dripId">
                    <div class="re-drip-drop dropdown">
                      <a
                        class="dropdown-toggle"
                        id="drip-dropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src="/assets/images/drip.svg" alt="drip" />
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="drip-dropdown"
                      >
                        <div class="dropdown-sub">
                          <a class="fa fa-times-circle re-close"></a>
                          <div class="re-infodata">
                            <h5 class="re-titles">Drip sequence</h5>
                            <span class="re-name">{{
                              isDripData?.dripName
                            }}</span>
                            <span class="re-times"
                              >Started: {{ isDripData?.started }}</span
                            >
                            <span class="re-times"
                              >Auto Stop:
                              {{
                                isDripData?.autoStopDrip == true ? "Yes" : "No"
                              }}</span
                            >
                          </div>
                          <div class="re-summary">
                            <h5 class="re-titles">Drip SUMMARY</h5>
                            <div class="re-infobox">
                              <div class="re-infobox-list">
                                <span class="re-txt1">Active since</span>
                                <span class="re-txt2 re-active-since">
                                  {{ isDripData?.dripStartDate }}
                                </span>
                              </div>
                              <div class="re-infobox-list">
                                <span class="re-txt1">#SMS sent</span>
                                <span class="re-txt2">
                                  {{ isDripData?.completedData?.sms || 0 }} SMS
                                </span>
                              </div>
                              <div class="re-infobox-list">
                                <span class="re-txt1">#RVM sent</span>
                                <span class="re-txt2">
                                  {{ isDripData?.completedData?.rvm || 0 }} RVMs
                                </span>
                              </div>
                              <div class="re-infobox-list">
                                <span class="re-txt1">#Email sent</span>
                                <span class="re-txt2">
                                  {{ isDripData?.completedData?.email || 0 }}
                                  Emails
                                </span>
                              </div>
                              <div class="re-infobox-list">
                                <span class="re-txt1">Direct mails</span>
                                <span class="re-txt2">
                                  {{
                                    isDripData?.completedData?.directMail || 0
                                  }}
                                  Direct mails
                                </span>
                              </div>
                              <div class="re-infobox-list">
                                <span class="re-txt1">Tasks</span>
                                <span class="re-txt2">
                                  {{ isDripData?.completedData?.task || 0 }}
                                  Tasks
                                </span>
                              </div>
                              <div class="re-infobox-list">
                                <span class="re-txt1">Webhook Trigger</span>
                                <span class="re-txt2">
                                  {{ isDripData?.completedData?.webhook || 0 }}
                                  Webhook Trigger
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="re-steps">
                            <h5 class="re-titles">STEPS Summary</h5>
                            <div class="re-stepbox">
                              <div
                                class="re-stepbox-list"
                                *ngFor="
                                  let drip of isDripData?.breakDown;
                                  let i = index
                                "
                              >
                                <img
                                  class="re-img"
                                  *ngIf="drip.completed"
                                  src="/assets/images/circle-icons.png"
                                  alt="check"
                                />
                                <img
                                  class="re-img"
                                  *ngIf="!drip.completed"
                                  src="/assets/images/check-circle.png"
                                  alt="check"
                                />
                                <span class="re-txt1">
                                  Step #{{ i + 1 }} -
                                  {{ getActionName(drip?.action) }}
                                </span>
                                <span class="re-txt2" *ngIf="drip.completed">
                                  Scheduled {{ drip.scheduled }}
                                </span>
                                <span class="re-txt2" *ngIf="!drip.completed">
                                  {{ drip.scheduled }}
                                </span>
                              </div>
                            </div>
                          </div>
                          <a (click)="onCampaign(true)" class="re-stop-drip"
                            >Stop Drip</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Dropdown Icon -->
                  <div class="re-custom-dropdown" *ngIf="isPinnedIconView">
                    <div
                      class="re-pin-drop dropdown"
                      *ngIf="moduleName == 'lead'"
                    >
                      <a
                        class="dropdown-toggle"
                        id="pin-dropdown"
                        (click)="changeActivityType()"
                        pTooltip="Click to view Pinned Notes"
                        tooltipPosition="bottom"
                      >
                        <img src="/assets/images/pin-teal1.svg" alt="pin" />
                      </a>
                      <div class="dropdown-menu" aria-labelledby="pin-dropdown">
                        <a
                          class="fa fa-times-circle re-close"
                          (click)="closePinned()"
                        ></a>
                        <activity-logs
                          *ngIf="activityView"
                          #activityLog
                          [activityMainStyle]="{
                            height: 'auto',
                            maxHeight: 'CALC(90vh - 100px)'
                          }"
                          [style]="{ borderTop: 'none' }"
                          [moduleId]="moduleId"
                          [leadId]="leadId"
                          [leadData]="data?.leadData"
                          [activityType]="activityType"
                          [moduleName]="moduleName"
                          [isPinned]="true"
                          [activityFilterView]="false"
                          [activityQuillBox]="false"
                          [isWebFormLink]="true"
                          [isDateView]="false"
                          [isPinnedIconView]="true"
                          [isPinnedCount]="true"
                        ></activity-logs>
                      </div>
                    </div>
                  </div>

                  <a
                    class="re-newopen-icon"
                    *ngIf="leadData?.isNewLead"
                    (click)="onOpenLead()"
                    [pTooltip]="
                      !openLeadTime
                        ? 'Running timer only available for leads that go in Open status starting Apr 28, 2022'
                        : ''
                    "
                    tooltipPosition="bottom"
                  >
                    Open {{ openLeadTime ? "|" : "" }}
                    <b>{{ openLeadTime }}</b>
                  </a>

                  <!-- Qualified -->
                  <span
                    class="re-qualified-btn pull-left"
                    (click)="changeQualified()"
                    *ngIf="isVisibleOnDialer('lead')"
                    [pTooltip]="
                      leadData?.isQualified
                        ? 'Click to mark this lead as Not Qualified'
                        : 'Click to mark this lead as Qualified'
                    "
                    tooltipPosition="bottom"
                  >
                    <img
                      src="/assets/images/check-cheked.svg"
                      alt="check"
                      *ngIf="leadData?.isQualified"
                    />
                    <img
                      src="/assets/images/close-red.svg"
                      alt="close"
                      *ngIf="!leadData?.isQualified"
                    />
                    {{ !leadData?.isQualified ? "Not" : "" }} Qualified
                  </span>

                  <!-- <button class="re-switchbtn" (click)="switchToBasicDialer()">
                    Switch to Basic Dialer
                  </button> -->
                  <button class="re-activbtn" (click)="showActivity()">
                    Activity Log
                  </button>
                </div>
                <div class="subbar">
                  <div
                    class="custom-select1 dropdown drop"
                    *ngIf="projectStatus?.length"
                    [pTooltip]="
                      makingCall ? 'You cannot change status during Call' : ''
                    "
                    tooltipPosition="bottom"
                  >
                    <a
                      id="dropdownMenu2"
                      data-toggle="dropdown"
                      href="javascript:void(0);"
                      aria-haspopup="true"
                      aria-expanded="false"
                      class="select-selected"
                      [style]="
                        makingCall
                          ? 'pointer-events: none'
                          : 'pointer-events: auto'
                      "
                    >
                      {{
                        leadData?.mainStatusTitle
                          ? leadData?.mainStatusTitle
                          : ""
                      }}
                    </a>

                    <div aria-labelledby="dropdownMenu2" class="dropdown-menu">
                      <span
                        *ngFor="
                          let status of projectStatus;
                          let statusIndex = index
                        "
                      >
                        <div
                          class="dropdown-item select-value"
                          [class.active]="isActive(status)"
                          (click)="selectMainStatus(status)"
                        >
                          {{
                            status?.labels?.title ||
                              status?.name ||
                              status?.title
                          }}
                        </div>
                      </span>
                    </div>
                  </div>

                  <span class="breath" *ngIf="leadData?.crmQuestionTitle">
                    {{ leadData?.crmQuestionTitle }}
                  </span>

                  <span class="breath re-breath-market">
                    {{
                      leadData?.marketingTitle && !leadData?.defaultNumber
                        ? leadData?.marketingTitle
                        : "Other"
                    }}
                  </span>
                  <span
                    class="breath re-breath-market"
                    *ngIf="leadData?.number"
                  >
                    {{ _utilities.maskNumber(leadData?.number) }}
                  </span>
                </div>
                <div class="re-tag-full-width">
                  <app-global-tags
                    [showTags]="showTags"
                    [splitTags]="splitTags"
                    [moduleName]="moduleName"
                    [leadId]="leadId"
                    [moduleId]="moduleId"
                    [tagType]="tagType"
                    [tagDialer]="true"
                    [allTags]="leadData?.tags"
                    (_refreshLeadsInfo)="getLeadDetails(leadId)"
                  >
                  </app-global-tags>
                </div>
                <!-- <div class="re-tags">
                  <div class="re-list-with-dropdown">
                    <button type="button" class="droptoggle">
                      test <i class="fa fa-times-circle"></i>
                    </button>
                  </div>
                  <div class="re-list-with-dropdown">
                    <button type="button" class="droptoggle">
                      asdf <i class="fa fa-times-circle"></i>
                    </button>
                  </div>
                  <a href="javascript:void(0)" class="re-tags-list2">
                    +2 More
                  </a>
                  <a href="javascript:void(0);" class="re-tags-list3">
                    +Add Tag
                  </a>
                </div> -->
              </div>
              <div class="re-lead-data">
                <div class="re-analytic">
                  <div class="re-titlediv">
                    <h4>ANALYTICS INFO</h4>
                    <h5>
                      Days in pipeline:
                      {{ pipeline(leadData?.leadCreated) || "1" }} Days
                    </h5>
                  </div>
                  <div
                    class="re-list"
                    [class.active]="leadData?.leadCreated"
                    [class.current]="leadData?.appointmentDate"
                  >
                    <span class="re-text1">Lead created</span>
                    <span class="re-text2">
                      {{ getDate(leadData?.leadCreated, "MMM DD, yyyy") }}
                    </span>
                  </div>
                  <div
                    class="re-list"
                    [class.active]="leadData?.appointmentDate"
                    [class.current]="
                      leadData?.dateOfYourOffer || leadData?.yourOfferPrice
                    "
                  >
                    <span class="re-text1">Appointment</span>
                    <span class="re-text2">
                      {{ getDate(leadData?.appointmentDate, "MMM DD, yyyy") }}
                    </span>
                  </div>
                  <div
                    class="re-list"
                    [class.active]="
                      leadData?.dateOfYourOffer || leadData?.yourOfferPrice
                    "
                    [class.current]="
                      leadData?.underContractDate ||
                      leadData?.underContractPrice
                    "
                  >
                    <span class="re-text1">Offer made</span>
                    <span class="re-text2">
                      {{ getDate(leadData?.dateOfYourOffer, "MMM DD, yyyy") }}
                    </span>
                    <span class="text3" style="color: #95989a">
                      {{
                        leadData?.yourOfferPrice >= 0
                          ? (leadData?.yourOfferPrice | currency)
                          : "N/A"
                      }}
                    </span>
                  </div>
                  <div
                    class="re-list"
                    [class.active]="
                      leadData?.underContractDate ||
                      leadData?.underContractPrice
                    "
                    [class.current]="
                      leadData?.assignmentContractDate ||
                      leadData?.assignmentFee
                    "
                  >
                    <span class="re-text1">Under contract</span>
                    <span class="re-text2">
                      {{ getDate(leadData?.underContractDate, "MMM DD, yyyy") }}
                    </span>
                    <span class="re-text2" style="color: #95989a">
                      {{
                        leadData?.underContractPrice >= 0
                          ? (leadData?.underContractPrice | currency)
                          : "N/A"
                      }}
                    </span>
                    <span class="text3">
                      {{ getDate(leadData?.closingDate, "MMM DD, yyyy") }}
                    </span>
                  </div>
                  <div
                    class="re-list"
                    [class.active]="
                      leadData?.assignmentContractDate ||
                      leadData?.assignmentFee
                    "
                    [class.current]="
                      leadData?.assignmentContractDate ||
                      leadData?.assignmentFee
                    "
                  >
                    <span class="re-text1">Assigned</span>
                    <span class="re-text2">
                      {{
                        getDate(
                          leadData?.assignmentContractDate,
                          "MMM DD, yyyy"
                        )
                      }}
                    </span>
                    <span class="re-text4">
                      {{
                        leadData?.assignmentFee >= 0
                          ? (leadData?.assignmentFee | currency)
                          : "N/A"
                      }}
                    </span>
                  </div>
                </div>
                <div class="re-property">
                  <div class="re-titlediv">
                    <h4>Property Info</h4>
                  </div>
                  <ul class="re-list">
                    <!-- MARKET -->
                    <li>
                      <img src="assets/images/newHouse.svg" alt="market" />
                      <span class="re-text1">Market</span>
                      <span class="re-text2">
                        {{ getMarket() }}
                      </span>
                    </li>

                    <!-- OWNER INFO -->
                    <li>
                      <img
                        src="assets/images/inventory-details/leadicons-1.png"
                        alt="leadicons"
                      />
                      <span class="re-text1">Owner info</span>
                      <span class="re-text2 re-overdata">
                        {{
                          !leadData?.ownerMailingAddress
                            ? "Unknown"
                            : leadData?.address?.includes(
                                leadData?.ownerMailingAddress
                              )
                            ? "Homeowner"
                            : "Absentee"
                        }}
                        <span class="more">More</span>

                        <div class="toolbox max">
                          <span class="txt1">Owner name</span>
                          <span class="txt2 mb">
                            {{
                              leadData?.ownerName1
                                ? leadData?.ownerName1
                                : "N/A"
                            }}
                          </span>
                          <span class="txt1">Owner mailing address</span>
                          <span class="txt2">
                            {{
                              leadData?.ownerMailingAddress
                                ? leadData?.ownerMailingAddress
                                : "N/A"
                            }}
                          </span>
                        </div>
                      </span>
                    </li>

                    <!-- BEDROOM COUNT -->
                    <li>
                      <img
                        style="margin-top: 3px"
                        src="assets/images/newbeds.svg"
                        alt="newbeds"
                      />
                      <span class="re-text1"># of Bedrooms</span>
                      <span class="re-text2">
                        {{ leadData?.bedroomsCount || "N/A" }}
                      </span>
                    </li>

                    <!-- BATHROOM COUNT -->
                    <li>
                      <img
                        style="margin-top: 3px"
                        src="assets/images/newbaths.svg"
                        alt="newBaths"
                      />
                      <span class="re-text1"># of Bathrooms</span>
                      <span class="re-text2">
                        {{ leadData?.bathCount || "N/A" }}
                      </span>
                    </li>

                    <!-- AREA -->
                    <li>
                      <img
                        style="margin-top: 3px"
                        src="assets/images/newMeasureRuler.svg"
                        alt="measureruler"
                      />
                      <span class="re-text1">Approx. Sqft.</span>
                      <span class="re-text2"
                        >{{
                          leadData?.area && leadData?.area > 0
                            ? (leadData?.area | number) + " sqft"
                            : "N/A"
                        }}
                      </span>
                    </li>

                    <!-- LOT SIZE -->
                    <li>
                      <img src="assets/images/newRuler.svg" alt="newRuler" />
                      <span class="re-text1">Lot Size Sqft.</span>
                      <span class="re-text2">
                        <ng-container
                          *ngIf="
                            !leadData?.lotSizeSqft ||
                              leadData?.lotSizeSqft == 'undefined';
                            else else_block
                          "
                        >
                          N/A
                        </ng-container>
                        <ng-template #else_block>
                          {{
                            leadData?.lotSizeSqft && leadData?.lotSizeSqft > 0
                              ? (leadData?.lotSizeSqft | number) + " sqft"
                              : "N/A"
                          }}
                        </ng-template>
                      </span>
                    </li>

                    <!-- YEAR BUILT -->
                    <li>
                      <img
                        src="assets/images/newYearBuild.svg"
                        alt="YearBuild"
                      />
                      <span class="re-text1">Year Built</span>
                      <span class="re-text2">
                        {{ leadData?.yearBuilt || "N/A" }}
                      </span>
                    </li>

                    <!-- HOUSE TYPE -->
                    <li>
                      <img src="assets/images/newHouse.svg" alt="newHouse" />
                      <span class="re-text1">House Type</span>
                      <span class="re-text2">{{
                        leadData?.useCode || "N/A"
                      }}</span>
                    </li>

                    <!-- ESTIMATED VALUE -->
                    <!-- Estimate Low -->
                    <li>
                      <img src="assets/images/newDollar.svg" alt="newDollar" />
                      <span class="re-text1">Estimated Value</span>

                      <span
                        *ngIf="
                          leadData?.estimateLow &&
                          leadData?.estimateLow != '0' &&
                          leadData?.estimateLow != 0
                        "
                        class="re-text2"
                        >{{ (leadData?.estimateLow | currency) || "N/A" }}</span
                      >
                      <span
                        *ngIf="
                          leadData?.estimateLow == '0' ||
                          leadData?.estimateLow == 0 ||
                          leadData?.estimateLow == '' ||
                          !leadData?.estimateLow
                        "
                        class="re-text2"
                        >N/A</span
                      >
                    </li>

                    <!-- MORTGAGE INFO -->

                    <li>
                      <img src="assets/images/newDollar.svg" alt="newDollar" />
                      <span class="re-text1">Mortgage Info</span>
                      <span class="re-text2 re-overdata">
                        {{ (leadData?.mortgageAmount | currency) || "N/A" }}
                        <span
                          class="more"
                          *ngIf="
                            leadData?.mortgageDate ||
                            leadData?.mortgageDate !== '0'
                          "
                          >More</span
                        >

                        <div
                          class="toolbox max"
                          *ngIf="
                            leadData?.mortgageDate ||
                            leadData?.mortgageDate !== '0'
                          "
                        >
                          <span class="txt1">Mortgage Amount</span>
                          <span class="txt2 mb">
                            {{
                              leadData?.mortgageAmount != 0 &&
                              leadData?.mortgageAmount != "0"
                                ? (leadData?.mortgageAmount | currency)
                                : "N/A"
                            }}
                          </span>
                          <span class="txt1">Mortgage Date</span>
                          <span class="txt2">
                            {{
                              leadData?.mortgageDate &&
                              leadData?.mortgageDate != 0
                                ? (leadData?.mortgageDate
                                  | date : "MMM dd, yyyy")
                                : "N/A"
                            }}
                          </span>
                        </div>
                      </span>
                    </li>

                    <!-- TAX INFO -->
                    <li>
                      <img src="assets/images/newDollar.svg" alt="newDollar" />
                      <span class="re-text1">Tax Info</span>
                      <span class="re-text2 re-overdata">
                        {{
                          leadData?.taxAssessedValue &&
                          leadData?.taxAssessedValue != 0 &&
                          leadData?.taxAssessedValue != "0"
                            ? (leadData?.taxAssessedValue | currency)
                            : "N/A"
                        }}
                        <span
                          class="more"
                          *ngIf="
                            leadData?.taxAssessedYear ||
                            leadData?.taxAssessedValue
                          "
                        >
                          More
                        </span>

                        <!-- MORE -->
                        <div
                          class="toolbox"
                          *ngIf="
                            leadData?.taxAssessedYear ||
                            leadData?.taxAssessedValue
                          "
                        >
                          <span class="txt1">Tax Assessed Year</span>
                          <span class="txt2 mb">
                            {{ leadData?.taxAssessedYear || "N/A" }}
                          </span>
                          <span class="txt1">Tax Assessed Value</span>
                          <span class="txt2 mb">
                            {{
                              leadData?.taxAssessedValue != 0 &&
                              leadData?.taxAssessedValue != "0"
                                ? (leadData?.taxAssessedValue | currency)
                                : "N/A"
                            }}
                          </span>
                          <span class="txt1">Tax Billed Amount</span>
                          <span class="txt2">
                            {{
                              leadData?.taxBilledAmount != 0 &&
                              leadData?.taxBilledAmount != "0"
                                ? (leadData?.taxBilledAmount | currency)
                                : "N/A"
                            }}
                          </span>
                        </div>
                      </span>
                    </li>

                    <!-- SALES INFO -->
                    <li>
                      <img src="assets/images/newDollar.svg" alt="newDollar" />
                      <span class="re-text1">Sales Info</span>
                      <span class="re-text2 re-overdata">
                        {{
                          leadData?.lastSoldDate && leadData?.lastSoldDate != 0
                            ? (leadData?.lastSoldDate | date : "MMM dd, yyyy")
                            : "N/A"
                        }}
                        <span
                          class="more"
                          *ngIf="
                            leadData?.lastSoldDate ||
                            leadData?.lastSoldPrice ||
                            leadData?.priorSalePrice
                          "
                        >
                          More
                        </span>

                        <div
                          class="toolbox"
                          *ngIf="
                            leadData?.lastSoldDate ||
                            leadData?.lastSoldPrice ||
                            leadData?.priorSalePrice
                          "
                        >
                          <span class="txt1">Sales Date</span>
                          <span class="txt2 mb">
                            {{
                              leadData?.lastSoldDate &&
                              leadData?.lastSoldDate != 0
                                ? (leadData?.lastSoldDate
                                  | date : "MMM dd, yyyy")
                                : "N/A"
                            }}
                          </span>
                          <span class="txt1">Sales Amount</span>
                          <span class="txt2 mb">
                            {{
                              leadData?.lastSoldPrice != 0 &&
                              leadData?.lastSoldPrice != "0"
                                ? (leadData?.lastSoldPrice | currency)
                                : "N/A"
                            }}
                          </span>
                          <span class="txt1">Prior Sale Date</span>
                          <span class="txt2 mb">
                            {{
                              leadData?.priorSaleDate &&
                              leadData?.priorSaleDate != 0
                                ? (leadData?.priorSaleDate
                                  | date : "MMM dd, yyyy")
                                : "N/A"
                            }}
                          </span>
                          <span class="txt1">Prior Sale Price</span>
                          <span class="txt2">
                            {{ (leadData?.priorSalePrice | currency) || "N/A" }}
                          </span>
                        </div>
                      </span>
                    </li>

                    <!-- GARAGE STATUS -->
                    <li>
                      <img src="assets/images/newnewGarage.svg" alt="Garage" />
                      <span class="re-text1">Is There a Garage</span>
                      <span class="re-text2" *ngIf="checkGarage()">
                        <span
                          *ngIf="leadData?.garage?.status"
                          style="white-space: nowrap"
                        >
                          {{ leadData?.garage?.status ? "Yes" : "No" }}
                        </span>
                        <span
                          *ngIf="!leadData?.garage?.status"
                          style="white-space: nowrap"
                        >
                          N/A
                        </span>
                      </span>
                      <span class="re-text2" *ngIf="!checkGarage()">
                        <span>N/A</span>
                      </span>
                    </li>

                    <!-- GARAGE AREA -->
                    <li>
                      <img src="assets/images/newnewGarage.svg" alt="Garage" />
                      <span class="re-text1">How Big Is The Garage?</span>
                      <span class="re-text2">
                        {{ leadData?.garage?.sqft || "N/A" }}
                      </span>
                    </li>

                    <!-- GARAGE TYPE -->
                    <li>
                      <img src="assets/images/newnewGarage.svg" alt="Garage" />
                      <span class="re-text1">Garage Attached or Detached?</span>
                      <span
                        class="re-text2"
                        *ngIf="leadData?.garage?.garageType"
                      >
                        <ng-container
                          *ngIf="leadData?.garage?.garageType == 'true'"
                        >
                          Attached
                        </ng-container>
                        <ng-container
                          *ngIf="leadData?.garage?.garageType == 'false'"
                        >
                          Detached
                        </ng-container>
                        <ng-container
                          *ngIf="
                            leadData?.garage?.garageType != 'true' &&
                            leadData?.garage?.garageType != 'false'
                          "
                        >
                          {{ leadData?.garage?.garageType }}
                        </ng-container>
                      </span>
                      <span
                        class="re-text2"
                        *ngIf="!leadData?.garage?.garageType"
                      >
                        N/A
                      </span>
                    </li>
                  </ul>
                  <app-preference-question
                    #preferenceQuestion
                    [preferenceData]="preferenceData"
                    [listStackingInfo]="true"
                    [viewPropertyList]="viewPropertyList"
                  ></app-preference-question>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div role="tabpanel" id="tabs2" class="tab-pane text-center">
          <activity-logs
            #activityLog
            [activityMainStyle]="{ height: 'CALC(100vh - 335px)' }"
            [style]="{
              borderTop: 'none',
              maxWidth: '770px',
              textAlign: 'left'
            }"
            [activityMediaStyle]="true"
            [moduleId]="moduleId"
            [leadId]="leadId"
            [leadData]="data?.leadData"
            [moduleName]="moduleName"
            [isPinned]="true"
            [activityTabView]="false"
            [activityQuillBox]="false"
            [isWebFormLink]="true"
          ></activity-logs>
        </div>
      </div>
      <div class="re-diallead-btnlist" *ngIf="!callEnd">
        <div class="re-btnlist-left">
          <a
            href="javascript:;"
            class="btn-teal"
            (click)="omSmsDialog()"
            [class.disabled]="sendSmsBtn"
            >Send SMS</a
          >
          <a
            href="javascript:;"
            class="{{
              leadData?.dripId ? 'btn-teal re-btn-disable' : 'btn-teal'
            }}"
            (click)="onCampaign()"
            >Activate Drip</a
          >
          <a
            href="javascript:;"
            class="btn-teal"
            (click)="onAppointmentDialog()"
            >Add Appt.</a
          >
          <a href="javascript:;" class="btn-teal" (click)="onTaskDialog()"
            >Add Task</a
          >
        </div>
        <div class="re-btnlist-right">
          <a href="javascript:;" class="btn-blue" (click)="leaveNote()"
            >Leave Note</a
          >
          <a href="javascript:;" class="btn-blue" (click)="editLead()"
            >Edit Lead</a
          >
        </div>
      </div>
      <!--  -->
      <div class="re-dialer-result" *ngIf="callEnd">
        <div class="re-result-address">
          <h3 class="re-txt1">
            {{ leadData?.address ? leadData?.address : "N/A" }}

            <!-- Qualified -->
            <span
              class="re-qualified-btn"
              (click)="changeQualified()"
              *ngIf="isVisibleOnDialer('lead')"
              [pTooltip]="
                leadData?.isQualified
                  ? 'Click to mark this lead as Not Qualified'
                  : 'Click to mark this lead as Qualified'
              "
              tooltipPosition="bottom"
            >
              <img
                src="/assets/images/check-cheked.svg"
                alt="check"
                *ngIf="leadData?.isQualified"
              />
              <img
                src="/assets/images/close-red.svg"
                alt="close"
                *ngIf="!leadData?.isQualified"
              />
              {{ !leadData?.isQualified ? "Not" : "" }} Qualified
            </span>
          </h3>
          <h5 class="re-txt2">
            {{
              callingData?.contactType == "Buyer" ||
              callingData?.contactType == "Agent"
                ? callingData?.firstName + " " + callingData?.lastName
                : callingData?.name
            }}
            •
            {{
              callingData?.contactType == "Buyer" ||
              callingData?.contactType == "Agent"
                ? _utilities.maskNumber(callingData?.contactNumber)
                : _utilities.maskNumber(callingData?.phoneNumber)
            }}
          </h5>
          <a
            class="btn-red"
            (click)="oncallAnswerSubmit()"
            [class.re-disable]="!callAnswerForm.get('callAnswer').value"
            >Exit Dialing</a
          >
        </div>
        <div class="re-result-left">
          <div class="re-result-infos">
            <div class="re-result-note">
              <div class="">
                <label class="re-lab">NOTES</label
                ><textarea
                  rows="2"
                  placeholder="Enter your note and it will auto save once you click Call Next Contact or Stop Dialing"
                  name="comments"
                  class="form-control"
                  [(ngModel)]="comments"
                ></textarea>
              </div>
            </div>
            <form
              [formGroup]="callAnswerForm"
              (ngSubmit)="oncallAnswerSubmit()"
              autocomplete="off"
              novalidate
            >
              <div class="re-result-checkbx">
                <div class="row">
                  <div class="col-12 col-md-12">
                    <h4 class="re-headin">CALL RESULT</h4>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label class="re-lab">LEAD CONNECTED</label>
                      <div class="radiolist">
                        <label
                          class="radiobtn"
                          *ngFor="let callStatus of callConnectedStatus"
                          (click)="otherReason(callStatus?.value)"
                        >
                          <input
                            type="radio"
                            [value]="callStatus?.value"
                            name="callAnswer"
                            formControlName="callAnswer"
                          /><span class="checkmark"></span
                          >{{ callStatus?.name }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <h5 class="re-heading2">LEAD NOT CONNECTED</h5>
                      <div class="radiolist">
                        <ng-container
                          *ngFor="let callStatus of callNotConnectedStatus"
                        >
                          <label
                            class="radiobtn"
                            *ngIf="callStatus?.value != 12"
                            (click)="otherReason(callStatus?.value)"
                          >
                            <input
                              type="radio"
                              [value]="callStatus?.value"
                              name="callAnswer"
                              formControlName="callAnswer"
                            /><span class="checkmark"></span
                            >{{ callStatus?.name }}
                          </label>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-12 col-md-12"
                    *ngIf="callConnectReason || callNotConnectReason"
                  >
                    <div class="mb-0">
                      <textarea
                        rows="2"
                        placeholder="Enter Reason"
                        class="form-control"
                        formControlName="otherReason"
                        name="otherReason"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="re-btnlist">
              <a
                class="btn-teal-outline"
                (click)="oncallAnswerSubmit('Redial')"
                [class.re-disable]="
                  !callAnswerForm.get('callAnswer').value &&
                  mergeCallDialer.callEnded
                "
                ><img src="assets/images/icon-redial.svg" alt="redial" />
                Redial</a
              >
              <a
                href="javascript:;"
                (click)="oncallAnswerSubmit('Next')"
                class="{{
                  isNextEnable ? 'btn-teal2' : 'btn-teal2 re-btn-disable'
                }}"
                *ngIf="data?.isHideNext == false"
                [class.re-disable]="!callAnswerForm.get('callAnswer').value"
                ><img src="assets/images/icon-contact.svg" alt="contact" />Call
                Next Contact</a
              >
            </div>
          </div>
          <div class="re-tasklist">
            <h3 class="re-title">
              <span>My Assigned Tasks ({{ taskCount }}) </span>
              <a class="re-add-taskbtn" (click)="onTaskDialog()">Add Task</a>
            </h3>
            <div
              class="checkboxlist"
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              [scrollWindow]="false"
              (scrolled)="onScrollTask()"
            >
              <label
                class="re-checkboxbtn"
                *ngFor="let task of tasks; let i = index"
              >
                <input
                  type="checkbox"
                  name="status"
                  [value]="task?._id"
                  (click)="isCompletedTask(task)"
                />
                <span class="re-checkmark"></span>
                <span
                  class="re-txt"
                  [pTooltip]="task?.title"
                  tooltipPosition="bottom"
                  >{{ task?.title }}</span
                >
                <span class="re-list">
                  <img
                    alt="Description"
                    *ngIf="task.taskDetail"
                    [pTooltip]="task.taskDetail"
                    tooltipPosition="bottom"
                    src="assets/images/description-1.svg"
                  />
                  <img
                    alt="Bell"
                    *ngIf="task.reminders.length > 0"
                    src="assets/images/bell.svg"
                  />
                  <img
                    alt="Due Date"
                    [pTooltip]="
                      _utilities.dueDateFormat(
                        task?.dueInIsoTimeStamp,
                        task?.tasktimezone
                      ).timezoneDate +
                      ' ' +
                      _utilities.dueDateFormat(
                        task?.dueInIsoTimeStamp,
                        task?.tasktimezone
                      ).timezoneTime
                    "
                    tooltipPosition="bottom"
                    [src]="getTaskIcon(task?.dueInIsoTimeStamp)"
                  />
                  <span
                    class="re-custom re-call"
                    class="{{
                      customFunction(
                        task?.taskTypeOption,
                        'taskTypeOption',
                        'class'
                      )
                    }}"
                  >
                    {{
                      customFunction(
                        task?.taskTypeOption,
                        "taskTypeOption",
                        "value"
                      )
                    }}
                  </span>
                </span>
              </label>
            </div>
            <div class="re-btnlist">
              <a
                href="javascript:;"
                class="{{
                  leadData?.dripId ? 'btn-teal1 re-btn-disable' : 'btn-teal1'
                }}"
                (click)="onCampaign()"
                >Activate Drip</a
              >
              <a
                href="javascript:;"
                class="btn-teal1"
                (click)="onAppointmentDialog()"
                >Add Appt.</a
              >
            </div>
          </div>
          <div class="re-result-btnlist d-none">
            <div class="re-btnlist-left">
              <a
                href="javascript:;"
                class="{{
                  leadData?.dripId ? 'btn-teal1 re-btn-disable' : 'btn-teal1'
                }}"
                (click)="onCampaign()"
                >Activate Drip</a
              >
              <a
                href="javascript:;"
                class="btn-teal1"
                (click)="onAppointmentDialog()"
                >Add Appt.</a
              >
              <a href="javascript:;" class="btn-teal1" (click)="onTaskDialog()"
                >Add Task</a
              >
            </div>
            <div class="re-btnlist-right">
              <a class="btn-teal-outline" (click)="oncallAnswerSubmit('Redial')"
                ><img src="assets/images/icon-redial.svg" alt="redial" />
                Redial</a
              >
              <a
                href="javascript:;"
                (click)="oncallAnswerSubmit('Next')"
                class="{{
                  isNextEnable ? 'btn-teal2' : 'btn-teal2 re-btn-disable'
                }}"
                *ngIf="data?.isHideNext == false"
                ><img src="assets/images/icon-contact.svg" alt="contact" />Call
                Next Contact</a
              ><a class="btn-red" (click)="oncallAnswerSubmit()"
                ><img
                  src="assets/images/icon-stop-dialing.svg"
                  alt="dialing"
                />Stop Dialing</a
              >
            </div>
          </div>
        </div>
        <div class="re-result-right">
          <div class="re-expand-div">
            <span class="re-tit">SEND SMS</span>
          </div>
          <div class="re-form-div">
            <form
              [formGroup]="smsDetailForm"
              (ngSubmit)="sendSms()"
              autocomplete="off"
              novalidate
            >
              <div class="form-group">
                <label class="re-label">Send To</label
                ><select
                  class="form-control"
                  formControlName="smsNumber"
                  name="smsNumber"
                  (change)="setSmsTemplate(smsDetailForm.value.smsTemplateId)"
                >
                  <option
                    *ngFor="let contact of contactData; let i = index"
                    value="{{ i }}"
                  >
                    {{
                      contact?.contactType == "Buyer" ||
                      contact?.contactType == "Agent"
                        ? contact?.firstName
                          ? contact?.firstName
                          : "" + " " + contact?.lastName
                          ? contact?.lastName
                          : ""
                        : contact?.name
                    }}
                    •
                    {{
                      contact?.contactNumber
                        ? _utilities.maskNumber(contact?.contactNumber)
                        : contact?.phoneNumber
                        ? _utilities.maskNumber(contact?.phoneNumber)
                        : ""
                    }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label class="re-label">SMS Template</label
                ><select
                  class="form-control"
                  formControlName="smsTemplateId"
                  name="smsTemplateId"
                  (change)="setSmsTemplate($event.target.value, true)"
                >
                  <option value="">Select SMS Template</option>
                  <option *ngFor="let sms of smsTemplates" [value]="sms._id">
                    {{ sms?.templateName }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label class="re-label">Message text</label
                ><quill-editor
                  #editor
                  name="messageText"
                  formControlName="smsBody"
                  [styles]="editorStyle"
                  [modules]="{
                    toolbar: false,
                    mention: editorOptions.mention
                  }"
                  [placeholder]="editorOptions.placeholder"
                  (ngModelChange)="getSMSCount($event)"
                ></quill-editor>
                <div class="invalid-feedback mt-1">
                  <span
                    *ngIf="submittedSms && hasErrorSms('smsBody', 'required')"
                  >
                    *Please enter Message
                  </span>
                </div>
              </div>
              <div class="form-group">
                <span class="re-attach-title">ATTACHMENTS</span>
                <div class="re-attach-list">
                  <div
                    class="re-attach"
                    *ngFor="
                      let attachment of showAttachments;
                      let attachmentIndex = index
                    "
                  >
                    <span> {{ attachment?.name }} </span
                    ><img
                      src="/assets/images/close-black.svg"
                      (click)="removeExistingAttachment(attachmentIndex)"
                    />
                  </div>
                  <div
                    class="re-attach"
                    *ngFor="
                      let attachment of attachments;
                      let attachmentIndex = index
                    "
                  >
                    <span> {{ attachment?.name }} </span
                    ><img
                      src="/assets/images/close-black.svg"
                      (click)="removeAttachment(attachmentIndex)"
                    />
                  </div>
                </div>
                <span class="re-file-attach"
                  ><input
                    type="file"
                    name="mediaUrls"
                    formControlName="mediaUrls"
                    #uploader
                    ngFileSelect
                    multiple
                    (uploadOutput)="onUploadLogo($event)"
                    [uploadInput]="uploadInput"
                  /><img
                    src="assets/images/inventory-details/attachment-symbol.png"
                    alt="attachment"
                  />
                  Add attachments
                </span>
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-submit">Send SMS</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="re-dial-right">
      <div class="re-expand-div">
        <span class="txt">{{ callStatus }}</span>
        <span
          class="re-expand"
          pTooltip="Minimize call window"
          tooltipPosition="bottom"
          (click)="toggleDisplay()"
        >
          <img
            src="assets/images/icon-clone.svg"
            alt="clone"
            *ngIf="!isMinimized"
          />
        </span>
        <span
          class="re-close"
          pTooltip="Close call window"
          [class.re-cross-disable]="isCalling"
          [class.re-close-disable]="makingCall"
          [class.re-cross-disable]="callEnd"
          tooltipPosition="bottom"
          matDialogClose
          mat-dialog-close
        >
          <img src="assets/images/close-red.svg" alt="close" />
        </span>
      </div>
      <div class="re-call-change" *ngIf="defaultDiaplay">
        <div class="re-callnum">
          <span
            class="re-numb"
            *ngIf="contactType == 'seller' && leadData?.numberId"
            >{{
              leadData?.defaultCallNumber
                ? _utilities.maskNumber(leadData?.defaultCallNumber)
                : _utilities.maskNumber(leadData?.number)
            }}</span
          >
          <span
            class="re-numb"
            *ngIf="contactType == 'seller' && !leadData?.numberId"
            >N/A</span
          >
          <select
            class="re-sselect"
            *ngIf="contactType != 'seller'"
            [(ngModel)]="buyerCallFrom"
          >
            <option
              *ngFor="let source of buyerNumber; let i = index"
              [value]="source?.number"
            >
              {{ _utilities.maskNumber(source?.number) }}
            </option>
          </select>
          <span class="txt">Call/SMS/RVM from number</span>
          <a
            class="re-changebtn"
            href="javascript:;"
            (click)="changeNumber()"
            pTooltip="Change phone number to call, send SMS or send RVM from"
            tooltipPosition="bottom"
            *ngIf="contactType == 'seller' && leadData?.numberId"
            >Change</a
          >
        </div>
      </div>
      <div class="re-call-hold" *ngIf="holdCall">
        <div class="re-holdname">
          <img class="mr-1" src="assets/images/icon-hold.svg" alt="hold" />
          {{
            contactData[currentContact - 1]?.contactType == "Buyer"
              ? contactData[currentContact - 1]?.firstName +
                " " +
                contactData[currentContact - 1]?.lastName
              : contactData[currentContact - 1]?.name
          }}
          on hold
        </div>
        <!-- <div class="re-participant">
          <span class="re-txt1">New Participant</span>
          <span class="re-txt2">(456) 789-1234</span>
          <span class="re-txt3">Calling...</span>
        </div> -->
      </div>
      <div class="re-call-slider" *ngIf="isCurrentCall && !holdCall">
        <h3 class="re-count-title">
          Current Callee ({{ currentContact }}/{{ contactData?.length }})
        </h3>
        <div
          id="nameslide"
          class="carousel slide"
          data-ride="false"
          data-interval="false"
          data-wrap="false"
        >
          <div
            class="carousel-inner"
            *ngIf="
              leadData?.isSecondaryContacts &&
              (leadData?.secondaryContact?.length || leadData?.buyers?.length)
            "
          >
            <div
              class="carousel-item"
              [class.active]="i == currentContactDis"
              *ngFor="let contact of contactData; let i = index"
            >
              <div class="re-slidata">
                <h4
                  class="re-name"
                  *ngIf="
                    contact.contactType != 'Buyer' &&
                    contact.contactType != 'Agent'
                  "
                >
                  {{ contact.name }}&nbsp;
                </h4>
                <h4
                  class="re-name"
                  *ngIf="
                    contact.contactType == 'Buyer' ||
                    contact.contactType == 'Agent'
                  "
                >
                  {{ contact?.firstName }} {{ contact?.lastName }}&nbsp;
                </h4>
                <span
                  class="re-role"
                  *ngIf="contact.contactType && !contact.type"
                  >{{ contact.contactType }}</span
                >
                <span
                  class="re-role"
                  *ngIf="contact.contactType && contact.type"
                  >{{ contact.contactType }} • {{ contact.type }}</span
                >
                <span class="re-number"
                  >{{
                    contact?.contactNumber
                      ? _utilities.maskNumber(contact?.contactNumber)
                      : contact?.phoneNumber
                      ? _utilities.maskNumber(contact?.phoneNumber)
                      : ""
                  }}&nbsp;
                  <span
                    *ngIf="
                      contact?.numberType && contact?.numberType !== 'invalid'
                    "
                  >
                    ({{ onNumberType(contact?.numberType) }})
                  </span>
                  <span
                    *ngIf="
                      contact?.numberType == '' ||
                      contact?.numberType == 'invalid' ||
                      contact?.numberType == null
                    "
                  >
                    (N/A)
                  </span>
                </span>

                <span class="re-mail"
                  >{{ contact?.email ? contact?.email : "" }}&nbsp;</span
                >
              </div>
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#nameslide"
            role="button"
            data-slide="prev"
            [pTooltip]="
              currentContact != 1 ? 'Previous contact in the lead' : ''
            "
            tooltipPosition="bottom"
            [class]="
              currentContact == 1
                ? 're-disable-btn'
                : makingCall
                ? 're-disable-btn-call'
                : callEnd
                ? 're-disable-btn-call'
                : sliderBtn
                ? 're-disable-btn-call'
                : ''
            "
          >
            <img
              src="assets/images/arrow-left.svg"
              alt="arrow-left"
              (click)="getCurrentContact('previous')"
            />
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#nameslide"
            role="button"
            data-slide="next"
            [pTooltip]="
              currentContact != contactData?.length
                ? 'Next contact in the lead'
                : ''
            "
            tooltipPosition="bottom"
            [class]="
              currentContact == contactData?.length
                ? 're-disable-btn'
                : makingCall
                ? 're-disable-btn-call'
                : callEnd
                ? 're-disable-btn-call'
                : sliderBtn
                ? 're-disable-btn-call'
                : ''
            "
          >
            <img
              src="assets/images/arrow-right.svg"
              alt="arrow-right"
              (click)="getCurrentContact('next')"
            />
            <span class="sr-only">Next</span>
          </a>
        </div>
        <div class="re-call-ended" *ngIf="redialCall">
          <div class="re-callon-info">
            <h3 class="re-calltime">Call Ended ({{ callEndDuration }})</h3>
            <span class="re-name">{{
              callingData?.contactType == "Buyer"
                ? callingData?.firstName + " " + callingData?.lastName
                : callingData?.name
            }}</span>
            <span class="re-number" *ngIf="callingData">{{
              callingData?.contactType == "Buyer"
                ? _utilities.maskNumber(callingData?.contactNumber)
                : _utilities.maskNumber(callingData?.phoneNumber)
            }}</span>
            <span class="re-endtime">Ended {{ callEndTime }}</span>
            <div
              class="re-call-btn"
              [class.re-call-center]="data?.isHideNext == true"
            >
              <a
                class="{{
                  isPreviousEnable ? 'btn-arrow' : 'btn-arrow re-btn-disable'
                }}"
                role="button"
                (click)="previousLead()"
                *ngIf="data?.isHideNext == false"
                ><i class="fa fa-chevron-left"></i> Prev</a
              >
              <a
                class="btn-call"
                [attr.disable]="callDisable"
                [class.re-call-disable]="callEnd"
                (click)="makeCall()"
                >Redial</a
              >
              <a
                class="{{
                  isNextEnable ? 'btn-arrow' : 'btn-arrow re-btn-disable'
                }}"
                role="button"
                (click)="nextLead()"
                *ngIf="data?.isHideNext == false"
                >Next <i class="fa fa-chevron-right"></i
              ></a>
            </div>
            <!-- <a class="btn-redial" href="javascript:;" (click)="makeCall()"
              >Redial</a
            >
            <a
              class="btn-nextcall"
              href="javascript:;"
              (click)="nextLead()"
              [class.re-disable]="callNextLead"
              *ngIf="data?.isHideNext == false"
              >Call Next Lead</a
            > -->
          </div>
        </div>
        <div
          class="re-call-btn"
          *ngIf="defaultDiaplay"
          [class.re-call-center]="data?.isHideNext == true"
        >
          <a
            class="{{
              isPreviousEnable ? 'btn-arrow' : 'btn-arrow re-btn-disable'
            }}"
            role="button"
            (click)="previousLead()"
            *ngIf="data?.isHideNext == false"
            ><i class="fa fa-chevron-left"></i> Prev</a
          >
          <a
            class="btn-call"
            [attr.disable]="callDisable && mergeCallDialer.callEnded"
            (click)="makeCall()"
            >Call Lead</a
          >
          <a
            class="{{
              isNextEnable ? 'btn-arrow' : 'btn-arrow re-btn-disable'
            }}"
            role="button"
            (click)="nextLead()"
            *ngIf="data?.isHideNext == false"
            >Next <i class="fa fa-chevron-right"></i
          ></a>
        </div>
      </div>
      <!-- <div class="re-call-next" *ngIf="defaultDiaplay">
        <a
          class="btn-call"
          href="javascript:;"
          [attr.disable]="callDisable"
          (click)="makeCall()"
          ><i class="fa fa-phone" aria-hidden="true"></i> Call</a
        >
        <a
          class="{{
            isPreviousEnable ? 'btn-contact' : 'btn-contact re-btn-disable'
          }}"
          role="button"
          (click)="previousLead()"
          *ngIf="data?.isHideNext == false"
        >
          Previous Lead
        </a>
        <a
          class="{{
            isNextEnable ? 'btn-contact' : 'btn-contact re-btn-disable'
          }}"
          role="button"
          (click)="nextLead()"
          *ngIf="data?.isHideNext == false"
        >
          Next Lead
        </a>
        <a class="" href="javascript:;"></a>
      </div> -->
      <div
        class="re-dial-screen"
        *ngIf="makingCall && !addMemberCall && !showMemberList"
      >
        <div class="">
          <h3 class="re-count" *ngIf="callReady">{{ callDuration }}</h3>
          <h3 class="re-count" *ngIf="!callReady">00:00:00</h3>
          <div
            class="re-call-particip"
            style="cursor: pointer"
            *ngIf="mergeCallDialer?.secondCall"
            (click)="getMemberList()"
          >
            {{ totalParticipant }} Participants
          </div>
          <div class="re-callnum">
            Calling from
            <span class="re-numb" *ngIf="contactType == 'seller'">{{
              leadData?.defaultCallNumber
                ? _utilities.maskNumber(leadData?.defaultCallNumber)
                : _utilities.maskNumber(leadData?.number)
            }}</span>
            <span class="re-numb" *ngIf="contactType != 'seller'">{{
              _utilities.maskNumber(buyerCallFrom)
            }}</span>
          </div>
        </div>
        <div class="re-callbtn">
          <div class="re-callfeature">
            <a
              class="re-callicon re-mute"
              href="javascript:;"
              *ngIf="muteCall"
              (click)="unmute()"
              pTooltip="Unmute"
              tooltipPosition="bottom"
              ><img src="assets/images/icon-micoff.svg" alt="micoff"
            /></a>
            <a
              class="re-callicon"
              href="javascript:;"
              *ngIf="!muteCall"
              (click)="mute()"
              pTooltip="Mute"
              tooltipPosition="bottom"
              ><img src="assets/images/icon-micoff.svg" alt="micoff"
            /></a>
            <a
              class="re-callicon"
              href="javascript:;"
              (click)="dialPad()"
              pTooltip="Keypad"
              tooltipPosition="bottom"
              ><img src="assets/images/icon-dialpad.svg" alt="dialpad"
            /></a>
            <!-- <a
              class="re-callicon"
              href="javascript:;"
              *ngIf="!holdCall && mergeCallDialer?.secondCall"
              (click)="hold()"
              pTooltip="Hold with music"
              tooltipPosition="bottom"
              ><img src="assets/images/Call-hold.png" alt="transfer"
            /></a>
            <a
              class="re-callicon re-hold"
              href="javascript:;"
              *ngIf="holdCall && mergeCallDialer?.secondCall"
              (click)="unHold()"
              pTooltip="Resume call"
              tooltipPosition="bottom"
              ><img src="assets/images/Call-hold.png" alt="transfer"
            /></a> -->
          </div>
          <div class="re-callfeature">
            <a
              class="re-callicon"
              href="javascript:;"
              *ngIf="mergeCallDialer?.secondCall"
              pTooltip="Participants call"
              tooltipPosition="bottom"
              (click)="getMemberList()"
              ><img
                src="assets/images/icon-participants.svg"
                alt="participants"
            /></a>
            <a
              class="re-callicon"
              href="javascript:;"
              pTooltip="Add call"
              *ngIf="mergeCallDialer?.firstCall"
              tooltipPosition="bottom"
              (click)="addMemberData()"
              ><img src="assets/images/icon-addcall.svg" alt="addcall"
            /></a>
            <a
              class="re-callicon"
              href="javascript:;"
              pTooltip="Merge Call"
              *ngIf="
                mergeCallDialer?.secondCall &&
                !mergeCallDialer?.callMerged &&
                !showMemberList
              "
              tooltipPosition="bottom"
              (click)="callMerge()"
              ><img src="assets/images/icon-merge.svg" alt="merge"
            /></a>
            <!--            <a-->
            <!--              class="re-callicon"-->
            <!--              href="javascript:;"-->
            <!--              pTooltip="Merge Call"-->
            <!--              *ngIf="-->
            <!--                mergeCallDialer?.secondCall &&-->
            <!--                mergeCallDialer?.callMerged &&-->
            <!--                !showMemberList-->
            <!--              "-->
            <!--              tooltipPosition="bottom"-->
            <!--              ><img src="assets/images/icon-merge.svg" alt="merge"-->
            <!--            /></a>-->
            <a
              class="re-callicon"
              href="javascript:;"
              pTooltip="Merge Call"
              *ngIf="mergeCallDialer?.secondCall && showMemberList"
              tooltipPosition="bottom"
              [class.re-disable]="mergeCallBtn"
              ><img src="assets/images/icon-merge.svg" alt="merge"
            /></a>
            <!--            <a-->
            <!--              class="re-callicon"-->
            <!--              href="javascript:;"-->
            <!--              pTooltip="Switch Participant"-->
            <!--              tooltipPosition="bottom"-->
            <!--              ><img-->
            <!--                src="assets/images/icon-transfer-multimedia-option.svg"-->
            <!--                alt="Switch"-->
            <!--            /></a>-->
            <!-- <a
              class="re-callicon d-none"
              href="javascript:;"
              *ngIf="!holdCall"
              (click)="hold()"
              pTooltip="Hold with music"
              tooltipPosition="bottom"
              ><img src="assets/images/Call-hold.png" alt="transfer"
            /></a>
            <a
              class="re-callicon re-hold d-none"
              href="javascript:;"
              *ngIf="holdCall"
              (click)="unHold()"
              pTooltip="Resume call"
              tooltipPosition="bottom"
              ><img src="assets/images/Call-hold.png" alt="transfer"
            /></a> -->
          </div>
          <div class="re-leavebtn" *ngIf="mergeCallDialer?.secondCall">
            <a
              href="javascript:;"
              class="btn-dropme"
              (click)="dropMembers(mainMember)"
              >Drop Me</a
            >
            <!--            <a href="javascript:;" class="btn-leave" (click)="endCall()"-->
            <!--              >End for All</a-->
            <!--            >-->
          </div>
          <a class="re-callonoff d-none" href="javascript:;"
            ><img src="assets/images/call-on.png" alt="call-on"
          /></a>
          <a
            *ngIf="!mergeCallDialer?.secondCall"
            class="re-callonoff"
            href="javascript:;"
            (click)="cancel()"
            pTooltip="End Call"
            tooltipPosition="bottom"
            ><img src="assets/images/call-off.png" alt="call-off"
          /></a>
        </div>
      </div>
      <div class="re-tabsdiv" *ngIf="addMemberCall && !showMemberList">
        <div class="text-right">
          <a
            class="re-close-call"
            (click)="addMemberCall = false"
            style="display: inline-block; width: 16px; cursor: pointer"
            ><img
              style="max-width: 100%"
              src="assets/images/close-black-1.svg"
              alt="close-black"
          /></a>
        </div>
        <div role="tablist" class="nav re-tabs-btn">
          <a
            href="#tabs11"
            role="tab"
            data-toggle="tab"
            aria-expanded="false"
            class="active"
            >Contact</a
          >
          <a href="#tabs22" role="tab" data-toggle="tab" aria-expanded="false"
            >Keypad</a
          >
        </div>
        <div class="tab-content">
          <div role="tabpanel" id="tabs11" class="tab-pane active">
            <div class="re-contact-tabdiv">
              <div class="input-group re-search-input-group">
                <a href="javascript:;" class="btn btn-default">
                  <span class="fa fa-search"></span>
                </a>
                <input
                  type="text"
                  placeholder="Search contact"
                  class="form-control"
                  (keyup)="searchByFilter($event)"
                />
              </div>
              <div class="re-contactlist">
                <div *ngFor="let user of teams">
                  <div
                    class="re-contact"
                    [ngClass]="{
                      're-selected': dialedNumber === user.contactNumber
                    }"
                  >
                    <div class="re-profile-img" (click)="selectUser(user)">
                      <img src="assets/images/demo-profile.png" alt="profile" />
                    </div>
                    <div (click)="selectUser(user)">
                      <span class="re-con-names">{{
                        user.name
                      }}</span>
                      <span class="re-con-num">{{ user.contactNumber }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <a
                  class="re-callonoff"
                  href="javascript:;"
                  pTooltip="Start Call"
                  tooltipPosition="bottom"
                  (click)="addMemberByContact('contact')"
                  ><img src="assets/images/call-on.png" alt="call-on"
                /></a>
              </div>
            </div>
          </div>
          <div role="tabpanel" id="tabs22" class="tab-pane">
            <div class="re-dialpad my-3">
              <div class="re-dialpad-bottom">
                <div class="re-numbshow">
                  {{ dialedNumber }}
                  <img
                    class="re-errase"
                    src="assets/images/icon-backspace.svg"
                    alt="backspace"
                    (click)="removeDialer()"
                  />
                </div>
                <div class="re-keypad">
                  <div class="re-row">
                    <div class="re-digit" (click)="dialer('1')">1</div>
                    <div class="re-digit" (click)="dialer('2')">2</div>
                    <div class="re-digit" (click)="dialer('3')">3</div>
                  </div>
                  <div class="re-row">
                    <div class="re-digit" (click)="dialer('4')">4</div>
                    <div class="re-digit" (click)="dialer('5')">5</div>
                    <div class="re-digit" (click)="dialer('6')">6</div>
                  </div>
                  <div class="re-row">
                    <div class="re-digit" (click)="dialer('7')">7</div>
                    <div class="re-digit" (click)="dialer('8')">8</div>
                    <div class="re-digit" (click)="dialer('9')">9</div>
                  </div>
                  <div class="re-row">
                    <div class="re-digit" (click)="dialer('*')">*</div>
                    <div class="re-digit" (click)="dialer('0')">0</div>
                    <div class="re-digit" (click)="dialer('#')">#</div>
                  </div>
                </div>
                <div class="text-center">
                  <a
                    class="re-callonoff"
                    href="javascript:;"
                    pTooltip="Start Call"
                    tooltipPosition="bottom"
                    (click)="addMember('dial')"
                    ><img src="assets/images/call-on.png" alt="call-on"
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="re-dialpad" *ngIf="isDialPad && !showMemberList">
        <div class="re-dialpad-top">
          <div class="text-right">
            <a class="re-dialclose" (click)="closeDialPad()"
              ><img src="assets/images/close-black-1.svg" alt="close-black"
            /></a>
          </div>
          <h3 class="re-count" *ngIf="callReady">{{ callDuration }}</h3>
          <h3 class="re-count" *ngIf="!callReady">00:00:00</h3>
          <div class="re-callnum">
            Calling from
            <span class="re-numb">{{
              _utilities.maskNumber(leadData?.number)
            }}</span>
          </div>
        </div>
        <div class="re-dialpad-bottom">
          <div class="re-numbshow">
            {{ dialedNumber }}
            <img
              class="re-errase"
              src="assets/images/icon-backspace.svg"
              alt="backspace"
              (click)="removeDialer()"
            />
          </div>
          <div class="re-keypad">
            <div class="re-row">
              <div class="re-digit" (click)="dialer('1')">1</div>
              <div class="re-digit" (click)="dialer('2')">2</div>
              <div class="re-digit" (click)="dialer('3')">3</div>
            </div>
            <div class="re-row">
              <div class="re-digit" (click)="dialer('4')">4</div>
              <div class="re-digit" (click)="dialer('5')">5</div>
              <div class="re-digit" (click)="dialer('6')">6</div>
            </div>
            <div class="re-row">
              <div class="re-digit" (click)="dialer('7')">7</div>
              <div class="re-digit" (click)="dialer('8')">8</div>
              <div class="re-digit" (click)="dialer('9')">9</div>
            </div>
            <div class="re-row">
              <div class="re-digit" (click)="dialer('*')">*</div>
              <div class="re-digit" (click)="dialer('0')">0</div>
              <div class="re-digit" (click)="dialer('#')">#</div>
            </div>
          </div>
          <!--          <div class="text-center">-->
          <!--            <a-->
          <!--              class="re-callonoff"-->
          <!--              href="javascript:;"-->
          <!--              (click)="cancel()"-->
          <!--              pTooltip="End Call"-->
          <!--              tooltipPosition="bottom"-->
          <!--              ><img src="assets/images/call-off.png" alt="call-off"-->
          <!--            /></a>-->
          <!--          </div>-->
        </div>
      </div>
      <div class="re-callers" *ngIf="showMemberList && !addMemberCall">
        <h4 class="re-title">
          Callers
          <a
            class="re-close-call"
            (click)="showMemberList = false"
            pTooltip="Go back to main screen"
            tooltipPosition="bottom"
            ><img src="assets/images/close-black-1.svg" alt="close-black"
          /></a>
        </h4>
        <div *ngIf="_utilities.isTwilioUser">
          <div
            class="re-boxes"
            *ngFor="let member of memberList; let i = index"
          >
            <div>
              <span class="re-name">{{
                member.label !== "Owner" ? getName(member.label) : "Owner"
              }}</span>
              <span class="re-numb">{{
                member.label === "Owner" ? "Owner" : getNumber(member.label)
              }}</span>
              <div *ngIf="member.label !== 'Owner'">
                <a
                  class="re-cutbtn"
                  href="javascript:;"
                  (click)="dropMembers(member)"
                  >Drop</a
                >
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!_utilities.isTwilioUser">
          <div
            class="re-boxes"
            *ngFor="let member of memberList; let i = index"
          >
            <div *ngIf="member.exitCause === null">
              <span class="re-name">{{
                member.memberAddress.substring(0, 3) === "sip"
                  ? "Owner"
                  : getName(member.memberAddress)
              }}</span>
              <span class="re-numb">{{
                member.memberAddress.substring(0, 3) === "sip"
                  ? "Owner"
                  : getNumber(member.memberAddress)
              }}</span>
              <!--            <span class="re-numb" *ngIf="i === memberList.length - 1">Owner{{-->

              <!--              // _utilities.maskNumber(member.memberAddress)-->

              <!--               */-->

              <!--               */-->
              <!--            }}</span>-->
              <div *ngIf="member.memberAddress.substring(0, 3) !== 'sip'">
                <a
                  class="re-cutbtn"
                  href="javascript:;"
                  (click)="dropMembers(member.memberId)"
                  >Drop</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="re-leavebtn">
          <a
            href="javascript:;"
            class="btn-dropme"
            (click)="dropMembers(mainMember)"
            >Drop Me</a
          >
          <!--          <a href="javascript:;" class="btn-leave" (click)="endCall()"-->
          <!--            >End for All</a-->
          <!--          >-->
        </div>
      </div>
      <div
        [class]="
          makingCall || isDialPad ? 're-tasklist re-taskhide' : 're-tasklist'
        "
      >
        <h3 class="re-title">
          <span
            [pTooltip]="
              makingCall
                ? 'Assigned tasks are not available during the call.'
                : ''
            "
            tooltipPosition="top"
            >My Assigned Tasks ({{ taskCount }})
            <i
              class="fa fa-chevron-up"
              aria-hidden="true"
              *ngIf="makingCall"
            ></i
          ></span>
        </h3>
        <div
          class="checkboxlist"
          infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50"
          [scrollWindow]="false"
          (scrolled)="onScrollTask()"
        >
          <label
            class="re-checkboxbtn"
            *ngFor="let task of tasks; let i = index"
          >
            <input
              type="checkbox"
              name="status"
              [value]="task?._id"
              (click)="isCompletedTask(task)"
            />
            <span class="re-checkmark"></span>
            <span
              class="re-txt"
              [pTooltip]="task?.title"
              tooltipPosition="bottom"
              >{{ task?.title }}</span
            >
            <span class="re-list">
              <img
                alt="Description"
                *ngIf="task.taskDetail"
                [pTooltip]="task.taskDetail"
                tooltipPosition="bottom"
                src="assets/images/description-1.svg"
              />
              <img
                alt="Bell"
                *ngIf="task.reminders.length > 0"
                src="assets/images/bell.svg"
              />
              <img
                alt="Due Date"
                [pTooltip]="
                  _utilities.dueDateFormat(
                    task?.dueInIsoTimeStamp,
                    task?.tasktimezone
                  ).timezoneDate +
                  ' ' +
                  _utilities.dueDateFormat(
                    task?.dueInIsoTimeStamp,
                    task?.tasktimezone
                  ).timezoneTime
                "
                tooltipPosition="bottom"
                [src]="getTaskIcon(task?.dueInIsoTimeStamp)"
              />
              <span
                class="re-custom re-call"
                class="{{
                  customFunction(
                    task?.taskTypeOption,
                    'taskTypeOption',
                    'class'
                  )
                }}"
              >
                {{
                  customFunction(
                    task?.taskTypeOption,
                    "taskTypeOption",
                    "value"
                  )
                }}
              </span>
            </span>
          </label>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
