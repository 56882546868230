import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// UTILS
import { MessageConstant } from 'src/app/utils/message-constant';
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';

// SERVICES
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../../../shared/shared.service';

@Component({
  selector: 'app-add-edit-custom-question',
  templateUrl: './add-edit-custom-question.component.html',
  styleUrls: ['./add-edit-custom-question.component.scss'],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class AddEditCustomQuestionComponent implements OnInit {
  submitted: boolean = false;
  messageConstant = MessageConstant;
  questionForm: FormGroup;
  questionTypes: any[] = [
    { id: 'TEXT', name: 'Single line text' },
    { id: 'MULTILINE_TEXT', name: 'Multi line text' },
    { id: 'NUMBER', name: 'Number input' },
    { id: 'DATE', name: 'Date input' },
    { id: 'CURRENCY', name: 'Currency' },
    { id: 'CHECK_LIST', name: 'Check list' },
    { id: 'RADIO_BUTTON', name: 'Radio list' },
    { id: 'DROPDOWN', name: 'Dropdown' },
  ];
  dateFormats: any[] = [
    { label: 'Dec 31, 2020', value: 'M dd, yy' },
    { label: 'December 31, 2020', value: 'MM dd, yy' },
    { label: '12/31/2020', value: 'mm/dd/yy' },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialog: MatDialogRef<AddEditCustomQuestionComponent>,
    private _sharedService: SharedService,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.questionForm = this.fb.group({
      questionTitle: ['', [Validators.required]],
      view: [1, []],
      questionType: ['', [Validators.required]],
      moduleId: ['', []],
      mainGroupId: ['', []],
      isSelect: [true, []],
      dateFormat: ['', []],
      currencyFormat: ['', []],
      numberFormat: ['', []],
      placeholder: ['', [Validators.required]],
      options: this.fb.array([]),
    });

    const { question, moduleId, group } = this.data;

    if (this.data.isAdd === 'edit') {
      this.updateValidators(question?.questionType);
      this.questionForm.patchValue({
        ...question,
        options: question?.options ? question?.options : [],
        moduleId,
        mainGroupId: group?._id,
      });

      if (question?.options) {
        question?.options.map((x) => {
          this.options.push(
            this.fb.group({
              key: [x.key, [Validators.required]],
              value: [x.key, [Validators.required]],
              defaultSelected: x.defaultSelected,
            })
          );
        });
      }
    } else {
      this.questionForm.patchValue({
        ...question,
        moduleId,
        mainGroupId: group?._id,
      });
    }

    this.questionForm.get('questionType').valueChanges.subscribe((value) => {
      this.options.clear();
      this.updateValidators(value, true);
    });
  }

  updateValidators(value, bool?) {
    if (
      value === 'CHECK_LIST' ||
      value === 'RADIO_BUTTON' ||
      value === 'DROPDOWN'
    ) {
      if (bool) {
        this.addMoreOption();
      }
      this.questionForm.controls['placeholder'].setValue('');
      this.questionForm.controls['placeholder'].clearValidators();
      this.questionForm.controls['placeholder'].updateValueAndValidity();
    } else {
      if (value === 'NUMBER') {
        this.questionForm.controls['numberFormat'].setValue('en-GB');
      } else {
        this.questionForm.controls['numberFormat'].setValue('');
      }

      if (value === 'DATE') {
        this.questionForm.controls['dateFormat'].setValue('M dd, yy');
      } else {
        this.questionForm.controls['dateFormat'].setValue('');
      }

      if (value === 'CURRENCY') {
        this.questionForm.controls['currencyFormat'].setValue('en-GB');
      } else {
        this.questionForm.controls['currencyFormat'].setValue('');
      }

      this.questionForm.controls['placeholder'].setValidators([
        Validators.required,
      ]);

      this.questionForm.controls['numberFormat'].updateValueAndValidity();
      this.questionForm.controls['placeholder'].updateValueAndValidity();
    }
  }

  updateNumberFormat(type) {
    this.questionForm.controls['numberFormat'].setValue(type);
    this.questionForm.controls['numberFormat'].updateValueAndValidity();
  }

  updateCurrencyFormat(type) {
    this.questionForm.controls['currencyFormat'].setValue(type);
    this.questionForm.controls['currencyFormat'].updateValueAndValidity();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.questionForm.controls[controlName].hasError(errorName);
  };

  clearFormFields(fields): void {
    const params = fields.reduce((field, x) => {
      field[x] = '';
      return field;
    }, {});
    this.questionForm.patchValue({
      ...params,
    });
  }

  setValue(key: string, value: string) {
    this.questionForm.patchValue({
      [key]: value,
    });
  }

  isVisible(type) {
    switch (type) {
      case 'number':
        return !!(this.questionForm?.value?.questionType === 'NUMBER');

      case 'date':
        return !!(this.questionForm?.value?.questionType === 'DATE');

      case 'currency':
        return !!(this.questionForm?.value?.questionType === 'CURRENCY');

      case 'data-source':
        return !!(
          this.questionForm?.value?.questionType === 'CHECK_LIST' ||
          this.questionForm?.value?.questionType === 'RADIO_BUTTON' ||
          this.questionForm?.value?.questionType === 'DROPDOWN'
        );

      case 'placeholder':
        return !!(
          this.questionForm?.value?.questionType !== 'CHECK_LIST' &&
          this.questionForm?.value?.questionType !== 'RADIO_BUTTON' &&
          this.questionForm?.value?.questionType !== 'DROPDOWN'
        );
    }
  }

  get options() {
    return this.questionForm.get('options') as FormArray;
  }

  addMoreOption() {
    this.options.push(
      this.fb.group({
        key: ['', [Validators.required]],
        value: ['', []],
        defaultSelected: '',
      })
    );
  }

  selectDefaultOptions(index) {
    this.options.controls.forEach((x, i) => {
      if (i != index) {
        this.options.at(i).patchValue({
          defaultSelected: '',
        });
      }
    });
  }

  deleteOption(index) {
    this.options.removeAt(index);
  }

  onSubmit() {
    this.submitted = true;
    if (this.questionForm.invalid) {
      return;
    }

    let obj = this.questionForm.value;
    Object.keys(obj).forEach(
      (key) => (obj[key] == '' || obj[key] == null) && delete obj[key]
    );

    if (this.data.isAdd === 'edit') {
      obj = {
        ...obj,
        order: this.data?.question?.order,
        view: this.data?.question?.view,
      };
    }

    let checkOption = [];
    if (obj?.options) {
      obj.options.forEach((element) => {
        element.value = element.key;
        checkOption.push(element.value);
      });
    }

    let checkValue = false;
    if (checkOption) {
      checkOption.forEach((option, a) => {
        obj.options.forEach((element, i) => {
          if (a != i) {
            if (element.value == option) {
              checkValue = true;
            }
          }
        });
      });
    }

    if (checkValue) {
      this._toastrService.error(this.messageConstant.checkValuePreference, '');
      return;
    }

    this._loaderService.start();
    obj['isOrder'] = false;
    this._sharedService
      .saveQuestion(obj, this.data.isAdd === 'edit' ? this.data.question : '')
      .subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            if (this.data.isAdd === 'edit') {
              const successEditMessage = this.messageConstant.customSectionUpdateSuccess
                .replace('[[type]]', 'Question')
                .replace('[[status]]', 'Updated');
              this._toastrService.success(successEditMessage, '');
              this.dialog.close({ ...obj, _id: this.data?.question?._id });
            } else {
              const successAddMessage = this.messageConstant.customSectionUpdateSuccess
                .replace('[[type]]', 'Question')
                .replace('[[status]]', 'Added');
              this._toastrService.success(successAddMessage, '');
              this.dialog.close(response.data);
            }
          }
          this._loaderService.stop();
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
  }
}
