import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

//SERVICES
import { ListStackingService } from 'src/app/providers/list-stacking/list-stacking.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { DialerService } from 'src/app/providers/dialer/dialer.service';
import {
  ListResponseModel,
  ResponseModel,
} from 'src/app/utils/models/response';
import { SharedService } from 'src/app/shared/shared.service';

//UTILS
import { MessageConstant } from 'src/app/utils/message-constant';

@Component({
  selector: 'app-manage-email-dialog',
  templateUrl: './manage-email-dialog.component.html',
  styleUrls: ['./manage-email-dialog.component.scss'],
})
export class ManageEmailDialogComponent implements OnInit {
  currentData: any;
  emailMsg: any[] = ['', '', ''];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<ManageEmailDialogComponent>,
    private _listStackingService: ListStackingService,
    private _loaderService: NgxUiLoaderService,
    private _dialerService: DialerService,
    private _sharedService: SharedService,
    private _toastrService: ToastrService
  ) {
    this.getListDetail();
  }

  ngOnInit(): void {}

  getListDetail() {
    if (this.data.details) {
      this.currentData = this.data.details;
      if (this.currentData.Email_1 == 'NA') this.currentData.Email_1 = '';
      if (this.currentData.Email_2 == 'NA') this.currentData.Email_2 = '';
      if (this.currentData.Email_3 == 'NA') this.currentData.Email_3 = '';
      if (this.currentData.Email_4 == 'NA') this.currentData.Email_4 = '';
    }
  }

  validateEmail(emailField) {
    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(emailField) == false) {
      return false;
    }
    return true;
  }

  callValidateEmail(mode) {
    let res;
    mode = parseInt(mode);
    if (this.currentData['Email_' + (mode + 1)].length == 0) {
      res = true;
    } else {
      res = this.validateEmail(this.currentData['Email_' + (mode + 1)]);
    }
    this.emailMsg[parseInt(mode)] = res ? '' : 'Please enter valid Email Id';
  }

  updateEmail() {
    let ValidateFlag = 1;
    let email_msg = 'Invalid value of ';
    let emailFlag = 1;
    let id = this.currentData._id;
    if (this.currentData.Email_1.length > 0) {
      if (!this.validateEmail(this.currentData.Email_1)) {
        emailFlag = 0;
        email_msg += ' Email 1,';
      }
    }
    if (this.currentData.Email_2.length > 0) {
      if (!this.validateEmail(this.currentData.Email_2)) {
        emailFlag = 0;
        email_msg += ' Email 2,';
      }
    }
    if (this.currentData.Email_3.length > 0) {
      if (!this.validateEmail(this.currentData.Email_3)) {
        emailFlag = 0;
        email_msg += ' Email 3,';
      }
    }
    if (this.currentData.Email_4.length > 0) {
      if (!this.validateEmail(this.currentData.Email_4)) {
        emailFlag = 0;
        email_msg += ' Email 4,';
      }
    }
    email_msg = email_msg.slice(0, -1);
    if (emailFlag == 0) {
      ValidateFlag = 0;
      this._toastrService.error(email_msg);
    }
    if (ValidateFlag == 0) {
      return;
    }

    this._loaderService.start();
    this._listStackingService.editProperty(this.currentData, id).subscribe(
      (data: any) => {
        if (data.code == 200) {
          this._loaderService.stop();
          this._toastrService.success(
            MessageConstant.emailAddressUpdateSuccess
          );
          if (this.data.activityAdd) {
            let activityComment = [{ name: 'Property updated Successfully' }];
            let myobj = {
              subModuleId: [id],
              comment: activityComment,
              activityType: 16,
              isPhoneActivity: true,
            };
            this._dialerService.addActivityList(myobj).subscribe(
              (response: ResponseModel) => {
                this._loaderService.stop();
                if (response.statusCode == 200) {
                  this._sharedService.refreshActivityLog.next(true);
                }
              },
              (err) => {
                this._loaderService.stop();
                console.log(err);
              }
            );
          }
          this.dialog.close(true);
        } else {
          this._loaderService.stop();
          this._toastrService.error(data.message);
        }
      },
      (err) => {
        this._loaderService.stop();
      }
    );
  }
}
