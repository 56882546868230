<mat-dialog-content class="">
  <span class="re-close" mat-dialog-close>
    <i class="fa fa-times-circle-o" aria-hidden="true"></i>
  </span>
  <div class="container">
    <div class="re-errortext">
      <img class="img" src="assets/images/warning-24x.png" alt="warning" />
      <p>Are you sure you want to remove lead from "Open" status?</p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
  <button (click)="onSubmit()" mat-raised-button color="warn" cdkFocusInitial>
    Yes
  </button>
</mat-dialog-actions>
