import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// SERVICES
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { BuyersService } from 'src/app/providers/buyers/buyers.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

// UTILS
import { MessageConstant } from 'src/app/utils/message-constant';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';

@Component({
  selector: 'app-add-edit-inquiry',
  templateUrl: './add-edit-inquiry.component.html',
  styleUrls: ['./add-edit-inquiry.component.scss'],
})
export class AddEditInquiryComponent implements OnInit {
  addEditInquiryForm: FormGroup;
  messageConstant = MessageConstant;
  isSubmitted: boolean = false;
  buyerName: string;
  buyer: any;
  filteredBuyers: any[] = [];
  characterCount: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    public _utilities: CommonFunctionsService,
    private _buyerService: BuyersService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _dialog: MatDialogRef<AddEditInquiryComponent>
  ) {}

  ngOnInit() {
    this.addEditInquiryForm = this._fb.group({
      buyerId: ['', [Validators.required]],
      comment: ['', [Validators.required, Validators.maxLength(400)]],
    });

    if (this.data.action === 'EDIT') {
      this.addEditInquiryForm.patchValue({
        buyerId: this.data?.item?.buyerId,
        comment: this.data?.item?.comment,
      });
      this.selectItem(this.data?.item);
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.addEditInquiryForm.controls[controlName].hasError(errorName);
  };

  checkBuyer($event, key) {
    if (!$event?.query) {
      return;
    }

    let obj = {
      [key]: $event?.query,
      patternMatch: true,
    };

    this._buyerService.checkBuyer(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.filteredBuyers = response.data;
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  selectItem(buyer) {
    this.buyer = buyer;

    setTimeout(() => {
      this.buyerName = buyer?.firstName;
      if (buyer?.lastName) {
        this.buyerName += ` ${buyer?.lastName}`;
      }

      this.buyerName = this._utilities.capitalizeName(this.buyerName);
    });

    this.addEditInquiryForm.patchValue({
      buyerId: buyer?.buyerId || buyer._id,
    });

    return this.buyerName;
  }

  onChange($event) {
    if ($event?.target?.value == '') {
      this.buyerName = '';
      this.addEditInquiryForm.patchValue({
        buyerId: '',
      });
    }
  }

  numberType(buyer) {
    if (!buyer?.contactNumber) {
      return;
    }

    const type = this._utilities.numberType(buyer?.numberType?.toLowerCase());
    return `(${type || 'N/A'})`;
  }

  addInquiry() {
    this.isSubmitted = true;
    if (this.addEditInquiryForm.invalid) return;

    let obj = {
      ...this.addEditInquiryForm.value,
      leadId: this.data?.leadId,
      isAdd: true,
    };

    if (this.data.action === 'EDIT') {
      obj = {
        ...obj,
        _id: this.data?.item?._id,
      };
    }
    this._loaderService.start();
    this._buyerService.addInquiry(obj).subscribe(
      (response: ResponseModel) => {
        if (this.data.action === 'EDIT') {
          this._toastrService.success(this.messageConstant.editInquirySuccess);
        } else {
          this._toastrService.success(this.messageConstant.addInquirySuccess);
        }
        this._loaderService.stop();
        if (response.statusCode == 200) {
          this._dialog.close(true);
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  updateTextCount($event) {
    this.characterCount = $event.target.value.length;
  }
  maxError() {
    if (this.characterCount > 400) {
      return true;
    }
  }
}
