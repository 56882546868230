<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      style="display: block !important"
      class="w-100 d-flex justify-content-between"
    >
      {{ data.subModuleId | titlecase }} Call Flow
    </h2>
    <span matDialogClose mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<mat-dialog-content class="mat-typography">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-12 col-sm-12 padd-15">
        <p>Are you sure you want to {{ data.subModuleId }} this Call Flow?</p>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" style="padding-top: 14px !important">
  <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
  <button (click)="submit()" mat-raised-button color="primary" cdkFocusInitial>
    {{ data.subModuleId | titlecase }} Call Flow
  </button>
</mat-dialog-actions>
