<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      style="display: block !important"
      class="w-100 d-flex justify-content-between"
    >
      Converting to Lead
    </h2>
    <span matDialogClose mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<form novalidate>
  <mat-dialog-content>
    <div>
      <div class="re-box">
        <div class="re-infodiv">
          <span class="re-address">{{
            propertyDetails?.Formated_Address || "N/A"
          }}</span>
          <!-- <div class="re-movetext">
            Moving to <span class="re-txt">{{ data.leadName.name }}</span>
          </div> -->
        </div>
      </div>
      <hr />
      <div class="re-box">
        <div class="form-group">
          <label class="re-labels">Select Lead Status</label>
          <select
            class="form-control"
            [(ngModel)]="mainStatusId"
            name="mainStatusId"
            disabled
          >
            <option
              *ngFor="let mainStatus of mainStatusList"
              value="{{ mainStatus.id }}"
            >
              {{ mainStatus.lable }}
            </option>
          </select>
        </div>
      </div>
      <hr />
      <div class="re-box">
        <div class="form-group">
          <label class="re-labels"
            >Select which campaigns would you like to assign this lead to?
            <span class="text-danger">*</span></label
          >
          <select
            class="form-control"
            [(ngModel)]="selectMarketCampaign"
            name="selectMarketCampaign"
          >
            <option value="">Select Campaign</option>
            <optgroup
              label="{{ eachCampaignList?.marketName }}"
              *ngFor="let eachCampaignList of campaignArray; let i = index"
            >
              <option
                value="{{ campaignOption?.campaignId }}"
                *ngFor="
                  let campaignOption of eachCampaignList['campaignOption'];
                  let j = index
                "
              >
                {{ campaignOption?.campaignSource }} /
                {{ campaignOption?.campaignName }}
              </option>
            </optgroup>
          </select>
        </div>
      </div>
      <hr />
      <div class="re-box">
        <div class="re-switchdiv">
          <p class="re-switchlabel">
            Would you like to put contact on a Drip Campaign?
          </p>
          <label class="switch">
            <input
              type="checkbox"
              id="checkbox1"
              name="drip"
              (change)="onChangeCampaign('drip')"
            />
            <span class="slider"></span>
            <span class="text1" value="false">No</span>
            <span class="text2" value="true">Yes</span>
          </label>
        </div>
        <div class="form-group" *ngIf="showDripCampaign">
          <label class="re-labels"
            >Select Drip Campaign <span class="text-danger">*</span></label
          >
          <select
            class="form-control"
            [(ngModel)]="selectDripCampaign"
            name="selectDripCampaign"
          >
            <option value="">--Select--</option>
            <option *ngFor="let drip of dripList" value="{{ drip._id }}">
              {{ drip.name }}
            </option>
          </select>
        </div>
        <div class="form-group" *ngIf="showDripCampaign">
          <label class="re-labels"
            >Would you like to stop the Drip Campaign once you receive a Call or
            SMS from the lead?</label
          >
          <div class="re-radiolist">
            <label class="re-radiobtn">
              <input
                type="radio"
                value="true"
                name="isPaid"
                [(ngModel)]="stopDripCallAndSms"
                checked
              />
              Yes
              <span class="re-checkmark"></span>
            </label>
            <label class="re-radiobtn">
              <input
                type="radio"
                value="false"
                name="isPaid"
                [(ngModel)]="stopDripCallAndSms"
              />
              No
              <span class="re-checkmark"></span>
            </label>
          </div>
        </div>
      </div>
      <hr />
      <div class="re-box">
        <div class="re-switchdiv">
          <p class="re-switchlabel">Would you like to add tags to the lead?</p>
          <label class="switch">
            <input
              type="checkbox"
              id="checkbox2"
              name="tags"
              (change)="onChangeCampaign('tag Lead')"
            />
            <span class="slider"></span>
            <span class="text1" value="false">No</span>
            <span class="text2" value="true">Yes</span>
          </label>
        </div>
        <div class="form-group" *ngIf="selectTagLead">
          <label class="re-labels"
            >Enter tags <span class="text-danger">*</span></label
          >
          <mat-form-field class="re-chip-list" style="width: 100%">
            <mat-chip-list #chipList aria-label="Tag selection">
              <mat-chip
                *ngFor="let tag of tags; let tagIndex = index"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(tagIndex)"
              >
                {{ tag.label }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>

              <input
                #tagInput
                [formControl]="tagCtrl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)"
                (input)="onSearchChange($event.target.value)"
              />
            </mat-chip-list>
            <mat-placeholder
              [ngClass]="
                tags.length > 0
                  ? 'placeholder removePlaceHolder'
                  : 'placeholder'
              "
              >Type...</mat-placeholder
            >
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="selected($event)"
            >
              <ng-container *ngFor="let tag of filteredTags | async">
                <mat-option *ngIf="isVisible(tag)" [value]="tag">
                  {{ tag.label }}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
          <label
            style="font-weight: normal; font-size: 13px"
            class="mt-1"
            for="inputCampaign4"
            *ngIf="isShowPlaceHolder"
            >Press Enter or comma to create a new tag</label
          >
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button
      mat-flat-button
      color="primary"
      cdkFocusInitial
      (click)="saveToLead()"
    >
      Save Lead
    </button>
  </mat-dialog-actions>
</form>
