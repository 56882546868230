import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrl } from '../../utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
@Injectable({
  providedIn: 'root',
})
export class TaskService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem('token');

  constructor(private _commonFunction: CommonFunctionsService) {}

  createTask = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.taskUrl + 'save';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  listTask = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.taskUrl + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateTask = (data, id): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.taskUrl + 'update/' + id;
    return this._commonFunction.globalPutService(endpoint, data);
  };

  deleteTask = (id): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.taskUrl + 'delete/' + id;
    return this._commonFunction.globalDeleteService(endpoint);
  };

  userlistTask = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.taskUrl + 'user/list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  taskCount = (userId): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.taskUrl + 'taskcount/' + userId;
    return this._commonFunction.globalGetService(endpoint);
  };

  getPendingCount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.taskUrl + 'pending/count';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  dropDownListTask = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.taskUrl + 'dropdown/list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  countTasks = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.taskUrl + 'pending/task/local/count';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  /**** Task Template service ****/
  createTaskTemplate = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.taskTemplate + 'save';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  listTaskTemplate = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.taskTemplate + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateTaskTemplate = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.taskTemplate + 'edit';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteTaskTemplate = (id): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.taskTemplate + 'delete';
    return this._commonFunction.globalPostService(endpoint, id);
  };
}
