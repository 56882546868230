<div class="re-choose-mail-temp-modal" *ngIf="data?.emailList">
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between"
        style="display: block !important"
      >
        Choose Email Template
      </h2>
      <span matDialogClose mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>

  <mat-dialog-content class="mat-typography">
    <div class="re-no-padd">
      <div class="re-box">
        <ul class="re-email-temp-list">
          <li
            *ngFor="let email of emailTemplateList; let i = index"
            class="re-padd-20"
          >
            <a
              class="re-alink"
              matDialogClose
              href="javascript:;"
              (click)="onEditEmailTemplate('sendEmail', 'edit', i, email)"
            >
              <p class="text1">{{ email?.templateName }}</p>
              <p class="text2">
                <span class="txt" *ngIf="email?.subject"
                  >{{ email?.subject }} •</span
                >
                <span *ngIf="email?.fromEmail">{{ email?.fromEmail }} •</span>
                <span *ngIf="email?.attachmentArray"
                  >{{ email?.attachmentArray?.length }} attachments</span
                >
              </p>
            </a>
            <div class="re-orText re-padd-20" *ngIf="email?.or">
              {{ email?.or }}
            </div>
          </li>
          <li
            *ngIf="data?.emailList.length == 1"
            class="text-center re-no-data"
          >
            No Template Available
          </li>
        </ul>
      </div>
    </div>
  </mat-dialog-content>
</div>
