<div class="re-modal">
  <div class="dialog-header">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between"
        style="display: block !important"
      >
        {{ this.data.action === "EDIT" ? "Update" : "Add an" }} Interested Buyer
      </h2>
      <span matDialogClose mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>

  <form
    autocomplete="off"
    [formGroup]="addEditInterestedBuyer"
    (ngSubmit)="onSubmit()"
    novalidate
  >
    <mat-dialog-content class="">
      <div class="container-fluid p-0">
        <div class="re-box">
          <div class="row">
            <!-- BUYER -->
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="re-label">
                  Select Buyer
                  <span class="text-danger">*</span>
                </label>

                <p-autoComplete
                  #autoComplete
                  class="re-add-buyer-name"
                  placeholder="Name"
                  name="buyerName"
                  [(ngModel)]="buyerName"
                  [ngModelOptions]="{ standalone: true }"
                  [suggestions]="filteredBuyers"
                  (completeMethod)="checkBuyer($event, 'name')"
                  [minLength]="1"
                  (onSelect)="selectItem($event)"
                  [disabled]="data.action === 'EDIT'"
                  appendTo="body"
                >
                  <ng-template let-buyer pTemplate="item">
                    <div class="ui-helper-clearfix">
                      <div class="re-search-name">
                        {{ _utils.capitalizeName(buyer?.firstName) }}
                        {{ _utils.capitalizeName(buyer?.lastName) }} |
                        {{ buyer?.email }} |
                        {{ _utils.maskNumber(buyer?.contactNumber) }}
                        {{ numberType(buyer) }}
                      </div>
                    </div>
                  </ng-template>
                </p-autoComplete>
              </div>
            </div>

            <!-- OPEN HOUSE -->
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="re-label">
                  Select Open House Attending
                  <span class="text-danger">*</span>
                </label>

                <select
                  class="form-control"
                  formControlName="openHouseAttending"
                  name="openHouseAttending"
                >
                  <option value="">Select Open House Attending</option>
                  <option
                    value="N/A"
                    *ngIf="listingOpenHouse?.length == 0 || !listingOpenHouse"
                  >
                    No Open House Added From Current Date and Time
                  </option>
                  <ng-container *ngFor="let openHouse of listingOpenHouse">
                    <option [value]="openHouse?._id">
                      {{ _utils.dueDateFormat(openHouse?.date).date }}
                      {{ getTime(openHouse) }}
                    </option>
                  </ng-container>
                </select>

                <div class="invalid-feedback">
                  <span
                    *ngIf="
                      isSubmitted && hasError('openHouseAttending', 'required')
                    "
                  >
                    {{
                      messageConstant.requiredField.replace(
                        "Field",
                        "Option House Attending"
                      )
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <hr style="margin: 0 0 8px 0 !important" />

    <mat-dialog-actions align="center">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        cdkFocusInitial
        [disabled]="addEditInterestedBuyer.invalid"
      >
        {{ this.data.action === "EDIT" ? "Update" : "Add" }} Interested Buyer
      </button>
    </mat-dialog-actions>
  </form>
</div>
