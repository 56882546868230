import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrl } from '../../utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { Subject } from 'rxjs';
import { Device } from '@twilio/voice-sdk';
import { ResponseModel } from '../models/response';
import { ErrorModel } from 'src/app/utils/models/error';
// declare var Twilio: any;
@Injectable({
  providedIn: 'root',
})
export class TwilioService {
  baseUrl = environment.baseUrl;
  twilioIncomingEvent: any = new Subject();
  twilioIncomingCancelEvent: any = new Subject();
  twilioOutboundEvent: any = new Subject();
  twilioOutboundHangupEvent: any = new Subject();
  twilioDevice: any;
  twilioCallConnection: any;
  twilioCallIncomingConnection: any;
  audio: HTMLAudioElement;

  constructor(private _utilities: CommonFunctionsService) {}

  async connectTwilio() {
    let twilioToken = await this.getTwilioAccessToken();

    try {
      let deviceOptions: any = {
        debug: true,
        enableRingingState: true,
        fakeLocalDTMF: true,
        codecPreferences: ['opus', 'pcmu'],
        answerCallOnBridge: true,
        logLevel: 1,
        edge: ['dublin', 'singapore'],
        sounds: {
          outgoing: 'https://resimpli.s3.us-west-2.amazonaws.com/slient.mp3',
          disconnect: 'https://resimpli.s3.us-west-2.amazonaws.com/slient.mp3',
        },
      };
      this.twilioDevice = new Device(twilioToken?.toString(), deviceOptions);
      const handleSuccessfulRegistration = () => {
        console.log('The device is ready to receive incoming calls.');
        this._utilities.isTwilioDeviceConnect = true;
      };
      this.twilioDevice.on('registered', handleSuccessfulRegistration);
      this.twilioDevice.register();

      // this.twilioDevice = new Twilio.Device(twilioToken?.toString(), deviceOptions);

      this.twilioDevice.on('ready', (res, err) => {
        console.log('====twilio device connected ready');
        this._utilities.isTwilioDeviceConnect = true;
      });

      this.twilioDevice.on('error', (twilioError, call) => {
        console.log('An twilio error has occurred: ', twilioError);
      });

      this.twilioDevice.on('incoming', (connection) => {
        if (this._utilities.isTwilioOngoingCall) {
          connection.accept();
          this._utilities.currentCallSid = connection.CallSid;
        }
        this.twilioCallIncomingConnection = connection;
        this.twilioIncomingEvent.next(connection);

        connection.on('accept', (connection) => {
          console.log('Twilio call accept.', connection);
        });

        connection.on('disconnect', (connection) => {
          console.log('Twilio call disconnect.', connection);
          this.twilioIncomingCancelEvent.next('disconnect');
          this._utilities.isTwilioOngoingCall = false;
        });

        connection.on('cancel', (connection) => {
          console.log('Twilio call cancel=>', connection);
          this.twilioIncomingCancelEvent.next('cancel');
          this._utilities.isTwilioOngoingCall = false;
        });

        connection.on('reject', (connection) => {
          console.log('Twilio call reject.', connection);
          this.twilioIncomingCancelEvent.next('reject');
          this._utilities.isTwilioOngoingCall = false;
        });
      });

      this.twilioDevice.on('offline', function (device) {
        console.log('Twilio call offline.', this.twilioDevice.status());
        this._utilities.isTwilioDeviceConnect = false;
      });

      this.twilioDevice.on('accept', (connection) => {
        console.log('Twilio call accept.', connection);
      });

      this.twilioDevice.on('cancel', (connection) => {
        console.log('Twilio call cancel.', this.twilioDevice.status());
        this.twilioIncomingCancelEvent.next(connection);
      });

      this.twilioDevice.on('reject', (connection) => {
        console.log('Twilio call reject.', this.twilioDevice.status());
        this._utilities.isTwilioOngoingCall = false;
        this.twilioIncomingCancelEvent.next(connection);
      });

      this.twilioDevice.on('disconnect', (connection) => {
        console.log('Twilio call disconnect.', this.twilioDevice.status());
        this._utilities.isTwilioOngoingCall = false;
        this.twilioIncomingCancelEvent.next(connection);
      });

      this.twilioDevice.on('tokenWillExpire', async () => {
        console.log('Twilio Token Will Expire.');
        twilioToken = await this.getTwilioAccessToken();
        this.twilioDevice.updateToken(twilioToken);
        this._utilities.isTwilioOngoingCall = false;
      });
    } catch (error) {
      console.log('====errorrrr', error);
    }
  }

  async initializeTwilio(payload: any) {
    let {
      from,
      to,
      number,
      userId,
      moduleType,
      eUname,
      buyerId,
      leadId,
      vendorId,
      campaignId,
      propertyId,
    } = payload;

    const extraHeaders = {
      To: to,
      From: from,
      'X-PH-to': to,
      'X-PH-from': from,
      'X-PH-default': this._utilities.unMaskNumber(number),
      'X-PH-userId': userId,
      'X-PH-Id': eUname,
      'X-PH-moduleType': moduleType,
    };

    if (buyerId) {
      extraHeaders['X-PH-buyerId'] = buyerId;
    }

    if (leadId) {
      extraHeaders['X-PH-leadId'] = leadId;
    }

    if (vendorId) {
      extraHeaders['X-PH-vendorId'] = vendorId;
    }

    if (campaignId) {
      extraHeaders['X-PH-campaignId'] = campaignId;
    }

    if (propertyId) {
      extraHeaders['X-PH-propertyId'] = propertyId;
    }

    this.twilioCallConnection = await this.twilioDevice.connect({
      params: extraHeaders,
    });

    // this.twilioCallConnection = await this.twilioDevice.connect(extraHeaders);

    // this.twilioCallConnection.on('ringing', (connection) => {
    //   console.log('Twilio call ringing..', connection);
    // });

    this.twilioCallConnection.on('ringing', (hasEarlyMedia) => {
      console.log('Twilio call ringing..hasEarlyMedia', hasEarlyMedia);
      if (!hasEarlyMedia) {
        this.audio = new Audio(
          'https://resimpli.s3.us-west-2.amazonaws.com/audio/outgoing.mp3'
        );
        this.audio.load();
        this.audio.loop = true;
        this.audio.play();
      }
    });

    this.twilioCallConnection.on('accept', (connection) => {
      console.log('Twilio call accept..');
      this.audio.pause();
      this.audio.currentTime = 0;
      this.twilioOutboundEvent.next(connection);
    });

    this.twilioCallConnection.on('disconnect', (connection) => {
      console.log('Twilio call disconnect..', connection);
      this.callHangup();
      this.audio.pause();
      this.audio.currentTime = 0;
      this.twilioOutboundHangupEvent.next(connection);
    });
  }
  async initializeTwilioListen(payload: any) {
    return new Promise(async (resolve, reject) => {
      this._utilities.isTwilioOngoingCall = true;
      this.listeningConference(payload).subscribe(
        (response: ResponseModel) => {
          if (response.data && response.data.status === 'Failed') {
            resolve(response.data);
          } else {
            resolve(true);
          }
        },
        (err: ErrorModel) => {
          resolve(true);
        }
      );

      this.twilioCallConnection.on('ringing', (hasEarlyMedia) => {
        //this.twilioCallConnection.accept();
      });
      this.twilioDevice.on('incoming', (connection) => {
        //connection.accept();
      });
      this.twilioCallConnection.on('accept', (connection) => {});

      this.twilioCallConnection.on('disconnect', (connection) => {});
    });
  }

  callMuteUnmute(type: boolean) {
    // this.twilioDevice.mute(type);
    this.twilioCallConnection.mute(type);
  }

  callHangup() {
    this.twilioDevice.disconnectAll();
  }

  sendDigits(digits) {
    this.twilioCallConnection.sendDigits(digits);
  }

  deviceDestroy() {
    this.twilioDevice.destroy();
  }

  getTwilioAccessToken() {
    return new Promise(async (resolve, reject) => {
      this.createToken().subscribe(
        (response: any) => {
          let twilioToken = response?.data;
          resolve(twilioToken);
        },
        (error: any) => {
          resolve('');
          console.log('====errorrrr', error);
          // this._loaderService.stop();
        }
      );
    });
  }
  createToken = (): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.linkTwilio + 'twilioVoiceAccessToken';
    return this._utilities.globalPostService(endpoint, {});
  };

  holdCallFn = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkTwilio + 'holdCall';
    try {
      return this._utilities.globalPostService(endpoint, data);
    } catch (error) {
      console.log('----', error);
    }
  };
  holdCallIncomingFn = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkTwilio + 'holdCallIncoming';
    try {
      return this._utilities.globalPostService(endpoint, data);
    } catch (error) {
      console.log('----', error);
    }
  };

  unHoldCallFn = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkTwilio + 'unHoldCall';
    return this._utilities.globalPostService(endpoint, data);
  };

  unHoldMainIncomingCall = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.linkTwilio + 'unHoldMainIncomingCall';
    return this._utilities.globalPostService(endpoint, data);
  };

  addMultiPartyCall = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkTwilio + 'addMemberTwilio';
    return this._utilities.globalPostService(endpoint, data);
  };
  addMultiPartyCallIncoming = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.linkTwilio + 'addMemberIncomingTwilio';
    return this._utilities.globalPostService(endpoint, data);
  };
  createConference = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkTwilio + 'twilioConference';
    return this._utilities.globalPostService(endpoint, data);
  };

  allHoldUnholdMember = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkTwilio + 'holdUnholdCallTwilio';
    return this._utilities.globalPostService(endpoint, data);
  };
  twilioConferenceMergeParticipant = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.linkTwilio + 'twilioConferenceMergeParticipant';
    return this._utilities.globalPostService(endpoint, data);
  };
  twilioConferenceList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkTwilio + 'twilioConferenceList';
    return this._utilities.globalPostService(endpoint, data);
  };
  dropMember = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkTwilio + 'dropMemberTwilio';
    return this._utilities.globalPostService(endpoint, data);
  };
  listeningConference = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkTwilio + `listeningConference`;
    return this._utilities.globalPostService(endpoint, obj);
  };
  listeningConferenceIncoming = (obj): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.linkTwilio + `listeningConferenceIncoming`;
    return this._utilities.globalPostService(endpoint, obj);
  };
  getCallConfInfo = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkTwilio + 'getCallConfInfo';
    return this._utilities.globalPostService(endpoint, data);
  };
}
