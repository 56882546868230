import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrl } from 'src/app/utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

@Injectable({
  providedIn: 'root',
})
export class BuyersService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem('token');

  constructor(private _commonFunction: CommonFunctionsService) {}

  buyerDetails = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyerUrl + 'details';

    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateBuyerCalLogs = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyerUrl + 'updateNewTextStatus';

    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteBuyer = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyerUrl + 'delete';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getBuyerListing = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyerUrl + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getBuyersQuestions = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyerQuestionUrl + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  addBuyer = (data, val?): Observable<any> => {
    let midUrl = val ? BaseUrl.webFormUrl + 'buyer/' : BaseUrl.buyerUrl;
    const endpoint = this.baseUrl + midUrl + 'save';
    return this._commonFunction.globalPostService(
      endpoint,
      data,
      this.getRequestHeaders(val)
    );
  };

  updateBuyerLeadId = (data, val?): Observable<any> => {
    let midUrl = val ? BaseUrl.webFormUrl + 'buyer/' : BaseUrl.buyerUrl;
    const endpoint = this.baseUrl + midUrl + 'update_lead_id';

    return this._commonFunction.globalPostService(
      endpoint,
      data,
      this.getRequestHeaders(val)
    );
  };

  checkBuyer = (data, val?, type?): Observable<any> => {
    let endpoint = this.baseUrl;

    if (type) {
      endpoint += BaseUrl.buyerWebFormUrl + 'checkPhoneNumber';
    } else {
      if (val) {
        endpoint += BaseUrl.webFormUrl + 'buyer/';
      } else {
        endpoint += BaseUrl.buyerUrl;
      }

      endpoint += 'check';
    }

    return this._commonFunction.globalPostService(
      endpoint,
      data,
      this.getRequestHeaders(val)
    );
  };

  getBuyerCitiesAndZips = (data, webFormLink?): Observable<any> => {
    let endpoint = this.baseUrl;

    if (webFormLink) {
      endpoint += BaseUrl.buyerWebFormUrl + 'get';
    } else {
      endpoint += BaseUrl.buyerUrl + 'get';
    }

    return this._commonFunction.globalPostService(
      endpoint,
      data,
      this.getRequestHeaders(webFormLink)
    );
  };

  getCampaigns = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.sendGridV2Url + 'campaign-stats';

    return this._commonFunction.globalPostService(endpoint, data);
  };

  getCampaignsSuggestions = (data, type): Observable<any> => {
    let endpoint = this.baseUrl;

    if (type === 'email') {
      endpoint += BaseUrl.sendGridV2Url + 'campaign-stats';
    } else {
      endpoint += BaseUrl.smsUrl + 'campaignStats';
    }

    return this._commonFunction.globalPostService(endpoint, data);
  };

  getSMSCampaigns = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.smsUrl + 'campaignStats';

    return this._commonFunction.globalPostService(endpoint, data);
  };

  uploadCSV(fileToUpload: File, obj, colorSequence): Observable<any> {
    const token = localStorage.getItem('token');
    const endpoint = this.baseUrl + BaseUrl.buyerUrl + 'bulk-upload';
    const formData: FormData = new FormData();
    formData.append('files', fileToUpload, fileToUpload.name);
    formData.append('mappingString', JSON.stringify(obj));
    formData.append('colorSequence', JSON.stringify(colorSequence));

    return this._commonFunction.globalPostService(endpoint, formData, {
      headers: { Authorization: token },
    });
  }

  getBulkUploadList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.bulkUpload + 'list';

    return this._commonFunction.globalGetService(endpoint);
  };

  checkFileStatus = (): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.bulkUpload + 'file-status';
    return this._commonFunction.globalGetService(endpoint);
  };

  updateFileState = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.bulkUpload + 'file-status-update';
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  getCount = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyerUrl + 'getCount';
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  getAnalytics = (): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyerUrl + 'analytics';
    return this._commonFunction.globalGetService(endpoint);
  };

  exportBuyer = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyerUrl + 'export';

    return this._commonFunction.globalPostService(endpoint, data, {
      responseType: 'text',
    });
  };

  updateBuyers = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyerUrl + 'update-multiple';

    return this._commonFunction.globalPostService(endpoint, obj);
  };

  getAdjacentBuyers = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyerUrl + 'prev-next';

    return this._commonFunction.globalPostService(endpoint, obj);
  };

  getTagList = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyerUrl + 'tag-list';
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  addInterestedBuyer = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + 'interested_buyer';
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  getDispoDetails = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyerUrl + 'dispo_details';
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  addInquiry = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + 'add-inquiry';
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  addInterested = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + 'interested-buyer';
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  getInboxList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.inboxList + 'inbox-list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  markAsReadMsg = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.inboxList + 'mark-as-read-message';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  // SET HEADERS
  protected getRequestHeaders(val?): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    let headers;
    let obj = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };

    if (val) {
      obj['webFormLink'] = val || localStorage.getItem('webFormLink');
    }

    headers = new HttpHeaders(obj);
    return { headers: headers };
  }
  // ADD  OR EDIT DEAL IN DEALS TAB
  addEditDeal = (data, id?): Observable<any> => {
    let lastUrl = 'add-deal';
    if (id != '') {
      lastUrl = `edit-deal/${id}`;
    }
    const endpoint = this.baseUrl + BaseUrl.buyerUrl + lastUrl;
    return this._commonFunction.globalPostService(endpoint, data);
  };
  // DELETE DEAL IN DEALS TAB
  deleteDeal = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyerUrl + 'delete-deal;';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  // ADD  OR EDIT Interested Buyer
  addEditInterestedBuyer = (data, id?): Observable<any> => {
    let lastUrl = 'add-interested-buyer';
    if (id != '') {
      lastUrl = `edit-interested-buyer/${id}`;
    }
    const endpoint = this.baseUrl + BaseUrl.buyerUrl + lastUrl;
    return this._commonFunction.globalPostService(endpoint, data);
  };

  // DELETE Interested Buyer
  deleteInterestedBuyer = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.buyerUrl + 'delete-interested-buyer;';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  // ADD  OR EDIT BUYER IN CONTACT DETAILS TAB ADD BUYER BUTTON
  addEditBuyer = (data, id?): Observable<any> => {
    let lastUrl = 'add-buyer';
    if (id != '') {
      lastUrl = `edit-buyer/${id}`;
    }
    const endpoint = this.baseUrl + BaseUrl.buyerUrl + lastUrl;
    return this._commonFunction.globalPostService(endpoint, data);
  };

  // ADD  OR EDIT OFFER IN OFFER TAB OF BUYER DETAILS
  addEditOffer = (data, id?): Observable<any> => {
    let lastUrl = 'add-offer';
    if (id != '') {
      lastUrl = `edit-offer/${id}`;
    }
    const endpoint = this.baseUrl + BaseUrl.buyerUrl + lastUrl;
    return this._commonFunction.globalPostService(endpoint, data);
  };

  // DELETE BUYER OFFER IN OFFER TAB OF BUYER DETAILS
  deleteOffer = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyerUrl + 'delete-offer';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  // GET OPEN HOUSE LIST
  getOpenHouseDetails = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.buyerUrl + 'dispo_details_for_open_house';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  // GET INQUIRY LIST
  getInquiryList = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.buyerUrl + 'dispo_details_for_inquiry';
    return this._commonFunction.globalPostService(endpoint, data);
  };
}
