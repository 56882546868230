import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { BaseUrl } from '../../utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
@Injectable({
  providedIn: 'root',
})
export class CreditService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem('token');
  private userPlanList: Observable<any>;

  constructor(private _commonFunction: CommonFunctionsService) {}

  getSMSCredit = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.creditUrl + 'getSMSCredit';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getCallCredit = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.creditUrl + 'getCallCredit';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getDocumentCredit = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.creditUrl + 'getDocumentCredit';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getSkipDataCredit = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.creditUrl + 'getSkipDataCredit';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getDirectMailCredit = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.creditUrl + 'getDirectMailCredit';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getRVMCredit = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.creditUrl + 'getRVMCredit';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateCredit = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.creditUrl + 'updateCredit';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  /**
   * Function: GET ALL USER PLAN
   * @param {Object} data
   * @returns ARRAY OF PLAN
   */
  getUserPlan = (data): Observable<any> => {
    // Restrict Multiple Calls For Module List
    const endpoint = this.baseUrl + BaseUrl.creditUrl + 'getUserPlan';
    if (this.userPlanList) {
      return this.userPlanList;
    }
    let list = this._commonFunction.globalPostService(endpoint, data);

    //Executes the http request immediately
    this.userPlanList = list;
    return this.userPlanList;
  };

  getListStackingCredit = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStackingCreditUrl + 'getListStackingCredit';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getListStackingPlan = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStackingCreditUrl + 'getListStackingPlan';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  listStackingSubscribe = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStackingCreditUrl + 'listStackingSubscribe';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateListStackingCredit = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStackingCreditUrl + 'updateListStackingCredit';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getBulkSMSCredits = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.smsUrl + 'checkCreditAndBuyerCount';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  upgradePlan = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.creditUrl + 'upgradePlan';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getUpgradeAmount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.creditUrl + 'getUpgradeAmount';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getPostcardCredit = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.creditUrl + 'getPostcardCredit';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getDrivePlanList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.driveUrl + 'getDrivePlanList';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getAiCallTranscriptionCredit = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.creditUrl + 'getAiCallTranscriptionCredit';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  drivePlanSubscribe = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.driveUrl + 'drivePlanSubscribe';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateDriveUser = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.driveUrl + 'updateDriveUser';
    return this._commonFunction.globalPostService(endpoint, data);
  };
}
