// MODULES
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

// UTILS
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';
import { environment } from 'src/environments/environment';
import { MessageConstant } from 'src/app/utils/message-constant';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CreditService } from 'src/app/providers/credit/credit.service';
import { LeadsService } from 'src/app/providers/leads/leads.service';

// COMPONENTS
import { CallDetailDailogsComponent } from '../call-detail-dailogs/call-detail-dailogs.component';
import { SmsDialogComponent } from '../sms-dialog/sms-dialog.component';
import { SendRvmComponent } from '../../sub-header/send-rvm/send-rvm.component';
import { SharedService } from '../../shared.service';
import { ChooseEmailDailogComponent } from '../../dialog/emails-dailog/choose-email-dailog/choose-email-dailog.component';

@Component({
  selector: 'app-contacts-dialog',
  templateUrl: './contacts-dialog.component.html',
  styleUrls: ['./contacts-dialog.component.scss'],
})
export class ContactsDialogComponent implements OnInit {
  dialogRef: any;
  messageConstant = MessageConstant;
  SMSBalance: number = 0;
  callBalance: number = 0;
  RVMBalance: number = 0;
  currentPage: number = 1;
  teams: any = {};
  constructor(
    private dialog: MatDialog,
    private plivoDialogRef: MatDialogRef<ContactsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public utilities: CommonFunctionsService,
    private _creditService: CreditService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _sharedService: SharedService,
    private leadService: LeadsService
  ) {}

  ngOnInit(): void {
    if (this.data.type === 'SMS') {
      this.getSMSCredit();
    } else if (this.data.type === 'Call') {
      this.getCallCredit();
    } else if (this.data.type === 'RVM') {
      this.getRVMCredit();
    }
  }

  getBuyerNumber(contact, type?) {
    const data = {
      page: this.currentPage,
      limit: 1000,
      numTypeId: type === 'Vendors' ? 4 : 2,
    };
    this._loaderService.start();
    this._sharedService.getnumbers(data).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          if (!response.data?.items?.length) {
            let str = this.messageConstant.noNumber;

            if (type === 'Vendors') {
              str = str
                .replace(/buyers/g, 'vendors')
                .replace(/Buyers/g, 'Vendors');
            }

            this._toastrService.info(str, '', {
              enableHtml: true,
            });
            this._loaderService.stop();
            return;
          }

          this.callDetails(contact, type || 'Buyer', response.data?.items);
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        this._toastrService.error(
          err.message ? err.message : MessageConstant.unknownError
        );
      }
    );
  }

  callDetails(contact, type?, number?) {
    if (!contact?.phoneNumber && !type) {
      this._toastrService.error(this.messageConstant.noBuyerNumber, '');
      return false;
    } else if (type && !contact?.contactNumber) {
      this._toastrService.error(this.messageConstant.noBuyerNumber, '');
      return false;
    }
    if (contact) this.plivoDialogRef.close();

    let dialog, width;
    if (this.data.type === 'Call') {
      dialog = CallDetailDailogsComponent;
      width = '400px';
    } else if (this.data.type === 'RVM') {
      dialog = SendRvmComponent;
      width = '500px';
    } else {
      dialog = SmsDialogComponent;
      width = '500px';
    }

    let obj = {
      moduleId: this.data?.moduleId,
      callBalance: this.callBalance,
      SMSBalance: this.SMSBalance,
      RVMBalance: this.RVMBalance,
      address: this.data?.contacts?.address,
      teams: this.data?.teams,
    };

    if (type) {
      const data =
        type == 'Buyer'
          ? this.data.contacts.buyers
          : this.data.contacts.vendors;
      const contacts = data.map((x) => {
        let name = '';
        if (x?.firstName) {
          name = x?.firstName;
        }
        if (x?.lastName) {
          name = name + ' ' + x?.lastName;
        }
        if (x?.name) {
          name = x?.name;
        }
        let obj = {
          name,
          contactType: x?.contactType || 'Vendors',
          phoneNumber: x?.contactNumber,
        };

        return obj;
      });

      let buyerName = '';
      if (contact?.firstName) {
        buyerName = contact?.firstName;
      }
      if (contact?.lastName) {
        buyerName = buyerName + ' ' + contact?.lastName;
      }
      let buyer = {
        name: buyerName,
        contactType: contact?.contactType,
        phoneNumber: contact?.contactNumber,
        moduleType: type == 'Buyer' ? 'Buyer' : 'Vendor',
        leadSource: number,
        defaultSmsNumber:
          type == 'Buyer' &&
          this.data?.contacts?.buyers &&
          this.data?.contacts?.buyers[0]?.defaultNumberArrayNew
            ? this.data?.contacts?.buyers[0]?.defaultNumberArrayNew[0]?.number
            : type == 'Buyer' && this.data?.contacts?.defaultCallNumber
            ? this.data?.contacts?.defaultCallNumber
            : type != 'Buyer' &&
              this.data?.contacts?.vendors &&
              this.data?.contacts?.vendors[0]?.defaultNumberArrayNew
            ? this.data?.contacts?.vendors[0]?.defaultNumberArrayNew[0]?.number
            : type != 'Buyer' && this.data?.contacts?.defaultCallNumber
            ? this.data?.contacts?.defaultCallNumber
            : '',
        contacts,
        leadId: this.data.subModuleId,
      };

      if (type == 'Buyer') {
        buyer['buyerId'] = contact?._id;
      } else {
        buyer['vendorId'] = contact?._id;
      }

      obj = { ...obj, ...buyer };
    } else {
      obj = {
        ...obj,
        ...contact,
        leadSource: this.data.contacts?.defaultCallNumber
          ? this.data.contacts?.defaultCallNumber
          : this.data.contacts?.number,
        contacts: this.data?.contacts?.secondaryContact,
        leadId: this.data?.subModuleId,
        mainStatusId: this.data?.contacts?.mainStatusId,
      };
    }
    if (this.data?.numberId || this.data?.defaultNumberId) {
      obj['numberId'] = this.data?.defaultNumberId
        ? this.data?.defaultNumberId
        : this.data?.numberId;
    }
    if (this.data.type === 'Call') {
      if (this.utilities.isDialogOpen) {
        if (this.utilities.dialogcurrentLeadId == this.data?.leadId) {
          this._sharedService.refreshDialerToggleDisplay.next(true);
          return false;
        } else {
          this._toastrService.warning(this.messageConstant.callUnderProgress);
          this._loaderService.stop();
          return false;
        }
      }
      this.utilities.isDialogOpen = true;
      this.utilities.dialogcurrentLeadId = this.data?.leadId;
      this.dialogRef = this.dialog.open(dialog, {
        width,
        panelClass: this.data.type === 'RVM' ? 'sendRvm' : '',
        data: obj,
        disableClose: true,
        closeOnNavigation: false,
        backdropClass: 'popup-Backdrop-Class-dialer',
      });
    } else {
      this.dialogRef = this.dialog.open(dialog, {
        width,
        panelClass: this.data.type === 'RVM' ? 'sendRvm' : '',
        data: obj,
        disableClose: true,
      });
    }
    this.dialogRef.afterClosed().subscribe((result) => {
      this.utilities.isDialogOpen = false;
    });
  }

  getSMSCredit() {
    this._loaderService.start();
    this._creditService.getSMSCredit({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.SMSBalance = response?.data;
          if (this.data?.sendContact) {
            this.plivoDialogRef.close();
            if (this.data?.sendContact?.contactType == 'Buyer') {
              this.getBuyerNumber(this.data?.sendContact);
            } else {
              this.callDetails(this.data?.sendContact);
            }
          }
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getCallCredit() {
    this._loaderService.start();
    this._creditService.getCallCredit({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.callBalance = response?.data;
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getRVMCredit() {
    this._loaderService.start();
    this._creditService.getRVMCredit({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.RVMBalance = response?.data;
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  onNumberType(value) {
    const type = this.utilities.numberType(value?.toLowerCase());
    return type;
  }
  onEmailDialog(contact) {
    if (!contact?.email) {
      this._toastrService.error(this.messageConstant.noEmail);
      return;
    }
    let obj = {
      leadId: this.data?.contacts?.leadId
        ? this.data?.contacts?.leadId
        : this.data?.leadId,
    };

    this.leadService.getTeams(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode && response.statusCode == 200) {
          this.teams = response.data.items;
          this.dialogRef = this.dialog.open(ChooseEmailDailogComponent, {
            width: '650px',
            data: {
              type: 'sub-header',
              subType: 'sendEmail',
              category: '',
              email: contact?.email,
              details: this.data.contacts,
              items: this.data.moduleList,
              emailList: this.data.emailList,
              moduleId: this.data.moduleId,
              subModuleId: this.data.subModuleId,
              teams: this.teams,
            },
          });
          this.plivoDialogRef.close();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        this.dialogRef = this.dialog.open(ChooseEmailDailogComponent, {
          width: '650px',
          data: {
            type: 'sub-header',
            subType: 'sendEmail',
            category: '',
            email: contact?.email,
            details: this.data.contacts,
            items: this.data.moduleList,
            emailList: this.data.emailList,
            moduleId: this.data.moduleId,
            subModuleId: this.data.subModuleId,
            teams: this.teams,
          },
        });
      }
    );
  }
}
