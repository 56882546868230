// MODULES
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Subject } from 'rxjs';
//import Plivo from 'plivo-browser-sdk';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { MessageConstant } from 'src/app/utils/message-constant';
import { environment } from 'src/environments/environment';
import { CallFlowService } from '../../../providers/call-flow/call-flow.service';
import { CreditService } from '../../../providers/credit/credit.service';
import { SharedService } from '../../shared.service';
import { BugsnagService } from 'src/app/bugsnag.service';
import { LeadsService } from 'src/app/providers/leads/leads.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TwilioService } from 'src/app/utils/twilio-service/twilio.service';

// UTILS
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { MiscellaneousConstant } from '../../../utils/miscellaneous-constant';

// COMPONENTS
import { ChangeNumberComponent } from '../change-number/change-number.component';
import { LeadCallStatusDialogComponent } from './lead-call-status-dialog/lead-call-status-dialog.component';
import { UpgradeAccountComponent } from '../upgrade-account/upgrade-account.component';
import {UserService} from "../../../providers/user/user.service";

declare var Plivo;

@Component({
  selector: 'app-call-detail-dailogs',
  templateUrl: './call-detail-dailogs.component.html',
  styleUrls: ['./call-detail-dailogs.component.scss'],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class CallDetailDailogsComponent implements OnInit {
  callDetailForm: FormGroup;
  dialogRef;
  plivoBrowserSdk; //: Plivo;
  plivoAccountIndex: number = 0;
  options = {
    debug: 'ALL',
    permOnClick: true,
    enableTracking: true,
    closeProtection: true,
    maxAverageBitrate: 48000,
  };
  callDuration: string = '00:00:00';
  counter: any;
  callReady: boolean = false;
  muteCall: boolean = false;
  isCalling: boolean = false;
  submitted: boolean = false;
  messageConstant = MessageConstant;
  userId: string = '';
  leadSourceCountryCode: string = environment.countryCode;
  callBalance: number = 0;
  isMinimized: boolean = false;
  isMinimizedDis: boolean = false;
  evt: any;
  isDialerOpen: boolean = false;
  dialedNumber: string = '';
  isMinimizedScreen: boolean = true;
  defaultNumberList: any[] = [];
  isCallingEnding: boolean = false;
  isAnsweredCall: boolean = false;
  callUUID: any;

  mergeCallDialer: any = {
    firstCall: false,
    secondCall: false,
    callMerged: false,
    callEnded: true,
    callNotJoined: false,
    checkStatus: false,
    endCause: '',
  };
  callMerged: boolean = false;
  statusCheckFlag: boolean = false;
  addMemberCall = false;
  showMemberList = false;
  mergeCallBtn: boolean = true;
  holdCall: boolean = false;
  multiCallUUID = '';
  multiCallEventName = '';
  mergeCallList = false;
  totalParticipant: any;
  memberList: any[] = [];
  mainMember: any;
  callStatus: string = '';
  teams: any = {};
  allTeams: any = {};
  searchedItem: any;
  callAnswerInterval: any;
  joinToMpcHeader: boolean = false;
  userPlan: any;
  isMarketReadonly: boolean = false;

  isTwilioCall: boolean = false;
  twilioCallSid: any;
  addToMpcHeader: any;
  conferenceId: any;
  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _toastrService: ToastrService,
    private _sharedService: SharedService,
    public _utilities: CommonFunctionsService,
    private _dialog: MatDialog,
    private _bugsnagService: BugsnagService,
    private _leadService: LeadsService,
    private _loaderService: NgxUiLoaderService,
    private _flowService: CallFlowService,
    private creditService: CreditService,
    private _twilioService: TwilioService,
    private _userService: UserService,
  ) {
    this.isTwilioCall = this._utilities.isTwilioUser;
    this.getUser();
    if (data?.callBalance) {
      this.callBalance = data?.callBalance;
    }
    this._sharedService.refreshCallerToggleDisplay = new Subject();
    this._sharedService.refreshCallerToggleDisplay.subscribe((response) => {
      if (response) {
        setTimeout(() => {
          this.minimizeDisplay();
        }, 100);
      }
    });
    this.getUserPlan();
    this.getTeam();
  }

  ngOnInit(): void {
    if (this.isTwilioCall) {
      //twilio call disconnect event
      this._twilioService.twilioOutboundHangupEvent = new Subject();
      this._twilioService.twilioOutboundHangupEvent.subscribe((response) => {
        this.callUUID = response?.parameters?.CallSid;
        this.cancel();
      });

      //twilio call accept event
      this._twilioService.twilioOutboundEvent = new Subject();
      this._twilioService.twilioOutboundEvent.subscribe((response) => {
        if (response) {
          this.twilioOutboundCallEvent(response);
        }
      });
    }

    this.getUserPlan();
    this.getDefaultCampaignList();
    this.callDetailForm = this.formBuilder.group({
      leadSource: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(MiscellaneousConstant.phonePattern),
        ]),
      ],
    });

    if (this.data.leadSource && typeof this.data.leadSource === 'string') {
      this.leadSourceCountryCode = this._utilities.getCountryCode(
        this.data.leadSource
      );

      this.callDetailForm.patchValue({
        leadSource: this._utilities.maskNumber(this.data.leadSource),
      });
    } else if (
      this.data?.defaultCallNumber &&
      typeof this.data?.defaultCallNumber === 'string'
    ) {
      this.leadSourceCountryCode = this._utilities.getCountryCode(
        this.data?.defaultCallNumber
      );
      this.callDetailForm
        .get('leadSource')
        .setValidators([Validators.compose([Validators.required])]);
      this.callDetailForm.patchValue({
        leadSource: this.data?.defaultCallNumber,
      });
    } else {
      this.callDetailForm
        .get('leadSource')
        .setValidators([Validators.compose([Validators.required])]);
    }
  }

  ngAfterViewInit(): void {
    document.onclick = (args: any): void => {
      if (
        args.target.className ==
        'cdk-overlay-backdrop popup-Backdrop-Class-dialer cdk-overlay-backdrop-showing'
      ) {
        if (this.isCalling) {
          this.minimizeDisplay();
        }
        return;
      }
    };
    this.isMarketReadonly = true;
  }

  getUserPlan() {
    this.creditService.getUserPlan({}).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.userPlan = response.data?.planName?.toLowerCase();
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getUser() {
    this._sharedService.getUser().subscribe(
      (response) => {
        if (response.code == 200) {
          this.userId = response.data?.userId;
          this.initializePlivo();
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getName(address) {
    let name = '';
    let name1 =
      this.teams && this.teams.length > 0
        ? this.teams?.find((d) => d.contactNumber === '+' + address || d.contactNumber === address)
        : '';
    if (name1 && name1?.name !== '') {
      return name1?.name;
    } else {
      return 'Unknown Number';
    }
  }

  dropMembers(id) {
    if (this._utilities.isTwilioUser) {
      this._twilioService
        .dropMember({
          conferenceId: id.conferenceSid,
          callSid: id.callSid,
        })
        .subscribe((response: ResponseModel) => {
          //this.memberList = response.data.objects.filter((d) => d.exitCause === null);
          this.showMemberList = false;
          this.getInitialMemberList();
        });
    } else {
      this._flowService
        .dropMember({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
          memberId: id,
        })
        .subscribe((response: ResponseModel) => {
          //this.memberList = response.data.objects.filter((d) => d.exitCause === null);
          this.showMemberList = false;
          this.getInitialMemberList();
        });
    }
  }

  selectUser(user) {
    this.dialedNumber = user.contactNumber;
  }

  // async addMember(data) {
  //   let jointoMpc;
  //   jointoMpc = this.joinToMpcHeader;
  //   if (!this.joinToMpcHeader) {
  //     this.joinToMpcHeader = true;
  //   }
  //   this.statusCheckFlag = false;
  //   this.holdCall = true;
  //   let startTime = new Date().getTime();
  //   const toNumber = environment.countryCode + this.dialedNumber;
  //   const user = JSON.parse(localStorage.getItem('currentUser'));
  //   this.addMemberCall = false;
  //   const params = {
  //     EventName: this.multiCallEventName,
  //     MPCUUID: this.multiCallUUID,
  //     toNumber: toNumber,
  //     token: localStorage.getItem('token'),
  //     memberList: this.memberList || [],
  //     mergeCallDialer: {
  //       firstCall: this.mergeCallDialer?.firstCall
  //         ? this.mergeCallDialer.firstCall
  //         : false,
  //       secondCall: this.mergeCallDialer?.secondCall
  //         ? this.mergeCallDialer.secondCall
  //         : false,
  //       callMerged: this.mergeCallDialer?.callMerged
  //         ? this.mergeCallDialer.callMerged
  //         : false,
  //       callEnded: this.mergeCallDialer?.callEnded
  //         ? this.mergeCallDialer.callEnded
  //         : true,
  //       callNotJoined: this.mergeCallDialer?.callNotJoined
  //         ? this.mergeCallDialer.callNotJoined
  //         : false,
  //     },
  //     uname: user.eUname,
  //     callUUID: this.callUUID,
  //     jointoMpc: jointoMpc,
  //   };
  //   const name = this.multiCallEventName.replace('+', 'Resimpli');
  //   const memberInterval = setInterval(() => {
  //     if (this.statusCheckFlag === false) {
  //       this._flowService.getCallInfo({ name }).subscribe(
  //         (response: ResponseModel) => {
  //           if (response?.data) {
  //             this.mergeCallDialer = response?.data?.users;
  //             this.mergeCallDialer.callNotJoined = true;
  //             this.statusCheckFlag = response?.data?.isCheckStatus;
  //           }
  //           if (this.statusCheckFlag === true) {
  //             this.getInitialMemberList();
  //             if (memberInterval) {
  //               clearInterval(memberInterval);
  //             }
  //           }
  //         },
  //         (err: ErrorModel) => {
  //           this._loaderService.stop();
  //         }
  //       );
  //       if (new Date().getTime() - startTime > 30000) {
  //         this.statusCheckFlag = true;
  //         clearInterval(memberInterval);
  //         this.allHoldUnholdMember({
  //           hold: false,
  //           mute: false,
  //         });
  //       }
  //     }
  //   }, 1000);
  //   if (this.dialedNumber !== '') {
  //     if (this.mergeCallDialer.secondCall === false) {
  //       if (this.plivoBrowserSdk.client.callUUID && this.muteCall) {
  //         this.muteCall = false;
  //         this.plivoBrowserSdk.client.unmute();
  //       }
  //       if (this.plivoBrowserSdk.client.callUUID && this.holdCall) {
  //         let obj = { uuid: this.plivoBrowserSdk.client.callUUID };
  //         this._sharedService.unHoldCallFn(obj).subscribe(
  //           (response) => {
  //             if (response.statusCode == 200) {
  //               //this.holdCall = false;
  //               this._flowService
  //                 .flowConferenceAddParticipant(params)
  //                 .subscribe(
  //                   (response: ResponseModel) => {},
  //                   (err: ErrorModel) => {
  //                     this._loaderService.stop();
  //                   }
  //                 );
  //             }
  //           },
  //           (err: ErrorModel) => {
  //             if (err.error) {
  //               const error: ResponseModel = err.error;
  //               this._toastrService.error(error.message, '');
  //             } else {
  //               this._toastrService.error(
  //                 this.messageConstant.unknownError,
  //                 ''
  //               );
  //             }
  //           }
  //         );
  //       } else {
  //         await this._flowService
  //           .flowConferenceAddParticipant(params)
  //           .subscribe(
  //             (response: ResponseModel) => {},
  //             (err: ErrorModel) => {
  //               this._loaderService.stop();
  //             }
  //           );
  //       }
  //     } else {
  //       await this._flowService.flowConferenceAddParticipant(params).subscribe(
  //         (response: ResponseModel) => {},
  //         (err: ErrorModel) => {
  //           this._loaderService.stop();
  //         }
  //       );
  //     }
  //   }
  // }
  getInitialMemberList() {
    if (this._utilities.isTwilioUser) {
      this._twilioService
        .twilioConferenceList({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
        })
        .subscribe((response: ResponseModel) => {
          this.memberList = response.data;
          this.mainMember = this.memberList.filter(
            (m) => m.label === 'Owner'
          )[0];
          this.totalParticipant = this.memberList.length;
          if (this.memberList && this.memberList.length > 0) {
            this.conferenceId = this.memberList[0].conferenceSid;
          }
        });
    } else {
      this._flowService
        .plivoConferenceList({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
        })
        .subscribe((response: ResponseModel) => {
          this.memberList = response.data.objects.filter(
            (d) => d.exitCause === null
          );
          this.memberList = this.removeDuplicatesValue(
            this.memberList,
            'memberAddress'
          );
          this.totalParticipant = this.memberList.length;
        });
    }
  }

  addMemberData() {
    if (
      this.userPlan === 'enterprise' ||
      this.userPlan === 'pro' ||
      this.userPlan === 'premium' ||
      this.userPlan === 'enterprise existing'
    ) {
      this.addMemberCall = true;
      this.teams = this.allTeams;
      this.dialedNumber = '';
    } else {
      this.dialog.open(UpgradeAccountComponent, {
        disableClose: true,
        width: '350px',
        data: {
          link: '',
        },
      });
    }
  }

  // async addMemberByContact(data) {
  //   this.statusCheckFlag = false;
  //   this.addMemberCall = false;
  //   this.holdCall = true;
  //   let jointoMpc;
  //   jointoMpc = this.joinToMpcHeader;
  //   if (!this.joinToMpcHeader) {
  //     this.joinToMpcHeader = true;
  //   }
  //   let startTime = new Date().getTime();
  //   const user = JSON.parse(localStorage.getItem('currentUser'));
  //   const params = {
  //     EventName: this.multiCallEventName,
  //     MPCUUID: this.multiCallUUID,
  //     toNumber: this.dialedNumber,
  //     token: localStorage.getItem('token'),
  //     memberList: this.memberList || [],
  //     mergeCallDialer: {
  //       firstCall: this.mergeCallDialer?.firstCall
  //         ? this.mergeCallDialer.firstCall
  //         : false,
  //       secondCall: this.mergeCallDialer?.secondCall
  //         ? this.mergeCallDialer.secondCall
  //         : false,
  //       callMerged: this.mergeCallDialer?.callMerged
  //         ? this.mergeCallDialer.callMerged
  //         : false,
  //       callEnded: this.mergeCallDialer?.callEnded
  //         ? this.mergeCallDialer.callEnded
  //         : true,
  //       callNotJoined: this.mergeCallDialer?.callNotJoined
  //         ? this.mergeCallDialer.callNotJoined
  //         : false,
  //     },
  //     uname: user.eUname,
  //     callUUID: this.callUUID,
  //     jointoMpc: jointoMpc,
  //   };
  //
  //   this.statusCheckFlag = false;
  //   const name = this.multiCallEventName.replace('+', 'Resimpli');
  //
  //   const memberInter = setInterval(() => {
  //     if (this.statusCheckFlag === false) {
  //       this._flowService.getCallInfo({ name }).subscribe(
  //         (response: ResponseModel) => {
  //           if (response?.data) {
  //             this.mergeCallDialer = response?.data?.users;
  //             this.mergeCallDialer.callNotJoined = true;
  //             this.statusCheckFlag = response?.data?.isCheckStatus;
  //           }
  //           if (this.statusCheckFlag === true) {
  //             this.getInitialMemberList();
  //             if (memberInter) {
  //               clearInterval(memberInter);
  //             }
  //           }
  //         },
  //         (err: ErrorModel) => {
  //           this._loaderService.stop();
  //         }
  //       );
  //       if (new Date().getTime() - startTime > 30000) {
  //         this.statusCheckFlag = true;
  //         clearInterval(memberInter);
  //         this.allHoldUnholdMember({
  //           hold: false,
  //           mute: false,
  //         });
  //       }
  //     }
  //   }, 1000);
  //   if (this.dialedNumber !== '') {
  //     if (this.mergeCallDialer.secondCall === false) {
  //       if (this.plivoBrowserSdk.client.callUUID && this.muteCall) {
  //         this.muteCall = false;
  //         this.plivoBrowserSdk.client.unmute();
  //       }
  //       if (this.plivoBrowserSdk.client.callUUID && this.holdCall) {
  //         let obj = { uuid: this.plivoBrowserSdk.client.callUUID };
  //         this._sharedService.unHoldCallFn(obj).subscribe(
  //           (response) => {
  //             if (response.statusCode == 200) {
  //               //this.holdCall = false;
  //               this._flowService
  //                 .flowConferenceAddParticipant(params)
  //                 .subscribe(
  //                   (response: ResponseModel) => {},
  //                   (err: ErrorModel) => {
  //                     this._loaderService.stop();
  //                   }
  //                 );
  //             }
  //           },
  //           (err: ErrorModel) => {
  //             if (err.error) {
  //               const error: ResponseModel = err.error;
  //               this._toastrService.error(error.message, '');
  //             } else {
  //               this._toastrService.error(
  //                 this.messageConstant.unknownError,
  //                 ''
  //               );
  //             }
  //           }
  //         );
  //       } else {
  //         await this._flowService
  //           .flowConferenceAddParticipant(params)
  //           .subscribe(
  //             (response: ResponseModel) => {},
  //             (err: ErrorModel) => {
  //               this._loaderService.stop();
  //             }
  //           );
  //       }
  //     } else {
  //       await this._flowService.flowConferenceAddParticipant(params).subscribe(
  //         (response: ResponseModel) => {},
  //         (err: ErrorModel) => {
  //           this._loaderService.stop();
  //         }
  //       );
  //     }
  //   }
  // }
  async addMember(data) {
    let jointoMpc;
    jointoMpc = this.joinToMpcHeader;
    if (!this.joinToMpcHeader) {
      this.joinToMpcHeader = true;
    }
    const toNumber = environment.countryCode + this.dialedNumber;
    //const toNumber = '+12195010032';
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (this._utilities.isTwilioUser) {
      this.statusCheckFlag = false;
      this.holdCall = true;
      let startTime = new Date().getTime();

      this.addMemberCall = false;
      const params = {
        EventName: this.multiCallEventName,
        MPCUUID: this.multiCallUUID,
        toNumber: toNumber,
        token: localStorage.getItem('token'),
        memberList: this.memberList || [],
        mergeCallDialer: {
          firstCall: this.mergeCallDialer?.firstCall
            ? this.mergeCallDialer.firstCall
            : false,
          secondCall: this.mergeCallDialer?.secondCall
            ? this.mergeCallDialer.secondCall
            : false,
          callMerged: this.mergeCallDialer?.callMerged
            ? this.mergeCallDialer.callMerged
            : false,
          callEnded: this.mergeCallDialer?.callEnded
            ? this.mergeCallDialer.callEnded
            : true,
          callNotJoined: this.mergeCallDialer?.callNotJoined
            ? this.mergeCallDialer.callNotJoined
            : false,
        },
        uname: user.eUname,
        addToMpcHeader: this.addToMpcHeader,
        jointoMpc: jointoMpc,
        callUUID: this.callUUID,
      };
      const name = this.callUUID;
      const memberInterval = setInterval(() => {
        if (this.statusCheckFlag === false) {
          this._flowService.getCallInfo({ name }).subscribe(
            (response: ResponseModel) => {
              this.mergeCallDialer = response.data.users;
              this.mergeCallDialer.callNotJoined = true;
              this.statusCheckFlag = response.data.isCheckStatus;
              if (this.statusCheckFlag === true) {
                this.getInitialMemberList();
                if (memberInterval) {
                  clearInterval(memberInterval);
                }
              }
            },
            (err: ErrorModel) => {
              this._loaderService.stop();
            }
          );
          if (new Date().getTime() - startTime > 30000) {
            this.statusCheckFlag = true;
            clearInterval(memberInterval);
            if (this.mergeCallDialer.secondCall === false) {
              setTimeout(() => {
                this.callMerge();
              }, 2000);
            } else {
              this.allHoldUnholdMember({
                hold: false,
                mute: false,
              });
            }
          }
        }
      }, 1000);
      try {
        await this._twilioService.addMultiPartyCall(params).subscribe(
          (response: ResponseModel) => {},
          (err: ErrorModel) => {
            this._loaderService.stop();
          }
        );
      } catch (e) {
        console.log('e', e);
      }
    } else {
      try {
        this.statusCheckFlag = false;
        this.holdCall = true;
        let startTime = new Date().getTime();

        this.addMemberCall = false;
        const params = {
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
          toNumber: toNumber,
          token: localStorage.getItem('token'),
          memberList: this.memberList || [],
          mergeCallDialer: {
            firstCall: this.mergeCallDialer?.firstCall
              ? this.mergeCallDialer.firstCall
              : false,
            secondCall: this.mergeCallDialer?.secondCall
              ? this.mergeCallDialer.secondCall
              : false,
            callMerged: this.mergeCallDialer?.callMerged
              ? this.mergeCallDialer.callMerged
              : false,
            callEnded: this.mergeCallDialer?.callEnded
              ? this.mergeCallDialer.callEnded
              : true,
            callNotJoined: this.mergeCallDialer?.callNotJoined
              ? this.mergeCallDialer.callNotJoined
              : false,
          },
          uname: user.eUname,
          addToMpcHeader: this.addToMpcHeader,
          jointoMpc: jointoMpc,
          callUUID: this.callUUID,
        };
        const name = this.multiCallEventName.replace('+', 'Resimpli');
        const memberInterval = setInterval(() => {
          if (this.statusCheckFlag === false) {
            this._flowService.getCallInfo({ name }).subscribe(
              (response: ResponseModel) => {
                this.mergeCallDialer = response.data.users;
                this.mergeCallDialer.callNotJoined = true;
                this.statusCheckFlag = response.data.isCheckStatus;
                if (this.statusCheckFlag === true) {
                  this.getInitialMemberList();
                  if (memberInterval) {
                    clearInterval(memberInterval);
                  }
                }
              },
              (err: ErrorModel) => {
                this._loaderService.stop();
              }
            );
            if (new Date().getTime() - startTime > 30000) {
              this.statusCheckFlag = true;
              clearInterval(memberInterval);
              this.allHoldUnholdMember({
                hold: false,
                mute: false,
              });
            }
          }
        }, 1000);
        if (this.mergeCallDialer.secondCall === false) {
          if (this.plivoBrowserSdk.client.callUUID && this.muteCall) {
            this.muteCall = false;
            this.plivoBrowserSdk.client.unmute();
          }
          if (this.plivoBrowserSdk.client.callUUID && this.holdCall) {
            let obj = { uuid: this.plivoBrowserSdk.client.callUUID };
            this._sharedService.unHoldCallFn(obj).subscribe(
              (response) => {
                if (response.statusCode == 200) {
                  //this.holdCall = false;
                  this._flowService
                    .flowConferenceAddParticipant(params)
                    .subscribe(
                      (response: ResponseModel) => {},
                      (err: ErrorModel) => {
                        this._loaderService.stop();
                      }
                    );
                }
              },
              (err: ErrorModel) => {
                if (err.error) {
                  const error: ResponseModel = err.error;
                  this._toastrService.error(error.message, '');
                } else {
                  this._toastrService.error(
                    this.messageConstant.unknownError,
                    ''
                  );
                }
              }
            );
          } else {
            await this._flowService
              .flowConferenceAddParticipant(params)
              .subscribe(
                (response: ResponseModel) => {},
                (err: ErrorModel) => {
                  this._loaderService.stop();
                }
              );
          }
        } else {
          await this._flowService
            .flowConferenceAddParticipant(params)
            .subscribe(
              (response: ResponseModel) => {},
              (err: ErrorModel) => {
                this._loaderService.stop();
              }
            );
        }
      } catch (e) {
        console.log('e', e);
      }
    }
  }

  async addMemberByContact(data) {
    let jointoMpc;
    jointoMpc = this.joinToMpcHeader;
    if (!this.joinToMpcHeader) {
      this.joinToMpcHeader = true;
    }

    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (this._utilities.isTwilioUser) {
      this.statusCheckFlag = false;
      this.holdCall = true;
      let startTime = new Date().getTime();

      this.addMemberCall = false;
      const params = {
        EventName: this.multiCallEventName,
        MPCUUID: this.multiCallUUID,
        toNumber: this.dialedNumber,
        token: localStorage.getItem('token'),
        memberList: this.memberList || [],
        mergeCallDialer: {
          firstCall: this.mergeCallDialer?.firstCall
            ? this.mergeCallDialer.firstCall
            : false,
          secondCall: this.mergeCallDialer?.secondCall
            ? this.mergeCallDialer.secondCall
            : false,
          callMerged: this.mergeCallDialer?.callMerged
            ? this.mergeCallDialer.callMerged
            : false,
          callEnded: this.mergeCallDialer?.callEnded
            ? this.mergeCallDialer.callEnded
            : true,
          callNotJoined: this.mergeCallDialer?.callNotJoined
            ? this.mergeCallDialer.callNotJoined
            : false,
        },
        uname: user.eUname,
        addToMpcHeader: this.addToMpcHeader,
        jointoMpc: jointoMpc,
        callUUID: this.callUUID,
      };
      const name = this.callUUID;
      const memberInterval = setInterval(() => {
        if (this.statusCheckFlag === false) {
          this._flowService.getCallInfo({ name }).subscribe(
            (response: ResponseModel) => {
              this.mergeCallDialer = response.data.users;
              this.mergeCallDialer.callNotJoined = true;
              this.statusCheckFlag = response.data.isCheckStatus;
              if (this.statusCheckFlag === true) {
                this.getInitialMemberList();
                if (memberInterval) {
                  clearInterval(memberInterval);
                }
              }
            },
            (err: ErrorModel) => {
              this._loaderService.stop();
            }
          );
          if (new Date().getTime() - startTime > 30000) {
            this.statusCheckFlag = true;
            clearInterval(memberInterval);
            if (this.mergeCallDialer.secondCall === false) {
              this.statusCheckFlag = true;
              setTimeout(() => {
                this.callMerge();
              }, 2000);
            } else {
              this.allHoldUnholdMember({
                hold: false,
                mute: false,
              });
            }
          }
        }
      }, 1000);
      try {
        await this._twilioService.addMultiPartyCall(params).subscribe(
          (response: ResponseModel) => {},
          (err: ErrorModel) => {
            this._loaderService.stop();
          }
        );
      } catch (e) {
        console.log('e', e);
      }
    } else {
      try {
        this.statusCheckFlag = false;
        this.addMemberCall = false;
        this.holdCall = true;
        let startTime = new Date().getTime();
        const params = {
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
          toNumber: this.dialedNumber,
          token: localStorage.getItem('token'),
          memberList: this.memberList || [],
          mergeCallDialer: {
            firstCall: this.mergeCallDialer?.firstCall
              ? this.mergeCallDialer.firstCall
              : false,
            secondCall: this.mergeCallDialer?.secondCall
              ? this.mergeCallDialer.secondCall
              : false,
            callMerged: this.mergeCallDialer?.callMerged
              ? this.mergeCallDialer.callMerged
              : false,
            callEnded: this.mergeCallDialer?.callEnded
              ? this.mergeCallDialer.callEnded
              : true,
            callNotJoined: this.mergeCallDialer?.callNotJoined
              ? this.mergeCallDialer.callNotJoined
              : false,
          },
          uname: user.eUname,
          addToMpcHeader: this.addToMpcHeader,
          callUUID: this.callUUID,
          jointoMpc: jointoMpc,
        };

        this.statusCheckFlag = false;
        const name = this.multiCallEventName.replace('+', 'Resimpli');

        const memberInter = setInterval(() => {
          if (this.statusCheckFlag === false) {
            this._flowService.getCallInfo({ name }).subscribe(
              (response: ResponseModel) => {
                this.mergeCallDialer = response.data.users;
                this.mergeCallDialer.callNotJoined = true;
                this.statusCheckFlag = response.data.isCheckStatus;
                if (this.statusCheckFlag === true) {
                  this.getInitialMemberList();
                  if (memberInter) {
                    clearInterval(memberInter);
                  }
                }
              },
              (err: ErrorModel) => {
                this._loaderService.stop();
              }
            );
            if (new Date().getTime() - startTime > 30000) {
              this.statusCheckFlag = true;
              clearInterval(memberInter);
              this.allHoldUnholdMember({
                hold: false,
                mute: false,
              });
            }
          }
        }, 1000);
        if (this.mergeCallDialer.secondCall === false) {
          if (this.plivoBrowserSdk.client.callUUID && this.muteCall) {
            this.muteCall = false;
            this.plivoBrowserSdk.client.unmute();
          }
          if (this.plivoBrowserSdk.client.callUUID && this.holdCall) {
            let obj = { uuid: this.plivoBrowserSdk.client.callUUID };
            this._sharedService.unHoldCallFn(obj).subscribe(
              (response) => {
                if (response.statusCode == 200) {
                  //this.holdCall = false;
                  this._flowService
                    .flowConferenceAddParticipant(params)
                    .subscribe(
                      (response: ResponseModel) => {},
                      (err: ErrorModel) => {
                        this._loaderService.stop();
                      }
                    );
                }
              },
              (err: ErrorModel) => {
                if (err.error) {
                  const error: ResponseModel = err.error;
                  this._toastrService.error(error.message, '');
                } else {
                  this._toastrService.error(
                    this.messageConstant.unknownError,
                    ''
                  );
                }
              }
            );
          } else {
            await this._flowService
              .flowConferenceAddParticipant(params)
              .subscribe(
                (response: ResponseModel) => {},
                (err: ErrorModel) => {
                  this._loaderService.stop();
                }
              );
          }
        } else {
          await this._flowService
            .flowConferenceAddParticipant(params)
            .subscribe(
              (response: ResponseModel) => {},
              (err: ErrorModel) => {
                this._loaderService.stop();
              }
            );
        }
      } catch (e) {
        console.log('e', e);
      }
    }
  }
  getTeam() {
    let objTeams = {
      leadId: this.data.leadId,
    };
    let param = {
      page: 1,
      limit: 100,
    };
    this._userService.getSubUserList(param).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode && response.statusCode == 200) {
          this.teams = response.data.items;
          this.allTeams = response.data.items;
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
      }
    );
  }

  removeDialer() {
    if (this.dialedNumber) {
      this.dialedNumber = this.dialedNumber.slice(0, -1);
    }
  }

  callMerge() {
    this.statusCheckFlag = true;
    this.holdCall = false;
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (this._utilities.isTwilioUser) {
      this._twilioService
        .twilioConferenceMergeParticipant({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
          memberList: this.memberList || [],
          uname: user.eUname,
          callUuid: this.callUUID,
        })
        .subscribe((response: ResponseModel) => {
          this.mergeCallDialer.callMerged = true;
          this.statusCheckFlag = true;
          //this.getInitialMemberList();
          this.getMemberList();
          this.allHoldUnholdMember({
            hold: false,
            mute: false,
          });
        });
    } else {
      this._flowService
        .flowConferenceMergeParticipant({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
          memberList: this.memberList || [],
          uname: user.eUname,
        })
        .subscribe((response: ResponseModel) => {
          this.mergeCallDialer.callMerged = true;
          this.statusCheckFlag = true;
        });
    }
  }

  getNumber(address) {
    if (
      address.startsWith('Listening:') ||
      address.startsWith('Agent:') ||
      address.startsWith('New Participant:')
    ) {
      return 'Team Member';
    } else {
      return this._utilities.maskNumber(address);
    }
  }

  removeDuplicatesValue(originalArray, objKey) {
    let trimmedArray = [];
    let values = [];
    let value;

    for (let i = 0; i < originalArray.length; i++) {
      value = originalArray[i][objKey];

      if (values.indexOf(value) === -1) {
        trimmedArray.push(originalArray[i]);
        values.push(value);
      }
    }

    return trimmedArray;
  }

  getMemberList() {
    if (this._utilities.isTwilioUser) {
      this._twilioService
        .twilioConferenceList({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
        })
        .subscribe((response: ResponseModel) => {
          this.memberList = response.data;
          this.mainMember = this.memberList.filter(
            (m) => m.label === 'Owner'
          )[0];
          this.showMemberList = true;
          this.totalParticipant = this.memberList.length;
        });
    } else {
      this._flowService
        .plivoConferenceList({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
        })
        .subscribe((response: ResponseModel) => {
          this.memberList = response.data.objects.filter(
            (d) => d.exitCause === null
          );
          this.memberList = this.removeDuplicatesValue(
            this.memberList,
            'memberAddress'
          );
          this.mainMember = this.memberList[this.memberList.length - 1];
          this.showMemberList = true;
          this.totalParticipant = this.memberList.length;
        });
    }
  }

  hold() {
    if (this.isTwilioCall && this.twilioCallSid) {
      this._twilioService
        ?.holdCallFn({ callSid: this.twilioCallSid })
        .subscribe(
          (response) => {
            this.holdCall = true;
          },
          (err: ErrorModel) => {
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
      this.holdCall = true;
    } else {
      if (
        this.plivoBrowserSdk.client.callUUID &&
        !this.mergeCallDialer.callNotJoined
      ) {
        let obj = { uuid: this.plivoBrowserSdk.client.callUUID };
        this._sharedService.holdCallFn(obj).subscribe(
          (response) => {
            if (response.statusCode == 200) {
              this.holdCall = true;
            }
          },
          (err: ErrorModel) => {
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
      } else {
        this.allHoldUnholdMember({
          hold: true,
          mute: this.muteCall,
        });
      }
    }
  }

  unHold(disConnect?) {
    if (this.isTwilioCall && this.twilioCallSid) {
      this._twilioService
        ?.unHoldCallFn({ callSid: this.twilioCallSid })
        .subscribe(
          (response) => {
            this.holdCall = false;
            if (disConnect) {
              setTimeout(() => {
                this.cancel();
              }, 2000);
            }
          },
          (err: ErrorModel) => {
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
      this.holdCall = false;
    } else {
      if (
        this.plivoBrowserSdk.client.callUUID &&
        !this.mergeCallDialer.callNotJoined
      ) {
        let obj = { uuid: this.plivoBrowserSdk.client.callUUID };
        this._sharedService.unHoldCallFn(obj).subscribe(
          (response) => {
            if (response.statusCode == 200) {
              this.holdCall = false;
            }
          },
          (err: ErrorModel) => {
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
      } else {
        this.allHoldUnholdMember({
          hold: false,
          mute: this.muteCall,
        });
      }
    }
  }

  dialPad() {
    this.isDialerOpen = true;
    // this.isCurrentCall = false;
    // this.makingCall = false;
    this._utilities.makingCallDialer = false;
  }

  allHoldUnholdMember(data) {
    this.holdCall = data.hold;
    this.muteCall = data.mute;
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (this._utilities.isTwilioUser) {
      this._twilioService
        .allHoldUnholdMember({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
          conferenceId: this.conferenceId,
          allHold: data.hold,
          allMute: data.mute,
          memberList: this.memberList || [],
          mergeCallDialer: {
            firstCall: this.mergeCallDialer?.firstCall
              ? this.mergeCallDialer.firstCall
              : false,
            secondCall: this.mergeCallDialer?.secondCall
              ? this.mergeCallDialer.secondCall
              : false,
            callMerged: this.mergeCallDialer?.callMerged
              ? this.mergeCallDialer.callMerged
              : false,
            callEnded: this.mergeCallDialer?.callEnded
              ? this.mergeCallDialer.callEnded
              : true,
            callNotJoined: this.mergeCallDialer?.callNotJoined
              ? this.mergeCallDialer.callNotJoined
              : false,
          },
          uname: user.eUname,
        })
        .subscribe((response: ResponseModel) => {});
    } else {
      this._flowService
        .allHoldUnholdMember({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
          allHold: data.hold,
          allMute: data.mute,
          memberList: this.memberList || [],
          mergeCallDialer: {
            firstCall: this.mergeCallDialer?.firstCall
              ? this.mergeCallDialer.firstCall
              : false,
            secondCall: this.mergeCallDialer?.secondCall
              ? this.mergeCallDialer.secondCall
              : false,
            callMerged: this.mergeCallDialer?.callMerged
              ? this.mergeCallDialer.callMerged
              : false,
            callEnded: this.mergeCallDialer?.callEnded
              ? this.mergeCallDialer.callEnded
              : true,
            callNotJoined: this.mergeCallDialer?.callNotJoined
              ? this.mergeCallDialer.callNotJoined
              : false,
          },
          uname: user.eUname,
        })
        .subscribe((response: ResponseModel) => {});
    }
  }

  endCall() {
    if (this._utilities.isTwilioUser) {
      const member = this.memberList.filter((d) => d.label === 'Owner');
      this._twilioService
        .dropMember({
          conferenceId: member[0].conferenceSid,
          callSid: member[0].callSid,
        })
        .subscribe((response: ResponseModel) => {
          this.callStatus = 'Call Ended';
        });
      this.showMemberList = false;
      this.addMemberCall = false;
      if (this.counter) {
        clearInterval(this.counter);
      }
      this.statusCheckFlag = true;
    } else {
      this._flowService
        .endCall({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
        })
        .subscribe((response: ResponseModel) => {
          this.callStatus = 'Call Ended';
        });
      this.showMemberList = false;
      this.addMemberCall = false;
      if (this.counter) {
        clearInterval(this.counter);
      }
      this.statusCheckFlag = true;
    }
  }

  get leadSource() {
    return this.callDetailForm.get('leadSource');
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.callDetailForm.controls[controlName].hasError(errorName);
  };

  initializePlivo() {
    this.plivoBrowserSdk = new Plivo(this.options);

    this.plivoBrowserSdk.client.on('onLogout', () => {});
    this.plivoBrowserSdk.client.on('onLoginFailed', (cause) => {
      this._bugsnagService.upload({ 'onLoginFailed =>': cause }, 'error');
      // this._toastrService.warning('Cannot initiate call at the moment.');
      window.location.reload();
    });

    this.plivoBrowserSdk.client.on('onIncomingCallIgnored', () => {});
    this.plivoBrowserSdk.client.on('onCallRemoteRinging', () => {});
    this.plivoBrowserSdk.client.on('onIncomingCallCanceled', () => {});

    this.plivoBrowserSdk.client.on('onCallFailed', (cause, callInfo) => {
      console.log('onCallFailed===cause===>', cause);
      console.log('onCallFailed===callInfo===>', callInfo);
      this._toastrService.clear();
      this._bugsnagService.upload(
        { 'onCallFailed =>': cause, 'callInfo=>': callInfo },
        'error'
      );
      if (this.plivoBrowserSdk.client.callUUID) {
        this.callUUID = this.plivoBrowserSdk.client.callUUID;
      }
      if (cause == 'Not Found') {
        this._toastrService.error(this.messageConstant.callBusyMessage, '');
      } else if (cause == 'Canceled') {
        this._toastrService.info(this.messageConstant.callCancelledMessage, '');
      } else if (cause == 'Busy') {
        this._toastrService.info(this.messageConstant.callBusyMessage, '');
      } else {
        this._toastrService.warning(this.messageConstant.callNotConnected, '');
      }

      setTimeout(() => {
        this._sharedService.refreshActivityLog.next(true);
      }, 2000);
      this.cancel();
    });
    this.plivoBrowserSdk.client.on('onCallAnswered', (callInfo) => {
      this.callDuration = '00:00:00';
      this.callReady = true;
      let a = moment();
      let b = moment();
      if (this.plivoBrowserSdk.client.callUUID) {
        this.callUUID = this.plivoBrowserSdk.client.callUUID;
      } else {
        this.callUUID = callInfo.callUUID;
      }

      this.multiCallUUID = callInfo.callUUID;
      this.multiCallEventName = callInfo.extraHeaders['X-PH-from'];
      // this.counter = setInterval(() => {
      //   this.showTime(a, b);
      // }, 1000);
      if (!this.plivoBrowserSdk.client.callUUID) {
        this.callAnswerInterval = setInterval(() => {
          if (this.statusCheckFlag === false) {
            this._flowService
              .getCallInfo({
                name: callInfo.extraHeaders['X-PH-from'].replace(
                  '+',
                  'Resimpli'
                ),
              })
              .subscribe(
                (response: ResponseModel) => {
                  this.mergeCallDialer = response.data.users;
                  this.statusCheckFlag = response.data.isCheckStatus;
                  if (
                    this.mergeCallDialer.firstCall &&
                    this.callDuration === '00:00:00'
                  ) {
                    this.counter = setInterval(() => {
                      this.showTime(a, b);
                    }, 1000);
                  }
                  if (this.statusCheckFlag === true) {
                    if (this.callAnswerInterval) {
                      clearInterval(this.callAnswerInterval);
                    }
                  }
                },
                (err: ErrorModel) => {
                  this._loaderService.stop();
                }
              );
          }
        }, 1000);
      } else {
        this.mergeCallDialer.firstCall = true;
        this.counter = setInterval(() => {
          this.showTime(a, b);
        }, 1000);
      }
    });
    this.plivoBrowserSdk.client.on('onMediaConnected', () => {});
    this.plivoBrowserSdk.client.on(
      'onCallTerminated',
      (hangupInfo, callInfo) => {
        console.log('onCallTerminated===hangupInfo===>', hangupInfo);
        console.log('onCallTerminated===callInfo===>', callInfo);
        this._bugsnagService.upload(
          { 'hangupInfo=>': hangupInfo, callInfo: callInfo },
          'info'
        );
        if (callInfo && callInfo.state === 'ended') {
          clearInterval(this.callAnswerInterval);
        }
        if (this.isCalling) {
          this._toastrService.clear();

          this._toastrService.success(this.messageConstant.callCompleted);
          setTimeout(() => {
            this._sharedService.refreshActivityLog.next(true);
          }, 2000);
        }

        if (this.counter) {
          clearInterval(this.counter);
        }
        this.cancel();
      }
    );
    this.plivoBrowserSdk.client.on('onCalling', () => {});
    this.plivoBrowserSdk.client.on('onIncomingCall', () => {});
    this.plivoBrowserSdk.client.on('onMediaPermission', (event) => {
      this._bugsnagService.upload({ 'event =>': event }, 'error');
    });
    this.plivoBrowserSdk.client.on('mediaMetrics', () => {});
    this.plivoBrowserSdk.client.on('onConnectionChange', () => {});
  }

  makeCall(data) {
    this.callDuration = '00:00:00';
    this.submitted = true;
    this.isAnsweredCall = true;
    if (this.callDetailForm.invalid) {
      return;
    }
    this.isMinimizedDis = false;
    this.isCalling = true;
    this.callUUID = '';
    if (this.plivoBrowserSdk.client.isLoggedIn) {
      this.initiateCall(data);
    } else {
      this.plivoAccountIndex = Math.floor(Math.random() * 5);
      const user = JSON.parse(localStorage.getItem('currentUser'));
      if (user?.email === 'john@cmjhomebuyers.com') {
        this.plivoAccountIndex = 5;
      }

      this.plivoBrowserSdk.client.login(user.eUname, user.ePwd);
      //  this.plivoBrowserSdk.client.login(
      //    'safalTest542770037250398027',
      //    'mastermind###'
      //  );

      this.plivoBrowserSdk.client.on('onLogin', () => {
        if (this.isCalling) {
          this.initiateCall(data);
        }
      });
    }
  }

  initiateCall({ phoneNumber }) {
    this.mergeCallDialer = {
      firstCall: false,
      secondCall: false,
      callMerged: false,
      callEnded: true,
      callNotJoined: false,
      checkStatus: false,
      endCause: '',
    };
    this.statusCheckFlag = false;
    const user = JSON.parse(localStorage.getItem('currentUser'));

    const { leadSource } = this.callDetailForm.value;
    let number = leadSource;
    if (typeof this.data.leadSource === 'string') {
      number =
        this.leadSourceCountryCode + this._utilities.unMaskNumber(leadSource);
    }

    const extraHeaders = {
      'X-PH-to': phoneNumber,
      'X-PH-from': number,
      'X-PH-default': this.data.leadSource
        ? this._utilities.unMaskNumber(this.data.leadSource)
        : number,
      'X-PH-userId': this.userId,
      'X-PH-moduleType': this.data.moduleType || 'Leads',
    };
    this.addToMpcHeader = {
      'X-PH-to': phoneNumber,
      'X-PH-from': number,
      'X-PH-default': this._utilities.unMaskNumber(number),
      'X-PH-userId': this.userId,
      'X-PH-Id': user.eUname,
      'X-PH-moduleType': this.data.moduleType || 'Leads',
    };
    let vendorId;
    let buyerId;
    let leadId;

    if (this.data.moduleType) {
      if (this.data.moduleType === 'Vendor') {
        extraHeaders['X-PH-vendorId'] =
          this.data?.vendorId || this.data.buyerId;
        vendorId = this.data?.vendorId || this.data.buyerId;
      } else {
        extraHeaders['X-PH-buyerId'] = this.data.buyerId;
        buyerId = this.data.buyerId;
      }
    } else {
      extraHeaders['X-PH-leadId'] = this.data.leadId;
      leadId = this.data.leadId;
    }

    if (this.isTwilioCall) {
      let moduleType = this.data.moduleType || 'Leads';

      this._twilioService.initializeTwilio({
        from: number,
        to: phoneNumber,
        number: this.data.leadSource
          ? this._utilities.unMaskNumber(this.data.leadSource)
          : number,
        userId: this.userId,
        moduleType: moduleType,
        buyerId: buyerId,
        leadId: leadId,
        vendorId: vendorId,
      });
    } else {
      this.plivoBrowserSdk.client.call(
        `${user.eUname}@phone.plivo.com`,
        extraHeaders
      );
    }
    // this.plivoBrowserSdk.client.call(
    //   `safalTest542770037250398027`,
    //   extraHeaders
    // );
    this._utilities.callType = 'outgoing';
    this._utilities.numberOfCallsRunning++;
  }

  showTime(dateA, dateB) {
    dateB.add(1, 'seconds');
    this.callDuration = moment
      .utc(
        moment(dateB, 'DD/MM/YYYY HH:mm:ss').diff(
          moment(dateA, 'DD/MM/YYYY HH:mm:ss')
        )
      )
      .format('HH:mm:ss');
  }

  mute() {
    this.muteCall = true;
    if (this.isTwilioCall && this.twilioCallSid) {
      this._twilioService.callMuteUnmute(true);
    }
    if (
      this.plivoBrowserSdk.client.callUUID &&
      !this.mergeCallDialer.callNotJoined
    ) {
      this.plivoBrowserSdk.client.mute();
    } else {
      this.allHoldUnholdMember({
        hold: this.holdCall,
        mute: true,
      });
    }
  }

  unmute() {
    this.muteCall = false;
    if (this.isTwilioCall && this.twilioCallSid) {
      this._twilioService.callMuteUnmute(false);
    }
    if (
      this.plivoBrowserSdk.client.callUUID &&
      !this.mergeCallDialer.callNotJoined
    ) {
      this.plivoBrowserSdk.client.unmute();
    } else {
      this.allHoldUnholdMember({
        hold: this.holdCall,
        mute: false,
      });
    }
  }

  cancel() {
    if (this.holdCall) {
      this.unHold(true);
      return;
    }
    this.joinToMpcHeader = false;
    if (this._utilities.callType == 'outgoing' && this.isAnsweredCall) {
      this.isAnsweredCall = false;
      if (this.isTwilioCall) {
        this._twilioService.callHangup();
      } else {
        if (this.plivoBrowserSdk.client.callSession) {
          this.plivoBrowserSdk.client.hangup();
          if (this._utilities.numberOfCallsRunning > 0) {
            this._utilities.numberOfCallsRunning--;
          }
        }
      }

      if (location.pathname !== '/leads/details') {
        if (location.pathname !== '/leads') {
          this._dialog.closeAll();
          return;
        }
      }
      if (this.counter) {
        clearInterval(this.counter);
      }
      setTimeout(() => {
        this._sharedService.refreshActivityLog.next(true);
      }, 2000);
      this.isCalling = false;
      this.callReady = false;

      if (this.isMinimized) this.toggleDisplay();
      this.data['callUUID'] = this.callUUID;
      if (
        this.callUUID == null ||
        this.callUUID == '' ||
        this.callDuration != '00:00:00'
      ) {
        if (!this._utilities.isCallStatusDialogOpen) {
          this._utilities.isCallStatusDialogOpen = true;
          this.dialogRef = this._dialog.open(LeadCallStatusDialogComponent, {
            width: '600px',
            data: this.data,
            disableClose: true,
            closeOnNavigation: false,
            backdropClass: 'popup-Backdrop-Class-dialer',
          });

          this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              if (this.counter) {
                clearInterval(this.counter);
              }
              this._sharedService.refreshActivityLog.next(true);
              this.dialogRef.close(true);
              this._utilities.isCallStatusDialogOpen = false;
            }
          });
        }
      }
    } else {
      if (this._utilities.callTypeReject == 'false') {
        this.isCalling = true;
      }
    }
  }
  searchByFilter(data) {
    const re = RegExp(
      `.*${data.target.value.toLowerCase().split('').join('.*')}.*`
    );
    this.teams = this.allTeams.filter(
      (v) =>
        v.firstName.toLowerCase().match(re) ||
        v.lastName.toLowerCase().match(re)
    );
    if (this.teams && this.teams.length === 0) {
      this.dialedNumber = '';
    }
  }
  toggleDisplay() {
    if (this.isMinimized) {
      if (document.querySelector('.cdk-overlay-backdrop'))
        document.querySelector('.cdk-overlay-backdrop')['style'].display =
          'block';

      if (document.querySelector('.cdk-overlay-pane')) {
        document.querySelector('.cdk-overlay-pane')['style'].position =
          'relative';
        document.querySelector('.cdk-overlay-pane')['style'].height = 'auto';
        document.querySelector('.cdk-overlay-pane')['style'].transform = '';
        document.querySelector('.cdk-overlay-pane')['style'].bottom = 'auto';
        document.querySelector('.cdk-overlay-pane')['style'].left = 'auto';
      }
    } else {
      if (document.querySelector('.cdk-overlay-backdrop'))
        document.querySelector('.cdk-overlay-backdrop')['style'].display =
          'none';

      if (document.querySelector('.cdk-overlay-pane')) {
        document.querySelector('.cdk-overlay-pane')['style'].position =
          'absolute';
        document.querySelector('.cdk-overlay-pane')['style'].bottom = '2px';
        document.querySelector('.cdk-overlay-pane')['style'].left = '2px';
        document.querySelector('.cdk-overlay-pane')['style'].height = '170px';
      }
      this.reset();
    }

    this.isMinimized = !this.isMinimized;
  }

  minimizeDisplay() {
    if (this.isMinimizedDis) {
      if (document.querySelector('.cdk-overlay-backdrop'))
        document.querySelector('.cdk-overlay-backdrop')['style'].display =
          'block';

      if (document.querySelector('.cdk-overlay-pane')) {
        document.querySelector('.cdk-overlay-pane')['style'].position =
          'relative';
        document.querySelector('.cdk-overlay-pane')['style'].height = 'auto';
        document.querySelector('.cdk-overlay-pane')['style'].transform = '';
        document
          .querySelector('.cdk-overlay-pane')
          .classList.remove('re-lead-dialer-minimize');
      }
    } else {
      if (document.querySelector('.cdk-overlay-backdrop'))
        document.querySelector('.cdk-overlay-backdrop')['style'].display =
          'none';
      if (document.querySelector('.cdk-overlay-pane')) {
        document.querySelector('.cdk-overlay-pane')['style'].position =
          'absolute';
        document
          .querySelector('.cdk-overlay-pane')
          .classList.add('re-lead-dialer-minimize');
      }
      this.reset();
    }

    this.isMinimizedDis = !this.isMinimizedDis;
    this.isMinimizedScreen = false;
  }

  drop(ev): void {
    this.evt = ev.source;
  }

  reset() {
    if (this.evt) this.evt._dragRef.reset();
  }

  dialer(key) {
    if (!key) {
      return;
    }
    this.dialedNumber += key;
    if (this.isTwilioCall) {
      this._twilioService.sendDigits(key);
    } else {
      this.plivoBrowserSdk.client.sendDtmf(key);
    }
  }
  async changeNumber() {
    this.dialogRef = this._dialog.open(ChangeNumberComponent, {
      width: '450px',
      disableClose: true,
      data: {
        numberList: this.defaultNumberList,
        leadId: this.data.leadId,
        numberId: this.data?.numberId,
        leadIds: [],
        allLeadHide: true,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.data.leadSource = result?.defaultNumberId;
        let phone = this.defaultNumberList
          .map((number) => {
            if (number._id == result?.defaultNumberId) {
              return number.number;
            }
          })
          .filter((x) => x);
        this.data.leadSource = phone[0];
        this.callDetailForm.patchValue({
          leadSource: this._utilities.maskNumber(phone[0]),
        });
      }
    });
  }
  getDefaultCampaignList() {
    const obj = {
      crmQueId: [],
      numTypeId: 1,
    };

    this._leadService.getCampaignList(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.defaultNumberList = response.data?.items;
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
      }
    );
  }

  onNumberType(value) {
    const type = this._utilities.numberType(value?.toLowerCase());
    return type;
  }

  twilioOutboundCallEvent(connection) {
    this.twilioCallSid = connection?.parameters?.CallSid;
    this.callUUID = connection?.parameters?.CallSid;
    this.multiCallUUID = connection?.parameters?.CallSid;
    this.multiCallEventName = connection.customParameters.get('X-PH-from');
    if (this.twilioCallSid) {
      this.callReady = true;
      let a = moment();
      let b = moment();
      this.callDuration = '00:00:00';
      this.callStatus = 'Ongoing Call...';
      this.mergeCallDialer.firstCall = true;
      this.counter = setInterval(() => {
        this.showTime(a, b);
      }, 1000);
    }
  }
}
