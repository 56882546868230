<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      <span> Send SMS </span>
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<mat-dialog-content class="re-details-modal">
  <div class="container">
    <div class="box re-addrvm-modal">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="labels"
              >SMS Message<span class="re-reqired">*</span></label
            >
            <textarea
              class="form-control"
              placeholder="SMS Template"
              [(ngModel)]="smsContent"
            >
            </textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="padding-top: 14px !important">
  <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
  <button color="primary" mat-raised-button cdkFocusInitial (click)="sendSMS()">
    Send SMS
  </button>
</mat-dialog-actions>
