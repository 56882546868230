import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// UTILS
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { CreditService } from 'src/app/providers/credit/credit.service';
import { DripService } from 'src/app/providers/drip/drip.service';

@Component({
  selector: 'app-drip-automation',
  templateUrl: './drip-automation.component.html',
  styleUrls: ['./drip-automation.component.scss'],
})
export class DripAutomationComponent implements OnInit {
  @Output() _emitter = new EventEmitter<any>();
  dripAutomationForm: FormGroup;
  messageConstant = MessageConstant;
  isAllowManageNumberFeature: boolean = true;
  dripList: any = [];
  currentPage: number = 1;
  currentLimit: number = 100; //environment.pagination.pageLimit;
  leadManually: boolean = true;
  collapsedSections: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private fb: FormBuilder,
    public _utilities: CommonFunctionsService,
    private _dripService: DripService,
    private _creditService: CreditService
  ) {}

  ngOnInit(): void {
    this.onDripList();
    this.dripAutomationForm = this.fb.group({
      isAutoAssignDrip: [this.data.details.isAutoAssignDrip || false],
      isLeadCreatedManual: [this.data.details.isLeadCreatedManual || false],
      manualLeadDripId: [this.data.details.manualLeadDripId || null],
      isManualLeadAutoStopDrip: [
        this.data.details.isManualLeadAutoStopDrip || false,
      ],
      isDripAnsCall: [this.data.details.isDripAnsCall || false],
      ansDripId: [this.data.details.ansDripId || null],
      isAnsAutoStopDrip: [this.data.details.isAnsAutoStopDrip || false],
      isDripUnansCall: [this.data.details.isDripUnansCall || false],
      unansDripId: [this.data.details.unansDripId || null],
      isUnansAutoStopDrip: [this.data.details.isUnansAutoStopDrip || false],
      isDripZap: [this.data.details.isDripZap || false],
      zapDripId: [this.data.details.zapDripId || null],
      isZapAutoStopDrip: [this.data.details.isZapAutoStopDrip || false],
      isDripWebForm: [this.data.details.isDripWebForm || false],
      webFormDripId: [this.data.details.webFormDripId || null],
      isWebFormAutoStopDrip: [this.data.details.isWebFormAutoStopDrip || false],
      isDripWebSite: [this.data.details.isDripWebSite || false],
      webSiteDripId: [this.data.details.webSiteDripId || null],
      isWebSiteAutoStopDrip: [this.data.details.isWebSiteAutoStopDrip || false],
    });
  }

  toggleSection(groupIndex) {
    const index = this.collapsedSections.findIndex((x) => x === groupIndex);
    this.collapsedSections = [];
    if (index > -1) {
      this.collapsedSections.splice(index, 1);
    } else {
      this.collapsedSections.push(groupIndex);
    }
  }

  isVisible(groupIndex) {
    const index = this.collapsedSections.findIndex((x) => x === groupIndex);
    return index > -1 ? false : true;
  }

  onDripList() {
    this._loaderService.start();
    let obj = {
      page: this.currentPage,
      limit: this.currentLimit,
    };
    this._dripService.listDrip(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          this.dripList = response.data.items;
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  onDripWebForm() {
    if (this.dripAutomationForm?.value?.isDripWebForm == false) {
      this.dripAutomationForm.patchValue({
        webFormDripId: null,
        isWebFormAutoStopDrip: false,
      });
    }
    this.callEmitForm();
  }

  onDripWebSite() {
    if (this.dripAutomationForm?.value?.isDripWebSite == false) {
      this.dripAutomationForm.patchValue({
        webSiteDripId: null,
        isWebSiteAutoStopDrip: false,
      });
    }
    this.callEmitForm();
  }

  onDripUnansCall() {
    if (this.dripAutomationForm?.value?.isDripUnansCall == false) {
      this.dripAutomationForm.patchValue({
        unansDripId: null,
        isUnansAutoStopDrip: false,
      });
    }
    this.callEmitForm();
  }

  getUserPlan() {
    this._loaderService.start();
    let obj = {};
    this._creditService.getUserPlan(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          this.isAllowManageNumberFeature =
            response?.data?.isAllowManageNumberFeature;
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  onAutoAssignDrip() {
    if (this.dripAutomationForm?.value?.isAutoAssignDrip == false) {
      this.dripAutomationForm.patchValue({
        isLeadCreatedManual: false,
        manualLeadDripId: null,
        isManualLeadAutoStopDrip: false,
        isDripAnsCall: false,
        ansDripId: null,
        isAnsAutoStopDrip: false,
        isDripUnansCall: false,
        unansDripId: null,
        isUnansAutoStopDrip: false,
        isDripZap: false,
        zapDripId: null,
        isZapAutoStopDrip: false,
        isDripWebForm: false,
        webFormDripId: null,
        isWebFormAutoStopDrip: false,
        isDripWebSite: false,
        webSiteDripId: null,
        isWebSiteAutoStopDrip: false,
      });
    }
    this.callEmitForm();
  }

  callEmitForm() {
    this._emitter.emit({
      type: 'drip-automation',
      data: this.dripAutomationForm.value,
    });
  }

  onLeadCreatedManual() {
    if (this.dripAutomationForm?.value?.isLeadCreatedManual == false) {
      this.dripAutomationForm.patchValue({
        manualLeadDripId: null,
        isManualLeadAutoStopDrip: false,
      });
    }
    this.callEmitForm();
  }

  onDripAnsCall() {
    if (this.dripAutomationForm?.value?.isDripAnsCall == false) {
      this.dripAutomationForm.patchValue({
        ansDripId: null,
        isAnsAutoStopDrip: false,
      });
    }
    this.callEmitForm();
  }

  onDripZap() {
    if (this.dripAutomationForm?.value?.isDripZap == false) {
      this.dripAutomationForm.patchValue({
        zapDripId: null,
        isZapAutoStopDrip: false,
      });
    }
    this.callEmitForm();
  }
}
