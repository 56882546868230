export class MessageConstant {
  public static permissionDenied = `Oops! You don't have access to this.`;
  public static requiredFieldMsg = '*This field is required';
  public static requiredField = '*Field is required';
  public static checkField = '*Please add valid Field';
  public static invalidFieldValue = 'Invalid field value';
  public static validFieldValue = 'Please enter valid';
  public static invalidEmail = '*Please Enter a valid Email';
  public static notMatchedPassword = 'Password does not match';
  public static requiredEmail = '*Email is required';
  public static emailInUse = 'Email is already in use.';
  public static requiredPassword = '*Password is required';
  public static requiredName = '*Name is required';
  public static namePattern = '*Name must contain only characters';
  public static passwordPattern =
    'Password must contain Capital Letter, Small Letter, A Special Character, A Number and a minimum of 8 character length';
  public static minMaxValue =
    'Value should be [[type]] than or equal to [[value]]';
  public static requiredReferral =
    'Please enter coupon code or give brief description of where you heard about REsimpli.';
  public static unAuthorizedError = 'You are not authorized. Please login.';
  public static unknownError = 'Something went wrong. Please try again later.';
  public static successRegister =
    'You are registered successfully. Please login with your registered credentials';
  public static invalidFormFields = 'Please fill all the fields correctly';
  public static forgotPwdSuccessMsg =
    'Please check your mail to get the reset password link.';
  public static resetPwdSuccessMsg = 'Your password was reset successfully';
  public static folderAddSuccess = 'Folder Added Successfully';
  public static folderUpdateSuccess = 'Folder Updated Successfully';
  public static folderSharedSuccess = 'Folder Shared Successfully';
  public static folderDeletedSuccess = 'Folder Deleted Successfully';
  public static folderUnsharedSuccess =
    'The owner of this folder has disabled file sharing.';
  public static fileAddSuccess = 'File Added Successfully';
  public static fileUpdateSuccess = 'File Updated Successfully';
  public static fileSharedSuccess = 'File Shared Successfully';
  public static fileDeletedSuccess = 'File Deleted Successfully';
  public static fileUnsharedSuccess =
    'The owner of this file has disabled file sharing.';
  public static fileDownloadSuccess = 'File Downloaded Successfully';
  public static linkCopiedSuccess = 'Link Copied To Clipboard';
  public static customSectionUpdateSuccess = '[[type]] [[status]] Successfully';
  public static contactAddedSuccess = 'Contact Added Successfully';
  public static contactUpdatedSuccess = 'Contact Updated Successfully';
  public static contactDeletedSuccess = 'Contact Deleted Successfully';
  public static numberDeletedSuccess = 'Number Deleted Successfully';
  public static numberUpdatedSuccess = 'Number Updated Successfully';
  public static rvmDeletedSuccess = 'RVM Deleted Successfully';
  public static leadAddedSuccess = 'Lead Added Successfully';
  public static leadUpdatedSuccess = 'Lead Updated Successfully';
  public static leadDeletedSuccess = 'Lead Deleted Successfully';
  public static selectProperAddress = 'Please Select Proper Address';
  public static statusUpdatedSuccess = 'Status updated successfully';
  public static sendRVMSuccess = 'RVM Sent successfully';
  public static blankTag = 'New Tag Name is required';
  public static blankList = 'New List Name is required';
  public static sameRenameTag = "New Tag Name and Old Tag Name can't be same";
  public static sameRenameList =
    "New List Name and Old List Name can't be same";
  public static tagLengthError = 'Tag Name cannot be more than 50 characters';
  public static invalidRVMUrl = 'Please select RVM recording.';
  public static invalidRVMContact = "Send to contact can't be empty";
  public static markedAsFavorite = 'Marked as Favorite Successfully.';
  public static removedAsFavorite = 'Unmarked as Favorite Successfully.';
  public static markedAsHot = 'Marked as Hot Lead Successfully.';
  public static removedAsHot = 'Unmarked as Hot Lead Successfully.';
  public static callforward = 'Different forwarding number Added Successfully';
  public static removedcallforward =
    'Different forwarding number Deleted Successfully';
  public static noCampaignNumber =
    'You do not have any campaign assigned to this lead. <br><a href="https://resimpli.com/assigning-a-marketing-campaign-before-calling" target="_blank" class="toastr-link"><u>Click here to watch a tutorial video on how to do that.</u></a>';
  public static noEmail =
    'You do not have any email associated. Please assign an email before continuing with this action.';
  public static noBuyerNumber =
    'You do not have any phone number associated. Please assign a phone before continuing with this action.';
  public static noTollFreeNumber =
    'You can only send Bulk SMS using a Toll Free number. Please add a Toll Free number and assign it to Buyers before continuing with this action. <br><a href="https://www.loom.com/share/38ed7a8b65e04dd3882ceaaaf6480268" target="_blank" class="toastr-link"><u> Click here for a quick demo video on how to send Bulk SMS to Buyers using Toll Free number. </u></a>';
  public static noNumber =
    'Please add a phone number for Buyers before you can call or text any of your buyers. <br><a href="https://www.loom.com/share/16c8ec39f2404afd943d729f991bd764" target="_blank" class="toastr-link"><u>Click here for a quick demo video on how to add a phone number for Buyers.</u></a>';
  public static noNumberBuyer =
    'Please purchase a number that is assigned specifically to Buyers before continuing with this action.';
  public static numbersOnly = '[[FIELD]] can only contain numbers.';
  public static noDataAvailable = 'No Data Available';
  public static dataAvailableAfter = 'Data available only after 25-feb-2023';
  public static comingSoon = 'Coming Soon!';
  public static tagsAddedSuccess = 'Tags Added Successfully';
  public static nonUniqueTag = 'Tag Name already exists!';
  public static tagRenamedSuccess = 'Tag Renamed Successfully';
  public static listRenamedSuccess = 'List Renamed Successfully';
  public static nonUniqueList = 'List Name already exists!';
  public static tagsDeletedSuccess = 'Tags Deleted Successfully';
  public static tagsRemoveBlank = 'Please select tags to remove';
  public static listRemoveBlank = 'Please select lists to remove';
  public static rvmAddedSuccess = 'RVM Added Successfully';
  public static rvmUpdatedSuccess = 'RVM Updated Successfully';
  public static invalidRVMTitle = 'Invalid RVM Title';
  public static invalidRVMModules = 'Select Modules To Map';
  public static invalidRVMFile = 'Select files to upload';
  public static tagDeletedSuccess = 'Tag Deleted Successfully';
  public static fileMoveRequired = 'Please select at least one file.';
  public static callTerminated = 'Call terminated.';
  public static callFailed = 'Call failed.';
  public static callCompleted = 'Call completed successfully';
  public static callNotConnected =
    'Call could not be connected. Please try again.';

  // Email
  public static exceedSizeLimit =
    'Your selected attachment size must be less than 10mb';
  public static exceedTotalSizeLimit =
    'Your total attachments size must be less than 10mb';
  public static emailAddedSuccess = 'Email Template Added Successfully';
  public static emailUpdatedSuccess = 'Email Template Updated Successfully';
  public static emailDeletedSuccess = 'Email Template Deleted Successfully';
  public static emailSendSuccess = 'Email sent successfully';
  public static checkYourEmail =
    'Please check your email to verify your email address';
  public static campaignExists = 'Campaign Name Already Exists.';
  public static selectContactWithEmail =
    'Please select at least one contact with email id';
  public static selectContactWithNumber =
    'Please select at least one contact with contact number';
  public static selectContactToAction =
    'Please select at least one contact to [[TYPE]]';
  public static selectBulkContactWithLimit =
    'You can only send [[TYPE]] to [[LIMIT]] contacts at a time';

  // Team
  public static teamUpdatedSuccess = 'Team Updated Successfully';

  //Activity
  public static activityLogsAddedSuccess = 'Logs Added Successfully';
  public static activityLogsUpdatedSuccess = 'Logs Updated Successfully';
  public static activityLogsDeletedSuccess = 'Logs Deleted Successfully';
  public static noteAddedSuccess = 'Note Added Successfully';

  // Contractor
  public static contractorAddedSuccess = 'Contractor Added Successfully';
  public static contractorUpdatedSuccess = 'Contractor Updated Successfully';
  public static contractorDeletedSuccess = 'Contractor Deleted Successfully';

  // Esign
  public static esignAddedSuccess = 'Esign Added Successfully';
  public static esignUpdatedSuccess = 'Esign Updated Successfully';
  public static esignDeletedSuccess = 'Esign Deleted Successfully';
  public static esignCopySuccess = 'Esign Copied Successfully';
  public static esignDocumentAddedSuccess = 'Esign Document Added Successfully';
  public static esignDocumentDeletedSuccess =
    'Your document has been Deleted  Successfully';
  public static propertyAddressMissing =
    'Please fill in property address before proceeding';
  public static propertyAddedSuccess = 'Inventory Added Successfully';
  public static propertyUpdatedSuccess = 'Inventory Updated Successfully';
  public static flowAddedSuccess = 'Flow Added Successfully';
  public static flowEditSuccess = 'Flow Edited Successfully';
  public static flowCopySuccess = 'Flow Copied Successfully';
  public static greetingAddedSuccess = 'Greeting Added Successfully';
  public static voiceMailAddedSuccess = 'Voice Mail Added Successfully';
  public static flowDeletedSuccess = 'Flow Deleted Successfully';
  public static dripAddedSuccess = 'Drip Campaign Added Successfully';
  public static dripUpdatedSuccess = 'Drip Campaign Updated Successfully';
  public static dripDeletedSuccess = 'Drip Campaign Deleted Successfully';
  public static dripCopySuccess = 'Drip Campaign Copied Successfully';
  public static dripStopSuccess = 'Drip Campaign Stopped Successfully';

  // Account
  public static accountAddedSuccess = 'Account added successfully';
  public static accountUpdatedSuccess = 'Account updated successfully';
  public static accountDeletedSuccess = 'Account deleted successfully';

  // Vendor
  public static vendorAddedSuccess = 'Vendor added successfully';
  public static vendorUpdatedSuccess = 'Vendor updated successfully';
  public static vendorDeletedSuccess = 'Vendor deleted successfully';
  public static vendorDoseNotExist = 'Vendor Does Not Exists';
  public static vendorPreferredUpdatedSuccess =
    'Successfully Marked as Preferred';
  public static vendorBlacklistedUpdatedSuccess =
    'Successfully Marked as Blacklisted';

  // Account Tag
  public static accountTagAddedSuccess = 'Account tag added successfully';
  public static accountTagUpdatedSuccess = 'Account tag updated successfully';
  public static accountTagDeletedSuccess = 'Account tag updated successfully';

  // Transaction
  public static transactionAddedSuccess = 'Transaction added successfully';
  public static transactionUpdatedSuccess = 'Transaction updated successfully';
  public static transactionDeletedSuccess = 'Transaction deleted successfully';
  public static directMailSuccess = 'Direct Mail Sent Successfully';

  // List Stacking
  public static uploadLimit =
    'Cannot Insert More Than 30,000 Records At a Time.';
  public static blankUploadList = 'Please Upload List';
  public static blankListTitle = 'List Name is required';
  public static blankTagTitle = 'Tag Name is required';
  public static listCreated = 'List Created Successfully';
  public static tagCreated = 'Tag Created Successfully';
  public static blankListImport = 'Please Select Import To Existing List';
  public static processMsg =
    'A file upload is already in process. Please try again after few mins';
  public static blankSearchVal = 'Search Field is required';
  public static invalidGoToNumber = 'Page Number Does Not Exists';
  public static blankGoToNumber = 'Page Number is required';
  public static listDeletedSuccess = 'Property Deleted Successfully';
  public static blankSkipTraceField =
    'Skip Traced Date and Skip Traced Source is required';
  public static mapStatusRequired = 'Please select all standard phone status';
  public static sendToDialerLimit =
    'You can only send maximum of 10,000 records to the dialer at a time';
  public static sendToDialerPropertiesSuccess =
    ' properties were added to the campaign with phone number';
  public static sendToDialerPropertiesDuplicateRemoveSuccess =
    ' properties sent to Dialer after removing any properties that were already part of a Dialer campaign';
  public static sendToDialerPropertySuccess =
    ' property was added to the campaign with phone number';
  public static sendToDialerPropertyDuplicateRemoveSuccess =
    ' property sent to Dialer after removing any properties that were already part of a Dialer campaign';
  public static sendToDialerInfoMsg =
    'This may take upto minute please do not refresh the page';
  //User Profile
  public static userProfileUpdatedSuccess = 'User Profile Updated Successfully';
  public static userProfileImageUpdatedSuccess =
    'User Profile Image Updated Successfully';
  public static userCompanyLogoImageUpdatedSuccess =
    'Company Logo Updated Successfully';
  public static notificationUpdated =
    'Notification Preference Updated Successfully';
  public static subUserAddSuccess = 'User Added Successfully';
  public static subUserEditSuccess = 'User Updated Successfully';
  public static subUserDeleteSuccess = 'User Deleted Successfully';
  public static subUserRoleDeleteSuccess =
    'User has been successfully removed from the role.';
  public static subUserMarketDeleteSuccess =
    'User has been successfully removed from the market';
  public static subUserCampaignDeleteSuccess =
    'User has been successfully removed from the campaign';
  public static subUserLeadAccessDeleteSuccess =
    'Successfully removed lead access from user';
  public static passwordChangeSuccess = 'Password Updated Successfully';
  public static passwordSetSuccess = 'Password Set Successfully';

  public static planActivated = 'Your Selected Plan';

  // Report
  public static startEndDateError =
    'Please select both the start & the end Date';
  public static blockNumber = 'Number blocked successfully';
  public static unBlockNumber = 'Number unblocked successfully';

  // Card
  public static cardAddedSuccess = 'Card added successfully';
  public static cardUpdatedSuccess = 'Card updated successfully';
  public static cardDeletedSuccess = 'Card deleted successfully';

  // Sms
  public static smsSendSuccess = 'SMS Sent Successfully';
  public static smsScheduledSuccess = 'SMS Scheduled Successfully';
  public static deleteSmsScheduledSuccess =
    'Scheduled SMS Deleted Successfully';
  public static smsScheduledUpdateSuccess =
    'SMS Scheduled Updated Successfully';
  public static phoneNumberRequired = '*Phone Number is required';
  public static invalidPhoneNumber = '*Please Enter a valid Phone Number';
  public static smsCreditLimit = 'You do not have enough credits to send SMS.';

  // Webform
  public static webFormShow = 'Selected Section is Visible';
  public static webFormHide = 'Selected Section is Hidden';
  public static webFormQuestionShow = 'Selected Question  is Visible';
  public static webFormQuestionHide = 'Selected Question  is Hidden';
  public static webFormFieldShow = 'Selected Field is Visible';
  public static webFormFieldHide = 'Selected Field is Hidden';
  public static eSignTemplateError = 'Esign Template Name Already Exists!';
  public static checkValuePreference =
    'Same options values are not allowed in Data Source';

  // Tasks
  public static taskAddedSuccess = 'Task Added Successfully';
  public static taskUpdatedSuccess = 'Task Updated Successfully';
  public static taskDeletedSuccess = 'Task Deleted Successfully';
  public static taskPendingSuccess = 'Task marked as Pending';
  public static taskCompleteSuccess = 'Task marked as Completed';

  // SOW
  public static sowDeletedSuccess = 'SOW Deleted Successfully';
  public static leadDoseNotExist = 'Lead Does Not Exists';
  public static buyerDoseNotExist = 'Buyer Does Not Exists';
  public static enterValidAddress = 'Please enter valid address';

  // Direct Mail
  public static leadDripAssignSuccess = 'Drip Campaign Activated Successfully';
  public static leadDripUnassignSuccess = 'Drip Campaign Stopped Successfully';

  // SMS Template
  public static smsTemplateAddSuccess = 'SMS Template Added Successfully';
  public static smsTemplateEditSuccess = 'SMS Template Updated Successfully';
  public static smsTemplateDeleteSuccess = 'SMS Template Deleted Successfully';

  // BUYERS BULK IMPORT
  public static bulkBuyerUpload = 'List upload in process';
  public static bulkImportLimit =
    'Cannot Upload More Than 5000 Records At a Time.';
  public static bulkImportSuccess = '[[FILE]] Uploaded Successfully';
  public static bulkImportLimitExceed =
    'A file upload is already in process. Please try again after few mins';
  // public static flowNotFound =
  //   'You do not have any Call Flow in your account. Call Flow is required to be attached to a phone number so please create a Call Flow before buying a new phone number';
  public static flowNotFound =
    'You do not have any Call Flow in your account. Call Flow is required to be attached to a phone number so please create a Call Flow before buying a new phone number. <br><a href="https://resimpli.com/tutorial/#video-gallery-6229bd5-24" target="_blank" class="toastr-link"><u> Click for a demo video on how to set up a Call Flow. </u></a>';

  //custom message
  public static customSelectValidateMessage = 'Please select [[field]]';
  public static customRequiredValidateMessage = '[[field]] is required';
  public static customDeleteMessage = '[[field]] Deleted Successfully';
  public static customAddMessage = '[[field]] Added Successfully';
  public static customUpdateMessage = '[[field]] Updated Successfully';
  //dialer voice mail
  public static dvmDeletedSuccess = 'Dialer Voicemail Deleted Successfully';
  public static dvmAddedSuccess = 'Dialer Voicemail Added Successfully';
  public static dvmUpdatedSuccess = 'Dialer Voicemail Updated Successfully';
  // Journal Entry
  public static journalEntryddedSuccess = 'Journal entry added successfully';
  public static journalEntryUpdatedSuccess =
    'Journal entry updated successfully';
  public static journalEntrynDeletedSuccess =
    'Journal entry deleted successfully';
  // FILTER TEMPLATE
  public static filterDeleteSuccess = 'Filter Deleted Successfully';
  public static filterSaveSuccess = 'Filter Saved Successfully';
  public static filterEditSuccess = 'Filter Updated Successfully';
  public static deleteMarket =
    "Please send us an email at <a href='mailto:support@REsimpli.com' class='toastr-link'><u>support@REsimpli.com</u></a> to delete this market from your account.";
  public static deleteUser =
    "Please send us an email at <a href='mailto:support@REsimpli.com' class='toastr-link'><u>support@REsimpli.com</u></a> to remove a team member from your account.";
  public static deletePrimaryMarket = 'Primary Market cannot be deleted.';
  public static deleteMainUser =
    'The main user on the account cannot be deleted.';
  public static callUnderProgress =
    'You can not make another call while there is a call in progress';

  public static callNotFoundMessage =
    'The number you are trying to call is not valid. Please check the number';
  public static callCancelledMessage = 'Call ended by the caller';
  public static callBusyMessage =
    'The number you are trying to call is currently not available. Please try again later.';
  public static featureMessage =
    "This feature is only available in Pro and Enterprise plans. Please send an email to <a href='mailto:support@REsimpli.com' class='toastr-link'><u>support@REsimpli.com</u></a> to upgrade your plan.";

  public static buyerAlreadyExists = 'Buyer Already Exists';
  // public static interestedBuyerAddSuccess =
  //   'Interested Buyer Added Successfully.';
  // public static interestedBuyerDeleteSuccess =
  //   'Interested Buyer Deleted Successfully.';
  public static offerAddSuccess = 'Offer Added Successfully';
  public static offerEditSuccess = 'Offer Updated Successfully';
  public static buyerAddSuccess = 'Buyer Added Successfully';
  public static offerAcceptSuccess = 'Offer Accepted Successfully';
  public static offerRejectedSuccess = 'Offer Rejected Successfully';
  public static mailTemplateCopySuccess = 'Email Template Copied Successfully';
  public static noUserAssignedToRole = 'No user assigned to this role.';
  public static assigned = 'Assigned Successfully';
  public static unAssigned = 'Unassigned Successfully';
  public static campaignUpdated = 'Campaign Updated Successfully';
  public static campaignDeleted = 'Campaign Deleted Successfully';

  public static userRoleUpdated = 'User Role Updated Successfully';

  public static newBuyNumberAdd = 'New phone number purchased successfully';
  public static notAllowed = 'Not Allowed';
  public static removeUserFromRole =
    'Please remove the role and add the role again.';
  public static removeUserRestrict = `You can only delete a user that's assigned to one role. Please remove this user from all but one role before deleting. <a href="https://www.loom.com/share/69f790cadcb14050970bf5d494d819cf" target="_blank" class="toastr-link"><u>Click here</u></a> to find how to remove user from a role.`;
  public static successleadClaim = 'Lead Claim Successfully';
  public static mailTempalteCopySuccess = 'Email Template Copied Successfully';
  public static leadQualifiedSuccess = 'Lead Qualified Successfully';
  public static leadNotQualifiedSuccess = 'Lead Unqualified Successfully';
  public static phoneNumberUpdateSuccess = 'Phone number updated successfully';
  public static emailAddressUpdateSuccess = 'Email Address Update Successfully';
  public static pleaseSelectOption = 'Please select any one option';
  public static groupAddSuccess = 'Group Added Successfully';
  public static groupUpdateSuccess = 'Group Updated Successfully';
  public static numberUpdated = 'Number Updated Successfully';
  public static noCampaignNumberProperty =
    'You do not have any campaign number';
  public static userExperienceSuccess =
    'Your Personalizing Experience Added Successfully';
  // TaskTemplate
  public static userVerificationOtpSuccess = 'Otp sent Successfully';
  public static taskTemplateAddedSuccess = 'Task Template Added Successfully';
  public static taskTemplateUpdatedSuccess =
    'Task Template Updated Successfully';
  public static taskTemplateDeletedSuccess =
    'Task Template Deleted Successfully';
  public static marketUpdateSuccess = 'Market Updated Successfully.';
  public static marketAddSuccess = 'Market Added Successfully.';
  public static vacationModeOn = 'Vacation Mode turned ON';
  public static vacationModeOff = 'Vacation Mode turned OFF';
  public static vacationModeUpdateSuccess =
    'Vacation Mode updated successfully.';
  public static campaignRequired =
    'Please select at least any 1 campaign to proceed further';
  public static changeNumberSuccess = 'Number Changed Successfully';

  // OFFER
  public static offerDeleteSuccess = 'Offer Deleted Successfully';
  public static appointmentStatusChangeSuccess =
    'Appointment status changed successfully';
  public static setAvailabilitySuccess = 'Availability Updated Successfully';

  // CONTINGENCY
  public static contingencyAddSuccess = 'Contingency Added Successfully';
  public static contingencyUpdateSuccess = 'Contingency Updated Successfully';
  public static contingencyDeleteSuccess = 'Contingency Deleted Successfully';
  public static noNumberProperty =
    'You do not have any phone number in this property';

  // Wholesale Pipeline
  public static soldFromLeadsMessage =
    'A property with Exit Type of Wholesale - Assignment Contract or Wholesale - Double Close can only be moved to Sold from Wholesale Pipeline.';

  // URL Pattern message
  public static urlPattern = 'Please enter valid url';

  // calendar sync
  public static calendarSync = 'Google Calendar Added Successfully';
  public static calendarUnSync = 'Google Calendar Deleted Successfully';

  // webhook
  public static webhookAdded = 'Webhook Added Successfully';
  public static webhookUpdated = 'Webhook Updated Successfully';
  public static webhookDelete = 'Webhook Deleted Successfully';
  public static actionPosted = 'Please select atleast one action posted';

  public static movedBackToLead = 'Property moved back to lead successfully';
  public static moveToBuyer = 'Property moved to buyer successfully';
  public static moveToVendor = 'Property moved to vendor successfully';
  public static webhookLimit = "Can't add multiple webhooks";
  public static webhookEnterpriseLimit = "Can't add more than 5 webhooks";
  public static uploadMediaError =
    'Only png,jpeg and mp4 media files are allowed';
  public static uploadDocError = 'Please Add Text Files Only';
  public static listEditSuccess = 'Listing Updated Successfully';
  public static listAddSuccess = 'Listing Added Successfully';
  public static maxDocMessage = 'Maximum Addition Of 5 Documents Allowed';
  public static maxMediaMessage =
    'Maximum Addition Of 100 Images or Videos Allowed';
  public static publishSuccess = 'Website Published Successfully';
  public static unPublishSuccess = 'Website Unpublished Successfully';
  public static updatePublishSuccess = 'Website Updated Successfully';
  public static addDealSuccess = 'Deal Added Successfully';
  public static editDealSuccess = 'Deal Updated Successfully';
  public static deleteDealSuccess = 'Deal Deleted Successfully';
  public static addInterestedBuyerSuccess =
    'Interested Buyer Added Successfully';
  public static editInterestedBuyerSuccess =
    'Interested Buyer Updated Successfully';
  public static deleteInterestedBuyerSuccess =
    'Interested Buyer Deleted Successfully';
  public static addInquirySuccess = 'Inquiry Added Successfully';
  public static editInquirySuccess = 'Inquiry Updated Successfully';
  public static deleteInquirySuccess = 'Inquiry Deleted Successfully';
  public static addBuyerSuccess = 'Buyer Added Successfully';
  public static editBuyerSuccess = 'Buyer Updated Successfully';
  public static markedAsRead = 'Message Marked As Read';
  public static addressCopiedSuccess = 'Address Copied To Clipboard';
  public static uploadRequired = 'Please attach at least one document';

  //EMAIL ACCOUNT SECTION
  public static defaultMailSuccess = 'Email set as default successfully.';
  public static mailAccountDeleteSuccess =
    'Email account removed successfully.';
  public static emailCopySuccess = 'Email copied successfully.';
  public static newEmailAddSuccess = 'New email added successfully.';
  public static addEditSignSuccess = 'Signature {{NAME}} successfully.';
  public static removeSignatureSuccess = 'Signature removed successfully.';
  public static setDefaultSignature = 'Default signature set successfully.';
  public static emailConfigUpdateSuccess =
    'Email configuration updated successfully.';

  //INBOX SECTION
  public static mailDeleteSuccess = 'Conversation moved to trash.';
  public static allMailDeleteSuccess = 'Selected Conversations moved to trash.';
  public static emptyTrashSuccess = 'Conversations deleted forever.';
  public static movedToFavorite = 'Conversation moved to favorites.';
  public static removedFromFavorite = 'conversation removed from favorites.';
  public static moveAllToFavorite =
    'Selected conversations moved to favorites.';
  public static unfavoriteAllSuccess =
    'Selected conversations removed from favorites.';
  public static draftSaveSuccess = 'Mail saved in draft.';
  public static calllogError =
    'You are currently in the Ongoing (Beta) phase, so the filter and export call log functionality is not working. Please switch to the Archived tab.';
}
