import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseUrl } from 'src/app/utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

@Injectable({
  providedIn: 'root',
})
export class PreferenceService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem('token');

  constructor(private _commonFunction: CommonFunctionsService) {}

  getGroups = (data, val?): Observable<any> => {
    let midUrl = val
      ? BaseUrl.webFormUrl + 'masterGroup/'
      : BaseUrl.masterGroupUrl;
    const endpoint = this.baseUrl + midUrl + 'list';
    return this._commonFunction.globalPostService(
      endpoint,
      data,
      this.getRequestHeaders(val)
    );
  };

  getFields = (data, val?): Observable<any> => {
    let midUrl = val
      ? BaseUrl.webFormUrl + 'masterQuestion/'
      : BaseUrl.masterQuestionUrl;
    const endpoint = this.baseUrl + midUrl + 'list';
    return this._commonFunction.globalPostService(
      endpoint,
      data,
      this.getRequestHeaders(val)
    );
  };
  // SET HEADERS
  protected getRequestHeaders(
    val?
  ): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    let headers;
    let obj = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };

    if (val) {
      obj['webFormLink'] = val || localStorage.getItem('webFormLink');
    }

    headers = new HttpHeaders(obj);
    return { headers: headers };
  }
}
