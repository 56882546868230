<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      style="display: block !important"
      class="w-100 d-flex justify-content-between"
    >
      {{ data?.title }}
    </h2>
    <span matDialogClose mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<mat-dialog-content class="re-typography">
  <div class="container">
    <!--customize data block-->
    <div
      [ngClass]="
        allTabs?.customData
          ? 're-info-dropdown re-open-drop'
          : 're-info-dropdown'
      "
    >
      <div class="re-manager-title" (click)="setTabs('customData')">
        <div class="re-manager-title-left">
          Customize Data<br />
          <small>Select columns you would like to export</small>
        </div>

        <span
          [ngClass]="
            allTabs?.customData
              ? 'fa fa-chevron-down re-down-icon fa-flip-vertical'
              : 'fa fa-chevron-down re-down-icon'
          "
        ></span>
      </div>
      <div
        class="re-manager-boxes"
        [ngStyle]="{ display: allTabs?.customData ? 'block' : 'none' }"
      >
        <a
          type="button"
          [ngClass]="
            selectAllColumn
              ? 'btn btn-default re-btn'
              : 'btn btn-default re-btn re-select-btn'
          "
          (click)="selectUnselect()"
        >
          {{ selectAllColumn ? "Unselect All" : "Select All" }}
          <i class="fa fa-close ng-star-inserted" *ngIf="selectAllColumn"></i
        ></a>
        <div class="re-homebox" *ngFor="let eachGroup of groupList">
          <h4 class="re-title">{{ eachGroup }}</h4>

          <div class="row">
            <ng-container *ngFor="let item of checkboxesDataList">
              <div
                class="col-4 col-md-4 col-sm-12"
                *ngIf="eachGroup == item?.group"
              >
                <div class="re-checksec">
                  <label class="re-checkb">
                    <input
                      type="checkbox"
                      [(ngModel)]="item.isChecked"
                      name="export_fields[]"
                      (change)="changeSelection()"
                    />
                    <span class="checkmark"></span>
                    <span class="txt">{{ item.label }}</span>
                  </label>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <!--marketing Info block-->
    <div
      [ngClass]="
        allTabs?.marketingTab
          ? 're-info-dropdown re-open-drop'
          : 're-info-dropdown'
      "
    >
      <div class="re-manager-title" (click)="setTabs('marketingTab')">
        <div class="re-manager-title-left">
          Marketing Info<br />
          <small>Helps you explore data that helps you market well</small>
        </div>

        <span
          [ngClass]="
            allTabs?.marketingTab
              ? 'fa fa-chevron-down re-down-icon fa-flip-vertical'
              : 'fa fa-chevron-down re-down-icon'
          "
        ></span>
      </div>
      <div
        class="re-manager-boxes"
        [ngStyle]="{ display: allTabs?.marketingTab ? 'block' : 'none' }"
      >
        <div class="re-homebox">
          <div class="re-radio-switch">
            <p class="re-radio-text">Will you be marketing to this list?</p>
            <label class="switch">
              <input
                type="checkbox"
                id="checkbox1"
                (change)="clearMarketingData($event)"
              />
              <span class="slider"></span>
              <span class="text1" value="false">No</span>
              <span class="text2" value="true">Yes</span>
            </label>
          </div>
          <ng-container *ngIf="isMarketing == 'yes'">
            <p>Marketing Channel</p>
            <table class="table">
              <colgroup>
                <col width="25%" />
                <col width="43%" />
                <col width="32%" />
              </colgroup>
              <thead>
                <tr>
                  <th>Marketing Type<span class="re-req-icon">*</span></th>
                  <th>Campaign Name<span class="re-req-icon">*</span></th>
                  <th>
                    Expected Starting Date<span class="re-req-icon">*</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="re-check-sec">
                      <p-checkbox
                        [(ngModel)]="marketType"
                        value="{{ marketTypeVal[0] }}"
                        label="Direct Mail"
                        labelStyleClass="re-export-market"
                      ></p-checkbox>
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Campaign"
                      [(ngModel)]="campaignName[0]"
                    />
                  </td>
                  <td>
                    <p-calendar
                      styleClass="ngCalendarClass"
                      appendTo="body"
                      [icon]="'fa fa-calendar'"
                      [(ngModel)]="marketingDate[0]"
                      name="marketingDate"
                      placeholder="Select Date"
                      dateFormat="MM d, yy"
                      [minDate]="dMToMinDateValue"
                      [showButtonBar]="true"
                      [showIcon]="true"
                    ></p-calendar>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="re-check-sec">
                      <p-checkbox
                        [(ngModel)]="marketType"
                        value="{{ marketTypeVal[1] }}"
                        label="Cold Calling"
                        labelStyleClass="re-export-market"
                      ></p-checkbox>
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Campaign"
                      [(ngModel)]="campaignName[1]"
                    />
                  </td>
                  <td>
                    <p-calendar
                      styleClass="ngCalendarClass"
                      appendTo="body"
                      [icon]="'fa fa-calendar'"
                      [(ngModel)]="marketingDate[1]"
                      placeholder="Select Date"
                      dateFormat="MM d, yy"
                      [minDate]="dMToMinDateValue"
                      [showButtonBar]="true"
                      [showIcon]="true"
                    ></p-calendar>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="re-check-sec">
                      <p-checkbox
                        [(ngModel)]="marketType"
                        value="{{ marketTypeVal[2] }}"
                        label="SMS"
                        labelStyleClass="re-export-market"
                      ></p-checkbox>
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Campaign"
                      [(ngModel)]="campaignName[2]"
                    />
                  </td>
                  <td>
                    <p-calendar
                      styleClass="ngCalendarClass"
                      appendTo="body"
                      [icon]="'fa fa-calendar'"
                      [(ngModel)]="marketingDate[2]"
                      placeholder="Select Date"
                      dateFormat="MM d, yy"
                      [minDate]="dMToMinDateValue"
                      [showButtonBar]="true"
                      [showIcon]="true"
                    ></p-calendar>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="re-check-sec">
                      <p-checkbox
                        [(ngModel)]="marketType"
                        value="{{ marketTypeVal[3] }}"
                        label="RVM"
                        labelStyleClass="re-export-market"
                      ></p-checkbox>
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Campaign"
                      [(ngModel)]="campaignName[3]"
                    />
                  </td>
                  <td>
                    <p-calendar
                      styleClass="ngCalendarClass"
                      appendTo="body"
                      [icon]="'fa fa-calendar'"
                      [(ngModel)]="marketingDate[3]"
                      placeholder="Select Date"
                      dateFormat="MM d, yy"
                      [minDate]="dMToMinDateValue"
                      [showButtonBar]="true"
                      [showIcon]="true"
                    ></p-calendar>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
            <div class="re-check-sec re-all-select">
              <p-checkbox
                [(ngModel)]="addToAll"
                value="all"
                label="Update marketing details in Related Properties."
                labelStyleClass="re-export-market"
              ></p-checkbox>
              <span class="re-tooltip">
                <i class="fa fa-question-circle"></i>
                <span class="re-tooltiptext">
                  <p>
                    By selecting the option, Cold Calling, SMS or RVM marketing
                    details will be updated to matching related properties also.
                    Related Properties are properties where the owner has
                    multiple properties based on the same mailing address.
                    Direct Mail marketing information is updated by default
                    based on the mailing address.
                  </p>
                  <p>
                    For example, you send a letter to 123 Main St which mailing
                    address of 456 Main St. All properties for that owner with
                    Mailing Address of 456 Main St will get updated with the
                    Direct Mail info for better marketing tracking.
                  </p>
                </span>
              </span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <!--Export File Preference block-->
    <div
      [ngClass]="
        allTabs?.phoneTab ? 're-info-dropdown re-open-drop' : 're-info-dropdown'
      "
    >
      <div class="re-manager-title" (click)="setTabs('phoneTab')">
        <div class="re-manager-title-left">
          Export File Preference<br />
          <small>Only export the following phone numbers:</small>
        </div>

        <span
          [ngClass]="
            allTabs?.phoneTab
              ? 'fa fa-chevron-down re-down-icon fa-flip-vertical'
              : 'fa fa-chevron-down re-down-icon'
          "
        ></span>
      </div>
      <div
        class="re-manager-boxes"
        [ngStyle]="{ display: allTabs?.phoneTab ? 'block' : 'none' }"
      >
        <div class="re-homebox">
          <p>Select Phone Types</p>
          <ul class="re-phone-type-list">
            <li>
              <p-checkbox
                [(ngModel)]="selectedPhoneType"
                value="Mobile"
                label="Mobile"
              ></p-checkbox>
            </li>
            <li>
              <p-checkbox
                [(ngModel)]="selectedPhoneType"
                value="Landline"
                label="Landline"
              ></p-checkbox>
            </li>
            <li>
              <p-checkbox
                [(ngModel)]="selectedPhoneType"
                value="Voip"
                label="VoIP"
              ></p-checkbox>
            </li>
            <li>
              <p-checkbox
                [(ngModel)]="selectedPhoneType"
                value="Other"
                label="Other"
              ></p-checkbox>
            </li>
          </ul>
          <p>Select Phone Status</p>
          <div class="re-phone-status-section">
            <div class="row">
              <div class="col-md-4"><p>Phone Status to Include</p></div>
              <div class="col-md-6">
                <p-autoComplete
                  #phoneStatusInc
                  (onFocus)="phoneStatusDownClick(phoneStatusInc)"
                  (click)="phoneStatusDownClick(phoneStatusInc)"
                  (onUnselect)="phoneStatusDownClick(phoneStatusInc)"
                  (onKeyUp)="
                    $event.keyCode == 13 || $event.keyCode == 8
                      ? phoneStatusDownClick(phoneStatusInc)
                      : $event
                  "
                  (onClear)="clearListItem(phoneStatusInc)"
                  placeholder="Phone Status"
                  [(ngModel)]="phoneStatusInclude"
                  field="name"
                  [suggestions]="searchedPhoneStatus"
                  (completeMethod)="searchPhoneStatus($event.query, 'include')"
                  emptyMessage="{{ noDataAvailable }}"
                  dataKey="name"
                  [dropdown]="true"
                  [multiple]="true"
                  appendTo="body"
                >
                </p-autoComplete>
              </div>
              <div class="col-md-2">
                <select class="form-control" [(ngModel)]="includeCondition">
                  <option value="or">or</option>
                  <option value="and">and</option>
                  <option value="only">only</option>
                </select>
              </div>
            </div>
          </div>
          <div class="re-phone-status-section">
            <div class="row">
              <div class="col-md-4"><p>Phone Status to Exclude</p></div>
              <div class="col-md-6">
                <p-autoComplete
                  #phoneStatusExc
                  (onFocus)="phoneStatusDownClick(phoneStatusExc)"
                  (click)="phoneStatusDownClick(phoneStatusExc)"
                  (onUnselect)="phoneStatusDownClick(phoneStatusExc)"
                  (onKeyUp)="
                    $event.keyCode == 13 || $event.keyCode == 8
                      ? phoneStatusDownClick(phoneStatusExc)
                      : $event
                  "
                  (onClear)="clearListItem(phoneStatusExc)"
                  placeholder="Phone Status"
                  [(ngModel)]="phoneStatusExclude"
                  field="name"
                  [suggestions]="searchedPhoneStatus"
                  (completeMethod)="searchPhoneStatus($event.query, 'exclude')"
                  emptyMessage="{{ noDataAvailable }}"
                  dataKey="name"
                  [dropdown]="true"
                  [multiple]="true"
                  appendTo="body"
                >
                </p-autoComplete>
              </div>
              <div class="col-md-2">
                <select class="form-control" [(ngModel)]="excludeCondition">
                  <option value="or">or</option>
                  <option value="and">and</option>
                  <option value="only">only</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center" style="padding-top: 14px !important">
  <button
    type="button"
    class="btn re-cancelbtn"
    mat-raised-button
    [mat-dialog-close]="false"
  >
    Cancel
  </button>
  <button type="button" class="btn save" (click)="downloadCSV()">
    Export List
  </button>
</mat-dialog-actions>
