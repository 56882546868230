<mat-dialog-content>
  <ng-container *ngIf="isShowSchedule">
    <iframe
      class="calendly-inline-widget"
      src="https://calendly.com/d/5kp-j6g-s7c/resimpli-onboarding-support?hide_gdpr_banner=1&primary_color=008088"
      style="width: 100%; aspect-ratio: 16 / 9"
    ></iframe>
  </ng-container>
  <ng-container *ngIf="!isShowSchedule">
    <div class="re-onboard-main">
      <img
        class="re-close"
        src="assets/images/close-black-1.svg"
        alt="close"
        width="20"
        mat-dialog-close
      />
      <h2 class="re-text-1">Book a Free Onboarding Call</h2>
      <h3 class="re-text-2">
        Learn how to utilize the most of REsimpli in your business
      </h3>
      <div class="re-arrowdiv">
        <p>Learn How Industry Experts Use REsimpli</p>
        <p>Set Up REsimpli to Fit Your Business</p>
      </div>
      <button type="button" class="btn re-btn" (click)="isShowScheduleDialog()">
        Schedule A Call
      </button>
      <div class="text-center">
        <a class="re-link1" (click)="onBoardingStatusUpdate()"
          >I have already booked a call</a
        >
      </div>
      <img
        class="re-support1"
        src="assets/images/support-1.png"
        alt="support"
        width="120"
      />
      <img
        class="re-support2"
        src="assets/images/support-2.png"
        alt="support"
        width="100"
      />
      <img
        class="re-support3"
        src="assets/images/support-3.png"
        alt="support"
        width="110"
      />
    </div>
  </ng-container>
</mat-dialog-content>
