<div class="re-sub-table">
  <form [formGroup]="transactionDetailForm">
    <table class="table">
      <tbody>
        <tr>
          <td class="py-0 px-3 re-cusborder">
            <table class="table bordernone">
              <colgroup>
                <col width="16%" />
                <col width="18%" />
                <col width="16%" />
                <col width="16%" />
                <col width="14%" />
                <col width="20%" />
              </colgroup>
              <tbody>
                <tr class="border-0">
                  <td>
                    <div class="">
                      <label class="re-labels">Date</label>
                      <div class="position-relative">
                        <p-calendar
                          appendTo="body"
                          [showIcon]="true"
                          [icon]="'fa fa-calendar'"
                          styleClass="ngCalendarClass"
                          formControlName="transactionDate"
                          *ngIf="accountData?.isManual == true"
                        ></p-calendar>
                        <input
                          formControlName="transactionDate"
                          type="text"
                          class="form-control"
                          *ngIf="accountData?.isManual == false"
                          readonly
                          value="{{
                            tempTransactionDate | date: 'MMM dd, yyyy'
                          }}"
                        />
                      </div>
                      <div class="invalid-feedback">
                        <span
                          *ngIf="
                            submitted && hasError('transactionDate', 'required')
                          "
                          >Transaction Date is required
                        </span>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div class="">
                      <label class="re-labels">
                        Vendor
                        <span class="text-danger">*</span>
                        <i
                          class="fa fa-plus-circle re-plus-icon"
                          (click)="onAddVendorsDialog()"
                          style="cursor: pointer"
                        ></i>
                      </label>

                      <p-dropdown
                        *ngIf="vendorData"
                        class="re-custom-dropdown"
                        [options]="vendorData"
                        optionLabel="name"
                        formControlName="vendorId"
                        showClear="true"
                        [filter]="true"
                        appendTo="body"
                        placeholder="Select Vendor"
                      >
                        <ng-template let-item pTemplate="item">
                          <span class="re-drtxt1">
                            {{ item?.value?.name || item?.name }}
                          </span>

                          <span class="re-drtxt2">
                            {{
                              item?.value?.categoryName || item?.categoryName
                            }}
                            <ng-container
                              *ngIf="
                                item?.value?.subCategoryName ||
                                item?.subCategoryName
                              "
                            >
                              -
                              {{
                                item?.value?.subCategoryName ||
                                  item?.subCategoryName
                              }}
                            </ng-container>
                          </span>
                        </ng-template>
                      </p-dropdown>

                      <div class="invalid-feedback">
                        <span
                          *ngIf="submitted && hasError('vendorId', 'required')"
                        >
                          Vendor is required
                        </span>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div class="">
                      <label class="re-labels">
                        Method <span class="text-danger">*</span>
                      </label>
                      <select
                        id="inputAccount"
                        class="form-select form-control"
                        formControlName="method"
                      >
                        <option value="">Select Payment Method</option>
                        <option
                          *ngFor="let paymentMethod of paymentMethodList"
                          [value]="paymentMethod?.value"
                        >
                          {{ paymentMethod?.label }}
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        <span
                          *ngIf="submitted && hasError('method', 'required')"
                        >
                          Method is required
                        </span>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div class="">
                      <label class="re-labels">Ref/Check Number</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Ref/Check Number"
                        formControlName="checkNum"
                      />
                    </div>
                  </td>

                  <td>
                    <div class="">
                      <label class="re-labels">Amount</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputBalance"
                        placeholder="Enter Amount"
                        formControlName="amount"
                        prefix="$"
                        (keypress)="
                          onBlurParentAndChildMethod(
                            true,
                            transactionDetailForm.controls.amount
                          )
                        "
                        (keydown.backspace)="
                          onBlurParentAndChildMethod(
                            true,
                            transactionDetailForm.controls.amount
                          )
                        "
                        mask="separator.2"
                        [thousandSeparator]="','"
                        [decimalMarker]="'.'"
                        [readonly]="accountData?.isManual == false"
                      />
                      <!--<span class="fa fa-usd re-manual-icon"></span>-->
                      <div class="invalid-feedback">
                        <span
                          *ngIf="submitted && hasError('amount', 'required')"
                        >
                          Amount is required
                        </span>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div class="">
                      <label class="re-labels">
                        Transaction Type
                        <span class="text-danger"> * </span>
                      </label>
                      <div
                        class="re-add-checkbox"
                        *ngIf="accountData?.isManual == true"
                      >
                        <label class="form-check form-check-inline">
                          <input
                            class="form-check-input checkbox-size"
                            type="radio"
                            name="creditDebitType"
                            value="debit"
                            formControlName="creditDebitType"
                            id="inlineRadio1"
                          />
                          <div
                            class="form-check-label re-no-margin"
                            for="inlineRadio1"
                          >
                            Money Out
                          </div>
                          <span class="re-checkmark"></span>
                        </label>
                        <label class="form-check form-check-inline">
                          <input
                            class="form-check-input re-checkbox-size"
                            type="radio"
                            name="creditDebitType"
                            value="credit"
                            formControlName="creditDebitType"
                            id="inlineRadio2"
                          />
                          <div
                            class="form-check-label re-no-margin"
                            for="inlineRadio2"
                          >
                            Money In
                          </div>
                          <span class="re-checkmark"></span>
                        </label>
                      </div>
                      <div
                        class="re-add-checkbox re-disabled"
                        *ngIf="accountData?.isManual == false"
                      >
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input checkbox-size"
                            type="radio"
                            name="creditDebitType"
                            value="debit"
                            formControlName="creditDebitType"
                            id="inlineRadio1"
                            [attr.disabled]="
                              isMarketReadonly ? 'disabled' : null
                            "
                          />
                          <label
                            class="form-check-label re-no-margin"
                            for="inlineRadio1"
                            >Money Out</label
                          >
                          <span class="re-checkmark"></span>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input re-checkbox-size"
                            type="radio"
                            name="creditDebitType"
                            value="credit"
                            formControlName="creditDebitType"
                            id="inlineRadio2"
                            [attr.disabled]="
                              isMarketReadonly ? 'disabled' : null
                            "
                          />
                          <label
                            class="form-check-label re-no-margin"
                            for="inlineRadio2"
                            >Money In</label
                          >
                          <span class="re-checkmark"></span>
                        </div>
                      </div>
                      <div class="invalid-feedback">
                        <span
                          *ngIf="
                            submitted && hasError('creditDebitType', 'required')
                          "
                          >Transaction Type is required
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

        <tr>
          <td class="py-0 px-3">
            <table class="table re-split-table" formArrayName="breakDown">
              <colgroup>
                <col width="7%" />
                <col width="17%" />
                <col width="16%" />
                <col width="16%" />
                <col width="32%" />
                <col width="12%" />
              </colgroup>
              <thead>
                <tr class="border-0">
                  <th>#</th>
                  <th>Property Address<span class="text-danger">*</span></th>
                  <th>
                    Account<span class="text-danger">*</span
                    ><i
                      class="fa fa-plus-circle re-plus-icon"
                      (click)="onAddAccountTagDialog()"
                      style="cursor: pointer"
                    ></i>
                  </th>
                  <th>Campaign Name</th>
                  <th>Description</th>
                  <th>Amount<span class="text-danger">*</span></th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let control of transactionDetailForm['controls'].breakDown[
                      'controls'
                    ];
                    let i = index
                  "
                  [formGroupName]="i"
                >
                  <tr
                    class="border-0"
                    class="{{ i >= 2 && isShowMore ? 're-hide-row' : '' }}"
                  >
                    <td>{{ i + 1 }}</td>
                    <td>
                      <!-- DROPDOWN -->
                      <p-dropdown
                        [options]="propertyData"
                        optionLabel="address"
                        formControlName="propertyAddress"
                        showClear="true"
                        [filter]="true"
                        appendTo="body"
                        placeholder="Select Property"
                      ></p-dropdown>
                    </td>
                    <td>
                      <!-- DROPDOWN -->
                      <p-dropdown
                        [options]="accountTagData"
                        optionLabel="name"
                        formControlName="accountTag"
                        showClear="true"
                        [filter]="true"
                        appendTo="body"
                        placeholder="Select Account"
                        (onChange)="onChangeAccountTag($event)"
                      >
                        <ng-template let-item pTemplate="item">
                          <span class="re-drtxt1">
                            {{ item?.value?.name || item?.name }}
                          </span>
                          <span class="re-drtxt2">
                            {{
                              item?.value?.categoryName || item?.categoryName
                            }}
                            <ng-container
                              *ngIf="
                                item?.value?.subCategoryName ||
                                item?.subCategoryName
                              "
                            >
                              -
                              {{
                                item?.value?.subCategoryName ||
                                  item?.subCategoryName
                              }}
                            </ng-container>
                          </span>
                        </ng-template>
                      </p-dropdown>
                    </td>
                    <td>
                      <!-- DROPDOWN -->
                      <p-dropdown
                        class="form-select"
                        *ngIf="getCampaignArray(i).length > 0"
                        [options]="getCampaignArray(i)"
                        optionLabel="marketingTitle"
                        formControlName="campaignId"
                        showClear="true"
                        [filter]="true"
                        appendTo="body"
                        placeholder="Select Account"
                      ></p-dropdown>
                      <div
                        class="re-campaign"
                        *ngIf="getCampaignArray(i).length == 0"
                      ></div>
                    </td>
                    <td>
                      <!-- TEXT -->
                      <input
                        type="text"
                        class="form-control"
                        id="inputAccountnumber"
                        placeholder="Enter Description"
                        formControlName="userDescription"
                      />
                    </td>
                    <td>
                      <!-- NUMBER -->
                      <input
                        type="text"
                        class="form-control re-padd-left2"
                        id="inputBalance"
                        placeholder=""
                        formControlName="amount"
                        mask="separator.2"
                        [thousandSeparator]="','"
                        [decimalMarker]="'.'"
                        [allowNegativeNumbers]="true"
                        prefix="$"
                        (keypress)="onBlurParentAndChildMethod(false)"
                        (keydown.backspace)="onBlurParentAndChildMethod(false)"
                      />
                      <a
                        class="re-list-icon"
                        *ngIf="i != 0"
                        (click)="deleteBreakdown(i)"
                        ><img src="/assets/images/delete.png"
                      /></a>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
              <tfoot>
                <tr class="border-0">
                  <td>
                    <button class="btn re-split-btn" (click)="addBreakdown()">
                      Split
                    </button>
                  </td>
                  <td colspan="5">
                    <div class="re-split-total">
                      <span class="re-total-txt">TOTAL</span>
                      <span class="re-total-ammount">{{
                        breakDownTotal | currency
                      }}</span>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
            <div class="text-center" *ngIf="breakdown?.length > 2">
              <button class="btn re-showmore-btn" (click)="onShowMore()">
                {{ isShowMore ? "Show More" : "Show Less" }}
              </button>
            </div>
          </td>
        </tr>

        <tr>
          <td class="py-2 px-4">
            <div class="re-transaction-detail">
              <div class="row">
                <div class="col-12 col-md-6">
                  <label class="re-labels">Transaction Memo</label>
                  <div class="re-transaction-memo">
                    <textarea
                      class="form-control"
                      formControlName="fullDescription"
                    ></textarea>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <label class="re-labels"
                    >Attachments<span class="re-fileattach"
                      >Add File<input
                        type="file"
                        #uploader
                        ngFileSelect
                        multiple
                        (uploadOutput)="onUploadLogo($event)"
                        [uploadInput]="uploadInput"
                      /> </span
                  ></label>
                  <div class="re-attachments">
                    <span
                      class="re-attach-icon"
                      *ngFor="
                        let file of attachmentArray;
                        let fileIndex = index
                      "
                    >
                      <img
                        src="/assets/images/{{ getIcon(file) }}_icon.png"
                        [alt]="file?.title"
                        style="margin-right: 10px; cursor: pointer"
                        (click)="preview(fileIndex)"
                      />
                      <i
                        class="fa fa-times-circle re-close-icon"
                        (click)="removeAttachmentArray(fileIndex)"
                        style="cursor: pointer"
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div class="re-detail-savebtn">
              <div class="row">
                <div class="col-12 col-md-12">
                  <div class="re-btnright">
                    <button class="btn re-cancel-btn" (click)="onCancel()">
                      Cancel
                    </button>
                    <button class="btn re-save-btn" (click)="saveTransaction()">
                      Save
                    </button>
                    <button
                      type="button"
                      class="btn re-unmap-btn"
                      (click)="unmappedTransaction()"
                      *ngIf="
                        isEdit &&
                        accountData?.isManual == false &&
                        transactionData?.recognized &&
                        transactionData?.manuallyMapped
                      "
                    >
                      Unmap
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </form>
</div>
