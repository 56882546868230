import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable, Observer, Subject, fromEvent, merge } from 'rxjs';
import { Title } from '@angular/platform-browser';
import Bugsnag from '@bugsnag/js';
import jwt_decode from 'jwt-decode';
// SERVICES
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LeadsService } from './providers/leads/leads.service';
import { SocketIoService } from './providers/socket-io/socket-io.service';
import { UpdateService } from './update.service';
import { CommonFunctionsService } from './utils/common-functions/common-functions.service';
import { ErrorModel } from './utils/models/error';
import { ResponseModel } from './utils/models/response';
import { TwilioService } from 'src/app/utils/twilio-service/twilio.service';

// COMPONENTS
import { IncomingCallDetailDailogsComponent } from './shared/dialog/incoming-call-detail-dailogs/incoming-call-detail-dailogs.component';
import { HeaderComponent } from './navigation/header/header.component';

declare var $: any;
declare var Plivo;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @ViewChild('header') header: HeaderComponent;

  title = 'resimpli-dashboard';
  isNavVisible: boolean = false;
  plivoBrowserSdk; //: Plivo;
  openModel: boolean = false;
  openModels: any = [];
  dialogRefs: MatDialogRef<IncomingCallDetailDailogsComponent>;
  options = {
    debug: 'ALL',
    permOnClick: true,
    enableTracking: true,
    closeProtection: true,
    maxAverageBitrate: 48000,
    allowMultipleIncomingCalls: true,
  };
  socketConnectionSet: boolean = false;
  constructor(
    private _router: Router,
    private ngxService: NgxUiLoaderService,
    private sw: UpdateService,
    public _utilities: CommonFunctionsService,
    private _titleService: Title,
    private _dialog: MatDialog,
    private _leadService: LeadsService,
    private socketService: SocketIoService,
    private _twilioService: TwilioService
  ) {
    const token = localStorage.getItem('token');
    this._utilities.isTwilioUser =
      localStorage.getItem('isTwilioUser') == 'true' ? true : false;

    //this.connectTwilio();
    if (
      token &&
      this._utilities.isTwilioUser &&
      !this._utilities.isTwilioDeviceConnect
    ) {
      this._twilioService.connectTwilio();
    }

    this._twilioService.twilioIncomingEvent = new Subject();
    this._twilioService.twilioIncomingEvent.subscribe((response) => {
      if (response) {
        this.twilioIncomingCallEvent(response);
      }
    });

    // CHECK UPDATES
    this.sw.checkForUpdates();

    // CHECK INTERNET CONNECTION
    this.checkInternetStatus();

    // CHECK ROUTE EVENTS
    this._router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const user = localStorage.getItem('currentUser')
          ? JSON.parse(localStorage.getItem('currentUser'))
          : null;
        const token = localStorage.getItem('token');

        const currentState = this._router.getCurrentNavigation();
        if (currentState?.extras?.state?.isClearGlobalSearch && this.header)
          this.header.setGlobalSearch('');

        let currentUrl = window.location.pathname;
        if (val.url != currentUrl) {
          this.ngxService.start();
        }

        // this._dialog.closeAll();
        this.checkCurrentBugsnagSession();

        this.setTitle(val);

        if (user && token) {
          if (!this.socketConnectionSet) {
            this.socketConnectionSet = true;
            this.socketService.setupSocketConnection();
          }
          if (!this._utilities.isTwilioUser) {
            this.initializePlivo();

            if (this._utilities.numberOfCallsRunning < 1) {
              this.plivoBrowserSdk.client.logout();
              this.plivoBrowserSdk.client.login(user.eUname, user.ePwd);
            }
          }
        }
        if (val['urlAfterRedirects'].indexOf('/auth/login') >= 0) {
          this.socketConnectionSet = false;
        }
        if (val['urlAfterRedirects'].indexOf('/general-files') >= 0) {
          this._utilities.isShowSettingsRoute = true;
        }
        if (val['urlAfterRedirects'].indexOf('/direct-mail/details/') < 0)
          this.ngxService.stop();
        this.isNavVisible =
          val['urlAfterRedirects'].indexOf('/auth/login') >= 0 ||
          val['urlAfterRedirects'] == '/auth/forgot-password' ||
          val['urlAfterRedirects'].indexOf('/auth/reset-password/') >= 0 ||
          val['urlAfterRedirects'].indexOf('/auth/confirm-email/') >= 0 ||
          val['urlAfterRedirects'].indexOf('/public') >= 0 ||
          val['urlAfterRedirects'].indexOf('/auth/signup') >= 0 ||
          val['urlAfterRedirects'].indexOf('/auth/offer-signup') >= 0 ||
          val['urlAfterRedirects'].indexOf('/thank-you-for-registration') >=
            0 ||
          val['urlAfterRedirects'].indexOf('/thank-you-direct-mail') >= 0 ||
          val['urlAfterRedirects'].indexOf(
            '/.well-known/apple-app-site-association'
          ) >= 0 ||
          val['urlAfterRedirects'].indexOf('/auth/challenge-signup') >= 0
            ? false
            : true;

        const urlParams = new URLSearchParams(window.location.search);
        if (val['urlAfterRedirects'].indexOf('/public/web-form') >= 0) {
          if (urlParams.get('webFormLink')) this.isNavVisible = false;
          else this.isNavVisible = true;
        }
      }
    });
  }

  async getListStackSearch(param) {
    return new Promise(async (resolve, reject) => {
      let item = {
        phoneNumber: param,
      };
      this._leadService.getListStackSearchByPhone(item).subscribe(
        (response: ResponseModel) => {
          resolve(response.data);
        },
        (err: ErrorModel) => {
          resolve(false);
        }
      );
    });
  }

  async getBuyersDetailsSearch(param) {
    return new Promise(async (resolve, reject) => {
      let item = {
        page: 1,
        limit: 10,
        unreadTab: false,
        searchStr: param,
      };
      this._leadService.getBuyersSearchByPhone(item).subscribe(
        (response: ResponseModel) => {
          resolve(response.data.items);
        },
        (err: ErrorModel) => {
          resolve(false);
        }
      );
    });
  }
  async getBuyersDetails(param) {
    return new Promise(async (resolve, reject) => {
      let item = {
        id: param,
      };
      this._leadService.getBuyersDetailsSearchById(item).subscribe(
        (response: ResponseModel) => {
          resolve(response.data);
        },
        (err: ErrorModel) => {
          resolve(false);
        }
      );
    });
  }

  initializePlivo() {
    this.plivoBrowserSdk = new Plivo(this.options);
    // @ts-ignore
    window.plivoBrowserSdk = this.plivoBrowserSdk;
    const that = this;
    this.plivoBrowserSdk.client.on('onLogin', () => {
      console.log('Logged in as ', that.plivoBrowserSdk.client.userName);
    });
    this.plivoBrowserSdk.client.on('onWebrtcNotSupported', () => {
      console.log('Not supported');
    });
    this.plivoBrowserSdk.client.on('onLoginFailed', (reason) => {
      console.log('Login failed: ', reason);
    });
    this.plivoBrowserSdk.client.on(
      'onIncomingCall',
      async (callerName, extraHeaders, callInfo, caller_Name) => {
        let splitNumber = caller_Name.split('-');
        if (this.openModel === false) {
          this.openModel = true;
          this._utilities.callType = 'incoming';
          let buyersList;
          let buyersDetails;
          let buyerLeadDetails = [];
          if (
            extraHeaders['X-Ph-Numtypeid'] === '2' ||
            extraHeaders['X-Ph-Numtypeid'] === 2
          ) {
            buyersList = await this.getBuyersDetailsSearch(
              extraHeaders['X-Ph-Fromnumber'].replace('+1', '')
            );
            if (buyersList && buyersList.length > 0) {
              buyersDetails = await this.getBuyersDetails(buyersList[0]._id);
              if (buyersDetails) {
                if (
                  buyersDetails.leadDetails.length === 0 &&
                  buyersDetails.inventoryDetails.length > 0
                ) {
                  buyerLeadDetails = buyersDetails.inventoryDetails;
                } else if (
                  buyersDetails.leadDetails.length > 0 &&
                  buyersDetails.inventoryDetails.length === 0
                ) {
                  buyerLeadDetails = buyersDetails.leadDetails;
                } else {
                  buyerLeadDetails = [
                    ...buyersDetails.leadDetails,
                    ...buyersDetails.inventoryDetails,
                  ];
                }
              }
            }

            this.dialogRefs = this._dialog.open(
              IncomingCallDetailDailogsComponent,
              {
                width: '400px',
                panelClass: 're-incomingcall-modal',
                backdropClass: 'popup-Backdrop-Class-dialer-incoming',
                id: callInfo.callUUID,
                data: {
                  isCalling: false,
                  callInfo,
                  buyersDetails: buyersDetails ? buyersDetails : undefined,
                  buyerLeadDetails: buyerLeadDetails
                    ? buyerLeadDetails
                    : undefined,
                  buyers: true,
                  name: caller_Name
                    ? extraHeaders['X-Ph-Fromnumber']
                    : callerName,
                  callerName: extraHeaders['X-Ph-Fromnumber'],
                  numberType: extraHeaders['X-Ph-Numtypeid'],
                  isTwilio: false,
                },
                disableClose: true,
              }
            );

            this.dialogRefs.afterClosed().subscribe(() => {
              if (this._utilities.numberOfCallsRunning === 0) {
                this.openModel = false;
              }
            });

            // setTimeout(() => {
            //   notification.close();
            // }, 3000);
          } else {
            let listStackingDetails = await this.getListStackSearch(
              extraHeaders['X-Ph-Fromnumber'].replace('+1', '')
            );
            this._leadService
              .leadSearch({
                searchString: extraHeaders['X-Ph-Fromnumber'].replace('+1', ''),
              })
              .subscribe((response: ResponseModel) => {
                let leadDetails = {};
                if (response.statusCode === 200) {
                  leadDetails = response.data.items;
                }
                this.dialogRefs = this._dialog.open(
                  IncomingCallDetailDailogsComponent,
                  {
                    width: '400px',
                    panelClass: 're-incomingcall-modal',
                    backdropClass: 'popup-Backdrop-Class-dialer-incoming',
                    id: callInfo.callUUID,
                    data: {
                      isCalling: false,
                      callInfo,
                      leadDetails,
                      buyers: false,
                      name: caller_Name
                        ? extraHeaders['X-Ph-Fromnumber']
                        : callerName,
                      callerName: extraHeaders['X-Ph-Fromnumber'],
                      numberType: extraHeaders['X-Ph-Numtypeid'],
                      listStackingDetails,
                      isTwilio: false,
                    },
                    disableClose: true,
                  }
                );

                this.dialogRefs.afterClosed().subscribe(() => {
                  if (this._utilities.numberOfCallsRunning === 0) {
                    this.openModel = false;
                  }
                });
                // setTimeout(() => {
                //   notification.close();
                // }, 3000);
              });
          }
        }
      }
    );
    this.plivoBrowserSdk.client.on(
      'onIncomingCallCanceled',
      (hangupInfo, callInfo) => {
        if (
          hangupInfo.state === 'canceled' &&
          this._utilities.numberOfCallsRunning === 0
        ) {
          this.openModel = false;
          this.dialogRefs.close();
        }
        if (this._utilities.numberOfCallsRunning === 0) {
          this.openModel = false;
        }
      }
    );
    this.plivoBrowserSdk.client.on('onCallFailed', (cause, callInfo) => {
      this.openModel = false;
    });
    this.plivoBrowserSdk.client.on('onMediaPermission', () => {
      console.log('Media Permissions');
    });
    this.plivoBrowserSdk.client.on(
      'onCallTerminated',
      (hangupInfo, callInfo) => {
        //this.openModel = false;
        if (this._utilities.numberOfCallsRunning > 0) {
          this._utilities.numberOfCallsRunning =
            this._utilities.numberOfCallsRunning - 1;
        }
        if (this._utilities.numberOfCallsRunning === 0) {
          this.openModel = false;
        }
        if (this.plivoBrowserSdk.client.callSession) {
          this.plivoBrowserSdk.client.hangup(callInfo.callUUID);
        } else {
          this.plivoBrowserSdk.client.reject(callInfo.callUUID);
        }
      }
    );
  }

  checkCurrentBugsnagSession() {
    const currentUserSession = Bugsnag.getUser();
    if (
      !Object.keys(currentUserSession).length &&
      localStorage.getItem('token')
    ) {
      const decoded: any = jwt_decode(localStorage.getItem('token'));
      const user = JSON.parse(localStorage.getItem('currentUser'));

      Bugsnag.setUser(
        decoded?.userId,
        decoded?.email || user?.email,
        `${decoded?.firstName || ''} ${decoded?.lastName || ''}`
      );
      Bugsnag.addMetadata('company', { name: 'REsimpli' });
    }
  }

  checkInternetStatus() {
    this.createOnline$().subscribe((isOnline) => {
      if (!isOnline) {
        this.ngxService.startLoader('NO-INTERNET');
      } else {
        this.ngxService.stopLoader('NO-INTERNET');
      }
      this._router.initialNavigation();
    });
  }

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }

  reload() {
    document.location.reload();
  }

  setTitle(val) {
    const location = val['urlAfterRedirects'].split('/');
    let url = '';

    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      const leadType = urlParams.get('leadType');
      if (leadType) url = `${leadType} Leads`;
      else {
        let pathName = window.location.pathname.split('/');
        if (pathName[2]) {
          url = this._utilities.capitalizeName(pathName[2]);
        } else {
          url = 'REsimpli Dashboard';
          this._titleService.setTitle(url);
          return;
        }
      }
    } else {
      const locationFirstIndex = location?.length ? location[1] : '';

      const locationSecondIndex =
        location?.length && location?.length > 2 ? location[2] : '';

      url = locationFirstIndex?.replaceAll('-', ' ')?.split('?')[0];

      if (url === 'public' || url === 'accounting' || url === 'users') {
        url = locationSecondIndex;

        if (locationSecondIndex !== 'notification-preference') {
          url = locationFirstIndex?.replaceAll('-', ' ')?.split('?')[0];
        }
      }

      if (url === 'kpis') {
        if (locationSecondIndex === 'appointments') {
          this._titleService.setTitle('Seller Appointment KPIs');

          return;
        }

        this._titleService.setTitle('KPIs');
        return;
      }

      if (url === 'account-list') {
        this._titleService.setTitle('Bank Accounts');
        return;
      }

      if (url === 'income-expense') {
        this._titleService.setTitle('Income Statement');
        return;
      }

      if (url === 'report-list') {
        this._titleService.setTitle('Reports');
        return;
      }

      if (url === 'auth') {
        url = `Auth - ${locationSecondIndex?.replaceAll('-', ' ')}`;
        this._titleService.setTitle(this._utilities.capitalizeName(url));
        return;
      }

      if (url === 'my scheduled sms') {
        this._titleService.setTitle('Scheduled SMS');
        return;
      }

      if (url === 'dashboard') {
        if (locationSecondIndex) {
          this._titleService.setTitle(
            this._utilities.capitalizeName(locationSecondIndex)
          );
        } else {
          this._titleService.setTitle('REsimpli Dashboard');
        }
        return;
      }

      if (url === 'profile' && locationSecondIndex) {
        url = locationSecondIndex;
      }
      if (url === 'sms') {
        this._titleService.setTitle('SMS Templates');
        return;
      }
      if (url === 'email template') {
        this._titleService.setTitle('Email Templates');
        return;
      }
    }

    let title = url
      ?.replace(/([A-Z]+)/g, ' $1')
      ?.replace(/([A-Z][a-z])/g, ' $1')
      ?.replace(/[^a-zA-Z0-9]/g, ' ');
    if (url == 'Createtemplate') {
      title = 'eSign Template';
    }
    this._titleService.setTitle(this._utilities.capitalizeName(title));
  }

  async twilioIncomingCallEvent(connection) {
    const myObject = connection?.customParameters.get('customData');
    const header = JSON.parse(myObject);
    const parameters = connection.parameters;
    const fromNumber = header.fromNumber.trim();
    const connectionParams = connection;
    if (this.openModel === false) {
      this.openModel = true;
      this._utilities.callType = 'incoming';
      let buyersList;
      let buyersDetails;
      let buyerLeadDetails = [];
      if (header.numTypeId === '2' || header.numTypeId === 2) {
        buyersList = await this.getBuyersDetailsSearch(
          fromNumber.replace('+1', '')
        );
        if (buyersList && buyersList.length > 0) {
          buyersDetails = await this.getBuyersDetails(buyersList[0]._id);
          if (buyersDetails) {
            if (
              buyersDetails.leadDetails.length === 0 &&
              buyersDetails.inventoryDetails.length > 0
            ) {
              buyerLeadDetails = buyersDetails.inventoryDetails;
            } else if (
              buyersDetails.leadDetails.length > 0 &&
              buyersDetails.inventoryDetails.length === 0
            ) {
              buyerLeadDetails = buyersDetails.leadDetails;
            } else {
              buyerLeadDetails = [
                ...buyersDetails.leadDetails,
                ...buyersDetails.inventoryDetails,
              ];
            }
          }
        }

        this.dialogRefs = this._dialog.open(
          IncomingCallDetailDailogsComponent,
          {
            width: '400px',
            panelClass: 're-incomingcall-modal',
            backdropClass: 'popup-Backdrop-Class-dialer-incoming',
            id: parameters.CallSid,
            data: {
              isCalling: false,
              parameters,
              buyersDetails: buyersDetails ? buyersDetails : undefined,
              buyerLeadDetails: buyerLeadDetails ? buyerLeadDetails : undefined,
              buyers: true,
              name: fromNumber,
              callerName: fromNumber,
              numberType: header.numTypeId,
              isTwilio: true,
              connectionParams,
            },
            disableClose: true,
          }
        );

        this.dialogRefs.afterClosed().subscribe(() => {
          if (this._utilities.numberOfCallsRunning === 0) {
            this.openModel = false;
          }
        });

        // setTimeout(() => {
        //   notification.close();
        // }, 3000);
      } else {
        let listStackingDetails = await this.getListStackSearch(
          fromNumber.replace('+1', '')
        );
        this._leadService
          .leadSearch({
            searchString: fromNumber.replace('+1', ''),
          })
          .subscribe((response: ResponseModel) => {
            let leadDetails = {};
            if (response.statusCode === 200) {
              leadDetails = response.data.items;
            }
            this.dialogRefs = this._dialog.open(
              IncomingCallDetailDailogsComponent,
              {
                width: '400px',
                panelClass: 're-incomingcall-modal',
                backdropClass: 'popup-Backdrop-Class-dialer-incoming',
                id: parameters.CallSid,
                data: {
                  isCalling: false,
                  parameters,
                  leadDetails,
                  buyers: false,
                  name: fromNumber,
                  callerName: fromNumber,
                  numberType: header.numTypeId,
                  listStackingDetails,
                  isTwilio: true,
                  connectionParams,
                },
                disableClose: true,
              }
            );

            this.dialogRefs.afterClosed().subscribe(() => {
              if (this._utilities.numberOfCallsRunning === 0) {
                this.openModel = false;
              }
            });
            // setTimeout(() => {
            //   notification.close();
            // }, 3000);
          });
      }
    }
  }
  twilioIncomingCallAccept(connection) {}

  twilioToken() {
    const user = localStorage.getItem('currentUser')
      ? JSON.parse(localStorage.getItem('currentUser'))
      : null;
    const token = localStorage.getItem('token');
    if (user && token) {
      this._twilioService.connectTwilio();
      this._twilioService.twilioIncomingEvent = new Subject();
      this._twilioService.twilioIncomingEvent.subscribe((response) => {
        if (response) {
          this.twilioIncomingCallEvent(response);
        }
      });
    }
  }
}
