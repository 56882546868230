import { Injectable } from '@angular/core';
import { catchError, finalize } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, throwError, from } from 'rxjs';

// UTILS
import { environment } from 'src/environments/environment';
import { BaseUrl } from '../utils/base-url-constants';

// SERVICES
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  baseUrl = environment.baseUrl;
  listStackingUrl = environment.listStackingUrl;
  public token = localStorage.getItem('token');

  private modulesList: Observable<any>;

  refreshActivityLog: any = new Subject();
  refreshContingency: any = new Subject();
  refreshInfo: any = new Subject();
  refreshLeadsGrid: any = new Subject();
  refreshDialerInfo: any = new Subject();
  refreshTodayDialerInfo: any = new Subject();
  refreshScheduledDialerInfo: any = new Subject();
  refreshDialerToggleDisplay: any = new Subject();
  refreshCallerToggleDisplay: any = new Subject();
  refreshTeamsInfo: any = new Subject();
  refreshRelated: any = new Subject();
  refreshListTask: any = new Subject();
  refreshDocumentList: any = new Subject();
  refreshDashboard: any = new Subject();
  refreshAppointments: any = new Subject();
  refreshInbox: any = new Subject();
  refreshUnresolvedAppts: any = new Subject();
  refreshApptsStats: any = new Subject();
  refreshSubHeaderPinnedCount: any = new Subject();
  refreshCommentFilterInfo: any = new Subject();
  refreshWebFormInfo: any = new Subject();
  refreshNotificationCount: any = new Subject();
  refreshPinnedCountActivity: any = new Subject();

  constructor(
    private http: HttpClient,
    private _loaderService: NgxUiLoaderService
  ) {}

  getUser = (): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'decode_token';

    return this.http.post(endpoint, {}).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getProjectStatus = (fieldData): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.projectStatus + 'list';

    return this.http.post(endpoint, fieldData).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  updateMainStatus = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.mainStatus + 'update-label';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getMainStatus = (fieldData): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.mainStatus + 'list';
    return this.http.post(endpoint, fieldData).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  /**
   * FUNCTION: GET ALL MODULES
   * @param {Object} fieldData
   * @returns ARRAY OF MODULES
   */
  getAllModules = (fieldData): Observable<any> => {
    // Restrict Multiple Calls For Module List
    const endpoint = this.baseUrl + BaseUrl.moduleUrl + 'list';
    if (this.modulesList) {
      return this.modulesList;
    }
    let list = this.http.post(endpoint, fieldData).pipe(
      catchError((err) => {
        return throwError(err);
      })
      // finalize(() => {
      //   // Clear the observable now that it has been listened to
      //   this.modulesList = null;
      // })
    );

    //Executes the http request immediately
    this.modulesList = from(list.toPromise());
    return this.modulesList;
  };

  unHoldCallFn = (fieldData): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkPlivo + 'unHoldCall';
    return this.http.post(endpoint, fieldData).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  holdCallFn = (fieldData): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkPlivo + 'holdCall';
    return this.http.post(endpoint, fieldData).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  checkLoader(id?) {
    return this._loaderService.hasRunningTask(true, id);
  }

  getFiles = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.fileUrl + 'list';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getFile = (data, isPublic?): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.sharedUrl;

    if (isPublic) {
      endpoint += `get-public-file?${isPublic?.type}ShareLink=${isPublic?.itemId}`;
    } else {
      endpoint += 'get-file';
    }

    return this.http.post(endpoint, data, { responseType: 'blob' }).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getMultipleFiles = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.sharedUrl;

    endpoint += 'get-multiplefiles';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  addFile = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.fileUrl + 'save';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  updateFile = (data, fileId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.fileUrl + 'update';

    if (fileId) {
      endpoint += `/${fileId}`;
    }

    return this.http.put(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteFile = (fileId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.fileUrl + 'delete';

    if (fileId) {
      endpoint += `/${fileId}`;
    }

    return this.http.delete(endpoint).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  moveFiles = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.fileUrl + 'move-to-other-folder';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getUploadUrl = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.sharedUrl + 's3-signed-url-put';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getFolders = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.folderUrl + 'list';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  addFolder = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.folderUrl + 'save';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  updateFolder = (data, folderId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.folderUrl + 'update';

    if (folderId) {
      endpoint += `/${folderId}`;
    }

    return this.http.put(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteFolder = (folderId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.folderUrl + 'delete';

    if (folderId) {
      endpoint += `/${folderId}`;
    }

    return this.http.delete(endpoint).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getLinks = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkUrl + 'list';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  addLink = (fieldData): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkUrl + 'save';
    return this.http.post(endpoint, fieldData).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  updateLink = (data, folderId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.linkUrl + 'update';

    if (folderId) {
      endpoint += `/${folderId}`;
    }

    return this.http.put(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteLink = (linkId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.linkUrl + 'delete';

    if (linkId) {
      endpoint += `/${linkId}`;
    }

    return this.http.delete(endpoint).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  changeLeadStatus = (moduleId, data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + 'statusUpdate';

    if (moduleId) {
      endpoint += `/${moduleId}`;
    }

    return this.http.put(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  changeWholesaleStatus = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.leadUrlV2Url + 'wholesale-pipeline/status-update';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  changeLeadInvestmentStatus = (moduleId, data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + 'investmentStatusUpdate';

    if (moduleId) {
      endpoint += `/${moduleId}`;
    }

    return this.http.put(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  changeInventoryStatus = (moduleId, data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.inventoryUrl + 'statusUpdate';

    if (moduleId) {
      endpoint += `/${moduleId}`;
    }

    return this.http.put(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  markAsFavorite = (moduleId, data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + 'favourite'; // TODO: SPELL CHECK

    if (moduleId) {
      endpoint += `/${moduleId}`;
    }

    return this.http.put(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  markAsVIP = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyerUrl + 'update_vip';

    return this.http.put(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  updatePreferred = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.vendorManagementV2Url + 'update-preferred';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  updateBlacklisted = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.vendorManagementV2Url + 'update-black-listed';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  saveQuestion = (data, val?): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.masterQuestionUrl;

    if (val) {
      endpoint += 'edit';
      data = { ...data, masterQuestionId: val?._id };
    } else {
      endpoint += 'save';
    }

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  saveSection = (data, val?): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.masterGroupUrl;

    if (val) {
      endpoint += 'edit';
      data = { ...data, masterGroupId: val?._id };
    } else {
      endpoint += 'save';
    }

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteCustomSection = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.masterGroupUrl + 'delete';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteCustomQuestion = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.masterQuestionUrl + 'delete';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getAvailability = (data, webLink?): Observable<any> => {
    let endpoint = this.baseUrl;

    if (!webLink) {
      endpoint += BaseUrl.appointmentV2Url + 'calendar-availability';
    } else {
      endpoint += BaseUrl.webFormUrl + 'availability';
    }

    return this.http
      .post(endpoint, data, webLink ? this.getRequestHeaders(webLink) : {})
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  };

  getStats = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.appointmentV2Url + 'stats';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getAppointments = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.appointmentV2Url + 'list';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  dismissAppointments = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.appointmentV2Url + 'dismiss';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  saveAppointment = (data, isEdit?, webLink?): Observable<any> => {
    let endpoint = this.baseUrl;

    if (webLink) {
      endpoint += BaseUrl.webFormUrl + 'appointmentSave';
    } else {
      endpoint += BaseUrl.appointmentV2Url;

      if (isEdit) {
        endpoint += `edit/${isEdit?._id}`;
      } else {
        endpoint += 'save';
      }
    }

    return this.http
      .post(endpoint, data, webLink ? this.getRequestHeaders(webLink) : {})
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  };

  updateAppointmentStatus = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.appointmentV2Url + 'update-status';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteAppointment = (id): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.appointmentV2Url + 'delete';

    if (id) {
      endpoint += `/${id}`;
    }

    return this.http.delete(endpoint).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getMapLatLng = (address): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.googleMapUrl + 'streetView';
    return this.http.post(endpoint, address).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getBuyNumbers = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkPlivo + 'fetch_numbers';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getLeadSource = (data, val?): Observable<any> => {
    let endpoint = this.baseUrl;

    if (val) {
      endpoint += BaseUrl.buyerWebFormUrl;
    } else {
      endpoint += BaseUrl.crmUrl;
    }

    endpoint += 'leadSource';

    return this.http.post(endpoint, data, this.getRequestHeaders(val)).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getCampaignList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.numberUrl + 'campaignlist';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  submitLeadSource = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkBuyNumber + 'save';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getAllNumType = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkBuyNumber + 'fetch_num_types';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  editNumber = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkBuyNumber + 'update';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };
  updateCallRecord = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkBuyNumber + 'updateCallRecord';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  buyFreeNumber = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkPlivo + 'buy_free_numbers';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteNumber = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyNumberV2Url + 'delete';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteRVM = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.rvmUrl + 'remove';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getManagenumbers = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkBuyNumber + 'list';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getManageBuyerNumbers = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkBuyNumber + 'number';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getBuyNumberCount = (): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkBuyNumber + 'getCount';
    return this.http.post(endpoint, {}).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getEmailList = (data, webLink?): Observable<any> => {
    let endpoint = this.baseUrl;

    if (!webLink) {
      endpoint += BaseUrl.mailTemplateUrl + 'list';
    } else {
      endpoint += BaseUrl.webFormUrl + 'mailTemplateList';
    }

    return this.http
      .post(endpoint, data, webLink ? this.getRequestHeaders(webLink) : {})
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  };

  addEmail = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.mailTemplateUrl + 'save';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  editEmail = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.mailTemplateUrl + 'edit';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteEmail = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.mailTemplateUrl + 'delete';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  copyEmail = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.mailTemplateUrl + 'copy';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteGlobalTag = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.globalTagsUrl + 'delete/' + data.id;

    return this.http.delete(endpoint).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  sendEmail = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.mailTemplateUrl + 'sendEmail';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  checkEmail = (email, isBulkEmail?): Observable<any> => {
    let endpoint = this.baseUrl;
    endpoint += BaseUrl.sendGridUrl + 'checkEmail';

    // if (isBulkEmail) {
    // } else {
    //   endpoint += BaseUrl.mailTemplateUrl + 'checkEmail';
    // }

    return this.http.post(endpoint, email).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  verifyEmail = (data, isBulkEmail?): Observable<any> => {
    let endpoint = this.baseUrl;
    endpoint += BaseUrl.sendGridUrl + 'verify';

    // if (isBulkEmail) {
    // } else {
    //   endpoint += BaseUrl.mailTemplateUrl + 'verify';
    // }

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  sendSms = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.communicationUrl + 'sendSms';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  sendBulkSms = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.smsUrl + 'bulk';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getAccessToken = (): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.googleCalendarUrl + 'get-access-token';

    return this.http.post(endpoint, {}).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  unlinkCalendar = (): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.googleCalendarUrl + 'unlink';

    return this.http.post(endpoint, {}).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getActivityLog = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.activityUrl + 'list';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  addActivityLog = (data, isPublic?): Observable<any> => {
    let endpoint = this.baseUrl;

    if (isPublic) {
      endpoint += BaseUrl.webFormUrl + 'activity/save';
    } else {
      endpoint += BaseUrl.activityUrl + 'save';
    }

    return this.http
      .post(endpoint, data, this.getRequestHeaders(isPublic))
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  };

  editActivityLogs = (data, id, isPublic?): Observable<any> => {
    let endpoint = this.baseUrl;

    if (isPublic) {
      endpoint += BaseUrl.webFormUrl + 'activity/update/' + id;
    } else {
      endpoint += BaseUrl.activityUrl + 'update/' + id;
    }

    return this.http.put(endpoint, data, this.getRequestHeaders(isPublic)).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteActivityLogs = (id): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.activityUrl + 'delete/' + id;
    return this.http.delete(endpoint).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  googleCalendarLink = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.googleCalendarUrl + 'link';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  setAvailability = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'setAvailability';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  allCountries(): Observable<any> {
    return this.http.get(
      'https://raw.githubusercontent.com/sagarshirbhate/Country-State-City-Database/master/Contries.json'
    );
  }

  getManageFlow = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.callFlowUrl + 'list';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };
  getGroupNumber = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.groupNumberV2Url + 'list';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };
  addGroupNumber = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.groupNumberV2Url + 'save';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };
  editGroupNumber = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.groupNumberV2Url + 'edit';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };
  groupNumberInfo = (lead: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.groupNumberV2Url + 'detail';

    return this.http.post(endpoint, lead).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteFlow = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.callFlowUrl + 'delete';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  updateDocument = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.esignUrl + 'docUpdate';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  addTags = (fieldData): Observable<any> => {
    let baseUrl = '';

    if (fieldData?.for == 'lead') {
      baseUrl = BaseUrl.leadUrl;
    } else if (fieldData?.for == 'inventory') {
      baseUrl = BaseUrl.inventoryUrl;
    } else if (fieldData?.for == 'buyer') {
      baseUrl = BaseUrl.buyerUrl;
    } else if (fieldData?.for == 'vendors') {
      baseUrl = BaseUrl.vendorManagementV2Url;
    }

    const endpoint =
      this.baseUrl + baseUrl + 'add-or-remove-tag/' + fieldData.leadId;

    return this.http
      .post(endpoint, {
        moduleId: fieldData.moduleId,
        tagIds: fieldData.tagIds,
        isTagAdd: fieldData.isTagAdd,
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  };

  getFlowList = (): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.callFlowUrl + 'list';

    return this.http.get(endpoint).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteSingleProperty = (data): Observable<any> => {
    const endpoint =
      this.listStackingUrl + BaseUrl.listStacking + 'deleteSingleProperties';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteCampaignProperty = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dialerCampaignUrl + 'campaignPropertyRemove';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getDirectMailList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.directMailTemplate + 'orderList';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getAnalyticList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.directMailTemplate + 'list';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getChartList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.directMailTemplate + 'reportFilter';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteProperty = (data): Observable<any> => {
    const endpoint =
      this.listStackingUrl + BaseUrl.listStacking + 'deleteProperty';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  updatemultitags = (data): Observable<any> => {
    const endpoint = this.listStackingUrl + BaseUrl.listStacking + 'updateTags';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  updatemultilists = (data): Observable<any> => {
    const endpoint =
      this.listStackingUrl + BaseUrl.listStacking + 'updatemultiplelists';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getEmailTemplates = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.templateUrl + 'get';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  saveEmailTemplates = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.templateUrl + 'save';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  editEmailTemplates = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.templateUrl + 'edit';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  sendBulkEmail = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.sendGridUrl + 'send';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  blockNumber = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.blockNumberUrl + 'blocknumber';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  checkBlockNumber = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.blockNumberUrl + 'checkblocknumber';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getBlockNumber = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.blockNumberUrl + 'getblocknumber';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  uploadFile = (endpoint, data): Observable<any> => {
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  checkCampaign = (data, type?): Observable<any> => {
    let endpoint = this.baseUrl;

    if (type) {
      endpoint += BaseUrl.smsUrl;
    } else {
      endpoint += BaseUrl.sendGridUrl;
    }

    endpoint += 'checkCampaign';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getRoles = (webFormLink?): Observable<any> => {
    let endpoint = this.baseUrl;

    if (webFormLink) {
      endpoint += BaseUrl.webFormUrl + 'roleList';
    } else {
      endpoint += BaseUrl.role + 'list';
    }

    return this.http
      .post(endpoint, {}, this.getRequestHeaders(webFormLink))
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  };

  appointmentForTaskPage = (fieldData): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.appointmentV2Url + 'task/list';
    return this.http.post(endpoint, fieldData).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getFilterTemplates = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.filterTemplateUrl + 'list';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };
  getCampaignListAll = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.numberUrl + 'getCampaignListAll';

    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  saveFilterTemplates = (data, type: string = 'save'): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.filterTemplateUrl + type;
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteFilterTemplate = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.filterTemplateUrl + 'delete';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getDialerFilterTemplates = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.dialerStatsUrl + 'filterList';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  saveDialerFilterTemplates = (
    data,
    type: string = 'filterSave'
  ): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.dialerStatsUrl + type;
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  deleteDialerFilterTemplate = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.dialerStatsUrl + 'filterDelete';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getManageNumbersV2 = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyNumberV2Url + 'list';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getManageNumbersListV2 = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dialerCampaignUrl + 'dialerBuyNumber';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getManageGroupNumbersV2 = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.groupNumberV2Url + 'groupList';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  //v2 apis

  getAllNumTypeV2 = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyNumberV2Url + 'fetch_num_types';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  submitCampaign = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyNumberV2Url + 'save';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getBuyNumberCountV2 = (): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyNumberV2Url + 'getCount';
    return this.http.post(endpoint, {}).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  updateAssignment = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyNumberV2Url + 'update';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  submitDlc = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkBuyNumber + 'saveDlcData';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getDLCForm = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkBuyNumber + 'getDLCForm';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  updateLeadConnect = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkBuyNumber + 'updateLeadConnect';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getEmailStatsById = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.sendGridV2Url + 'campaign-stats-by-submoduleId';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getSMSstatsById = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.smsUrl + 'campaign-stats-by-submoduleId';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  getnumbers = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.linkBuyNumber + 'lists';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  //GLOBAL SEARCH API
  getGlobalSearchResult = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.globalSearch;
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  //Notification
  getAllNotifications = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.notificationUrl + 'list';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  markAllReadUnread = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.notificationUrl + 'isReadMessage';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  //FA Auto Data
  faAutoAdress = (data): Observable<any> => {
    const endpoint =
      this.listStackingUrl + BaseUrl.listStacking + 'autocompletePropertyList';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  faAutoAddressPublic = (data?, type?): Observable<any> => {
    const endpoint =environment.uspsUrl + 'autocompletePublic';
    return this.http.post(endpoint, data, this.getRequestHeaders(type)).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  markNotificationAsRead = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.notificationUrl + 'isReadMessage';
    return this.http.post(endpoint, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  };

  // SET HEADERS
  protected getRequestHeaders(val?): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    let headers;
    let obj = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };

    if (val) {
      obj['webFormLink'] = localStorage.getItem('webFormLink') || val;
    }
    if (val == 'isWebForm') {
      obj['x-api-key'] =
        '6xOOYtXtJerUCybZFyyMp3kgped6ZUuFqJX3VZ4BU4Tt3l2U63EjrPSvXVPKFuJG';
    }

    headers = new HttpHeaders(obj);
    return { headers: headers };
  }
}
