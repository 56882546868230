<div *ngIf="isModelType">
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between"
        style="display: block !important"
      >
        {{ data?.isWithoutLogin ? "Re-activate Account" : "Billing" }}
      </h2>
      <span mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>

  <mat-dialog-content class="mat-typography">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 re-information">
          <p class="re-text" *ngIf="data?.isWithoutLogin">
            Your account cancelled. please re-activate your account.
          </p>
          <p class="re-text">
            Charge Amount : $
            {{ _utilities.chargeSummary?.total | number : "1.2-2" }}
          </p>
          <div class="re-table-main">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <th>Details</th>
                  <th>Total</th>
                  <th>Cost/{{ _utilities?.chargeSummary?.isYearlyPlan ? "Year" : "Month" }}</th>
                  <th>Sub Total</th>
                  <th
                    *ngIf="
                      _utilities.chargeSummary?.discountData.discountPer > 0
                    "
                  >
                    Discount
                  </th>
                  <th>Total</th>
                </tr>
                <tr *ngIf="_utilities.chargeSummary?.planData?.total > 0">
                  <td style="text-align: left">Main User</td>
                  <td style="text-align: right">1</td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.planData?.cost
                        | number : "1.2-2"
                    }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.planData?.subTotal
                        | number : "1.2-2"
                    }}
                  </td>
                  <td
                    *ngIf="
                      _utilities.chargeSummary?.discountData.discountPer > 0
                    "
                  >
                    {{ _utilities.chargeSummary?.discountData.discountPer }}%
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.planData?.total
                        | number : "1.2-2"
                    }}
                  </td>
                </tr>
                <tr *ngIf="_utilities.chargeSummary?.subUserData?.total > 0">
                  <td style="text-align: left">Sub Users</td>
                  <td style="text-align: right">
                    {{ _utilities.chargeSummary?.subUserData?.count }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.subUserData?.cost
                        | number : "1.2-2"
                    }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.subUserData?.subTotal
                        | number : "1.2-2"
                    }}
                  </td>
                  <td
                    *ngIf="
                      _utilities.chargeSummary?.discountData.discountPer > 0
                    "
                  >
                    {{ _utilities.chargeSummary?.discountData.discountPer }}%
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.subUserData?.total
                        | number : "1.2-2"
                    }}
                  </td>
                </tr>
                <tr *ngIf="_utilities.chargeSummary?.marketData?.total > 0">
                  <td style="text-align: left">Markets</td>
                  <td style="text-align: right">
                    {{ _utilities.chargeSummary?.marketData?.count }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.marketData?.cost
                        | number : "1.2-2"
                    }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.marketData?.subTotal
                        | number : "1.2-2"
                    }}
                  </td>
                  <td
                    *ngIf="
                      _utilities.chargeSummary?.discountData.discountPer > 0
                    "
                  >
                    N/A
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.marketData?.total
                        | number : "1.2-2"
                    }}
                  </td>
                </tr>
                <tr
                  *ngIf="_utilities.chargeSummary?.listStackingData?.total > 0"
                >
                  <td style="text-align: left">List Stacking</td>
                  <td style="text-align: right">1</td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.listStackingData?.cost
                        | number : "1.2-2"
                    }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.listStackingData?.subTotal
                        | number : "1.2-2"
                    }}
                  </td>
                  <td
                    *ngIf="
                      _utilities.chargeSummary?.discountData.discountPer > 0
                    "
                  >
                    N/A
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.listStackingData?.total
                        | number : "1.2-2"
                    }}
                  </td>
                </tr>
                <tr *ngIf="_utilities.chargeSummary?.numberData?.total > 0">
                  <td style="text-align: left">Additional Phone Numbers</td>
                  <td style="text-align: right">
                    {{ _utilities.chargeSummary?.numberData?.count }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.numberData?.cost
                        | number : "1.2-2"
                    }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.numberData?.subTotal
                        | number : "1.2-2"
                    }}
                  </td>
                  <td
                    *ngIf="
                      _utilities.chargeSummary?.discountData.discountPer > 0
                    "
                  >
                    N/A
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.numberData?.total
                        | number : "1.2-2"
                    }}
                  </td>
                </tr>
                <tr
                  *ngIf="
                    _utilities.chargeSummary?.subscriberDriveData?.total > 0
                  "
                >
                  <td style="text-align: left">
                    Driving for Dollar subscription
                  </td>
                  <td style="text-align: right">1</td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.subscriberDriveData?.cost
                        | number : "1.2-2"
                    }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.subscriberDriveData?.subTotal
                        | number : "1.2-2"
                    }}
                  </td>
                  <td
                    *ngIf="
                      _utilities.chargeSummary?.discountData.discountPer > 0
                    "
                  >
                    N/A
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.subscriberDriveData?.total
                        | number : "1.2-2"
                    }}
                  </td>
                </tr>
                <tr *ngIf="_utilities.chargeSummary?.leadDialerData?.total > 0">
                  <td style="text-align: left">Lead Dialer</td>
                  <td style="text-align: right">
                    {{ _utilities.chargeSummary?.leadDialerData?.count }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.leadDialerData?.cost
                        | number : "1.2-2"
                    }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.leadDialerData?.subTotal
                        | number : "1.2-2"
                    }}
                  </td>
                  <td
                    *ngIf="
                      _utilities.chargeSummary?.discountData.discountPer > 0
                    "
                  >
                    N/A
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.leadDialerData?.total
                        | number : "1.2-2"
                    }}
                  </td>
                </tr>
                <tr
                  *ngIf="
                    _utilities.chargeSummary?.listStackingDialerData?.total > 0
                  "
                >
                  <td style="text-align: left">List Stacking Dialer</td>
                  <td style="text-align: right">
                    {{
                      _utilities.chargeSummary?.listStackingDialerData?.count
                    }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.listStackingDialerData?.cost
                        | number : "1.2-2"
                    }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.listStackingDialerData?.subTotal
                        | number : "1.2-2"
                    }}
                  </td>
                  <td
                    *ngIf="
                      _utilities.chargeSummary?.discountData.discountPer > 0
                    "
                  >
                    N/A
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.listStackingDialerData?.total
                        | number : "1.2-2"
                    }}
                  </td>
                </tr>
                <tr
                  *ngIf="
                    _utilities.chargeSummary?.advanceListStackingDialerData
                      ?.total > 0
                  "
                >
                  <td style="text-align: left">Advance List Stacking Dialer</td>
                  <td style="text-align: right">
                    {{
                      _utilities.chargeSummary?.advanceListStackingDialerData
                        ?.count
                    }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.advanceListStackingDialerData
                        ?.cost | number : "1.2-2"
                    }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.advanceListStackingDialerData
                        ?.subTotal | number : "1.2-2"
                    }}
                  </td>
                  <td
                    *ngIf="
                      _utilities.chargeSummary?.discountData.discountPer > 0
                    "
                  >
                    N/A
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.advanceListStackingDialerData
                        ?.total | number : "1.2-2"
                    }}
                  </td>
                </tr>
                <tr *ngIf="_utilities.chargeSummary?.dlcData?.total > 0">
                  <td style="text-align: left">10 DLC Charge</td>
                  <td style="text-align: right">
                    {{ _utilities.chargeSummary?.dlcData?.count }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.dlcData?.cost | number : "1.2-2"
                    }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.dlcData?.subTotal
                        | number : "1.2-2"
                    }}
                  </td>
                  <td
                    *ngIf="
                      _utilities.chargeSummary?.discountData.discountPer > 0
                    "
                  >
                    N/A
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.dlcData?.total
                        | number : "1.2-2"
                    }}
                  </td>
                </tr>
                <tr
                  *ngIf="_utilities.chargeSummary?.callerNumberData?.total > 0"
                >
                  <td style="text-align: left">Spam Check Surcharge</td>
                  <td style="text-align: right">
                    {{ _utilities.chargeSummary?.callerNumberData?.count }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.callerNumberData?.cost
                        | number : "1.2-2"
                    }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.callerNumberData?.subTotal
                        | number : "1.2-2"
                    }}
                  </td>
                  <td
                    *ngIf="
                      _utilities.chargeSummary?.discountData.discountPer > 0
                    "
                  >
                    N/A
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.callerNumberData?.total
                        | number : "1.2-2"
                    }}
                  </td>
                </tr>
                <tr *ngIf="_utilities.chargeSummary?.inAppAnsData?.total > 0">
                  <td style="text-align: left">In App Answer Charge</td>
                  <td style="text-align: right">
                    {{ _utilities.chargeSummary?.inAppAnsData?.count }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.inAppAnsData?.cost
                        | number : "1.2-2"
                    }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.inAppAnsData?.subTotal
                        | number : "1.2-2"
                    }}
                  </td>
                  <td
                    *ngIf="
                      _utilities.chargeSummary?.discountData.discountPer > 0
                    "
                  >
                    N/A
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.inAppAnsData?.total
                        | number : "1.2-2"
                    }}
                  </td>
                </tr>
                <tr *ngIf="_utilities.chargeSummary?.websiteData?.total > 0">
                  <td style="text-align: left">Website</td>
                  <td style="text-align: right">
                    {{ _utilities.chargeSummary?.websiteData?.count }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.websiteData?.cost
                        | number : "1.2-2"
                    }}
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.websiteData?.subTotal
                        | number : "1.2-2"
                    }}
                  </td>
                  <td
                    *ngIf="
                      _utilities.chargeSummary?.discountData.discountPer > 0
                    "
                  >
                    N/A
                  </td>
                  <td style="text-align: right">
                    $
                    {{
                      _utilities.chargeSummary?.websiteData?.total
                        | number : "1.2-2"
                    }}
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left" colspan="3">
                    Total {{ _utilities?.chargeSummary?.isYearlyPlan ? "Yearly" : "Monthly" }} Cost
                  </td>
                  <td style="text-align: right">
                    $
                    {{ _utilities.chargeSummary?.subTotal | number : "1.2-2" }}
                  </td>
                  <td
                    *ngIf="
                      _utilities.chargeSummary?.discountData.discountPer > 0
                    "
                  ></td>
                  <td style="text-align: right">
                    $ {{ _utilities.chargeSummary?.total | number : "1.2-2" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-12 re-information">
          <div class="row">
            <div class="col-12">
              <h4 class="re-payment-title">PAY USING SAVED CARDS</h4>
              <a href="javascript:;" class="btn re-newcard" (click)="addCard()"
                >Add New Card</a
              >
            </div>
            <div class="col-12">
              <div class="radiolist" *ngIf="cardList.length > 0">
                <label class="radiobtn" *ngFor="let card of cardList">
                  <input
                    type="radio"
                    name="radioa1"
                    (click)="onCardSelectAction(card)"
                  />
                  <span class="checkmark"></span>
                  {{ card.cardDetail }}
                  <img class="img" src="/assets/images/{{ card.type }}" />
                </label>
              </div>
              <div *ngIf="cardList.length == 0">
                <p>{{ cardMsg }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="text-center" style="padding-top: 14px !important">
    <button mat-raised-button [mat-dialog-close]="false" class="re-cancelbtn">
      Cancel
    </button>
    <button
      (click)="submit()"
      mat-raised-button
      color="primary"
      cdkFocusInitial
    >
      Submit
    </button>
  </mat-dialog-actions>
</div>
<div *ngIf="!isModelType">
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between"
        style="display: block !important"
      >
        Billing
      </h2>
      <span mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>

  <mat-dialog-content class="mat-typography">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 re-information">
          <p class="re-text">
            Charge Amount : $ {{ _utilities.chargeSummary?.total }}
          </p>
        </div>

        <div class="col-12 re-information"></div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="text-center" style="padding-top: 14px !important">
    <button mat-raised-button [mat-dialog-close]="false" class="re-cancelbtn">
      Cancel
    </button>
    <button
      (click)="paymentProceed()"
      mat-raised-button
      color="primary"
      cdkFocusInitial
    >
      Proceed
    </button>
  </mat-dialog-actions>
</div>
