import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

//SERVICES
import { ListStackingService } from 'src/app/providers/list-stacking/list-stacking.service';
import { DialerService } from 'src/app/providers/dialer/dialer.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import {
  ListResponseModel,
  ResponseModel,
} from 'src/app/utils/models/response';
import { SharedService } from 'src/app/shared/shared.service';

//UTILS
import { MessageConstant } from 'src/app/utils/message-constant';

@Component({
  selector: 'app-add-phone-dialog',
  templateUrl: './add-phone-dialog.component.html',
  styleUrls: ['./add-phone-dialog.component.scss'],
})
export class AddPhoneDialogComponent implements OnInit {
  currentData: any;
  listslistData: any;
  tagsData: any;
  phoneMsg: any[] = ['', '', '', '', '', '', '', '', '', ''];
  allPhoneObject: any[] = [];
  noDataAvailable: string = 'No Data Available';
  promises: any[] = [];
  allPhoneStatus: any[] = [];
  searchedPhoneStatus: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<AddPhoneDialogComponent>,
    private _listStackingService: ListStackingService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _dialerService: DialerService,
    private _sharedService: SharedService,
    public router: Router
  ) {
    this.getPermission();
  }

  ngOnInit(): void {}

  getPermission() {
    this._loaderService.start();
    this._listStackingService.getListStackingPermission({}).subscribe(
      (response) => {
        if (response?.statusCode) {
          if (response?.data) {
            this.getListDetail();
          } else {
            this._loaderService.stop();
            this.router.navigate(['/permission-denied']);
          }
        }
      },
      (err) => {
        this.router.navigate(['/permission-denied']);
      }
    );
  }

  getListDetail() {
    if (this.data.details) {
      this.promises = [];
      this.promises.push(this.getStatus());
      this.currentData = this.data.details;
      Promise.all(this.promises)
        .then((result) => {
          this._loaderService.stop();
          this.allPhoneObject = [];
          let statusVal, pType, finalStatusVal, statusIndex;
          for (let i = 0; i < 10; i++) {
            if (this.currentData[`Phone_${i + 1}`]) {
              statusVal = [];
              pType = '';
              finalStatusVal = [];

              if (
                this.currentData[`Phone_${i + 1}_status`] &&
                this.currentData[`Phone_${i + 1}_status`].length
              ) {
                statusVal =
                  typeof this.currentData[`Phone_${i + 1}_status`] == 'string'
                    ? this.currentData[`Phone_${i + 1}_status`].split(',')
                    : this.currentData[`Phone_${i + 1}_status`];
              }

              statusVal.filter((e) => {
                statusIndex = this.allPhoneStatus.findIndex(
                  (x) => x.name === e
                );

                if (statusIndex >= 0) {
                  finalStatusVal.push(this.allPhoneStatus[statusIndex]);
                }
              });

              if (this.currentData[`Phone_${i + 1}_type`]) {
                const phoneTypes = ['Mobile', 'Landline', 'Voip'];
                if (
                  !phoneTypes.includes(this.currentData[`Phone_${i + 1}_type`])
                ) {
                  pType = 'Other';
                } else {
                  pType = this.currentData[`Phone_${i + 1}_type`];
                }
              }

              this.allPhoneObject.push({
                phone: this.currentData[`Phone_${i + 1}`],
                phoneType: pType,
                phoneStatusVal: finalStatusVal,
                savedPhoneStatus: finalStatusVal,
                litigator: this.currentData[`Phone_${i + 1}_IsLitigator`] || '',
                dnc: this.currentData[`Phone_${i + 1}_DNC`] || '',
              });
            }
          }
        })
        .catch((err) => {
          this._loaderService.stop();
        });
    }
  }
  getStatus() {
    const promise = new Promise((resolve, reject) => {
      this._listStackingService.getPhoneStatus().subscribe(
        (response) => {
          if (response.code == 200) {
            this.allPhoneStatus = response?.message?.phoneStatus || [];
            this.allPhoneStatus = this.allPhoneStatus.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            return resolve(this.allPhoneStatus);
          }
        },
        (err) => {
          return reject(err);
        }
      );
    });
    return promise;
  }

  clearListItem(autocomplete: any) {
    autocomplete.value = '';
    autocomplete.handleDropdownClick();
  }

  phoneStatusDownClick(autocomplete: any) {
    autocomplete.handleDropdownClick(1);
  }

  searchPhoneStatus(searchStr, index) {
    let tempAr = [];
    this.allPhoneObject[index]?.phoneStatusVal.filter((element) => {
      tempAr.push(element.name);
    });
    setTimeout(() => {
      this.searchedPhoneStatus = this.allPhoneStatus.filter((e) => {
        if (
          e.name.toUpperCase().includes(searchStr.toUpperCase()) &&
          tempAr.indexOf(e.name) == -1
        )
          return e;
      });
    }, 500);
  }

  emitRemoveData(e, index) {
    if (e.name == 'Attached with Lead') {
      const statusIndex = this.allPhoneObject[
        index
      ]?.savedPhoneStatus.findIndex((x) => x.name === e.name);
      if (statusIndex >= 0) this.allPhoneObject[index]?.phoneStatusVal.push(e);
    }
  }

  checkPhone(mode) {
    let res;
    mode = parseInt(mode);
    res =
      this.allPhoneObject[mode]['phone'].length > 0 &&
      this.allPhoneObject[mode]['phone'].length != 10
        ? false
        : true;
    let phoneValidMsg = 'Please enter valid Phone Number';
    this.phoneMsg[mode] = res ? '' : this._toastrService.error(phoneValidMsg);

    if (res && this.allPhoneObject[mode]['phone'].length) {
      this._loaderService.start();
      this._listStackingService
        .findPhoneStatus({
          phoneNumber: this.allPhoneObject[mode]['phone'],
        })
        .subscribe(
          (response) => {
            this._loaderService.stop();
            if (response.code == 200) {
              let res = response?.message?.phoneStatus || [];
              let prevPhoneStatus = [];
              prevPhoneStatus = this.allPhoneStatus.filter((e) => {
                return res.includes(e.name);
              });
              this.allPhoneObject[mode]['phoneStatusVal'] = prevPhoneStatus;
              this.allPhoneObject[mode]['savedPhoneStatus'] = prevPhoneStatus;
            }
          },
          (err) => {
            this._loaderService.stop();
          }
        );
    }
  }

  addPhoneNumber() {
    this.allPhoneObject.push({
      phone: '',
      phoneType: '',
      phoneStatusVal: [],
      litigator: '',
      dnc: '',
    });
  }

  removePhoneNumber(i) {
    this.allPhoneObject.splice(i, 1);
  }

  addKey() {
    let ValidateFlag = 1;
    let phoneMsg = 'Invalid value of ';
    let phoneFlag = 1;
    let id = this.currentData._id;
    let reqPhoneMsg = '',
      reqPhoneTypeMsg = '',
      phoneTypeFlag = 1;
    this.allPhoneObject.filter((x, i) => {
      if (x.phone && x.phone.length > 0 && x.phone.length < 10) {
        phoneFlag = 0;
        phoneMsg += ` Phone ${i + 1},`;
      } else if (!x.phone) {
        phoneFlag = -1;
        if (reqPhoneMsg.length == 0) reqPhoneMsg = 'Please fill ';
        reqPhoneMsg += ` Phone ${i + 1},`;
      }
      if (!x.phoneType) {
        phoneTypeFlag = 0;
        if (reqPhoneTypeMsg.length == 0) reqPhoneTypeMsg = 'Please select ';
        reqPhoneTypeMsg += ` Phone Type ${i + 1},`;
      }
    });

    phoneMsg = phoneMsg.slice(0, -1);
    if (phoneFlag == 0) {
      ValidateFlag = 0;
      this._toastrService.error(phoneMsg);
    }
    if (phoneFlag == -1) {
      ValidateFlag = 0;
      reqPhoneMsg = reqPhoneMsg.slice(0, -1);
      this._toastrService.error(reqPhoneMsg);
    }
    if (phoneTypeFlag == 0) {
      ValidateFlag = 0;
      reqPhoneTypeMsg = reqPhoneTypeMsg.slice(0, -1);
      this._toastrService.error(reqPhoneTypeMsg);
    }
    let Property_Street_Address = this.currentData?.Property_Street_Address?.toUpperCase();
    let Property_Street_Address_Trim = Property_Street_Address?.replace(
      /\s/g,
      ''
    );
    let Mailing_Street_Address = this.currentData?.Mailing_Street_Address?.toUpperCase();
    Mailing_Street_Address = Mailing_Street_Address?.replace(/\s/g, '');
    let propertycheck = Property_Street_Address_Trim.localeCompare(
      Mailing_Street_Address
    );

    let Property_City = this.currentData?.Property_City?.toUpperCase();
    let Property_City_Trim = Property_City?.replace(/\s/g, '');
    let Mailing_City = this.currentData?.Mailing_City?.toUpperCase();
    Mailing_City = Mailing_City?.replace(/\s/g, '');
    let citycheck = Property_City_Trim?.localeCompare(Mailing_City);

    let Property_State = this.currentData?.Property_State?.toUpperCase();
    let Property_State_Trim = Property_State?.replace(/\s/g, '');
    let Mailing_State = this.currentData?.Mailing_State?.toUpperCase();
    Mailing_State = Mailing_State?.replace(/\s/g, '');
    let statecheck = Property_State_Trim?.localeCompare(Mailing_State);

    let Property_Zip_Code = this.currentData?.Property_Zip_Code?.toUpperCase();
    let Property_Zip_Code_Trim = Property_Zip_Code?.replace(/\s/g, '');
    let Mailing_Zip_Code = this.currentData?.Mailing_Zip_Code?.toUpperCase();
    Mailing_Zip_Code = Mailing_Zip_Code?.replace(/\s/g, '');
    let blankPropertyMsg = '';
    if (Property_Street_Address?.length == 0) {
      blankPropertyMsg = 'Property Street Address,';
      ValidateFlag = 0;
    }
    if (Property_City?.length == 0) {
      blankPropertyMsg = 'Property City,';
      ValidateFlag = 0;
    }
    if (Property_State?.length == 0) {
      blankPropertyMsg = 'Property State,';
      ValidateFlag = 0;
    }
    if (Property_Zip_Code?.length == 0) {
      blankPropertyMsg = 'Property Zip Code,';
      ValidateFlag = 0;
    }
    if (!this.currentData?.Property_County_Name) {
      blankPropertyMsg = 'Property County Name,';
      ValidateFlag = 0;
    }
    if (Property_Zip_Code_Trim?.length != 5) {
      this._toastrService.error('Invalid Property Zip Code');
      ValidateFlag = 0;
    }
    blankPropertyMsg = blankPropertyMsg.slice(0, -1);
    if (ValidateFlag == 0 && blankPropertyMsg?.length) {
      blankPropertyMsg =
        blankPropertyMsg +
        ' is required. Please first update property then add phone number';
      this._toastrService.error(blankPropertyMsg);
    }
    if (ValidateFlag == 0) {
      return;
    }

    let allStatus;
    for (let i = 0; i < 10; i++) {
      allStatus = [];
      if (this.allPhoneObject[i]) {
        this.allPhoneObject[i]?.phoneStatusVal.filter((e) => {
          allStatus.push(e.name);
        });
        this.currentData[`Phone_${i + 1}`] = this.allPhoneObject[i][
          'phone'
        ].replace(/\D+/g, '');
        if (this.currentData[`Phone_${i + 1}`].length > 10)
          this.currentData[`Phone_${i + 1}`] = this.currentData[
            `Phone_${i + 1}`
          ].substr(1, 10);
        this.currentData[`Phone_${i + 1}_status`] = allStatus;
        this.currentData[`Phone_${i + 1}_type`] = this.allPhoneObject[i][
          'phoneType'
        ];
        this.currentData[`Phone_${i + 1}_DNC`] = this.allPhoneObject[i]['dnc'];
        this.currentData[`Phone_${i + 1}_IsLitigator`] = this.allPhoneObject[i][
          'litigator'
        ];
      } else {
        this.currentData[`Phone_${i + 1}`] = '';
        this.currentData[`Phone_${i + 1}_status`] = [];
        this.currentData[`Phone_${i + 1}_type`] = '';
        this.currentData[`Phone_${i + 1}_DNC`] = '';
        this.currentData[`Phone_${i + 1}_IsLitigator`] = '';
      }
    }

    this._loaderService.start();
    this._listStackingService.editProperty(this.currentData, id).subscribe(
      (data: any) => {
        if (data.code == 200) {
          this._loaderService.stop();
          this._toastrService.success(MessageConstant.phoneNumberUpdateSuccess);
          if (this.data.activityAdd) {
            let activityComment = [{ name: 'Property updated Successfully' }];
            let myobj = {
              subModuleId: [id],
              comment: activityComment,
              activityType: 16,
              isPhoneActivity: true,
            };
            this._dialerService.addActivityList(myobj).subscribe(
              (response: ResponseModel) => {
                this._loaderService.stop();
                if (response.statusCode == 200) {
                  this._sharedService.refreshActivityLog.next(true);
                }
              },
              (err) => {
                this._loaderService.stop();
                console.log(err);
              }
            );
          }
          this.dialog.close(true);
        } else {
          this._loaderService.stop();
          this._toastrService.error(data.message);
        }
      },
      (err) => {
        this._loaderService.stop();
      }
    );
  }
}
