import {
  Component,
  OnInit,
  Inject,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessageConstant } from 'src/app/utils/message-constant';
import { ResponseModel } from '../../../utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { Router } from '@angular/router';
import { SOWService } from './../../../providers/sow/sow.service';
import { ContractorService } from './../../../providers/contractor/contractor.service';
import { environment } from 'src/environments/environment';
import { UploadOutput, UploadInput } from 'ngx-uploader';
import { directMailConstant } from '../../../utils/direct-mail-constant';
import { BaseUrl } from 'src/app/utils/base-url-constants';
@Component({
  selector: 'app-sow-dailog',
  templateUrl: './sow-dailog.component.html',
  styleUrls: ['./sow-dailog.component.scss'],
})
export class SowDailogComponent implements OnInit {
  @ViewChild('uploader') uploaderInput: ElementRef;

  allCategoryData: any[] = directMailConstant.allCategoryData;
  subSubCheckedVal: any = {};
  dateTime: any;
  subSubQuantityVal: any = {};
  subSubUPriceVal: any = {};
  subSubUoMVal: any = {};
  subSubDescVal: any = {};
  sowDetail: any = {};
  itemPriced: string = 'Lumpsum';
  sowName: string = '';
  sowSDate: Date;
  sowEDate: Date;
  sowEstimate: string = '';
  sowContractor: string = '';
  sowContractorName: string = '';
  sowMaterialBy: string = 'Contractor';
  contractorList: any[] = [];
  sowTotal: any[] = [];
  fileCatI: number;
  fileSubCatI: number;
  fileSubSubCatI: number;
  files: any;
  uploadFileName: string = '';
  addedMinDateValue: any;
  s3Url: string = environment.newS3Url;
  isEdit: boolean = false;
  sowNameMsg: string = '';
  sowSDateMsg: string = '';
  sowEDateMsg: string = '';
  sowEstimateMsg: string = '';
  sowContractorMsg: string = '';
  uploadInput: EventEmitter<UploadInput>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _sowDialogRef: MatDialogRef<SowDailogComponent>,
    public router: Router,
    public _sowService: SOWService,
    private _loaderService: NgxUiLoaderService,
    public _toastrService: ToastrService,
    private _contractorService: ContractorService
  ) {
    this.uploadInput = new EventEmitter<UploadInput>();
  }

  ngOnInit(): void {
    this.getContractor();
    if (typeof this.data?.id != 'undefined') {
      this.isEdit = true;
      this.sowDetail = this.data?.details;
      this.sowName = this.sowDetail?.sowname;
      this.sowSDate = new Date(this.sowDetail?.startdate);
      this.sowEDate = new Date(this.sowDetail?.enddate);
      this.sowEstimate = this.sowDetail?.estimation;
      this.itemPriced = this.sowDetail?.itemprice;
      this.sowContractor = this.sowDetail?.contractorid;
      this.sowContractorName = this.sowDetail?.contractorname;
      this.sowMaterialBy = this.sowDetail?.materialpaid;
      this.updateCategory();
    }
    this.setSubSubChecked();
  }

  setSubSubChecked() {
    let key1, key2, key3;
    for (let i = 0; i < this.allCategoryData.length; i++) {
      key1 = this.allCategoryData[i]._id;
      if (this.allCategoryData[i]?.subCategory) {
        for (let j = 0; j < this.allCategoryData[i].subCategory.length; j++) {
          key2 = this.allCategoryData[i].subCategory[j]._id;
          if (this.isEdit) {
            let tempAr = [];
            for (
              let k = 0;
              k < this.allCategoryData[i].subCategory[j].subSubCategory.length;
              k++
            ) {
              key3 = this.allCategoryData[i].subCategory[j].subSubCategory[k]
                ._id;
              if (
                this.allCategoryData[i].subCategory[j].subSubCategory[k].status
              )
                tempAr.push(
                  this.allCategoryData[i].subCategory[j].subSubCategory[k]._id
                );
              this.subSubQuantityVal[key1 + '_' + key2 + '_' + key3] =
                this.allCategoryData[i].subCategory[j].subSubCategory[k]
                  .quantity > 0
                  ? this.allCategoryData[i].subCategory[j].subSubCategory[k]
                      .quantity
                  : '';
              this.subSubUoMVal[
                key1 + '_' + key2 + '_' + key3
              ] = this.allCategoryData[i].subCategory[j].subSubCategory[
                k
              ].measurement;
              this.subSubUPriceVal[key1 + '_' + key2 + '_' + key3] =
                this.allCategoryData[i].subCategory[j].subSubCategory[k]
                  .unitprice > 0
                  ? this.allCategoryData[i].subCategory[j].subSubCategory[k]
                      .unitprice
                  : '';
              this.subSubDescVal[
                key1 + '_' + key2 + '_' + key3
              ] = this.allCategoryData[i].subCategory[j].subSubCategory[
                k
              ].description;
            }
            this.subSubCheckedVal[key1 + '_' + key2] = tempAr;
          } else {
            this.subSubCheckedVal[key1 + '_' + key2] = [];
            for (
              let k = 0;
              k < this.allCategoryData[i].subCategory[j].subSubCategory.length;
              k++
            ) {
              key3 = this.allCategoryData[i].subCategory[j].subSubCategory[k]
                ._id;
              this.subSubQuantityVal[key1 + '_' + key2 + '_' + key3] = '';
              this.subSubUoMVal[key1 + '_' + key2 + '_' + key3] = 'Each';
              this.subSubUPriceVal[key1 + '_' + key2 + '_' + key3] = '';
              this.subSubDescVal[key1 + '_' + key2 + '_' + key3] = '';
            }
          }
        }
      }
    }
  }

  setAddedToDate() {
    this.sowSDateMsg = '';
    this.addedMinDateValue = this.sowSDate;
    if (this.sowSDate > this.sowEDate) {
      this.sowEDate = undefined;
    }
  }

  clearEndDate() {
    this.sowEDate = undefined;
    this.addedMinDateValue = undefined;
  }

  resetEndDateMsg() {
    this.sowEDateMsg = '';
  }

  hasChecked(val, catId, subCatId) {
    if (this.subSubCheckedVal[catId + '_' + subCatId]) {
      if (this.subSubCheckedVal[catId + '_' + subCatId].includes(val))
        return true;
      else return false;
    } else {
      return false;
    }
  }

  resetTotal() {
    this.sowEstimate = '';
    let key1, key2, key3;
    for (let i = 0; i < this.allCategoryData.length; i++) {
      key1 = this.allCategoryData[i]._id;
      for (let j = 0; j < this.allCategoryData[i].subCategory.length; j++) {
        key2 = this.allCategoryData[i].subCategory[j]._id;
        for (
          let k = 0;
          k < this.allCategoryData[i].subCategory[j].subSubCategory.length;
          k++
        ) {
          key3 = this.allCategoryData[i].subCategory[j].subSubCategory[k]._id;
          this.subSubQuantityVal[key1 + '_' + key2 + '_' + key3] = '';
          this.subSubUoMVal[key1 + '_' + key2 + '_' + key3] = 'Each';
          this.subSubUPriceVal[key1 + '_' + key2 + '_' + key3] = '';
          this.allCategoryData[i].subCategory[j].subSubCategory[k].quantity = 0;
          this.allCategoryData[i].subCategory[j].subSubCategory[
            k
          ].unitprice = 0;
          this.allCategoryData[i].subCategory[j].subSubCategory[k].measurement =
            'Each';
          this.allCategoryData[i].subCategory[j].subSubCategory[k].total =
            '0.0';
        }
      }
    }
  }

  resetSelectTotal(e, iInd, jInd, kInd) {
    let total;
    let catCount = this.allCategoryData[iInd].count;
    let subCatCount = this.allCategoryData[iInd].subCategory[jInd].count;
    total =
      this.sowEstimate != null && this.sowEstimate != ''
        ? parseFloat(this.sowEstimate)
        : 0.0;
    if (!e.checked) {
      if (this.itemPriced == 'Itemized') {
        total =
          total -
          parseFloat(
            this.allCategoryData[iInd].subCategory[jInd].subSubCategory[kInd]
              .total
          );
        this.sowEstimate = total.toString();
      }
      catCount--;
      subCatCount--;
      this.allCategoryData[iInd].count = catCount;
      this.allCategoryData[iInd].subCategory[jInd].count = subCatCount;
      this.allCategoryData[iInd].subCategory[jInd].subSubCategory[
        kInd
      ].status = false;
    } else {
      if (this.itemPriced == 'Itemized') {
        total =
          total +
          parseFloat(
            this.allCategoryData[iInd].subCategory[jInd].subSubCategory[kInd]
              .total
          );
        this.sowEstimate = total.toString();
      }
      catCount++;
      subCatCount++;
      this.allCategoryData[iInd].count = catCount;
      this.allCategoryData[iInd].subCategory[jInd].count = subCatCount;
      this.allCategoryData[iInd].subCategory[jInd].subSubCategory[
        kInd
      ].status = true;
    }
  }

  setSOWValues(val, catId, subCatId, subSubCatId, mode) {
    console.log(val);
    let eTotal = 0.0;
    for (let i = 0; i < this.allCategoryData.length; i++) {
      for (let j = 0; j < this.allCategoryData[i].subCategory.length; j++) {
        for (
          let k = 0;
          k < this.allCategoryData[i].subCategory[j].subSubCategory.length;
          k++
        ) {
          if (
            this.allCategoryData[i].subCategory[j].subSubCategory[k]._id ==
            subSubCatId
          ) {
            if (mode == 'q') {
              this.allCategoryData[i].subCategory[j].subSubCategory[
                k
              ].quantity = parseFloat(val);

              this.allCategoryData[i].subCategory[j].subSubCategory[k].total =
                this.allCategoryData[i].subCategory[j].subSubCategory[k]
                  .quantity *
                this.allCategoryData[i].subCategory[j].subSubCategory[k]
                  .unitprice;
              if (
                !isNaN(
                  this.allCategoryData[i].subCategory[j].subSubCategory[k].total
                )
              ) {
                this.allCategoryData[i].subCategory[j].subSubCategory[
                  k
                ].total = this.allCategoryData[i].subCategory[j].subSubCategory[
                  k
                ].total.toFixed(2);
                if (this.itemPriced == 'Itemized') {
                  eTotal =
                    eTotal +
                    parseFloat(
                      this.allCategoryData[i].subCategory[j].subSubCategory[k]
                        .total
                    );
                }
              } else {
                this.allCategoryData[i].subCategory[j].subSubCategory[
                  k
                ].total = 0.0;
              }
            } else if (mode == 'u') {
              this.allCategoryData[i].subCategory[j].subSubCategory[
                k
              ].measurement = val;
            } else if (mode == 'p') {
              this.allCategoryData[i].subCategory[j].subSubCategory[
                k
              ].unitprice = parseFloat(val);

              this.allCategoryData[i].subCategory[j].subSubCategory[k].total =
                this.allCategoryData[i].subCategory[j].subSubCategory[k]
                  .quantity *
                this.allCategoryData[i].subCategory[j].subSubCategory[k]
                  .unitprice;
              if (
                !isNaN(
                  this.allCategoryData[i].subCategory[j].subSubCategory[k].total
                )
              ) {
                this.allCategoryData[i].subCategory[j].subSubCategory[
                  k
                ].total = this.allCategoryData[i].subCategory[j].subSubCategory[
                  k
                ].total.toFixed(2);
                if (this.itemPriced == 'Itemized') {
                  eTotal =
                    eTotal +
                    parseFloat(
                      this.allCategoryData[i].subCategory[j].subSubCategory[k]
                        .total
                    );
                }
              } else {
                this.allCategoryData[i].subCategory[j].subSubCategory[
                  k
                ].total = 0.0;
              }
            } else if (mode == 'd') {
              this.allCategoryData[i].subCategory[j].subSubCategory[
                k
              ].description = val;
            }
          } else {
            if (mode == 'q' || mode == 'p') {
              if (
                !isNaN(
                  this.allCategoryData[i].subCategory[j].subSubCategory[k].total
                )
              ) {
                if (this.itemPriced == 'Itemized') {
                  eTotal =
                    eTotal +
                    parseFloat(
                      this.allCategoryData[i].subCategory[j].subSubCategory[k]
                        .total
                    );
                }
              }
            }
          }
        }
      }
    }
    if (mode != 'd' && mode != 'u') this.sowEstimate = eTotal.toString();
  }

  getContractor() {
    let obj = {};
    this.contractorList = [];
    this._contractorService.getContractors(obj).subscribe(
      (response: ResponseModel) => {
        console.log(response);
        if (response?.statusCode && response.statusCode == 200) {
          this.contractorList = response?.data?.items
            ? response?.data?.items
            : [];
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();

        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }

  transformAmount(element) {
    var value = element.target.value.split('.');
    if (element.target.value && value[1]) {
      if (value[1].length == 2) {
        element.target.value = element.target.value;
      } else if (value[1].length == 1) {
        element.target.value = element.target.value + '0';
      } else {
        element.target.value = element.target.value + '00';
      }
    } else if (element.target.value != '$ ') {
      element.target.value = value[0] + '.00';
    }
  }

  resetSOWName() {
    this.sowNameMsg = '';
    if (this.sowName.length > 80) {
      this.sowName = this.sowName.substring(0, 80);
    }
  }

  checkName() {
    this.sowNameMsg = '';
    this.sowSDateMsg = '';
    this.sowEDateMsg = '';
    this.sowContractorMsg = '';
    this.sowEstimateMsg = '';
    if (this.sowName.length == 0) {
      this.sowNameMsg = 'SOW Name is required';
    }
    if (this.sowName.length > 80) {
      this.sowNameMsg = 'SOW Name cannot be more than 80 characters';
    }
    if (this.sowSDate == null) {
      this.sowSDateMsg = 'SOW Start Date is required';
    }
    if (this.sowEDate == null) {
      this.sowEDateMsg = 'SOW End Date is required';
    }
    if (
      this.itemPriced == 'Lumpsum' &&
      (this.sowEstimate == null ||
        this.sowEstimate == '' ||
        parseFloat(this.sowEstimate) <= 0)
    ) {
      this.sowEstimateMsg = 'Estimate is required';
    }

    if (this.sowContractor == '') {
      this.sowContractorMsg = 'Contractor is required';
    }

    if (
      this.sowNameMsg != '' ||
      this.sowSDateMsg != '' ||
      this.sowEDateMsg != '' ||
      this.sowContractorMsg != '' ||
      this.sowEstimateMsg != ''
    )
      return false;
    let differentName = true;
    if (this.isEdit && this.sowName == this.sowDetail?.sowname) {
      differentName = false;
    }

    this._loaderService.start();
    if (differentName == true) {
      let obj = {
        sowname: this.sowName,
        propertyid: this.data?.inventoryId,
      };
      this._sowService.checkSOWNamePresence(obj).subscribe(
        (response: ResponseModel) => {
          console.log(response);
          if (response?.statusCode && response?.statusCode == 200) {
            if (response?.data && response?.data.length == 0) {
              this.submitSow();
            } else {
              this._loaderService.stop();
              this.sowNameMsg = 'SOW Name Already Exists!';
              //this._toastrService.error('SOW Name Already Exists!');
            }
          } else {
            this._loaderService.stop();
            this.sowNameMsg = 'SOW Name Already Exists!';
            //this._toastrService.error('SOW Name Already Exists!');
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();

          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(MessageConstant.unknownError, '');
          }
        }
      );
    } else {
      this.submitSow();
    }
  }
  submitSow() {
    let itemsAr = {},
      key1,
      key2,
      key3;
    let validationRes = true;
    let checkWork = 0;
    for (let i = 0; i < this.allCategoryData.length; i++) {
      key1 = this.allCategoryData[i]._id;
      itemsAr[key1] = {};
      for (let j = 0; j < this.allCategoryData[i].subCategory.length; j++) {
        key2 = this.allCategoryData[i].subCategory[j]._id;
        itemsAr[key1][key2] = {};
        itemsAr[key1][key2][key2 + 'items'] = this.allCategoryData[
          i
        ].subCategory[j].count;
        for (
          let k = 0;
          k < this.allCategoryData[i].subCategory[j].subSubCategory.length;
          k++
        ) {
          if (this.allCategoryData[i].subCategory[j].subSubCategory[k].status) {
            checkWork = 1;
            validationRes = this.validateSOW(i, j, k);
          }
          if (!validationRes) break;
          key3 = this.allCategoryData[i].subCategory[j].subSubCategory[k]._id;
          itemsAr[key1][key2][key3] = {
            name: this.allCategoryData[i].subCategory[j].subSubCategory[k].name,
            status: this.allCategoryData[i].subCategory[j].subSubCategory[k]
              .status,
            description: this.subSubDescVal[key1 + '_' + key2 + '_' + key3],
            // this.allCategoryData[i].subCategory[j].subSubCategory[k]
            //   .description,
            imageurl: this.allCategoryData[i].subCategory[j].subSubCategory[k]
              .imageurl,
            quantity: this.allCategoryData[i].subCategory[j].subSubCategory[k]
              .quantity,
            measurement: this.allCategoryData[i].subCategory[j].subSubCategory[
              k
            ].measurement,
            unitprice: this.allCategoryData[i].subCategory[j].subSubCategory[k]
              .unitprice,
            total: this.allCategoryData[i].subCategory[j].subSubCategory[k]
              .total,
          };
        }
        if (!validationRes) break;
      }
      if (!validationRes) break;
    }
    if (checkWork == 0) {
      this._toastrService.error('Please select atleast one work.');
      validationRes = false;
    }
    if (validationRes) {
      let obj = {
        propertyid: this.data?.inventoryId,
        sowname: this.sowName,
        startdate: this.sowSDate,
        enddate: this.sowEDate,
        estimation: this.sowEstimate,
        contractorname: this.sowContractorName,
        contractorid: this.sowContractor,
        materialpaid: this.sowMaterialBy,
        itemprice: this.itemPriced,
        items: itemsAr,
      };
      console.log(obj);
      if (this.isEdit) {
        obj['sowId'] = this.sowDetail?._id;
        this._sowService.updateSOW(obj).subscribe(
          (response: ResponseModel) => {
            console.log(response);

            //this._loaderService.stop();
            if (response?.statusCode && response?.statusCode == 200) {
              this._toastrService.success('SOW Updated Successfully');
              this._sowDialogRef.close('edit');
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();

            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(MessageConstant.unknownError, '');
            }
          }
        );
      } else {
        this._sowService.createSOW(obj).subscribe(
          (response: ResponseModel) => {
            console.log(response);

            //this._loaderService.stop();
            if (response?.statusCode && response?.statusCode == 200) {
              this._toastrService.success('SOW Created Successfully');
              this._sowDialogRef.close('create');
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();

            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(MessageConstant.unknownError, '');
            }
          }
        );
      }
    } else {
      this._loaderService.stop();
    }
  }
  validateSOW(i, j, k) {
    if (this.itemPriced == 'Itemized') {
      if (
        isNaN(
          this.allCategoryData[i].subCategory[j].subSubCategory[k].quantity
        ) ||
        this.allCategoryData[i].subCategory[j].subSubCategory[k].quantity ==
          null ||
        this.allCategoryData[i].subCategory[j].subSubCategory[k].quantity ==
          '' ||
        this.allCategoryData[i].subCategory[j].subSubCategory[k].quantity == 0
      ) {
        this._toastrService.error(
          'Quantity cannot be blank or 0 in ' +
            this.allCategoryData[i].name +
            ' -> ' +
            this.allCategoryData[i].subCategory[j].name +
            ' -> ' +
            this.allCategoryData[i].subCategory[j].subSubCategory[k].name
        );
        return false;
      } else if (
        this.allCategoryData[i].subCategory[j].subSubCategory[k].measurement ==
        ''
      ) {
        this._toastrService.error(
          'Measurement is required in ' +
            this.allCategoryData[i].name +
            ' -> ' +
            this.allCategoryData[i].subCategory[j].name +
            ' -> ' +
            this.allCategoryData[i].subCategory[j].subSubCategory[k].name
        );
        return false;
      } else if (
        this.allCategoryData[i].subCategory[j].subSubCategory[k].unitprice ==
          null ||
        this.allCategoryData[i].subCategory[j].subSubCategory[k].unitprice ==
          '' ||
        isNaN(
          this.allCategoryData[i].subCategory[j].subSubCategory[k].unitprice
        ) ||
        parseFloat(
          this.allCategoryData[i].subCategory[j].subSubCategory[k].unitprice
        ) <= 0
      ) {
        this._toastrService.error(
          'Unit Price cannot be blank or 0 in ' +
            this.allCategoryData[i].name +
            ' -> ' +
            this.allCategoryData[i].subCategory[j].name +
            ' -> ' +
            this.allCategoryData[i].subCategory[j].subSubCategory[k].name
        );
        return false;
      }
    }

    return true;
  }
  setFileUpload(catI, subCatI, subSubCatI) {
    this.fileCatI = catI;
    this.fileSubCatI = subCatI;
    this.fileSubSubCatI = subSubCatI;
    console.log(
      this.fileCatI + '-' + this.fileSubCatI + '-' + this.fileSubSubCatI
    );
    document.getElementById('fileUploadInput').click();
  }
  resetDescript(cId, sCId, sSCId) {
    if (this.subSubDescVal[cId + '_' + sCId + '_' + sSCId].length > 200) {
      this.subSubDescVal[cId + '_' + sCId + '_' + sSCId] = this.subSubDescVal[
        cId + '_' + sCId + '_' + sSCId
      ].substring(0, 200);
    }
  }
  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'cancelled') {
      this._loaderService.stop();
    } else if (output.type === 'allAddedToQueue') {
      const token = localStorage.getItem('token');
      const event: UploadInput = {
        type: 'uploadAll',
        url: environment.baseUrl + BaseUrl.sowUrl + 'uploadMultiImages',
        method: 'POST',
        headers: { Authorization: token },
        data: {},
      };
      this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue') {
      this._loaderService.start();

      console.log('addedToQueue--- ');
      // add file to array when added
    } else if (output.type === 'done' && typeof output.file !== 'undefined') {
      this.files = output.file;
      //this.uploadFileName = this.files?.name;
      this._loaderService.stop();

      if (
        this.files.response.statusCode &&
        this.files.response.statusCode == 200
      ) {
        console.log(this.files.response?.data?.filename);
        if (
          this.files.response?.data?.filename &&
          this.files.response?.data?.filename.length > 0
        ) {
          let finalAr = this.files.response?.data?.filename.concat(
            this.allCategoryData[this.fileCatI].subCategory[this.fileSubCatI]
              .subSubCategory[this.fileSubSubCatI].imageurl
          );
          this.allCategoryData[this.fileCatI].subCategory[
            this.fileSubCatI
          ].subSubCategory[this.fileSubSubCatI].imageurl = finalAr;
          console.log(this.allCategoryData);
          this.fileCatI = 0;
          this.fileSubCatI = 0;
          this.fileSubSubCatI = 0;
          this.uploaderInput.nativeElement.value = '';
        }
      } else {
        var errorMsg = '';
      }
    }
  }
  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }
  removeImg(imgI, cI, sCI, sSCI) {
    let tempAr = [];
    for (
      let i = 0;
      i <
      this.allCategoryData[cI].subCategory[sCI].subSubCategory[sSCI].imageurl
        .length;
      i++
    ) {
      if (imgI != i)
        tempAr.push(
          this.allCategoryData[cI].subCategory[sCI].subSubCategory[sSCI]
            .imageurl[i]
        );
    }
    this.allCategoryData[cI].subCategory[sCI].subSubCategory[
      sSCI
    ].imageurl = tempAr;
  }
  updateCategory() {
    let key1,
      key2,
      key3,
      tCat = 0,
      tSCat = 0;
    for (let i = 0; i < this.allCategoryData.length; i++) {
      key1 = this.allCategoryData[i]._id;
      tCat = 0;
      for (let j = 0; j < this.allCategoryData[i].subCategory.length; j++) {
        key2 = this.allCategoryData[i].subCategory[j]._id;
        tCat = tCat + this.sowDetail?.items[key1][key2][key2 + 'items'];
        this.allCategoryData[i].subCategory[j].count = this.sowDetail?.items[
          key1
        ][key2][key2 + 'items'];
        for (
          let k = 0;
          k < this.allCategoryData[i].subCategory[j].subSubCategory.length;
          k++
        ) {
          key3 = this.allCategoryData[i].subCategory[j].subSubCategory[k]._id;
          if (
            this.sowDetail?.items[key1] &&
            this.sowDetail?.items[key1][key2] &&
            this.sowDetail?.items[key1][key2][key3] &&
            this.sowDetail?.items[key1][key2][key3].status == true
          ) {
            this.allCategoryData[i].subCategory[j].subSubCategory[
              k
            ].status = this.sowDetail?.items[key1][key2][key3].status;
            this.allCategoryData[i].subCategory[j].subSubCategory[
              k
            ].description = this.sowDetail?.items[key1][key2][key3].description;
            this.allCategoryData[i].subCategory[j].subSubCategory[
              k
            ].imageurl = this.sowDetail?.items[key1][key2][key3].imageurl;
            this.allCategoryData[i].subCategory[j].subSubCategory[
              k
            ].quantity = this.sowDetail?.items[key1][key2][key3].quantity;
            this.allCategoryData[i].subCategory[j].subSubCategory[
              k
            ].measurement = this.sowDetail?.items[key1][key2][key3].measurement;
            this.allCategoryData[i].subCategory[j].subSubCategory[
              k
            ].unitprice = this.sowDetail?.items[key1][key2][key3].unitprice;
            this.allCategoryData[i].subCategory[j].subSubCategory[
              k
            ].total = this.sowDetail?.items[key1][key2][key3].total;
          }
        }
      }
      this.allCategoryData[i].count = tCat;
    }
  }
  changeContractor(sel) {
    this.sowContractorName =
      sel.target.options[sel.target.options.selectedIndex].text;
    console.log(this.sowContractorName);
  }
}
