// MODULES
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

//Component
import { ActivityLogsComponent } from 'src/app/shared/activity-logs/activity-logs.component';

// SERVICES
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-pinned-notes-dialog',
  templateUrl: './pinned-notes-dialog.component.html',
  styleUrls: ['./pinned-notes-dialog.component.scss'],
})
export class PinnedNotesDialogComponent implements OnInit {
  @ViewChild('activityLog') activityLog: ActivityLogsComponent;
  totalCount: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _pinnedNotesDialogDialogRef: MatDialogRef<PinnedNotesDialogComponent>,
    private _sharedService: SharedService
  ) {
    this._sharedService.refreshSubHeaderPinnedCount = new Subject();
    this._sharedService.refreshSubHeaderPinnedCount.subscribe((response) => {
      if (response) {
        this.totalCount = response?.count;
      }
    });
  }

  ngOnInit(): void {}
}
