import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { BaseUrl } from '../../utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

@Injectable({
  providedIn: 'root',
})
export class AccountingService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem('token');

  constructor(
    private http: HttpClient,
    private _commonFunction: CommonFunctionsService
  ) {}

  getAccountList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountUrl + 'account_list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getAccountDetails = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountUrl + 'details';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  addEditManualAccount = (data, isEdit): Observable<any> => {
    const endpoint =
      this.baseUrl +
      BaseUrl.accountUrl +
      (isEdit ? 'edit_manual' : 'add_manual');

    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteAccount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountUrl + 'delete_manual';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  //************** Vendors **************

  getVendorList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.vendorUrl + 'getVendorList';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  addEditManualVendor = (data, isEdit): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.vendorUrl + (isEdit ? 'edit' : 'add');
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteVendor = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.vendorUrl + 'delete';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getVendorDetails = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.vendorUrl + 'details';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getVendorCategory = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.vendorCategoryUrl + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getVendorSubcategory = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.vendorSubCategoryUrl + 'categoryWiseList';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  //************** Account Tags **************

  getAccountTagsList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountTagsUrl + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  addEditAccountTags = (data, isEdit): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.accountTagsUrl + (isEdit ? 'edit' : 'add');
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteAccountTagsForUser = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountTagsUrl + 'deleteForUser';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getAccountTagDetails = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountTagsUrl + 'details';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getAccountTagCategory = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountTagCategoryUrl + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getAccountTagSubcategory = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.accountTagSubCategoryUrl + 'categoryWiseList';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getAccountTagChildCategory = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.accountTagChildCategoryUrl + 'categoryWiseList';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  //************** Property **************

  getPropertyList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountUrl + 'propertyList';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getAllProjectType = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.projectType + 'getAll';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  //************** Transaction List **************

  getMappedTransactionList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.transactionUrl + 'mappedList';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getRecognizedTransactionList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.transactionUrl + 'recognizedList';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getUnrecognizedTransactionList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.transactionUrl + 'unrecognizedList';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getFinicityTransactions = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.accountUrl + 'getFinicityTransaction';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getTransactionsCount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.transactionUrl + 'transactionCount';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getAllVendors = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.vendorUrl + 'getAllVendors';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getAllProperty = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountUrl + 'getAllProperty';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getAllAccountTag = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountTagsUrl + 'getAllAccountTag';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getTransactionDetails = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.transactionUrl + 'details';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  addEditTransaction = (data, isEdit): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.transactionUrl + (isEdit ? 'edit' : 'add');
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteTransaction = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.transactionUrl + 'delete';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getCampaignById = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountUrl + 'getCampaignById';

    return this._commonFunction.globalPostService(endpoint, data);
  };

  getTransactionFilter = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.transactionUrl + 'filter';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getTransactionGroupFilter = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.transactionUrl + 'groupFilter';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getPropertyDetails = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountUrl + 'getPropertyDetails';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getAccountConnectLink = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountUrl + 'getAccountConnect';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  refreshFinicityAccounts = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountUrl + 'refreshAccounts';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getFinicityHistoricTransaction = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.accountUrl + 'getFinicityHistoricTransaction';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  confirmTransaction = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.transactionUrl + 'confirm';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  editLinkAccount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountUrl + 'editLinkAccount';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getAccountTagsStatement = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.transactionUrl + 'getAccountTagStatement';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getTransactionFilterBalance = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.transactionUrl + 'filterBalance';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  fixAccount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountUrl + 'fixAccount';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getAllMappedTransaction = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.transactionUrl + 'getAllMappedTransaction';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getNetIncomeStatement = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.transactionUrl + 'getNetIncomeStatement';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getIncomeStatementPropertyWise = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.transactionUrl + 'getIncomeStatementPropertyWise';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getIncomeStatementVendorWise = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.transactionUrl + 'getIncomeStatementVendorWise';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getIncomeStatementMonthWise = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.transactionUrl + 'getIncomeStatementMonthWise';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getAllTransactionList = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.transactionUrl + 'getAllTransactionList';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  //************** Journal Entry **************

  getJournalEntryList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.journalEntryUrl + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  addEditJournalEntry = (data, isEdit): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.journalEntryUrl + (isEdit ? 'edit' : 'add');
    console.log('endpoint = ', endpoint);

    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteJournalEntry = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.journalEntryUrl + 'delete';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getJournalEntryDetails = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.journalEntryUrl + 'details';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getAllAccounts = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountUrl + 'getAllAccounts';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  createLinkToken = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountUrl + 'createLinkToken';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  savePublicToken = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountUrl + 'savePublicToken';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  refreshPlaidAccounts = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountUrl + 'refreshPlaidAccounts';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getPlaidTransaction = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountUrl + 'getPlaidTransaction';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getNextJournalNo = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.journalEntryUrl + 'getNextJournalNo';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getBalanceSheet = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.transactionUrl + 'getBalanceSheet';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getOpeningBalance = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountUrl + 'getOpeningBalance';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  addOpeningBalance = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.accountUrl + 'addOpeningBalance';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  unMatchTransaction = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.transactionUrl + 'unMatch';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  unmappedTransaction = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.transactionUrl + 'unmapped';
    return this._commonFunction.globalPostService(endpoint, data);
  };
}
