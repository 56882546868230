<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      style="display: block !important"
      class="w-100 d-flex justify-content-between"
    >
      PINNED NOTES ({{ totalCount || 0 }})
    </h2>
    <span matDialogClose mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-12 box-md-12 col-sm-12">
      <activity-logs
        #activityLog
        classNew="re-typography"
        [activityMainStyle]="{ height: 'CALC(100vh - 535px)' }"
        [style]="{ borderTop: 'none', height: 'CALC(100vh - 535px)' }"
        [moduleId]="data?.moduleId"
        [leadId]="data?.leadId"
        [leadData]="data?.leadData"
        [moduleName]="data?.moduleName"
        [isPinned]="true"
        [isPinnedIconView]="true"
        [activityTabView]="false"
        [activityFilterView]="false"
        [activityQuillBox]="false"
        [isWebFormLink]="true"
      ></activity-logs>
    </div>
  </div>
</div>
