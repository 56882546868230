import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { BaseUrl } from '../../utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpBackend,
} from '@angular/common/http';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
@Injectable({
  providedIn: 'root',
})
export class ListStackingService {
  baseUrl = environment.listStackingUrl;
  mainUrl = environment.baseUrl;
  public token = localStorage.getItem('token');
  private httpClient: HttpClient;

  constructor(
    private _commonFunction: CommonFunctionsService,
    httpBackend: HttpBackend
  ) {
    this.httpClient = new HttpClient(httpBackend);
  }

  getAllPropertyList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'getAllListProperty';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getLists = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'getLists';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getListsTags = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'getTags';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  addLists = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'createList';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getSearchListslist = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'searchLists';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getSearchListsTags = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'searchTags';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getPhoneStatus = (): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'getPhoneStatus';
    return this._commonFunction.globalGetService(endpoint);
  };

  addListsTags = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'createTags';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  addPhoneStatus = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'attachNewPhoneStatus';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateDNCPhoneStatus = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'updateDNCPhoneStatus';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateMarketingCampaign = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'renameMarketing';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteMarketingCampaign = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'deleteMarketing';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getProcessingLog = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'getNotProcessedLogCount';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  importContacts = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'backgroundImportContacts';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  logInfo = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'logInfo';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  csvLogs = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'csvLogs';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getLogCount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'getLogCount';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getTagsCount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'tagAnalytics';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getListsCount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'listAnalytics';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  renameTag = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'renameTag';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  renameList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'renameList';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getListDetail = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'getListDetail';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getAllNotMatchProperty = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'getAllNotMatchProperty';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getPropertySearchResult = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'getPropertySearchResult';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  analyticsinfo = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'analyticsinfo';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  distinctPhoneType = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'getPhoneType';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  distinctPropData = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'distinctPropertyData';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  matchNotMatchedProperty = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'matchNotMatchedProperty';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  updatemultilists = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'updatemultiplelists';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  updatemultitags = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'updateTags';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getListsOfSelected = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'getListForSelectedProperty';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getTagsForSelectedProperty = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'getTagsForSelectedProperty';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  checkUniqueSkipTrace = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'findSkipTracingName';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  addSkipTrace = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'skipTracing';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getSkipTraceLog = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'skipTracingLogs';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getListStackingPermission = (data): Observable<any> => {
    const endpoint = this.mainUrl + BaseUrl.user + 'getListStackingPermission';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  updatemultioptouts = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'updatemultioptouts';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getAllListCSV = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'getAllListCSV';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  addMarketingDetails = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'addMarketingDetails';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  editProperty = (data, id): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'editProperty/' + id;
    return this._commonFunction.globalPutService(endpoint, data);
  };
  getfileValueMapping = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'fileValueMapping';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  orderFromLS = (data): Observable<any> => {
    const endpoint = this.mainUrl + BaseUrl.directMailUrl + 'orderFromLS';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getSkipTraceCredit = (data): Observable<any> => {
    const endpoint = this.mainUrl + BaseUrl.creditUrl + 'getSkipDataCredit';
    return this._commonFunction.globalPostService(endpoint, {});
  };
  createCsvFile = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'createCsvFile';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getDashboardData = (data): Observable<any> => {
    let endpoint = '';
    if (data == 1) endpoint = this.baseUrl + BaseUrl.listStacking + 'dashboard';
    else if (data == 2)
      endpoint = this.baseUrl + BaseUrl.listStacking + 'dashboard1';
    else if (data == 3)
      endpoint = this.baseUrl + BaseUrl.listStacking + 'dashboard2';

    return this._commonFunction.globalGetService(endpoint);
  };
  getOtherPhoneType = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'checkOtherPhoneType';

    return this._commonFunction.globalGetService(endpoint);
  };
  getOtherPhoneStatus = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'checkOtherPhoneStatus';
    return this._commonFunction.globalGetService(endpoint);
  };
  updatePhoneType = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'updatePhoneType';

    return this._commonFunction.globalPostService(endpoint, data);
  };
  updatePhoneStatus = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'updatePhoneStatus';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  findPhoneStatus = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'findPhoneStatus';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteLogss = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'deleteLogss';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getMarketingList = (): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'marketingList';
    return this._commonFunction.globalGetService(endpoint);
  };

  removeMarkets = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'removeMarketingData';

    return this._commonFunction.globalPostService(endpoint, data);
  };

  getCurrentProcessCount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'getInprocessFiles';
    return this._commonFunction.globalGetService(endpoint);
  };

  getAdjacentProperties = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'prevAndNextDocumentId';

    return this._commonFunction.globalPostService(endpoint, data);
  };

  getActivityLog = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'getActivity';

    return this._commonFunction.globalPostService(endpoint, data);
  };

  addActivityLog = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'saveActivity';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  editActivityLogs = (data, id): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.listStacking + 'updateActivity/' + id;
    return this._commonFunction.globalPutService(endpoint, data);
  };

  deleteActivityLogs = (id): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'deleteActivity/' + id;

    return this._commonFunction.globalDeleteService(endpoint);
  };

  getOwnersRelatedProperty = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'ownersRelatedProperty';

    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateRelatedProperty = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'updateRelatedProperty';

    return this._commonFunction.globalPostService(endpoint, data);
  };
  updateLastStatus = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listStacking + 'updateLastStatus';

    return this._commonFunction.globalPostService(endpoint, data);
  };

  uploadsingleproperty = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'uploadsingleproperty';

    return this._commonFunction.globalPostService(endpoint, data);
  };

  getPortfolioData = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'getLinkedProperties';

    return this._commonFunction.globalPostService(endpoint, data);
  };

  getSearchPropertyData = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.listStacking + 'autocompletePropertyList';

    return this._commonFunction.globalPostService(endpoint, data);
  };

  // getLinkedPropertyList = (data): Observable<any> => {
  //   const endpoint =
  //     environment.globalPropertyAddressUrl + 'getLinkedPropertyList';

  //   return this.httpClient.post(endpoint, data, this.getRequestHeaders()).pipe(
  //     catchError((err) => {
  //       return throwError(err);
  //     })
  //   );
  // };

  // protected getRequestHeaders(): {
  //   headers: HttpHeaders | { [header: string]: string | string[] };
  // } {
  //   let headers;
  //   let obj = {
  //     'Content-Type': 'application/json',
  //     'Access-Control-Allow-Origin': '*',
  //     authorization:
  //       'Hp8OvrqtBXrpGU7dO1xsJ8ACwkVuKcZGWeOwOhLbCaws33X5pdCtbQDRuBLQDWQs',
  //   };

  //   headers = new HttpHeaders(obj);
  //   return { headers: headers };
  // }
}
