<div class="re-sendemail-modal" *ngIf="data.details">
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between"
        style="display: block !important"
      >
        Send Email
      </h2>
      <span mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>
  <form
    [formGroup]="sendEmailForm"
    (ngSubmit)="onSubmit()"
    autocomplete="off"
    novalidate
  >
    <mat-dialog-content class="">
      <div class="container-fluid p-0">
        <div class="box">
          <div class="row">
            <div class="col-12 col-lg-6 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="labels"
                  >From Name<span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  name="fromName"
                  formControlName="fromName"
                  class="form-control"
                  placeholder="Name"
                  (blur)="onBlurMethod('templateName')"
                />
                <div class="text-danger re-error-text">
                  <span *ngIf="submitted && hasError('fromName', 'required')"
                    ><span>
                      {{
                        messageConstant.requiredField.replace("Field", "Name")
                      }}
                    </span>
                  </span>

                  <span
                    *ngIf="
                      checkValidation['fromName'] &&
                      hasError('fromName', 'pattern')
                    "
                  >
                    {{ messageConstant.validFieldValue }} Name
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="labels"
                  >From Email<span class="text-danger">*</span></label
                >
                <input
                  type="email"
                  class="form-control"
                  name="fromEmail"
                  formControlName="fromEmail"
                  placeholder="Email"
                  (blur)="
                    onBlurMethod('fromEmail'); verifyEmail($event.target.value)
                  "
                />
                <div class="text-danger re-error-text">
                  <span *ngIf="submitted && hasError('fromEmail', 'required')"
                    ><span>
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "From Email"
                        )
                      }}
                    </span>
                  </span>

                  <span
                    *ngIf="
                      checkValidation['fromEmail'] &&
                      hasError('fromEmail', 'pattern')
                    "
                  >
                    {{ messageConstant.validFieldValue }} From Email
                  </span>
                </div>
                <p
                  *ngIf="
                    isEmailExist === false &&
                    sendEmailForm.controls.fromEmail.status == 'VALID'
                  "
                  class="text-danger re-error-text"
                >
                  {{ emailInvalidMessage }}
                  <a
                    class="re-text-teal pointer"
                    (click)="onVerifyEmailDailog(sendEmailForm.value.fromEmail)"
                    *ngIf="
                      isEmailExist === false &&
                      emailInvalidMessage?.includes(
                        'Please verify email address.'
                      )
                    "
                  >
                    Click Here
                  </a>
                  <!-- <a
                    class="re-text-teal pointer"
                    (click)="onVerifyEmailDailog(sendEmailForm.value.fromEmail)"
                    >Click Here</a
                  > -->
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="box">
          <div class="row">
            <div class="col-12 col-lg-8 col-md-8 col-sm-7">
              <div class="form-group">
                <label class="labels"
                  >To Email<span class="text-danger">*</span></label
                >
                <div>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="To Email"
                    name="toEmail"
                    formControlName="toEmail"
                    (blur)="onBlurMethod('toEmail')"
                    (change)="onAllowUniqueEmail($event.target.value)"
                    [(ngModel)]="toEmail"
                  />
                  <div class="text-danger re-error-text to-email">
                    <span *ngIf="submitted && hasError('toEmail', 'required')"
                      ><span>
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "To Email"
                          )
                        }}
                      </span>
                    </span>
                    <span
                      *ngIf="
                        checkValidation['toEmail'] &&
                        hasError('toEmail', 'pattern')
                      "
                    >
                      {{ messageConstant.validFieldValue }} To Email
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 col-md-4 col-sm-5 re-cc-bcc">
              <div class="ccbcdiv">
                <a
                  href="javascript:;"
                  class="ccbc"
                  id="ccbtn"
                  (click)="addRemoveCC(true)"
                  >Add CC</a
                >
                <a
                  href="javascript:;"
                  class="ccbc"
                  id="bccbtn"
                  (click)="addRemoveBCC(true)"
                  >Add BCC</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="box pt-0">
          <div class="row" *ngIf="showCC">
            <div class="col-12 col-lg-11 col-md-11 col-sm-10">
              <div class="form-group">
                <label class="labels">CC: (Seperate by ,)</label>
                <input
                  type="email"
                  name="ccEmail"
                  formControlName="ccEmail"
                  class="form-control"
                  placeholder="CC Email"
                  (change)="onAllowUniqueCcEmail($event.target.value)"
                  [(ngModel)]="ccEmail"
                />
                <div class="text-danger re-error-text">
                  <span *ngIf="!ccChecker">
                    {{ messageConstant.validFieldValue }} CC Email
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-1 col-md-1 col-sm-2 mt-4">
              <a
                href="javascript:;"
                class="re-trashbtn"
                (click)="addRemoveCC(false)"
              >
                <img
                  class="re-delete-icon"
                  src="assets/images/delete-red.svg"
                  alt="delete"
                />
              </a>
            </div>
          </div>
          <div class="row" *ngIf="showBCC">
            <div class="col-12 col-lg-11 col-md-11 col-sm-10">
              <div class="form-group">
                <label class="labels">BCC: (Seperate by ,)</label>
                <input
                  type="email"
                  class="form-control"
                  name="bccEmail"
                  formControlName="bccEmail"
                  placeholder="BCC Email"
                  (change)="onAllowUniqueBccEmail($event.target.value)"
                  [(ngModel)]="bccEmail"
                />
                <div class="text-danger re-error-text">
                  <span *ngIf="!bccChecker">
                    {{ messageConstant.validFieldValue }} BCC Email
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-1 col-md-1 col-sm-2 mt-4">
              <a
                href="javascript:;"
                class="re-trashbtn"
                (click)="addRemoveBCC(false)"
              >
                <img
                  class="re-delete-icon"
                  src="assets/images/delete-red.svg"
                  alt="delete"
                />
              </a>
            </div>
            <div class="col-12 col-lg-12 col-md-12 col-sm-12" *ngIf="showCC">
              <p style="color: #009a44" class="mb-2 re-error-text">
                Each email address should be unique between cc, and bcc.
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div class="box">
          <div class="row">
            <div class="col-12 col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="labels"
                  >Subject<span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  name="subject"
                  formControlName="subject"
                  placeholder="Subject"
                  [mention]="items"
                  [mentionConfig]="{
                    triggerChar: '@',
                    maxItems: 100,
                    labelKey: 'name',
                    mentionSelect: onMentionSelect,
                    allowSpace: true
                  }"
                  (blur)="onChangeVal($event, 'subject')"
                />
                <div class="text-danger re-error-text">
                  <span *ngIf="submitted && hasError('subject', 'required')">
                    {{
                      messageConstant.requiredField.replace("Field", "Subject")
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="labels"
                  >Message<span class="text-danger">*</span></label
                >
                <quill-editor
                  #editor
                  name="message"
                  formControlName="messageBody"
                  [styles]="editorStyle"
                  [modules]="{
                    toolbar: false,
                    mention: editorOptions.mention
                  }"
                  [placeholder]="editorOptions.placeholder"
                  (blur)="onChangeVal($event, 'message')"
                ></quill-editor>
                <div class="text-danger re-error-text">
                  <span
                    *ngIf="submitted && hasError('messageBody', 'required')"
                  >
                    {{
                      messageConstant.requiredField.replace("Field", "Message")
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-12 col-md-12 col-sm-12">
              <div class="attachfile">
                <label class="labels">Attachment</label>
                <div>
                  <ul class="attchlist re-list1" *ngIf="editAttachmentArray">
                    <li *ngFor="let file of editAttachmentArray; let i = index">
                      {{ file.name }}
                      <a
                        class="fa fa-times-circle"
                        (click)="removeAttachmentArray(file)"
                      ></a>
                    </li>
                  </ul>
                  <ul class="attchlist re-list2">
                    <li *ngFor="let file of attachments; let i = index">
                      {{ file.name }}
                      <a
                        class="fa fa-times-circle"
                        (click)="removeImageArr(file)"
                      ></a>
                    </li>
                  </ul>
                  <div class="attachbtn">
                    <input
                      type="file"
                      name="attachmentArray"
                      formControlName="attachmentArray"
                      #uploader
                      ngFileSelect
                      multiple
                      [options]="options"
                      (uploadOutput)="onUploadLogo($event)"
                      [uploadInput]="uploadInput"
                      style="cursor: pointer"
                    />
                    <img
                      src="assets/images/inventory-details/attachment-symbol.png"
                      alt="attachment"
                      style="cursor: pointer"
                    />
                    Add attachments
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <hr style="margin-bottom: 10px !important" />

    <mat-dialog-actions align="center">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button type="submit" mat-flat-button color="primary" cdkFocusInitial>
        Send Email
      </button>
    </mat-dialog-actions>
  </form>
</div>
