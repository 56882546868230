import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';

// UTILITIES
import { MessageConstant } from 'src/app/utils/message-constant';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { LeadsService } from 'src/app/providers/leads/leads.service';
import { SharedService } from '../../shared.service';

// UTILS
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';

@Component({
  selector: 'app-change-number',
  templateUrl: './change-number.component.html',
  styleUrls: ['./change-number.component.scss'],
})
export class ChangeNumberComponent implements OnInit {
  changeNumberForm: FormGroup;
  submitted: boolean = false;
  messageConstant = MessageConstant;
  checkError: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ChangeNumberComponent>,
    public _utilities: CommonFunctionsService,
    private formBuilder: FormBuilder,
    private _loaderService: NgxUiLoaderService,
    private _leadService: LeadsService,
    private _toastrService: ToastrService,
    private _sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.changeNumberForm = this.formBuilder.group({
      numberId: ['', Validators.required],
      makeDefault: ['false'],
    });
    this.changeNumberForm.patchValue({ numberId: this.data?.numberId });
    this.data.numberList.sort((a, b) => a.marketingTitle.localeCompare(b.marketingTitle));
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.changeNumberForm.controls[controlName].hasError(errorName);
  };
  onChangeNumber() {
    this.submitted = true;
    this.checkError = false;
    if (this.changeNumberForm.invalid) {
      return;
    }

    const { leadId, leadIds } = this.data;
    const { numberId, makeDefault } = this.changeNumberForm.value;
    if (!makeDefault) {
      this.checkError = true;
      return;
    }
    let number;
    if (numberId) {
      number = this.data?.numberList.filter((obj) => {
        return obj?._id == numberId;
      });
    }
    if (number?.length === 0) {
      return;
    }
    this._loaderService.start();
    let myObj = {
      numberId: numberId,
      makeDefault: makeDefault,
      leadId: leadId,
      leadIds: leadIds,
      number: number[0]?.number,
    };
    this._leadService.numberChange(myObj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._toastrService.success(this.messageConstant.changeNumberSuccess);
          this.dialogRef.close({ defaultNumberId: numberId });
          this._sharedService.refreshDialerInfo.next(true);
          this._sharedService.refreshLeadsGrid.next(true);
          this._sharedService.refreshInfo.next(true);
        }
        setTimeout(() => {
          this._loaderService.stop();
        }, 2000);
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
}
