<form [formGroup]="tagAutomationForm" autocomplete="off">
  <ng-container *ngIf="isAllowManageNumberFeature">
    <div class="form-group">
      <div class="re-radiosec">
        <p class="re-radotext">
          Auto assign a Tags to leads created under this campaign?
        </p>
        <label class="re-switch">
          <input
            type="checkbox"
            id="marketingcheck"
            formControlName="isTagAutomation"
            (change)="resetAllChip()"
          />
          <span class="re-slider"></span>
          <span value="false" class="re-text1">No</span>
          <span value="true" class="re-text2">Yes</span>
        </label>
      </div>
      <hr />
    </div>

    <!--leads manually-->
    <div
      class="re-tasks-cont"
      *ngIf="tagAutomationForm?.value?.isTagAutomation"
    >
      <div class="tasktitle">
        <a href="javascript:void(0)" (click)="toggleSection('leadManually')">
          <span class="re-name-txt">Leads created manually</span>
          <!-- <span class="re-assign-txt">
            Enabled • XYZ Follow-up sequence • Drip stop configured
          </span> -->
          <span class="re-arrows" *ngIf="!isCollapseVisible('leadManually')">
            <i class="fa fa-chevron-circle-up re-teals" aria-hidden="true"></i>
          </span>
          <span class="re-arrows" *ngIf="isCollapseVisible('leadManually')">
            <i
              class="fa fa-chevron-circle-down re-blacks"
              aria-hidden="true"
            ></i>
          </span>
        </a>
      </div>

      <div
        class="row pb-3"
        [class.collapse]="isCollapseVisible('leadManually')"
      >
        <div class="col-12 col-md-12">
          <div class="re-radiosec">
            <p class="re-radotext">Would you like to enable this</p>
            <label class="re-switch">
              <input
                type="checkbox"
                id="leadcreate"
                formControlName="isTagLeadCreatedManual"
                (change)="resetChipIds()"
              />
              <span class="re-slider"></span>
              <span value="false" class="re-text1">Off</span>
              <span value="true" class="re-text2">On</span>
            </label>
          </div>
          <div *ngIf="tagAutomationForm?.value?.isTagLeadCreatedManual">
            <label class="labels">
              Auto assign Tags to leads created manually
            </label>
            <!-- Tag Editor -->
            <mat-form-field class="re-chip-list">
              <mat-chip-list #chipList1 aria-label="Tag selection">
                <mat-chip
                  *ngFor="let tag of tagsCreatedManually; let tagIndex = index"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(tagIndex, 1)"
                >
                  {{ tag.label }}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>

                <input
                  #tagInputCreatedManually
                  [formControl]="filteredTagsCreatedManually"
                  [matAutocomplete]="auto1"
                  [matChipInputFor]="chipList1"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="add($event, 1)"
                  (input)="onSearchChange($event.target.value, 1)"
                />
              </mat-chip-list>
              <mat-placeholder
                [ngClass]="
                  tagsCreatedManually.length > 0
                    ? 'placeholder removePlaceHolder'
                    : 'placeholder'
                "
                >Type tag name and press enter or comma to create a new
                tag</mat-placeholder
              >
              <mat-autocomplete
                #auto1="matAutocomplete"
                (optionSelected)="selected($event, 1)"
              >
                <ng-container
                  *ngFor="let tag of filteredTagsCreatedManually | async"
                >
                  <mat-option *ngIf="isVisible(tag, 1)" [value]="tag">
                    {{ tag.label }}
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
            <label style="font-weight: normal; font-size: 13px" class="mt-1"
              >Press Enter or comma to create a new tag</label
            >
            <!-- End Tag Editor -->
          </div>
        </div>
      </div>
    </div>

    <!--leads Answered-->
    <div
      class="re-tasks-cont"
      *ngIf="tagAutomationForm?.value?.isTagAutomation"
    >
      <div class="tasktitle">
        <a href="javascript:void(0)" (click)="toggleSection('leadAnswered')">
          <span class="re-name-txt"
            >Leads created from incoming ANSWERED calls</span
          >
          <!-- <span class="re-assign-txt"
            >Enabled • XYZ Follow-up sequence • Drip stop configured</span
          > -->
          <span class="re-arrows" *ngIf="!isCollapseVisible('leadAnswered')">
            <i class="fa fa-chevron-circle-up re-teals" aria-hidden="true"></i>
          </span>
          <span class="re-arrows" *ngIf="isCollapseVisible('leadAnswered')">
            <i
              class="fa fa-chevron-circle-down re-blacks"
              aria-hidden="true"
            ></i>
          </span>
        </a>
      </div>
      <div
        class="row pb-3"
        [class.collapse]="isCollapseVisible('leadAnswered')"
      >
        <div class="col-12 col-md-12">
          <div class="re-radiosec">
            <p class="re-radotext">Would you like to enable this</p>
            <label class="re-switch">
              <input
                type="checkbox"
                id="leadcreate"
                formControlName="isTagAnsCall"
                (change)="resetChipIds()"
              />
              <span class="re-slider"></span>
              <span value="false" class="re-text1">Off</span>
              <span value="true" class="re-text2">On</span>
            </label>
          </div>
          <div *ngIf="tagAutomationForm?.value?.isTagAnsCall">
            <label class="labels"
              >Auto assign Tags to leads created from incoming answered calls
            </label>
            <!-- Tag Editor -->
            <mat-form-field class="re-chip-list">
              <mat-chip-list #chipList2 aria-label="Tag selection">
                <mat-chip
                  *ngFor="
                    let tag of tagsIncomingAnswerCall;
                    let tagIndex = index
                  "
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(tagIndex, 2)"
                >
                  {{ tag.label }}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>

                <!-- CAMEL CASE ISSUE WITH VARIABLE -->
                <input
                  #tagInputIncomingAnswerCall
                  [formControl]="filteredTagsIncomingAnswerCall"
                  [matAutocomplete]="auto2"
                  [matChipInputFor]="chipList2"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="add($event, 2)"
                  (input)="onSearchChange($event.target.value, 2)"
                />
              </mat-chip-list>
              <mat-placeholder
                [ngClass]="
                  tagsIncomingAnswerCall.length > 0
                    ? 'placeholder removePlaceHolder'
                    : 'placeholder'
                "
                >Type tag name and press enter or comma to create a new
                tag</mat-placeholder
              >
              <mat-autocomplete
                #auto2="matAutocomplete"
                (optionSelected)="selected($event, 2)"
              >
                <!-- CAMEL CASE ISSUE WITH VARIABLE -->
                <ng-container
                  *ngFor="let tag of filteredTagsIncomingAnswerCall | async"
                >
                  <mat-option *ngIf="isVisible(tag, 2)" [value]="tag">
                    {{ tag.label }}
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
            <label style="font-weight: normal; font-size: 13px" class="mt-1"
              >Press Enter or comma to create a new tag</label
            >
            <!-- End Tag Editor -->
          </div>
        </div>
      </div>
    </div>

    <!--leads Un-Answered-->
    <div
      class="re-tasks-cont"
      *ngIf="tagAutomationForm?.value?.isTagAutomation"
    >
      <div class="tasktitle">
        <a href="javascript:void(0)" (click)="toggleSection('leadUnAnswered')">
          <span class="re-name-txt"
            >Leads created from incoming UN-ANSWERED calls</span
          >
          <!-- <span class="re-assign-txt"
            >Enabled • XYZ Follow-up sequence • Drip stop configured</span
          > -->
          <span class="re-arrows" *ngIf="!isCollapseVisible('leadUnAnswered')">
            <i class="fa fa-chevron-circle-up re-teals" aria-hidden="true"></i>
          </span>
          <span class="re-arrows" *ngIf="isCollapseVisible('leadUnAnswered')">
            <i
              class="fa fa-chevron-circle-down re-blacks"
              aria-hidden="true"
            ></i>
          </span>
        </a>
      </div>
      <div
        class="row pb-3"
        [class.collapse]="isCollapseVisible('leadUnAnswered')"
      >
        <div class="col-12 col-md-12">
          <div class="re-radiosec">
            <p class="re-radotext">Would you like to enable this</p>
            <label class="re-switch">
              <input
                type="checkbox"
                id="leadcreate"
                formControlName="isTagUnansCall"
                (change)="resetChipIds()"
              />
              <span class="re-slider"></span>
              <span value="false" class="re-text1">Off</span>
              <span value="true" class="re-text2">On</span>
            </label>
          </div>
          <div *ngIf="tagAutomationForm?.value?.isTagUnansCall">
            <label class="labels"
              >Auto assign Tags to leads created from incoming un-answered calls
            </label>
            <!-- Tag Editor -->
            <mat-form-field class="re-chip-list">
              <mat-chip-list #chipList3 aria-label="Tag selection">
                <mat-chip
                  *ngFor="
                    let tag of tagsIncomingUnAnswerCall;
                    let tagIndex = index
                  "
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(tagIndex, 3)"
                >
                  {{ tag.label }}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>

                <input
                  #tagInputIncomingUnAnswerCall
                  [formControl]="filteredTagsIncomingUnAnswerCall"
                  [matAutocomplete]="auto3"
                  [matChipInputFor]="chipList3"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="add($event, 3)"
                  (input)="onSearchChange($event.target.value, 3)"
                />
              </mat-chip-list>
              <mat-placeholder
                [ngClass]="
                  tagsIncomingUnAnswerCall.length > 0
                    ? 'placeholder removePlaceHolder'
                    : 'placeholder'
                "
                >Type tag name and press enter or comma to create a new
                tag</mat-placeholder
              >
              <mat-autocomplete
                #auto3="matAutocomplete"
                (optionSelected)="selected($event, 3)"
              >
                <ng-container
                  *ngFor="let tag of filteredTagsIncomingUnAnswerCall | async"
                >
                  <mat-option *ngIf="isVisible(tag, 3)" [value]="tag">
                    {{ tag.label }}
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
            <label style="font-weight: normal; font-size: 13px" class="mt-1"
              >Press Enter or comma to create a new tag</label
            >
            <!-- End Tag Editor -->
          </div>
        </div>
      </div>
    </div>

    <!--leads integration-->
    <div
      class="re-tasks-cont"
      *ngIf="tagAutomationForm?.value?.isTagAutomation"
    >
      <div class="tasktitle">
        <a href="javascript:void(0)" (click)="toggleSection('leadIntegration')">
          <span class="re-name-txt"
            >Leads created from integration (Zapier or Carrot)</span
          >
          <!-- <span class="re-assign-txt"
            >Enabled • XYZ Follow-up sequence • Drip stop configured</span
          > -->
          <span class="re-arrows" *ngIf="!isCollapseVisible('leadIntegration')">
            <i class="fa fa-chevron-circle-up re-teals" aria-hidden="true"></i>
          </span>
          <span class="re-arrows" *ngIf="isCollapseVisible('leadIntegration')">
            <i
              class="fa fa-chevron-circle-down re-blacks"
              aria-hidden="true"
            ></i>
          </span>
        </a>
      </div>
      <div
        class="row pb-3"
        [class.collapse]="isCollapseVisible('leadIntegration')"
      >
        <div class="col-12 col-md-12">
          <div class="re-radiosec">
            <p class="re-radotext">Would you like to enable this</p>
            <label class="re-switch">
              <input
                type="checkbox"
                id="leadcreate"
                formControlName="isTagZap"
                (change)="resetChipIds()"
              />
              <span class="re-slider"></span>
              <span value="false" class="re-text1">Off</span>
              <span value="true" class="re-text2">On</span>
            </label>
          </div>
          <div *ngIf="tagAutomationForm?.value?.isTagZap">
            <label class="labels"
              >Auto assign Tags to leads created from integration
            </label>
            <!-- Tag Editor -->
            <mat-form-field class="re-chip-list">
              <mat-chip-list #chipList4 aria-label="Tag selection">
                <mat-chip
                  *ngFor="let tag of tagsZapierOrCarrot; let tagIndex = index"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(tagIndex, 4)"
                >
                  {{ tag.label }}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>

                <input
                  #tagInputZapierOrCarrot
                  [formControl]="filteredTagsZapierOrCarrot"
                  [matAutocomplete]="auto4"
                  [matChipInputFor]="chipList4"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="add($event, 4)"
                  (input)="onSearchChange($event.target.value, 4)"
                />
              </mat-chip-list>
              <mat-placeholder
                [ngClass]="
                  tagsZapierOrCarrot.length > 0
                    ? 'placeholder removePlaceHolder'
                    : 'placeholder'
                "
                >Type tag name and press enter or comma to create a new
                tag</mat-placeholder
              >
              <mat-autocomplete
                #auto4="matAutocomplete"
                (optionSelected)="selected($event, 4)"
              >
                <ng-container
                  *ngFor="let tag of filteredTagsZapierOrCarrot | async"
                >
                  <mat-option *ngIf="isVisible(tag, 4)" [value]="tag">
                    {{ tag.label }}
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
            <label style="font-weight: normal; font-size: 13px" class="mt-1"
              >Press Enter or comma to create a new tag</label
            >
            <!-- End Tag Editor -->
          </div>
        </div>
      </div>
    </div>

    <!--leads web form-->
    <div
      class="re-tasks-cont"
      *ngIf="tagAutomationForm?.value?.isTagAutomation"
    >
      <div class="tasktitle">
        <a href="javascript:void(0)" (click)="toggleSection('leadWebForm')">
          <span class="re-name-txt">Leads created from Web Forms</span>
          <!-- <span class="re-assign-txt"
            >Enabled • XYZ Follow-up sequence • Drip stop configured</span
          > -->
          <span class="re-arrows" *ngIf="!isCollapseVisible('leadWebForm')">
            <i class="fa fa-chevron-circle-up re-teals" aria-hidden="true"></i>
          </span>
          <span class="re-arrows" *ngIf="isCollapseVisible('leadWebForm')">
            <i
              class="fa fa-chevron-circle-down re-blacks"
              aria-hidden="true"
            ></i>
          </span>
        </a>
      </div>
      <div class="row pb-3" [class.collapse]="isCollapseVisible('leadWebForm')">
        <div class="col-12 col-md-12">
          <div class="re-radiosec">
            <p class="re-radotext">Would you like to enable this</p>
            <label class="re-switch">
              <input
                type="checkbox"
                id="leadcreate"
                formControlName="isTagWebForm"
                (change)="resetChipIds()"
              />
              <span class="re-slider"></span>
              <span value="false" class="re-text1">Off</span>
              <span value="true" class="re-text2">On</span>
            </label>
          </div>
          <div *ngIf="tagAutomationForm?.value?.isTagWebForm">
            <label class="labels"
              >Auto assign Tags to leads created from Web Forms
            </label>
            <!-- Tag Editor -->
            <mat-form-field class="re-chip-list">
              <mat-chip-list #chipList5 aria-label="Tag selection">
                <mat-chip
                  *ngFor="let tag of tagsWebForms; let tagIndex = index"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(tagIndex, 5)"
                >
                  {{ tag.label }}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>

                <input
                  #tagInputWebForms
                  [formControl]="filteredTagsWebForms"
                  [matAutocomplete]="auto5"
                  [matChipInputFor]="chipList5"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="add($event, 5)"
                  (input)="onSearchChange($event.target.value, 5)"
                />
              </mat-chip-list>
              <mat-placeholder
                [ngClass]="
                  tagsWebForms.length > 0
                    ? 'placeholder removePlaceHolder'
                    : 'placeholder'
                "
                >Type tag name and press enter or comma to create a new
                tag</mat-placeholder
              >
              <mat-autocomplete
                #auto5="matAutocomplete"
                (optionSelected)="selected($event, 5)"
              >
                <ng-container *ngFor="let tag of filteredTagsWebForms | async">
                  <mat-option *ngIf="isVisible(tag, 5)" [value]="tag">
                    {{ tag.label }}
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
            <label style="font-weight: normal; font-size: 13px" class="mt-1"
              >Press Enter or comma to create a new tag</label
            >
            <!-- End Tag Editor -->
          </div>
        </div>
      </div>
    </div>

    <!--leads web Site-->
    <div
      class="re-tasks-cont"
      *ngIf="tagAutomationForm?.value?.isTagAutomation"
    >
      <div class="tasktitle">
        <a href="javascript:void(0)" (click)="toggleSection('leadWebSite')">
          <span class="re-name-txt">Leads created from REsimpli Website</span>
          <!-- <span class="re-assign-txt"
        >Enabled • XYZ Follow-up sequence • Drip stop configured</span
      > -->
          <span class="re-arrows" *ngIf="!isCollapseVisible('leadWebSite')">
            <i class="fa fa-chevron-circle-up re-teals" aria-hidden="true"></i>
          </span>
          <span class="re-arrows" *ngIf="isCollapseVisible('leadWebSite')">
            <i
              class="fa fa-chevron-circle-down re-blacks"
              aria-hidden="true"
            ></i>
          </span>
        </a>
      </div>
      <div class="row pb-3" [class.collapse]="isCollapseVisible('leadWebSite')">
        <div class="col-12 col-md-12">
          <div class="re-radiosec">
            <p class="re-radotext">Would you like to enable this</p>
            <label class="re-switch">
              <input
                type="checkbox"
                id="leadcreate"
                formControlName="isTagWebSite"
                (change)="resetChipIds()"
              />
              <span class="re-slider"></span>
              <span value="false" class="re-text1">Off</span>
              <span value="true" class="re-text2">On</span>
            </label>
          </div>
          <div *ngIf="tagAutomationForm?.value?.isTagWebSite">
            <label class="labels"
              >Auto assign Tags to leads created from Web Site
            </label>
            <!-- Tag Editor -->
            <mat-form-field class="re-chip-list">
              <mat-chip-list #chipList6 aria-label="Tag selection">
                <mat-chip
                  *ngFor="let tag of tagsWebSite; let tagIndex = index"
                  [selectable]="selectable"
                  [removable]="removable"
                  (removed)="remove(tagIndex, 6)"
                >
                  {{ tag.label }}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>

                <input
                  #tagInputWebSite
                  [formControl]="filteredTagsWebSite"
                  [matAutocomplete]="auto6"
                  [matChipInputFor]="chipList6"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="add($event, 6)"
                  (input)="onSearchChange($event.target.value, 6)"
                />
              </mat-chip-list>
              <mat-placeholder
                [ngClass]="
                  tagsWebSite.length > 0
                    ? 'placeholder removePlaceHolder'
                    : 'placeholder'
                "
                >Type tag name and press enter or comma to create a new
                tag</mat-placeholder
              >
              <mat-autocomplete
                #auto6="matAutocomplete"
                (optionSelected)="selected($event, 6)"
              >
                <ng-container *ngFor="let tag of filteredTagsWebSite | async">
                  <mat-option *ngIf="isVisible(tag, 6)" [value]="tag">
                    {{ tag.label }}
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
            <label style="font-weight: normal; font-size: 13px" class="mt-1"
              >Press Enter or comma to create a new tag</label
            >
            <!-- End Tag Editor -->
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="re-unauthorized" *ngIf="isAllowManageNumberFeature == false">
    <img
      class="img-fluid"
      src="assets/images/unauthrozied.png"
      alt="unauthrozied"
    />
    <h4>Upgrade Your Account</h4>
    <div class="re-text">
      Automations are only available on <u>Pro</u> and <u>Enterprise</u> plans.
      <u>Automate your leads follow ups</u> by upgrading your plan.
    </div>
    <!-- <a class="btn re-teal-btn">Upgrade Plan</a> -->
  </div>
</form>
