<div class="row re-header">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      {{ data?.title }}
    </h2>
    <span (click)="closeModal()" style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<mat-dialog-content class="re-typography">
  <div class="" *ngIf="data?.subModule == 'Tag' || data?.subModule == 'List'">
    <div class="re-tags-list" *ngIf="data?.allData?.length">
      <h5>Added {{ data?.subModule }}s</h5>
      <div class="re-each-list" *ngFor="let eachData of data?.allData">
        <div class="row">
          <div class="col-md-10">
            {{ eachData?.name }}
          </div>
          <div class="col-md-2">
            <button (click)="confirmRemoveTags(data?.subModule, eachData?._id)">
              <img src="assets/images/deleteActive.svg" alt="delete" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="re-box">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <label class="re-labels"
              >Create a new {{ data?.subModule }}
              <span class="re-req-icon">*</span></label
            >
            <div class="re-newtags">
              <input
                type="text"
                class="form-control"
                placeholder="New {{ data?.subModule }} title"
                name="newTagName"
                [(ngModel)]="newTagName"
              />
              <a
                class="btn"
                href="javascript:void(0);"
                (click)="addNew(data?.subModule)"
                >Add New {{ data?.subModule }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-0" />
    <div class="re-box">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group" *ngIf="data?.subModule == 'Tag'">
            <label class="re-labels"
              >Add selected properties to an existing tag(s)
              <span class="re-req-icon">*</span></label
            >
            <p-autoComplete
              #autocomplete
              (onFocus)="autocomplete.handleDropdownClick(1)"
              (onSelect)="autocomplete.handleDropdownClick(1)"
              (onClear)="clearListItem(autocomplete)"
              class="listSelect"
              name="TagId"
              [(ngModel)]="TagId"
              [suggestions]="tagsData"
              emptyMessage="{{ noDataAvailable }}"
              placeholder="Select Tag"
              (completeMethod)="addTagSearch($event.query)"
              field="name"
              dataKey="_id"
              [multiple]="true"
              [dropdown]="true"
            >
            </p-autoComplete>
          </div>
          <div class="form-group" *ngIf="data?.subModule == 'List'">
            <label class="re-labels"
              >Add selected properties to an existing list
              <span class="re-req-icon">*</span></label
            >
            <p-autoComplete
              #autocomplete
              (onFocus)="autocomplete.handleDropdownClick(1)"
              (onSelect)="autocomplete.handleDropdownClick(1)"
              (onClear)="clearListItem(autocomplete)"
              class="listSelect"
              name="ListId"
              [(ngModel)]="ListId"
              [suggestions]="listslistData"
              emptyMessage="{{ noDataAvailable }}"
              placeholder="Select List"
              (completeMethod)="searchAddList($event.query)"
              field="name"
              dataKey="_id"
              [dropdown]="true"
              [multiple]="true"
              appendTo="body"
            >
            </p-autoComplete>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="" *ngIf="data?.subModule == 'Skip'">
    <div class="re-box">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <label class="re-labels">Name Skip Trace</label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="skipTraceName"
              id="skipTraceNameField"
              (keypress)="pressSkipEnter($event.which)"
              placeholder="Skip trace title"
            />
            <span class="re-labelnote"
              >You will be only charged
              <span class="re-skip-charge">$0.15</span> per match to records.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="" *ngIf="data?.subModule == 'Optout'">
    <div class="re-box">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <p>
              {{ data?.optValue ? "Add" : "Remove" }} selected properties
              {{ data?.optValue ? "to" : "from" }}
              OptOut
            </p>
            <!-- <span class="text-danger">This action can not be undone. </span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="" *ngIf="data?.subModule == 'Direct Mail'">
    <div class="re-box">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <p>Add selected {{ data?.rows }} properties to Direct Mail</p>
            <!-- <span class="text-danger">This action can not be undone. </span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="padding-top: 14px !important">
  <button
    type="button"
    class="btn btn-default re-cancelbtn"
    (click)="closeModal()"
  >
    Cancel
  </button>
  <button
    type="button"
    class="btn save"
    *ngIf="data?.subModule == 'Tag'"
    (click)="updatemultiTags()"
  >
    Add Properties To Tags
  </button>
  <button
    type="button"
    class="btn save"
    *ngIf="data?.subModule == 'List'"
    (click)="updatemultiLists()"
  >
    Add Properties To List
  </button>
  <button
    type="button"
    class="btn save"
    *ngIf="data?.subModule == 'Skip'"
    (click)="checkSkipTraceCredit()"
  >
    Proceed
  </button>
  <button
    type="button"
    class="btn save"
    *ngIf="data?.subModule == 'Optout'"
    (click)="updatemultioptouts()"
  >
    Update Optout
  </button>
  <button
    type="button"
    class="btn save"
    *ngIf="data?.subModule == 'Direct Mail'"
    (click)="createCSV()"
  >
    Order Direct Mail
  </button>
</mat-dialog-actions>
