import { Component, Inject, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

// UTILS
import { MessageConstant } from 'src/app/utils/message-constant';

declare var google: any;
declare namespace google.maps.places {
  export interface PlaceResult {
    geometry;
  }
}

@Component({
  selector: 'app-street-map',
  templateUrl: './street-map.component.html',
  styleUrls: ['./street-map.component.scss'],
})
export class StreetMapComponent implements AfterViewInit {
  streetview;
  address;

  messageConstant = MessageConstant;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngAfterViewInit(): void {
    if (this.data.address) {
      this.geocoder(this.data.address, this.data.responseAddress);
    }
  }

  geocoder(address?, responseAddress?) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        address: address,
      },
      (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          if (responseAddress == '') {
            responseAddress = results[0].geometry.location;
          }
          const myStreetView = new google.maps.StreetViewPanorama(
            document.getElementById('re-street-view-container')
          );
          myStreetView.setPosition(responseAddress);

          google.maps.event.addListenerOnce(
            myStreetView,
            'status_changed',
            () => {
              const status = myStreetView.getStatus();
              if (status == 'OK') {
                this.streetview = true;
              } else {
                this.streetview = false;
                myStreetView.setVisible(false);
              }

              if (myStreetView.getLocation()) {
                const heading = google.maps.geometry.spherical?.computeHeading(
                  myStreetView.getLocation()?.latLng,
                  results[0].geometry.location
                );
                myStreetView.setPov({
                  heading: heading,
                  pitch: 0,
                  zoom: 0,
                });
                setTimeout(function () {
                  const marker = new google.maps.Marker({
                    position: results[0].geometry.location,
                    map: myStreetView,
                    title: address,
                  });
                  if (marker && marker.setMap) marker.setMap(myStreetView);
                }, 500);
              }
            }
          );
        }
      }
    );
  }
}
