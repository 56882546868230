<div class="re-modal">
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between"
        style="display: block !important"
      >
        {{ isEdit ? "Edit" : "Add" }} Contingency
      </h2>

      <span matDialogClose mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="padding-right: 5px; margin-top: 15px"
        />
      </span>
    </div>
  </div>

  <form
    [formGroup]="contingencyForm"
    (ngSubmit)="onSubmit()"
    autocomplete="off"
    novalidate
    class="mt-3"
  >
    <mat-dialog-content class="px-3">
      <div class="row">
        <!-- CONTINGENCY TYPE -->
        <div class="col-12">
          <div class="form-group">
            <label class="labels">Transaction Type</label>
            <mat-radio-group
              aria-label="Select Transaction Type"
              formControlName="contingencyType"
            >
              <mat-radio-button class="mr-2" [value]="0">
                Buy Side
              </mat-radio-button>

              <mat-radio-button class="mr-2" [value]="1">
                Sell Side
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <!-- CONTINGENCY NAME -->
        <div class="col-12 col-md-6 col-sm-12">
          <div class="form-group">
            <label class="labels">
              Select Contingency <span class="text-danger">*</span>
            </label>

            <select
              class="form-control"
              name="contingencyTypeId"
              formControlName="contingencyTypeId"
            >
              <option value="">Select Contingency</option>

              <option *ngFor="let item of contingencies" [value]="item?.value">
                {{ item.label }}
              </option>
            </select>

            <div class="text-danger re-error-text">
              <span
                *ngIf="isSubmitted && hasError('contingencyTypeId', 'required')"
              >
                {{
                  messageConstant.requiredField.replace("Field", "Contingency")
                }}
              </span>
            </div>
          </div>
        </div>

        <!-- CONTINGENCY EXPIRATION -->
        <div class="col-12 col-md-6 col-sm-12">
          <div class="form-group">
            <label class="labels">
              Contingency Expiration <span class="text-danger">*</span>
            </label>

            <p-calendar
              formControlName="contingencyExpiration"
              styleClass="ngCalendarClass"
              [showIcon]="true"
              [icon]="'fa fa-calendar'"
              appendTo="body"
              readonlyInput="true"
              placeholder="Select Contingency Expiration"
            >
            </p-calendar>

            <div class="text-danger re-error-text">
              <span
                *ngIf="
                  isSubmitted && hasError('contingencyExpiration', 'required')
                "
              >
                {{
                  messageConstant.requiredField.replace(
                    "Field",
                    "Contingency Expiration"
                  )
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="center" style="padding-top: 14px !important">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" type="submit" cdkFocusInitial>
        {{ isEdit ? "Edit" : "Save" }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
