export class directMailConstant {
  public static get allCategoryData() {
    return [
      {
        _id: 'interior',
        name: 'Interior',
        count: 0,
        subCategory: [
          {
            _id: 'framing',
            name: 'Framing',
            count: 0,
            subSubCategory: [
              {
                _id: 'framewalls',
                name: 'Frame Walls',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'framefloor',
                name: 'Frame Floor',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'frameceiling',
                name: 'Frame Ceiling',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'otherframingjobs',
                name: 'Other Framing Jobs',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'doorstrims',
            name: 'Doors and Trims',
            count: 0,
            subSubCategory: [
              {
                _id: 'exteriorentrydoor',
                name: 'Exterior Entry Door',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'exteriordoorhardware',
                name: 'Exterior Door Hardware',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'screenstormdoor',
                name: 'Screen/Storm Door',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'frenchdoor',
                name: 'French Door',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'slidingpatiodoor',
                name: 'Sliding Patio Door',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'interiordoor',
                name: 'Interior Door',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'interiordoorhardware',
                name: 'Interior Door Hardware',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'doorstopper',
                name: 'Door Stopper',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'closetdoors',
                name: 'Closet Doors',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'wainscotingpaneling',
                name: 'Wainscoting Paneling',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'baseboard',
                name: 'Baseboard',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'quarterround',
                name: 'Quarterround',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'crownmolding',
                name: 'Crown Molding',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'windowanddoorcasing',
                name: 'Window and Door Casing',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'drywall',
            name: 'Drywall',
            count: 0,
            subSubCategory: [
              {
                _id: 'patchdrywall',
                name: 'Patch Drywall',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'installnewdrywall',
                name: 'Install New Drywall',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'applytexture',
                name: 'Apply Texture',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replacepaneling',
                name: 'Replace Paneling',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'dropceiling',
                name: 'Drop Ceiling',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'tiling',
            name: 'Tiling',
            count: 0,
            subSubCategory: [
              {
                _id: 'flooring',
                name: 'Flooring',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'backsplashtile',
                name: 'Backsplash Tile',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'showertile',
                name: 'Shower Tile',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'showeraccenttile',
                name: 'Shower Accent Tile',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'fireplacetile',
                name: 'Fireplace Tile',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'insulation',
            name: 'Insulation',
            count: 0,
            subSubCategory: [
              {
                _id: 'wallinsulation',
                name: 'Wall Insulation',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'floorinsulation',
                name: 'Floor Insulation',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'atticinsulation',
                name: 'Attic Insulation',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'kitchenbathroomscabinet',
            name: 'Kitchen & Bathroom Cabinets',
            count: 0,
            subSubCategory: [
              {
                _id: 'kitchencabinets',
                name: 'Kitchen Cabinets',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'bathroomvanity',
                name: 'Bathroom Vanity',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'countertops',
            name: 'Counter Tops',
            count: 0,
            subSubCategory: [
              {
                _id: 'countertoplaminate',
                name: 'Counter Top Laminate',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'countertopsgranite',
                name: 'Counter Tops Granite',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'bathroomvanitytops',
                name: 'Bathroom Vanity Tops',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'flooring',
            name: 'Flooring',
            count: 0,
            subSubCategory: [
              {
                _id: 'replacesubfloor',
                name: 'Replace Sub Floor',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'rolledvinyl',
                name: 'Rolled Vinyl',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'vinylpeelandsticktiles',
                name: 'Vinyl Peel and Stick Tiles',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'vinylplank',
                name: 'Vinyl plank',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'installcarpet',
                name: 'Install Carpet',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'cleancarpet',
                name: 'Clean Carpet',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'installlaminatewood',
                name: 'Install Laminate Wood',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'installengineeredwood',
                name: 'Install Engineered Wood',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'installhardwood',
                name: 'Install Hardwood',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'sandrefinishhardwood',
                name: 'Sand Refinish Hardwood',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
        ],
      },
      {
        _id: 'exterior',
        name: 'Exterior',
        count: 0,
        subCategory: [
          {
            _id: 'decksporchessteps',
            name: 'Decks/Porches/Steps',
            count: 0,
            subSubCategory: [
              {
                _id: 'frontwoodensteps',
                name: 'Front Wooden Steps',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'backwoodensteps',
                name: 'Back Wooden Steps',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'woodenhandrails',
                name: 'Wooden Handrails',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replacedamagedboards',
                name: 'Replace Damaged Boards',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'sandrefinishdeck',
                name: 'Sand & Refinish Deck',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'buildnewdeck',
                name: 'Build New Deck',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'fence',
            name: 'Fence',
            count: 0,
            subSubCategory: [
              {
                _id: 'repairfence',
                name: 'Repair Fence',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replacefence',
                name: 'Replace Fence',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'fencegate',
                name: 'Fence Gate',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'gutterssoffitfascia',
            name: 'Gutter/Soffit/Fascia',
            count: 0,
            subSubCategory: [
              {
                _id: 'cleangutters',
                name: 'Clean Gutters',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replacegutters',
                name: 'Replace Gutters',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'downspoutsextension',
                name: 'Down Spouts Extension',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'wrapfasciainaluminum',
                name: 'Wrap Fasciain Aluminum',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replacefascia',
                name: 'Replace Fascia',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replacesoffit',
                name: 'Replace Soffit',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'roof',
            name: 'Roof',
            count: 0,
            subSubCategory: [
              {
                _id: 'roofrepair',
                name: 'Roof Repair',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'addanotherlayeronexistinglayer',
                name: 'Add Another Layer on Existing Layer',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'tearoffandputnewlayer',
                name: 'Tear Off and Put New Layer',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'premiumfor3layerstearoff',
                name: 'Premium for 3 layers tear-off',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'premiumforsteeppitchedroof',
                name: 'Premium for steep pitched roof',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replacesheathing',
                name: 'Replace Sheathing',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'siding',
            name: 'Siding',
            count: 0,
            subSubCategory: [
              {
                _id: 'patchsiding',
                name: 'Patch Siding',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'addanotherlayeronexistingsiding',
                name: 'Add Another Layer on Existing Siding',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'demoexistingsidingandinstallnewsiding',
                name: 'Demo Existing Siding and Install New Siding',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replacesheathing',
                name: 'Replace Sheathing',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replacehousewrap',
                name: 'Replace House Wrap',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'installinsulation',
                name: 'Install Insulation',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'swimmingpool',
            name: 'Swimming Pool',
            count: 0,
            subSubCategory: [
              {
                _id: 'servicepool',
                name: 'Service Pool',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'checkpump',
                name: 'Check Pump',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'checkpooldrains',
                name: 'Check Pool Drains',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'redoplaster',
                name: 'Redo Plaster',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'redoentireswimmingpool',
                name: 'Redo Entire Swimming Pool',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'removepool',
                name: 'Remove Pool',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'windows',
            name: 'Windows',
            count: 0,
            subSubCategory: [
              {
                _id: 'replacewindowscreen',
                name: 'Replace Window Screen',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replaceglass',
                name: 'Replace Glass',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'skylight',
                name: 'Sky Light',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replacewindows',
                name: 'Replace Windows',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'fixwindows',
                name: 'Fix Windows',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'landscaping',
            name: 'Landscaping',
            count: 0,
            subSubCategory: [
              {
                _id: 'cleanupyard',
                name: 'Cleanup Yard',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'lawnmaintenance',
                name: 'Lawn Maintenance',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'trimbushes',
                name: 'Trim Bushes',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'trimtree',
                name: 'Trim Tree',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'removetree',
                name: 'Remove Tree',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'installmulch',
                name: 'Install Mulch',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'installsod',
                name: 'Install Sod',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'planttree',
                name: 'Plant Tree',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
        ],
      },
      {
        _id: 'painting',
        name: 'Painting',
        count: 0,
        subCategory: [
          {
            _id: 'exteriorpainting',
            name: 'Exterior Painting',
            count: 0,
            subSubCategory: [
              {
                _id: 'powerwash',
                name: 'Power Wash',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'paintexterior',
                name: 'Paint Exterior',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'paintexteriortrim',
                name: 'Paint Exterior Trim',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'paintgaragedoor',
                name: 'Paint Garage Door',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'paintfence',
                name: 'Paint Fence',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'paintconcretefloor',
                name: 'Paint Concrete Floor',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'paintdeck',
                name: 'Paint Deck',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'interiorpainting',
            name: 'Interior Painting',
            count: 0,
            subSubCategory: [
              {
                _id: 'prepdrywallforpaint',
                name: 'Prep Dry Wall For Paint',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'paintwalls',
                name: 'Paint Walls',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'paintceilings',
                name: 'Paint Ceilings',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'paintdoors',
                name: 'Paint Doors',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'painttrim',
                name: 'Paint Trim',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'refinishandpaintkitchencabinets',
                name: 'Refinish And Paint Kitchen Cabinets',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'paintconcretefloor',
                name: 'Paint Concrete Floor',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
        ],
      },
      {
        _id: 'structuralfoundation',
        name: 'Structural/Foundation',
        count: 0,
        subCategory: [
          {
            _id: 'concreteasphalt',
            name: 'Concrete/Asphalt',
            count: 0,
            subSubCategory: [
              {
                _id: 'demoexistingconcreteasphalt',
                name: 'Demo Existing Concrete Asphalt',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'concreteinstalledforpatio',
                name: 'Concrete Installed for Patio',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'asphaltdriveway',
                name: 'Asphalt Driveway',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'concreteretainingwall',
                name: 'Concrete Retaining Wall',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'concreteinstalledfordriveway',
                name: 'Concrete Installed for Driveway',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'otherconcretejobs',
                name: 'Other Concrete Jobs',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'foundation',
            name: 'Foundation',
            count: 0,
            subSubCategory: [
              {
                _id: 'structuralengineeranalysis',
                name: 'Structural Engineer Analysis',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'fixbowedwalls',
                name: 'Fix Bowed Walls',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'fixedcrackedfoundation',
                name: 'Fixed Cracked Foundation',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'installibeams',
                name: 'Install i-Beams',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replacefoundationwall',
                name: 'Replace Foundation Wall',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'otherfoundationrepair',
                name: 'Other Foundation Repair',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'masonry',
            name: 'Masonry',
            count: 0,
            subSubCategory: [
              {
                _id: 'tuckpointing',
                name: 'Tuck-pointing',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'fireplacechimney',
                name: 'Fireplace/Chimney',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'stoneretainingwall',
                name: 'Stone Retaining Wall',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'stonework',
                name: 'Stonework',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'brickwork',
                name: 'Brickwork',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'othermasonrywork',
                name: 'other Masonry Work',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'spectic',
            name: 'Spectic System',
            count: 0,
            subSubCategory: [
              {
                _id: 'inspectcleanseptictank',
                name: 'Inspect/Clean Septic Tank',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'newseptictank',
                name: 'New Septic Tank',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replaceleachfield',
                name: 'Replace Leach Field',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'allnewsepticsystem',
                name: 'All New Septic System',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'waterproofing',
            name: 'Water Proofing',
            count: 0,
            subSubCategory: [
              {
                _id: 'sumppump',
                name: 'Sump Pump',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'sealbasement',
                name: 'Seal Basement',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'installdraintiles',
                name: 'Install Drain Tiles',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'installfrenchdrains',
                name: 'Install French Drains',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
        ],
      },
      {
        _id: 'mechanicals',
        name: 'Mechanicals',
        count: 0,
        subCategory: [
          {
            _id: 'plumbing',
            name: 'Plumbing',
            count: 0,
            subSubCategory: [
              {
                _id: 'replacemainline',
                name: 'Replace Mainline',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'gaslinepressuretest',
                name: 'Gasline Pressure Test',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replacewaterheater',
                name: 'Replace Water Heater',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replaceallsupplylines',
                name: 'Replace All Supply Lines',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replacewasherbox',
                name: 'Replace Washer Box',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'fixleak',
                name: 'Fix Leak',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'unclogdrain',
                name: 'Unclog Drain',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'buildtileshower',
                name: 'Build Tile Shower',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'installtoilet',
                name: 'Install Toilet',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'installbathtub',
                name: 'Install Bathtub',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'showerbathtubfaucet',
                name: 'Shower Bathtub Faucet',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'bathroomsink',
                name: 'Bathroom Sink',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'pedestalsinkbase',
                name: 'Pedestal Sink Base',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'bathroomsinkfaucet',
                name: 'Bathroom Sink Faucet',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'installdishwasher',
                name: 'Install Dishwasher',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'installgarbagedisposal',
                name: 'Install Garbage Disposal',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'kitchensink',
                name: 'Kitchen Sink',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'kitchensinkfaucet',
                name: 'Kitchen Sink Faucet',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'refrigeratorsupplyline',
                name: 'Refrigerator Supplyline',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'otherplumbing',
                name: 'Other Plumbing',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'electric',
            name: 'Electric',
            count: 0,
            subSubCategory: [
              {
                _id: 'upgradeservice',
                name: 'Upgrade Service',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replacecircuitpanel',
                name: 'Replace Circuit Panel',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'rewirehouse',
                name: 'Rewire House',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'addnewcircuit',
                name: 'Add New Circuit',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'addnewoutletbox',
                name: 'Add New Outlet Box',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'upgradeoutlettogfci',
                name: 'Upgrade Outlet to GFCI',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'addnewswitch',
                name: 'Add New Switch',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'exteriorlight',
                name: 'Exterior Light',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'canlight',
                name: 'Can Light',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'flushmountlight',
                name: 'Flush Mount Light',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'foyerlight',
                name: 'Foyer Light',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'diningroomchandelier',
                name: 'Dining Room Chandelier',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'bathroomvanitylight',
                name: 'Bathroom Vanity Light',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'kitchentracklight',
                name: 'Kitchen Track Light',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'kitchenpendantlight',
                name: 'Kitchen Pendant Light',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'installbathroomexhaustfanlightcombo',
                name: 'Install Bathroom Exhaust Fan/Light Combo',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'installceilingfan',
                name: 'Install Ceiling Fan',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replacegfci',
                name: 'Replace GFCI',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replaceoutlet',
                name: 'Replace Outlet',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replaceoutletcovers',
                name: 'Replace Outlet Covers',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'blankwallplates',
                name: 'Blank Wall Plates',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replacelightswitch',
                name: 'Replace Light Switch',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replacelightswitchcovers',
                name: 'Replace Light Switch Covers',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'hardwiredsmokedetector',
                name: 'Hard Wired Smoke Detector',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'otherelectric',
                name: 'Other Electric',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'hvac',
            name: 'HVAC',
            count: 0,
            subSubCategory: [
              {
                _id: 'hvacmaintenance',
                name: 'HVAC Maintenance',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'newductwork',
                name: 'New Duct Work',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'installforcedairfurnace',
                name: 'Install Forced-Air Furnace',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'installnewac',
                name: 'Install New AC',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replacethermostat',
                name: 'Replace Thermostat',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'cleanducts',
                name: 'Clean Ducts',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'otherhvac',
                name: 'Other HVAC',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
        ],
      },
      {
        _id: 'environmental',
        name: 'Environmental',
        count: 0,
        subCategory: [
          {
            _id: 'asbestos',
            name: 'Asbestos',
            count: 0,
            subSubCategory: [
              {
                _id: 'asbestosremovalandabatement',
                name: 'Asbestos Removal and Abatement',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'otherasbestosjob',
                name: 'Other Asbestos Job',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'mold',
            name: 'Mold',
            count: 0,
            subSubCategory: [
              {
                _id: 'airqualitytestaqt',
                name: 'Air Quality Test (AQT)',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'moldremediation',
                name: 'Mold Remediation',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
        ],
      },
      {
        _id: 'other',
        name: 'Other',
        count: 0,
        subCategory: [
          {
            _id: 'appliances',
            name: 'Appliances',
            count: 0,
            subSubCategory: [
              {
                _id: 'refrigerator',
                name: 'Refrigerator',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'range',
                name: 'Range',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'rangehood',
                name: 'Range Hood',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'dishwasher',
                name: 'Dish Washer',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'microwave',
                name: 'Microwave',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'garbagedisposal',
                name: 'Garbage Disposal',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'washer',
                name: 'Washer',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'dryer',
                name: 'Dryer',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'demo',
            name: 'Demo',
            count: 0,
            subSubCategory: [
              {
                _id: 'changelocks',
                name: 'Change Locks',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'installlockbox',
                name: 'Install Lockbox',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'dumpster',
                name: 'Dumpster',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'cleanoutanddemo',
                name: 'Clean-out and Demo',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'garagedoor',
            name: 'Garage Door',
            count: 0,
            subSubCategory: [
              {
                _id: 'servicegaragedoor',
                name: 'Service Garage Door',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replacegaragedoor',
                name: 'Replace Garage Door',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'replacedooropener',
                name: 'Replace Door Opener',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'permitsinspections',
            name: 'Permits Inspections',
            count: 0,
            subSubCategory: [
              {
                _id: 'permitdrawings',
                name: 'Permit Drawings',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'permit',
                name: 'Permit',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'survey',
                name: 'Survey',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'inspection',
                name: 'Inspection',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'pesttermite',
            name: 'Pest Termite',
            count: 0,
            subSubCategory: [
              {
                _id: 'pestcontroltreatment',
                name: 'Pest Control Treatment',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'termiteinspection',
                name: 'Termite Inspection',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'termitetreatment',
                name: 'Termite Treatment',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'termiteletter',
                name: 'Termite Letter',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
          {
            _id: 'punchlist',
            name: 'Punch List',
            count: 0,
            subSubCategory: [
              {
                _id: 'batteryoperatedsmokecodetector',
                name: 'Battery Operated Smoke & CO Detector',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'plastictubsurround',
                name: 'Plastic Tub Surround',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'mailbox',
                name: 'Mailbox',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'heatingventcovers',
                name: 'Heating Vent Covers',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'caulking',
                name: 'Caulking',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'doorbell',
                name: 'Door bell',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'lightbulbs',
                name: 'Light Bulbs',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'piece3bathroomaccessorykit',
                name: 'Piece 3 Bathroom Accessory Kit',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'vanitymirror',
                name: 'Vanity Mirror',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'medicinecabinet',
                name: 'Medicine Cabinet',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'housecleaning',
                name: 'House Cleaning',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'professionalphotography',
                name: 'Professional Photography',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'homestaging',
                name: 'Home Staging',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
              {
                _id: 'other',
                name: 'Other',
                status: false,
                description: '',
                imageurl: [],
                quantity: 0,
                measurement: 'Each',
                unitprice: 0,
                total: 0,
              },
            ],
          },
        ],
      },
    ];
  }
}
