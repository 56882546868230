import {
  Component,
  OnInit,
  Inject,
  HostListener,
  ViewChild,
  ElementRef,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

//service
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DialerService } from 'src/app/providers/dialer/dialer.service';
import { ToastrService } from 'ngx-toastr';
import { ListStackingService } from './../../../providers/list-stacking/list-stacking.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { SmsService } from 'src/app/providers/sms/sms.service';
import { SharedService } from 'src/app/shared/shared.service';
import { MasterTaskService } from 'src/app/providers/master-task/master-task.service';
import { CreditService } from 'src/app/providers/credit/credit.service';
import { BugsnagService } from 'src/app/bugsnag.service';
import { UserService } from 'src/app/providers/user/user.service';
import { LeadsService } from 'src/app/providers/leads/leads.service';
import { CallFlowService } from 'src/app/providers/call-flow/call-flow.service';

//Component
import { TakeNoteDialerComponent } from 'src/app/shared/dialog/cold-call/take-note-dialer/take-note-dialer.component';
import { SendSmsComponent } from 'src/app/shared/dialog/cold-call/send-sms/send-sms.component';
import { ListExportMarketingComponent } from '../list-export-marketing/list-export-marketing.component';
import { AddPhoneDialogComponent } from './add-phone-dialog/add-phone-dialog.component';
import { ChangeNumberDialogComponent } from './change-number-dialog/change-number-dialog.component';
import { AddTagListSkipComponent } from '../../../shared/dialog/add-tag-list-skip/add-tag-list-skip.component';
import { ManagePhoneStatusComponent } from './manage-phone-status/manage-phone-status.component';
import { ManageEmailDialogComponent } from './manage-email-dialog/manage-email-dialog.component';
import { DialerConvertLeadComponent } from 'src/app/shared/dialog/dialer-convert-lead/dialer-convert-lead.component';
import { SmsDialogComponent } from '../sms-dialog/sms-dialog.component';
import { StreetMapComponent } from '../maps/street-map/street-map.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ActivityLogsComponent } from 'src/app/shared/activity-logs/activity-logs.component';

//utils
import { MessageConstant } from './../../../utils/message-constant';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { environment } from 'src/environments/environment';
import { QuillEditorComponent } from 'ngx-quill';
import { UploadInput, UploadOutput } from 'ngx-uploader';
import { BaseUrl } from 'src/app/utils/base-url-constants';
import { timezoneConstant } from 'src/app/utils/timezone-list-constant';
import { TwilioService } from 'src/app/utils/twilio-service/twilio.service';

declare var Plivo;
@Component({
  selector: 'app-cold-call',
  templateUrl: './cold-call.component.html',
  styleUrls: ['./cold-call.component.scss'],
})
export class ColdCallComponent implements OnInit {
  @ViewChild('uploader', { read: ElementRef }) fileInput: ElementRef;
  @HostListener('window:beforeunload', ['$event']) unload(event) {
    if (this.callEndDuration != '00:00:00') {
      this.callStatus = '14';
      this.saveStatus();
    }
    this.endTime = Date.now();
    var totalTime = ((this.endTime - this.startTime) / 60000).toFixed(2);
    this.dialerTimeCount(totalTime);
  }
  @ViewChild('editor') editor: QuillEditorComponent;
  @ViewChild('activityLog') activityLog: ActivityLogsComponent;
  SMSCredit: number = 0;
  editorStyle: {
    height: '300px';
  };
  startTime = Date.now();
  endTime = Date.now();
  countryCode = environment.countryCode;
  editorOptions: any = {};
  uploadInput: EventEmitter<UploadInput>;
  count: number = 0;
  timeOut: any;
  smsDetailForm: FormGroup;
  moduleName: string = 'Dialer';
  style: any;
  leadModuleId: string = '5faa63fdfd20cd581703d255';
  listModuleId: string = '61b7043f79a58008e1663d2b';
  moduleIdd: string = '601cf3c070419f19b74a6882';
  otherReason: string = '';
  campaignId: string = '';
  propertyDetailsId: string = '';
  isPropertyDetails: boolean = false;
  dialed: string = '';
  dialerType: string = 'auto-dialer';
  abandonCall: number = 0;
  isAbandonCall: boolean = false;
  isCallStatusChange: boolean = false;
  templateId: string = '';
  vmId: string = '';
  voiceMailUrl: string = '';
  scriptDetail: any = {};
  isMinimized: boolean = false;
  evt: any;
  currentNumberCall: number = 0;
  currentPage: number = 1;
  campaignColdCallPage: number = 1;
  currentLimit: number = 10; //environment.pagination.pageLimit;
  currentActivityPage: number = 1;
  currentActivityLimit: number = 10;
  campaignDetails: any = {};
  propertyDetails: any = {};
  duplicateData: any = {};
  isVisible: any[] = [];
  userList: any[] = [];
  callDateAttempt: any[] = [];
  callAttempt: any[] = [];
  lastCallStatus: any = {};
  activityDetails: any = {};
  propertyList: any[] = [];
  propertyDefultNumber: any[] = [];
  defultNumber: string = '';
  promises: any[] = [];
  arrayId: any[] = [];
  userImage: any[] = [];
  allPhoneStatus: any[] = [];
  campaignIdList: any[] = [];
  defaultNumberList: any[] = [];
  defaultCallNumber;
  defaultFirstCallNumber;
  lastCallNumber: number = 0;
  totalCallAttempt: number = 0;
  totalPropertyCount: number = 0;
  currentContact: number = 0;
  currentNumber: number = 0;
  currentViewNumber: number = 0;
  numberToCall: string = '';
  displayNumberToCall: string = '';
  fromNumber: string = '';
  propertyDetailLoader: boolean = true;
  sliderBtn: boolean = false;
  scriptDescription: string = '';
  public phoneData: any[] = [];
  smsTemplateList: any[] = [];
  callEndedStat: boolean = false;
  showCallEndedPopup: boolean = false;
  dialogRef;
  lastCallDateTime: string = '';
  callStatus: string = '';
  scheduleDate: Date = new Date();
  scheduleTime: Date = new Date();
  minDate: Date = new Date();
  scheduleZone: string = '';
  plivoBrowserSdk;
  propertyIdList;
  counter: any;
  timerSetCounter: any;
  callUUID: any;
  callReady: boolean = false;
  isCalling: boolean = false;
  showTimerCount: string = '00:00:00';
  callDuration: string = '00:00:00';
  callEndDuration: string = '00:00:00';
  callEndtime: string = '00:00';
  defaultDiaplay: boolean = true;
  makingCall: boolean = false;
  isDialPad: boolean = false;
  callEnd: boolean = false;
  redialCall: boolean = false;
  holdCall: boolean = false;
  isCurrentCall: boolean = true;
  muteCall: boolean = false;
  multipleCall: boolean = false;
  EmptyEmailObject: boolean = false;
  inboxCallEnable: boolean = false;
  campaignScheduled: boolean = false;
  callDialyLimit: boolean = false;
  title: any;
  type: string = 'Dialer';
  logs: any = {};
  activityType: string = '';
  userListing: any[] = [];
  inboxPropertiesId: string = '';
  options = {
    debug: 'ALL',
    permOnClick: true,
    enableTracking: true,
    closeProtection: true,
    maxAverageBitrate: 48000,
  };
  userId: string = '';
  campaignTodayDate: string = '';
  selectPhoneNumber: string = '';
  callAnswerInterval: any;
  statusCheckFlag: boolean = false;
  mergeCallDialer: any = {
    firstCall: false,
    secondCall: false,
    callMerged: false,
    callEnded: true,
    callNotJoined: false,
    checkStatus: false,
    endCause: '',
  };
  public items: string[] = [
    'First_Name',
    'Last_Name',
    'Property_Street_Address',
    'Property_City',
    'Property_State',
    'Property_Zip_Code',
  ];
  callDisplay: string = '';
  public allListslistData: any[] = [];
  public allTagsData: any[] = [];
  public ListNames: any[] = [];
  public TagNames: any[] = [];
  public removeTagsNames: any[] = [];
  public removeListsNames: any[] = [];
  public allMarketData: any[] = [];
  public allPhoneObject: any[] = [];
  public allEmailObject: any[] = [];
  public allPhoneNumberList: any[] = [];
  public allGroupNumberList: any[] = [];
  public marketTypeVal: any[] = [
    { type: 'Direct Mail', count: 0 },
    { type: 'Cold Calling', count: 0 },
    { type: 'SMS', count: 0 },
    {
      type: 'RVM',
      count: 0,
    },
    {
      type: 'All',
      count: 0,
    },
  ];
  public singleListsDMail: any[] = [];
  callConnectedStatus: any;
  callNotConnectedStatus: any;
  showAttachments: any[] = [];
  smsCount: number = 0;
  smsCharCount: number = 0;
  smsFilterBody: String = '';
  attachments: any[] = [];
  submittedSms: boolean = false;
  comment: string = '';
  callBtnAction: boolean = false;
  keptRinging: any = 8;
  leftVM: any = 12;
  moveToLeadStatus: any = 4;
  smsTemplateId: string = '';
  callTimeOut: any = '';
  isPropertyToLead: boolean = false;
  userData: any;
  currentTimezone: any;
  timezoneList: any[] = [];
  minimumDate = new Date();
  minimumTime = new Date();
  scheduledCallFilters: any = {};
  callingFrom: any = '';
  selectedPhoneType: any = [];
  phoneStatusExclude: any[] = [];
  inLeadStatus: string = '';
  allMainStatus: any[] = [];

  isTwilioCall: boolean = false;
  twilioCallSid: any;

  constructor(
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private coldCallDialog: MatDialogRef<ColdCallComponent>,
    private _loaderService: NgxUiLoaderService,
    private _dialerService: DialerService,
    private _toastrService: ToastrService,
    private _listStackingService: ListStackingService,
    public _utilities: CommonFunctionsService,
    private _smsService: SmsService,
    private _userService: UserService,
    private dialog: MatDialog,
    private _sanitizer: DomSanitizer,
    private _sharedService: SharedService,
    private _creditService: CreditService,
    private _masterTaskService: MasterTaskService,
    private _leadService: LeadsService,
    private _bugsnagService: BugsnagService,
    private _fb: FormBuilder,
    private _flowService: CallFlowService,
    private _twilioService: TwilioService
  ) {
    this.isTwilioCall = this._utilities.isTwilioUser;
    this._sharedService.refreshActivityLog = new Subject();
    this._sharedService.refreshActivityLog.subscribe((response) => {
      if (response) {
        setTimeout(() => {
          this.getActivityList();
        }, 2000);
      }
    });
    this._sharedService.refreshDialerToggleDisplay = new Subject();
    this._sharedService.refreshDialerToggleDisplay.subscribe((response) => {
      if (response) {
        setTimeout(() => {
          this.toggleDisplay();
        }, 2000);
      }
    });
    this.uploadInput = new EventEmitter<UploadInput>();
    this.editorOptions = {
      placeholder: this._utilities.commonEditor().placeholder,
      toolbar: this._utilities.commonEditor().toolbar,
      mention: this.mentionEditor(),
    };
  }

  ngOnInit(): void {
    if (this.isTwilioCall) {
      //twilio call disconnect event
      this._twilioService.twilioOutboundHangupEvent = new Subject();
      this._twilioService.twilioOutboundHangupEvent.subscribe((response) => {
        this.callUUID = response?.parameters?.CallSid;
        this.cancel();
      });

      //twilio call accept event
      this._twilioService.twilioOutboundEvent = new Subject();
      this._twilioService.twilioOutboundEvent.subscribe((response) => {
        if (response) {
          this.twilioOutboundCallEvent(response);
        }
      });
    }
    let dateA = moment();
    let dateB = moment();
    this.timerSetCounter = setInterval(() => {
      dateB.add(1, 'seconds');
      this.showTimerCount = moment
        .utc(
          moment(dateB, 'DD/MM/YYYY HH:mm:ss').diff(
            moment(dateA, 'DD/MM/YYYY HH:mm:ss')
          )
        )
        .format('HH:mm:ss');
    }, 1000);

    this.callDialyLimit =
      localStorage.getItem('callDialyLimit') == 'true' ? true : false;
    this.propertyDetailsId = this.data?.propertyDetailsId
      ? this.data?.propertyDetailsId
      : '';
    if (this.propertyDetailsId) {
      this.propertyList.push(this.data?.propertyDetailsId);
    }
    this.isPropertyDetails = this.data?.isPropertyDetails
      ? this.data?.isPropertyDetails
      : false;
    this.currentContact = this.data?.currentContact
      ? this.data?.currentContact
      : 0;
    this.dialed = this.data?.dialed ? this.data?.dialed : '';
    this.allPhoneNumberList = this.data?.phoneNumberId;
    this.callConnectedStatus = this._utilities.callConnectedDialerStatus();
    this.callNotConnectedStatus =
      this._utilities.callNotConnectedDialerStatus();
    this.templateId = this.data?.scriptId;
    this.vmId = this.data?.voiceMailId;
    this.voiceMailUrl = this.data?.voiceMailUrl;
    this.dialerType = this.data?.dialerType;
    this.abandonCall = this.data?.abandonCall;
    this.selectedPhoneType = this.data?.selectedPhoneType;
    this.phoneStatusExclude = this.data?.phoneStatusExclude;

    this.getSMSBalance();
    this.getListsTags();
    this.getLists();
    this.getScriptDetail();
    this.getSubUserList();
    this.getUserRoleList();
    if (
      this.data.campaignId &&
      !this.data?.campaignTodayDate &&
      !this.data?.campaignScheduled &&
      !this.data?.inboxPropertiesId &&
      !this.data.inboxCallEnable
    ) {
      this.campaignId = this.data?.campaignId;
      this.campaignColdCallPage = this.data?.currentCampaignPage;
      this.getCampaignDetail();
    } else if (
      this.data?.campaignScheduled &&
      !this.data?.campaignTodayDate &&
      !this.data.campaignId &&
      !this.data?.inboxPropertiesId &&
      !this.data.inboxCallEnable
    ) {
      this.campaignScheduled = this.data?.campaignScheduled;
      this.campaignColdCallPage = this.data?.scheduledCallPage;
      this.scheduledCallFilters = this.data?.scheduledCallFilters;
      this.getCampaignDetail();
    } else if (
      this.data?.campaignTodayDate &&
      !this.data?.campaignScheduled &&
      !this.data.campaignId &&
      !this.data?.inboxPropertiesId &&
      !this.data.inboxCallEnable
    ) {
      this.campaignTodayDate = this.data?.campaignTodayDate;
      this.campaignColdCallPage = this.data?.todayCallPage;
      this.moduleName = "Today's Call";
      this.getCampaignDetail();
    } else if (
      this.data?.inboxPropertiesId &&
      !this.data?.campaignScheduled &&
      !this.data.campaignId &&
      !this.data?.campaignTodayDate &&
      !this.data.inboxCallEnable
    ) {
      this.inboxPropertiesId = this.data?.inboxPropertiesId;
      this.campaignColdCallPage = 1;
      this.getCampaignDetail();
    } else if (
      this.data.inboxCallEnable &&
      !this.data?.inboxPropertiesId &&
      !this.data?.campaignScheduled &&
      !this.data.campaignId &&
      !this.data?.campaignTodayDate
    ) {
      this.inboxCallEnable = this.data?.inboxCallEnable;
      this.campaignColdCallPage = this.data?.inboxCallPage;
      this.getCampaignDetail();
    }

    if (this.data?.groupNumberId && this.data?.groupNumberId.length > 0) {
      this.allGroupNumberList = this.data?.groupNumberId;
      this.getPhoneNumberList();
    }
    this.smsDetailForm = this._fb.group({
      smsNumber: ['0', Validators.required],
      smsBody: ['', Validators.required],
      mediaUrls: [''],
      smsTemplateId: ['', []],
    });
    this.timezoneList = timezoneConstant.timezoneArray;
    this.minimumDate = new Date(
      moment
        .tz(localStorage.getItem('timezoneOffset'))
        .format('YYYY/MM/DD h:mm:ss A')
    );
    this.minimumTime = new Date(
      moment
        .tz(localStorage.getItem('timezoneOffset'))
        .subtract(1, 'days')
        .format('YYYY/MM/DD h:mm:ss A')
    );
  }
  getPhoneNumberList() {
    var phoneNumberArray = [];
    const data = {
      page: this.currentPage,
      limit: this.currentLimit,
      numTypeId: 3,
      groupId: this.allGroupNumberList,
    };

    this._sharedService.getManageGroupNumbersV2(data).subscribe(
      (response) => {
        if (response && response.statusCode == 200) {
          phoneNumberArray = response.data.items;
          for (let i = 0; i < phoneNumberArray.length; i++) {
            if (!this.allPhoneNumberList.includes(phoneNumberArray[i].number)) {
              this.allPhoneNumberList.push(phoneNumberArray[i].number);
            }
          }
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          this._loaderService.stop();
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }
  getSubUserList() {
    let param = {
      page: 1,
      limit: 100,
    };

    this._userService.getSubUserList(param).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          this.userList = response.data.items;
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message);
        } else {
          this._toastrService.error(MessageConstant.unknownError);
        }
      }
    );
  }
  toggleDisplay() {
    if (this.isMinimized) {
      //this.getLeadDetails(this.leadId);
      if (document.querySelector('.cdk-overlay-backdrop'))
        document.querySelector('.cdk-overlay-backdrop')['style'].display =
          'block';

      if (document.querySelector('.cdk-overlay-pane')) {
        document.querySelector('.cdk-overlay-pane')['style'].position =
          'relative';
        document.querySelector('.cdk-overlay-pane')['style'].height = 'auto';
        document.querySelector('.cdk-overlay-pane')['style'].transform = '';
        document
          .querySelector('.cdk-overlay-pane')
          .classList.remove('re-lead-dialer-minimize');
      }
    } else {
      if (document.querySelector('.cdk-overlay-backdrop'))
        document.querySelector('.cdk-overlay-backdrop')['style'].display =
          'none';
      if (document.querySelector('.cdk-overlay-pane')) {
        document.querySelector('.cdk-overlay-pane')['style'].position =
          'absolute';
        document
          .querySelector('.cdk-overlay-pane')
          .classList.add('re-lead-dialer-minimize');
      }
      this.reset();
    }

    this.isMinimized = !this.isMinimized;
  }
  reset() {
    if (this.evt) this.evt._dragRef.reset();
  }
  initializePlivo() {
    this.plivoBrowserSdk = new Plivo(this.options);

    this.plivoBrowserSdk.client.on('onLogout', () => {});
    this.plivoBrowserSdk.client.on('onLoginFailed', (cause) => {
      this._bugsnagService.upload({ 'onLoginFailed =>': cause }, 'error');
      this._toastrService.warning('Cannot initiate call at the moment.');
    });

    this.plivoBrowserSdk.client.on('onIncomingCallIgnored', () => {
      if (this.plivoBrowserSdk.client.callUUID) {
        this.callUUID = this.plivoBrowserSdk.client.callUUID;
      }
    });
    this.plivoBrowserSdk.client.on('onCallRemoteRinging', () => {
      if (this.plivoBrowserSdk.client.callUUID) {
        this.callUUID = this.plivoBrowserSdk.client.callUUID;
      }
    });
    this.plivoBrowserSdk.client.on('onIncomingCallCanceled', () => {
      if (this.plivoBrowserSdk.client.callUUID) {
        this.callUUID = this.plivoBrowserSdk.client.callUUID;
      }
    });
    this.plivoBrowserSdk.client.on('onCallFailed', (cause, callInfo) => {
      console.log('onCallFailed===cause===>', cause);
      console.log('onCallFailed===callInfo===>', callInfo);
      // this.openCloseCallEnded('Connected');
      this._bugsnagService.upload(
        { 'onCallFailed =>': cause, 'callInfo=>': callInfo },
        'error'
      );
      if (this.plivoBrowserSdk.client.callUUID) {
        this.callUUID = this.plivoBrowserSdk.client.callUUID;
      }
      this._toastrService.clear();
      if (cause == 'Not Found') {
        this._toastrService.error(MessageConstant.callBusyMessage, '');
      } else if (cause == 'Canceled') {
        if (this.abandonCall != 0) {
          setTimeout(() => {
            this._sharedService.refreshActivityLog.next('');
          }, 2000);
          // if (
          //   this.callUUID == null ||
          //   this.callUUID == '' ||
          //   this.callEndDuration == '00:00:00'
          // ) {
          //   this.callStatus = '14';
          //   this.saveStatus();
          // }
          this.openCloseCallEnded('Connected');
          return;
        }
      } else if (cause == 'Busy') {
        this._toastrService.info(MessageConstant.callBusyMessage, '');
      } else {
        this._toastrService.warning(MessageConstant.callNotConnected, '');
      }

      setTimeout(() => {
        this._sharedService.refreshActivityLog.next('');
      }, 2000);
      // if (
      //   this.callUUID == null ||
      //   this.callUUID == '' ||
      //   this.callEndDuration == '00:00:00'
      // ) {
      //   this.callStatus = '14';
      //   this.saveStatus();
      // }
      this.openCloseCallEnded('Connected');
      this.cancel();
    });
    this.plivoBrowserSdk.client.on('onCallAnswered', (callInfo) => {
      if (this.plivoBrowserSdk.client.callUUID) {
        this.callUUID = this.plivoBrowserSdk.client.callUUID;
      } else {
        this.callUUID = callInfo.callUUID;
      }
      this.callReady = true;
      this.callDuration = '00:00:00';
      this.callDisplay = 'Ongoing Call...';
      let a = moment();
      let b = moment();
      if (!this.plivoBrowserSdk.client.callUUID) {
        this.callAnswerInterval = setInterval(() => {
          if (this.statusCheckFlag === false) {
            this._flowService
              .getCallInfo({
                name: callInfo.extraHeaders['X-PH-from'].replace(
                  '+',
                  'Resimpli'
                ),
              })
              .subscribe(
                (response: ResponseModel) => {
                  this.mergeCallDialer = response.data.users;
                  this.statusCheckFlag = response.data.isCheckStatus;
                  if (
                    this.mergeCallDialer.firstCall &&
                    this.callDuration === '00:00:00'
                  ) {
                    this.counter = setInterval(() => {
                      this.showTime(a, b);
                    }, 1000);
                  }
                  if (this.statusCheckFlag === true) {
                    if (this.callAnswerInterval) {
                      clearInterval(this.callAnswerInterval);
                    }
                  }
                },
                (err: ErrorModel) => {
                  this._loaderService.stop();
                }
              );
          }
        }, 1000);
      } else {
        this.counter = setInterval(() => {
          this.showTime(a, b);
        }, 1000);
      }
    });
    this.plivoBrowserSdk.client.on('onMediaConnected', () => {
      if (this.plivoBrowserSdk.client.callUUID) {
        this.callUUID = this.plivoBrowserSdk.client.callUUID;
      }
    });
    this.plivoBrowserSdk.client.on(
      'onCallTerminated',
      (hangupInfo, callInfo) => {
        console.log('onCallTerminated===hangupInfo===>', hangupInfo);
        console.log('onCallTerminated===callInfo===>', callInfo);
        if (this.plivoBrowserSdk.client.callUUID) {
          this.callUUID = this.plivoBrowserSdk.client.callUUID;
        }
        if (this.isCalling) {
          this._toastrService.clear();
          // this._toastrService.error('Call got terminated');
        }
        this.callEndedStat = true;
        this.openCloseCallEnded('Connected');
        this.cancel();
      }
    );
    this.plivoBrowserSdk.client.on('onCalling', () => {
      if (this.plivoBrowserSdk.client.callUUID) {
        this.callUUID = this.plivoBrowserSdk.client.callUUID;
      }
    });
    this.plivoBrowserSdk.client.on('onIncomingCall', () => {
      if (this.plivoBrowserSdk.client.callUUID) {
        this.callUUID = this.plivoBrowserSdk.client.callUUID;
      }
    });
    this.plivoBrowserSdk.client.on('onMediaPermission', () => {
      if (this.plivoBrowserSdk.client.callUUID) {
        this.callUUID = this.plivoBrowserSdk.client.callUUID;
      }
    });
    this.plivoBrowserSdk.client.on('mediaMetrics', () => {
      if (this.plivoBrowserSdk.client.callUUID) {
        this.callUUID = this.plivoBrowserSdk.client.callUUID;
      }
    });
    this.plivoBrowserSdk.client.on('onConnectionChange', () => {
      if (this.plivoBrowserSdk.client.callUUID) {
        this.callUUID = this.plivoBrowserSdk.client.callUUID;
      }
    });
  }
  showTime(dateA, dateB) {
    this.callDisplay = 'Ongoing Call...';
    dateB.add(1, 'seconds');
    this.callDuration = moment
      .utc(
        moment(dateB, 'DD/MM/YYYY HH:mm:ss').diff(
          moment(dateA, 'DD/MM/YYYY HH:mm:ss')
        )
      )
      .format('HH:mm:ss');
    this.callEndDuration = moment
      .utc(
        moment(dateB, 'DD/MM/YYYY HH:mm:ss').diff(
          moment(dateA, 'DD/MM/YYYY HH:mm:ss')
        )
      )
      .format('HH:mm:ss');
  }

  mute() {
    this.muteCall = true;
    if (this.isTwilioCall && this.twilioCallSid) {
      this._twilioService.callMuteUnmute(true);
    }
    if (this.plivoBrowserSdk.client.callUUID) {
      this.plivoBrowserSdk.client.mute();
    } else {
      this.allHoldUnholdMember({
        hold: this.holdCall,
        mute: true,
      });
    }
  }
  unmute() {
    this.muteCall = false;
    if (this.isTwilioCall && this.twilioCallSid) {
      this._twilioService.callMuteUnmute(false);
    }
    if (this.plivoBrowserSdk.client.callUUID) {
      this.plivoBrowserSdk.client.unmute();
    } else {
      this.allHoldUnholdMember({
        hold: this.holdCall,
        mute: false,
      });
    }
  }
  dialPad() {
    this.isDialPad = true;
    this.isCurrentCall = false;
    this.makingCall = false;
  }
  closeDialPad() {
    this.isDialPad = false;
    this.isCurrentCall = true;
    this.makingCall = true;
  }
  dialer(key) {
    if (!key) {
      return;
    }
    this.displayNumberToCall += key;

    if (this.isTwilioCall) {
      this._twilioService.sendDigits(key);
    } else {
      this.plivoBrowserSdk.client.sendDtmf(key);
    }
  }
  clearDialer() {
    this.displayNumberToCall = this.displayNumberToCall.slice(0, -1);
  }
  cancel() {
    if (this.holdCall) {
      this.unHold(true);
      return;
    }
    this._loaderService.stop();
    if (this.callStatus == '4' && !this.propertyDetails?.InLead) {
      this.callStatus = '';
    }
    this.isPropertyToLead = false;
    this.smsDetailForm.patchValue({
      smsTemplateId: this.smsTemplateId.toString(),
    });

    if (this.isTwilioCall) {
      this._twilioService.callHangup();
      this.openCloseCallEnded('Connected');
    } else {
      if (this.plivoBrowserSdk.client.callSession) {
        this.plivoBrowserSdk.client.hangup();
      }
    }

    this.defaultDiaplay = false;
    this.makingCall = false;
    this.isCalling = false;
    this.callReady = false;
    this.isDialPad = false;
    this.callEnd = true;
    this.muteCall = false;
    this.redialCall = true;
    this.holdCall = false;
    this.isCurrentCall = true;
    this.callDisplay = 'Call Ended';
    this.callEndtime = moment()
      .tz(localStorage.timezoneOffset)
      .format('HH:mm A');
    if (this.counter) {
      clearInterval(this.counter);
    }
    if (this.isMinimized) this.toggleDisplay();
  }
  hold() {
    if (this.isTwilioCall && this.twilioCallSid) {
      this._twilioService
        ?.holdCallFn({ callSid: this.twilioCallSid })
        .subscribe(
          (response) => {
            this.holdCall = true;
          },
          (err: ErrorModel) => {
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            }
          }
        );
      this.holdCall = true;
    } else {
      if (this.plivoBrowserSdk.client.callUUID) {
        let obj = { uuid: this.plivoBrowserSdk.client.callUUID };
        //api call
        this._dialerService.holdCallFn(obj).subscribe(
          (response) => {
            if (response.statusCode == 200) {
              this.holdCall = true;
            }
          },
          (err: ErrorModel) => {
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(MessageConstant.unknownError, '');
            }
          }
        );
      } else {
        this.allHoldUnholdMember({
          hold: true,
          mute: this.muteCall,
        });
      }
    }
  }
  unHold(disConnect?) {
    if (this.isTwilioCall) {
      this._twilioService
        ?.unHoldCallFn({ callSid: this.twilioCallSid })
        .subscribe(
          (response) => {
            this.holdCall = false;
            if (disConnect) {
              setTimeout(() => {
                this.cancel();
              }, 2000);
            }
          },
          (err: ErrorModel) => {
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            }
          }
        );
      this.holdCall = false;
    } else {
      if (this.plivoBrowserSdk.client.callUUID) {
        let obj = { uuid: this.plivoBrowserSdk.client.callUUID };
        //api call
        this._dialerService.unHoldCallFn(obj).subscribe(
          (response) => {
            if (response.statusCode == 200) {
              this.holdCall = false;
            }
          },
          (err: ErrorModel) => {
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(MessageConstant.unknownError, '');
            }
          }
        );
      } else {
        this.allHoldUnholdMember({
          hold: false,
          mute: this.muteCall,
        });
      }
    }
  }
  getSMSTemplate() {
    this._smsService.getSmsList({}).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.smsTemplateList = response?.data?.items;
          this.smsTemplateList.sort((a, b) => a.templateName.localeCompare(b.templateName));
          let smsTemplateId = this.smsTemplateList
            .map((template) => {
              if (template.smsTemplateId) {
                return template.smsTemplateId;
              }
            })
            .filter((x) => x)[0];
          this.smsTemplateId = smsTemplateId.toString();
          if (smsTemplateId) {
            this.smsDetailForm.patchValue({
              smsTemplateId: smsTemplateId.toString(),
            });
            this.setSmsTemplate(smsTemplateId, false);
          }
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
        } else {
        }
      }
    );
  }

  addTagList(module) {
    let title = module == 'Tag' ? 'Add Tags' : 'Add Lists';
    let obj = {
      createdAt: -1,
      dataType: 'Self',
      limit: 10,
      page: 1,
      ids: [this.propertyList[this.currentContact]],
    };
    this.dialogRef = this.dialog.open(AddTagListSkipComponent, {
      data: {
        subModule: module,
        title: title,
        filter: obj,
      },
      height: 'auto',
      width: '350px',
      panelClass: 'editRvm',
      disableClose: false,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._loaderService.stop();
        if (module == 'List') {
          this.getLists(result);
        }
        if (module == 'Tag') {
          this.getListsTags(result);
        }
      }
    });
  }

  getLists(result?) {
    this._listStackingService.getLists({}).subscribe(
      (response) => {
        if (response.code == 200) {
          this.allListslistData = response.data;
          if (result) {
            result = result.filter((e) => {
              return !this.propertyDetails.ListId.includes(e);
            });
            this.propertyDetails.ListId =
              this.propertyDetails.ListId.concat(result);
            this.getListNames();
          }
        }
      },
      (err) => {
        console.log('errpr');
      }
    );
  }
  getListsTags(result?) {
    this._listStackingService.getListsTags({}).subscribe(
      (response) => {
        if (response.code == 200) {
          this.allTagsData = response.data;
          if (result) {
            result = result.filter((e) => {
              return !this.propertyDetails.TagId.includes(e);
            });
            this.propertyDetails.TagId =
              this.propertyDetails.TagId.concat(result);
            this.getTagNames();
          }
        }
      },
      (err) => {
        console.log('errpr');
      }
    );
  }

  getListNames() {
    this.ListNames = [];
    let name;
    for (var i = 0; i < this.propertyDetails.ListId.length; i++) {
      var ressw3 = this.propertyDetails.ListId[i];
      for (var j = 0; j < this.allListslistData.length; j++) {
        if (ressw3 == this.allListslistData[j]._id) {
          name = this.allListslistData[j].name;
          this.ListNames.push({
            _id: this.allListslistData[j]._id,
            name: name,
          });
          break;
        }
      }
    }
  }

  getTagNames() {
    this.TagNames = [];
    let name;
    for (var i = 0; i < this.propertyDetails?.TagId.length; i++) {
      var ressw3 = this.propertyDetails?.TagId[i];
      for (var j = 0; j < this.allTagsData.length; j++) {
        if (ressw3 == this.allTagsData[j]?._id) {
          name = this.allTagsData[j]?.name;
          this.TagNames.push({ _id: this.allTagsData[j]?._id, name: name });
          break;
        }
      }
    }
  }

  confirmRemoveTags(mode, _id) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: {
        header: 'Confirmation',
        text: `Are you sure you want to remove this ${mode}?`,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let obj = {
            createdAt: -1,
            dataType: 'Self',
            limit: 10,
            page: 1,
            ids: [this.propertyList[this.currentContact]],
          },
          callFunction;
        if (mode == 'Tag') {
          obj['TagId'] = [_id];
          callFunction = this._listStackingService.updatemultitags;
        } else {
          obj['listId'] = [_id];
          callFunction = this._listStackingService.updatemultilists;
        }
        obj['action'] = 'remove';
        this._loaderService.start();
        callFunction(obj).subscribe(
          (response: ResponseModel) => {
            if (response.code == 200) {
              this._loaderService.stop();
              if (mode == 'Tag') {
                this.propertyDetails.TagId = this.propertyDetails.TagId.filter(
                  (e) => {
                    return e != _id;
                  }
                );
                this.getTagNames();
              } else {
                this.propertyDetails.ListId =
                  this.propertyDetails.ListId.filter((e) => {
                    return e != _id;
                  });
                this.getListNames();
              }
              this.removeTagListName(_id, mode);
              this.addActivityList(mode);
              this._toastrService.success(response.message);
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
          }
        );
      }
    });
  }

  removeTagListName(removeId, mode) {
    this.removeTagsNames = [];
    this.removeListsNames = [];
    let name;
    if (mode == 'Tag') {
      for (var j = 0; j < this.allTagsData.length; j++) {
        if (removeId == this.allTagsData[j]._id) {
          name = this.allTagsData[j].name;
          this.removeTagsNames.push({
            name: name,
          });
          break;
        }
      }
    } else {
      for (var j = 0; j < this.allListslistData.length; j++) {
        if (removeId == this.allListslistData[j]._id) {
          name = this.allListslistData[j].name;
          this.removeListsNames.push({
            name: name,
          });
          break;
        }
      }
    }
  }

  addActivityList(mode) {
    let tagActivity;
    let activityType;
    let activityComment;
    if (mode == 'Tag') {
      activityType = 19;
      tagActivity = 8;
      activityComment = this.removeTagsNames;
    } else {
      activityType = 34;
      tagActivity = 12;
      activityComment = this.removeListsNames;
    }

    this._loaderService.start();
    let myobj = {
      subModuleId: [this.propertyList[this.currentContact]],
      comment: activityComment,
      activityType: activityType,
      subType: tagActivity,
      address: '',
      title: '',
      phoneNumber: '',
    };
    this._dialerService.addActivityList(myobj).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode == 200) {
          this._sharedService.refreshActivityLog.next(true);
        }
      },
      (err) => {
        this._loaderService.stop();
        console.log(err);
      }
    );
  }

  getScriptDetail() {
    this._dialerService
      .getScriptDetail({ scriptId: this.templateId })
      .subscribe(
        (response: ResponseModel) => {
          this.scriptDetail = response?.data;
        },
        (err) => {
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(MessageConstant.unknownError, '');
          }
        }
      );
  }
  getCampaignDetail() {
    this.propertyDetailLoader = true;
    if (this.campaignScheduled) {
      let myObj = {};
      if (Object.keys(this.scheduledCallFilters).length != 0) {
        myObj['filterData'] = { ...this.scheduledCallFilters };
      }
      myObj['page'] = 1;
      myObj['limit'] = 100000;
      myObj['isDialer'] = true;
      myObj['isScheduled'] = true;

      this._dialerService.getScheduledCallDetails(myObj).subscribe(
        (response: ResponseModel) => {
          if (response?.statusCode == 200) {
            this.campaignDetails = response?.data?.campaignDetails || {};
            this.propertyDefultNumber =
              response?.data?.propertyDefultNumber || [];
            let scheduledDateArray = response?.data?.scheduledDateArray || [];
            if (scheduledDateArray.length > 0) {
              scheduledDateArray.forEach((element) => {
                this.propertyList.push(element?.pId);
                this.campaignIdList.push(element?.campaignId);
              });
            }
            this.totalPropertyCount = this.propertyList.length;
            let scheduledPropertyId = this.data.currentPropertyId;
            let scheduledCampaignName = this.data.currentCampaignName;
            if (scheduledPropertyId && scheduledCampaignName) {
              let propertyIndex = scheduledDateArray.findIndex(
                (x) =>
                  x.pId === scheduledPropertyId &&
                  x.campaignName === scheduledCampaignName
              );
              this.currentContact = propertyIndex;
            }
            if (this.propertyList.length > 0) {
              this.getPropertyDetails(true);
            }
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();

          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(MessageConstant.unknownError, '');
          }
        }
      );
    } else if (this.inboxCallEnable) {
      let myObj = {
        page: this.campaignColdCallPage,
        limit: 100000,
        isLs: true,
      };
      this._dialerService.getinboxCallDetails(myObj).subscribe(
        (response: ResponseModel) => {
          if (response?.statusCode == 200) {
            this.campaignDetails = response?.data?.campaignDetails || {};
            this.propertyIdList = response?.data?.propertyDetails || [];
            this.propertyDefultNumber =
              response?.data?.propertyDefultNumber || [];
            let campaignListIds = response?.data?.campaignListIds || [];
            let subModuleListId = response?.data?.subModuleListId || [];
            subModuleListId.forEach((element) => {
              this.campaignDetails.forEach((item) => {
                item.propertyIds.forEach((data) => {
                  if (element == data.pId) {
                    this.propertyList.push(element);
                    this.campaignIdList.push(item._id);
                  }
                });
              });
            });
            this.totalPropertyCount = this.propertyList.length;
            let inboxPropertyListId = this.data.inboxPropertyListId;
            if (inboxPropertyListId) {
              let propertyIndex =
                this.propertyList.indexOf(inboxPropertyListId);
              this.currentContact = propertyIndex;
            }
            if (this.propertyList.length > 0) {
              this.getPropertyDetails(true);
            }
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();

          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(MessageConstant.unknownError, '');
          }
        }
      );
    } else {
      this._dialerService
        .getCampaignPropertyDetails({
          page: 1,
          limit: 100000,
          campaignId: this.campaignId,
          isDialer: this.dialed,
          moduleName: this.moduleName,
          inboxPropertiesId: this.inboxPropertiesId,
        })
        .subscribe(
          (response: ResponseModel) => {
            if (response?.statusCode == 200) {
              this.campaignDetails = response?.data?.campaignDetails || {};
              if (
                this.campaignDetails?.numberToUse &&
                this.campaignDetails?.numberToUse.length
              )
                this.fromNumber = this.campaignDetails?.numberToUse[0]?.number;
              this.propertyIdList = response?.data?.propertyDetails || [];
              this.propertyDefultNumber =
                response?.data?.propertyDefultNumber || [];
              let currentPropertyId = this.data.currentPropertyId;
              if (this.moduleName == "Today's Call") {
                let todayDateArray = response?.data?.todayDateArray || [];
                if (todayDateArray.length > 0) {
                  todayDateArray.forEach((element) => {
                    this.propertyList.push(element?.pId);
                    this.campaignIdList.push(element?.campaignId);
                  });
                }
                let todayCampaignName = this.data.currentCampaignName;
                if (currentPropertyId && todayCampaignName) {
                  let propertyIndex = todayDateArray.findIndex(
                    (x) =>
                      x.pId === currentPropertyId &&
                      x.campaignName === todayCampaignName
                  );
                  this.currentContact = propertyIndex;
                }
              } else {
                for (let i = 0; i < this.propertyIdList.length; i++) {
                  this.propertyList.push(this.propertyIdList[i]._id);
                  if (response?.data?.campaignIdList) {
                    this.campaignIdList.push(
                      response?.data?.campaignIdList[this.propertyIdList[i]._id]
                    );
                  }
                }
                if (currentPropertyId) {
                  let propertyIndex =
                    this.propertyList.indexOf(currentPropertyId);
                  this.currentContact = propertyIndex;
                }
              }

              this.totalPropertyCount = this.propertyList.length;
              if (this.propertyList.length > 0) {
                this.getFilterdPropertyIds();
              }
            }
          },
          (err: ErrorModel) => {
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(MessageConstant.unknownError, '');
            }
          }
        );
    }
  }
  getFilterdPropertyIds() {
    this._loaderService.start();
    let obj = {
      ids: this.propertyList,
      phoneStatusExclude: this.phoneStatusExclude,
      selectedPhoneType: this.selectedPhoneType,
    };
    this._dialerService.getFilterPropertyIds(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          if (
            this.phoneStatusExclude?.length > 0 ||
            this.selectedPhoneType?.length > 0
          ) {
            if (response?.data?.length > 0) {
              this.propertyList = this.propertyList.filter((element) =>
                response?.data?.includes(element)
              );
              this.totalPropertyCount = this.propertyList?.length;
              let campaignPropertyListId = this.data.currentPropertyId;
              if (campaignPropertyListId) {
                let propertyIndex = this.propertyList.indexOf(
                  campaignPropertyListId
                );
                if (propertyIndex > -1) {
                  this.currentContact = propertyIndex;
                } else {
                  this.currentContact = 0;
                }
              }
              if (this.currentContact > this.propertyList?.length) {
                this.currentContact = 0;
              }
              this.getPropertyDetails(true);
            } else {
              this.propertyList = [];
              this.currentContact = 0;
              this.totalPropertyCount = 1;
              this.getPropertyDetails(true);
            }
          } else {
            this.getPropertyDetails(true);
          }
        }

        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error('No matching data found', '');
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }
  getPropertyDetails(onLoad?) {
    this.defultNumber =
      this.propertyDefultNumber[this.propertyList[this.currentContact]];
    this.propertyDetailLoader = true;
    this._listStackingService
      .getListDetail({ _id: this.propertyList[this.currentContact] })
      .subscribe(
        (response) => {
          this.propertyDetailLoader = false;
          this.callDisplay = '';
          if (response.code == 200) {
            this.propertyDetails = response.data;
            this.duplicateData = response.duplicate;
            this.singleListsDMail = response?.data?.dmDetails
              ? response?.data?.dmDetails
              : [];
            this.getAllUserDetails();
            this.getCallLastStatus(this.propertyDetails?._id);
            this.getAllMarketingData(this.propertyDetails?.exportDetails);
            this.getAllPhoneNumberList();
            this.getAllEmailList();
            this.getDefaultCampaignList();
            this.currentPage = 1;
            this.getActivityList();
            if (this.scriptDetail) {
              this.scriptDescription = this.getReplacedString(
                this.scriptDetail?.description,
                this.items,
                this.propertyDetails
              );
            }
            this.title =
              this.propertyDetails?.firstName +
              ' ' +
              this.propertyDetails?.lastName;
            this.callEndedStat = false;
            this.numberToCall = '';
            this.displayNumberToCall = '';
            if (this.phoneData.length) {
              this.numberToCall =
                environment.countryCode +
                this._utilities.unMaskNumber(
                  this.phoneData[this.currentNumber].phone
                );
              this.displayNumberToCall = this.numberToCall;
            }
            this.getListNames();
            this.getTagNames();
            this.currentNumber = this.currentNumber;
            this.currentViewNumber = this.currentNumber;
            this.getSMSTemplate();
            this.getUser(onLoad ? true : false);
            if (this.propertyDetails?.InLead == false) {
              this.inLeadStatus = 'No';
            }
            this.getMainStatus(this.propertyDetails?.lead_property_status);
            setTimeout(() => {
              this.callAttempts();
            }, 1000);
          } else {
            this._toastrService.error('No matching data found');
          }
        },
        (err) => {
          this.propertyDetailLoader = false;
          if (err.error) {
            const error = err.error;
            this._toastrService.error('No matching data found', '');
          } else {
            this._toastrService.error(MessageConstant.unknownError, '');
          }
        }
      );
  }

  getFormattedStatus(allStatus) {
    let formattedStatus = [];
    for (let i = 0; i < allStatus.length; i++) {
      if (allStatus[i].length == 0) continue;
      formattedStatus.push({
        shortStatus:
          allStatus[i].length > 20
            ? allStatus[i].substring(0, 20) + '...'
            : allStatus[i],
        actualStatus: allStatus[i],
      });
    }
    return formattedStatus;
  }

  getDefaultCampaignList() {
    const obj = {
      crmQueId: [],
      numTypeId: 3,
    };

    this._leadService.getCampaignList(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.defaultNumberList = response.data?.items;
          let defaultCallList =
            this.propertyDefultNumber[this.propertyList[this.currentContact]];
          if (!defaultCallList && this.defaultFirstCallNumber) {
            this.defaultCallNumber = this.defaultFirstCallNumber;
          } else {
            this.defaultCallNumber = defaultCallList;
          }
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }
  getReplacedString(entireString, patternsToMatch, valueObj) {
    for (let i = 0; i < patternsToMatch.length; i++) {
      if (valueObj[patternsToMatch[i]]) {
        entireString = entireString.replaceAll(
          '@' + patternsToMatch[i],
          valueObj[patternsToMatch[i]]
        );
      } else {
        entireString = entireString.replaceAll(
          '@' + patternsToMatch[i],
          patternsToMatch[i] + '(N/A)'
        );
      }
    }
    return entireString;
  }
  getAllMarketingData(exportDetails) {
    this.allMarketData = [];
    this.marketTypeVal[0].count = 0;
    this.marketTypeVal[1].count = 0;
    this.marketTypeVal[2].count = 0;
    this.marketTypeVal[3].count = 0;
    this.marketTypeVal[4].count = 0;
    let eDate, displayMarketDate;
    for (let i = 0; i < exportDetails.length; i++) {
      if (exportDetails[i]?.directMail) {
        this.allMarketData.push({
          type: this.marketTypeVal[0]?.type,
          campaignName: exportDetails[i]?.campaignName,
          mailerType: 'N.A',
          expectedStartDate: exportDetails[i]?.expectedStartDate,
        });
        this.marketTypeVal[0].count++;
      } else if (exportDetails[i]?.coldCalling) {
        this.allMarketData.push({
          type: this.marketTypeVal[1]?.type,
          campaignName: exportDetails[i]?.campaignName,
          mailerType: 'N.A',
          expectedStartDate: exportDetails[i]?.expectedStartDate,
        });
        this.marketTypeVal[1].count++;
      } else if (exportDetails[i]?.exportedForSms) {
        this.allMarketData.push({
          type: this.marketTypeVal[2]?.type,
          campaignName: exportDetails[i]?.campaignName,
          mailerType: 'N.A',
          expectedStartDate: exportDetails[i]?.expectedStartDate,
        });
        this.marketTypeVal[2].count++;
      } else if (exportDetails[i]?.exportedForRvm) {
        this.allMarketData.push({
          type: this.marketTypeVal[3]?.type,
          campaignName: exportDetails[i]?.campaignName,
          mailerType: 'N.A',
          expectedStartDate: exportDetails[i]?.expectedStartDate,
        });
        this.marketTypeVal[3].count++;
      }
    }
    for (let i = 0; i < this.singleListsDMail.length; i++) {
      eDate = this.singleListsDMail[i]?.date;
      displayMarketDate = '';
      if (eDate) {
        displayMarketDate = eDate.substring(0, 10);
        let splitDate = displayMarketDate.split('-');
        if (splitDate.length == 3) {
          displayMarketDate =
            splitDate[1] + '-' + splitDate[2] + '-' + splitDate[0];
        }
      }
      this.allMarketData.push({
        type: this.marketTypeVal[0]?.type,
        campaignName: this.singleListsDMail[i]?.campaignName
          ? this.singleListsDMail[i]?.campaignName
          : 'N.A',
        mailerType: this.singleListsDMail[i]?.mailertype,
        expectedStartDate: displayMarketDate,
      });
    }
    this.marketTypeVal[0].count =
      this.marketTypeVal[0].count + this.singleListsDMail.length;
    this.marketTypeVal[4].count = this.allMarketData.length;
  }

  getListTagContent(eachProperty, mode) {
    let listContent = [];
    let tagContent = [];
    if (
      mode == 'list' &&
      eachProperty?.ListId.length > 0 &&
      this.allListslistData
    ) {
      for (let i = 0; i < eachProperty?.ListId.length; i++) {
        let ressw3 = eachProperty?.ListId[i];
        for (let j = 0; j < this.allListslistData.length; j++) {
          if (ressw3 == this.allListslistData[j]?._id) {
            listContent.push(this.allListslistData[j]?.name);
            break;
          }
        }
      }
      let listMessageTooltip = '<ul>';
      if (listContent && listContent.length > 0) {
        for (let i = 0; i < listContent.length; i++) {
          listMessageTooltip +=
            '<li style="margin-bottom: 10px;">' + listContent[i] + '</li>';
        }
      }
      listMessageTooltip += '</ul>';
      return listMessageTooltip;
    }
    if (mode == 'tag' && eachProperty?.TagId.length > 0 && this.allTagsData) {
      for (let i = 0; i < eachProperty?.TagId.length; i++) {
        let ressw3 = eachProperty?.TagId[i];
        for (let j = 0; j < this.allTagsData.length; j++) {
          if (ressw3 == this.allTagsData[j]?._id) {
            tagContent.push(this.allTagsData[j]?.name);
            break;
          }
        }
      }
      let tagMessageTooltip = '<ul>';
      if (tagContent && tagContent.length > 0) {
        for (let i = 0; i < tagContent.length; i++) {
          tagMessageTooltip +=
            '<li style="margin-bottom: 10px;">' + tagContent[i] + '</li>';
        }
      }
      tagMessageTooltip += '</ul>';
      return tagMessageTooltip;
    }
  }

  getPropertyPhoneNumber(eachProperty) {
    let numCount = 0;
    for (let i = 1; i <= 10; i++) {
      if (eachProperty['Phone_' + i] && eachProperty['Phone_' + i].length > 0)
        numCount++;
    }
    return numCount;
  }

  getActivityList() {
    if (this.activityLog) {
      this.activityLog.initialize();
    }
  }

  getCallStatus(isCallConnected, callStatusId) {
    if (callStatusId == '') {
      return 'N/A';
    }
    if (isCallConnected) {
      if (callStatusId == 4) {
        this.callConnectedStatus[3].name = 'Move to lead';
      }
      return this.callConnectedStatus.filter((x) => x?.value == callStatusId)[0]
        .name;
    } else {
      return this.callNotConnectedStatus.filter(
        (x) => x?.value == callStatusId
      )[0].name;
    }
  }

  getAllPhoneNumberList() {
    this.promises = [];
    this.promises.push(this.getStatus());
    Promise.all(this.promises)
      .then((result) => {
        this.phoneData = [];
        let statusVal, pType, finalStatusVal, statusIndex;
        for (let i = 1; i <= 10; i++) {
          if (this.propertyDetails[`Phone_${i}`]) {
            statusVal = [];
            pType = '';
            finalStatusVal = [];

            if (
              this.propertyDetails[`Phone_${i}_status`] &&
              this.propertyDetails[`Phone_${i}_status`].length
            ) {
              statusVal =
                typeof this.propertyDetails[`Phone_${i}_status`] == 'string'
                  ? this.propertyDetails[`Phone_${i}_status`].split(',')
                  : this.propertyDetails[`Phone_${i}_status`];
            }

            statusVal.filter((e) => {
              statusIndex = this.allPhoneStatus.findIndex((x) => x.name === e);

              if (statusIndex >= 0) {
                finalStatusVal.push(this.allPhoneStatus[statusIndex]);
              }
            });

            if (this.propertyDetails[`Phone_${i}_type`]) {
              const phoneTypes = ['Mobile', 'Landline', 'Voip'];
              if (
                !phoneTypes.includes(this.propertyDetails[`Phone_${i}_type`])
              ) {
                pType = 'Other';
              } else {
                pType = this.propertyDetails[`Phone_${i}_type`];
              }
            }

            if (
              this.propertyDetails['Phone_' + i] &&
              this.propertyDetails['Phone_' + i].length > 0
            ) {
              if (
                this.selectedPhoneType?.length &&
                this.phoneStatusExclude?.length
              ) {
                if (
                  !this.selectedPhoneType.includes(
                    this.propertyDetails['Phone_' + i + '_type']
                  )
                ) {
                  let excludeMatch = false;
                  excludeMatch = this.getMatched(
                    this.phoneStatusExclude,
                    this.propertyDetails['Phone_' + i + '_status']
                  );
                  if (!excludeMatch) {
                    this.phoneData.push({
                      phone: this.propertyDetails['Phone_' + i],
                      type: this.propertyDetails['Phone_' + i + '_type'],
                      dnc: this.propertyDetails['Phone_' + i + '_DNC']
                        ? this.propertyDetails['Phone_' + i + '_DNC']
                        : '--',
                      litigator: this.propertyDetails[
                        'Phone_' + i + '_IsLitigator'
                      ]
                        ? this.propertyDetails['Phone_' + i + '_IsLitigator']
                        : '--',
                      status: this.propertyDetails['Phone_' + i + '_status']
                        ? this.getFormattedStatus(
                            typeof this.propertyDetails[
                              'Phone_' + i + '_status'
                            ] == 'string'
                              ? this.propertyDetails[
                                  'Phone_' + i + '_status'
                                ].split(',')
                              : this.propertyDetails['Phone_' + i + '_status']
                          )
                        : [],
                      currentIndex: i,
                    });
                  }
                }
              } else if (this.selectedPhoneType?.length) {
                if (
                  !this.selectedPhoneType.includes(
                    this.propertyDetails['Phone_' + i + '_type']
                  )
                ) {
                  this.phoneData.push({
                    phone: this.propertyDetails['Phone_' + i],
                    type: this.propertyDetails['Phone_' + i + '_type'],
                    dnc: this.propertyDetails['Phone_' + i + '_DNC']
                      ? this.propertyDetails['Phone_' + i + '_DNC']
                      : '--',
                    litigator: this.propertyDetails[
                      'Phone_' + i + '_IsLitigator'
                    ]
                      ? this.propertyDetails['Phone_' + i + '_IsLitigator']
                      : '--',
                    status: this.propertyDetails['Phone_' + i + '_status']
                      ? this.getFormattedStatus(
                          typeof this.propertyDetails[
                            'Phone_' + i + '_status'
                          ] == 'string'
                            ? this.propertyDetails[
                                'Phone_' + i + '_status'
                              ].split(',')
                            : this.propertyDetails['Phone_' + i + '_status']
                        )
                      : [],
                    currentIndex: i,
                  });
                }
              } else if (this.phoneStatusExclude?.length) {
                let excludeMatch = false;
                excludeMatch = this.getMatched(
                  this.phoneStatusExclude,
                  this.propertyDetails['Phone_' + i + '_status']
                );
                if (!excludeMatch) {
                  this.phoneData.push({
                    phone: this.propertyDetails['Phone_' + i],
                    type: this.propertyDetails['Phone_' + i + '_type'],
                    dnc: this.propertyDetails['Phone_' + i + '_DNC']
                      ? this.propertyDetails['Phone_' + i + '_DNC']
                      : '--',
                    litigator: this.propertyDetails[
                      'Phone_' + i + '_IsLitigator'
                    ]
                      ? this.propertyDetails['Phone_' + i + '_IsLitigator']
                      : '--',
                    status: this.propertyDetails['Phone_' + i + '_status']
                      ? this.getFormattedStatus(
                          typeof this.propertyDetails[
                            'Phone_' + i + '_status'
                          ] == 'string'
                            ? this.propertyDetails[
                                'Phone_' + i + '_status'
                              ].split(',')
                            : this.propertyDetails['Phone_' + i + '_status']
                        )
                      : [],
                    currentIndex: i,
                  });
                }
              } else {
                this.phoneData.push({
                  phone: this.propertyDetails['Phone_' + i],
                  type: this.propertyDetails['Phone_' + i + '_type'],
                  dnc: this.propertyDetails['Phone_' + i + '_DNC']
                    ? this.propertyDetails['Phone_' + i + '_DNC']
                    : '--',
                  litigator: this.propertyDetails['Phone_' + i + '_IsLitigator']
                    ? this.propertyDetails['Phone_' + i + '_IsLitigator']
                    : '--',
                  status: this.propertyDetails['Phone_' + i + '_status']
                    ? this.getFormattedStatus(
                        typeof this.propertyDetails['Phone_' + i + '_status'] ==
                          'string'
                          ? this.propertyDetails[
                              'Phone_' + i + '_status'
                            ].split(',')
                          : this.propertyDetails['Phone_' + i + '_status']
                      )
                    : [],
                  currentIndex: i,
                });
              }
            }
          }
        }
        this.numberToCall = '';
        this.displayNumberToCall = '';
        if (this.phoneData.length) {
          this.numberToCall =
            environment.countryCode +
            this._utilities.unMaskNumber(
              this.phoneData[this.currentNumber].phone
            );
          this.displayNumberToCall = this.numberToCall;
        }
        this.currentNumber = this.currentNumber;
        this.callAttempts(true);
      })
      .catch((err) => {
        this._loaderService.stop();
      });
  }

  getAllEmailList() {
    this.allEmailObject = [];
    this.EmptyEmailObject = false;
    if (this.propertyDetails.Email_1 == 'NA') this.propertyDetails.Email_1 = '';
    if (this.propertyDetails.Email_2 == 'NA') this.propertyDetails.Email_2 = '';
    if (this.propertyDetails.Email_3 == 'NA') this.propertyDetails.Email_3 = '';
    if (this.propertyDetails.Email_4 == 'NA') this.propertyDetails.Email_4 = '';

    if (
      !this.propertyDetails.Email_1 &&
      !this.propertyDetails.Email_2 &&
      !this.propertyDetails.Email_3 &&
      !this.propertyDetails.Email_4
    ) {
      this.EmptyEmailObject = true;
    }
    for (let i = 1; i <= 4; i++) {
      if (
        this.propertyDetails['Email_' + i] &&
        this.propertyDetails['Email_' + i].length > 0
      )
        this.allEmailObject.push(this.propertyDetails['Email_' + i]);
    }
  }

  getStatus() {
    const promise = new Promise((resolve, reject) => {
      this._listStackingService.getPhoneStatus().subscribe(
        (response) => {
          if (response.code == 200) {
            this.allPhoneStatus = response?.message?.phoneStatus || [];
            this.allPhoneStatus = this.allPhoneStatus.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            return resolve(this.allPhoneStatus);
          }
        },
        (err) => {
          return reject(err);
        }
      );
    });
    return promise;
  }

  filterMarketType(type) {
    this.getAllMarketingData(this.propertyDetails?.exportDetails);
    if (type != 'All') {
      this.allMarketData = this.allMarketData.filter(function (el) {
        return el.type == type;
      });
    }
  }

  formatPhoneNumber(phoneNumberString, p_type, bool?) {
    var tempVar = '';
    if (phoneNumberString) {
      var charFound = phoneNumberString.match(/[^$,.\d]/);
      var subStringNumber = phoneNumberString;
      if (charFound == '+') {
        subStringNumber = phoneNumberString.substring(2);
      }
      var cleaned = ('' + subStringNumber).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (p_type != null && p_type.length != 0) {
        if (p_type.length > 3) {
          if (bool) {
            tempVar = ' (' + p_type.substring(0, 1) + ')';
          } else {
            tempVar = ' (' + p_type.substring(0, 3) + ')';
          }
        } else {
          tempVar = ' (' + p_type + ')';
        }
      }
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3] + tempVar;
      }
    }
    return phoneNumberString + tempVar;
  }
  prevProperty(type?) {
    this.currentViewNumber = 0;
    this.currentNumber = 0;
    if (this.currentContact != 0) {
      this.currentContact--;
      this.resetStatus();
      this.getPropertyDetails();
      setTimeout(() => {
        if (type == 'call') {
          this.makeCall();
        }
      }, 2500);
    }
  }
  nextProperty(type?) {
    this.currentViewNumber = 0;
    this.currentNumber = 0;
    if (this.currentContact + 1 != this.totalPropertyCount) {
      this.currentContact++;
      this.resetStatus();
      this.getPropertyDetails();
      setTimeout(() => {
        if (type == 'call') {
          this.makeCall();
        }
      }, 2500);
    }
  }
  resetStatus() {
    this.isCalling = false;
    this.defaultDiaplay = true;
    this.makingCall = false;
    this.redialCall = false;
    this.callEnd = false;
    this.muteCall = false;
    this.callDuration = '00:00:00';
    this.callEndDuration = '00:00:00';
    this.callEndtime = '00:00';
  }
  setCurrentNumber(mode) {
    this.resetStatus();
    if (this.makingCall) {
      return;
    }
    this.sliderBtn = true;
    setTimeout(() => {
      this.sliderBtn = false;
    }, 700);
    if (mode == 'N') {
      if (this.currentNumber + 1 != this.phoneData.length) {
        this.currentNumber++;
        this.numberToCall =
          environment.countryCode +
          this._utilities.unMaskNumber(
            this.phoneData[this.currentNumber].phone
          );
        this.displayNumberToCall = this.numberToCall;
      }
    } else if (mode == 'P') {
      if (this.currentNumber != 0) {
        this.currentNumber--;
        this.numberToCall =
          environment.countryCode +
          this._utilities.unMaskNumber(
            this.phoneData[this.currentNumber].phone
          );
        this.displayNumberToCall = this.numberToCall;
      }
    }
  }
  callSetCurrentNumber(mode) {
    setTimeout(() => {
      this.currentViewNumber++;
      if (
        this.phoneData.length == 0 ||
        this.currentViewNumber + 1 > this.phoneData.length
      ) {
        this.openCloseCallEnded('close');
        this.resetStatus();
        return;
      } else {
        if (mode == 'N') document.getElementById('nextBtn').click();
        this.makeCall();
      }
    }, 3000);
  }
  setNumber(mode) {
    if (mode == 'N') document.getElementById('nextBtn').click();
  }
  callToCurrentNumber(number, index) {
    if (this.makingCall) return;
    document.querySelectorAll('.carousel-item').forEach((element, i) => {
      element.classList.remove('active');
      if (i == index) {
        element.classList.add('active');
      }
    });
    if (number) {
      this.currentNumber = index;
      this.currentViewNumber = index;
      this.numberToCall = environment.countryCode + number;
      this.displayNumberToCall = this.numberToCall;
      this.makeCall();
    }
  }
  async makeCall() {
    this.callStatus = '';
    this.callEndDuration = '00:00:00';
    this.callingFrom = '';
    if (this.defultNumber != '') {
      this.callingFrom = this.allPhoneNumberList.filter(
        (x) => x == this.defultNumber
      )[0];
    }
    if (!this.callingFrom) {
      this.callingFrom = this.allPhoneNumberList[this.currentNumberCall];
    }
    let perDayCallCount: any = await this.callCountDay(
      this.callingFrom != ''
        ? this.callingFrom
        : this.defultNumber != ''
        ? this.defultNumber
        : this.allPhoneNumberList[this.currentNumberCall]
    );
    if (perDayCallCount >= 100 && !this.callDialyLimit) {
      let errorNumber =
        this.callingFrom != ''
          ? this._utilities.maskNumber(this.callingFrom)
          : this.defultNumber != ''
          ? this._utilities.maskNumber(this.defultNumber)
          : this._utilities.maskNumber(
              this.allPhoneNumberList[this.currentNumberCall]
            );
      this._toastrService.error(
        'You have dialed more than 100 contacts from this number (' +
          errorNumber +
          '). We suggest you to change your number to prevent it from getting marked as spam.',
        ''
      );
      localStorage.setItem('callDialyLimit', 'true');
      this.callDialyLimit = true;
      // return;
    }
    if (this.allPhoneNumberList?.length == 0) {
      this._toastrService.info(MessageConstant.noCampaignNumberProperty, '', {
        enableHtml: true,
      });
      return;
    }
    if (this.currentNumberCall + 1 > this.allPhoneNumberList?.length) {
      this.currentNumberCall = 0;
    }
    if (!this.numberToCall) {
      this._toastrService.error(MessageConstant.noNumberProperty, '');
      return;
    }
    this.smsDetailForm.patchValue({
      smsNumber: this.currentNumber,
    });
    this.callUUID = '';
    this.isCalling = true;
    this.defaultDiaplay = false;
    this.makingCall = true;
    this.redialCall = false;
    this.callEnd = false;
    this.callDisplay = 'Ongoing Call...';
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (this.plivoBrowserSdk.client.isLoggedIn) {
      this.initiateCall(this.numberToCall);
    } else {
      // this.plivoBrowserSdk.client.login(
      //   environment.coldCallPlivo.username,
      //   environment.coldCallPlivo.password
      // );
      this.plivoBrowserSdk.client.login(user.eUname, user.ePwd);
      this.plivoBrowserSdk.client.on('onLogin', () => {
        if (this.isCalling) {
          this.initiateCall(this.numberToCall);
        }
      });
    }
  }
  initiateCall(phoneNumber) {
    const extraHeaders = {
      'X-PH-to': phoneNumber,
      'X-PH-from': this.callingFrom,
      'X-PH-default': this.callingFrom,
      'X-PH-userId': this.userId,
      'X-PH-moduleType': 'ListStacking',
      'X-PH-campaignId': this.campaignId
        ? this.campaignId
        : this.campaignIdList[this.currentContact],
      'X-PH-propertyId': this.propertyList[this.currentContact],
    };
    const user = JSON.parse(localStorage.getItem('currentUser'));

    if (this.isTwilioCall) {
      let campaignId = this.campaignId
        ? this.campaignId
        : this.campaignIdList[this.currentContact];

      let propertyId = this.propertyList[this.currentContact];

      this._twilioService.initializeTwilio({
        from: this.callingFrom,
        to: phoneNumber,
        number: this.callingFrom,
        userId: this.userId,
        moduleType: 'ListStacking',
        campaignId: campaignId,
        propertyId: propertyId,
      });
    } else {
      this.plivoBrowserSdk.client.call(
        `${user.eUname}@phone.plivo.com`,
        extraHeaders
      );
    }
    if (this.abandonCall != 0 && this.makingCall) {
      clearTimeout(this.callTimeOut);
      this.callTimeOut = setTimeout(() => {
        if (
          this.makingCall &&
          (this.callUUID == null ||
            this.callUUID == '' ||
            this.callEndDuration == '00:00:00')
        ) {
          this.callStatus = this.keptRinging;
          this.callEndedStat = true;
          this.isAbandonCall = true;
          this.openCloseCallEnded('Connected');
          this.cancel();
        }
      }, this.abandonCall);
    }
  }
  getUser(onLoad?) {
    this._sharedService.getUser().subscribe(
      (response) => {
        if (response.code == 200) {
          this.userId = response.data?.userId;
          if (!this.plivoBrowserSdk?.client?.isLoggedIn) {
            this.initializePlivo();
          }
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }
  getAllUserDetails() {
    this._userService.getUserDetails({}).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode && response?.statusCode == 200) {
          this.userData = response?.data;
          if (this.userData) {
            this.currentTimezone = this.timezoneList.filter(
              (x) => x.timezonename == this.userData?.timezoneOffset
            )[0].timezonename;
            let currentTime = new Date();
            let convertTime = moment(currentTime)
              .tz(localStorage.getItem('timezoneOffset'))
              .format('YYYY-MM-DD HH:mm:ss');
            let dueIso = this._utilities.dueDateFormat(
              new Date(convertTime)
            ).parseDate;
            this.scheduleDate = new Date(dueIso);
            this.scheduleTime = new Date(dueIso);
          }
        } else {
          this._toastrService.error(response.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }
  saveStatus(type?) {
    if (!this.callStatus) {
      this._toastrService.error(MessageConstant.pleaseSelectOption, '');
      return;
    }
    let sTime = moment(new Date(this.scheduleTime), ['h:mm A']).format('HH:mm');
    let sDate = moment(new Date(this.scheduleDate)).format('DD-MM-YYYY');
    var dateObj = moment(sDate + ' ' + sTime, 'DD-MM-YYYYTHH:mm');
    var timeDate = dateObj.format('YYYY-MM-DDTHH:mm');
    let fullDateTime = moment(timeDate).format('MMM DD, YYYY hh:mm a');
    let timeStamp =
      fullDateTime +
      moment(fullDateTime).tz(localStorage.timezoneOffset).format('Z');
    const fullDate = new Date(timeStamp).getTime();
    this._loaderService.start();

    // this.defaultFirstCallNumber =
    //   this.callingFrom != ''
    //     ? this.callingFrom
    //     : this.defultNumber != ''
    //     ? this.defultNumber
    //     : this.allPhoneNumberList[this.currentNumberCall];

    let obj = {
      campaignId: this.campaignId
        ? this.campaignId
        : this.campaignIdList[this.currentContact],
      propertyId: this.propertyList[this.currentContact],
      statusId: parseInt(this.callStatus),
      isConnected:
        parseInt(this.callStatus) < 7 || parseInt(this.callStatus) == 15
          ? true
          : false,
      otherReason: this.otherReason ? this.otherReason : '',
      callUuid: this.callUUID,
      scheduledDate: sDate,
      scheduledAt: fullDate,
      fullDateTime,
      toNumber: this.numberToCall
        ? this.numberToCall
        : environment.countryCode +
          this._utilities.unMaskNumber(this.phoneData[0].phone),
      isAbandonCall: this.isAbandonCall,
      fromNumber:
        this.callingFrom != ''
          ? this.callingFrom
          : this.defultNumber != ''
          ? this.defultNumber
          : this.allPhoneNumberList[this.currentNumberCall],
    };
    if (parseInt(this.callStatus) == 3) {
      obj['isPropertyRemove'] = false;
    } else {
      obj['isPropertyRemove'] = true;
    }
    this._dialerService.saveCallStatus(obj).subscribe(
      async (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.isPropertyToLead = false;
          if (
            parseInt(this.callStatus) == 4 &&
            !this.propertyDetails?.lead_property_id
          ) {
            this.propertyDetails = await this.getPropertyData(true);
          }
          let dncStatus = false;
          if (parseInt(this.callStatus) == 2) {
            dncStatus = true;
          }
          this.propertyDetails['lastStatus'] = this.callStatus;
          this.updateLastStatusInLS(this.callStatus);
          this.addPhoneStatus(parseInt(this.callStatus), dncStatus);
          this.addLogs();
          this.otherReason = '';
          this.callBtnAction = false;
          this.getCallLastStatus(this.propertyList[this.currentContact]);
          this.isAbandonCall = false;
          this.getDefaultCampaignList();
          if (this.campaignScheduled) {
            this._sharedService.refreshScheduledDialerInfo.next(true);
          }
          setTimeout(() => {
            this._sharedService.refreshDialerInfo.next(this.campaignId);
          }, 5000);
          if (
            this.defultNumber == '' ||
            this.callingFrom != this.defultNumber
          ) {
            this.currentNumberCall++;
            if (this.currentNumberCall + 1 > this.allPhoneNumberList?.length) {
              this.currentNumberCall = 0;
            }
          }
          if (this.callingFrom) {
            this.defultNumber = this.callingFrom;
            this.defaultCallNumber = this.callingFrom;
            this.propertyDefultNumber[this.propertyList[this.currentContact]] =
              this.callingFrom;
          }
          this.openCloseCallEnded('close');
          if (type == 'redial') {
            this.makeCall();
          } else if (type == 'nextNumber') {
            this.callSetCurrentNumber('N');
          } else if (type == 'nextProperty') {
            this.nextProperty('call');
          }
        }
        setTimeout(() => {
          this.callAttempts();
        }, 8000);
        setTimeout(() => {
          let response = {
            isRefresh: true,
            mode: 'todayCall',
          };
          this._sharedService.refreshTodayDialerInfo.next(response);
        }, 10000);
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }

  updateStatus(bool?) {
    let sTime = moment(new Date(this.scheduleTime), ['h:mm A']).format('HH:mm');
    let sDate = moment(new Date(this.scheduleDate)).format('DD-MM-YYYY');
    var dateObj = moment(sDate + ' ' + sTime, 'DD-MM-YYYYTHH:mm');
    var timeDate = dateObj.format('YYYY-MM-DDTHH:mm');
    let fullDateTime = moment(timeDate).format('MMM DD, YYYY hh:mm a');
    let timeStamp =
      fullDateTime +
      moment(fullDateTime).tz(localStorage.timezoneOffset).format('Z');
    const fullDate = new Date(timeStamp).getTime();

    let obj = {
      id: this.lastCallStatus?._id,
      statusId: parseInt(this.callStatus),
      isConnected:
        parseInt(this.callStatus) < 7 || parseInt(this.callStatus) == 15
          ? true
          : false,
      otherReason: this.otherReason ? this.otherReason : '',
      propertyId: this.propertyList[this.currentContact],
      scheduledDate: sDate,
      scheduledAt: fullDate,
      fullDateTime,
      toNumber: this.numberToCall,
      fromNumber:
        this.callingFrom != ''
          ? this.callingFrom
          : this.defultNumber != ''
          ? this.defultNumber
          : this.allPhoneNumberList[this.currentNumberCall],
    };
    if (parseInt(this.callStatus) == 3) {
      obj['isPropertyRemove'] = false;
    } else {
      obj['isPropertyRemove'] = true;
    }
    this._loaderService.start();
    this._dialerService.editCallStatus(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.isPropertyToLead = false;
          if (this.callStatus == '4') {
            this.getPropertyDetails(true);
          }
          let dncStatus = false;
          if (this.callStatus == '2') {
            dncStatus = true;
          }
          // this.addPhoneStatus(this.callStatus, dncStatus);
          this.otherReason = '';
          this.updateLastStatusInLS(this.callStatus);
          this.getCallLastStatus(this.propertyList[this.currentContact]);
          this.getActivityList();
          if (bool) {
            if (this.campaignScheduled) {
              this._sharedService.refreshScheduledDialerInfo.next(true);
            }
            if (this.campaignTodayDate) {
              let response = {
                isRefresh: true,
                mode: '',
              };
              this._sharedService.refreshTodayDialerInfo.next(response);
            }
            this.isCallStatusChange = !this.isCallStatusChange;
          }
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }

  addPhoneStatus(statusId, bool) {
    let currentCallNumber = this.phoneData[this.currentNumber].phone;
    let phoneObj = {};
    if (statusId) {
      if (statusId == 1) {
        phoneObj[currentCallNumber] = 'DNC';
      } else if (statusId == 3) {
        phoneObj[currentCallNumber] = 'Correct Number';
      } else if (statusId == 4) {
        phoneObj[currentCallNumber] = [];
        phoneObj[currentCallNumber].push('Attached with Lead');
        phoneObj[currentCallNumber].push('Correct Number');
      } else if (statusId == 5 || statusId == 6) {
        this.updatePropertyStatus(this.propertyDetails);
      } else if (statusId == 7) {
        phoneObj[currentCallNumber] = 'Unknown';
      } else if (statusId == 8) {
        phoneObj[currentCallNumber] = 'Keeps Ringing';
      } else if (statusId == 9) {
        phoneObj[currentCallNumber] = 'Wrong Number';
      } else if (statusId == 10) {
        phoneObj[currentCallNumber] = 'Disconnected';
      } else if (statusId == 11) {
        phoneObj[currentCallNumber] = 'Busy';
      } else if (statusId == 12) {
        phoneObj[currentCallNumber] = 'Unknown';
      } else if (statusId == 13) {
        phoneObj[currentCallNumber] = 'No VM set up';
      } else if (statusId == 14) {
        phoneObj[currentCallNumber] = 'Unknown';
      }
    }
    if (bool) {
      let obj = {
        id: this.propertyList[this.currentContact],
      };
      this._listStackingService.updateDNCPhoneStatus(obj).subscribe(
        (response: ResponseModel) => {
          if (response.code == 200) {
            this.getPropertyDetails(true);
            setTimeout(() => {
              this.updatePropertyStatus(this.propertyDetails);
            }, 2000);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(MessageConstant.unknownError, '');
          }
        }
      );
    } else {
      this._listStackingService.addPhoneStatus(phoneObj).subscribe(
        (response: ResponseModel) => {
          if (response.code == 200) {
            this.getPropertyDetails(true);
            setTimeout(() => {
              this.updateProperty(this.propertyDetails);
            }, 3000);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(MessageConstant.unknownError, '');
          }
        }
      );
    }
  }
  updateProperty(currentData) {
    let id = currentData._id;
    this._listStackingService.editProperty(currentData, id).subscribe(
      (response: ResponseModel) => {
        if (response.code == 200) {
          this.getPropertyDetails(true);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        // if (err.error) {
        //   const error: ResponseModel = err.error;
        //   this._toastrService.error(error.message, '');
        // } else {
        //   this._toastrService.error(MessageConstant.unknownError, '');
        // }
      }
    );
  }

  updatePropertyStatus(currentData) {
    let id = currentData._id;
    currentData['Opt'] = true;
    this._listStackingService.editProperty(currentData, id).subscribe(
      (response: ResponseModel) => {
        if (response.code == 200) {
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        // if (err.error) {
        //   const error: ResponseModel = err.error;
        //   this._toastrService.error(error.message, '');
        // } else {
        //   this._toastrService.error(MessageConstant.unknownError, '');
        // }
      }
    );
  }
  updateLastStatusInLS(callStatus) {
    let obj = {
      id: this.propertyList[this.currentContact],
      lastStatus: callStatus,
    };
    this._listStackingService.updateLastStatus(obj).subscribe(
      (response: ResponseModel) => {},
      (err: ErrorModel) => {}
    );
  }

  openNote() {
    let phoneNumber = '';
    if (this.phoneData.length > 0) {
      phoneNumber = this.phoneData[0].phone;
    }
    this.dialogRef = this.dialog.open(TakeNoteDialerComponent, {
      data: {
        subModuleId: this.propertyList[this.currentContact],
        moduleId: this.listModuleId,
        propertyDetails: this.propertyDetails,
        phoneNumber: phoneNumber,
        userListing: this.userListing,
      },
      height: 'auto',
      width: '400px',
      panelClass: 'device-modal',
      disableClose: false,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getActivityList();
      }
    });
  }
  openSendSMS(templateObj) {
    this.dialogRef = this.dialog.open(SendSmsComponent, {
      data: {
        From: this.fromNumber,
        SmsText: templateObj?.message,
        To: this.numberToCall,
        campaignId: this.campaignId
          ? this.campaignId
          : this.campaignIdList[this.currentContact],
        propertyId: this.propertyList[this.currentContact],
        userId: this.userId,
      },
      height: 'auto',
      width: '400px',
      panelClass: 'device-modal',
      disableClose: false,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._sharedService.refreshActivityLog.next(true);
      }
    });
  }
  openCloseCallEnded(mode) {
    if (mode == 'close') {
      this.showCallEndedPopup = false;
      this.callBtnAction = false;
    } else {
      this.showCallEndedPopup = true;
      this.callBtnAction = true;
    }
  }
  getTabValues(index) {
    if (index == '2') {
      this.currentPage = 1;
      this.getActivityList();
    }
  }
  getUserRoleList() {
    this._masterTaskService
      .getUserRoleList({ page: this.currentPage, limit: 100 })
      .subscribe(
        (response: ResponseModel) => {
          this.userListing = response.data.items;
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(MessageConstant.unknownError, '');
          }
        }
      );
  }
  getActivity(onLoad?) {
    this._dialerService
      .getActivity({
        campaignId: this.campaignId
          ? this.campaignId
          : this.campaignIdList[this.currentContact],
        propertyId: this.propertyList[this.currentContact],
      })
      .subscribe(
        (response) => {
          if (response.code == 200) {
            if (this.logs.items) {
              if (this.logs.items?.length === response.data?.count) {
                return;
              }

              this.logs.count = response.data?.count;
              if (onLoad) {
                this.logs.items = response.data?.items;
              } else {
                this.logs.items = [...this.logs.items, ...response.data?.items];
              }
            } else {
              this.logs = response?.data;
            }
          }
        },
        (err: ErrorModel) => {
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(MessageConstant.unknownError, '');
          }
        }
      );
  }

  getMainStatus(propertyStat?) {
    const obj = {
      page: 1,
      limit: 100,
      moduleId: '5faa63fdfd20cd581703d255',
    };
    this._sharedService.getMainStatus(obj).subscribe(
      (response) => {
        if (response.statusCode == 200) {
          let arr = response?.data?.items || [];
          arr = arr.filter((e) => {
            return e?._id != '60221e37d8562ef0219c072a';
          });
          arr.push(
            { _id: '5feb3f94aa810a3849fa5515', title: 'Inventory' },
            { _id: '5feb4221aa810a3849fa551b', title: 'Sold' },
            { _id: '5feb4252aa810a3849fa551c', title: 'Rental' }
          );
          this.allMainStatus = arr;
          if (propertyStat) {
            this.inLeadStatus = 'Yes';
            arr.filter((e) => {
              if (e?._id == propertyStat)
                this.inLeadStatus +=
                  ' (' +
                  this._utilities.capitalizeName(e?.labels?.title || e?.title) +
                  ')';
            });
          }
        }
      },
      (err) => {}
    );
  }

  addMarketingDetails() {
    let idsObj = { ids: [this.propertyDetails?._id], dataType: 'Self' };
    this.dialogRef = this.dialog.open(ListExportMarketingComponent, {
      data: {
        title: 'Add Marketing Details',
        myobj: idsObj,
        fromExport: false,
      },
      height: 'auto',
      width: '600px',
      panelClass: 'device-modal',
      disableClose: false,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getPropertyDetails(true);
      }
    });
  }

  addPhoneNumber() {
    this.dialogRef = this.dialog.open(AddPhoneDialogComponent, {
      data: {
        title: 'Add Marketing Details',
        id: this.propertyList[this.currentContact],
        details: this.propertyDetails,
        activityAdd: true,
      },
      height: 'auto',
      width: '800px',
      panelClass: 'device-modal',
      disableClose: false,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getAllPhoneNumberList();
      }
    });
  }
  managePhoneStatus(type, phoneStatusVal?, index?) {
    this.dialogRef = this.dialog.open(ManagePhoneStatusComponent, {
      data: {
        type: type,
        phoneStatusVal: phoneStatusVal,
        id: this.propertyList[this.currentContact],
        details: this.propertyDetails,
        index: index - 1,
      },
      height: 'auto',
      width: '400px',
      disableClose: true,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getAllPhoneNumberList();
      }
    });
  }
  addEmail() {
    this.dialogRef = this.dialog.open(ManageEmailDialogComponent, {
      data: {
        title: 'Email Address',
        id: this.propertyList[this.currentContact],
        details: this.propertyDetails,
        activityAdd: true,
      },
      height: 'auto',
      width: '400px',
      disableClose: false,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getAllEmailList();
      }
    });
  }
  redialMakeCall(type) {
    this.saveStatus(type);
  }

  convertToLead() {
    this.dialogRef = this.dialog.open(DialerConvertLeadComponent, {
      data: {
        campaignId: this.campaignId
          ? this.campaignId
          : this.campaignIdList[this.currentContact],
        details: this.propertyDetails,
        dialerType: this.dialerType,
        leadName: this.callConnectedStatus[3],
      },
      height: 'auto',
      width: '350px',
      disableClose: true,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result?._id) {
        this.callStatus = this.moveToLeadStatus;
        this.isPropertyToLead = true;
      } else {
        this.isPropertyToLead = false;
        this.callStatus = '';
        setTimeout(() => {
          this.getPropertyDetails(true);
        }, 2000);
      }
    });
  }

  sendSmsDialog(type?) {
    let phoneObj = {};
    let phoneArray = [];
    let leadNumberArray = [];
    let selectNumber;
    if (this.allPhoneNumberList.length > 0) {
      this.allPhoneNumberList.forEach((item) => {
        leadNumberArray.push({
          number: item,
        });
      });
    } else {
      this.defaultNumberList.forEach((item) => {
        leadNumberArray.push({
          number: item.number,
        });
      });
    }

    this.phoneData.forEach((item) => {
      phoneObj = {
        phoneNumber: environment.countryCode + item.phone,
        name: this.propertyDetails.Full_Name,
      };
      phoneArray.push(phoneObj);
    });

    if (type) {
      selectNumber = environment.countryCode + type;
    } else {
      selectNumber = phoneArray[0]?.phoneNumber;
    }

    if (selectNumber == environment.countryCode) {
      this._toastrService.error(MessageConstant.noNumberProperty, '');
      return;
    }
    let checkCallNumber = false;
    leadNumberArray.forEach((item) => {
      if (item.number.includes(this.defaultCallNumber)) {
        checkCallNumber = true;
      }
    });
    this._loaderService.start();
    this._creditService.getSMSCredit({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          let SMSBalance = response?.data;
          let obj = {
            leadSource: leadNumberArray,
            defaultSmsNumber:
              this.defaultCallNumber && checkCallNumber
                ? this.defaultCallNumber
                : '',
            moduleType: 'ListStacking',
            propertyFormatedAddress: this.propertyDetails?.Formated_Address,
            dialerSmsTemplete: 'dialer Sms',
            propertyAddress: this.propertyDetails?.Property_Street_Address,
            propertyCity: this.propertyDetails?.Property_City,
            propertyState: this.propertyDetails?.Property_State,
            propertyZipCode: this.propertyDetails?.Property_Zip_Code,
            contacts: phoneArray,
            phoneNumber: selectNumber,
            leadId: this.propertyList[this.currentContact],
            moduleId: this.listModuleId,
            SMSBalance,
            dialerCampaignId: this.campaignId
              ? this.campaignId
              : this.campaignIdList[this.currentContact],
          };

          this.dialogRef = this.dialog.open(SmsDialogComponent, {
            width: '600px',
            data: obj,
          });

          this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
            }
          });
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }
  setSmsTemplate(id, bool?) {
    this.showAttachments = [];
    if (!id) {
      this.smsDetailForm.patchValue({
        smsBody: '',
      });
    }
    const smsTemplateId = id;
    if (bool) {
      let obj = { smsTemplateId: smsTemplateId };

      this._smsService.changeTemplate(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
          }
        },
        (err: ErrorModel) => {}
      );
    }
    const index = this.smsTemplateList.findIndex(
      (x) => x._id === smsTemplateId
    );
    if (index === -1) {
      return;
    }
    if (this.smsTemplateList[index]?.attachmentArray) {
      let attchments = this.smsTemplateList[index]?.attachmentArray.map(
        (item) => {
          return { name: item?.name, url: item?.url };
        }
      );
      this.showAttachments = attchments;
    }
    this.messageBody(
      this._utilities.getTextWithMentions(this.smsTemplateList[index].message),
      []
    );
    this.smsDetailForm.patchValue({
      smsBody: this._utilities.populateEditorDataWithN(this.smsFilterBody),
    });
    this.getSMSCount('');
  }
  getSMSCount(e) {
    const MAX_LENGTH = 160;
    const message = this._utilities.getText(
      this['editor'].quillEditor.getContents()
    );

    const length = message.length ? message?.length - 1 : 0;

    this.smsCount = Math.ceil(length / MAX_LENGTH);
    this.smsCharCount = MAX_LENGTH * this.smsCount - length;
  }
  messageBody(smsMessage, teams) {
    if (this.currentNumberCall + 1 > this.allPhoneNumberList?.length) {
      this.currentNumberCall = 0;
    }
    const { smsNumber } = this.smsDetailForm.value;
    let data = {
      leadSource:
        this.callingFrom != ''
          ? this.callingFrom
          : this.defultNumber != ''
          ? this.defultNumber
          : this.allPhoneNumberList[this.currentNumberCall],
      propertyFormatedAddress: this.propertyDetails?.Formated_Address,
      dialerSmsTemplete: 'dialer Sms',
      propertyAddress: this.propertyDetails?.Property_Street_Address,
      propertyCity: this.propertyDetails?.Property_City,
      propertyState: this.propertyDetails?.Property_State,
      propertyZipCode: this.propertyDetails?.Property_Zip_Code,
      leadId: this.propertyDetails?._id,
      moduleId: this.listModuleId,
      name: this.propertyDetails?.Full_Name,
    };

    let messageBody;
    let address;
    let streetAddress;
    let city;
    let state;
    let zip;
    let name;
    let firstName;
    let lastName;

    if (data?.name) {
      const leadTitle = data?.name
        ? data.name.replace(/ {2,}/g, ' ').trim()
        : '';
      name = leadTitle ? leadTitle.split(' ') : '';
      firstName = name[0] ? name[0] : 'First_Name(N/A)';
      lastName = name[1] ? name[1] : 'last_Name(N/A)';
    } else {
      firstName = 'First_Name(N/A)';
      lastName = 'last_Name(N/A)';
    }
    if (data.dialerSmsTemplete && data.dialerSmsTemplete == 'dialer Sms') {
      address = data?.propertyFormatedAddress;
      streetAddress = data?.propertyAddress
        ? data?.propertyAddress
        : 'Property_Street_Address(N/A)';
      city = data?.propertyCity ? data?.propertyCity : 'Property_City(N/A)';
      state = data?.propertyState ? data?.propertyState : 'Property_State(N/A)';
      zip = data?.propertyZipCode
        ? data?.propertyZipCode
        : 'Property_Zip_Code(N/A)';
    } else {
      streetAddress = 'Property_Street_Address(N/A)';
      city = 'Property_City(N/A)';
      state = 'Property_State(N/A)';
      zip = 'Property_Zip_Code(N/A)';
    }

    let myObj = {
      '@First_Name': this._utilities.titleCaseToWord(firstName),
      '@Last_Name': this._utilities.titleCaseToWord(lastName),
      '@Property_Street_Address': streetAddress ? streetAddress : '',
      '@Property_City': city ? city : '',
      '@Property_State': state ? state : '',
      '@Property_Zip_Code': zip ? zip : '',
      '@Appt_Date_and_Time': '',
      '@Appt_Day': '',
      '@Appt_Time': '',
    };
    teams?.roles?.map((i) => {
      let key = i.name.toString();
      if (key != undefined && smsMessage.includes(key)) {
        let originalRole = teams.roles.find((item) => item.name == key);
        let userId = teams.assignUser[originalRole._id];
        if (!userId) {
          if (teams?.items?.roles) {
            originalRole = teams?.items?.roles.find(
              (item) => item.name == 'Owner'
            );
          }
          if (teams?.roles) {
            originalRole = teams?.roles.find((item) => item.name == 'Owner');
          }
        }
        userId =
          userId != null
            ? userId
            : teams?.items?.assignUser['5fd093a2f44c5ac2ea1919b4']
            ? teams?.items?.assignUser['5fd093a2f44c5ac2ea1919b4']
            : teams?.assignUser['5fd093a2f44c5ac2ea1919b4'];
        let username;
        if (userId) {
          username = originalRole.members.find(
            (item) => item._id.toString() == userId.toString()
          );
        }
        let obj = {};
        obj['@' + key] = username?.firstName
          ? this._utilities.titleCaseToWord(`${username?.firstName}`)
          : `${key}(N/A)`;
        myObj = { ...myObj, ...obj };
      }
    });

    let rowRole = [
      '@First_Name',
      '@Last_Name',
      '@Property_Street_Address',
      '@Property_City',
      '@Property_State',
      '@Property_Zip_Code',
      '@Appt_Date_and_Time',
      '@Appt_Day',
      '@Appt_Time',
    ];

    let roles = teams?.roles
      ? teams?.roles?.map((item) => '@' + item.name)
      : [];
    let rolesWithKey = new RegExp([...rowRole, ...roles].join('|'), 'g');
    messageBody = smsMessage.replace(rolesWithKey, (matched) => {
      return myObj[matched];
    });
    this.smsFilterBody = messageBody;
  }
  mentionEditor() {
    return {
      mentionListClass: 'ql-mention-list mat-elevation-z8',
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      showDenotationChar: true,
      spaceAfterInsert: true,
      onSelect: (item, insertItem) => {
        const editor = this.editor.quillEditor;
        insertItem(item);
        editor.insertText(editor.getLength() - 1, '', 'user');
      },
      source: (searchTerm, renderList) => {
        const values = this.items.map((item, index) => {
          return { id: index, value: item };
        });

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];

          values.forEach((entry) => {
            if (
              entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            ) {
              matches.push(entry);
            }
          });
          renderList(matches, searchTerm);
        }
      },
    };
  }
  onUploadLogo(output: UploadOutput): void {
    this.fileInput.nativeElement.value = '';
    this._loaderService.start();
    clearTimeout(this.timeOut);
    if (output.type === 'allAddedToQueue') {
      // when all files added in queue
      const token = localStorage.getItem('token');
      let endpoint =
        environment.baseUrl + BaseUrl.sharedUrl + 'mms-file-upload';
      if (this.listModuleId) {
        endpoint += `?moduleId=${this.listModuleId}&isPublic=1`;
      }

      const event: UploadInput = {
        type: 'uploadAll',
        url: endpoint,
        method: 'POST',
        headers: { Authorization: token },
      };
      this.uploadInput.emit(event);
    } else if (
      output.type === 'addedToQueue' &&
      typeof output.file !== 'undefined'
    ) {
    } else if (output.type === 'done' && typeof output.file !== 'undefined') {
      // this.files = output.file;
      this.count += 1;
      if (output.file.response.statusCode == 200) {
        this._loaderService.stop();
        if (typeof output.file.response.data == 'object') {
          output.file.response.data?.filter((x) => {
            this.attachments.push(x);
          });
          this.attachments = _.uniqBy(this.attachments, 'name');
        } else {
          this.attachments = output.file.response.data;
          this.attachments = _.uniqBy(this.attachments, 'name');
        }
      } else {
        this._loaderService.stop();
        if (output.file?.response?.message) {
          this._toastrService.error(output.file.response.message);
        } else {
          this._toastrService.error('Invalid');
        }
      }
    } else if (output.type === 'dragOut') {
      // drag out event
      setTimeout(() => {
        this._loaderService.stop();
      }, 2000);
    } else if (output.type === 'drop') {
      // on drop event
      setTimeout(() => {
        this._loaderService.stop();
      }, 2000);
    }
  }
  removeExistingAttachment(index) {
    this.showAttachments.splice(index, 1);
  }
  removeAttachment(index) {
    this.attachments.splice(index, 1);
  }
  public hasErrorSms = (controlName: string, errorName: string) => {
    return this.smsDetailForm.controls[controlName].hasError(errorName);
  };
  sendSms() {
    if (this.SMSCredit == 0) {
      return;
    }
    this.submittedSms = true;
    if (this.smsDetailForm.invalid) {
      return;
    }
    const { smsNumber } = this.smsDetailForm.value;
    let number =
      this.callingFrom != ''
        ? this.callingFrom
        : this.defultNumber != ''
        ? this.defultNumber
        : this.allPhoneNumberList[this.currentNumberCall];
    let smsNumbers = this.phoneData[smsNumber]?.phone
      ? this.phoneData[smsNumber]?.phone
      : this.phoneData[smsNumber]?.phone;
    let obj = {
      ...this.smsDetailForm.value,
      leadSourceNumber: number,
      smsNumber: environment.countryCode + smsNumbers,
      moduleType: 'ListStacking',
      propertyId: this.propertyDetails?._id,
      fromBuyerModule: this.data.fromBuyerModule,
      dialerCampaignId: this.campaignId
        ? this.campaignId
        : this.campaignIdList[this.currentContact],
    };
    if (this.showAttachments.length > 0) {
      this.showAttachments.forEach((item) => {
        this.attachments.push(item);
      });
    }

    if (this.attachments.length) {
      obj = {
        ...obj,
        mediaUrls: this.attachments.map((x) => x.url),
      };
      this.attachments = [];
    } else {
      delete obj['mediaUrls'];
    }
    this._loaderService.start();
    delete obj['scheduleDate'];
    delete obj['scheduleTime'];
    this.messageBody(this._utilities.getTextWithMentions(obj.smsBody), []);
    obj.smsBody = this._utilities.populateEditorDataBR(this.smsFilterBody);

    if (obj.smsBody) {
      obj.smsBody = obj.smsBody
        ?.replaceAll('&amp;', '&')
        ?.replaceAll('&gt;', '>')
        ?.replaceAll('&lt;', '<')
        ?.replaceAll('&#xFEFF;', '');

      obj.smsBody = obj.smsBody.replace(/[^\x00-\x7F]/g, '');
    }
    this._sharedService.sendSms(obj).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode == 200) {
          this.submittedSms = false;
          this.smsDetailForm.patchValue({
            smsBody: '',
          });
          this.getSMSBalance();
          this._toastrService.success(MessageConstant.smsSendSuccess, '');
          this._sharedService.refreshActivityLog.next(true);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }
  getSMSBalance() {
    this._creditService.getSMSCredit({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.SMSCredit = response?.data;
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }
  streetViewApi(map, address) {
    if (address) {
      this._sharedService.getMapLatLng({ address }).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            let responseAddress = response?.data?.location;
            this.onStreetMapDailog(map, address, responseAddress);
          }
          this._loaderService.stop();
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(MessageConstant.unknownError, '');
          }
        }
      );
    }
  }
  onStreetMapDailog(map, address, responseAddress) {
    this.dialogRef = this.dialog.open(StreetMapComponent, {
      width: '820px',
      height: '450px',
      panelClass: 're-new-googleMap',
      data: {
        action: map,
        address,
        responseAddress,
      },
    });
  }
  changePhoneNumber() {
    this.dialogRef = this.dialog.open(ChangeNumberDialogComponent, {
      width: '450px',
      height: 'auto',
      panelClass: 'device-modal',
      disableClose: true,
      data: {
        campaignId: this.campaignId
          ? this.campaignId
          : this.campaignIdList[this.currentContact],
        id: this.propertyList[this.currentContact],
        details: this.propertyDetails,
        numberList: this.defaultNumberList,
        numberId: this.defaultCallNumber,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.defultNumber = result?.defaultNumber;
        this.defaultCallNumber = result?.defaultNumber;
        this.propertyDefultNumber[this.propertyList[this.currentContact]] =
          this.defaultCallNumber;
      }
    });
  }
  callAttempts(bool?) {
    let phoneArray = [];
    this.phoneData.forEach((item) => {
      phoneArray.push(environment.countryCode + item.phone);
    });
    let obj = { phoneNumber: phoneArray };
    if (bool) {
      this._loaderService.start();
    }
    this._dialerService.callAttempts(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          let sum = 0;
          let allDateList = [];
          this.lastCallNumber = 0;
          this.lastCallDateTime = '';
          response?.data.forEach((element) => {
            sum += element.count;
            this.callAttempt[element._id] = element.count;
            this.callDateAttempt[element._id] = moment(element.lastAttempt)
              .tz(localStorage.timezoneOffset)
              .format('MMM DD, YYYY');
            allDateList.push(new Date(element.lastAttempt));
            const maxDate = new Date(Math.max(...allDateList));
            let lastDateTime = maxDate.getTime();
            if (lastDateTime == element.lastAttempt) {
              this.lastCallNumber = element._id;
            }
            this.lastCallDateTime = moment(maxDate)
              .tz(localStorage.timezoneOffset)
              .format('MMM DD, YYYY');
          });
          this.totalCallAttempt = sum;
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }
  moveToLead() {
    this.dialogRef = this.dialog.open(DialerConvertLeadComponent, {
      data: {
        campaignId: this.campaignId
          ? this.campaignId
          : this.campaignIdList[this.currentContact],
        details: this.propertyDetails,
        dialerType: this.dialerType,
        leadName: this.callConnectedStatus[3],
      },
      height: 'auto',
      width: '350px',
      disableClose: true,
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result?._id) {
        this.callStatus = '';
        setTimeout(() => {
          this.getPropertyDetails(true);
        }, 2000);
      }
    });
  }
  ngOnDestroy() {
    this.endTime = Date.now();
    var totalTime = ((this.endTime - this.startTime) / 60000).toFixed(2);
    this.dialerTimeCount(totalTime);
  }
  dialerTimeCount(totalTime) {
    let obj = { totalTime: totalTime };
    this._dialerService.dialerTimeCount(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          if (this.timerSetCounter) {
            clearInterval(this.timerSetCounter);
          }
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }
  changeStatus() {
    this.isCallStatusChange = !this.isCallStatusChange;
  }
  getCallLastStatus(id) {
    let obj = {
      propertyId: id,
    };
    this._loaderService.start();
    this._dialerService.getCallLastStatus(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.lastCallStatus = response?.data;
          this.callStatus = response?.data?.statusId;
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }
  async callCountDay(number) {
    return new Promise(async (resolve, reject) => {
      let param = {
        phoneNumber: number,
        isLs: true,
      };
      this._dialerService.callCountDay(param).subscribe(
        (response: ResponseModel) => {
          resolve(response?.data);
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          resolve(0);
        }
      );
    });
  }
  dropVm() {
    if (this.callUUID) {
      let obj = { uuid: this.callUUID, vmUrl: this.voiceMailUrl };
      //api call
      this._loaderService.start();
      this._dialerService.dropVmFn(obj).subscribe(
        (response) => {
          if (response.statusCode == 200) {
            this.callStatus = this.leftVM;
          }
        },
        (err: ErrorModel) => {
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(MessageConstant.unknownError, '');
          }
        }
      );
    }
  }
  addLogs() {
    if (!this.comment) {
      return;
    }
    this.comment = this.comment.trim();
    let obj = {
      subModuleId: this.propertyList[this.currentContact],
      comment: this.comment,
    };
    if (this.propertyDetails?.Formated_Address) {
      obj['address'] = this.propertyDetails?.Formated_Address;
    }
    if (this.propertyDetails?.Full_Name) {
      obj['title'] = this.propertyDetails?.Full_Name;
    }
    obj['phoneNumber'] = this.phoneData[0].phone
      ? environment.countryCode + this.phoneData[0].phone
      : '';
    let leadObj = obj;
    this._dialerService.addNotes(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          if (this.propertyDetails?.lead_property_id) {
            leadObj['subModuleId'] = this.propertyDetails?.lead_property_id;
            leadObj['moduleId'] = '5faa63fdfd20cd581703d255'; // Lead moduleId
            delete leadObj['createdBy'];
            delete leadObj['subactivity'];
            this._sharedService.addActivityLog(leadObj, false).subscribe(
              (response: ResponseModel) => {},
              (err: ErrorModel) => {}
            );
          }

          this.comment = '';
        }
      },
      (err: ErrorModel) => {
        this.comment = '';
      }
    );
  }
  allHoldUnholdMember(data) {
    this.holdCall = data.hold;
    this.muteCall = data.mute;
    const user = JSON.parse(localStorage.getItem('currentUser'));
    this._flowService
      .allHoldUnholdMember({
        EventName:
          this.callingFrom != ''
            ? this.callingFrom
            : this.defultNumber != ''
            ? this.defultNumber
            : this.allPhoneNumberList[this.currentNumberCall],
        MPCUUID: '',
        allHold: data.hold,
        allMute: data.mute,
        memberList: [],
        mergeCallDialer: {},
        uname: user.eUname,
      })
      .subscribe((response: ResponseModel) => {});
  }

  getMatched(array1, array2) {
    let matchedElement = [],
      matched = false;
    matchedElement = array1.filter((element) => array2.includes(element));
    //checking for condition
    if (matchedElement.length > 0) {
      matched = true;
    }
    return matched;
  }

  getPropertyStat(eachProperty) {
    let pStat = 'No';
    if (eachProperty?.InLead) {
      pStat = '';
      this.allMainStatus.filter((e) => {
        if (e?._id == eachProperty?.lead_property_status) {
          pStat = e?.labels?.title || e?.title;
        }
      });
    }
    return this._utilities.capitalizeName(pStat);
  }

  twilioOutboundCallEvent(connection) {
    this.twilioCallSid = connection?.parameters?.CallSid;
    this.callUUID = connection?.parameters?.CallSid;
    if (this.twilioCallSid) {
      // this.callAnswered = true;
      this.callReady = true;
      this.callDuration = '00:00:00';
      this.callStatus = 'Ongoing Call...';
      let a = moment();
      let b = moment();
      this.counter = setInterval(() => {
        this.showTime(a, b);
      }, 1000);
    }
  }
  getPropertyData(onLoad?) {
    return new Promise(async (resolve, reject) => {
      this.defultNumber =
        this.propertyDefultNumber[this.propertyList[this.currentContact]];
      this.propertyDetailLoader = true;
      this._listStackingService
        .getListDetail({ _id: this.propertyList[this.currentContact] })
        .subscribe(
          (response) => {
            this.propertyDetailLoader = false;
            this.callDisplay = '';
            if (response.code == 200) {
              this.propertyDetails = response.data;
              this.duplicateData = response.duplicate;
              this.singleListsDMail = response?.data?.dmDetails
                ? response?.data?.dmDetails
                : [];
              this.getAllUserDetails();
              this.getCallLastStatus(this.propertyDetails?._id);
              this.getAllMarketingData(this.propertyDetails?.exportDetails);
              this.getAllPhoneNumberList();
              this.getAllEmailList();
              this.getDefaultCampaignList();
              this.currentPage = 1;
              this.getActivityList();
              if (this.scriptDetail) {
                this.scriptDescription = this.getReplacedString(
                  this.scriptDetail?.description,
                  this.items,
                  this.propertyDetails
                );
              }
              this.title =
                this.propertyDetails?.firstName +
                ' ' +
                this.propertyDetails?.lastName;
              this.callEndedStat = false;
              this.numberToCall = '';
              this.displayNumberToCall = '';
              if (this.phoneData.length) {
                this.numberToCall =
                  environment.countryCode +
                  this._utilities.unMaskNumber(
                    this.phoneData[this.currentNumber].phone
                  );
                this.displayNumberToCall = this.numberToCall;
              }
              this.getListNames();
              this.getTagNames();
              this.currentNumber = this.currentNumber;
              this.currentViewNumber = this.currentNumber;
              this.getSMSTemplate();
              this.getUser(onLoad ? true : false);
              if (this.propertyDetails?.InLead == false) {
                this.inLeadStatus = 'No';
              }
              this.getMainStatus(this.propertyDetails?.lead_property_status);
              setTimeout(() => {
                this.callAttempts();
              }, 1000);
              resolve(this.propertyDetails);
            } else {
              this._toastrService.error('No matching data found');
              reject(false);
            }
          },
          (err) => {
            this.propertyDetailLoader = false;
            if (err.error) {
              const error = err.error;
              this._toastrService.error('No matching data found', '');
            } else {
              this._toastrService.error(MessageConstant.unknownError, '');
            }
          }
        );
    });
  }
}
