import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-task-drip-status-change',
  templateUrl: './task-drip-status-change.component.html',
  styleUrls: ['./task-drip-status-change.component.scss']
})
export class TaskDripStatusChangeComponent implements OnInit {

  yesButtonTitle: string = 'Continue';
  noButtonTitle: string = 'Cancel';

  constructor(
    private dialogRef: MatDialogRef<TaskDripStatusChangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data?.yesTitle) {
      this.yesButtonTitle = data?.yesTitle;
    }
    if (data?.noTitle) {
      this.noButtonTitle = data?.noTitle;
    }
  }

  ngOnInit(): void {
    this.data.taskTypeAssignment = "NONE";
    this.data.dripStop = false
  }

  submit() {
    this.dialogRef.close(this.data);
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(this.data);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  chooseDripStop(bool) {
    if (bool) {
      this.data.dripStop = true;
    } else {
      this.data.dripStop = false;
    }
  }

  chooseTaskAssignment(type) {
    this.data.taskTypeAssignment = type;
  }

}
