import {
  Component,
  Inject,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// UTILS
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';
import { MessageConstant } from 'src/app/utils/message-constant';

// SERVICES
import { SharedService } from 'src/app/shared/shared.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../../providers/user/user.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
})
export class GeneralComponent implements OnInit, AfterViewInit {
  @Input() campaign: any;
  @Output() _emitter = new EventEmitter<any>();
  messageConstant = MessageConstant;
  generalForm: FormGroup;
  leadSources: any = '';
  submitted: boolean = false;
  flowList: any[];
  currentPage: number = 1;
  currentLimit: number = 100; //environment.pagination.pageLimit;
  markets: any[] = [];
  isMarketReadonly: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _sharedService: SharedService,
    private fb: FormBuilder,
    public _utilities: CommonFunctionsService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _usersService: UserService
  ) {}

  ngAfterViewInit(): void {
    this.generalForm.patchValue({
      marketingTitle: this.data?.details?.marketingTitle,
      number: this.data?.details?.number
        ? this._utilities.maskNumber(this.data?.details?.number)
        : '',
    });
    this.isMarketReadonly = true;
  }

  ngOnInit(): void {
    this.getLeadSourceData();
    this.getManageFlow();
    this.getMarkets();

    this.generalForm = this.fb.group({
      marketingTitle: ['', [Validators.required]],
      crmQuestionId: ['', [Validators.required]],
      number: ['', [Validators.required]],
      flowId: ['', [Validators.required]],
      marketId: ['', [Validators.required]],
    });
  }

  getLeadSourceData() {
    const data = {
      searchString: 'hear-about-us',
    };
    this._sharedService.getLeadSource(data).subscribe((response) => {
      if (response) {
        this.submitted = false;
        this.leadSources = response.data.items;

        this.generalForm.patchValue({
          crmQuestionId: this.campaign.crmQuestionId,
        });
      }
    });
  }

  getManageFlow() {
    const obj = {
      page: 1,
      limit: 2000,
    };

    this._sharedService.getManageFlow(obj).subscribe((response) => {
      if (response) {
        this.flowList = response?.data?.items;
        this.flowList.sort((a, b) => a.name.localeCompare(b.name));
        for (let i = 0; i < this.flowList.length; i++) {
          if (this.flowList[i]['_id'] == this.campaign?.flow?._id) {
            this.generalForm.patchValue({
              flowId: this.flowList[i]['_id'],
            });
            break;
          }
        }
      }
    });
  }

  getMarkets() {
    const obj = {
      page: this.currentPage,
      limit: this.currentLimit,
    };

    this._loaderService.start();
    this._usersService.getMarkets(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.markets = response.data?.items?.filter(
            (x) => x.isEnabled === true
          );
          this.generalForm.patchValue({
            marketId: this.campaign?.marketId,
          });
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  public get f() {
    return this.generalForm.controls;
  }

  onChangeEvent($event) {
    this._emitter.emit({ type: 'general-form', data: this.generalForm.value });
  }
}
