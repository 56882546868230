<div class="">
  <div class="col-12 d-flex re-header">
    <h2
      mat-dialog-title
      style="display: block !important"
      class="w-100 d-flex justify-content-between"
    >
      {{ data?.header }}
    </h2>
    <span matDialogClose mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<mat-dialog-content class="mat-typography">
  <div class="re-buy-credit" *ngIf="data.type != 'Email'">
    <div class="re-buy-txt">
      <p class="re-buy-txt1" *ngIf="data.type === 'SMS'">
        Available SMS Credits
      </p>
      <p class="re-buy-txt1" *ngIf="data.type === 'Call'">
        Available Call Credits
      </p>
      <p class="re-buy-txt1" *ngIf="data.type === 'RVM'">
        Available RVM Credits
      </p>
      <p class="re-buy-txt2" *ngIf="data.type === 'RVM'">
        {{ RVMBalance }} RVM Credits
      </p>
      <p class="re-buy-txt2" *ngIf="data.type === 'SMS'">
        {{ SMSBalance }} SMS Credits
      </p>
      <p class="re-buy-txt2" *ngIf="data.type === 'Call'">
        {{ callBalance }} Call Credits
      </p>
    </div>
    <a
      *ngIf="data.type != 'Email'"
      class="btn btn-credit"
      routerLink="/credit/buy-credit"
      matDialogClose
      mat-dialog-close
      >Buy Credit</a
    >
  </div>
  <div
    class="re-note-address"
    *ngIf="
      (data?.address || data?.mainStatusTitle || data?.sendType) &&
      data.type == 'Email'
    "
  >
    <span class="re-txt1" *ngIf="data?.address">{{
      utilities.formatAddress(data?.address)
    }}</span>
    <span class="re-txt2" *ngIf="data?.mainStatusTitle && data?.type"
      >{{ data?.sendType }} > {{ data?.mainStatusTitle }}</span
    >
  </div>
  <hr style="margin: 10px auto" *ngIf="data.sendType" />
  <div
    *ngIf="
      (data.type === 'Call' && callBalance > 0) ||
      (data.type === 'SMS' && SMSBalance > 0) ||
      (data.type === 'RVM' && RVMBalance > 0)
    "
  >
    <ng-container
      *ngIf="
        !data?.contacts?.secondaryContact?.length &&
        !data?.contacts?.buyers?.length &&
        !data?.contacts?.vendors?.length
      "
    >
      <ul class="re-list">
        <li class="text-center pr-0">
          <p class="re-name">No Data</p>
        </li>
      </ul>
    </ng-container>

    <ul
      class="re-list"
      *ngIf="
        data?.contacts?.secondaryContact ||
        data?.contacts?.buyers ||
        data?.contacts?.vendors
      "
    >
      <!-- SECONDARY CONTACTS -->
      <ng-container *ngIf="data?.contacts?.number">
        <li
          *ngFor="
            let contact of data?.contacts?.secondaryContact;
            let contactIndex = index
          "
        >
          <p class="re-name">
            {{ utilities.capitalizeName(contact?.name) || "N/A" }} &bull;
            {{ utilities.capitalizeName(contact?.contactType) || "seller" }}
          </p>
          <p class="re-number">
            {{ utilities.maskNumber(contact.phoneNumber) }}
            <span
              *ngIf="contact?.numberType && contact?.numberType !== 'invalid'"
            >
              &nbsp;({{ onNumberType(contact?.numberType) }})
            </span>
            <span
              *ngIf="
                contact?.numberType == '' ||
                contact?.numberType == 'invalid' ||
                contact?.numberType == null
              "
            >
              &nbsp;(N/A)
            </span>
          </p>
          <a
            (click)="callDetails(contact)"
            class="re-call"
            href="javascript:void(0);"
          >
            {{ data?.type }}
          </a>
        </li>
      </ng-container>

      <!-- BUYERS -->
      <li
        *ngFor="let contact of data?.contacts?.buyers; let contactIndex = index"
      >
        <p class="re-name">
          {{
            utilities.capitalizeName(contact?.firstName) +
              " " +
              (contact?.lastName
                ? utilities.capitalizeName(contact?.lastName)
                : "")
          }}
          &bull;
          {{ utilities.capitalizeName(contact?.contactType) }}
        </p>
        <p class="re-number">
          {{ utilities.maskNumber(contact.contactNumber) }}
          <span
            *ngIf="contact?.numberType && contact?.numberType !== 'invalid'"
          >
            &nbsp;({{ onNumberType(contact?.numberType) }})
          </span>
          <span
            *ngIf="
              contact?.numberType == '' ||
              contact?.numberType == 'invalid' ||
              contact?.numberType == null
            "
          >
            &nbsp;(N/A)
          </span>
        </p>
        <a
          (click)="getBuyerNumber(contact)"
          class="re-call"
          href="javascript:void(0);"
        >
          {{ data?.type }}
        </a>
      </li>

      <!-- VENDORS -->
      <li
        *ngFor="
          let contact of data?.contacts?.vendors;
          let contactIndex = index
        "
      >
        <p class="re-name">
          <ng-container *ngIf="contact?.name">
            {{ utilities.capitalizeName(contact?.name) }}
            &bull;
          </ng-container>
          {{ utilities.capitalizeName(contact?.category?.name) }}
          <span *ngIf="contact?.subcategoryId">
            &bull; {{ utilities.capitalizeName(contact?.subcategory?.name) }}
          </span>
        </p>
        <p class="re-number">
          {{ utilities.maskNumber(contact.contactNumber) }}
          <span
            *ngIf="contact?.numberType && contact?.numberType !== 'invalid'"
          >
            &nbsp;({{ onNumberType(contact?.numberType) }})
          </span>
          <span
            *ngIf="
              contact?.numberType == '' ||
              contact?.numberType == 'invalid' ||
              contact?.numberType == null
            "
          >
            &nbsp;(N/A)
          </span>
        </p>
        <a
          (click)="getBuyerNumber(contact, 'Vendors')"
          class="re-call"
          href="javascript:void(0);"
        >
          {{ data?.type }}
        </a>
      </li>
    </ul>
  </div>

  <!--Email List-->
  <div *ngIf="data.type === 'Email'">
    <ul
      class="re-list"
      *ngIf="
        data?.contacts?.secondaryContact ||
        data?.contacts?.buyers ||
        data?.contacts?.vendors
      "
    >
      <!-- SECONDARY CONTACTS -->
      <ng-container *ngIf="data?.contacts?.number">
        <li
          *ngFor="
            let contact of data?.contacts?.secondaryContact;
            let contactIndex = index
          "
        >
          <p class="re-name">
            {{ utilities.capitalizeName(contact?.name) || "N/A" }} &bull;
            {{
              utilities.capitalizeName(contact?.contactType) ||
                utilities.capitalizeName(contact?.categoryId?.name) ||
                "seller"
            }}
          </p>
          <p class="re-number">
            {{ contact.email || "N/A" }}
          </p>
          <a
            (click)="onEmailDialog(contact)"
            class="re-call"
            href="javascript:void(0);"
          >
            {{ data?.type }}
          </a>
        </li>
      </ng-container>

      <!-- BUYERS -->
      <li
        *ngFor="let contact of data?.contacts?.buyers; let contactIndex = index"
      >
        <p class="re-name">
          {{
            utilities.capitalizeName(contact?.firstName) +
              " " +
              (contact?.lastName
                ? utilities.capitalizeName(contact?.lastName)
                : "")
          }}
          &bull;
          {{ utilities.capitalizeName(contact?.contactType) }}
        </p>
        <p class="re-number">
          {{ contact.email || "N/A" }}
        </p>
        <a
          (click)="onEmailDialog(contact)"
          class="re-call"
          href="javascript:void(0);"
        >
          {{ data?.type }}
        </a>
      </li>

      <!-- VENDORS -->
      <li
        *ngFor="
          let contact of data?.contacts?.vendors;
          let contactIndex = index
        "
      >
        <p class="re-name">
          {{
            utilities.capitalizeName(contact?.firstName) +
              " " +
              (contact?.lastName
                ? utilities.capitalizeName(contact?.lastName)
                : "")
          }}
          &bull;
          {{ utilities.capitalizeName(contact?.categoryId?.name) }}
          <span *ngIf="contact?.subcategoryId">
            &bull; {{ utilities.capitalizeName(contact?.subcategoryId?.name) }}
          </span>
        </p>
        <p class="re-number">
          {{ contact.email || "N/A" }}
        </p>
        <a
          (click)="onEmailDialog(contact)"
          class="re-call"
          href="javascript:void(0);"
        >
          {{ data?.type }}
        </a>
      </li>
    </ul>
  </div>
</mat-dialog-content>
