<div class="re-top-bar" [class.re-custom-topbar]="!moduleIcon">
  <!-- LEFT BAR -->
  <div class="re-left-bar" [class.pl-0]="!moduleIcon">
    <img
      class="re-module-icon"
      *ngIf="moduleIcon"
      [src]="moduleIcon"
      [alt]="moduleName"
    />
    <a
      class="re-left-link"
      [routerLink]="linkRedirect"
      (click)="onMainLinkAddress()"
      *ngIf="linkTitle"
      style="cursor: pointer"
    >
      {{ linkTitle }}
    </a>
    <a
      class="re-left-link"
      routerLink="/settings"
      *ngIf="linkTitleSetting"
      style="cursor: pointer"
    >
      Settings >
    </a>
    <i class="fa fa-angle-right re-right-angle" *ngIf="linkAddress"></i>
    <a
      class="re-left-link"
      *ngIf="linkAddress"
      style="cursor: pointer"
      (click)="onLinkAddress()"
    >
      {{ linkAddress }}
    </a>
    <i class="fa fa-angle-right re-right-angle" *ngIf="linkTitle"></i>
    <span class="re-module-name" *ngIf="moduleName">
      <span
        [class.re-pointer]="isModuleClickExists"
        (click)="isModuleClickExists && callAction()"
      >
        {{ moduleName }}
      </span>

      <span class="re-sub-module-name" *ngIf="subModuleName">
        <i class="fa fa-angle-right"></i>
        {{ subModuleName }}
      </span>

      <span class="re-sub-module-name" *ngIf="subModuleNameDetails">
        <i class="fa fa-angle-right"></i>
        {{ subModuleNameDetails }}
      </span>

      <i
        *ngIf="moduleNameToolTip"
        class="fa fa-question-circle"
        pTooltip="The time below is {{ currentTimezone }}"
        tooltipPosition="bottom"
      ></i>

      <ng-container *ngIf="isModuleCountEnabled">
        ({{ getCount() }})
      </ng-container>

      <ng-container
        *ngIf="isUserTimeZoneEnabled && currentUser?.timezoneOffset"
      >
        <span class="re-time-zone"> > {{ currentUser?.timezoneOffset }} </span>
      </ng-container>

      <ng-container *ngIf="isEmailSyncEnabled && syncedUserEmail">
        <span class="re-email"> > {{ syncedUserEmail }} </span>
      </ng-container>
    </span>
  </div>

  <!-- RIGHT BAR -->
  <div class="re-right-bar">
    <!-- Whiteboard -->
    <!-- <div class="float-left" *ngIf="isCancelAccountEnable">
      <a class="btn re-btn-whiteboard" (click)="onCancelAccount()">
        <img
          src="/assets/images/navbar/billing-icons.svg"
          alt="Cancel Account"
        />
        <span class="re-btn-text">Cancel Account</span>
      </a>
    </div> -->

    <!-- Whiteboard -->
    <div class="float-left" *ngIf="isWhiteBoardEnable">
      <a
        class="btn re-btn-whiteboard"
        [routerLink]="['/leads']"
        [queryParams]="{ leadType: 'whiteboard' }"
      >
        <img src="/assets/images/whiteboard.svg" alt="whiteboard" />
        <span class="re-btn-text">Whiteboard</span>
      </a>
    </div>

    <!-- Back to lead -->
    <div class="float-left" *ngIf="isbackToLeadEnable">
      <a class="btn re-btn-whiteboard" [routerLink]="['/leads']">
        <img src="/assets/images/left-arrow.svg" alt="left-arrow" />
        <span class="re-btn-text">Back To Leads</span>
      </a>
    </div>

    <!-- My Task Count -->
    <div
      class="re-mytask-filter float-left"
      *ngIf="moduleName == 'My Tasks' || moduleName === 'Contingencies'"
    >
      <a
        (click)="searchDuePast()"
        href="javascript:void(0);"
        class="re-due-button re-red"
        [class.re-filter-active]="filterObj?.pastDate"
        [pTooltip]="getTooltip('Past Due')"
        tooltipPosition="bottom"
      >
        {{ pastDue }}
      </a>
      <a
        (click)="searchDueToday()"
        href="javascript:void(0);"
        class="re-due-button re-yellow"
        [class.re-filter-active]="filterObj?.presetDate"
        [pTooltip]="getTooltip('Due Today')"
        tooltipPosition="bottom"
      >
        {{ dueToday }}
      </a>
      <a
        (click)="searchDueLater()"
        href="javascript:void(0);"
        class="re-due-button re-green"
        [class.re-filter-active]="filterObj?.futureDate"
        [pTooltip]="getTooltip('Due Later')"
        tooltipPosition="bottom"
      >
        {{ dueLater }}
      </a>
      <a
        *ngIf="filterActive"
        class="btn btn-default re-reset-filter"
        type="button"
        (click)="clearFilter()"
        title="Reset Filter"
      >
        <i class="fa fa-refresh"></i> Reset All Filters
      </a>
    </div>

    <!-- SORT -->
    <div
      class="float-left"
      [class.re-border-right]="isSortEnabled"
      *ngIf="isSortEnabled"
    >
      <div class="re-box">
        <ng-container *ngIf="sort?.length">
          <a
            *ngFor="let item of sort"
            (click)="setSort(item?.name, true)"
            class="pointer"
            [class.active]="item?.isActive"
            [pTooltip]="item?.tooltip"
            tooltipPosition="bottom"
          >
            <img
              [src]="item?.icon + (item?.isActive ? '-active.svg' : '.svg')"
              [alt]="item?.name"
            />
          </a>
        </ng-container>
      </div>
    </div>

    <!-- Expanded Grid -->
    <div class="float-left re-border-right" *ngIf="isReduceGrid">
      <div class="re-box re-expand-reduce">
        <a
          class="pointer"
          pTooltip="Expanded Grid"
          tooltipPosition="bottom"
          (click)="setGridView(true)"
          [class.active]="_utilities.reduceGrid"
        >
          <img src="/assets/images/expanded-icons-1.svg" alt="expanded" />
        </a>
        <a
          class="pointer"
          pTooltip="Reduced Grid"
          tooltipPosition="bottom"
          (click)="setGridView(false)"
          [class.active]="!_utilities.reduceGrid"
        >
          <img src="/assets/images/reduce-icons-1.svg" alt="reduce" />
        </a>
      </div>
    </div>

    <!-- VIEW -->
    <div
      class="float-left"
      [class.re-border-right]="isViewEnabled"
      *ngIf="isViewEnabled"
    >
      <div class="re-box">
        <a
          *ngFor="let item of view"
          (click)="setView(item?.name, true)"
          class="pointer"
          [class.active]="item?.isActive"
          [pTooltip]="item?.tooltip"
          tooltipPosition="bottom"
        >
          <img
            [src]="item?.icon + (item?.isActive ? '-active.svg' : '.svg')"
            [alt]="item?.name"
          />
        </a>
      </div>
    </div>

    <!-- SELECT BAR -->
    <div
      class="dropdown re-dropdown"
      *ngIf="isSelectBarEnabled"
      id="re-select-field"
      #actionDropdown
    >
      <button class="btn" type="button" (click)="toggleMenu()">
        {{ getSelectLabel() }}
      </button>
      <div class="dropdown-menu" *ngIf="isMenuVisible">
        <div class="re-radiolist">
          <label class="re-radiobtn">
            Select visible ({{ moduleData?.items?.length || 0 }} items)
            <input
              type="radio"
              name="selectedValue"
              [(ngModel)]="selectedValue"
              (change)="selectAction(1)"
              value="Self"
            />
            <span class="re-checkmark"> </span>
          </label>
          <label class="re-radiobtn">
            Select all ({{ totalRecord }} items)
            <input
              type="radio"
              name="selectedValue"
              [(ngModel)]="selectedValue"
              (change)="selectAction(2)"
              value="All"
            />
            <span class="re-checkmark"></span>
          </label>
        </div>
        <div class="re-radiolist">
          <button
            class="re-btn-outline m-t-10 re-clear-btn"
            type="button"
            (click)="clearCustomSelection()"
          >
            Clear
          </button>
        </div>
      </div>
    </div>

    <div
      class="dropdown re-actiondrop"
      *ngIf="isActionBarEnabled && toggleActionBar"
    >
      <button
        class="btn"
        type="button"
        id="actiondropdownMenu"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Action
      </button>
      <div class="dropdown-menu" aria-labelledby="actiondropdownMenu">
        <ng-container *ngFor="let action of additionalActions">
          <ng-container *ngIf="action?.isGroup; else else_group">
            <a href="javascript:void(0)" class="re-group">
              <p>
                {{ action?.label }}

                <ng-container *ngIf="action?.isBeta">
                  <span class="re-active">(Beta)</span>
                </ng-container>
              </p>

              <ng-container *ngFor="let group of action?.subGroup">
                <a
                  href="javascript:void(0);"
                  (click)="emitSelected(group)"
                  class="re-link"
                >
                  <img
                    src="{{ group?.icon }}"
                    *ngIf="group?.icon"
                    class="re-action-icon"
                  />
                  <span [ngClass]="group?.icon ? 're-action-text' : ''">
                    {{ group?.label }}

                    <ng-container *ngIf="group?.isBeta">
                      <span class="re-active">(Beta)</span>
                    </ng-container>
                  </span>
                </a>
              </ng-container>
            </a>
          </ng-container>

          <ng-template #else_group>
            <a
              href="javascript:void(0);"
              (click)="emitSelected(action)"
              class="re-link"
            >
              <img
                src="{{ action?.icon }}"
                *ngIf="action?.icon"
                class="re-action-icon"
              />

              <span [ngClass]="action?.icon ? 're-action-text' : ''">
                {{ action?.label }}

                <ng-container *ngIf="action?.isBeta">
                  <span class="re-active">(Beta)</span>
                </ng-container>
              </span>
            </a>
          </ng-template>
        </ng-container>
      </div>
    </div>

    <div class="leadboard_topbar" *ngIf="moduleName == 'Leaderboard (Beta)'">
      <div class="dropdown" [class.show]="isLeaderboardMenuVisible">
        <button
          class="btn dropdown-toggle"
          type="button"
          (click)="toggleLeaderboardMenu()"
        >
          <i class="fa fa-th-large" aria-hidden="true"></i> Customize View
        </button>
        <div
          class="dropdown-menu dropdown-menu-right"
          *ngIf="isLeaderboardMenuVisible"
        >
          <div class="re-droptop">
            <h4>CUSTOMIZE VIEW</h4>
            <h6>Control column's visibility</h6>
            <img
              class="re-img"
              (click)="toggleLeaderboardMenu()"
              src="assets/images/close-black.svg"
              alt="close"
            />
          </div>
          <div class="re-dropbottom">
            <div class="re-switchdiv">
              <p class="switchtxt">Revenue</p>
              <label class="switch">
                <input
                  type="checkbox"
                  id="checkbox1"
                  [(ngModel)]="isRevenueShow"
                  (change)="onCustomViewChange('revenue', $event)"
                />
                <span class="slider"></span>
                <span class="text1" value="false">Hide</span>
                <span class="text2" value="true">Show</span>
              </label>
            </div>
            <div class="re-switchdiv">
              <p class="switchtxt">Average Revenue</p>
              <label class="switch">
                <input
                  type="checkbox"
                  id="checkbox2"
                  [(ngModel)]="isAvgRevenueShow"
                  (change)="onCustomViewChange('avgRevenue', $event)"
                />
                <span class="slider"></span>
                <span class="text1" value="false">Hide</span>
                <span class="text2" value="true">Show</span>
              </label>
            </div>
            <div class="re-switchdiv">
              <p class="switchtxt">Deals</p>
              <label class="switch">
                <input
                  type="checkbox"
                  id="checkbox3"
                  [(ngModel)]="isDealCountShow"
                  (change)="onCustomViewChange('dealCount', $event)"
                />
                <span class="slider"></span>
                <span class="text1" value="false">Hide</span>
                <span class="text2" value="true">Show</span>
              </label>
            </div>
            <div class="re-switchdiv">
              <p class="switchtxt">Contracts</p>
              <label class="switch">
                <input
                  type="checkbox"
                  id="checkbox4"
                  [(ngModel)]="isUnderContractCountShow"
                  (change)="onCustomViewChange('underContractCount', $event)"
                />
                <span class="slider"></span>
                <span class="text1" value="false">Hide</span>
                <span class="text2" value="true">Show</span>
              </label>
            </div>
            <div class="re-switchdiv">
              <p class="switchtxt">Offers</p>
              <label class="switch">
                <input
                  type="checkbox"
                  id="checkbox5"
                  [(ngModel)]="isOfferMadeCountShow"
                  (change)="onCustomViewChange('offerMadeCount', $event)"
                />
                <span class="slider"></span>
                <span class="text1" value="false">Hide</span>
                <span class="text2" value="true">Show</span>
              </label>
            </div>
            <div class="re-switchdiv">
              <p class="switchtxt">Appointments</p>
              <label class="switch">
                <input
                  type="checkbox"
                  id="checkbox6"
                  [(ngModel)]="isApptCountShow"
                  (change)="onCustomViewChange('apptCount', $event)"
                />
                <span class="slider"></span>
                <span class="text1" value="false">Hide</span>
                <span class="text2" value="true">Show</span>
              </label>
            </div>
            <div class="re-switchdiv">
              <p class="switchtxt">Calls</p>
              <label class="switch">
                <input
                  type="checkbox"
                  id="checkbox7"
                  [(ngModel)]="isCallsShow"
                  (change)="onCustomViewChange('calls', $event)"
                />
                <span class="slider"></span>
                <span class="text1" value="false">Hide</span>
                <span class="text2" value="true">Show</span>
              </label>
            </div>
            <div class="re-switchdiv">
              <p class="switchtxt">SMS</p>
              <label class="switch">
                <input
                  type="checkbox"
                  id="checkbox8"
                  [(ngModel)]="isSmsShow"
                  (change)="onCustomViewChange('sms', $event)"
                />
                <span class="slider"></span>
                <span class="text1" value="false">Hide</span>
                <span class="text2" value="true">Show</span>
              </label>
            </div>
            <div class="re-switchdiv">
              <p class="switchtxt">Average Call Duration</p>
              <label class="switch">
                <input
                  type="checkbox"
                  id="checkbox9"
                  [(ngModel)]="isAvgCallDurationShow"
                  (change)="onCustomViewChange('avgCallDuration', $event)"
                />
                <span class="slider"></span>
                <span class="text1" value="false">Hide</span>
                <span class="text2" value="true">Show</span>
              </label>
            </div>
            <div class="re-switchdiv">
              <p class="switchtxt">Total Call Duration</p>
              <label class="switch">
                <input
                  type="checkbox"
                  id="checkbox10"
                  [(ngModel)]="isTotalCallDurationShow"
                  (change)="onCustomViewChange('totalCallDuration', $event)"
                />
                <span class="slider"></span>
                <span class="text1" value="false">Hide</span>
                <span class="text2" value="true">Show</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Actions Dropdown -->
    <div class="float-left" *ngIf="isActionDropDownEnabled">
      <div class="dropdown re-actions-drop">
        <a class="dropdown-toggle" data-toggle="dropdown">
          <svg width="24" height="24" viewBox="0 0 24 24">
            <g>
              <circle cx="1" cy="1" r="1" transform="translate(3 11)"></circle>
              <rect
                width="14"
                height="2"
                rx=".94"
                transform="translate(7 11)"
              ></rect>
              <rect
                width="18"
                height="2"
                rx=".94"
                transform="translate(3 16)"
              ></rect>
              <rect
                width="18"
                height="2"
                rx=".94"
                transform="translate(3 6)"
              ></rect>
            </g>
          </svg>
          Actions
        </a>
        <div
          class="dropdown-menu dropdown-block dropdown-menu-right"
          role="menu"
        >
          <a
            class="re-links"
            *ngIf="moduleName == 'Dialer'"
            [class.re-campaignName-disable]="dialedCampaignName"
            (click)="
              dialedCampaignName
                ? ''
                : onCampaignUpdate(campaignDetails, 'campaignName')
            "
            >Change Campaign Name</a
          >
          <a
            class="re-links"
            *ngIf="moduleName == 'Dialer'"
            (click)="onCampaignUpdate(campaignDetails, 'campaignDelete')"
            >Delete Campaign</a
          >
          <a
            class="re-links"
            *ngIf="moduleName == 'Dialer'"
            (click)="onCampaignUpdate(campaignDetails, 'assignUser')"
            >Assign to Different User</a
          >
        </div>
      </div>
    </div>

    <!-- Search -->
    <div class="re-filter float-left" *ngIf="isSearchEnabled">
      <a
        href="javascript:;"
        class="btn btn-default"
        (click)="openSearchModal()"
        pTooltip="Search"
        tooltipPosition="bottom"
      >
        <!-- <span class="fa fa-search"></span> -->
        <img
          style="margin-top: 2px"
          src="/assets/images/search.svg"
          alt="search"
        />
      </a>
    </div>

    <!-- Search Bar -->
    <div
      class="re-filter re-border-right float-left"
      *ngIf="isSearchBarEnabled"
    >
      <form
        method="post"
        class="re-search search {{ isSearchOpen ? 'open' : '' }}"
        novalidate
        [formGroup]="searchDataForm"
        (ngSubmit)="onSearchSubmit()"
      >
        <div class="input-group re-search-input-group">
          <a
            href="javascript:;"
            class="btn btn-default"
            (click)="expandSearch()"
          >
            <span class="fa fa-search"></span>
          </a>
          <input
            type="text"
            class="form-control"
            name="searchVal"
            placeholder="Search here"
            formControlName="searchText"
            *ngIf="moduleName != 'Call Logs'"
          />
          <input
            type="text"
            class="form-control"
            name="searchVal"
            placeholder="Search here"
            formControlName="searchText"
            (keyup)="onSearchCallLog()"
            *ngIf="moduleName == 'Call Logs'"
          />

          <i class="re-clear" (click)="resetSearch()">&times;</i>
        </div>
      </form>
    </div>

    <!-- Call Log Archived -->
    <div class="re-logdiv float-left" *ngIf="isCallLogArchived">
      <a [class.re_ondiv]="isOngoingCall" (click)="onChangeOngoingCall('true')">
        Ongoing (Beta)
      </a>
      <a
        [class.re_ondiv]="!isOngoingCall"
        (click)="onChangeOngoingCall('false')"
        >Archived</a
      >
    </div>

    <!-- INBOX FILTER BUTTONS  -->
    <div
      class="float-left re-add re-inbox-div"
      *ngIf="
        currentModule == '2' &&
        (moduleName == 'Buyer/Agents' || moduleName == 'Vendors')
      "
    >
      <button
        class="btn btn-default re-btn-custom re-inbox-btn"
        (click)="showMessages(moduleName, 'all')"
        [ngClass]="{ 'active-button': activeButton === 'all' }"
      >
        All Messages
      </button>
      <button
        class="btn btn-default re-btn-custom re-inbox-btn"
        (click)="showMessages(moduleName, 'unread')"
        [ngClass]="{ 'active-button': activeButton === 'unread' }"
      >
        Unread
      </button>
      <button
        class="btn btn-default re-btn-custom re-inbox-btn re-no-border"
        (click)="showMessages(moduleName, 'read')"
        [ngClass]="{ 'active-button': activeButton === 'read' }"
      >
        Read
      </button>
    </div>

    <!-- REFRESH -->
    <div class="re-filter float-left re-refresh" *ngIf="isRefreshEnabled">
      <a
        href="javascript:;"
        class="btn btn-default"
        (click)="refresh()"
        pTooltip="Refresh"
        tooltipPosition="bottom"
      >
        <img src="/assets/images/refresh-1.png" alt="refresh" />
      </a>
    </div>

    <!-- FILTER -->
    <ng-container *ngIf="isFilterEnabled">
      <div class="re-filter float-left">
        <a
          href="javascript:;"
          class="btn btn-default"
          (click)="openFilterModal()"
          pTooltip="Filter"
          tooltipPosition="bottom"
        >
          <img src="assets/images/filter-icon.svg" alt="filter" />
        </a>
      </div>

      <!-- RESET FILTER -->
      <div class="re-reset-filter float-left" *ngIf="isFilter">
        <a href="javascript:;" class="btn btn-default" (click)="resetFilter()">
          <i class="fa fa-refresh"></i> Reset All Filter
        </a>
      </div>
    </ng-container>

    <div class="dropdown filter-drop ml-2" *ngIf="isDropdownFilterEnabled">
      <a
        id="dropdownMenuButton"
        class="dropdown-toggle dropdown-filter"
        [class.active-filter]="isFilter"
      >
        <img
          src="assets/images/filter-icon-new{{ isFilter ? '-active' : '' }}.svg"
          alt="filter"
          pTooltip="Filter"
          tooltipPosition="bottom"
          [matMenuTriggerFor]="menu"
          (click)="getDropdownFilters()"
        />

        <!-- APPLIED FILTER COUNT -->
        <span
          *ngIf="isFilter"
          class="re-count"
          pTooltip="Filter"
          tooltipPosition="bottom"
          [matMenuTriggerFor]="menu"
          (click)="getDropdownFilters()"
        >
          ({{ getFilterCount() }})
        </span>

        <i
          class="fa fa-times-circle pull-right"
          pTooltip="Clear All Filters"
          tooltipPosition="bottom"
          (click)="resetFilter()"
          *ngIf="isFilter"
        ></i>
      </a>
      <mat-menu #menu="matMenu" class="main-drop-menu">
        <app-filters
          #dropdownFilter
          [filters]="filters"
          [isFilter]="isFilter"
          [appliedFilters]="appliedFilters"
          [moduleId]="moduleId"
          [_applyFilter]="_applyFilter"
          [tagType]="tagType"
          [isSaveFilter]="isSaveFilter"
        ></app-filters>
      </mat-menu>
    </div>
    <div
      class="dropdown filter-drop ml-2"
      *ngIf="isDailerDropdownFilterEnabled && isStatsFilterMainUser"
    >
      <a
        id="dropdownMenuButton"
        class="dropdown-toggle dropdown-filter"
        [class.active-filter]="isFilter"
      >
        <img
          src="assets/images/filter-icon-new{{ isFilter ? '-active' : '' }}.svg"
          alt="filter"
          pTooltip="Filter"
          tooltipPosition="bottom"
          [matMenuTriggerFor]="menu"
          (click)="getDropdownDialerFilters()"
        />

        <!-- APPLIED FILTER COUNT -->
        <span
          *ngIf="isFilter"
          class="re-count"
          pTooltip="Filter"
          tooltipPosition="bottom"
          [matMenuTriggerFor]="menu"
          (click)="getDropdownDialerFilters()"
        >
          ({{ getFilterCount() }})
        </span>

        <i
          class="fa fa-times-circle pull-right"
          pTooltip="Clear All Filters"
          tooltipPosition="bottom"
          (click)="resetFilter()"
          *ngIf="isFilter"
        ></i>
      </a>
      <mat-menu #menu="matMenu" class="main-drop-menu">
        <app-dialer-filter
          #DialerDropdownFilter
          [dilerFilters]="dilerFilters"
          [isFilter]="isFilter"
          [appliedDialFilters]="appliedDialFilters"
          [moduleId]="moduleId"
          [_applyDialFilter]="_applyDialFilter"
          [isSaveDialFilter]="isSaveDialFilter"
        ></app-dialer-filter>
      </mat-menu>
    </div>
    <!-- APPLIED CALLLOGS FILTER -->
    <div
      class="dropdown filter-drop ml-2"
      *ngIf="isDailerCallLogDropdownFilterEnabled"
    >
      <a
        id="dropdownMenuButton"
        class="dropdown-toggle dropdown-filter"
        [class.active-filter]="isFilter"
      >
        <img
          src="assets/images/filter-icon.svg"
          alt="filter"
          pTooltip="Filter"
          tooltipPosition="bottom"
          [matMenuTriggerFor]="menu"
          (click)="getDropdownCallLogDialerFilters()"
        />

        <!-- APPLIED FILTER COUNT -->
        <span
          *ngIf="isFilter"
          class="re-count"
          pTooltip="Filter"
          tooltipPosition="bottom"
          [matMenuTriggerFor]="menu"
          (click)="getDropdownCallLogDialerFilters()"
        >
          ({{ getFilterCount() }})
        </span>

        <i
          class="fa fa-times-circle pull-right"
          pTooltip="Clear All Filters"
          tooltipPosition="bottom"
          (click)="resetFilter()"
          *ngIf="isFilter"
        ></i>
      </a>
      <mat-menu #menu="matMenu" class="main-drop-menu">
        <app-dialer-filter
          #DialerDropdownCallLogFilter
          [dilerFilters]="dialerCallLogFilters"
          [isFilter]="isFilter"
          [appliedDialFilters]="appliedDialFilters"
          [moduleId]="moduleId"
          [_applyDialFilter]="_applyDialFilter"
          [isSaveDialFilter]="isSaveDialFilter"
        ></app-dialer-filter>
      </mat-menu>
    </div>
    <!-- APPLIED SCHEDULED CALL BACK FILTER -->
    <div
      class="dropdown filter-drop ml-2"
      *ngIf="isDailerScheduledCallFilterEnabled && isScheduledCallMainUser"
    >
      <a
        id="dropdownMenuButton"
        class="dropdown-toggle dropdown-filter"
        [class.active-filter]="isFilter"
      >
        <img
          src="assets/images/filter-icon.svg"
          alt="filter"
          pTooltip="Filter"
          tooltipPosition="bottom"
          [matMenuTriggerFor]="menu"
          (click)="getDropdownScheduledCallDialerFilters()"
        />

        <!-- APPLIED FILTER COUNT -->
        <span
          *ngIf="isFilter"
          class="re-count"
          pTooltip="Filter"
          tooltipPosition="bottom"
          [matMenuTriggerFor]="menu"
          (click)="getDropdownScheduledCallDialerFilters()"
        >
          ({{ getFilterCount() }})
        </span>

        <i
          class="fa fa-times-circle pull-right"
          pTooltip="Clear All Filters"
          tooltipPosition="bottom"
          (click)="resetFilter()"
          *ngIf="isFilter"
        ></i>
      </a>
      <mat-menu #menu="matMenu" class="main-drop-menu">
        <app-dialer-filter
          #DialerDropdownScheduledCallFilter
          [dilerFilters]="dialerScheduledCallFilters"
          [isFilter]="isFilter"
          [mainUserId]="mainUserId"
          [appliedDialFilters]="appliedDialFilters"
          [moduleId]="moduleId"
          [_applyDialFilter]="_applyDialFilter"
        ></app-dialer-filter>
      </mat-menu>
    </div>

    <!-- DOWNLOAD -->
    <div class="re-filter float-left" *ngIf="isExportEnable">
      <a
        href="javascript:;"
        class="btn btn-default"
        (click)="exportData()"
        pTooltip="Export REsimpli Leads"
        tooltipPosition="bottom"
      >
        <img src="assets/images/download-icon.svg" alt="download" />
      </a>
    </div>

    <!-- DOWNLOAD ACTION -->
    <div class="re-filter float-left" *ngIf="isDownloadAction">
      <a
        href="javascript:;"
        class="btn btn-default"
        (click)="downloadDataAction()"
        [pTooltip]="downloadToolTip"
        tooltipPosition="left"
      >
        <img src="assets/images/download-icon.svg" alt="download" />
      </a>
    </div>

    <!-- CLOSE BUTTON FOR GLOBAL SEARCH -->
    <div *ngIf="isCloseBtnVisible" class="re-closeBtn-div">
      <a (click)="closeSearch()" class="btn re-close-btn">
        <span class="re-btn-text"> Close </span>
        <img src="assets/images/close-grey.svg" alt="close" />
      </a>
    </div>

    <span
      class="float-left"
      [class.re-border-left]="isActionLeftBorderShow"
      *ngIf="!isCloseBtnVisible"
    >
      <!-- ACTION -->
      <div class="float-left" *ngIf="isActionEnabled">
        <div class="dropdown re-actiondrop2" id="actionDropdownDiv">
          <button
            class="btn dropdown-toggle1"
            type="button"
            id="dropdownMenu1"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
          >
            Actions
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenu1">
            <div class="re-groupbox" *ngFor="let item of action">
              <p class="re-group-title">
                {{ item?.label }}
              </p>
              <ul class="re-view">
                <li
                  *ngFor="let key of item?.options"
                  (click)="onActionClick(key)"
                  [class.re-disabled]="key?.isDisabled"
                >
                  <a class="re-options">
                    {{ key?.label }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- CALENDARS -->
      <div class="float-left" #actionDropdown *ngIf="isCalendarEnabled">
        <div class="dropdown re-calendarsdrop" id="actionDropdownDiv">
          <button
            class="btn dropdown-toggle1"
            type="button"
            id="dropdownMenu1"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <img src="/assets/images/dropdown-icon.svg" alt="dropdown" />
            Calendars
          </button>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenu1"
          >
            <div class="checkboxlist" *ngFor="let user of users">
              <label class="checkboxbtn" (click)="selectedUsers(user)">
                <input
                  type="checkbox"
                  [name]="this.moduleName"
                  [value]="user?._id"
                  style="vertical-align: middle"
                  [checked]="user?.isChecked"
                />
                <span class="checkmark"></span>
                {{ user?.firstName }} {{ user?.lastName }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </span>

    <div class="re-filter float-left re-border-left" *ngIf="isImportEnabled">
      <a
        href="javascript:;"
        (click)="bulkUpload()"
        class="btn btn-default"
        pTooltip="Import"
        tooltipPosition="bottom"
      >
        <img src="./assets/images/cloud.svg" alt="cloud" />
      </a>
    </div>

    <div class="re-filter float-left re-border-left" *ngIf="isReportExport">
      <div class="re-btnbar">
        <div class="dropdown">
          <button
            class="btn btn-default"
            type="button"
            id="dropdownMenu1"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img src="./assets/images/download-icon.svg" alt="download" />
          </button>
          <div class="dropdown-menu pull-right" aria-labelledby="dropdownMenu1">
            <ul class="list">
              <li>
                <a href="javascript:;" (click)="reportExportAction(1)"
                  >Download PDF</a
                >
              </li>
              <li>
                <a href="javascript:;" (click)="reportExportAction(3)"
                  >Download Excel</a
                >
              </li>
            </ul>
          </div>
        </div>
        <a
          (click)="reportExportAction(2)"
          href="javascript:;"
          class="btn btn-default"
          ><img src="./assets/images/print-icon.svg" alt="print-icon"
        /></a>
      </div>
    </div>
    <!--View Button-->
    <a
      class="btn float-left re-view-btn re-p-5"
      *ngIf="showViewBtn"
      (click)="viewAction()"
    >
      <span class="re-btn-text"> View Logs </span>
    </a>

    <!-- ADD -->
    <span
      [class.re-border-left]="
        (isSearchEnabled ||
          isRefreshEnabled ||
          isFilterEnabled ||
          isDropdownFilterEnabled) &&
        (isAddEnabled || isAddCustomSection)
      "
    >
      <div class="re-add float-left" *ngIf="isGroupEnabled">
        <a class="btn re-btn-custom" (click)="callActionGroup()">
          <img src="/assets/images/add-group.svg" alt="plus-circle" />
          <span class="re-btn-text">Add Group</span>
        </a>
      </div>
      <div class="re-add float-left" *ngIf="isAddEnabled">
        <a
          class="btn re-btn-custom"
          *ngIf="moduleName != 'eSign Template' && !isCallEnabled"
          (click)="callAction()"
        >
          <img src="/assets/images/plus-circle.svg" alt="plus-circle" />
          <span class="re-btn-text" *ngIf="moduleAddName">
            {{ addText || "Add " + moduleAddName }}
          </span>
          <span class="re-btn-text" *ngIf="!moduleAddName">
            {{ addText || "Add " + moduleName }}
          </span>
        </a>
        <a
          class="btn re-btn-custom re-call-custbtn"
          *ngIf="moduleName != 'eSign Template' && isCallEnabled"
          (click)="callAction()"
        >
          <i aria-hidden="true" class="fa fa-phone"></i>
          <span class="re-btn-text" *ngIf="moduleAddName">
            {{ addText || "Add " + moduleAddName }}
          </span>
          <span class="re-btn-text" *ngIf="!moduleAddName">
            {{ addText || "Add " + moduleName }}
          </span>
        </a>
        <div class="uploadiv" *ngIf="moduleName == 'eSign Template'">
          <input
            type="file"
            accept=".pdf"
            #uploader
            ngFileSelect
            (uploadOutput)="uploadFile($event)"
            [uploadInput]="uploadInput"
          />
          <a class="btn re-btn-custom" *ngIf="moduleName == 'eSign Template'">
            <img src="/assets/images/plus-circle.svg" alt="plus-circle" />
            <span class="re-btn-text">
              {{ addText || "Add " + moduleName }}
            </span>
          </a>
        </div>
      </div>

      <!-- ADD CUSTOM SECTION -->
      <div class="re-add float-left" *ngIf="isAddCustomSection">
        <a class="btn re-btn-custom" (click)="_emitAddCustomSection.emit(true)">
          <img src="/assets/images/plus-circle.svg" alt="plus-circle" />
          <span class="re-btn-text">Add Custom Section</span>
        </a>
      </div>

      <!-- COPY WEB FORM -->
      <div class="re-add float-left" *ngIf="isCopyWebForm">
        <a class="btn re-btn-custom" (click)="_emitCopyToClipboard.emit(true)">
          <img src="/assets/images/Copy-inactive.svg" alt="Copy To Clipboard" />
          <span class="re-btn-text">{{ webFormHeader }}</span>
        </a>
      </div>

      <div class="re-add float-left" *ngIf="isCancelShow">
        <a class="btn re-btn-custom-cancel" (click)="cancelAction()">
          <span class="re-btn-text">Cancel</span>
        </a>
      </div>

      <div class="re-account-head-right" *ngIf="isAccountList">
        <a
          class="re-addje-btn"
          pTooltip="Add Journal Entry"
          tooltipPosition="bottom"
          (click)="_emitAccount.emit('moveToJournal')"
          >Add JE</a
        >
        <a class="re-pointer re-open-account-dropdown">
          <div class="dropdown mr-0">
            <button
              class="btn btn-secondary dropdown-toggle re-add-btn"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-expanded="true"
              (click)="onToggle()"
            >
              Add Account
            </button>
            <ul
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownMenuButton"
            >
              <li>
                <a (click)="_emitAccount.emit('linkAccount')">
                  <img src="./assets/images/account.svg" alt="account" /> Link
                  Account
                </a>
              </li>
              <li>
                <a (click)="_emitAccount.emit('manualAccount')">
                  <img src="./assets/images/banking-icon.svg" alt="banking" />
                  Manual Account
                </a>
              </li>
            </ul>
          </div>
        </a>

        <a
          class="re-pointer re-refresh-icon"
          style="cursor: pointer"
          (click)="_emitAccount.emit('refreshAccount')"
          pTooltip="Refresh Account"
        >
          <img src="/assets/images/refresh.svg" alt="refresh" />
        </a>
      </div>
      <div class="re-account-head-right" *ngIf="isListStackingAdd">
        <a class="re-pointer re-open-account-dropdown re-open-list-dropdown">
          <div class="dropdown mr-0">
            <button
              class="btn dropdown-toggle re-add-list-btn"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-expanded="true"
              (click)="onToggle()"
            >
              Add Record
            </button>
            <ul
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownMenuButton"
            >
              <li>
                <a (click)="_callBackFunction.emit('bulk')">
                  <img src="./assets/images/cloud.svg" alt="Bulk" /> Bulk Upload
                </a>
              </li>
              <li>
                <a (click)="_callBackFunction.emit('single')">
                  <img src="/assets/images/plus-circle.svg" alt="plus-circle" />
                  Add Single
                </a>
              </li>
            </ul>
          </div>
        </a>
      </div>
    </span>

    <!-- MANAGE VACATION MODE -->
    <div
      class="re-vacation-mode float-left"
      *ngIf="isManageVacationModeEnabled"
    >
      <a
        href="javascript:;"
        class="btn btn-default re-manage-vacation-mode"
        [class.re-mr-20]="isDeleteUserEnabled"
        (click)="editUser('vacation_mode')"
      >
        Manage Vacation Mode
      </a>

      <a
        href="javascript:;"
        class="btn btn-default re-deactivate-vacation-mode"
        *ngIf="isDeleteUserEnabled"
        (click)="editUser('remove_user')"
      >
        Delete
      </a>
    </div>
  </div>
</div>
