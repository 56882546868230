<div class="re-modal">
  <div class="dialog-header">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between"
        style="display: block !important"
      >
        {{ type === "UPDATE_BUYER" ? "Update" : type }} Offer
      </h2>
      <span matDialogClose mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>

  <form
    [formGroup]="addEditContactForm"
    (ngSubmit)="addBuyer()"
    autocomplete="off"
    novalidate
  >
    <mat-dialog-content class="">
      <div class="container-fluid p-0">
        <div class="re-box">
          <div class="row">
            <!-- BUYER NAME -->
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="re-label">
                  Select Buyer
                  <span class="text-danger">*</span>
                </label>
                <p-autoComplete
                  class="re-add-buyer-name"
                  placeholder="Name"
                  name="buyerName"
                  [(ngModel)]="buyerName"
                  [ngModelOptions]="{ standalone: true }"
                  [suggestions]="filteredBuyers"
                  (completeMethod)="checkBuyer($event, 'name')"
                  [minLength]="1"
                  (onSelect)="selectItem($event)"
                >
                  <ng-template let-buyer pTemplate="item">
                    <div class="ui-helper-clearfix">
                      <div class="re-search-name">
                        {{ _utilities.capitalizeName(buyer?.firstName) }}
                        {{ _utilities.capitalizeName(buyer?.lastName) }} |
                        {{ _utilities.maskNumber(buyer?.contactNumber) }} |
                        {{ buyer?.email }}
                      </div>
                      <!-- {{ numberType(buyer) }} -->
                    </div>
                  </ng-template>
                </p-autoComplete>

                <button
                  mat-flat-button
                  class="btn-custom mt-2"
                  color="primary"
                  type="button"
                  (click)="removeSelection()"
                  *ngIf="isEdit && !isContactUpdate"
                >
                  Clear Selection
                </button>

                <div class="text-danger">
                  <span *ngIf="isSubmitted && !buyerName">
                    {{ messageConstant.requiredField.replace("Field", "Name") }}
                  </span>
                </div>
              </div>
            </div>

            <!-- PHONE -->
            <div class="col-12 col-md-6 col-sm-6">
              <div class="form-group">
                <label class="re-label"> Phone </label>
                <p-autoComplete
                  class="re-add-buyer-name"
                  placeholder="Phone Number"
                  name="buyerNumber"
                  [(ngModel)]="buyerNumber"
                  [ngModelOptions]="{ standalone: true }"
                  [suggestions]="filteredBuyers"
                  (completeMethod)="checkBuyer($event, 'number')"
                  [minLength]="1"
                  (onSelect)="selectItem($event)"
                  (onBlur)="checkValidity('number')"
                  (keypress)="checkValidNumber($event)"
                >
                  <ng-template let-buyer pTemplate="item">
                    <div class="ui-helper-clearfix">
                      <div class="re-search-name">
                        {{ _utilities.maskNumber(buyer?.contactNumber) }}
                        {{ _utilities.capitalizeName(buyer?.firstName) }}
                        {{ _utilities.capitalizeName(buyer?.lastName) }}
                        {{ buyer?.email }}
                        {{ numberType(buyer) }}
                      </div>
                    </div>
                  </ng-template>
                </p-autoComplete>
                <div class="text-danger">
                  <span *ngIf="!buyerPhoneInvalid && phoneAlreadyInUse">
                    Phone Number already in use
                  </span>
                  <span *ngIf="!phoneAlreadyInUse && buyerPhoneInvalid">
                    {{ messageConstant.validFieldValue }} Phone Number
                  </span>
                </div>
              </div>
            </div>

            <!-- EMAIL -->
            <div class="col-12 col-md-6 col-sm-6">
              <div class="form-group">
                <label class="re-label"> Email </label>
                <p-autoComplete
                  class="re-add-buyer-name"
                  placeholder="Email"
                  name="buyerEmail"
                  [(ngModel)]="buyerEmail"
                  [ngModelOptions]="{ standalone: true }"
                  [suggestions]="filteredBuyers"
                  (completeMethod)="checkBuyer($event, 'email')"
                  [minLength]="1"
                  (onSelect)="selectItem($event)"
                  appendTo="body"
                  (onBlur)="checkValidity('email')"
                >
                  <ng-template let-buyer pTemplate="item">
                    <div class="ui-helper-clearfix">
                      <div class="re-search-name">
                        {{ buyer?.email }}
                        {{ _utilities.capitalizeName(buyer?.firstName) }}
                        {{ _utilities.capitalizeName(buyer?.lastName) }}
                        {{ _utilities.maskNumber(buyer?.contactNumber) }}
                        {{ numberType(buyer) }}
                      </div>
                    </div>
                  </ng-template>
                </p-autoComplete>
                <div class="text-danger">
                  <span *ngIf="!buyerMailInvalid && emailAlreadyInUse">
                    Email already in use
                  </span>
                  <span *ngIf="!emailAlreadyInUse && buyerMailInvalid">
                    {{ messageConstant.validFieldValue }} Email
                  </span>
                </div>
              </div>
            </div>

            <!-- PURCHASE PRICE -->
            <div class="col-12 col-md-6 col-sm-6">
              <div class="form-group">
                <label class="re-label">
                  Purchase Price
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="purchasePrice"
                  placeholder="$Price"
                  mask="separator.2"
                  [thousandSeparator]="','"
                  [decimalMarker]="'.'"
                  [dropSpecialCharacters]="true"
                />

                <div class="invalid-feedback">
                  <span
                    *ngIf="isSubmitted && hasError('purchasePrice', 'required')"
                  >
                    {{
                      messageConstant.requiredField.replace(
                        "Field",
                        "Purchase Price"
                      )
                    }}
                  </span>
                </div>
              </div>
            </div>

            <!-- PAYMENT METHOD -->
            <div class="col-12 col-md-6 col-sm-6">
              <div class="form-group">
                <label class="re-label">
                  Payment Method
                  <span class="text-danger">*</span>
                </label>

                <select
                  class="form-control"
                  formControlName="paymentMethod"
                  name="status"
                >
                  <option value="" selected="selected">
                    Select Payment Method
                  </option>
                  <option value="cash">Cash</option>
                  <option value="financing">Financing</option>
                </select>

                <div class="invalid-feedback">
                  <span
                    *ngIf="isSubmitted && hasError('purchasePrice', 'required')"
                  >
                    {{
                      messageConstant.requiredField.replace(
                        "Field",
                        "Purchase Price"
                      )
                    }}
                  </span>
                </div>
              </div>
            </div>

            <!-- EMD -->
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="re-label"> EMD (Earnest Money Deposit) </label>

                <input
                  type="text"
                  class="form-control"
                  formControlName="emd"
                  placeholder="$EMD"
                  mask="separator.2"
                  [thousandSeparator]="','"
                  [decimalMarker]="'.'"
                  [dropSpecialCharacters]="true"
                />
              </div>
            </div>

            <!-- CONTINGENCIES -->
            <div class="col-12 col-md-6 col-sm-6">
              <div class="form-group">
                <label class="re-label"> Contingencies </label>

                <p-multiSelect
                  #select
                  placeholder="Select Contingencies"
                  [options]="contingencies"
                  formControlName="contingencies"
                  appendTo="body"
                ></p-multiSelect>
              </div>
            </div>

            <!-- CLOSING DATE -->
            <div class="col-12 col-md-6 col-sm-6">
              <div class="form-group">
                <label class="re-label">
                  Closing Date
                  <span class="text-danger">*</span>
                </label>

                <p-calendar
                  appendTo="body"
                  [showIcon]="true"
                  [icon]="'fa fa-calendar'"
                  [showTime]="false"
                  formControlName="closingDate"
                  dateFormat="mm/dd/yy"
                  placeholder="Date when deal closed"
                  readonlyInput="true"
                ></p-calendar>

                <div class="invalid-feedback">
                  <span
                    *ngIf="isSubmitted && hasError('closingDate', 'required')"
                  >
                    {{
                      messageConstant.requiredField.replace(
                        "Field",
                        "Closing Date"
                      )
                    }}
                  </span>
                </div>
              </div>
            </div>

            <!-- POF (Proof of Fund) -->
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="re-label"> POF (Proof of Fund) </label>
                <div class="radiolist">
                  <label class="radiobtn form-check-inline">
                    <input
                      type="radio"
                      value="yes"
                      formControlName="pof"
                      name="pof"
                    />
                    <span class="checkmark"></span>Yes
                  </label>

                  <label class="radiobtn form-check-inline">
                    <input
                      type="radio"
                      value="no"
                      formControlName="pof"
                      name="pof"
                    />
                    <span class="checkmark"></span>No
                  </label>
                </div>
              </div>
            </div>

            <!--ATTACH DOCUMENT CONTAINER -->
            <div
              class="col-12 re-files-sec"
              *ngIf="addEditContactForm.value.pof === 'yes'"
              ngFileDrop
            >
              <div class="re-files-right-sec infinite-scroll">
                <ng-container *ngIf="mainFileArr || mainFileArr?.length > 0">
                  <table>
                    <colgroup>
                      <col width="5%" />
                      <col width="85%" />
                      <col width="5%" />
                    </colgroup>
                    <tr
                      class="re-table-tr"
                      *ngFor="let file of mainFileArr; let i = index"
                    >
                      <td>
                        <img
                          class="re-fileImg-logo"
                          src="../../../assets/images/{{
                            getIcon(file, 'FILE')
                          }}"
                        />
                      </td>
                      <td>{{ file.originalName }}</td>
                      <td>
                        <img
                          class="delete-img"
                          src="/assets/images/delete.svg"
                          (click)="deleteFile(file, i)"
                        />
                      </td>
                    </tr>
                  </table>
                </ng-container>
                <ng-container *ngIf="!mainFileArr || mainFileArr?.length == 0">
                  <div class="re-no-file">No files added</div>
                </ng-container>
              </div>
              <a href="javascript:void(0);" class="btn re-attachmnet-btn">
                <img src="assets/images/inventory-details/file-2.svg" />&nbsp;
                Attach Document
                <input
                  type="file"
                  #uploader
                  ngFileSelect
                  multiple
                  (uploadOutput)="onUploadLogo($event)"
                  [uploadInput]="uploadInput"
              /></a>
              <!-- (change)="onFileChange($event)" -->
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <hr style="margin: 0 0 8px 0 !important" />

    <mat-dialog-actions align="center">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        cdkFocusInitial
        [disabled]="addEditContactForm.invalid"
      >
        {{ type == "Add" ? "Add" : "Edit" }} Offer
      </button>
    </mat-dialog-actions>
  </form>
</div>
