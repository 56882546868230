// MODULES
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// COMPONENTS
import { RenameTagComponent } from 'src/app/shared/dialog/rename-tag/rename-tag.component';
import { RemoveTagsComponent } from 'src/app/shared/dialog/remove-tags/remove-tags.component';
import { AddTagsComponent } from 'src/app/shared/dialog/add-tags/add-tags.component';

// SERVICES
import { TaggingService } from 'src/app/providers/tagging/tagging.service';
import { SharedService } from 'src/app/shared/shared.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpCancelService } from 'src/app/providers/http-cancel/http-cancel.service';

// UTILS
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';
import { MessageConstant } from 'src/app/utils/message-constant';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
@Component({
  selector: 'app-global-tags',
  templateUrl: './global-tags.component.html',
  styleUrls: ['./global-tags.component.scss'],
})
export class GlobalTagsComponent implements OnInit {
  @Output() _refreshLeadsInfo = new EventEmitter<any>();
  @Output() _refreshActivity = new EventEmitter<any>();
  @Input() showTags: any[];
  @Input() splitTags: any[];
  @Input() moduleName: string;
  @Input() moduleId: string;
  @Input() leadId: string;
  @Input() tagType: string;
  @Input() allTags: any[];
  @Input() tagDialer: boolean = false;

  messageConstant = MessageConstant;

  dialogRef: any;
  public docClick;
  currentTag: string = '';
  dropdownTagId: string = '';
  selectedTagId: string = '';
  public colorCodes = MiscellaneousConstant.colorCodes;

  constructor(
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private _dialog: MatDialog,
    private _taggingService: TaggingService,
    private _sharedService: SharedService,
    private httpCancelService: HttpCancelService
  ) {}

  ngOnInit(): void {
    this.docClick = (event) => {
      var tagWithDropdown = document.getElementById(
        'dropDownId_' + this.currentTag
      );

      if (typeof tagWithDropdown !== 'undefined' && tagWithDropdown !== null) {
        var isTagClickInside = tagWithDropdown.contains(
          <HTMLInputElement>event.target
        );
        if (!isTagClickInside) {
          this.currentTag = '';
        }
      }
      var tagWithDropdown = document.getElementById(
        'dropDownId_' + this.dropdownTagId
      );

      if (typeof tagWithDropdown !== 'undefined' && tagWithDropdown !== null) {
        var isTagClickInside = tagWithDropdown.contains(
          <HTMLInputElement>event.target
        );
        if (!isTagClickInside) {
          this.selectedTagId = '';
          this.dropdownTagId = '';
        }
      }
    };
    document.addEventListener('click', this.docClick, false);
  }

  showColorDropdown(id) {
    if (id != this.currentTag) this.currentTag = id;
    else {
      this.currentTag = '';
    }
  }

  showTagDropDown(id) {
    if (this.dropdownTagId == id) {
      this.selectedTagId = '';
      this.dropdownTagId = '';
      return;
    }

    this.selectedTagId = id;
    this.dropdownTagId = id;
  }

  removeTag(tag) {
    this._loaderService.start();
    const obj = {
      leadId: this.leadId,
      moduleId: this.moduleId,
      tagIds: [tag],
      isTagAdd: false,
      for: this.moduleName,
    };

    this._sharedService.addTags(obj).subscribe(
      (response) => {
        this._loaderService.stop();
        if (response) {
          // this._toastrService.success(this.messageConstant.tagDeletedSuccess);
          this._refreshLeadsInfo.emit();
          this._refreshActivity.emit("");
          // this.getLeadInfo();
          // this.getActivityLog(true);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
      }
    );
  }

  renameTag(id?, label?) {
    this.dialogRef = this._dialog.open(RenameTagComponent, {
      width: '500px',
      data: {
        _id: id,
        label,
        tagType: this.tagType,
        items: this.allTags,
      },
    });

    this.dialogRef.afterClosed().subscribe(() => {
      this._refreshLeadsInfo.emit();
      if (this.moduleName == 'leads') this._refreshActivity.emit();
      // this.getLeadInfo();
      // this.getActivityLog();
    });
  }

  changeTagColor(colorObj, cIndex) {
    this._loaderService.start();
    let obj = {
      label: colorObj?.label,
      colorIndex: cIndex,
      tagType: this.tagType,
    };
    this._taggingService.renameTags(colorObj?._id, obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode && response.statusCode == 200) {
          this._loaderService.stop();
          this._refreshLeadsInfo.emit(true);
          // this._toastrService.success('Color Changed Successfully.');
          //this.getLeadInfo(true);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          // this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  onViewAllTags() {
    this.dialogRef = this._dialog.open(RemoveTagsComponent, {
      width: '400px',
      data: {
        moduleId: this.moduleId,
        subModuleId: this.leadId,
        items: this.allTags,
        for: this.moduleName,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._refreshLeadsInfo.emit();
        if (this.moduleName == 'leads') this._refreshActivity.emit();
        // this.getLeadInfo();
        // this.getActivityLog();
      }
    });
  }

  onAddTagOpen() {
    this.dialogRef = this._dialog.open(AddTagsComponent, {
      width: '500px',
      data: {
        subModule: 'Add Tags',
        moduleId: this.moduleId,
        leadId: this.leadId,
        prevTags: this.splitTags,
        for: this.moduleName,
        edit: 0,
        tagType: this.tagType,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._refreshLeadsInfo.emit();
        if (this.moduleName == 'leads') this._refreshActivity.emit(true);
        // this.getLeadInfo();
        // this.getActivityLog(true);
      }
    });
  }
}
