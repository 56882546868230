<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      style="display: block !important"
      class="w-100 d-flex justify-content-between"
    >
      {{ data?.type }}
    </h2>
    <span matDialogClose mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<form
  [formGroup]="questionForm"
  (ngSubmit)="onSubmit()"
  autocomplete="off"
  novalidate
>
  <mat-dialog-content class="re-typography">
    <div class="container-fluid p-0">
      <div class="re-box">
        <div class="row">
          <!-- QUESTION -->
          <div class="col-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label class="label required">Question</label>
              <input
                type="text"
                class="form-control"
                [placeholder]="'Enter question name'"
                formControlName="questionTitle"
                name="questionTitle"
              />

              <div
                class="text-danger"
                *ngIf="submitted && hasError('questionTitle', 'required')"
              >
                <span>{{
                  messageConstant?.requiredName.replace("Name", "Question")
                }}</span>
              </div>
            </div>
          </div>

          <!-- QUESTION TYPE -->
          <div class="col-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label class="label">Question Type</label>

              <ul class="nav" role="tablist">
                <li role="presentation" *ngFor="let type of questionTypes">
                  <button
                    class="btn re-chip"
                    type="button"
                    [ngClass]="{
                      active: type?.id === questionForm?.value?.questionType
                    }"
                    (click)="setValue('questionType', type?.id)"
                  >
                    {{ type?.name }}
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <!-- NUMBER FORMAT -->
          <div class="col-12 col-md-12 col-sm-12" *ngIf="isVisible('number')">
            <div class="form-group">
              <label class="label">Number Format</label>
              <a
                class="re-number-format"
                (click)="updateNumberFormat('en-GB')"
                [ngClass]="{
                  active: questionForm?.value?.numberFormat == 'en-GB'
                }"
              >
                000,000.00
              </a>

              <!-- <a
                class="re-number-format"
                (click)="updateNumberFormat('en-US')"
                [ngClass]="{
                  active: questionForm?.value?.numberFormat == 'en-US'
                }"
              >
                0,00,000.00
              </a> -->
            </div>
          </div>

          <!-- DATE FORMAT -->
          <div class="col-12 col-md-12 col-sm-12" *ngIf="isVisible('date')">
            <div class="form-group">
              <label class="label">Date Format</label>
              <a
                *ngFor="let format of dateFormats"
                class="re-date-format"
                [ngClass]="{
                  active: questionForm?.value?.dateFormat == format?.value
                }"
                (click)="setValue('dateFormat', format?.value)"
              >
                {{ format?.label }}
              </a>
            </div>
          </div>

          <!-- CURRENCY FORMAT -->
          <div class="col-12 col-md-12 col-sm-12" *ngIf="isVisible('currency')">
            <div class="form-group">
              <label class="label">Currency Format</label>
              <a
                class="re-currency-format"
                (click)="updateCurrencyFormat('en-GB')"
                [ngClass]="{
                  active: questionForm?.value?.currencyFormat == 'en-GB'
                }"
              >
                $000,000.00
              </a>
              <!-- <a
                class="re-number-format"
                (click)="updateCurrencyFormat('en-US')"
                [ngClass]="{
                  active: questionForm?.value?.currencyFormat == 'en-US'
                }"
              >
                $0,00,000.00
              </a> -->
            </div>
          </div>

          <!-- DATA SOURCE -->
          <div
            class="col-12 col-md-12 col-sm-12"
            *ngIf="isVisible('data-source')"
          >
            <div class="form-group">
              <label class="label">Data Source</label>

              <div
                class="row align-items-center mb-3"
                *ngFor="
                  let option of options.controls;
                  let i = index;
                  let first = first
                "
                formArrayName="options"
              >
                <div
                  class="col-12 col-md-5 col-sm-12"
                  [formGroup]="options.controls[i]"
                >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="key"
                    placeholder="Enter key"
                  />

                  <!-- REQUIRED KEY ERROR -->
                  <div
                    class="text-danger"
                    *ngIf="submitted && option.get('key').errors?.required"
                  >
                    <span>
                      {{
                        messageConstant.requiredField.replace("Field", "Key")
                      }}
                    </span>
                  </div>
                </div>
                <div
                  class="col-12 col-md-5 col-sm-12"
                  [formGroup]="options.controls[i]"
                >
                  <div class="radiolist">
                    <label class="radiobtn">
                      Default Selected
                      <input
                        type="radio"
                        value="true"
                        formControlName="defaultSelected"
                        (change)="selectDefaultOptions(i)"
                      />
                      <span class="checkmark"> </span>
                    </label>
                  </div>
                </div>
                <div class="col-12 col-md-2 col-sm-12">
                  <button
                    class="btn re-custom-btn"
                    (click)="deleteOption(i)"
                    *ngIf="!first"
                  >
                    <img src="assets/images/delete.svg" alt="delete" />
                  </button>
                </div>
              </div>

              <a class="re-add-option" (click)="addMoreOption()">
                Add Another Option
              </a>
            </div>
          </div>

          <!-- PLACEHOLDER -->
          <div
            class="col-12 col-md-12 col-sm-12"
            *ngIf="isVisible('placeholder')"
          >
            <div class="form-group">
              <label class="label required">Placeholder Text</label>
              <input
                type="text"
                class="form-control"
                [placeholder]="'Text appear as a placeholder'"
                formControlName="placeholder"
                name="placeholder"
              />

              <div
                class="text-danger"
                *ngIf="submitted && hasError('placeholder', 'required')"
              >
                <span>{{
                  messageConstant?.requiredName.replace("Name", "Placeholder")
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center" style="padding-top: 14px !important">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" cdkFocusInitial type="submit">
      {{ this.data.isAdd === "edit" ? "Update" : "Add" }} Question
    </button>
  </mat-dialog-actions>
</form>
