<div class="re-container" *ngIf="showPagination">
  <!-- CURRENT PAGE -->
  <div class="re-records">
    Showing
    <span *ngIf="count > 0">
      {{ getCount() }}
    </span>
    <span *ngIf="count == 0"> 0 </span>
    of <span>{{ count }}</span> records
  </div>

  <div
    class="re-pagination-container"
    *ngIf="pager.pages && pager.pages.length && count > limit"
  >
    <!-- PAGES -->
    <ul class="pagination">
      <li
        [ngClass]="{ disabled: pager.currentPage === 1 }"
        class="page-item first-item pointer"
      >
        <a (click)="setPage(1)" class="page-link pointer">
          <span class="fa fa-step-backward"></span>
        </a>
      </li>

      <li
        [ngClass]="{ disabled: pager.currentPage === 1 }"
        class="page-item previous-item pointer re-mr-right"
      >
        <a (click)="setPage(pager.currentPage - 1)" class="page-link">
          <span class="fa fa-chevron-left"></span>
        </a>
      </li>

      <li
        *ngFor="let page of pager.pages"
        [ngClass]="{ active: pager.currentPage === page }"
        class="page-item number-item pointer re-number"
      >
        <a (click)="setPage(page)" class="page-link pointer">{{ page }}</a>
      </li>

      <li
        [ngClass]="{ disabled: pager.currentPage === pager.totalPages }"
        class="page-item next-item pointer re-mr-left"
      >
        <a (click)="setPage(pager.currentPage + 1)" class="page-link">
          <span class="fa fa-chevron-right"></span>
        </a>
      </li>

      <li
        [ngClass]="{ disabled: pager.currentPage === pager.totalPages }"
        class="page-item last-item pointer"
      >
        <a (click)="setPage(pager.totalPages)" class="page-link pointer">
          <span class="fa fa-step-forward"></span>
        </a>
      </li>
    </ul>

    <!-- GO TO -->
    <div class="re-goto" *ngIf="count > limit">
      <button
        class="dropdown-toggle"
        (click)="getMenu()"
        [matMenuTriggerFor]="menu"
      >
        <img src="./assets/images/right-arrow.png" alt="right" />Go to
      </button>
      <mat-menu #menu="matMenu">
        <div class="re-menu">
          <p class="re-text">Go to page</p>
          <div class="re-form">
            <input
              type="number"
              class="form-control"
              name="goToPage"
              [(ngModel)]="page"
              onkeypress="return (event.charCode == 8) ? null : event.charCode >= 48 && event.charCode <= 57"
              placeholder="Page #"
              min="1"
            />
            <button
              class="btn"
              type="button"
              (click)="setPage(page, false, true)"
            >
              Go
            </button>
          </div>
        </div>
      </mat-menu>
    </div>
  </div>

  <!-- RECORDS LIMIT -->
  <div class="re-limit">
    <span class="re-text">Show </span>
    <select
      class="form-control"
      name="currentLimit"
      [(ngModel)]="limit"
      (change)="changeLimit($event.target.value)"
    >
      <ng-container *ngFor="let limit of recordsLimit">
        <option [value]="limit">{{ limit }}</option>
      </ng-container>
    </select>
    <span class="re-text">records </span>
  </div>
</div>
