<div class="sidebar-wrapper">
  <a
    class="navbar-brand1"
    href="javascript:;"
    routerLink="/dashboard"
    *ngIf="isDirectMailFreeUser == false"
  >
    <img src="assets/images/navbar/logo.svg" alt="logo" />
    <span class="re-count" *ngIf="_utilities.newCount">
      {{ _utilities.newCount > 9 ? "9+" : _utilities.newCount }}
    </span>
  </a>
  <a
    class="navbar-brand1"
    href="javascript:;"
    style="cursor: auto"
    *ngIf="isDirectMailFreeUser"
  >
    <img src="assets/images/navbar/logo.svg" alt="logo" />
  </a>
  <ul id="sidemenu" class="sidebar-nav">
    <li class="droplist" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }" *ngIf="isDirectMailFreeUser == false"
      (click)="$event.stopPropagation()">
      <a class="dropdown-toggle" [class.active]="checkSettingActive()" pTooltip="Inbox" tooltipPosition="right"
        routerLink="/web-inbox">
        <img src="/assets/images/navbar/inbox.svg" alt="menu" />
      </a>
    </li>
    <ng-container *ngFor="let menu of sideMenu; let i = index">
      <li
        class="droplist"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: false }"
        (click)="$event.stopPropagation()"
      >
        <a class="dropdown-toggle" data-toggle="dropdown" [attr.data_id]="i">
          <img [src]="menu?.icon" alt="menu" />
        </a>
        <div class="dropdown-menu dropdown_menu_{{ i }}">
          <div class="subdiv">
            <span class="title">{{ menu?.label }}</span>
            <div class="dropdown-divider"></div>
            <ng-container *ngFor="let subMenu of menu?.subMenu">
              <a
                [routerLink]="subMenu?.route"
                [target]="subMenu?.target"
                [queryParams]="subMenu.queryParams"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{
                  exact: true
                }"
                [class.active]="checkActive(subMenu)"
                class="dropdown-item"
                (click)="closePopOver()"
                *ngIf="
                  subMenu?.label != 'Dialer' &&
                  subMenu?.route != '/dialer' &&
                  subMenu?.route != '/link'
                "
              >
                <img [src]="subMenu?.icon" alt="submenu" />
                {{ subMenu?.label }}
              </a>
              <a
                [routerLink]="subMenu?.route"
                [target]="subMenu?.target"
                [queryParams]="subMenu.queryParams"
                [class.active23]="checkActive(subMenu)"
                class="dropdown-item re-dialer-link"
                (click)="closePopOver(); checkPermission()"
                *ngIf="
                  subMenu?.label == 'Dialer' && subMenu?.route == '/dialer'
                "
              >
                <img [src]="subMenu?.icon" alt="submenu" />
                {{ subMenu?.label }}
              </a>
              <a
                *ngIf="subMenu?.route == '/link'"
                [class.active]="checkActive(subMenu)"
                class="dropdown-item"
                (click)="onLinkClick(subMenu)"
                href="javascript:;"
              >
                <img [src]="subMenu?.icon" alt="submenu" />
                {{ subMenu?.label }}
              </a>
            </ng-container>
          </div>
        </div>
      </li>
    </ng-container>
  </ul>
  <!-- <a
    class="re-chat-link"
    id="custom_link_intercom"
    pTooltip="Live chat"
    tooltipPosition="right"
  >
    <img src="assets/images/chat-2.png" alt="chat" />
  </a>
  <a
    href="https://resimpli.com/tutorial/"
    target="_blank"
    pTooltip="Video tutorials"
    class="re-question-link"
    tooltipPosition="right"
    (click)="onChatClick()"
  >
    <img src="assets/images/Help-default.svg" alt="Help" />
  </a> -->
  <a
    class="re-setting-link"
    [class.active]="checkSettingActive()"
    pTooltip="Settings"
    tooltipPosition="right"
    routerLink="/settings"
  >
    <img src="assets/images/settings-icon.svg" alt="settings" />
  </a>
  <a
    class="re-question-link"
    pTooltip="Help Desk"
    tooltipPosition="right"
    (click)="onChatClick()"
  >
    <img src="assets/images/message-notif.png" alt="chat" />
  </a>
</div>

<div class="re-helpdesk-modal" [class.d-none]="!showHelpDesk">
  <div class="re-top-head">
    <div class="col-12 sub-head">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between d-none"
        *ngIf="!isCall && !isMail"
      >
        REsimpli Help Desk
      </h2>
      <div class="d-flex w-100 align-items-center" *ngIf="isCall || isMail">
        <a class="re-back" (click)="onAction('back')">
          <i aria-hidden="true" class="fa fa-arrow-left mr-2"></i> Back
        </a>
      </div>
      <span mat-dialog-close style="float: right" (click)="onChatClick()">
        <img
          src="assets/images/close-full-white.svg"
          alt="close"
          style="margin-top: 12px"
        />
      </span>
    </div>
  </div>
  <mat-dialog-content class="">
    <div class="container">
      <div class="boxes" *ngIf="!isCall && !isMail && !isAssistance">
        <!-- <div class="re-title-div">
          <h4>Hi {{ _utilities?.userData?.firstName }}!</h4>
          <h5>How may we help you today?</h5>
        </div>
        <h5 class="re-support-title">Support</h5> -->
        <ul class="re-support-ul">
          <li>
            <a
              class="re-img"
              (click)="onAction('chat')"
              id="custom_link_intercom"
              ><img src="assets/images/support_sms.svg" alt="support_sms"
            /></a>
            <span class="re-txt">Live Chat</span>
          </li>
          <li>
            <a class="re-img" (click)="onAction('mail')"
              ><img src="assets/images/support_mail.svg" alt="support_mail"
            /></a>
            <span class="re-txt">Email</span>
          </li>
          <li>
            <a class="re-img" (click)="onAction('call')"
              ><img src="assets/images/support_sms.svg" alt="support_call"
            /></a>
            <span class="re-txt">SMS</span>
          </li>
          <li>
            <a class="re-img" (click)="onAction('faq')"
              ><img src="assets/images/support_faq.svg" alt="support_faq"
            /></a>
            <span class="re-txt">Self Help</span>
          </li>
        </ul>
        <div class="re-zoom-div" *ngIf="isZoomEnable()">
          <h3>Get Live Help from Customer Support</h3>
          <p>Available Monday to Friday, from 9 AM to 5 PM (EST)</p>
          <a
            class="re-links"
            href="https://us06web.zoom.us/j/6891441267?pwd=NGplbk1aQUJTNHhyNHRTYVBycFRNZz09#success"
            target="_blank"
            >Join Zoom Call</a
          >
        </div>
        <div class="re-support-lists">
          <div class="re-lists">
            <img
              class="re-support-img"
              src="assets/images/phone-watch-symbol-teal.png"
              alt="scheduleCall"
            />
            <div>
              <h5 style="cursor: pointer" (click)="onAction('scheduleCall')">
                Schedule a Call
              </h5>
              <p>
                Schedule a call with our expert support team for any assistance.
              </p>
            </div>
            <a class="re_open_link" (click)="onAction('scheduleCall')"
              ><img src="assets/images/open_link_teal.svg" alt="open_link_teal"
            /></a>
          </div>
          <div class="re-lists">
            <img
              class="re-support-img"
              src="assets/images/training-figure.svg"
              alt="training figure"
            />
            <div>
              <h5
                style="cursor: pointer"
                (click)="onAction('videoTrainingLibrary')"
              >
                REsimpli Self Guided Onboarding
              </h5>
              <p>
                Get more value from your REsimpli account by checking out the
                video training library
              </p>
            </div>
            <a class="re_open_link" (click)="onAction('videoTrainingLibrary')"
              ><img src="assets/images/open_link_teal.svg" alt="open_link_teal"
            /></a>
          </div>
          <div class="re-lists">
            <img
              class="re-support-img"
              src="assets/images/support_pen.svg"
              alt="support_pen"
            />
            <div>
              <h5 style="cursor: pointer" (click)="onAction('feature')">
                Request a Feature
              </h5>
              <p>
                Help us improve REsimpli, suggest features that will add more
                value to your business
              </p>
            </div>
            <a class="re_open_link" (click)="onAction('feature')"
              ><img src="assets/images/open_link_teal.svg" alt="open_link_teal"
            /></a>
          </div>
        </div>
        <!-- <div class="re_need_more">
          Need more assistance?
          <a (click)="onAction('assistance')">Click Here</a>
          to book an Onboarding Call.
        </div> -->
      </div>
      <div class="boxes" *ngIf="isAssistance">
        <div class="re-title-div">
          <h4>Hi {{ _utilities?.userData?.firstName }} !</h4>
          <h5>How may we help you today?</h5>
        </div>
        <h5 class="re-support-title">
          Book an Onboarding call with our Representatives
        </h5>
        <div class="re-bookcall-div">
          <div class="re-bookcall">
            <img src="assets/images/anthony.png" alt="anthony" />
            <a (click)="onActionBook('anthony-resimpli')">Book with Anthony</a>
          </div>
          <div class="re-bookcall">
            <img src="assets/images/red-pigoloy.png" alt="red-pigoloy" />
            <a (click)="onActionBook('red-resimpli')">Book with Red</a>
          </div>
        </div>
        <a class="re_need_more_back" (click)="onAction('assistance')"
          ><img
            class="img"
            src="assets/images/left_angle_teal.svg"
            alt="left_angle_teal"
          />
          Go back to Help Desk</a
        >
      </div>
      <div class="boxes re-call-email" *ngIf="isMail">
        <h3>Contact us by email</h3>
        <p>
          <a
            href="mailto:support@resimpli.com"
            target="_blank"
            rel="noopener noreferrer"
            >support@resimpli.com</a
          >
        </p>
        <p>
          We usually reply to emails within 1-2 hours during business hours.
        </p>
      </div>
      <div class="boxes re-call-email" *ngIf="isCall">
        <h3>Contact us by phone</h3>
        <p *ngIf="isEnterpriseUser() == false">+1 (619) 786-3482</p>
        <p *ngIf="isEnterpriseUser() == true">+1 (855) 701-6682</p>
        <p>Our phones are open on weekdays between 10 am - 6 pm EST.</p>
      </div>
    </div>
  </mat-dialog-content>
</div>
