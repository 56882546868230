import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ThankYouForRegistrationComponent } from './auth/thank-you-for-registration/thank-you-for-registration.component';
import { ThankYouDirectMailComponent } from './modules/direct-mail/thank-you-direct-mail/thank-you-direct-mail.component';
import { TaskTemplateComponent } from './modules/tasks/task-template/task-template.component';
import { SettingsComponent } from './navigation/settings/settings.component';

const routes: Routes = [
  // AUTH
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    data: { title: 'Auth Module' },
  },
  {
    path: 'thank-you-for-registration',
    component: ThankYouForRegistrationComponent,
  },
  {
    path: 'thank-you-direct-mail',
    component: ThankYouDirectMailComponent,
  },
  {
    path: 'task-template',
    component: TaskTemplateComponent,
  },
  {
    path: 'settings',
    component: SettingsComponent,
  },
  // PUBLIC
  {
    path: 'public',
    loadChildren: () =>
      import('./public/public.module').then((m) => m.PublicModule),
    data: { title: 'Public Module' },
  },

  // MODULES
  {
    path: '',
    loadChildren: () =>
      import('./modules/modules.module').then((m) => m.ModulesModule),
    data: { title: 'Modules' },
  },
  // {
  //   path: '.well-known/apple-app-site-association',
  //   component: WellKnownComponent,
  //   data: { title: 'well-known' },
  // },

  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
