import {
  Component,
  Input,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageConstant } from '../../../../utils/message-constant';
import { ResponseModel } from '../../../../utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';

import { CallDetailDailogsComponent } from '../../../../shared/dialog/call-detail-dailogs/call-detail-dailogs.component';
import { LeadDialerComponent } from '../../../../shared/dialog/lead-dialer/lead-dialer.component';
import { SmsDialogComponent } from '../../../../shared/dialog/sms-dialog/sms-dialog.component';
import { ModalReplyCommentComponent } from 'src/app/modules/dashboard/modal-reply-comment/modal-reply-comment.component';
//services
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharedService } from '../../../../shared/shared.service';
import { CreditService } from 'src/app/providers/credit/credit.service';
@Component({
  selector: 'app-tab-content',
  templateUrl: './tab-content.component.html',
  styleUrls: ['./tab-content.component.scss'],
})
export class TabContentComponent {
  @Input() allNotifications: any = {};
  @Input() currentTab: any = '';

  @Output() _callbackFunction: EventEmitter<any> = new EventEmitter<any>();
  messageConstant = MessageConstant;
  callBalance: number = 0;
  SMSBalance: number = 0;
  leadModuleId: string = '5faa63fdfd20cd581703d255';
  buyerModuleId: string = '6007bd05b84a567a12c3bca9';
  vendorModuleId: string = '6374c9378bc3a0ee1f84bae3';
  dialogRef: any;

  constructor(
    private _dialog: MatDialog,
    public _commonFunctions: CommonFunctionsService,
    private _loaderService: NgxUiLoaderService,
    public _toastrService: ToastrService,
    public _sharedService: SharedService,
    private _creditService: CreditService,
    private _router: Router
  ) {}

  onScroll() {}

  getNotificationUrl(notification) {
    if (notification?.activityType == 1)
      return { image: true, url: './assets/images/main-notification-sms.png' };
    else if (notification?.activityType == 4)
      return {
        image: true,
        url: './assets/images/main-notification-incoming.png',
      };
    else if (notification?.activityType == 8) {
      if (notification?.createdByUser?.profileImage)
        return { image: true, url: notification?.createdByUser?.profileImage };
      else if (notification?.createdByUser?.name)
        return {
          image: false,
          url: this.getInitials(notification?.createdByUser?.name),
        };
      else
        return {
          image: true,
          url: './assets/images/main-notification-tagged.png',
        };
    } else if (notification?.activityType == 9) {
      if (notification?.createdByUser?.profileImage)
        return { image: true, url: notification?.createdByUser?.profileImage };
      else if (notification?.createdByUser?.name)
        return {
          image: false,
          url: this.getInitials(notification?.createdByUser?.name),
        };
      else
        return {
          image: true,
          url: './assets/images/main-notification-task.png',
        };
    } else if (notification?.activityType == 10)
      return {
        image: true,
        url: './assets/images/main-notification-status.png',
      };
    else if (notification?.activityType == 18)
      return {
        image: true,
        url: './assets/images/main-notification-drip-stop.png',
      };
    else if (notification?.activityType == 37)
      return {
        image: true,
        url: './assets/images/main-notification-status.png',
      };
    else if (notification?.activityType == 42)
      return {
        image: true,
        url: './assets/images/main-notification-lead-zapier.png',
      };
    else if (notification?.activityType == 43)
      return {
        image: true,
        url: './assets/images/main-notification-lead-web.png',
      };
    else if (notification?.activityType == 44)
      return {
        image: true,
        url: './assets/images/main-notification-lead-assign.png',
      };
    else if (notification?.activityType == 45)
      return {
        image: true,
        url: './assets/images/main-notification-missed-call.png',
      };
    else if (notification?.activityType == 46)
      return {
        image: true,
        url: './assets/images/main-notification-status.png',
      };
    else if (notification?.activityType == 47)
      return {
        image: true,
        url: './assets/images/main-notification-lead-website.png',
      };
    else if (notification?.activityType == 49)
      return {
        image: true,
        url: './assets/images/duplicateRejected.png',
      };
    else if (notification?.activityType == 50)
      return {
        image: true,
        url: './assets/images/ClaimLead.png',
      };
    else if (notification?.activityType == 51)
      return {
        image: true,
        url: './assets/images/eSigned-open.png',
      };
    else if (notification?.activityType == 52)
      return {
        image: true,
        url: './assets/images/eSigned-partial.png',
      };
    else if (notification?.activityType == 53)
      return {
        image: true,
        url: './assets/images/eSigned-completed.png',
      };
  }

  getInitials(user) {
    var initials = user.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  getHighlightedData(notification) {
    let content = '';
    if (notification?.activityType == 1) return '';
    else if (notification?.activityType == 4) return '';
    else if (notification?.activityType == 8 || notification?.activityType == 9)
      return `By ${notification?.createdByUser?.name || 'N/A'} •`;
    else if (
      notification?.activityType == 10 ||
      notification?.activityType == 18
    ) {
      content = `${notification?.createdByUser?.name || 'N/A'}`;
      if (!notification?.isFromDripCam) content += ` •`;
      return content;
    } else if (notification?.activityType == 37) return '';
    else if (notification?.activityType == 42)
      return `${notification?.title || ''} •`;
    else if (notification?.activityType == 43)
      return `${notification?.title || ''} •`;
    else if (notification?.activityType == 44) return '';
    else if (notification?.activityType == 45) return '';
    else if (notification?.activityType == 46) return '';
    else if (notification?.activityType == 47)
      return `${notification?.title || ''} •`;
    else if (notification?.activityType == 49)
      return `${notification?.title || ''} •`;
    else if (notification?.activityType == 50) return ``;
    else if (notification?.activityType == 51)
      return `${
        this._commonFunctions.titleCaseToWord(notification?.leadTitle) || 'N/A'
      } •`;
    else if (notification?.activityType == 52)
      return `${
        this._commonFunctions.titleCaseToWord(notification?.leadTitle) || 'N/A'
      } •`;
    else if (notification?.activityType == 53)
      return `${
        this._commonFunctions.titleCaseToWord(notification?.leadTitle) || 'N/A'
      } •`;
    return 'N/A •';
  }

  markRead(notification) {
    this._callbackFunction.emit({ from: 'mark', data: notification });
  }

  getBuyerNumbers() {
    const promise = new Promise((resolve, reject) => {
      const data = {
        page: 1,
        limit: 1000,
        numTypeId: 2,
      };
      this._loaderService.start();
      this._sharedService.getnumbers(data).subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          if (response.statusCode === 200) {
            return resolve(response.data?.items || []);
          }

          return resolve([]);
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          return reject([]);
        }
      );
    });
    return promise;
  }

  getVendorNumbers() {
    const promise = new Promise((resolve, reject) => {
      const data = {
        page: 1,
        limit: 1000,
        numTypeId: 4,
      };
      this._loaderService.start();
      this._sharedService.getManagenumbers(data).subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          if (response.statusCode === 200) {
            return resolve(response.data?.items || []);
          }
          return resolve([]);
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          return reject([]);
        }
      );
    });
    return promise;
  }

  makeCall(item) {
    this._loaderService.start();
    this._creditService.getCallCredit({}).subscribe(
      async (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.callBalance = response?.data;

          let obj = {
            callBalance: this.callBalance,
            name: item?.leadTitle || 'N/A',
            phoneNumber: item?.from,
            leadSource: item?.to,
            userId: item.createdBy || '',
            leadId: item?.subModuleId || '',
            moduleId: item?.moduleId || '',
          };
          let numbers: any = [];
          if (item?.numberType == 2) {
            numbers = await this.getBuyerNumbers();
            obj['moduleType'] = 'Buyer';
            obj['buyerId'] = item?.subModuleId || '';
            obj['leadSource'] = numbers || [];
            delete obj['leadId'];
          } else if (item?.numberType == 4) {
            numbers = await this.getVendorNumbers();
            obj['moduleType'] = 'Vendor';
            obj['vendorId'] = item?.subModuleId || '';
            obj['leadSource'] = numbers || [];
            delete obj['leadId'];
          }

          if (this._commonFunctions.userAccessData?.isDialerAccess) {
            if (this._commonFunctions.isDialogOpen) {
              if (
                this._commonFunctions.dialogcurrentLeadId == item?.subModuleId
              ) {
                this._sharedService.refreshCallerToggleDisplay.next(true);
                return false;
              } else {
                this._toastrService.warning(
                  this.messageConstant.callUnderProgress
                );
                this._loaderService.stop();
                return false;
              }
            }
            if (this.callBalance > 0 && item?.numberType == 1) {
              this._commonFunctions.isDialogOpen = true;
              this._commonFunctions.dialogcurrentLeadId = item?.subModuleId;
              this.dialogRef = this._dialog.open(LeadDialerComponent, {
                width: '1366px',
                id: item?.subModuleId,
                disableClose: true,
                closeOnNavigation: false,
                backdropClass: 'popup-Backdrop-Class-dialer',
                data: {
                  leadId: item?.subModuleId,
                  isHideNext: false,
                },
              });
              this.dialogRef.afterClosed().subscribe((result) => {
                this._commonFunctions.isDialogOpen = false;
              });
            } else {
              this.dialogRef = this._dialog.open(CallDetailDailogsComponent, {
                width: '400px',
                data: obj,
                disableClose: true,
                closeOnNavigation: false,
                backdropClass: 'popup-Backdrop-Class-dialer',
              });
              this.dialogRef.afterClosed().subscribe((result) => {
                this._commonFunctions.isDialogOpen = false;
              });
            }
          } else {
            if (this._commonFunctions.isDialogOpen) {
              if (
                this._commonFunctions.dialogcurrentLeadId == item?.subModuleId
              ) {
                this._sharedService.refreshCallerToggleDisplay.next(true);
                return false;
              } else {
                this._toastrService.warning(
                  this.messageConstant.callUnderProgress
                );
                this._loaderService.stop();
                return false;
              }
            }
            this._commonFunctions.isDialogOpen = true;
            this._commonFunctions.dialogcurrentLeadId = item?.subModuleId;
            this.dialogRef = this._dialog.open(CallDetailDailogsComponent, {
              width: '400px',
              data: obj,
              disableClose: true,
              closeOnNavigation: false,
              backdropClass: 'popup-Backdrop-Class-dialer',
            });
            this.dialogRef.afterClosed().subscribe((result) => {
              this._commonFunctions.isDialogOpen = false;
            });
          }
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
    if (!item?.isReadMessage) {
      this._callbackFunction.emit({
        from: 'mark',
        data: item,
      });
    }
  }

  replySms(item) {
    if (item?.activityType == 8) {
      let name = item?.createdByUser?.name?.split(' ');

      this.dialogRef = this._dialog.open(ModalReplyCommentComponent, {
        width: '600px',
        data: {
          item: {
            ...item,
            ...{
              firstName: name[0] || '',
              lastName: name[1] || '',
              email: item?.createdByUser?.email,
              direction: 'comment',
              leadData: {
                leadId: item?.subModuleId,
                mainStatusId: item?.mainStatusId,
              },
            },
          },
        },
      });

      this.dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
        }
      });
    } else {
      this._loaderService.start();
      this._creditService.getSMSCredit({}).subscribe(
        async (response: ResponseModel) => {
          this._loaderService.stop();
          if (response?.statusCode == 200) {
            this.SMSBalance = response?.data;

            let contact = {
              name: item?.leadTitle || '',
              contactType:
                item?.numberType == 4
                  ? 'vendor'
                  : item?.numberType == 2
                  ? 'buyer'
                  : 'seller',
              phoneNumber: item?.from,
            };

            let obj = {
              SMSBalance: this.SMSBalance,
              name: item?.leadTitle || '',
              phoneNumber: item?.from,
              leadSource: item?.to,
              userId: item?.createdBy,
              leadId: item?.subModuleId || '',
              contacts: [contact],
              moduleId: item?.moduleId || '',
              changeNumber: false,
              addressLead: item?.address
                ? this._commonFunctions.formatAddress(
                    item?.address,
                    item?.unitNumber
                  )
                : ' ',
              address: item?.address
                ? this._commonFunctions.formatAddress(
                    item?.address,
                    item?.unitNo
                  )
                : ' ',
            };

            let numbers: any = [];
            if (item?.numberType == 2) {
              numbers = await this.getBuyerNumbers();
              obj['moduleType'] = 'Buyer';
              obj['buyerId'] = item?.subModuleId || '';
              obj['fromBuyerModule'] = true;
              obj['leadSource'] = numbers || [];
              obj['defaultSmsNumber'] = item?.to || '';
              delete obj['leadId'];
              delete obj['addressLead'];
            } else if (item?.numberType == 4) {
              numbers = await this.getVendorNumbers();
              obj['moduleType'] = 'Vendor';
              obj['vendorId'] = item?.subModuleId || '';
              obj['leadSource'] = numbers || [];
              obj['defaultSmsNumber'] = item?.to || '';
              delete obj['leadId'];
              delete obj['addressLead'];
            }
            this.dialogRef = this._dialog.open(SmsDialogComponent, {
              width: '500px',
              data: obj,
            });
            this.dialogRef.afterClosed().subscribe((result) => {});
          } else {
            this._toastrService.error(response?.message, '');
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
    }
    if (!item?.isReadMessage) {
      this._callbackFunction.emit({
        from: 'mark',
        data: item,
      });
    }
  }

  replaceLineBreak(name: string) {
    return name; //?.replace(/\<br\>/g, '');
  }

  getRedirectUrlOnly(notification, isLead) {
    //this._callbackFunction.emit({ from: 'mark', data: notification });

    let redirecturl = '';
    if (notification?.numberType == 2) {
      if (isLead) {
        redirecturl = `/leads/details?leadsId=${notification?.subModuleId}`;
      } else {
        let id = notification?.buyerId
          ? notification?.buyerId
          : notification?.subModuleId;
        redirecturl = `/buyers/details?buyersId=${id}&contact=1`;
      }
    } else if (notification?.numberType == 4) {
      if (isLead) {
        redirecturl = `/leads/details?leadsId=${notification?.subModuleId}`;
      } else {
        let id = notification?.buyerId
          ? notification?.buyerId
          : notification?.subModuleId;
        redirecturl = `/vendors/details?vendorsId=${id}`;
      }
    } else if (notification?.numberType == 5) {
      let id = notification?.buyerId
        ? notification?.buyerId
        : notification?.subModuleId;
      redirecturl = `/inventory/details?inventoryId=${id}`;
    } else if (notification?.numberType == 6) {
      let id = notification?.buyerId
        ? notification?.buyerId
        : notification?.subModuleId;
      redirecturl = `/rental/details?rentalId=${id}`;
    } else if (notification?.numberType == 7) {
      let id = notification?.buyerId
        ? notification?.buyerId
        : notification?.subModuleId;
      redirecturl = `/sold/details?soldId=${id}`;
    } else if (notification?.numberType == 1) {
      const route = this.getType(notification.moduleId);
      redirecturl = `/${route?.type}/details?${route.subType}=${notification?.subModuleId}`;
    } else {
      if (
        notification?.activityType == 9 &&
        notification?.address == 'General Business'
      )
        redirecturl = `/my-tasks`;
      else redirecturl = `/leads/details?leadsId=${notification?.subModuleId}`;
    }
    return redirecturl;
  }

  getRedirectUrl(notification, isLead) {
    //this._callbackFunction.emit({ from: 'mark', data: notification });

    let redirecturl = '';
    if (notification?.numberType == 2) {
      if (isLead) {
        redirecturl = `/leads/details?leadsId=${notification?.subModuleId}`;
      } else {
        let id = notification?.buyerId
          ? notification?.buyerId
          : notification?.subModuleId;
        redirecturl = `/buyers/details?buyersId=${id}&contact=1`;
      }
    } else if (notification?.numberType == 4) {
      if (isLead) {
        redirecturl = `/leads/details?leadsId=${notification?.subModuleId}`;
      } else {
        let id = notification?.buyerId
          ? notification?.buyerId
          : notification?.subModuleId;
        redirecturl = `/vendors/details?vendorsId=${id}`;
      }
    } else if (notification?.numberType == 5) {
      let id = notification?.buyerId
        ? notification?.buyerId
        : notification?.subModuleId;
      redirecturl = `/inventory/details?inventoryId=${id}`;
    } else if (notification?.numberType == 6) {
      let id = notification?.buyerId
        ? notification?.buyerId
        : notification?.subModuleId;
      redirecturl = `/rental/details?rentalId=${id}`;
    } else if (notification?.numberType == 7) {
      let id = notification?.buyerId
        ? notification?.buyerId
        : notification?.subModuleId;
      redirecturl = `/sold/details?soldId=${id}`;
    } else if (notification?.numberType == 1) {
      const route = this.getType(notification.moduleId);
      redirecturl = `/${route?.type}/details?${route.subType}=${notification?.subModuleId}`;
    } else {
      if (
        notification?.activityType == 9 &&
        notification?.address == 'General Business'
      )
        redirecturl = `/my-tasks`;
      else redirecturl = `/leads/details?leadsId=${notification?.subModuleId}`;
    }

    this._callbackFunction.emit({
      from: 'close',
      redirectUrl: redirecturl,
      data: notification,
    });
  }

  getType(moduleId) {
    if (moduleId === '5faa63fdfd20cd581703d255') {
      return { type: 'leads', subType: 'leadsId' };
    } else if (moduleId === '5faa66379837ff5b02bfb365') {
      return { type: 'inventory', subType: 'inventoryId' };
    } else if (moduleId === '5fea1988e1cbc5258bd3d4b9') {
      return { type: 'rental', subType: 'rentalId' };
    } else if (moduleId === '5fea197ce1cbc5258bd3d4b7') {
      return { type: 'sold', subType: 'soldId' };
    } else {
      return { type: 'leads', subType: 'leadsId' };
    }
  }

  callsRedirectText(notification) {
    if (notification?.numberType == 2) return 'See Buyer Details';
    else if (notification?.numberType == 4) return 'See Vendor Details';
    else {
      if (notification?.address)
        return this._commonFunctions.formatAddress(notification?.address);
      else return 'See Lead Details';
    }
  }
}
