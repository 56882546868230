import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrl } from 'src/app/utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
@Injectable({
  providedIn: 'root',
})
export class VendorsService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem('token');

  constructor(private _commonFunction: CommonFunctionsService) {}

  checkVendor = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.vendorManagementV2Url + 'check';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  addVendor = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.vendorManagementV2Url + 'save';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getAnalytics = (): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.vendorManagementV2Url + 'analytics';
    return this._commonFunction.globalGetService(endpoint);
  };

  getVendorListing = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.vendorManagementV2Url + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  vendorDetails = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.vendorManagementV2Url + 'details';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  editVendor = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.vendorManagementV2Url + 'edit';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteVendor = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.vendorManagementV2Url + 'delete';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getAdjacentVendors = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.vendorManagementV2Url + 'prev-next';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getRelatedVendors = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.vendorManagementV2Url + 'related';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getEmailStats = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.sendGridV2Url + 'campaign-stats';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getEmailStatsById = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.sendGridV2Url + 'campaign-stats-by-id';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getSMSstats = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.smsUrl + 'campaignStats';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getSMSstatsById = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.smsUrl + 'campaign-stats-by-id';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  exportVendor = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.vendorManagementV2Url + 'export';
    return this._commonFunction.globalPostService(endpoint, data, {
      responseType: 'text',
    });
  };

  updateVendors = (obj): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.vendorManagementV2Url + 'update-multiple';
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  getTagList = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.vendorManagementV2Url + 'tag-list';
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  getCount = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.vendorManagementV2Url + 'getCount';
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  updateVendorLeadId = (data, val?): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.vendorManagementV2Url + 'update_lead_id';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getInboxList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.inboxList + 'inbox-list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  markAsReadMsg = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.inboxList + 'mark-as-read-message';
    return this._commonFunction.globalPostService(endpoint, data);
  };
}
