<div class="activitylogg_modal">
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between"
        style="display: block !important"
      >
        Filter Activity
      </h2>
      <span matDialogClose mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>
  <form
    autocomplete="off"
    novalidate
    [formGroup]="filterForm"
    (ngSubmit)="onSubmit()"
  >
    <mat-dialog-content class="re-typography">
      <div class="container">
        <div class="box">
          <div class="row">
            <div
              class="col-12 col-md-12 col-sm-12"
              *ngIf="data?.type != 'list-stacking'"
            >
              <div class="form-group">
                <label class="labels">Assignee</label>
                <p-multiSelect
                  [options]="user"
                  name="userName"
                  formControlName="userName"
                  appendTo="body"
                  [defaultLabel]="'Select Assignee'"
                ></p-multiSelect>
                <!-- <select
                  class="form-control"
                  
                  name="account"
                >
                  <option value="">Select assignee(s) 
                </option>
                </select> -->
              </div>
            </div>
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="labels">Activity Type</label>
                <p-multiSelect
                  [options]="activityType"
                  name="type"
                  formControlName="type"
                  appendTo="body"
                  [defaultLabel]="'Select Activity Type'"
                ></p-multiSelect>
                <!-- <select
                  class="form-control"
                  
                  name="vendor"
                >
                  <option value="">Select type(s) from call, sms, email, task etc </option>
                </select> -->
              </div>
            </div>
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="labels">From Date</label>
                <p-calendar
                  [showIcon]="true"
                  [icon]="'fa fa-calendar'"
                  appendTo="body"
                  styleClass="ngCalendarClass"
                  formControlName="startDate"
                  placeholder="Select From Date"
                  (onSelect)="onDate($event)"
                ></p-calendar>
              </div>
            </div>
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="labels">To Date</label>
                <p-calendar
                  [showIcon]="true"
                  [icon]="'fa fa-calendar'"
                  appendTo="body"
                  styleClass="ngCalendarClass"
                  formControlName="endDate"
                  placeholder="Select To Date"
                  [minDate]="startDate"
                ></p-calendar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <hr class="m-0" />
    <mat-dialog-actions align="end">
      <button
        type="button"
        *ngIf="filterFormReset"
        mat-stroked-button
        color="warn"
        (click)="reset()"
      >
        Reset
      </button>
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" cdkFocusInitial type="submit">
        Filter Activity
      </button>
    </mat-dialog-actions>
  </form>
</div>
