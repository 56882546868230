import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrl } from '../../utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem('token');

  constructor(private _commonFunction: CommonFunctionsService) {}

  addInventory = (fieldData): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.inventoryUrl + 'save';
    return this._commonFunction.globalPostService(endpoint, fieldData);
  };

  editInventory = (fieldData, id): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.inventoryUrl + 'edit';

    if (id) {
      endpoint += `/${id}`;
    }
    return this._commonFunction.globalPostService(endpoint, fieldData);
  };

  getInventories = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.inventoryUrl + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getInvestmentType = (fieldData): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.projectType + 'list';
    return this._commonFunction.globalPostService(endpoint, fieldData);
  };

  getLeadSource = (fieldData): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.crmUrl + 'leadSource';
    return this._commonFunction.globalPostService(endpoint, fieldData);
  };

  getMarketingCampaign = (fieldData): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.numberUrl + 'campaignList';
    return this._commonFunction.globalPostService(endpoint, fieldData);
  };

  getPropertyFromMelissa = (address): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.melissaUrl + 'property';

    if (address) {
      endpoint += `?address=${address}`;
    }
    return this._commonFunction.globalGetService(endpoint);
  };

  projectInfo = (inventory): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.inventoryUrl + 'detail';
    return this._commonFunction.globalPostService(endpoint, inventory);
  };
  getQuestions = (inventory): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.inventoryUrl + 'getQuestions';

    return this._commonFunction.globalPostService(endpoint, inventory);
  };

  updateBuyerToInventory = (inventory): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.inventoryUrl + 'updateBuyerToInventory';
    return this._commonFunction.globalPostService(endpoint, inventory);
  };

  getInventoriesIds = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.inventoryUrl + 'getInventoriesIds';

    return this._commonFunction.globalPostService(endpoint, data);
  };

  exportInventories = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.inventoryUrl + 'export';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  backToLeads = (id): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.inventoryUrl + `back-to-lead/${id}`;

    return this._commonFunction.globalPostService(endpoint, {});
  };

  getInventoryByLeadId = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.inventoryUrl + `getInventoryByLeadId`;

    return this._commonFunction.globalPostService(endpoint, data);
  };
} //Main class ends here
