import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';

// UTILITIES
import { MessageConstant } from 'src/app/utils/message-constant';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared/shared.service';
import { DialerService } from 'src/app/providers/dialer/dialer.service';

// UTILS
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';

@Component({
  selector: 'app-change-number-dialog',
  templateUrl: './change-number-dialog.component.html',
  styleUrls: ['./change-number-dialog.component.scss'],
})
export class ChangeNumberDialogComponent implements OnInit {
  changeNumberForm: FormGroup;
  submitted: boolean = false;
  messageConstant = MessageConstant;
  checkError: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ChangeNumberDialogComponent>,
    public _utilities: CommonFunctionsService,
    private formBuilder: FormBuilder,
    private _loaderService: NgxUiLoaderService,
    private _dialerService: DialerService,
    private _toastrService: ToastrService,
    private _sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.changeNumberForm = this.formBuilder.group({
      numberId: ['', Validators.required],
    });
    this.changeNumberForm.patchValue({ numberId: this.data?.numberId });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.changeNumberForm.controls[controlName].hasError(errorName);
  };

  onChangeNumber() {
    this.submitted = true;
    this.checkError = false;
    if (this.changeNumberForm.invalid) {
      return;
    }
    const { numberId } = this.changeNumberForm.value;
    this._loaderService.start();
    let myObj = {
      number: numberId,
      campaignId: this.data.campaignId,
      propertyId: this.data.details._id,
    };
    this._dialerService.changeNumber(myObj).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode == 200) {
          this._toastrService.success(MessageConstant.changeNumberSuccess, '');
          this.dialogRef.close({ defaultNumber: numberId });
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }
}
