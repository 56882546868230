import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';

// SERVICE
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/providers/user/user.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

// UTILS
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';

@Component({
  selector: 'app-view-members-list-dialog',
  templateUrl: './view-members-list-dialog.component.html',
  styleUrls: ['./view-members-list-dialog.component.scss'],
})
export class ViewMembersListDialogComponent implements OnInit {
  currentPage: number = 1;
  currentLimit: number = MiscellaneousConstant.paginationLimit.STANDARD;

  roles: any[] = [];
  users: any[] = [];
  messageConstant = MessageConstant;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _utilities: CommonFunctionsService,
    private _userService: UserService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    if (this.data.number_list === 'Number') {
      this.getNumber();
    } else {
      this.getRoles();
    }
  }

  getNumber() {
    this._loaderService.start();
    if (this.data.item.numberData) {
      this._loaderService.stop();
      this.roles = this.data.item.numberData.map((x, index) => {
        let obj = {
          label: x.marketingTitle,
          value: x?.number,
        };
        return obj;
      });
    } else {
      this._loaderService.stop();
    }
  }

  getRoles() {
    this._loaderService.start();
    this._userService.getRoles({}).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          this.roles = response?.data.map((x) => {
            let obj = {
              label: x.name,
              value: x.key,
            };

            return obj;
          });

          this.roles = _.sortBy(this.roles, ['label']);

          this.getUserList();
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getUserList() {
    const obj = {
      page: this.currentPage,
      limit: 100,
    };

    this._userService.getSubUserList(obj).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          this.users = response.data.items.map((x) => {
            let obj = {
              label: `${x?.name}`,
              value: x?._id,
              roleData: x?.roleData,
            };

            return obj;
          });
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getUserByRole(currentRole) {
    return this.users
      .filter((x) => {
        const roleData = x.roleData
          .map((role) => (role.name === currentRole.label ? role : ''))
          .filter((x) => x);
        return roleData.length ? x : '';
      })
      .filter((x) => x);
  }

  getLabel(user) {
    const index = this.users.findIndex((x) => x.value === user);

    if (index > -1) {
      return this.users[index]?.label;
    }

    return '';
  }
}
