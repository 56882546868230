import { Component, Inject, OnInit, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UploadOutput, UploadInput } from 'ngx-uploader';
import { MatDialog } from '@angular/material/dialog';
//service
import { SharedService } from '../../shared.service';
import { RvmService } from './../../../providers/rvm/rvm.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AddRecordingComponent } from '../../../shared/dialog/add-recording/add-recording.component';

//utils
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';

import { environment } from 'src/environments/environment';
import { BaseUrl } from 'src/app/utils/base-url-constants';
import { IfStmt } from '@angular/compiler';
import { PlayingAudio } from '../../../pubsub/playing-audio';
// import { PubsubService } from '@fsms/angular-pubsub';

@Component({
  selector: 'app-add-edit-rvm',
  templateUrl: './add-edit-rvm.component.html',
  styleUrls: ['./add-edit-rvm.component.scss'],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class AddEditRvmComponent implements OnInit {
  messageConstant = MessageConstant;

  uploadInput: EventEmitter<UploadInput>;
  public moduleData: any;
  uploadFiles: any[] = [];
  recordFile: any[] = [];
  selectedModuleId: any[] = [];
  rvmTitle: string = '';
  rvmTitleRequied: boolean = false;
  audioRequied: boolean = false;
  rvmTitleLenght: boolean = false;
  moduleId: string;
  imageArr: any[] = [];
  originName: string = '';
  fileText: string =
    'Select an audio file (mp3, mp4, wav, aac audio files supported)';
  audioStart = false;
  audio: any;
  rvmFile: any = '';
  urlData: any = [];
  newOrOld = 'select-existing';
  audioList: any;
  dialogRef: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<AddEditRvmComponent>,
    private router: Router,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private _sharedService: SharedService,
    private _rvmService: RvmService,
    private fb: FormBuilder,
    private _dialog: MatDialog,
    // private pubsubService: PubsubService
  ) {
    this.getGreetingList();
    this.audio = new Audio();
    this.audio.addEventListener('ended', () => {
      this.audioStart = false;
    });

    // this.pubsubService.subscribe({
    //   messageType: PlayingAudio.messageType,
    //   callback: (type) => {
    //     if (type !== 'voice-mail' && this.audioStart) {
    //       this.stopAudio();
    //     }
    //   },
    // });
  }

  ngOnInit(): void {
    this.moduleId = this.data.moduleId;
    this.uploadInput = new EventEmitter<UploadInput>();
    if (typeof this.data.dataVal !== 'undefined') {
      console.log('this.data.dataVal', this.data.dataVal);
      this.rvmTitle = this.data.dataVal.name;
      this.fileText = this.data.dataVal.originName;
      this.originName = this.data.dataVal.originName;
      for (let i = 0; i < this.data.dataVal.moduleId.length; i++) {
        for (let j = 0; j < this.data.items.length; j++) {
          if (this.data.items[j]._id == this.data.dataVal.moduleId[i]) {
            this.selectedModuleId.push(this.data.items[j]);
            break;
          }
        }
      }
    }
  }
  ngOnDestroy(): void {
    this.stopAudio();
  }
  onUploadLogo(output: UploadOutput): void {
    this._loaderService.start();
    this.audioRequied = false;
    this.rvmFile = '';
    if (output.type === 'allAddedToQueue') {
      // when all files added in queue
      const token = localStorage.getItem('token');
      //for rvm type should be 1, 2 for mms and 0 for rest
      let endpoint = environment.baseUrl + BaseUrl.sharedUrl + 'file-upload';
      if (this.moduleId)
        endpoint += `?moduleId=${this.moduleId}&isPublic=1&type=1`;

      const event: UploadInput = {
        type: 'uploadAll',
        url: endpoint,
        method: 'POST',
        headers: { Authorization: token },
      };
      this.uploadInput.emit(event);
    } else if (
      output.type === 'addedToQueue' &&
      typeof output.file !== 'undefined'
    ) {
      this.uploadFiles.push(output);
    } else if (output.type === 'done' && typeof output.file !== 'undefined') {
      this._loaderService.stop();
      if (output.file.response.statusCode == 200) {
        this.originName = output.file.name;
        this.fileText = this.originName;
        if (typeof output.file.response.data == 'object') {
          output.file.response.data?.filter((x) => {
            this.imageArr.push(x);
            this.urlData['url'] = this.imageArr[0].url;
            console.log('this.urlData =>>>>>>>>>', this.urlData);
          });
        } else {
          this.imageArr = output.file.response.data;
          this.urlData['url'] = this.imageArr[0].url;
          console.log('this.urlData =>>>>>>>>>', this.urlData);
        }
      }
      // this.files = output.file;
    } else if (output.type === 'dragOut') {
      // drag out event
    } else if (output.type === 'drop') {
      // on drop event
    }
  }
  search(moduleName) {
    let tempArr = [];
    for (let i = 0; i < this.data.items.length; i++) {
      if (this.data.items[i].title.indexOf(moduleName) >= 0) {
        tempArr.push(this.data.items[i]);
      }
    }
    let finalArr = [],
      flag;
    for (let i = 0; i < tempArr.length; i++) {
      flag = 1;
      for (let j = 0; j < this.selectedModuleId.length; j++) {
        if (tempArr[i]._id == this.selectedModuleId[j]._id) {
          flag = 0;
          break;
        }
      }
      if (flag == 1) finalArr.push(tempArr[i]);
    }

    this.moduleData = finalArr;
    console.log(this.moduleData);
  }
  submitForm() {
    let moduleIds = [];
    for (let i = 0; i < this.selectedModuleId.length; i++) {
      moduleIds.push(this.selectedModuleId[i]._id);
    }
    if (this.rvmTitle.length == 0) {
      this.rvmTitleRequied = true;
      this.audioRequied = false;
      return false;
    } else if (this.rvmTitle.length == 0) {
      this.rvmTitleRequied = true;
      return false;
    } else if (this.rvmTitle.length > 50) {
      this.rvmTitleLenght = true;
      return false;
    }

    this._loaderService.start();
    if (typeof this.data.dataVal === 'undefined') {
      let rvm = this.audioList.filter((d) => d._id === this.rvmFile)[0];
      let fileUrl;
      if (rvm && this.newOrOld == 'select-existing') {
        this.originName = rvm.originName;
        fileUrl = rvm.url;
      } else if (this.recordFile && this.newOrOld == 'new-record') {
        this.originName = this.recordFile['originName'];
        fileUrl = this.recordFile['url'];
      } else if (this.imageArr.length != 0) {
        fileUrl = this.imageArr[0].url;
      }
      if (!fileUrl) {
        this._loaderService.stop();
        this.audioRequied = true;
        return false;
      }
      let obj = {
        name: this.rvmTitle,
        originName: this.originName,
        url: fileUrl,
        urlType:
          this.data?.urlType == 'dialerVoiceMail'
            ? this.data?.urlType
            : 'ringlessvoicemail',
      };
      this._rvmService.saveRVM(obj).subscribe(
        (response) => {
          //console.log('res', response);
          this._loaderService.stop();
          if (response && response.statusCode == 200) {
            this.dialog.close();
            if (this.data?.urlType == 'dialerVoiceMail')
              this._toastrService.success(this.messageConstant.dvmAddedSuccess);
            else
              this._toastrService.success(this.messageConstant.rvmAddedSuccess);
          } else {
            this._toastrService.error(response.message);
          }
        },
        (err: ErrorModel) => {
          //console.log(err);
          this._toastrService.error(err.error.message);
          this._loaderService.stop();
          // this._toastrService.error(error.message ? error.message : this.messageConstant.unknownError,);
        }
      );
    } else {
      let fileUrl;
      let rvm = this.audioList.filter((d) => d._id === this.rvmFile)[0];
      if (rvm && this.newOrOld == 'select-existing') {
        this.originName = rvm.originName;
        fileUrl = rvm.url;
      } else if (this.recordFile && this.newOrOld == 'new-record') {
        this.originName = this.recordFile['originName'];
        fileUrl = this.recordFile['url'];
      } else {
        fileUrl =
          this.imageArr.length > 0
            ? this.imageArr[0].url
            : this.data.dataVal.url;
      }
      let obj = {
        audioTrackId: this.data.dataVal._id,
        moduleId: moduleIds,
        name: this.rvmTitle,
        originName: this.originName,
        url: fileUrl,
      };
      this._rvmService.editRVM(obj).subscribe(
        (response) => {
          // console.log('res', response);
          this._loaderService.stop();
          if (response && response.statusCode == 200) {
            let result = this.data.dataVal;
            result.name = this.rvmTitle;
            result.moduleId = moduleIds;
            result.originName = this.originName;
            result.url =
              this.imageArr.length > 0
                ? this.imageArr[0].url
                : this.data.dataVal.url;
            this.dialog.close(result);
            if (this.data?.urlType == 'dialerVoiceMail')
              this._toastrService.success(
                this.messageConstant.dvmUpdatedSuccess
              );
            else
              this._toastrService.success(
                this.messageConstant.rvmUpdatedSuccess
              );
          } else {
            this._toastrService.error(response.message);
          }
        },
        (err: ErrorModel) => {
          // console.log(err);
          this._toastrService.error(err.error.message);
          this._loaderService.stop();
          // this._toastrService.error(error.message ? error.message : this.messageConstant.unknownError,);
        }
      );
    }
  }
  checkValue(event) {
    this.rvmTitleRequied = event?.target?.value ? false : true;
    this.rvmTitleLenght = event?.target?.value?.length > 50 ? true : false;
  }
  changeVoice(id = null) {
    this.stopAudio();
    if (this.rvmFile) {
      this.audioRequied = false;
    }
    if (id === null) {
      this.urlData = this.audioList.filter((d) => d._id === this.rvmFile)[0];
    }
    if (id === null && this.newOrOld === 'new-upload') {
      this.urlData = [];
    }
    // this.onVoiceChange.next(data);
    if (this.newOrOld === 'new-record') {
      this.onAddRecordingDialog();
    }
  }
  onAddRecordingDialog() {
    this.dialogRef = this._dialog.open(AddRecordingComponent, {
      width: '500px',
      panelClass: 'editRvm',
      data: {
        type: 'RVM',
        title: 'Record RVM',
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      this.newOrOld = 'select-existing';
      if (result) {
        // this.rvmFile = '';
        // this.audioRequied = false;
        // this.recordFile = result;
        result.urlType = 'ringlessvoicemail';
        this._loaderService.start();
        this.uploadFile(result);
      }
    });
  }
  getGreetingList() {
    const data = { page: 1, limit: 100, urlType: this.data?.urlType };
    this._rvmService.getRVM(data).subscribe(
      (response) => {
        if (response && response?.statusCode === 200) {
          this.audioList = response?.data?.items;
          this.audioList.sort((a, b) => a.originName.localeCompare(b.originName));
          //this.data.dataVal._id
          if (this.data?.dataVal?._id) {
            this.urlData = response?.data?.items.filter(
              (d) => d._id === this.data.dataVal._id
            )[0];
            this.rvmFile = this.urlData?._id;
          }
        }
      },
      (err: ErrorModel) => {}
    );
  }
  startAudio() {
    // this.pubsubService.publish(new PlayingAudio('voice-mail'));
    this.audio.src = '';
    this.audioStart = true;
    this.audio.src = this.urlData.url ? this.urlData.url : '';
    if (this.audio.src !== '' && this.urlData?.url !== undefined) {
      this.audio.load();
      this.audio.play();
    } else {
      this.audioStart = false;
      this._toastrService.error('Audio file is empty');
    }
  }
  stopAudio() {
    this.audioStart = false;
    this.audio.pause();
    this.audio.currentTime = 0;
    //this.audio = new Audio();
  }
  uploadFile(e) {
    let moduleIds = [];
    for (let i = 0; i < this.selectedModuleId.length; i++) {
      moduleIds.push(this.selectedModuleId[i]._id);
    }
    if (typeof this.data.dataVal === 'undefined') {
      const obj = {
        name: this.rvmTitle ? this.rvmTitle : e.name,
        originName: e.originName,
        url: e.url,
        urlType:
          this.data?.urlType == 'dialerVoiceMail'
            ? this.data?.urlType
            : e.urlType,
      };

      this._rvmService.saveRVM(obj).subscribe(
        (response) => {
          this._loaderService.stop();
          if (response && response?.statusCode == 200) {
            this.urlData = [];
            if (this.data?.urlType == 'dialerVoiceMail')
              this._toastrService.success(this.messageConstant.dvmAddedSuccess);
            else
              this._toastrService.success(this.messageConstant.rvmAddedSuccess);
            this.dialog.close();
          } else {
            this._toastrService.error(response?.message);
          }
        },
        (err: ErrorModel) => {
          this._toastrService.error(err.error.message);
          this._loaderService.stop();
        }
      );
    } else {
      let obj = {
        audioTrackId: this.data.dataVal._id,
        moduleId: moduleIds,
        name: this.rvmTitle ? this.rvmTitle : e.name,
        originName: e.originName,
        url: e.url,
      };
      this._rvmService.editRVM(obj).subscribe(
        (response) => {
          // console.log('res', response);
          this._loaderService.stop();
          if (response && response.statusCode == 200) {
            let result = this.data.dataVal;
            result.name = this.rvmTitle;
            result.moduleId = moduleIds;
            result.originName = this.originName;
            result.url =
              this.imageArr.length > 0
                ? this.imageArr[0].url
                : this.data.dataVal.url;
            this.dialog.close(result);
            if (this.data?.urlType == 'dialerVoiceMail')
              this._toastrService.success(
                this.messageConstant.dvmUpdatedSuccess
              );
            else
              this._toastrService.success(
                this.messageConstant.rvmUpdatedSuccess
              );
          } else {
            this._toastrService.error(response.message);
          }
        },
        (err: ErrorModel) => {
          // console.log(err);
          this._toastrService.error(err.error.message);
          this._loaderService.stop();
          // this._toastrService.error(error.message ? error.message : this.messageConstant.unknownError,);
        }
      );
    }
  }
}
