<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important;"
    >
      Save Template
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<form
  [formGroup]="templateForm"
  (ngSubmit)="onSubmit()"
  autocomplete="off"
  novalidate
>
  <mat-dialog-content class="re-typography">
    <div class="container-fluid p-0">
      <div class="re-box">
        <div class="row mt-3">
          <div class="col-12 col-md-12 col-sm-12">
            <!-- TEMPLATE NAME -->
            <div class="form-group">
              <label class="label required">Template name</label>
              <input
                formControlName="label"
                type="text"
                class="form-control"
                placeholder="Template Name"
              />

              <div class="text-danger">
                <span *ngIf="submitted && hasError('label', 'required')">
                  <span>
                    {{
                      messageConstant.requiredField.replace(
                        "Field",
                        "Template Name"
                      )
                    }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end" style="padding-top: 14px !important">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="primary" cdkFocusInitial type="submit">
      Next
    </button>
  </mat-dialog-actions>
</form>
