import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'myTimezone',
})
export class DateByTimeZonePipe implements PipeTransform {
  public constructor() {}

  transform(value, format = 'lll'): any {
    if (!value) return value;
    const timezoneOffset = localStorage.timezoneOffset || 'America/Chicago';
    const utcOffset = moment(new Date()).tz(timezoneOffset).format('Z');
    return moment.utc(value).utcOffset(utcOffset).format(format);
  }
}
