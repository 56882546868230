import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// UTILS
import { MessageConstant } from 'src/app/utils/message-constant';
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';

@Component({
  selector: 'app-test-email-dialog',
  templateUrl: './test-email-dialog.component.html',
  styleUrls: ['./test-email-dialog.component.scss'],
})
export class TestEmailDialogComponent implements OnInit {
  userForm: FormGroup;
  submitted: boolean = false;
  messageConstant = MessageConstant;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<TestEmailDialogComponent>,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.userForm = this._formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      contactNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(MiscellaneousConstant.phonePattern),
        ],
      ],
      company: ['', [Validators.required]],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(MiscellaneousConstant.emailPatternWithCaps),
        ],
      ],
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.userForm.controls[controlName].hasError(errorName);
  };

  onSubmit() {
    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    }

    setTimeout(() => {
      if (document.querySelector('.re-sendbulk-dialog'))
        document.querySelector('.re-sendbulk-dialog')['click']();
    }, 500);
    this._dialogRef.close([this.userForm.value]);
  }
}
