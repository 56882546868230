<div class="re-pinned-main">
  <div class="re-pinned-head">
    <h5>Pinned Notes ({{ totalPinCommentCount }})</h5>
    <img
      class="re-close"
      matDialogClose
      src="assets/images/close-black-1.svg"
      alt="close"
      width="16"
    />
  </div>
  <div class="re-pinned-body">
    <ng-container *ngIf="pinCommentData.length == 0">
      <div class="re-no-record">No Record Found</div>
    </ng-container>
    <ng-container *ngIf="pinCommentData.length > 0">
      <div class="re-pinned" *ngFor="let pinData of pinCommentData">
        <div class="re-media-left">
          <img class="re-img" [src]="pinData?.profileImage" alt="profile" />
        </div>
        <div class="re-media-body">
          <!-- (click)="onDelete('comment', 'leads', log, logIndex, log._id)" -->
          <div class="re-action-div">
            <a class="re-edits" href="javascript:void(0);"
            (click)="onEdit(pinData)"
              ><img
                height="20px"
                width="20px"
                src="assets/images/editActive.svg"
                alt="edit"
            /></a>
            <a class="re-edits" (click)="onDelete(pinData)" href="javascript:void(0);">
              <img
                height="20px"
                width="20px"
                src="assets/images/deleteActive.svg"
                alt="delete"
              />
            </a>
            <div class="re-unpin" (click)="setPinnedComment(pinData)">
              <img src="/assets/images/pin-white.svg" alt="profile" />
              Unpin
            </div>
          </div>
          <div class="re-names">
            {{ getTitle(pinData?.firstName, pinData?.lastName) }}
          </div>
          <div class="re-date">
            {{ pinData?.createdAt | date : "MMM dd hh:mm a" }}
          </div>
          <div class="re-desq">
            <div
              class="re-text"
              [innerHTML]="
                replaceLineBreak(
                  pinData?.comment | mentionHighlight | textToLink
                ) | safe
              "
            ></div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
