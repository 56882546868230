import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// UTILS
import { environment } from 'src/environments/environment';
import { BaseUrl } from 'src/app/utils/base-url-constants';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

@Injectable({
  providedIn: 'root',
})
export class ContingenciesService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem('token');

  constructor(private _commonFunction: CommonFunctionsService) {}

  getContingencyType = (fieldData): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.contingencyV2Url + 'contingencyTypeList';

    return this._commonFunction.globalPostService(endpoint, fieldData);
  };

  getContingencies = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contingencyV2Url + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  addContingency = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contingencyV2Url + 'save';

    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateContingency = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contingencyV2Url + 'update';

    return this._commonFunction.globalPostService(endpoint, data);
  };

  completeContingency = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.contingencyV2Url + 'compContingency';

    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteContingency = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.contingencyV2Url + 'delete';

    return this._commonFunction.globalPostService(endpoint, data);
  };
}
