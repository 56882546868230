<div class="re-top-head">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      Rename {{ displayName }}
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<mat-dialog-content class="mt-3">
  <div class="container-fluid">
    <div class="box re-addrvm-modal">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <label class="re-labels">Old {{ displayName }} Name </label>
            <input
              type="text"
              class="form-control re-input-height-dis"
              [(ngModel)]="oldTagName"
              (keypress)="pressRenameEnter($event.which)"
              readonly
            />
          </div>
        </div>
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <label class="re-labels"
              >New {{ displayName }} Name
              <span class="re-req-icon">*</span></label
            >
            <input
              type="text"
              class="form-control re-input-height"
              placeholder="Enter New {{ displayName }} Name"
              [(ngModel)]="newTagName"
              (keypress)="pressRenameEnter($event.which)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="padding-top: 12px !important">
  <button mat-raised-button [mat-dialog-close]="false" class="re-cancel-btn">
    Cancel
  </button>
  <button
    (click)="submitForm()"
    mat-raised-button
    cdkFocusInitial
    class="re-submit-btn"
  >
    Rename {{ displayName }}
  </button>
</mat-dialog-actions>
