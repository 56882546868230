<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      Edit number
    </h2>
    <span matDialogClose mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<mat-dialog-content class="re-typography vh-100">
  <div class="container">
    <form [formGroup]="manageNumberForm" autocomplete="off">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12">
          <ul role="tablist" class="nav nav-tabs">
            <li role="presentation">
              <a
                href="#tabs1"
                aria-controls="tabs1"
                role="tab"
                data-toggle="tab"
                aria-expanded="false"
                class="active"
                >General</a
              >
            </li>
            <li role="presentation" *ngIf="data.details?.numTypeId == 1">
              <a
                href="#tabs2"
                aria-controls="tabs2"
                role="tab"
                data-toggle="tab"
                aria-expanded="false"
                class=""
                >Team</a
              >
            </li>
            <li role="presentation" *ngIf="data.details?.numTypeId == 1">
              <a
                href="#tabs3"
                aria-controls="tabs3"
                role="tab"
                data-toggle="tab"
                aria-expanded="false"
                class=""
                >Drip Automation</a
              >
            </li>
            <li role="presentation" *ngIf="data.details?.numTypeId == 1">
              <a
                href="#tabs4"
                aria-controls="tabs4"
                role="tab"
                data-toggle="tab"
                aria-expanded="false"
                class=""
                >Tags Automation</a
              >
            </li>
          </ul>
        </div>
        <div class="col-12 col-md-12 col-sm-12">
          <div class="tab-content">
            <div role="tabpanel" id="tabs1" class="tab-pane active">
              <!-- PHONE NUMBER -->
              <div class="form-group">
                <label class="labels" for="inputCampaign4">Phone Number</label>
                <input
                  type="text"
                  class="form-control"
                  id="inputphone"
                  placeholder="Phone"
                  formControlName="number"
                  readonly
                />
              </div>

              <!-- LEAD SOURCE -->
              <div class="form-group">
                <label class="labels" for="inputleadSource">
                  Lead Source <span class="text-danger">*</span>
                </label>
                <select
                  id="inputleadSource"
                  class="form-control"
                  formControlName="crmQuestionId"
                >
                  <option value="">Select Lead Source</option>
                  <option
                    *ngFor="let getleadSource of leadSources"
                    [value]="getleadSource._id"
                  >
                    {{ getleadSource.answer }}
                  </option>
                </select>
                <div
                  *ngIf="
                    (submitted && f.crmQuestionId.errors) ||
                    (f.crmQuestionId.errors &&
                      (f.crmQuestionId.dirty || f.crmQuestionId.touched))
                  "
                  class="text-danger"
                >
                  <span *ngIf="f.crmQuestionId.errors.required">
                    Lead Source is required
                  </span>
                </div>
              </div>

              <!-- CAMPAIGN NAME -->
              <div class="form-group">
                <label class="labels" for="inputCampaign4">
                  Campaign Name <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="inputCampaign4"
                  formControlName="marketingTitle"
                  placeholder="Campaign Name"
                />
                <div
                  *ngIf="
                    (submitted && f.marketingTitle.errors) ||
                    (f.marketingTitle.errors &&
                      (f.marketingTitle.dirty || f.marketingTitle.touched))
                  "
                  class="text-danger"
                >
                  <span *ngIf="f.marketingTitle.errors.required"
                    >Campaign Name is required</span
                  >
                </div>
              </div>

              <!-- FLOW NAME -->
              <div class="form-group">
                <label class="labels" for="inputflowName2">
                  Call Flow Name <span class="text-danger">*</span>
                </label>

                <select class="form-control" formControlName="flowId">
                  <option value="">Select Flow</option>
                  <option
                    *ngFor="let eachFlow of flowList"
                    [value]="eachFlow._id"
                  >
                    {{ eachFlow.name }}
                  </option>
                </select>
                <div
                  *ngIf="
                    (submitted && f.flowId.errors) ||
                    (f.flowId.errors && (f.flowId.dirty || f.flowId.touched))
                  "
                  class="text-danger"
                >
                  <span *ngIf="f.flowId.errors.required">
                    Flow Name is required
                  </span>
                </div>
              </div>
            </div>
            <div role="tabpanel" id="tabs2" class="tab-pane">
              <!-- MARKET ID -->
              <div class="form-group">
                <label class="labels" for="inputCampaign4">
                  Market <span class="text-danger">*</span>
                </label>
                <select
                  class="form-control"
                  formControlName="marketId"
                  name="userdata.market_id"
                  (change)="changeMarket()"
                >
                  <option *ngFor="let market of markets" [value]="market._id">
                    {{ market.title }}
                  </option>
                </select>

                <div
                  *ngIf="
                    (submitted && f.marketId.errors) ||
                    (f.marketId.errors &&
                      (f.marketId.dirty || f.marketId.touched))
                  "
                  class="text-danger"
                >
                  <span *ngIf="f.marketId.errors.required"
                    >Market is required</span
                  >
                </div>
              </div>

              <!-- ROLES -->
              <ng-container *ngFor="let role of roles; let i = index">
                <div
                  class="form-group"
                  *ngIf="userRoles[role?.value]?.users?.length"
                >
                  <label class="labels">
                    {{ role?.label }}
                    <span class="text-danger">*</span>
                  </label>

                  <p-multiSelect
                    placeholder="Users"
                    [options]="userRoles[role?.value]?.users"
                    [name]="'users_' + i"
                    [formControlName]="role.value"
                    [disabled]="userRoles[role?.value]?.isReadOnly"
                    appendTo="body"
                  ></p-multiSelect>

                  <div class="text-danger">
                    <span *ngIf="submitted && hasError(role.value, 'required')">
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          role?.label
                        )
                      }}
                    </span>
                  </div>
                </div>
              </ng-container>

              <span style="display: none">
                <button
                  type="submit"
                  class="btn btn-primary"
                  (click)="submitForm()"
                >
                  Update
                </button>
              </span>
            </div>
            <div role="tabpanel" id="tabs3" class="tab-pane">
              <ng-container *ngIf="isAllowManageNumberFeature">
                <div class="form-group">
                  <div class="radiosec">
                    <p class="radotext">
                      Would you like to auto assign a drip campaign to leads
                      created under this marketing campaign?
                    </p>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="dripcamp"
                        formControlName="isAutoAssignDrip"
                        (change)="onAutoAssignDrip()"
                      />
                      <span class="slider"></span>
                      <span value="false" class="text1">No</span>
                      <span value="true" class="text2">Yes</span>
                    </label>
                  </div>
                  <hr />
                </div>

                <div
                  class="form-group"
                  *ngIf="manageNumberForm?.value?.isAutoAssignDrip"
                >
                  <div class="radiosec">
                    <p class="radotext">Leads created manually</p>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="leadcreate"
                        formControlName="isLeadCreatedManual"
                        (change)="onLeadCreatedManual()"
                      />
                      <span class="slider"></span>
                      <span value="false" class="text1">Off</span>
                      <span value="true" class="text2">On</span>
                    </label>
                  </div>
                  <div *ngIf="manageNumberForm?.value?.isLeadCreatedManual">
                    <div class="form-group">
                      <label class="labels"
                        >Choose auto follow-up sequence *
                      </label>
                      <select
                        class="form-control"
                        formControlName="manualLeadDripId"
                      >
                        <option value="">--Select--</option>
                        <option
                          *ngFor="let drip of dripList"
                          value="{{ drip._id }}"
                        >
                          {{ drip.name }}
                        </option>
                      </select>
                    </div>
                    <div class="radiosec">
                      <p class="radotext">
                        Would you like to stop the drip campaign once you
                        receive a Call or SMS from the lead?
                      </p>
                      <!-- <label class="switch">
                        <input
                          type="checkbox"
                          id="callsms"
                          formControlName="isManualLeadAutoStopDrip"
                        />
                        <span class="slider"></span>
                        <span value="false" class="text1">No</span>
                        <span value="true" class="text2">Yes</span>
                      </label> -->
                      <div class="re-radiolist">
                        <label class="re-radiobtn">
                          <input
                            type="radio"
                            name="isManualLeadAutoStopDrip"
                            [value]="true"
                            formControlName="isManualLeadAutoStopDrip"
                            id="inlineRadio1"
                          />
                          <div
                            class="form-check-label re-no-margin"
                            for="inlineRadio1"
                          >
                            Yes
                          </div>
                          <span class="re-checkmark"></span>
                        </label>
                        <label class="re-radiobtn">
                          <input
                            type="radio"
                            name="isManualLeadAutoStopDrip"
                            [value]="false"
                            formControlName="isManualLeadAutoStopDrip"
                            id="inlineRadio2"
                          />
                          <div
                            class="form-check-label re-no-margin"
                            for="inlineRadio2"
                          >
                            No
                          </div>
                          <span class="re-checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>

                <div
                  class="form-group"
                  *ngIf="manageNumberForm?.value?.isAutoAssignDrip"
                >
                  <div class="radiosec">
                    <p class="radotext">
                      Leads created from incoming ANSWERED calls
                    </p>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="leadcreateans"
                        formControlName="isDripAnsCall"
                        (change)="onDripAnsCall()"
                      />
                      <span class="slider"></span>
                      <span value="false" class="text1">Off</span>
                      <span value="true" class="text2">On</span>
                    </label>
                  </div>
                  <div *ngIf="manageNumberForm?.value?.isDripAnsCall">
                    <div class="form-group">
                      <label class="labels"
                        >Choose auto follow-up sequence *
                      </label>
                      <select class="form-control" formControlName="ansDripId">
                        <option value="">--Select--</option>
                        <option
                          *ngFor="let drip of dripList"
                          value="{{ drip._id }}"
                        >
                          {{ drip.name }}
                        </option>
                      </select>
                    </div>
                    <div class="radiosec">
                      <p class="radotext">
                        Would you like to stop the drip campaign once you
                        receive a Call or SMS from the lead?
                      </p>
                      <!-- <label class="switch">
                        <input
                          type="checkbox"
                          id="callsms1"
                          formControlName="isAnsAutoStopDrip"
                        />
                        <span class="slider"></span>
                        <span value="false" class="text1">No</span>
                        <span value="true" class="text2">Yes</span>
                      </label> -->
                      <div class="re-radiolist">
                        <label class="re-radiobtn">
                          <input
                            type="radio"
                            name="isAnsAutoStopDrip"
                            [value]="true"
                            formControlName="isAnsAutoStopDrip"
                            id="inlineRadio1"
                          />
                          <div
                            class="form-check-label re-no-margin"
                            for="inlineRadio1"
                          >
                            Yes
                          </div>
                          <span class="re-checkmark"></span>
                        </label>
                        <label class="re-radiobtn">
                          <input
                            type="radio"
                            name="isAnsAutoStopDrip"
                            [value]="false"
                            formControlName="isAnsAutoStopDrip"
                            id="inlineRadio2"
                          />
                          <div
                            class="form-check-label re-no-margin"
                            for="inlineRadio2"
                          >
                            No
                          </div>
                          <span class="re-checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>

                <div
                  class="form-group"
                  *ngIf="manageNumberForm?.value?.isAutoAssignDrip"
                >
                  <div class="radiosec">
                    <p class="radotext">
                      Leads created from incoming UN-ANSWERED calls
                    </p>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="leadCreateunans"
                        formControlName="isDripUnansCall"
                        (change)="onDripUnansCall()"
                      />
                      <span class="slider"></span>
                      <span value="false" class="text1">Off</span>
                      <span value="true" class="text2">On</span>
                    </label>
                  </div>
                  <div *ngIf="manageNumberForm?.value?.isDripUnansCall">
                    <div class="form-group">
                      <label class="labels"
                        >Choose auto follow-up sequence *
                      </label>
                      <select
                        class="form-control"
                        formControlName="unansDripId"
                      >
                        <option value="">--Select--</option>
                        <option
                          *ngFor="let drip of dripList"
                          value="{{ drip._id }}"
                        >
                          {{ drip.name }}
                        </option>
                      </select>
                    </div>
                    <div class="radiosec">
                      <p class="radotext">
                        Would you like to stop the drip campaign once you
                        receive a Call or SMS from the lead?
                      </p>
                      <!-- <label class="switch">
                        <input
                          type="checkbox"
                          id="callsms2"
                          formControlName="isUnansAutoStopDrip"
                        />
                        <span class="slider"></span>
                        <span value="false" class="text1">No</span>
                        <span value="true" class="text2">Yes</span>
                      </label> -->
                      <div class="re-radiolist">
                        <label class="re-radiobtn">
                          <input
                            type="radio"
                            name="isUnansAutoStopDrip"
                            [value]="true"
                            formControlName="isUnansAutoStopDrip"
                            id="inlineRadio1"
                          />
                          <div
                            class="form-check-label re-no-margin"
                            for="inlineRadio1"
                          >
                            Yes
                          </div>
                          <span class="re-checkmark"></span>
                        </label>
                        <label class="re-radiobtn">
                          <input
                            type="radio"
                            name="isUnansAutoStopDrip"
                            [value]="false"
                            formControlName="isUnansAutoStopDrip"
                            id="inlineRadio2"
                          />
                          <div
                            class="form-check-label re-no-margin"
                            for="inlineRadio2"
                          >
                            No
                          </div>
                          <span class="re-checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>

                <div
                  class="form-group"
                  *ngIf="manageNumberForm?.value?.isAutoAssignDrip"
                >
                  <div class="radiosec">
                    <p class="radotext">
                      Leads created from integration (Zapier or Carrot)
                    </p>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="leadCreatezap"
                        formControlName="isDripZap"
                        (change)="onDripZap()"
                      />
                      <span class="slider"></span>
                      <span value="false" class="text1">Off</span>
                      <span value="true" class="text2">On</span>
                    </label>
                  </div>
                  <div *ngIf="manageNumberForm?.value?.isDripZap">
                    <div class="form-group">
                      <label class="labels"
                        >Choose auto follow-up sequence *
                      </label>
                      <select class="form-control" formControlName="zapDripId">
                        <option value="">--Select--</option>
                        <option
                          *ngFor="let drip of dripList"
                          value="{{ drip._id }}"
                        >
                          {{ drip.name }}
                        </option>
                      </select>
                    </div>
                    <div class="radiosec">
                      <p class="radotext">
                        Would you like to stop the drip campaign once you
                        receive a Call or SMS from the lead?
                      </p>
                      <!-- <label class="switch">
                        <input
                          type="checkbox"
                          id="callsms2"
                          formControlName="isZapAutoStopDrip"
                        />
                        <span class="slider"></span>
                        <span value="false" class="text1">No</span>
                        <span value="true" class="text2">Yes</span>
                      </label> -->
                      <div class="re-radiolist">
                        <label class="re-radiobtn">
                          <input
                            type="radio"
                            name="isZapAutoStopDrip"
                            [value]="true"
                            formControlName="isZapAutoStopDrip"
                            id="inlineRadio1"
                          />
                          <div
                            class="form-check-label re-no-margin"
                            for="inlineRadio1"
                          >
                            Yes
                          </div>
                          <span class="re-checkmark"></span>
                        </label>
                        <label class="re-radiobtn">
                          <input
                            type="radio"
                            name="isZapAutoStopDrip"
                            [value]="false"
                            formControlName="isZapAutoStopDrip"
                            id="inlineRadio2"
                          />
                          <div
                            class="form-check-label re-no-margin"
                            for="inlineRadio2"
                          >
                            No
                          </div>
                          <span class="re-checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
                <div
                  class="form-group"
                  *ngIf="manageNumberForm?.value?.isAutoAssignDrip"
                >
                  <div class="radiosec">
                    <p class="radotext">Leads created from Web Forms</p>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="leadCreateWebForm"
                        formControlName="isDripWebForm"
                        (change)="onDripWebForm()"
                      />
                      <span class="slider"></span>
                      <span value="false" class="text1">Off</span>
                      <span value="true" class="text2">On</span>
                    </label>
                  </div>
                  <div *ngIf="manageNumberForm?.value?.isDripWebForm">
                    <div class="form-group">
                      <label class="labels"
                        >Choose auto follow-up sequence *
                      </label>
                      <select
                        class="form-control"
                        formControlName="webFormDripId"
                      >
                        <option value="">--Select--</option>
                        <option
                          *ngFor="let drip of dripList"
                          value="{{ drip._id }}"
                        >
                          {{ drip.name }}
                        </option>
                      </select>
                    </div>
                    <div class="radiosec">
                      <p class="radotext">
                        Would you like to stop the drip campaign once you
                        receive a Call or SMS from the lead?
                      </p>
                      <!-- <label class="switch">
                        <input
                          type="checkbox"
                          id="callsms2"
                          formControlName="isWebFormAutoStopDrip"
                        />
                        <span class="slider"></span>
                        <span value="false" class="text1">No</span>
                        <span value="true" class="text2">Yes</span>
                      </label> -->
                      <div class="re-radiolist">
                        <label class="re-radiobtn">
                          <input
                            type="radio"
                            name="isWebFormAutoStopDrip"
                            [value]="true"
                            formControlName="isWebFormAutoStopDrip"
                            id="inlineRadio1"
                          />
                          <div
                            class="form-check-label re-no-margin"
                            for="inlineRadio1"
                          >
                            Yes
                          </div>
                          <span class="re-checkmark"></span>
                        </label>
                        <label class="re-radiobtn">
                          <input
                            type="radio"
                            name="isWebFormAutoStopDrip"
                            [value]="false"
                            formControlName="isWebFormAutoStopDrip"
                            id="inlineRadio2"
                          />
                          <div
                            class="form-check-label re-no-margin"
                            for="inlineRadio2"
                          >
                            No
                          </div>
                          <span class="re-checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              </ng-container>
              <div
                class="re-unauthorized"
                *ngIf="isAllowManageNumberFeature == false"
              >
                <img
                  class="img-fluid"
                  src="assets/images/unauthrozied.png"
                  alt="unauthrozied"
                />
                <h4>Upgrade Your Account</h4>
                <div class="re-text">
                  Automations are only available on <u>Pro</u> and
                  <u>Enterprise</u> plans.
                  <u>Automate your leads follow ups</u> by upgrading your plan.
                </div>
                <!-- <a class="btn re-teal-btn">Upgrade Plan</a> -->
              </div>
            </div>
            <div role="tabpanel" id="tabs4" class="tab-pane">
              <ng-container *ngIf="isAllowManageNumberFeature">
                <div class="radiosec">
                  <p class="radotext">
                    Would you like to auto assign tags to the leads created
                    under this marketing campaign?
                  </p>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id="marketingcheck"
                      formControlName="isTagAutomation"
                    />
                    <span class="slider"></span>
                    <span value="false" class="text1">No</span>
                    <span value="true" class="text2">Yes</span>
                  </label>
                </div>

                <div
                  class="form-group"
                  *ngIf="manageNumberForm?.value?.isTagAutomation"
                >
                  <div class="radiosec">
                    <p class="radotext">Leads created manually</p>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="leadcreate"
                        formControlName="isTagLeadCreatedManual"
                      />
                      <span class="slider"></span>
                      <span value="false" class="text1">Off</span>
                      <span value="true" class="text2">On</span>
                    </label>
                  </div>
                  <div *ngIf="manageNumberForm?.value?.isTagLeadCreatedManual">
                    <label class="labels"
                      >Auto assign Tags to leads created manually
                    </label>
                    <!-- Tag Editor -->
                    <mat-form-field class="re-chip-list">
                      <mat-chip-list #chipList1 aria-label="Tag selection">
                        <mat-chip
                          *ngFor="let tag of tags1; let tagIndex = index"
                          [selectable]="selectable"
                          [removable]="removable"
                          (removed)="remove(tagIndex, 1)"
                        >
                          {{ tag.label }}
                          <mat-icon matChipRemove *ngIf="removable"
                            >cancel</mat-icon
                          >
                        </mat-chip>

                        <input
                          #tagInput1
                          [formControl]="tagCtrl1"
                          [matAutocomplete]="auto1"
                          [matChipInputFor]="chipList1"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          (matChipInputTokenEnd)="add($event, 1)"
                          (input)="onSearchChange($event.target.value, 1)"
                        />
                      </mat-chip-list>
                      <mat-placeholder
                        [ngClass]="
                          tags1.length > 0
                            ? 'placeholder removePlaceHolder'
                            : 'placeholder'
                        "
                        >Type tag name and press enter or comma to create a new
                        tag</mat-placeholder
                      >
                      <mat-autocomplete
                        #auto1="matAutocomplete"
                        (optionSelected)="selected($event, 1)"
                      >
                        <ng-container *ngFor="let tag of filteredTags1 | async">
                          <mat-option *ngIf="isVisible(tag, 1)" [value]="tag">
                            {{ tag.label }}
                          </mat-option>
                        </ng-container>
                      </mat-autocomplete>
                    </mat-form-field>
                    <label
                      style="font-weight: normal; font-size: 13px"
                      class="mt-1"
                      *ngIf="isShowPlaceHolder1"
                      >Press Enter or comma to create a new tag</label
                    >
                    <!-- End Tag Editor -->
                  </div>
                  <hr />
                </div>

                <div
                  class="form-group"
                  *ngIf="manageNumberForm?.value?.isTagAutomation"
                >
                  <div class="radiosec">
                    <p class="radotext">
                      Leads created from incoming ANSWERED calls
                    </p>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="leadcreate"
                        formControlName="isTagAnsCall"
                      />
                      <span class="slider"></span>
                      <span value="false" class="text1">Off</span>
                      <span value="true" class="text2">On</span>
                    </label>
                  </div>
                  <div *ngIf="manageNumberForm?.value?.isTagAnsCall">
                    <label class="labels"
                      >Auto assign Tags to leads created from incoming answered
                      calls
                    </label>
                    <!-- Tag Editor -->
                    <mat-form-field class="re-chip-list">
                      <mat-chip-list #chipList2 aria-label="Tag selection">
                        <mat-chip
                          *ngFor="let tag of tags2; let tagIndex = index"
                          [selectable]="selectable"
                          [removable]="removable"
                          (removed)="remove(tagIndex, 2)"
                        >
                          {{ tag.label }}
                          <mat-icon matChipRemove *ngIf="removable"
                            >cancel</mat-icon
                          >
                        </mat-chip>

                        <input
                          #tagInput2
                          [formControl]="tagCtrl2"
                          [matAutocomplete]="auto2"
                          [matChipInputFor]="chipList2"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          (matChipInputTokenEnd)="add($event, 2)"
                          (input)="onSearchChange($event.target.value, 2)"
                        />
                      </mat-chip-list>
                      <mat-placeholder
                        [ngClass]="
                          tags2.length > 0
                            ? 'placeholder removePlaceHolder'
                            : 'placeholder'
                        "
                        >Type tag name and press enter or comma to create a new
                        tag</mat-placeholder
                      >
                      <mat-autocomplete
                        #auto2="matAutocomplete"
                        (optionSelected)="selected($event, 2)"
                      >
                        <ng-container *ngFor="let tag of filteredTags2 | async">
                          <mat-option *ngIf="isVisible(tag, 2)" [value]="tag">
                            {{ tag.label }}
                          </mat-option>
                        </ng-container>
                      </mat-autocomplete>
                    </mat-form-field>
                    <label
                      style="font-weight: normal; font-size: 13px"
                      class="mt-1"
                      *ngIf="isShowPlaceHolder2"
                      >Press Enter or comma to create a new tag</label
                    >
                    <!-- End Tag Editor -->
                  </div>
                  <hr />
                </div>

                <div
                  class="form-group"
                  *ngIf="manageNumberForm?.value?.isTagAutomation"
                >
                  <div class="radiosec">
                    <p class="radotext">
                      Leads created from incoming UN-ANSWERED calls
                    </p>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="leadcreate"
                        formControlName="isTagUnansCall"
                      />
                      <span class="slider"></span>
                      <span value="false" class="text1">Off</span>
                      <span value="true" class="text2">On</span>
                    </label>
                  </div>
                  <div *ngIf="manageNumberForm?.value?.isTagUnansCall">
                    <label class="labels"
                      >Auto assign Tags to leads created from incoming
                      un-answered calls
                    </label>
                    <!-- Tag Editor -->
                    <mat-form-field class="re-chip-list">
                      <mat-chip-list #chipList3 aria-label="Tag selection">
                        <mat-chip
                          *ngFor="let tag of tags3; let tagIndex = index"
                          [selectable]="selectable"
                          [removable]="removable"
                          (removed)="remove(tagIndex, 3)"
                        >
                          {{ tag.label }}
                          <mat-icon matChipRemove *ngIf="removable"
                            >cancel</mat-icon
                          >
                        </mat-chip>

                        <input
                          #tagInput3
                          [formControl]="tagCtrl3"
                          [matAutocomplete]="auto3"
                          [matChipInputFor]="chipList3"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          (matChipInputTokenEnd)="add($event, 3)"
                          (input)="onSearchChange($event.target.value, 3)"
                        />
                      </mat-chip-list>
                      <mat-placeholder
                        [ngClass]="
                          tags3.length > 0
                            ? 'placeholder removePlaceHolder'
                            : 'placeholder'
                        "
                        >Type tag name and press enter or comma to create a new
                        tag</mat-placeholder
                      >
                      <mat-autocomplete
                        #auto3="matAutocomplete"
                        (optionSelected)="selected($event, 3)"
                      >
                        <ng-container *ngFor="let tag of filteredTags3 | async">
                          <mat-option *ngIf="isVisible(tag, 3)" [value]="tag">
                            {{ tag.label }}
                          </mat-option>
                        </ng-container>
                      </mat-autocomplete>
                    </mat-form-field>
                    <label
                      style="font-weight: normal; font-size: 13px"
                      class="mt-1"
                      *ngIf="isShowPlaceHolder3"
                      >Press Enter or comma to create a new tag</label
                    >
                    <!-- End Tag Editor -->
                  </div>
                  <hr />
                </div>

                <div
                  class="form-group"
                  *ngIf="manageNumberForm?.value?.isTagAutomation"
                >
                  <div class="radiosec">
                    <p class="radotext">
                      Leads created from integration (Zapier or Carrot)
                    </p>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="leadcreate"
                        formControlName="isTagZap"
                      />
                      <span class="slider"></span>
                      <span value="false" class="text1">Off</span>
                      <span value="true" class="text2">On</span>
                    </label>
                  </div>
                  <div *ngIf="manageNumberForm?.value?.isTagZap">
                    <label class="labels"
                      >Auto assign Tags to leads created from integration
                    </label>
                    <!-- Tag Editor -->
                    <mat-form-field class="re-chip-list">
                      <mat-chip-list #chipList4 aria-label="Tag selection">
                        <mat-chip
                          *ngFor="let tag of tags4; let tagIndex = index"
                          [selectable]="selectable"
                          [removable]="removable"
                          (removed)="remove(tagIndex, 4)"
                        >
                          {{ tag.label }}
                          <mat-icon matChipRemove *ngIf="removable"
                            >cancel</mat-icon
                          >
                        </mat-chip>

                        <input
                          #tagInput4
                          [formControl]="tagCtrl4"
                          [matAutocomplete]="auto4"
                          [matChipInputFor]="chipList4"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          (matChipInputTokenEnd)="add($event, 4)"
                          (input)="onSearchChange($event.target.value, 4)"
                        />
                      </mat-chip-list>
                      <mat-placeholder
                        [ngClass]="
                          tags4.length > 0
                            ? 'placeholder removePlaceHolder'
                            : 'placeholder'
                        "
                        >Type tag name and press enter or comma to create a new
                        tag</mat-placeholder
                      >
                      <mat-autocomplete
                        #auto4="matAutocomplete"
                        (optionSelected)="selected($event, 4)"
                      >
                        <ng-container *ngFor="let tag of filteredTags4 | async">
                          <mat-option *ngIf="isVisible(tag, 4)" [value]="tag">
                            {{ tag.label }}
                          </mat-option>
                        </ng-container>
                      </mat-autocomplete>
                    </mat-form-field>
                    <label
                      style="font-weight: normal; font-size: 13px"
                      class="mt-1"
                      *ngIf="isShowPlaceHolder4"
                      >Press Enter or comma to create a new tag</label
                    >
                    <!-- End Tag Editor -->
                  </div>
                  <hr />
                </div>
                <div
                  class="form-group"
                  *ngIf="manageNumberForm?.value?.isTagAutomation"
                >
                  <div class="radiosec">
                    <p class="radotext">Leads created from Web Forms</p>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id="leadcreate"
                        formControlName="isTagWebForm"
                      />
                      <span class="slider"></span>
                      <span value="false" class="text1">Off</span>
                      <span value="true" class="text2">On</span>
                    </label>
                  </div>
                  <div *ngIf="manageNumberForm?.value?.isTagWebForm">
                    <label class="labels"
                      >Auto assign Tags to leads created from Web Forms
                    </label>
                    <!-- Tag Editor -->
                    <mat-form-field class="re-chip-list">
                      <mat-chip-list #chipList5 aria-label="Tag selection">
                        <mat-chip
                          *ngFor="let tag of tags5; let tagIndex = index"
                          [selectable]="selectable"
                          [removable]="removable"
                          (removed)="remove(tagIndex, 5)"
                        >
                          {{ tag.label }}
                          <mat-icon matChipRemove *ngIf="removable"
                            >cancel</mat-icon
                          >
                        </mat-chip>

                        <input
                          #tagInput5
                          [formControl]="tagCtrl5"
                          [matAutocomplete]="auto5"
                          [matChipInputFor]="chipList5"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          (matChipInputTokenEnd)="add($event, 5)"
                          (input)="onSearchChange($event.target.value, 5)"
                        />
                      </mat-chip-list>
                      <mat-placeholder
                        [ngClass]="
                          tags5.length > 0
                            ? 'placeholder removePlaceHolder'
                            : 'placeholder'
                        "
                        >Type tag name and press enter or comma to create a new
                        tag</mat-placeholder
                      >
                      <mat-autocomplete
                        #auto5="matAutocomplete"
                        (optionSelected)="selected($event, 5)"
                      >
                        <ng-container *ngFor="let tag of filteredTags5 | async">
                          <mat-option *ngIf="isVisible(tag, 5)" [value]="tag">
                            {{ tag.label }}
                          </mat-option>
                        </ng-container>
                      </mat-autocomplete>
                    </mat-form-field>
                    <label
                      style="font-weight: normal; font-size: 13px"
                      class="mt-1"
                      *ngIf="isShowPlaceHolder5"
                      >Press Enter or comma to create a new tag</label
                    >
                    <!-- End Tag Editor -->
                  </div>
                  <hr />
                </div>
              </ng-container>
              <div
                class="re-unauthorized"
                *ngIf="isAllowManageNumberFeature == false"
              >
                <img
                  class="img-fluid"
                  src="assets/images/unauthrozied.png"
                  alt="unauthrozied"
                />
                <h4>Upgrade Your Account</h4>
                <div class="re-text">
                  Automations are only available on <u>Pro</u> and
                  <u>Enterprise</u> plans.
                  <u>Automate your leads follow ups</u> by upgrading your plan.
                </div>
                <!-- <a class="btn re-teal-btn">Upgrade Plan</a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="padding-top: 14px !important">
  <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
  <button
    (click)="submitForm()"
    mat-raised-button
    cdkFocusInitial
    class="submitBtn"
  >
    Edit Number
  </button>
</mat-dialog-actions>
