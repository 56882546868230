<div class="">
  <div class="col-12 d-flex re-header">
    <h2
      mat-dialog-title
      style="display: block !important"
      class="w-100 d-flex justify-content-between"
    >
      SOW Detail
    </h2>
    <span matDialogClose mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<mat-dialog-content class="re-typography">
  <div class="container-fluid p-0 position-relative">
    <div class="re-loader-td" *ngIf="loadingDetail">
      <img class="loader-media" src="/assets/images/loading.gif" alt="loader" />
    </div>

    <div class="re-boxline re-border-btm" *ngIf="shareLinkStatus">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12">
          <div class="re-link-box">
            <div class="re-link-link">
              <p class="re-label">SOW - Sharing: ON</p>
              <p class="re-copy-to-clipboard">{{ shareLink }}</p>
            </div>
            <div class="re-clipboard-box" (click)="copyToClipboard(shareLink)">
              <img
                src="/assets/images/clipboard-link.svg"
                alt="Copy To Clipboard"
              />
              Copy Url
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="re-boxline">
      <div class="row">
        <div class="col-12 col-md-6 col-sm-12">
          <p class="re-text1">{{ sowDetail.sowname }}</p>
          <p class="re-text2">
            Estimate:
            {{ sowDetail?.estimation | currency | currencyPositive }}
          </p>
          <p class="re-text2">Material Paid By: {{ sowDetail.materialpaid }}</p>
          <p class="re-text2">
            Project Timeline: {{ sowDetail.startdate | date: "mediumDate" }}-{{
              sowDetail.enddate | date: "mediumDate"
            }}({{ diffDays }} days)
          </p>
        </div>
        <div class="col-12 col-md-6 col-sm-12 re-textright">
          <button
            class="btn btn-sm"
            type="button"
            (click)="getUserDetails()"
            pTooltip="Print"
            tooltipPosition="bottom"
          >
            <svg
              version="1.1"
              width="18px"
              height="18px"
              viewBox="0 0 83 83"
              xml:space="preserve"
            >
              <g>
                <path
                  d="M73.908,13.162h-5.381V8.784c0-3.936-3.202-7.139-7.138-7.139H22.416c-3.937,0-7.139,3.203-7.139,7.139v4.378H9.898
              C4.44,13.162,0,17.601,0,23.058v32.271c0,5.458,4.44,9.898,9.898,9.898h5.379v9.795c0,3.937,3.202,7.139,7.139,7.139h38.973
              c3.936,0,7.138-3.202,7.138-7.139v-9.795h5.381c5.458,0,9.898-4.44,9.898-9.898v-32.27C83.806,17.602,79.366,13.162,73.908,13.162z
                M21.278,8.784c0-0.628,0.511-1.139,1.139-1.139H61.39c0.627,0,1.138,0.511,1.138,1.139v19.451c0,0.627-0.511,1.138-1.138,1.138
              H22.416c-0.628,0-1.139-0.51-1.139-1.138V8.784H21.278z M62.527,75.023c0,0.628-0.511,1.139-1.138,1.139H22.416
              c-0.628,0-1.139-0.511-1.139-1.139V56.882c0-0.628,0.511-1.139,1.139-1.139h38.973c0.627,0,1.138,0.511,1.138,1.139V75.023z
                M77.806,55.329c0,2.149-1.749,3.898-3.898,3.898h-5.381v-2.345c0-3.937-3.202-7.139-7.138-7.139H22.416
              c-3.937,0-7.139,3.202-7.139,7.139v2.346H9.898C7.749,59.228,6,57.479,6,55.33V23.059c0-2.148,1.749-3.896,3.898-3.896h5.379v9.073
              c0,3.936,3.202,7.138,7.139,7.138h38.973c3.936,0,7.138-3.202,7.138-7.138v-9.074h5.381c2.149,0,3.898,1.748,3.898,3.896V55.329z"
                />
                <rect x="26.5" y="58.852" width="30.882" height="6" />
                <rect x="26.5" y="67.763" width="30.882" height="6" />
              </g>
            </svg>
            <!-- <img src="assets/images/printer-black.svg" /> -->
          </button>
          <button
            [ngClass]="
              sowDetail?.sharing ? 'btn btn-sm re-shared' : 'btn btn-sm'
            "
            type="button"
            (click)="shareSOW(sowDetail?.sharing ? '1' : '0')"
            pTooltip="Share"
            tooltipPosition="bottom"
          >
            <svg
              version="1.1"
              width="18px"
              height="18px"
              viewBox="0 0 227 227"
              xml:space="preserve"
              *ngIf="!sowDetail?.sharing"
            >
              <path
                d="M175.897,141.476c-13.249,0-25.11,6.044-32.98,15.518l-51.194-29.066c1.592-4.48,2.467-9.297,2.467-14.317
              c0-5.019-0.875-9.836-2.467-14.316l51.19-29.073c7.869,9.477,19.732,15.523,32.982,15.523c23.634,0,42.862-19.235,42.862-42.879
              C218.759,19.229,199.531,0,175.897,0C152.26,0,133.03,19.229,133.03,42.865c0,5.02,0.874,9.838,2.467,14.319L84.304,86.258
              c-7.869-9.472-19.729-15.514-32.975-15.514c-23.64,0-42.873,19.229-42.873,42.866c0,23.636,19.233,42.865,42.873,42.865
              c13.246,0,25.105-6.042,32.974-15.513l51.194,29.067c-1.593,4.481-2.468,9.3-2.468,14.321c0,23.636,19.23,42.865,42.867,42.865
              c23.634,0,42.862-19.23,42.862-42.865C218.759,160.71,199.531,141.476,175.897,141.476z M175.897,15
              c15.363,0,27.862,12.5,27.862,27.865c0,15.373-12.499,27.879-27.862,27.879c-15.366,0-27.867-12.506-27.867-27.879
              C148.03,27.5,160.531,15,175.897,15z M51.33,141.476c-15.369,0-27.873-12.501-27.873-27.865c0-15.366,12.504-27.866,27.873-27.866
              c15.363,0,27.861,12.5,27.861,27.866C79.191,128.975,66.692,141.476,51.33,141.476z M175.897,212.216
              c-15.366,0-27.867-12.501-27.867-27.865c0-15.37,12.501-27.875,27.867-27.875c15.363,0,27.862,12.505,27.862,27.875
              C203.759,199.715,191.26,212.216,175.897,212.216z"
              />
            </svg>
            <!-- <img
              src="assets/images/share-black.svg"
              *ngIf="!sowDetail?.sharing"
            /> -->
            <img
              src="assets/images/share-tilt.svg"
              *ngIf="sowDetail?.sharing"
              alt="share-tilt"
            />
          </button>
          <button
            class="btn btn-sm"
            type="button"
            pTooltip="Edit"
            tooltipPosition="bottom"
            (click)="onEditSow('Edit', sowDetail)"
          >
            <svg width="18px" height="18px" viewBox="0 0 24 24">
              <path
                d="M4 16L3.46967 15.4697C3.32902 15.6103 3.25 15.8011 3.25 16H4ZM17 3L17.5303 2.46967C17.2374 2.17678 16.7626 2.17678 16.4697 2.46967L17 3ZM21 7L21.5303 7.53033C21.8232 7.23744 21.8232 6.76256 21.5303 6.46967L21 7ZM8 20V20.75C8.19891 20.75 8.38968 20.671 8.53033 20.5303L8 20ZM4 20H3.25C3.25 20.4142 3.58579 20.75 4 20.75V20ZM12 19.25C11.5858 19.25 11.25 19.5858 11.25 20C11.25 20.4142 11.5858 20.75 12 20.75V19.25ZM20.5 20.75C20.9142 20.75 21.25 20.4142 21.25 20C21.25 19.5858 20.9142 19.25 20.5 19.25V20.75ZM4.53033 16.5303L17.5303 3.53033L16.4697 2.46967L3.46967 15.4697L4.53033 16.5303ZM16.4697 3.53033L20.4697 7.53033L21.5303 6.46967L17.5303 2.46967L16.4697 3.53033ZM20.4697 6.46967L7.46967 19.4697L8.53033 20.5303L21.5303 7.53033L20.4697 6.46967ZM8 19.25H4V20.75H8V19.25ZM4.75 20V16H3.25V20H4.75ZM13.4697 6.53033L17.4697 10.5303L18.5303 9.46967L14.5303 5.46967L13.4697 6.53033ZM12 20.75H20.5V19.25H12V20.75Z"
              />
            </svg>
            <!-- <img src="assets/images/edit-2.svg" /> -->
          </button>
        </div>
      </div>
    </div>
    <div class="re-box">
      <div class="row">
        <div class="col-12 col-md-6 col-sm-12 re-box-section">
          <p class="re-text1">PROPERTY INFORMATION</p>
          <p
            class="re-text1 pl-3"
            *ngIf="mainUserData?.firstName || mainUserData?.lastName"
          >
            {{ mainUserData?.firstName }} {{ mainUserData?.lastName }}
          </p>
          <p class="re-text1 pl-3" *ngIf="mainUserData?.companyName">
            {{ mainUserData?.companyName }}
          </p>
          <!-- <p class="re-text2 pl-3 re-gray" *ngIf="mainUserData?.email">
            {{ mainUserData?.email }}
          </p> -->
          <p class="re-text2 pl-3 re-gray" *ngIf="mainUserData?.contactNumber">
            {{ utilities.maskNumber(mainUserData?.contactNumber) }}
          </p>

          <p class="re-text2 pl-3">{{ address }}</p>
        </div>
        <div class="col-12 col-md-6 col-sm-12 re-box-section2">
          <p class="re-text1">CONTRACTOR INFORMATION</p>
          <p class="re-text1 pl-3">
            {{ contractorDetail?.firstName + " " + contractorDetail?.lastName }}
          </p>
          <p class="re-text2 pl-3 re-gray">
            {{ contractorDetail?.displayAddress }}
          </p>
          <p
            class="re-text2 pl-3 re-gray"
            *ngIf="
              contractorDetail?.phone && contractorDetail?.phone.length > 0
            "
          >
            {{ utilities.maskNumber(contractorDetail?.phone) }}
          </p>
          <p
            class="re-text2 pl-3 re-gray"
            *ngIf="
              contractorDetail?.email && contractorDetail?.email.length > 0
            "
          >
            {{ contractorDetail?.email }}
          </p>
        </div>
      </div>
    </div>
    <div>
      <div
        class="re-each-cat"
        *ngFor="let eachCat of selectedArray; let i = index"
      >
        <p class="re-text2 re-heading">
          <b>{{ eachCat?.name }}</b>
        </p>
        <div
          class="re-each-sub-cat"
          *ngFor="let eachSubCat of eachCat?.subCat; let j = index"
        >
          <p class="re-text2 text-uppercase">
            <b>{{ eachSubCat?.name }}</b>
          </p>
          <div
            class="re-each-sub-sub-cat"
            *ngFor="let eachSubSubCat of eachSubCat?.subSubCat; let k = index"
          >
            <p class="re-text1">
              {{ eachSubSubCat?.name }}
            </p>
            <ul class="re-list1" *ngIf="sowDetail?.itemprice == 'Itemized'">
              <li class="ng-star-inserted">
                <span class="re-text1">Quantity</span>
                <span class="re-text2">{{ eachSubSubCat?.quantity }}</span>
              </li>
              <li class="ng-star-inserted">
                <span class="re-text1">UoM</span>
                <span class="re-text2">{{ eachSubSubCat?.measurement }}</span>
              </li>
              <li class="ng-star-inserted">
                <span class="re-text1">Unit Price</span>
                <span class="re-text2">
                  {{
                    eachSubSubCat?.unitprice | currency | currencyPositive
                  }}</span
                >
              </li>
              <li class="ng-star-inserted">
                <span class="re-text1">Total</span>
                <span class="re-text2">
                  {{ eachSubSubCat?.total | currency | currencyPositive }}</span
                >
              </li>
            </ul>
            <p class="re-text2">
              {{ eachSubSubCat?.description }}
            </p>
            <ul class="re-list1">
              <li
                class="ng-star-inserted re-img"
                *ngFor="let eachImg of eachSubSubCat?.imageurl; let i = index"
              >
                <a href="{{ s3Url + eachImg }}" target="_blank"
                  ><img src="{{ s3Url + eachImg }}" alt="images" />
                  <p class="re-text1">
                    {{ eachSubSubCat?.name + " #" + (i + 1) }}
                  </p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
