// MODULES
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

// COMPONENT
import { MediaPreviewComponent } from 'src/app/shared/dialog/media-preview/media-preview.component';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharedService } from 'src/app/shared/shared.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { UserService } from 'src/app/providers/user/user.service';

// UTILS
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';

@Component({
  selector: 'app-activity-dialog',
  templateUrl: './activity-dialog.component.html',
  styleUrls: ['./activity-dialog.component.scss'],
})
export class ActivityDialogComponent implements OnInit {
  style: any;
  dialogRef: any;
  messageConstant = MessageConstant;
  currentActivityPage: number = 1;
  activityCurrentLimit: number = 20;
  activitySort: boolean = false;
  filterData: any = {};
  activityType: string = 'comm';
  logs: any = {};
  userImage: any[] = [];
  isUserListResponse: boolean = false;
  userList: any[] = [];
  selectedUserId: string = '';
  userListing: any[] = [];
  selectedUserName: string = '';
  mainUserId: string = '';
  isSubUser: boolean = true;
  comment: any = '';
  comment2: any;
  action: string = 'add';
  leadData: any = {};
  propertyData: any = {};
  callConnectedStatus: any;
  callNotConnectedStatus: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _sharedService: SharedService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _sanitizer: DomSanitizer,
    public _utilities: CommonFunctionsService,
    private _userService: UserService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.callConnectedStatus = this._utilities.callConnectedStatus();
    this.callNotConnectedStatus = this._utilities.callNotConnectedStatus();
    this.leadData = this.data?.leadData;
    this.getActivityLog(true);
    this.getLoginUser();
  }

  getActivityLog(bool?, isLoaderVisible?, sort?) {
    let obj = {
      moduleId: this.data?.moduleId,
      subModuleId: this.data?.leadId,
      page: this.currentActivityPage,
      limit: this.activityCurrentLimit,
      holdingSort: this.activitySort,
    };

    if (this.filterData) {
      obj = { ...obj, ...this.filterData };
    }

    if (this.activityType) {
      obj['activityType'] = this.activityType;
    }

    if (!isLoaderVisible) {
      this._loaderService.start();
    }
    this._sharedService.getActivityLog(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._loaderService.stop();
          if (this.logs.items) {
            if (!sort && !bool) {
              if (this.logs.items?.length === response.data?.count) {
                return;
              }
            }

            this.logs.count = response.data?.count;
            if (bool) {
              this.logs.items = response.data?.items;
            } else {
              this.logs.items = [...this.logs.items, ...response.data?.items];
            }
          } else {
            this.logs = response?.data;
          }
        }
        if (!isLoaderVisible) {
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  checkType(direction, log?, index?) {
    let type = '';
    let mode = '';
    let icon = '';
    let number = '';
    let email = '';
    let from = '';

    switch (direction) {
      case 'outgoingCall':
        type = 'CALL';
        mode = 'send';
        icon = '/assets/images/outgoing-calls.svg';
        number = log['to'];
        from = log['from'];
        break;

      case 'outbound':
        type = 'CALL';
        mode = 'send';
        icon = '/assets/images/outgoing-calls.svg';
        number = log['to'];
        from = log['from'];
        break;

      case 'incomingCall':
        type = 'CALL';
        mode = 'receive';
        icon =
          log?.voiceCallStatus == 'no-answer' ||
          log?.voiceCallStatus == 'cancel' ||
          log?.voiceCallStatus == 'no-confirm' ||
          log?.voiceCallStatus == 'busy'
            ? '/assets/images/missed-calls.svg'
            : log?.activityType == 5
            ? '/assets/images/voice-mail.svg'
            : '/assets/images/incoming-calls.svg';
        number = log['from'];
        from = log['to'];
        break;

      case 'outgoingSms':
        type = 'SMS';
        mode = 'send';
        icon = '/assets/images/outgoing-smss.svg';
        number = log['to'];
        from = log['from'];
        break;

      case 'incomingSms':
        type = 'SMS';
        mode = 'receive';
        icon = '/assets/images/incoming-smss.svg';
        number = log['from'];
        from = log['from'];
        break;

      case 'outgoingMms':
        type = 'MMS';
        mode = 'send';
        icon = '/assets/images/outgoing-smss.svg';
        number = log['to'];
        from = log['from'];
        break;

      case 'incomingMms':
        type = 'MMS';
        mode = 'receive';
        icon = '/assets/images/incoming-smss.svg';
        number = log['from'];
        from = log['to'];
        break;

      case 'outgoingEmail':
        type = 'EMAIL';
        mode = 'send';
        icon = '/assets/images/outgoing-emails.svg';
        email = log['email'];
        from = log['from'];
        break;

      case 'outgoingRvm':
        type = 'RVM';
        mode = 'send';
        icon = '/assets/images/voicemail-rvm.svg';
        number = log['to'];
        from = log['from'];
        break;

      default:
        log.direction = 'comment';
        type = 'COMMENT';
        icon = '/assets/images/demo-profile.png';
        mode = 'send';
        break;
    }
    if (index) {
      this.getFile(log, index);
    }
    return { type, mode, icon, number, email, from };
  }
  getFile(log, index) {
    let param = {
      moduleId: '601cf3c070419f19b74a6882',
      src: log['profileImage'],
    };

    this._sharedService.getFile(param).subscribe(
      (response) => {
        if (response) {
          let objectURL = URL.createObjectURL(response);
          this.userImage[index] =
            this._sanitizer.bypassSecurityTrustUrl(objectURL);
        }
      },
      (err: ErrorModel) => {}
    );
  }
  checkSellerName(number?, index?) {
    let name = 'N/A';
    if (this.propertyData?._id) {
      let propertyBuyer = this.propertyData?.buyers
        .map((secondary) => {
          if (secondary?.contactNumber == number) {
            return secondary;
          }
        })
        .filter((x) => x);
      if (propertyBuyer?.length > 0) {
        let buyerName = propertyBuyer[0]?.firstName
          ? propertyBuyer[0]?.firstName
          : '';
        let buyerLastName = propertyBuyer[0]?.lastName
          ? propertyBuyer[0]?.lastName
          : '';
        name = buyerName + ' ' + buyerLastName;
      } else if (this.leadData) {
        if (number == this.leadData?.phoneNumber) {
          name = this.leadData?.title ? this.leadData?.title : 'N/A';
        } else if (this.leadData?.buyers) {
          let buyerDetail = this.leadData?.buyers
            .map((buyers) => {
              if (buyers?.contactNumber == number) {
                return buyers;
              }
            })
            .filter((x) => x);
          if (buyerDetail?.length > 0) {
            let buyerName = buyerDetail[0]?.firstName
              ? buyerDetail[0]?.firstName
              : '';
            let buyerLastName = buyerDetail[0]?.lastName
              ? buyerDetail[0]?.lastName
              : '';
            name = buyerName + ' ' + buyerLastName;
          } else {
            if (this.leadData?.secondaryContact) {
              name = this.leadData?.secondaryContact
                .map((secondary) => {
                  if (secondary?.phoneNumber == number) {
                    return secondary?.name ? secondary?.name : 'N/A';
                  }
                })
                .filter((x) => x);
            }
          }
        }
      }
    } else if (this.leadData) {
      if (number == this.leadData?.phoneNumber) {
        name = this.leadData?.title ? this.leadData?.title : 'N/A';
      } else if (number == this.leadData?.contactNumber) {
        let buyerName = this.leadData?.firstName
          ? this.leadData?.firstName
          : '';
        let buyerLastName = this.leadData?.lastName
          ? this.leadData?.lastName
          : '';
        name = buyerName + ' ' + buyerLastName;
      } else if (this.leadData?.buyers) {
        let buyerDetail = this.leadData?.buyers
          .map((buyers) => {
            if (buyers?.contactNumber == number) {
              return buyers;
            }
          })
          .filter((x) => x);
        if (buyerDetail?.length > 0) {
          let buyerName = buyerDetail[0]?.firstName
            ? buyerDetail[0]?.firstName
            : '';
          let buyerLastName = buyerDetail[0]?.lastName
            ? buyerDetail[0]?.lastName
            : '';
          name = buyerName + ' ' + buyerLastName;
        } else {
          if (this.leadData?.secondaryContact) {
            name = this.leadData?.secondaryContact
              .map((secondary) => {
                if (secondary?.phoneNumber == number) {
                  return secondary?.name ? secondary?.name : 'N/A';
                }
              })
              .filter((x) => x);
          }
        }
      }
    }
    return name;
  }
  getUsers() {
    this._loaderService.start();

    this._userService.getUsers({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.userList = [];
          this.userListing = response?.data;
          for (let i = 0; i < response?.data.length; i++) {
            const user = response?.data[i];
            const fullName = user.firstName + ' ' + user.lastName;
            const matches = fullName.match(/\b(\w)/g);
            const acronym = matches?.join('');
            this.userList.push({
              label: user.firstName + ' ' + user.lastName,
              value: user._id,
              profileImage: user?.profileImage ? user?.profileImage : '',
              name: acronym || 'N/A',
            });
            if (this.selectedUserId == user._id) {
              this.selectedUserName = user.firstName + ' ' + user.lastName;
            }
          }
          this.isUserListResponse = true;
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  getLoginUser = () => {
    this._userService.getUserDetails({}).subscribe(
      (response: ResponseModel) => {
        this.mainUserId = response?.data?._id;

        if (this.selectedUserId == '') {
          this.selectedUserId = response?.data?._id;
          this.selectedUserName =
            response?.data?.firstName + ' ' + response?.data?.lastName;
        }
        this.isSubUser = response?.data?.isSubUser;
        this.getUsers();
      },
      (err: ErrorModel) => {
        this.getUsers();
      }
    );
  };
  onScroll() {
    if (this.logs?.count == this.logs?.items?.length) {
      return;
    }
    this.currentActivityPage += 1;
    this.getActivityLog();
  }
  previewAttachment(log, fileIndex) {
    let re = /(?:\.([^.]+))?$/;

    this.dialogRef = this._dialog.open(MediaPreviewComponent, {
      width: '50vw',
      data: {
        file: { fileType: 'multiple', originalName: 'Attachments' },
        items: log?.attachments.map((x) => {
          // let fileType = 'png';
          let fileType = re.exec(x)[1];
          let obj = { src: x, fileType: fileType, title: x };
          return obj;
        }),
        index: fileIndex,
      },
    });
  }
  getIcon(fileName) {
    var re = /(?:\.([^.]+))?$/;
    var name = re.exec(fileName)[1];
    name = name.toLocaleLowerCase();
    if (name === 'doc' || name === 'docx') {
      return 'doc_icon.png';
    }

    if (name === 'pdf') {
      return 'pdf_icon.png';
    }

    if (name === 'mp4') {
      return 'mp4_icon.png';
    }

    if (name === 'mp3') {
      return 'mp3_icon.png';
    }

    if (name === 'gif') {
      return 'gif_icon.png';
    }

    if (name === 'png') {
      return 'png_icon.png';
    }

    if (name === 'xls' || name === 'xlsx') {
      return 'xls_icon.png';
    }

    if (name === 'jpeg' || name === 'jpg') {
      return 'jpeg_icon.png';
    }

    if (name === 'ico') {
      return 'ico_icon.png';
    }
    if (name === 'svg') {
      return 'svg_icon.png';
    }
    if (name === 'zip') {
      return 'zip_icon.png';
    }
    if (name === 'txt') {
      return 'txt_icon.png';
    }
    if (name === 'rar') {
      return 'rar_icon.png';
    }
    if (name === 'json') {
      return 'json_icon.png';
    }
    if (name === 'html') {
      return 'html_icon.png';
    }
    if (name === 'dwg') {
      return 'dwg_icon.png';
    }
    if (name === 'ai') {
      return 'ai_icon.png';
    }
    if (name === 'csv') {
      return 'csv_icon.png';
    }
    if (name === 'webp') {
      return 'webp_icon.png';
    }
    if (name) {
      return 'png_icon.png';
    }

    return 'other';
  }
  changeActivityType($event) {
    this.activityType = $event;
    this.currentActivityPage = 1;
    this.getActivityLog(true);
  }
  getDirection(createdBy) {
    if (createdBy == this.mainUserId) {
      return true;
    } else {
      return false;
    }
  }
  onComment(value) {
    this.comment = value; //.replace(/\r\n|\r|\n/g, '<br />');
  }

  onComment2(value) {
    this.comment2 = value; //.replace(/\r?\n/g, '<br />');
  }
  async onSubmit(isPublic?) {
    if (!this.comment || !this.comment2) {
      return;
    }
    this.comment = this.comment.trim();
    let body = {};
    body = {
      moduleId: this.data?.moduleId,
      subModuleId: this.data?.leadId,
      comment: this.comment,
      isTagged: this.comment?.includes('@'),
      tagUsers: await this.getTaggedUsers(),
    };
    if (this.data?.address) {
      body['address'] = this.data?.address;
    }
    if (this.data?.unitNo) {
      body['unitNo'] = this.data?.unitNo;
    }
    if (this.data?.title) {
      body['title'] = this.data?.title;
    }

    if (this.data?.mainStatus) {
      body['mainStatus'] = this.data?.mainStatus;
    }
    if (this.data?.phoneNumber) {
      body['phoneNumber'] = this.data?.phoneNumber;
    }
    this._loaderService.start();
    this._sharedService.addActivityLog(body, isPublic).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          let result = response.data;
          this.comment = '';
          this.comment2 = '';
          this.logs = [];
          this.currentActivityPage = 1;
          this.getActivityLog(true);
        }
      },
      (err: ErrorModel) => {
        this.comment = '';
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  async getTaggedUsers() {
    const mention = '@';
    const myArray = this.comment.replace(/\n/g, ' ').split(' ');

    let taggedUsers = [];
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].charAt(0) == mention) {
        this.userListing.filter((x) => {
          if (x.firstName === myArray[i].substr(1)) {
            taggedUsers.push(x?._id);
          }
        });
      }
    }
    const users = new Set(taggedUsers);
    return [...users];
  }
  tagUser($event) {
    return `@${$event.firstName}` + ` `;
  }
  getCallStatus(iscallConnected, callStatusId) {
    if (iscallConnected) {
      return this.callConnectedStatus.filter((x) => x?.value == callStatusId)[0]
        .name;
    } else {
      return this.callNotConnectedStatus.filter(
        (x) => x?.value == callStatusId
      )[0].name;
    }
  }
}
