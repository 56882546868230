import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { ColdCallComponent } from 'src/app/shared/dialog/cold-call/cold-call.component';

//service
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { DialerService } from 'src/app/providers/dialer/dialer.service';
import { RvmService } from './../../../providers/rvm/rvm.service';
import { LeadsService } from 'src/app/providers/leads/leads.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { SharedService } from 'src/app/shared/shared.service';
import { ListStackingService } from 'src/app/providers/list-stacking/list-stacking.service';

//utils
import { MessageConstant } from './../../../utils/message-constant';
import { environment } from 'src/environments/environment';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cold-call-setting',
  templateUrl: './cold-call-setting.component.html',
  styleUrls: ['./cold-call-setting.component.scss'],
})
export class ColdCallSettingComponent implements OnInit {
  messageConstant = MessageConstant;
  selectScript: string = '';
  selectVoiceMail: string = '';
  selectPhoneNumber: string = '';
  scriptPlaceHolder: boolean = true;
  voicemailPlaceHolder: boolean = true;
  dialerType: string = 'auto-dialer';
  abandonCall: string = '';
  scriptData: any[] = [];
  voiceMailData: any[] = [];
  phoneNumberData: any[] = [];
  groupNumberList: any[] = [];
  phoneUserList: any[] = [];
  settingColdCallData: any = [];
  dialogRef: any;
  selectedPhoneType: any = [];
  phoneStatusExclude: any[] = [];
  searchedPhoneStatus: any[] = [];
  allPhoneStatus: any[] = [];
  public noDataAvailable = 'No Data Available';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _sharedService: SharedService,
    private router: Router,
    private settingDialog: MatDialogRef<ColdCallSettingComponent>,
    public _utilities: CommonFunctionsService,
    private _dialerService: DialerService,
    private _rvmService: RvmService,
    private _leadsService: LeadsService,
    private dialog: MatDialog,
    private _listStackingService: ListStackingService
  ) {}

  ngOnInit(): void {
    this.getColdCallSettingData();
    this.getScripts();
    this.getDVMList();
    this.getGroupNumber();
    this.getStatus();
  }
  getColdCallSettingData() {
    this._dialerService.getColdCallList({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode == 200) {
          let callSettingData = response?.data;
          this.selectScript =
            callSettingData['scriptId'] != null
              ? callSettingData['scriptId']
              : '';
          this.selectVoiceMail =
            callSettingData['voiceMailId'] != null
              ? callSettingData['voiceMailId']
              : '';
          this.selectPhoneNumber = callSettingData['phoneNumberList']
            ? callSettingData['phoneNumberList']
            : '';
          this.dialerType = callSettingData['dialerType'];
          this.abandonCall = callSettingData['abandonCall'];
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }
  getScripts() {
    let obj = { page: 1, limit: 1000 };
    this._loaderService.start();
    this._dialerService.scriptList(obj).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();

        if (response?.statusCode == 200) {
          this.scriptData = response?.data?.items || [];
          this.scriptData.sort((a, b) => a.name.localeCompare(b.name));
          if (this.scriptData.length == 0) {
            this.scriptPlaceHolder = false;
          }
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }

  getGroupNumber() {
    const obj = {
      page: 1,
      limit: 20000,
    };
    this._sharedService.getGroupNumber(obj).subscribe(
      (response: ResponseModel) => {
        if (response && response.statusCode == 200) {
          this.getNumberList();
          let groupNameList = response?.data?.items || [];
          for (var j = 0; j < groupNameList.length; j++) {
            this.groupNumberList.push({
              label: groupNameList[j].title,
              value: groupNameList[j]._id,
            });
          }
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getNumberList() {
    const obj = {
      crmQueId: [],
      numTypeId: 3,
    };

    this._leadsService.getCampaignList(obj).subscribe(
      (response: ResponseModel) => {
        if (response && response.statusCode == 200) {
          let phoneUserListData = response?.data?.items || [];
          for (var i = 0; i < phoneUserListData.length; i++) {
            this.phoneUserList.push({
              label: this._utilities.maskNumber(phoneUserListData[i].number),
              value: phoneUserListData[i].number,
            });
          }
          this.phoneNumberData = this.groupNumberList.concat(
            this.phoneUserList
          );
          this.phoneNumberData.sort((a, b) => a.label.localeCompare(b.label));
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getDVMList() {
    let data = {
      page: 1,
      limit: 100,
      urlType: 'dialerVoiceMail',
    };

    this._rvmService.getRVM(data).subscribe(
      (response) => {
        if (response && response.statusCode == 200) {
          this.voiceMailData = response?.data?.items || [];
          this.voiceMailData.sort((a, b) => a.name.localeCompare(b.name));
          if (this.voiceMailData.length == 0) {
            this.voicemailPlaceHolder = false;
          }
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          // this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  saveColdCallSetting(obj) {
    this.settingColdCallData = [];
    this._dialerService.coldCallSettingDetails(obj).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode == 200) {
          this.settingColdCallData = response?.data;
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }

  goToColdCall() {
    if (this._utilities.isLSDialer) {
      if (this._utilities.dialerCurrentCampaignId == this.data?.campaignId) {
        this._sharedService.refreshDialerToggleDisplay.next(true);
        return false;
      } else {
        this._toastrService.warning(this.messageConstant.callUnderProgress);
        this._loaderService.stop();
        return false;
      }
    }
    this._toastrService.info('Please wait as we prepare your dialing session');
    this._utilities.isLSDialer = true;
    this._utilities.dialerCurrentCampaignId = this.data?.campaignId;
    const phoneNumberArray = [];
    const groupIdArray = [];
    this.groupNumberList.forEach((element) => {
      if (this.selectPhoneNumber.indexOf(element.value) != -1) {
        groupIdArray.push(element.value);
      }
    });
    this.phoneUserList.forEach((element) => {
      if (this.selectPhoneNumber.indexOf(element.value) != -1) {
        phoneNumberArray.push(element.value);
      }
    });
    let selectNumberArray = groupIdArray.concat(phoneNumberArray);
    let coldCallSettingObj = {
      scriptId: this.selectScript,
      voiceMailId: this.selectVoiceMail,
      phoneNumberList: selectNumberArray,
      dialerType: this.dialerType,
      abandonCall: this.abandonCall,
    };
    this.saveColdCallSetting(coldCallSettingObj);
    this.settingDialog.close();
    this.dialogRef = this.dialog.open(ColdCallComponent, {
      width: '1366px',
      maxWidth: '96vw',
      panelClass: 're-coldcol-custom',
      disableClose: true,
      closeOnNavigation: false,
      data: {
        dialed: this.data?.dialed,
        campaignId: this.data?.campaignId,
        campaignTodayDate: this.data.campaignTodayDate,
        inboxCallEnable: this.data.inboxCallEnable,
        inboxPropertyListId: this.data.inboxPropertyListId
          ? this.data.inboxPropertyListId
          : '',
        inboxCallPage: this.data.inboxCallPage,
        campaignScheduled: this.data.campaignScheduled,
        scheduledCallFilters: this.data.scheduledCallFilters,
        currentCampaignPage: this.data.currentCampaignPage,
        todayCallPage: this.data.todayCallPage,
        scheduledCallPage: this.data.scheduledCallPage,
        scriptId: this.selectScript,
        voiceMailId: this.selectVoiceMail,
        groupNumberId: groupIdArray,
        phoneNumberId: phoneNumberArray,
        dialerType: this.dialerType,
        abandonCall: this.abandonCall,
        voiceMailUrl: this.voiceMailData.filter(
          (x) => x._id === this.selectVoiceMail
        )[0]['url'],
        selectedPhoneType: this.selectedPhoneType,
        phoneStatusExclude: this.phoneStatusExclude
          .filter((e) => e.name != '')
          .map((x) => x.name),
        currentContact: this.data?.currentContact,
        currentPropertyId: this.data?.currentPropertyId,
        currentCampaignName: this.data?.currentCampaignName,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      this._utilities.isLSDialer = false;
      if (result?.isEdit) {
      }
    });
    // this.router.navigate([
    //   '/dialer/call/' +
    //     this.data?.campaignId +
    //     '/' +
    //     this.selectScript +
    //     '/' +
    //     this.selectVoiceMail,
    // ]);
  }
  phoneStatusDownClick(autocomplete: any) {
    autocomplete.handleDropdownClick(1);
  }
  clearListItem(autocomplete: any) {
    autocomplete.value = '';

    autocomplete.handleDropdownClick();
  }
  searchPhoneStatus(searchStr, mode) {
    let tempAr = [];
    this.phoneStatusExclude.filter((element) => {
      tempAr.push(element.name);
    });

    setTimeout(() => {
      this.searchedPhoneStatus = this.allPhoneStatus.filter((e) => {
        if (
          e.name.toUpperCase().includes(searchStr.toUpperCase()) &&
          tempAr.indexOf(e.name) == -1
        )
          return e;
      });
    }, 500);
  }
  getStatus() {
    this._listStackingService.getPhoneStatus().subscribe(
      (response) => {
        if (response.code == 200) {
          this.allPhoneStatus = response?.message?.phoneStatus || [];
          this.allPhoneStatus = this.allPhoneStatus.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
        }
      },
      (err) => {}
    );
  }
  selectMobileType(val) {
    if (this.selectedPhoneType.includes(val)) {
      this.selectedPhoneType.splice(this.selectedPhoneType.indexOf(val), 1);
      return;
    }
    this.selectedPhoneType.push(val);
  }
}
