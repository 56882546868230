// MODULES
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

// UTILS
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';

// SERVICES
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { AccountingService } from 'src/app/providers/accounting/accounting.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-modal-add-account-tags',
  templateUrl: './modal-add-account-tags.component.html',
  styleUrls: ['./modal-add-account-tags.component.scss'],
})
export class ModalAddAccountTagsComponent implements OnInit {
  messageConstant = MessageConstant;
  isEdit: boolean = false;
  _id: string;
  accountTagDataForm: FormGroup;
  submitButton: string;
  submitted: boolean = false;
  categoryData: any[] = [];
  subcategoryData: any[] = [];
  childCategoryData: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _accountingService: AccountingService,
    private _sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accountTagDailog: MatDialogRef<ModalAddAccountTagsComponent>
  ) {}

  ngOnInit(): void {
    if (this.data.action == 'edit') {
      this._id = this.data.id;
      this.isEdit = true;
      this.getAccountTagDetails(this._id);
    }

    this.submitButton = this.isEdit ? 'Update Account Tag' : 'Add Account Tag';
    this.accountTagDataForm = this.formBuilder.group({
      name: ['', Validators.required],
      categoryId: ['', Validators.required],
      subcategoryId: [''],
      childCategoryId: [''],
    });
    this.getAccountTagCategory();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.accountTagDataForm.controls[controlName].hasError(errorName);
  };

  cancel() {
    this.accountTagDailog.close();
  }

  onSubmit() {
    this.submitted = true;
    if (this.accountTagDataForm.invalid) {
      return;
    }

    let { name, categoryId, subcategoryId, childCategoryId } =
      this.accountTagDataForm.value;

    if (!categoryId && this.categoryData.length > 0) {
      this._toastrService.error('Please select account type', '');
      return;
    }

    if (!subcategoryId && this.subcategoryData.length > 0) {
      this._toastrService.error('Please select category', '');
      return;
    }
    if (!childCategoryId && this.childCategoryData.length > 0) {
      this._toastrService.error('Please select subcategory', '');
      return;
    }

    this._loaderService.start();
    let param = {
      name,
      categoryId,
      subcategoryId,
      childCategoryId,
    };

    if (param.subcategoryId == '') {
      delete param.subcategoryId;
    }
    if (param.childCategoryId == '') {
      delete param.childCategoryId;
    }

    if (this.isEdit) {
      param['accountTagId'] = this._id;
    }
    this._accountingService.addEditAccountTags(param, this.isEdit).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          this._loaderService.stop();
          this._toastrService.success(
            this.isEdit
              ? this.messageConstant.accountTagUpdatedSuccess
              : this.messageConstant.accountTagAddedSuccess
          );
          let result = response?.data;
          this.accountTagDailog.close(result);
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getAccountTagDetails(accountTagId) {
    let param = { accountTagId };

    this._loaderService.start();

    this._accountingService.getAccountTagDetails(param).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          var responseData = response?.data;
          if (responseData) {
            this.accountTagDataForm.patchValue({
              name: responseData.name,
              categoryId: responseData.categoryId,
              subcategoryId: responseData.subcategoryId,
              childCategoryId: responseData.childCategoryId,
            });

            if (responseData.categoryId) {
              if (responseData.categoryId != '') {
                this.getAccountTagSubcategory(responseData.categoryId);
              }
            }
            if (responseData.subcategoryId) {
              if (responseData.subcategoryId != '') {
                if (
                  responseData.subcategoryName.toLocaleLowerCase() ==
                  'marketing'
                ) {
                  this.getMarketingCategory();
                } else {
                  this.getAccountTagChildCategory(responseData.subcategoryId);
                }
              }
            }
          }
        } else {
          this._toastrService.error(response.message);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message);
        } else {
          this._toastrService.error(this.messageConstant.unknownError);
        }
      }
    );
  }

  getAccountTagCategory() {
    this._loaderService.start();

    this._accountingService.getAccountTagCategory({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode && response.statusCode == 200) {
          this.categoryData = response.data;
        } else {
          this._toastrService.error(response.message);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message);
        } else {
          this._toastrService.error(this.messageConstant.unknownError);
        }
      }
    );
  }

  getAccountTagSubcategory(categoryId) {
    if (!categoryId) {
      return;
    }
    if (categoryId == '5a6bcd996b3a635c14e54bba') {
      return;
    }
    this._loaderService.start();
    let param = { categoryId };
    this._accountingService.getAccountTagSubcategory(param).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode && response.statusCode == 200) {
          this.subcategoryData = response.data;
        } else {
          this._toastrService.error(response.message);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message);
        } else {
          this._toastrService.error(this.messageConstant.unknownError);
        }
      }
    );
  }

  getChildCategory(categoryId) {
    var categoryName = '';
    this.subcategoryData.filter((field) => {
      if (categoryId == field._id) {
        categoryName = field.name;
      }
    });
    if (categoryName.toLocaleLowerCase() == 'marketing') {
      this.getMarketingCategory();
    } else {
      this.getAccountTagChildCategory(categoryId);
    }
  }

  getAccountTagChildCategory(subcategoryId) {
    if (!subcategoryId) {
      return;
    }
    this._loaderService.start();
    let param = { subcategoryId };
    this._accountingService.getAccountTagChildCategory(param).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.childCategoryData = response?.data;
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getMarketingCategory() {
    this._loaderService.start();
    let param = { searchString: 'hear-about-us' };
    this._sharedService.getLeadSource(param).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.childCategoryData = response?.data?.items;
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
}
