<div class="re-message-box">
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        style="display: block !important"
        class="w-100 d-flex justify-content-between"
      >
        Speed to Lead
      </h2>
      <span matDialogClose mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          (click)="onClose()"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>
</div>
<mat-dialog-content class="re-typography">
  <div class="re-box re-sms-box">
    <div class="row">
      <div class="col-12 col-sm-12">
        <p class="radotext">NEW LEAD TRIGGERS</p>
        <p class="re-shedule-p">
          Select the type of leads that should trigger the Speed to Lead
        </p>
      </div>
    </div>
    <div class="re-switch">
      <div class="row">
        <div class="col-12 col-sm-12">
          <!--            <div class="form-group">-->
          <!--              <div class="radiosec">-->
          <!--                <p class="radoSecondtext">Leads created manually</p>-->
          <!--                <label-->
          <!--                  class="switch"-->
          <!--                  [ngClass]="{-->
          <!--                    'not-active': data.type == 'scheduledSMS'-->
          <!--                  }"-->
          <!--                >-->
          <!--                  <input-->
          <!--                    type="checkbox"-->
          <!--                    id="checkbox1"-->
          <!--                    (click)="onToggle()"-->
          <!--                    name="activeTimeChange"-->
          <!--                    formControlName="activeTimeChange"-->
          <!--                  />-->
          <!--                  <span class="slider"></span>-->
          <!--                  <span class="text1" value="false">No</span>-->
          <!--                  <span class="text2" value="true">Yes</span>-->
          <!--                </label>-->
          <!--              </div>-->
          <!--            </div>-->

          <!--            <div class="form-group">-->
          <!--              <div class="radiosec">-->
          <!--                <p class="radoSecondtext">Leads created from incoming Un-Answered calls</p>-->
          <!--                <label-->
          <!--                  class="switch"-->
          <!--                  [ngClass]="{-->
          <!--                    'not-active': data.type == 'scheduledSMS'-->
          <!--                  }"-->
          <!--                >-->
          <!--                  <input-->
          <!--                    type="checkbox"-->
          <!--                    id="checkbox2"-->
          <!--                    (click)="onToggle()"-->
          <!--                    name="activeTimeChange2"-->
          <!--                    formControlName="activeTimeChange"-->
          <!--                  />-->
          <!--                  <span class="slider"></span>-->
          <!--                  <span class="text1" value="false">No</span>-->
          <!--                  <span class="text2" value="true">Yes</span>-->
          <!--                </label>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="form-group">-->
          <!--              <div class="radiosec">-->
          <!--                <p class="radoSecondtext">Leads created from incoming Answered calls</p>-->
          <!--                <label-->
          <!--                  class="switch"-->
          <!--                  [ngClass]="{-->
          <!--                    'not-active': data.type == 'scheduledSMS'-->
          <!--                  }"-->
          <!--                >-->
          <!--                  <input-->
          <!--                    type="checkbox"-->
          <!--                    id="checkbox3"-->
          <!--                    (click)="onToggle()"-->
          <!--                    name="activeTimeChange2"-->
          <!--                    formControlName="activeTimeChange"-->
          <!--                  />-->
          <!--                  <span class="slider"></span>-->
          <!--                  <span class="text1" value="false">No</span>-->
          <!--                  <span class="text2" value="true">Yes</span>-->
          <!--                </label>-->
          <!--              </div>-->
          <!--            </div>-->
          <div class="form-group">
            <div class="radiosec">
              <p class="radoSecondtext">Leads created via REsimpli websites</p>
              <label
                class="switch"
                [ngClass]="{
                  'not-active': data.type == 'scheduledSMS'
                }"
              >
                <input
                  type="checkbox"
                  id="website"
                  (click)="onToggle('website')"
                  [checked]="this.instantLeadAssignment?.trigger['website']"
                  [(ngModel)]="this.instantLeadAssignment?.trigger['website']"
                  name="website"
                />
                <span class="slider"></span>
              </label>
            </div>
          </div>
          <div class="form-group">
            <div class="radiosec">
              <p class="radoSecondtext">
                Leads created from integration (Zapier, Carrot, etc.)
              </p>
              <label
                class="switch"
                [ngClass]="{
                  'not-active': data.type == 'scheduledSMS'
                }"
              >
                <input
                  type="checkbox"
                  id="integration"
                  (click)="onToggle('integration')"
                  [checked]="this.instantLeadAssignment?.trigger['integration']"
                  [(ngModel)]="
                    this.instantLeadAssignment?.trigger['integration']
                  "
                  name="integration"
                />
                <span class="slider"></span>
              </label>
            </div>
          </div>
          <div class="form-group">
            <div class="radiosec">
              <p class="radoSecondtext">Leads created via web-forms</p>
              <label
                class="switch"
                [ngClass]="{
                  'not-active': data.type == 'scheduledSMS'
                }"
              >
                <input
                  type="checkbox"
                  id="webForm"
                  (click)="onToggle('webForm')"
                  [checked]="this.instantLeadAssignment?.trigger['webForm']"
                  [(ngModel)]="this.instantLeadAssignment?.trigger['webForm']"
                  name="webForm"
                />
                <span class="slider"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="
        this.instantLeadAssignment?.trigger['website'] ||
        this.instantLeadAssignment?.trigger['webForm'] ||
        this.instantLeadAssignment?.trigger['integration']
      "
    >
      <div class="row mt-4 mb-3">
        <div class="col-12 col-sm-12">
          <p class="radotext">ROLES TO CALL</p>
          <p class="re-shedule-p">
            Please select roles you would like to call as new lead added
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4">
          <label class="cus_label">Roles in Campaign</label>
        </div>
        <div class="col-12 col-md-8">
          <label class="cus_label">Call Preference</label>
        </div>
      </div>
      <div class="">
        <div
          class="row mb-2"
          *ngFor="let role of userAssignmentArray; let i = index"
        >
          <div class="col-12 col-md-4">
            <select class="form-control">
              <option selected value="{{ role }}" disabled>
                {{ getRoleName(role) }}
              </option>
            </select>
          </div>
          <div class="col-12 col-md-8">
            <div class="radiolist">
              <!--            <label class="radiobtn form-check-inline" [for]="'lead-role-inapp'+i">-->
              <!--              <input-->
              <!--                class="form-check-input re-checkbox-size"-->
              <!--                type="radio"-->
              <!--                value="0"-->
              <!--                [id]="'lead-role-inapp'+i"-->
              <!--                [name]="'lead-role-inapp'+i"-->
              <!--                [(ngModel)]="instantLeadAssignment?.roles[i][role]"-->
              <!--                [checked]="instantLeadAssignment?.roles[i][role] === '0'"-->
              <!--                (change)="changeRoles(instantLeadAssignment, role, '0', i)"-->
              <!--              />-->
              <!--              <span class="checkmark"></span>In App Call-->
              <!--            </label>-->
              <label
                class="radiobtn form-check-inline"
                [for]="'lead-role-contact' + i"
              >
                <input
                  class="form-check-input re-checkbox-size"
                  type="radio"
                  value="1"
                  [id]="'lead-role-contact' + i"
                  [name]="'lead-role-contact' + i"
                  [(ngModel)]="instantLeadAssignment?.roles[i][role]"
                  [checked]="instantLeadAssignment?.roles[i][role] === '1'"
                  (change)="changeRoles(instantLeadAssignment, role, '1', i)"
                />
                <span class="checkmark"></span>Profile Number
              </label>
              <label
                class="radiobtn form-check-inline"
                [for]="'lead-role-donot' + i"
              >
                <input
                  class="form-check-input re-checkbox-size"
                  type="radio"
                  value="2"
                  [id]="'lead-role-donot' + i"
                  [name]="'lead-role-donot' + i"
                  [(ngModel)]="instantLeadAssignment?.roles[i][role]"
                  [checked]="
                    instantLeadAssignment?.roles[i][role] === '2' ||
                    instantLeadAssignment?.roles[i][role] === undefined
                  "
                  (change)="changeRoles(instantLeadAssignment, role, '2', i)"
                />
                <span class="checkmark"></span>Do Not Call
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center" style="padding-top: 15px !important">
  <button mat-stroked-button mat-dialog-close (click)="onClose()">
    Cancel
  </button>
  <button mat-flat-button color="primary" type="button" (click)="onDone()">
    Update Preference
  </button>
</mat-dialog-actions>
