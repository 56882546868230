// MODULES
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';

// SERVICES
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharedService } from '../../shared.service';

// UTILS
import { MessageConstant } from 'src/app/utils/message-constant';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { ToastrService } from 'ngx-toastr';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { timezoneConstant } from 'src/app/utils/timezone-list-constant';

@Component({
  selector: 'app-set-availability-dialog',
  templateUrl: './set-availability-dialog.component.html',
  styleUrls: ['./set-availability-dialog.component.scss'],
})
export class SetAvailabilityDialogComponent implements OnInit {
  availabilityForm: FormGroup;
  messageConstant = MessageConstant;
  submitted: boolean = false;

  timezoneList: any[] = [];
  availabilityTimezoneOffset: string = 'America/New_York';

  week: any[] = [
    {
      label: 'Monday',
      value: '0',
      isSetAvailability: true,
    },
    {
      label: 'Tuesday',
      value: '1',
      isSetAvailability: true,
    },
    {
      label: 'Wednesday',
      value: '2',
      isSetAvailability: true,
    },
    {
      label: 'Thursday',
      value: '3',
      isSetAvailability: true,
    },
    {
      label: 'Friday',
      value: '4',
      isSetAvailability: true,
    },
    {
      label: 'Saturday',
      value: '5',
      isSetAvailability: true,
    },
    {
      label: 'Sunday',
      value: '6',
      isSetAvailability: true,
    },
  ];

  constructor(
    private _dialogRef: MatDialogRef<SetAvailabilityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private _loaderService: NgxUiLoaderService,
    private _sharedService: SharedService,
    private _toastrService: ToastrService,
    private _utilities: CommonFunctionsService
  ) {}

  ngOnInit(): void {
    const newDate = new Date();

    this.availabilityForm = this._formBuilder.group({
      0: this._formBuilder.group({
        startTime: [this.setHours(newDate, '09:00am')],
        endTime: [this.setHours(newDate, '06:00pm')],
        isSetAvailability: true,
      }),
      1: this._formBuilder.group({
        startTime: [this.setHours(newDate, '09:00am')],
        endTime: [this.setHours(newDate, '06:00pm')],
        isSetAvailability: true,
      }),
      2: this._formBuilder.group({
        startTime: [this.setHours(newDate, '09:00am')],
        endTime: [this.setHours(newDate, '06:00pm')],
        isSetAvailability: true,
      }),
      3: this._formBuilder.group({
        startTime: [this.setHours(newDate, '09:00am')],
        endTime: [this.setHours(newDate, '06:00pm')],
        isSetAvailability: true,
      }),
      4: this._formBuilder.group({
        startTime: [this.setHours(newDate, '09:00am')],
        endTime: [this.setHours(newDate, '06:00pm')],
        isSetAvailability: true,
      }),
      5: this._formBuilder.group({
        startTime: [this.setHours(newDate, '09:00am')],
        endTime: [this.setHours(newDate, '06:00pm')],
        isSetAvailability: true,
      }),
      6: this._formBuilder.group({
        startTime: [this.setHours(newDate, '09:00am')],
        endTime: [this.setHours(newDate, '06:00pm')],
        isSetAvailability: true,
      }),
    });

    this.timezoneList = timezoneConstant.timezoneArray;
    this.getAvailability();
  }

  setHours(dt, h) {
    let s = /(\d+):(\d+)(.+)/.exec(h);
    dt.setHours(s[3] === 'pm' ? 12 + parseInt(s[1], 10) : parseInt(s[1], 10));
    dt.setMinutes(parseInt(s[2], 10));
    return new Date(dt);
  }

  getAvailability() {
    const user = this.data.users.filter(
      (x) => x?._id === this.data.currentUser?.userId
    );

    if (user?.length) {
      const availability = user[0]?.availability;
      this.availabilityTimezoneOffset =
        user[0]?.availabilityTimezoneOffset || 'America/New_York';
      Object.keys(availability).filter((day) => {
        const { startTime, endTime, isSetAvailability } = availability[day];
        const weekIndex = this.week.findIndex((x) => x.value === day);
        this.week[weekIndex].isSetAvailability = isSetAvailability;

        this.availabilityForm.controls[day]['controls'][
          'isSetAvailability'
        ].setValue(isSetAvailability);

        if (startTime) {
          const startingTime = moment(startTime)
            .tz(this.availabilityTimezoneOffset)
            .format('YYYY-MM-DDTHH:mm:ss');

          this.availabilityForm.controls[day]['controls']['startTime'].setValue(
            new Date(startingTime)
          );
          this.availabilityForm
            .get(day)
            ['controls']['startTime'].setValidators([Validators.required]);
        }

        if (endTime) {
          const endingTime = moment(endTime)
            .tz(this.availabilityTimezoneOffset)
            .format('YYYY-MM-DDTHH:mm:ss');

          this.availabilityForm.controls[day]['controls']['endTime'].setValue(
            new Date(endingTime)
          );
          this.availabilityForm
            .get(day)
            ['controls']['endTime'].setValidators([Validators.required]);
        }
      });
    }
  }

  toggleAvailability(day) {
    this.availabilityForm.controls[day?.value]['controls'][
      'isSetAvailability'
    ].setValue(day?.isSetAvailability);
  }

  public hasError = (day: string, controlName: string, errorName: string) => {
    return this.availabilityForm.controls[day]['controls'][
      controlName
    ].hasError(errorName);
  };

  changeDate(day, type) {
    this.availabilityForm
      .get(day)
      ['controls'][type].setValidators([Validators.required]);

    if (!this.availabilityForm.get(day)['controls'][type].value) {
      this.availabilityForm
        .get(day)
        ['controls'][type].setErrors({ required: true });
    } else {
      this.availabilityForm.get(day)['controls'][type].setErrors(null);
    }
  }

  getMinDate(day) {
    return this.availabilityForm.get(day).value.startTime;
  }

  onSubmit() {
    this.submitted = true;
    if (this.availabilityForm.invalid) {
      return;
    }

    let weeks = JSON.parse(JSON.stringify(this.availabilityForm.value));

    Object.keys(weeks).filter((day) => {
      const startingHours = moment(weeks[day].startTime)
        .format('HH:mm')
        .split(':');

      const endingHours = moment(weeks[day].endTime).format('HH:mm').split(':');

      weeks[day].startTime = weeks[day].startTime
        ? moment
            .tz(this.availabilityTimezoneOffset)
            .set({
              hours: parseInt(startingHours[0]),
              minutes: parseInt(startingHours[1]),
              seconds: 0,
            })
            .format('YYYY-MM-DDTHH:mm:ssZ')
        : '';

      weeks[day].endTime = weeks[day].endTime
        ? moment
            .tz(this.availabilityTimezoneOffset)
            .set({
              hours: parseInt(endingHours[0]),
              minutes: parseInt(endingHours[1]),
              seconds: 0,
            })
            .format('YYYY-MM-DDTHH:mm:ssZ')
        : '';
    });

    let obj = {
      isSetAvailability: true,
      availabilityTimezoneOffset: this.availabilityTimezoneOffset,
      availability: weeks,
    };

    this._loaderService.start();
    this._sharedService.setAvailability(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._toastrService.success(
            this.messageConstant.setAvailabilitySuccess
          );
          this._loaderService.stop();
          this._dialogRef.close(obj);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
}
