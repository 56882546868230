import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// SERVICES
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { BuyersService } from 'src/app/providers/buyers/buyers.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

// UTILS
import { MessageConstant } from 'src/app/utils/message-constant';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';
import { LeadsService } from 'src/app/providers/leads/leads.service';
import * as moment from 'moment';

@Component({
  selector: 'app-add-edit-interested',
  templateUrl: './add-edit-interested.component.html',
  styleUrls: ['./add-edit-interested.component.scss'],
})
export class AddEditInterestedComponent {
  addEditInterestedBuyer: FormGroup;
  messageConstant = MessageConstant;
  isSubmitted: boolean = false;
  buyerName: string;
  buyer: any;
  filteredBuyers: any[] = [];
  listingData: any;
  currentDate = new Date();
  listingOpenHouse: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    public _utils: CommonFunctionsService,
    private _buyerService: BuyersService,
    private _leadService: LeadsService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _dialog: MatDialogRef<AddEditInterestedComponent>
  ) {}

  ngOnInit() {
    this.addEditInterestedBuyer = this._fb.group({
      buyerId: ['', [Validators.required]],
      openHouseAttending: ['', [Validators.required]],
    });

    if (this.data.action === 'EDIT') {
      this.addEditInterestedBuyer.patchValue({
        buyerId: this.data?.item?.buyerId,
        openHouseAttending: this.data?.item?.openHouseAttending,
      });
      this.selectItem(this.data?.item);
    }

    this.getListingDetails();
  }

  getListingDetails() {
    let obj = {
      leadId: this.data.leadId,
    };
    this._loaderService.start();
    this._leadService.getListing(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode && response.statusCode == 200) {
          this._loaderService.stop();
          this.listingData = response?.data;
          this.listingOpenHouse = this.listingData?.openHouses?.filter(
            (item) => {
              const momentTime1 = moment(this.currentDate, 'HH:mm');
              const momentTime2 = moment(item?.startTime, 'HH:mm');

              const itemDate = moment(new Date(item.date), 'YYYY-MM-DD');
              const currentDate = moment(this.currentDate, 'YYYY-MM-DD');

              if (
                itemDate.isSame(currentDate, 'day') &&
                momentTime2.isAfter(momentTime1)
              ) {
                return item;
              }
              if (itemDate.isAfter(currentDate, 'day')) {
                return item;
              }
            }
          );
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          // this.toastr.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.addEditInterestedBuyer.controls[controlName].hasError(
      errorName
    );
  };

  checkBuyer($event, key) {
    if (!$event?.query) {
      return;
    }

    let obj = {
      [key]: $event?.query,
      patternMatch: true,
    };

    this._buyerService.checkBuyer(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.filteredBuyers = response.data;
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  selectItem(buyer) {
    this.buyer = buyer;

    setTimeout(() => {
      this.buyerName = buyer?.firstName;
      if (buyer?.lastName) {
        this.buyerName += ` ${buyer?.lastName}`;
      }

      this.buyerName = this._utils.capitalizeName(this.buyerName);
    });

    this.addEditInterestedBuyer.patchValue({
      buyerId: buyer?.buyerId || buyer._id,
    });

    return this.buyerName;
  }

  numberType(buyer) {
    if (!buyer?.contactNumber) {
      return;
    }

    const type = this._utils.numberType(buyer?.numberType?.toLowerCase());
    return `(${type || 'N/A'})`;
  }

  getTime(openHouse) {
    let startTime = this._utils.dueDateFormat(
      openHouse?.date,
      '',
      {
        h: openHouse?.startTime?.split(':')[0],
        m: openHouse?.startTime?.split(':')[1],
        s: 0,
      },
      '',
      true
    ).time;

    let endTime = this._utils.dueDateFormat(
      openHouse?.date,
      '',
      {
        h: openHouse?.endTime?.split(':')[0],
        m: openHouse?.endTime?.split(':')[1],
        s: 0,
      },
      '',
      true
    ).time;

    return `${startTime} - ${endTime}`;
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.addEditInterestedBuyer.invalid) return;

    let obj = {
      ...this.addEditInterestedBuyer.value,
      leadId: this.data?.leadId,
      isAdd: true,
    };

    if (this.data.action === 'EDIT') {
      obj = {
        ...obj,
        // _id: this.data?.item?._id,
      };
    }

    this._loaderService.start();
    this._buyerService.addInterested(obj).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode == 200) {
          this._dialog.close(true);
          if (this.data.action == 'EDIT') {
            this._toastrService.success(
              this.messageConstant.editInterestedBuyerSuccess
            );
          } else {
            this._toastrService.success(
              this.messageConstant.addInterestedBuyerSuccess
            );
          }
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
}
