import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// SERVICES
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { LeadsService } from 'src/app/providers/leads/leads.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';

// UTILS
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';
import { MessageConstant } from 'src/app/utils/message-constant';

@Component({
  selector: 'app-lead-call-status-dialog',
  templateUrl: './lead-call-status-dialog.component.html',
  styleUrls: ['./lead-call-status-dialog.component.scss'],
})
export class LeadCallStatusDialogComponent implements OnInit {
  callConnectedStatus: any;
  callNotConnectedStatus: any;
  callConnectReasonText: boolean = false;
  callNotConnectReasonText: boolean = false;
  callConnectReason: boolean = false;
  callNotConnectReason: boolean = false;
  callAnswerForm: FormGroup;
  moduleId: string = '5faa63fdfd20cd581703d255';
  messageConstant = MessageConstant;
  constructor(
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _fb: FormBuilder,
    public _utilities: CommonFunctionsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private callStatusDialog: MatDialogRef<LeadCallStatusDialogComponent>,
    private _leadService: LeadsService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.callAnswerForm = this._fb.group({
      callAnswer: ['', Validators.required],
      otherReason: [''],
    });
    this.callConnectedStatus = this._utilities.callConnectedStatus();
    this.callNotConnectedStatus = this._utilities.callNotConnectedStatus();
  }
  otherReason(status) {
    this.callConnectReasonText = false;
    this.callNotConnectReasonText = false;
    this.callConnectReason = status == 5 ? true : false;
    this.callNotConnectReason = status == 9 ? true : false;
  }
  oncallAnswerSubmit() {
    const { callAnswer, otherReason } = this.callAnswerForm.value;
    let myObj = {
      leadId: this.data?.leadId,
      statusId: callAnswer,
      isConnected:
        callAnswer == 1 ||
        callAnswer == 2 ||
        callAnswer == 3 ||
        callAnswer == 4 ||
        callAnswer == 5 ||
        callAnswer == 10
          ? true
          : false,
      otherReason: otherReason,
      callUuid: this.data?.callUUID,
      toNumber: this.data?.phoneNumber,
      fromNumber:
        typeof(this.data?.leadSource) === 'string'
          ? this.data?.leadSource
          : this.data?.leadSource[0]?.number,
      moduleId: this.moduleId,
    };
    this._loaderService.start();
    this._leadService.callAnswer(myObj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.callStatusDialog.close(true);
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
}
