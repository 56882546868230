<div class="">
  <form
    [formGroup]="teamsForm"
    (ngSubmit)="onSubmit()"
    autocomplete="off"
    novalidate
  >
    <div class="re-assign" *ngIf="leadMessageShow" role="alert">
      Lead Reassignment Completed
      <img
        src="assets/images/cancel-active.svg"
        (click)="closeMessage()"
        class="re-close-lead"
      />
    </div>
    <div class="form-group">
      <label class="labels">
        Assignment Type
        <a href="javascript:;" class="re-learn" (click)="learnMoreShow()">
          Learn more
        </a>
      </label>
      <div class="radiolist" id="radiobtnlist2" style="display: block">
        <label class="radiobtn">
          Round Robin
          <input
            type="radio"
            name="userType"
            formControlName="assignmentMethod"
            (change)="checkForUpgrade($event)"
            value="1"
          />
          <span class="checkmark"></span>
        </label>
        <label class="radiobtn">
          First to Claim
          <input
            type="radio"
            name="userType"
            formControlName="assignmentMethod"
            (change)="checkForUpgrade($event)"
            value="2"
          />
          <span class="checkmark"></span>
        </label>
        <label class="radiobtn">
          Manual Assignment
          <input
            type="radio"
            name="userType"
            formControlName="assignmentMethod"
            (change)="checkForUpgrade($event)"
            value="3"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>

    <hr />
    <div class="re-block" *ngIf="currentRoleCount === 0">
      There is no lead created for this campaign. Team will be displayed once
      lead is created with this campaign.
    </div>
    <div *ngFor="let item of rolesModifiedArray">
      <!-- *ngIf="getRoleTabData(item?.row).user > 0" -->
      <div class="tasks_cont">
        <div *ngIf="item?.roleName != 'undefined'">
          <div class="tasktitle">
            <a
              href="javascript:void(0)"
              (click)="toggleSection(item?.roleName)"
            >
              <span class="re-name-txt">{{ item?.displayName }}</span>
              <!-- <span class="re-assign-txt">
                {{ getRoleTabData(item?.row).user }} User •
                {{ getRoleTabData(item?.row).assigned }} Assigned •
                {{ getRoleTabData(item?.row).unassigned }} Unassigned
              </span> -->
              <span class="re-arrows" *ngIf="!isVisible(item?.roleName)">
                <i
                  class="fa fa-chevron-circle-up re-teals"
                  aria-hidden="true"
                ></i>
              </span>
              <span class="re-arrows" *ngIf="isVisible(item?.roleName)">
                <i
                  class="fa fa-chevron-circle-down re-blacks"
                  aria-hidden="true"
                ></i>
              </span>
            </a>
          </div>

          <div class="row pb-3" [class.collapse]="isVisible(item?.roleName)">
            <ng-container
              *ngFor="let data of item?.row | keyvalue; let userIndex = index"
            >
              <div
                [ngClass]="userIndex == 0 ? 'row col-12' : 'row col-12 pt-5'"
              >
                <div class="col-12 col-md-3">
                  <p class="re-txts1 re-unassigned" *ngIf="data.key == 'null'">
                    Unassigned
                  </p>
                  <p class="re-txts1" *ngIf="data.key != 'null'">
                    {{ getUserName(data?.key) }}
                  </p>
                  <p class="re-txts2">
                    {{ countAssignedLeads(data?.value) }} Leads Assigned
                  </p>
                  <!-- <p class="re-txts3">3 Statuses Selected</p> -->
                </div>

                <div class="col-md-5">
                  <div
                    class="mb-2"
                    *ngFor="
                      let status of data?.value;
                      let userStatusIndex = index
                    "
                  >
                    <div
                      class="checkboxlist"
                      id=""
                      style="display: block"
                      *ngIf="status?.count"
                    >
                      <label class="checkboxbtn">
                        <input
                          type="checkbox"
                          value="{{
                            item?.roleName +
                              '_' +
                              userIndex +
                              '_' +
                              userStatusIndex
                          }}"
                          (change)="
                            handleCheck(
                              data?.key,
                              status?.mainStatusId,
                              status?.count,
                              item?.roleName,
                              $event,
                              userIndex
                            )
                          "
                        />
                        {{ getMainStatus(status?.mainStatusId) }} 
                        <span class="re-assign-lead-count">
                        ({{
                          status?.count
                        }})
                      </span>
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  class="col-12 col-md-4 re-bord-left pt-2"
                  [class.re-btn-disable]="
                    chekboxBtn(
                      data?.key + '_' + item?.roleName + '_' + userIndex
                    )
                  "
                  *ngIf="data.key != 'null' && checkCount(data?.value)"
                >
                  <a
                    href="javascript:void(0);"
                    data-toggle="modal"
                    class="re-btn-reassign"
                    *ngIf="
                      isReassignAvailable(
                        data?.key,
                        item?.roleName + '_' + userIndex
                      )
                    "
                    (click)="
                      openReAssignPopUp(
                        data?.key,
                        item?.roleName + '_' + userIndex
                      )
                    "
                  >
                    Reassign
                  </a>

                  <a
                    href="javascript:void(0);"
                    data-toggle="modal"
                    class="re-btn-unassign"
                    (click)="
                      openUnAssignPopUp(
                        data?.key,
                        item?.roleName + '_' + userIndex
                      )
                    "
                  >
                    Unassign
                  </a>
                </div>

                <div
                  class="col-12 col-md-4 re-bord-left pt-2"
                  *ngIf="data.key == 'null'"
                >
                  <a
                    href="javascript:void(0);"
                    data-toggle="modal"
                    class="re-btn-reassign"
                    (click)="
                      openAssignTeamMemberPopUp(
                        data?.key,
                        item?.roleName + '_' + userIndex
                      )
                    "
                  >
                    Assign Team Member
                  </a>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
