import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrl } from '../../utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
@Injectable({
  providedIn: 'root',
})
export class SOWService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem('token');

  constructor(private _commonFunction: CommonFunctionsService) {}

  createSOW = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.sowUrl + 'create';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  checkSOWNamePresence = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.sowUrl + 'checksownamepresence';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getContractor = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.sowUrl + 'getcontractor';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getSOW = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.sowUrl + 'getsow';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getSOWDetail = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.sowUrl + 'getsowwithid';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteSOW = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.sowUrl + 'delete';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateSOW = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.sowUrl + 'update';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  generatePDF = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.sowUrl + 'generatepdf';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getShareSOW = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.sowUrl + 'getsharesow';
    return this._commonFunction.globalPostService(endpoint, data);
  };
}
