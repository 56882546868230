import {
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChildren,
  QueryList,
  ViewChild,
} from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatDialog } from '@angular/material/dialog';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MultiSelect } from 'primeng/multiselect';

// UTILS
import { FilterListModel } from 'src/app/utils/models/filter';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';
import { environment } from 'src/environments/environment';
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';
import { ListResponseModel } from 'src/app/utils/models/response';
import { ListErrorModel } from 'src/app/utils/models/error';

// SERVICES
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { BuyersService } from 'src/app/providers/buyers/buyers.service';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../shared.service';
import { ListStackingService } from 'src/app/providers/list-stacking/list-stacking.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TaggingService } from 'src/app/providers/tagging/tagging.service';
import { MarketsService } from 'src/app/providers/markets/markets.service';
import { UserService } from 'src/app/providers/user/user.service';
import { VendorsService } from 'src/app/providers/vendors/vendors.service';
import { AccountingService } from 'src/app/providers/accounting/accounting.service';
import { PreferenceService } from 'src/app/providers/preference/preference.service';

// COMPONENTS
import { SaveFilterTemplateDialogComponent } from './save-filter-template-dialog/save-filter-template-dialog.component';
import { DeleteDialogComponent } from '../dialog/delete-dialog/delete-dialog.component';

import * as moment from 'moment';
@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  @ViewChildren('chipInput') chipInput: QueryList<ElementRef<HTMLInputElement>>;
  @ViewChild('search') searchElement: ElementRef;
  @ViewChild('addFilter') addFilter: ElementRef;
  @ViewChild(MultiSelect) select: MultiSelect;

  @Input() moduleId: string = '';
  @Input() filters: FilterListModel[] = [];
  @Input() isFilter: boolean = false;
  @Input() appliedFilters: any = {};
  @Input() _applyFilter: any;
  @Input() tagType: string;
  @Input() isSaveFilter: boolean = true;

  templates: any[] = [];

  currentPage: number = 1;
  currentLimit: number = environment.pagination.pageLimit;

  dialogRef: any;

  selectable: boolean = true;
  removable: boolean = true;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  availableFilters: FilterListModel[] = [];
  appliedFilteredList: FilterListModel[] = [];
  suggestionList: any[] = [];

  selectedTemplateIndex: number = -1;
  noDataAvailable: string = 'No Data Available';
  searchFilter: string = '';
  searchTemplate: string = '';
  messageConstant = MessageConstant;

  cityControl = new FormControl();
  filteredCities: Observable<any[]>;
  allCitiesList: any[] = [];

  zipControl = new FormControl();
  filteredZip: Observable<any[]>;
  allZipList: any[] = [];

  allTagList: any[] = [];
  tagControl = new FormControl();
  filteredTags: Observable<any[]>;

  unavailableColorIndex: any[] = [];
  unavailableColorIndexResponse: any[] = [];
  colorCodes = MiscellaneousConstant.colorCodes;

  lists: any[] = [];
  filterGroup: any[] = [];
  leadModuleId: string = '5faa63fdfd20cd581703d255';
  groups: any;
  promises: any[];
  questionsResult: any[] = [];

  constructor(
    private _sharedServices: SharedService,
    private _buyersService: BuyersService,
    private _toastrService: ToastrService,
    private _dialog: MatDialog,
    private _loaderService: NgxUiLoaderService,
    private _taggingService: TaggingService,
    private _listStackingService: ListStackingService,
    private _commonFunctionsService: CommonFunctionsService,
    private _userService: UserService,
    private _marketServices: MarketsService,
    private _vendorsService: VendorsService,
    private _accountingService: AccountingService,
    private _preferenceService: PreferenceService
  ) {}

  ngOnInit(): void {
    let index;
    for (let i = 0; i < this.filters.length; i++) {
      this.filters[i]['show'] = 1;
      index = this.filterGroup.findIndex(
        (item) => item.name === this.filters[i]?.filterGroup
      );
      if (this.filters[i]?.filterGroup && index < 0)
        this.filterGroup.push({ name: this.filters[i]?.filterGroup, show: 1 });
    }
    this.availableFilters = Object.assign([], this.filters);
  }

  initialize() {
    if (!Object.keys(this.appliedFilters).length) {
      setTimeout(() => {
        this.addFilter?.nativeElement?.click();
      }, 50);
    }

    this.selectedTemplateIndex = -1;
    this.resetFilterSearch();
    this.parseFilter();
    this.getSavedTemplates();
  }

  async commonFunctionCalls(filter: FilterListModel) {
    let filterCopy = Object.assign({}, filter);
    if (filter.value === 'tags') {
      filterCopy.options = await this.getTags();
      this.getUsedColorIndex();
    } else if (filter.value === 'targetZip') {
      filterCopy.options = await this.getBuyerCitiesAndZips('zip');
    } else if (filter.value === 'targetCities') {
      filterCopy.options = await this.getBuyerCitiesAndZips('city');
    } else if (filter.value === 'targetAreas') {
      filterCopy.options = await this.getVendorTags();
    } else if (
      filter.value === 'listsInclude' ||
      filter.value === 'listsExclude'
    ) {
      filterCopy.options = await this.getListsTags('getLists');
      this.lists = filterCopy.options;
      this.modelChanged(filterCopy);
    } else if (
      filter.value === 'tagsInclude' ||
      filter.value === 'tagsExclude'
    ) {
      filterCopy.options = await this.getListsTags('getListsTags');
    } else if (filter.value === 'propertyCity') {
      filterCopy.options = await this.distinctPropData('Property_City');
    } else if (filter.value === 'propertyState') {
      filterCopy.options = await this.distinctPropData('Property_State');
    } else if (filter.value === 'propertyZipCode') {
      filterCopy.options = await this.distinctPropData('Property_Zip_Code');
    } else if (filter.value === 'propertyCountyName') {
      filterCopy.options = await this.distinctPropData('Property_County_Name');
    } else if (filter.value === 'mailingCity') {
      filterCopy.options = await this.distinctPropData('Mailing_City');
    } else if (filter.value === 'mailingState') {
      filterCopy.options = await this.distinctPropData('Mailing_State');
    } else if (filter.value === 'mailingZipCode') {
      filterCopy.options = await this.distinctPropData('Mailing_Zip_Code');
    } else if (filter.value === 'mailingCountyName') {
      filterCopy.options = await this.distinctPropData('Mailing_County_Name');
    } else if (filter.value === 'phoneStatus') {
      filterCopy.options = await this.distinctPropData('phoneStatus');
    } else if (filter.value === 'companyName') {
      filterCopy.options = await this.distinctPropData('companyName');
    } else if (filter.value === 'skipTracedSource') {
      filterCopy.options = await this.distinctPropData('skipTracedSource');
    } else if (
      filter.value === 'phoneStatusInclude' ||
      filter.value === 'phoneStatusExclude'
    ) {
      filterCopy.options = await this.getStatus();
    } else if (filter.value === 'roleStatus') {
      filterCopy.options = await this.getRoles();
    } else if (filter.value === 'marketStatus') {
      filterCopy.options = await this.getMarkets();
    } else if (filter.value === 'propertyHouseType') {
      filterCopy.options = await this.distinctPropData('Property_House_Type');
    } else if (filter.value === 'lead_property_status') {
      filterCopy.options = await this.getMainStatus();
    } else if (filter.value === 'vendorType') {
      filterCopy.options = await this.getVendorCategory();
    } else if (filter.value === 'buyerQuestions') {
      // BUYERS MODULE ID PASSING TO GETGROUPS FUNCTION
      filterCopy.options = await this.getGroups('6007bd05b84a567a12c3bca9');
    } else if (filter.value === 'vendorQuestions') {
      // VENDORS MODULE ID PASSING TO GETGROUPS FUNCTION
      filterCopy.options = await this.getGroups('6374c9378bc3a0ee1f84bae3');
    }
    return filterCopy;
  }

  updateOptions() {
    let repeatingMailingAddressIndex = this.appliedFilteredList.findIndex(
      (x) => x.value === 'repeatingMailingAddress'
    );
    let appliedOwnerTypeIndex = this.appliedFilteredList.findIndex(
      (x) => x.value === 'ownerType'
    );
    let appliedMailingAddressIndex = this.appliedFilteredList.findIndex(
      (x) => x.value === 'mailingAddress'
    );

    if (appliedOwnerTypeIndex > -1) {
      let ownerTypeOptionLength = this.appliedFilteredList[
        appliedOwnerTypeIndex
      ].options.length;
      if (repeatingMailingAddressIndex > -1) {
        if (ownerTypeOptionLength == 3)
          this.appliedFilteredList[appliedOwnerTypeIndex].options.splice(2, 1);
      } else {
        if (ownerTypeOptionLength == 2)
          this.appliedFilteredList[appliedOwnerTypeIndex].options.push({
            label: 'Unknown',
            value: 'Unknown',
          });
      }
    }
    if (appliedMailingAddressIndex > -1) {
      let mailingAddressoptionLength = this.appliedFilteredList[
        appliedMailingAddressIndex
      ].options.length;
      if (repeatingMailingAddressIndex > -1) {
        if (mailingAddressoptionLength == 4) {
          this.appliedFilteredList[
            appliedMailingAddressIndex
          ].selectedOption = this.appliedFilteredList[
            appliedMailingAddressIndex
          ].selectedOption
            ? this.appliedFilteredList[
                appliedMailingAddressIndex
              ].selectedOption.filter((e) => {
                return e != 'missing';
              })
            : [];
          this.appliedFilteredList[appliedMailingAddressIndex].options.splice(
            3,
            1
          );
        }
      } else {
        if (mailingAddressoptionLength == 3)
          this.appliedFilteredList[appliedMailingAddressIndex].options.push({
            label: 'Missing',
            value: 'missing',
          });
      }
    }
  }

  async selectFilter(filter: FilterListModel) {
    if (this.isDisabled(filter)) return;
    if (this.isActive(filter)) return;

    this.appliedFilteredList.push(await this.commonFunctionCalls(filter));
    let appliedFilteredListOrder = this.appliedFilteredList.map(
      (item, index) => {
        return { ...item, order: index };
      }
    );

    this.appliedFilteredList = appliedFilteredListOrder;
    this.updateOptions();
    this.resetFilterSearch();
  }

  searchFilterFunction(e) {
    this.filterGroup.map((e) => {
      e.show = 0;
    });

    const searchData = this.searchFilter?.toLowerCase();
    let filterLabel, index;
    for (let i = 0; i < this.filters.length; i++) {
      this.filters[i].show = 0;
      filterLabel = this.filters[i]?.label?.toLowerCase();
      if (filterLabel?.includes(searchData)) {
        this.filters[i].show = 1;
        index = this.filterGroup.findIndex(
          (x) => x.name === this.filters[i]?.filterGroup
        );
        if (index >= 0) this.filterGroup[index].show = 1;
      }
    }
  }

  resetFilterSearch() {
    this.searchFilter = '';
    this.filterGroup.map((e) => {
      e.show = 1;
    });
    this.filters.map((e) => {
      e.show = 1;
    });
  }

  async changeFilter($event, index: number) {
    let filter = this.filters.find((x) => x.value === $event.target.value);
    filter = await this.commonFunctionCalls(filter);
    if (filter) {
      this.appliedFilteredList[index] = filter;
    }
  }

  getTags() {
    return new Promise((resolve, reject) => {
      this._loaderService.start();

      this._taggingService.getTags(this.tagType).subscribe(
        (response) => {
          this._loaderService.stop();
          if (response && response.statusCode == 200) {
            let arr = response.data;
            const result = arr.reduce((acc, d) => {
              if (d.label) {
                const value = { _id: d._id, label: d.label, value: d._id };

                acc.push(value);
              }

              return acc;
            }, []);

            this.allTagList = result;

            this.filteredTags = this.tagControl.valueChanges.pipe(
              startWith(null),
              map((tag) =>
                tag ? this._filter(tag, 'tag') : this.allTagList.slice()
              )
            );

            resolve(result);
          }
        },
        (err: ErrorModel) => {
          reject([]);
          this._loaderService.stop();
        }
      );
    });
  }

  getUsedColorIndex() {
    this._loaderService.start();

    this._taggingService.getUsedTagsColor(this.tagType).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._loaderService.stop();
          this.unavailableColorIndex = response?.data;
          this.unavailableColorIndexResponse = [];
          let i, j;

          for (i = 0; i < this.colorCodes.length; i++) {
            this.unavailableColorIndexResponse.push({ _id: i, count: 0 });

            for (j = 0; j < this.unavailableColorIndex.length; j++) {
              if (this.unavailableColorIndex[j]._id == i) {
                this.unavailableColorIndexResponse[
                  i
                ].count = this.unavailableColorIndex[j].count;
                break;
              }
            }
          }

          this.unavailableColorIndexResponse.sort(
            (a, b) => parseFloat(a.count) - parseFloat(b.count)
          );
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
      }
    );
  }

  getBuyerCitiesAndZips(type) {
    return new Promise((resolve, reject) => {
      this._loaderService.start();
      let obj = {
        updateType: type === 'city' ? 1 : 2,
        filterData: {},
        isFilter: true,
        buyerIds: [],
      };

      this._buyersService.getTagList(obj).subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          if (response.statusCode == 200) {
            let arr = response.data.items;

            const result = arr.reduce((acc, d) => {
              if (d) {
                const value = { _id: d, label: d, value: d };

                acc.push(value);
              }

              return acc;
            }, []);

            if (type === 'zip') {
              this.allZipList = result;
              this.filteredZip = this.zipControl.valueChanges.pipe(
                startWith(null),
                map((tag) =>
                  tag ? this._filter(tag, 'zip') : this.allZipList.slice()
                )
              );
            }

            if (type === 'city') {
              this.allCitiesList = result;
              this.filteredCities = this.cityControl.valueChanges.pipe(
                startWith(null),
                map((tag) =>
                  tag ? this._filter(tag, 'city') : this.allCitiesList.slice()
                )
              );
            }
            resolve(result);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
        }
      );
    });
  }

  private _filter(value, type) {
    let filterValue;
    if (value._id) {
      filterValue = value.label?.toLowerCase();
    } else {
      filterValue = value.toLowerCase();
    }

    if (type === 'city') {
      return this.allCitiesList.filter(
        (tag) => tag.label.toLowerCase().indexOf(filterValue) === 0
      );
    } else if (type === 'zip') {
      return this.allZipList.filter(
        (tag) => tag.label.toLowerCase().indexOf(filterValue) === 0
      );
    } else if (type === 'tag') {
      return this.allTagList.filter(
        (tag) => tag.label.toLowerCase().indexOf(filterValue) === 0
      );
    }
  }

  async applyFilter() {
    let {
      filter,
      isValid,
    }: { filter: any; isValid: boolean } = this.calculateFilter(
      this.appliedFilteredList
    );

    if (isValid && Object.keys(filter).length) {
      if (filter.tags && filter?.tags?.optionsType === 'CHIPS') {
        const { value } = filter.tags;
        filter.tags.value = await this.executeTags(value);
        delete filter?.tags?.optionsType;
      }

      if (
        filter.targetCities &&
        filter?.targetCities?.optionsType === 'CHIPS'
      ) {
        filter.targetCities.value = filter.targetCities.value.map(
          (x) => x.label
        );
        delete filter?.targetCities?.optionsType;
      }

      if (filter.targetZip && filter?.targetZip?.optionsType === 'CHIPS') {
        filter.targetZip.value = filter.targetZip.value.map((x) => x.label);
        delete filter?.targetCities?.optionsType;
      }

      delete filter['optionType'];
      this._applyFilter.emit(filter);
    }
  }

  async executeTags(item) {
    let existingTags = item
      ?.filter((x) => x._id != 'custom')
      ?.reduce((acc, d) => {
        acc.push(d._id);
        return acc;
      }, []);

    let newTags = item
      ?.filter((x) => x._id === 'custom')
      ?.reduce((acc, d) => {
        acc.push(d.label);
        return acc;
      }, []);

    if (!newTags?.length) {
      return existingTags;
    }

    let tagsToSave = this.getTagsToSave(newTags);
    let obj = {
      tagsArr: tagsToSave,
      tagType: this.tagType,
    };

    return await this.saveTags(obj, existingTags);
  }

  getTagsToSave(tagsArr) {
    if (tagsArr.length) {
      let finalArr = [],
        colorIndex = 0;

      for (let i = 0; i < tagsArr.length; i++) {
        if (this.unavailableColorIndexResponse.length == colorIndex)
          colorIndex = 0;
        finalArr.push({
          tag: tagsArr[i],
          colorIndex: this.unavailableColorIndexResponse[colorIndex]?._id,
        });
        colorIndex++;
      }
      return finalArr;
    } else {
      return [];
    }
  }

  saveTags(obj, existingTags) {
    return new Promise((resolve, reject) => {
      this._loaderService.start();
      this._taggingService.addTags(obj).subscribe(
        (response) => {
          if (response) {
            let finalIds = existingTags;

            for (let i = 0; i < response?.data?.length; i++) {
              if (response?.data[i] != null)
                finalIds.push(response?.data[i]?._id);
            }

            this._loaderService.stop();
            resolve(finalIds);
          }
        },
        (err: ErrorModel) => {
          reject([]);
          this._loaderService.stop();
          this._toastrService.error(
            err.message ? err.message : MessageConstant.unknownError
          );
        }
      );
    });
  }

  async parseFilter() {
    let appliedFilteredListLength = this.appliedFilteredList.length;
    this.appliedFilteredList = [];
    Object.keys(this.appliedFilters).forEach(async (key) => {
      let filter = this.availableFilters.find((filter) => filter.value === key);

      if (filter) {
        const {
          value,
          operator,
          selectedCondition,
          order,
        } = this.appliedFilters[key];
        filter.order = order;

        let filterMultiTypes = [
          'tags',
          'targetAreas',
          'targetCities',
          'targetZip',
          'listsInclude',
          'listsExclude',
          'propertyCity',
          'propertyState',
          'propertyZipCode',
          'propertyCountyName',
          'mailingCity',
          'mailingState',
          'mailingZipCode',
          'mailingCountyName',
          'phoneStatus',
          'companyName',
          'tagsInclude',
          'tagsExclude',
          'repeatingMailingAddress',
          'phoneStatusInclude',
          'phoneStatusExclude',
          'roleStatus',
          'marketStatus',
          'propertyHouseType',
          'skipTracedSource',
          'lead_property_status',
          'vendorType',
        ];
        if (filterMultiTypes.includes(filter.value)) {
          filter = await this.commonFunctionCalls(filter);

          if (filter.optionsType === 'CHIPS') {
            value?.filter((x) => {
              this.addChip(filter, x);
            });
          } else {
            filter.selectedOption = value;
            filter.selectedOperator = operator;
            if (selectedCondition) filter.selectedCondition = selectedCondition;
          }
        } else {
          if (filter.value === 'emailCampaign') {
            filter.selectedOption = await this.getCampaigns(
              { campaignIds: value },
              'email'
            );
          } else if (filter.value === 'smsCampaign') {
            filter.selectedOption = await this.getCampaigns(
              { campaignIds: value },
              'sms'
            );
          } else {
            if (filter.optionsType === 'RANGE') {
              filter.minVal = value[0] || value[0] == 0 ? value[0] : '';
              filter.maxVal = value[1] || value[1] == 0 ? value[1] : '';
            } else if (filter.optionsType === 'DATE-RANGE') {
              let minDate = '',
                maxDate = '',
                tempDate;
              if (value[0]) {
                tempDate = value[0].split('-');
                minDate = tempDate[1] + '-' + tempDate[2] + '-' + tempDate[0];
              }
              if (value[1]) {
                tempDate = value[1].split('-');
                maxDate = tempDate[1] + '-' + tempDate[2] + '-' + tempDate[0];
              }
              filter.minVal = minDate ? new Date(minDate) : '';
              filter.maxVal = maxDate ? new Date(maxDate) : '';
            } else {
              filter.selectedOption = value;
            }
          }

          filter.selectedOperator = operator;
        }

        this.appliedFilteredList.push(filter);
        this.updateOptions();
        if (appliedFilteredListLength == this.appliedFilteredList.length) {
          this.appliedFilteredList = this._commonFunctionsService.orderItems(
            this.appliedFilteredList,
            'order'
          );
        }
      }
    });
  }

  calculateFilter(filter) {
    let obj = {};
    let isValid = true;
    filter.map((x) => {
      if (
        x.optionsType != 'RANGE' &&
        x.optionsType != 'DATE-RANGE' &&
        (!x.selectedOption || !x.selectedOption?.length)
      ) {
        x.error = true;
        isValid = false;
      } else if (
        x.optionsType === 'RANGE' &&
        this.validateMaxMinRange(x?.selectedOperator, x?.minVal, x?.maxVal)
      ) {
        x.rangeErrorMsg = this.validateMaxMinRange(
          x?.selectedOperator,
          x?.minVal,
          x?.maxVal
        );
        x.error = true;
        isValid = false;
      } else if (
        x.optionsType === 'DATE-RANGE' &&
        this.validateDateRange(x?.selectedOperator, x?.minVal, x?.maxVal)
      ) {
        x.rangeErrorMsg = this.validateDateRange(
          x?.selectedOperator,
          x?.minVal,
          x?.maxVal
        );
        x.error = true;
        isValid = false;
      } else {
        x.error = false;
      }
      let value = x.selectedOption;
      if (x.optionsType === 'AUTO-COMPLETE') {
        value = x.selectedOption.map((option) => option[x.valueKey]);
      }
      if (x.optionsType === 'RANGE') {
        value = [x?.minVal, x?.maxVal];
      }
      if (x.optionsType === 'DATE-RANGE') {
        let timezoneoffset = this._commonFunctionsService.dueDateFormat()
          .browserTimezone;
        let rangeOne = moment
          .utc(new Date(x?.minVal))
          .utcOffset(timezoneoffset)
          .format('YYYY-MM-DD');
        let rangeTwo =
          x?.maxVal && x?.selectedOperator == 'between'
            ? moment
                .utc(new Date(x?.maxVal))
                .utcOffset(timezoneoffset)
                .format('YYYY-MM-DD')
            : '';
        value = [rangeOne, rangeTwo];
      }
      let dateValue = null;
      if (this.moduleId == '' && x.optionsType === 'DATE-RANGE') {
        dateValue = [x?.minVal, x?.maxVal];
      }

      obj[x.value] = {
        value,
        operator: x.selectedOperator,
        optionsType: x.optionsType,
        order: x.order,
        dateValue,
      };
      if (x?.selectedCondition)
        obj[x.value]['selectedCondition'] = x?.selectedCondition;
      return x;
    });

    return { filter: obj, isValid };
  }

  validateMaxMinRange(selectedOperator, minVal, maxVal) {
    if (selectedOperator == 'is-between') {
      if ((!minVal && minVal != 0) || (!maxVal && maxVal != 0)) {
        return '*Please fill min and max value.';
      }
      if (minVal > maxVal) return "*Min value can't be more than max value.";
    } else {
      if (typeof minVal == 'undefined' || minVal == null)
        return '*Please fill value.';
      if (selectedOperator == 'is-less-than' && minVal == 0)
        return "*Value can't be 0";
    }
    return '';
  }

  validateDateRange(selectedOperator, minVal, maxVal) {
    if (selectedOperator != 'between') {
      if (!minVal) return '*Please select date.';
    } else {
      if (!minVal || !maxVal) return '*Please select both dates.';
    }
    return '';
  }

  removeFilter($event, index: number) {
    $event.stopPropagation();
    $event.preventDefault();
    let selectVal = this.appliedFilteredList[index].value;

    if (selectVal === 'listsInclude' || selectVal === 'listsExclude') {
      const key =
        selectVal === 'listsInclude' ? 'listsExclude' : 'listsInclude';

      const currentFilterIndex = this.appliedFilteredList.findIndex(
        (x) => x.value === key
      );

      if (currentFilterIndex > -1)
        this.appliedFilteredList[currentFilterIndex].options = this.lists;
    }

    this.appliedFilteredList.splice(index, 1);
    if (selectVal == 'repeatingMailingAddress') {
      this.updateOptions();
    }
  }

  isActive(filter: FilterListModel) {
    return !!(
      this.appliedFilteredList.findIndex((x) => x.value === filter.value) > -1
    );
  }

  removeChip(filter: FilterListModel, chipIndex) {
    if (chipIndex >= 0) {
      filter.selectedOption.splice(chipIndex, 1);
    }
  }

  addChip(filter: FilterListModel, event): void {
    const input = event?.input;
    const value = event.value || event;
    let key;
    let control;
    let field;

    if (filter.value === 'tags') {
      key = this.allTagList;
      control = this.tagControl;
      field = '_id';
    } else if (filter.value === 'targetZip') {
      key = this.allZipList;
      control = this.zipControl;

      if (isNaN(Number(value))) {
        this._toastrService.error(
          MessageConstant.numbersOnly.replace('[[FIELD]]', 'Target Zip')
        );
        return;
      }
    } else if (filter.value === 'targetCities') {
      key = this.allCitiesList;
      control = this.cityControl;
    }

    if (this.checkForUnique(value, filter.selectedOption, field)) {
      // let index = -1;
      let index = key.findIndex(
        (val) => val[field || 'label']?.toLowerCase() == value.toLowerCase()
      );

      if (index >= 0) {
        filter.selectedOption.push(key[index]);
      } else {
        if ((value || '').trim()) {
          filter.selectedOption.push({ _id: 'custom', label: value.trim() });
        }
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    control.setValue(null);
  }

  checkForUnique(val, data, field?) {
    let flag = true;
    for (let i = 0; i < data.length; i++) {
      if (data[i][field || 'label']?.toLowerCase() == val.toLowerCase()) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  reset() {
    this.filters.map((x) => {
      x.selectedOption = [];
      x.minVal = '';
      x.maxVal = '';
      return x;
    });
    this.selectedTemplateIndex = -1;
    this.appliedFilteredList = [];
  }

  async getSuggestionList(query, filter, isDefault?) {
    if (!isDefault && (!query || query == '')) {
      return;
    }

    let obj = {};

    if (isDefault) {
      obj['limit'] = environment.pagination.pageLimit;
    } else {
      obj['searchString'] = query;
    }
    this.modelChanged(filter);
    switch (filter.value) {
      case 'emailCampaign':
        await this.getCampaigns(obj, 'email');
        break;
      case 'smsCampaign':
        await this.getCampaigns(obj, 'sms');
        break;
    }
  }

  setMaxDate(filter) {
    if (filter?.selectedOperator == 'between') {
      const index = this.appliedFilteredList.findIndex(
        (x) => x.value === filter.value
      );
      if (index > -1) {
        this.appliedFilteredList[
          index
        ].minSelectDate = this.appliedFilteredList[index].minVal;
        if (
          this.appliedFilteredList[index].minVal >
          this.appliedFilteredList[index].maxVal
        ) {
          this.appliedFilteredList[index].maxVal = undefined;
        }
      }
    }
    this.modelChanged(filter);
  }

  getCampaigns(obj, type) {
    return new Promise((resolve, reject) => {
      obj = {
        ...obj,
        moduleId: this.moduleId,
      };
      this._buyersService.getCampaignsSuggestions(obj, type).subscribe(
        (response: ResponseModel) => {
          if (response?.statusCode == 200) {
            this.suggestionList = response.data?.items;
            resolve(response.data?.items);
          }
        },
        (err: ErrorModel) => {
          reject(false);
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
    });
  }

  getSavedTemplates() {
    if (this.moduleId == '') {
      return;
    }
    const obj = {
      moduleId: this.moduleId,
      page: this.currentPage,
      limit: 100,
    };

    this._sharedServices.getFilterTemplates(obj).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          this.templates = [...response.data?.items];
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  async selectTemplate(template, index) {
    try {
      let templateData = JSON.parse(template.filterData);
      this.appliedFilteredList = [];
      templateData.map(async (e) => {
        e = await this.commonFunctionCalls(e);
        this.appliedFilteredList.push(e);
        return e;
      });
      this.selectedTemplateIndex = index;
    } catch (error) {
      console.log('error: ', error);
    }
  }

  editFilterTemplate(template, index) {
    const { filter, isValid } = this.calculateFilter(
      JSON.parse(template.filterData)
    );
    if (!(isValid && Object.keys(filter).length)) return;

    this.dialogRef = this._dialog.open(SaveFilterTemplateDialogComponent, {
      width: '500px',
      data: {
        filterData: template.filterData,
        moduleId: this.moduleId,
        template,
      },
    });

    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) this.templates[index] = data;
    });
  }

  saveFilterTemplate() {
    const { filter, isValid } = this.calculateFilter(this.appliedFilteredList);
    if (!(isValid && Object.keys(filter).length)) return;

    this.dialogRef = this._dialog.open(SaveFilterTemplateDialogComponent, {
      width: '500px',
      data: {
        filterData: JSON.stringify(this.appliedFilteredList),
        moduleId: this.moduleId,
        template:
          this.selectedTemplateIndex === -1
            ? null
            : this.templates[this.selectedTemplateIndex],
      },
    });

    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (this.selectedTemplateIndex === -1) this.templates.push(data);
        else this.templates[this.selectedTemplateIndex] = data;
      }
    });
  }

  deleteFilterTemplate(template, index) {
    this.dialogRef = this._dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: {
        subModule: 'filter',
        moduleId: this.moduleId,
        details: template,
        index,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteFilterTemplateAPICall(template?._id, index);
      }
    });
  }

  deleteFilterTemplateAPICall(_id, index) {
    this._sharedServices
      .deleteFilterTemplate({ filterTemplateId: _id })
      .subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            if (index === this.selectedTemplateIndex)
              this.selectedTemplateIndex = -1;
            this.templates.splice(index, 1);
            this._toastrService.success(
              this.messageConstant.filterDeleteSuccess
            );
            this._loaderService.stop();
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
  }

  selected(event: MatAutocompleteSelectedEvent, filter: FilterListModel): void {
    if (this.checkForUnique(event.option.value.label, filter.selectedOption)) {
      filter.selectedOption.push(event.option.value);
      const list = this.chipInput?.toArray();
      if (list) {
        list.filter((input) => {
          input.nativeElement.value = '';
          input.nativeElement.blur();
        });
      }

      this.modelChanged(filter);
      if (filter.value === 'tags') {
        this.tagControl.setValue(null);
      } else if (filter.value === 'targetCities') {
        this.cityControl.setValue(null);
      } else if (filter.value === 'targetZip') {
        this.zipControl.setValue(null);
      }
    }
  }

  isVisible(filter: FilterListModel, item) {
    if (filter.value === 'targetCities') {
      const cityIndex = filter.selectedOption.findIndex(
        (x) => x.label === item.label
      );
      return cityIndex > -1 ? false : true;
    } else if (filter.value === 'targetZip') {
      const zipIndex = filter.selectedOption.findIndex(
        (x) => x.label === item.label
      );
      return zipIndex > -1 ? false : true;
    } else if (filter.value === 'tags') {
      const tagIndex = filter.selectedOption.findIndex(
        (x) => x.label === item.label
      );
      return tagIndex > -1 ? false : true;
    }
  }

  getFormControl(filter: FilterListModel) {
    if (filter.value === 'targetCities') {
      return this.cityControl;
    } else if (filter.value === 'targetZip') {
      return this.zipControl;
    } else if (filter.value === 'tags') {
      return this.tagControl;
    }
  }

  getFilteredList(filter: FilterListModel) {
    if (filter.value === 'targetCities') {
      return this.filteredCities;
    } else if (filter.value === 'targetZip') {
      return this.filteredZip;
    } else if (filter.value === 'tags') {
      return this.filteredTags;
    }
  }

  isDisabled(filter: FilterListModel) {
    if (filter.value === 'smsStats') {
      const index = this.appliedFilteredList.findIndex(
        (x) => x.value === 'smsCampaign'
      );
      if (
        index > -1 &&
        this.appliedFilteredList[index].selectedOption?.length === 1
      ) {
        return false;
      } else {
        filter.error =
          'This filter option will not be applied if you have more than one or no SMS Campaign selected.';
        return true;
      }
    }

    if (filter.value === 'emailStats') {
      const index = this.appliedFilteredList.findIndex(
        (x) => x.value === 'emailCampaign'
      );
      if (
        index > -1 &&
        this.appliedFilteredList[index].selectedOption?.length === 1
      ) {
        return false;
      } else {
        filter.error =
          'This filter option will not be applied if you have more than one or no Email Campaign selected.';
        return true;
      }
    }
    if (filter.value === 'litigator' || filter.value === 'dnc') {
      const index = this.appliedFilteredList.findIndex(
        (x) => x.value === 'skipTraced'
      );

      if (
        index > -1 &&
        this.appliedFilteredList[index].selectedOption === 'no'
      ) {
        filter.error =
          'You cannot apply "' +
          filter.label +
          '" filter when "Skip Traced" Equals No';
        return true;
      } else if (
        index > -1 &&
        this.appliedFilteredList[index].selectedOption === 'yes'
      ) {
        return false;
      }
    } else if (filter.value === 'mailCount') {
      const index = this.appliedFilteredList.findIndex(
        (x) => x.value === 'repeatingMailingAddress'
      );

      if (
        index > -1 &&
        this.appliedFilteredList[index].selectedOption === 'custom'
      ) {
        return false;
      } else if (index > -1) {
        filter.error =
          'You cannot apply "' +
          filter.label +
          '" filter when "Owners w/ Multiple Properties" Equals No';
        return true;
      }
    } else if (filter.value === 'mailType') {
      const index = this.appliedFilteredList.findIndex(
        (x) => x.value === 'directMailSent'
      );

      if (
        index > -1 &&
        this.appliedFilteredList[index].selectedOption === 'yes'
      ) {
        return false;
      } else if (index > -1) {
        filter.error =
          'You cannot apply "' +
          filter.label +
          '" filter when "Direct Mail Sent" Equals No';
        return true;
      }
    }
    return filter.disabled;
  }

  setSearchFocus() {
    setTimeout(() => {
      this.searchElement?.nativeElement?.focus();
    }, 50);
  }

  getListsTags(type) {
    return new Promise((resolve, reject) => {
      this._loaderService.start();

      this._listStackingService[type]({}).subscribe(
        (response: ListResponseModel) => {
          if (response.code == 200) {
            this._loaderService.stop();
            let arr = response?.data || [];
            let result = [];
            result = arr.map((e) => {
              return { _id: e._id, label: e.name, value: e._id };
            });

            resolve(result);
          }
        },
        (err: ListErrorModel) => {
          reject(false);
          this._loaderService.stop();
        }
      );
    });
  }

  getStatus() {
    return new Promise((resolve, reject) => {
      this._loaderService.start();
      this._listStackingService.getPhoneStatus().subscribe(
        (response) => {
          if (response.code == 200) {
            this._loaderService.stop();
            let arr = response?.message?.phoneStatus || [];
            arr = arr.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            let result = [];
            result = arr.map((e) => {
              return { _id: e.name, label: e.name, value: e.name };
            });

            resolve(result);
          }
        },
        (err) => {
          reject(false);
          this._loaderService.stop();
        }
      );
    });
  }

  distinctPropData(val) {
    return new Promise((resolve, reject) => {
      this._loaderService.start();

      this._listStackingService
        .distinctPropData({
          myfield: val,
        })
        .subscribe(
          (response: ListResponseModel) => {
            if (response.code == 200) {
              this._loaderService.stop();
              let arr = response?.data || [];
              const result = arr.reduce(function (res, val) {
                if (val) {
                  res.push({ _id: val, label: val, value: val });
                }
                return res;
              }, []);
              resolve(result);
            }
          },
          (err: ListErrorModel) => {
            reject(false);
            this._loaderService.stop();
          }
        );
    });
  }

  getMainStatus() {
    return new Promise((resolve, reject) => {
      this._loaderService.start();
      const obj = {
        page: 1,
        limit: this.currentLimit,
        moduleId: this.leadModuleId,
      };
      this._sharedServices.getMainStatus(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this._loaderService.stop();
            let arr = response?.data?.items || [];
            arr = arr.filter((e) => {
              return e?._id != '60221e37d8562ef0219c072a';
            });
            arr.push(
              { _id: '5feb3f94aa810a3849fa5515', title: 'Inventory' },
              { _id: '5feb4221aa810a3849fa551b', title: 'Sold' },
              { _id: '5feb4252aa810a3849fa551c', title: 'Rental' }
            );
            const result = arr.reduce((res, val) => {
              if (val) {
                res.push({
                  _id: val?._id,
                  label: this._commonFunctionsService.capitalizeName(
                    val?.labels?.title || val?.title
                  ),
                  value: val?._id,
                });
              }
              return res;
            }, []);
            resolve(result);
          }
        },
        (err: ErrorModel) => {
          reject(false);
          this._loaderService.stop();
        }
      );
    });
  }

  modelChanged(filter) {
    delete filter['error'];

    if (filter?.value !== 'listsInclude' && filter?.value !== 'listsExclude') {
      return;
    }

    let key;

    if (filter?.value === 'listsInclude' || filter?.value === 'listsExclude') {
      key = filter?.value === 'listsInclude' ? 'listsExclude' : 'listsInclude';
    }

    const currentFilterIndex = this.appliedFilteredList.findIndex(
      (x) => x.value === key
    );

    if (currentFilterIndex > -1) {
      this.appliedFilteredList[currentFilterIndex].options = this.lists.filter(
        (x) => !filter?.selectedOption?.includes(x?.value)
      );

      if (
        this.appliedFilteredList[currentFilterIndex]?.selectedOption?.length
      ) {
        filter.options = this.lists.filter(
          (x) =>
            !this.appliedFilteredList[
              currentFilterIndex
            ].selectedOption.includes(x?.value)
        );
      }
    }
  }

  hideOptionsPanel() {
    this.select.hide();
  }

  getRoles() {
    return new Promise((resolve, reject) => {
      this._loaderService.start();
      this._userService.getRoles({}).subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          let roleList = [];
          if (response.statusCode == 200) {
            let role = response?.data.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            role.filter((x) => {
              roleList.push({ label: x?.name, value: x?._id });
            });
          }
          resolve(roleList);
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          resolve([]);
        }
      );
    });
  }

  getMarkets() {
    const obj = {
      page: 1,
      limit: 1000,
    };
    this._loaderService.start();
    return new Promise((resolve, reject) => {
      this._marketServices.getMarkets(obj).subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          let marketList = [];
          if (response.statusCode == 200) {
            let markets = response.data.items;

            markets = markets.filter((x) => x.isEnabled === true);

            markets.filter((x) => {
              marketList.push({ label: x?.title, value: x?._id });
            });
          }
          resolve(marketList);
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          resolve([]);
        }
      );
    });
  }

  getVendorTags() {
    return new Promise((resolve, reject) => {
      this._loaderService.start();
      let obj = {
        updateType: 1,
        isFilter: true,
      };

      this._vendorsService.getTagList(obj).subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          if (response.statusCode == 200) {
            const arr = response.data.items;
            const result = arr.reduce((acc, d) => {
              if (d) {
                const value = { _id: d, label: d, value: d };

                acc.push(value);
              }

              return acc;
            }, []);

            return resolve(result);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
        }
      );
    });
  }

  getVendorCategory() {
    return new Promise((resolve, reject) => {
      this._accountingService.getVendorCategory({}).subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          if (response?.statusCode == 200) {
            let category = [];
            response?.data?.filter((x) => {
              category.push({ label: x.name, value: x._id });
            });
            return resolve(category);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
          return resolve([]);
        }
      );
    });
  }

  async getGroups(moduleId) {
    const promise = new Promise((resolve, reject) => {
      this._loaderService.start();
      const obj = {
        page: this.currentPage,
        limit: 100,
        moduleId: moduleId,
        isFromList: true,
      };
      this._preferenceService.getGroups(obj).subscribe(
        async (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this.groups = this._commonFunctionsService.orderItems(
              response.data.items,
              'order'
            );

            this.promises = [];
            this.groups.filter((x) =>
              this.promises.push(this.getQuestions([x?._id]))
            );

            const result = await Promise.all(this.promises);
            const flattenedResults = [].concat(...result);
            resolve(flattenedResults);
          } else {
            this._loaderService.stop();
            resolve([]);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
          reject(err);
        }
      );
    });
    return promise;
  }

  //GET BUYER QUESTIONS IN FILTER
  getQuestions(groupId) {
    const promise = new Promise((resolve, reject) => {
      this._loaderService.start();
      const obj = {
        mainGroupId: groupId,
        page: this.currentPage,
        limit: this.currentLimit,
      };

      this._preferenceService.getFields(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            let arr = response?.data?.items || [];

            this.questionsResult = arr.map((e) => {
              return { _id: e._id, label: e.questionTitle, value: e._id };
            });
            this._loaderService.stop();
            resolve(this.questionsResult);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          return reject(err);
        }
      );
    });

    return promise;
  }
}
