<div class="position-relative">
  <span
    style="width: 25px; height: 25px"
    [mat-dialog-close]="false"
    mat-dialog-close
    class="re-map-cancel"
  >
    <img src="assets/images/close-circle.svg" alt="close" width="28px" />
  </span>
  <mat-dialog-content
    class="mat-typography"
    style="max-height: 72vh !important"
  >
    <div id="re-street-view-container" *ngIf="data.address && data.responseAddress">
      <div id="re-street-view-pano"></div>
    </div>
    <div class="re-map-view" *ngIf="!data.address && !data.responseAddress">
      <span>
        Please fill the address to preview
        <br />
        the Google street view.
      </span>
    </div>
  </mat-dialog-content>
</div>
