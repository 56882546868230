import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { VersionConstant } from 'src/app/utils/version-constant';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class WebmailService {
  constructor(
    private http: HttpClient,
    private _commonFunction: CommonFunctionsService
  ) {}

  // baseUrl : 'http://new.resimpli.com:3001/api/v1/'
  public url = environment.apiUrl;
  public accountUrl = environment.accountApiUrl;
  public baseUrl = `${environment.baseUrl + VersionConstant.version}`;

  public webmailLogin(data: any): Observable<any> {
    return this.http.post(this.url, data);
  }

  public getSearchedLeads(data: any) {
    return this._commonFunction.globalPostService(
      `${this.baseUrl}/lead/get-searched`,
      data
    );
  }

  public createEmailTagsLead(data: any) {
    return this._commonFunction.globalPostService(
      `${this.baseUrl}lead/save-email-tags`,
      data
    );
  }

  public getEmailTags(id: any) {
    return this._commonFunction.globalGetService(
      `${this.baseUrl}lead/get-email-tags/${id}`
    );
  }

  public getEmailsByLeadId(id: any) {
    return this._commonFunction.globalGetService(
      `${this.baseUrl}lead/get-emails/${id}`
    );
  }

  public deleteEmailTags(data: any) {
    return this._commonFunction.globalPostService(
      `${this.baseUrl}lead/delete-email-tags`,
      data
    );
  }

  public userAlreadyLogin(data: any) {
    const url = `${this.baseUrl}lead/webmail/check-user-email`;
    return this._commonFunction.globalPostService(url, data);
  }

  public createWebmailUser(data: any) {
    const url = `${this.baseUrl}lead/webmail/create-login`;
    return this._commonFunction.globalPostService(url, data);
  }

  public getdata(data: any, token: any): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const options = { headers };
    return this._commonFunction.globalPostService(this.url, data, options);
  }

  public getAccountId(email: string): Observable<any> {
    httpOptions.headers.append('Access-Control-Allow-Origin', '*');
    httpOptions.headers.append(
      'Access-Control-Allow-Methods',
      'GET, POST, PUT, PATCH, DELETE'
    );
    httpOptions.headers.append(
      'Access-Control-Allow-Headers',
      'X-Requested-With,content-type'
    );
    const url = `${this.accountUrl}account/show?email=${email}`;
    return this._commonFunction.globalGetService(url, httpOptions);
  }

  public checkFromEmail() {
    const url = 'https://api.sendgrid.com/v3/marketing/senders';
    const token =
      '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';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    const options = { headers };
    return this._commonFunction.globalGetService(url, options);
  }
}
