<div class="re-tags">
  <div
    class="dropdown re-list-with-dropdown"
    *ngFor="let splitTag of showTags; let i = index"
    id="dropDownId_{{ tagDialer ? splitTag?._id + i : splitTag?._id }}"
  >
    <button
      class="btn-toggle droptoggle"
      type="button"
      [style.background-color]="
        colorCodes[splitTag?.colorIndex ? splitTag?.colorIndex : 0]
          ?.backgroundColor
      "
      [style.color]="
        colorCodes[splitTag?.colorIndex ? splitTag?.colorIndex : 0]?.color
      "
      [style.border-color]="
        colorCodes[splitTag?.colorIndex ? splitTag?.colorIndex : 0]?.borderColor
      "
      (click)="showTagDropDown(tagDialer ? splitTag?._id + i : splitTag?._id)"
    >
      {{ splitTag?.label }}
      <i class="fa fa-times-circle-o" (click)="removeTag(splitTag?._id)"></i>
      <span
        class="re-dot"
        [style.background-color]="
          colorCodes[splitTag?.colorIndex ? splitTag?.colorIndex : 0]
            ?.backgroundColor
        "
        >•••</span
      >
    </button>
    <div
      [ngClass]="
        (tagDialer ? splitTag?._id + i : splitTag?._id) == dropdownTagId
          ? 'dropdown-menu show'
          : 'dropdown-menu'
      "
    >
      <a
        class="re-link"
        href="javascript:void(0);"
        (click)="removeTag(splitTag?._id)"
      >
        Delete
      </a>
      <hr />
      <a
        class="re-link"
        href="javascript:void(0);"
        (click)="renameTag(splitTag?._id, splitTag?.label)"
      >
        Rename
      </a>
      <hr />
      <div class="dropdown-submenu">
        <a
          class="re-link btn-toggle"
          href="javascript:void(0);"
          (click)="
            showColorDropdown(tagDialer ? splitTag?._id + i : splitTag?._id)
          "
          >Change Color</a
        >
        <div
          [ngClass]="
            currentTag == (tagDialer ? splitTag?._id + i : splitTag?._id)
              ? 'dropdown-menu show'
              : 'dropdown-menu'
          "
        >
          <a
            [ngClass]="
              cIndex == splitTag?.colorIndex
                ? 're-color fa fa-check'
                : 're-color fa'
            "
            href="javascript:void(0);"
            (click)="changeTagColor(splitTag, cIndex)"
            *ngFor="let eachColor of colorCodes; let cIndex = index"
            [style.background-color]="eachColor?.color"
            [style.border-color]="eachColor?.borderColor"
          ></a>
        </div>
      </div>
    </div>
  </div>

  <a
    href="javascript:void(0)"
    *ngIf="splitTags?.length > 6"
    class="re-tags-list2"
    (click)="onViewAllTags()"
  >
    +{{ this.splitTags.length - 6 }} More
  </a>
  <a (click)="onAddTagOpen()" href="javascript:void(0);" class="re-tags-list3">
    +Add Tag
  </a>
</div>
