import { Component } from '@angular/core';

// UTILS
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

@Component({
  selector: 'app-dlc-status-dailog',
  templateUrl: './dlc-status-dailog.component.html',
  styleUrls: ['./dlc-status-dailog.component.scss'],
})
export class DlcStatusDailogComponent {
  constructor(public _utilities: CommonFunctionsService) {

  }

  getBrandClass() {
    if (this._utilities.dlcForm?.twilioProfileData?.brandStatus == 'APPROVED') {
      return 're-verified';
    }
    if (this._utilities.dlcForm?.twilioProfileData?.brandStatus == 'FAILED') {
      return 're-failed';
    }
    if (
      this._utilities.dlcForm?.twilioProfileData?.brandStatus == 'IN_PROGRESS'
    ) {
      return 're-inprocess';
    }
    return '';
  }

  getCampaignClass() {
    if (
      this._utilities.dlcForm?.twilioProfileData?.campaignStatus == 'VERIFIED'
    ) {
      return 're-verified';
    }
    if (
      this._utilities.dlcForm?.twilioProfileData?.campaignStatus == 'FAILED'
    ) {
      return 're-failed';
    }
    if (
      this._utilities.dlcForm?.twilioProfileData?.campaignStatus ==
      'IN_PROGRESS'
    ) {
      return 're-inprocess';
    }
    return '';
  }

  getBrandMessage() {
    if (this._utilities.dlcForm?.twilioProfileData?.brandStatus == 'APPROVED') {
      return 'Brand approved successfully.';
    }
    if (this._utilities.dlcForm?.twilioProfileData?.brandStatus == 'FAILED') {
      return 'Your company name and EIN number are not validated.';
    }

    return 'Your brand was not submitted because we need a working website and an associated email address.';
  }
}
