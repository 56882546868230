<div class="re-top-head">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      Add Phone Numbers
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<mat-dialog-content>
  <div class="re-addnumb-box">
    <div class="row">
      <div class="col-12 col-md-12 col-sm-12">
        <h4
          class="re-title col-12 text-center"
          *ngIf="allPhoneObject.length == 0"
        >
          No Phone Numbers to show
        </h4>
        <div class="w-100 d-inline-block">
          <div class="row">
            <ng-container
              *ngFor="let eachPhone of allPhoneObject; let i = index"
            >
              <!-- Phone Numbers -->
              <div class="col-12 col-md-3">
                <div class="form-group">
                  <label class="re-label"
                    >Phone {{ i + 1 }}<span class="re-req">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    mask="(000) 000-0000"
                    [(ngModel)]="eachPhone.phone"
                    placeholder="Phone {{ i + 1 }}"
                    (blur)="checkPhone(i)"
                  />
                </div>
              </div>
              <!-- Phone Number Type -->
              <div class="col-12 col-md-3">
                <div class="form-group">
                  <label class="re-label"
                    >Phone {{ i + 1 }} Type<span class="re-req">*</span></label
                  >
                  <select
                    class="form-control"
                    [(ngModel)]="eachPhone.phoneType"
                  >
                    <option value="">--Phone {{ i + 1 }} Type--</option>
                    <option>Mobile</option>
                    <option>Landline</option>
                    <option value="Voip">VoIP</option>
                    <option>Other</option>
                  </select>
                </div>
              </div>
              <!-- Phone Number status -->
              <div class="col-12 col-md-5">
                <div class="form-group">
                  <label class="re-label">Phone {{ i + 1 }} Status</label>
                  <p-autoComplete
                    #phoneStatus
                    (onFocus)="phoneStatusDownClick(phoneStatus)"
                    (onSelect)="phoneStatusDownClick(phoneStatus)"
                    (onUnselect)="phoneStatusDownClick(phoneStatus)"
                    (onKeyUp)="
                      $event.keyCode == 13 || $event.keyCode == 8
                        ? phoneStatusDownClick(phoneStatus)
                        : $event
                    "
                    (onClear)="clearListItem(phoneStatus)"
                    placeholder="Phone {{ i + 1 }} Status"
                    [(ngModel)]="eachPhone.phoneStatusVal"
                    field="name"
                    [suggestions]="searchedPhoneStatus"
                    (completeMethod)="searchPhoneStatus($event.query, i)"
                    (onUnselect)="emitRemoveData($event, i)"
                    emptyMessage="{{ noDataAvailable }}"
                    dataKey="name"
                    [dropdown]="true"
                    [multiple]="true"
                    appendTo="body"
                  >
                  </p-autoComplete>
                </div>
              </div>
              <div class="col-12 col-md-1">
                <div class="form-group text-center">
                  <label class="re-label re-mobile-hidden"></label>
                  <a class="pointer" (click)="removePhoneNumber(i)">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="row" *ngIf="allPhoneObject.length != 10">
          <div class="col-12 col-md-12 text-center">
            <button class="btn re-add-btn mt-2" (click)="addPhoneNumber()">
              + Add Phone Numbers
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="padding-top: 14px !important">
  <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
  <button cdkFocusInitial class="btn btn-teal" (click)="addKey()">
    Update Number
  </button>
</mat-dialog-actions>
