<div class="re-sendemail-modal">
  <div class="re-top-head">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between"
        style="display: block !important"
      >
        {{ data?.allTitle?.heading }}
      </h2>
      <span mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>

  <!-- ADD EDIT TEMPLATE -->
  <form
    *ngIf="data.template == 'add-edit'"
    [formGroup]="addEditEmailForm"
    (ngSubmit)="onSubmit(data?.template)"
    autocomplete="off"
    novalidate
  >
    <mat-dialog-content class="">
      <div class="container-fluid p-0">
        <!-- TEMPLATE NAME -->
        <div class="box">
          <div class="row">
            <div class="col-12 col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="labels">
                  Template Name<span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="templateName"
                  formControlName="templateName"
                  class="form-control"
                  placeholder="Name"
                  (blur)="onBlurMethod('templateName')"
                  maxlength="50"
                />
                <div class="text-danger re-error-text">
                  <span
                    *ngIf="submitted && hasError('templateName', 'required')"
                    ><span>
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Template Name"
                        )
                      }}
                    </span>
                  </span>
                  <span
                    *ngIf="
                      checkValidation['templateName'] &&
                      hasError('templateName', 'pattern')
                    "
                  >
                    {{ messageConstant.validFieldValue }} Template Name
                  </span>
                  <span *ngIf="hasError('templateName', 'maxlength')">
                    Template Name Accept Maximum 50 Letters
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <!-- SUBJECT -->
        <div class="box">
          <div class="row">
            <div class="col-12 col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="labels">
                  Subject<span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="subject"
                  formControlName="subject"
                  placeholder="Subject"
                  [mention]="items"
                  [mentionConfig]="{
                    triggerChar: '@',
                    maxItems: 100,
                    labelKey: 'name',
                    mentionSelect: onMentionSelect,
                    allowSpace: true
                  }"
                />
                <div class="text-danger re-error-text">
                  <span *ngIf="submitted && hasError('subject', 'required')">
                    {{
                      messageConstant.requiredField.replace("Field", "Subject")
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="box">
          <div class="row">
            <!-- FROM NAME -->
            <div class="col-12 col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="labels">
                  From Name<span class="text-danger">*</span>
                </label>
                <div>
                  <input
                    type="text"
                    class="form-control"
                    name="fromName"
                    formControlName="fromName"
                    placeholder="Name"
                  />
                  <div class="text-danger re-error-text">
                    <span *ngIf="submitted && hasError('fromName', 'required')"
                      ><span>
                        {{
                          messageConstant.requiredField.replace("Field", "Name")
                        }}
                      </span>
                    </span>
                    <span
                      *ngIf="
                        checkValidation['fromName'] &&
                        hasError('fromName', 'pattern')
                      "
                    >
                      {{ messageConstant.validFieldValue }} Name
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <!-- FROM EMAIL -->
            <div class="col-12 col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="labels">
                  From Email
                  <span class="text-danger">*</span></label
                >
                <input
                  type="email"
                  class="form-control"
                  name="fromEmail"
                  formControlName="fromEmail"
                  placeholder="Email"
                  (blur)="
                    onBlurMethod('fromEmail'); verifyEmail($event.target.value)
                  "
                />

                <div class="text-danger re-error-text">
                  <span *ngIf="submitted && hasError('fromEmail', 'required')"
                    ><span>
                      {{
                        messageConstant.requiredField.replace("Field", "Email")
                      }}
                    </span>
                  </span>

                  <span
                    *ngIf="
                      checkValidation['fromEmail'] &&
                      hasError('fromEmail', 'pattern')
                    "
                  >
                    {{ messageConstant.validFieldValue }} Email
                  </span>
                </div>

                <p
                  *ngIf="!isEmailValid && email.status === 'VALID'"
                  class="text-danger mt-1"
                  class="text-danger re-error-text"
                >
                  {{ emailInvalidMessage }}
                  <a
                    class="re-text-teal pointer"
                    (click)="
                      onVerifyEmailDialog(addEditEmailForm.value.fromEmail)
                    "
                    *ngIf="
                      !isEmailValid &&
                      emailInvalidMessage?.includes(
                        'Please verify email address.'
                      )
                    "
                  >
                    Click Here
                  </a>
                </p>
              </div>
            </div>

            <!-- ADD CC / BCC -->
            <div class="col-12 col-lg-4 col-md-4 col-sm-12 re-cc-bcc">
              <div class="ccbcdiv">
                <a
                  href="javascript:;"
                  class="ccbc"
                  id="ccbtn"
                  (click)="addRemoveCC(true)"
                >
                  Add CC
                </a>
                <a
                  href="javascript:;"
                  class="ccbc"
                  id="bccbtn"
                  (click)="addRemoveBCC(true)"
                >
                  Add BCC
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="box pt-0">
          <!-- CC -->
          <div *ngIf="showCC" class="row">
            <div class="col-12 col-lg-11 col-md-11 col-sm-10">
              <div class="form-group">
                <label class="labels">CC: (Separate by ,)</label>
                <input
                  type="email"
                  name="ccEmail"
                  formControlName="ccEmail"
                  class="form-control"
                  placeholder="CC Email"
                  (change)="onAllowUniqueCcEmail($event.target.value)"
                  [(ngModel)]="ccEmail"
                />
                <div class="text-danger re-error-text">
                  <span *ngIf="!ccChecker">
                    {{ messageConstant.validFieldValue }} CC Email
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-1 col-md-1 col-sm-2 mt-4">
              <a
                href="javascript:;"
                class="re-trashbtn"
                (click)="addRemoveCC(false)"
              >
                <img
                  class="re-delete-icon"
                  src="assets/images/delete-red.svg"
                  alt="delete"
                />
              </a>
            </div>
          </div>

          <!-- BCC -->
          <div *ngIf="showBCC" class="row">
            <div class="col-12 col-lg-11 col-md-11 col-sm-10">
              <div class="form-group">
                <label class="labels">BCC: (Separate by ,)</label>
                <input
                  type="email"
                  class="form-control"
                  name="bccEmail"
                  formControlName="bccEmail"
                  placeholder="BCC Email"
                  (change)="onAllowUniqueBccEmail($event.target.value)"
                  [(ngModel)]="bccEmail"
                />
                <div class="text-danger re-error-text">
                  <span *ngIf="!bccChecker">
                    {{ messageConstant.validFieldValue }} BCC Email
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-1 col-md-1 col-sm-2 mt-4">
              <a
                href="javascript:;"
                class="re-trashbtn"
                (click)="addRemoveBCC(false)"
              >
                <img
                  class="re-delete-icon"
                  src="assets/images/delete-red.svg"
                  alt="delete"
                />
              </a>
            </div>
            <div class="col-12 col-lg-12 col-md-12 col-sm-12" *ngIf="showCC">
              <p style="color: #009a44" class="mb-2 re-error-text">
                Each email address should be unique between cc, and bcc.
              </p>
            </div>
          </div>
        </div>

        <hr />

        <!-- MESSAGE -->
        <div class="box">
          <div class="row">
            <div class="col-12 col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="labels">
                  Message<span class="text-danger">*</span>
                </label>
                <quill-editor
                  #editor
                  name="message"
                  formControlName="message"
                  [styles]="editorStyle"
                  [modules]="{
                    toolbar: false,
                    mention: editorOptions.mention
                  }"
                  [placeholder]="editorOptions.placeholder"
                  appendTo="body"
                ></quill-editor>
                <div class="text-danger re-error-text">
                  <span *ngIf="submitted && hasError('message', 'required')">
                    {{
                      messageConstant.requiredField.replace("Field", "Message")
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <!-- ATTACHMENT -->
        <div class="box">
          <div class="row">
            <div class="col-12 col-lg-12 col-md-12 col-sm-12">
              <div class="attachfile">
                <label class="labels">Attachment</label>
                <div>
                  <ul class="attchlist re-list1" *ngIf="editAttachmentArray">
                    <li *ngFor="let file of editAttachmentArray; let i = index">
                      {{ file.name }}
                      <a
                        class="fa fa-times-circle"
                        (click)="removeAttachmentArray(file)"
                      ></a>
                    </li>
                  </ul>

                  <ul class="attchlist re-list2">
                    <li *ngFor="let file of duplicateImageArr; let i = index">
                      {{ file.name }}
                      <a
                        class="fa fa-times-circle"
                        (click)="removeImageArr(file)"
                      ></a>
                    </li>
                  </ul>

                  <div class="attachbtn" style="cursor: pointer">
                    <input
                      type="file"
                      name="attachmentArray"
                      formControlName="attachmentArray"
                      #uploader
                      [options]="options"
                      ngFileSelect
                      multiple
                      (uploadOutput)="onUploadLogo($event)"
                      [uploadInput]="uploadInput"
                    />
                    <img
                      src="assets/images/inventory-details/attachment-symbol.png"
                      alt="attachment"
                      style="cursor: pointer"
                    />
                    Add attachments
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <hr style="margin-bottom: 10px !important" />
    <mat-dialog-actions align="center">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button type="submit" mat-flat-button color="primary" cdkFocusInitial>
        {{ data?.allTitle?.buttonTitle }}
      </button>
    </mat-dialog-actions>
  </form>

  <!-- SAVE TEMPLATE -->
  <form
    *ngIf="data.template == 'sendToSave'"
    [formGroup]="templateForm"
    autocomplete="off"
    novalidate
  >
    <mat-dialog-content class="mat-typography">
      <div class="container-fluid p-0">
        <div class="box">
          <div class="row">
            <!-- TEMPLATE NAME -->
            <div class="col-12 col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="labels"
                  >Template Name<span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  name="template"
                  formControlName="template"
                  class="form-control"
                  placeholder="Template Name"
                />
                <div class="text-danger re-error-text">
                  <span
                    *ngIf="
                      submitted2 && hasTemplateError('template', 'required')
                    "
                    ><span>
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Template Name"
                        )
                      }}
                    </span>
                  </span>
                  <span *ngIf="hasTemplateError('template', 'pattern')">
                    {{ messageConstant.validFieldValue }} Template Name
                  </span>
                </div>
              </div>
            </div>

            <!-- USED FOR -->
            <div class="col-12 col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="labels"
                  >Template to be used for<span class="text-danger"
                    >*</span
                  ></label
                >
                <p-autoComplete
                  #autocomplete1
                  [(ngModel)]="selectedModuleId"
                  field="title"
                  name="selectedModuleId"
                  formControlName="selectModuleId"
                  [suggestions]="moduleData"
                  (completeMethod)="search($event.query)"
                  placeholder="Select Modules"
                  dataKey="_id"
                  [dropdown]="true"
                  [multiple]="true"
                  [forceSelection]="true"
                  unique="true"
                  required
                >
                </p-autoComplete>
                <div class="text-danger re-error-text">
                  <span
                    *ngIf="
                      submitted2 &&
                      hasTemplateError('selectModuleId', 'required')
                    "
                    ><span>
                      {{
                        messageConstant.requiredField.replace("Field", "Module")
                      }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <hr style="margin-bottom: 10px !important" />
    <mat-dialog-actions align="center">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button type="submit" mat-flat-button color="primary" cdkFocusInitial>
        {{ data?.allTitle?.buttonTitle }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
