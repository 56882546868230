import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrl } from '../../utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
@Injectable({
  providedIn: 'root',
})
export class DialerService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem('token');

  constructor(private _commonFunction: CommonFunctionsService) {}
  scriptList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerScriptUrl + 'list';

    return this._commonFunction.globalPostService(endpoint, data);
  };
  scriptSave = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerScriptUrl + 'save';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  scriptDelete = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerScriptUrl + 'remove';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  scriptUpdate = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerScriptUrl + 'edit';

    return this._commonFunction.globalPostService(endpoint, data);
  };
  coldCallSettingDetails = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerScriptUrl + 'coldCallSetting';

    return this._commonFunction.globalPostService(endpoint, data);
  };
  updateColdCallSetting = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dialerScriptUrl + 'coldCallSettingUpdate';

    return this._commonFunction.globalPostService(endpoint, data);
  };
  getColdCallList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerScriptUrl + 'coldCallList';

    return this._commonFunction.globalPostService(endpoint, data);
  };
  getTodayCallCount = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dialerCampaignUrl + 'todayCallCount';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getCampaigns = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerCampaignUrl + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  saveCampaigns = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerCampaignUrl + 'save';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  editCampaigns = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerCampaignUrl + 'edit';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  removeCampaigns = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerCampaignUrl + 'remove';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getCampaignDialedCount = (data): Observable<any> => {
    var endpoint;
    endpoint = this.baseUrl + BaseUrl.dialerCampaignUrl + 'campaignDialedList';

    return this._commonFunction.globalPostService(endpoint, data);
  };
  getCampaignPropertyDetails = (data): Observable<any> => {
    var endpoint;
    if (data.moduleName == "Today's Call") {
      endpoint = this.baseUrl + BaseUrl.dialerCampaignUrl + 'todayCallDetails';
    } else {
      endpoint =
        this.baseUrl + BaseUrl.dialerCampaignUrl + 'campaignPropertyDetails';
    }

    return this._commonFunction.globalPostService(endpoint, data);
  };
  getInboxDetails = (data): Observable<any> => {
    var endpoint;
    endpoint = this.baseUrl + BaseUrl.dialerCampaignUrl + 'inboxDetails';

    return this._commonFunction.globalPostService(endpoint, data);
  };
  inboxNewCallSave = (data): Observable<any> => {
    var endpoint;
    endpoint = this.baseUrl + BaseUrl.dialerCampaignUrl + 'inboxNewSave';

    return this._commonFunction.globalPostService(endpoint, data);
  };
  getinboxCallDetails = (data): Observable<any> => {
    var endpoint;
    endpoint = this.baseUrl + BaseUrl.dialerCampaignUrl + 'inboxCallDetails';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getScheduledCallDetails = (data): Observable<any> => {
    var endpoint;
    endpoint =
      this.baseUrl + BaseUrl.dialerCampaignUrl + 'scheduledCallDetails';

    return this._commonFunction.globalPostService(endpoint, data);
  };
  getDialerCallLogs = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerCallLogUrl + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getDialerCampaignList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerCallLogUrl + 'campaignList';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getScriptDetail = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerScriptUrl + 'scriptDetails';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  sendSms = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerCampaignUrl + 'sendSms';

    return this._commonFunction.globalPostService(endpoint, data);
  };
  getNotes = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerActivityUrl + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  addNotes = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerActivityUrl + 'save';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  holdCallFn = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerCampaignUrl + 'holdCall';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  unHoldCallFn = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerCampaignUrl + 'unHoldCall';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getCampaignUploadStatus = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerCampaignUrl + 'file-status';
    return this._commonFunction.globalGetService(endpoint);
  };
  getActivity = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dialerCampaignUrl + 'dialerActivityList';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  saveCallStatus = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerCampaignUrl + 'callStatus';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  editCallStatus = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dialerCampaignUrl + 'editCallStatus';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getCallLastStatus = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dialerCampaignUrl + 'getCallLastStatus';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  callAttempts = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerCampaignUrl + 'callAttempts';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getLastCallDate = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dialerCampaignUrl + 'getLastCallDate';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  allActivityList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.activityListUrl + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  addActivityList = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.activityListUrl + 'multipleActivity';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  changeNumber = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerCampaignUrl + 'changeNumber';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getTotalRecord = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerStatsUrl + 'totalRecord';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getTotalDialedCount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerStatsUrl + 'totalDialedCount';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getTotalDialedConnect = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dialerStatsUrl + 'totalDialedConnect';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getTotalContactLead = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerStatsUrl + 'totalContactLead';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getTotalLeadToDeals = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerStatsUrl + 'totalLeadToDeals';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getAbandonedCall = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dialerStatsUrl + 'dialerAbandonedCall';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getShortDurationCall = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dialerStatsUrl + 'shortDurationCall';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getDialerCallResults = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dialerStatsUrl + 'dialerCallResults';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getTotalTimeDialing = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerStatsUrl + 'totalTimeDialing';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getAvgCallDuration = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerStatsUrl + 'avgCallDuration';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getTimeOnCall = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerStatsUrl + 'timeOnCall';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getTotalCallCount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerStatsUrl + 'totalCallCount';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  dialerTimeCount = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dialerCampaignUrl + 'dialerTimeCount';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  callCountDay = (data): Observable<any> => {
    var endpoint;
    endpoint = this.baseUrl + BaseUrl.dialerCampaignUrl + 'callCountDay';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  dropVmFn = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dialerCampaignUrl + 'dropVm';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getFilterPropertyIds = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dialerCampaignUrl + 'getFilterPropertyIds';
    return this._commonFunction.globalPostService(endpoint, data);
  };
}
