import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { BaseUrl } from '../../utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  baseUrl = environment.baseUrl;
  redirectUrl = environment.appUrl + BaseUrl.confirmEmailRedirectLink;
  public token = localStorage.getItem('token');
  private userList: Observable<any>;
  constructor(private _commonFunction: CommonFunctionsService) {}

  /**
   * FUNCTION: GET USER DETAILS
   * @param {Object} data
   * @returns OBJECT OF USER DETAILS
   */
  getUserDetails = (data): Observable<any> => {
    // Restrict Multiple Calls For User Detail
    const endpoint = this.baseUrl + BaseUrl.user + 'get_user_detail';
    if (this.userList) {
      return this.userList;
    }
    let list = this._commonFunction.globalPostService(endpoint, data);

    //Executes the http request immediately
    this.userList = from(list);
    return this.userList;
  };

  getCoursePercentage = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'getCoursePercentage';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  editUserDetails = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'edit_user_details';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateUserProfileImage = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'updateUserProfileImage';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateCompanyLogoImage = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'updateCompanyLogoImage';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateNotificationPreferences = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.user + 'updateNotificationPreferences';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getSubUserList = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'getSubUserList';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getRoles = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.role + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  addEditSubUser = (data, isEdit): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.user + (isEdit ? 'editSubUser' : 'addSubUser');
    return this._commonFunction.globalPostService(
      endpoint,
      data,
      this.getRequestHeaders()
    );
  };

  getSubUserDetail = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'getSubUserDetail';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteSubUser = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'deleteSubUser';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  changePassword = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'changePassword';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  addEditMainUser = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'editMainUser';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  setPassword = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'setPassword';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  editRole = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'role/update';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getSubUserChargeAmount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'getSubUserChargeAmount';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getUsers = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'getUsers';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getMarketChargeAmount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'getMarketChargeAmount';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteUserRole = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'deleteUserRole';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  saveUserForDirectMail = (fieldData): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'saveUserForDirectMail';
    return this._commonFunction.globalPostService(endpoint, fieldData, {
      observe: 'response' as 'body',
    });
  };

  verifyEmail = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'verifyEmail';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  sendPassword = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'sendPassword';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getUserPlan = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.plans + 'getUserPlan';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getDialerChargeAmount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'getDialerChargeAmount';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  dialerCharge = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'dialerCharge';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getUserAccessData = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'getUserAccessData';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  addUserExperience = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'addUserExperience';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  sendOtp = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'sendOtp';
    return this._commonFunction.globalPostService(endpoint, data);
  };
  getUserExperience = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'getUserExperience';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getWPToken = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'getWPToken';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  /**
   * Function: GET LIST OF MARKET
   * @param {Object} data
   * @returns ARRAY OF MARKET
   */
  getMarkets = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.marketV2Url + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  /**
   * Function: GET CAMPAIGNS WITH NUMBER
   * @param {Object} data
   * @returns ARRAY OF CAMPAIGNS
   */
  getCampaigns = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.marketV2Url + 'listWithNumber';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  /**
   * Function: ADD A SUB USER
   * @param {Object} data
   * @returns IF SUCCESS ADDED USER OBJECT ELSE ERROR
   */
  addUser = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.userV2Url + 'addSubUser';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  /**
   * Function: ADD A MARKET
   * @param {Object} data
   * @returns IF SUCCESS ADDED MARKET OBJECT ELSE ERROR
   */
  saveMarkets = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.marketV2Url + 'save';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  /**
   * Function: EDIT A MARKET
   * @param {Object} data
   * @returns IF SUCCESS EDITED MARKET OBJECT ELSE ERROR
   */
  editMarkets = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.marketV2Url + 'edit';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  /**
   * Function: EDIT A USER
   * @param {Object} data
   * @returns IF SUCCESS EDITED USER OBJECT ELSE ERROR
   */
  editUser = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.userV2Url + 'editSubUser';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  /**
   * Function: ADD EDIT USER ROLE ACCESS
   * @param {Object} data
   * @returns IF SUCCESS USER ROLE OBJECT ELSE ERROR
   */
  addRoleAccess = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.userV2Url + 'add-role-access';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  /**
   * Function: REMOVE USER ROLE ACCESS
   * @param {Object} data
   * @returns IF SUCCESS OR ERROR MESSAGE
   */
  removeUserRoleAccess = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.userV2Url + 'remove-role-access';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  /**
   * Function: REMOVE USER
   * @param {Object} data
   * @returns IF SUCCESS OR ERROR MESSAGE
   */
  removeUser = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.userV2Url + 'remove';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  /**
   * Function: SAVE UPGRADE ACTION
   * @param {Object} data
   * @returns IF SUCCESS SAVE UPGRADE ACTION ELSE ERROR
   */
  saveUpgradeAction = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.userV2Url + 'saveUpgradeAction';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getUserProgress = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.userV2Url + 'getUserProgress';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getUserProgressListStacking = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.userV2Url + 'getUserProgressListStacking';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  toggleVacationMode = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.userV2Url + 'vacation-mode-on-off';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateNewSettingStatus = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'updateNewSettingStatus';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  saveDialogAction = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.userV2Url + 'saveDialogAction';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  protected getRequestHeaders(): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    let headers;
    headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Redirect-Url': this.redirectUrl,
    });
    return { headers: headers };
  }

  updateOnBoardingCallStatus = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'updateOnBoardingCallStatus';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getAiCallSummaryChargeAmount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'getAiCallSummaryChargeAmount';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  aiCallSummaryCharge = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.user + 'aiCallSummaryCharge';
    return this._commonFunction.globalPostService(endpoint, data);
  };
}
