<div class="re-sendbulk-dialog" #body>
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between"
        style="display: block !important"
      >
        Bulk Email
      </h2>
      <span (click)="cancel()" style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>

  <!-- MAIL -->
  <form
    [formGroup]="emailForm"
    (ngSubmit)="onSubmit()"
    autocomplete="off"
    novalidate
    *ngIf="formStep === 1"
  >
    <mat-dialog-content class="re-typography">
      <div class="container p-0" id="email-form-container">
        <div class="re-box">
          <div class="row mt-3">
            <div class="col-12 col-md-12 col-sm-12">
              <ul class="re-steplist">
                <li class="re-active"><div class="re-num">1</div></li>
                <li><div class="re-num">2</div></li>
                <li><div class="re-num">3</div></li>
              </ul>
              <h4 class="re-single-title">SENDERS DETAIL</h4>
            </div>
            <!-- FROM NAME -->
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="label required">From Name</label>
                <input
                  formControlName="fromName"
                  type="text"
                  class="form-control"
                  placeholder="Name"
                />

                <div class="text-danger">
                  <span *ngIf="submitted && hasError('fromName', 'required')">
                    <span>
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "From Name"
                        )
                      }}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <!-- FROM EMAIL -->
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="label required">From Email</label>
                <input
                  formControlName="fromEmail"
                  type="text"
                  class="form-control"
                  placeholder="Email address"
                  (blur)="checkEmail()"
                />

                <div class="text-danger">
                  <span *ngIf="submitted && hasError('fromEmail', 'required')">
                    <span>
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "From Email"
                        )
                      }}
                    </span>
                  </span>
                </div>

                <div
                  class="text-danger mt-1"
                  *ngIf="hasError('fromEmail', 'pattern')"
                >
                  <span>{{ messageConstant?.invalidEmail }}</span>
                </div>

                <div
                  *ngIf="!isEmailValid && email.status === 'VALID'"
                  class="text-danger mt-1"
                >
                  {{ emailInvalidMessage }}
                  <a
                    class="re-text-teal pointer"
                    (click)="onVerifyEmailDialog(emailForm.value.fromEmail)"
                    *ngIf="
                      !isEmailValid &&
                      emailInvalidMessage?.includes(
                        'Please verify email address.'
                      )
                    "
                  >
                    Click Here
                  </a>
                </div>
              </div>
            </div>

            <!-- SUBJECT -->
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="label required">Subject</label>
                <input
                  formControlName="subject"
                  type="text"
                  class="form-control"
                  placeholder="Use @ to better customize your email subject."
                  [mention]="items"
                  [mentionConfig]="{
                    triggerChar: '@',
                    maxItems: 100,
                    labelKey: 'name',
                    allowSpace: true
                  }"
                />

                <div class="text-danger">
                  <span *ngIf="submitted && hasError('subject', 'required')">
                    <span>
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Subject"
                        )
                      }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-stroked-button (click)="cancel()">Cancel</button>
      <button mat-flat-button color="primary" cdkFocusInitial type="submit">
        Next
      </button>
    </mat-dialog-actions>
  </form>

  <!-- CAMPAIGN NAME -->
  <form
    [formGroup]="emailForm"
    (ngSubmit)="onSubmit()"
    autocomplete="off"
    novalidate
    *ngIf="formStep === 2"
  >
    <mat-dialog-content class="re-typography">
      <div class="container p-0">
        <div class="re-box">
          <div class="row mt-3">
            <div class="col-12 col-md-12 col-sm-12">
              <ul class="re-steplist">
                <li class="re-active"><div class="re-num">1</div></li>
                <li class="re-active"><div class="re-num">2</div></li>
                <li><div class="re-num">3</div></li>
              </ul>
              <h4 class="re-single-title">CAMPAIGN NAME</h4>
            </div>
            <!-- CAMPAIGN NAME -->
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="label required">Campaign name</label>
                <input
                  formControlName="campaignName"
                  type="text"
                  class="form-control"
                  placeholder="Campaign Name"
                />

                <div class="text-danger">
                  <span
                    *ngIf="submitted && hasError('campaignName', 'required')"
                  >
                    <span>
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Campaign Name"
                        )
                      }}
                    </span>
                  </span>
                  <span
                    *ngIf="
                      submitted &&
                      !hasError('campaignName', 'required') &&
                      isCampaignExists
                    "
                  >
                    <span> {{ messageConstant.campaignExists }} </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-stroked-button (click)="goBack(1)">Back</button>
      <button mat-flat-button color="primary" cdkFocusInitial type="submit">
        Next
      </button>
    </mat-dialog-actions>
  </form>

  <!-- CHOOSE TEMPLATE -->
  <ng-container *ngIf="formStep === 3">
    <mat-dialog-content class="re-typography">
      <div class="container p-0">
        <div class="re-box">
          <div class="row mt-3">
            <div class="col-12 col-md-12 col-sm-12">
              <ul class="re-steplist">
                <li class="re-active"><div class="re-num">1</div></li>
                <li class="re-active"><div class="re-num">2</div></li>
                <li class="re-active"><div class="re-num">3</div></li>
              </ul>
              <h4 class="re-single-title">CHOOSE FROM EXISTING TEMPLATES</h4>
            </div>
            <div class="col-12 col-md-12 col-sm-12">
              <div
                class="re-template"
                (click)="selectPreviousCampaign()"
                [class.re-template-active]="isPreviousTemplateActive"
                *ngIf="data?.previousCampaign?.htmlContent"
              >
                Select previously sent template
              </div>

              <ng-container
                *ngFor="let template of templates; let templateIndex = index"
              >
                <div
                  class="re-template"
                  [class.re-template-active]="
                    selectedTemplateIndex === templateIndex
                  "
                  (click)="selectTemplate(template, templateIndex)"
                >
                  {{ template?.label }}
                </div>
              </ng-container>

              <ng-container *ngIf="!templates?.length">
                <p class="re-template re-no-template text-muted text-center">
                  No Template Found.
                </p>
              </ng-container>

              <div class="re-or-text">OR</div>
              <div class="text-center">
                <p class="re-custom-template-text" (click)="createTemplate()">
                  Create your own template
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-stroked-button (click)="goBack(2)">Back</button>
      <button
        mat-flat-button
        color="primary"
        cdkFocusInitial
        type="button"
        (click)="onSubmit()"
        [disabled]="selectedTemplateIndex === -1"
      >
        Next
      </button>
    </mat-dialog-actions>
  </ng-container>

  <!-- CREATE TEMPLATE -->
  <ng-container *ngIf="formStep === 4">
    <mat-dialog-content class="re-typography">
      <div class="container p-0">
        <div class="re-box">
          <div class="row mt-3" *ngIf="isPreviousTemplateActive">
            <div
              id="editor-container"
              [innerHtml]="
                this.sanitizer.bypassSecurityTrustHtml(
                  data?.previousCampaign?.htmlContent
                )
              "
              #editorContainer
            ></div>
          </div>

          <div class="row mt-3" *ngIf="!isPreviousTemplateActive">
            <div id="editor-container" #editorContainer></div>
            <!-- <email-editor
              #emailEditor
              [options]="options"
              (loaded)="editorLoaded($event)"
            ></email-editor> -->
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-stroked-button (click)="goBack(3)">Back</button>
      <button
        mat-stroked-button
        cdkFocusInitial
        type="button"
        (click)="onTest()"
        class="re-outline-teal"
        tooltipPosition="top"
        pTooltip="Send a test email to yourself"
      >
        Send Test Email
      </button>
      <button
        mat-flat-button
        color="primary"
        cdkFocusInitial
        type="button"
        (click)="onSubmit()"
        [disabled]="isSendEmail"
        class="re-send-email"
      >
        Send
      </button>
      <button
        mat-flat-button
        color="primary"
        cdkFocusInitial
        type="button"
        (click)="onSave()"
        [disabled]="isSendEmail"
        class="re-send-email"
      >
        Save and Send
      </button>
      <!-- <div class="btn-group dropup">
        <button
          mat-flat-button
          color="primary"
          cdkFocusInitial
          type="button"
          (click)="onSubmit()"
          [disabled]="isSendEmail"
          class="re-send-email"
        >
          Send
        </button>
        <button
          mat-flat-button
          color="primary"
          cdkFocusInitial
          type="button"
          class="dropdown-toggle dropdown-toggle-split"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          *ngIf="!isPreviousTemplateActive"
        ></button>
        <div class="dropdown-menu">
          <a
            class="dropdown-item"
            (click)="onSave()"
            [class.disabled]="isSendEmail"
          >
            Save and Send
          </a>
        </div>
      </div> -->
    </mat-dialog-actions>
  </ng-container>
</div>
