import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import jwt_decode from 'jwt-decode';

// UTILS
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

// SERVICES
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { SharedService } from 'src/app/shared/shared.service';

// COMPONENTS
import { NotificationComponent } from 'src/app/shared/notification/notification.component';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class SocketIoService {
  socket: Socket;
  message$: Subject<any> = new Subject<any>();
  dialogRefs: MatDialogRef<NotificationComponent>;

  audio: HTMLAudioElement;

  timeoutId: any;
  isHovered: boolean = false;

  constructor(
    private _commonService: CommonFunctionsService,
    private _dialog: MatDialog,
    private _sharedService: SharedService,
    private _userService: UserService
  ) {
    this.message$.subscribe((data) => {
      this.notify();

      if (this.dialogRefs) this.dialogRefs.close();
      this.dialogRefs = this._dialog.open(NotificationComponent, {
        width: '430px',
        panelClass: 're-notification-dialog',
        data,
      });

      this.timeoutId = setTimeout(() => {
        if (!this.isHovered) {
          this.dialogRefs.close();
        }
      }, 5000); // Close after 5 seconds

      // Listen for mouseenter and mouseleave events to toggle the isHovered flag
      this.dialogRefs.afterOpened().subscribe(() => {
        this.dialogRefs._containerInstance?.[
          '_elementRef'
        ]?.nativeElement?.addEventListener('mouseenter', () => {
          this.isHovered = true;
          clearTimeout(this.timeoutId); // Clear the timeout
        });

        this.dialogRefs._containerInstance?.[
          '_elementRef'
        ]?.nativeElement?.addEventListener('mouseleave', () => {
          this.isHovered = false;
          this.timeoutId = setTimeout(() => {
            this.dialogRefs.close();
          }, 5000); // Restart the timeout
        });
      });

      this._sharedService.refreshActivityLog.next(true);
      this._sharedService.refreshNotificationCount.next(true);
    });
  }

  setupSocketConnection() {
    try {
      const decoded: any = jwt_decode(localStorage.getItem('token'));

      this.socket = io(environment.socketUrl);

      this.socket.on('connect', () => {
        this._commonService.isSocketConnected = true;
      });

      this.socket.on('disconnect', () => {
        this._commonService.isSocketConnected = false;
      });

      this.socket.on(decoded?.userId, (data) => {
        this.message$.next(data);
      });
    } catch (e) {
      console.log('Socket Err', e);
    }
  }

  disconnect() {
    this.socket.disconnect();
  }

  async notify() {
    const details = await this._userService.getUserDetails({}).toPromise();
    if (!details?.data?.notificationPreference?.isNotificationSound) return;

    const audio = new Audio();
    audio.src =
      'https://resimpli.s3.us-west-2.amazonaws.com/audio/iphone_sms.mp3';
    audio.load();
    audio.play();
  }
}
