import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

// SERVICES
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranscriptionModule } from '../../../providers/transcription/transcription.module';
import { ToastrService } from 'ngx-toastr';

// UTILS
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';
@Component({
  selector: 'app-call-transcript-dialog',
  templateUrl: './call-transcript-dialog.component.html',
  styleUrls: ['./call-transcript-dialog.component.scss'],
})
export class CallTranscriptDialogComponent implements OnInit {
  isActiveCallSummery: boolean = true;

  summary: any;
  speaker: any;
  transcription: any;
  propertyDetails: any;
  reasonOfSelling: any;
  timelineOfSelling: any;
  conditionOfTheProperty: any;
  priceExpectations: any;
  objections: any;
  handleTheObjections: any;
  takeaway: any;

  transcriptTimeline: any;
  timeOnRecording: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dailogRef: MatDialogRef<CallTranscriptDialogComponent>,
    private _loaderService: NgxUiLoaderService,
    private dialog: MatDialog,
    private _toastrService: ToastrService,
    private _transcription: TranscriptionModule
  ) {}

  ngOnInit(): void {
    this.getTranscript();
  }

  onTabChange(type: boolean) {
    this.isActiveCallSummery = type;
  }

  getTranscript() {
    let obj = {
      callUuid: this.data?.callUuid,
      audioUrl: this.data?.recording,
      activityId: this.data?.activityId,
      leadId: this.data?.leadId,
    };
    this._loaderService.start();
    this._transcription.getTranscript(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._loaderService.stop();
          let data = response?.data;
          if (data?.isPending) {
            this._toastrService.success(
              'The file is too large. Processing is underway. Please check back in 5 to 10 minutes for the transcription.'
            );
            this.dailogRef.close();
            return;
          }

          localStorage.setItem('aiCallTranscipt', this.data?.activityId);
          this.transcriptTimeline = data?.timeLine;
          this.transcription = data?.transcription;

          this.summary = data?.summary
            ? data?.summary
            : this.transcription
            ? this.transcription
            : 'Summary unavailable; topic not discussed in detail during the call.';
          this.speaker = data?.speaker;

          this.propertyDetails = data?.propertyDetails
            ? data?.propertyDetails
            : 'Summary unavailable; topic not discussed in detail during the call.';
          this.reasonOfSelling = data?.reasonOfSelling
            ? data?.reasonOfSelling
            : 'Summary unavailable; topic not discussed in detail during the call.';
          this.timelineOfSelling = data?.timelineOfSelling
            ? data?.timelineOfSelling
            : 'Summary unavailable; topic not discussed in detail during the call.';
          this.conditionOfTheProperty = data?.conditionOfTheProperty
            ? data?.conditionOfTheProperty
            : 'Summary unavailable; topic not discussed in detail during the call.';
          this.priceExpectations = data?.priceExpectations
            ? data?.priceExpectations
            : 'Summary unavailable; topic not discussed in detail during the call.';
          this.objections = data?.objections
            ? data?.objections
            : 'Summary unavailable; topic not discussed in detail during the call.';
          this.handleTheObjections = data?.handleTheObjections
            ? data?.handleTheObjections
            : 'Summary unavailable; topic not discussed in detail during the call.';
          this.takeaway =
            data?.takeaway.length > 0
              ? data?.takeaway
              : [
                  'Summary unavailable; topic not discussed in detail during the call.',
                ];
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        this.dailogRef.close();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }

  secondFormat(value: any) {
    let valueInSecond = value * 100;

    const minutes: number = Math.floor(valueInSecond / 60);
    const seconds: number = Math.floor(valueInSecond % 60);
    const formattedSeconds: string =
      seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${formattedSeconds}`;
  }

  formatPer(percentage) {
    return percentage?.toString()?.replace('%', '');
  }

  setTimeOnRecording(time) {
    this.timeOnRecording = Math.round(time * 100);
  }
}
