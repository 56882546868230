<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      style="display: block !important"
      class="w-100 d-flex justify-content-between"
    >
      Remove Market Details
    </h2>
    <span matDialogClose mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<mat-dialog-content class="re-typography">
  <div class="container">
    <div
      *ngIf="
        !this.tagsData[0]?.length &&
        !this.tagsData[1]?.length &&
        !this.tagsData[2]?.length &&
        !this.tagsData[3]?.length &&
        this.blankMsg == ''
      "
      class="re-loader-td"
    >
      <img class="re-loader-media" src="./assets/images/loading.gif" />
    </div>
    <div
      *ngIf="
        !this.tagsData[0]?.length &&
        !this.tagsData[1]?.length &&
        !this.tagsData[2]?.length &&
        !this.tagsData[3]?.length &&
        this.blankMsg != ''
      "
      class="re-loader-td"
    >
      {{ blankMsg }}
    </div>
    <div class="row">
      <div
        class="col-12 col-md-12 re-market-group"
        *ngFor="let eachMarkets of tagsData; let i = index"
      >
        <ng-container *ngIf="eachMarkets.length > 0">
          <label class="re-labels" for="inputCampaign4">{{
            getMarketName(i)
          }}</label>
          <div class="row tag_list" *ngFor="let eachTag of eachMarkets">
            <div
              class="col-8 col-md-6 col-sm-12 tag_list_val remove"
              *ngIf="removedTags[i].indexOf(eachTag) > -1"
            >
              <del>{{ eachTag }}</del>
            </div>
            <div
              class="col-8 col-md-6 col-sm-12 tag_list_val"
              *ngIf="removedTags[i].indexOf(eachTag) == -1"
            >
              <span class="re-tagname">{{ eachTag }}</span>
            </div>
            <div
              class="col-4 col-md-6 col-sm-12 tag_list_action remove"
              *ngIf="removedTags[i].indexOf(eachTag) == -1"
            >
              <a
                href="javascript:void(0)"
                (click)="removeUndo(eachTag, i, 'remove')"
                class="re-remove"
                >Remove</a
              >
            </div>
            <div
              class="col-4 col-md-6 col-sm-12 tag_list_action"
              *ngIf="removedTags[i].indexOf(eachTag) > -1"
            >
              <a
                href="javascript:void(0)"
                (click)="removeUndo(eachTag, i, 'undo')"
                class="re-undo"
                >Undo</a
              >
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="padding-top: 14px !important">
  <button mat-raised-button [mat-dialog-close]="false" class="re-cancelbtn">
    Cancel
  </button>
  <button
    (click)="submitForm()"
    mat-raised-button
    cdkFocusInitial
    class="re-submit-btn"
  >
    Remove Market
  </button>
</mat-dialog-actions>
