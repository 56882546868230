import { Component, Inject, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ResponseModel } from 'src/app/utils/models/response';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';
import { SharedService } from '../../shared.service';
import { TaggingService } from '../../../providers/tagging/tagging.service';
import { ListStackingService } from './../../../providers/list-stacking/list-stacking.service';
import { ListErrorModel } from 'src/app/utils/models/error';
import { ListResponseModel } from '../../../utils/models/response';
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';
import { DialerService } from 'src/app/providers/dialer/dialer.service';
@Component({
  selector: 'app-remove-tags',
  templateUrl: './remove-tags.component.html',
  styleUrls: ['./remove-tags.component.scss'],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class RemoveTagsComponent implements OnInit {
  ListNames: any[] = [];
  tagsNames: any[] = [];
  public tagsData: any[] = [];
  public removedTags: any = [];
  public moduleName: string = '';
  public blankMsg: string = '';
  public colorCodes = MiscellaneousConstant.colorCodes;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<RemoveTagsComponent>,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private _sharedService: SharedService,
    private _listStackingService: ListStackingService,
    private _dialerService: DialerService,
    private _taggingService: TaggingService
  ) {
    if (this.data?.for && this.data?.for == 'stacking') {
      this.moduleName = this.data?.subModuleId;
      this.getData();
    } else {
      this.moduleName = 'Tags';
      this.tagsData = this.data.items;
    }
  }

  ngOnInit(): void {}

  getData() {
    if (this.data?.subModuleId == 'Tags') {
      let myobj = this.data?.filter;
      this._listStackingService.getTagsForSelectedProperty(myobj).subscribe(
        (response) => {
          if (response.code == 200) {
            this.tagsData = response.data;

            if (!this.tagsData.length) this.blankMsg = 'No records to show';
          } else {
            this.removedTags = [];
            this.blankMsg = 'No records to show';
          }
        },
        (err) => {
          this.removedTags = [];
          this.blankMsg = 'No records to show';
        }
      );
    } else {
      let myobj = this.data?.filter;
      this._listStackingService.getListsOfSelected(myobj).subscribe(
        (response) => {
          if (response.code == 200) {
            this.tagsData = response.data;
            if (this.tagsData.length == 0) this.blankMsg = 'No records to show';
          } else {
            this.removedTags = [];
            this.blankMsg = 'No records to show';
          }
        },
        (err) => {
          this.removedTags = [];
          this.blankMsg = 'No records to show';
        }
      );
    }
  }

  removeUndo(id, mode) {
    if (mode == 'undo') {
      const index = this.removedTags.indexOf(id);
      if (index > -1) {
        this.removedTags.splice(index, 1);
      }
    } else this.removedTags.push(id);
  }

  submitForm() {
    if (this.removedTags.length == 0) {
      if (this.moduleName == 'Tags')
        this._toastrService.error(MessageConstant.tagsRemoveBlank);
      else this._toastrService.error(MessageConstant.listRemoveBlank);
      return false;
    }

    this._loaderService.start();
    this.blankMsg = '';

    if (this.data?.for && this.data?.for == 'stacking') {
      let obj = this.data.filter;
      this._loaderService.start();
      if (this.data?.subModuleId == 'Tags') {
        obj['TagId'] = this.removedTags;
        obj['action'] = 'remove';
        this._listStackingService.updatemultitags(obj).subscribe(
          async (response: ListResponseModel) => {
            this._loaderService.stop();
            if (response.code == 200) {
              await this.getTagsNames(this.removedTags);
              this.addActivityList();
              this.dialog.close(this.removedTags);
              this._toastrService.success(response.message);
            }
          },
          (err: ListErrorModel) => {
            this._loaderService.stop();

            this.removedTags = [];
            this.blankMsg = 'No records to show';
          }
        );
      } else {
        obj['listId'] = this.removedTags;
        obj['action'] = 'remove';
        this._listStackingService.updatemultilists(obj).subscribe(
          async (response: ListResponseModel) => {
            this._loaderService.stop();
            if (response.code == 200) {
              await this.getListNames(this.removedTags);
              this.addActivityList();
              this.dialog.close(this.removedTags);
              this._toastrService.success(response.message);
            }
          },
          (err: ListErrorModel) => {
            this._loaderService.stop();

            this.removedTags = [];
            this.blankMsg = 'No records to show';
          }
        );
      }
    } else {
      let obj2 = {
        moduleId: this.data.moduleId,
        leadId: this.data.subModuleId,
        tagIds: this.removedTags,
        isTagAdd: false,
        for: this.data?.for,
      };
      this._sharedService.addTags(obj2).subscribe(
        (response) => {
          this._loaderService.stop();
          if (response) {
            this.dialog.close(this.removedTags);
            this._toastrService.success(MessageConstant.tagsDeletedSuccess);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
        }
      );
    }
  }

  getListNames(listId) {
    this.ListNames = [];
    let name;
    for (var i = 0; i < listId.length; i++) {
      var ressw3 = listId[i];
      for (var j = 0; j < this.tagsData.length; j++) {
        if (ressw3 == this.tagsData[j]._id) {
          name = this.tagsData[j].name;
          this.ListNames.push({
            name: name,
          });
          break;
        }
      }
    }
  }

  getTagsNames(tagId) {
    this.tagsNames = [];
    let name;
    for (var i = 0; i < tagId.length; i++) {
      var ressw3 = tagId[i];
      for (var j = 0; j < this.tagsData.length; j++) {
        if (ressw3 == this.tagsData[j]._id) {
          name = this.tagsData[j].name;
          this.tagsNames.push({
            name: name,
          });
          break;
        }
      }
    }
  }

  addActivityList() {
    let tagActivity;
    let activityType;
    let activityComment;

    if (this.moduleName == 'Tags') {
      activityType = 19;
      tagActivity = 8;
      activityComment = this.tagsNames;
    } else {
      activityType = 34;
      tagActivity = 12;
      activityComment = this.ListNames;
    }

    this._loaderService.start();
    let myobj = {
      subModuleId: this.data.filter.ids,
      comment: activityComment,
      activityType: activityType,
      subType: tagActivity,
      address: '',
      title: '',
      phoneNumber: '',
    };
    this._dialerService.addActivityList(myobj).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode == 200) {
          this._sharedService.refreshActivityLog.next(true);
        }
      },
      (err) => {
        this._loaderService.stop();
        console.log(err);
      }
    );
  }
}
