import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// MODEL AND CONSTANT
import { MessageConstant } from 'src/app/utils/message-constant';
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';
import { environment } from 'src/environments/environment';
import { BaseUrl } from 'src/app/utils/base-url-constants';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharedService } from '../../shared.service';
import { EsignService } from '../../../providers/esign/esign.service';

@Component({
  selector: 'app-copy-template',
  templateUrl: './copy-template.component.html',
  styleUrls: ['./copy-template.component.scss'],
})
export class CopyTemplateComponent implements OnInit {
  sectionForm: FormGroup;
  submitted: boolean = false;
  messageConstant = MessageConstant;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<CopyTemplateComponent>,
    private fb: FormBuilder,
    private _router: Router,
    private _sharedService: SharedService,
    private _esignService: EsignService,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.sectionForm = this.fb.group({
      name: ['', [Validators.required,Validators.minLength(3),Validators.maxLength(50)]],
    });
    if (this.data) {
      this.sectionForm.patchValue({
        ...this.data.details,
      });
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.sectionForm.controls[controlName].hasError(errorName);
  };

  onSubmit() {
    // this._loaderService.start();
    // const { _id } = this.data.details;

    console.log(this.data);

    switch (this.data.subModuleId) {
      case 'copy':
        this.copyTemplate();
        break;

      default:
        this._loaderService.stop();
        break;
    }
  }

  copyTemplate() {
    this.submitted = true;
    if (this.sectionForm.invalid) {
      return;
    }

    let param = {
      templateId: this.data?.details?.esignSecretId,
      templateUrl: this.data?.details?.templateUrl,
      templateName: this.sectionForm.value.name + '.pdf',
      redirectUrl: environment.appUrl + BaseUrl.eSignCreateTemplateRedirectLink,
    };
    this._loaderService.start();
    this._esignService.eSignCopyTemplate(param).subscribe(
      (response: ResponseModel) => {
        if (response && response.statusCode == 200) {
          this.dialog.close(response);
          this._toastrService.success(this.messageConstant.esignCopySuccess);
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          // this.dialog.close(this.data.details);
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message,'');
        }
      }
    );
  }
}
