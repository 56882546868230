<div class="re-top-head">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      {{
        marketCampaignData
          ? "Market Campaign Phone Number Deletion"
          : "Assign to Another Campaign"
      }}
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<mat-dialog-content class="" *ngIf="marketCampaignData">
  <div class="re-radiosec mt-4 px-3">
    <p class="re-radotext">Campaign Name | Phone Number</p>
    <p class="re-radotext-delete">
      {{ data?.details?.marketingTitle }} |
      {{ _utilities.maskNumber(data?.details?.number) }}
    </p>
  </div>
  <hr class="m-0" />
  <div class="re-radiosec mt-4 px-3">
    <p class="re-radotext-delete">
      You are deleting a marketing campaign phone number. What would you like to
      do with the leads that are assigned to this campaign?
    </p>
  </div>
</mat-dialog-content>

<mat-dialog-content class="" *ngIf="assignCampaignData">
  <div class="re-radiosec mt-4 px-3">
    <ul class="re-assignsec">
      <li>
        All existing leads will get assigned to the team based on new marketing
        campaign phone number
      </li>
      <li>All tasks in the leads will be deleted</li>
      <li>
        Any drip campaign set on this marketing campaign phone number being
        deleted will continue under the new marketing campaign phone number.
      </li>
    </ul>
  </div>
  <hr class="m-0" />
  <div class="re-radiosec mt-4 px-3">
    <form [formGroup]="campaignMigrationForm" autocomplete="off" novalidate>
      <div class="box">
        <div class="row">
          <div class="col-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label class="labels"
                >Select an existing marketing campaign*</label
              >
              <select
                class="form-control"
                name="numberId"
                formControlName="numberId"
              >
                <option value="">Select marketing campaign</option>
                <option
                  *ngFor="let campaign of defaultNumberList"
                  [value]="campaign?._id"
                >
                  {{ campaign.marketingTitle }}
                  {{ _utilities.maskNumber(campaign.number) }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions
  class="{{ marketCampaignData ? 'text-center' : 'text-right' }}"
  style="padding-top: 14px !important"
>
  <button
    mat-raised-button
    (click)="onUnassignLeads()"
    *ngIf="marketCampaignData"
    class="re-unassign"
  >
    Unassign All Leads
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="onAssignCampaign(true)"
    *ngIf="marketCampaignData"
    cdkFocusInitial
  >
    Assign to Another Campaign
  </button>

  <button
    mat-raised-button
    (click)="onAssignCampaign()"
    *ngIf="assignCampaignData"
  >
    Cancel
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="onDeleteNumber()"
    *ngIf="assignCampaignData"
    cdkFocusInitial
  >
    Confirm Assignment
  </button>
</mat-dialog-actions>
