<div class="re-map-container position-relative">
  <span [mat-dialog-close]="false" mat-dialog-close class="re-map-cancel">
    <img src="assets/images/close-circle.svg" alt="close" width="28px" />
  </span>

  <mat-dialog-content class="mat-typography">
    <div class="re-map-padding" *ngIf="latitude && longitude">
      <div id="map" #mapRef></div>
    </div>

    <div class="re-map-view" *ngIf="!latitude && !longitude">
      <span>
        Please fill the address to preview
        <br />
        the Google street view.
      </span>
    </div>
  </mat-dialog-content>
</div>
