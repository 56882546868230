export class ActivityLogConstant {
  public static ActivityType = {
    SMS: 1,
    RVM: 2,
    EMAIL: 3,
    VOICECALL: 4,
    VOICEMAIL: 5,
    REMINDER: 6,
    APPOINTMENT: 7,
    COMMENT: 8,
    TASK: 9,
    STATUS: 10,
    WEBFORM: 11,
    FOLDER: 12,
    SHAREFOLDER: 13,
    FILE: 14,
    SHAREFILE: 15,
    API: 16,
    DRIPASSIGN: 17,
    DRIPUNASSIGN: 18,
    TAGS: 19,
    BUYER: 20,
    ESIGN: 21,
    MMS: 22,
    INCOMINGLEAD: 23,
    DIRECTMAIL: 24,
    MARKETCAMPEGION: 25,
    SCHEDULEDSMS: 26,
    SMSCAMPEGION: 27,
    BUYERWEBFORM: 28,
    MERGELEAD: 29,
    UPDATETEAMFORMCAMPAIGN: 30,
    UPDATETEAMFORMLEAD: 31,
    DELETECAMPAGION: 32,
    DELETECAMPAGIONANDREASSGNOHETCAMPAGION: 33,
    LIST: 34,
    EMAILCAMPAIGNEVENT: 35,
    CONTINGENCY: 36,
    OFFER: 37,
    VENDOR: 40,
    WEBHOOKTRIGGER: 48,
  };

  public static ActivitySubType = {
    MISSCALLSMS: 1,
    HOTLEAD: 2,
    UNHOTLEAD: 3,
    TASKCREATE: 4,
    TASKUPDATE: 5,
    TASKCOMPLETED: 6,
    TAGADD: 7,
    TAGREMOVE: 8,
    ZAPIER: 9,
    CARROT: 10,
    LISTADD: 11,
    LISTREMOVE: 12,
    WEBSITE: 13,
    DIALERMOVELEAD: 14,
    LSMOVELEAD: 15,
  };
}
