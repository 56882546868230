import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// UTILS
import { MessageConstant } from 'src/app/utils/message-constant';
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharedService } from '../../../../../shared/shared.service';

@Component({
  selector: 'app-add-edit-custom-section',
  templateUrl: './add-edit-custom-section.component.html',
  styleUrls: ['./add-edit-custom-section.component.scss'],
})
export class AddEditCustomSectionComponent implements OnInit {
  sectionForm: FormGroup;
  submitted: boolean = false;
  messageConstant = MessageConstant;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialog: MatDialogRef<AddEditCustomSectionComponent>,
    private _sharedService: SharedService,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.sectionForm = this.fb.group({
      title: ['', [Validators.required]],
      view: [1, [Validators.required]],
      moduleId: [''],
    });

    if (this.data.group) {
      this.sectionForm.patchValue({
        ...this.data.group,
        moduleId: this.data?.moduleId,
      });
    } else {
      this.sectionForm.patchValue({
        order: this.data.order,
        moduleId: this.data?.moduleId,
      });
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.sectionForm.controls[controlName].hasError(errorName);
  };

  onSubmit() {
    this.submitted = true;

    if (this.sectionForm.invalid) {
      return;
    }

    let obj = { ...this.sectionForm.value };

    if (this.data.group) {
      obj = { ...obj, order: this.data.group.order };
    }

    this._loaderService.start();
    this._sharedService.saveSection(obj, this.data.group).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          if (this.data.group) {
            const successEditMessage = this.messageConstant.customSectionUpdateSuccess
              .replace('[[type]]', 'Section')
              .replace('[[status]]', 'Updated');
            this._toastrService.success(successEditMessage, '');

            this.dialog.close({
              ...this.sectionForm.value,
              _id: this.data.group._id,
            });
          } else {
            const successEditMessage = this.messageConstant.customSectionUpdateSuccess
              .replace('[[type]]', 'Section')
              .replace('[[status]]', 'Added');
            this._toastrService.success(successEditMessage, '');

            this.dialog.close(response.data);
          }
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
}
