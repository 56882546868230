// MODULES
import {
  Component,
  OnInit,
  Inject,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as Moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import { extendMoment } from 'moment-range';
import * as _ from 'lodash';

const moment = extendMoment(Moment);

// SERVICES
import { MasterTaskService } from 'src/app/providers/master-task/master-task.service';
import { SharedService } from 'src/app/shared/shared.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { LeadsService } from 'src/app/providers/leads/leads.service';
import { SmsService } from 'src/app/providers/sms/sms.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

// UTILS
import { MessageConstant } from 'src/app/utils/message-constant';
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';
import { environment } from 'src/environments/environment';
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';
import { timezoneConstant } from 'src/app/utils/timezone-list-constant';

@Component({
  selector: 'app-add-edit-appointments',
  templateUrl: './add-edit-appointments.component.html',
  styleUrls: ['./add-edit-appointments.component.scss'],
})
export class AddEditAppointmentsComponent implements OnInit {
  @Input() isDialog: boolean = true;
  @Input() appointmentData: any;
  @Output() _emitter = new EventEmitter<any>();

  addEditForm: FormGroup;

  teamsData: any = {};
  selectUser: any;
  moment = moment;
  startTime: any = '';
  endTime: any = '';
  messageConstant = MessageConstant;

  users: any[] = [];
  teamUsers: any[] = [];
  teams: any[] = [];
  calendarOptions: any[] = [];
  emailTemplates: any[] = [];
  smsTemplates: any[] = [];
  availability: any[] = [];
  appointments: any[] = [];
  timezoneList: any[] = [];
  contacts: any[] = [];
  taskTimeTypeList: any = [];
  roles: any[] = [];
  allRoles: any[] = [
    'accountant',
    'acquisitionManager',
    'acquisitionSalesManager',
    'admin',
    'bookkeeper',
    'coldCaller',
    'closingCoordinator',
    'coOwner',
    'dispositionManager',
    'leadManager',
    'marketingAssistant',
    'marketingManager',
    'officeManager',
    'otherRole',
    'owner',
    'projectManager',
    'propertyAnalyst',
    'propertyManager',
    'transactionCoordinator',
  ];
  appointmentTypes: any[] = MiscellaneousConstant.appointmentTypes;
  appointmentSubTypes: any[] = MiscellaneousConstant.appointmentSubTypes;

  user: string = '';
  selectedTimeZone: string = '';
  slotType: string = 'startTime';
  profileModuleId: string = '601cf3c070419f19b74a6882';
  smsFilterBody: string = '';
  appointmentType: string = '';
  selectedRole: string = '';
  public items: string[] = [
    'First_Name',
    'Last_Name',
    'Property_Street_Address',
    'Property_City',
    'Property_State',
    'Property_Zip_Code',
  ];

  currentDate: Date = new Date();

  submitted: boolean = false;
  isChooseAvailability: boolean = false;
  onLoadStatus: boolean = true;

  currentPage: number = 1;
  currentLimit: number = 1000; // MiscellaneousConstant.paginationLimit.STANDARD;
  selectedDate: number = new Date().getDay();
  isMarketReadonly: boolean = false;
  constructor(
    private _dialogRef: MatDialogRef<AddEditAppointmentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private _utilities: CommonFunctionsService,
    private _masterTaskService: MasterTaskService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    public _sharedServices: SharedService,
    private _leadService: LeadsService,
    private _smsService: SmsService
  ) {}

  ngOnInit(): void {
    if (!this.isDialog) {
      this.data = this.appointmentData;
    }

    this.appointmentType = !this.data.details ? 'Add' : 'Edit';
    this.timezoneList = timezoneConstant.timezoneArray;
    this.taskTimeTypeList = this._utilities.taskTimeTypeList();
    this.addEditForm = this._formBuilder.group({
      title: ['', [Validators.required]],
      description: ['', []],

      timezoneOffset: ['', [Validators.required]],
      dateTime: [new Date(), [Validators.required]],

      // SELLER REMINDER
      isInstantNotification: [false, []],
      isInstantNotificationType: ['0', []],

      smsRemindTime: ['2', []],
      smsRemindBefore: ['Hours', []],
      remindSmsMessage: ['', []],
      smsTemplateId: ['', []],

      emailRemindTime: ['2', []],
      emailRemindBefore: ['Hours', []],
      emailTemplateId: ['', []],

      // MY REMINDER
      isMyRemindNotification: [true, []],
      isMyRemindNotificationType: ['2', []],

      smsMyRemindTime: ['2', []],
      smsMyRemindBefore: ['Hours', []],

      emailMyRemindTime: ['1', []],
      emailMyRemindBefore: ['Hours', []],

      assignUserId: [[''], [Validators.required]],

      secondaryContact: [[], []],

      availability: [''],

      appointmentType: [3],
      appointmentSubType: [null],
    });

    if (this.data?.leadInfo) {
      this.currentDate = new Date(this.data?.leadInfo?.leadCreated);
    }

    this.getRoles();
    this.getTeamDetails();

    if (this.data?.secondaryContacts) {
      this.getContacts();
    }
  }

  ngAfterViewInit() {
    this.isMarketReadonly = true;
  }
  getContacts() {
    this.contacts = [];
    this.data?.secondaryContacts?.filter((x) => {
      //delete x['isOwnerContact'];

      let label = `${x?.name || 'N/A'}`;

      if (x?.type) {
        label += ` • ${x?.type} `;
      }

      if (x?.phoneNumber) {
        label += ` • ${this._utilities.maskNumber(x?.phoneNumber)}`;
      }

      if (x?.numberType) {
        label += ` (${this.getNumberType(x?.numberType)})`;
      }

      if (x?.email) {
        label += ` • ${x?.email}`;
      }

      this.contacts.push({
        label,
        value: {
          email: x?.email || undefined,
          phoneNumber: x?.phoneNumber || undefined,
        },
      });
    });
    this.contacts.sort((a, b) => a.label.localeCompare(b.label));
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.addEditForm.controls[controlName].hasError(errorName);
  };

  getNumberType(numberType) {
    const type = this._utilities.numberType(numberType?.toLowerCase());
    return `${type || 'N/A'}`;
  }

  getSubUsers() {
    let obj = {
      page: this.currentPage,
      limit: this.currentLimit,
    };

    this._loaderService.start();
    this._masterTaskService
      .getUserRoleList(
        obj,
        this.data?.webFormLink,
        this.appointmentType === 'Edit' ? true : false
      )
      .subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            const users = response.data?.items;

            let userArray = [];
            const assignUserObject = this.teamsData?.assignUser;
            for (const roleId in assignUserObject) {
              const userId = assignUserObject[roleId];
              const role = this.teamsData?.roles.find(
                (item) => item._id == roleId
              );

              if (role && userId && users.length) {
                const assignedUser = users?.find((item) => item._id == userId);
                if (assignedUser) {
                  const label =
                    role.name +
                    ' | ' +
                    assignedUser.firstName +
                    ' ' +
                    assignedUser.lastName;

                  userArray.push({
                    ...assignedUser,
                    label,
                    roleKey: role?.key,
                  });
                }
              }
            }

            if (this.data?.details) {
              this.data.details.assignUserId.filter((userId) => {
                const userIndex = userArray.findIndex((x) => x._id === userId);
                if (userIndex === -1) {
                  const user = users.find((x) => x._id === userId);
                  this.teamUsers.push(userId);
                  if (user) {
                    user.roleData.filter((role) => {
                      const label =
                        role.name +
                        ' | ' +
                        user.firstName +
                        ' ' +
                        user.lastName;

                      userArray.push({
                        ...user,
                        label,
                        roleKey: role?.key,
                      });
                    });
                  }
                }
              });
            }

            this.users = _.sortBy(userArray, ['firstName', 'lastName']);
            this.users = this.users.filter((user) =>
              this.teamUsers.includes(user?._id)
            );

            if (this.users?.length) {
              let userId = this.data?.currentUser?.userId;

              if (this.data?.details) {
                userId = this.data?.details?.assignUserId[0];
              }
              let currentUser = this.users.filter((x) => x._id === userId);

              if (currentUser.length) {
                this.addEditForm.patchValue({
                  timezoneOffset:
                    this.data?.details?.timezoneOffset ||
                    currentUser[0]?.availabilityTimezoneOffset ||
                    currentUser[0]?.timezoneOffset,
                });
                this.selectedTimeZone = this.addEditForm.value.timezoneOffset;

                let startDateTime;
                if (this.data?.details) {
                  if (this.data?.details?.isOldData) {
                    startDateTime = moment(this.data?.details?.startDateTime);
                  } else {
                    startDateTime = moment(
                      this.data?.details?.startDateTime
                    ).tz(this.data?.details?.timezoneOffset);
                  }
                  let date = new Date();
                  date.setFullYear(startDateTime.get('year'));
                  date.setMonth(startDateTime.get('month'));
                  date.setDate(startDateTime.get('date'));
                  date.setHours(startDateTime.get('hours'));
                  date.setMinutes(startDateTime.get('minutes'));
                  date.setSeconds(0);

                  this.getAvailability(
                    [
                      this.data?.details?.assignUserId[0] ||
                        currentUser[0]?._id,
                    ],
                    '',
                    true,
                    '',
                    date
                  );
                } else {
                  this.getAvailability(
                    [
                      this.data?.details?.assignUserId[0] ||
                        currentUser[0]?._id,
                    ],
                    '',
                    true
                  );
                }
              } else {
                this.getAvailability([this.users[0]?._id]);
              }

              this.users.filter((x) => {
                this.getImage(x);
              });
            }

            this.calendarOptions = [
              {
                label: 'REsimpli (Local)',
                value: '',
                disabled: true,
              },
            ];
          }
          this._loaderService.stop();
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
  }

  getImage(user) {
    if (!user?.profileImage) {
      this.calendarOptions.push({
        label: `${user?.firstName} ${user?.lastName}`,
        value: user?._id,
        profileImage: '/assets/images/demo-profile.png',
      });
      return;
    } else {
      this.calendarOptions.push({
        label: `${user?.firstName} ${user?.lastName}`,
        value: user?._id,
        profileImage: user?.profileImage,
      });
    }
  }

  getTeamDetails() {
    let obj: any = {
      leadId: this.data?.leadInfo?.leadId
        ? this.data?.leadInfo?.leadId
        : this.data?.leadInfo?._id,
    };

    if (this.data.currentMarket) {
      obj = {
        ...obj,
        marketId: this.data.currentMarket,
      };
    }

    if (this.data.numberId) {
      obj = {
        ...obj,
        numberId: this.data.numberId,
      };
    }

    this._loaderService.start();
    this._leadService.getTeams(obj, this.data?.webFormLink).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.teamsData = response.data?.items;
          this.teams = response.data?.items?.roles.map((x) => {
            return { label: `${x?.name}`, value: x?._id };
          });

          let users = [];

          response.data?.items?.assignUser &&
            Object.keys(response.data?.items?.assignUser).filter((user) => {
              const userId = response.data?.items?.assignUser[user];
              if (userId) {
                users.push(userId);
              }
            });
          this.teamUsers = [...new Set(users)];

          this.getSubUsers();
          this.getEmailTemplates();
          this.getSmsTemplates();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getRoles() {
    this._loaderService.start();

    this._sharedServices
      .getRoles(this.data?.webFormLink)
      .subscribe((response: ResponseModel) => {
        if (response.statusCode === 200) {
          this._loaderService.stop();
          this.roles = response.data;
        }
      });
  }

  getEmailTemplates() {
    let obj = {
      limit: 1000,
      page: 1,
    };

    this._sharedServices.getEmailList(obj, this.data?.webFormLink).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.emailTemplates = response.data?.items;
          this.emailTemplates.sort((a, b) => a.templateName.localeCompare(b.templateName));
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getSmsTemplates() {
    let obj = {
      limit: 1000,
      page: 1,
    };

    this._smsService.getSmsList(obj, this.data?.webFormLink).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.smsTemplates = response.data?.items;
          this.smsTemplates.sort((a, b) => a.templateName.localeCompare(b.templateName));
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  setSmsTemplate($event) {
    if (!$event) {
      this.addEditForm.patchValue({
        remindSmsMessage: '',
      });

      return;
    }

    const index = this.smsTemplates.findIndex((x) => x._id === $event);
    if (index === -1) {
      return;
    }

    this.messageBody(this.data, this.smsTemplates[index].message);
  }

  messageBody(data, smsMessage) {
    let messageBody;
    let address;
    let streetAddress;
    let city;
    let state;
    let zip;
    let name;
    let firstName;
    let lastName;
    let appDateTime;
    let appDay;
    let appTiming;

    if (data?.leadInfo?.title) {
      name = data.leadInfo.title.split(' ');
      firstName = name[0] ? name[0] : 'First_Name(N/A)';
      lastName = name[1] ? name[1] : 'last_Name(N/A)';
    }

    const { title, dateTime } = this.addEditForm.value;
    if (title) {
      appDateTime =
        moment(dateTime).format('ddd, MMM DD') +
        ' at ' +
        moment(this.startTime).format('hh:mm A');

      appDay = moment(dateTime).format('dddd');
      appTiming = moment(this.startTime).format('hh:mm A');
    }

    if (data?.leadInfo?.address) {
      address = data.leadInfo.address.split(',');
      if (address.length > 1) {
        streetAddress = address[0]
          ? address[0]
          : 'Property_Street_Address(N/A)';
        city = address[1] ? address[1] : 'Property_City(N/A)';
        if (address.length > 2) {
          let subDivide = address[2];
          if (subDivide) {
            let sub = subDivide.split(' ');
            state = sub[1] ? sub[1] : 'Property_State(N/A)';
            zip = sub[2] ? sub[2] : 'Property_Zip_Code(N/A)';
          }
        } else {
          state = 'Property_State(N/A)';
          zip = 'Property_Zip_Code(N/A)';
        }
      } else {
        streetAddress = address[0];
        city = 'Property_City(N/A)';
        state = 'Property_State(N/A)';
        zip = 'Property_Zip_Code(N/A)';
      }
    } else {
      streetAddress = 'Property_Street_Address(N/A)';
      city = 'Property_City(N/A)';
      state = 'Property_State(N/A)';
      zip = 'Property_Zip_Code(N/A)';
    }

    let myObj = {
      '@First_Name': firstName || '',
      '@Last_Name': lastName || '',
      '@Property_Street_Address': streetAddress || '',
      '@Property_City': city || '',
      '@Property_State': state || '',
      '@Property_Zip_Code': zip || '',
      '@Appt_Date_and_Time': appDateTime || '',
      '@Appt_Day': appDay || '',
      '@Appt_Time': appTiming || '',
    };

    this.teamsData.roles.filter((role) => {
      let key = role?.name?.toString();
      if (key != undefined && smsMessage.includes(key)) {
        let originalRole = this.teamsData.roles.find(
          (item) => item?.name == key
        );
        let userId = this.teamsData?.assignUser[originalRole?._id];
        if (userId) {
          let username = originalRole.members.find(
            (item) => item._id.toString() == userId.toString()
          );
          let obj = {};
          obj['@' + key] = `${username.firstName}`;
          myObj = { ...myObj, ...obj };
        }
      }
    });

    let rowRole = [
      '@First_Name',
      '@Last_Name',
      '@Property_Street_Address',
      '@Property_City',
      '@Property_State',
      '@Property_Zip_Code',
      '@Appt_Date_and_Time',
      '@Appt_Day',
      '@Appt_Time',
    ];
    let roles = this.teamsData.roles.map((item) => '@' + item.name);
    let rolesWithKey = new RegExp([...rowRole, ...roles].join('|'), 'g');
    messageBody = smsMessage.replace(rolesWithKey, (matched) => {
      return myObj[matched] || '';
    });
    messageBody = messageBody.replace(/@/g, '');
    this.smsFilterBody = messageBody;

    this.addEditForm.patchValue({
      remindSmsMessage: this.smsFilterBody,
    });
  }

  getAvailability(user, bool?, isSetTimezone?, $event?, date?) {
    this.selectRole($event);
    const userIds = user.filter((x) => x);
    if (!userIds.length) {
      return;
    }

    const { dateTime } = this.addEditForm.value;
    let obj: any = {
      assignUserId: userIds,
    };

    let startStr = moment(date || dateTime)
      .set({ hours: 0, minutes: 0, seconds: 0 })
      .format('YYYY-MM-DDTHH:mm:ss');
    let endStr = moment(date || dateTime)
      .set({ hours: 23, minutes: 59, seconds: 59 })
      .format('YYYY-MM-DDTHH:mm:ss');

    obj = { ...obj, startDateTime: startStr, endDateTime: endStr };

    const index = this.users.findIndex((x) => x?._id === user[0]);
    if (index > -1) {
      this.selectUser = this.users[index];
    }

    this._loaderService.startLoader('slot');
    this._sharedServices.getAvailability(obj, this.data?.webFormLink).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          if (!isSetTimezone) {
            this.addEditForm.patchValue({
              timezoneOffset:
                this.selectUser?.availabilityTimezoneOffset ||
                this.selectUser?.timezoneOffset,
            });
            this.startTime = '';
            this.endTime = '';
          }
          this.selectedTimeZone = this.addEditForm.value.timezoneOffset;

          this.timezoneChange();
          this.appointments = response?.data?.appointmentData;
        }
        this.selectedDate = new Date(dateTime).getDay();
        this.setSlot(
          this.selectedDate,
          { label: '15 Mins', value: 15 },
          '',
          '',
          bool
        );
        this._loaderService.stopLoader('slot');
      },
      (err: ErrorModel) => {
        this._loaderService.stopLoader('slot');
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  timezoneChange() {
    const { dateTime, timezoneOffset } = this.addEditForm.value;
    let startDateTime, endDateTime;
    let startTimeArray = moment(this.startTime).format('HH:mm').split(':');
    let endTimeArray = moment(this.endTime).format('HH:mm').split(':');
    const tz = moment(dateTime).tz(this.selectedTimeZone).format('Z');

    if (this.data?.details && this.data?.details?.isOldData) {
      startDateTime = moment(dateTime)
        .set({
          hours: parseInt(startTimeArray[0]),
          minutes: parseInt(startTimeArray[1]),
          second: 0,
        })
        .format('YYYY-MM-DDTHH:mm');

      endDateTime = moment(dateTime)
        .set({
          hours: parseInt(endTimeArray[0]),
          minutes: parseInt(endTimeArray[1]),
          second: 0,
        })
        .format('YYYY-MM-DDTHH:mm');
    } else {
      startDateTime =
        moment(dateTime)
          .set({
            hours: parseInt(startTimeArray[0]),
            minutes: parseInt(startTimeArray[1]),
            second: 0,
          })
          .format('YYYY-MM-DDTHH:mm:ss') + tz;

      endDateTime =
        moment(dateTime)
          .set({
            hours: parseInt(endTimeArray[0]),
            minutes: parseInt(endTimeArray[1]),
            second: 0,
          })
          .format('YYYY-MM-DDTHH:mm:ss') + tz;
    }
    if (this.startTime) {
      const start = momentTimezone(startDateTime)
        .tz(timezoneOffset)
        .format('HH:mm');
      this.startTime = moment(start, 'HH:mm');
    }

    if (this.endTime) {
      const end = momentTimezone(endDateTime)
        .tz(timezoneOffset)
        .format('HH:mm');
      this.endTime = moment(end, 'HH:mm');
    }

    this.selectedTimeZone = timezoneOffset;
  }

  selectRole($event) {
    if (!$event || $event.target.selectedIndex < 1) {
      return;
    }
    const index = $event.target.selectedIndex - 1;
    this.selectedRole = this.users[index]?.roleData[0]?._id;
  }

  checkAvailability(slot, bool?) {
    const userAppointment = this.appointments.findIndex(
      (x) => x?._id === this.selectUser?._id
    );

    let isDisabled = false;

    if (userAppointment > -1) {
      const { dateTime, timezoneOffset } = this.addEditForm.value;
      const appointments = this.appointments[userAppointment]?.appointmentTime;

      const slotArray = momentTimezone(slot).format('HH:mm').split(':');
      const startTimeArray = momentTimezone(this.startTime)
        .format('HH:mm')
        .split(':');
      const endTimeArray = momentTimezone(this.endTime)
        .format('HH:mm')
        .split(':');
      const currentSlot = moment(dateTime)
        .set({
          hours: parseInt(slotArray[0]),
          minutes: parseInt(slotArray[1]),
          second: 0,
        })
        .format('YYYY-MM-DDTHH:mm:ss');

      const startTime = moment(dateTime)
        .set({
          hours: parseInt(startTimeArray[0]),
          minutes: parseInt(startTimeArray[1]),
          second: 0,
        })
        .format('YYYY-MM-DDTHH:mm:ss');

      const endTime = moment(dateTime)
        .set({
          hours: parseInt(endTimeArray[0]),
          minutes: parseInt(endTimeArray[1]),
          second: 0,
        })
        .format('YYYY-MM-DDTHH:mm:ss');

      if (
        this.data.details &&
        moment(currentSlot).isSameOrAfter(startTime) &&
        moment(currentSlot).isSameOrBefore(endTime)
      ) {
        return false;
      }

      appointments.filter((x) => {
        let startTimeArray = momentTimezone(slot).format('HH:mm').split(':');

        let startDateTime;
        const tz = moment(dateTime).tz(timezoneOffset).format('Z');

        if (this.slotType === 'endTime') {
          startDateTime =
            moment(dateTime)
              .set({
                hours: parseInt(startTimeArray[0]),
                minutes: parseInt(startTimeArray[1]),
                second: 0,
              })
              .subtract(15, 'minute')
              .format('YYYY-MM-DDTHH:mm:ss') + tz;
        } else {
          startDateTime =
            moment(dateTime)
              .set({
                hours: parseInt(startTimeArray[0]),
                minutes: parseInt(startTimeArray[1]),
                second: 0,
              })
              .format('YYYY-MM-DDTHH:mm:ss') + tz;
        }

        const isAfter = moment(startDateTime).isSameOrAfter(x?.startDateTime);

        const endTime = moment(x?.endDateTime).subtract(15, 'minutes');

        const isBefore = moment(startDateTime).isSameOrBefore(endTime);

        if (isAfter && isBefore) {
          isDisabled = true;
        }
      });
    }

    return isDisabled;
  }

  getTime() {
    const { dateTime } = this.addEditForm.value;
    return moment(dateTime).format('ddd DD, YYYY');
  }

  changeAvailableDate($event) {
    if (!$event) {
      return;
    }

    const assignUserId = [this.selectUser?._id];
    this.getAvailability(assignUserId, true, true);
  }

  setSlot(day, slot, startSlotTime?, endSlotTime?, bool?) {
    const index = day === 0 ? 6 : day - 1;
    let availability = this.selectUser?.availability
      ? this.selectUser?.availability[index]
      : {};

    let newDate = new Date();

    // START SLOT TIME
    let startTime = '';

    if (startSlotTime) {
      startTime = this.setHours(newDate, startSlotTime);
    } else {
      startTime =
        availability?.startTime ||
        this.setHours(newDate, moment().set({ hours: 9, minutes: 0 }));
    }

    // END SLOT TIME
    let endTime = '';
    if (endSlotTime) {
      endTime = this.setHours(newDate, endSlotTime);
    } else {
      endTime =
        availability?.endTime ||
        this.setHours(newDate, moment().set({ hours: 18, minutes: 0 }));
    }

    const { timezoneOffset } = this.addEditForm.value;

    let start = momentTimezone(startTime).tz(timezoneOffset).format('HH:mm');

    let end = momentTimezone(endTime).tz(timezoneOffset).format('HH:mm');
    if (availability?.isSetAvailability) {
      this.availability = this.getTimeStops(start, end, slot.value);
    } else {
      this.availability = [];
    }

    if (!this.availability.length && this.data?.details) {
      let startTime = this.data?.details?.startDateTime;
      let endTime = this.data?.details?.endDateTime;

      if (!this.data.details.isOldData) {
        startTime = startTime.substr(0, startTime.length - 6);
        endTime = endTime.substr(0, endTime.length - 6);
      }

      this.startTime = moment(moment(new Date(startTime), 'HH:mm'));
      this.endTime = moment(moment(new Date(endTime), 'HH:mm'));
    }

    if (!this.availability.length) {
      if (
        this.data?.details &&
        this.slotType === 'startTime' &&
        this.startTime
      ) {
        this.availability.push(moment(moment(this.startTime, 'HH:mm')));
      }

      if (this.data?.details && this.slotType === 'endTime' && this.endTime) {
        this.availability.push(moment(moment(this.endTime, 'HH:mm')));
      }
    }

    if (this.data?.details && this.onLoadStatus) {
      let assignUserId = [''];

      if (this.data?.details?.assignUserId) {
        this.user = this.data?.details?.assignUserId[0];
        assignUserId = [...assignUserId, ...this.data?.details?.assignUserId];
      }
      if (!bool) {
        this.user = this.data?.details?.assignUserId[0];
        let startDateTime;
        if (this.data?.details?.isOldData) {
          startDateTime = moment(this.data?.details?.startDateTime);
        } else {
          startDateTime = moment(this.data?.details?.startDateTime).tz(
            this.data?.details?.timezoneOffset
          );
        }
        let date = new Date();
        date.setFullYear(startDateTime.get('year'));
        date.setMonth(startDateTime.get('month'));
        date.setDate(startDateTime.get('date'));
        date.setHours(startDateTime.get('hours'));
        date.setMinutes(startDateTime.get('minutes'));
        date.setSeconds(0);
        let contactNo = [];
        this.data?.details?.secondaryContact?.map((x) => {
          this.data?.secondaryContacts?.map((secondary) => {
            if (secondary?.phoneNumber == x?.phoneNumber) {
              contactNo.push({
                email: secondary?.email || undefined,
                phoneNumber:
                  (this._utilities.getCountryCode(secondary?.phoneNumber)
                    ? ''
                    : environment.countryCode) +
                    this._utilities.unMaskNumber(secondary?.phoneNumber) ||
                  undefined,
              });
            }
          });
        });
        this.addEditForm.patchValue({
          ...this.data?.details,
          secondaryContact: contactNo,
          dateTime: date,
          assignUserId,
        });
      }
      const {
        isInstantNotificationEmail,
        isInstantNotificationSms,
        isMyRemindNotificationEmail,
        isMyRemindNotificationSms,
      } = this.data.details;
      if (isInstantNotificationEmail && isInstantNotificationSms) {
        this.addEditForm.patchValue({
          isInstantNotificationType: '0',
        });
      } else if (isInstantNotificationEmail && !isInstantNotificationSms) {
        this.addEditForm.patchValue({
          isInstantNotificationType: '2',
        });
      } else if (!isInstantNotificationEmail && isInstantNotificationSms) {
        this.addEditForm.patchValue({
          isInstantNotificationType: '1',
        });
      }

      if (isMyRemindNotificationEmail && isMyRemindNotificationSms) {
        this.addEditForm.patchValue({
          isMyRemindNotificationType: '0',
        });
      } else if (isMyRemindNotificationEmail && !isMyRemindNotificationSms) {
        this.addEditForm.patchValue({
          isMyRemindNotificationType: '2',
        });
      } else if (!isMyRemindNotificationEmail && isMyRemindNotificationSms) {
        this.addEditForm.patchValue({
          isMyRemindNotificationType: '1',
        });
      }

      this.availability.filter((x) => {
        const slot = moment(x).format('hh:mm A');
        const { startDateTime, endDateTime, timezoneOffset, isOldData } =
          this.data?.details;
        let startTime = '';
        let endTime = '';

        if (isOldData) {
          startTime = moment(startDateTime).format('hh:mm A');

          endTime = moment(endDateTime).format('hh:mm A');
        } else {
          startTime = moment(startDateTime)
            .tz(timezoneOffset)
            .format('hh:mm A');

          endTime = moment(endDateTime).tz(timezoneOffset).format('hh:mm A');
        }

        if (slot === startTime) {
          this.startTime = x;
        }

        if (slot === endTime) {
          this.endTime = x;
        }
      });

      if (this.startTime == '') {
        let startTime = this.data?.details?.startDateTime;
        if (!this.data.details.isOldData) {
          startTime = startTime.substr(0, startTime.length - 6);
        }
        this.startTime = moment(moment(new Date(startTime), 'HH:mm'));
      }

      if (this.endTime == '') {
        let endTime = this.data?.details?.endDateTime;
        if (!this.data.details.isOldData) {
          endTime = endTime.substr(0, endTime.length - 6);
        }
        this.endTime = moment(moment(new Date(endTime), 'HH:mm'));
      }

      if (!bool && this.addEditForm.value?.smsTemplateId) {
        this.setSmsTemplate(this.addEditForm.value?.smsTemplateId);
      }

      this.onLoadStatus = false;
    } else {
      if (!bool) {
        this.user = '';
        if (this.data?.currentUser?.userId) {
          const userIndex = this.users.findIndex(
            (user) => user?._id === this.data?.currentUser?.userId
          );
          if (userIndex > -1) {
            this.user = this.data?.currentUser?.userId;
          }
        }

        this.addEditForm.patchValue({
          assignUserId: ['', this.user],
        });
      }
    }
  }

  setHours(dt, h) {
    const { timezoneOffset } = this.addEditForm.value;
    var time = h.format('HH:mm').split(':');

    return momentTimezone(dt)
      .tz(timezoneOffset)
      .set({
        hours: parseInt(time[0]),
        minutes: parseInt(time[1]),
        seconds: 0,
      })
      .format('YYYY-MM-DDTHH:mm:ssZ');
  }

  getTimeStops(start, end, interval) {
    let startTime = moment(start, 'HH:mm');
    let endTime = moment(end, 'HH:mm');

    let timeStops = [];

    while (startTime <= endTime) {
      if (this.slotType === 'endTime') {
        if (!this.checkAvailability(moment(startTime))) {
          timeStops.push(moment(startTime));
          startTime.add(interval, 'minutes');
        } else {
          break;
        }
      } else {
        timeStops.push(moment(startTime));
        startTime.add(interval, 'minutes');
      }
    }

    return timeStops;
  }

  isSlotActive(slot) {
    return (
      !!(
        this.slotType === 'startTime' && moment(this.startTime).isSame(slot)
      ) ||
      (this.slotType === 'endTime' && moment(this.endTime).isSame(slot))
    );
  }

  setAvailability(slot, lastIndex) {
    if (
      !(
        (lastIndex && this.slotType === 'startTime') ||
        this.checkAvailability(slot)
      )
    ) {
      this[this.slotType] = slot;

      if (moment(this.startTime).isAfter(moment(this.endTime))) {
        this.endTime = '';
      }

      this.isChooseAvailability = false;
    }
  }

  getDate(date) {
    return new Date(date);
  }

  chooseTime(type) {
    this.slotType = type;
    if (this.slotType === 'endTime') {
      const startTime = moment(this.startTime).add(15, 'minutes');
      this.setSlot(
        this.selectedDate,
        { label: '15 Mins', value: 15 },
        startTime,
        '',
        true
      );
    } else {
      this.endTime = '';
      this.setSlot(
        this.selectedDate,
        { label: '15 Mins', value: 15 },
        '',
        '',
        true
      );
    }

    this.isChooseAvailability = true;
  }

  onSubmit() {
    this.submitted = true;
    if (this.addEditForm.invalid) {
      return;
    }

    if (!this.startTime && !this.endTime) {
      this._toastrService.error('Please Select Start And End Time');
      return;
    }

    if (!this.startTime) {
      this._toastrService.error('Please Select Start Time.');
      return;
    }

    if (!this.endTime) {
      this._toastrService.error('Please Select End Time.');
      return;
    }

    let {
      dateTime,
      isInstantNotificationType,
      isInstantNotification,
      timezoneOffset,
      isMyRemindNotificationType,
      isMyRemindNotification,
    } = this.addEditForm.value;

    let startTimeArray = moment(this.startTime).format('HH:mm').split(':');
    let endTimeArray = moment(this.endTime).format('HH:mm').split(':');

    let tz = moment(dateTime).tz(timezoneOffset).format('Z');

    let startDateTime = '';
    let endDateTime = '';

    // const isDST = moment(dateTime).tz(timezoneOffset).isDST();

    // if (isDST) {
    //   tz = moment(dateTime).tz(timezoneOffset).format('Z');
    // }

    if (this.data?.details && this.data?.details?.isOldData) {
      startDateTime = moment(dateTime)
        .set({
          hours: parseInt(startTimeArray[0]),
          minutes: parseInt(startTimeArray[1]),
          second: 0,
        })
        .format('YYYY-MM-DDTHH:mm');

      endDateTime = moment(dateTime)
        .set({
          hours: parseInt(endTimeArray[0]),
          minutes: parseInt(endTimeArray[1]),
          second: 0,
        })
        .format('YYYY-MM-DDTHH:mm');
    } else {
      startDateTime =
        moment(dateTime)
          .set({
            hours: parseInt(startTimeArray[0]),
            minutes: parseInt(startTimeArray[1]),
            second: 0,
          })
          .format('YYYY-MM-DDTHH:mm:ss') + tz;

      endDateTime =
        moment(dateTime)
          .set({
            hours: parseInt(endTimeArray[0]),
            minutes: parseInt(endTimeArray[1]),
            second: 0,
          })
          .format('YYYY-MM-DDTHH:mm:ss') + tz;
    }

    let obj = {
      ...this.data,
      ...this.addEditForm.value,
      assignUserId: [this.selectUser?._id],
      startDateTime,
      endDateTime,
    };

    let isInstantNotificationEmail = false,
      isInstantNotificationSms = false,
      isMyRemindNotificationEmail = false,
      isMyRemindNotificationSms = false;

    if (isInstantNotification) {
      if (isInstantNotificationType == 0) {
        isInstantNotificationEmail = true;
        isInstantNotificationSms = true;
      } else if (isInstantNotificationType == 1) {
        isInstantNotificationEmail = false;
        isInstantNotificationSms = true;

        obj['emailRemindTime'] = 0;
        obj['emailTemplateId'] = '';
      } else if (isInstantNotificationType == 2) {
        isInstantNotificationEmail = true;
        isInstantNotificationSms = false;

        obj['smsRemindTime'] = 0;
        obj['remindSmsMessage'] = '';
      }
    }

    if (isMyRemindNotification) {
      if (isMyRemindNotificationType == 0) {
        isMyRemindNotificationEmail = true;
        isMyRemindNotificationSms = true;
      } else if (isMyRemindNotificationType == 1) {
        isMyRemindNotificationEmail = false;
        isMyRemindNotificationSms = true;

        obj['emailMyRemindTime'] = 0;
      } else if (isMyRemindNotificationType == 2) {
        isMyRemindNotificationEmail = true;
        isMyRemindNotificationSms = false;

        obj['smsMyRemindTime'] = 0;
      }
    }

    obj = {
      ...obj,
      isInstantNotificationEmail,
      isInstantNotificationSms,
      isMyRemindNotificationEmail,
      isMyRemindNotificationSms,
    };

    delete obj['currentUser'];
    delete obj['dateTime'];
    delete obj['isInstantNotificationType'];
    delete obj['availability'];
    delete obj['secondaryContacts'];
    delete obj['details'];
    delete obj['isMyRemindNotificationType'];
    delete obj['leadInfo'];
    delete obj['teams'];

    if (obj['webFormLink']) delete obj['webFormLink'];
    if (obj['markets']) delete obj['markets'];
    if (obj['currentMarket']) delete obj['currentMarket'];
    if (obj['numberId']) delete obj['numberId'];

    Object.keys(obj).forEach((key) => {
      if (
        key == 'secondaryContact' &&
        typeof obj[key] === 'object' &&
        obj[key]?.length == 0
      ) {
        delete obj[key];
      }
      if (obj[key] === '' || obj[key] === null) delete obj[key];
      if (Array.isArray(obj[key])) {
        obj[key].forEach((subKey) => {
          Object.keys(subKey).forEach(
            (innerKey) =>
              (subKey[innerKey] == '' || subKey[innerKey] == null) &&
              delete subKey[innerKey]
          );
        });

        obj[key].forEach((subKey) => {
          if (!Object.keys(subKey).length) delete obj[key];
        });
      } else {
        if (typeof obj[key] === 'object') {
          Object.keys(obj[key]).forEach((subKey) => {
            if (obj[key][subKey] == '' || obj[key][subKey] == null) {
              delete obj[key][subKey];
            }
          });

          if (!Object.keys(obj[key]).length) delete obj[key];
        }
      }
    });

    if (!this.data.subModuleId) {
      this._dialogRef.close({ ...obj, teamData: this.teamsData.assignUser });
      return;
    }

    this._loaderService.start();
    this._sharedServices
      .saveAppointment(obj, this.data?.details, this.data?.webFormLink)
      .subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          if (response.statusCode == 200) {
            if (this.isDialog) {
              this._dialogRef.close(true);
              if (this.data?.details)
                this._toastrService.success(
                  this.messageConstant.customSectionUpdateSuccess
                    .replace('[[type]]', 'Appointment')
                    .replace('[[status]]', 'Updated')
                );
              this._sharedServices.refreshActivityLog.next(true);
              this._sharedServices.refreshInfo.next(true);
            } else {
              this._emitter.emit(startDateTime);
            }
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
  }

  notifySeller() {
    const { isInstantNotification, isInstantNotificationType } =
      this.addEditForm.value;
    if (isInstantNotification) {
      if (isInstantNotificationType === '0') {
        this.setValidators([
          'smsTemplateId',
          'emailTemplateId',
          'secondaryContact',
        ]);
      } else if (isInstantNotificationType === '1') {
        this.unsetValidators(['emailTemplateId']);
        this.setValidators(['smsTemplateId', 'secondaryContact']);
      } else if (isInstantNotificationType === '2') {
        this.unsetValidators(['smsTemplateId', 'remindSmsMessage']);
        this.setValidators(['emailTemplateId', 'secondaryContact']);
      }
    } else {
      this.unsetValidators([
        'smsTemplateId',
        'remindSmsMessage',
        'emailTemplateId',
        'secondaryContact',
      ]);
    }
  }

  setValidators(fields) {
    fields.filter((field) => {
      this.addEditForm.get(field).setValidators([Validators.required]);
    });
  }

  unsetValidators(fields) {
    fields.filter((field) => {
      this.addEditForm.get(field).clearValidators();
      this.addEditForm.get(field).setErrors(null);
      this.addEditForm.get(field).setValue('');
    });
  }

  isSellerAppointmentVisible() {
    const type = this.appointmentTypes.find(
      (x) => x.label === 'Seller Appointment'
    );

    return !!(type.value == this.addEditForm.value.appointmentType);
  }

  changeAppointmentType() {
    if (this.addEditForm.value.appointmentType === 0) {
      this.setValidators(['appointmentSubType']);
    } else {
      this.unsetValidators(['appointmentSubType']);
    }
  }
}
