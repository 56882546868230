<form [formGroup]="dripAutomationForm" autocomplete="off">
  <ng-container *ngIf="isAllowManageNumberFeature">
    <div class="form-group">
      <div class="re-radiosec">
        <p class="re-radotext">
          Auto assign a Drip to leads created under this campaign?
        </p>
        <label class="re-switch">
          <input
            type="checkbox"
            id="dripcamp"
            formControlName="isAutoAssignDrip"
            (change)="onAutoAssignDrip()"
          />
          <span class="re-slider"></span>
          <span value="false" class="re-text1">No</span>
          <span value="true" class="re-text2">Yes</span>
        </label>
      </div>
      <hr />
    </div>

    <div
      class="re-tasks-cont"
      *ngIf="dripAutomationForm?.value?.isAutoAssignDrip"
    >
      <div class="tasktitle">
        <a href="javascript:void(0)" (click)="toggleSection('leadManually')">
          <span class="re-name-txt">Leads created manually</span>
          <!-- <span class="re-assign-txt"
            >Enabled • XYZ Follow-up sequence • Drip stop configured</span
          > -->
          <span class="re-arrows" *ngIf="!isVisible('leadManually')">
            <i class="fa fa-chevron-circle-up re-teals" aria-hidden="true"></i>
          </span>
          <span class="re-arrows" *ngIf="isVisible('leadManually')">
            <i
              class="fa fa-chevron-circle-down re-blacks"
              aria-hidden="true"
            ></i>
          </span>
        </a>
      </div>

      <div class="row pb-3" [class.collapse]="isVisible('leadManually')">
        <div class="col-12 col-md-12">
          <div class="re-radiosec">
            <p class="re-radotext">Would you like to enable this</p>
            <label class="re-switch">
              <input
                type="checkbox"
                id="leadcreate"
                formControlName="isLeadCreatedManual"
                (change)="onLeadCreatedManual()"
              />
              <span class="re-slider"></span>
              <span value="false" class="re-text1">Off</span>
              <span value="true" class="re-text2">On</span>
            </label>
          </div>
          <div *ngIf="dripAutomationForm?.value?.isLeadCreatedManual">
            <div class="form-group">
              <label class="labels">Choose auto follow-up sequence * </label>
              <select
                class="form-control"
                formControlName="manualLeadDripId"
                (change)="onLeadCreatedManual()"
              >
                <option value="null">--Select drip campaign sequence--</option>
                <option *ngFor="let drip of dripList" value="{{ drip._id }}">
                  {{ drip.name }}
                </option>
              </select>
            </div>

            <div class="re-radiosec">
              <p class="re-radotext">
                Would you like to stop the drip campaign once you receive a Call
                or SMS from the lead?
              </p>
              <div class="re-radiolist">
                <label class="re-radiobtn">
                  <input
                    type="radio"
                    name="isManualLeadAutoStopDrip"
                    [value]="true"
                    formControlName="isManualLeadAutoStopDrip"
                    id="inlineRadio1"
                    (change)="callEmitForm()"
                  />
                  <div class="form-check-label re-no-margin" for="inlineRadio1">
                    Yes
                  </div>
                  <span class="re-checkmark"></span>
                </label>
                <label class="re-radiobtn">
                  <input
                    type="radio"
                    name="isManualLeadAutoStopDrip"
                    [value]="false"
                    formControlName="isManualLeadAutoStopDrip"
                    id="inlineRadio2"
                    (change)="callEmitForm()"
                  />
                  <div class="form-check-label re-no-margin" for="inlineRadio2">
                    No
                  </div>
                  <span class="re-checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Unanswered-->
    <div
      class="re-tasks-cont"
      *ngIf="dripAutomationForm?.value?.isAutoAssignDrip"
    >
      <div class="tasktitle">
        <a href="javascript:void(0)" (click)="toggleSection('leadUnAnswered')">
          <span class="re-name-txt"
            >Leads created from incoming UN-ANSWERED calls</span
          >
          <!-- <span class="re-assign-txt"
            >Enabled • ABC Follow-up sequence • Drip stop not configured</span
          > -->
          <span class="re-arrows" *ngIf="!isVisible('leadUnAnswered')">
            <i class="fa fa-chevron-circle-up re-teals" aria-hidden="true"></i>
          </span>
          <span class="re-arrows" *ngIf="isVisible('leadUnAnswered')">
            <i
              class="fa fa-chevron-circle-down re-blacks"
              aria-hidden="true"
            ></i>
          </span>
        </a>
      </div>
      <div class="row pb-3" [class.collapse]="isVisible('leadUnAnswered')">
        <div class="col-12 col-md-12">
          <div class="re-radiosec">
            <p class="re-radotext">Would you like to enable this</p>
            <label class="re-switch">
              <input
                type="checkbox"
                id="leadCreateunans"
                formControlName="isDripUnansCall"
                (change)="onDripUnansCall()"
              />
              <span class="re-slider"></span>
              <span value="false" class="re-text1">Off</span>
              <span value="true" class="re-text2">On</span>
            </label>
          </div>

          <div *ngIf="dripAutomationForm?.value?.isDripUnansCall">
            <div class="form-group">
              <label class="labels">Choose auto follow-up sequence * </label>
              <select
                class="form-control"
                formControlName="unansDripId"
                (change)="onDripUnansCall()"
              >
                <option value="null">--Select drip campaign sequence--</option>
                <option *ngFor="let drip of dripList" value="{{ drip._id }}">
                  {{ drip.name }}
                </option>
              </select>
            </div>
            <div class="re-radiosec">
              <p class="re-radotext">
                Would you like to stop the drip campaign once you receive a Call
                or SMS from the lead?
              </p>
              <div class="re-radiolist">
                <label class="re-radiobtn">
                  <input
                    type="radio"
                    name="isUnansAutoStopDrip"
                    [value]="true"
                    formControlName="isUnansAutoStopDrip"
                    id="inlineRadio1"
                    (change)="callEmitForm()"
                  />
                  <div class="form-check-label re-no-margin" for="inlineRadio1">
                    Yes
                  </div>
                  <span class="re-checkmark"></span>
                </label>
                <label class="re-radiobtn">
                  <input
                    type="radio"
                    name="isUnansAutoStopDrip"
                    [value]="false"
                    formControlName="isUnansAutoStopDrip"
                    id="inlineRadio2"
                    (change)="callEmitForm()"
                  />
                  <div class="form-check-label re-no-margin" for="inlineRadio2">
                    No
                  </div>
                  <span class="re-checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Answered-->
    <div
      class="re-tasks-cont"
      *ngIf="dripAutomationForm?.value?.isAutoAssignDrip"
    >
      <div class="tasktitle">
        <a href="javascript:void(0)" (click)="toggleSection('leadAnswered')">
          <span class="re-name-txt"
            >Leads created from incoming ANSWERED calls</span
          >
          <!-- <span class="re-assign-txt"
            >Enabled • ABC Follow-up sequence • Drip stop not configured</span
          > -->
          <span class="re-arrows" *ngIf="!isVisible('leadAnswered')">
            <i class="fa fa-chevron-circle-up re-teals" aria-hidden="true"></i>
          </span>
          <span class="re-arrows" *ngIf="isVisible('leadAnswered')">
            <i
              class="fa fa-chevron-circle-down re-blacks"
              aria-hidden="true"
            ></i>
          </span>
        </a>
      </div>
      <div class="row pb-3" [class.collapse]="isVisible('leadAnswered')">
        <div class="col-12 col-md-12">
          <div class="re-radiosec">
            <p class="re-radotext">Would you like to enable this</p>
            <label class="re-switch">
              <input
                type="checkbox"
                id="leadcreateans"
                formControlName="isDripAnsCall"
                (change)="onDripAnsCall()"
              />
              <span class="re-slider"></span>
              <span value="false" class="re-text1">Off</span>
              <span value="true" class="re-text2">On</span>
            </label>
          </div>
          <div *ngIf="dripAutomationForm?.value?.isDripAnsCall">
            <div class="form-group">
              <label class="labels">Choose auto follow-up sequence * </label>
              <select
                class="form-control"
                formControlName="ansDripId"
                (change)="onDripAnsCall()"
              >
                <option value="null">--Select drip campaign sequence--</option>
                <option *ngFor="let drip of dripList" value="{{ drip._id }}">
                  {{ drip.name }}
                </option>
              </select>
            </div>
            <div class="re-radiosec">
              <p class="re-radotext">
                Would you like to stop the drip campaign once you receive a Call
                or SMS from the lead?
              </p>
              <div class="re-radiolist">
                <label class="re-radiobtn">
                  <input
                    type="radio"
                    name="isAnsAutoStopDrip"
                    [value]="true"
                    formControlName="isAnsAutoStopDrip"
                    id="inlineRadio1"
                    (change)="callEmitForm()"
                  />
                  <div class="form-check-label re-no-margin" for="inlineRadio1">
                    Yes
                  </div>
                  <span class="re-checkmark"></span>
                </label>
                <label class="re-radiobtn">
                  <input
                    type="radio"
                    name="isAnsAutoStopDrip"
                    [value]="false"
                    formControlName="isAnsAutoStopDrip"
                    id="inlineRadio2"
                    (change)="callEmitForm()"
                  />
                  <div class="form-check-label re-no-margin" for="inlineRadio2">
                    No
                  </div>
                  <span class="re-checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Integration-->
    <div
      class="re-tasks-cont"
      *ngIf="dripAutomationForm?.value?.isAutoAssignDrip"
    >
      <div class="tasktitle">
        <a href="javascript:void(0)" (click)="toggleSection('leadIntegration')">
          <span class="re-name-txt"
            >Leads created from integration(Zapier, Carrot, etc.)</span
          >
          <!-- <span class="re-assign-txt"
            >Enabled • ABC Follow-up sequence • Drip stop not configured</span
          > -->
          <span class="re-arrows" *ngIf="!isVisible('leadIntegration')">
            <i class="fa fa-chevron-circle-up re-teals" aria-hidden="true"></i>
          </span>
          <span class="re-arrows" *ngIf="isVisible('leadIntegration')">
            <i
              class="fa fa-chevron-circle-down re-blacks"
              aria-hidden="true"
            ></i>
          </span>
        </a>
      </div>
      <div class="row pb-3" [class.collapse]="isVisible('leadIntegration')">
        <div class="col-12 col-md-12">
          <div class="re-radiosec">
            <p class="re-radotext">Would you like to enable this</p>
            <label class="re-switch">
              <input
                type="checkbox"
                id="leadCreatezap"
                formControlName="isDripZap"
                (change)="onDripZap()"
              />
              <span class="re-slider"></span>
              <span value="false" class="re-text1">Off</span>
              <span value="true" class="re-text2">On</span>
            </label>
          </div>

          <div *ngIf="dripAutomationForm?.value?.isDripZap">
            <div class="form-group">
              <label class="labels">Choose auto follow-up sequence * </label>
              <select
                class="form-control"
                formControlName="zapDripId"
                (change)="onDripZap()"
              >
                <option value="null">--Select drip campaign sequence--</option>
                <option *ngFor="let drip of dripList" value="{{ drip._id }}">
                  {{ drip.name }}
                </option>
              </select>
            </div>
            <div class="re-radiosec">
              <p class="re-radotext">
                Would you like to stop the drip campaign once you receive a Call
                or SMS from the lead?
              </p>
              <div class="re-radiolist">
                <label class="re-radiobtn">
                  <input
                    type="radio"
                    name="isZapAutoStopDrip"
                    [value]="true"
                    formControlName="isZapAutoStopDrip"
                    id="inlineRadio1"
                    (change)="callEmitForm()"
                  />
                  <div class="form-check-label re-no-margin" for="inlineRadio1">
                    Yes
                  </div>
                  <span class="re-checkmark"></span>
                </label>
                <label class="re-radiobtn">
                  <input
                    type="radio"
                    name="isZapAutoStopDrip"
                    [value]="false"
                    formControlName="isZapAutoStopDrip"
                    id="inlineRadio2"
                    (change)="callEmitForm()"
                  />
                  <div class="form-check-label re-no-margin" for="inlineRadio2">
                    No
                  </div>
                  <span class="re-checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Web form-->
    <div
      class="re-tasks-cont"
      *ngIf="dripAutomationForm?.value?.isAutoAssignDrip"
    >
      <div class="tasktitle">
        <a href="javascript:void(0)" (click)="toggleSection('leadWebForm')">
          <span class="re-name-txt">Leads created from Web Forms</span>
          <!-- <span class="re-assign-txt"
            >Enabled • ABC Follow-up sequence • Drip stop not configured</span
          > -->
          <span class="re-arrows" *ngIf="!isVisible('leadWebForm')">
            <i class="fa fa-chevron-circle-up re-teals" aria-hidden="true"></i>
          </span>
          <span class="re-arrows" *ngIf="isVisible('leadWebForm')">
            <i
              class="fa fa-chevron-circle-down re-blacks"
              aria-hidden="true"
            ></i>
          </span>
        </a>
      </div>
      <div class="row pb-3" [class.collapse]="isVisible('leadWebForm')">
        <div class="col-12 col-md-12">
          <div class="re-radiosec">
            <p class="re-radotext">Would you like to enable this</p>
            <label class="re-switch">
              <input
                type="checkbox"
                id="leadCreateWebForm"
                formControlName="isDripWebForm"
                (change)="onDripWebForm()"
              />
              <span class="re-slider"></span>
              <span value="false" class="re-text1">Off</span>
              <span value="true" class="re-text2">On</span>
            </label>
          </div>

          <div *ngIf="dripAutomationForm?.value?.isDripWebForm">
            <div class="form-group">
              <label class="labels">Choose auto follow-up sequence * </label>
              <select
                class="form-control"
                formControlName="webFormDripId"
                (change)="onDripWebForm()"
              >
                <option value="null">--Select drip campaign sequence--</option>
                <option *ngFor="let drip of dripList" value="{{ drip._id }}">
                  {{ drip.name }}
                </option>
              </select>
            </div>
            <div class="re-radiosec">
              <p class="re-radotext">
                Would you like to stop the drip campaign once you receive a Call
                or SMS from the lead?
              </p>
              <div class="re-radiolist">
                <label class="re-radiobtn">
                  <input
                    type="radio"
                    name="isWebFormAutoStopDrip"
                    [value]="true"
                    formControlName="isWebFormAutoStopDrip"
                    id="inlineRadio1"
                    (change)="callEmitForm()"
                  />
                  <div class="form-check-label re-no-margin" for="inlineRadio1">
                    Yes
                  </div>
                  <span class="re-checkmark"></span>
                </label>
                <label class="re-radiobtn">
                  <input
                    type="radio"
                    name="isWebFormAutoStopDrip"
                    [value]="false"
                    formControlName="isWebFormAutoStopDrip"
                    id="inlineRadio2"
                    (change)="callEmitForm()"
                  />
                  <div class="form-check-label re-no-margin" for="inlineRadio2">
                    No
                  </div>
                  <span class="re-checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Web Site-->
    <div
      class="re-tasks-cont"
      *ngIf="dripAutomationForm?.value?.isAutoAssignDrip"
    >
      <div class="tasktitle">
        <a href="javascript:void(0)" (click)="toggleSection('leadWebSite')">
          <span class="re-name-txt">Leads created from REsimpli Website </span>
          <!-- <span class="re-assign-txt"
            >Enabled • ABC Follow-up sequence • Drip stop not configured</span
          > -->
          <span class="re-arrows" *ngIf="!isVisible('leadWebSite')">
            <i class="fa fa-chevron-circle-up re-teals" aria-hidden="true"></i>
          </span>
          <span class="re-arrows" *ngIf="isVisible('leadWebSite')">
            <i
              class="fa fa-chevron-circle-down re-blacks"
              aria-hidden="true"
            ></i>
          </span>
        </a>
      </div>
      <div class="row pb-3" [class.collapse]="isVisible('leadWebSite')">
        <div class="col-12 col-md-12">
          <div class="re-radiosec">
            <p class="re-radotext">Would you like to enable this</p>
            <label class="re-switch">
              <input
                type="checkbox"
                id="leadCreateWebSite"
                formControlName="isDripWebSite"
                (change)="onDripWebSite()"
              />
              <span class="re-slider"></span>
              <span value="false" class="re-text1">Off</span>
              <span value="true" class="re-text2">On</span>
            </label>
          </div>

          <div *ngIf="dripAutomationForm?.value?.isDripWebSite">
            <div class="form-group">
              <label class="labels">Choose auto follow-up sequence * </label>
              <select
                class="form-control"
                formControlName="webSiteDripId"
                (change)="onDripWebForm()"
              >
                <option value="null">--Select drip campaign sequence--</option>
                <option *ngFor="let drip of dripList" value="{{ drip._id }}">
                  {{ drip.name }}
                </option>
              </select>
            </div>
            <div class="re-radiosec">
              <p class="re-radotext">
                Would you like to stop the drip campaign once you receive a Call
                or SMS from the lead?
              </p>
              <div class="re-radiolist">
                <label class="re-radiobtn">
                  <input
                    type="radio"
                    name="isWebSiteAutoStopDrip"
                    [value]="true"
                    formControlName="isWebSiteAutoStopDrip"
                    id="inlineRadio1"
                    (change)="callEmitForm()"
                  />
                  <div class="form-check-label re-no-margin" for="inlineRadio1">
                    Yes
                  </div>
                  <span class="re-checkmark"></span>
                </label>
                <label class="re-radiobtn">
                  <input
                    type="radio"
                    name="isWebSiteAutoStopDrip"
                    [value]="false"
                    formControlName="isWebSiteAutoStopDrip"
                    id="inlineRadio2"
                    (change)="callEmitForm()"
                  />
                  <div class="form-check-label re-no-margin" for="inlineRadio2">
                    No
                  </div>
                  <span class="re-checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="re-unauthorized" *ngIf="isAllowManageNumberFeature == false">
    <img
      class="img-fluid"
      src="assets/images/unauthrozied.png"
      alt="unauthrozied"
    />
    <h4>Upgrade Your Account</h4>
    <div class="re-text">
      Automations are only available on <u>Pro</u> and <u>Enterprise</u> plans.
      <u>Automate your leads follow ups</u> by upgrading your plan.
    </div>
  </div>
</form>
