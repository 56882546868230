<div class="re-top-head">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      Update Number
    </h2>
    <span mat-dialog-close style="float: right; cursor: pointer">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<form
  class="re-payment-form"
  [formGroup]="numberForm"
  (ngSubmit)="onSubmit()"
  autocomplete="off"
>
  <mat-dialog-content>
    <div class="container-fluid mt-3">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <label class="re-labels">Phone Number</label>
            <input
              type="text"
              class="form-control"
              [value]="_utilities.maskNumber(data.details.number)"
              [attr.disabled]="isMarketReadonly ? 'disabled' : null"
            />
          </div>
        </div>
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <label class="re-labels"
              >Part of Group(s) <span class="text-danger">*</span></label
            >
            <select
              class="form-control"
              formControlName="groupId"
              name="groupId"
            >
              <option value="">Select group</option>
              <option
                *ngFor="let groupData of groupNumberList"
                [value]="groupData._id"
              >
                {{ groupData.title }}
              </option>
            </select>
            <div
              *ngIf="
                (submitted && f.groupId.errors) ||
                (f.groupId.errors && (f.groupId.dirty || f.groupId.touched))
              "
              class="text-danger"
            >
              <span *ngIf="f.groupId.errors.required">
                Select group is required
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <label class="re-labels" for="inputflowName2">
              Call Flow Name <span class="text-danger">*</span>
            </label>

            <select class="form-control" formControlName="flowId">
              <option value="">Select Flow</option>
              <option *ngFor="let eachFlow of flowList" [value]="eachFlow._id">
                {{ eachFlow.name }}
              </option>
            </select>
            <div
              *ngIf="
                (submitted && f.flowId.errors) ||
                (f.flowId.errors && (f.flowId.dirty || f.flowId.touched))
              "
              class="text-danger"
            >
              <span *ngIf="f.flowId.errors.required">
                Flow Name is required
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center" style="padding-top: 14px !important">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button type="submit" mat-flat-button color="primary" cdkFocusInitial>
      Update Number
    </button>
  </mat-dialog-actions>
</form>
