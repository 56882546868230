import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({
  selector: 'app-call-transcript-credit-manage',
  templateUrl: './call-transcript-credit-manage.component.html',
  styleUrls: ['./call-transcript-credit-manage.component.scss'],
})
export class CallTranscriptCreditManageComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _router: Router,
    private dialog: MatDialogRef<CallTranscriptCreditManageComponent>
  ) {}
  buyCredit() {
    this.dialog.close();
    this._router.navigate(['/credit/buy-credit']);
  }
}
