import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

// UTILS
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';
import { SideBarListModel } from 'src/app/utils/models/sidebar';
import { MessageConstant } from 'src/app/utils/message-constant';

// COMPONENTS
import { GettingStartedUserProgressComponent } from 'src/app/shared/dialog/getting-started-user-progress/getting-started-user-progress.component';

// SERVICES
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { DashboardService } from 'src/app/providers/dashboard/dashboard.service';
import { UserService } from 'src/app/providers/user/user.service';

declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  messageConstant = MessageConstant;
  dialogRef: any;
  sideMenu: SideBarListModel[] = [];
  isDirectMailFreeUser: boolean = false;
  isCall: Boolean = false;
  isMail: Boolean = false;
  isAssistance: Boolean = false;
  showHelpDesk: Boolean = false;

  constructor(
    public _utilities: CommonFunctionsService,
    private _dashboardService: DashboardService,
    private _toastrService: ToastrService,
    private _userService: UserService,
    private _loaderService: NgxUiLoaderService,
    private _router: Router,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (
      localStorage?.isDirectMailFreeUser == true ||
      localStorage?.isDirectMailFreeUser == 'true'
    ) {
      this.isDirectMailFreeUser = true;
      this.sideMenu.push(
        // MARKETING
        {
          icon: '/assets/images/navbar/marketing.svg',
          label: 'Marketing',
          subMenu: [
            {
              icon: '/assets/images/navbar/direct-mail.svg',
              label: 'Direct Mail',
              route: '/direct-mail',
            },
          ],
        }
      );
    } else {
      this.sideMenu.push(
        // INBOX
        // {
        //   icon: '/assets/images/navbar/inbox.svg',
        //   label: 'Inbox',
        //   subMenu: [
        //     {
        //       icon: '/assets/images/navbar/inbox.svg',
        //       label: 'Inbox',
        //       route: '/web-inbox',
        //     },
        //   ],
        // },

        // DATA
        {
          icon: '/assets/images/navbar/data.svg',
          label: 'Data',
          subMenu: [
            {
              icon: '/assets/images/navbar/kpi.svg',
              label: 'KPIs',
              route: '/kpis',
            },
            {
              icon: '/assets/images/navbar/leaderboard.svg',
              label: 'Leaderboard',
              route: '/dashboard/leaderboard',
            },
            {
              icon: '/assets/images/navbar/kpi.svg',
              label: 'Seller Appointment KPIs',
              route: '/kpis/appointments',
            },
            // {
            //   icon: '/assets/images/navbar/kpi.svg',
            //   label: 'KPIs Comparision',
            //   route: '/kpis/kpi-comparision',
            // },
          ],
        },

        // MARKETING
        {
          icon: '/assets/images/navbar/marketing.svg',
          label: 'Marketing',
          subMenu: [
            {
              icon: '/assets/images/navbar/Liststackig-navigation-default.svg',
              label: 'List Stacking',
              route: '/list-stacking',
            },
            {
              icon: '/assets/images/navbar/direct-mail.svg',
              label: 'Direct Mail',
              route: '/direct-mail',
            },
            {
              icon: '/assets/images/navbar/dialer-gray.svg',
              label: 'Dialer',
              route: '/dialer',
            },
            {
              icon: '/assets/images/navbar/Websites.svg',
              label: 'Website',
              route: '/link',
            },
          ],
        },

        // LEADS
        {
          icon: '/assets/images/navbar/leads-gray1.svg',
          label: 'Leads',
          subMenu: [
            {
              icon: '/assets/images/navbar/leads.svg',
              label: 'Active Leads',
              route: '/leads',
            },
            {
              icon: '/assets/images/navbar/warm-leads.svg',
              label: 'Warm Leads',
              route: '/leads',
              queryParams: { leadType: 'warm' },
            },
            {
              icon: '/assets/images/navbar/dead-leads.svg',
              label: 'Dead Leads',
              route: '/leads',
              queryParams: { leadType: 'dead' },
            },
            {
              icon: '/assets/images/navbar/Refer-agent-v2-default.svg',
              label: 'Referred To Agent',
              route: '/leads',
              queryParams: { leadType: 'referredtoagent' },
            },
            {
              icon: '/assets/images/navbar/wholesale-default.svg',
              label: 'Wholesale Pipeline',
              route: '/wholesale-pipeline',
            },
          ],
        },

        // PROPERTIES
        {
          icon: '/assets/images/navbar/properties.svg',
          label: 'Properties',
          subMenu: [
            {
              icon: '/assets/images/navbar/inventories.svg',
              label: 'Inventory',
              route: '/inventory',
            },
            {
              icon: '/assets/images/navbar/sold.svg',
              label: 'Sold',
              route: '/sold',
            },
            {
              icon: '/assets/images/navbar/rental.svg',
              label: 'Rentals',
              route: '/rental',
            },
          ],
        },

        // ACTIVITY
        {
          icon: '/assets/images/navbar/activity.svg',
          label: 'Activity',
          subMenu: [
            {
              icon: '/assets/images/navbar/call-logs.svg',
              label: 'Ongoing (Beta)',
              route: '/logs/call',
              queryParams: { type: 'Ongoing' },
            },
            {
              icon: '/assets/images/navbar/call-logs.svg',
              label: 'Call Logs',
              route: '/logs/call',
            },
            {
              icon: '/assets/images/navbar/schedule-sms.svg',
              label: 'Scheduled SMS',
              route: '/my-scheduled-sms',
            },
            {
              icon: '/assets/images/navbar/tasks.svg',
              label: 'Tasks',
              route: '/my-tasks',
            },
            // {
            //   icon: '/assets/images/navbar/sms.svg',
            //   label: 'Email Inbox',
            //   route: '/email-account',
            // },
            // {
            //   icon: '/assets/images/navbar/side-inbox.svg',
            //   label: 'Inbox',
            //   route: '/web-inbox',
            // },
            // {
            //   icon: '/assets/images/navbar/tasks.svg',
            //   label: 'Global Tasks',
            //   route: '/global-tasks',
            // },
          ],
        },

        // CONTACTS
        {
          icon: '/assets/images/navbar/contact.svg',
          label: 'Contacts',
          subMenu: [
            {
              icon: '/assets/images/navbar/buyers.svg',
              label: 'Buyers',
              route: '/buyers',
              queryParams: { ['contact']: 1 },
            },
            // {
            //   icon: '/assets/images/navbar/contractor.svg',
            //   label: 'Contractor',
            //   route: '/contractor',
            // },
            {
              icon: '/assets/images/navbar/vendors.svg',
              label: 'Vendors',
              route: '/vendors',
              queryParams: { ['v']: 1 },
            },
          ],
        },

        // GENERAL
        // {
        //   icon: '/assets/images/navbar/general.svg',
        //   label: 'General',
        //   subMenu: [
        //     // {
        //     //   icon: '/assets/images/navbar/drip-campaign.svg',
        //     //   label: 'Drip Campaign',
        //     //   route: '/drip-campaigns',
        //     // },
        //     // {
        //     //   icon: '/assets/images/navbar/web-forms.svg',
        //     //   label: 'Web Forms',
        //     //   route: '/public/web-form',
        //     //   // target: '_blank',
        //     // },
        //     // {
        //     //   icon: '/assets/images/navbar/e-sign.svg',
        //     //   label: 'e-Sign',
        //     //   route: '/esign/Template',
        //     // },
        //     // {
        //     //   icon: '/assets/images/general-files.svg',
        //     //   label: 'General Files',
        //     //   route: '/general-files',
        //     // },
        //     {
        //       icon: '/assets/images/navbar/sms.svg',
        //       label: 'Email Account',
        //       route: '/email-account',
        //     },
        //     // {
        //     //   icon: '/assets/images/navbar/direct-mail.svg',
        //     //   label: 'Inbox',
        //     //   route: '/inbox',
        //     // },
        //   ],
        // },

        // FINANCIALS
        {
          icon: '/assets/images/navbar/financials.svg',
          label: 'Financials',
          subMenu: [
            {
              icon: '/assets/images/navbar/banking.svg',
              label: 'Banking',
              route: '/accounting/account-list',
            },
            {
              icon: '/assets/images/navbar/vendors.svg',
              label: 'Vendors',
              route: '/accounting/vendors',
            },
            {
              icon: '/assets/images/navbar/properties.svg',
              label: 'Properties',
              route: '/accounting/properties',
            },
            {
              icon: '/assets/images/navbar/accounts.svg',
              label: 'Account Tags',
              route: '/accounting/account-tags',
            },
            {
              icon: '/assets/images/navbar/Report-icon.svg',
              label: 'Reports',
              route: '/accounting/report-list',
            },
          ],
        }
      );
    }

    $(function () {
      $('.sidebar-nav .droplist').on('mouseenter mouseleave', function () {
        if ($('.dropdown-menu', this).length) {
          var elm = $('.dropdown-menu', this);
          var off = elm.offset();
          var l = off.top;
          var w = elm.width();
          var docH = $('.sidebar-wrapper').height();
          var docW = $('.sidebar-wrapper').width();

          var isEntirelyVisible = l + w <= docH;

          if (!isEntirelyVisible) {
            $(this).addClass('is-about');
          } else {
            $(this).removeClass('is-about');
          }
        }
      });
    });

    this.getActivityBadge();
  }

  getActivityBadge() {
    let param = {
      browserTimeZoneOffset: this._utilities.dueDateFormat().browserTimezone,
      countType: 3,
    };

    this._dashboardService.getActivityBadgeCount(param).subscribe(
      (response: ResponseModel) => {
        this._utilities.newCount = response?.data;
        this._utilities.newCount1 = response?.data;
      },
      (err: ErrorModel) => {}
    );
  }

  closePopOver() {
    $('.popover_close').parents('.popover').popover('hide');
    $('body .infinite-scroll').removeClass('re-scroll-hide');
    $('body .re-card').removeClass('re-open-card');
  }
  checkPermission() {
    if (
      !this._utilities.userAccessData?.isListStackingDialerAccess &&
      !this._utilities.userAccessData?.isLSDManagerAccess
    ) {
      this._router.navigate([`/permission-denied`], {});
      return;
    }
    // this._router.navigate([`/dialer`]);
  }

  checkActive(subMenu) {
    const { pathname } = window.location;

    if (pathname.includes('detail')) {
      if (pathname.includes(subMenu.route) && !subMenu.queryParams) return true;
    }

    return false;
  }
  checkSettingActive() {
    const { pathname } = window.location;
    if (pathname.includes('/settings')) return true;
    return false;
  }

  onChatClick() {
    //userflow.openResourceCenter();
    this.showHelpDesk = !this.showHelpDesk;
  }

  onAction(action) {
    if (action == 'call') {
      this.isCall = true;
    } else if (action == 'mail') {
      this.isMail = true;
    } else if (action == 'back') {
      this.isMail = false;
      this.isCall = false;
    } else if (action == 'chat') {
      this.showHelpDesk = false;
    } else if (action == 'faq') {
      window.open('https://help.resimpli.com/en/', '_blank');
      this.showHelpDesk = false;
    } else if (action == 'affiliate') {
      window.open('https://resimpli.com/affiliate', '_blank');
      this.showHelpDesk = false;
    } else if (action == 'feature') {
      window.open(
        'https://docs.google.com/forms/d/e/1FAIpQLScq7XJDL6NjUj_2Y05810VWSCXS4oEsySAJq_U_6Y4BtZsFRg/viewform',
        '_blank'
      );
      this.showHelpDesk = false;
    } else if (action == 'assistance') {
      // if (this.isBasicUser()) {
      //   window.open(
      //     'https://calendly.com/d/3jm-kjj-2sn/onboarding-resimpli',
      //     '_blank'
      //   );
      // } else {
      //   window.open('https://resimpli.com/book-a-call/', '_blank');
      // }
      if (this._utilities.userData?.loginData?.isTrialPeriod) {
        window.open('https://resimpli.com/book-a-call/', '_blank');
      } else {
        window.open('https://resimpli.com/book-a-call/', '_blank');
      }

      // this.isAssistance = !this.isAssistance;
    } else if (action == 'videoTrainingLibrary') {
      this.getUserProgress();
      // window.open('https://course.resimpli.com/', '_blank');
      this.showHelpDesk = false;
    } else if (action == 'scheduleCall') {
      if (this._utilities.userData?.loginData?.isTrialPeriod) {
        window.open(
          'https://calendly.com/d/cpkp-hkv-vt6/onboarding-strategy-call',
          '_blank'
        );
      } else {
        window.open(
          'https://calendly.com/d/5kp-j6g-s7c/resimpli-onboarding-support',
          '_blank'
        );
      }
    }
  }

  onActionBook(action) {
    window.open(
      'https://calendly.com/' +
        action +
        '/60min?month=' +
        new Date().getFullYear().toString() +
        '-' +
        new Date().getMonth().toString(),
      '_blank'
    );
    this.showHelpDesk = false;
    this.isAssistance = false;
  }

  onLinkClick(subMenu) {
    $('.popover_close').parents('.popover').popover('hide');
    $('body .infinite-scroll').removeClass('re-scroll-hide');
    $('body .re-card').removeClass('re-open-card');
    if (subMenu?.label == 'Website') {
      this._loaderService.start();
      this._userService.getWPToken({}).subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          this.callWPWebsite(
            response.data?.wpToken,
            response.data?.userId,
            response.data?.beUrl
          );
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
    }
  }

  callWPWebsite(wpToken, userId, beUrl) {
    axios({
      method: 'post',
      url: beUrl, //'https://resimpliwebsites.com/wp-json/userinfo/login',
      data: {
        resimpliId: userId,
        wpToken: wpToken,
      },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(function (response) {
        if (response?.data?.data) {
          if (response?.data?.data?.length > 0) {
            let url = response.data?.data[0]?.loginURL;
            if (url) {
              window.open(url, '_blank');
            }
          }
        }
      })
      .catch(function (response) {});
  }

  isEnterpriseUser() {
    if (
      this._utilities.currentPlanData?._id == '60e98dae20d43c033073ba36' ||
      this._utilities.currentPlanData?._id == '60d44df3d8460702deea3930'
    ) {
      return true;
    }
    return false;
  }

  isBasicUser() {
    if (
      this._utilities.currentPlanData?._id == '60d44e0cd8460702deea3932' ||
      this._utilities.currentPlanData?._id == '5cda93957074b715e4d21919'
    ) {
      return true;
    }
    return false;
  }

  getUserProgress() {
    this._loaderService.start();

    this._userService.getUserProgress({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();

        this.getUserProgressUpdated(response, null, true);

        this._userService.getUserProgressListStacking({}).subscribe(
          (lisStackingResponse: ResponseModel) => {
            this.getUserProgressUpdated(response, lisStackingResponse, false);
          },
          (err: ErrorModel) => {}
        );
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getUserProgressUpdated(response, lisStackingResponse, isOpenPopup) {
    let progressData = response?.data?.data;
    let progressData1 = response?.data?.data;

    if (lisStackingResponse?.data?.data) {
      this._utilities.progressListStackingData =
        lisStackingResponse?.data?.data;
      if (this._utilities.progressListStackingData?.length > 1) {
        progressData?.map((item) => {
          if (item?.title == 'Data') {
            let valueArray = [];
            let listStackingObj = this._utilities.progressListStackingData[0];
            valueArray.push(listStackingObj);

            if (item?.value?.length > 0) {
              valueArray.push(item?.value[0]);
            }

            let skipTracingObj = this._utilities.progressListStackingData[1];
            valueArray.push(skipTracingObj);
            item.value = valueArray;
          }
        });
      }
    }

    let totalProgress = 0;
    let userProgress = 0;

    for (let i = 0; i < progressData.length; i++) {
      const obj = progressData[i];
      for (let j = 0; j < obj?.value?.length; j++) {
        const subObj = obj?.value[j];
        totalProgress = totalProgress + 1;
        if (subObj?.value) {
          userProgress = userProgress + 1;
        }
      }
    }
    this._utilities.userProgressPercentage = Math.round(
      (userProgress * 100) / totalProgress
    );

    // if (this._utilities.userProgressPercentage >= 100) {
    //   this.isShowUserProgress = false;
    // } else {
    //   this.isShowUserProgress = true;
    // }

    this._utilities.progressPercentage =
      this._utilities.userProgressPercentage + ',100';

    if (isOpenPopup) {
      this.dialogRef = this._dialog.open(GettingStartedUserProgressComponent, {
        width: '600px',
        data: {
          progressData: progressData1,
        },
      });
    }
  }

  isZoomEnable() {
    if (
      (this._utilities.currentPlanData?._id == '60d44e0cd8460702deea3932' ||
        this._utilities.currentPlanData?._id == '5cda93957074b715e4d21919') &&
      !this._utilities.currentPlanData?.isYearlyPlan
    ) {
      return false;
    }
    return true;
  }
}
