<mat-dialog-content class="">
  <span class="re-close" mat-dialog-close>
    <i class="fa fa-times-circle-o" aria-hidden="true"></i>
  </span>
  <div class="container">
    <div class="re-errortext">
      <img class="img" src="assets/images/warning-24x.png" alt="warning" />
      <ng-container *ngIf="data?.subModule === 'Market'; else else_block1">
        Are you sure you want to
        {{ data?.details?.isEnabled ? "disable" : "enable" }} this market?
      </ng-container>

      <ng-template #else_block1>
        <ng-container *ngIf="data?.subModule === 'primary'; else else_block">
          Are you sure you want to make this contact as primary contact? If you
          have a Drip Campaign active, the drip messages will be sent to the new
          primary contact.
        </ng-container>
      </ng-template>

      <ng-template #else_block>
        <p
          [class.re-call-delete]="data?.subModule == 'Call Flow'"
          *ngIf="!data?.customMessage"
        >
          Are you sure you want to delete this
          {{
            data?.deleteNumber == true
              ? "this"
              : _utilities.maskNumber(data?.deleteNumber)
          }}
          <ng-container
            *ngIf="
              data?.subModule === 'buyer' ||
                data?.subModule === 'vendor_management';
              else else_text
            "
          >
            <span *ngIf="data?.subModule === 'buyer'">
              {{ data?.details?.contactType?.toLowerCase() }}?
            </span>

            <span *ngIf="data?.subModule === 'vendor_management'">
              {{ data?.details?.firstName }} {{ data?.details?.lastName }}?
            </span>
          </ng-container>

          <ng-template #else_text> {{ data?.subModule }}? </ng-template>
        </p>
        <p *ngIf="data?.customMessage">
          {{ data?.customMessage }}
        </p>
        <p class="text-danger">This action can not be undone.</p>
      </ng-template>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
  <button (click)="submit()" mat-raised-button color="warn" cdkFocusInitial>
    <ng-container *ngIf="data?.subModule === 'Market'">
      {{ data?.details?.isEnabled ? "Disable" : "Enable" }}
      {{ data?.subModule }}
    </ng-container>

    <ng-container
      *ngIf="
        data?.subModule !== 'Market' &&
        data?.subModule != 'Call Flow' &&
        data?.subModule != 'buyer' &&
        data?.subModule != 'primary' &&
        data?.subModule != 'leadUnAssign' &&
        data?.subModule != 'vendor_management' &&
        data?.subModule != 'vendor_contact'
      "
    >
      Delete
      {{
        data?.subModule == "sms"
          ? "SMS"
          : data?.subModule == "blockNumber"
          ? "Number"
          : data?.subModule
      }}
    </ng-container>

    <ng-container *ngIf="data?.subModule === 'buyer'">
      Delete
      {{ data?.details?.contactType?.toLowerCase() }}?
    </ng-container>

    <ng-container *ngIf="data?.subModule === 'vendor_management'">
      Yes
    </ng-container>

    <ng-container *ngIf="data?.subModule === 'vendor_contact'">
      Delete
    </ng-container>

    <ng-container *ngIf="data?.subModule == 'leadUnAssign'">
      Unassign
    </ng-container>

    <ng-container *ngIf="data?.subModule == 'primary'">
      Make {{ data?.subModule }}
    </ng-container>

    <ng-container *ngIf="data?.subModule == 'Call Flow'"> Yes </ng-container>
  </button>
</mat-dialog-actions>
