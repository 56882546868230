import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialerService } from 'src/app/providers/dialer/dialer.service';
import { MasterTaskService } from 'src/app/providers/master-task/master-task.service';
import { ListStackingService } from 'src/app/providers/list-stacking/list-stacking.service';

//utils
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MessageConstant } from './../../../utils/message-constant';
import { ToastrService } from 'ngx-toastr';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dialer-change-campaign',
  templateUrl: './dialer-change-campaign.component.html',
  styleUrls: ['./dialer-change-campaign.component.scss'],
})
export class DialerChangeCampaignComponent implements OnInit {
  messageConstant = MessageConstant;
  public campaignName: string = '';
  public selectUser: string = '';
  public oldCampaignName: string = '';
  yesButtonTitle: string = 'Update';
  currentPage: number = 1;
  currentLimit: number = 50;
  users: any[] = [];
  propertyId: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialerChangeCampaignComponent>,
    private _loaderService: NgxUiLoaderService,
    private _dialerService: DialerService,
    private _masterTaskService: MasterTaskService,
    private _toastrService: ToastrService,
    private _listStackingService: ListStackingService
  ) {
    if (data?.yesTitle) {
      this.yesButtonTitle = data?.yesTitle;
    }
  }

  ngOnInit(): void {
    this.oldCampaignName = this.data?.text;
    this.propertyId = this.data?.details?.propertyId;
    if (this.data?.dialerCampaign == 'assignUser') {
      this.getUsers();
      this.selectUser = this.data.details.assignUser[0];
    }
  }

  getUsers() {
    const obj = {
      page: this.currentPage,
      limit: this.currentLimit,
    };
    this._loaderService.start();
    this._masterTaskService.getUserRoleList(obj).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          response.data.items.map((x) => {
            if (x?.isDialerAccess) {
              let obj = {
                label: `${x?.firstName} ${x?.lastName}`,
                value: x?._id,
                roleData: x.roleData,
              };
              this.users.push(obj);
              this.users.sort((a, b) => a.label.localeCompare(b.label));
            }
          });
          this._loaderService.stop();
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  onEnter() {
    this.submit();
  }


  submit() {
    if (this.data?.dialerCampaign == 'campaign') {
      let ValidateCampaign = 1;
      if (this.campaignName.length == 0) {
        const validateMessage =
          this.messageConstant.customRequiredValidateMessage.replace(
            '[[field]]',
            'Campaign Name'
          );
        this._toastrService.error(validateMessage);
        ValidateCampaign = 0;
      }
      if (ValidateCampaign == 0) {
        return;
      }

      let obj = {
        campaignId: this.data.details.id,
        campaignName: this.campaignName,
      };
      this._loaderService.start();
      this._dialerService.editCampaigns(obj).subscribe(
        (response: ResponseModel) => {
          if (response?.statusCode == 200) {
            this.dialogRef.close(true);
            this._toastrService.success(this.messageConstant.campaignUpdated);
          }
          this._loaderService.stop();
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            // this.dialogRef.close(true);
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
    } else if (this.data?.dialerCampaign == 'assignUser') {
      let ValidateCampaign = 1;
      if (this.selectUser.length == 0) {
        const validateMessage =
          this.messageConstant.customSelectValidateMessage.replace(
            '[[field]]',
            'User'
          );
        this._toastrService.error(validateMessage);
        ValidateCampaign = 0;
      }
      if (ValidateCampaign == 0) {
        return;
      }
      let obj = {
        campaignId: this.data.details.id,
        assignedUserId: this.selectUser,
      };
      this._loaderService.start();
      this._dialerService.editCampaigns(obj).subscribe(
        (response: ResponseModel) => {
          if (response?.statusCode == 200) {
            this.dialogRef.close(true);
            this._toastrService.success(this.messageConstant.campaignUpdated);
          }
          this._loaderService.stop();
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            this.dialogRef.close(true);
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
    } else {
      this.dialogRef.close(this.data);
    }
  }
}
