import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import * as moment from 'moment';
//service
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MarketsService } from 'src/app/providers/markets/markets.service';
import { ToastrService } from 'ngx-toastr';
import { MasterTaskService } from 'src/app/providers/master-task/master-task.service';
import { UserService } from 'src/app/providers/user/user.service';
import { DialerService } from 'src/app/providers/dialer/dialer.service';
import { SharedService } from 'src/app/shared/shared.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { ListStackingService } from 'src/app/providers/list-stacking/list-stacking.service';
//utils
import { MessageConstant } from './../../../utils/message-constant';
import { environment } from 'src/environments/environment';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { Router } from '@angular/router';

@Component({
  selector: 'app-send-dialer',
  templateUrl: './send-dialer.component.html',
  styleUrls: ['./send-dialer.component.scss'],
})
export class SendDialerComponent implements OnInit {
  messageConstant = MessageConstant;
  public dialerCampaignType: string = 'exist';
  public campaignName: string = '';
  public noDataAvailable = 'No Data Available';
  public selectCampaign: string = '';
  public selectUser: string = '';
  public marketId: string = '';
  public allUserByRole: any = {};
  public selectNumber: any[] = [];
  public allCampaigns: any[] = [];
  userRoles: any = {};
  allRoles: any[] = [];
  users: any[] = [];
  roles: any[] = [];
  dialerAccessUserId: any[] = [];
  selectNumberData: any[] = [];
  markets: any[] = [];
  currentPage: number = 1;
  userOption: any[] = [];
  currentLimit: number = environment.pagination.pageLimit;
  selectedCampaignData: any = {};
  isPropertyOtherCampaign: boolean = false;
  isPropertyInLead: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _loaderService: NgxUiLoaderService,
    private _marketServices: MarketsService,
    private _toastrService: ToastrService,
    private _dialerService: DialerService,
    private router: Router,
    private _userService: UserService,
    private _masterTaskService: MasterTaskService,
    private dialog: MatDialogRef<SendDialerComponent>,
    private _sharedService: SharedService,
    public _utilities: CommonFunctionsService,
    private _listStackingService: ListStackingService
  ) {}

  ngOnInit(): void {
    this.getMarkets();
    this.getUsers();
  }
  clearAutocomplete(autocomplete: any) {
    //console.log(autocomplete);
    autocomplete.value = '';
    //console.log("autocomplete.value", autocomplete.value);
    autocomplete.handleDropdownClick();
  }
  getMarkets() {
    const obj = {
      page: this.currentPage,
      limit: this.currentLimit,
    };

    this._loaderService.start();
    this._marketServices.getMarkets(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.markets = response.data?.items?.filter(
            (x) => x.isEnabled === true
          );
          let generalMarket = this.markets.find(
            (x) => x.title === 'Primary Market'
          );

          if (this.markets?.length)
            this.marketId = generalMarket?._id || this.markets[0]._id;
          this.getRoles();
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  getRoles() {
    this._userService.getRoles({}).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          this.allRoles = response?.data;
          this.roles = response?.data.map((x) => {
            let obj = {
              label: x.name,
              value: x.key,
              _id: x._id,
            };

            return obj;
          });

          this.roles = _.sortBy(this.roles, ['label']);
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  getUsers() {
    const obj = {
      page: 1,
      limit: 1000,
    };
    this._masterTaskService.getUserRoleList(obj).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          this.getCampaigns();
          response.data.items.map((x) => {
            if (x?.isDialerAccess) {
              let obj = {
                label: `${x?.firstName} ${x?.lastName}`,
                value: x?._id,
                roleData: x.roleData,
              };
              this.dialerAccessUserId.push(x?._id.toString());
              this.users.push(obj);
              this.users.sort((a, b) => a.label.localeCompare(b.label));
            }
          });

          this.getUserByRole();
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  getUserByRole() {
    this.userRoles = {};
    this.roles.filter((currentRole) => {
      //console.log(currentRole);
      let isReadOnly = false;
      const users = this.users
        .filter((x) => {
          const marketIndex = this.markets.findIndex(
            (market) => market?._id === this.marketId
          );
          if (
            marketIndex > -1 &&
            this.markets[marketIndex][currentRole.value].length &&
            this.markets[marketIndex][currentRole.value].includes(x.value)
          ) {
            return x;
          }
        })
        .filter((x) => x);
      if (users.length === 1) {
        isReadOnly = true;
      }
      let userData = {};
      let userAssign = [];
      for (let i = 0; i < users.length; i++) {
        userAssign.push({
          label: users[i].label,
          value: users[i].value,
          roleId: currentRole?._id,
          roleName: currentRole?.label,
          roleKey: currentRole?.value,
        });
      }
      if (users.length > 0) {
        delete users[0].roleData;
        userData = users[0];
      }

      this.userRoles[currentRole?.value] = {
        users: userAssign,
        roleId: currentRole?._id,
        roleName: currentRole?.label,
        roleKey: currentRole?.value,
        isReadOnly,
      };
      this.allUserByRole[currentRole?.value] = [
        {
          ...userData,
          roleId: currentRole?._id,
          roleName: currentRole?.label,
          roleKey: currentRole?.value,
        },
      ];
    });
    console.log(this.allUserByRole);
    console.log(this.userRoles);
  }
  validateCampaign() {
    if (this.dialerCampaignType == 'exist' && this.selectCampaign.length == 0) {
      const validateMessage =
        this.messageConstant.customSelectValidateMessage.replace(
          '[[field]]',
          'Campaign'
        );
      this._toastrService.error(validateMessage);
      return false;
    }
    if (this.dialerCampaignType == 'new' && this.campaignName.length == 0) {
      const validateMessage =
        this.messageConstant.customRequiredValidateMessage.replace(
          '[[field]]',
          'Campaign Name'
        );
      this._toastrService.error(validateMessage);
      return false;
    }
    if (this.selectUser.length == 0) {
      const validateMessage =
        this.messageConstant.customSelectValidateMessage.replace(
          '[[field]]',
          'User'
        );
      this._toastrService.error(validateMessage);
      return false;
    }
    return true;
  }
  searchRoleUser(searchStr, index) {
    console.log(this.userRoles);

    setTimeout(() => {
      let allUsers = [];
      for (let i = 0; i < this.userRoles[index]?.users.length; i++) {
        if (this.userRoles[index]?.users[i].label.indexOf(searchStr) >= 0) {
          let flag = 1;
          for (let j = 0; j < this.allUserByRole[index].length; j++) {
            if (
              this.allUserByRole[index][j]?.value ==
              this.userRoles[index]?.users[i].value
            ) {
              flag = 0;
              break;
            }
          }
          if (flag == 1) allUsers.push(this.userRoles[index]?.users[i]);
        }
      }
      console.log(allUsers);
      this.userOption = allUsers;
    }, 1000);
  }
  searchNumber(searchStr) {
    const data = {
      page: this.currentPage,
      limit: 100,
      numTypeId: 1,
    };
    this._sharedService.getManagenumbers(data).subscribe(
      (response) => {
        if (response) {
          console.log('numbersssss', response);
          this.selectNumberData = response?.data?.items;
          let allNumbers = [];
          for (let i = 0; i < this.selectNumberData.length; i++) {
            if (this.selectNumberData[i]?.number.indexOf(searchStr) >= 0) {
              let flag = 1;
              for (let j = 0; j < this.selectNumber.length; j++) {
                if (this.selectNumberData[i]?._id == this.selectNumber[j]._id) {
                  flag = 0;
                  break;
                }
              }
              if (flag == 1)
                allNumbers.push({
                  _id: this.selectNumberData[i]?._id,
                  number: this.selectNumberData[i]?.number,
                  maskedNumber: this._utilities.maskNumber(
                    this.selectNumberData[i]?.number
                  ),
                });
            }
          }
          this.selectNumberData = allNumbers;
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          this._loaderService.stop();
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }
  getCampaigns() {
    this._loaderService.start();
    const obj = {
      page: 1,
      limit: 100000,
    };

    this._dialerService.getCampaigns(obj).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          response?.data?.items.map((x) => {
            if (
              this.dialerAccessUserId.includes(x?.assignedUserId.toString())
            ) {
              this.allCampaigns.push(x);
              this.allCampaigns.sort((a, b) => a.campaignName.localeCompare(b.campaignName));
            }
          });
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  resetCampaignType(val) {
    this.selectedCampaignData = {};
    this.selectCampaign = '';
    this.campaignName = '';
    this.selectUser = '';
  }
  setCampaignData(id) {
    if (id != '') {
      for (let i = 0; i < this.selectCampaign.length; i++) {
        if (this.allCampaigns[i]._id == id) {
          this.selectedCampaignData = this.allCampaigns[i];
          break;
        }
      }
      this.selectUser = this.selectedCampaignData?.assignedUserId;
      this.marketId = this.selectedCampaignData?.marketId;

      this.selectNumber = this.selectedCampaignData?.numberToUse.map((e) => {
        return { ...e, maskedNumber: this._utilities.maskNumber(e?.number) };
      });

      for (const [key, value] of Object.entries(this.allUserByRole)) {
        if (
          this.selectedCampaignData[key] &&
          this.selectedCampaignData[key].length
        ) {
          let newAr = [];
          for (let j = 0; j < this.selectedCampaignData[key].length; j++) {
            let index = this.userRoles[key].users.findIndex(
              (user) => user.value === this.selectedCampaignData[key][j]
            );
            if (index >= 0) {
              newAr.push(this.userRoles[key]?.users[index]);
            }
          }
          this.allUserByRole[key] = newAr;
        }
      }
    } else {
      this.selectUser = '';
    }
    console.log(this.allUserByRole);
  }
  submitCampaign() {
    if (this.validateCampaign()) {
      let keyVal = {},
        arr = [];
      let invalidForm = '';
      for (const [key, value] of Object.entries(this.allUserByRole)) {
        arr = [];
        for (let i = 0; i < this.allUserByRole[key].length; i++) {
          if (this.allUserByRole[key][i]?.value) {
            arr.push(this.allUserByRole[key][i].value);
          }
        }

        keyVal[key] = arr;
        if (this.userRoles[key]?.users.length > 0 && arr.length == 0) {
          invalidForm = this.userRoles[key]?.roleName;
          break;
        }
      }
      if (invalidForm != '') {
        const validateMessage =
          this.messageConstant.customSelectValidateMessage.replace(
            '[[field]]',
            invalidForm
          );
        this._toastrService.error(validateMessage);
        return false;
      }
      const utcOffset = moment(new Date())
        .tz(localStorage.timezoneOffset)
        .format('Z');
      let currentDate = new Date(
        moment.utc(new Date()).utcOffset(utcOffset).format('lll')
      );
      const campaignDate = new Date(currentDate).getTime();
      this._toastrService.info(this.messageConstant.sendToDialerInfoMsg, '');
      this._loaderService.start();
      let obj = {
        mode: this.dialerCampaignType,
        campaignId: this.selectCampaign,
        campaignName: this.selectCampaign
          ? this.selectedCampaignData?.campaignName
          : this.campaignName,
        assignedUserId: this.selectUser,
        marketId: this.marketId,
        ...keyVal,
        numberToUse: this.selectNumber,
        propertyFilter: this.data?.filter,
        isPropertyOtherCampaign: this.isPropertyOtherCampaign,
        isPropertyInLead: this.isPropertyInLead,
        createCampaignDate: campaignDate,
      };
      this._dialerService.saveCampaigns(obj).subscribe(
        (response: ResponseModel) => {
          if (response?.statusCode == 200) {
            if (response?.data?.length > 1) {
              this._toastrService.success(
                this.isPropertyOtherCampaign
                  ? response?.data?.length +
                      this.messageConstant
                        .sendToDialerPropertiesDuplicateRemoveSuccess
                  : response?.data?.length +
                      this.messageConstant.sendToDialerPropertiesSuccess,
                ''
              );
            } else {
              this._toastrService.success(
                this.isPropertyOtherCampaign
                  ? response?.data?.length +
                      this.messageConstant
                        .sendToDialerPropertyDuplicateRemoveSuccess
                  : response?.data?.length +
                      this.messageConstant.sendToDialerPropertySuccess,
                ''
              );
            }
            if (response?.data?.length > 0) {
              let myObj = {
                exportDetails: [
                  {
                    coldCalling: true,
                    campaignName: this.selectCampaign
                      ? this.selectedCampaignData?.campaignName
                      : this.campaignName,
                    expectedStartDate: moment
                      .utc(new Date())
                      .format('YYYY-MM-DD'),
                    fromDialer: true,
                  },
                ],
                dataType: 'Self',
                exportedForMarketing: true,
                ids: response?.data,
                updateRelatedProperty: false,
              };
              this._listStackingService.addMarketingDetails(myObj).subscribe(
                (response) => {
                  this.dialog.close();
                  this.router.navigate(['/dialer']);
                },
                (err) => {
                  this.dialog.close();
                  this.router.navigate(['/dialer']);
                }
              );
            } else {
              this.dialog.close();
              this.router.navigate(['/dialer']);
            }
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );

      console.log(JSON.stringify(obj));
    }
  }
}
