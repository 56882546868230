<div class="re-top-head">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      {{ data?.title }} Group
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<form
  class="re-payment-form"
  [formGroup]="groupForm"
  (ngSubmit)="onSubmit()"
  autocomplete="off"
>
  <mat-dialog-content>
    <div class="container-fluid pt-3">
      <div class="row">
        <div
          class="col-12 col-md-12"
          *ngIf="data?.title == 'Update' && data?.details"
        >
          <div class="form-group">
            <label class="re-labels">Old Group Name</label>
            <input
              type="text"
              class="form-control"
              [value]="data?.details?.title"
              disabled
            />
          </div>
        </div>
        <div class="col-12 col-md-12">
          <div class="form-group">
            <label class="re-labels"
              >{{ data?.title == "Update" ? "New " : "" }}Group Name
              <span class="text-danger">*</span></label
            >
            <input
              type="text"
              class="form-control"
              placeholder="Enter Group Name"
              formControlName="title"
            />
            <div
              *ngIf="
                (submitted && f.title.errors) ||
                (f.title.errors && (f.title.dirty || f.title.touched))
              "
              class="text-danger"
            >
              <span class="re-error-text" *ngIf="f.title.errors.required"
                >Group Name is required
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" style="padding-top: 14px !important">
    <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
    <button
      type="submit"
      mat-flat-button
      color="primary"
      cdkFocusInitial
      class="btn btn-teal"
    >
      {{ data?.title }} Group
    </button>
  </mat-dialog-actions>
</form>
