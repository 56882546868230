import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrl } from '../../utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
@Injectable({
  providedIn: 'root',
})
export class ScheduledSmsService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem('token');

  constructor(private _commonFunction: CommonFunctionsService) {}

  listScheduledSMS = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.scheduledSmsUrl + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  editScheduledSMS = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.scheduledSmsUrl + 'edit';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteScheduledSMS = (id): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.scheduledSmsUrl + 'delete/' + id;
    return this._commonFunction.globalDeleteService(endpoint);
  };
}
