<div class="re-top-head">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      Reassign Leads
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<mat-dialog-content>
  <div class="container">
    <div class="box mt-3">
      <h4 class="re-title1">
        {{ getUserName(this.userId) }} • {{ getRoleName(this.roleId) }}
      </h4>
      <span class="re-title2">
        {{ statusCount }} Status • {{ leadCount }} Leads Selected
      </span>
      <div class="row">
        <!-- LEAD STATUS -->
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="re-labels" for="inputleadSource"> Lead Status </label>
            <select
              id="inputleadSource"
              class="form-control"
              [(ngModel)]="statusLead"
            >
              <option value="{{ statusCount }} Status • {{ leadCount }} Leads">
                {{ statusCount }} Status • {{ leadCount }} Leads
              </option>
            </select>
          </div>
        </div>

        <!-- NEW ASSIGNEE -->
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="re-labels" for="inputleadSource">
              New Assignee
            </label>
            <select
              id="inputleadSource"
              class="form-control"
              [(ngModel)]="newUserId"
              (change)="getNewUserId()"
            >
              <option value="">Select User</option>
              <option *ngFor="let user of users" [value]="user">
                {{ getUserName(user) }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <h4 class="re-title1">NOTE</h4>

      <div>
        <p>
          You are about to reassign {{ leadCount }} selected leads to
          <b>{{ selectedUser }}</b
          ><span *ngIf="userId == 'null'"> that are currently unassigned.</span
          ><span *ngIf="userId != 'null'">
            that are currently assigned to {{ getUserName(this.userId) }}.</span
          >
        </p>
        <!-- <p><a class="re-link">Click here</a> to learn more.</p> -->
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" style="padding-top: 14px !important">
  <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
  <button cdkFocusInitial class="btn btn-teal" (click)="saveAssignment()">
    Update Assignment
  </button>
</mat-dialog-actions>
<!-- </form> -->
