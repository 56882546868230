<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      {{ data?.header }}
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<mat-dialog-content class="">
  <div class="container">
    <div class="row">
      <div class="col-12 re-information" *ngIf="data?.dialerCampaign">
        <div class="form-group" *ngIf="data?.dialerCampaign == 'campaign'">
          <div class="col-12 re-title" *ngIf="oldCampaignName">
            <p class="re-text">
              Old Campaign Name : <b>{{ oldCampaignName }}</b>
            </p>
          </div>
          <label class="re-labels"
            >Enter New Campaign Name <span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control"
            name="campaignName"
            [(ngModel)]="campaignName"
            placeholder="Campaign Name"
            (keyup.enter)="onEnter()"
          />
        </div>
        <div class="form-group" *ngIf="data?.dialerCampaign == 'assignUser'">
          <label class="re-labels"
            >Select User <span class="text-danger">*</span></label
          >
          <select
            class="form-control"
            [(ngModel)]="selectUser"
            name="selectUser"
          >
            <option value="" disabled selected hidden>Select a user</option>
            <option value="{{ user.value }}" *ngFor="let user of users">
              {{ user.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="text-center" style="padding-top: 14px !important">
  <button mat-raised-button [mat-dialog-close]="false">Cancel</button>
  <button (click)="submit()" mat-raised-button color="primary" cdkFocusInitial>
    {{ yesButtonTitle }}
  </button>
</mat-dialog-actions>
