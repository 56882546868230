import {
  Component,
  OnInit,
  Inject,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UploadOutput, UploadInput, UploaderOptions } from 'ngx-uploader';
import * as _ from 'lodash';

// UTILS
import { MessageConstant } from 'src/app/utils/message-constant';
import { environment } from 'src/environments/environment';
import { BaseUrl } from 'src/app/utils/base-url-constants';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';

// SERVICES
import { SharedService } from '../../shared.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { SmsService } from 'src/app/providers/sms/sms.service';
import { MasterTaskService } from 'src/app/providers/master-task/master-task.service';
import { QuillEditorComponent } from 'ngx-quill';
import 'quill-mention';
@Component({
  selector: 'app-add-edit-sms-template',
  templateUrl: './add-edit-sms-template.component.html',
  styleUrls: ['./add-edit-sms-template.component.scss'],
})
export class AddEditSmsTemplateComponent implements OnInit {
  @ViewChild('uploader', { read: ElementRef }) fileInput: ElementRef;
  @ViewChild('editor') editor: QuillEditorComponent;
  templateForm: FormGroup;
  messageConstant = MessageConstant;
  uploadInput: EventEmitter<UploadInput>;

  public items: string[] = [
    'First_Name',
    'Last_Name',
    'Property_Street_Address',
    'Property_City',
    'Property_State',
    'Property_Zip_Code',
    'Appt_Date_and_Time',
    'Appt_Day',
    'Appt_Time',
    'A2P_10DLC_Company_Name',
    'DBA',
  ];

  submitted: boolean = false;
  selectedModuleId: any[] = [];
  moduleData: any[] = [];
  editAttachmentArray: any = [];
  fileSize: any = 0;
  selectedFileSize: any = 0;
  imageArr: any[] = [];
  uploadFiles: any[] = [];
  fileCount: number = 0;
  allFileCount: number = 0;
  duplicateImageArr: any = [];
  options: UploaderOptions;
  smsCount: number = 0;
  smsCharCount: number = 0;
  editorStyle: {
    height: '500px';
  };
  editorConfig: any = {};
  editorOptions: any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private _sharedService: SharedService,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private _smsDailog: MatDialogRef<AddEditSmsTemplateComponent>,
    private _utilities: CommonFunctionsService,
    private _smsService: SmsService,
    private _masterTaskService: MasterTaskService
  ) {
    this.editorOptions = {
      placeholder: this._utilities.commonEditor().placeholder,
      toolbar: this._utilities.commonEditor().toolbar,
      mention: this.mentionEditor(),
    };
  }

  mentionEditor() {
    return {
      mentionListClass: 'ql-mention-list mat-elevation-z8',
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      showDenotationChar: true,
      spaceAfterInsert: true,
      onSelect: (item, insertItem) => {
        const editor = this.editor.quillEditor;
        insertItem(item);
        // this.tagUser(item.value)
        // necessary because quill-mention triggers changes as 'api' instead of 'user'
        editor.insertText(editor.getLength() - 1, '', 'user');
      },
      source: (searchTerm, renderList) => {
        const values = this.items.map((item, index) => {
          return { id: index, value: item };
        });

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];

          values.forEach((entry) => {
            if (
              entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            ) {
              matches.push(entry);
            }
          });
          renderList(matches, searchTerm);
        }
      },
    };
  }

  ngOnInit(): void {
    this.getUserRoleList();
    this.templateForm = this._formBuilder.group({
      templateName: ['', Validators.compose([Validators.required])],
      message: ['', Validators.required],
      attachmentArray: [''],
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.templateForm.controls[controlName].hasError(errorName);
  };

  getUserRoleList() {
    this._loaderService.start();
    let role = [];
    this._masterTaskService.getUserRoleList({ page: 1, limit: 100 }).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._loaderService.stop();
          let hash = Object.create(null);

          let roles = _.flatten(
            response.data.items.map((item) => item.roleData)
          );
          let result = roles.reduce((r, o) => {
            if (!hash[o?._id]) {
              hash[o?._id] = {
                _id: o?._id,
                name: o?.name,
                roles: [],
              };
              r.push(hash[o?._id]);
            }
            hash[o?._id].roles.push({
              _id: o?._id,
              name: o?.name,
            });
            return r;
          }, []);
          result.map((item) => {
            role.push(item.name.toString());
          });
          this.items = [...this.items, ...role];

          if (this.data.action == 'edit') {
            if (this.data.details?.attachmentArray) {
              this.fileSize = this.data.details?.attachmentArray
                .map((obj) => {
                  return Number(obj.size);
                })
                .reduce((a, b) => a + b, 0);
            }

            this.editAttachmentArray = this.data.details?.attachmentArray
              ? this.data.details?.attachmentArray
              : [];

            this.templateForm.patchValue({
              templateName: this.data.details?.templateName,
              message: this._utilities.populateEditorDataWithBR(
                this.items,
                this.data.details?.message
              ),
            });

            this.getSMSCount('');

            for (let i = 0; i < this.data.details.moduleId.length; i++) {
              for (let j = 0; j < this.data.items.length; j++) {
                if (this.data.items[j]._id == this.data.details.moduleId[i]) {
                  this.selectedModuleId.push(this.data.items[j]);
                  break;
                }
              }
            }
          }
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  search(moduleName) {
    let tempArr = [];
    for (let i = 0; i < this.data.items.length; i++) {
      let title = this._utilities.capitalizeName(this.data.items[i].title);
      if (title.indexOf(moduleName) >= 0) {
        tempArr.push({ ...this.data.items[i], title });
      }
    }

    let finalArr = [],
      flag;
    for (let i = 0; i < tempArr.length; i++) {
      flag = 1;
      for (let j = 0; j < this.selectedModuleId.length; j++) {
        if (tempArr[i]._id == this.selectedModuleId[j]._id) {
          flag = 0;
          break;
        }
      }
      if (flag == 1) finalArr.push(tempArr[i]);
    }
    this.moduleData = finalArr;
    console.log(this.moduleData);
  }

  onSubmit() {
    this.submitted = true;
    if (this.templateForm.invalid) {
      return;
    }
    this.templateForm.value.message = this._utilities.getTextWithMentions(
      this.templateForm.value.message
    );
    this.templateForm.value.message = this.templateForm.value.message
      .replace(/[^\x00-\x7F]/g, '')
      .replace(/&#xFEFF;/g, '');
    let obj = {
      ...this.templateForm.value,
    };

    this._loaderService.start();
    this.editAttachmentArray = [
      ...this.editAttachmentArray,
      ...this.duplicateImageArr,
    ];
    this.editAttachmentArray = [
      ...new Set(this.editAttachmentArray.map(JSON.stringify)),
    ];
    this.editAttachmentArray = this.editAttachmentArray.map(JSON.parse);
    if (this.editAttachmentArray?.length > 0) {
      obj['attachmentArray'] = this.editAttachmentArray;
    } else {
      delete obj['attachmentArray'];
    }
    if (this.data.action === 'edit') {
      obj = {
        ...obj,
        smsTemplateId: this.data.details?._id,
      };

      this._smsService.editSmsTemplate(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this._smsDailog.close(true);
            this._toastrService.success(
              this.messageConstant.smsTemplateEditSuccess
            );
          }
          this._loaderService.stop();
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );

      return;
    }

    this._smsService.addSmsTemplate(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._smsDailog.close(response?.data);
          this._toastrService.success(
            this.messageConstant.smsTemplateEditSuccess
          );
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  removeAttachmentArray(value) {
    this.editAttachmentArray = this.editAttachmentArray.filter(
      (item) => item.url !== value.url
    );
    this.fileSize = this.fileSize - Number(value?.size);
    this.selectedFileSize = 0;
  }
  onUploadLogo(output: UploadOutput): void {
    this.fileInput.nativeElement.value = null;
    this.imageArr = [];
    // console.log("output.type =>",output.type)
    if (output.type === 'allAddedToQueue') {
      this.uploadAttachment();
    } else if (
      output.type === 'addedToQueue' &&
      typeof output.file !== 'undefined'
    ) {
      this.selectedFileSize = output.file?.size ? Number(output.file?.size) : 0;
      if (this.selectedFileSize > 10000000) {
        this.selectedFileSize = 0;
        this._toastrService.error(this.messageConstant.exceedSizeLimit, '');

        const event: UploadInput = {
          type: 'cancel',
          id: output.file.id,
        };
        this.uploadInput.emit(event);

        const event2: UploadInput = {
          type: 'remove',
          id: output.file.id,
        };
        this.uploadInput.emit(event2);
      } else {
        this.fileSize = Number(this.fileSize) + this.selectedFileSize;
        if (this.fileSize > 10000000) {
          this.fileSize = Number(this.fileSize) - this.selectedFileSize;
          this.selectedFileSize = 0;
          this._toastrService.error(
            this.messageConstant.exceedTotalSizeLimit,
            ''
          );
          const event: UploadInput = {
            type: 'cancel',
            id: output.file.id,
          };
          this.uploadInput.emit(event);

          const event2: UploadInput = {
            type: 'remove',
            id: output.file.id,
          };
          this.uploadInput.emit(event2);
        } else {
          this.uploadFiles.push(output);
        }
      }
    } else if (
      output.type === 'rejected' &&
      typeof output.file !== 'undefined'
    ) {
      this._toastrService.error(this.messageConstant.exceedSizeLimit, '');
    } else if (output.type === 'done' && typeof output.file !== 'undefined') {
    } else if (output.type === 'dragOut') {
      // drag out event
    } else if (output.type === 'drop') {
      // on drop event
    }
  }
  async uploadAttachment() {
    if (this.uploadFiles.length > 0) {
      for (let i = 0; i < this.uploadFiles.length; i++) {
        const output = this.uploadFiles[i];
        this.fileCount = i + 1;
        this.uploadFile(output.file);
      }
    }
    this.uploadFiles = [];
  }
  uploadFile(fileData) {
    let endpoint = environment.baseUrl + BaseUrl.sharedUrl + 'file-upload';
    if (this.data.moduleId) {
      endpoint += `?moduleId=${'5faa63fdfd20cd581703d255'}&isPublic=1&type=0`;
    }

    let newFormData: FormData = new FormData();
    const file: File = fileData.nativeFile;
    newFormData.append('file', file, file.name);

    this._loaderService.start();
    this._sharedService.uploadFile(endpoint, newFormData).subscribe(
      (response: ResponseModel) => {
        this.allFileCount += 1;
        if (this.allFileCount == this.fileCount) {
          this._loaderService.stop();
          this.allFileCount = 0;
        }

        if (response?.statusCode && response?.statusCode == 200) {
          if (response?.data.length > 0) {
            let imgData = response?.data[0];
            imgData['size'] = String(fileData?.size);
            this.duplicateImageArr.push(imgData);
          }
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  removeImageArr(value) {
    this.duplicateImageArr = this.duplicateImageArr.filter(
      (item) => item.url !== value.url
    );
    this.fileSize = this.fileSize - Number(value?.size);
    this.selectedFileSize = 0;
  }

  getSMSCount(e) {
    const MAX_LENGTH = 160;
    const message = this._utilities.getText(
      this['editor'].quillEditor.getContents()
    );

    const length = message.length ? message?.length - 1 : 0;

    this.smsCount = Math.ceil(length / MAX_LENGTH);
    this.smsCharCount = MAX_LENGTH * this.smsCount - length;
  }
}
