// MODULES
import {
  Component,
  OnInit,
  Inject,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';

// UTILITIES
import { MessageConstant } from 'src/app/utils/message-constant';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { LeadsStatusChangeModel } from 'src/app/utils/models/leads-status-change';
import { StatusConstant } from 'src/app/utils/status-constant';
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';
import { UploadInput, UploadOutput } from 'ngx-uploader';
import { BaseUrl } from 'src/app/utils/base-url-constants';
import { QuillEditorComponent } from 'ngx-quill';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { LeadsService } from 'src/app/providers/leads/leads.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CallFlowService } from '../../../providers/call-flow/call-flow.service';
import { SocketIoService } from '../../../providers/socket-io/socket-io.service';
import { SharedService } from '../../shared.service';
import { InventoryService } from 'src/app/providers/inventory/inventory.service';
import { TaskService } from 'src/app/providers/task/task.service';
import { MasterTaskService } from 'src/app/providers/master-task/master-task.service';
import { MarketsService } from 'src/app/providers/markets/markets.service';
import { DashboardService } from 'src/app/providers/dashboard/dashboard.service';
import { CreditService } from 'src/app/providers/credit/credit.service';
import { BugsnagService } from 'src/app/bugsnag.service';
import { SmsService } from 'src/app/providers/sms/sms.service';

// COMPONENTS
import { ChangeNumberComponent } from '../change-number/change-number.component';
import { TakeNoteComponent } from '../take-note/take-note.component';
import { OpenLeadDialogComponent } from 'src/app/shared/dialog/open-lead-dialog/open-lead-dialog.component';
import { GoogleMapComponent } from 'src/app/shared/dialog/maps/google-map/google-map.component';
import { StreetMapComponent } from 'src/app/shared/dialog/maps/street-map/street-map.component';
import { AutoFollowUpComponent } from '../../sub-header/auto-follow-up/auto-follow-up.component';
import { ConfirmationDialogComponent } from 'src/app/shared/dialog/confirmation-dialog/confirmation-dialog.component';
import { LeadsStatusChangeComponent } from 'src/app/modules/leads/leads-list/leads-status-change/leads-status-change.component';
import { AddEditAppointmentsComponent } from 'src/app/modules/leads/leads-details/task/add-edit-appointments/add-edit-appointments.component';
import { AddEditTaskComponent } from 'src/app/modules/leads/leads-details/task/add-edit-task/add-edit-task.component';
import { ContactsDialogComponent } from 'src/app/shared/dialog/contacts-dialog/contacts-dialog.component';
import { ActivityDialogComponent } from 'src/app/shared/dialog/activity-dialog/activity-dialog.component';
import { SmsDialogComponent } from 'src/app/shared/dialog/sms-dialog/sms-dialog.component';
import { TaskDripStatusChangeComponent } from 'src/app/modules/leads/leads-list/task-drip-status-change/task-drip-status-change.component';
import { PreferenceQuestionComponent } from '../../preference-question/preference-question.component';
import { ActivityLogsComponent } from 'src/app/shared/activity-logs/activity-logs.component';
import { WholesalePipelineStatusChangeComponent } from 'src/app/modules/wholesale-pipeline/wholesale-pipeline-list/wholesale-pipeline-status-change/wholesale-pipeline-status-change.component';
// UTILS
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';
import { environment } from 'src/environments/environment';
import { UpgradeAccountComponent } from '../upgrade-account/upgrade-account.component';
import { TwilioService } from 'src/app/utils/twilio-service/twilio.service';
import { UserService } from '../../../providers/user/user.service';

declare var $: any;
declare var jQuery: any;
declare var Plivo;

interface dripDataInterface {
  autoStopDrip: any;
  started: any;
  dripName: any;
  dripStartDate: any;
  created: any;
  completedData: any;
  breakDown: any;
}

@Component({
  selector: 'app-lead-dialer',
  templateUrl: './lead-dialer.component.html',
  styleUrls: ['./lead-dialer.component.scss'],
})
export class LeadDialerComponent implements OnInit {
  // private device: any;
  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _route: Router,
    public _utilities: CommonFunctionsService,
    private _leadService: LeadsService,
    private _sharedService: SharedService,
    private _inventoryService: InventoryService,
    private _taskService: TaskService,
    private _masterTaskService: MasterTaskService,
    private leadDialerDailog: MatDialogRef<LeadDialerComponent>,
    private _sanitizer: DomSanitizer,
    private _creditService: CreditService,
    private _fb: FormBuilder,
    private _marketServices: MarketsService,
    private _dashboardService: DashboardService,
    private _bugsnagService: BugsnagService,
    private _smsService: SmsService,
    private _flowService: CallFlowService,
    private _socketService: SocketIoService,
    private _twilioService: TwilioService,
    private creditService: CreditService,
    private _userService: UserService
  ) {
    this.isTwilioCall = this._utilities.isTwilioUser;
    this.uploadInput = new EventEmitter<UploadInput>();
    this.editorOptions = {
      placeholder: this._utilities.commonEditor().placeholder,
      toolbar: this._utilities.commonEditor().toolbar,
      mention: this.mentionEditor(),
    };
    this._sharedService.refreshSubHeaderPinnedCount = new Subject();
    this._sharedService.refreshSubHeaderPinnedCount.subscribe((response) => {
      if (response) {
        if (response?.count > 0) {
          this.isPinnedIconView = true;
        } else {
          this.isPinnedIconView = false;
        }
      }
    });
  }
  @ViewChild('uploader', { read: ElementRef }) fileInput: ElementRef;
  @ViewChild('editor') editor: QuillEditorComponent;
  @ViewChild('preferenceQuestion')
  preferenceQuestion: PreferenceQuestionComponent;
  @ViewChild('activityLog') activityLog: ActivityLogsComponent;
  uploadInput: EventEmitter<UploadInput>;
  count: number = 0;
  timeOut: any;
  callAnswerForm: FormGroup;
  smsDetailForm: FormGroup;
  freeTrial: boolean = localStorage?.freeTrial == 'false' ? false : true;
  isMinimized: boolean = false;
  sendSmsBtn: boolean = false;
  callConnected: boolean = false;
  evt: any;
  dialogRef;
  messageConstant = MessageConstant;
  currentLimit: number = MiscellaneousConstant.paginationLimit.STANDARD;
  currentPage: number = 1;
  SMSCredit: number = 0;
  userList: any[] = [];
  leadData: any;
  mainStatusId: string = '';
  showTags: any[] = [];
  splitTags: any[] = [];
  moduleName: string = 'lead';
  callStatus: string = '';
  leadId: string = '';
  moduleId: string = '5faa63fdfd20cd581703d255';
  tagType: string = StatusConstant.TagsType.LEADS;
  type: string = 'leads';
  contactType: string = 'seller';
  buyerCallFrom: string = '';
  currentUser: any = {};
  teams: any = {};
  teamsData: any = {};
  buyerNumber: any = {};
  userListing: any = [];
  leadCountData: any;
  currentActivityPage: number = 1;
  activityCurrentLimit: number = 10;
  currentTaskPage: number = 1;
  taskCurrentLimit: number = 10;
  tasks: any = [];
  taskCount: number = 0;
  logs: any = {};
  userImage: any[] = [];
  isVisible: any[] = [];
  style: any;
  callBalance: number = 0;
  currentContact: number = 1;
  dialedNumber: string = '';
  currentContactArr: number = 0;
  currentContactDis: number = 0;
  contactData: any;
  plivoBrowserSdk;
  plivoAccountIndex: number = 0;
  options = {
    debug: 'ALL',
    permOnClick: true,
    enableTracking: true,
    closeProtection: true,
    maxAverageBitrate: 48000,
    allowMultipleIncomingCalls: true,
  };
  callReady: boolean = false;
  counter: any;
  isCalling: boolean = false;
  callDuration: string = '00:00:00';
  userId: string = '';
  makingCall: boolean = false;
  smsTemplates: any[] = [];
  mergeCall: boolean = false;
  defaultDiaplay: boolean = true;
  sliderBtn: boolean = false;
  mergeCallBtn: boolean = true;
  multipleCall: boolean = false;
  muteCall: boolean = false;
  isDialPad: boolean = false;
  isCurrentCall: boolean = true;
  holdCall: boolean = false;
  leadIds: any = [];
  isLeadListResponse: boolean = false;
  currentLeadIndex: number = 0;
  callDisable: boolean = false;
  defaultNumberList: any[] = [];
  currentMainStatusId: string = '';
  isNextEnable: boolean = true;
  isPreviousEnable: boolean = true;
  callEnd: boolean = false;
  redialCall: boolean = false;
  callNextLead: boolean = true;
  isMinimizedScreen: boolean = true;
  callEndDuration: string = '00:00:00';
  callEndTime: string = '00:00';
  callingData: any;
  callConnectedStatus: any;
  callNotConnectedStatus: any;
  callUUID: any;
  callConnectReason: boolean = false;
  callNotConnectReason: boolean = false;
  submitted: boolean = false;
  submittedSms: boolean = false;
  callConnectReasonText: boolean = false;
  callNotConnectReasonText: boolean = false;
  marketList: any[] = [];
  projectStatus: any[] = [];
  mainProjectStatus: any[] = [];
  viewPropertyList: any = [];
  preferenceData: any;
  isDripData: dripDataInterface = {
    autoStopDrip: '',
    started: '',
    dripName: '',
    dripStartDate: '',
    created: '',
    completedData: '',
    breakDown: [],
  };
  sort: any = { dueInIsoTimeStamp: 1 };
  @Output() _activityType = new EventEmitter<any>();
  openLeadTime: string = '';
  currentCallInfo: any = '';
  showAttachments: any[] = [];
  smsCount: number = 0;
  smsCharCount: number = 0;
  smsFilterBody: String = '';
  attachments: any[] = [];
  editorStyle: {
    height: '300px';
  };
  editorConfig: any = {};
  editorOptions: any = {};
  items: string[] = [
    'First_Name',
    'Last_Name',
    'Property_Street_Address',
    'Property_City',
    'Property_State',
    'Property_Zip_Code',
    'A2P_10DLC_Company_Name',
    'DBA',
  ];
  comments: string = '';
  multiCallUUID = '';
  multiCallEventName = '';
  addMemberCall = false;
  callAnswered = false;
  showMemberList = false;
  mergeCallList = false;
  totalParticipant: any;
  memberList: any[] = [];
  mainMember: any;
  firstMember: any;
  assignUser: any;
  roles: any;
  mergeCallDialer: any = {
    firstCall: false,
    secondCall: false,
    callMerged: false,
    callEnded: true,
    callNotJoined: false,
    checkStatus: false,
    endCause: '',
  };
  callMerged: boolean = false;
  statusCheckFlag: boolean = false;
  allTeams: any = {};
  callAnswerInterval: any;
  addToMpcHeader: any;
  joinToMpcHeader: boolean = false;
  userPlan: any;
  activityType: String = 'notes';
  isPinnedIconView: boolean = false;
  activityView: boolean = false;

  isTwilioCall: boolean = false;
  twilioCallSid: any;
  conferenceId: any;
  leadsTeams: any[] = [];
  mainUserData: any = {};

  @HostListener('window:beforeunload', ['$event']) unload(event) {
    if (this.callConnected && this.callEndDuration != '00:00:00') {
      this.callAnswerForm.patchValue({
        callAnswer: 12,
        otherReason: '',
      });
      this.oncallAnswerSubmit();

      this.getUserPlan();
    }
  }

  ngOnInit(): void {
    if (this.isTwilioCall) {
      //twilio call disconnect event
      this._twilioService.twilioOutboundHangupEvent = new Subject();
      this._twilioService.twilioOutboundHangupEvent.subscribe((response) => {
        this.callUUID = response?.parameters?.CallSid;
        this.cancel();
      });

      //twilio call accept event
      this._twilioService.twilioOutboundEvent = new Subject();
      this._twilioService.twilioOutboundEvent.subscribe((response) => {
        if (response) {
          this.twilioOutboundCallEvent(response);
        }
      });
    }

    this.getUserPlan();
    // this._socketService.message$.subscribe((data) => {
    //   this.mergeCallDialer = data;
    //   if (data?.checkStatus && data?.endCause === '') {
    //     this.getFinalMemberList();
    //   }
    //   if (data?.firstCall) {
    //     this.mergeCallDialer.firstCall = data.firstCall;
    //   }
    //   if (data?.secongCall) {
    //     this.mergeCallDialer.secongCall = data.secongCall;
    //     this.getFinalMemberList();
    //   }
    //   if (data?.callMerged) {
    //     this.mergeCallDialer.callMerged = data.callMerged;
    //     this.getFinalMemberList();
    //   }
    //
    //   if (data?.callEnded) {
    //     this.mergeCallDialer.callEnded = data.callEnded;
    //     this.memberList = [];
    //   }
    //
    //   if (data?.callNotJoined) {
    //     this.mergeCallDialer.callNotJoined = data.callNotJoined;
    //   } else {
    //     this.mergeCallDialer.callNotJoined = false;
    //   }
    //
    //   if (data?.endCause) {
    //     //this.getInitialMemberList()
    //   }
    // });
    this.callConnectedStatus = this._utilities.callConnectedStatus();
    this.callNotConnectedStatus = this._utilities.callNotConnectedStatus();
    this.callAnswerForm = this._fb.group({
      callAnswer: ['', Validators.required],
      otherReason: [''],
    });
    this.smsDetailForm = this._fb.group({
      smsNumber: ['0', Validators.required],
      smsBody: ['', Validators.required],
      mediaUrls: [''],
      smsTemplateId: ['', []],
    });
    this._sharedService.refreshDialerInfo = new Subject();
    this._sharedService.refreshDialerInfo.subscribe((response) => {
      if (response) {
        setTimeout(() => {
          this.getLeadDetails(this.leadId ? this.leadId : this.data?.leadId);
        }, 2000);
      }
    });
    this._sharedService.refreshDialerToggleDisplay = new Subject();
    this._sharedService.refreshDialerToggleDisplay.subscribe((response) => {
      if (response) {
        setTimeout(() => {
          this.toggleDisplay();
        }, 2000);
      }
    });
    this.leadId = this.data?.leadId;
    this.getBuyerNumber();
    this.getSMSBalance();
    this.viewProperty();
    this.getQuestions(this.leadId);
    this.getDefaultCampaignList();
    // this.contactData = [
    //   ...this.data?.leadData?.secondaryContact,
    //   ...this.data?.leadData?.buyers,
    // ];
    // this.getUser();
    // this.onCallDialog();

    this.getMarkets();
    this.getMainStatus();
    this.getUserRoleList();
    this.getTeam();
  }
  getUserPlan() {
    this.creditService.getUserPlan({}).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.userPlan = response.data?.planName?.toLowerCase();
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  mentionEditor() {
    return {
      mentionListClass: 'ql-mention-list mat-elevation-z8',
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      showDenotationChar: true,
      spaceAfterInsert: true,
      onSelect: (item, insertItem) => {
        const editor = this.editor.quillEditor;
        insertItem(item);
        // this.tagUser(item.value)
        // necessary because quill-mention triggers changes as 'api' instead of 'user'
        editor.insertText(editor.getLength() - 1, '', 'user');
      },
      source: (searchTerm, renderList) => {
        const values = this.items.map((item, index) => {
          return { id: index, value: item };
        });

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];

          values.forEach((entry) => {
            if (
              entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            ) {
              matches.push(entry);
            }
          });
          renderList(matches, searchTerm);
        }
      },
    };
  }
  ngAfterViewInit(): void {
    document.onclick = (args: any): void => {
      if (
        args.target.className ==
        'cdk-overlay-backdrop popup-Backdrop-Class-dialer cdk-overlay-backdrop-showing'
      ) {
        this.toggleDisplay();
        return;
      }
    };
  }

  closePinned() {
    jQuery('.re-pin-drop.dropdown').removeClass('show');
    jQuery('.re-pin-drop .dropdown-menu').removeClass('show');
  }

  changeActivityType() {
    jQuery('.re-pin-drop.dropdown').addClass('show');
    jQuery('.re-pin-drop .dropdown-menu').addClass('show');
    this.activityView = true;
  }

  toggleDisplay() {
    const cdkOverlayPane = document.querySelector(
      '.cdk-overlay-pane'
    ) as HTMLElement | null;
    const cdkOverlayBackdrop = document.querySelector(
      '.cdk-overlay-backdrop'
    ) as HTMLElement | null;
    if (this.isMinimized) {
      this.getLeadDetails(this.leadId);
      this.getQuestions(this.leadId);
      if (document.querySelector('.cdk-overlay-backdrop')) {
        cdkOverlayBackdrop.style.display = 'block';
      }

      if (document.querySelector('.cdk-overlay-pane')) {
        cdkOverlayPane.style.position = 'relative';
        cdkOverlayPane.style.height = 'auto';
        cdkOverlayPane.style.transform = '';
        document
          .querySelector('.cdk-overlay-pane')
          .classList.remove('re-lead-dialer-minimize');
      }
    } else {
      if (document.querySelector('.cdk-overlay-backdrop')) {
        cdkOverlayBackdrop.style.display = 'none';
      }
      if (document.querySelector('.cdk-overlay-pane')) {
        cdkOverlayPane.style.position = 'absolute';
        document
          .querySelector('.cdk-overlay-pane')
          .classList.add('re-lead-dialer-minimize');
      }
      this.reset();
    }

    this.isMinimized = !this.isMinimized;
    this.isMinimizedScreen = false;
  }

  reset() {
    if (this.evt) {
      this.evt._dragRef.reset();
    }
  }

  async changeNumber() {
    this.dialogRef = this.dialog.open(ChangeNumberComponent, {
      width: '450px',
      disableClose: true,
      data: {
        numberList: this.defaultNumberList,
        leadId: this.leadId,
        numberId: this.leadData?.defaultNumberId
          ? this.leadData?.defaultNumberId
          : this.leadData?.numberId,
        leadIds: this.leadIds,
        allLeadHide: true,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.leadData.defaultNumberId = result?.defaultNumberId;
        let phone = this.defaultNumberList
          .map((number) => {
            if (number._id == result?.defaultNumberId) {
              return number.number;
            }
          })
          .filter((x) => x);
        this.leadData.defaultCallNumber = phone[0];
      }
    });
  }

  leaveNote() {
    this.dialogRef = this.dialog.open(TakeNoteComponent, {
      width: '400px',
      disableClose: true,
      data: {
        leadId: this.leadId,
        moduleId: this.moduleId,
        leadData: this.leadData,
        moduleName: this.moduleName,
        type: 'lead',
        listPropertyDataFromLead: this.viewPropertyList?.property,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.header.isNewLead = false;
      }
      this._sharedService.refreshActivityLog.next(true);
    });
  }

  getCurrentContact(type) {
    this.sliderBtn = true;
    setTimeout(() => {
      this.sliderBtn = false;
    }, 700);
    if (this.makingCall) {
      return;
    }
    if (type == 'next' && this.currentContact < this.contactData?.length) {
      this.defaultDiaplay = false;
      this.makingCall = false;
      this._utilities.makingCallDialer = false;
      this.isCalling = false;
      this.callReady = false;
      this.isDialPad = false;
      this.callEnd = false;
      this.redialCall = false;
      this.holdCall = false;
      this.muteCall = false;
      this.isCurrentCall = true;
      this.defaultDiaplay = true;
      this.currentContact = this.currentContact + 1;
      this.currentContactArr = this.currentContactArr + 1;
    } else if (type == 'previous' && this.currentContact > 1) {
      this.defaultDiaplay = false;
      this.makingCall = false;
      this._utilities.makingCallDialer = false;
      this.isCalling = false;
      this.callReady = false;
      this.isDialPad = false;
      this.callEnd = false;
      this.redialCall = false;
      this.holdCall = false;
      this.muteCall = false;
      this.isCurrentCall = true;
      this.defaultDiaplay = true;
      this.currentContact = this.currentContact - 1;
      this.currentContactArr = this.currentContactArr - 1;
    }
    let phone = this.contactData[this.currentContact - 1]?.phoneNumber
      ? this.contactData[this.currentContact - 1]?.phoneNumber
      : this.contactData[this.currentContact - 1]?.contactNumber;
    this.contactType = this.contactData[this.currentContact - 1]?.contactType
      ? this.contactData[this.currentContact - 1]?.contactType.toLowerCase()
      : 'seller';
    this.callDisable = phone ? false : true;
  }

  onCallDialog() {
    this._creditService.getCallCredit({}).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode && response?.statusCode == 200) {
          this.callBalance = response?.data;
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
        } else {
        }
      }
    );
  }

  initializePlivo() {
    this.plivoBrowserSdk = new Plivo(this.options);

    this.plivoBrowserSdk.client.on('onLogout', () => {});
    this.plivoBrowserSdk.client.on('onLoginFailed', (cause) => {
      this._bugsnagService.upload({ 'onLoginFailed =>': cause }, 'error');
      this._toastrService.warning('Cannot initiate call at the moment.');
    });

    this.plivoBrowserSdk.client.on('onIncomingCallIgnored', () => {});
    this.plivoBrowserSdk.client.on('onCallRemoteRinging', () => {});
    this.plivoBrowserSdk.client.on('onConnectionChange', (event) => {});
    this.plivoBrowserSdk.client.on('onIncomingCallCanceled', () => {});
    this.plivoBrowserSdk.client.on('onCallFailed', (cause, callInfo) => {
      console.log('onCallFailed===cause===>', cause);
      console.log('onCallFailed===callInfo===>', callInfo);
      this._bugsnagService.upload(
        { 'onCallFailed =>': cause, 'callInfo=>': callInfo },
        'error'
      );
      if (this.plivoBrowserSdk.client.callUUID) {
        this.callUUID = this.plivoBrowserSdk.client.callUUID;
      }
      this._toastrService.clear();
      if (cause == 'Not Found') {
        this._toastrService.error(this.messageConstant.callBusyMessage, '');
      } else if (cause == 'Canceled') {
        this.callConnected = true;
        // this._toastrService.info(this.messageConstant.callCancelledMessage, '');
      } else if (cause == 'Busy') {
        this._toastrService.info(this.messageConstant.callBusyMessage, '');
      } else {
        this._toastrService.warning(this.messageConstant.callNotConnected, '');
      }

      setTimeout(() => {
        this._sharedService.refreshActivityLog.next('');
      }, 2000);
      if (
        this.callUUID == null ||
        this.callUUID == '' ||
        this.callEndDuration == '00:00:00'
      ) {
        this.callAnswerForm.patchValue({
          callAnswer: 12,
          otherReason: '',
        });
        this.oncallAnswerSubmit();
      }
      this.cancel();
    });
    this.plivoBrowserSdk.client.on('onCallAnswered', (callInfo) => {
      if (this.plivoBrowserSdk.client.callUUID) {
        this.callUUID = this.plivoBrowserSdk.client.callUUID;
      } else {
        this.callUUID = callInfo.callUUID;
      }

      this.multiCallUUID = callInfo.callUUID;
      this.multiCallEventName = callInfo.extraHeaders['X-PH-from'];
      this.callAnswered = true;
      this.callReady = true;
      this.callDuration = '00:00:00';
      this.callStatus = 'Ongoing Call...';
      let a = moment();
      let b = moment();
      if (!this.plivoBrowserSdk.client.callUUID) {
        this.callAnswerInterval = setInterval(() => {
          if (this.statusCheckFlag === false) {
            this._flowService
              .getCallInfo({
                name: callInfo.extraHeaders['X-PH-from'].replace(
                  '+',
                  'Resimpli'
                ),
              })
              .subscribe(
                (response: ResponseModel) => {
                  this.mergeCallDialer = response.data.users;
                  this.mergeCallDialer.callNotJoined = true;
                  this.statusCheckFlag = response.data.isCheckStatus;
                  if (
                    this.mergeCallDialer.firstCall &&
                    this.callDuration === '00:00:00'
                  ) {
                    this.counter = setInterval(() => {
                      this.showTime(a, b);
                    }, 1000);
                  }
                  if (this.statusCheckFlag === true) {
                    if (this.callAnswerInterval) {
                      clearInterval(this.callAnswerInterval);
                    }
                  }
                },
                (err: ErrorModel) => {
                  this._loaderService.stop();
                }
              );
          }
        }, 1000);
      } else {
        this.mergeCallDialer.firstCall = true;
        this.counter = setInterval(() => {
          this.showTime(a, b);
        }, 1000);
      }

      // setTimeout(() => {
      //   this.getInitialMemberList();
      // }, 7000);
    });
    this.plivoBrowserSdk.client.on('onMediaConnected', () => {});
    this.plivoBrowserSdk.client.on(
      'onCallTerminated',
      (hangupInfo, callInfo) => {
        console.log('onCallTerminated===hangupInfo===>', hangupInfo);
        console.log('onCallTerminated===callInfo===>', callInfo);
        this._bugsnagService.upload(
          { 'hangupInfo =>': hangupInfo, callInfo },
          'info'
        );
        if (callInfo && callInfo.state === 'ended') {
          clearInterval(this.callAnswerInterval);
        }
        if (this.isCalling) {
          this._toastrService.clear();
          // this._toastrService.success(this.messageConstant.callCompleted);
          setTimeout(() => {
            this._sharedService.refreshActivityLog.next('');
          }, 2000);
        }

        if (this.counter) {
          clearInterval(this.counter);
        }
        this.cancel();
      }
    );
    this.plivoBrowserSdk.client.on('onCalling', () => {});
    this.plivoBrowserSdk.client.on('onIncomingCall', () => {});
    this.plivoBrowserSdk.client.on('onMediaPermission', (event) => {
      this._bugsnagService.upload({ 'event =>': event }, 'error');
    });
    this.plivoBrowserSdk.client.on('mediaMetrics', () => {});
    this.plivoBrowserSdk.client.on('onConnectionChange', () => {});
  }

  async addMember(data) {
    let generalData = await this.getInitialMemberList();
    let jointoMpc;
    jointoMpc = this.joinToMpcHeader;
    if (!this.joinToMpcHeader) {
      this.joinToMpcHeader = true;
    }
    const toNumber = environment.countryCode + this.dialedNumber;
    //const toNumber = '+12195010032';
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (this._utilities.isTwilioUser) {
      this.statusCheckFlag = false;
      this.holdCall = true;
      let startTime = new Date().getTime();

      this.addMemberCall = false;
      const params = {
        EventName: this.multiCallEventName,
        MPCUUID: this.multiCallUUID,
        toNumber: toNumber,
        token: localStorage.getItem('token'),
        memberList: this.memberList || [],
        mergeCallDialer: {
          firstCall: this.mergeCallDialer?.firstCall
            ? this.mergeCallDialer.firstCall
            : false,
          secondCall: this.mergeCallDialer?.secondCall
            ? this.mergeCallDialer.secondCall
            : false,
          callMerged: this.mergeCallDialer?.callMerged
            ? this.mergeCallDialer.callMerged
            : false,
          callEnded: this.mergeCallDialer?.callEnded
            ? this.mergeCallDialer.callEnded
            : true,
          callNotJoined: this.mergeCallDialer?.callNotJoined
            ? this.mergeCallDialer.callNotJoined
            : false,
        },
        uname: user.eUname,
        addToMpcHeader: this.addToMpcHeader,
        jointoMpc: jointoMpc,
        callUUID: this.callUUID,
      };
      const name = this.callUUID;
      const memberInterval = setInterval(() => {
        if (this.statusCheckFlag === false) {
          this._flowService.getCallInfo({ name }).subscribe(
            (response: ResponseModel) => {
              this.mergeCallDialer = response.data.users;
              this.mergeCallDialer.callNotJoined = true;
              this.statusCheckFlag = response.data.isCheckStatus;
              if (this.statusCheckFlag === true) {
                this.getInitialMemberList();
                if (memberInterval) {
                  clearInterval(memberInterval);
                }
              }
            },
            (err: ErrorModel) => {
              this._loaderService.stop();
            }
          );
          if (new Date().getTime() - startTime > 30000) {
            this.statusCheckFlag = true;
            clearInterval(memberInterval);
            if (this.mergeCallDialer.secondCall === false) {
              setTimeout(() => {
                this.callMerge();
              }, 2000);
            } else {
              this.allHoldUnholdMember({
                hold: false,
                mute: false,
              });
            }
          }
        }
      }, 1000);
      try {
        await this._twilioService.addMultiPartyCall(params).subscribe(
          (response: ResponseModel) => {},
          (err: ErrorModel) => {
            this._loaderService.stop();
          }
        );
      } catch (e) {
        console.log('e', e);
      }
    } else {
      try {
        this.statusCheckFlag = false;
        this.holdCall = true;
        let startTime = new Date().getTime();

        this.addMemberCall = false;
        const params = {
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
          toNumber: toNumber,
          token: localStorage.getItem('token'),
          memberList: this.memberList || [],
          mergeCallDialer: {
            firstCall: this.mergeCallDialer?.firstCall
              ? this.mergeCallDialer.firstCall
              : false,
            secondCall: this.mergeCallDialer?.secondCall
              ? this.mergeCallDialer.secondCall
              : false,
            callMerged: this.mergeCallDialer?.callMerged
              ? this.mergeCallDialer.callMerged
              : false,
            callEnded: this.mergeCallDialer?.callEnded
              ? this.mergeCallDialer.callEnded
              : true,
            callNotJoined: this.mergeCallDialer?.callNotJoined
              ? this.mergeCallDialer.callNotJoined
              : false,
          },
          uname: user.eUname,
          addToMpcHeader: this.addToMpcHeader,
          jointoMpc: jointoMpc,
          callUUID: this.callUUID,
        };
        const name = this.multiCallEventName.replace('+', 'Resimpli');
        const memberInterval = setInterval(() => {
          if (this.statusCheckFlag === false) {
            this._flowService.getCallInfo({ name }).subscribe(
              (response: ResponseModel) => {
                this.mergeCallDialer = response.data.users;
                this.mergeCallDialer.callNotJoined = true;
                this.statusCheckFlag = response.data.isCheckStatus;
                if (this.statusCheckFlag === true) {
                  this.getInitialMemberList();
                  if (memberInterval) {
                    clearInterval(memberInterval);
                  }
                }
              },
              (err: ErrorModel) => {
                this._loaderService.stop();
              }
            );
            if (new Date().getTime() - startTime > 30000) {
              this.statusCheckFlag = true;
              clearInterval(memberInterval);
              this.allHoldUnholdMember({
                hold: false,
                mute: false,
              });
            }
          }
        }, 1000);
        if (this.mergeCallDialer.secondCall === false) {
          if (this.plivoBrowserSdk.client.callUUID && this.muteCall) {
            this.muteCall = false;
            this.plivoBrowserSdk.client.unmute();
          }
          if (this.plivoBrowserSdk.client.callUUID && this.holdCall) {
            let obj = { uuid: this.plivoBrowserSdk.client.callUUID };
            this._sharedService.unHoldCallFn(obj).subscribe(
              (response) => {
                if (response.statusCode == 200) {
                  //this.holdCall = false;
                  this._flowService
                    .flowConferenceAddParticipant(params)
                    .subscribe(
                      (response: ResponseModel) => {},
                      (err: ErrorModel) => {
                        this._loaderService.stop();
                      }
                    );
                }
              },
              (err: ErrorModel) => {
                if (err.error) {
                  const error: ResponseModel = err.error;
                  this._toastrService.error(error.message, '');
                } else {
                  this._toastrService.error(
                    this.messageConstant.unknownError,
                    ''
                  );
                }
              }
            );
          } else {
            await this._flowService
              .flowConferenceAddParticipant(params)
              .subscribe(
                (response: ResponseModel) => {},
                (err: ErrorModel) => {
                  this._loaderService.stop();
                }
              );
          }
        } else {
          await this._flowService
            .flowConferenceAddParticipant(params)
            .subscribe(
              (response: ResponseModel) => {},
              (err: ErrorModel) => {
                this._loaderService.stop();
              }
            );
        }
      } catch (e) {
        console.log('e', e);
      }
    }
  }

  async addMemberByContact(data) {
    let jointoMpc;
    jointoMpc = this.joinToMpcHeader;
    if (!this.joinToMpcHeader) {
      this.joinToMpcHeader = true;
    }

    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (this._utilities.isTwilioUser) {
      this.statusCheckFlag = false;
      this.holdCall = true;
      let startTime = new Date().getTime();

      this.addMemberCall = false;
      const params = {
        EventName: this.multiCallEventName,
        MPCUUID: this.multiCallUUID,
        toNumber: this.dialedNumber,
        token: localStorage.getItem('token'),
        memberList: this.memberList || [],
        mergeCallDialer: {
          firstCall: this.mergeCallDialer?.firstCall
            ? this.mergeCallDialer.firstCall
            : false,
          secondCall: this.mergeCallDialer?.secondCall
            ? this.mergeCallDialer.secondCall
            : false,
          callMerged: this.mergeCallDialer?.callMerged
            ? this.mergeCallDialer.callMerged
            : false,
          callEnded: this.mergeCallDialer?.callEnded
            ? this.mergeCallDialer.callEnded
            : true,
          callNotJoined: this.mergeCallDialer?.callNotJoined
            ? this.mergeCallDialer.callNotJoined
            : false,
        },
        uname: user.eUname,
        addToMpcHeader: this.addToMpcHeader,
        jointoMpc: jointoMpc,
        callUUID: this.callUUID,
      };
      const name = this.callUUID;
      const memberInterval = setInterval(() => {
        if (this.statusCheckFlag === false) {
          this._flowService.getCallInfo({ name }).subscribe(
            (response: ResponseModel) => {
              this.mergeCallDialer = response.data.users;
              this.mergeCallDialer.callNotJoined = true;
              this.statusCheckFlag = response.data.isCheckStatus;
              if (this.statusCheckFlag === true) {
                this.getInitialMemberList();
                if (memberInterval) {
                  clearInterval(memberInterval);
                }
              }
            },
            (err: ErrorModel) => {
              this._loaderService.stop();
            }
          );
          if (new Date().getTime() - startTime > 30000) {
            this.statusCheckFlag = true;
            clearInterval(memberInterval);
            if (this.mergeCallDialer.secondCall === false) {
              this.statusCheckFlag = true;
              setTimeout(() => {
                this.callMerge();
              }, 2000);
            } else {
              this.allHoldUnholdMember({
                hold: false,
                mute: false,
              });
            }
          }
        }
      }, 1000);
      try {
        await this._twilioService.addMultiPartyCall(params).subscribe(
          (response: ResponseModel) => {},
          (err: ErrorModel) => {
            this._loaderService.stop();
          }
        );
      } catch (e) {
        console.log('e', e);
      }
    } else {
      try {
        this.statusCheckFlag = false;
        this.addMemberCall = false;
        this.holdCall = true;
        let startTime = new Date().getTime();
        const params = {
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
          toNumber: this.dialedNumber,
          token: localStorage.getItem('token'),
          memberList: this.memberList || [],
          mergeCallDialer: {
            firstCall: this.mergeCallDialer?.firstCall
              ? this.mergeCallDialer.firstCall
              : false,
            secondCall: this.mergeCallDialer?.secondCall
              ? this.mergeCallDialer.secondCall
              : false,
            callMerged: this.mergeCallDialer?.callMerged
              ? this.mergeCallDialer.callMerged
              : false,
            callEnded: this.mergeCallDialer?.callEnded
              ? this.mergeCallDialer.callEnded
              : true,
            callNotJoined: this.mergeCallDialer?.callNotJoined
              ? this.mergeCallDialer.callNotJoined
              : false,
          },
          uname: user.eUname,
          addToMpcHeader: this.addToMpcHeader,
          callUUID: this.callUUID,
          jointoMpc: jointoMpc,
        };

        this.statusCheckFlag = false;
        const name = this.multiCallEventName.replace('+', 'Resimpli');

        const memberInter = setInterval(() => {
          if (this.statusCheckFlag === false) {
            this._flowService.getCallInfo({ name }).subscribe(
              (response: ResponseModel) => {
                this.mergeCallDialer = response.data.users;
                this.mergeCallDialer.callNotJoined = true;
                this.statusCheckFlag = response.data.isCheckStatus;
                if (this.statusCheckFlag === true) {
                  this.getInitialMemberList();
                  if (memberInter) {
                    clearInterval(memberInter);
                  }
                }
              },
              (err: ErrorModel) => {
                this._loaderService.stop();
              }
            );
            if (new Date().getTime() - startTime > 30000) {
              this.statusCheckFlag = true;
              clearInterval(memberInter);
              this.allHoldUnholdMember({
                hold: false,
                mute: false,
              });
            }
          }
        }, 1000);
        if (this.mergeCallDialer.secondCall === false) {
          if (this.plivoBrowserSdk.client.callUUID && this.muteCall) {
            this.muteCall = false;
            this.plivoBrowserSdk.client.unmute();
          }
          if (this.plivoBrowserSdk.client.callUUID && this.holdCall) {
            let obj = { uuid: this.plivoBrowserSdk.client.callUUID };
            this._sharedService.unHoldCallFn(obj).subscribe(
              (response) => {
                if (response.statusCode == 200) {
                  //this.holdCall = false;
                  this._flowService
                    .flowConferenceAddParticipant(params)
                    .subscribe(
                      (response: ResponseModel) => {},
                      (err: ErrorModel) => {
                        this._loaderService.stop();
                      }
                    );
                }
              },
              (err: ErrorModel) => {
                if (err.error) {
                  const error: ResponseModel = err.error;
                  this._toastrService.error(error.message, '');
                } else {
                  this._toastrService.error(
                    this.messageConstant.unknownError,
                    ''
                  );
                }
              }
            );
          } else {
            await this._flowService
              .flowConferenceAddParticipant(params)
              .subscribe(
                (response: ResponseModel) => {},
                (err: ErrorModel) => {
                  this._loaderService.stop();
                }
              );
          }
        } else {
          await this._flowService
            .flowConferenceAddParticipant(params)
            .subscribe(
              (response: ResponseModel) => {},
              (err: ErrorModel) => {
                this._loaderService.stop();
              }
            );
        }
      } catch (e) {
        console.log('e', e);
      }
    }
  }
  getMemberList() {
    if (this._utilities.isTwilioUser) {
      this._twilioService
        .twilioConferenceList({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
        })
        .subscribe((response: ResponseModel) => {
          this.memberList = response.data;
          this.mainMember = this.memberList.filter(
            (m) => m.label === 'Owner'
          )[0];
          this.showMemberList = true;
          this.totalParticipant = this.memberList.length;
        });
    } else {
      this._flowService
        .plivoConferenceList({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
        })
        .subscribe((response: ResponseModel) => {
          this.memberList = response.data.objects.filter(
            (d) => d.exitCause === null
          );
          this.memberList = this.removeDuplicatesValue(
            this.memberList,
            'memberAddress'
          );
          this.mainMember = this.memberList[this.memberList.length - 1];
          this.showMemberList = true;
          this.totalParticipant = this.memberList.length;
        });
    }
  }

  callMerge() {
    this.statusCheckFlag = true;
    this.holdCall = false;
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (this._utilities.isTwilioUser) {
      this._twilioService
        .twilioConferenceMergeParticipant({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
          memberList: this.memberList || [],
          uname: user.eUname,
          callUuid: this.callUUID,
        })
        .subscribe((response: ResponseModel) => {
          this.mergeCallDialer.callMerged = true;
          this.statusCheckFlag = true;
          //this.getInitialMemberList();
          this.getMemberList();
          this.allHoldUnholdMember({
            hold: false,
            mute: false,
          });
        });
    } else {
      this._flowService
        .flowConferenceMergeParticipant({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
          memberList: this.memberList || [],
          uname: user.eUname,
          callUuid: this.callUUID,
        })
        .subscribe((response: ResponseModel) => {
          this.mergeCallDialer.callMerged = true;
          this.statusCheckFlag = true;
        });
    }
    return true;
  }
  getInitialMemberList() {
    if (this._utilities.isTwilioUser) {
      this._twilioService
        .twilioConferenceList({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
        })
        .subscribe((response: ResponseModel) => {
          this.memberList = response.data;
          this.mainMember = this.memberList.filter(
            (m) => m.label === 'Owner'
          )[0];
          this.totalParticipant = this.memberList.length;
          if (this.memberList && this.memberList.length > 0) {
            this.conferenceId = this.memberList[0].conferenceSid;
          }
        });
    } else {
      this._flowService
        .plivoConferenceList({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
        })
        .subscribe((response: ResponseModel) => {
          this.memberList = response.data.objects.filter(
            (d) => d.exitCause === null
          );
          this.memberList = this.removeDuplicatesValue(
            this.memberList,
            'memberAddress'
          );
          this.totalParticipant = this.memberList.length;
        });
    }
  }

  removeDuplicatesValue(originalArray, objKey) {
    let trimmedArray = [];
    let values = [];
    let value;

    for (let i = 0; i < originalArray.length; i++) {
      value = originalArray[i][objKey];

      if (values.indexOf(value) === -1) {
        trimmedArray.push(originalArray[i]);
        values.push(value);
      }
    }

    return trimmedArray;
  }
  getFinalMemberList() {
    this.mergeCallDialer.checkStatus = false;
    this._flowService
      .plivoConferenceList({
        EventName: this.multiCallEventName,
        MPCUUID: this.multiCallUUID,
      })
      .subscribe((response: ResponseModel) => {
        let data = response.data.objects.filter((d) => d.exitCause === null);
        if (data && data.length === 2) {
          this.mergeCallDialer.firstCall = true;
          this.mergeCallDialer.secongCall = false;
          this.mergeCallDialer.callMerged = false;
        } else if (data && data.length >= 3) {
          this.mergeCallDialer.firstCall = true;
          this.mergeCallDialer.secongCall = true;
        }

        this.mergeCallList = data.length > 2;
        this.memberList = data;
        this.totalParticipant = data.length;
      });
  }
  unHoldMembers() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    this._flowService
      .unHoldMember({
        EventName: this.multiCallEventName,
        MPCUUID: this.multiCallUUID,
        memberList: this.memberList || [],
        mergeCallDialer: {
          firstCall: this.mergeCallDialer?.firstCall
            ? this.mergeCallDialer.firstCall
            : false,
          secondCall: this.mergeCallDialer?.secondCall
            ? this.mergeCallDialer.secondCall
            : false,
          callMerged: this.mergeCallDialer?.callMerged
            ? this.mergeCallDialer.callMerged
            : false,
          callEnded: this.mergeCallDialer?.callEnded
            ? this.mergeCallDialer.callEnded
            : true,
          callNotJoined: this.mergeCallDialer?.callNotJoined
            ? this.mergeCallDialer.callNotJoined
            : false,
        },
        uname: user.eUname,
      })
      .subscribe((response: ResponseModel) => {});
  }

  allHoldUnholdMember(data) {
    this.holdCall = data.hold;
    this.muteCall = data.mute;
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (this._utilities.isTwilioUser) {
      this._twilioService
        .allHoldUnholdMember({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
          conferenceId: this.conferenceId,
          allHold: data.hold,
          allMute: data.mute,
          memberList: this.memberList || [],
          mergeCallDialer: {
            firstCall: this.mergeCallDialer?.firstCall
              ? this.mergeCallDialer.firstCall
              : false,
            secondCall: this.mergeCallDialer?.secondCall
              ? this.mergeCallDialer.secondCall
              : false,
            callMerged: this.mergeCallDialer?.callMerged
              ? this.mergeCallDialer.callMerged
              : false,
            callEnded: this.mergeCallDialer?.callEnded
              ? this.mergeCallDialer.callEnded
              : true,
            callNotJoined: this.mergeCallDialer?.callNotJoined
              ? this.mergeCallDialer.callNotJoined
              : false,
          },
          uname: user.eUname,
        })
        .subscribe((response: ResponseModel) => {});
    } else {
      this._flowService
        .allHoldUnholdMember({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
          allHold: data.hold,
          allMute: data.mute,
          memberList: this.memberList || [],
          mergeCallDialer: {
            firstCall: this.mergeCallDialer?.firstCall
              ? this.mergeCallDialer.firstCall
              : false,
            secondCall: this.mergeCallDialer?.secondCall
              ? this.mergeCallDialer.secondCall
              : false,
            callMerged: this.mergeCallDialer?.callMerged
              ? this.mergeCallDialer.callMerged
              : false,
            callEnded: this.mergeCallDialer?.callEnded
              ? this.mergeCallDialer.callEnded
              : true,
            callNotJoined: this.mergeCallDialer?.callNotJoined
              ? this.mergeCallDialer.callNotJoined
              : false,
          },
          uname: user.eUname,
        })
        .subscribe((response: ResponseModel) => {});
    }
  }

  dropMembers(id) {
    if (this._utilities.isTwilioUser) {
      this._twilioService
        .dropMember({
          conferenceId: id.conferenceSid,
          callSid: id.callSid,
        })
        .subscribe((response: ResponseModel) => {
          //this.memberList = response.data.objects.filter((d) => d.exitCause === null);
          if (id.label === 'Owner') {
            this.resetToDefault();
          }
          this.showMemberList = false;
          this.getInitialMemberList();
        });
    } else {
      this._flowService
        .dropMember({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
          memberId: id,
        })
        .subscribe((response: ResponseModel) => {
          //this.memberList = response.data.objects.filter((d) => d.exitCause === null);
          this.showMemberList = false;
          this.getInitialMemberList();
        });
    }
  }
  getName(address) {
    let name = '';
    let name1 = this.teams?.find(
      (d) => d.contactNumber === '+' + address || d.contactNumber === address
    );
    let name2 = this.contactData?.find(
      (d) => d.phoneNumber === '+' + address || d.contactNumber === address
    );
    if (name1 && name1.name !== '') {
      return name1.name;
    } else if (name2 && name2.name !== '') {
      return name2.name;
    } else {
      return 'Unknown Number';
    }
  }
  getNumber(address) {
    if (
      address.startsWith('Listening:') ||
      address.startsWith('Agent:') ||
      address.startsWith('New Participant:')
    ) {
      return 'Team Member';
    } else {
      return this._utilities.maskNumber(address);
    }
  }

  selectUser(user) {
    this.dialedNumber = user.contactNumber;
  }
  endCall() {
    if (this._utilities.isTwilioUser) {
      const member = this.memberList.filter((d) => d.label === 'Owner');
      this._twilioService
        .dropMember({
          conferenceId: member[0].conferenceSid,
          callSid: member[0].callSid,
        })
        .subscribe((response: ResponseModel) => {
          this.callStatus = 'Call Ended';
        });
      this.showMemberList = false;
      this.addMemberCall = false;
      if (this.counter) {
        clearInterval(this.counter);
      }
      this.statusCheckFlag = true;
      this.resetToDefault();
    } else {
      this._flowService
        .endCall({
          EventName: this.multiCallEventName,
          MPCUUID: this.multiCallUUID,
        })
        .subscribe((response: ResponseModel) => {
          this.callStatus = 'Call Ended';
        });
      this.showMemberList = false;
      this.addMemberCall = false;
      if (this.counter) {
        clearInterval(this.counter);
      }
      this.statusCheckFlag = true;
      this.resetToDefault();
    }
  }

  cancel() {
    if (this.holdCall) {
      this.unHold(true);
      return;
    }
    if (this._utilities.callType == 'outgoing') {
      this.callConnected = true;
      this.dialedNumber = '';
      this.callStatus = 'Call Ended';
      if (this.counter) {
        clearInterval(this.counter);
      }
      this.statusCheckFlag = false;

      if (this.isTwilioCall) {
        this._twilioService.callHangup();
      } else {
        if (this.plivoBrowserSdk.client.callSession) {
          this.plivoBrowserSdk.client.hangup();
          if (this._utilities.numberOfCallsRunning > 0) {
            this._utilities.numberOfCallsRunning--;
          }
        }
      }

      setTimeout(() => {
        this._sharedService.refreshActivityLog.next('');
      }, 2000);

      this.resetToDefault();

      if (this.isMinimized) {
        this.toggleDisplay();
      }
    } else {
      if (this._utilities.callTypeReject == 'false') {
        this.callConnected = true;
        this.dialedNumber = '';
        this.callStatus = 'Call Ended';
        this.resetToDefault();
      }
    }
  }

  resetToDefault() {
    this.defaultDiaplay = false;
    this.makingCall = false;
    this._utilities.makingCallDialer = false;
    this.isCalling = false;
    this.callReady = false;
    this.isDialPad = false;
    this.callEnd = true;
    if (
      this.callUUID == null ||
      this.callUUID == '' ||
      this.callEndDuration == '00:00:00'
    ) {
      this.callEnd = false;
    }
    this.redialCall = true;
    this.holdCall = false;
    this.muteCall = false;
    this.isCurrentCall = true;
    this.callEndTime = moment()
      .tz(localStorage.timezoneOffset)
      .format('HH:mm A');
  }

  showTime(dateA, dateB) {
    this.callStatus = 'Ongoing Call...';
    dateB.add(1, 'seconds');
    this.callDuration = moment
      .utc(
        moment(dateB, 'DD/MM/YYYY HH:mm:ss').diff(
          moment(dateA, 'DD/MM/YYYY HH:mm:ss')
        )
      )
      .format('HH:mm:ss');
    this.callEndDuration = moment
      .utc(
        moment(dateB, 'DD/MM/YYYY HH:mm:ss').diff(
          moment(dateA, 'DD/MM/YYYY HH:mm:ss')
        )
      )
      .format('HH:mm:ss');
  }

  makeCall() {
    this._utilities.isCallStatusLead = false;
    this.callEndDuration = '00:00:00';
    if (!this.leadData?.number) {
      this._toastrService.info(this.messageConstant.noCampaignNumber, '', {
        enableHtml: true,
      });
      return;
    }
    if (this.contactType != 'seller') {
      if (this.buyerCallFrom == '') {
        this._toastrService.info(this.messageConstant.noNumber, '', {
          enableHtml: true,
        });
        return;
      }
    }
    this.callUUID = '';
    let phone = this.contactData[this.currentContact - 1]?.phoneNumber
      ? this.contactData[this.currentContact - 1]?.phoneNumber
      : this.contactData[this.currentContact - 1]?.contactNumber;
    if (!phone) {
      return;
    }
    // this.makeOutgoingCall(phone);
    this.callingData = this.contactData[this.currentContact - 1];
    this.smsDetailForm.patchValue({
      smsNumber: this.currentContact - 1,
    });
    this.isCalling = true;
    this.defaultDiaplay = false;
    this.makingCall = true;
    this._utilities.makingCallDialer = true;
    this.redialCall = false;
    this.callEnd = false;
    this.callStatus = 'Ongoing Call...';
    if (this.isTwilioCall) {
      this.initiateCall(phone);
    } else {
      if (this.plivoBrowserSdk.client.isLoggedIn) {
        this.initiateCall(phone);
      } else {
        this.plivoAccountIndex = Math.floor(Math.random() * 5);
        const user = JSON.parse(localStorage.getItem('currentUser'));
        if (user?.email === 'john@cmjhomebuyers.com') {
          this.plivoAccountIndex = 5;
        }

        this.plivoBrowserSdk.client.login(user.eUname, user.ePwd);
        // this.plivoBrowserSdk.client.login(
        //   'safalTest542770037250398027',
        //   'mastermind###'
        // );

        this.plivoBrowserSdk.client.on('onLogin', () => {
          if (this.isCalling) {
            this.initiateCall(phone);
          }
        });
      }
    }
  }

  async getLeadDetails(leadId, isFirstLead?) {
    const obj = {
      leadId: leadId,
    };
    this._loaderService.start('loader-01');

    try {
      const response: any = await this._leadService.leadInfo(obj).toPromise();
      this._loaderService.stop('loader-01');
      if (response.statusCode == 200) {
        this._sharedService.refreshInfo.next(true);
        this.callStatus = '';
        if (this.isMinimizedScreen) {
          this.redialCall = false;
          this.defaultDiaplay = true;
        }
        this.isMinimizedScreen = true;
        this.leadData = response?.data?.leadData;

        const status = this.projectStatus.find(
          (x) => x._id == this.leadData?.mainStatusId
        );
        this.leadData.mainStatusTitle =
          status?.labels?.title || status?.title || 'NA';
        this.getCount(this.leadData?._id);
        this.getTeams(this.leadData?._id);
        this.currentTaskPage = 1;
        this.listTask(true);
        this.leadData.taskCount = this.leadData?.taskCount || 0;
        this.leadData.tags = _.sortBy(this.leadData?.tags, 'label');
        if (isFirstLead) {
          this.currentMainStatusId = this.leadData?.mainStatusId;
        }
        if (this.leadData?.tags) {
          this.splitTags = this.leadData?.tags;
          this.showTags =
            this.splitTags.length > 6
              ? this.splitTags.slice(0, 6)
              : this.splitTags;
        }
        if (this.leadData?.secondaryContact) {
          this.leadData['isSecondaryContacts'] = true;
          if (
            this.leadData?.email ||
            this.leadData?.title ||
            this.leadData?.phoneNumber
          ) {
            const obj = {
              email: this.leadData?.email,
              name: this.leadData?.title,
              phoneNumber: this.leadData?.phoneNumber,
              contactType: 'seller',
              isOwnerContact: true,
              numberType: this.leadData?.numberType,
            };

            this.leadData.secondaryContact.unshift(obj);
          }
        }

        this.currentContact = 1;
        this.contactData = [
          ...this.leadData?.secondaryContact,
          ...this.leadData?.buyers,
        ];
        if (this.data?.index) {
          this.currentContact = this.data?.index + 1;
          this.currentContactDis = this.data?.index;
          delete this.data['index'];
        }
        this.callingData = this.contactData[this.currentContact - 1];
        this.getUser();
        this.onCallDialog();
        this.getTeam();
        if (this.leadData?.dripId) {
          this.isDripData.autoStopDrip = response?.data?.leadData.autoStopDrip;
          this.isDripData.dripName = this.leadData?.dripData.name;
          this.isDripData.started = moment(this.leadData?.activeTime)
            .tz(localStorage.getItem('timezoneOffset'))
            .format('MMM D, YYYY hh:mm A');
          this.isDripData.dripStartDate = `${this.getHumanReadableTime(
            this.leadData?.activeTime,
            localStorage.getItem('timezoneOffset')
          )} ago`;
          let completedStep = [];
          let pendingStep = [];
          response.data.leadData.dripBreakDown.map((dripStep) => {
            if (
              moment().tz(localStorage.getItem('timezoneOffset')).valueOf() >
              dripStep.targetTime
            ) {
              completedStep.push(dripStep);
            } else {
              pendingStep.push(dripStep);
            }
          });
          this.isDripData.completedData = this.viewDripDetails(
            _.groupBy(completedStep, 'action')
          );
          let breakDown = this.leadData?.dripBreakDown.map((item) => {
            if (
              moment().tz(localStorage.getItem('timezoneOffset')).valueOf() <
              item.targetTime
            ) {
              item.completed = true;
            } else {
              item.completed = false;
            }
            item.scheduled = moment(item.targetTime)
              .tz(localStorage.getItem('timezoneOffset'))
              .format('MMM D, YYYY hh:mm A');
            return item;
          });
          this.isDripData.breakDown = breakDown;
        }
        if (!this.isLeadListResponse) {
          this.getLeadListId(this.leadData?.mainStatusId);
        }
        this.updateStatusDropDown();
        this.getSmsTemplates();
        this.openLeadTime = '';
        if (this.leadData?.isNewLead && this.leadData?.openLeadDate) {
          setInterval(() => {
            let diffTime = Math.abs(
              new Date().valueOf() -
                new Date(this.leadData?.openLeadDate).valueOf()
            );
            if (diffTime) {
              let days = diffTime / (24 * 60 * 60 * 1000);
              let hours = (days % 1) * 24;
              let minutes = (hours % 1) * 60;
              let secs = (minutes % 1) * 60;
              [days, hours, minutes, secs] = [
                Math.floor(days),
                Math.floor(hours),
                Math.floor(minutes),
                Math.floor(secs),
              ];
              this.openLeadTime =
                days + 'd ' + hours + 'h ' + minutes + 'm ' + secs + 's';
            }
          }, 1);
        }
      }
    } catch (err) {
      this._loaderService.stop('loader-01');
      if (err.error) {
        const error: ResponseModel = err.error;
        this._toastrService.error(error.message, '');
      } else {
        this._toastrService.error(this.messageConstant.unknownError, '');
      }
      this._route.navigate(['/leads']);
    }
  }

  updateStatusDropDown() {
    const status = this.leadData?.mainStatusTitle;
    this.projectStatus = this.mainProjectStatus;
    // if (status === 'warm lead' || status === 'dead lead') {
    //   this.projectStatus = this.mainProjectStatus.filter(
    //     (x) =>
    //       x.title === 'no contact made' ||
    //       x.title === 'contact made' ||
    //       x.title === 'warm lead' ||
    //       x.title === 'dead lead'
    //   );
    // }
    if (status == 'assigned to buyer') {
      this.projectStatus = this.mainProjectStatus.filter(
        (x) => x.title != 'inventory'
      );
      let statusObj = {
        _id: '5feb4221aa810a3849fa551b',
        title: 'sold',
      };
      this.projectStatus.push(statusObj);
      this.projectStatus = _.uniqBy(this.projectStatus, '_id');
    }
  }

  onMapDailog(map, lat, long) {
    this.dialogRef = this.dialog.open(GoogleMapComponent, {
      width: '820px',
      height: '500px',
      panelClass: 're-new-googleMap',
      data: {
        action: map,
        lat: lat,
        long: long,
      },
    });
  }

  streetViewApi(map, address) {
    if (address) {
      this._sharedService.getMapLatLng({ address }).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            let responseAddress = response?.data?.location;
            this.onStreetMapDailog(map, address, responseAddress);
          }
          this._loaderService.stop();
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
    }
  }

  onStreetMapDailog(map, address, responseAddress) {
    this.dialogRef = this.dialog.open(StreetMapComponent, {
      width: '820px',
      height: '450px',
      panelClass: 're-new-googleMap',
      data: {
        action: map,
        address,
        responseAddress,
      },
    });
  }

  onOpenLead() {
    this.dialogRef = this.dialog.open(OpenLeadDialogComponent, {
      width: '500px',
      data: {
        leadId: this.leadData?._id,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.leadData.isNewLead = false;
      }
    });
  }

  markHotLead() {
    this._loaderService.start();

    let param = {
      isHotLead: !this.leadData?.isHotLead,
    };
    this._leadService.markHotLead(param, this.leadData?._id).subscribe(
      (data: any) => {
        this._loaderService.stop();
        this.leadData.isHotLead = !this.leadData?.isHotLead;
        this._sharedService.refreshInfo.next(true);
        this._sharedService.refreshActivityLog.next(true);
        if (this.leadData?.isHotLead) {
          this._toastrService.success(this.messageConstant.markedAsHot, '');
        } else {
          this._toastrService.success(this.messageConstant.removedAsHot, '');
        }
      },
      (error: any) => {
        this._loaderService.stop();
      }
    );
  }

  markAsFavorite() {
    let obj = {
      isFavourite: !this.leadData?.isFavourite,
    };
    let _id = this.leadData?._id;
    this._loaderService.start();
    this._sharedService.markAsFavorite(_id, obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.leadData.isFavourite = !this.leadData?.isFavourite;
          if (this.leadData?.isFavourite) {
            this._toastrService.success(
              this.messageConstant.markedAsFavorite,
              ''
            );
          } else {
            this._toastrService.success(
              this.messageConstant.removedAsFavorite,
              ''
            );
          }
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  onCampaign(stop?) {
    if (this.leadData?.dripId && !stop) {
      return;
    }
    if (
      typeof this.leadData.number == 'undefined' ||
      this.leadData.number == '' ||
      this.leadData.number == null
    ) {
      this._toastrService.info(this.messageConstant.noCampaignNumber, '', {
        enableHtml: true,
      });
    } else {
      this.dialogRef = this.dialog.open(AutoFollowUpComponent, {
        width: '550px',
        data: {
          data: this.leadData,
        },
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this._leadService.leadInfo({ leadId: this.leadData._id }).subscribe(
            (response: ResponseModel) => {
              if (response.statusCode == 200) {
                this._loaderService.stop();
                this.leadData.dripId = response.data.leadData.dripId;
                this.getLeadDetails(this.leadData?._id);
                this._sharedService.refreshInfo.next(true);
                this._sharedService.refreshActivityLog.next(true);
              }
            },
            (err: ErrorModel) => {
              this._loaderService.stop();
              if (err.error) {
                const error: ResponseModel = err.error;
                this._toastrService.error(error.message, '');
              } else {
                this._toastrService.error(
                  this.messageConstant.unknownError,
                  ''
                );
              }
            }
          );
        }
      });
    }
  }

  initiateCall(phoneNumber) {
    this.mergeCallDialer = {
      firstCall: false,
      secondCall: false,
      callMerged: false,
      callEnded: true,
      callNotJoined: false,
      checkStatus: false,
      endCause: '',
    };
    this.statusCheckFlag = false;
    const user = JSON.parse(localStorage.getItem('currentUser'));
    let number = this.leadData?.defaultCallNumber
      ? this.leadData?.defaultCallNumber
      : this.leadData?.number;
    if (this.contactType != 'seller') {
      number = this.buyerCallFrom;
    }
    const extraHeaders = {
      'X-PH-to': phoneNumber,
      'X-PH-from': number,
      'X-PH-default': this._utilities.unMaskNumber(number),
      'X-PH-userId': this.userId,
      'X-PH-Id': user.eUname,
      'X-PH-moduleType':
        this.contactData[this.currentContact - 1]?.contactType == 'Buyer' ||
        this.contactData[this.currentContact - 1]?.contactType == 'Agent'
          ? 'Buyer'
          : 'Leads',
    };

    this.addToMpcHeader = {
      'X-PH-to': phoneNumber,
      'X-PH-from': number,
      'X-PH-default': this._utilities.unMaskNumber(number),
      'X-PH-userId': this.userId,
      'X-PH-Id': user.eUname,
      'X-PH-moduleType':
        this.contactData[this.currentContact - 1]?.contactType == 'Buyer' ||
        this.contactData[this.currentContact - 1]?.contactType == 'Agent'
          ? 'Buyer'
          : 'Leads',
    };

    let buyerId;
    let leadId;

    if (
      this.contactData[this.currentContact - 1]?.contactType == 'Buyer' ||
      this.contactData[this.currentContact - 1]?.contactType == 'Agent'
    ) {
      extraHeaders['X-PH-buyerId'] =
        this.contactData[this.currentContact - 1]?._id;
      buyerId = this.contactData[this.currentContact - 1]?._id;
    } else {
      extraHeaders['X-PH-leadId'] = this.leadId;
      leadId = this.leadId;
    }

    // this.plivoBrowserSdk.client.call(
    //   `safalTest542770037250398027@phone.plivo.com`,
    //   extraHeaders
    // );

    if (this.isTwilioCall) {
      let moduleType =
        this.contactData[this.currentContact - 1]?.contactType == 'Buyer' ||
        this.contactData[this.currentContact - 1]?.contactType == 'Agent'
          ? 'Buyer'
          : 'Leads';

      this._twilioService.initializeTwilio({
        from: number,
        to: phoneNumber,
        number: number,
        userId: this.userId,
        moduleType: moduleType,
        eUname: user.eUname,
        buyerId: buyerId,
        leadId: leadId,
      });
    } else {
      this.plivoBrowserSdk.client.call(
        `${user.eUname}@phone.plivo.com`,
        extraHeaders
      );
    }

    this._utilities.callType = 'outgoing';
    this._utilities.numberOfCallsRunning++;
  }

  addMemberData() {
    if (
      this.userPlan === 'enterprise' ||
      this.userPlan === 'pro' ||
      this.userPlan === 'premium' ||
      this.userPlan === 'enterprise existing'
    ) {
      this.addMemberCall = true;
      this.teams = this.allTeams;
      this.dialedNumber = '';
    } else {
      this.dialog.open(UpgradeAccountComponent, {
        disableClose: true,
        width: '350px',
        data: {
          link: '',
        },
      });
    }
  }
  getUser() {
    this._sharedService.getUser().subscribe(
      (response) => {
        if (response.code == 200) {
          this.userId = response.data?.userId;

          if (!this.isTwilioCall) {
            if (!this.plivoBrowserSdk?.client?.isLoggedIn) {
              this.initializePlivo();
            }
          }
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  isActive(status) {
    let mainStatus = status?.title || status?.name;
    return mainStatus == this.leadData?.mainStatusTitle;
  }

  selectMainStatus(status) {
    //this.mainStatusId = status?._id;
    this.updateLeadStatusFunction(status?._id);
  }

  updateLeadStatusFunction(value) {
    const currentStatus = this.projectStatus.find(
      (x) => x._id == this.leadData?.mainStatusId
    );

    const fromStatus = currentStatus?._id;
    const type = this.projectStatus.filter((x) => x._id === value)[0];
    const toStatus = type?._id;
    this.mainStatusId = value;

    const taskStatusExclude = [
      StatusConstant.InventoryStatus.INVENTORY,
      StatusConstant.InventoryStatus.SOLD,
    ];

    let checkResponse = this._utilities.statusChangeChecks({
      data: this.leadData,
      fromStatus,
      toStatus,
      checkTask: true,
      checkPropertyAddress: true,
      allTasks: this.leadData?.taskCount,
      module: 'Leads',
    });

    if (checkResponse?.success == 0) {
      if (checkResponse?.type == 2) {
        this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '450px',
          data: {
            header: 'Confirmation',
            type: 'Leads',
            yesTitle: 'Ok',
            hideNoButton: true,
            text: this.messageConstant.soldFromLeadsMessage,
          },
        });
      }
      if (checkResponse?.type == 3)
        this._toastrService.error(this.messageConstant.propertyAddressMissing);
      return;
    } else {
      let underContractDate;
      let offerCreatedDate;
      if (this.leadData?.underContractDate == undefined) {
        underContractDate = this.leadData?.underContractDate;
      } else {
        underContractDate = new Date(Number(this.leadData?.underContractDate));
      }

      if (this.leadData?.dateOfYourOffer == undefined) {
        offerCreatedDate = this.leadData?.dateOfYourOffer;
      } else {
        offerCreatedDate = new Date(Number(this.leadData?.dateOfYourOffer));
      }
      let data = JSON.parse(JSON.stringify(this.leadData));
      if (checkResponse?.isTask) {
        let headerCaption =
          this.leadData?.taskCount &&
          this.leadData?.dripId &&
          !taskStatusExclude.includes(toStatus)
            ? 'Pending Tasks & Drip Campaign'
            : this.leadData?.dripId &&
              !this.leadData?.taskCount &&
              !taskStatusExclude.includes(toStatus)
            ? 'Drip Campaign'
            : 'Pending Tasks';

        this.dialogRef = this.dialog.open(TaskDripStatusChangeComponent, {
          width: '450px',
          data: {
            header: headerCaption,
            type: type,
            lead: this.leadData,
            taskCount: this.leadData?.taskCount,
          },
        });
        this.dialogRef.afterClosed().subscribe((resultTaskDrip) => {
          if (resultTaskDrip) {
            this.callCheckForTaskAndPopup(
              this.leadData,
              type,
              toStatus,
              fromStatus,
              offerCreatedDate,
              underContractDate,
              checkResponse,
              resultTaskDrip
            );
          }
        });
      } else {
        //change status without task
        this.callCheckForTaskAndPopup(
          this.leadData,
          type,
          toStatus,
          fromStatus,
          offerCreatedDate,
          underContractDate,
          checkResponse
        );
      }
    }
  }

  callCheckForTaskAndPopup(
    data,
    type,
    toStatus,
    fromStatus,
    offerCreatedDate,
    underContractDate,
    checkResponse,
    resultTaskDrip?
  ) {
    if (
      checkResponse?.showPopup &&
      checkResponse?.data &&
      !StatusConstant.LeadExcludeStatus.includes(toStatus)
    ) {
      //change status with popup and with task
      this.openModal(
        data,
        type,
        toStatus,
        fromStatus,
        new Date(Number(data?.leadCreated)),
        offerCreatedDate,
        underContractDate,
        checkResponse?.data,
        checkResponse?.fromKey,
        resultTaskDrip
      );
    } else {
      //change status without popup but with task
      if (this.type === 'wholesale') {
        let obj = {
          wholesalePipelineId: type?._id,
          leadId: this.leadId,
          taskTypeAssignment: resultTaskDrip?.taskTypeAssignment || 'NONE',
          ...checkResponse?.data,
        };

        this.changeStatus(obj);
      } else {
        let result = {};
        if (toStatus == StatusConstant.InventoryStatus.INVENTORY) {
          result['dripId'] = data?.dripId;
        } else {
          result['dripId'] = resultTaskDrip?.dripStop ? data?.dripId : '';
        }
        delete data['header'];
        result['taskTypeAssignment'] =
          resultTaskDrip?.taskTypeAssignment || 'NONE';
        this.changeStatus(data, { mainStatusId: type?._id, ...result });
      }
    }
  }
  // =============== Change Lead Status ================

  changeStatus(lead, data?) {
    let obj: LeadsStatusChangeModel = {
      ...JSON.parse(JSON.stringify(data)),
    };
    if (!obj['dripId']) {
      delete obj['dripId'];
    }
    let isInventory = data?.inventory
      ? Object.keys(data?.inventory)?.length
      : '';
    delete obj['inventory'];

    const leadData = {
      leadId: lead?._id,
      dateOfYourOffer: obj.dateOfYourOffer,
      offerBy: 'our_offer',
      yourOfferPrice: obj.yourOfferPrice,
      offerType: obj.offerType,
    };
    delete obj['offerType'];

    this._loaderService.start();
    this._sharedService.changeLeadStatus(lead?._id, obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          const status = this.projectStatus.filter(
            (x) => x._id === response.data.mainStatusId
          )[0];
          this.getLeadDetails(lead?._id);
          this.data.projectStatusName = status?.title;
          this.mainStatusId = status?._id;
          if (this.leadIds) {
            this.leadIds = this.leadIds?.filter((x) => x != lead?._id);
            this.currentLeadIndex = this.currentLeadIndex - 1;
          }
          if (isInventory) {
            this.addInventory(lead?._id, lead, data?.inventory, data);
            this.leadDialerDailog.close();
          } else {
            if (obj.yourOfferPrice) {
              this.saveOffer(leadData);
            } else {
              this._loaderService.stop();
              this.detailsSameLead(lead?._id);
            }
          }

          this._sharedService.refreshActivityLog.next('');
          this._sharedService.refreshListTask.next(true);
          this._sharedService.refreshLeadsGrid.next(true);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  saveOffer(obj) {
    this._leadService.addOffer(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._loaderService.stop();
          this.detailsSameLead(obj.leadId);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }

  openModal(
    data,
    type,
    toStatus,
    fromStatus,
    leadCreatedDate,
    offerCreatedDate,
    underContractDate,
    isVisibleData,
    fromKey,
    resultTaskDrip?
  ) {
    let component;

    if (this.type !== 'wholesale') {
      component = LeadsStatusChangeComponent;
    } else if (this.type === 'wholesale') {
      component = WholesalePipelineStatusChangeComponent;
    }
    let leadData1, leadData2;
    this.dialogRef = this.dialog.open(component, {
      width: '600px',
      data: {
        header: type?.title,
        status: { to: toStatus, from: fromStatus, toId: type?._id },
        moduleId: this.moduleId,
        subModuleId: data?._id,
        page: this.currentPage,
        limit: this.currentLimit,
        leadCreatedDate,
        offerCreatedDate,
        underContractDate,
        item: data,
        mainStatus: this.projectStatus,
        isVisibleData: isVisibleData,
        fromKey: fromKey,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (this.type === 'wholesale') {
          leadData1 = {
            wholesalePipelineId: type?._id,
            leadId: this.leadId,
            ...isVisibleData,
          };

          let resultObj = {
            marketingToBuyersStartDate: result?.marketingToBuyersStartDate,
            askForProperty: result?.askForProperty,
            buyerShowingSchedule: result?.buyerShowingSchedule,
            firstOfferDate: result?.firstOfferDate,
            lowestOffer: result?.lowestOffer,
            highestOffer: result?.highestOffer,
            acceptOfferDate: result?.acceptOfferDate,
            buyingOfferPrice: result?.buyingOfferPrice,
            contingencyArray: result?.contingencyArray,
            contingencyStatus: result?.contingencyStatus,
            clearToCloseDate: result?.clearToCloseDate,
            contingencyType: result?.contingencyType,
          };
          for (var key in resultObj) {
            if (resultObj.hasOwnProperty(key)) {
              if (typeof resultObj[key] !== 'undefined')
                leadData1[key] = resultObj[key];
            }
          }

          if (toStatus == StatusConstant.InventoryStatus.SOLD) {
            leadData2 = {
              mainStatusId: type?._id,
              closingDate: result?.closingDate,
              assignmentFee: result?.assignmentFee,
              inventory: result?.inventory,
            };
          }
          if (resultTaskDrip) {
            leadData1['taskTypeAssignment'] =
              resultTaskDrip?.taskTypeAssignment;
          }
        } else {
          if (resultTaskDrip) {
            if (toStatus == StatusConstant.LeadStatus.INVENTORY) {
              result['dripId'] = data?.dripId;
            } else {
              result['dripId'] = resultTaskDrip?.dripStop ? data?.dripId : '';
            }
            delete data['header'];
            result['taskTypeAssignment'] = resultTaskDrip.taskTypeAssignment;
          } else {
            delete data['header'];
            data['taskTypeAssignment'] = 'NONE';
            if (
              toStatus == StatusConstant.LeadStatus.INVENTORY ||
              toStatus == StatusConstant.InventoryStatus.SOLD
            ) {
              result['dripId'] = data?.dripId;
            }
          }
          leadData1 = data;
          leadData2 = { mainStatusId: type?._id, ...result };
        }
        this.changeStatus(leadData1, leadData2);
      }
    });
  }

  addInventory(leadId, lead, inventory, data?) {
    const obj = {
      address: lead?.address,
      unitNo: lead?.unitNo,
      addedFrom: 'CRM',
      leadId,
      latitude: lead?.latitude,
      longitude: lead?.longitude,
      isFromLead: true,
      dripId: data.dripId,
      taskTypeAssignment: data.taskTypeAssignment || 'NONE',
      ...inventory,
    };

    this._inventoryService.addInventory(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          this._loaderService.stop();

          if (
            response.data.mainStatusId == StatusConstant.InventoryStatus.SOLD
          ) {
            this._route.navigate([`/sold/details`], {
              queryParams: { soldId: response.data._id },
            });
          } else {
            this._route.navigate([`/inventory/details`], {
              queryParams: { inventoryId: response.data._id },
            });
          }
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  mute() {
    this.muteCall = true;
    if (this.isTwilioCall && this.twilioCallSid) {
      this._twilioService.callMuteUnmute(true);
    }
    if (
      this.plivoBrowserSdk.client.callUUID &&
      !this.mergeCallDialer.callNotJoined
    ) {
      this.plivoBrowserSdk.client.mute();
    } else {
      this.allHoldUnholdMember({
        hold: this.holdCall,
        mute: true,
      });
    }
  }

  unmute() {
    this.muteCall = false;
    if (this.isTwilioCall && this.twilioCallSid) {
      this._twilioService.callMuteUnmute(false);
    }

    if (
      this.plivoBrowserSdk.client.callUUID &&
      !this.mergeCallDialer.callNotJoined
    ) {
      this.plivoBrowserSdk.client.unmute();
    } else {
      this.allHoldUnholdMember({
        hold: this.holdCall,
        mute: false,
      });
    }
  }

  dialPad() {
    this.isDialPad = true;
    this.isCurrentCall = false;
    this.makingCall = false;
    this._utilities.makingCallDialer = false;
  }

  closeDialPad() {
    this.isDialPad = false;
    this.isCurrentCall = true;
    this.makingCall = true;
    this._utilities.makingCallDialer = true;
  }

  dialer(key) {
    if (!key) {
      return;
    }
    this.dialedNumber += key;

    if (this.isTwilioCall) {
      this._twilioService.sendDigits(key);
    } else {
      this.plivoBrowserSdk.client.sendDtmf(key);
    }
  }

  removeDialer() {
    if (this.dialedNumber) {
      this.dialedNumber = this.dialedNumber.slice(0, -1);
    }
  }

  hold() {
    this.holdCall = true;

    if (this.isTwilioCall && this.twilioCallSid) {
      if (this.twilioCallSid && !this.mergeCallDialer.callNotJoined) {
        this._twilioService
          ?.holdCallFn({ callSid: this.twilioCallSid })
          .subscribe(
            (response) => {
              this.holdCall = true;
              if (response.statusCode == 200) {
              }
            },
            (err: ErrorModel) => {
              if (err.error) {
                const error: ResponseModel = err.error;
                this._toastrService.error(error.message, '');
              } else {
                this._toastrService.error(
                  this.messageConstant.unknownError,
                  ''
                );
              }
            }
          );
      } else {
        this.allHoldUnholdMember({
          hold: true,
          mute: this.muteCall,
        });
      }
    } else {
      if (
        this.plivoBrowserSdk.client.callUUID &&
        !this.mergeCallDialer.callNotJoined
      ) {
        let obj = { uuid: this.plivoBrowserSdk.client.callUUID };
        this._sharedService.holdCallFn(obj).subscribe(
          (response) => {
            if (response.statusCode == 200) {
              this.holdCall = true;
            }
          },
          (err: ErrorModel) => {
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
      } else {
        this.allHoldUnholdMember({
          hold: true,
          mute: this.muteCall,
        });
      }
    }
  }

  unHold(disConnect?) {
    this.holdCall = false;
    if (this.isTwilioCall && this.twilioCallSid) {
      if (this.twilioCallSid && !this.mergeCallDialer.callNotJoined) {
        this._twilioService
          ?.unHoldCallFn({ callSid: this.twilioCallSid })
          .subscribe(
            (response: any) => {
              this.holdCall = false;
              if (disConnect) {
                setTimeout(() => {
                  this.cancel();
                }, 2000);
              }
            },
            (err: ErrorModel) => {
              if (err.error) {
                const error: ResponseModel = err.error;
                this._toastrService.error(error.message, '');
              } else {
                this._toastrService.error(
                  this.messageConstant.unknownError,
                  ''
                );
              }
            }
          );
      } else {
        this.allHoldUnholdMember({
          hold: false,
          mute: this.muteCall,
        });
      }
      this.holdCall = false;
    } else {
      if (
        this.plivoBrowserSdk.client.callUUID &&
        !this.mergeCallDialer.callNotJoined
      ) {
        let obj = { uuid: this.plivoBrowserSdk.client.callUUID };
        this._sharedService.unHoldCallFn(obj).subscribe(
          (response) => {
            if (response.statusCode == 200) {
              this.holdCall = false;
            }
          },
          (err: ErrorModel) => {
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
      } else {
        this.allHoldUnholdMember({
          hold: false,
          mute: this.muteCall,
        });
      }
    }
  }

  pipeline(first) {
    const nowDate = new Date().getTime();
    const createdDate = new Date(first).getTime();
    const differenceInTime = nowDate - createdDate;
    return Math.round(differenceInTime / (1000 * 3600 * 24));
  }

  getMarket() {
    const index = this.marketList?.findIndex(
      (x) => x._id === this.leadData?.marketId
    );
    if (index > -1) {
      return this.marketList[index].title;
    }

    return 'Primary Market';
  }

  checkGarage() {
    return !!(
      this.leadData?.garage && Object.keys(this.leadData?.garage)?.length
    );
  }

  getDate(data, format) {
    if (!data) {
      return 'N/A';
    }

    return moment(data).format(format);
  }

  async onAppointmentDialog(data?) {
    if (!this.currentUser?.userId) {
      await this.getCurrentUser();
    }

    this.dialogRef = this.dialog.open(AddEditAppointmentsComponent, {
      width: '670px',
      data: {
        moduleId: this.moduleId,
        subModuleId: this.leadId,
        address: this.leadData?.address,
        currentUser: this.currentUser,
        secondaryContacts: this.leadData?.secondaryContact,
        details: data,
        leadInfo: this.leadData,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._sharedService.refreshInfo.next(true);
        this._sharedService.refreshAppointments.next(true);
      }
    });
  }

  getCurrentUser = () => {
    return new Promise(async (resolve, reject) => {
      this._sharedService.getUser().subscribe(
        (response: ResponseModel) => {
          this.currentUser = response.data;
          resolve(true);
        },
        (err) => {
          console.log(err);
          this._toastrService.error(
            'Something went wrong. Please try again later.',
            ''
          );
          resolve(false);
        }
      );
    });
  };

  async onTaskDialog(event?) {
    if (!this.userListing) {
      await this.getUserRoleList();
    }
    if (!this.currentUser?.userId) {
      await this.getCurrentUser();
    }
    this.dialogRef = this.dialog.open(AddEditTaskComponent, {
      width: '600px',
      data: {
        type: 'task',
        subType: 'task',
        category: 'Add',
        details: event,
        id: event,
        userList: this.userList,
        currentUser: this.currentUser,
        leadInfo: this.leadData,
        isLocalTask: true,
        isGetTeams: false,
        isGeneralTask: false,
      },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._loaderService.start();
        result.title = result.title.trim();
        result.taskDetail = result.taskDetail.trim();
        result.taskType = 'LEAD';
        if (!result.isGeneralTask) {
          result.subModuleId = this.leadId;
          result.moduleId = this.moduleId;
          result.mainStatusId = this.leadData?.mainStatusId;
        } else {
          result.moduleId = StatusConstant.GeneralModule.GENERAL_MODULE_ID;
          result.mainStatusId = result.mainStatusId;
          result.subModuleId =
            StatusConstant.GeneralModule.GENERAL_SUB_MODULE_ID;
          result.roleId = null;
        }
        this._taskService.createTask(result).subscribe(
          (response: ResponseModel) => {
            if (response.statusCode == 200) {
              // this._toastrService.success(
              //   this.messageConstant.taskAddedSuccess,
              //   ''
              // );
              this.listTask(true);
              this._loaderService.stop();
              this._sharedService.refreshInfo.next(true);
              this._sharedService.refreshActivityLog.next('');
              this._sharedService.refreshAppointments.next(true);
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
      }
    });
  }

  getUserRoleList() {
    return new Promise(async (resolve, reject) => {
      this._masterTaskService
        .getUserRoleList({ page: 1, limit: 100 }, '', true)
        .subscribe(
          (response: ResponseModel) => {
            this.userListing = response.data.items;
            this.userList = [];
            this.userListing = response?.data;
            for (let i = 0; i < response?.data?.items.length; i++) {
              const user = response?.data?.items[i];
              const fullName = user.firstName + ' ' + user.lastName;
              const matches = fullName.match(/\b(\w)/g);
              const acronym = matches?.join('');
              this.userList.push({
                label: user.firstName + ' ' + user.lastName,
                value: user._id,
                profileImage: user?.profileImage ? user?.profileImage : '',
                name: acronym || 'N/A',
              });
            }
          },
          (err: ErrorModel) => {
            this._loaderService.stop();
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
    });
  }

  onContactsDialog() {
    this.sendSmsBtn = true;
    if (!this.leadData?.number && !this.leadData?.defaultCallNumber) {
      this._toastrService.info(this.messageConstant.noCampaignNumber, '', {
        enableHtml: true,
      });
      return;
    }
    let lead = JSON.parse(JSON.stringify(this.leadData));
    lead.number = this.leadData?.defaultCallNumber
      ? this.leadData?.defaultCallNumber
      : this.leadData?.number;
    if (lead?.buyersLeads) {
      for (var i = 0; i < lead?.buyersLeads?.length; i++) {
        lead.buyers.push(lead?.buyersLeads[i]);
      }
    }

    if (lead?.secondaryContact) {
      if (lead?.email || lead?.title || lead?.phoneNumber) {
        const obj = {
          email: lead?.email,
          name: lead?.title,
          phoneNumber: lead?.phoneNumber,
          contactType: 'seller',
          isOwnerContact: true,
        };
        lead.secondaryContact.unshift(obj);
      }
    }

    let leadId = this.leadData._id;
    let objTeams = {
      leadId: leadId,
    };
    this._leadService.getTeams(objTeams).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode && response.statusCode == 200) {
          let teams = response.data.items;
          this.dialogRef = this.dialog.open(ContactsDialogComponent, {
            width: '300px',
            data: {
              contacts: lead,
              type: 'SMS',
              header: 'Send Message',
              leadId: leadId,
              moduleId: this.moduleId,
              subModuleId: this.leadData?._id,
              numberId: this.leadData?.defaultNumberId
                ? this.leadData?.defaultNumberId
                : this.leadData?.numberId,
              teams: teams,
              sendContact: this.contactData[this.currentContact - 1],
            },
          });
          this.sendSmsBtn = false;
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        this.dialogRef = this.dialog.open(ContactsDialogComponent, {
          width: '300px',
          data: {
            contacts: lead,
            type: 'SMS',
            header: 'Send Message',
            leadId: leadId,
            moduleId: this.moduleId,
            subModuleId: this.leadData?._id,
            sendContact: this.contactData[this.currentContact - 1],
          },
        });
        this.sendSmsBtn = false;
      }
    );
    this.dialogRef.afterClosed().subscribe((result) => {});
  }

  editLead() {
    this.toggleDisplay();
    let route = `/leads/edit`;
    this._route.navigate([route, this.leadId]);
    // this.leadDialerDailog.close({
    //   isEdit: true,
    //   leadId: this.leadId,
    // });
  }

  getFile(log, index) {
    let param = {
      moduleId: '601cf3c070419f19b74a6882',
      src: log['profileImage'],
    };

    this._sharedService.getFile(param).subscribe(
      (response) => {
        if (response) {
          let objectURL = URL.createObjectURL(response);
          this.userImage[index] =
            this._sanitizer.bypassSecurityTrustUrl(objectURL);
        }
      },
      (err: ErrorModel) => {}
    );
  }

  toggleVisibility(logIndex) {
    const index = this.isVisible.indexOf(logIndex);
    if (index > -1) {
      this.isVisible.splice(index, 1);
    } else {
      this.isVisible.push(logIndex);
    }
  }

  async nextLead() {
    if (this.isNextEnable == false) {
      return;
    }
    this.currentContactDis = 0;
    this.callNextLead = true;
    this.currentLeadIndex += 1;
    if (this.currentLeadIndex < 0) {
      this.currentLeadIndex = 0;
    }
    if (!this.isLeadListResponse) {
      await this.getLeadListId(this.currentMainStatusId);
    }
    if (this.leadIds.length > this.currentLeadIndex) {
      this.updateNextLead(this.leadIds[this.currentLeadIndex]);
      this.getQuestions(this.leadIds[this.currentLeadIndex]);
      if (this.currentLeadIndex > 0) {
        this.isPreviousEnable = true;
      }
    } else {
      if (
        this.currentMainStatusId == StatusConstant.LeadStatus.NEW_LEAD &&
        (this.leadIds.length == 0 ||
          this.leadIds.length <= this.currentLeadIndex)
      ) {
        this.leadIds = [];
        this.currentMainStatusId = StatusConstant.LeadStatus.NO_CONTACT_MADE;
        await this.getLeadListId(this.currentMainStatusId);
      }
      if (
        this.currentMainStatusId == StatusConstant.LeadStatus.NO_CONTACT_MADE &&
        (this.leadIds.length == 0 ||
          this.leadIds.length <= this.currentLeadIndex)
      ) {
        this.leadIds = [];
        this.currentMainStatusId = StatusConstant.LeadStatus.CONTACT_MADE;
        await this.getLeadListId(this.currentMainStatusId);
      }
      if (
        this.currentMainStatusId == StatusConstant.LeadStatus.CONTACT_MADE &&
        (this.leadIds.length == 0 ||
          this.leadIds.length <= this.currentLeadIndex)
      ) {
        this.leadIds = [];
        this.currentMainStatusId = StatusConstant.LeadStatus.APPOINTMENT_SET;
        await this.getLeadListId(this.currentMainStatusId);
      }
      if (
        this.currentMainStatusId == StatusConstant.LeadStatus.APPOINTMENT_SET &&
        (this.leadIds.length == 0 ||
          this.leadIds.length <= this.currentLeadIndex)
      ) {
        this.leadIds = [];
        this.currentMainStatusId = StatusConstant.LeadStatus.DUE_DILIGENCE;
        await this.getLeadListId(this.currentMainStatusId);
      }
      if (
        this.currentMainStatusId == StatusConstant.LeadStatus.DUE_DILIGENCE &&
        (this.leadIds.length == 0 ||
          this.leadIds.length <= this.currentLeadIndex)
      ) {
        this.leadIds = [];
        this.currentMainStatusId = StatusConstant.LeadStatus.OFFER_MADE;
        await this.getLeadListId(this.currentMainStatusId);
      }
      if (
        this.currentMainStatusId == StatusConstant.LeadStatus.OFFER_MADE &&
        (this.leadIds.length == 0 ||
          this.leadIds.length <= this.currentLeadIndex)
      ) {
        this.leadIds = [];
        this.currentMainStatusId = StatusConstant.LeadStatus.UNDER_CONTRACT;
        await this.getLeadListId(this.currentMainStatusId);
      }
      if (
        this.currentMainStatusId == StatusConstant.LeadStatus.UNDER_CONTRACT &&
        (this.leadIds.length == 0 ||
          this.leadIds.length <= this.currentLeadIndex)
      ) {
        this.leadIds = [];
        this.currentMainStatusId = StatusConstant.LeadStatus.ASSIGN_TO_BUYER;
        await this.getLeadListId(this.currentMainStatusId);
      }
      if (
        this.currentMainStatusId == StatusConstant.LeadStatus.ASSIGN_TO_BUYER &&
        (this.leadIds.length == 0 ||
          this.leadIds.length <= this.currentLeadIndex)
      ) {
        this.leadIds = [];
        this.currentMainStatusId = StatusConstant.LeadStatus.WARM_LEAD;
        await this.getLeadListId(this.currentMainStatusId);
      }
      if (
        this.currentMainStatusId == StatusConstant.LeadStatus.WARM_LEAD &&
        (this.leadIds.length == 0 ||
          this.leadIds.length <= this.currentLeadIndex)
      ) {
        this.leadIds = [];
        this.currentMainStatusId = StatusConstant.LeadStatus.DEAD_LEAD;
        await this.getLeadListId(this.currentMainStatusId);
      }
      if (
        this.currentMainStatusId == StatusConstant.LeadStatus.DEAD_LEAD &&
        (this.leadIds.length == 0 ||
          this.leadIds.length <= this.currentLeadIndex)
      ) {
        this.leadIds = [];
        this.currentMainStatusId = StatusConstant.LeadStatus.REFERRED_TO_AGENT;
        await this.getLeadListId(this.currentMainStatusId);
      }
      if (this.leadIds.length > this.currentLeadIndex) {
        this.isNextEnable = true;
        this.updateNextLead(this.leadIds[this.currentLeadIndex]);
        this.getQuestions(this.leadIds[this.currentLeadIndex]);
      } else {
        this.isNextEnable = false;
        if (
          (this.currentMainStatusId =
            StatusConstant.LeadStatus.REFERRED_TO_AGENT)
        ) {
          this.currentLeadIndex = this.leadIds?.length - 1;
        }
      }
      if (this.currentLeadIndex > 0) {
        this.isPreviousEnable = true;
      }
    }
  }

  async previousLead() {
    if (this.isPreviousEnable == false) {
      return;
    }
    this.currentContactDis = 0;
    this.currentLeadIndex -= 1;
    if (!this.isLeadListResponse) {
      await this.getLeadListId(this.currentMainStatusId, true);
    }
    if (
      this.leadIds.length > this.currentLeadIndex &&
      this.currentLeadIndex >= 0
    ) {
      this.updateNextLead(this.leadIds[this.currentLeadIndex]);
      this.getQuestions(this.leadIds[this.currentLeadIndex]);
      if (this.leadIds.length > this.currentLeadIndex) {
        this.isNextEnable = true;
      }
    } else {
      if (
        this.currentMainStatusId ==
          StatusConstant.LeadStatus.REFERRED_TO_AGENT &&
        (this.leadIds.length == 0 || this.currentLeadIndex < 0)
      ) {
        this.leadIds = [];
        this.currentMainStatusId = StatusConstant.LeadStatus.DEAD_LEAD;
        await this.getLeadListId(this.currentMainStatusId, true, true);
      }
      if (
        this.currentMainStatusId == StatusConstant.LeadStatus.DEAD_LEAD &&
        (this.leadIds.length == 0 || this.currentLeadIndex < 0)
      ) {
        this.leadIds = [];
        this.currentMainStatusId = StatusConstant.LeadStatus.WARM_LEAD;
        await this.getLeadListId(this.currentMainStatusId, true, true);
      }
      if (
        this.currentMainStatusId == StatusConstant.LeadStatus.WARM_LEAD &&
        (this.leadIds.length == 0 || this.currentLeadIndex < 0)
      ) {
        this.leadIds = [];
        this.currentMainStatusId = StatusConstant.LeadStatus.UNDER_CONTRACT;
        await this.getLeadListId(this.currentMainStatusId, true, true);
      }
      // if (
      //   this.currentMainStatusId == StatusConstant.LeadStatus.ASSIGN_TO_BUYER &&
      //   (this.leadIds.length == 0 || this.currentLeadIndex < 0)
      // ) {
      //   this.leadIds = [];
      //   this.currentMainStatusId = StatusConstant.LeadStatus.UNDER_CONTRACT;
      //   await this.getLeadListId(this.currentMainStatusId, true, true);
      // }
      if (
        this.currentMainStatusId == StatusConstant.LeadStatus.UNDER_CONTRACT &&
        (this.leadIds.length == 0 || this.currentLeadIndex < 0)
      ) {
        this.leadIds = [];
        this.currentMainStatusId = StatusConstant.LeadStatus.OFFER_MADE;
        await this.getLeadListId(this.currentMainStatusId, true, true);
      }
      if (
        this.currentMainStatusId == StatusConstant.LeadStatus.OFFER_MADE &&
        (this.leadIds.length == 0 || this.currentLeadIndex < 0)
      ) {
        this.leadIds = [];
        this.currentMainStatusId = StatusConstant.LeadStatus.DUE_DILIGENCE;
        await this.getLeadListId(this.currentMainStatusId, true, true);
      }
      if (
        this.currentMainStatusId == StatusConstant.LeadStatus.DUE_DILIGENCE &&
        (this.leadIds.length == 0 || this.currentLeadIndex < 0)
      ) {
        this.leadIds = [];
        this.currentMainStatusId = StatusConstant.LeadStatus.APPOINTMENT_SET;
        await this.getLeadListId(this.currentMainStatusId, true, true);
      }
      if (
        this.currentMainStatusId == StatusConstant.LeadStatus.APPOINTMENT_SET &&
        (this.leadIds.length == 0 || this.currentLeadIndex < 0)
      ) {
        this.leadIds = [];
        this.currentMainStatusId = StatusConstant.LeadStatus.CONTACT_MADE;
        await this.getLeadListId(this.currentMainStatusId, true, true);
      }
      if (
        this.currentMainStatusId == StatusConstant.LeadStatus.CONTACT_MADE &&
        (this.leadIds.length == 0 || this.currentLeadIndex < 0)
      ) {
        this.leadIds = [];
        this.currentMainStatusId = StatusConstant.LeadStatus.NO_CONTACT_MADE;
        await this.getLeadListId(this.currentMainStatusId, true, true);
      }
      if (
        this.currentMainStatusId == StatusConstant.LeadStatus.NO_CONTACT_MADE &&
        (this.leadIds.length == 0 || this.currentLeadIndex < 0)
      ) {
        this.leadIds = [];
        this.currentMainStatusId = StatusConstant.LeadStatus.NEW_LEAD;
        await this.getLeadListId(this.currentMainStatusId, true, true);
      }
      if (
        this.leadIds.length > this.currentLeadIndex &&
        this.currentLeadIndex >= 0
      ) {
        this.isPreviousEnable = true;
        this.updateNextLead(this.leadIds[this.currentLeadIndex]);
        this.getQuestions(this.leadIds[this.currentLeadIndex]);
      } else {
        this.isPreviousEnable = false;
      }
      if (this.leadIds.length > this.currentLeadIndex) {
        this.isNextEnable = true;
      }
    }
    if (this.currentLeadIndex < 0) {
      this.currentLeadIndex = 0;
    }
  }

  getLeadListId(mainStatusId, isPrevious?, isStatusChange?) {
    return new Promise(async (resolve, reject) => {
      let obj = {
        holdingSort: this._utilities.dialerLeadSort,
        mainStatusId: [mainStatusId],
        currentLeadId: this.leadId,
      };
      if (this._utilities.isFilterLeads) {
        obj = { ...obj, ...this._utilities.leadFilter };
      }

      this._loaderService.start();
      this._leadService.getListLeadId(obj).subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();

          if (response?.statusCode && response?.statusCode == 200) {
            this.currentLeadIndex = 0;
            this.leadIds = response.data;
            if (isPrevious) {
              if (this.leadIds) {
                if (isStatusChange) {
                  let index = this.leadIds.findIndex((x) => x === this.leadId);
                  if (index > 0) {
                    this.currentLeadIndex = index;
                  } else {
                    this.currentLeadIndex = this.leadIds.length - 1;
                  }
                } else {
                  let index = this.leadIds.findIndex((x) => x === this.leadId);
                  this.currentLeadIndex = index;
                }
              }
            } else {
              if (this.leadIds) {
                let index = this.leadIds.findIndex((x) => x === this.leadId);
                if (index > 0) {
                  this.currentLeadIndex = index;
                } else {
                  this.currentLeadIndex = 0;
                }
              }
            }
            this.isLeadListResponse = true;
          }
          resolve(true);
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
          resolve(true);
        }
      );
    });
  }

  async updateNextLead(leadId) {
    this.leadId = leadId;
    await this.getLeadDetails(leadId);
    this.detailsNextLead(leadId);
  }

  getDefaultCampaignList() {
    const obj = {
      crmQueId: [],
      numTypeId: 1,
    };

    this._leadService.getCampaignList(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.defaultNumberList = this._utilities.sortItems(
            response?.data?.items,
            'marketingTitle'
          );
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
      }
    );
  }

  otherReason(status) {
    this.callConnectReasonText = false;
    this.callNotConnectReasonText = false;
    this.callConnectReason = status == 5 ? true : false;
    this.callNotConnectReason = status == 9 ? true : false;
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.callAnswerForm.controls[controlName].hasError(errorName);
  };
  public hasErrorSms = (controlName: string, errorName: string) => {
    return this.smsDetailForm.controls[controlName].hasError(errorName);
  };
  oncallAnswerSubmit(type?) {
    if (this._utilities.isCallStatusLead) {
      return;
    }
    this._utilities.isCallStatusLead = true;
    this.submitted = true;
    this.joinToMpcHeader = false;
    if (this.counter) {
      clearInterval(this.counter);
    }
    if (this.callAnswerForm.invalid) {
      this._toastrService.error('Please select any one option');
      return;
    }
    this.callConnected = false;
    this.statusCheckFlag = true;
    const { callAnswer, otherReason } = this.callAnswerForm.value;
    // if (callAnswer == 5 && otherReason == '') {
    //   this.callConnectReasonText = true;
    //   return;
    // }
    // if (callAnswer == 9 && otherReason == '') {
    //   this.callNotConnectReasonText = true;
    //   return;
    // }
    let phone = this.contactData[this.currentContact - 1]?.phoneNumber
      ? this.contactData[this.currentContact - 1]?.phoneNumber
      : this.contactData[this.currentContact - 1]?.contactNumber;
    let number = this.leadData?.defaultCallNumber
      ? this.leadData?.defaultCallNumber
      : this.leadData?.number;
    this._loaderService.start();
    let myObj = {
      leadId: this.leadData?._id,
      statusId: callAnswer,
      isConnected:
        callAnswer == 1 ||
        callAnswer == 2 ||
        callAnswer == 3 ||
        callAnswer == 4 ||
        callAnswer == 5 ||
        callAnswer == 10
          ? true
          : false,
      otherReason: otherReason,
      callUuid: this.callUUID,
      toNumber: phone,
      fromNumber: number,
      moduleId: this.moduleId,
    };
    this._leadService.callAnswer(myObj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.saveActivity();
          this.callUUID = '';
          this.callEnd = false;
          this.callNextLead = false;
          this._loaderService.stop();
          if (type == 'Redial') {
            this.makeCall();
          } else if (type == 'Next') {
            this.nextLead();
            setTimeout(() => {
              this.makeCall();
            }, 2500);
          }
        }
        this._sharedService.refreshActivityLog.next(true);
        this.callAnswerForm.patchValue({
          callAnswer: '',
          otherReason: '',
        });
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  detailsPreviousLead() {
    if (this._utilities.currentLeadCount <= 0) {
      return;
    }
    this._utilities.currentLeadCount -= 1;

    let leadId = this._utilities.leadIdArray[this._utilities.currentLeadCount];
    this.moveToModule(leadId);
  }

  detailsNextLead(leadId) {
    // if (
    //   this._utilities.currentLeadCount >=
    //   this._utilities.leadIdArray.length - 1
    // ) {
    //   return;
    // }
    // this._utilities.currentLeadCount += 1;
    // let leadId = this._utilities.leadIdArray[this._utilities.currentLeadCount];
    this._utilities.leadIdArray = [];
    this.moveToModule(leadId);
  }

  detailsSameLead(leadId) {
    this._utilities.leadIdArray = [];
    this.moveToModule(leadId, true);
  }

  moveToModule(id, isSame?) {
    let type = 'leadsId';
    let url = 'leads';
    if (isSame) {
      if (localStorage?.dialerFlag == 'true') {
        this._route.navigate([`/${url}/details`], {
          queryParams: { [type]: id, refresh: 'false' },
        });
        localStorage.setItem('dialerFlag', 'false');
      } else {
        this._route.navigate([`/${url}/details`], {
          queryParams: { [type]: id, refresh: 'true' },
        });
        localStorage.setItem('dialerFlag', 'true');
      }
    } else {
      this._route.navigate([`/${url}/details`], {
        queryParams: { [type]: id },
      });
    }
  }

  getMarkets() {
    const obj = {
      page: 1,
      limit: 1000,
    };
    this._marketServices.getMarkets(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.marketList = response.data.items;
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getMainStatus() {
    const obj = {
      page: 1,
      limit: 1000,
      moduleId: this.moduleId,
    };
    this._sharedService.getMainStatus(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.projectStatus = response.data['items'];
          this._utilities.checkForStatusNameChange(
            this.projectStatus,
            this.type
          );
          let statusObj = {
            _id: '5feb4221aa810a3849fa551b',
            title: 'sold',
          };
          this.projectStatus.push(statusObj);
          this.mainProjectStatus = response.data['items'];
        }
        this.getLeadDetails(this.data?.leadId, true);
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  viewProperty() {
    const obj = {
      lead_property_id: this.leadId,
    };
    this._leadService.viewProperty(obj).subscribe(
      (response: ResponseModel) => {
        if (response.code == 200) {
          this.viewPropertyList = response;
        }
      },
      (err: ErrorModel) => {}
    );
  }

  getQuestions(leadId) {
    const obj = { leadId: leadId };
    this._leadService.getQuestions(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.preferenceData = response?.data;
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  showActivity() {
    this.dialogRef = this.dialog.open(ActivityDialogComponent, {
      width: '500px',
      disableClose: true,
      data: {
        moduleId: this.moduleId,
        leadId: this.leadId,
        address: this.leadData?.address,
        unitNo: this.leadData?.unitNo,
        phoneNumber: this.leadData?.phoneNumber,
        mainStatus: this.leadData?.mainStatusTitle,
        title: this.leadData?.title,
        leadData: this.leadData,
      },
    });
  }

  getActionName(action) {
    switch (action) {
      case 1:
        return 'Direct Mail';
      case 2:
        return 'SMS';
      case 3:
        return 'Task';
      case 4:
        return 'RVM';
      case 5:
        return 'Email';
      case 6:
        return 'Webhook Trigger';
    }
  }

  getHumanReadableTime(activeTime, timezone) {
    let currentTime = moment().tz(timezone);
    let existsTime = moment(activeTime).tz(timezone);
    let time = currentTime.diff(existsTime, 'days');
    if (time <= 30) {
      let minutesTime = currentTime.diff(existsTime, 'minutes');
      return this._utilities.calculateMinutes(minutesTime);
    } else {
      return this._utilities.calculateTimimg(time);
    }
  }

  viewDripDetails(data) {
    let result = {};
    for (const [key, value] of Object.entries(data)) {
      switch (key) {
        case '1':
          result['directMail'] = JSON.parse(JSON.stringify(value)).length;
          break;
        case '2':
          result['sms'] = JSON.parse(JSON.stringify(value)).length;
          break;
        case '3':
          result['task'] = JSON.parse(JSON.stringify(value)).length;
          break;
        case '4':
          result['rvm'] = JSON.parse(JSON.stringify(value)).length;
          break;
        case '5':
          result['email'] = JSON.parse(JSON.stringify(value)).length;
          break;
        case '6':
          result['webhook'] = JSON.parse(JSON.stringify(value)).length;
          break;
      }
    }
    return result;
  }

  async omSmsDialog(contact?, type?, numbers?) {
    if (!type) {
      if (
        typeof this.leadData.number == 'undefined' ||
        this.leadData.number == '' ||
        this.leadData.number == null
      ) {
        this._toastrService.info(this.messageConstant.noCampaignNumber, '', {
          enableHtml: true,
        });
        return;
      }
    }
    if (
      !this.contactData[this.currentContact - 1]?.phoneNumber &&
      this.contactData[this.currentContact - 1]?.contactType != 'Buyer' &&
      this.contactData[this.currentContact - 1]?.contactType != 'Agent'
    ) {
      this._toastrService.error(this.messageConstant.noBuyerNumber, '');
      return false;
    } else if (
      (this.contactData[this.currentContact - 1]?.contactType == 'Buyer' ||
        this.contactData[this.currentContact - 1]?.contactType == 'Agent') &&
      !this.contactData[this.currentContact - 1]?.contactNumber
    ) {
      this._toastrService.error(this.messageConstant.noBuyerNumber, '');
      return false;
    }
    if (!this.isLeadListResponse) {
      await this.getLeadListId(this.leadData?.mainStatusId);
    }
    this._loaderService.start();
    this._creditService.getSMSCredit({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          let SMSBalance = response?.data;
          let obj = {
            ...this.contactData[this.currentContact - 1],
            leadSource: this.leadData?.defaultCallNumber
              ? this.leadData?.defaultCallNumber
              : this.leadData?.number,
            address: this.leadData?.address,
            contacts: this.leadData?.secondaryContact,
            leadId: this.leadData?._id,
            moduleId: this.moduleId,
            // fromBuyerPage: this.moduleId == "5faa63fdfd20cd581703d255" ? false : true,
            SMSBalance,
            leadIds: this.leadIds,
          };

          if (
            this.contactData[this.currentContact - 1]?.contactType == 'Buyer' ||
            this.contactData[this.currentContact - 1]?.contactType == 'Agent'
          ) {
            if (!this.buyerNumber?.length) {
              this._toastrService.info(this.messageConstant.noNumber, '', {
                enableHtml: true,
              });
              this._loaderService.stop();
              return;
            }
            let buyerName = '';
            if (this.contactData[this.currentContact - 1]?.firstName) {
              buyerName = this.contactData[this.currentContact - 1]?.firstName;
            }
            if (this.contactData[this.currentContact - 1]?.lastName) {
              buyerName =
                buyerName +
                ' ' +
                this.contactData[this.currentContact - 1]?.lastName;
            }
            obj = {
              phoneNumber:
                this.contactData[this.currentContact - 1]?.contactNumber,
              leadSource: this.buyerNumber,
              buyerId: this.contactData[this.currentContact - 1]?._id,
              moduleId: this.moduleId,
              leadId: this.leadData?._id,
              contacts: [
                {
                  name: buyerName,
                  contactType:
                    this.contactData[this.currentContact - 1]?.contactType,
                  phoneNumber:
                    this.contactData[this.currentContact - 1]?.contactNumber,
                },
              ],
              moduleType: 'Buyer',
              SMSBalance,
              address: this.leadData?.address,
              leadIds: this.leadIds,
            };
          }
          obj.teams = this.leadsTeams;
          if (this.leadData?.numberId || this.leadData?.defaultNumberId) {
            obj.numberId = this.leadData?.defaultNumberId
              ? this.leadData?.defaultNumberId
              : this.leadData?.numberId;
          }
          obj.allLeadHide = true;
          obj.mainStatusId = this.leadData?.mainStatusId;
          this.dialogRef = this.dialog.open(SmsDialogComponent, {
            width: '600px',
            data: obj,
          });
          this.dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              if (!type) {
                this.leadData.defaultCallNumber = result?.leadSourceNumber;
                this.leadData.defaultNumberId = result?.newLeadSourceId;
              }
            }
          });
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  searchByFilter(data) {
    const re = RegExp(
      `.*${data.target.value.toLowerCase().split('').join('.*')}.*`
    );
    this.teams = this.allTeams.filter(
      (v) =>
        v.firstName.toLowerCase().match(re) ||
        v.lastName.toLowerCase().match(re)
    );
    if (this.teams && this.teams.length === 0) {
      this.dialedNumber = '';
    }
  }

  getTeam() {
    let objTeams = {
      leadId: this.leadData._id,
    };
    let param = {
      page: 1,
      limit: 100,
    };
    this._userService.getSubUserList(param).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode && response.statusCode == 200) {
          this.teamsData = response.data.items;
          this.teams = response.data.items;
          this.allTeams = response.data.items;
          this.getSmsTemplates();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
      }
    );
  }
  removeDuplicates(arr) {
    const unique = [];
    arr.forEach((element) => {
      if (!unique.includes(element._id)) {
        unique.push(element);
      }
    });
    return unique;
  }
  getBuyerNumber() {
    const data = {
      page: this.currentPage,
      limit: 1000,
      numTypeId: 2,
    };

    this._loaderService.start();
    this._sharedService.getManageBuyerNumbers(data).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          if (!response.data?.items?.length) {
            this._loaderService.stop();
            return;
          }
          this.buyerNumber = response.data?.items;
          this.buyerCallFrom = this.buyerNumber[0]?.number;
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        this._toastrService.error(
          err.message ? err.message : MessageConstant.unknownError
        );
      }
    );
  }

  getCount(leadId) {
    const obj = { leadId: leadId };
    this._leadService.getCount(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.leadCountData = response?.data;
          this.leadData.activityCount = this.leadCountData?.activityCount || 0;
          this.leadData.appointmentCount =
            this.leadCountData?.appointmentCount || 0;
          this.leadData.esignCount = this.leadCountData?.esignCount || 0;
          this.leadData.fileCount = this.leadCountData?.fileCount || 0;
          this.leadData.linkCount = this.leadCountData?.linkCount || 0;
          this.leadData.relatedLeadCount =
            this.leadCountData?.relatedLeadCount || 0;
          this.leadData.taskCount = this.leadCountData?.taskCount || 0;
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          // const error: ResponseModel = err.error;
          // this._toastrService.error(error.message, '');
          this._loaderService.stop();
          this._route.navigate(['/leads']);
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  freeTrialHide() {
    localStorage.setItem('freeTrial', 'false');
    this.freeTrial = false;
  }

  async listTask(bool?) {
    if (!this.currentUser?.userId) {
      await this.getCurrentUser();
    }
    if (bool) {
      this.currentTaskPage = 1;
    }
    let payload = {
      moduleId: this.moduleId,
      subModuleId: this.leadId,
      page: this.currentTaskPage,
      limit: this.taskCurrentLimit,
      isCompleted: false,
      taskDueType: 'ALL',
      sort: this.sort,
      assignUserId: this.currentUser?.userId,
    };

    if (!payload.moduleId) return;
    this._taskService.listTask(payload).subscribe(
      (response: ResponseModel) => {
        response.data.items = response.data.items.map((element) => {
          return {
            ...element,
            dueInIso: moment(element.dueInIso).format('MMM D, YYYY hh:mm A'),
          };
        });
        if (this.tasks && !bool) {
          if (this.tasks?.length === response.data?.count) {
            return;
          }
          this.taskCount = response.data?.count;
          if (bool) {
            this.tasks = response.data?.items;
          } else {
            this.tasks = [...this.tasks, ...response.data?.items];
          }
        } else {
          this.tasks = response?.data?.items;
          this.taskCount = response?.data?.count;
        }

        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  customFunction(taskTypeOption, header, type) {
    let value = taskTypeOption || 'N/A';
    let key;
    let data;

    if (type === 'class') {
      key = 'class';
    } else if (type === 'value') {
      key = 'name';
    }

    if (header === 'taskTypeOption') {
      if (value === 'N/A') {
        value = 8;
      }

      let task = this['_utilities'].getTaskTypeOption(value)[key];
      data =
        key === 'name'
          ? this['_utilities'].capitalizeName(task)
          : `re-custom ${task}`;
    }
    return data;
  }

  onScrollTask() {
    this.currentTaskPage += 1;
    this.listTask();
  }

  isCompletedTask(item) {
    let obj = {
      isCompleted: !item.isCompleted,
    };
    this._loaderService.start();
    this._taskService.updateTask(obj, item._id).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          this.listTask(true);
          this._sharedService.refreshActivityLog.next(true);
          this._sharedService.refreshAppointments.next(true);
          if (obj.isCompleted) {
            this._toastrService.success('Task marked as Completed', '');
          } else {
            this._toastrService.success('Task marked as Pending', '');
          }
          this.currentTaskPage = 1;
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getTaskIcon(dueInIsoTimeStamp) {
    let startTime = moment()
      .tz(localStorage.timezoneOffset)
      .startOf('day')
      .valueOf();
    let endTime = moment()
      .tz(localStorage.timezoneOffset)
      .endOf('day')
      .valueOf();
    if (dueInIsoTimeStamp < startTime) {
      return 'assets/images/time-icon-red.png';
    }
    if (dueInIsoTimeStamp <= endTime) {
      return 'assets/images/time-icon-yellow.png';
    }
    if (dueInIsoTimeStamp > endTime) {
      return 'assets/images/time-icon-green.png';
    }
  }

  // switchToBasicDialer()
  // {
  //   console.log("switchToBasicDialer")
  //   this.leadDialerDailog.close("Switch To Basic Dialer");
  // }

  onNumberType(value) {
    const type = this._utilities.numberType(value?.toLowerCase());
    return type;
  }
  async sendSms() {
    if (this.SMSCredit == 0) {
      return;
    }
    this.submittedSms = true;
    if (this.smsDetailForm.invalid) {
      return;
    }
    const { smsNumber } = this.smsDetailForm.value;
    let number = this.leadData?.defaultCallNumber
      ? this.leadData?.defaultCallNumber
      : this.leadData?.number;
    const smsNumbers = this.contactData[smsNumber]?.contactNumber
      ? this.contactData[smsNumber]?.contactNumber
      : this.contactData[smsNumber]?.phoneNumber;
    const moduleType =
      this.contactData[smsNumber]?.contactType == 'Buyer' ||
      this.contactData[smsNumber]?.contactType == 'Agent'
        ? 'Buyer'
        : 'Leads';
    if (
      this.contactData[smsNumber]?.contactType == 'Buyer' ||
      this.contactData[smsNumber]?.contactType == 'Agent'
    ) {
      if (!this.buyerNumber?.length) {
        this._toastrService.info(this.messageConstant.noNumber, '', {
          enableHtml: true,
        });
        this._loaderService.stop();
        return;
      }
      if (this.buyerCallFrom == '') {
        this._toastrService.info(this.messageConstant.noNumber, '', {
          enableHtml: true,
        });
        return;
      }
      number = this.buyerCallFrom;
    }
    let obj = {
      ...this.smsDetailForm.value,
      leadSourceNumber: number,
      smsNumber: smsNumbers,
      moduleType: moduleType,
      fromBuyerModule: this.data.fromBuyerModule,
    };
    if (moduleType == 'Buyer') {
      obj['buyerId'] = this.contactData[smsNumber]?._id;
      obj['leadId'] = this.leadData?._id;
    } else {
      obj['leadId'] = this.leadData?._id;
    }

    if (this.showAttachments.length > 0) {
      this.showAttachments.forEach((item) => {
        this.attachments.push(item);
      });
    }

    if (this.attachments.length) {
      obj = {
        ...obj,
        mediaUrls: this.attachments.map((x) => x.url),
      };
      this.attachments = [];
    } else {
      delete obj['mediaUrls'];
    }
    this._loaderService.start();
    delete obj['scheduleDate'];
    delete obj['scheduleTime'];
    if (!this.mainUserData?._id) {
      this.mainUserData = await this.getMainUser();
    }
    this.messageBody(
      this._utilities.getTextWithMentions(obj.smsBody),
      this.leadsTeams
    );
    obj.smsBody = this._utilities.populateEditorDataBR(this.smsFilterBody);

    if (obj.smsBody) {
      obj.smsBody = obj.smsBody
        ?.replaceAll('&amp;', '&')
        ?.replaceAll('&gt;', '>')
        ?.replaceAll('&lt;', '<')
        ?.replaceAll('&#xFEFF;', '');

      obj.smsBody = obj.smsBody.replace(/[^\x00-\x7F]/g, '');
    }
    this._sharedService.sendSms(obj).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode == 200) {
          this.getSMSBalance();
          this._toastrService.success(this.messageConstant.smsSendSuccess, '');
          this._sharedService.refreshActivityLog.next(true);
          this._sharedService.refreshDashboard.next(true);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  getSmsTemplates() {
    let obj = {};
    this._smsService.getSmsList(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.smsTemplates = response.data?.items;
          this.smsTemplates.sort((a, b) =>
            a.templateName.localeCompare(b.templateName)
          );
          let smsTemplateId = this.smsTemplates
            .map((template) => {
              if (template.smsTemplateId) {
                return template.smsTemplateId;
              }
            })
            .filter((x) => x)[0];

          if (smsTemplateId) {
            this.smsDetailForm.patchValue({
              smsTemplateId: smsTemplateId.toString(),
            });
            this.setSmsTemplate(smsTemplateId, false);
          }
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  async setSmsTemplate(id, bool?) {
    this.showAttachments = [];
    if (!id) {
      this.smsDetailForm.patchValue({
        smsBody: '',
      });
    }
    const smsTemplateId = id;
    if (bool) {
      let obj = { smsTemplateId: smsTemplateId };

      this._smsService.changeTemplate(obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
          }
        },
        (err: ErrorModel) => {}
      );
    }
    const index = this.smsTemplates.findIndex((x) => x._id === smsTemplateId);
    if (index === -1) {
      return;
    }
    if (this.smsTemplates[index]?.attachmentArray) {
      let attchments = this.smsTemplates[index]?.attachmentArray.map((item) => {
        return { name: item?.name, url: item?.url };
      });
      this.showAttachments = attchments;
    }
    if (!this.mainUserData?._id) {
      this.mainUserData = await this.getMainUser();
    }
    this.messageBody(
      this._utilities.getTextWithMentions(this.smsTemplates[index].message),
      this.leadsTeams
    );
    this.smsDetailForm.patchValue({
      smsBody: this._utilities.populateEditorDataWithN(this.smsFilterBody),
    });
    this.getSMSCount('');
  }
  messageBody(smsMessage, teams) {
    const { smsNumber } = this.smsDetailForm.value;
    let data = {
      ...this.contactData[smsNumber],
      leadSource: this.leadData?.defaultCallNumber
        ? this.leadData?.defaultCallNumber
        : this.leadData?.number,
      address: this.leadData?.address,
      contacts: this.leadData?.secondaryContact,
      leadId: this.leadData?._id,
      moduleId: this.moduleId,
      leadIds: this.leadIds,
    };

    if (
      this.contactData[smsNumber]?.contactType == 'Buyer' ||
      this.contactData[smsNumber]?.contactType == 'Agent'
    ) {
      if (!this.buyerNumber?.length) {
        this._toastrService.info(this.messageConstant.noNumber, '', {
          enableHtml: true,
        });
        this._loaderService.stop();
        return;
      }
      let buyerName = '';
      if (this.contactData[smsNumber]?.firstName) {
        buyerName = this.contactData[smsNumber]?.firstName;
      }
      if (this.contactData[smsNumber]?.lastName) {
        buyerName = buyerName + ' ' + this.contactData[smsNumber]?.lastName;
      }
      data = {
        phoneNumber: this.contactData[smsNumber]?.contactNumber,
        leadSource: this.buyerNumber,
        buyerId: this.contactData[smsNumber]?._id,
        moduleId: this.moduleId,
        leadId: this.leadData?._id,
        contacts: [
          {
            name: buyerName,
            contactType: this.contactData[smsNumber]?.contactType,
            phoneNumber: this.contactData[smsNumber]?.contactNumber,
          },
        ],
        moduleType: 'Buyer',
        address: this.leadData?.address,
        leadIds: this.leadIds,
      };
    }
    data.teams = this.teams;
    if (this.leadData?.numberId || this.leadData?.defaultNumberId) {
      data.numberId = this.leadData?.defaultNumberId
        ? this.leadData?.defaultNumberId
        : this.leadData?.numberId;
    }
    data.allLeadHide = true;
    data.mainStatusId = this.leadData?.mainStatusId;
    let messageBody;
    let address;
    let streetAddress;
    let city;
    let state;
    let zip;
    let name;
    let firstName;
    let lastName;

    if (data?.name) {
      const leadTitle = data?.name
        ? data.name.replace(/ {2,}/g, ' ').trim()
        : '';
      name = leadTitle ? leadTitle.split(' ') : '';
      firstName = name[0] ? name[0] : 'First_Name(N/A)';
      lastName = name[1] ? name[1] : 'last_Name(N/A)';
    } else if (data?.contacts[0]?.name) {
      name = data?.contacts[0]?.name.split(' ');
      firstName = name[0] ? name[0] : 'First_Name(N/A)';
      lastName = name[1] ? name[1] : 'last_Name(N/A)';
    } else {
      firstName = 'First_Name(N/A)';
      lastName = 'last_Name(N/A)';
    }
    if (data?.address) {
      address = data?.address.split(',');
      if (address.length > 1) {
        streetAddress = address[0]
          ? address[0]
          : 'Property_Street_Address(N/A)';
        city = address[1] ? address[1] : 'Property_City(N/A)';
        if (address.length > 2) {
          let subDivide = address[2];
          if (subDivide) {
            let sub = subDivide.split(' ');
            state = sub[1] ? sub[1] : 'Property_State(N/A)';
            zip = sub[2] ? sub[2] : 'Property_Zip_Code(N/A)';
          }
        } else {
          state = 'Property_State(N/A)';
          zip = 'Property_Zip_Code(N/A)';
        }
      } else {
        streetAddress = address[0];
        city = 'Property_City(N/A)';
        state = 'Property_State(N/A)';
        zip = 'Property_Zip_Code(N/A)';
      }
    } else {
      streetAddress = 'Property_Street_Address(N/A)';
      city = 'Property_City(N/A)';
      state = 'Property_State(N/A)';
      zip = 'Property_Zip_Code(N/A)';
    }

    let myObj = {
      '@First_Name': this._utilities.titleCaseToWord(firstName),
      '@Last_Name': this._utilities.titleCaseToWord(lastName),
      '@Property_Street_Address': streetAddress ? streetAddress : '',
      '@Property_City': city ? city : '',
      '@Property_State': state ? state : '',
      '@Property_Zip_Code': zip ? zip : '',
      '@Appt_Date_and_Time': '',
      '@Appt_Day': '',
      '@Appt_Time': '',
      '@A2P_10DLC_Company_Name': this.mainUserData?.A2P10DLCCompanyName
        ? this.mainUserData?.A2P10DLCCompanyName
        : '',
      '@DBA': this.mainUserData?.A2PDBA
        ? this.mainUserData?.A2PDBA
        : '',
    };
    teams?.roles?.map((i) => {
      let key = i.name.toString();
      if (key != undefined && smsMessage.includes(key)) {
        let originalRole = teams.roles.find((item) => item.name == key);
        let userId = teams.assignUser[originalRole._id];
        if (!userId) {
          if (teams?.items?.roles) {
            originalRole = teams?.items?.roles.find(
              (item) => item.name == 'Owner'
            );
          }
          if (teams?.roles) {
            originalRole = teams?.roles.find((item) => item.name == 'Owner');
          }
        }
        userId =
          userId != null
            ? userId
            : teams?.items?.assignUser['5fd093a2f44c5ac2ea1919b4']
            ? teams?.items?.assignUser['5fd093a2f44c5ac2ea1919b4']
            : teams?.assignUser['5fd093a2f44c5ac2ea1919b4'];
        let username;
        if (userId) {
          username = originalRole.members.find(
            (item) => item._id.toString() == userId.toString()
          );
        }
        let obj = {};
        obj['@' + key] = username?.firstName
          ? this._utilities.titleCaseToWord(`${username?.firstName}`)
          : `${key}(N/A)`;
        myObj = { ...myObj, ...obj };
      }
    });

    let rowRole = [
      '@First_Name',
      '@Last_Name',
      '@Property_Street_Address',
      '@Property_City',
      '@Property_State',
      '@Property_Zip_Code',
      '@Appt_Date_and_Time',
      '@Appt_Day',
      '@Appt_Time',
      '@A2P_10DLC_Company_Name',
      '@DBA',
    ];

    let roles = teams?.roles
      ? teams?.roles?.map((item) => '@' + item.name)
      : [];
    let rolesWithKey = new RegExp([...rowRole, ...roles].join('|'), 'g');
    messageBody = smsMessage.replace(rolesWithKey, (matched) => {
      return myObj[matched];
    });
    //messageBody = messageBody.replace(/@/g, '');
    this.smsFilterBody = messageBody;
  }
  getSMSCount(e) {
    const MAX_LENGTH = 160;
    const message = this._utilities.getText(
      this['editor'].quillEditor.getContents()
    );

    const length = message.length ? message?.length - 1 : 0;

    this.smsCount = Math.ceil(length / MAX_LENGTH);
    this.smsCharCount = MAX_LENGTH * this.smsCount - length;
  }
  onUploadLogo(output: UploadOutput): void {
    this.fileInput.nativeElement.value = '';
    this._loaderService.start();
    clearTimeout(this.timeOut);
    if (output.type === 'allAddedToQueue') {
      // when all files added in queue
      const token = localStorage.getItem('token');
      let endpoint =
        environment.baseUrl + BaseUrl.sharedUrl + 'mms-file-upload';
      if (this.moduleId) {
        endpoint += `?moduleId=${this.moduleId}&isPublic=1`;
      }

      const event: UploadInput = {
        type: 'uploadAll',
        url: endpoint,
        method: 'POST',
        headers: { Authorization: token },
      };
      this.uploadInput.emit(event);
    } else if (
      output.type === 'addedToQueue' &&
      typeof output.file !== 'undefined'
    ) {
    } else if (output.type === 'done' && typeof output.file !== 'undefined') {
      // this.files = output.file;
      this.count += 1;
      if (output.file.response.statusCode == 200) {
        this._loaderService.stop();
        if (typeof output.file.response.data == 'object') {
          output.file.response.data?.filter((x) => {
            this.attachments.push(x);
          });
          this.attachments = _.uniqBy(this.attachments, 'name');
        } else {
          this.attachments = output.file.response.data;
          this.attachments = _.uniqBy(this.attachments, 'name');
        }
      } else {
        this._loaderService.stop();
        if (output.file?.response?.message) {
          this._toastrService.error(output.file.response.message);
        } else {
          this._toastrService.error('Invalid');
        }
      }
    } else if (output.type === 'dragOut') {
      // drag out event
      setTimeout(() => {
        this._loaderService.stop();
      }, 2000);
    } else if (output.type === 'drop') {
      // on drop event
      setTimeout(() => {
        this._loaderService.stop();
      }, 2000);
    }
  }
  removeExistingAttachment(index) {
    this.showAttachments.splice(index, 1);
  }
  removeAttachment(index) {
    this.attachments.splice(index, 1);
  }
  getSMSBalance() {
    this._creditService.getSMSCredit({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.SMSCredit = response?.data;
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  async saveActivity(isPublic?) {
    if (!this.comments) {
      return;
    }
    this.comments = this.comments.trim();

    let body = {};
    body = {
      moduleId: this.moduleId,
      subModuleId: this.leadId,
      comment: this.comments,
      isTagged: false,
    };
    if (this.leadData?.address) {
      body['address'] = this.leadData?.address;
    }
    if (this.leadData?.unitNo) {
      body['unitNo'] = this.leadData?.unitNo;
    }
    if (this.leadData?.title) {
      body['title'] = this.leadData?.title;
    }

    if (this.leadData?.mainStatusTitle) {
      body['mainStatus'] = this.leadData?.mainStatusTitle;
    }
    if (this.leadData?.phoneNumber) {
      body['phoneNumber'] = this.leadData?.phoneNumber;
    }
    this._sharedService.addActivityLog(body, isPublic).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          this._sharedService.refreshActivityLog.next(true);
          let result = response.data;
          this.comments = '';
        }
      },
      (err: ErrorModel) => {
        this.comments = '';
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  twilioOutboundCallEvent(connection) {
    this.twilioCallSid = connection?.parameters?.CallSid;
    this.callUUID = connection?.parameters?.CallSid;
    this.multiCallUUID = connection?.parameters?.CallSid;
    this.multiCallEventName = connection.customParameters.get('X-PH-from');
    if (this.twilioCallSid) {
      this.callAnswered = true;
      this.callReady = true;
      this.callDuration = '00:00:00';
      this.callStatus = 'Ongoing Call...';
      this.mergeCallDialer.firstCall = true;
      let a = moment();
      let b = moment();
      this.counter = setInterval(() => {
        this.showTime(a, b);
      }, 1000);
    }
  }

  changeQualified() {
    this._loaderService.start();
    const obj = {
      isQualified: !this.leadData?.isQualified,
      updatedFrom: 'Qualified',
    };

    this._leadService.editLead(obj, this.data.leadId).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          this.leadData.isQualified = !this.leadData?.isQualified;
          this._sharedService.refreshActivityLog.next(true);
          this._sharedService.refreshInfo.next(true);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  isVisibleOnDialer(val?) {
    if (val) {
      return !!(this.moduleName == val);
    }
    return !!(this.moduleName != 'Leads' && this.moduleName != 'Inventory');
  }
  getTeams(leadId) {
    let obj = {
      leadId,
    };

    this._leadService.getTeams(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode && response.statusCode == 200) {
          this.leadsTeams = response.data.items;
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          // this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  getMainUser() {
    return new Promise(async (resolve, reject) => {
      this._masterTaskService
        .getUserRoleList({ page: 1, limit: 100 })
        .subscribe(
          (response: ResponseModel) => {
            if (response.statusCode == 200) {
              resolve(response.data.items.filter((x) => !x.mainUserId)[0]);
            }
          },
          (err: ErrorModel) => {
            resolve('');
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
    });
  }
}
