import { Component, OnInit, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// UTILS
import { MessageConstant } from 'src/app/utils/message-constant';
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';

import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { ContingenciesService } from 'src/app/providers/contingencies/contingencies.service';
@Component({
  selector: 'app-wholesale-pipeline-status-change',
  templateUrl: './wholesale-pipeline-status-change.component.html',
  styleUrls: ['./wholesale-pipeline-status-change.component.scss'],
})
export class WholesalePipelineStatusChangeComponent implements OnInit {
  wholeSaleStatusForm: FormGroup;

  messageConstant = MessageConstant;
  contingencies: any[] = [];
  isContingencyCheck: boolean = false;
  isMarketingBuyers: boolean = false;
  isShowingBuyers: boolean = false;
  isEvaluatingOffers: boolean = false;
  isAcceptedOffer: boolean = false;
  isClearClose: boolean = false;
  isSold: boolean = false;
  submitted: boolean = false;

  maxDateTime = new Date();
  isMarketReadonly: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private wholesalePipelineStatusChangeComponent: MatDialogRef<WholesalePipelineStatusChangeComponent>,
    private fb: FormBuilder,
    public _commonFunction: CommonFunctionsService,
    private _contingencyService: ContingenciesService,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.wholeSaleStatusForm = this.fb.group({
      //marketing to buyers
      marketingToBuyersStartDate: [new Date()],
      askForProperty: [null],

      //showing to buyers
      buyerShowingSchedule: [new Date()],

      //evaluating offers
      firstOfferDate: [new Date()],
      lowestOffer: [null],
      highestOffer: [null],

      //accepted offer
      acceptOfferDate: [new Date()],
      buyingOfferPrice: [null],
      contingencyArray: this.fb.array([]),
      contingencyStatus: [false],

      //clear to close
      clearToCloseDate: [new Date()],

      //sold
      exitType: [this.data?.item?.investmentTitle || ''],
      closingDate: [new Date()],
      assignmentFee: [null],
      purchasePrice: [null],
      purchaseDate: [new Date()],
      salesPrice: [null],
      salesDate: [new Date()],
      netProfit: [null],
    });
    this.isVisible();
  }

  ngAfterViewInit() {
    this.isMarketReadonly = true;
  }

  setValidators(fields) {
    fields.filter((field) => {
      this.wholeSaleStatusForm.get(field).setValidators([Validators.required]);
    });
  }

  unsetValidators(fields) {
    fields.filter((field) => {
      this.wholeSaleStatusForm.get(field).clearValidators();
      this.wholeSaleStatusForm.get(field).setErrors(null);
      this.wholeSaleStatusForm.get(field).setValue('');
    });
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.wholeSaleStatusForm.controls[controlName].hasError(errorName);
  };

  isVisible() {
    const {
      isMarketingToBuyers,
      isShowingToBuyers,
      isEvaluatingOffers,
      isAcceptedOffer,
      isClearToClose,
      isSold,
    } = this.data.isVisibleData;

    let formActivated = [];
    if (isMarketingToBuyers && this.data?.fromKey != 'isMarketingToBuyers') {
      this.isMarketingBuyers = true;
      formActivated.push('marketingToBuyersStartDate', 'askForProperty');
    }
    if (isShowingToBuyers && this.data?.fromKey != 'isShowingToBuyers') {
      this.isShowingBuyers = true;
      formActivated.push('buyerShowingSchedule');
    }
    if (isEvaluatingOffers && this.data?.fromKey != 'isEvaluatingOffers') {
      this.isEvaluatingOffers = true;
      formActivated.push('firstOfferDate', 'lowestOffer', 'highestOffer');
    }
    if (isAcceptedOffer && this.data?.fromKey != 'isAcceptedOffer') {
      this.isAcceptedOffer = true;
      formActivated.push('acceptOfferDate', 'buyingOfferPrice');
      this.getContingency();
    }
    if (isClearToClose && this.data?.fromKey != 'isClearToClose') {
      this.isClearClose = true;
      formActivated.push('clearToCloseDate');
      this.getContingency();
    }
    if (isSold) {
      this.isSold = true;
      if (
        this.data?.item?.investmentTitle == 'Wholesale - Assignment Contract' ||
        this.data?.item?.investmentTitle == 'Novation'
      )
        formActivated.push('closingDate', 'assignmentFee', 'netProfit');
      else
        formActivated.push(
          'purchasePrice',
          'purchaseDate',
          'salesPrice',
          'salesDate',
          'netProfit'
        );
    }
    this.setValidators(formActivated);
  }

  getContingency() {
    let obj = {
      page: 0,
      limit: 0,
    };

    this._contingencyService.getContingencyType(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode && response.statusCode == 200) {
          this.contingencies = response.data;
        } else {
          this._toastrService.error(response.message, '');
        }
      },
      (err: ErrorModel) => {
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  selectContingencyStatus(bool) {
    this.isContingencyCheck = bool;

    this.wholeSaleStatusForm.patchValue({
      contingencyStatus: bool,
    });

    if (bool) {
      this.addContingency();
      this.setValidators(['contingencyArray']);

      return;
    }

    this.contingencies.filter((e, index) => {
      this.deleteContingency(index);
    });
    this.unsetValidators(['contingencyArray']);
  }

  get contingencyFormArray() {
    return this.wholeSaleStatusForm.get('contingencyArray') as FormArray;
  }

  addContingency() {
    this.contingencyFormArray.push(
      this.fb.group({
        contingencyName: ['', []],
        contingencyTypeId: ['', [Validators.required]],
        contingencyExpiration: ['', [Validators.required]],
      })
    );
  }

  deleteContingency(index) {
    this.contingencyFormArray.removeAt(index);
  }

  transformAmount(element) {
    var value = element.target.value.split('.');
    if (element.target.value && value[1]) {
      if (value[1].length == 2) {
        element.target.value = element.target.value;
      } else if (value[1].length == 1) {
        element.target.value = element.target.value + '0';
      } else {
        element.target.value = element.target.value + '00';
      }
    } else if (element.target.value != '$ ') {
      element.target.value = value[0] + '.00';
    }
  }

  getData() {
    let {
      marketingToBuyersStartDate,
      askForProperty,
      buyerShowingSchedule,
      firstOfferDate,
      lowestOffer,
      highestOffer,
      acceptOfferDate,
      buyingOfferPrice,
      contingencyArray,
      contingencyStatus,
      clearToCloseDate,
      closingDate,
      assignmentFee,
      purchasePrice,
      purchaseDate,
      salesPrice,
      salesDate,
      netProfit,
    } = this.wholeSaleStatusForm.value;

    marketingToBuyersStartDate = this._commonFunction.dueDateFormat(
      marketingToBuyersStartDate
    ).timeStamp;
    buyerShowingSchedule =
      this._commonFunction.dueDateFormat(buyerShowingSchedule).timeStamp;
    firstOfferDate =
      this._commonFunction.dueDateFormat(firstOfferDate).timeStamp;

    acceptOfferDate =
      this._commonFunction.dueDateFormat(acceptOfferDate).timeStamp;
    clearToCloseDate =
      this._commonFunction.dueDateFormat(clearToCloseDate).timeStamp;

    closingDate = this._commonFunction.dueDateFormat(closingDate).timeStamp;
    purchaseDate = this._commonFunction.dueDateFormat(purchaseDate).timeStamp;
    salesDate = this._commonFunction.dueDateFormat(salesDate).timeStamp;

    contingencyArray = contingencyArray.map((x) => {
      x.contingencyExpiration = this._commonFunction.dueDateFormat(
        x.contingencyExpiration
      ).timeStamp;

      if (x.contingencyTypeId) {
        const contingency = this.contingencies.find(
          (contingency) => contingency.value === x.contingencyTypeId
        );
        x.contingencyName = contingency?.label || '';
      }

      return x;
    });
    askForProperty = parseFloat(askForProperty);
    lowestOffer = parseFloat(lowestOffer);
    highestOffer = parseFloat(highestOffer);
    buyingOfferPrice = parseFloat(buyingOfferPrice);

    assignmentFee = parseFloat(assignmentFee);
    purchasePrice = parseFloat(purchasePrice);
    salesPrice = parseFloat(salesPrice);
    netProfit = parseFloat(netProfit);

    const {
      isMarketingToBuyers,
      isShowingToBuyers,
      isEvaluatingOffers,
      isAcceptedOffer,
      isClearToClose,
      isSold,
    } = this.data.isVisibleData;

    const { to, from } = this.data?.status;
    let statusChangeData = {};

    if (isMarketingToBuyers && this.data?.fromKey != 'isMarketingToBuyers') {
      statusChangeData['marketingToBuyersStartDate'] =
        marketingToBuyersStartDate;
      statusChangeData['askForProperty'] = askForProperty;
    }
    if (isShowingToBuyers && this.data?.fromKey != 'isShowingToBuyers') {
      statusChangeData['buyerShowingSchedule'] = buyerShowingSchedule;
    }
    if (isEvaluatingOffers && this.data?.fromKey != 'isEvaluatingOffers') {
      statusChangeData['firstOfferDate'] = firstOfferDate;
      statusChangeData['lowestOffer'] = lowestOffer;
      statusChangeData['highestOffer'] = highestOffer;
    }
    if (isAcceptedOffer && this.data?.fromKey != 'isAcceptedOffer') {
      statusChangeData['acceptOfferDate'] = acceptOfferDate;
      statusChangeData['buyingOfferPrice'] = buyingOfferPrice;
      statusChangeData['contingencyArray'] = contingencyArray;
      statusChangeData['contingencyStatus'] = contingencyStatus;
      statusChangeData['contingencyType'] = 1;
    }
    if (isClearToClose && this.data?.fromKey != 'isClearToClose') {
      statusChangeData['clearToCloseDate'] = clearToCloseDate;
    }
    if (isSold) {
      if (
        this.data?.item?.investmentTitle == 'Wholesale - Assignment Contract' ||
        this.data?.item?.investmentTitle == 'Novation'
      ) {
        statusChangeData['closingDate'] = closingDate;
        statusChangeData['assignmentFee'] = assignmentFee;
        statusChangeData['netProfit'] = netProfit;
        statusChangeData['inventory'] = {
          projectTypeId: this.data?.item?.projectTypeId || '',
          mainStatusId: to,
          purchaseDate: closingDate,
          salesDate: closingDate,
          netProfit: netProfit,
        };
      } else {
        statusChangeData['inventory'] = {
          projectTypeId: this.data?.item?.projectTypeId || '',
          mainStatusId: to,
          purchaseDate,
          purchasePrice,
          salesDate,
          salesPrice,
          netProfit,
        };
      }
    }

    if (
      !statusChangeData['contingencyArray'] ||
      !statusChangeData['contingencyArray'].length
    ) {
      delete statusChangeData['contingencyArray'];
    }

    return statusChangeData;
  }

  onSubmit() {
    this.submitted = true;
    if (this.wholeSaleStatusForm.invalid) {
      return;
    }
    let statusChangeData: any = this.getData();
    this.wholesalePipelineStatusChangeComponent.close(statusChangeData);
  }
}
