<div class="re-top-head">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      Send Leads to Cold Calling Dialer
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<mat-dialog-content class="mt-3">
  <div class="container-fluid p-0">
    <div class="box">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12">
          <h5 class="re-filter-header">CAMPAIGN SELECTION</h5>
        </div>
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <div class="re-radio-list">
              <label class="re-radio-btn"
                >Use existing campaign
                <input
                  type="radio"
                  name="dialerCampaignType "
                  [(ngModel)]="dialerCampaignType"
                  (click)="resetCampaignType($event.target.value)"
                  value="exist"
                  checked /><span class="re-checkmark"></span
              ></label>
              <label class="re-radio-btn"
                >Create new campaign
                <input
                  type="radio"
                  name="dialerCampaignType "
                  [(ngModel)]="dialerCampaignType"
                  (click)="resetCampaignType($event.target.value)"
                  value="new" /><span class="re-checkmark"></span
              ></label>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group" *ngIf="dialerCampaignType == 'exist'">
            <label class="re-labels"
              >Select Campaign<span class="text-danger">*</span></label
            >

            <select
              class="form-control"
              [(ngModel)]="selectCampaign"
              name="selectCampaign"
              (change)="setCampaignData($event.target.value)"
            >
              <option value="">Select Campaign</option>
              <option
                value="{{ eachCampaign._id }}"
                *ngFor="let eachCampaign of allCampaigns; let i = index"
              >
                {{ eachCampaign.campaignName }}
              </option>
            </select>
          </div>
          <div class="form-group" *ngIf="dialerCampaignType == 'new'">
            <label class="re-labels"
              >Enter New Campaign Name<span class="text-danger">*</span></label
            >
            <input
              type="text"
              class="form-control"
              name="campaignName"
              [(ngModel)]="campaignName"
              placeholder="Campaign Name"
            />
          </div>
          <hr />
        </div>
        <div class="col-12 col-md-12 col-sm-12">
          <h5 class="re-filter-header">ASSIGNMENT</h5>
        </div>
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <label class="re-labels"
              >Assigned to User <span class="text-danger">*</span>
              <img
                src="assets/images/info-icon.png"
                alt=""
                class="ml-1 align-middle"
                pTooltip='You can only assign a Campaign in Dialer to a 
              user in the role of "Cold Caller"'
                tooltipPosition="bottom"
              />
            </label>
            <select
              class="form-control"
              [(ngModel)]="selectUser"
              name="selectUser"
              [disabled]="dialerCampaignType == 'exist'"
            >
              <option value="">Select assignee user</option>
              <option value="{{ user.value }}" *ngFor="let user of users">
                {{ user.label }}
              </option>
            </select>
          </div>
          <hr />
        </div>
        <div class="col-12 col-md-12 col-sm-12">
          <h5 class="re-filter-header">DUPLICATION</h5>
          <div class="re-switchsec">
            <!-- <div class="radiosec">
              <p class="radotext">Scrub DNC</p>
              <label class="switch">
                <input type="checkbox" id="checkbox1" />
                <span class="slider"></span>
                <span class="text1" value="false">No</span>
                <span class="text2" value="true">Yes</span>
              </label>
            </div> -->
            <div class="radiosec">
              <p class="radotext">
                Remove if already on a Campaign
                <img
                  src="assets/images/info-icon.png"
                  alt=""
                  class="ml-1 align-middle"
                  pTooltip="Enabling this will not add any records that are already assigned to any user in any Cold Calling campaign."
                  tooltipPosition="bottom"
                />
              </p>
              <label class="switch">
                <input
                  type="checkbox"
                  id="checkbox2"
                  [(ngModel)]="isPropertyOtherCampaign"
                />
                <span class="slider"></span>
                <span class="text1" value="false">No</span>
                <span class="text2" value="true">Yes</span>
              </label>
            </div>
            <div class="radiosec">
              <p class="radotext">
                Remove if already a lead
                <img
                  src="assets/images/info-icon.png"
                  alt=""
                  class="ml-1 align-middle"
                  pTooltip="Enabling this will not add any records that are already in Lead."
                  tooltipPosition="bottom"
                />
              </p>
              <label class="switch">
                <input
                  type="checkbox"
                  id="checkbox2"
                  [(ngModel)]="isPropertyInLead"
                />
                <span class="slider"></span>
                <span class="text1" value="false">No</span>
                <span class="text2" value="true">Yes</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="padding-top: 12px !important">
  <button mat-raised-button [mat-dialog-close]="false" class="re-cancel-btn">
    Cancel
  </button>
  <button
    mat-raised-button
    cdkFocusInitial
    class="re-submit-btn"
    (click)="submitCampaign()"
  >
    Assign Campaign
  </button>
</mat-dialog-actions>
