export const environment = {
  production: true,
  hmr: false,
  releaseStage: 'live',
  version: require('../../package.json').version,

  // URLS
  baseUrl: `https://live-api.resimpli.com/`,
  appUrl: `${window.location.origin}/`,
  socketUrl: 'https://socket.resimpli.com/',
  listStackingUrl: 'https://api-list-stacking.resimpli.com/',
  uspsUrl:'https://uspsapis.resimpli.com/',
  s3Url: 'https://resimpli.s3.us-west-2.amazonaws.com/',
  newS3Url: 'https://resimpli--v2-public.s3.us-east-2.amazonaws.com/',
  accountApiUrl: 'https://webmail-api.resimpli.com/webmail/api/',
  apiUrl: 'https://webmail-api.resimpli.com/webmail/index.php?Api',
  webSiteUrl: 'https://resimpliwebsites.com/wp-signup.php?type=buyer',
  // BUGSNAG
  bugSnag: {
    apiKey: '0ab5f4521aeb2bce0c2a9fae7d590130',
  },

  // KEYS
  authorizationKey: 'cmVzaW1wbGk6YW5ndWxhcmV4cGVydA==',
  esigngenieClientId: '122cc0895e4142df8771169dc4e70df7',
  esigngenieClientSecret: '8b1bcdead0e543f4bb40c15820aa6591',
  stripePublickKey: 'pk_live_iIz7C9HE6mVpzOHfUFQhN9TL00GCnx6uye',

  // SOCIALS
  google: {
    googleLoginProvider:
      '668610683479-drd7ghck2895p8atpltiabq72893agb1.apps.googleusercontent.com',
    apiKey: 'AIzaSyAM-KSxUpch6bTsE5hAbHgf2pM1kzbxnt0',
  },

  // PLIVO
  plivo: [
    {
      username: 'REsimpliV233000729162042852939018',
      password: 'Paasword!23',
      sipUrl: 'resimpliv233000729162042852939018@phone.plivo.com',
    },
    {
      username: 'REsimpliE1147999846652469456399',
      password: 'Asdf!234',
      sipUrl: 'REsimpliE1147999846652469456399@phone.plivo.com',
    },
    {
      username: 'REsimpliE6749755024154155',
      password: 'Asdf!234',
      sipUrl: 'REsimpliE6749755024154155@phone.plivo.com',
    },
    {
      username: 'REsimpliE974824294846876742088',
      password: 'Asdf!234',
      sipUrl: 'REsimpliE974824294846876742088@phone.plivo.com',
    },
    {
      username: 'REsimpliE36998227033339694621734',
      password: 'Asdf!234',
      sipUrl: 'REsimpliE36998227033339694621734@phone.plivo.com',
    },
    {
      username: 'REsimpliE18274901211364462024603',
      password: 'Asdf!234',
      sipUrl: 'REsimpliE18274901211364462024603@phone.plivo.com',
    },
  ],

  // PAGINATION
  pagination: {
    defaultSort: 'ASC', // ASC, DESC
    defaultView: 'GRID', // GRID, LIST
    pageLimit: 12,
    type: 'STANDARD',
  },

  // OTHERS
  defaultPath: 'dashboard',
  worker: true,
  defaultPlan: 99,
  countryCode: '+1',
  listingFileLimit: 100,
  listingDocLimit: 5,

  // BUYERS
  buyers: {
    bulkImportLimit: 5001,
    bulkSMSLimit: 2500,
    bulkEmailLimit: 50000,
  },

  // GENERAL FILES
  generalFiles: {
    moduleId: '615f1f5634bb78755ff35013',
    subModuleId: '6164512234bb78755f543982',
  },
};
