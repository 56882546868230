import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ContingenciesService } from 'src/app/providers/contingencies/contingencies.service';
import { InventoryService } from 'src/app/providers/inventory/inventory.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

// UTILS
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';
import { MessageConstant } from 'src/app/utils/message-constant';

@Component({
  selector: 'app-add-edit-contingency',
  templateUrl: './add-edit-contingency.component.html',
  styleUrls: ['./add-edit-contingency.component.scss'],
})
export class AddEditContingencyComponent implements OnInit {
  isEdit: boolean = false;
  isSubmitted: boolean = false;

  contingencyForm: FormGroup;
  messageConstant = MessageConstant;

  investmentType: any[] = [];
  contingencies: any[] = [];

  constructor(
    private _dialogRef: MatDialogRef<AddEditContingencyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _contingencyService: ContingenciesService,
    private _inventoryService: InventoryService,
    private _commonFunction: CommonFunctionsService
  ) {
    this.getInvestmentType();
  }

  ngOnInit(): void {
    this.contingencyForm = this._fb.group({
      contingencyName: ['', []],
      contingencyType: [this.data?.type == 'leads' ? 0 : 1],
      contingencyTypeId: ['', [Validators.required]],
      contingencyExpiration: ['', [Validators.required]],
      subModuleId: [this.data.leadId, [Validators.required]],
      existingTypeId: ['', [Validators.required]],
      contingencyStatus: [false, [Validators.required]],
    });
  }

  getInvestmentType() {
    const obj = {
      page: 1,
    };

    this._loaderService.start();
    this._inventoryService.getInvestmentType(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode && response.statusCode == 200) {
          this.investmentType = response.data.items;
          this.getContingencyType();

          return;
        }

        this._toastrService.error(response.message, '');
      },
      (err: ErrorModel) => {
        this._loaderService.stop();

        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  getContingencyType() {
    const obj = {
      page: 0,
      limit: 0,
    };

    this._contingencyService.getContingencyType(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode && response.statusCode == 200) {
          this._loaderService.stop();
          this.contingencies = response.data;

          this.isEdit = !!this.data.details;

          if (this.isEdit) {
            this.contingencyForm.patchValue({
              ...this.data.details,
              subModuleId: this.data.leadId,
              contingencyExpiration: new Date(
                this.data.details.contingencyExpiration
              ),
            });
          } else {
            const type = this.investmentType.find(
              (x) =>
                x.name?.toLowerCase() ===
                this.data?.item?.investmentTitle?.toLowerCase()
            );

            if (!type) return;

            this.contingencyForm.patchValue({
              existingTypeId: type._id,
            });
          }

          return;
        }

        this._toastrService.error(response.message, '');
      },
      (err: ErrorModel) => {
        this._loaderService.stop();

        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.contingencyForm.controls[controlName].hasError(errorName);
  };

  onSubmit() {
    try {
      this.isSubmitted = true;

      if (this.contingencyForm.invalid) return;

      let obj = {
        ...this.contingencyForm.value,
      };

      obj['contingencyExpiration'] = this._commonFunction.dueDateFormat(
        obj['contingencyExpiration'],
        '',
        {
          hours: 0,
          minutes: 0,
          seconds: 0,
        }
      ).timeStamp;

      if (obj['contingencyTypeId']) {
        const contingency = this.contingencies.find(
          (x) => x.value === obj['contingencyTypeId']
        );
        obj['contingencyName'] = contingency?.label || '';
      }

      let method = 'addContingency';

      if (this.isEdit) {
        method = 'updateContingency';

        obj['contingencyId'] = this.data?.details?._id;
      } else {
        obj = {
          subModuleId: obj.subModuleId,
          existingTypeId: obj.existingTypeId,
          contingencyStatus: obj.contingencyStatus,
          contingencyType: obj.contingencyType,
          contingencyArray: [
            {
              contingencyName: obj.contingencyName,
              contingencyTypeId: obj.contingencyTypeId,
              contingencyExpiration: obj.contingencyExpiration,
            },
          ],
        };
      }

      this._loaderService.start();
      this._contingencyService[method](obj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this._loaderService.stop();
            this._dialogRef.close(response.data);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
    } catch (error) {
      console.log('ERROR: ', error);
    }
  }
}
