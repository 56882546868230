import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textToLink',
})
export class TextToLinkPipe implements PipeTransform {
  transform(value: string): string {
    let replacedText;
    let replacePattern1;
    let replacePattern2;
    let replacePattern3;
    value = value.replace(/<p>/g, '');
    value = value.replace(/<\/p>/g, '\n');
    value = value.replace(/href="http:\/\/www./g, 'hyperfwww="'); // replace all https://www. inside anchor tag with unique pattern
    value = value.replace(/href="http:\/\//g, 'hyperf="'); // replace all http:// inside anchor tag with unique pattern
    value = value.replace(/href="https:\/\/www./g, 'hyperfswww="'); // replace all https://www. inside anchor tag with unique pattern
    value = value.replace(/href="https:\/\//g, 'hyperfs="'); // replace all https:// inside anchor tag with unique pattern
    value = value.replace(/href="www./g, 'www="'); // replace all www. inside anchor tag with unique pattern
    value = value.replace(/href="ftp:\/\//g, 'hyperftp="'); // replace all ftp:// inside anchor tag with unique pattern

    // URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = value.replace(
      replacePattern1,
      '<a class="re-teal" href="$1" target="_blank">$1</a>'
    );

    // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(
      replacePattern2,
      '$1<a class="re-teal" href="http://$2" target="_blank">$2</a>'
    );

    // Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(
      replacePattern3,
      '<a class="re-teal" href="mailto:$1">$1</a>'
    );
    replacedText = replacedText.replace(/hyperf="/g, 'href="http://'); // revert all http:// inside anchor tag that was replaced
    replacedText = replacedText.replace(/hyperfwww="/g, 'href="http://www.'); // revert all http://www. inside anchor tag that was replaced
    replacedText = replacedText.replace(/hyperfs="/g, 'href="https://'); // revert all https:// inside anchor tag that was replaced
    replacedText = replacedText.replace(/hyperfswww="/g, 'href="https://www.'); // revert all https://www. inside anchor tag that was replaced
    replacedText = replacedText.replace(/www="/g, 'href="www.'); // revert all ftp:// inside anchor tag that was replaced
    replacedText = replacedText.replace(/hyperftp="/g, 'href="ftp://'); // revert all ftp:// inside anchor tag that was replaced
    return replacedText;
  }
}
