<div class="re-header-container">
  <div class="col-12 d-flex re-header">
    <h2
      mat-dialog-title
      style="display: block !important"
      class="w-100 d-flex justify-content-between re-header-title"
    >
      {{ data?.items[currentIndex]?.title || "Attachment" }}
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        class="re-closed"
        style="margin-top: 10px"
      />
    </span>
  </div>
</div>

<div
  id="carouselControls"
  class="carousel slide"
  data-interval="false"
  data-ride="carousel"
  (swipe)="onSwipe($event)"
>
  <div class="carousel-inner">
    <div
      *ngFor="let image of data['items']; let imageIndex = index"
      class="carousel-item"
      [ngClass]="{ active: imageIndex === currentIndex }"
    >
      <ng-container *ngIf="checkFileType(image.fileType); else else_block">
        <ng-container *ngIf="isImage(image?.fileType?.toLowerCase())">
          <ng-container
            *ngIf="
              !image?.thumbnailUrl && image?.fileType?.toLowerCase() === 'heic';
              else else_preview
            "
          >
            <div class="re-no-preview-available">
              <div class="re-box">
                <p>No preview available.</p>
                <button
                  class="btn re-download"
                  (click)="downloadFile(image)"
                  *ngIf="!image?.directDownload"
                >
                  <i class="fa fa-download"></i>
                  Download
                </button>
                <a
                  class="btn re-download"
                  href="{{ _environment?.newS3Url + '' + image?.photo }}"
                  *ngIf="image?.directDownload"
                  download
                >
                  <i class="fa fa-download"></i>
                  Download
                </a>
              </div>
            </div>
          </ng-container>

          <ng-template #else_preview>
            <img
              [src]="getImageSrc(image)"
              class="re-image-view"
              [alt]="data?.file?.title"
            />
          </ng-template>
        </ng-container>

        <ng-container *ngIf="isVideo(image?.fileType?.toLowerCase())">
          <video
            #viewPlayer
            class="w-100"
            controls
            [src]="getImageSrc(image)"
          ></video>
        </ng-container>

        <ng-container
          *ngIf="isDoc(image?.fileType?.toLowerCase()) && image.src"
        >
          <iframe
            [src]="image?.src"
            frameborder="0"
            class="re-iframe"
            (swipe)="onSwipe($event)"
          ></iframe>
        </ng-container>
        <ng-container *ngIf="isAudio(image?.fileType?.toLowerCase())">
          <app-audio-player
            #audioPlayer
            [url]="image?.src"
            [customCss]="'re-dark'"
          ></app-audio-player>
        </ng-container>

        <ng-container
          *ngIf="
            !isAudio(image?.fileType?.toLowerCase()) &&
            isDoc(image?.fileType?.toLowerCase()) &&
            image.src &&
            isVideo(image?.fileType?.toLowerCase()) &&
            isImage(image?.fileType?.toLowerCase())
          "
        >
          <iframe
            [src]="image?.src"
            frameborder="0"
            class="re-iframe"
            (swipe)="onSwipe($event)"
          ></iframe>
        </ng-container>
      </ng-container>

      <ng-template #else_block>
        <div class="re-no-preview-available">
          <div class="re-box">
            <p>No preview available.</p>
            <button
              class="btn re-download"
              (click)="downloadFile(image)"
              *ngIf="!image?.directDownload"
            >
              <i class="fa fa-download"></i>
              Download
            </button>
            <a
              class="btn re-download"
              href="{{ _environment?.newS3Url + '' + image?.photo }}"
              *ngIf="image?.directDownload"
              download
            >
              <i class="fa fa-download"></i>
              Download
            </a>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <a
    class="carousel-control-prev"
    href="javascript:void(0)"
    role="button"
    data-slide="prev"
    (click)="changeSlide()"
    *ngIf="currentIndex > 0"
  >
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a
    class="carousel-control-next"
    href="javascript:void(0)"
    role="button"
    data-slide="next"
    (click)="changeSlide(true)"
    *ngIf="currentIndex < data['items']?.length - 1"
  >
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>

  <!-- MOBILE CONTROLS -->
  <div class="re-mobile-controls">
    <a (click)="changeSlide()" *ngIf="currentIndex > 0">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      (click)="changeSlide(true)"
      *ngIf="currentIndex < data['items']?.length - 1"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
