<mat-dialog-content>
  <div class="re-call-credit">
    <img
      class="re-close"
      src="assets/images/close-black-1.svg"
      alt="close"
      width="25"
      mat-dialog-close
    />
    <img class="img-fluid" src="assets/images/ai-call.svg" alt="ai-call" />
    <div class="re-title">AI Call Summary Needs a Credit Top-Up!</div>
    <div class="re-desc">
      To use this feature, you’ll need to add some credit to your AI Call
      Summary. Click “Buy Credit” below to head straight to the top-up page.
    </div>
    <button
      type="button"
      class="btn btn-block btn-credit"
      (click)="buyCredit()"
    >
      Buy Credit
    </button>
  </div>
</mat-dialog-content>
