// MODULES
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// UTILS
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LeadsService } from '../../../providers/leads/leads.service';

@Component({
  selector: 'app-open-lead-dialog',
  templateUrl: './open-lead-dialog.component.html',
  styleUrls: ['./open-lead-dialog.component.scss'],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class OpenLeadDialogComponent implements OnInit {
  messageConstant = MessageConstant;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<OpenLeadDialogComponent>,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private _leadsService: LeadsService,
    public _utilities: CommonFunctionsService
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    let param = {
      leadId: this.data.leadId,
    };

    this._loaderService.start();
    this._leadsService.updateOpenLead(param).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode == 200) {
          if (this._utilities.newCount > 0) {
            this._utilities.newCount = this._utilities.newCount - 1;
          }
          this.dialog.close(response?.data);
          this._toastrService.success(response?.data);
        } else {
          this._toastrService.error(response?.message, '');
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          this.dialog.close(this.data.details);
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
}
