<main>
  <app-topbar
    [moduleName]="moduleName"
    [isAddEnabled]="isAddEnabled"
    [isSearchBarEnabled]="isSearchBarEnabled"
    [isFilterEnabled]="isFilterEnabled"
    [isFilter]="isFilter"
    [addText]="addText"
    [isAddModal]="isAddModal"
    [secondaryData]="moduleList"
    [moduleData]="taskTemplate"
    [isModuleCountEnabled]="isModuleCountEnabled"
    (_emitFilterSearch)="setFilterSearch($event)"
    (_emitter)="eventChange($event)"
  >
  </app-topbar>

  <app-list-view
    #listView
    [header]="header"
    [data]="taskTemplate"
    [moduleId]="moduleId"
    [secondaryData]="moduleList"
    [moduleName]="moduleName"
    [paginationType]="paginationType"
    (_changePage)="changePage($event)"
    (_emitter)="eventChange($event)"
    (_changeSort)="changeSort($event)"
  ></app-list-view>
</main>
