export class MiscellaneousConstant {
  // EMAIL
  public static emailPattern =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  // EMAIL WITH CAPS
  public static emailPatternWithCaps =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // NAME
  public static namePattern = /^[a-zA-Z ]*$/;
  public static namePatternWithoutSpace = /^[a-zA-Z]+$/;
  public static namePatternWithNumber = /^[a-zA-Z0-9 ]*$/;

  // phone
  public static phonePattern =
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  //password
  public static passwordPattern =
    /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;

  //number
  public static numberOnly = /^[0-9]*$/;

  //year-Build
  public static yearBuild = /^([0-9]{4})$/;

  public static zipPattern = /^([0-9]{5})$/;

  public static phoneMask = [
    /[2-9]/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  public static colorCodes = [
    {
      backgroundColor: '#F3E0EC',
      borderColor: '#A41A71',
      color: '#A41A71',
    },
    {
      backgroundColor: '#ECF3E0',
      borderColor: '#8EA862',
      color: '#8EA862',
    },
    {
      backgroundColor: '#BEDBFF',
      borderColor: '#3466A5',
      color: '#3466A5',
    },
    {
      backgroundColor: '#DAFFC5',
      borderColor: '#4FA520',
      color: '#4FA520',
    },
    {
      backgroundColor: '#FFC5DA',
      borderColor: '#B75679',
      color: '#B75679',
    },
    {
      backgroundColor: '#E0F3EE',
      borderColor: '#269175',
      color: '#269175',
    },
    {
      backgroundColor: '#F3E3E0',
      borderColor: '#BC7366',
      color: '#BC7366',
    },
    {
      backgroundColor: '#F2E1BC',
      borderColor: '#A27A20',
      color: '#A27A20',
    },
    {
      backgroundColor: '#E2D3FC',
      borderColor: '#8C72B8',
      color: '#8C72B8',
    },
    {
      backgroundColor: '#FECBB6',
      borderColor: '#BF5F34',
      color: '#BF5F34',
    },
    {
      backgroundColor: '#DAF8D9',
      borderColor: '#73B970',
      color: '#73B970',
    },
    {
      backgroundColor: '#EED2CC',
      borderColor: '#E37259',
      color: '#E37259',
    },
    {
      backgroundColor: '#D2E0DF',
      borderColor: '#429E97',
      color: '#429E97',
    },
    {
      backgroundColor: '#FECCFB',
      borderColor: '#FA46F0',
      color: '#FA46F0',
    },
    {
      backgroundColor: '#EEFFE8',
      borderColor: '#80B234',
      color: '#80B234',
    },
    {
      backgroundColor: '#F2F3CE',
      borderColor: '#979D05',
      color: '#979D05',
    },
    {
      backgroundColor: '#F6E9FF',
      borderColor: '#9564BB',
      color: '#9564BB',
    },
    {
      backgroundColor: '#FFDBB2',
      borderColor: '#A76010',
      color: '#A76010',
    },
    {
      backgroundColor: '#B5FFFF',
      borderColor: '#1C8181',
      color: '#1C8181',
    },
    {
      backgroundColor: '#F1F6FC',
      borderColor: '#5587C7',
      color: '#5587C7',
    },
  ];

  public static paginationType = {
    INFINITE: 'INFINITE_SCROLL',
    STANDARD: 'STANDARD',
    ADVANCED: 'ADVANCED',
  };

  public static paginationLimit = {
    INFINITE: 30,
    STANDARD: 10,
  };

  public static roleIds = {
    '5d368c084c1ade1ddc2d94c2': '',
    '5d368bcb4c1ade1ddc2d94bd': '',
    '5e4e75f36d677809b449d077': '',
    '5d3684b04c1ade1ddc2d94b6': '',
    '5d368c014c1ade1ddc2d94c1': '',
    '5d368c274c1ade1ddc2d94c5': '',
    '5d6ca26d8557e423b86bd87f': '',
    '5d368bd74c1ade1ddc2d94be': '',
    '5d368bbd4c1ade1ddc2d94bc': '',
    '5d368c1c4c1ade1ddc2d94c4': '',
    '5d368c114c1ade1ddc2d94c3': '',
    '5d368bae4c1ade1ddc2d94bb': '',
    '5d368c404c1ade1ddc2d94c7': '',
    '5d368ba54c1ade1ddc2d94ba': '',
    '5d368be14c1ade1ddc2d94bf': '',
    '5d368c364c1ade1ddc2d94c6': '',
    '5d368bf04c1ade1ddc2d94c0': '',
    '5e4e75e86d677809b449d076': '',
    '62a0a77f3c9feb0a915fa618': '',
  };
  public static roleNames = {
    '5d368c084c1ade1ddc2d94c2': 'Accountant',
    '5d368bcb4c1ade1ddc2d94bd': 'Acquisition Manager',
    '5e4e75f36d677809b449d077': 'Acquisition Sales Manager',
    '5d3684b04c1ade1ddc2d94b6': 'Admin',
    '5d368c014c1ade1ddc2d94c1': 'Bookkeeper',
    '5d368c274c1ade1ddc2d94c5': 'Closing Coordinator',
    '5d6ca26d8557e423b86bd87f': 'Co-Owner',
    '5d368bd74c1ade1ddc2d94be': 'Disposition Manager',
    '5d368bbd4c1ade1ddc2d94bc': 'Lead Manager',
    '5d368c1c4c1ade1ddc2d94c4': 'Marketing Assistant',
    '5d368c114c1ade1ddc2d94c3': 'Marketing Manager',
    '5d368bae4c1ade1ddc2d94bb': 'Office Manager',
    '5d368c404c1ade1ddc2d94c7': 'Other Role',
    '5d368ba54c1ade1ddc2d94ba': 'Owner',
    '5d368be14c1ade1ddc2d94bf': 'Project Manager',
    '5d368c364c1ade1ddc2d94c6': 'Property Analyst',
    '5d368bf04c1ade1ddc2d94c0': 'Property Manager',
    '5e4e75e86d677809b449d076': 'Transaction Coordinator',
    '62a0a77f3c9feb0a915fa618': 'Real Estate Agent',
  };

  public static appointmentTypes = [
    { label: 'Seller Appointment', value: 0 },
    { label: 'Buyer Showings', value: 1 },
    { label: 'Contractor Walkthrough', value: 2 },
    { label: 'Other', value: 3 },
  ];

  public static appointmentSubTypes = [
    { label: 'In-Person Appointment', value: 0 },
    { label: 'Phone Appointment', value: 1 },
    { label: 'Drive By Appointment', value: 2 },
  ];

  public static appointmentStatus = [
    { label: 'No Answer', value: 0 },
    { label: 'Kept', value: 1 },
    { label: 'Not Kept', value: 2 },
  ];

  //http validation
  public static urlValidation =
    /^(http[s]?:\/\/){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;

  //http & https validation
  public static urlHttpCheck =
    /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z]{2,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
}
