import { Injectable, Injector } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class BugsnagService {
  constructor(private injector: Injector) {}

  upload(data, severity) {
    const location = this.injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    const obj = {
      ...data,
      url,
    };

    Bugsnag.notify(JSON.stringify(obj, null, 2), (event) => {
      event.severity = severity;
    });
  }
}
