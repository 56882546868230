<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      style="display: block !important"
      class="w-100 d-flex justify-content-between"
    >
      {{ data?.number_list }} List - {{ data?.item?.title }}
    </h2>
    <span matDialogClose mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<mat-dialog-content class="">
  <div class="container-fluid p-0">
    <div class="re-box">
      <div class="row mt-3" *ngIf="data.number_list === 'Members'">
        <ng-container *ngFor="let role of roles">
          <div
            class="col-12 col-md-4 col-sm-6 mb-3"
            *ngIf="
              data['item'].userAssignment[role?.value] &&
              data['item'].userAssignment[role?.value].length > 0
            "
          >
            <label class="re-label">
              {{ role?.label }}
            </label>

            <ul class="re-ulist">
              <li *ngFor="let user of data['item'].userAssignment[role?.value]">
                {{ getLabel(user) }}
              </li>
            </ul>
          </div>
        </ng-container>
      </div>

      <div class="row mt-3" *ngIf="data.number_list === 'Number'">
        <ng-container *ngFor="let role of roles">
          <div class="col-12 col-md-4 col-sm-6 mb-3">
            <label class="re-label">
              {{ role?.label }}
            </label>

            <ul class="re-ulist">
              <li>
                {{ _utilities.maskNumber(role?.value) }}
              </li>
            </ul>
          </div>
        </ng-container>

        <!-- NO RECORDS -->
        <ng-container *ngIf="!roles?.length">
          <div class="col-12">
            <p class="text-center mb-2 re-label">No records found.</p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center" style="padding-top: 14px !important">
  <button mat-stroked-button mat-dialog-close>Close</button>
</mat-dialog-actions>
