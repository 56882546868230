import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
} from '@angular/forms';
@Component({
  selector: 'app-filter-activitylog-dialog',
  templateUrl: './filter-activitylog-dialog.component.html',
  styleUrls: ['./filter-activitylog-dialog.component.scss']
})
export class FilterActivitylogDialogComponent implements OnInit {
  filterForm: FormGroup;
  submitted: boolean = false;
  user: any[] = [];
  filterFormReset: boolean = false;
  startDate = new Date();
  activityType: any[] = [
    { label: 'Outgoing SMS', value: 'outgoingSms' },
    { label: 'Incoming SMS', value: 'incomingSms' },
    { label: 'Outgoing Call', value: 'outgoingCall' },
    { label: 'Incoming Call', value: 'incomingCall' },
    { label: 'Outgoing MMS', value: 'outgoingMms' },
    { label: 'Incoming MMS', value: 'incomingMms' },
    // { label: 'SMS', value: 1 },
    { label: 'Rvm', value: 2 },
    { label: 'Email', value: 3 },
    // { label: 'Voice Call', value: 4 },
    // { label: 'Voice Email', value: 5 },
    // { label: 'Reminder', value: 6 },
    // { label: 'Appoinment', value: 7 },
    { label: 'Comment', value: 8 },
    // { label: 'Task', value: 9 },
    // { label: 'Status', value: 10 },
    // { label: 'Webform', value: 11 },
    // { label: 'Folder', value: 12 },
    // { label: 'Share Folder', value: 13 },
    // { label: 'File', value: 14 },
    // { label: 'Share File', value: 15 },
    // { label: 'Api', value: 16 },
    // { label: 'Drip Assign', value: 17 },
    // { label: 'Drip Unassign', value: 18 },
    // { label: 'Tags', value: 19 },
    // { label: 'Buyer', value: 20 },
    { label: 'E-sign', value: 21 },
    // { label: 'MMS', value: 22 },
    // { label: 'Incoming Lead', value: 23 },
  ];
  constructor(
  @Inject(MAT_DIALOG_DATA) public data: any,
  private _fb: FormBuilder,
  private dialogRef: MatDialogRef<FilterActivitylogDialogComponent>,
  ) { }

  ngOnInit(): void {
    let taggedusers = [];
    this.data.userListing.filter((x) => {
      taggedusers.push({ label: x?.firstName, value: x?._id });
    });
    this.user = taggedusers;
    this.user.sort((a, b) => a.label.localeCompare(b.label));
    this.filterForm = this._fb.group({
      userName: ['', []],
      type: ['', []],
      startDate: [''],
      endDate: [''],
    });
    console.log("this.data?.filterData  ===>>>",this.data?.filterData);
    this.filterForm.patchValue({
      ...this.data?.filterData,
    });
    if(this.data?.filterData?.userName || this.data?.filterData?.type || this.data?.filterData?.startDate || this.data?.filterData?.endDate)
    {
      this.filterFormReset = true;
    }
    if(this.data?.filterData?.startDate)
    {
      this.filterForm.patchValue({
        startDate:new Date(this.data?.filterData?.startDate)
      });
    }
    if(this.data?.filterData?.endDate)
    {
      this.filterForm.patchValue({
        endDate:new Date(this.data?.filterData?.endDate)
      });
    }
  }
  onSubmit() {
    this.submitted = true;
    if (this.filterForm.invalid) {
      return;
    }
    let obj = {
      ...this.filterForm.value,
    };
    if(!this.filterForm.value.userName || this.filterForm.value.userName == '')
    {
      delete obj['userName'];
    }
    if(this.filterForm.value.startDate)
    {
      obj['startDate'] = new Date(this.filterForm.value.startDate).getTime();
    }
    else
    {
      delete obj['startDate'];
    }
    if(this.filterForm.value.endDate)
    {
      obj['endDate'] = new Date(this.filterForm.value.endDate).getTime();
    }
    else
    {
      delete obj['endDate'];
    }
    if(!this.filterForm.value.type || this.filterForm.value.type == '')
    {
      delete obj['type'];
    }
    this.dialogRef.close(obj);
  }
  reset() {
    this.filterForm.reset();
    this.dialogRef.close({ reset: true });
  }
  onDate(event) {
    this.startDate = new Date(event);
    this.filterForm.patchValue({endDate:''});
  }
}
