import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// SERVICES
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { RvmService } from './../../../providers/rvm/rvm.service';

// UTILS
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';
import { ResponseModel } from 'src/app/utils/models/response';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-send-rvm',
  templateUrl: './send-rvm.component.html',
  styleUrls: ['./send-rvm.component.scss'],
})
export class SendRvmComponent implements OnInit {
  rvmCredit: number = 0;
  secondaryContact: any[] = [];
  sendTo: string = '';
  selectRvmRecording: string = '';
  sendFrom: string = '';
  rvmList: any[] = [];
  moduleId: string;
  subModuleId: string;

  messageConstant = MessageConstant;
  leadSourceCountryCode: string = environment.countryCode;

  constructor(
    private dialog: MatDialogRef<SendRvmComponent>,
    public _utilities: CommonFunctionsService,
    private _rvmService: RvmService,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data?.RVMBalance) {
      this.rvmCredit = this.data?.RVMBalance;
    }
  }

  ngOnInit(): void {
    const { contacts, phoneNumber, leadSource, leadId, moduleId } = this.data;
    this.secondaryContact = contacts;
    this.secondaryContact.sort((a, b) => a.name.localeCompare(b.name));
    this.sendTo = phoneNumber;
    this.moduleId = moduleId;
    this.subModuleId = leadId;
    this.leadSourceCountryCode =
      typeof leadSource === 'string'
        ? this._utilities.getCountryCode(leadSource)
        : '';
    this.sendFrom =
      typeof leadSource === 'string'
        ? this._utilities.maskNumber(leadSource)
        : '';
    this.getRVMList();
  }

  getRVMList() {
    let data = { page: 1, limit: 200 };
    this._rvmService.getRVM(data).subscribe(
      (response) => {
        if (response && response.statusCode == 200) {
          this.rvmList = response.data?.items ? response.data.items : [];
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  onSubmit() {
    if (this.selectRvmRecording == '') {
      this._toastrService.error(MessageConstant.invalidRVMUrl, 'Error');
      return false;
    } else if (this.sendTo == '') {
      this._toastrService.error(MessageConstant.invalidRVMContact, 'Error');
      return false;
    }

    this._loaderService.start();
    let data = {
      url: this.selectRvmRecording,
      from:
        this.leadSourceCountryCode +
        this._utilities.unMaskNumber(this.sendFrom),
      to: this.sendTo,
      moduleId: this.moduleId,
      subModuleId: this.subModuleId,
    };

    this._rvmService.sendRVM(data).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.dialog.close();
          this._toastrService.success(MessageConstant.sendRVMSuccess, '');
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
  changeValue(event) {
    if (event.target.value) {
      this.leadSourceCountryCode = this._utilities.getCountryCode(
        event.target.value
      );
      this.sendFrom = this._utilities.maskNumber(event.target.value);
    }
  }
}
