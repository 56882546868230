import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrl } from '../../utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  baseUrl = environment.baseUrl;

  constructor(private _commonFunction: CommonFunctionsService) {}

  getCommentData = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + 'getCommentData';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getCommunicationData = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dashboardUrl + 'getCommunicationData';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getDashboardData = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + 'getDashboardData';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getAllLeadAddress = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + 'getAllLeadAddress';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  setCommentPinned = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + 'setCommentPinned';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  setActivityBadge = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + 'setActivityBadge';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  clearActivityBadge = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + 'clearActivityBadge';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getActivityBadgeCount = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dashboardUrl + 'getActivityBadgeCount';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getLeadStatusData = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + 'getLeadStatusData';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getLeadSourceData = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + 'getLeadSourceData';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getOpenLeadCount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + 'getOpenLeadCount';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getCallSMSData = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + 'getCallSMSData';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getCallMade = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + 'getCallMade';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getConversionKPIData = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dashboardUrl + 'getConversionKPIData';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getTimelineKPIData = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + 'getTimelineKPIData';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getUnassignedLeadData = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dashboardUrl + 'getUnassignedLeadData';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getUnassignedLeadV2Data = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dashboardV2Url + 'getunAssignedLeadCount';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getUnclaimedLeadV2Data = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dashboardV2Url + 'getUnClamLeadCount';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getLeadMarkNTaskStatus = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dashboardV2Url + 'leadMarkNTaskStatus';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getNewLeadStats = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardV2Url + 'newLeadStats';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getCommunicationDataWeb = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dashboardUrl + 'getCommunicationDataWeb';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getCallResultData = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + 'getCallResultData';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getAllTaskNames = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.taskUrl + 'getTaskTitle';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getHotLeadData = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + 'getHotLeadCount';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getConversionTrendData = (data): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.dashboardUrl + 'getConversionTrendData';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getCallStats = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + 'getCallStats';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getLeaderboardData = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + 'getLeaderboard';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getLeadTaskCount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + 'getLeadTaskCount';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getBuyerInboxCount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardUrl + 'getBuyerInboxCount';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getContingenciesCount = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.dashboardV2Url + 'getContiCount';
    return this._commonFunction.globalPostService(endpoint, data);
  };
}
