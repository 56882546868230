import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// SERVICES
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SharedService } from 'src/app/shared/shared.service';
import { TaggingService } from 'src/app/providers/tagging/tagging.service';

// UTILS
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';
import { MessageConstant } from 'src/app/utils/message-constant';
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';

@Component({
  selector: 'add-edit-campaign',
  templateUrl: './edit-campaign.component.html',
  styleUrls: ['./edit-campaign.component.scss'],
})
export class EditCampaignComponent implements OnInit {
  messageConstant = MessageConstant;

  tabIndex: number = 0;

  general: any;
  drip: any;
  tag: any;

  unavailableColorIndex: any[] = [];
  unavailableColorIndexResponse: any[] = [];
  colorCodes = MiscellaneousConstant.colorCodes;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialog: MatDialogRef<EditCampaignComponent>,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _sharedService: SharedService,
    private _taggingService: TaggingService
  ) {}

  ngOnInit(): void {}

  getIndex(index) {
    this.tabIndex = index;
  }

  getUsedColorIndex() {
    this._loaderService.start();

    this._taggingService.getUsedTagsColor(this.data?.tagType).subscribe(
      (response) => {
        this._loaderService.stop();
        if (response && response.statusCode == 200) {
          this.unavailableColorIndex = response?.data;
          this.unavailableColorIndexResponse = [];
          let i, j;
          for (i = 0; i < this.colorCodes.length; i++) {
            this.unavailableColorIndexResponse.push({ _id: i, count: 0 });
            for (j = 0; j < this.unavailableColorIndex.length; j++) {
              if (this.unavailableColorIndex[j]._id == i) {
                this.unavailableColorIndexResponse[i].count =
                  this.unavailableColorIndex[j].count;
                break;
              }
            }
          }
          this.unavailableColorIndexResponse.sort(
            (a, b) => parseFloat(a.count) - parseFloat(b.count)
          );
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
      }
    );
  }

  emitter({ type, data }) {
    switch (type) {
      case `general-form`:
        this.general = data;
        break;
      case `drip-automation`:
        this.drip = data;
        break;
      case `tag-automation`:
        this.tag = data;
        break;
    }
  }

  async onCampaignSubmit() {
    if (this.drip?.isAutoAssignDrip) {
      if (
        this.drip?.isLeadCreatedManual == false &&
        this.drip?.isDripUnansCall == false &&
        this.drip?.isDripAnsCall == false &&
        this.drip?.isDripZap == false &&
        this.drip?.isDripWebForm == false &&
        this.drip?.isDripWebSite == false
      ) {
        this._toastrService.error('Please select drip');
        return;
      }

      if (
        this.drip?.isLeadCreatedManual &&
        this.drip?.manualLeadDripId == null
      ) {
        this._toastrService.error('Please select drip');
        return;
      }

      if (this.drip?.isDripUnansCall && this.drip?.unansDripId == null) {
        this._toastrService.error('Please select drip');
        return;
      }

      if (this.drip?.isDripAnsCall && this.drip?.ansDripId == null) {
        this._toastrService.error('Please select drip');
        return;
      }

      if (this.drip?.isDripZap && this.drip?.zapDripId == null) {
        this._toastrService.error('Please select drip');
        return;
      }

      if (this.drip?.isDripWebForm && this.drip?.webFormDripId == null) {
        this._toastrService.error('Please select drip');
        return;
      }

      if (this.drip?.isDripWebSite && this.drip?.webSiteDripId == null) {
        this._toastrService.error('Please select drip');
        return;
      }
    }

    if (this.tag?.isTagAutomation) {
      if (
        this.tag?.isTagLeadCreatedManual == false &&
        this.tag?.isTagAnsCall == false &&
        this.tag?.isTagUnansCall == false &&
        this.tag?.isTagZap == false &&
        this.tag?.isTagWebForm == false &&
        this.tag?.isTagWebSite == false
      ) {
        this._toastrService.error("Tags can't be blank");
        return;
      }

      if (
        this.tag?.isTagLeadCreatedManual &&
        this.tag?.leadCreatedManualTagIds.length == 0
      ) {
        this._toastrService.error("Tags can't be blank");
        return;
      }

      if (this.tag?.isTagAnsCall && this.tag?.ansCallTagIds.length == 0) {
        this._toastrService.error("Tags can't be blank");
        return;
      }

      if (this.tag?.isTagUnansCall && this.tag?.unansCallTagIds.length == 0) {
        this._toastrService.error("Tags can't be blank");
        return;
      }

      if (this.tag?.isTagZap && this.tag?.zapTagIds.length == 0) {
        this._toastrService.error("Tags can't be blank");
        return;
      }

      if (this.tag?.isTagWebForm && this.tag?.webFormTagIds.length == 0) {
        this._toastrService.error("Tags can't be blank");
        return;
      }
      
      if (this.tag?.isTagWebSite && this.tag?.webSiteTagIds.length == 0) {
        this._toastrService.error("Tags can't be blank");
        return;
      }

      if (this.tag?.isTagLeadCreatedManual) {
        this.tag.leadCreatedManualTagIds = await this.tagSubmitForm(1);
      }

      if (this.tag?.isTagAnsCall) {
        this.tag.ansCallTagIds = await this.tagSubmitForm(2);
      }

      if (this.tag?.isTagUnansCall) {
        this.tag.unansCallTagIds = await this.tagSubmitForm(3);
      }

      if (this.tag?.isTagZap) {
        this.tag.zapTagIds = await this.tagSubmitForm(4);
      }

      if (this.tag?.isTagWebForm) {
        this.tag.webFormTagIds = await this.tagSubmitForm(5);
      }
      if (this.tag?.isTagWebSite) {
        this.tag.webSiteTagIds = await this.tagSubmitForm(6);
      }
    }

    this._loaderService.start();

    let obj = {
      marketingTitle: this.data?.details?.marketingTitle,
      crmQuestionId: this.data?.details?.crmQuestionId,
      flowId: this.data?.details?.flow?._id,
      marketId: this.data?.details?.marketId,
      ...this.general,
      ...this.drip,
      ...this.tag,
      number: this.data?.details?.number,
    };

    this._sharedService.updateAssignment(obj).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode == 200) {
          this._toastrService.success(this.messageConstant.campaignUpdated);
          this._dialog.close(obj);
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  async tagSubmitForm(viewIndex) {
    return new Promise(async (resolve, reject) => {
      let existingTags = [];
      let newTags = [];

      if (viewIndex == 1) {
        if (this.tag?.leadCreatedManualTagIds.length == 0) {
          resolve([]);
          return;
        }
        existingTags = this.filterData(this.tag?.leadCreatedManualTagIds, '1');
        newTags = this.filterData(this.tag?.leadCreatedManualTagIds, '0');
      } else if (viewIndex == 2) {
        if (this.tag?.ansCallTagIds.length == 0) {
          resolve([]);
          return;
        }
        existingTags = this.filterData(this.tag?.ansCallTagIds, '1');
        newTags = this.filterData(this.tag?.ansCallTagIds, '0');
      } else if (viewIndex == 3) {
        if (this.tag?.unansCallTagIds.length == 0) {
          resolve([]);
          return;
        }
        existingTags = this.filterData(this.tag?.unansCallTagIds, '1');
        newTags = this.filterData(this.tag?.unansCallTagIds, '0');
      } else if (viewIndex == 4) {
        if (this.tag?.zapTagIds.length == 0) {
          resolve([]);
          return;
        }
        existingTags = this.filterData(this.tag?.zapTagIds, '1');
        newTags = this.filterData(this.tag?.zapTagIds, '0');
      } else if (viewIndex == 5) {
        if (this.tag?.webFormTagIds.length == 0) {
          resolve([]);
          return;
        }
        existingTags = this.filterData(this.tag?.webFormTagIds, '1');
        newTags = this.filterData(this.tag?.webFormTagIds, '0');
      } else if (viewIndex == 6) {
        if (this.tag?.webSiteTagIds.length == 0) {
          resolve([]);
          return;
        }
        existingTags = this.filterData(this.tag?.webSiteTagIds, '1');
        newTags = this.filterData(this.tag?.webSiteTagIds, '0');
      }

      const existingIds = existingTags.reduce((acc, d) => {
        acc.push(d._id);
        return acc;
      }, []);

      const tagsArr = newTags.reduce((acc, d) => {
        acc.push(d.label);
        return acc;
      }, []);

      let tagsToSave = await this.getTagsToSave(tagsArr);

      if (!tagsArr.length) {
        resolve(existingIds);
        return;
      }
      let obj = {
        tagsArr: tagsToSave,
        tagType: '1',
      };
      this._loaderService.start();
      this._taggingService.addTags(obj).subscribe(
        (response) => {
          this._loaderService.stop();
          let finalIds = existingIds;
          if (response) {
            for (let i = 0; i < response?.data?.length; i++) {
              if (response?.data[i] != null)
                finalIds.push(response?.data[i]?._id);
            }
          }
          resolve(finalIds);
          return;
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          resolve([]);
          return;
        }
      );
    });
  }

  filterData(data, mode) {
    let filteredData = [];
    if (mode == 1) {
      filteredData = data.filter(function (el) {
        return el._id != 'custom';
      });
    } else {
      filteredData = data.filter(function (el) {
        return el._id == 'custom';
      });
    }

    return filteredData;
  }

  getTagsToSave(tagsArr) {
    if (tagsArr.length) {
      let finalArr = [],
        newIndex;
      for (let i = 0; i < tagsArr.length; i++) {
        newIndex = -1;
        for (let j = 0; j < this.colorCodes.length; j++) {
          if (this.unavailableColorIndex.indexOf(j) < 0) {
            newIndex = j;
            this.unavailableColorIndex.push(newIndex);
            this.unavailableColorIndexResponse.push({
              _id: newIndex,
              count: 1,
            });
            break;
          }
        }
        if (newIndex < 0) {
          let minCount = 0,
            unavailableIndex = -1;
          for (let k = 0; k < this.unavailableColorIndexResponse.length; k++) {
            if (
              minCount == 0 ||
              minCount > parseInt(this.unavailableColorIndexResponse[k].count)
            ) {
              newIndex = this.unavailableColorIndexResponse[k]._id;
              unavailableIndex = k;
              minCount = parseInt(this.unavailableColorIndexResponse[k].count);
            }
          }
          if (unavailableIndex > -1)
            this.unavailableColorIndexResponse[unavailableIndex].count =
              this.unavailableColorIndexResponse[unavailableIndex].count + 1;
        }
        if (!newIndex) {
          newIndex = 0;
        }
        finalArr.push({ tag: tagsArr[i], colorIndex: newIndex });
      }
      return finalArr;
    } else {
      return [];
    }
  }
}
