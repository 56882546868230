import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { BaseUrl } from '../../utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { VersionConstant } from '../../utils/version-constant';
@Injectable({
  providedIn: 'root',
})
export class LeadsService {
  public baseUrl = environment.baseUrl;
  public listStackingUrl = environment.listStackingUrl;
  public token = localStorage.getItem('token');

  constructor(private _commonFunction: CommonFunctionsService) {}

  getLeads = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  addLeads = (fieldData: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + 'save';
    return this._commonFunction.globalPostService(endpoint, fieldData);
  };

  insertSingleProperty = (fieldData: any): Observable<any> => {
    const endpoint = this.listStackingUrl + 'liststacking/insertSingleProperty';
    return this._commonFunction.globalPostService(endpoint, fieldData);
  };

  viewProperty = (fieldData: any): Observable<any> => {
    const endpoint = this.listStackingUrl + 'liststacking/viewProperty';
    return this._commonFunction.globalPostService(endpoint, fieldData);
  };

  editSingleProperty = (fieldData: any): Observable<any> => {
    const endpoint = this.listStackingUrl + 'liststacking/editSingleProperty';
    return this._commonFunction.globalPostService(endpoint, fieldData);
  };

  updateLeadId = (fieldData: any): Observable<any> => {
    const endpoint = this.listStackingUrl + 'liststacking/updateLeadId';
    return this._commonFunction.globalPostService(endpoint, fieldData);
  };

  // leadUpdateFromLS = (fieldData: any): Observable<any> => {
  //   const endpoint = this.baseUrl + BaseUrl.leadUrl + 'leadUpdateFromLS';
  //   return this._commonFunction.globalPostService(endpoint, fieldData);
  // };

  deleteSingleProperty = (fieldData: any): Observable<any> => {
    const endpoint = this.listStackingUrl + 'liststacking/deleteSingleProperty';
    return this._commonFunction.globalPostService(endpoint, fieldData);
  };

  addTags = (fieldData): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.leadUrl + 'add-or-remove-tag/' + fieldData.leadId;
    return this._commonFunction.globalPostService(endpoint, {
      moduleId: fieldData.moduleId,
      tagIds: fieldData.tagIds,
      isTagAdd: fieldData.isTagAdd,
    });
  };

  addContact = (data, contactId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + 'edit';

    if (contactId) {
      endpoint += `/${contactId}`;
    }

    return this._commonFunction.globalPostService(endpoint, data);
  };

  editLead = (data, contactId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + 'edit';

    if (contactId) {
      endpoint += `/${contactId}`;
    }

    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteLead = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + 'deleteLead';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  statusUpdate = (data, mainStatusId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + 'statusUpdate';

    if (mainStatusId) {
      endpoint += `/${mainStatusId}`;
    }
    return this._commonFunction.globalPostService(endpoint, data);
  };

  leadInfo = (lead: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + 'detail';
    return this._commonFunction.globalPostService(endpoint, lead);
  };

  getQuestions = (lead: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + 'getQuestions';
    return this._commonFunction.globalPostService(endpoint, lead);
  };

  getCount = (lead: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + 'getCount';
    return this._commonFunction.globalPostService(endpoint, lead);
  };

  checkLead = (phoneNo: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + 'checkLead';
    return this._commonFunction.globalPostService(endpoint, phoneNo);
  };

  checkPropertyAddress = (address): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + 'checkPropertyAddress';
    return this._commonFunction.globalPostService(endpoint, address);
  };

  getPropertyFromMelissa = (address): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.melissaUrl + 'property';

    if (address) {
      endpoint += `?address=${address}`;
    }
    return this._commonFunction.globalGetService(endpoint);
  };

  getCampaignList = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.numberUrl + 'campaignlist';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getCrmQuestionList = (data: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.crmUrl + 'leadSource';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getRelatedLeads = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + 'related';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getTeams = (data, webLink?): Observable<any> => {
    let endpoint = this.baseUrl;

    if (!webLink) {
      endpoint += BaseUrl.leadUrl + 'teamDetailsData';
    } else {
      endpoint += BaseUrl.webFormUrl + 'teamDetails';
    }
    return this._commonFunction.globalPostService(
      endpoint,
      data,
      webLink ? this.getRequestHeaders(webLink) : {}
    );
  };

  updateTeam = (data, teamId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + 'teamUpdate';

    if (teamId) {
      endpoint += `/${teamId}`;
    }
    return this._commonFunction.globalPutService(endpoint, data);
  };

  getDeadReason = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.deadResonUrl + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  searchleads = (item): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + 'searchLeads';
    return this._commonFunction.globalPostService(endpoint, item);
  };

  leadSearch = (item): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrlV2Url + 'lead-search';
    return this._commonFunction.globalPostService(endpoint, item);
  };

  getProjectWithLeadId = (id): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + 'getProjectWithLeadId';
    return this._commonFunction.globalPostService(endpoint, id);
  };

  markHotLead = (data, leadId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + 'hotLead';

    if (leadId) {
      endpoint += `/${leadId}`;
    }

    return this._commonFunction.globalPutService(endpoint, data);
  };

  callForward = (data, leadId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + 'callForward';

    if (leadId) {
      endpoint += `/${leadId}`;
    }
    return this._commonFunction.globalPutService(endpoint, data);
  };

  getCallForwardNumber = (data, leadId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + 'callForwardNumber';

    if (leadId) {
      endpoint += `/${leadId}`;
    }
    return this._commonFunction.globalPutService(endpoint, data);
  };

  getFavoriteLeads = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + 'getFavoriteLead';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getSearchLeads = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + 'listSearchLeads';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getListLeadId = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + 'listLeadId';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  deleteInventory = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.inventoryUrl + 'deleteInventory';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateOpenLead = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + 'updateOpenLead';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  generateTokenForZap = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + 'generateTokenForZap';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getTokenForZap = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + 'getTokenForZap';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getExportLeads = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + 'getExportLeads';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  saveCalculations = (leadId, data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + 'add-calculator-details';

    if (leadId) {
      endpoint += `/${leadId}`;
    }
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getLeadList = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + 'getLeadList';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  numberChange = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + 'changeNumber';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  callAnswer = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + 'callAnswer';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  checkPropertyAddressWithUnitNo = (address): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.leadUrl + 'checkPropertyAddressWithUnitNo';
    return this._commonFunction.globalPostService(endpoint, address);
  };

  getDispoDetails = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + 'dispo_detail';
    return this._commonFunction.globalPostService(endpoint, obj);
  };
  getDispoInqueryDetails = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + '';
    return this._commonFunction.globalPostService(endpoint, obj);
  };
  getDispoInterstedBuyerDetails = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + '';
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  getMergeLeadList = (item): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + 'getMergeLeadList';
    return this._commonFunction.globalPostService(endpoint, item);
  };

  mergeLead = (item): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrl + 'mergeLead';
    return this._commonFunction.globalPostService(endpoint, item);
  };

  //v2 apis
  getTeamsV2 = (data, webLink?): Observable<any> => {
    let endpoint = this.baseUrl;

    if (!webLink) {
      endpoint += BaseUrl.leadUrlV2Url + 'teamDetailsData';
    } else {
      endpoint += BaseUrl.webFormUrl + 'teamDetails';
    }

    return this._commonFunction.globalPostService(
      endpoint,
      data,
      webLink ? this.getRequestHeaders(webLink) : {}
    );
  };

  addLeadsV2 = (fieldData: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrlV2Url + 'save';
    return this._commonFunction.globalPostService(endpoint, fieldData);
  };

  updateTeamV2 = (data, teamId): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrlV2Url + 'teamUpdate';

    if (teamId) {
      endpoint += `/${teamId}`;
    }

    return this._commonFunction.globalPutService(endpoint, data);
  };

  claimLead = (fieldData: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrlV2Url + 'lead-claim';
    return this._commonFunction.globalPostService(endpoint, fieldData);
  };

  getListStackSearchByPhone = (fieldData: any): Observable<any> => {
    const endpoint = this.listStackingUrl + 'liststacking/searchByPhone';
    return this._commonFunction.globalPostService(endpoint, fieldData);
  };

  getBuyersSearchByPhone = (fieldData: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyerUrl + 'list';
    return this._commonFunction.globalPostService(endpoint, fieldData);
  };
  getBuyersDetailsSearchById = (fieldData: any): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.buyerUrl + 'details';
    return this._commonFunction.globalPostService(endpoint, fieldData);
  };

  campaignTeamV2 = (data): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.leadUrlV2Url + 'leadStagWCampain';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  searchByPhone = (fieldData: any): Observable<any> => {
    const endpoint = this.listStackingUrl + 'liststacking/searchByPhone';
    return this._commonFunction.globalPostService(endpoint, fieldData);
  };

  getActiveTeam = (obj, webFormLink): Observable<any> => {
    let endpoint = this.baseUrl;
    if (webFormLink) {
      endpoint += BaseUrl.webFormV2Url + 'active-team';
    } else {
      endpoint += BaseUrl.buyNumberV2Url + 'active-team';
    }
    return this._commonFunction.globalPostService(
      endpoint,
      obj,
      this.getRequestHeaders(webFormLink)
    );
  };

  getOffers = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.offerV2Url + 'list';
    return this._commonFunction.globalPostService(endpoint, obj);
  };
  addOffer = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.offerV2Url + 'save';
    return this._commonFunction.globalPostService(endpoint, obj);
  };
  editOffer = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.offerV2Url + 'update';
    return this._commonFunction.globalPostService(endpoint, obj);
  };
  deleteOffer = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.offerV2Url + 'delete';
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  addAskQuestions = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.resimpliAiUrl + 'addAskQuestions';
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  removeAskQuestions = (obj): Observable<any> => {
    const endpoint =
      this.baseUrl + BaseUrl.resimpliAiUrl + 'removeAskQuestions';
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  getAskQuestions = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.resimpliAiUrl + 'getAskQuestions';
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  // SET HEADERS
  protected getRequestHeaders(
    val?
  ): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    let headers;
    let obj = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };

    if (val) {
      obj['webFormLink'] = val || localStorage.getItem('webFormLink');
    }

    headers = new HttpHeaders(obj);
    return { headers: headers };
  }
  // MOVE TO BUYER SERVICE
  moveToBuyerVendor = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.leadUrl + `move-to`;
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  //ADD EDIT LISTING DATA
  addEditListing = (obj, id?): Observable<any> => {
    let endpoint = this.baseUrl + BaseUrl.listingUrl;
    let lastUrl = 'add';
    if (id) {
      lastUrl = `edit/${id}`;
      endpoint += lastUrl;
      return this._commonFunction.globalPutService(endpoint, obj);
    }
    endpoint += lastUrl;
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  //GET LIST DETAIL
  getListing = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listingUrl + `detail`;
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  //DELETE LIST DETAIL
  deleteListing = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listingUrl + `deleteListing`;
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  //GET WEB SITE WEB FORM LIST
  getWebSiteWebFormList = (): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.websiteWebFormUrl + `list`;
    return this._commonFunction.globalGetService(endpoint);
  };

  //PUBLISH LISTING
  publishListing = (obj, id): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listingUrl + `createDomain/${id}`;
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  //PUBLISH LISTING
  unPublishListing = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listingUrl + `deleteObject`;
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  //UPDATE PUBLISH LISTING
  updatePublishListing = (obj, id): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listingUrl + `updateDomain/${id}`;
    return this._commonFunction.globalPutService(endpoint, obj);
  };
  //PUBLISH COUNT
  publishCount = (obj): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.listingUrl + `publish-count`;
    return this._commonFunction.globalPostService(endpoint, obj);
  };

  getPortfolioData = (data): Observable<any> => {
    const endpoint =
      this.baseUrl +
      VersionConstant.version +
      BaseUrl.listStacking +
      'getLinkedProperties';

    return this._commonFunction.globalPostService(endpoint, data);
  };

  getInterestRateData = (): Observable<any> => {
    let endpoint = `https://resimpli.s3.us-west-2.amazonaws.com/ls/interestRateFile.json`;
    return this._commonFunction.globalGetService(endpoint);
  };
}
