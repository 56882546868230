import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Router } from '@angular/router';

// UTILS
import { MessageConstant } from 'src/app/utils/message-constant';
import { ResponseModel } from '../../../utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { environment } from 'src/environments/environment';

// SERVICES
import { SOWService } from './../../../providers/sow/sow.service';
import { ContractorService } from './../../../providers/contractor/contractor.service';
import { UserService } from 'src/app/providers/user/user.service';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

// COMPONENTS
import { ConfirmationDialogComponent } from './../confirmation-dialog/confirmation-dialog.component';
import { SowDailogComponent } from '../../dialog/sow-dailog/sow-dailog.component';
import { directMailConstant } from '../../../utils/direct-mail-constant';
@Component({
  selector: 'app-sow-details',
  templateUrl: './sow-details.component.html',
  styleUrls: ['./sow-details.component.scss'],
})
export class SowDetailsComponent implements OnInit {
  allCategoryData: any[] = directMailConstant.allCategoryData;
  sowDetail: any = {};
  loadingDetail: boolean = true;
  shareLinkStatus: boolean = false;
  shareLink: string = '';
  isCopied: boolean = false;
  contractorDetail: any = {};
  selectedArray: any[] = [];
  s3Url: string = environment.newS3Url;
  baseUrl: string = environment.baseUrl;
  appUrl: string = environment.appUrl;
  pdfLink: string = '';
  propertyInfoList: any = {};
  mainUserData: any = {};
  address: string = '';
  userData: any = {};
  diffDays: number = 0;
  currentShareStatus: boolean = false;
  dialogRef: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public utilities: CommonFunctionsService,
    private _sowDialogRef: MatDialogRef<SowDetailsComponent>,
    private _dialog: MatDialog,
    public router: Router,
    public _sowService: SOWService,
    private _userService: UserService,
    private _loaderService: NgxUiLoaderService,
    public _toastrService: ToastrService,
    private _contractorService: ContractorService
  ) {}

  ngOnInit(): void {
    this.getSOWDetail();
  }

  getSOWDetail() {
    
    let obj = { sowid: this.data?.details?._id };
    this.propertyInfoList = this.data?.leadData;
    this.mainUserData = this.data?.userData;
    
    this.address = this.propertyInfoList?.address;
    this.loadingDetail = true;
    this._sowService.getSOWDetail(obj).subscribe(
      (response: ResponseModel) => {
        if (response?.statusCode && response.statusCode == 200) {
          this.sowDetail = response?.data;
          let date1 = new Date(this.sowDetail?.startdate);
          let date2 = new Date(this.sowDetail?.enddate);
          let dtime = date2.getTime() - date1.getTime();
          this.diffDays = dtime / (1000 * 3600 * 24);
          this.updateCategory();
        
          this.getContractorDetail(response?.data?.contractorid);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        this.loadingDetail = false;
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }

  updateCategory() {
    let key1, key2, key3;
    for (let i = 0; i < this.allCategoryData.length; i++) {
      key1 = this.allCategoryData[i]._id;
      for (let j = 0; j < this.allCategoryData[i].subCategory.length; j++) {
        key2 = this.allCategoryData[i].subCategory[j]._id;
        for (
          let k = 0;
          k < this.allCategoryData[i].subCategory[j].subSubCategory.length;
          k++
        ) {
          key3 = this.allCategoryData[i].subCategory[j].subSubCategory[k]._id;
          if (
            this.sowDetail?.items[key1] &&
            this.sowDetail?.items[key1][key2] &&
            this.sowDetail?.items[key1][key2][key3] &&
            this.sowDetail?.items[key1][key2][key3].status == true
          ) {
            this.allCategoryData[i].subCategory[j].subSubCategory[k].status =
              this.sowDetail?.items[key1][key2][key3].status;
            this.allCategoryData[i].subCategory[j].subSubCategory[
              k
            ].description = this.sowDetail?.items[key1][key2][key3].description;
            this.allCategoryData[i].subCategory[j].subSubCategory[k].imageurl =
              this.sowDetail?.items[key1][key2][key3].imageurl;
            this.allCategoryData[i].subCategory[j].subSubCategory[k].quantity =
              this.sowDetail?.items[key1][key2][key3].quantity;
            this.allCategoryData[i].subCategory[j].subSubCategory[
              k
            ].measurement = this.sowDetail?.items[key1][key2][key3].measurement;
            this.allCategoryData[i].subCategory[j].subSubCategory[k].unitprice =
              this.sowDetail?.items[key1][key2][key3].unitprice;
            this.allCategoryData[i].subCategory[j].subSubCategory[k].total =
              this.sowDetail?.items[key1][key2][key3].total;
            //updating selectedArray
            this.setSelectedArray(i, j, k);
          }
        }
      }
    }
  }

  setSelectedArray(i, j, k) {
    let key1 = this.allCategoryData[i]._id,
      key2 = this.allCategoryData[i].subCategory[j]._id,
      flag,
      indX;
    let foundI = this.checkIfKeyExist(key1);
    if (foundI >= 0) {
      flag = 0;
      for (indX = 0; indX < this.selectedArray[foundI].subCat.length; indX++) {
        if (this.selectedArray[foundI]['subCat'][indX]._id == key2) {
          flag = 1;
          break;
        }
      }
      if (flag == 1) {
        this.selectedArray[foundI]['subCat'][indX].subSubCat.push(
          this.allCategoryData[i].subCategory[j].subSubCategory[k]
        );
      } else {
        this.selectedArray[foundI].subCat.push({
          _id: key2,
          name: this.allCategoryData[i].subCategory[j].name,
          subSubCat: [this.allCategoryData[i].subCategory[j].subSubCategory[k]],
        });
      }
    } else {
      this.selectedArray.push({
        _id: key1,
        name: key1.toUpperCase(),
        subCat: [
          {
            _id: key2,
            name: this.allCategoryData[i].subCategory[j].name,
            subSubCat: [
              this.allCategoryData[i].subCategory[j].subSubCategory[k],
            ],
          },
        ],
      });
    }
  }

  checkIfKeyExist(key1) {
    let flag = -1;
    for (let i = 0; i < this.selectedArray.length; i++) {
      if (this.selectedArray[i]._id == key1) {
        flag = i;
        break;
      }
    }
    return flag;
  }

  getContractorDetail(id) {
    let obj = { id: id };
    this.loadingDetail = true;
    this._contractorService.getContractorsWithId(obj).subscribe(
      (response: ResponseModel) => {
        
        this.loadingDetail = false;
        if (response?.statusCode && response.statusCode == 200) {
          this.contractorDetail = response?.data?.result;
          let dAdd = this.contractorDetail?.address1
            ? this.contractorDetail?.address1 + ','
            : '';
          dAdd =
            dAdd +
            (this.contractorDetail?.city
              ? this.contractorDetail?.city + ','
              : '');
          dAdd =
            dAdd +
            ' ' +
            (this.contractorDetail?.state
              ? this.contractorDetail?.state + ' '
              : '');
          dAdd =
            dAdd +
            (this.contractorDetail?.zipcode
              ? this.contractorDetail?.zipcode + ' '
              : '');
          dAdd = dAdd.substring(0, dAdd.length - 1);
          this.contractorDetail['displayAddress'] = dAdd;
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        this.loadingDetail = false;
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }

  getUserDetails(fromShare?) {
    this._loaderService.start();
    this._userService.getUserDetails({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response?.statusCode && response?.statusCode == 200) {
          this.userData = response?.data;
          if (fromShare) {
            this.printSOW(true);
          } else {
            this.printSOW(false);
          }
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        this.loadingDetail = false;
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }

  printSOW(sharing?) {
    let obj = {
      sowid: this.data?.details?._id,
      address: this.address,
      sharing: sharing ? this.currentShareStatus : false,
      timezoneoffset: this.userData?.timezoneOffset,
    };
    this.loadingDetail = true;
    this.pdfLink = '';

    this._sowService.generatePDF(obj).subscribe(
      (response: ResponseModel) => {
        this.loadingDetail = false;

        if (sharing) {
          if (this.currentShareStatus) {
            this.shareLinkStatus = true;
            this.shareLink =
              this.appUrl + 'public/share-sow/' + this.data?.details?._id;
            this.copyToClipboard(this.shareLink);
            this._toastrService.success('SOW Shared Successfully');
            this._toastrService.success('Linked copied to clipboard.');
            this.sowDetail.sharing = true;
          } else {
            this.shareLinkStatus = false;
            this.shareLink = '';
            this._toastrService.success('SOW Unshared Successfully');
            this.sowDetail.sharing = false;
          }
        } else {
          this.photoPreview(response?.data?.fname);
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        this.loadingDetail = false;

        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(MessageConstant.unknownError, '');
        }
      }
    );
  }

  photoPreview = (photoPath): void => {
    var encodedPath = photoPath.replace(/\+/g, '%2B');
    this.pdfLink = this.s3Url + encodedPath;
    window.open(this.pdfLink);
  };

  shareSOW(mode) {
    let shareMode = mode == '1' ? 'unshare' : 'share';
    this.currentShareStatus = mode == '1' ? false : true;
    this.dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: {
        header: 'Confirmation',
        type: 'Leads',
        text: `Do you want to ${shareMode} this <b>SOW?</b>`,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getUserDetails(true);
      }
    });
  }

  onEditSow(data, sowDetail?) {
    this.dialogRef = this._dialog.open(SowDailogComponent, {
      data: {
        type: data,
        details: sowDetail,
        id: sowDetail?._id,
        moduleId: this.data?.moduleId,
        inventoryId: sowDetail?.propertyid,
      },
      width: '820px',
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      this._sowDialogRef.close();
    });
  }

  copyToClipboard(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.isCopied = true;
  }
}
