import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResponseModel } from 'src/app/utils/models/response';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorModel } from 'src/app/utils/models/error';
import { SharedService } from '../../shared.service';
import { MessageConstant } from 'src/app/utils/message-constant';

@Component({
  selector: 'app-add-edit-links',
  templateUrl: './add-edit-links.component.html',
  styleUrls: ['./add-edit-links.component.scss'],

  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class AddEditLinksComponent implements OnInit {
  addEditlinkForm: FormGroup;
  submitted: boolean = false;
  messageConstant = MessageConstant;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddEditLinksComponent>,
    private formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private _loaderService: NgxUiLoaderService,
    private _sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.addEditlinkForm = this.formBuilder.group({
      title: ['', Validators.required],
      hyperLink: ['', Validators.required],
    });

    if (this.data.action == 'Edit') {
      this.addEditlinkForm.patchValue({
        title: this.data.details?.title,
        hyperLink: this.data.details?.src,
      });
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.addEditlinkForm.controls[controlName].hasError(errorName);
  };

  onSubmitLink() {
    this.submitted = true;
    if (this.addEditlinkForm.invalid) {
      return;
    }

    const { moduleId, subModuleId } = this.data;
    const { title, hyperLink } = this.addEditlinkForm.value;

    this._loaderService.start();
    if (this.data.action == 'Add') {
      let myObj = {
        title: title,
        src: hyperLink,
        moduleId: moduleId,
        subModuleId: subModuleId,
        isSharing: false,
        isEnabled: true,
      };
      this._sharedService.addLink(myObj).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this.dialogRef.close(response.data);
            this._toastrService.success(response.message, '');
          }
          this._loaderService.stop();
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(
              this.messageConstant.unknownError,
              ''
            );
          }
        }
      );
    } else if (this.data.action == 'Edit') {
      let myObj = {
        title: title,
        src: hyperLink,
        isSharing: true,
        isEnabled: true,
      };
      this._sharedService.updateLink(myObj, this.data.details?._id).subscribe(
        (response: ResponseModel) => {
          if (response.statusCode == 200) {
            this.dialogRef.close(response.data);
            this._toastrService.success(response.message, '');
          }
          this._loaderService.stop();
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(
              this.messageConstant.unknownError,
              ''
            );
          }
        }
      );
    }
  }
  onClose() {}
}
