import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// COMPONENTS
import { ListViewComponent } from 'src/app/shared/list-view/list-view.component';

// UTILS
import { MessageConstant } from 'src/app/utils/message-constant';
import { environment } from 'src/environments/environment';
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';

// SERVICES
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared/shared.service';
import { TaskService } from 'src/app/providers/task/task.service';

@Component({
  selector: 'app-task-template',
  templateUrl: './task-template.component.html',
  styleUrls: ['./task-template.component.scss'],
})
export class TaskTemplateComponent implements OnInit {
  @ViewChild('listView') listView: ListViewComponent;
  moduleName: string = 'Task Templates';
  moduleId: string = '5faa63fdfd20cd581703d255';
  sortFiles: string = environment.pagination.defaultSort;
  currentPage: number = 1;
  currentLimit: number = MiscellaneousConstant.paginationLimit.STANDARD;
  paginationType: string = MiscellaneousConstant.paginationType.STANDARD;
  applyFilter: any = {
    taskDue: [true, false, false, false],
    userType: 'assign_to_me',
    taskTypeOption: '',
  };
  isSearchEnabled: boolean = true;
  isFilterEnabled: boolean = false;
  isFilter: boolean = false;
  isAddEnabled: boolean = true;
  isSearchBarEnabled: boolean = true;
  isAddModal: boolean = true;
  isModuleCountEnabled: boolean = true;
  data: any[] = [];
  header: any[] = [];
  taskTemplate: any = [];
  filterData: any = {};
  searchText: string = '';
  applySort: any;
  moduleList: any[] = [];
  addText: string = 'Add New';
  messageConstant = MessageConstant;
  dialogRef: any;
  constructor(
    private _taskService: TaskService,
    private toastr: ToastrService,
    private _loaderService: NgxUiLoaderService,
    public _sharedService: SharedService
  ) {
    this.listTaskTemplate(true);
  }

  ngOnInit(): void {
    this.header = [
      {
        label: 'Template Name',
        key: 'templateName',
        isClickExists: true,
        event: 'EDIT_TASK_TEMPLATE',
        style: { color: '#008080' },
        class: 'new_template_class',
        sort: { type: this.sortFiles },
      },
      {
        label: 'Date',
        key: 'createdAt',
        pipe: 'DATE',
        sort: { type: this.sortFiles },
      },
      {
        label: 'Action',
        key: 'action',
        options: [
          {
            type: 'EDIT_TASK_TEMPLATE',
            icon: '/assets/images/edit.svg',
            activeIcon: '/assets/images/editActive.svg',
          },
          {
            type: 'DELETE_TASK_TEMPLATE',
            icon: '/assets/images/delete.svg',
            activeIcon: '/assets/images/deleteActive.svg',
          },
        ],
      },
    ];
  }

  listTaskTemplate = (bool?) => {
    if (bool) {
      this.currentPage = 1;
    }
    let payload = {
      page: this.currentPage,
      limit: this.currentLimit,
    };
    if (this.applySort) {
      payload = { ...payload, ...this.applySort };
    }
    if (this.searchText != '') {
      payload['searchText'] = this.searchText;
    }
    this._loaderService.start();
    this._taskService.listTaskTemplate(payload).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.taskTemplate.count = response.data?.count;

          if (bool) {
            this.taskTemplate = response.data;

            if (
              this.paginationType ===
              MiscellaneousConstant.paginationType.STANDARD
            ) {
              this.listView.pagination.count = response?.data?.count || 0;
              this.listView.pagination.setPage(1, true);
            }
          } else {
            // STANDARD PAGINATION
            if (
              this.paginationType ===
              MiscellaneousConstant.paginationType.STANDARD
            ) {
              this.taskTemplate.items = response.data.items;
            }
            this.listView.pagination.setPage(this.currentPage, true);

            // INFINITE PAGINATION
            if (
              this.paginationType ===
              MiscellaneousConstant.paginationType.INFINITE
            ) {
              this.taskTemplate.items = this.taskTemplate.items
                ? [...this.taskTemplate.items, ...response.data.items]
                : response.data.items;
            }
          }

          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this.toastr.error(error.message, '');
        } else {
          this.toastr.error(this.messageConstant.unknownError, '');
        }
      }
    );
  };
  changePage(event) {
    if (event === this.currentPage) {
      return;
    }
    this.currentPage = event;
    this.listTaskTemplate();
  }
  eventChange({ module, type, details }) {
    if (module !== 'taskTemplate') {
      return;
    }
    if (type == 'delete') {
      this.deletetaskTemplate(details);
      return;
    }
    this.listTaskTemplate();
  }

  deletetaskTemplate(details) {
    let obj = {
      taskTemplateId: details?._id,
    };
    this._taskService.deleteTaskTemplate(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this._loaderService.stop();
          this.listTaskTemplate();
          this.toastr.success(this.messageConstant.taskTemplateDeletedSuccess);
        }
      },
      (err) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this.toastr.error(error.message, '');
        } else {
          this.toastr.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  changeSort(obj) {
    this.applySort = obj;
    this.listTaskTemplate(true);
  }
  setFilterSearch(searchText) {
    this.searchText = searchText;
    this.listTaskTemplate(true);
  }
}
