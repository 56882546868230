import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// UTILS
import { MessageConstant } from 'src/app/utils/message-constant';
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';

// SERVICES
import { SharedService } from '../../shared.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

@Component({
  selector: 'app-send-bulk-sms-dialog',
  templateUrl: './send-bulk-sms-dialog.component.html',
  styleUrls: ['./send-bulk-sms-dialog.component.scss'],
})
export class SendBulkSmsDialogComponent implements OnInit {
  public items: string[] = ['First_Name', 'Last_Name'];

  smsForm: FormGroup;
  submitted: boolean = false;
  isCampaignExists: boolean = false;
  messageConstant = MessageConstant;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<SendBulkSmsDialogComponent>,
    private _fb: FormBuilder,
    private _sharedService: SharedService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    public _utilities: CommonFunctionsService
  ) {}

  ngOnInit(): void {
    this.smsForm = this._fb.group({
      campaignName: ['', [Validators.required]],
      message: ['', [Validators.required]],
      fromNumber: ['', [Validators.required]],
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.smsForm.controls[controlName].hasError(errorName);
  };

  checkCampaign() {
    const { campaignName } = this.smsForm.value;
    if (!campaignName) {
      this.isCampaignExists = false;
      return;
    }

    this._sharedService.checkCampaign({ campaignName }, true).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          this.isCampaignExists = true;
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          if (!err?.error?.data) {
            this.isCampaignExists = false;
          } else {
            this._toastrService.error(
              err.error.message || this.messageConstant.unknownError,
              ''
            );
          }
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    if (this.smsForm.invalid || this.isCampaignExists) {
      return;
    }

    this.smsForm.value.message = this.smsForm.value.message
      .replace(/[^\x00-\x7F]/g, '')
      .replace(/&#xFEFF;/g, '');

    const { message, fromNumber, campaignName } = this.smsForm.value;
    const data = this.data.items
      .map((x) => {
        if (x?.contactNumber) {
          let obj = {
            message: message
              .replace(
                '@First_Name',
                this._utilities.capitalizeName(x?.firstName) || ''
              )
              .replace(
                '@Last_Name',
                this._utilities.capitalizeName(x?.lastName) || ''
              ),
            fromNumber,
            toNumber: x?.contactNumber,
            subModuleId: x?._id,
          };
          return obj;
        }
      })
      .filter((x) => x);

    let obj: any = {
      toSend: this.data?.selectActionType === 'All' ? [] : data,
      moduleId: this.data.moduleId,
      campaignName,
      message,
      fromNumber,
      filterData: { contactNumber: { value: 'yes', operator: 'is' } },
      isFilter: this.data?.selectActionType !== 'All' ? false : true,
      searchStr: this.data.searchStr,
    };

    if (this.data?.isFilter) {
      obj = {
        ...obj,
        filterData: { ...obj.filterData, ...this.data?.filterObj?.filterData },
      };
    }

    delete obj['limit'];
    delete obj['page'];

    this._loaderService.start();
    this._sharedService.sendBulkSms(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode === 200) {
          this._loaderService.stop();
          this._toastrService.success(response.message);
          this._dialogRef.close('sms');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
}
