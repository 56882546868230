<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      {{ data?.subModule }}
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<mat-dialog-content class="re-typography">
  <div
    class="re-note-address"
    *ngIf="data?.address || data?.mainStatusTitle || data?.type"
  >
    <span class="re-txt1" *ngIf="data?.address">{{
      _commonFunctionsService.formatAddress(data?.address, data?.unitNo)
    }}</span>
    <span class="re-txt2" *ngIf="data?.mainStatusTitle && data?.type"
      >{{ data?.type }} > {{ data?.mainStatusTitle }}</span
    >
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-12">
        <div class="form-group">
          <label for="inputCampaign4">Tags To Add</label>
          <mat-form-field class="re-chip-list">
            <mat-chip-list #chipList aria-label="Tag selection">
              <mat-chip
                *ngFor="let tag of tags; let tagIndex = index"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(tagIndex)"
              >
                {{ tag.label }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>

              <input
                #tagInput
                [formControl]="tagCtrl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)"
                (input)="onSearchChange($event.target.value)"
              />
            </mat-chip-list>
            <mat-placeholder
              [ngClass]="
                tags.length > 0
                  ? 'placeholder removePlaceHolder'
                  : 'placeholder'
              "
              >Type tag name and press enter or comma to create a new
              tag</mat-placeholder
            >
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="selected($event)"
            >
              <ng-container *ngFor="let tag of filteredTags | async">
                <mat-option *ngIf="isVisible(tag)" [value]="tag">
                  {{ tag.label }}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
          <label
            style="font-weight: normal; font-size: 13px"
            class="mt-1"
            for="inputCampaign4"
            *ngIf="isShowPlaceHolder"
            >Press Enter or comma to create a new tag</label
          >
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" style="padding-top: 14px !important">
  <button mat-raised-button [mat-dialog-close]="false" class="re-cancel-btn">
    Cancel
  </button>
  <button
    (click)="submitForm()"
    mat-raised-button
    cdkFocusInitial
    class="submitBtn"
    [disabled]="tags.length == 0"
  >
    {{ data?.subModule }}
  </button>
</mat-dialog-actions>
