<div class="re-notification">
  <div class="re-notification-header">
    <div class="row">
      <div class="col-md-8">
        <h1>Notifications ({{ unreadCount || 0 }} Unread)</h1>
      </div>
      <div class="col-md-4 right-div">
        <a href="javascript:void(0);" (click)="markAsReadNotification()"
          >Mark all as Read</a
        >
      </div>
    </div>
  </div>

  <ul class="nav nav-tabs" role="tablist">
    <li role="presentation" [ngClass]="{ active: tab == 'all' }">
      <a
        href="javascript:;"
        (click)="onActive('all')"
        [ngClass]="{ active: tab == 'all' }"
        aria-controls="all"
        role="tab"
        data-toggle="tab"
      >
        <img
          src="{{
            tab == 'all'
              ? './assets/images/notification-tab-bell-active.png'
              : './assets/images/notification-tab-bell.png'
          }}"
        />
        All
      </a>
    </li>
    <li role="presentation" [ngClass]="{ active: tab == 'missed-calls' }">
      <a
        href="javascript:;"
        (click)="onActive('missed-calls')"
        [ngClass]="{ active: tab == 'missed-calls' }"
        aria-controls="missed-calls"
        role="tab"
        data-toggle="tab"
      >
        <img
          src="{{
            tab == 'missed-calls'
              ? './assets/images/notification-tab-missed-active.png'
              : './assets/images/notification-tab-missed.png'
          }}"
        />
        Missed Calls
      </a>
    </li>

    <li role="presentation" [ngClass]="{ active: tab == 'tasks' }">
      <a
        href="javascript:;"
        (click)="onActive('tasks')"
        [ngClass]="{ active: tab == 'tasks' }"
        aria-controls="tasks"
        role="tab"
        data-toggle="tab"
      >
        <img
          src="{{
            tab == 'tasks'
              ? './assets/images/notification-tab-task-active.png'
              : './assets/images/notification-tab-task.png'
          }}"
        />
        Tasks
      </a>
    </li>

    <li role="presentation" [ngClass]="{ active: tab == 'sms' }">
      <a
        href="javascript:;"
        (click)="onActive('sms')"
        [ngClass]="{ active: tab == 'sms' }"
        aria-controls="sms"
        role="tab"
        data-toggle="tab"
      >
        <img
          src="{{
            tab == 'sms'
              ? './assets/images/notification-tab-sms-active.png'
              : './assets/images/notification-tab-sms.png'
          }}"
        />
        SMS
      </a>
    </li>
    <li role="presentation" [ngClass]="{ active: tab == 'mentions' }">
      <a
        href="javascript:;"
        (click)="onActive('mentions')"
        [ngClass]="{ active: tab == 'mentions' }"
        aria-controls="mentions"
        role="tab"
        data-toggle="tab"
      >
        <img
          src="{{
            tab == 'mentions'
              ? './assets/images/notification-tab-menstion-active.png'
              : './assets/images/notification-tab-menstion.png'
          }}"
        />
        Mentions
      </a>
    </li>
  </ul>
  <div class="tab-content">
    <!-- LEAD DETAILS -->
    <div
      role="tabpanel"
      class="tab-pane"
      [ngClass]="{ active: tab == 'all' }"
      id="all"
    >
      <div
        class="re-logs-list re-comments-data"
        infiniteScroll
        [infiniteScrollDistance]="3"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="onScroll()"
      >
        <div class="tab-loader" *ngIf="tab == 'all' && showTabLoader">
          <img src="./assets/images/loading.gif" />
        </div>
        <app-tab-content
          [currentTab]="tab"
          [allNotifications]="allNotifications"
          (_callbackFunction)="callBackFunction($event)"
        ></app-tab-content>
      </div>
    </div>
    <div
      role="tabpanel"
      class="tab-pane"
      [ngClass]="{ active: tab == 'missed-calls' }"
      id="missed-calls"
    >
      <div
        class="re-logs-list re-comments-data"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="onScroll()"
      >
        <div class="tab-loader" *ngIf="tab == 'missed-calls' && showTabLoader">
          <img src="./assets/images/loading.gif" />
        </div>
        <app-tab-content
          [currentTab]="tab"
          [allNotifications]="allNotifications"
          (_callbackFunction)="callBackFunction($event)"
        ></app-tab-content>
      </div>
    </div>

    <div
      role="tabpanel"
      class="tab-pane"
      [ngClass]="{ active: tab == 'tasks' }"
      id="tasks"
    >
      <div
        class="re-logs-list re-comments-data"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="onScroll()"
      >
        <div class="tab-loader" *ngIf="tab == 'tasks' && showTabLoader">
          <img src="./assets/images/loading.gif" />
        </div>
        <app-tab-content
          [currentTab]="tab"
          [allNotifications]="allNotifications"
          (_callbackFunction)="callBackFunction($event)"
        ></app-tab-content>
      </div>
    </div>

    <div
      role="tabpanel"
      class="tab-pane"
      [ngClass]="{ active: tab == 'sms' }"
      id="sms"
    >
      <div
        class="re-logs-list re-comments-data"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="onScroll()"
      >
        <div class="tab-loader" *ngIf="tab == 'sms' && showTabLoader">
          <img src="./assets/images/loading.gif" />
        </div>
        <app-tab-content
          [currentTab]="tab"
          [allNotifications]="allNotifications"
          (_callbackFunction)="callBackFunction($event)"
        ></app-tab-content>
      </div>
    </div>
    <div
      role="tabpanel"
      class="tab-pane"
      [ngClass]="{ active: tab == 'mentions' }"
      id="mentions"
    >
      <div
        class="re-logs-list re-comments-data"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="onScroll()"
      >
        <div class="tab-loader" *ngIf="tab == 'mentions' && showTabLoader">
          <img src="./assets/images/loading.gif" />
        </div>
        <app-tab-content
          [currentTab]="tab"
          [allNotifications]="allNotifications"
          (_callbackFunction)="callBackFunction($event)"
        ></app-tab-content>
      </div>
    </div>
  </div>
</div>
