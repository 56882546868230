<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      {{ data?.header }}
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<mat-dialog-content class="re-maindiv">
  <ng-container>
    <div class="container" *ngIf="data?.taskCount">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <h5 class="re-main-title">PENDING TASKS</h5>
            <label class="re-labels-div"
              >There are some pending tasks for this lead. What would you like
              to do with the pending tasks?</label
            >
            <div class="radiolist">
              <label class="radiobtn">
                Mark everyone's pending tasks as completed
                <input
                  type="radio"
                  name="taskCompleted"
                  value="false"
                  (click)="chooseTaskAssignment('ALL_COMPLETED')"
                />
                <span class="checkmark"></span>
              </label>
              <label class="radiobtn">
                Leave all pending task as pending
                <input
                  type="radio"
                  name="taskCompleted"
                  value="true"
                  (click)="chooseTaskAssignment('NONE')"
                  checked
                />
                <span class="checkmark"></span>
              </label>
              <label class="radiobtn">
                Mark only my pending task as completed
                <input
                  type="radio"
                  name="taskCompleted"
                  value="true"
                  (click)="chooseTaskAssignment('MY_COMPLETED')"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="data?.type?.title != 'inventory' && data?.type?.title != 'sold'">
      <div class="container" *ngIf="data?.lead?.dripId">
        <hr />
        <div class="row">
          <div class="col-12 col-md-12 col-sm-12">
            <div class="form-group">
              <h5 class="re-main-title">DRIP CAMPAIGN</h5>
              <label class="re-labels-div"
                ><b>"{{ data?.lead?.dripData?.name }}"</b> drip is active on this
                lead. What would you like to do?</label
              >
              <div class="radiolist">
                <label class="radiobtn">
                  Stop the active drip campaign
                  <input
                    type="radio"
                    name="dripstop"
                    value="false"
                    (click)="chooseDripStop(true)"
                  />
                  <span class="checkmark"></span>
                </label>
                <label class="radiobtn">
                  Keep the active drip campaign
                  <input
                    type="radio"
                    name="dripstop"
                    value="true"
                    (click)="chooseDripStop(false)"
                    checked
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end" style="padding-top: 14px !important">
  <ng-container *ngIf="data?.buttons; else else_block">
    <button
      mat-raised-button
      mat-dialog-close
      color="primary"
      *ngFor="let button of data?.buttons"
    >
      {{ button?.title }}
    </button>
  </ng-container>

  <ng-template #else_block>
    <button
      mat-raised-button
      [mat-dialog-close]="false"
      class="re-cancelbtn"
      *ngIf="noButtonTitle"
    >
      {{ noButtonTitle }}
    </button>
    <button
      (click)="submit()"
      mat-raised-button
      color="primary"
      *ngIf="yesButtonTitle"
      cdkFocusInitial
    >
      {{ yesButtonTitle }}
    </button>
    <button
      mat-raised-button
      [mat-dialog-close]="false"
      class="re-cancelbtn"
      *ngIf="!noButtonTitle"
    >
      No
    </button>
    <button
      (click)="submit()"
      mat-raised-button
      color="primary"
      *ngIf="!yesButtonTitle"
      cdkFocusInitial
    >
      Yes
    </button>
  </ng-template>
</mat-dialog-actions>
