<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      {{ data?.title }}
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<mat-dialog-content class="re-typography mat-typography">
  <div class="container">
    <div class="re-box">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12" *ngIf="data?.fromExport">
          <div class="form-group">
            <label class="re-labels">Will you be marketing to this list?</label>
            <div class="re-radio-list">
              <label class="re-radio-btn"
                >Yes
                <input
                  type="radio"
                  name="isMarketing"
                  [(ngModel)]="isMarketing"
                  value="yes"
                  (click)="clearMarketingData(0)"
                  checked /><span class="re-checkmark"></span
              ></label>
              <label class="re-radio-btn"
                >No
                <input
                  type="radio"
                  name="isMarketing"
                  [(ngModel)]="isMarketing"
                  (click)="clearMarketingData(1)"
                  value="no" /><span class="re-checkmark"></span
              ></label>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-sm-12" *ngIf="isMarketing == 'yes'">
          <div class="form-group">
            <label class="re-labels">Marketing types you will be using</label>
          </div>
          <table class="table">
            <colgroup>
              <col width="25%" />
              <col width="43%" />
              <col width="32%" />
            </colgroup>
            <thead>
              <tr>
                <th>Marketing Type<span class="re-req-icon">*</span></th>
                <th>Campaign Name<span class="re-req-icon">*</span></th>
                <th>
                  Expected Starting Date<span class="re-req-icon">*</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="re-check-sec">
                    <p-checkbox
                      [(ngModel)]="marketType"
                      value="{{ marketTypeVal[0] }}"
                      label="Direct Mail"
                    ></p-checkbox>
                  </div>
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Campaign"
                    [(ngModel)]="campaignName[0]"
                  />
                </td>
                <td>
                  <p-calendar
                    styleClass="ngCalendarClass"
                    appendTo="body"
                    [icon]="'fa fa-calendar'"
                    [(ngModel)]="marketingDate[0]"
                    name="marketingDate"
                    placeholder="Select Date"
                    dateFormat="MM d, yy"
                    [minDate]="dMToMinDateValue"
                    [showButtonBar]="true"
                    [showIcon]="true"
                  ></p-calendar>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="re-check-sec">
                    <p-checkbox
                      [(ngModel)]="marketType"
                      value="{{ marketTypeVal[1] }}"
                      label="Cold Calling"
                    ></p-checkbox>
                  </div>
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Campaign"
                    [(ngModel)]="campaignName[1]"
                  />
                </td>
                <td>
                  <p-calendar
                    styleClass="ngCalendarClass"
                    appendTo="body"
                    [icon]="'fa fa-calendar'"
                    [(ngModel)]="marketingDate[1]"
                    placeholder="Select Date"
                    dateFormat="MM d, yy"
                    [minDate]="dMToMinDateValue"
                    [showButtonBar]="true"
                    [showIcon]="true"
                  ></p-calendar>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="re-check-sec">
                    <p-checkbox
                      [(ngModel)]="marketType"
                      value="{{ marketTypeVal[2] }}"
                      label="SMS"
                    ></p-checkbox>
                  </div>
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Campaign"
                    [(ngModel)]="campaignName[2]"
                  />
                </td>
                <td>
                  <p-calendar
                    styleClass="ngCalendarClass"
                    appendTo="body"
                    [icon]="'fa fa-calendar'"
                    [(ngModel)]="marketingDate[2]"
                    placeholder="Select Date"
                    dateFormat="MM d, yy"
                    [minDate]="dMToMinDateValue"
                    [showButtonBar]="true"
                    [showIcon]="true"
                  ></p-calendar>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="re-check-sec">
                    <p-checkbox
                      [(ngModel)]="marketType"
                      value="{{ marketTypeVal[3] }}"
                      label="RVM"
                    ></p-checkbox>
                  </div>
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Campaign"
                    [(ngModel)]="campaignName[3]"
                  />
                </td>
                <td>
                  <p-calendar
                    styleClass="ngCalendarClass"
                    appendTo="body"
                    [icon]="'fa fa-calendar'"
                    [(ngModel)]="marketingDate[3]"
                    placeholder="Select Date"
                    dateFormat="MM d, yy"
                    [minDate]="dMToMinDateValue"
                    [showButtonBar]="true"
                    [showIcon]="true"
                  ></p-calendar>
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          <div class="re-check-sec re-all-select">
            <p-checkbox
              [(ngModel)]="addToAll"
              value="all"
              label="Update marketing details in Related Properties."
            ></p-checkbox>
            <span class="re-tooltip">
              <i class="fa fa-question-circle"></i>
              <span class="re-tooltiptext">
                <p>
                  By selecting the option, Cold Calling, SMS or RVM marketing
                  details will be updated to matching related properties also.
                  Related Properties are properties where the owner has multiple
                  properties based on the same mailing address. Direct Mail
                  marketing information is updated by default based on the
                  mailing address.
                </p>
                <p>
                  For example, you send a letter to 123 Main St which mailing
                  address of 456 Main St. All properties for that owner with
                  Mailing Address of 456 Main St will get updated with the
                  Direct Mail info for better marketing tracking.
                </p>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="padding-top: 14px !important">
  <button
    mat-dialog-close
    type="button"
    class="btn btn-default"
    data-dismiss="modal"
  >
    Cancel
  </button>
  <button type="button" class="btn save" (click)="saveDetails()">
    Save Details
  </button>
</mat-dialog-actions>
