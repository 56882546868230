<div class="re-top-head">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      Setting Up Cold Calling
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<mat-dialog-content class="mt-3">
  <div class="container-fluid p-0">
    <div class="box">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <label class="re-labels"
              >Select Call Script <span class="text-danger">*</span></label
            >
            <select
              class="form-control"
              [(ngModel)]="selectScript"
              name="selectScript"
            >
              <option
                value=""
                [hidden]="scriptPlaceHolder"
                [disabled]="scriptPlaceHolder"
                [selected]="scriptPlaceHolder"
              >
                Enter a self explanatory campaign name
              </option>
              <option
                value="{{ eachScript?._id }}"
                *ngFor="let eachScript of scriptData"
              >
                {{ eachScript?.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <label class="re-labels"
              >Select Voice Mail to Drop
              <span class="text-danger">*</span></label
            >
            <select
              class="form-control"
              [(ngModel)]="selectVoiceMail"
              name="selectVoiceMail"
            >
              <option
                value=""
                [hidden]="voicemailPlaceHolder"
                [disabled]="voicemailPlaceHolder"
                [selected]="voicemailPlaceHolder"
              >
                Select voice mail
              </option>
              <option
                value="{{ eachVoiceMail?._id }}"
                *ngFor="let eachVoiceMail of voiceMailData"
              >
                {{ eachVoiceMail?.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <label class="re-labels"
              >Select Groups or Phone Numbers
              <a
                pTooltip="The system will pick a phone number to call from on round robin basis."
                tooltipPosition="bottom"
                >Learn More</a
              ></label
            >
            <p-multiSelect
              placeholder="Select group(s) or Phone numbers"
              [options]="phoneNumberData"
              [(ngModel)]="selectPhoneNumber"
              appendTo="body"
            ></p-multiSelect>
          </div>
        </div>
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <label class="re-labels"
              >Abandon Call After
              <a
                pTooltip="The call will be dropped after the selected time period if there is no answer from the number you called."
                tooltipPosition="bottom"
                >Learn More</a
              ></label
            >
            <select
              class="form-control"
              [(ngModel)]="abandonCall"
              name="abandonCall"
            >
              <option value="" disabled selected hidden>Select duration</option>
              <option value="20000">20 Sec</option>
              <option value="25000">25 Sec</option>
              <option value="30000">30 Sec</option>
              <option value="45000">45 Sec</option>
              <option value="60000">60 Sec</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-12 col-sm-12">
          <div class="form-group">
            <label class="re-labels"
              >Dialer Type
              <a
                pTooltip="Auto Dialer will dial the next property owner as soon as you save the Call Result for an existing call. This option is best when you want to make most dials per hour.

              Click to dial will load up the information for the next property owner after you save the Call Result for an existing call. This option is best when you want to have information available for a lead before you make the call."
                tooltipPosition="top"
                [escape]="false"
                >Learn More</a
              ></label
            >
            <div class="re-radiolist">
              <label class="re-radiobtn">
                Auto Dialer
                <input
                  type="radio"
                  name="Dialer"
                  value="auto-dialer"
                  [(ngModel)]="dialerType"
                  checked
                />
                <span class="re-checkmark"></span>
              </label>
              <label class="re-radiobtn">
                Click to Dial
                <input
                  type="radio"
                  name="Dialer"
                  value="click-dialer"
                  [(ngModel)]="dialerType"
                />
                <span class="re-checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-sm-12">
          <hr />
          <div class="re-homebox">
            <div class="re-select-title">Exclude from Dialing</div>
            <label class="re-labels">Select Phone Types to Exclude</label>
            <ul class="re-phone-type-list">
              <li>
                <div class="checkboxlist">
                  <label class="checkboxbtn">
                    <input
                      type="checkbox"
                      value="Mobile"
                      name="Mobile"
                      (click)="selectMobileType($event.target.value)"
                    />
                    <span class="checkmark"></span>
                    <span class="txt">Mobile</span>
                  </label>
                </div>
              </li>
              <li>
                <div class="checkboxlist">
                  <label class="checkboxbtn">
                    <input
                      type="checkbox"
                      value="Landline"
                      name="Landline"
                      (click)="selectMobileType($event.target.value)"
                    />
                    <span class="checkmark"></span>
                    <span class="txt">Landline</span>
                  </label>
                </div>
              </li>
              <li>
                <div class="checkboxlist">
                  <label class="checkboxbtn">
                    <input
                      type="checkbox"
                      value="Voip"
                      name="Voip"
                      (click)="selectMobileType($event.target.value)"
                    />
                    <span class="checkmark"></span>
                    <span class="txt">VoIP</span>
                  </label>
                </div>
              </li>
              <li>
                <div class="checkboxlist">
                  <label class="checkboxbtn">
                    <input
                      type="checkbox"
                      value="Other"
                      name="Other"
                      (click)="selectMobileType($event.target.value)"
                    />
                    <span class="checkmark"></span>
                    <span class="txt">Other</span>
                  </label>
                </div>
              </li>
            </ul>
            <div class="re-phone-status-section">
              <div class="form-group">
                <label class="re-labels">Phone Status to Exclude</label>
                <p-autoComplete
                  #phoneStatusExc
                  (onFocus)="phoneStatusDownClick(phoneStatusExc)"
                  (click)="phoneStatusDownClick(phoneStatusExc)"
                  (onUnselect)="phoneStatusDownClick(phoneStatusExc)"
                  (onKeyUp)="
                    $event.keyCode == 8
                      ? phoneStatusDownClick(phoneStatusExc)
                      : $event
                  "
                  (onClear)="clearListItem(phoneStatusExc)"
                  placeholder="Phone Status"
                  [(ngModel)]="phoneStatusExclude"
                  field="name"
                  [suggestions]="searchedPhoneStatus"
                  (completeMethod)="searchPhoneStatus($event.query, 'exclude')"
                  emptyMessage="{{ noDataAvailable }}"
                  dataKey="name"
                  [dropdown]="true"
                  [multiple]="true"
                  appendTo="body"
                >
                </p-autoComplete>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="padding-top: 12px !important">
  <button mat-raised-button [mat-dialog-close]="false" class="re-cancel-btn">
    Cancel
  </button>
  <button
    mat-raised-button
    cdkFocusInitial
    class="re-submit-btn"
    (click)="goToColdCall()"
    [disabled]="
      selectScript.length == 0 ||
      selectVoiceMail.length == 0 ||
      selectPhoneNumber.length == 0
    "
  >
    Continue
  </button>
</mat-dialog-actions>
