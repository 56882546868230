import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//service
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DialerService } from 'src/app/providers/dialer/dialer.service';
import { ToastrService } from 'ngx-toastr';
//utils
import { MessageConstant } from '../../../../utils/message-constant';
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-send-sms',
  templateUrl: './send-sms.component.html',
  styleUrls: ['./send-sms.component.scss'],
  styles: [
    `
      .mat-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 6px;
      }
    `,
  ],
})
export class SendSmsComponent implements OnInit {
  public smsContent: string = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<SendSmsComponent>,
    private _loaderService: NgxUiLoaderService,
    private _dialerService: DialerService,
    private _toastrService: ToastrService
  ) {
    this.smsContent = this.data?.SmsText;
    console.log(this.data);
  }

  ngOnInit(): void {}
  sendSMS() {
    if (this.smsContent.length > 0) {
      this._loaderService.start();
      let myObj = {
        From: this.data?.From,
        SmsText: this.smsContent,
        To: this.data?.To,
        campaignId: this.data?.campaignId,
        propertyId: this.data?.propertyId,
        userId: this.data?.userId,
      };

      this._dialerService.sendSms(myObj).subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          if (response.status == 200) {
            this._toastrService.success('SMS Sent Successfully');
            this.dialog.close();
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
          } else {
          }
        }
      );
    } else {
      this._toastrService.error('Please fill SMS Content');
    }
  }
}
