import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

//SERVICES
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { ListStackingService } from 'src/app/providers/list-stacking/list-stacking.service';

//UTILS
import { MessageConstant } from 'src/app/utils/message-constant';

@Component({
  selector: 'app-manage-phone-status',
  templateUrl: './manage-phone-status.component.html',
  styleUrls: ['./manage-phone-status.component.scss'],
})
export class ManagePhoneStatusComponent implements OnInit {
  type: string = 'All';
  phoneStatusVal: any = [];
  allPhoneObject: any[] = [];
  searchedPhoneStatus: any[] = [];
  allPhoneStatus: any[] = [];
  noDataAvailable: string = 'No Data Available';
  promises: any[] = [];
  currentData: any;
  index: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<ManagePhoneStatusComponent>,
    private _listStackingService: ListStackingService,
    public router: Router,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService
  ) {
    this.phoneStatusVal = this.data?.phoneStatusVal;
    this.type = this.data?.type;
    this.index = this.data?.index;
  }

  ngOnInit(): void {
    this.getPermission();
  }
  getStatus() {
    const promise = new Promise((resolve, reject) => {
      this._listStackingService.getPhoneStatus().subscribe(
        (response) => {
          if (response.code == 200) {
            this.allPhoneStatus = response?.message?.phoneStatus || [];
            this.allPhoneStatus = this.allPhoneStatus.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            return resolve(this.allPhoneStatus);
          }
        },
        (err) => {
          return reject(err);
        }
      );
    });
    return promise;
  }
  getPermission() {
    this._loaderService.start();
    this._listStackingService.getListStackingPermission({}).subscribe(
      (response) => {
        if (response?.statusCode) {
          if (response?.data) {
            this.getListDetail();
          } else {
            this.router.navigate(['/permission-denied']);
          }
        }
      },
      (err) => {
        this.router.navigate(['/permission-denied']);
      }
    );
  }
  getListDetail() {
    if (this.data.details) {
      this.promises = [];
      this.promises.push(this.getStatus());
      this.currentData = this.data.details;
      Promise.all(this.promises)
        .then((result) => {
          this.allPhoneObject = [];
          let statusVal, pType, finalStatusVal, statusIndex;
          if (this.currentData[`Phone_${this.index + 1}`]) {
            statusVal = [];
            pType = '';
            finalStatusVal = [];

            if (
              this.currentData[`Phone_${this.index + 1}_status`] &&
              this.currentData[`Phone_${this.index + 1}_status`].length
            ) {
              statusVal =
                typeof this.currentData[`Phone_${this.index + 1}_status`] ==
                'string'
                  ? this.currentData[`Phone_${this.index + 1}_status`].split(
                      ','
                    )
                  : this.currentData[`Phone_${this.index + 1}_status`];
            }

            statusVal.filter((e) => {
              statusIndex = this.allPhoneStatus.findIndex((x) => x.name === e);

              if (statusIndex >= 0) {
                finalStatusVal.push(this.allPhoneStatus[statusIndex]);
              }
            });

            if (this.currentData[`Phone_${this.index + 1}_type`]) {
              const phoneTypes = ['Mobile', 'Landline', 'Voip'];
              if (
                !phoneTypes.includes(
                  this.currentData[`Phone_${this.index + 1}_type`]
                )
              ) {
                pType = 'Other';
              } else {
                pType = this.currentData[`Phone_${this.index + 1}_type`];
              }
            }

            this.allPhoneObject.push({
              phone: this.currentData[`Phone_${this.index + 1}`],
              phoneType: pType,
              phoneStatusVal: finalStatusVal,
              savedPhoneStatus: finalStatusVal,
              litigator:
                this.currentData[`Phone_${this.index + 1}_IsLitigator`] || '',
              dnc: this.currentData[`Phone_${this.index + 1}_DNC`] || '',
            });
          }
          this._loaderService.stop();
        })
        .catch((err) => {
          this._loaderService.stop();
        });
    }
  }
  searchPhoneStatus(searchStr, index) {
    let tempAr = [];
    this.allPhoneObject[index]?.phoneStatusVal.filter((element) => {
      tempAr.push(element.name);
    });
    setTimeout(() => {
      this.searchedPhoneStatus = this.allPhoneStatus.filter((e) => {
        if (
          e.name.toUpperCase().includes(searchStr.toUpperCase()) &&
          tempAr.indexOf(e.name) == -1
        )
          return e;
      });
    }, 500);
  }
  phoneStatusDownClick(autocomplete: any) {
    autocomplete.handleDropdownClick(1);
  }
  clearListItem(autocomplete: any) {
    autocomplete.value = '';
    autocomplete.handleDropdownClick();
  }
  emitRemoveData(e, index) {
    if (e.name == 'Attached with Lead') {
      const statusIndex = this.allPhoneObject[
        index
      ]?.savedPhoneStatus.findIndex((x) => x.name === e.name);
      if (statusIndex >= 0) this.allPhoneObject[index]?.phoneStatusVal.push(e);
    }
  }
  addKey() {
    let phoneMsg = 'Invalid value of ';
    let phoneFlag = 1;
    let id = this.currentData._id;
    let reqPhoneMsg = '',
      reqPhoneTypeMsg = '',
      phoneTypeFlag = 1;
    this.allPhoneObject.filter((x, i) => {
      if (x.phone && x.phone.length > 0 && x.phone.length < 10) {
        phoneFlag = 0;
        phoneMsg += ` Phone ${i + 1},`;
      } else if (!x.phone) {
        phoneFlag = -1;
        if (reqPhoneMsg.length == 0) reqPhoneMsg = 'Please fill ';
        reqPhoneMsg += ` Phone ${i + 1},`;
      }
      // if (!x.phoneType) {
      //   phoneTypeFlag = 0;
      //   if (reqPhoneTypeMsg.length == 0) reqPhoneTypeMsg = 'Please select ';
      //   reqPhoneTypeMsg += ` Phone Type ${i + 1},`;
      // }
    });

    phoneMsg = phoneMsg.slice(0, -1);
    if (phoneFlag == 0) {
      this._toastrService.error(phoneMsg);
    }
    if (phoneFlag == -1) {
      reqPhoneMsg = reqPhoneMsg.slice(0, -1);
      this._toastrService.error(reqPhoneMsg);
    }
    if (phoneTypeFlag == 0) {
      reqPhoneTypeMsg = reqPhoneTypeMsg.slice(0, -1);
      this._toastrService.error(reqPhoneTypeMsg);
    }

    let allStatus;
    allStatus = [];
    if (this.allPhoneObject[0]) {
      this.allPhoneObject[0]?.phoneStatusVal.filter((e) => {
        allStatus.push(e.name);
      });
      this.currentData[`Phone_${this.index + 1}_status`] = allStatus;
    } else {
      this.currentData[`Phone_${this.index + 1}_status`] = [];
    }

    this._loaderService.start();
    this._listStackingService.editProperty(this.currentData, id).subscribe(
      (data: any) => {
        if (data.code == 200) {
          this._loaderService.stop();
          this._toastrService.success(MessageConstant.phoneNumberUpdateSuccess);
          this.dialog.close(true);
        } else {
          this._loaderService.stop();
          this._toastrService.error(data.message);
        }
      },
      (err) => {
        this._loaderService.stop();
      }
    );
  }
}
