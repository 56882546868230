import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

declare var google;

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent implements OnInit {
  @ViewChild('mapRef') mapRef: ElementRef;

  latitude;
  longitude;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.latitude = Number(this.data.lat);
    this.longitude = Number(this.data.long);

    let interval = setInterval(() => {
      if (!this.mapRef?.nativeElement) return;

      clearInterval(interval);

      const location = {
        lat: this.latitude,
        lng: this.longitude,
      };
      const options = {
        center: location,
        zoom: 15,
      };

      const map = new google.maps.Map(this.mapRef.nativeElement, options);
      new google.maps.Marker({
        position: location,
        map,
      });

      this.cdRef.detectChanges();
    });
  }
}
