import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  EventEmitter,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as moment from 'moment-timezone';

// SERVICES
import { SharedService } from '../../shared.service';
import { SmsService } from 'src/app/providers/sms/sms.service';
import { ScheduledSmsService } from 'src/app/providers/scheduled-sms/scheduled-sms.service';
import { LeadsService } from 'src/app/providers/leads/leads.service';
import { MasterTaskService } from 'src/app/providers/master-task/master-task.service';

// COMPONENTS
import { ChangeNumberComponent } from '../change-number/change-number.component';
import { AddEditSmsTemplateComponent } from '../../dialog/add-edit-sms-template/add-edit-sms-template.component';

// UTILS
import { MessageConstant } from 'src/app/utils/message-constant';
import { ErrorModel } from 'src/app/utils/models/error';
import { ResponseModel } from 'src/app/utils/models/response';
import { UploadInput, UploadOutput } from 'ngx-uploader';
import { environment } from 'src/environments/environment';
import { BaseUrl } from 'src/app/utils/base-url-constants';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import * as _ from 'lodash';
import { QuillEditorComponent } from 'ngx-quill';
import 'quill-mention';
import { timezoneConstant } from 'src/app/utils/timezone-list-constant';

@Component({
  selector: 'app-sms-dialog',
  templateUrl: './sms-dialog.component.html',
  styleUrls: ['./sms-dialog.component.scss'],
})
export class SmsDialogComponent implements OnInit {
  @ViewChild('uploader', { read: ElementRef }) fileInput: ElementRef;
  @ViewChild('editor') editor: QuillEditorComponent;
  dialogRef;
  uploadInput: EventEmitter<UploadInput>;
  timeOut: any;
  smsDetailForm: FormGroup;
  contacts: any[] = [];
  messageConstant = MessageConstant;
  attachments: any[] = [];
  count: number = 0;
  SMSBalance: number = 0;
  submitted: boolean = false;
  smsTemplates: any[] = [];
  smsFilterBody: String = '';
  toggle = false;
  timezoneList: any = [];
  minimumTime = new Date();
  scheduledDateTime: boolean = false;
  showAttachments: any[] = [];
  leadId: string = '';
  defaultNumberList: any[] = [];
  newLeadSource: string = '';
  newLeadSourceId: string = '';
  leadIds: any = [];
  numberChange: boolean = true;
  smsCount: number = 0;
  smsCharCount: number = 0;
  items: string[] = [
    'First_Name',
    'Last_Name',
    'Property_Street_Address',
    'Property_City',
    'Property_State',
    'Property_Zip_Code',
    'A2P_10DLC_Company_Name',
    'DBA',
  ];
  editorStyle: {
    height: '500px';
  };
  editorConfig: any = {};
  editorOptions: any = {};
  mainUserData: any = {};

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _smsDialogRef: MatDialogRef<SmsDialogComponent>,
    private _sharedService: SharedService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    public _utilities: CommonFunctionsService,
    private _smsService: SmsService,
    private _scheduledSMSService: ScheduledSmsService,
    private _leadService: LeadsService,
    private _masterTaskService: MasterTaskService
  ) {
    this.uploadInput = new EventEmitter<UploadInput>();
    if (this.data?.SMSBalance) {
      this.SMSBalance = this.data?.SMSBalance;
    }
    this.editorOptions = {
      placeholder: this._utilities.commonEditor().placeholder,
      toolbar: this._utilities.commonEditor().toolbar,
      mention: this.mentionEditor(),
    };
  }

  mentionEditor() {
    return {
      mentionListClass: 'ql-mention-list mat-elevation-z8',
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      showDenotationChar: true,
      spaceAfterInsert: true,
      onSelect: (item, insertItem) => {
        const editor = this.editor.quillEditor;
        insertItem(item);
        // this.tagUser(item.value)
        // necessary because quill-mention triggers changes as 'api' instead of 'user'
        editor.insertText(editor.getLength() - 1, '', 'user');
      },
      source: (searchTerm, renderList) => {
        const values = this.items.map((item, index) => {
          return { id: index, value: item };
        });

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];

          values.forEach((entry) => {
            if (
              entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            ) {
              matches.push(entry);
            }
          });
          renderList(matches, searchTerm);
        }
      },
    };
  }

  getUserRoleList() {
    this._loaderService.start();
    let role = [];
    this._masterTaskService.getUserRoleList({ page: 1, limit: 100 }).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.mainUserData = response.data.items.filter((x) => !x.mainUserId)[0];
          this._loaderService.stop();
          let hash = Object.create(null);

          let roles = _.flatten(
            response.data.items.map((item) => item.roleData)
          );
          let result = roles.reduce((r, o) => {
            if (!hash[o?._id]) {
              hash[o?._id] = {
                _id: o?._id,
                name: o?.name,
                roles: [],
              };
              r.push(hash[o?._id]);
            }
            hash[o?._id].roles.push({
              _id: o?._id,
              name: o?.name,
            });
            return r;
          }, []);
          result.map((item) => {
            role.push(item.name.toString());
          });
          this.items = [...this.items, ...role];
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  ngOnInit(): void {
    this.getUserRoleList();
    this.getDefaultCampaignList();
    this.minimumTime = new Date(
      moment
        .tz(localStorage.getItem('timezoneOffset'))
        .startOf('day')
        .format('YYYY/MM/DD h:mm:ss A')
    );
    this.timezoneList = timezoneConstant.timezoneArray;
    this.smsDetailForm = this.fb.group({
      smsNumber: ['', Validators.required],
      leadSourceNumber: ['', Validators.required],
      smsBody: ['', Validators.required],
      mediaUrls: [''],
      smsTemplateId: ['', []],
      activeTimeChange: [false],
      scheduleDate: [''],
      scheduleTime: [''],
      timezone: [localStorage.getItem('timezoneOffset')],
    });

    if (this.data) {
      const { leadSource, phoneNumber } = this.data;
      this.smsDetailForm.patchValue({
        leadSourceNumber:
          typeof leadSource === 'string'
            ? this._utilities.maskNumber(leadSource)
            : this.data.defaultSmsNumber
            ? this.data.defaultSmsNumber
            : '',
        smsNumber: phoneNumber,
      });
    }
    if (this.data?.changeNumber == false) {
      this.numberChange = false;
    }
    this.getSmsTemplates();
    if (this.data.type == 'scheduledSMS') {
      if (this.data.attachments && this.data.attachments) {
        let attchments = this.data.attachments.map((item) => {
          let attach = item.split('/');
          return { name: attach[attach.length - 1], url: item };
        });
        this.showAttachments = attchments;
      }

      let convertTime = moment(this.data.dueInIso)
        .tz(this.data.timezone)
        .format('YYYY-MM-DD HH:mm:ss');
      let dueIso = this._utilities.dueDateFormat(
        new Date(convertTime)
      ).parseDate;

      this.toggle = true;
      this.smsDetailForm.patchValue({
        activeTimeChange: [true],
        smsTemplateId: this.data.smsTemplateId ? this.data.smsTemplateId : '',
        smsBody: this.data.smsTemplateBody,
        scheduleDate: dueIso,
        scheduleTime: dueIso,
        timezone: this.data.timezone,
      });
    }
    this.data?.contacts.sort((a, b) => a.name.localeCompare(b.name));
  }

  onToggle() {
    this.toggle = !this.toggle;
    if (this.toggle) {
      this.customFormValidation(
        this.smsDetailForm,
        ['scheduleDate', 'scheduleTime'],
        [[Validators.required], [Validators.required]]
      );

      let convertTime = moment()
        .tz(localStorage.getItem('timezoneOffset'))
        .format('YYYY-MM-DD HH:mm:ss');
      let dueIso = this._utilities.dueDateFormat(
        new Date(convertTime)
      ).parseDate;
      this.smsDetailForm.patchValue({
        scheduleDate: dueIso,
        scheduleTime: dueIso,
      });
    } else {
      this.smsDetailForm.patchValue({
        scheduleDate: '',
        scheduleTime: '',
      });
      this.customFormValidation(
        this.smsDetailForm,
        ['scheduleDate', 'scheduleTime'],
        [[''], ['']]
      );
    }
  }

  customFormValidation(form: FormGroup, field, validation) {
    for (let i = 0; i < field.length; i++) {
      form.get(field[i]).setValidators(validation[i]);
      form.get(field[i]).updateValueAndValidity();
    }
  }

  onUploadLogo(output: UploadOutput): void {
    this.fileInput.nativeElement.value = '';
    this._loaderService.start();
    clearTimeout(this.timeOut);
    if (output.type === 'allAddedToQueue') {
      // when all files added in queue
      const token = localStorage.getItem('token');
      let endpoint =
        environment.baseUrl + BaseUrl.sharedUrl + 'mms-file-upload';
      if (this.data?.moduleId) {
        endpoint += `?moduleId=${this.data?.moduleId}&isPublic=1`;
      }

      const event: UploadInput = {
        type: 'uploadAll',
        url: endpoint,
        method: 'POST',
        headers: { Authorization: token },
      };
      this.uploadInput.emit(event);
    } else if (
      output.type === 'addedToQueue' &&
      typeof output.file !== 'undefined'
    ) {
    } else if (output.type === 'done' && typeof output.file !== 'undefined') {
      // this.files = output.file;
      this.count += 1;
      if (output.file.response.statusCode == 200) {
        this._loaderService.stop();
        if (typeof output.file.response.data == 'object') {
          output.file.response.data?.filter((x) => {
            this.attachments.push(x);
          });
          this.attachments = _.uniqBy(this.attachments, 'name');
        } else {
          this.attachments = output.file.response.data;
          this.attachments = _.uniqBy(this.attachments, 'name');
        }
      } else {
        this._loaderService.stop();
        if (output.file?.response?.message) {
          this._toastrService.error(output.file.response.message);
        } else {
          this._toastrService.error('Invalid');
        }
      }
    } else if (output.type === 'dragOut') {
      // drag out event
      setTimeout(() => {
        this._loaderService.stop();
      }, 2000);
    } else if (output.type === 'drop') {
      // on drop event
      setTimeout(() => {
        this._loaderService.stop();
      }, 2000);
    }
  }

  removeAttachment(index) {
    this.attachments.splice(index, 1);
  }

  removeExistingAttachment(index) {
    this.showAttachments.splice(index, 1);
  }

  onSubmit() {
    this.submitted = true;
    if (this.smsDetailForm.invalid) {
      return;
    }

    const { smsNumber, leadSourceNumber } = this.smsDetailForm.value;
    let number = leadSourceNumber;
    if (typeof this.data.leadSource === 'string') {
      number = this.data?.leadSource;
    }
    if (this.newLeadSource && typeof this.newLeadSource === 'string') {
      number = this.newLeadSource;
    }

    let obj = {
      ...this.smsDetailForm.value,
      leadSourceNumber: number,
      smsNumber: smsNumber,
      moduleType: this.data.moduleType || 'Leads',
      fromBuyerModule: this.data.fromBuyerModule,
    };

    if (typeof this.data.leadSource != 'string') {
      this.data.leadSource.map((number) => {
        if (number.number == leadSourceNumber) {
          obj['numberId'] = number._id;
        }
      });
    }

    if (
      this.data.moduleType == 'ListStacking' ||
      this.data?.moduleTypeEdit == 'ListStacking'
    ) {
      obj['propertyId'] = this.data.leadId;
      obj['dialerCampaignId'] = this.data?.dialerCampaignId;
      obj['moduleType'] = 'ListStacking';
    } else if (this.data.moduleType === 'Buyer') {
      obj['buyerId'] = this.data.buyerId;
      obj['leadId'] = this.data.leadId;
    } else if (
      this.data.moduleType == 'Vendor' ||
      this.data?.moduleTypeEdit == 'Vendor'
    ) {
      obj['vendorId'] = this.data.vendorId;
      obj['moduleType'] = 'Vendor';
    } else {
      obj['leadId'] = this.data.leadId;
    }

    if (this.showAttachments.length > 0) {
      this.showAttachments.forEach((item) => {
        this.attachments.push(item);
      });
    }

    if (this.attachments.length) {
      obj = {
        ...obj,
        mediaUrls: this.attachments.map((x) => x.url),
      };
    } else {
      delete obj['mediaUrls'];
    }

    if (obj.activeTimeChange && this.scheduledDateTime) {
      this._toastrService.error('Invalid or expired time');
      return;
    }

    this._loaderService.start();
    if (obj.activeTimeChange) {
      obj.dueInIso =
        moment(obj.scheduleDate).utcOffset('0').format('YYYY-MM-DD') +
        'T' +
        moment(obj.scheduleTime).utcOffset('0').seconds(0).format('HH:mm:ss') +
        moment().tz(obj.timezone).format('Z');
    }
    delete obj['scheduleDate'];
    delete obj['scheduleTime'];
    this.messageBody(
      this.data,
      this._utilities.getTextWithMentions(obj.smsBody),
      this.data.teams
    );
    obj.smsBody = this._utilities.populateEditorDataBR(this.smsFilterBody);

    if (obj.smsBody) {
      obj.smsBody = obj.smsBody
        ?.replaceAll('&amp;', '&')
        ?.replaceAll('&gt;', '>')
        ?.replaceAll('&lt;', '<')
        ?.replaceAll('&#xFEFF;', '');

      obj.smsBody = obj.smsBody.replace(/[^\x00-\x7F]/g, '');
    }

    if (this.data.type == 'scheduledSMS') {
      obj.scheduledSmsId = this.data.scheduledSmsId;
      obj.smsTemplateId = obj.smsTemplateId == '' ? null : obj.smsTemplateId;
      delete obj['activeTimeChange'];
      delete obj['propertyId'];
      delete obj['vendorId'];
      delete obj['dialerCampaignId'];
      this._scheduledSMSService.editScheduledSMS(obj).subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          if (response.statusCode == 200) {
            this._toastrService.success(
              this.messageConstant.smsScheduledUpdateSuccess,
              ''
            );
            if (this.newLeadSourceId) {
              obj.newLeadSourceId = this.newLeadSourceId;
            }
            this._smsDialogRef.close(obj);
            this._sharedService.refreshActivityLog.next(true);
            this._sharedService.refreshDashboard.next(true);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
    } else {
      this._sharedService.sendSms(obj).subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          if (response.statusCode == 200) {
            if (obj.activeTimeChange) {
              this._toastrService.success(
                this.messageConstant.smsScheduledSuccess,
                ''
              );
            } else {
              this._toastrService.success(
                this.messageConstant.smsSendSuccess,
                ''
              );
            }
            if (this.newLeadSourceId) {
              obj.newLeadSourceId = this.newLeadSourceId;
            }
            this._smsDialogRef.close(obj);
            this._sharedService.refreshActivityLog.next(true);
            this._sharedService.refreshDashboard.next(true);
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
    }
  }

  onClose() {
    this._smsDialogRef.close();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.smsDetailForm.controls[controlName].hasError(errorName);
  };

  getSmsTemplates() {
    let obj = {};

    this._smsService.getSmsList(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.smsTemplates = response.data?.items;
          this.smsTemplates.sort((a, b) => a.templateName.localeCompare(b.templateName));
          if (this.data.type != 'scheduledSMS') {
            let smsTemplateId = this.smsTemplates
              .map((template) => {
                if (template.smsTemplateId) {
                  return template.smsTemplateId;
                }
              })
              .filter((x) => x)[0];
            if (smsTemplateId) {
              this.smsDetailForm.patchValue({
                smsTemplateId: smsTemplateId,
              });
              this.setSmsTemplate(smsTemplateId, false);
            }
          }
        }
        this._loaderService.stop();
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  async setSmsTemplate(id, bool?) {
    this.showAttachments = [];
    if (!id) {
      this.smsDetailForm.patchValue({
        smsBody: '',
      });
    }
    const smsTemplateId = id;
    if (bool) {
      if (this.data.type != 'scheduledSMS') {
        let obj = { smsTemplateId: smsTemplateId };

        this._smsService.changeTemplate(obj).subscribe(
          (response: ResponseModel) => {
            if (response.statusCode == 200) {
            }
          },
          (err: ErrorModel) => {}
        );
      }
    }
    const index = this.smsTemplates.findIndex((x) => x._id === smsTemplateId);
    if (index === -1) {
      return;
    }
    if (this.smsTemplates[index]?.attachmentArray) {
      let attchments = this.smsTemplates[index]?.attachmentArray.map((item) => {
        return { name: item?.name, url: item?.url };
      });
      this.showAttachments = attchments;
    }
    if (!this.mainUserData?._id) {
      this.mainUserData = await this.getMainUser();
    }
    this.messageBody(
      this.data,
      this._utilities.getTextWithMentions(this.smsTemplates[index].message),
      this.data.teams
    );
    this.smsDetailForm.patchValue({
      smsBody: this._utilities.populateEditorDataWithN(this.smsFilterBody),
    });
    this.getSMSCount('');
  }

  messageBody(data, smsMessage, teams) {
    let messageBody;
    let address;
    let streetAddress;
    let city;
    let state;
    let zip;
    let name;
    let firstName;
    let lastName;

    if (data?.name) {
      const leadTitle = data?.name
        ? data.name.replace(/ {2,}/g, ' ').trim()
        : '';
      name = leadTitle ? leadTitle.split(' ') : '';
      firstName = name[0] ? name[0] : 'First_Name(N/A)';
      lastName = name[1] ? name[1] : 'last_Name(N/A)';
    } else if (data?.contacts[0]?.name) {
      name = data?.contacts[0]?.name.split(' ');
      firstName = name[0] ? name[0] : 'First_Name(N/A)';
      lastName = name[1] ? name[1] : 'last_Name(N/A)';
    } else {
      firstName = 'First_Name(N/A)';
      lastName = 'last_Name(N/A)';
    }

    // if (this.addEditForm.value.title) {
    //   apptTime =
    //     this.addEditForm.value.title +
    //     ' ' +
    //     moment(this.addEditForm.value.dateTime).format('ddd MMM DD, YYYY') +
    //     ', at ' +
    //     moment(moment(this.startTime).set({ second: 0 })).format('LT');
    // }

    if (data?.address) {
      address = data?.address.split(',');
      if (address.length > 1) {
        streetAddress = address[0]
          ? address[0]
          : 'Property_Street_Address(N/A)';
        city = address[1] ? address[1] : 'Property_City(N/A)';
        if (address.length > 2) {
          let subDivide = address[2].trim();
          if (subDivide) {
            let sub = subDivide.split(' ');
            state = sub[0] ? sub[0] : 'Property_State(N/A)';
            zip = sub[1] ? sub[1] : 'Property_Zip_Code(N/A)';
          }
        } else {
          state = 'Property_State(N/A)';
          zip = 'Property_Zip_Code(N/A)';
        }
      } else {
        streetAddress = address[0];
        city = 'Property_City(N/A)';
        state = 'Property_State(N/A)';
        zip = 'Property_Zip_Code(N/A)';
      }
    } else if (
      data.dialerSmsTemplete &&
      data.dialerSmsTemplete == 'dialer Sms'
    ) {
      address = data?.propertyFormatedAddress;
      streetAddress = data?.propertyAddress
        ? data?.propertyAddress
        : 'Property_Street_Address(N/A)';
      city = data?.propertyCity ? data?.propertyCity : 'Property_City(N/A)';
      state = data?.propertyState ? data?.propertyState : 'Property_State(N/A)';
      zip = data?.propertyZipCode
        ? data?.propertyZipCode
        : 'Property_Zip_Code(N/A)';
    } else {
      streetAddress = 'Property_Street_Address(N/A)';
      city = 'Property_City(N/A)';
      state = 'Property_State(N/A)';
      zip = 'Property_Zip_Code(N/A)';
    }

    let myObj = {
      '@First_Name': this._utilities.titleCaseToWord(firstName),
      '@Last_Name': this._utilities.titleCaseToWord(lastName),
      '@Property_Street_Address': streetAddress ? streetAddress : '',
      '@Property_City': city ? city : '',
      '@Property_State': state ? state : '',
      '@Property_Zip_Code': zip ? zip : '',
      '@Appt_Date_and_Time': '',
      '@Appt_Day': '',
      '@Appt_Time': '',
      '@A2P_10DLC_Company_Name': this.mainUserData?.A2P10DLCCompanyName ? this.mainUserData?.A2P10DLCCompanyName : '',
      '@DBA': this.mainUserData?.A2PDBA ? this.mainUserData?.A2PDBA : '',
    };
    teams?.roles?.map((i) => {
      let key = i.name.toString();
      if (key != undefined && smsMessage.includes(key)) {
        let originalRole = teams.roles.find((item) => item.name == key);
        let userId = teams.assignUser[originalRole._id];
        if (!userId) {
          if (teams?.items?.roles) {
            originalRole = teams?.items?.roles.find(
              (item) => item.name == 'Owner'
            );
          }
          if (teams?.roles) {
            originalRole = teams?.roles.find((item) => item.name == 'Owner');
          }
        }
        userId =
          userId != null
            ? userId
            : teams?.items?.assignUser['5fd093a2f44c5ac2ea1919b4']
            ? teams?.items?.assignUser['5fd093a2f44c5ac2ea1919b4']
            : teams?.assignUser['5fd093a2f44c5ac2ea1919b4'];
        let username;
        if (userId) {
          username = originalRole.members.find(
            (item) => item._id.toString() == userId.toString()
          );
        }
        let obj = {};
        obj['@' + key] = username?.firstName
          ? this._utilities.titleCaseToWord(`${username?.firstName}`)
          : `${key}(N/A)`;
        myObj = { ...myObj, ...obj };
      }
    });

    let rowRole = [
      '@First_Name',
      '@Last_Name',
      '@Property_Street_Address',
      '@Property_City',
      '@Property_State',
      '@Property_Zip_Code',
      '@Appt_Date_and_Time',
      '@Appt_Day',
      '@Appt_Time',
      '@A2P_10DLC_Company_Name',
      '@DBA',
    ];

    let roles = teams?.roles
      ? teams?.roles?.map((item) => '@' + item.name)
      : [];
    let rolesWithKey = new RegExp([...rowRole, ...roles].join('|'), 'g');
    messageBody = smsMessage.replace(rolesWithKey, (matched) => {
      return myObj[matched];
    });
    //messageBody = messageBody.replace(/@/g, '');
    this.smsFilterBody = messageBody;
  }

  getSMSCount(e) {
    const MAX_LENGTH = 160;
    const message = this._utilities.getText(
      this['editor'].quillEditor.getContents()
    );

    const length = message.length ? message?.length - 1 : 0;

    this.smsCount = Math.ceil(length / MAX_LENGTH);
    this.smsCharCount = MAX_LENGTH * this.smsCount - length;
  }

  onChangeTime() {
    let nowDate = moment()
      .tz(this.smsDetailForm.get('timezone').value)
      .format();
    let selected = moment(this.smsDetailForm.get('scheduleDate').value).format(
      'YYYY-MM-DD'
    );
    let selectedTime = moment(
      this.smsDetailForm.get('scheduleTime').value
    ).format('HH:mm:ss');
    let selectedDate =
      selected +
      'T' +
      selectedTime +
      moment().tz(this.smsDetailForm.get('timezone').value).format('Z');

    if (nowDate.valueOf() > selectedDate.valueOf()) {
      this.scheduledDateTime = true;
      this._toastrService.error('Invalid or expired date');
    } else {
      this.scheduledDateTime = false;
    }
  }

  async changeNumber() {
    this.dialogRef = this.dialog.open(ChangeNumberComponent, {
      width: '450px',
      disableClose: true,
      data: {
        numberList: this.defaultNumberList,
        leadId: this.data?.leadId,
        numberId: this.data?.numberId,
        leadIds: this.leadIds ? this.leadIds : [this.data?.leadId],
        allLeadHide: true,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let phone = this.defaultNumberList
          .map((number) => {
            if (number._id == result?.defaultNumberId) {
              this.newLeadSourceId = number._id;
              return number.number;
            }
          })
          .filter((x) => x);
        if (phone[0]) {
          this.smsDetailForm.patchValue({
            leadSourceNumber: this._utilities.maskNumber(phone[0]),
          });
          this.newLeadSource = phone[0];
        }
      }
    });
  }
  getDefaultCampaignList() {
    const obj = {
      crmQueId: [],
      numTypeId: 1,
    };

    this._leadService.getCampaignList(obj).subscribe(
      (response: ResponseModel) => {
        if (response.statusCode == 200) {
          this.defaultNumberList = response.data?.items;
          this._loaderService.stop();
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
      }
    );
  }

  onAddTemplate() {
    this.dialogRef = this.dialog.open(AddEditSmsTemplateComponent, {
      width: '650px',
      disableClose: true,
      panelClass: 'editTemplate',
      data: {
        moduleId: 'id',
        action: 'create',
        items: [],
        allTitle: {
          heading: 'Create Template',
          buttonTitle: 'Save',
        },
      },
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        let smsTemplateId = data._id;
        this.getSmsTemplates();
        setTimeout(() => {
          this.smsDetailForm.patchValue({
            smsTemplateId: smsTemplateId,
          });
          this.setSmsTemplate(smsTemplateId, true);
        }, 1000);
      }
    });
  }
  getMainUser() {
    return new Promise(async (resolve, reject) => {
      this._masterTaskService
        .getUserRoleList({ page: 1, limit: 100 })
        .subscribe(
          (response: ResponseModel) => {
            if (response.statusCode == 200) {
              resolve(response.data.items.filter((x) => !x.mainUserId)[0]);
            }
          },
          (err: ErrorModel) => {
            resolve('');
            if (err.error) {
              const error: ResponseModel = err.error;
              this._toastrService.error(error.message, '');
            } else {
              this._toastrService.error(this.messageConstant.unknownError, '');
            }
          }
        );
    });
  }
}
