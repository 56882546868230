<div class="addlinkk_modal">
  <div class="row">
    <div class="col d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between"
        style="display: block !important"
      >
        {{ data?.action }} Link
      </h2>
      <span matDialogClose mat-dialog-close style="float: right"
        ><img
          src="assets/images/close-white.svg"
          alt="close"
          style="padding-right: 5px; margin-top: 15px"
      /></span>
    </div>
  </div>
  <form
    [formGroup]="addEditlinkForm"
    (ngSubmit)="onSubmitLink()"
    autocomplete="off"
    novalidate
  >
    <mat-dialog-content class="mat-typography">
      <div class="container">
        <div class="box padd-15">
          <div class="row">
            <div class="col-12 p-0 m-0">
              <div class="form-group">
                <label class="labels required">Title</label>
                <input
                  type="text"
                  class="form-control"
                  name="title"
                  formControlName="title"
                  placeholder="Link title that helps you recall link's purpose quick"
                />

                <!-- VALIDATION ERROR -->
                <div class="text-danger mt-1">
                  <span *ngIf="submitted && hasError('title', 'required')">
                    {{
                      messageConstant.requiredField.replace("Field", "Title")
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 p-0 m-0">
              <div class="form-group">
                <label class="labels required">Hyperlink</label>
                <input
                  type="text"
                  class="form-control"
                  name="hyperLink"
                  formControlName="hyperLink"
                  placeholder="https://www.link-you-want-to-save.com"
                />

                <!-- VALIDATION ERROR -->
                <div class="text-danger mt-1">
                  <span *ngIf="submitted && hasError('hyperLink', 'required')">
                    {{
                      messageConstant.requiredField.replace(
                        "Field",
                        "Hyperlink"
                      )
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <hr style="margin-bottom: 10px !important" />
    <mat-dialog-actions align="center">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" type="submit" cdkFocusInitial>
        Add Link
      </button>
    </mat-dialog-actions>
  </form>
</div>
