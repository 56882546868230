<div
  class="re-under-contract-modal"
  *ngIf="!data?.offerMadeUpdate && !data?.isUnderContract"
>
  <div class="row">
    <div class="col d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between text-uppercase"
        style="display: block !important"
      >
        LEAD STATUS - {{ data?.header }}
      </h2>
      <span mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>
  <form
    [formGroup]="offersForm"
    (ngSubmit)="onSubmit()"
    autocomplete="off"
    novalidate
  >
    <mat-dialog-content class="">
      <div class="container-fluid p-0">
        <div class="box mt-3">
          <!-- APPOINTMENTS -->
          <ng-container *ngIf="isAppointmentVisible">
            <!-- APPOINTMENT CHECK -->
            <div class="row" *ngIf="isAppointmentMainStatusExists">
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels">Did you go on an Appointment?</label>
                  <mat-radio-group aria-label="Select an option">
                    <mat-radio-button
                      class="mr-2"
                      value="true"
                      [checked]="isChooseAppointment == true"
                      (click)="chooseAppointment(true)"
                    >
                      Yes
                    </mat-radio-button>
                    <mat-radio-button
                      class="mr-2"
                      value="false"
                      [checked]="isChooseAppointment == false"
                      (click)="chooseAppointment(false)"
                    >
                      No
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </div>

            <!-- DEFAULT APPOINTMENTS -->
            <ng-container *ngIf="isChooseAppointment">
              <!-- APPOINTMENTS -->
              <div class="row">
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="labels">Appointments</label>
                    <mat-radio-group aria-label="Select an option">
                      <mat-radio-button
                        class="mr-3 mb-2"
                        value="true"
                        (click)="selectAppointmentType(true)"
                        [checked]="isChooseExisting"
                      >
                        Use Existing Appointments
                      </mat-radio-button>

                      <mat-radio-button
                        class="mr-3 mb-2"
                        value="false"
                        (click)="selectAppointmentType(false)"
                        [checked]="!isChooseExisting"
                      >
                        Create New Appointment
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>

              <!-- EXISTING APPOINTMENTS -->
              <div class="row" *ngIf="isChooseExisting">
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <mat-radio-group aria-label="Select an option">
                      <ng-container *ngFor="let appointment of appointments">
                        <mat-radio-button
                          class="mr-3 mb-2"
                          [value]="appointment?._id"
                          (click)="setExistingAppointment(appointment)"
                        >
                          {{ appointment?.title || "NA" }}
                        </mat-radio-button>
                      </ng-container>
                    </mat-radio-group>

                    <p *ngIf="!appointments?.length">No Appointments Found</p>
                  </div>
                </div>
              </div>

              <!-- NEW APPOINTMENT -->
              <div class="row re-new-appointset" *ngIf="!isChooseExisting">
                <app-add-edit-appointments
                  #appointment
                  [isDialog]="false"
                  [appointmentData]="appointmentData"
                  (_emitter)="_emit($event)"
                ></app-add-edit-appointments>
              </div>
            </ng-container>
          </ng-container>

          <div class="row">
            <!-- INITIAL OFFER -->
            <ng-container *ngIf="isOfferVisible">
              <!-- OFFER PRICE -->
              <div class="col-12 col-md-12 col-sm-12">
                <h4 class="re-status-heading">OFFERS MADE</h4>
              </div>
              <div class="col-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="labels">
                    Your Offer Price
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="yourOfferPrice"
                    placeholder="$xx.xx"
                    mask="separator.2"
                    [thousandSeparator]="','"
                    [decimalMarker]="'.'"
                    prefix="$ "
                    [dropSpecialCharacters]="true"
                    (blur)="transformAmount($event)"
                  />

                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="
                        submitted && hasError('yourOfferPrice', 'required')
                      "
                    >
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Your Offer Price"
                        )
                      }}
                    </span>
                    <span
                      *ngIf="submitted && hasError('yourOfferPrice', 'pattern')"
                    >
                      Please enter a valid amount
                    </span>
                  </div>
                </div>
              </div>

              <!-- OFFER TYPE -->
              <div class="col-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="labels">
                    Offer Type
                    <span class="text-danger">*</span>
                  </label>

                  <mat-radio-group
                    aria-label="Select an option"
                    formControlName="offerType"
                  >
                    <mat-radio-button class="mr-2" value="verbal">
                      Verbal
                    </mat-radio-button>
                    <mat-radio-button class="mr-2" value="written">
                      Written
                    </mat-radio-button>
                  </mat-radio-group>

                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="submitted && hasError('offerType', 'required')"
                    >
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "offerType"
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>

              <!-- DATE OF YOUR OFFER -->
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels">Date of Your Offer</label>
                  <p-calendar
                    formControlName="dateOfYourOffer"
                    styleClass="ngCalendarClass"
                    [showIcon]="true"
                    [icon]="'fa fa-calendar'"
                    appendTo="body"
                    [minDate]="leadCreatedDate"
                    [maxDate]="currentDate"
                    (onSelect)="onDate($event, 'offer')"
                    readonlyInput="true"
                  >
                  </p-calendar>
                </div>
              </div>
            </ng-container>

            <!-- UNDER CONTRACT -->
            <ng-container *ngIf="isUnderContractVisible">
              <!-- UNDER CONTRACT DATE -->
              <div class="col-12 col-md-12 col-sm-12">
                <h4 class="re-status-heading">UNDER CONTRACT</h4>
              </div>
              <div class="col-12 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="labels">Under Contract Date</label>
                  <p-calendar
                    formControlName="underContractDate"
                    styleClass="ngCalendarClass"
                    [showIcon]="true"
                    [icon]="'fa fa-calendar'"
                    appendTo="body"
                    [minDate]="
                      offerCreatedDate ? offerCreatedDate : leadCreatedDate
                    "
                    [maxDate]="currentDate"
                    (onSelect)="onDate($event, 'under')"
                    readonlyInput="true"
                  >
                  </p-calendar>
                </div>
              </div>

              <!-- UNDER CONTRACT PRICE -->
              <div class="col-12 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="labels">
                    Under Contract Price
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="underContractPrice"
                    placeholder="$xx.xx"
                    mask="separator.2"
                    [thousandSeparator]="','"
                    [decimalMarker]="'.'"
                    prefix="$ "
                    [dropSpecialCharacters]="true"
                    (blur)="transformAmount($event)"
                  />

                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="
                        submitted && hasError('underContractPrice', 'required')
                      "
                      >{{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Under Contract Price"
                        )
                      }}
                    </span>
                    <span
                      *ngIf="
                        submitted && hasError('underContractPrice', 'pattern')
                      "
                    >
                      Please enter a valid amount
                    </span>
                  </div>
                </div>
              </div>

              <!-- CLOSING DATE -->
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels">Scheduled Closing Date</label>
                  <p-calendar
                    formControlName="closingDate"
                    styleClass="ngCalendarClass"
                    [showIcon]="true"
                    [icon]="'fa fa-calendar'"
                    appendTo="body"
                    readonlyInput="true"
                    [minDate]="
                      underContactCreated
                        ? underContactCreated
                        : leadCreatedDate
                    "
                  >
                  </p-calendar>
                </div>
              </div>

              <!-- EXIT TYPE -->
              <div class="col-12 col-md-12 col-sm-12 re-container">
                <p class="re-label">Exit Type</p>

                <div class="form-group">
                  <label class="labels">
                    Select Exit Type <span class="text-danger">*</span>
                  </label>

                  <select
                    class="form-control"
                    name="existingTypeId"
                    formControlName="existingTypeId"
                  >
                    <option value="">Select Exit Type</option>

                    <option
                      *ngFor="let item of investmentType"
                      [value]="item?._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="
                        submitted && hasError('existingTypeId', 'required')
                      "
                    >
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Exit Type"
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>

              <!-- CONTINGENCY CHECK -->
              <div class="col-12 col-md-12 col-sm-12 re-container">
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="labels">
                      Are there any contingencies in this contract
                    </label>

                    <mat-radio-group
                      aria-label="Select an option"
                      formControlName="contingencyStatus"
                    >
                      <mat-radio-button
                        class="mr-3 mb-2"
                        [ngValue]="true"
                        (click)="selectContingencyStatus(true)"
                        [checked]="isContingencyCheck"
                      >
                        Yes
                      </mat-radio-button>

                      <mat-radio-button
                        class="mr-3 mb-2"
                        [ngValue]="false"
                        (click)="selectContingencyStatus(false)"
                        [checked]="!isContingencyCheck"
                      >
                        No
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="isContingencyCheck">
                <div class="col-12 col-md-12 col-sm-12 re-container">
                  <p class="re-label">Buy Side Contingencies</p>
                </div>

                <div class="col-12" formArrayName="contingencyArray">
                  <div
                    class="form-group row"
                    *ngFor="
                      let reason of offersForm.controls.contingencyArray[
                        'controls'
                      ];
                      let contingencyIndex = index
                    "
                    [formGroupName]="contingencyIndex"
                  >
                    <!-- CONTINGENCY -->
                    <div class="col-12 col-md-6 col-sm-12">
                      <div class="form-group mb-1">
                        <label class="labels" *ngIf="contingencyIndex === 0">
                          Select Contingency <span class="text-danger">*</span>
                        </label>

                        <select
                          class="form-control"
                          name="contingencyTypeId"
                          formControlName="contingencyTypeId"
                        >
                          <option value="">Select Contingency</option>

                          <option
                            *ngFor="let item of contingencies"
                            [value]="item?.value"
                          >
                            {{ item.label }}
                          </option>
                        </select>

                        <div class="text-danger re-error-text">
                          <span
                            *ngIf="
                              submitted &&
                              reason.controls['contingencyTypeId'].hasError(
                                'required'
                              )
                            "
                          >
                            {{
                              messageConstant.requiredField.replace(
                                "Field",
                                "Contingency"
                              )
                            }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- CONTINGENCY EXPIRATION -->
                    <div class="col-12 col-md-6 col-sm-12">
                      <div class="form-group mb-1">
                        <label class="labels" *ngIf="contingencyIndex === 0">
                          Contingency Expiration
                          <span class="text-danger">*</span>
                        </label>

                        <span class="re-input-container">
                          <p-calendar
                            formControlName="contingencyExpiration"
                            styleClass="ngCalendarClass"
                            [showIcon]="true"
                            [icon]="'fa fa-calendar'"
                            appendTo="body"
                            readonlyInput="true"
                            placeholder="Select Contingency Expiration"
                            class="re-input-date"
                          >
                          </p-calendar>

                          <a
                            class="re-list-icon"
                            *ngIf="
                              offersForm.controls.contingencyArray['controls']
                                .length > 0
                            "
                            (click)="deleteContingency(contingencyIndex)"
                          >
                            <img src="/assets/images/delete.png" />
                          </a>
                        </span>

                        <div class="text-danger re-error-text">
                          <span
                            *ngIf="
                              submitted &&
                              reason.controls['contingencyExpiration'].hasError(
                                'required'
                              )
                            "
                          >
                            {{
                              messageConstant.requiredField.replace(
                                "Field",
                                "Contingency Expiration"
                              )
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-danger re-error-text">
                  <span
                    *ngIf="
                      submitted && hasError('contingencyArray', 'required')
                    "
                  >
                    {{
                      messageConstant.requiredField.replace(
                        "Field",
                        "Contingency"
                      )
                    }}
                  </span>
                </div>

                <div class="col-12 re-add-contingency-container">
                  <button
                    (click)="addContingency()"
                    mat-flat-button
                    color="primary"
                    cdkFocusInitial
                    type="button"
                  >
                    Add Contingency
                  </button>
                </div>
              </ng-container>
            </ng-container>

            <!-- ASSIGNED TO BUYER -->
            <ng-container
              *ngIf="
                (isAssignedToBuyerVisible || !isAssignedToSold) &&
                !isReferredToAgentToSold
              "
            >
              <!-- ASSIGNMENT FEE -->
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels">
                    Assignment Fee
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="assignmentFee"
                    placeholder="$xx.xx"
                    mask="separator.2"
                    [thousandSeparator]="','"
                    [decimalMarker]="'.'"
                    prefix="$ "
                    [dropSpecialCharacters]="true"
                    (blur)="transformAmount($event)"
                  />

                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="submitted && hasError('assignmentFee', 'required')"
                      >{{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Assignment Fee"
                        )
                      }}
                    </span>
                    <span
                      *ngIf="submitted && hasError('assignmentFee', 'pattern')"
                    >
                      Please enter a valid amount
                    </span>
                  </div>
                </div>
              </div>

              <!-- Assignment Contract Date -->
              <div
                class="col-12 col-md-12 col-sm-12"
                *ngIf="isAssignedToBuyerVisible"
              >
                <div class="form-group">
                  <label class="labels">Assignment Contract Date</label>
                  <p-calendar
                    formControlName="assignmentContractDate"
                    styleClass="ngCalendarClass"
                    [showIcon]="true"
                    [icon]="'fa fa-calendar'"
                    appendTo="body"
                    [minDate]="
                      assignmentContractMinDate
                        ? assignmentContractMinDate
                        : currentDate
                    "
                    [maxDate]="
                      assignmentContractMaxDate
                        ? assignmentContractMaxDate
                        : currentDate
                    "
                    readonlyInput="true"
                  >
                  </p-calendar>
                </div>
              </div>

              <!-- Contract closing Date -->
              <div
                class="col-12 col-md-12 col-sm-12"
                *ngIf="!isAssignedToBuyerVisible"
              >
                <div class="form-group">
                  <label class="labels">Contract closing Date</label>
                  <p-calendar
                    formControlName="closingDate"
                    styleClass="ngCalendarClass"
                    [showIcon]="true"
                    [icon]="'fa fa-calendar'"
                    appendTo="body"
                    [maxDate]="currentDate"
                    readonlyInput="true"
                  >
                  </p-calendar>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="isReferredToAgentToSold">
              <!-- ASSIGNMENT FEE -->
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels">
                    Referral Fee
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="referralFee"
                    placeholder="$xx.xx"
                    mask="separator.2"
                    [thousandSeparator]="','"
                    [decimalMarker]="'.'"
                    prefix="$ "
                    [dropSpecialCharacters]="true"
                    (blur)="transformAmount($event)"
                  />

                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="submitted && hasError('referralFee', 'required')"
                      >{{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Referral Fee"
                        )
                      }}
                    </span>
                    <span
                      *ngIf="submitted && hasError('referralFee', 'pattern')"
                    >
                      Please enter a valid amount
                    </span>
                  </div>
                </div>
              </div>

              <!-- Property Closing Date -->
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels">Property Sold Date</label>
                  <p-calendar
                    formControlName="closingDate"
                    styleClass="ngCalendarClass"
                    [showIcon]="true"
                    [icon]="'fa fa-calendar'"
                    appendTo="body"
                    [maxDate]="currentDate"
                    readonlyInput="true"
                  >
                  </p-calendar>
                </div>
              </div>
            </ng-container>
          </div>

          <ng-container *ngIf="isDeadReasonVisible">
            <div class="row">
              <!-- DEFAULT REASONS -->
              <div
                class="col-12 col-md-12 col-sm-12 re-checkboxlist"
                formArrayName="deadReasonIds"
              >
                <div
                  class="form-group"
                  *ngFor="
                    let reason of offersForm.controls.deadReasonIds['controls'];
                    let reasonIndex = index
                  "
                  [formGroupName]="reasonIndex"
                >
                  <label class="checkboxbtn">
                    <input
                      type="checkbox"
                      [name]="'deadReasonIds'"
                      formControlName="selected"
                      [value]="reason.value"
                    />
                    <span class="re-checkmark"></span>
                    {{ getValue(reasonIndex) }}
                  </label>
                </div>
              </div>

              <!-- OTHERS -->
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels">Other Reasons</label>
                  <textarea
                    type="text"
                    class="form-control"
                    formControlName="deadReasonOtherText"
                    placeholder="Other Reasons"
                  ></textarea>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="isInventoryVisible">
            <div class="row">
              <!-- INVESTMENT TYPE -->
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels"
                    >Investment Type <span class="text-danger">*</span></label
                  >
                  <select
                    class="form-control"
                    name="projectTypeId"
                    formControlName="projectTypeId"
                  >
                    <option value="">Select Investment Type</option>
                    <option
                      *ngFor="let item of investmentType"
                      [value]="item?._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="submitted && hasError('projectTypeId', 'required')"
                      >{{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Investment Type"
                        )
                      }}</span
                    >
                  </div>
                </div>
              </div>

              <!-- PROJECT STATUS -->
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels">
                    Project Status
                    <span class="text-danger">*</span>
                  </label>
                  <select
                    class="form-control re-capitalize"
                    name="projectStatus"
                    formControlName="mainStatusId"
                    (change)="onChangeInventoryStatus($event.target.value)"
                  >
                    <option value="">Select Inventory Status</option>
                    <option
                      *ngFor="let item of projectStatus"
                      [value]="item?._id"
                    >
                      {{ item?.title }}
                    </option>
                  </select>
                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="submitted && hasError('mainStatusId', 'required')"
                    >
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Project Status"
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>

              <!-- PURCHASE DATE -->
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels">Purchase Date</label>
                  <p-calendar
                    formControlName="purchaseDate"
                    styleClass="ngCalendarClass"
                    appendTo="body"
                    [showIcon]="true"
                    [icon]="'fa fa-calendar'"
                    [maxDate]="purchaseDateMax"
                    [minDate]="purchaseDateMin"
                    readonlyInput="true"
                  ></p-calendar>

                  <!--<span
                    class="fa fa-calendar form-control-feedback opacity-05"
                    aria-hidden="true"
                  ></span>-->
                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="submitted && hasError('purchaseDate', 'required')"
                      >{{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Purchase Date"
                        )
                      }}</span
                    >
                  </div>
                </div>
              </div>

              <!-- PURCHASE PRICE -->
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels">
                    Purchase Price
                    <span class="text-danger">*</span>
                  </label>
                  <div class="customdisplay">
                    <input
                      type="text"
                      class="form-control amounts"
                      formControlName="purchasePrice"
                      placeholder="Purchase Price"
                      placeholder="$xx.xx"
                      mask="separator.2"
                      [thousandSeparator]="','"
                      [decimalMarker]="'.'"
                      prefix="$ "
                      [dropSpecialCharacters]="true"
                      (blur)="transformAmount($event)"
                    />
                    <!--<span class="fa fa-dollar form-control-feedback"></span>-->
                    <div class="text-danger re-error-text">
                      <span
                        *ngIf="
                          submitted && hasError('purchasePrice', 'required')
                        "
                        >{{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Purchase Price"
                          )
                        }}
                      </span>
                      <span
                        *ngIf="
                          submitted && hasError('purchasePrice', 'pattern')
                        "
                      >
                        Please enter a valid amount
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="isAnalyticsInfoExists()">
              <div class="col-12 col-md-12 col-sm-12">
                <h4 class="inventoradd_title">INVENTORY ANALYTICS INFO</h4>
              </div>

              <ng-container *ngIf="isUnderRehab">
                <!-- REHAB ESTIMATE STARTED -->
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="labels"> Rehab Estimate Started </label>
                    <p-calendar
                      formControlName="rehabStart"
                      styleClass="ngCalendarClass"
                      [showIcon]="true"
                      [icon]="'fa fa-calendar'"
                      appendTo="body"
                      readonlyInput="true"
                    >
                    </p-calendar>

                    <div class="text-danger re-error-text">
                      <span
                        *ngIf="submitted && hasError('rehabStart', 'required')"
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Rehab Estimate Started Date"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>

                <!-- ESTIMATED REHAB END DATE -->
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="labels">Estimated Rehab End Date</label>
                    <p-calendar
                      formControlName="rehabFinish"
                      styleClass="ngCalendarClass"
                      [showIcon]="true"
                      [icon]="'fa fa-calendar'"
                      appendTo="body"
                      readonlyInput="true"
                    >
                    </p-calendar>

                    <div class="text-danger re-error-text">
                      <span
                        *ngIf="submitted && hasError('rehabFinish', 'required')"
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Estimated Rehab End Date"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!-- LISTED FOR SALE -->
              <ng-container *ngIf="isListedForSale">
                <!-- WHEN DID PROPERTY GO ON MARKET -->
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="labels">
                      When did property go on Market?
                    </label>
                    <p-calendar
                      formControlName="goingOnMarket"
                      styleClass="ngCalendarClass"
                      [showIcon]="true"
                      [icon]="'fa fa-calendar'"
                      appendTo="body"
                      readonlyInput="true"
                    >
                    </p-calendar>

                    <div class="text-danger re-error-text">
                      <span
                        *ngIf="
                          submitted && hasError('goingOnMarket', 'required')
                        "
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Date is required"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>

                <!-- LISTED PRICE -->
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="labels">
                      Listed Price
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="listedPrice"
                      placeholder="$xx.xx"
                      mask="separator.2"
                      [thousandSeparator]="','"
                      [decimalMarker]="'.'"
                      prefix="$ "
                      [dropSpecialCharacters]="true"
                      (blur)="transformAmount($event)"
                    />

                    <div class="text-danger re-error-text">
                      <span
                        *ngIf="submitted && hasError('listedPrice', 'required')"
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Listed Price"
                          )
                        }}
                      </span>
                      <span
                        *ngIf="submitted && hasError('listedPrice', 'pattern')"
                      >
                        Please enter a valid amount
                      </span>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!-- UNDER CONTRACT -->
              <ng-container *ngIf="isUnderContract">
                <!-- WHEN DID PROPERTY GO UNDER CONTRACT -->
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="labels">
                      When did property go Under Contract
                    </label>
                    <p-calendar
                      formControlName="underCoGoing"
                      styleClass="ngCalendarClass"
                      [showIcon]="true"
                      [icon]="'fa fa-calendar'"
                      appendTo="body"
                      readonlyInput="true"
                    >
                    </p-calendar>

                    <div class="text-danger re-error-text">
                      <span
                        *ngIf="
                          submitted && hasError('underCoGoing', 'required')
                        "
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Under Contract Date"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>

                <!-- UNDER CONTRACT PRICE -->
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="labels">
                      Under Contract Price
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="underCoPrice"
                      placeholder="$xx.xx"
                      mask="separator.2"
                      [thousandSeparator]="','"
                      [decimalMarker]="'.'"
                      prefix="$ "
                      [dropSpecialCharacters]="true"
                      (blur)="transformAmount($event)"
                    />

                    <div class="text-danger re-error-text">
                      <span
                        *ngIf="
                          submitted && hasError('underCoPrice', 'required')
                        "
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Under Contract Price"
                          )
                        }}
                      </span>
                      <span
                        *ngIf="submitted && hasError('underCoPrice', 'pattern')"
                      >
                        Please enter a valid amount
                      </span>
                    </div>
                  </div>
                </div>

                <!-- EXPECTED CLOSING DATE -->
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="labels">Expected Closing Date</label>
                    <p-calendar
                      formControlName="underCoClosingDate"
                      styleClass="ngCalendarClass"
                      [showIcon]="true"
                      [icon]="'fa fa-calendar'"
                      appendTo="body"
                      readonlyInput="true"
                    >
                    </p-calendar>

                    <div class="text-danger re-error-text">
                      <span
                        *ngIf="
                          submitted &&
                          hasError('underCoClosingDate', 'required')
                        "
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Expected Closing Date"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <!-- RENTAL -->
          <ng-container *ngIf="isRental">
            <div class="row">
              <!-- MONTHLY RENT PRICE -->
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels">
                    Monthly Rent
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="monthlyRent"
                    placeholder="$xx.xx"
                    mask="separator.2"
                    [thousandSeparator]="','"
                    [decimalMarker]="'.'"
                    prefix="$ "
                    [dropSpecialCharacters]="true"
                    (blur)="transformAmount($event)"
                  />

                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="submitted && hasError('monthlyRent', 'required')"
                    >
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Monthly Rent"
                        )
                      }}
                    </span>
                    <span
                      *ngIf="submitted && hasError('monthlyRent', 'pattern')"
                    >
                      Please enter a valid amount
                    </span>
                  </div>
                </div>
              </div>

              <!-- LEASE TYPE -->
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels">
                    Lease Type
                    <span class="text-danger">*</span>
                  </label>
                  <select class="form-control" formControlName="leaseType">
                    <option
                      [attr.disabled]="isMarketReadonly ? 'disabled' : null"
                      [selected]
                      value=""
                    >
                      ---- Please Select ----
                    </option>
                    <option value="Annual">Annual</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Custom">Custom</option>
                  </select>

                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="submitted && hasError('leaseType', 'required')"
                    >
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Lease Type"
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>

              <!-- LEASE START DATE -->
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels">Lease Start Date</label>
                  <p-calendar
                    formControlName="leaseStartDate"
                    styleClass="ngCalendarClass"
                    [showIcon]="true"
                    [icon]="'fa fa-calendar'"
                    appendTo="body"
                    readonlyInput="true"
                  >
                  </p-calendar>

                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="
                        submitted && hasError('leaseStartDate', 'required')
                      "
                    >
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Lease Start Date"
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>

              <!-- LEASE END DATE -->
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels">Lease End Date</label>
                  <p-calendar
                    formControlName="leaseEndDate"
                    styleClass="ngCalendarClass"
                    [showIcon]="true"
                    [icon]="'fa fa-calendar'"
                    appendTo="body"
                    readonlyInput="true"
                  >
                  </p-calendar>

                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="submitted && hasError('leaseEndDate', 'required')"
                    >
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Lease End Date"
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- SOLD -->
          <ng-container *ngIf="isSold">
            <div class="row">
              <!-- INVESTMENT TYPE -->
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels"
                    >Investment Type <span class="text-danger">*</span></label
                  >
                  <select
                    class="form-control"
                    name="projectTypeId"
                    formControlName="projectTypeId"
                  >
                    <option value="">Select Investment Type</option>
                    <option
                      *ngFor="let item of investmentType"
                      [value]="item?._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="submitted && hasError('projectTypeId', 'required')"
                      >{{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Investment Type"
                        )
                      }}</span
                    >
                  </div>
                </div>
              </div>

              <!-- PROJECT STATUS -->
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels">
                    Project Status
                    <span class="text-danger">*</span>
                  </label>
                  <select
                    class="form-control re-capitalize"
                    name="projectStatus"
                    formControlName="mainStatusId"
                    (change)="onChangeInventoryStatus($event.target.value)"
                    [attr.disabled]="isSold ? true : null"
                  >
                    <option value="">Select Inventory Status</option>
                    <option
                      *ngFor="let item of projectStatus"
                      [value]="item?._id"
                    >
                      {{ item?.title }}
                    </option>
                  </select>
                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="submitted && hasError('mainStatusId', 'required')"
                      >{{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Project Status"
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <!-- PURCHASE DATE -->
              <div class="col-12 col-md-12 col-sm-12" *ngIf="isAssignedToSold">
                <div class="form-group">
                  <label class="labels">Purchase Date</label>
                  <p-calendar
                    formControlName="purchaseDate"
                    styleClass="ngCalendarClass"
                    appendTo="body"
                    [showIcon]="true"
                    [icon]="'fa fa-calendar'"
                    [maxDate]="purchaseDateMax"
                    [minDate]="purchaseDateMin"
                    readonlyInput="true"
                  ></p-calendar>

                  <!--<span
                    class="fa fa-calendar form-control-feedback opacity-05"
                    aria-hidden="true"
                  ></span>-->
                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="submitted && hasError('purchaseDate', 'required')"
                      >{{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Purchase Date"
                        )
                      }}</span
                    >
                  </div>
                </div>
              </div>

              <!-- PURCHASE PRICE -->
              <div class="col-12 col-md-12 col-sm-12" *ngIf="isAssignedToSold">
                <div class="form-group">
                  <label class="labels">
                    Purchase Price
                    <span class="text-danger">*</span>
                  </label>
                  <div class="customdisplay">
                    <input
                      type="text"
                      class="form-control amounts"
                      formControlName="purchasePrice"
                      placeholder="Purchase Price"
                      placeholder="$xx.xx"
                      mask="separator.2"
                      [thousandSeparator]="','"
                      [decimalMarker]="'.'"
                      prefix="$ "
                      [dropSpecialCharacters]="true"
                      (blur)="transformAmount($event)"
                    />
                    <!--<span class="fa fa-dollar form-control-feedback"></span>-->
                    <div class="text-danger re-error-text">
                      <span
                        *ngIf="
                          submitted && hasError('purchasePrice', 'required')
                        "
                        >{{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Purchase Price"
                          )
                        }}
                      </span>
                      <span
                        *ngIf="
                          submitted && hasError('purchasePrice', 'pattern')
                        "
                      >
                        Please enter a valid amount
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- SALE PRICE -->
              <div class="col-12 col-md-12 col-sm-12" *ngIf="isAssignedToSold">
                <div class="form-group">
                  <label class="labels">
                    Sale Price
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="salesPrice"
                    placeholder="$xx.xx"
                    mask="separator.2"
                    [thousandSeparator]="','"
                    [decimalMarker]="'.'"
                    prefix="$ "
                    [dropSpecialCharacters]="true"
                    (blur)="transformAmount($event)"
                  />

                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="submitted && hasError('salesPrice', 'required')"
                    >
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Sale Price"
                        )
                      }}
                    </span>
                    <span
                      *ngIf="submitted && hasError('salesPrice', 'pattern')"
                    >
                      Please enter a valid amount
                    </span>
                  </div>
                </div>
              </div>

              <!-- SALE DATE -->
              <div class="col-12 col-md-12 col-sm-12" *ngIf="isAssignedToSold">
                <div class="form-group">
                  <label class="labels">Sale Date</label>
                  <p-calendar
                    formControlName="salesDate"
                    styleClass="ngCalendarClass"
                    [showIcon]="true"
                    [icon]="'fa fa-calendar'"
                    appendTo="body"
                    readonlyInput="true"
                    [maxDate]="saleDateMax"
                  >
                  </p-calendar>

                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="submitted && hasError('salesDate', 'required')"
                    >
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Sale Date"
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>

              <!-- NET PROFIT -->
              <div class="col-12 col-md-12 col-sm-12" *ngIf="isAssignedToSold">
                <div class="form-group">
                  <label class="labels">
                    $Profit on Deal
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="netProfit"
                    placeholder="$xx.xx"
                    mask="separator.2"
                    [thousandSeparator]="','"
                    [decimalMarker]="'.'"
                    prefix="$ "
                    [dropSpecialCharacters]="true"
                    [allowNegativeNumbers]="true"
                    (blur)="transformAmount($event)"
                  />

                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="submitted && hasError('netProfit', 'required')"
                    >
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Net Profit"
                        )
                      }}
                    </span>
                    <span
                      *ngIf="submitted && hasError('netProfit', 'pattern')"
                    >
                      Please enter a valid amount
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- <ng-container>
            <div class="row">
              <div class="col-12 col-md-12 col-sm-12" *ngIf="isTaskVisible">
                <div class="form-group re-status-div">
                  <label class="labels"
                    >There are some pending tasks for this lead. What would you
                    like to do with the pending tasks?</label
                  >
                  <div class="radiolist">
                    <label class="radiobtn">
                      Mark everyone's pending tasks as completed
                      <input
                        type="radio"
                        name="dripstop"
                        value="false"
                        (click)="chooseDripStop(false)"
                        checked
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="radiobtn">
                      Leave all pending task as pending
                      <input
                        type="radio"
                        name="dripstop"
                        value="true"
                        (click)="chooseDripStop(true)"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="radiobtn">
                      Mark only my pending task as completed
                      <input
                        type="radio"
                        name="dripstop"
                        value="true"
                        (click)="chooseDripStop(true)"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div
                class="col-12 col-md-12 col-sm-12"
                *ngIf="
                  (isContactMadeDripVisible ||
                    isNoContactMadeDripVisible ||
                    isAppointmentDripVisible ||
                    isOfferMadeDripVisible ||
                    isUnderContractVisible ||
                    isAssignedToBuyerVisible ||
                    isInventoryVisible ||
                    isDeadReasonVisible ||
                    isWarmLeadDripVisible ||
                    isSold) &&
                  data?.item?.dripId
                "
              >
                <div class="form-group">
                  <label class="labels"
                    >There is an active drip campaign assigned to this property,
                    would you like to stop it or keep it?</label
                  >
                  <div class="radiolist">
                    <label class="radiobtn">
                      Keep the Drip
                      <input
                        type="radio"
                        name="dripstop"
                        value="false"
                        (click)="chooseDripStop(false)"
                        checked
                      />
                      <span class="checkmark"></span>
                    </label>
                    <label class="radiobtn">
                      Stop the Drip
                      <input
                        type="radio"
                        name="dripstop"
                        value="true"
                        (click)="chooseDripStop(true)"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </ng-container> -->
        </div>
      </div>
    </mat-dialog-content>
    <hr style="margin-bottom: 10px !important" />
    <mat-dialog-actions align="center">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" cdkFocusInitial type="submit">
        Update Status
      </button>
    </mat-dialog-actions>
  </form>
</div>
<div class="re-under-contract-modal" *ngIf="data?.offerMadeUpdate">
  <div class="">
    <div class="col-md-12 col-xs-12 col-12 offerMadeUpdateCard">
      <span mat-dialog-close class="re-closee-con">
        <img src="assets/images/close-black.png" alt="close" />
      </span>
      <div class="re-crossed-div">
        <img
          src="assets/images/crossed-fingers-v2.png"
          alt="close"
          class="crossed-fingers"
        />
      </div>
      <img
        src="assets/images/leadstatuschange_font2.png"
        alt="close"
        class="crossed-fingers-font"
      />
      <img class="logo-img" src="assets/images/resimpli_offer_logo.png" />
    </div>
  </div>
</div>
<div class="re-under-contract-modal" *ngIf="data?.isUnderContract">
  <div class="">
    <div class="col-md-12 col-xs-12 col-12 UnderContractCard">
      <span mat-dialog-close class="re-closee-con">
        <img src="assets/images/close-black.png" alt="close" />
      </span>
      <div class="re-boxesss">
        <img
          class="logo-img1"
          src="assets/images/leadStatusChange_Congrats.png"
        />
        <img class="logo-img2" src="assets/images/group-9101.png" />
      </div>
    </div>
  </div>
</div>
