<mat-dialog-content>
  <div class="re-dlc-main">
    <div class="re-dlc-left">
      <img
        class="re-img"
        src="assets/images/a2p-circle.svg"
        alt="a2p"
        width="210"
      />
      <div class="re-a2p-div">
        <div class="re-text1">A2P 10DLC</div>
        <div class="re-text2">
          Registration Deadline <span>Mar 31, 2024</span>
        </div>
      </div>
      <div class="text-left">
        <div class="re-impact-title">Impact</div>
        <div class="re-impact-text">
          Your outgoing SMS on REsimpli will be impacted if 10DLC is pending
          post Mar 31, 2024.
        </div>
      </div>
    </div>
    <div class="re-dlc-right">
      <div class="re-status-info">
        <img
          class="re-closeimg"
          src="assets/images/close-black-1.svg"
          alt="close"
          width="20"
          mat-dialog-close
        />
        <div class="re-status-title">Your 10DLC Status</div>
        <div class="re-status-label">Submitted - Missing Information</div>
        <div class="re-status-text">
          Your initial submission to Plivo was on
          {{
            _utilities.dlcForm?.createdAt
              ? (_utilities.dlcForm?.createdAt | date : "MMM dd, yyyy")
              : "N/A"
          }}. We need to submit brand and campaign to Twilio.
        </div>
        <div class="re-results-title">Twilio Submission Timeline - Results</div>
        <div class="re-timeline-div">
          <div class="re-timelines re-done-time">
            <div class="re-tline-title">Brand Submission</div>
            <div class="re-tline-date">
              {{
                _utilities.dlcForm?.twilioProfileData?.createdAt
                  ? (_utilities.dlcForm?.twilioProfileData?.createdAt
                    | date : "MMM dd, yyyy")
                  : "N/A"
              }}
            </div>
            <div class="re-tline-status {{ getBrandClass() }}">
              {{
                _utilities.dlcForm?.twilioProfileData?.brandStatus
                  ? _utilities.dlcForm?.twilioProfileData?.brandStatus
                  : "N/A"
              }}
            </div>
            <div class="re-tline-text">
              {{ getBrandMessage() }}
            </div>
          </div>
          <div class="re-timelines">
            <div class="re-tline-title">Campaign Status</div>
            <div class="re-tline-date">
              {{
                _utilities.dlcForm?.twilioProfileData?.createdAt
                  ? (_utilities.dlcForm?.twilioProfileData?.createdAt
                    | date : "MMM dd, yyyy")
                  : "N/A"
              }}
            </div>
            <div class="re-tline-status {{ getCampaignClass() }}">
              {{
                _utilities.dlcForm?.twilioProfileData?.campaignStatus
                  ? _utilities.dlcForm?.twilioProfileData?.campaignStatus
                  : "N/A"
              }}
            </div>
            <div
              class="re-tline-text"
              *ngIf="
                _utilities.dlcForm?.twilioProfileData?.campaignStatus ==
                'FAILED'
              "
            >
              Your campaign registration has failed.
            </div>
            <div
              class="re-tline-text"
              *ngIf="
                _utilities.dlcForm?.twilioProfileData?.campaignStatus !=
                'FAILED'
              "
            >
              Your campaign was not submitted because we require a functional
              website that includes an Opt-In checkbox.
            </div>
          </div>
        </div>
      </div>
      <div class="re-helps-div">
        <div class="re-helps-title">We’re here to help</div>
        <div class="re-helps-text">
          Please contact Jewel at

          <span class="re-mailid">
            <a
              href="mailto:jewel@resimpli.com"
              target="_blank"
              rel="noopener noreferrer"
              >jewel@resimpli.com
            </a></span
          >
          with your email and subject being 10DLC status. She will guide you
          through the process.
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
