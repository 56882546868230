<div class="re-set-availability-modal">
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        style="display: block !important"
        class="w-100 d-flex justify-content-between re-header"
      >
        Set Availability
      </h2>
      <span matDialogClose mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-right: -5px; margin-top: 15px"
        />
      </span>
    </div>
  </div>
  <form
    [formGroup]="availabilityForm"
    (ngSubmit)="onSubmit()"
    autocomplete="off"
    novalidate
  >
    <mat-dialog-content class="re-typography">
      <div class="box">
        <!-- TIME ZONE -->
        <div class="row mb-4">
          <div class="col-12 col-md-12 re-maintitle">SELECT TIMEZONE</div>
          <div class="col-12 col-md-2 re-label-container">
            <label class="labels"> Time Zone </label>
          </div>
          <div class="col-12 col-md-10">
            <div class="form-group mb-0">
              <select
                name="timezoneOffset"
                [(ngModel)]="availabilityTimezoneOffset"
                class="form-control re-res-select"
                [ngModelOptions]="{ standalone: true }"
              >
                <option value="">Select Timezone</option>
                <option
                  *ngFor="let timezone of timezoneList"
                  [value]="timezone?.timezonename"
                >
                  {{ timezone?.name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="row re-mobile-hide">
          <div class="col-12 col-md-2">DAY</div>
          <div class="col-12 col-md-5 mb-2 text-center">AVAILABILITY</div>
          <div class="col-12 col-md-5 mb-2 text-center">
            TIME AVAILABLE (FROM/TO)
          </div>
        </div>

        <!-- WEEK -->
        <ng-container *ngFor="let day of week">
          <div class="row" [formGroupName]="day?.value">
            <div class="col-6 col-md-2 col-sm-6 re-week-name">
              {{ day?.label }}
            </div>

            <!-- AVAILABILITY -->
            <div class="col-6 col-md-5 col-sm-6 re-availability text-center">
              <label class="switch">
                <input
                  type="checkbox"
                  id="checkbox1"
                  name="availability"
                  [(ngModel)]="day.isSetAvailability"
                  (change)="toggleAvailability(day)"
                  [ngModelOptions]="{ standalone: true }"
                />
                <span class="slider"></span>
              </label>
            </div>

            <div class="col-12 col-md-5 col-sm-12">
              <div class="row">
                <!-- START TIME -->
                <div class="col-6 col-md-6 col-sm-6">
                  <div class="form-group">
                    <!-- <label class="labels required"> Start Time </label> -->
                    <p-calendar
                      [timeOnly]="true"
                      appendTo="body"
                      hourFormat="12"
                      formControlName="startTime"
                      styleClass="ngCalendarClass"
                      (onSelect)="changeDate(day?.value, 'endTime')"
                      [disabled]="!day?.isSetAvailability"
                      [stepMinute]="30"
                      [readonlyInput]="true"
                    ></p-calendar>

                    <div class="text-danger">
                      <span
                        *ngIf="hasError(day?.value, 'startTime', 'required')"
                      >
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Start Time"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>

                <!-- END TIME -->
                <div class="col-6 col-md-6 col-sm-6">
                  <div class="form-group">
                    <!-- <label class="labels required">End Time</label> -->
                    <p-calendar
                      [timeOnly]="true"
                      appendTo="body"
                      hourFormat="12"
                      formControlName="endTime"
                      styleClass="ngCalendarClass"
                      [minDate]="getMinDate(day?.value)"
                      (onSelect)="changeDate(day?.value, 'startTime')"
                      [disabled]="!day?.isSetAvailability"
                      [stepMinute]="30"
                      [readonlyInput]="true"
                    ></p-calendar>

                    <div class="text-danger">
                      <span *ngIf="hasError(day?.value, 'endTime', 'required')">
                        {{
                          messageConstant.requiredField.replace(
                            "Field",
                            "End Time"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center" style="padding-top: 15px !important">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" cdkFocusInitial type="submit">
        Set Availability
      </button>
    </mat-dialog-actions>
  </form>
</div>
