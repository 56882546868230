<div class="re-under-contract-modal">
  <div class="dialog-header">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        class="w-100 d-flex justify-content-between"
        style="display: block !important"
      >
        Wholesale Status - {{ _commonFunction.titleCaseToWord(data?.header) }}
      </h2>
      <span mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>
  <form
    [formGroup]="wholeSaleStatusForm"
    (ngSubmit)="onSubmit()"
    autocomplete="off"
    novalidate
  >
    <mat-dialog-content class="re-typography">
      <div class="container">
        <div class="box mt-3">
          <div class="row">
            <ng-container *ngIf="isMarketingBuyers">
              <div class="col-12 col-md-12 col-sm-12">
                <h4 class="re-status-heading">Marketing to Buyers</h4>
                <div class="form-group">
                  <label class="labels"
                    >When did you start marketing to buyers?
                    <span class="text-danger">*</span></label
                  >
                  <p-calendar
                    styleClass="ngCalendarClass"
                    appendTo="body"
                    [icon]="'fa fa-calendar'"
                    formControlName="marketingToBuyersStartDate"
                    placeholder="Select Date"
                    dateFormat="MM d, yy"
                    [showButtonBar]="true"
                    [showIcon]="true"
                  ></p-calendar>
                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="
                        submitted &&
                        wholeSaleStatusForm.controls[
                          'marketingToBuyersStartDate'
                        ].hasError('required')
                      "
                    >
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Marketing to buyers start date"
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels"
                    >How much are you asking for the property?
                    <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="askForProperty"
                    placeholder="$xx.xx"
                    mask="separator.2"
                    [thousandSeparator]="','"
                    [decimalMarker]="'.'"
                    prefix="$ "
                    [dropSpecialCharacters]="true"
                    (blur)="transformAmount($event)"
                  />
                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="
                        submitted && hasError('askForProperty', 'required')
                      "
                    >
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Ask for the Property"
                        )
                      }}
                    </span>
                  </div>
                </div>
                <hr />
              </div>
            </ng-container>
            <ng-container *ngIf="isShowingBuyers">
              <div class="col-12 col-md-12 col-sm-12">
                <h4 class="re-status-heading">Showing to Buyers</h4>
                <div class="form-group">
                  <label class="labels"
                    >When is buyer showing schedule?<span class="text-danger"
                      >*</span
                    ></label
                  >
                  <p-calendar
                    styleClass="ngCalendarClass"
                    appendTo="body"
                    [icon]="'fa fa-calendar'"
                    formControlName="buyerShowingSchedule"
                    placeholder="Select Date"
                    dateFormat="MM d, yy"
                    [showButtonBar]="true"
                    [showIcon]="true"
                  ></p-calendar>
                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="
                        submitted &&
                        wholeSaleStatusForm.controls[
                          'buyerShowingSchedule'
                        ].hasError('required')
                      "
                    >
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Buyer showing schedule"
                        )
                      }}
                    </span>
                  </div>
                </div>
                <hr />
              </div>
            </ng-container>
            <ng-container *ngIf="isEvaluatingOffers">
              <div class="col-12 col-md-12 col-sm-12">
                <h4 class="re-status-heading">Evaluating Offers</h4>
                <div class="form-group">
                  <label class="labels"
                    >When did you receive first offer on the property?<span
                      class="text-danger"
                      >*</span
                    ></label
                  >
                  <p-calendar
                    styleClass="ngCalendarClass"
                    appendTo="body"
                    [icon]="'fa fa-calendar'"
                    formControlName="firstOfferDate"
                    placeholder="Select Date"
                    dateFormat="MM d, yy"
                    [showButtonBar]="true"
                    [showIcon]="true"
                    [maxDate]="maxDateTime"
                  ></p-calendar>
                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="
                        submitted &&
                        wholeSaleStatusForm.controls['firstOfferDate'].hasError(
                          'required'
                        )
                      "
                    >
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "First offer date"
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels"
                    >How much is the LOWEST offer on the property?<span
                      class="text-danger"
                      >*</span
                    ></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="lowestOffer"
                    placeholder="$xx.xx"
                    mask="separator.2"
                    [thousandSeparator]="','"
                    [decimalMarker]="'.'"
                    prefix="$ "
                    [dropSpecialCharacters]="true"
                    (blur)="transformAmount($event)"
                  />
                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="
                        submitted &&
                        wholeSaleStatusForm.controls['lowestOffer'].hasError(
                          'required'
                        )
                      "
                    >
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Lowest offer price"
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels"
                    >How much is the HIGHEST offer on the property?<span
                      class="text-danger"
                      >*</span
                    ></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="highestOffer"
                    placeholder="$xx.xx"
                    mask="separator.2"
                    [thousandSeparator]="','"
                    [decimalMarker]="'.'"
                    prefix="$ "
                    [dropSpecialCharacters]="true"
                    (blur)="transformAmount($event)"
                  />
                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="
                        submitted &&
                        wholeSaleStatusForm.controls['highestOffer'].hasError(
                          'required'
                        )
                      "
                    >
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Highest offer price"
                        )
                      }}
                    </span>
                  </div>
                </div>
                <hr />
              </div>
            </ng-container>
            <ng-container *ngIf="isAcceptedOffer">
              <div class="col-12 col-md-12 col-sm-12">
                <h4 class="re-status-heading">Accepted Offer</h4>
                <div class="form-group">
                  <label class="labels"
                    >When did you accept the offer?<span class="text-danger"
                      >*</span
                    ></label
                  >
                  <p-calendar
                    styleClass="ngCalendarClass"
                    appendTo="body"
                    [icon]="'fa fa-calendar'"
                    formControlName="acceptOfferDate"
                    placeholder="Select Date"
                    dateFormat="MM d, yy"
                    [showButtonBar]="true"
                    [showIcon]="true"
                    [maxDate]="maxDateTime"
                  ></p-calendar>
                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="
                        submitted &&
                        wholeSaleStatusForm.controls[
                          'acceptOfferDate'
                        ].hasError('required')
                      "
                    >
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Accepted offer date"
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels"
                    >How much is your expected profit from this deal?<span
                      class="text-danger"
                      >*</span
                    ></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="buyingOfferPrice"
                    placeholder="$xx.xx"
                    mask="separator.2"
                    [thousandSeparator]="','"
                    [decimalMarker]="'.'"
                    prefix="$ "
                    [dropSpecialCharacters]="true"
                    (blur)="transformAmount($event)"
                  />
                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="
                        submitted &&
                        wholeSaleStatusForm.controls[
                          'buyingOfferPrice'
                        ].hasError('required')
                      "
                    >
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Buying price"
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-12 col-sm-12 re-container">
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="labels">
                      Are there any contingencies in this contract?<span
                        class="text-danger"
                        >*</span
                      >
                    </label>

                    <mat-radio-group
                      aria-label="Select an option"
                      formControlName="contingencyStatus"
                    >
                      <mat-radio-button
                        class="mr-3 mb-2"
                        [ngValue]="true"
                        (click)="selectContingencyStatus(true)"
                        [checked]="isContingencyCheck"
                      >
                        Yes
                      </mat-radio-button>

                      <mat-radio-button
                        class="mr-3 mb-2"
                        [ngValue]="false"
                        (click)="selectContingencyStatus(false)"
                        [checked]="!isContingencyCheck"
                      >
                        No
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="isContingencyCheck">
                <div class="col-12 col-md-12 col-sm-12 re-container">
                  <p class="re-label">Sell Side Contingencies</p>
                </div>

                <div class="col-12" formArrayName="contingencyArray">
                  <div
                    class="form-group row"
                    *ngFor="
                      let reason of wholeSaleStatusForm.controls
                        .contingencyArray['controls'];
                      let contingencyIndex = index
                    "
                    [formGroupName]="contingencyIndex"
                  >
                    <!-- CONTINGENCY -->
                    <div class="col-12 col-md-6 col-sm-12">
                      <div class="form-group mb-1">
                        <label class="labels" *ngIf="contingencyIndex === 0">
                          Select Contingency
                          <span class="text-danger">*</span>
                        </label>

                        <select
                          class="form-control"
                          name="contingencyTypeId"
                          formControlName="contingencyTypeId"
                        >
                          <option value="">Select Contingency</option>

                          <option
                            *ngFor="let item of contingencies"
                            [value]="item?.value"
                          >
                            {{ item.label }}
                          </option>
                        </select>

                        <div class="text-danger re-error-text">
                          <span
                            *ngIf="
                              submitted &&
                              reason.controls['contingencyTypeId'].hasError(
                                'required'
                              )
                            "
                          >
                            {{
                              messageConstant.requiredField.replace(
                                "Field",
                                "Contingency"
                              )
                            }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- CONTINGENCY EXPIRATION -->
                    <div class="col-12 col-md-6 col-sm-12">
                      <div class="form-group mb-1">
                        <label class="labels" *ngIf="contingencyIndex === 0">
                          Contingency Expiration
                          <span class="text-danger">*</span>
                        </label>

                        <span class="re-input-container">
                          <p-calendar
                            formControlName="contingencyExpiration"
                            dateFormat="MM d, yy"
                            styleClass="ngCalendarClass"
                            [showIcon]="true"
                            [icon]="'fa fa-calendar'"
                            appendTo="body"
                            readonlyInput="true"
                            placeholder="Select Contingency Expiration"
                            class="re-input-date"
                          >
                          </p-calendar>

                          <a
                            class="re-list-icon"
                            *ngIf="
                              wholeSaleStatusForm.controls.contingencyArray[
                                'controls'
                              ].length > 0
                            "
                            (click)="deleteContingency(contingencyIndex)"
                          >
                            <img src="/assets/images/delete.png" />
                          </a>
                        </span>

                        <div class="text-danger re-error-text">
                          <span
                            *ngIf="
                              submitted &&
                              reason.controls['contingencyExpiration'].hasError(
                                'required'
                              )
                            "
                          >
                            {{
                              messageConstant.requiredField.replace(
                                "Field",
                                "Contingency Expiration"
                              )
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-danger re-error-text">
                  <span
                    *ngIf="
                      submitted && hasError('contingencyArray', 'required')
                    "
                  >
                    {{
                      messageConstant.requiredField.replace(
                        "Field",
                        "Contingency"
                      )
                    }}
                  </span>
                </div>

                <div class="col-12 re-add-contingency-container">
                  <button
                    (click)="addContingency()"
                    mat-flat-button
                    color="primary"
                    cdkFocusInitial
                    type="button"
                  >
                    Add Contingency
                  </button>
                </div>
              </ng-container>
              <div class="col-12 col-md-12 col-sm-12"><hr /></div>
            </ng-container>
            <ng-container *ngIf="isClearClose">
              <div class="col-12 col-md-12 col-sm-12">
                <h4 class="re-status-heading">Clear To Close</h4>
                <div class="form-group">
                  <label class="labels"
                    >When did you receive clear to close?<span
                      class="text-danger"
                      >*</span
                    ></label
                  >
                  <p-calendar
                    styleClass="ngCalendarClass"
                    appendTo="body"
                    [icon]="'fa fa-calendar'"
                    formControlName="clearToCloseDate"
                    placeholder="Select Date"
                    dateFormat="MM d, yy"
                    [showButtonBar]="true"
                    [showIcon]="true"
                  ></p-calendar>
                  <div class="text-danger re-error-text">
                    <span
                      *ngIf="
                        submitted &&
                        wholeSaleStatusForm.controls[
                          'clearToCloseDate'
                        ].hasError('required')
                      "
                    >
                      {{
                        messageConstant.requiredField.replace(
                          "Field",
                          "Clear to close date"
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="isSold">
              <div class="col-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="labels">
                    Was this transaction assignment or double close or novation?
                  </label>

                  <select
                    class="form-control"
                    formControlName="exitType"
                    [attr.disabled]="isMarketReadonly ? 'disabled' : null"
                  >
                    <option value="Wholesale - Assignment Contract">
                      Assignment
                    </option>
                    <option value="Wholesale - Double Close">
                      Double Close
                    </option>
                    <option value="Novation">Novation</option>
                  </select>
                </div>
              </div>
              <ng-container
                *ngIf="
                  wholeSaleStatusForm.controls['exitType'].value ==
                    'Wholesale - Assignment Contract' ||
                  wholeSaleStatusForm.controls['exitType'].value == 'Novation'
                "
              >
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="labels">Contract closing Date</label>
                    <p-calendar
                      formControlName="closingDate"
                      styleClass="ngCalendarClass"
                      [showIcon]="true"
                      [icon]="'fa fa-calendar'"
                      appendTo="body"
                      dateFormat="MM d, yy"
                      readonlyInput="true"
                      [maxDate]="maxDateTime"
                    >
                    </p-calendar>
                    <div class="text-danger re-error-text">
                      <span
                        *ngIf="
                          submitted &&
                          wholeSaleStatusForm.controls['closingDate'].hasError(
                            'required'
                          )
                        "
                        >{{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Closing Date"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- ASSIGNMENT FEE -->
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="labels">
                      Assignment Fee
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="assignmentFee"
                      placeholder="$xx.xx"
                      mask="separator.2"
                      [thousandSeparator]="','"
                      [decimalMarker]="'.'"
                      prefix="$ "
                      [dropSpecialCharacters]="true"
                      (blur)="transformAmount($event)"
                    />

                    <div class="text-danger re-error-text">
                      <span
                        *ngIf="
                          submitted &&
                          wholeSaleStatusForm.controls[
                            'assignmentFee'
                          ].hasError('required')
                        "
                        >{{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Assignment Fee"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="labels">
                      $Profit on Deal
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="netProfit"
                      placeholder="$xx.xx"
                      mask="separator.2"
                      [thousandSeparator]="','"
                      [decimalMarker]="'.'"
                      prefix="$ "
                      [dropSpecialCharacters]="true"
                      [allowNegativeNumbers]="true"
                      (blur)="transformAmount($event)"
                    />

                    <div class="text-danger re-error-text">
                      <span
                        *ngIf="
                          submitted &&
                          wholeSaleStatusForm.controls['netProfit'].hasError(
                            'required'
                          )
                        "
                        >{{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Net Profit"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="
                  wholeSaleStatusForm.controls['exitType'].value ==
                  'Wholesale - Double Close'
                "
              >
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="labels">
                      Purchase Price
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="purchasePrice"
                      placeholder="$xx.xx"
                      mask="separator.2"
                      [thousandSeparator]="','"
                      [decimalMarker]="'.'"
                      prefix="$ "
                      [dropSpecialCharacters]="true"
                      (blur)="transformAmount($event)"
                    />

                    <div class="text-danger re-error-text">
                      <span
                        *ngIf="
                          submitted &&
                          wholeSaleStatusForm.controls[
                            'purchasePrice'
                          ].hasError('required')
                        "
                        >{{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Purchase Price"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="labels">
                      Purchase Date
                      <span class="text-danger">*</span>
                    </label>
                    <p-calendar
                      formControlName="purchaseDate"
                      styleClass="ngCalendarClass"
                      [showIcon]="true"
                      [icon]="'fa fa-calendar'"
                      appendTo="body"
                      readonlyInput="true"
                      [maxDate]="maxDateTime"
                    >
                    </p-calendar>

                    <div class="text-danger re-error-text">
                      <span
                        *ngIf="
                          submitted &&
                          wholeSaleStatusForm.controls['purchaseDate'].hasError(
                            'required'
                          )
                        "
                        >{{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Purchase Date"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="labels">
                      Sale Price
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="salesPrice"
                      placeholder="$xx.xx"
                      mask="separator.2"
                      [thousandSeparator]="','"
                      [decimalMarker]="'.'"
                      prefix="$ "
                      [dropSpecialCharacters]="true"
                      (blur)="transformAmount($event)"
                    />

                    <div class="text-danger re-error-text">
                      <span
                        *ngIf="
                          submitted &&
                          wholeSaleStatusForm.controls['salesPrice'].hasError(
                            'required'
                          )
                        "
                        >{{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Sale Price"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="labels">
                      Sale Date
                      <span class="text-danger">*</span>
                    </label>
                    <p-calendar
                      formControlName="salesDate"
                      styleClass="ngCalendarClass"
                      [showIcon]="true"
                      [icon]="'fa fa-calendar'"
                      appendTo="body"
                      readonlyInput="true"
                      [maxDate]="maxDateTime"
                    >
                    </p-calendar>

                    <div class="text-danger re-error-text">
                      <span
                        *ngIf="
                          submitted &&
                          wholeSaleStatusForm.controls['salesDate'].hasError(
                            'required'
                          )
                        "
                        >{{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Sale Date"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="labels">
                      $Profit on Deal
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="netProfit"
                      placeholder="$xx.xx"
                      mask="separator.2"
                      [thousandSeparator]="','"
                      [decimalMarker]="'.'"
                      prefix="$ "
                      [dropSpecialCharacters]="true"
                      [allowNegativeNumbers]="true"
                      (blur)="transformAmount($event)"
                    />

                    <div class="text-danger re-error-text">
                      <span
                        *ngIf="
                          submitted &&
                          wholeSaleStatusForm.controls['netProfit'].hasError(
                            'required'
                          )
                        "
                        >{{
                          messageConstant.requiredField.replace(
                            "Field",
                            "Net Profit"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <hr style="margin-bottom: 10px !important" />
    <mat-dialog-actions align="center">
      <button mat-raised-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" cdkFocusInitial type="submit">
        Continue
      </button>
    </mat-dialog-actions>
  </form>
</div>
