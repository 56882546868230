import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';


@Component({
  selector: 'app-upgrade-account',
  templateUrl: './upgrade-account.component.html',
  styleUrls: ['./upgrade-account.component.scss'],
})
export class UpgradeAccountComponent implements OnInit {
  istextView: boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _router: Router,
    private dialog: MatDialogRef<UpgradeAccountComponent>,
   ) {}

  ngOnInit(): void {
    if (this.data?.userPlan != 'enterprise' && this.data?.userPlan != 'enterprise existing') {
      this.istextView = false;
    }
  }

  upgrade() {
    this.dialog.close(true);
    this._router.navigate(['/upgrade-plans']);
  }
}
