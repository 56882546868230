<div class="re-html-modal">
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        style="display: block !important"
        class="w-100 d-flex justify-content-between re-header"
      >
        {{ title }}
      </h2>

      <span matDialogClose mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-right: -5px; margin-top: 15px"
        />
      </span>
    </div>
  </div>

  <div class="re-container">
    <app-list-view
      #listView
      [header]="header"
      [data]="stats"
      [showPagination]="showPagination"
      [paginationType]="paginationType"
      (_changePage)="changePage($event)"
    ></app-list-view>
  </div>
</div>
