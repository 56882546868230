<div class="re-activity-main" [class]="classNew" [ngStyle]="activityMainStyle">
  <!---------------------- ACTIVITY TYPE AND COUNT SECTION START ----------------------->
  <h5
    class="re-title"
    [class.re-title-pinned]="isWebFormActivity"
    [class.re-title-margin]="isPinnedCount"
    [class.re-title-webForm]="type == 'web-form'"
    *ngIf="activityTabView"
  >
    <ng-container *ngIf="isWebFormActivity; else else_block_2">
      ACTIVITY LOG ({{ logs?.count || 0 }})
      <i
        class="fa fa-question-circle"
        pTooltip="The time below is {{ currentTimezone }}"
        tooltipPosition="top"
      ></i>
    </ng-container>
    <ng-template #else_block_2>
      <ng-container *ngIf="isPinnedCount; else else_block">
        PINNED NOTES ({{ logs?.count || 0 }})
      </ng-container>
    </ng-template>

    <ng-template #else_block>
      <mat-button-toggle-group
        [(ngModel)]="activityType"
        name="activityType"
        (ngModelChange)="changeActivityType()"
      >
        <mat-button-toggle value="commnotes">Comm & Notes</mat-button-toggle>
        <mat-button-toggle value="comm">Communication</mat-button-toggle>
        <mat-button-toggle value="notes">Notes</mat-button-toggle>
        <mat-button-toggle value="">All</mat-button-toggle>
      </mat-button-toggle-group>
    </ng-template>
  </h5>
  <!---------------------- ACTIVITY TYPE AND COUNT SECTION FINISH ----------------------->

  <!---------------------- ACTIVITY FILTER SECTION START ----------------------->
  <div class="re-filter" *ngIf="activityFilterView">
    <a
      class="re-expand-button"
      (click)="toggleExpand()"
      href="javascript:void(0);"
      *ngIf="type != 'web-form'"
    >
      <img
        class="re-expand-img"
        src="assets/images/arrow-expand.svg"
        alt="icon"
      />
      <img
        class="re-collapse-img"
        src="assets/images/arrow-collapse.svg"
        alt="icon"
      />
    </a>
    <a
      href="javascript:void(0);"
      class="re-activity-sort"
      [class.re-activity-sorting] = "activitySort"
      (click)="sortActivity()"
    >
      <img src="assets/images/sort-activity.svg" alt="Sort Activity" />
    </a>
    <a
      href="javascript:void(0);"
      data-toggle="modal"
      (click)="onActivityLogFilter()"
      *ngIf="type != 'list-stacking'"
    >
      <img src="assets/images/filter-icon.svg" alt="icon" />
    </a>
    <a href="javascript:void(0);" (click)="reset()" *ngIf="filterActive">
      <img src="assets/images/recycle-16x.png" alt="icon" />
    </a>
  </div>
  <!---------------------- ACTIVITY FILTER SECTION FINISH ----------------------->

  <!---------------------- ACTIVITY DASHBOARD FILTER SECTION START ----------------------->
  <div
    class="re-comments-top"
    *ngIf="activityDashboardComment || dashboardCommunicationView"
  >
    <div class="re-leftbtn">
      <a
        href="javascript:void(0);"
        class="{{ isNew ? 're-btn re-active' : 're-btn' }}"
        (click)="changeNew(true)"
        >New</a
      >
      <a
        href="javascript:void(0);"
        class="{{ isNew ? 're-btn' : 're-btn re-active' }}"
        (click)="changeNew(false)"
        >All</a
      >
    </div>
    <div class="re-rightbtn">
      <a
        href="javascript:void(0);"
        class="{{ isPinned ? 're-btn' : 're-btn re-active' }}"
        *ngIf="!isNew && !isCommunicationTabList"
        (click)="getPinComment(false)"
        >All</a
      >
      <a
        href="javascript:void(0);"
        class="{{ isPinned ? 're-btn re-active' : 're-btn' }}"
        *ngIf="!isNew && !isCommunicationTabList"
        (click)="getPinComment(true)"
        >Pinned</a
      >
      <a
        href="javascript:void(0);"
        class="{{ isAll ? 're-btn re-active' : 're-btn' }}"
        *ngIf="!isNew && isCommunicationTabList"
        (click)="getFilterData('all')"
        >All</a
      >
      <a
        href="javascript:void(0);"
        class="{{ isIncoming ? 're-btn re-active' : 're-btn' }}"
        *ngIf="!isNew && isCommunicationTabList"
        (click)="getFilterData('incoming')"
        >Incoming</a
      >
      <a
        href="javascript:void(0);"
        class="{{ isOutgoing ? 're-btn re-active' : 're-btn' }}"
        *ngIf="!isNew && isCommunicationTabList"
        (click)="getFilterData('outgoing')"
        >Outgoing</a
      >
      <a
        href="javascript:void(0);"
        class="re-btn"
        *ngIf="isNew && isMainUser"
        (click)="clearActivityBadge()"
        >Clear New</a
      >
    </div>
  </div>
  <!---------------------- ACTIVITY DASHBOARD FILTER SECTION FINISH ----------------------->

  <!---------------------- ACTIVITY COMMENT SECTION START ----------------------->
  <div
    #logsList
    class="re-logs-list"
    [class.re-comments-data]="
      activityDashboardComment || dashboardCommunicationView
    "
    infiniteScroll
    [infiniteScrollUpDistance]="2"
    [infiniteScrollThrottle]="50"
    [infiniteScrollDisabled]="scrollDisabled"
    [scrollWindow]="
      activityDashboardComment || dashboardCommunicationView ? true : false
    "
    (scrolledUp)="onScroll()"
    [ngStyle]="style"
  >
    <!---------------------- ACTIVITY Pin RECORD START ----------------------->
    <ng-container *ngIf="totalPinCommentCount > 0 && !isPinned">
      <div class="re-pinned-note">
        <div class="re-title">
          <img
            class="align-middle"
            src="assets/images/pin-black.svg"
            alt="pin-black"
            width="14"
          />
          {{ totalPinCommentCount }}&nbsp;Pinned Notes
        </div>
        <a class="re-btn" (click)="pinnedShow()">View Notes</a>
      </div>
    </ng-container>

    <!---------------------- ACTIVITY Pin RECORD FINISH ----------------------->

    <!---------------------- ACTIVITY NO RECORD SECTION START ----------------------->
    <div
      class="re-no-record"
      [class.re-comment-record]="
        activityDashboardComment || dashboardCommunicationView
      "
      *ngIf="logs?.items?.length == 0"
    >
      No Records Found
    </div>
    <!---------------------- ACTIVITY NO RECORD SECTION FINISH ----------------------->

    <ng-container *ngFor="let log of logs?.items; let logIndex = index">
      <div
        class="re-media"
        [class.re-comm-media]="dashboardCommunicationView"
        [class.re-edit]="log?.select"
        [class.re-call]="checkType(log?.direction, log)?.type === 'CALL'"
        [class.re-send]="checkType(log?.direction, log)?.mode === 'send'"
        [class.re-receive]="checkType(log?.direction, log)?.mode === 'receive'"
        [class.re-activity-media]="activityMediaStyle"
        [class.re-comment-boxes]="
          activityDashboardComment || getDirection(log?.createdBy)
        "
        [class.re-comm-boxes]="dashboardCommunicationView"
        [class.re-receive-comment]="
          activityDashboardComment && !getDirection(log?.createdBy)
        "
      >
        <!----------------------- ACTIVITY CANCEL ------------------------>
        <div class="re-close" *ngIf="log?.activityType == 8 && log?.select">
          <div class="re-button">
            <a
              (click)="onClose('add', log, logIndex, log._id)"
              href="javascript:void(0);"
            >
              <img
                height="20px"
                width="20px"
                src="assets/images/close-grey.svg"
                alt="edit"
              />
            </a>
          </div>
        </div>

        <!-------------------- ACTIVITY COMMENT LABEL START ------------------------>
        <label
          class="re-check"
          *ngIf="setNewData(log) && setLabelView(log)"
          (click)="clearSingleActivityBadge(log, logIndex)"
        >
          <img
            [src]="checkIsNewData(log)"
            class="new-data-img-{{ logIndex }}"
            alt="icon"
          />
          <span class="re-checktxt new-data-txt-{{ logIndex }}" *ngIf="isNew">
            Mark as read
          </span>
          <span class="re-checktxt new-data-txt-{{ logIndex }}" *ngIf="!isNew">
            Mark as unread
          </span>
        </label>
        <div class="re-addressec" *ngIf="dashboardCommunicationView">
          <span class="re-infos re-address" (click)="moveToDetails(log)">
            {{
              log?.address
                ? _utilities.formatAddress(log?.address, log?.unitNo)
                : "N/A"
            }}
          </span>
          <span class="re-infos" *ngIf="log?.marketingTitle">
            {{ log?.marketingTitle }} >
            {{ _utilities.maskNumber(log?.marketingNumber) }}
          </span>
          <span class="re-infos" *ngIf="log?.leadData?.mainStatusId">
            {{ getStatusLabel(log?.leadData?.mainStatusId) }}
          </span>
          <a
            class="re-btn"
            href="javascript:;"
            pTooltip="Reply"
            tooltipPosition="bottom"
            *ngIf="
              log?.direction == 'incomingSms' || log?.direction == 'incomingMms'
            "
            (click)="onReplayMessage(log)"
          >
            <svg width="14" height="10" viewBox="0 0 14 10">
              <g transform="translate(0 -40.801)">
                <path
                  d="M85.926 43.76v-2.354a.6.6 0 0 0-.393-.558.662.662 0 0 0-.694.131l-3.09 2.93a.582.582 0 0 0 0 .854l3.088 2.932a.654.654 0 0 0 .45.177.665.665 0 0 0 .243-.046.6.6 0 0 0 .393-.558v-2.294a5.373 5.373 0 0 1 4.909 5.226.637.637 0 0 0 1.273 0 6.617 6.617 0 0 0-6.179-6.44z"
                  transform="translate(-78.105 -.001)"
                ></path>
                <path
                  d="M1.536 44.335l2.639-2.5a.582.582 0 0 0 0-.854.661.661 0 0 0-.9 0l-3.09 2.93a.582.582 0 0 0 0 .854l3.088 2.932a.661.661 0 0 0 .9 0 .582.582 0 0 0 0-.854z"
                ></path>
              </g>
            </svg>
            Reply
          </a>
          <a
            class="re-btn"
            href="javascript:;"
            pTooltip="Call"
            tooltipPosition="bottom"
            *ngIf="log?.direction == 'incomingCall'"
            (click)="onReplayCall(log)"
          >
            <svg width="14" height="10" viewBox="0 0 14 10">
              <g transform="translate(0 -40.801)">
                <path
                  d="M85.926 43.76v-2.354a.6.6 0 0 0-.393-.558.662.662 0 0 0-.694.131l-3.09 2.93a.582.582 0 0 0 0 .854l3.088 2.932a.654.654 0 0 0 .45.177.665.665 0 0 0 .243-.046.6.6 0 0 0 .393-.558v-2.294a5.373 5.373 0 0 1 4.909 5.226.637.637 0 0 0 1.273 0 6.617 6.617 0 0 0-6.179-6.44z"
                  transform="translate(-78.105 -.001)"
                ></path>
                <path
                  d="M1.536 44.335l2.639-2.5a.582.582 0 0 0 0-.854.661.661 0 0 0-.9 0l-3.09 2.93a.582.582 0 0 0 0 .854l3.088 2.932a.661.661 0 0 0 .9 0 .582.582 0 0 0 0-.854z"
                ></path>
              </g>
            </svg>
            Call
          </a>
        </div>
        <!-------------------- ACTIVITY COMMENT LABEL FINISH ------------------------>

        <div [class.re-subboxes]="dashboardCommunicationView">
          <!------ ACTIVITY COMMENT ICON START --------->
          <div class="re-media-left">
            <a href="javascript:void(0);">
              <!-- ICON VIEW -->
              <img
                class="re-media-object"
                [src]="activityLogTextView(log, 'images').imageUrl"
                [pTooltip]="checkType(log?.direction, log).tooltip"
                tooltipPosition="bottom"
                [escape]="false"
              />
            </a>
          </div>

          <!-------------------- ACTIVITY COMMENT BODY START ------------------------>
          <div class="re-media-body">
            <div
              class="re-title-container"
              [class.re-comment-title]="
                activityDashboardComment || dashboardCommunicationView
              "
            >
              <!------ ACTIVITY COMMENT HEADER START ------->
              <h4
                class="re-media-heading"
                [class.re-comment-heading]="
                  activityDashboardComment || dashboardCommunicationView
                "
              >
                <ng-container>
                  {{ activityLogTextView(log, "headerText").headerText }}
                  <span class="re-sender-name" *ngIf="senderNameView">
                    {{ activityLogTextView(log, "headerText").number }} •
                    {{ activityLogTextView(log, "headerText").sellerViewName }}
                  </span>
                </ng-container>
                <img
                  *ngIf="isPinnedIconView"
                  alt="admin"
                  (click)="setPinnedComment(log)"
                  class="re-ping-icon"
                  src="/assets/images/pin-teal1.svg"
                />
              </h4>
              <!------ ACTIVITY COMMENT HEADER FINISH ------->

              <span class="re-time" *ngIf="isDateView">
                {{ log?.createdAt | myTimezone }}
              </span>
              <span class="re-comment-names" *ngIf="activityDashboardComment">
                {{ log?.createdAt | myTimezone }} •
                <span class="re-teal re-details" (click)="moveToDetails(log)">
                  {{
                    log?.address
                      ? _utilities.formatAddress(log?.address, log?.unitNo)
                      : "N/A"
                  }}
                </span>
                • {{ getStatusLabel(log?.leadData?.mainStatusId) }}
              </span>

              <!------ ACTIVITY COMMENT REPLAY START ------->
              <div class="re-answer-button" *ngIf="activityDashboardComment">
                <a
                  href="javascript:;"
                  pTooltip="Reply"
                  tooltipPosition="bottom"
                  class="re-textbtn"
                  (click)="onReplyComment(log)"
                >
                  <svg width="14" height="10" viewBox="0 0 14 10">
                    <g transform="translate(0 -40.801)">
                      <path
                        d="M85.926 43.76v-2.354a.6.6 0 0 0-.393-.558.662.662 0 0 0-.694.131l-3.09 2.93a.582.582 0 0 0 0 .854l3.088 2.932a.654.654 0 0 0 .45.177.665.665 0 0 0 .243-.046.6.6 0 0 0 .393-.558v-2.294a5.373 5.373 0 0 1 4.909 5.226.637.637 0 0 0 1.273 0 6.617 6.617 0 0 0-6.179-6.44z"
                        transform="translate(-78.105 -.001)"
                      ></path>
                      <path
                        d="M1.536 44.335l2.639-2.5a.582.582 0 0 0 0-.854.661.661 0 0 0-.9 0l-3.09 2.93a.582.582 0 0 0 0 .854l3.088 2.932a.661.661 0 0 0 .9 0 .582.582 0 0 0 0-.854z"
                      ></path>
                    </g>
                  </svg>
                  Reply
                </a>
                <a
                  href="javascript:;"
                  pTooltip="Pin"
                  tooltipPosition="bottom"
                  class="re-imgbtn"
                  (click)="setPinnedComment(log)"
                  pTooltip="{{ log.isPinned ? 'Unpin' : 'Pin' }}"
                  [class.re-active]="log?.isPinned"
                >
                  <svg width="16" height="16" viewBox="0 0 17 17">
                    <g>
                      <path
                        d="M16.834 6.289L10.712.166a.567.567 0 0 0-.8 0A7.406 7.406 0 0 0 8.894 2.7L6.436 5.163A2.181 2.181 0 0 0 4.2 8.772l1.614 1.614-5.648 5.647a.567.567 0 0 0 .8.8l5.646-5.646L8.229 12.8a2.181 2.181 0 0 0 3.609-2.238L14.3 8.107a7.4 7.4 0 0 0 2.535-1.017.567.567 0 0 0-.001-.801zm-2.946.751a.567.567 0 0 0-.31.177c-.03.034-.058.063-.084.089L10.76 10.04a.567.567 0 0 0-.087.689A1.047 1.047 0 0 1 9.03 12L7.016 9.987 5 7.971a1.048 1.048 0 0 1 1.272-1.644.567.567 0 0 0 .689-.087l2.734-2.734.088-.083a.567.567 0 0 0 .179-.311 11.839 11.839 0 0 1 .486-1.612L15.5 6.552a11.744 11.744 0 0 1-1.612.487z"
                      ></path>
                    </g>
                  </svg>
                </a>
              </div>
              <!------ ACTIVITY COMMENT REPLAY START ------->
            </div>

            <span
              class="re-names"
              *ngIf="
                log?.direction != 'comment' || log?.subType == 1 || log?.isDrip
              "
            >
              <span
                [class.deactiv_connect]="!log?.iscallConnected"
                [class.activ_connect]="log?.iscallConnected"
                *ngIf="log?.callStatusId"
              >
                {{ activityLogTextView(log, "bodyText").leadConnect }}
              </span>
              <span
                *ngIf="
                  dashboardCommunicationView &&
                  activityLogTextView(log, 'bodyText').bodyType != 'By'
                "
              >
                {{ activityLogTextView(log, "bodyText").bodyType }}:
              </span>
              <span *ngIf="!dashboardCommunicationView">
                {{ activityLogTextView(log, "bodyText").bodyType }}:
              </span>
              <span
                [class.re-txt]="!dashboardCommunicationView"
                *ngIf="activityLogTextView(log, 'bodyText').headerText"
              >
                {{ activityLogTextView(log, "bodyText").headerText }}
              </span>
              <span class="re-not" *ngIf="dashboardCommunicationView">
                {{ activityLogTextView(log, "bodyText").callAnswer }}
              </span>
              <span [class.re-txt]="!dashboardCommunicationView">
                {{
                  activityLogTextView(log, "bodyText").number
                    ? activityLogTextView(log, "bodyText").doteView
                    : ""
                }}
                {{ activityLogTextView(log, "bodyText").number }}
              </span>
            </span>

            <!-- CONTENT -->
            <div
              *ngIf="
                log?.smsBody &&
                  log?.activityType != 27 &&
                  !dashboardCommunicationView;
                else else_block
              "
              class="re-text"
            >
              {{
                log?.smsBody.slice(
                  0,
                  log?.smsBody?.length > 75 &&
                    isVisible.indexOf(logIndex) === -1
                    ? 75
                    : log?.smsBody?.length
                )
              }}

              <!-- SHOW MORE -->
              <a
                href="javascript:void(0);"
                *ngIf="
                  log?.smsBody?.length > 75 &&
                  isVisible.indexOf(logIndex) === -1
                "
                class="re-more"
                (click)="toggleVisibility(logIndex)"
              >
                ...More
              </a>

              <!-- SHOW LESS -->
              <a
                href="javascript:void(0);"
                *ngIf="
                  log?.smsBody?.length > 75 && isVisible.indexOf(logIndex) > -1
                "
                class="re-more"
                (click)="toggleVisibility(logIndex)"
              >
                Less
              </a>
            </div>

            <ng-template #else_block>
              <div
                class="re-text"
                *ngIf="
                  log?.comment != 'SMS Received' &&
                  log?.comment != 'You placed a call to' &&
                  log?.comment != 'You received a call' &&
                  log?.comment != 'You got a missed call' &&
                  log?.activityType != '2' &&
                  log?.activityType !== 25 &&
                  !activityDashboardComment &&
                  !dashboardCommunicationView
                "
                [innerHTML]="
                  replaceLineBreak(log?.comment | mentionHighlight | textToLink)
                    | safe
                "
              ></div>

              <ng-container *ngIf="log?.activityType == 25 && log?.htmlContent">
                <div class="re-text">
                  {{ log?.comment }}.
                  <span
                    class="re-more"
                    (click)="viewCampaign(log?.htmlContent)"
                  >
                    View
                  </span>
                </div>
              </ng-container>
            </ng-template>

            <!-- MEDIA -->
            <ng-container
              *ngIf="
                log?.recording &&
                log?.voiceCallStatus != 'cancel' &&
                log?.voiceCallStatus != 'no-answer' &&
                log?.voiceCallStatus != 'no-confirm' &&
                log?.voiceCallStatus != 'busy' &&
                !dashboardCommunicationView
              "
            >
              <app-audio-player [url]="log?.recording"></app-audio-player>
              <div
                class="re-ai-view-summarise"
                (click)="callTranscriptDialog(log)"
                *ngIf="log?.isAiCallTranscript"
              >
                <button type="button" class="re-ai-btn">
                  <img
                    class="mr-1"
                    src="assets/images/al-start.svg"
                    width="10"
                    alt="al-start"
                  />View Summary
                </button>
              </div>
              <div
                class="re-ai-summarise"
                *ngIf="!log?.isAiCallTranscript"
                (click)="callTranscriptDialog(log)"
              >
                <button type="button" class="re-ai-btn">
                  <img
                    class="mr-1"
                    src="assets/images/al-start.svg"
                    width="10"
                    alt="al-start"
                  />RE-AI Summarize This
                </button>
              </div>
            </ng-container>

            <ng-container
              *ngIf="
                log?.direction == 'outgoingCall' &&
                (log?.voiceCallStatus == 'no-answer' ||
                  log?.voiceCallStatus == 'cancel') &&
                !dashboardCommunicationView
              "
            >
              <div class="re-text">Not Answered</div>
            </ng-container>

            <ng-container
              *ngIf="log?.voiceMailUrl && !dashboardCommunicationView"
            >
              <app-audio-player [url]="log?.voiceMailUrl"></app-audio-player>
            </ng-container>

            <!-- ATTACHMENTS -->
            <ng-container
              *ngIf="
                log?.attachments?.length &&
                log?.activityType == 1 &&
                !dashboardCommunicationView
              "
            >
              <div class="re-attachments">
                <span
                  class="re-attach-icon"
                  *ngFor="
                    let attachment of log?.attachments;
                    let fileIndex = index
                  "
                  (click)="previewAttachment(log, fileIndex)"
                >
                  <img
                    src="/assets/images/{{ getIcon(attachment) }}"
                    class="re-image"
                  />
                </span>
              </div>
            </ng-container>

            <ng-container
              *ngIf="
                log?.attachments?.length &&
                log?.activityType == 3 &&
                !dashboardCommunicationView
              "
            >
              <div class="re-attachments">
                <a
                  class="re-attach-icon"
                  *ngFor="
                    let attachment of log?.attachments;
                    let fileIndex = index
                  "
                  target="_blank"
                  [href]="attachment?.url"
                >
                  <img
                    [alt]="attachment?.name"
                    src="/assets/images/{{ getIcon(attachment?.url) }}"
                    class="re-image"
                  />
                  <p>{{ attachment?.name }}</p>
                </a>
              </div>
            </ng-container>
          </div>
          <!-------------------- ACTIVITY COMMENT BODY FINISH ------------------------>
        </div>

        <!-- COMMENT TEXT -->
        <div
          *ngIf="getCommentTextList(log)"
          class="re-comment-text"
          [class.re-text]="dashboardCommunicationView"
          [innerHTML]="getCommentTextList(log) | mentionHighlight | textToLink"
        ></div>

        <!-- COMMUNICATION MEDIA -->
        <ng-container
          *ngIf="
            log?.recording &&
            log?.voiceCallStatus != 'cancel' &&
            log?.voiceCallStatus != 'no-answer' &&
            log?.voiceCallStatus != 'no-confirm' &&
            log?.voiceCallStatus != 'busy' &&
            dashboardCommunicationView
          "
        >
          <div class="re-audio-flat">
            <app-audio-player [url]="log?.recording"></app-audio-player>
          </div>
        </ng-container>
        <ng-container *ngIf="log?.voiceMailUrl && dashboardCommunicationView">
          <div class="re-audio-flat">
            <app-audio-player [url]="log?.voiceMailUrl"></app-audio-player>
          </div>
        </ng-container>

        <!-- ATTACHMENTS -->
        <ng-container
          *ngIf="
            log?.attachments?.length &&
            log?.activityType == 1 &&
            dashboardCommunicationView
          "
        >
          <div class="re-attachments">
            <span
              class="re-attach-icon"
              *ngFor="let attachment of log?.attachments; let fileIndex = index"
              (click)="previewAttachment(log, fileIndex)"
            >
              <img
                src="/assets/images/{{ getIcon(attachment) }}"
                class="re-image"
              />
            </span>
          </div>
        </ng-container>
        <!-- ATTACHMENTS -->
        <ng-container
          *ngIf="
            log?.attachments?.length &&
            log?.activityType == 3 &&
            dashboardCommunicationView
          "
        >
          <div class="re-attachments">
            <a
              class="re-attach-icon"
              *ngFor="let attachment of log?.attachments; let fileIndex = index"
              target="_blank"
              [href]="attachment?.url"
            >
              <img
                [alt]="attachment?.name"
                src="/assets/images/{{ getIcon(attachment?.url) }}"
                class="re-image"
              />
              <p>{{ attachment?.name }}</p>
            </a>
          </div>
        </ng-container>

        <!-- Action -->
        <div
          class="re-action"
          *ngIf="log?.activityType == 8 && log?.subType != 9"
        >
          <div class="re-button" *ngIf="!isWebFormLink">
            <!-- EDIT BUTTON -->
            <span class="re-editdiv">
              <a
                (click)="onEdit('edit', log, logIndex, log._id)"
                href="javascript:void(0);"
              >
                <img
                  height="20px"
                  width="20px"
                  src="assets/images/editActive.svg"
                  alt="edit"
                />
              </a>
            </span>

            <!-- DELETE BUTTON -->
            <span class="re-deletediv ml-1">
              <a
                (click)="onDelete('comment', 'leads', log, logIndex, log._id)"
                href="javascript:void(0);"
              >
                <img
                  height="20px"
                  width="20px"
                  src="assets/images/deleteActive.svg"
                  alt="delete"
                />
              </a>
            </span>

            <!-- PIN BUTTON -->
            <span
              class="{{ log?.isPinned ? 're-ping re-active' : 're-ping' }}"
              (click)="setPinnedComment(log)"
              *ngIf="type != 'list-stacking' && type != 'Dialer'"
            >
              <svg width="16" height="16" viewBox="0 0 17 17">
                <g>
                  <path
                    d="M16.834 6.289L10.712.166a.567.567 0 0 0-.8 0A7.406 7.406 0 0 0 8.894 2.7L6.436 5.163A2.181 2.181 0 0 0 4.2 8.772l1.614 1.614-5.648 5.647a.567.567 0 0 0 .8.8l5.646-5.646L8.229 12.8a2.181 2.181 0 0 0 3.609-2.238L14.3 8.107a7.4 7.4 0 0 0 2.535-1.017.567.567 0 0 0-.001-.801zm-2.946.751a.567.567 0 0 0-.31.177c-.03.034-.058.063-.084.089L10.76 10.04a.567.567 0 0 0-.087.689A1.047 1.047 0 0 1 9.03 12L7.016 9.987 5 7.971a1.048 1.048 0 0 1 1.272-1.644.567.567 0 0 0 .689-.087l2.734-2.734.088-.083a.567.567 0 0 0 .179-.311 11.839 11.839 0 0 1 .486-1.612L15.5 6.552a11.744 11.744 0 0 1-1.612.487z"
                  ></path>
                </g>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngFor="let log of emailActivity; let emailIndex = index">
      <div class="re-media re-send" (click)="showEmail(log, showEmailTemplate)">
        <!-- CANCEL -->
        <div class="re-close">
          <div class="re-button">
            <a (click)="onCloseEmail(log)" href="javascript:void(0);">
              <img
                height="20px"
                width="20px"
                src="assets/images/close-grey.svg"
                alt="edit"
              />
            </a>
          </div>
        </div>

        <!-- ICON -->
        <div class="re-media-left">
          <a href="javascript:void(0);">
            <img
              class="re-media-object"
              [src]="'assets/images/' + checkType(log?.direction, log).icon"
            />
          </a>
        </div>

        <!-- BODY -->
        <div class="re-media-body">
          <h4 class="re-media-heading">Email</h4>
          <span class="re-time">
            {{ log.email.sentFromEmail }} &bull;

            {{ log?.createdAt | date : "MMM dd hh:mm a" }}
          </span>
        </div>

        <!-- CONTENT -->
        <div class="re-text">{{ log.email.subject }}</div>

        <!-- Action -->
        <div class="re-action" *ngIf="log?.activityType == 8">
          <div class="re-button" *ngIf="!isWebFormLink">
            <!-- EDIT BUTTON -->
            <span class="re-editdiv">
              <a
                (click)="onEdit('edit', log, emailIndex, log._id)"
                href="javascript:void(0);"
              >
                <img
                  height="20px"
                  width="20px"
                  src="assets/images/edit.svg"
                  alt="edit"
                />
              </a>
            </span>

            <!-- DELETE BUTTON -->
            <span class="re-deletediv ml-1">
              <a
                (click)="onDeleteEmailActivity(log)"
                href="javascript:void(0);"
              >
                <img
                  height="20px"
                  width="20px"
                  src="assets/images/delete.svg"
                  alt="delete"
                />
              </a>
            </span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <!---------------------- ACTIVITY COMMENT SECTION FINISH ----------------------->

  <!--------- ACTIVITY QUILL BOX SECTION START ------------->
  <div class="re-sms-container" *ngIf="activityQuillBox">
    <div class="re-quill">
      <quill-editor
        #editor
        *ngIf="showQuillToolbar"
        name="rawEmailBody"
        [(ngModel)]="comment"
        (blur)="onComment($event)"
        (keyup)="onComment2($event)"
        [styles]="editorStyle"
        [modules]="editorConfig"
        [placeholder]="editorOptions.placeholder"
      ></quill-editor>

      <quill-editor
        #editor
        *ngIf="!showQuillToolbar"
        name="rawEmailBody"
        [(ngModel)]="comment"
        (blur)="onComment($event)"
        (keyup)="onComment2($event)"
        [styles]="editorStyle"
        [modules]="{ toolbar: false, mention: editorOptions.mention }"
        [placeholder]="editorOptions.placeholder"
      ></quill-editor>
    </div>

    <div class="re-action-container">
      <button
        (click)="showHideToolbar()"
        class="btn re-toggle-btn"
        [class.re-active]="showQuillToolbar"
      >
        Aa
      </button>

      <button
        [style.background-color]="isCommentExists() ? 'teal' : ''"
        data-toggle="tooltip"
        data-placement="top"
        [title]="action"
        class="btn send-btn"
        type="button"
        (click)="onSubmit()"
        [style.cursor]="isCommentExists() ? 'pointer' : 'not-allowed'"
      >
        <img
          style="padding-top: 5px"
          height="20px"
          width="20px"
          [src]="
            isCommentExists()
              ? 'assets/images/activity-white-send.svg'
              : 'assets/images/activity-send.svg'
          "
          alt="activity-send"
        />
      </button>
    </div>
  </div>
  <!--------- ACTIVITY QUILL BOX SECTION FINISH ------------->
</div>

<!-- EMAIL TEMPLATE -->
<ng-template #showEmailTemplate>
  <div class="re-modal-header modal-header">
    <h4 class="modal-title pull-left">Email</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">
        <img
          class="img"
          src="../../../assets/images/close-icon.png"
          alt="warning"
        />
      </span>
    </button>
  </div>

  <div class="re-tag-modal-body modal-body">
    <div class="box">
      <div *ngIf="emailBody">
        <div>
          <h4 *ngIf="currentEmail?.email?.subject != ''">
            {{ currentEmail?.email?.subject }}
          </h4>
          <p
            class="re-showEmail"
            *ngIf="currentEmail?.email?.sentFromEmail != ''"
          >
            {{ currentEmail?.email?.sentFromEmail }}
          </p>
          <div
            class="emailBody"
            *ngIf="emailBody"
            [innerHTML]="emailBody"
          ></div>
        </div>
      </div>
    </div>
  </div>

  <div class="re-modal-footer modal-footer">
    <button
      (click)="closeModal()"
      type="button"
      class="re-cancel btn btn-default"
      data-dismiss="modal"
    >
      Cancel
    </button>
  </div>
</ng-template>
