import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { BaseUrl } from '../../utils/base-url-constants';
import { environment } from 'src/environments/environment';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
@Injectable({
  providedIn: 'root',
})
export class MasterTaskService {
  baseUrl = environment.baseUrl;
  public token = localStorage.getItem('token');

  constructor(private _commonFunction: CommonFunctionsService) {}

  createTask = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.masterTaskUrl + 'save';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  getTask = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.masterTaskUrl + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  updateTask = (data, id): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.masterTaskUrl + 'update/' + id;
    return this._commonFunction.globalPutService(endpoint, data);
  };

  deleteTask = (id): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.masterTaskUrl + 'delete/' + id;
    return this._commonFunction.globalDeleteService(endpoint);
  };

  getAllTask = (data): Observable<any> => {
    const endpoint = this.baseUrl + BaseUrl.masterTaskUrl + 'list';
    return this._commonFunction.globalPostService(endpoint, data);
  };

  /**
   * FUNCTION: GET USER ROLES
   * @param {Object} data
   * @param {boolean} webLink
   * @param {boolean} isDeleted
   * @returns ARRAY OF USER ROLES
   */
  getUserRoleList = (data, webLink?, isDeleted?): Observable<any> => {
    // Restrict Multiple Calls For User Roles
    let endpoint = this.baseUrl;

    if (!webLink) {
      if (isDeleted) {
        endpoint += BaseUrl.user + 'sub_user_list/mainUserList';
      } else {
        endpoint += BaseUrl.user + 'sub_user_list/main_user';
      }
    } else {
      endpoint += BaseUrl.webFormUrl + 'mainUserList';
    }

    return this._commonFunction.globalPostService(
      endpoint,
      data,
      webLink ? this.getRequestHeaders(webLink) : {}
    );
  };

  // SET HEADERS
  protected getRequestHeaders(val?): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    let headers;
    let obj = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };

    if (val) {
      obj['webFormLink'] = val || localStorage.getItem('webFormLink');
    }

    headers = new HttpHeaders(obj);
    return { headers: headers };
  }
}
