import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { Router } from '@angular/router';

// SERVICES
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { UserService } from 'src/app/providers/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

// UTILS
import { ResponseModel } from 'src/app/utils/models/response';
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';

// COMPONENTS
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-transcription-plan-upgrade',
  templateUrl: './transcription-plan-upgrade.component.html',
  styleUrls: ['./transcription-plan-upgrade.component.scss'],
})
export class TranscriptionPlanUpgradeComponent {
  isYearlyPlan: boolean = true;
  messageConstant = MessageConstant;
  dialogRef: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _router: Router,
    private _dialog: MatDialog,
    private dialog: MatDialogRef<TranscriptionPlanUpgradeComponent>,
    public _utilities: CommonFunctionsService,
    private _usersService: UserService,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService
  ) {
    this.isYearlyPlan = this._utilities.currentPlanData?.isYearlyPlan;
  }

  getBillingAmount() {
    this._loaderService.start();
    this._usersService.getAiCallSummaryChargeAmount({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode === 200) {
          const payAmount = parseFloat(response?.data?.payAmount);
          let additionInfo = response?.data?.additionalAiCallSummaryCharge;
          let isYearlyPlan = response?.data?.isYearlyPlan;
          let isTrialPeriod = response?.data?.isTrialPeriod;

          if (isTrialPeriod) {
            this._toastrService.error(
              'Unlimited AI call summaries are available after the trial period. Please purchase individual AI call summary credits.'
            );
            return;
          }

          if (payAmount > 0) {
            let durationText = 'mo';
            let durationText2 = 'month';
            if (isYearlyPlan) {
              durationText = 'year';
              durationText2 = 'year';
            }
            let modalData = {
              yesTitle: 'Yes',
              noTitle: 'Cancel',
              header: `Add Additional Ai Call Transciption`,
              text: `Each additional  is $${additionInfo}/${durationText}.
              Your first ${durationText2} prorated charge will be $${payAmount} and then $${additionInfo}/${durationText} after that.
              Would you like to add another Ai Call Transciption?`,
            };
            this.openConfirmationDialog(modalData);
          }
        } else {
          this._toastrService.error(response.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }

  buyCredit() {
    this.dialog.close();
    this._router.navigate(['/credit/buy-credit']);
  }

  openConfirmationDialog(data) {
    this.dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '600px',
      data: data,
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.unlimitedAiCallSummaryCharge();
      }
    });
  }

  unlimitedAiCallSummaryCharge() {
    this._loaderService.start();
    this._usersService.aiCallSummaryCharge({}).subscribe(
      (response: ResponseModel) => {
        this._loaderService.stop();
        if (response.statusCode === 200) {
          this._toastrService.success(response?.data);
          this.dialog.close();
        } else {
          this._toastrService.error(response.message, '');
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
        if (err.error) {
          const error: ResponseModel = err.error;
          this._toastrService.error(error.message, '');
        } else {
          this._toastrService.error(this.messageConstant.unknownError, '');
        }
      }
    );
  }
}
