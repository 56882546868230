<form [formGroup]="generalForm" autocomplete="off">
  <!-- PHONE NUMBER -->
  <div class="form-group">
    <label class="labels" for="inputCampaign4">Phone Number</label>
    <input
      type="text"
      class="form-control"
      id="inputphone"
      placeholder="Phone"
      formControlName="number"
      readonly
      (change)="onChangeEvent($event)"
    />
  </div>

  <!-- MARKET NAME -->
  <div class="form-group">
    <label class="labels" for="inputflowName2">
      Which Market is this Campaign for? <span class="text-danger">*</span>
    </label>

    <select
      class="form-control"
      formControlName="marketId"
      name="marketId"
      (change)="onChangeEvent($event)"
      [attr.disabled]="isMarketReadonly ? 'disabled' : null"
    >
      <option *ngFor="let market of markets" [value]="market._id">
        {{ market.title }}
      </option>
    </select>
  </div>

  <!-- LEAD SOURCE -->
  <div class="form-group">
    <label class="labels" for="inputleadSource">
      Lead Source <span class="text-danger">*</span>
    </label>
    <select
      id="inputleadSource"
      class="form-control"
      formControlName="crmQuestionId"
      (change)="onChangeEvent($event)"
    >
      <option value="">Select Lead Source</option>
      <option *ngFor="let leadSource of leadSources" [value]="leadSource._id">
        {{ leadSource.answer }}
      </option>
    </select>
    <div
      *ngIf="
        (submitted && f.crmQuestionId.errors) ||
        (f.crmQuestionId.errors &&
          (f.crmQuestionId.dirty || f.crmQuestionId.touched))
      "
      class="text-danger"
    >
      <span *ngIf="f.crmQuestionId.errors.required">
        Lead Source is required
      </span>
    </div>
  </div>

  <!-- CAMPAIGN NAME -->
  <div class="form-group">
    <label class="labels" for="inputCampaign4">
      Campaign Name <span class="text-danger">*</span>
    </label>
    <input
      type="text"
      class="form-control"
      id="inputCampaign4"
      formControlName="marketingTitle"
      placeholder="Campaign Name"
      (change)="onChangeEvent($event)"
    />
    <div
      *ngIf="
        (submitted && f.marketingTitle.errors) ||
        (f.marketingTitle.errors &&
          (f.marketingTitle.dirty || f.marketingTitle.touched))
      "
      class="text-danger"
    >
      <span *ngIf="f.marketingTitle.errors.required">
        Campaign Name is required
      </span>
    </div>
  </div>

  <!-- CALL FLOW NAME -->
  <div class="form-group">
    <label class="labels" for="inputCalln4">
      Call Flow Name <span class="text-danger">*</span>
    </label>
    <select
      class="form-control"
      formControlName="flowId"
      name="flowId"
      (change)="onChangeEvent($event)"
    >
      <option *ngFor="let eachFlow of flowList" [value]="eachFlow._id">
        {{ eachFlow.name }}
      </option>
    </select>
  </div>
</form>
