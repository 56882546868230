<div class="">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      {{ data?.details?.crmQuestionAnswer }} >
      {{ data?.details?.marketingTitle }}
    </h2>
    <span matDialogClose mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>

<mat-dialog-content class="re-typography vh-100">
  <div class="container">
    <form autocomplete="off">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12">
          <mat-tab-group
            (selectedTabChange)="getIndex($event?.index)"
            *ngIf="data?.details?.numTypeId == 1"
          >
            <!-- General-->
            <mat-tab label="General">
              <app-general
                (_emitter)="emitter($event)"
                [campaign]="data?.details"
              ></app-general>
            </mat-tab>

            <!-- Team-->
            <mat-tab label="Team">
              <app-teams
                [details]="data?.details?.userAssignment"
                [campaign]="data?.details"
              ></app-teams>
            </mat-tab>

            <!-- Drip Automation-->
            <mat-tab label="Drip Automation">
              <app-drip-automation
                (_emitter)="emitter($event)"
              ></app-drip-automation>
            </mat-tab>

            <!-- Tags Automation-->
            <mat-tab label="Tags Automation">
              <app-tags-automation
                (_emitter)="emitter($event)"
              ></app-tags-automation>
            </mat-tab>
          </mat-tab-group>

          <mat-tab-group
            (selectedTabChange)="getIndex($event?.index)"
            *ngIf="
              data?.details?.numTypeId == 2 || data?.details?.numTypeId == 4
            "
          >
            <!-- General-->
            <mat-tab label="General">
              <app-general
                (_emitter)="emitter($event)"
                [campaign]="data?.details"
              ></app-general>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center" *ngIf="tabIndex != 1">
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button
    mat-flat-button
    color="primary"
    type="submit"
    (click)="onCampaignSubmit()"
    cdkFocusInitial
  >
    Edit Campaign
  </button>
</mat-dialog-actions>
