import {
  Component,
  Inject,
  OnInit,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs/operators';

// UTILS
import { ErrorModel } from 'src/app/utils/models/error';
import { MessageConstant } from 'src/app/utils/message-constant';
import { MiscellaneousConstant } from 'src/app/utils/miscellaneous-constant';

// SERVICES
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';
import { TaggingService } from 'src/app/providers/tagging/tagging.service';
@Component({
  selector: 'app-tags-automation',
  templateUrl: './tags-automation.component.html',
  styleUrls: ['./tags-automation.component.scss'],
})
export class TagsAutomationComponent implements OnInit {
  @Output() _emitter = new EventEmitter<any>();
  tagAutomationForm: FormGroup;
  @ViewChild('tagInputCreatedManually')
  tagInputCreatedManually: ElementRef<HTMLInputElement>;
  @ViewChild('tagInputIncomingAnswerCall')
  tagInputIncomingAnswerCall: ElementRef<HTMLInputElement>;
  @ViewChild('tagInputIncomingUnAnswerCall')
  tagInputIncomingUnAnswerCall: ElementRef<HTMLInputElement>;
  @ViewChild('tagInputZapierOrCarrot')
  tagInputZapierOrCarrot: ElementRef<HTMLInputElement>;
  @ViewChild('tagInputWebForms') tagInputWebForms: ElementRef<HTMLInputElement>;
  @ViewChild('tagInputWebSite') tagInputWebSite: ElementRef<HTMLInputElement>;
  manageNumberForm: FormGroup;
  messageConstant = MessageConstant;
  isAllowManageNumberFeature: boolean = true;
  dripList: any = [];
  currentPage: number = 1;
  currentLimit: number = 100; //environment.pagination.pageLimit;
  tagsCreatedManually: any[] = [];
  tagsIncomingAnswerCall: any[] = [];
  tagsIncomingUnAnswerCall: any[] = [];
  tagsZapierOrCarrot: any[] = [];
  tagsWebForms: any[] = [];
  tagsWebSite: any[] = [];
  allTagList: any[] = [];
  public colorCodes = MiscellaneousConstant.colorCodes;
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrlCreatedManually = new FormControl();
  tagCtrlIncomingAnswerCall = new FormControl();
  tagCtrlIncomingUnAnswerCall = new FormControl();
  tagCtrlZapierOrCarrot = new FormControl();
  tagCtrlWebForms = new FormControl();
  tagCtrlWebSite = new FormControl();
  filteredTagsCreatedManually: Observable<any[]>;
  filteredTagsIncomingAnswerCall: Observable<any[]>;
  filteredTagsIncomingUnAnswerCall: Observable<any[]>;
  filteredTagsZapierOrCarrot: Observable<any[]>;
  filteredTagsWebForms: Observable<any[]>;
  filteredTagsWebSite: Observable<any[]>;
  collapsedSections: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _loaderService: NgxUiLoaderService,
    private fb: FormBuilder,
    public _utilities: CommonFunctionsService,
    private _taggingService: TaggingService
  ) {}

  ngOnInit(): void {
    this.tagAutomationForm = this.fb.group({
      isTagAutomation: [this.data.details?.isTagAutomation || false],
      isTagLeadCreatedManual: [
        this.data.details?.isTagLeadCreatedManual || false,
      ],
      isTagAnsCall: [this.data.details?.isTagAnsCall || false],
      isTagUnansCall: [this.data.details?.isTagUnansCall || false],
      isTagZap: [this.data.details?.isTagZap || false],
      isTagWebForm: [this.data.details?.isTagWebForm || false],
      isTagWebSite: [this.data.details?.isTagWebSite || false],
      leadCreatedManualTagIds: [[]],
      ansCallTagIds: [[]],
      unansCallTagIds: [[]],
      zapTagIds: [[]],
      webFormTagIds: [[]],
      webSiteTagIds: [[]],
    });
    this.getAllTags();
  }

  getAllTags() {
    this._loaderService.start();

    this._taggingService.getTags(this.data?.tagType).subscribe(
      (response) => {
        this._loaderService.stop();
        if (response && response.statusCode == 200) {
          let arr = response.data;
          const result = arr.reduce((acc, d) => {
            if (d.label) {
              const value = { _id: d._id, label: d.label };

              acc.push(value);
            }

            return acc;
          }, []);
          this.allTagList = result;
          if (this.data.details?.leadCreatedManualTagIds) {
            this.data.details?.leadCreatedManualTagIds?.map((x) => {
              this.addExistingTags(x, 1);
            });
          }
          if (this.data.details?.ansCallTagIds) {
            this.data.details?.ansCallTagIds?.map((x) => {
              this.addExistingTags(x, 2);
            });
          }
          if (this.data.details?.unansCallTagIds) {
            this.data.details?.unansCallTagIds?.map((x) => {
              this.addExistingTags(x, 3);
            });
          }
          if (this.data.details?.zapTagIds) {
            this.data.details?.zapTagIds?.map((x) => {
              this.addExistingTags(x, 4);
            });
          }
          if (this.data.details?.webFormTagIds) {
            this.data.details?.webFormTagIds?.map((x) => {
              this.addExistingTags(x, 5);
            });
          }
          if (this.data.details?.webSiteTagIds) {
            this.data.details?.webSiteTagIds?.map((x) => {
              this.addExistingTags(x, 6);
            });
          }

          this.filteredTagsCreatedManually =
            this.tagCtrlCreatedManually.valueChanges.pipe(
              startWith(null),
              map((tag) => (tag ? this._filter(tag) : this.allTagList.slice()))
            );
          this.filteredTagsIncomingAnswerCall =
            this.tagCtrlIncomingAnswerCall.valueChanges.pipe(
              startWith(null),
              map((tag) => (tag ? this._filter(tag) : this.allTagList.slice()))
            );
          this.filteredTagsIncomingUnAnswerCall =
            this.tagCtrlIncomingUnAnswerCall.valueChanges.pipe(
              startWith(null),
              map((tag) => (tag ? this._filter(tag) : this.allTagList.slice()))
            );
          this.filteredTagsZapierOrCarrot =
            this.tagCtrlZapierOrCarrot.valueChanges.pipe(
              startWith(null),
              map((tag) => (tag ? this._filter(tag) : this.allTagList.slice()))
            );
          this.filteredTagsWebForms = this.tagCtrlWebForms.valueChanges.pipe(
            startWith(null),
            map((tag) => (tag ? this._filter(tag) : this.allTagList.slice()))
          );
          this.filteredTagsWebSite = this.tagCtrlWebSite.valueChanges.pipe(
            startWith(null),
            map((tag) => (tag ? this._filter(tag) : this.allTagList.slice()))
          );
        }
      },
      (err: ErrorModel) => {
        this._loaderService.stop();
      }
    );
  }

  private _filter(value) {
    let filterValue;
    if (value._id) {
      filterValue = value.label?.toLowerCase();
    } else {
      filterValue = value.toLowerCase();
    }

    return this.allTagList.filter(
      (tag) => tag.label.toLowerCase().indexOf(filterValue) === 0
    );
  }

  addExistingTags(tagId, viewIndex) {
    let filterArray = this.allTagList.filter((x) => x._id === tagId);

    if (filterArray.length > 0) {
      if (viewIndex == 1) {
        this.tagsCreatedManually.push(filterArray[0]);
      } else if (viewIndex == 2) {
        this.tagsIncomingAnswerCall.push(filterArray[0]);
      } else if (viewIndex == 3) {
        this.tagsIncomingUnAnswerCall.push(filterArray[0]);
      } else if (viewIndex == 4) {
        this.tagsZapierOrCarrot.push(filterArray[0]);
      } else if (viewIndex == 5) {
        this.tagsWebForms.push(filterArray[0]);
      } else if (viewIndex == 6) {
        this.tagsWebSite.push(filterArray[0]);
      }
      this.patchChipValue();
    }
  }

  onSearchChange(text, index) {
    let isShow = false;

    if (text) {
      let filterArray = this.allTagList.filter((x) => {
        return x?.label === text;
      });
      if (filterArray?.length == 0) {
        isShow = true;
      } else {
        isShow = false;
      }
    } else {
      isShow = false;
    }
  }

  add(event: MatChipInputEvent, viewIndex): void {
    const input = event.input;
    const value = event.value;
    if (this.checkForUnique(value, viewIndex)) {
      let index = -1;
      for (let i = 0; i < this.allTagList.length; i++) {
        if (this.allTagList[i].label?.toLowerCase() == value.toLowerCase()) {
          index = i;
          break;
        }
      }
      if (viewIndex == 1) {
        if (index >= 0) {
          this.tagsCreatedManually.push(this.allTagList[index]);
        } else {
          if ((value || '').trim()) {
            this.tagsCreatedManually.push({
              _id: 'custom',
              label: value.trim(),
            });
          }
        }
      } else if (viewIndex == 2) {
        if (index >= 0) {
          this.tagsIncomingAnswerCall.push(this.allTagList[index]);
        } else {
          if ((value || '').trim()) {
            this.tagsIncomingAnswerCall.push({
              _id: 'custom',
              label: value.trim(),
            });
          }
        }
      } else if (viewIndex == 3) {
        if (index >= 0) {
          this.tagsIncomingUnAnswerCall.push(this.allTagList[index]);
        } else {
          if ((value || '').trim()) {
            this.tagsIncomingUnAnswerCall.push({
              _id: 'custom',
              label: value.trim(),
            });
          }
        }
      } else if (viewIndex == 4) {
        if (index >= 0) {
          this.tagsZapierOrCarrot.push(this.allTagList[index]);
        } else {
          if ((value || '').trim()) {
            this.tagsZapierOrCarrot.push({
              _id: 'custom',
              label: value.trim(),
            });
          }
        }
      } else if (viewIndex == 5) {
        if (index >= 0) {
          this.tagsWebForms.push(this.allTagList[index]);
        } else {
          if ((value || '').trim()) {
            this.tagsWebForms.push({ _id: 'custom', label: value.trim() });
          }
        }
      } else if (viewIndex == 6) {
        if (index >= 0) {
          this.tagsWebSite.push(this.allTagList[index]);
        } else {
          if ((value || '').trim()) {
            this.tagsWebSite.push({ _id: 'custom', label: value.trim() });
          }
        }
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    if (viewIndex == 1) {
      this.tagCtrlCreatedManually.setValue(null);
    } else if (viewIndex == 2) {
      this.tagCtrlIncomingAnswerCall.setValue(null);
    } else if (viewIndex == 3) {
      this.tagCtrlIncomingUnAnswerCall.setValue(null);
    } else if (viewIndex == 4) {
      this.tagCtrlZapierOrCarrot.setValue(null);
    } else if (viewIndex == 5) {
      this.tagCtrlWebForms.setValue(null);
    } else if (viewIndex == 6) {
      this.tagCtrlWebSite.setValue(null);
    }
    this.patchChipValue();
  }

  remove(index, viewIndex) {
    if (viewIndex == 1) {
      if (index >= 0) {
        this.tagsCreatedManually.splice(index, 1);
      }
    } else if (viewIndex == 2) {
      if (index >= 0) {
        this.tagsIncomingAnswerCall.splice(index, 1);
      }
    } else if (viewIndex == 3) {
      if (index >= 0) {
        this.tagsIncomingUnAnswerCall.splice(index, 1);
      }
    } else if (viewIndex == 4) {
      if (index >= 0) {
        this.tagsZapierOrCarrot.splice(index, 1);
      }
    } else if (viewIndex == 5) {
      if (index >= 0) {
        this.tagsWebForms.splice(index, 1);
      }
    } else if (viewIndex == 6) {
      if (index >= 0) {
        this.tagsWebSite.splice(index, 1);
      }
    }
    this.patchChipValue();
  }

  selected(event: MatAutocompleteSelectedEvent, viewIndex): void {
    if (this.checkForUnique(event.option.value.label, viewIndex)) {
      if (viewIndex == 1) {
        this.tagsCreatedManually.push(event.option.value);
        this.tagInputCreatedManually.nativeElement.value = '';
        this.tagCtrlCreatedManually.setValue(null);
        this.tagInputCreatedManually.nativeElement.blur();
      } else if (viewIndex == 2) {
        this.tagsIncomingAnswerCall.push(event.option.value);
        this.tagInputIncomingAnswerCall.nativeElement.value = '';
        this.tagCtrlIncomingAnswerCall.setValue(null);
        this.tagInputIncomingAnswerCall.nativeElement.blur();
      } else if (viewIndex == 3) {
        this.tagsIncomingUnAnswerCall.push(event.option.value);
        this.tagInputIncomingUnAnswerCall.nativeElement.value = '';
        this.tagCtrlIncomingUnAnswerCall.setValue(null);
        this.tagInputIncomingUnAnswerCall.nativeElement.blur();
      } else if (viewIndex == 4) {
        this.tagsZapierOrCarrot.push(event.option.value);
        this.tagInputZapierOrCarrot.nativeElement.value = '';
        this.tagCtrlZapierOrCarrot.setValue(null);
        this.tagInputZapierOrCarrot.nativeElement.blur();
      } else if (viewIndex == 5) {
        this.tagsWebForms.push(event.option.value);
        this.tagInputWebForms.nativeElement.value = '';
        this.tagCtrlWebForms.setValue(null);
        this.tagInputWebForms.nativeElement.blur();
      } else if (viewIndex == 6) {
        this.tagsWebSite.push(event.option.value);
        this.tagInputWebSite.nativeElement.value = '';
        this.tagCtrlWebSite.setValue(null);
        this.tagInputWebSite.nativeElement.blur();
      }
    }
    this.patchChipValue();
  }

  resetAllChip() {
    this.tagAutomationForm.patchValue({
      isTagLeadCreatedManual: false,
      isTagAnsCall: false,
      isTagUnansCall: false,
      isTagZap: false,
      isTagWebForm: false,
      isTagWebSite: false,
    });
    this.resetChipIds();
  }

  resetChipIds() {
    if (this.tagAutomationForm?.value?.isTagLeadCreatedManual == false) {
      this.tagsCreatedManually = [];
    }
    if (this.tagAutomationForm?.value?.isTagAnsCall == false) {
      this.tagsIncomingAnswerCall = [];
    }
    if (this.tagAutomationForm?.value?.isTagUnansCall == false) {
      this.tagsIncomingUnAnswerCall = [];
    }
    if (this.tagAutomationForm?.value?.isTagZap == false) {
      this.tagsZapierOrCarrot = [];
    }
    if (this.tagAutomationForm?.value?.isTagWebForm == false) {
      this.tagsWebForms = [];
    }
    if (this.tagAutomationForm?.value?.isTagWebSite == false) {
      this.tagsWebSite = [];
    }
    this.patchChipValue();
  }

  patchChipValue() {
    let tagsCreatedManually = [],
      tagsIncomingAnswerCall = [],
      tagsIncomingUnAnswerCall = [],
      tagsZapierOrCarrot = [],
      tagsWebForms = [],
      tagsWebSite = [];

    this.tagsCreatedManually.filter((e) => tagsCreatedManually.push(e));
    this.tagsIncomingAnswerCall.filter((e) => tagsIncomingAnswerCall.push(e));
    this.tagsIncomingUnAnswerCall.filter((e) =>
      tagsIncomingUnAnswerCall.push(e)
    );
    this.tagsZapierOrCarrot.filter((e) => tagsZapierOrCarrot.push(e));
    this.tagsWebForms.filter((e) => tagsWebForms.push(e));
    this.tagsWebSite.filter((e) => tagsWebSite.push(e));
    this.tagAutomationForm.patchValue({
      leadCreatedManualTagIds: tagsCreatedManually,
      ansCallTagIds: tagsIncomingAnswerCall,
      unansCallTagIds: tagsIncomingUnAnswerCall,
      zapTagIds: tagsZapierOrCarrot,
      webFormTagIds: tagsWebForms,
      webSiteTagIds: tagsWebSite,
    });
    this._emitter.emit({
      type: 'tag-automation',
      data: this.tagAutomationForm.value,
    });
  }

  isVisible(tag, viewIndex) {
    if (viewIndex == 1) {
      const tagIndex = this.tagsCreatedManually.findIndex(
        (x) => x.label === tag.label
      );
      return tagIndex > -1 ? false : true;
    }
    if (viewIndex == 2) {
      const tagIndex = this.tagsIncomingAnswerCall.findIndex(
        (x) => x.label === tag.label
      );
      return tagIndex > -1 ? false : true;
    }
    if (viewIndex == 3) {
      const tagIndex = this.tagsIncomingUnAnswerCall.findIndex(
        (x) => x.label === tag.label
      );
      return tagIndex > -1 ? false : true;
    }
    if (viewIndex == 4) {
      const tagIndex = this.tagsZapierOrCarrot.findIndex(
        (x) => x.label === tag.label
      );
      return tagIndex > -1 ? false : true;
    }
    if (viewIndex == 5) {
      const tagIndex = this.tagsWebForms.findIndex(
        (x) => x.label === tag.label
      );
      return tagIndex > -1 ? false : true;
    }
    if (viewIndex == 6) {
      const tagIndex = this.tagsWebSite.findIndex((x) => x.label === tag.label);
      return tagIndex > -1 ? false : true;
    }
    return true;
  }

  toggleSection(groupIndex) {
    const index = this.collapsedSections.findIndex((x) => x === groupIndex);
    this.collapsedSections = [];
    if (index > -1) {
      this.collapsedSections.splice(index, 1);
    } else {
      this.collapsedSections.push(groupIndex);
    }
  }

  isCollapseVisible(groupIndex) {
    const index = this.collapsedSections.findIndex((x) => x === groupIndex);
    return index > -1 ? false : true;
  }

  checkForUnique(val, viewIndex) {
    let flag = true;
    if (viewIndex == 1) {
      for (let i = 0; i < this.tagsCreatedManually.length; i++) {
        if (
          this.tagsCreatedManually[i].label?.toLowerCase() == val.toLowerCase()
        ) {
          flag = false;
          break;
        }
      }
    } else if (viewIndex == 2) {
      for (let i = 0; i < this.tagsIncomingAnswerCall.length; i++) {
        if (
          this.tagsIncomingAnswerCall[i].label?.toLowerCase() ==
          val.toLowerCase()
        ) {
          flag = false;
          break;
        }
      }
    } else if (viewIndex == 3) {
      for (let i = 0; i < this.tagsIncomingUnAnswerCall.length; i++) {
        if (
          this.tagsIncomingUnAnswerCall[i].label?.toLowerCase() ==
          val.toLowerCase()
        ) {
          flag = false;
          break;
        }
      }
    } else if (viewIndex == 4) {
      for (let i = 0; i < this.tagsZapierOrCarrot.length; i++) {
        if (
          this.tagsZapierOrCarrot[i].label?.toLowerCase() == val.toLowerCase()
        ) {
          flag = false;
          break;
        }
      }
    } else if (viewIndex == 5) {
      for (let i = 0; i < this.tagsWebForms.length; i++) {
        if (this.tagsWebForms[i].label?.toLowerCase() == val.toLowerCase()) {
          flag = false;
          break;
        }
      }
    } else if (viewIndex == 6) {
      for (let i = 0; i < this.tagsWebSite.length; i++) {
        if (this.tagsWebSite[i].label?.toLowerCase() == val.toLowerCase()) {
          flag = false;
          break;
        }
      }
    }
    return flag;
  }
}
