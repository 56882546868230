<div class="re-transcript-main">
  <div class="re-infobox">
    <div class="re-media-left">
      <img class="p-element re-media-object" [src]="data?.icon" />
    </div>
    <div class="re-media-body">
      <div class="re-title-container">
        <h4 class="re-media-heading">
          <span class="re-sender-name">
            {{ data?.sellerNumber }} • {{ data?.sellerName }}
          </span>
        </h4>
      </div>
      <div class="re-names">
        <span class="re-txt"> {{ data?.direction }}: </span
        ><span>{{ data?.fromName }}</span
        ><span> {{ data?.fromNumber }} </span>
      </div>
    </div>
    <app-audio-player
      [url]="data?.recording"
      [timeOnRecording]="timeOnRecording"
    ></app-audio-player>
  </div>
  <div class="re-ai-summarise">
    <span class="re-ai-btn"
      ><img
        src="assets/images/al-start.svg"
        width="10"
        alt="al-start"
        class="mr-1"
      />RE-AI
    </span>
  </div>
  <div class="re-ai-infodiv">
    <div class="re-tabbtn">
      <button
        type="button"
        [class.btn-active]="isActiveCallSummery"
        (click)="onTabChange(true)"
        class="btn btn-active"
      >
        Call Summary
      </button>
      <button
        *ngIf="transcriptTimeline?.length"
        type="button"
        [class.btn-active]="!isActiveCallSummery"
        (click)="onTabChange(false)"
        class="btn"
      >
        Call Transcript
      </button>
    </div>
    <div *ngIf="isActiveCallSummery">
      <div class="mb-2">
        <h4 class="re-subtitle">Summary</h4>
        <div class="re-subtext">
          {{ summary }}
        </div>
      </div>
      <div class="mb-3" *ngIf="speaker?.length > 0">
        <h4 class="re-subtitle">Talk Time Analytics</h4>
        <div class="w-100 d-inline-block">
          <div
            class="re-analytics"
            *ngFor="let speaker of speaker; let i = index"
          >
            <h4 class="re-name">{{ speaker?.name }}</h4>
            <div class="re-progress-div">
              <div
                class="re-progress"
                [style.width.%]="formatPer(speaker?.percentage)"
              ></div>
            </div>
            <div class="re-persontage">
              {{ formatPer(speaker?.percentage) }}%
            </div>
          </div>
        </div>
      </div>
      <div class="mb-2">
        <h4 class="re-subtitle">Lead Qualification Questions</h4>
        <div class="mb-3">
          <div class="re-singleinfo">
            <img
              class="re-img"
              src="assets/images/ai-call-transcription/building.svg"
              alt=""
            />
            <div class="re-text1">Property</div>
            <div class="re-text2">
              {{ propertyDetails }}
            </div>
          </div>
          <div class="re-singleinfo">
            <img
              class="re-img"
              src="assets/images/ai-call-transcription/reason.svg"
              alt=""
            />
            <div class="re-text1">Reason for Selling</div>
            <div class="re-text2">
              {{ reasonOfSelling }}
            </div>
          </div>
          <div class="re-singleinfo">
            <img
              class="re-img"
              src="assets/images/ai-call-transcription/calendar.svg"
              alt=""
            />
            <div class="re-text1">Timeline for Selling</div>
            <div class="re-text2">
              {{ timelineOfSelling }}
            </div>
          </div>
          <div class="re-singleinfo">
            <img
              class="re-img"
              src="assets/images/ai-call-transcription/home.svg"
              alt=""
            />
            <div class="re-text1">Condition of Property</div>
            <div class="re-text2">
              {{ conditionOfTheProperty }}
            </div>
          </div>
          <div class="re-singleinfo">
            <img
              class="re-img"
              src="assets/images/ai-call-transcription/price.svg"
              alt=""
            />
            <div class="re-text1">Price Expectations</div>
            <div class="re-text2">
              {{ priceExpectations }}
            </div>
          </div>
        </div>
      </div>
      <div class="mb-2">
        <h4 class="re-subtitle">Objection Handling</h4>
        <div class="mb-3">
          <div class="re-singleinfo">
            <img
              class="re-img"
              src="assets/images/ai-call-transcription/reason.svg"
              alt=""
            />
            <div class="re-text1">Seller Objections</div>
            <div class="re-text2">
              {{ objections }}
            </div>
          </div>
          <div class="re-singleinfo">
            <img
              class="re-img"
              src="assets/images/ai-call-transcription/info-circle.svg"
              alt=""
            />
            <div class="re-text1">
              How did we handle the objections raised by seller
            </div>
            <div class="re-text2">
              {{ handleTheObjections }}
            </div>
          </div>
        </div>
      </div>
      <div class="mb-2">
        <h4 class="re-subtitle">Takeaway Action Items</h4>
        <div class="mb-3">
          <div class="re-singleinfo">
            <img
              class="re-img"
              src="assets/images/ai-call-transcription/arrow-square.svg"
              alt=""
            />
            <div class="re-text1">Takeaway Action Items</div>
            <div
              class="re-text2"
              *ngFor="let takeaway of takeaway; let i = index"
            >
              • {{ takeaway }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isActiveCallSummery">
      <div class="mb-2">
        <h4 class="re-subtitle">Call Transcript</h4>
        <div class="re-transcript-div">
          <div
            class="re-transcript"
            *ngFor="let timeTranscript of transcriptTimeline; let i = index"
          >
            <div
              class="re-times"
              (click)="setTimeOnRecording(timeTranscript?.start)"
            >
              {{ secondFormat(timeTranscript?.start) }}
            </div>
            <div class="re-datas">
              <div class="re-desq">
                {{ timeTranscript?.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
