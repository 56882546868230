<!-- ADD/EDIT MODAL -->
<div *ngIf="data?.action !== 'share'" class="re-add-folder-modal">
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        style="display: block !important"
        class="w-100 d-flex justify-content-between re-header"
      >
        {{ header }}
      </h2>
      <span matDialogClose mat-dialog-close style="float: right">
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>

  <form
    [formGroup]="addEditForm"
    (ngSubmit)="onSubmit()"
    autocomplete="off"
    novalidate
  >
    <mat-dialog-content class="re-typography">
      <div class="container">
        <div class="box p-0">
          <div class="row">
            <div class="col-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="labels required">{{ data?.type }} Name</label>
                <input
                  #input
                  type="text"
                  class="form-control"
                  [placeholder]="
                    'Enter ' + utilities.capitalizeName(data?.type) + ' Name'
                  "
                  formControlName="name"
                  name="name"
                  (keyup)="onValueChange($event)"
                />

                <div
                  class="text-danger"
                  *ngIf="submitted && hasError('name', 'required')"
                >
                  <span>
                    {{
                      messageConstant?.requiredField.replace(
                        "Field",
                        utilities.capitalizeName(data?.type) + " Name"
                      )
                    }}
                  </span>
                </div>
                <div class="text-danger" *ngIf="hasError('name', 'pattern')">
                  <span>
                    {{
                      "Please enter valid " +
                        utilities.capitalizeName(data?.type) +
                        " Name "
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center" style="padding-top: 15px !important">
      <button
        *ngIf="data?.action === 'edit'"
        mat-stroked-button
        mat-dialog-close
        (click)="onEditClose()"
      >
        Cancel
      </button>
      <button
        *ngIf="data?.action !== 'edit'"
        mat-stroked-button
        mat-dialog-close
      >
        Cancel
      </button>
      <button
        *ngIf="data?.action == 'add'"
        mat-flat-button
        color="primary"
        cdkFocusInitial
        type="submit"
      >
        Add Folder
      </button>
      <button
        *ngIf="data?.action == 'edit'"
        [disabled]="name == currentValue"
        mat-flat-button
        color="primary"
        cdkFocusInitial
        type="submit"
      >
        Update {{ data?.type }} Name
      </button>
    </mat-dialog-actions>
  </form>
</div>

<!-- SHARE MODAL -->
<div *ngIf="data?.action === 'share'" class="re-file-share-modal">
  <div class="">
    <div class="col-12 d-flex">
      <h2
        mat-dialog-title
        style="display: block !important"
        class="w-100 d-flex justify-content-between"
      >
        Sharing Options
      </h2>
      <span
        matDialogClose
        mat-dialog-close
        (click)="onEditClose()"
        style="float: right"
      >
        <img
          src="assets/images/close-white.svg"
          alt="close"
          style="margin-top: 15px"
        />
      </span>
    </div>
  </div>

  <mat-dialog-content class="re-typography">
    <div class="container no-padd">
      <div class="box padd-15">
        <div class="row">
          <div class="col-12 col-md-12 col-sm-12">
            <div class="form-group">
              <div class="radiosec">
                <p class="radotext">REsimpli sharable link</p>
                <label class="switch">
                  <input
                    type="checkbox"
                    id="checkbox1"
                    name="changeTiming"
                    [(ngModel)]="currentValue"
                    [checked]="data?.data?.isSharing"
                    (change)="shareLink($event)"
                  />
                  <span class="slider"></span>
                  <span class="text1" value="false">Disable</span>
                  <span class="text2" value="true">Enable</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box padd-15">
        <div class="row">
          <div class="col-12 col-md-12 col-sm-12" *ngIf="data?.data?.isSharing">
            <div id="radiobtnlist">
              <div
                class="row re-link-box padd-15"
                *ngIf="data?.data?.shareLink"
              >
                <div class="re-link-link">
                  <p class="sublabel re-label" style="margin-bottom: 4px">
                    Short url
                  </p>
                  <p
                    class="re-copy-to-clipboard"
                    *ngIf="data?.data?.shareLink"
                    (click)="openShareLink()"
                  >
                    {{ baseUrl + data?.data?.shareLink }}
                  </p>
                </div>

                <div class="re-clipboard-box" (click)="copyToClipboard()">
                  <img
                    src="/assets/images/clipboard-link.svg"
                    alt="Copy To Clipboard"
                  />
                  Copy Url
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-12 col-sm-12">
                  <div class="re-m-top-15">
                    <p class="sublabel re-label">Permission</p>
                  </div>
                  <div class="radiolist">
                    <label
                      class="radiobtn"
                      for="exampleRadios1"
                      (click)="accessLevel = 0"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios1"
                        value="0"
                        [checked]="accessLevel === 0"
                        (click)="accessLevel = 0"
                      />
                      View files only
                      <span class="checkmark"></span>
                    </label>
                  </div>

                  <ng-container *ngIf="data?.type !== 'file'">
                    <div class="radiolist">
                      <label
                        (click)="accessLevel = 1"
                        class="radiobtn"
                        for="exampleRadios2"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios2"
                          value="1"
                          [checked]="accessLevel === 1"
                          (click)="accessLevel = 1"
                        />
                        View and add new files
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="radiolist">
                      <label
                        class="radiobtn"
                        for="exampleRadios3"
                        (click)="accessLevel = 2"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios3"
                          value="2"
                          [checked]="accessLevel === 2"
                          (click)="accessLevel = 2"
                        />
                        View, add new files and delete files
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </ng-container>
                </div>
                <div class="col-12 col-md-12 col-sm-12 mb-2">
                  <div class="re-m-top-15">
                    <p class="re-label">
                      URL will be copied to Clipboard when you click Save.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <hr style="margin-bottom: 10px !important" />
  <mat-dialog-actions
    align="center"
    style="padding-top: 5px !important; border: none"
  >
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      (click)="onSubmit(true)"
      cdkFocusInitial
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
