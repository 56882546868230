<div class="re-top-head">
  <div class="col-12 d-flex">
    <h2
      mat-dialog-title
      class="w-100 d-flex justify-content-between"
      style="display: block !important"
    >
      Take a Note
    </h2>
    <span mat-dialog-close style="float: right">
      <img
        src="assets/images/close-white.svg"
        alt="close"
        style="margin-top: 15px"
      />
    </span>
  </div>
</div>
<div class="container-fluid">
  <div class="box">
    <div class="row">
      <div class="col-12 col-md-12 col-sm-12">
        <div role="tablist" class="nav">
          <a
            href="#tabs11"
            role="tab"
            data-toggle="tab"
            aria-expanded="false"
            class="re-tablink active"
            >Leave a Note</a
          >
          <a
            href="#tabs22"
            role="tab"
            data-toggle="tab"
            aria-expanded="false"
            class="re-tablink"
            >Previous notes</a
          >
        </div>
        <div class="tab-content">
          <div role="tabpanel" id="tabs11" class="tab-pane active">
            <div class="form-group mt-2">
              <label class="labels"
                >Note <span class="text-danger">*</span></label
              >
              <textarea
                class="form-control"
                rows="4"
                placeholder="Note text here..."
                [(ngModel)]="comment"
              ></textarea>
            </div>
            <mat-dialog-actions
              align="center"
              style="padding-top: 14px !important"
            >
              <button mat-raised-button mat-dialog-close>Cancel</button>
              <button
                mat-flat-button
                class="re-submit-btn"
                (click)="addLogs()"
                [disabled]="comment.length == 0"
              >
                Add Note
              </button>
            </mat-dialog-actions>
          </div>
          <div role="tabpanel" id="tabs22" class="tab-pane">
            <activity-logs
              #activityLog
              [activityMainStyle]="{ height: 'CALC(100vh - 535px)' }"
              [style]="{ borderTop: 'none', height: 'CALC(100vh - 535px)' }"
              [moduleId]="data?.moduleId"
              [leadId]="data?.subModuleId"
              [listPropertyData]="data?.propertyDetails"
              [activityType]="activityType"
              [moduleName]="moduleName"
              [userListing]="data?.userListing"
              [type]="takeNoteType"
              [activityTabView]="false"
              [activityFilterView]="false"
              [activityQuillBox]="false"
              [isWebFormLink]="true"
            ></activity-logs>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
