// MODULES
import { Component, OnInit, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { StripeCardComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

// UTILS
import { BaseUrl } from '../../../utils/base-url-constants';
import { MessageConstant } from '../../../utils/message-constant';
import { ResponseModel } from '../../../utils/models/response';
import { ErrorModel } from '../../../utils/models/error';
import { CommonFunctionsService } from 'src/app/utils/common-functions/common-functions.service';

// SERVICES
import { SharedService } from '../../../shared/shared.service';
import { BillingService } from '../../../providers/billing/billing.service';

// COMPONENTS
import { AddCardDialogComponent } from '../../../shared/dialog/add-card-dialog/add-card-dialog.component';

@Component({
  selector: 'app-charge-dialog',
  templateUrl: './charge-dialog.component.html',
  styleUrls: ['./charge-dialog.component.scss'],
})
export class ChargeDialogComponent implements OnInit {
  messageConstant = MessageConstant;
  public cardList: any = [];
  public cardMsg: string = '';
  selectedCard: any;
  dialogRef: any;
  isModelFor: any;
  isModelType: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef1: MatDialogRef<ChargeDialogComponent>,
    private _dialog: MatDialog,
    private _loaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    private _sharedService: SharedService,
    private _billingService: BillingService,
    public _utilities: CommonFunctionsService
  ) {}

  ngOnInit(): void {
    this.isModelType = this.data.isModelFor;
    this.selectedCard = {};
    if (this.isModelType) {
      this.getCardList();
    }
  }

  async getCardList() {
    this._loaderService.start();
    this.cardMsg = '';

    let paylod = {};
    if (this.data?.mainUserId) {
      let encryptedString = await this._utilities.getEncryptedString(
        this.data?.mainUserId
      );
      paylod = {
        muid: encryptedString,
      };
    }

    this._billingService
      .getCardList(paylod, this.data?.isWithoutLogin)
      .subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          if (response?.statusCode && response?.statusCode == 200) {
            this.cardList = response?.data;
            this.cardMsg =
              this.cardList.length == 0
                ? 'Please add your card details in order to proceed'
                : '';
            for (let i = 0; i < this.cardList.length; i++) {
              var card = this.cardList[i];
              card.cardDetail = card.brand + ' ending with ' + card.last4;
              card.expiring = card.exp_month + ' / ' + card.exp_year;
              card.iconType = 'CARDS';
              card.type = this.getBrandImage(card.brand);
            }
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
        }
      );
  }

  getBrandImage(name) {
    if (name == 'Visa') {
      return 'card_visa.png';
    }
    if (name == 'MasterCard') {
      return 'card_mastercard.png';
    }
    if (name == 'JCB') {
      return 'card_jcb.png';
    }
    if (name == 'Discover') {
      return 'card_discover.png';
    }
    if (name == 'American Express') {
      return 'card_americanexpress.png';
    }
    if (name == 'Diners Club') {
      return 'card_dinnerclub.png';
    }
    return '';
  }

  addCard() {
    this.dialogRef = this._dialog.open(AddCardDialogComponent, {
      width: '600px',
      data: this.data?.mainUserId ? { mainUserId: this.data?.mainUserId } : {},
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      this.getCardList();
    });
  }

  onCardSelectAction(card) {
    this.selectedCard = card;
  }

  submit() {
    if (this.selectedCard?.id) this.chargeAmount(this.selectedCard.id);
    else this._toastrService.error('Card is reqiured');
  }
  paymentProceed() {
    this.isModelType = true;
    this.getCardList();
  }

  async chargeAmount(cardId) {
    this._loaderService.start();
    let paylod = { cardId: cardId };

    if (this.data?.mainUserId) {
      let encryptedString = await this._utilities.getEncryptedString(
        this.data?.mainUserId
      );
      paylod['muid'] = encryptedString;
    }

    this._billingService
      .renewSubscription(paylod, this.data?.mainUserId ? true : false)
      .subscribe(
        (response: ResponseModel) => {
          this._loaderService.stop();
          if (response?.statusCode && response?.statusCode == 200) {
            this._utilities.chargeSummary = null;
            this._toastrService.success(
              'Renew subscription successfully completed',
              ''
            );
            this.dialogRef1.close({ success: true });
          }
        },
        (err: ErrorModel) => {
          this._loaderService.stop();
          if (err.error) {
            const error: ResponseModel = err.error;
            this._toastrService.error(error.message, '');
          } else {
            this._toastrService.error(this.messageConstant.unknownError, '');
          }
        }
      );
  }
}
