<mat-dialog-content>
  <div class="re-aion-main">
    <div class="re-ai-left">
      <img
        class="re-img"
        src="assets/images/ai-call.svg"
        alt="a2p"
        width="140"
      />
      <div class="re-a2p-div">
        <div class="re-text1">RE-AI On Your Side</div>
        <div class="re-text2">
          AI trained for RE Investors. Simple. Powerful.
        </div>
      </div>
      <div class="text-left">
        <div class="re-impact-title">Charges</div>
        <div class="re-impact-text">
          Two plans to choose from i.e Yearly and Monthly. Save <b>40%</b> with
          yearly plan.
        </div>
      </div>
    </div>
    <div class="re-ai-right">
      <div class="re-status-info">
        <img
          class="re-closeimg"
          src="assets/images/close-black-1.svg"
          alt="close"
          width="20"
          mat-dialog-close
        />
        <div class="re-status-title">Let RE-AI Summerise Calls</div>
        <div class="re-status-text">
          Our RE trained AI model helps you get the call summary, action items,
          property details discussed on call, and call transcript. On a tap of a
          button.
        </div>
        <div class="re-results-title">AI Call Feature Includes</div>
        <div class="w-100 d-inline-block mb-4">
          <div class="re-feature-list">
            <div class="re-text1">Short Summary</div>
          </div>
          <div class="re-feature-list">
            <div class="re-text1">Speak Time Breakdown</div>
          </div>
          <div class="re-feature-list">
            <div class="re-text1">Property Insights</div>
          </div>
          <div class="re-feature-list">
            <div class="re-text1">Seller’s Objection and Work Around</div>
          </div>
          <div class="re-feature-list">
            <div class="re-text1">Takeaway Action</div>
          </div>
        </div>
        <div class="re-preferred-title">
          Unlimited usage. Pick you preferred plan
        </div>
        <div class="text-center">
          <div
            class="re-plan-box re-select-plan"
            *ngIf="isYearlyPlan"
            (click)="getBillingAmount()"
          >
            <div class="re-plan-boxsub">
              <div class="re-price">$99.00/mo</div>
              <div class="re-name">Billed yearly</div>
              <div class="re-discount">40% savings</div>
            </div>
            <div class="re-btn">Continue with Yearly Plan</div>
          </div>
          <div
            class="re-plan-box re-select-plan"
            *ngIf="!isYearlyPlan"
            (click)="getBillingAmount()"
          >
            <div class="re-plan-boxsub">
              <div class="re-price">$149.00/mo</div>
              <div class="re-name">Billed monthly</div>
            </div>
            <div class="re-btn">Choose Monthly Plan</div>
          </div>
        </div>
      </div>
      <div class="re-credit-div">
        <div class="mr-2">
          <div class="re-text1">Looking for pay as you go?</div>
          <div class="re-text2">
            You can still buy individual credits for call summary $1 each.
          </div>
        </div>
        <button type="button" class="btn re-upgrade-btn" (click)="buyCredit()">
          Buy Individual Credit
        </button>
      </div>
    </div>
  </div>
</mat-dialog-content>
